<template>
    <tooltip>
        <span class="tooltip__icon tooltip__btn material-symbols-outlined">error_outline</span>
        <span> {{ $t('entities.productVariation.tooltips.quantity') }}</span>
    </tooltip>
</template>

<script>
import Tooltip from '@/components/common/Tooltip';
export default {
    name: 'variationQuantityTooltip',
    components: {
        Tooltip,
    },
};
</script>

<style lang="scss"></style>
