<template>
    <error-block v-if="!isAvailable"></error-block>
    <Table
        class="dashboard__products-table"
        v-else
        :show-toolbar="false"
        :show-selected-actions="false"
        :show-select-all="false"
        :columns="headers"
        :items="products"
    >
        <!--  IMAGE  -->
        <template v-slot:item.image="{ item }">
            <img
                v-if="images[item.id]"
                class="table__image"
                :alt="images[item.id].alt"
                :src="imageUrl + images[item.id].uri"
            />
            <img v-else class="table__image -default" alt="No image" src="@/assets/images/no-photo.svg" />
        </template>
        <!--  PRICE  -->
        <template v-slot:item.price="{ item }">
            {{ $t('lists.text.from') }} {{ item.defaultVariation.fullPrice | priceFormat(currency) }}
        </template>
        <!--  HEADING  -->
        <template v-slot:item.heading="{ item }">
            <div class="table-edit">
                <router-link class="table-edit__link" :to="{ name: 'Edit Product', params: { id: item.id } }">
                    {{ item.title }}
                </router-link>
                <span class="table-edit__icon material-icons-outlined">edit</span>
            </div>
        </template>
    </Table>
</template>

<script>
import Table from '@/components/common/Table';
import ProductService from '@/services/ProductService';
import ErrorBlock from '@/components/common/ErrorBlock';
import { imageUrl } from '@/helpers/values';
import Product from '@/entities/product/Product';

export default {
    components: { ErrorBlock, Table },
    name: 'DashboardProductsList',
    async created() {
        await this.getItems();
    },
    data: () => ({
        products: [],
        images: {},
        imageUrl: imageUrl(),
        isAvailable: true,
    }),
    computed: {
        currency() {
            return this.$store.getters['config/getCurrency'];
        },
        headers() {
            return [
                //   { text: '№', value: 'no' },
                { text: this.$t('lists.columns.image'), value: 'image', sortable: false },
                { text: this.$t('lists.columns.title'), value: 'heading', sortable: false },
                { text: this.$t('lists.columns.price'), value: 'price', sortable: false },
                { text: this.$t('lists.columns.quantity'), value: 'quantity', sortable: false },
            ];
        },
    },
    methods: {
        async getItems() {
            const params = {
                limit: 5,
            };
            const [error, result] = await ProductService.getAll(params);
            if (error) {
                this.isAvailable = false;
                return;
            }
            this.products = result.products.map((item) => new Product(item));
            this.images = Product.getDefaultImages(this.products);
        },
    },
};
</script>

<style scoped></style>
