<template>
    <headed-block class="product-form__block" :heading="$t('entities.blocks.catalogProducts')">
        <catalog-products-modal
            id="catalog-products-modal"
            @remove-item="removeEntry"
            @add-item="addItem"
            v-model="isProductsModalOpen"
        ></catalog-products-modal>
        <template v-slot:header-append>
            <Button icon="add_circle_outline" @click="openProductsModal">{{ $t('entities.add') }}</Button>
        </template>
        <div class="catalog-products-list">
            <error-block v-if="!isAvailable"></error-block>
            <Table
                v-model="selected"
                :columns="headers"
                :items="items"
                :isDataLoaded="isDataLoaded"
                :show-selected-actions="false"
                show-select
                :show-select-all="false"
                @drag-move="handleDrag"
                @drag-end="handleDragEnd"
                :draggable="sortBy === 'custom'"
            >
                <template v-slot:toolbar>
                    <div class="catalog-products-list__sort">
                        {{ $t('entities.catalog.sort') }}:
                        <div class="catalog-products-list__sort-container">
                            <Button
                                :key="button.text"
                                v-for="button in sortButtons"
                                class="catalog-products-list__sort-button"
                                :class="{ '-active': button.sortBy === sortBy }"
                                @click="handleSort(button.sortBy, button.direction)"
                                >{{ button.text }}</Button
                            >
                        </div>
                    </div>
                    <div class="catalog-products-list__items-info">
                        <span>{{ $t('entities.catalog.total') }}: {{ total }}</span>
                        <span class="catalog-products-list__selected-info"
                            >{{ $t('entities.catalog.selected') }}: {{ selected.length }}</span
                        >
                        <Button
                            v-if="selected.length"
                            type="text"
                            class="table__selected-action catalog-products-list__delete-selected"
                            icon="delete"
                            @click="removeSelected"
                        >
                            {{ $t('lists.actions.deleteSelected') }}</Button
                        >
                    </div>
                </template>
                <!--  ACTIONS  -->
                <template v-slot:item.actions="{ item }">
                    <div class="table__actions">
                        <Button
                            :title="$t('lists.deleteButton.product')"
                            @click="removeEntry(item)"
                            type="icon"
                            icon="delete"
                        />
                    </div>
                </template>
                <!--  IMAGE  -->
                <template v-slot:item.image="{ item }">
                    <img
                        v-if="hasImage(item)"
                        class="table__image"
                        :alt="images[item.id].alt"
                        :src="`${imageUrl}/${images[item.id].uri}`"
                    />
                    <img v-else class="table__image -default" alt="No image" src="@/assets/images/no-photo.svg" />
                </template>
                <!--  PRICE  -->
                <template v-slot:item.price="{ item }">
                    {{ item.getListPrice(currency) }}
                </template>
                <!-- ENABLED -->
                <template v-slot:item.enabled="{ item }">
                    <status
                        :icon="item.enabled ? 'check' : 'close'"
                        :text="
                            item.enabled
                                ? $t('entities.product.fields.enabled')
                                : $t('entities.product.fields.disabled')
                        "
                        :type="item.enabled ? 'success' : 'warning'"
                    ></status>
                </template>
                <!--  STOCK  -->
                <template v-slot:item.stock="{ item }">
                    <chip v-if="item.variations.every((item) => item.stock === 0)" type="error">
                        {{ $t('entities.product.stock.outOfStock') }}
                    </chip>
                    <chip v-else-if="item.variations.some((item) => item.stock === 0)" type="warning">
                        {{ $t('entities.product.stock.partially') }}
                    </chip>
                    <chip v-else type="success">
                        {{ $t('entities.product.stock.inStock') }}
                    </chip>
                </template>
                <!--  POSITION  -->
                <template v-slot:item.position="{ item }">
                    <input-text
                        class="table__position-input"
                        type="number"
                        v-model="entries[item.id].position"
                        @change="updatePosition(item, $event)"
                    ></input-text>
                </template>
                <!--  TITLE  -->
                <template v-slot:item.title="{ item }">
                    <span class="table-edit">
                        <router-link
                            target="_blank"
                            class="table-edit__link"
                            :to="{ name: 'Edit Product', params: { id: item.id } }"
                            >{{ item.title }}</router-link
                        >
                        <span class="table-edit__icon material-icons-outlined">edit</span>
                    </span>
                </template>
            </Table>
            <div class="catalog-products-list__footer">
                <Button
                    v-if="hasMore && isDataLoaded && isMoreLoaded"
                    @click="showMoreProducts"
                    class="catalog-products-list__show-more"
                    type="text"
                    >{{ $t('entities.catalog.showMore') }}</Button
                >
                <loader-circular v-else-if="!isMoreLoaded" size="medium" class="spinner"></loader-circular>
            </div>
        </div>
    </headed-block>
</template>

<script>
import CatalogService from '@/services/CatalogService';
import ErrorBlock from '@/components/common/ErrorBlock';
import { imageUrl } from '@/helpers/values';
import HeadedBlock from '@/components/common/HeadedBlock';
import CatalogProductsModal from '@/components/catalog/CatalogProductsModal';
import Table from '@/components/common/Table';
import InputText from '@/components/form/controls/InputText';
import Button from '@/components/common/Button';
import Status from '@/components/common/Status';
import { cloneDeep } from 'lodash';
import CatalogEntry from '@/entities/catalog/CatalogEntry';
import { arrayMove } from '@/helpers/utils';
import Product from '@/entities/product/Product';
import LoaderCircular from '@/components/common/ProgressCircular';
import EventEmitter from '@/helpers/eventEmitter';
import Chip from '@/components/common/Chip';

export default {
    name: 'CatalogProductsList',
    components: {
        Chip,
        LoaderCircular,
        Status,
        InputText,
        CatalogProductsModal,
        HeadedBlock,
        ErrorBlock,
        Table,
        Button,
    },
    async created() {
        //TODO: сделать notIds/ids для запроса, чтобы показывать выбранные товары в первую очередь?
        //как тогда поступить с пагинацией?
        await this.getItems();
    },
    props: {
        sortBy: {
            type: String,
            default: '',
        },
    },
    data: () => ({
        isDataLoaded: false,
        isMoreLoaded: true,
        items: [],
        entries: {},
        images: {},
        isAvailable: true,
        isProductsModalOpen: false,
        limit: 10,
        offset: 0,
        selected: [],
        total: null,
        imageUrl: imageUrl(),
    }),
    computed: {
        catalogId() {
            return this.$route.params.id;
        },
        currency() {
            return this.$store.getters['config/getCurrency'];
        },
        headers() {
            const headers = [
                { value: 'image', text: this.$t('lists.columns.product') },
                { value: 'title' },
                { text: this.$t('lists.columns.status'), value: 'enabled' },
                { text: this.$t('lists.columns.price'), value: 'price' },
                { text: this.$t('lists.columns.stock'), value: 'stock', width: '140px' },
                { value: 'actions', width: '20px' },
            ];
            if (this.sortBy === 'custom' && this.isDataLoaded) {
                headers.unshift({ value: 'position', text: this.$t('lists.columns.position'), width: '80px' });
            }
            return headers;
        },
        sortButtons() {
            return [
                { text: this.$t('entities.catalog.lowerPrice'), sortBy: 'price_asc' },
                { text: this.$t('entities.catalog.higherPrice'), sortBy: 'price_desc' },
                { text: this.$t('entities.catalog.alphabetical'), sortBy: 'title' },
                { text: this.$t('entities.catalog.manual'), sortBy: 'custom' },
            ];
        },
        hasMore() {
            return this.total > this.offset + this.limit;
        },
    },
    methods: {
        async getItems() {
            this.isDataLoaded = false;
            await this.getEntries();
            const [products, images] = await this.getProducts();
            this.items = products;
            this.images = images;
            this.isDataLoaded = true;
        },
        async getProducts() {
            const params = {
                sort: this.sortBy,
                limit: this.limit,
                offset: this.offset,
            };

            const [error, result] = await CatalogService.getProductsInCatalog(this.catalogId, params);
            if (error) {
                error.alert();
                this.isAvailable = false;
                return [];
            }
            const { products, count } = result;
            const items = products.map((item) => new Product(item));
            this.total = count;
            const images = Product.getDefaultImages(items);
            return [items, images];
        },

        async addItem(item) {
            const [error, result] = await CatalogService.createEntry(
                this.catalogId,
                new CatalogEntry({ productId: item.id, position: this.total })
            );
            if (error) error.notify();
            const newEntries = this.entries;
            newEntries[item.id] = result;
            this.entries = cloneDeep(newEntries);
        },
        async updatePosition(item, e) {
            EventEmitter.trigger('toggle-loader', { isLoading: true });
            const entry = this.entries[item.id];
            const oldIndex = this.items.findIndex((product) => product.id === item.id);
            const newIndex = +e > this.total ? this.total - 1 : +e <= 0 ? 0 : e - 1;
            entry.position = newIndex;
            await CatalogService.updateEntry(this.catalogId, entry);
            //если переместили элемент на позицию большую, чем сейчас показано элементов
            if (newIndex >= this.items.length) {
                const [error, result] = await CatalogService.getProductsInCatalog(this.catalogId, {
                    sort: 'custom',
                    offset: this.items.length - 1,
                    limit: 1,
                });
                if (error) {
                    error.notify();
                }
                this.items.splice(oldIndex, 1);
                this.items.push(new Product(result.products[0]));
            } else {
                this.items = arrayMove(this.items, oldIndex, newIndex);
            }
            if (newIndex > oldIndex) {
                for (let i = oldIndex; i < newIndex; i++) {
                    const product = this.items[i];
                    if (!product) break;
                    const entry = this.entries[product.id];
                    entry.position -= 1;
                }
            } else {
                for (let i = newIndex + 1; i <= oldIndex; i++) {
                    const product = this.items[i];
                    const entry = this.entries[product.id];
                    entry.position = +entry.position + 1;
                }
            }
            EventEmitter.trigger('toggle-loader', { isLoading: false });
        },
        async getEntries() {
            const [error, entries] = await CatalogService.getAllEntries(this.catalogId);
            if (error) {
                error.alert();
                return [];
            }
            const entriesMap = {};
            entries.forEach((item) => {
                entriesMap[item.productId] = item;
            });
            this.entries = cloneDeep(entriesMap);
        },

        async showMoreProducts() {
            this.isMoreLoaded = false;
            this.offset += this.limit;
            this.limit = 100;
            const [products, images] = await this.getProducts();
            await this.getEntries();
            this.items = [...this.items, ...products];
            this.images = { ...this.images, ...images };
            this.isMoreLoaded = true;
        },

        openProductsModal() {
            this.isProductsModalOpen = true;
        },
        getDefaultVariation(item) {
            return item.variations.find((item) => item.default);
        },
        hasImage(product) {
            return this.images[product.id]?.uri;
        },
        async removeEntry(removedItem) {
            this.isDataLoaded = false;
            const entry = this.entries[removedItem.id];
            await CatalogService.removeEntry(this.catalogId, entry.id);
            const indexOfDeleted = this.items.findIndex((item) => item.id === removedItem.id);
            //если удаляем через модалку за пределами показанных товаров
            if (indexOfDeleted === -1) {
                this.total -= 1;
                this.isDataLoaded = true;
                return;
            }
            //если удаляем из показанных товаров, но показаны не все товары
            if (this.total >= this.items.length) {
                const params = { sort: this.sortBy, limit: 1, offset: this.items.length - 1 };
                const [error, result] = await CatalogService.getProductsInCatalog(this.catalogId, params);
                if (error) {
                    error.notify();
                    return;
                }
                const { products, count } = result;
                const items = products.map((item) => new Product(item));
                this.items.push(...items);
                this.total = count;
            }
            //если удаляем из показанных и показаны все
            else {
                this.total -= 1;
            }
            this.items.splice(indexOfDeleted, 1);
            if (this.sortBy === 'custom') {
                for (let i = 0; i < this.items.length; i++) {
                    const product = this.items[i];
                    const entry = this.entries[product.id];
                    entry.position = i;
                }
            }
            this.selected = [];
            this.isDataLoaded = true;
        },

        async removeSelected() {
            this.isDataLoaded = false;
            const promises = this.selected.map((item) => {
                const entry = this.entries[item.id];
                return CatalogService.removeEntry(this.catalogId, entry.id);
            });
            await Promise.all(promises);
            const newItems = this.items.filter(
                (item) => !this.selected.find((selectedProduct) => selectedProduct.id === item.id)
            );

            //получаем итемы по количеству удалённых

            if (this.total >= this.limit + this.offset) {
                const [error, result] = await CatalogService.getProductsInCatalog(this.catalogId, {
                    sort: this.sortBy,
                    offset: newItems.length,
                    limit: this.selected.length,
                });

                if (error) {
                    error.notify();
                }

                const { products } = result;
                const items = products.map((item) => new Product(item));
                this.items = [...newItems, ...items];
            } else {
                this.items = newItems;
            }
            //проставляем позиции заново
            if (this.sortBy === 'custom') {
                for (let i = 0; i < this.items.length; i++) {
                    const product = this.items[i];
                    const entry = this.entries[product.id];
                    entry.position = i;
                }
            }
            this.total -= this.selected.length;
            //TODO: изменение items тригерит watch у table, в котором selected сбрасываются - не дело
            this.selected = [];
            this.isDataLoaded = true;
        },
        async handleSort(sortBy) {
            this.$emit('change-sorting', sortBy);
            this.$nextTick(async () => {
                this.isDataLoaded = false;
                this.offset = 0;
                this.limit = 10;
                this.selected = [];
                await this.getItems();
                this.isDataLoaded = true;
            });
        },
        handleDrag({ event, items }) {
            const direction = event.draggedRect.top > event.relatedRect.top ? 'up' : 'down';
            const { futureIndex, index } = event.draggedContext;
            const relatedIndex = event.relatedContext.index;
            const relatedIndexProduct = items[relatedIndex];
            const currentIndexProduct = items[index];
            const relatedEntry = this.entries[relatedIndexProduct.id];
            const currentEntry = this.entries[currentIndexProduct.id];
            relatedEntry.position = direction === 'up' ? relatedEntry.position + 1 : relatedEntry.position - 1;
            currentEntry.position = futureIndex;
        },
        async handleDragEnd({ event, items }) {
            EventEmitter.trigger('toggle-loader', { isLoading: true });
            this.items = cloneDeep(items);
            const index = event.newIndex;
            const product = this.items[index];
            await CatalogService.updateEntry(this.catalogId, this.entries[product.id]);
            EventEmitter.trigger('toggle-loader', { isLoading: false });
        },
    },
    watch: {
        async isProductsModalOpen(val) {
            //обновляем товары при закрытии модалки
            if (!val) {
                this.offset = 0;
                this.limit = 10;
                this.showMore = true;
                await this.getItems();
            }
        },
    },
};
</script>
<style lang="scss">
@import '@/scss/variables.scss';
.catalog-products-list {
    width: 100%;
    &__footer {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 23px;
        min-height: 36px;
    }
    &__sort {
        color: var(--v-on-surface-medium-base) !important;
        display: flex;
        gap: 12px;
        align-items: center;
    }
    &__sort-container {
        background-color: var(--v-surface-overlay-base);
        display: inline-flex;
        border-radius: 8px;
        padding: 4px;
        gap: 24px;
    }
    &__sort-button {
        background-color: transparent !important;
        color: var(--v-on-surface-medium-base) !important;
        font-weight: 400;
        &.button.button--primary:hover {
            background-color: var(--v-surface-base) !important;
            color: var(--v-on-surface-high-base) !important;
        }
        &.-active {
            background-color: var(--v-surface-base) !important;
            color: var(--v-on-surface-high-base) !important;
        }
    }
    &__items-info {
        margin-top: 35px;
        margin-bottom: 23px;
        display: flex;
        align-items: center;
    }
    &__selected-info {
        color: var(--v-on-surface-medium-base) !important;
        margin-left: 12px;
    }
    &__selected-action {
        margin-left: 12px;
    }

    .table {
        padding: 0 !important;
    }
    .table__position-input {
        input {
            text-align: center !important;
        }
    }
    &__delete-selected {
        margin-left: 12px;
    }
    &__saving-loader {
        display: flex;
        gap: 10px;
        margin-left: 22px;
    }
}
</style>
