import { SectionFixtureType } from '@/entities/section/fixtures';
import { createLayoutVariation } from '@/entities/section/fixtures/_base';

const itemStyleVariations = {
    style1: 'style1',
    style2: 'style2',
    style3: 'style3',
    style4: 'style4',
};

const createSectionFixture = (args: {
    preview: string;
    title: string;
    itemLayout: any;
    itemsTemplate: string;
    items: any[];
    options: any;
}): SectionFixtureType => {
    return {
        preview: args.preview,
        title: args.title,
        group: 'Properties',
        data: {
            id: 'fixture',
            items: args.items,
            required: false,
            fixed: false,
            type: 'SectionPropertyBase',
            options: {
                ...args.options,
                itemsLayout: args.itemLayout,
                itemsStyle: {
                    template: args.itemsTemplate,
                    gap: 20,
                },
            },
        },
    };
};

const summary: any = {};

summary.style1__list_column_4_white = createSectionFixture({
    title: 'Properties list with 4 columns, item style 1, white',
    preview: require('@/assets/images/sections/properties/property-style1prev1.jpg'),
    options: {
        section: {
            width: 'full',
            padding: {
                desktop: { top: 28, right: 0, bottom: 28, left: 0 },
                tablet: { top: 28, right: 0, bottom: 28, left: 0 },
                mobile: { top: 16, right: 0, bottom: 16, left: 0 },
            },
            borderRadius: {
                desktop: 0,
                tablet: 0,
                mobile: 0,
            },
            background: 'none',
        },
        container: {
            width: 'content',
            padding: {
                desktop: null,
                tablet: null,
                mobile: null,
            },
            borderRadius: {
                desktop: 0,
                tablet: 0,
                mobile: 0,
            },
            background: 'none',
        },
        heading: {
            title: 'Why us?',
            colorTitle: null,
            note: null,
            colorNote: null,
            textAlign: 'center',
            marginBottom: {
                desktop: null,
                tablet: null,
                mobile: null,
            },
        },
        content: {
            htmlBefore: null,
            htmlAfter: null,
            colorText: null,
        },
        footer: {
            note: null,
            colorNote: null,
            textAlign: 'left',
            marginTop: {
                desktop: null,
                tablet: null,
                mobile: null,
            },
            button: {
                isShow: false,
                styles: 'primary',
                text: 'Button',
                textColor: null,
                bgColor: null,
                link: null,
                iconLeft: null,
                iconRight: null,
                type: 'Link',
                url: null,
            },
        },
    },
    itemLayout: {
        desktop: createLayoutVariation({ type: 'list', columns: 4 }),
        tablet: createLayoutVariation({ type: 'list', columns: 2 }),
        mobile: createLayoutVariation({ type: 'list', columns: 1 }),
    },
    itemsTemplate: itemStyleVariations.style1,
    items: [
        {
            id: 'fixture',
            type: itemStyleVariations.style1,
            options: {
                heading: {
                    title: 'Free Shipping in the UAE',
                    color: null,
                },
                content: {
                    text: 'Free shipping over order AED 600',
                    color: null,
                },
            },
            position: 0,
        },
        {
            id: 'fixture',
            type: itemStyleVariations.style1,
            options: {
                heading: {
                    title: 'IQOS Warranty',
                    color: null,
                },
                content: {
                    text: 'Register your device on IQOS official website.',
                    color: null,
                },
            },
            position: 1,
        },
        {
            id: 'fixture',
            type: itemStyleVariations.style1,
            options: {
                heading: {
                    title: 'Support 24/7',
                    color: null,
                },
                content: {
                    text: 'Contact Us 24 hours a day, 7 days a week',
                    color: null,
                },
            },
            position: 1,
        },
        {
            id: 'fixture',
            type: itemStyleVariations.style1,
            options: {
                heading: {
                    title: '100% Genuine Products',
                    color: null,
                },
                content: {
                    text: 'We ensure our products are original',
                    color: null,
                },
            },
            position: 2,
        },
    ],
});
summary.style1__list_column_3_dark = createSectionFixture({
    title: 'Properties list with 3 columns, item style 1, dark',
    preview: require('@/assets/images/sections/properties/property-style1prev2.jpg'),
    options: {
        section: {
            width: 'full',
            padding: {
                desktop: { top: 28, right: 0, bottom: 28, left: 0 },
                tablet: { top: 28, right: 0, bottom: 28, left: 0 },
                mobile: { top: 16, right: 0, bottom: 16, left: 0 },
            },
            borderRadius: {
                desktop: 0,
                tablet: 0,
                mobile: 0,
            },
            background: 'dark',
        },
        container: {
            width: 'content',
            padding: {
                desktop: null,
                tablet: null,
                mobile: null,
            },
            borderRadius: {
                desktop: 0,
                tablet: 0,
                mobile: 0,
            },
            background: 'none',
        },
        heading: {
            title: 'Why us?',
            colorTitle: null,
            note: null,
            colorNote: null,
            textAlign: 'center',
            marginBottom: {
                desktop: null,
                tablet: null,
                mobile: null,
            },
        },
        content: {
            htmlBefore: null,
            htmlAfter: null,
            colorText: null,
        },
        footer: {
            note: null,
            colorNote: null,
            textAlign: 'center',
            marginTop: {
                desktop: null,
                tablet: null,
                mobile: null,
            },
            button: {
                isShow: true,
                styles: 'primary',
                text: 'More details',
                textColor: null,
                bgColor: null,
                link: null,
                iconLeft: null,
                iconRight: null,
            },
        },
    },
    itemLayout: {
        desktop: createLayoutVariation({ type: 'list', columns: 3 }),
        tablet: createLayoutVariation({ type: 'list', columns: 2 }),
        mobile: createLayoutVariation({ type: 'list', columns: 1 }),
    },
    itemsTemplate: itemStyleVariations.style1,
    items: [
        {
            id: 'fixture',
            type: itemStyleVariations.style1,
            options: {
                heading: {
                    title: 'Free Shipping in the UAE',
                    color: '#ffffff',
                },
                content: {
                    text: 'Free shipping over order AED 600',
                    color: '#e3e3e3',
                },
            },
            position: 0,
        },
        {
            id: 'fixture',
            type: itemStyleVariations.style1,
            options: {
                heading: {
                    title: 'IQOS Warranty',
                    color: '#ffffff',
                },
                content: {
                    text: 'Register your device on IQOS official website.',
                    color: '#e3e3e3',
                },
            },
            position: 1,
        },
        {
            id: 'fixture',
            type: itemStyleVariations.style1,
            options: {
                heading: {
                    title: 'Support 24/7',
                    color: '#ffffff',
                },
                content: {
                    text: 'Contact Us 24 hours a day, 7 days a week',
                    color: '#e3e3e3',
                },
            },
            position: 1,
        },
    ],
});
summary.style2__list_column_2 = createSectionFixture({
    title: 'Properties list with 2 columns, item style 2',
    preview: require('@/assets/images/sections/properties/property-style2prev1.jpg'),
    options: {
        section: {
            width: 'full',
            padding: {
                desktop: { top: 28, right: 0, bottom: 28, left: 0 },
                tablet: { top: 28, right: 0, bottom: 28, left: 0 },
                mobile: { top: 16, right: 0, bottom: 16, left: 0 },
            },
            borderRadius: {
                desktop: 0,
                tablet: 0,
                mobile: 0,
            },
            background: 'none',
        },
        container: {
            width: 'content',
            padding: {
                desktop: null,
                tablet: null,
                mobile: null,
            },
            borderRadius: {
                desktop: 0,
                tablet: 0,
                mobile: 0,
            },
            background: 'none',
        },
        heading: {
            title: 'Why us?',
            colorTitle: null,
            note: null,
            colorNote: null,
            textAlign: 'center',
            marginBottom: {
                desktop: null,
                tablet: null,
                mobile: null,
            },
        },
        content: {
            htmlBefore: null,
            htmlAfter: null,
            colorText: null,
        },
        footer: {
            note: null,
            colorNote: null,
            textAlign: 'center',
            marginTop: {
                desktop: null,
                tablet: null,
                mobile: null,
            },
            button: {
                isShow: false,
                styles: 'primary',
                text: 'Button',
                textColor: null,
                bgColor: null,
                link: null,
                iconLeft: null,
                iconRight: null,
                type: 'Link',
                url: null,
            },
        },
    },
    itemLayout: {
        desktop: createLayoutVariation({ type: 'list', columns: 2 }),
        tablet: createLayoutVariation({ type: 'list', columns: 2 }),
        mobile: createLayoutVariation({ type: 'list', columns: 1 }),
    },
    itemsTemplate: itemStyleVariations.style2,
    items: [
        {
            id: 'fixture',
            type: itemStyleVariations.style2,
            options: {
                heading: {
                    title: 'With us',
                    color: null,
                },
                content: {
                    text: '7000+ clients',
                    color: null,
                },
            },
            position: 0,
        },
        {
            id: 'fixture',
            type: itemStyleVariations.style2,
            options: {
                heading: {
                    title: 'Customer Care',
                    color: null,
                },
                content: {
                    text: '10000+ satisfied customers',
                    color: null,
                },
            },
            position: 1,
        },
        {
            id: 'fixture',
            type: itemStyleVariations.style2,
            options: {
                heading: {
                    title: 'Premium Support',
                    color: null,
                },
                content: {
                    text: 'We support online 24 hours a day',
                    color: null,
                },
            },
            position: 2,
        },
        {
            id: 'fixture',
            type: itemStyleVariations.style2,
            options: {
                heading: {
                    title: 'Free Shiping Over 200 AED',
                    color: null,
                },
                content: {
                    text: 'Free Shippig',
                    color: null,
                },
            },
            position: 3,
        },
    ],
});
summary.style3__list_column_3 = createSectionFixture({
    title: 'Properties list with 3 columns, item style 3',
    preview: require('@/assets/images/sections/properties/property-style3prev1.jpg'),
    options: {
        section: {
            width: 'full',
            padding: {
                desktop: { top: 28, right: 0, bottom: 28, left: 0 },
                tablet: { top: 28, right: 0, bottom: 28, left: 0 },
                mobile: { top: 16, right: 0, bottom: 16, left: 0 },
            },
            borderRadius: {
                desktop: 0,
                tablet: 0,
                mobile: 0,
            },
            background: 'none',
        },
        container: {
            width: 'content',
            padding: {
                desktop: null,
                tablet: null,
                mobile: null,
            },
            borderRadius: {
                desktop: 0,
                tablet: 0,
                mobile: 0,
            },
            background: 'none',
        },
        heading: {
            title: 'Our Flexible Car Rental Plans',
            colorTitle: null,
            note: null,
            colorNote: null,
            textAlign: 'center',
            marginBottom: {
                desktop: null,
                tablet: null,
                mobile: null,
            },
        },
        content: {
            htmlBefore: null,
            htmlAfter: null,
            colorText: null,
        },
        footer: {
            note: null,
            colorNote: null,
            textAlign: 'center',
            marginTop: {
                desktop: null,
                tablet: null,
                mobile: null,
            },
            button: {
                isShow: false,
                styles: 'primary',
                text: 'Button',
                textColor: null,
                bgColor: null,
                link: null,
                iconLeft: null,
                iconRight: null,
                type: 'Link',
                url: null,
            },
        },
    },
    itemLayout: {
        desktop: createLayoutVariation({ type: 'list', columns: 3 }),
        tablet: createLayoutVariation({ type: 'list', columns: 2 }),
        mobile: createLayoutVariation({ type: 'list', columns: 1 }),
    },
    itemsTemplate: itemStyleVariations.style3,
    items: [
        {
            id: 'fixture',
            type: itemStyleVariations.style3,
            options: {
                heading: {
                    title: 'Short Term',
                    color: null,
                },
                content: {
                    text:
                        'From daily plans to weekly ones, we pride ourselves in offering a short-term car rental deal to suit varied occasions.',
                    color: null,
                },
            },
            position: 0,
        },
        {
            id: 'fixture',
            type: itemStyleVariations.style3,
            options: {
                heading: {
                    title: 'Monthly',
                    color: null,
                },
                content: {
                    text: 'Whatever your car hire requirements, if you need a car for an extended period.',
                    color: null,
                },
            },
            position: 1,
        },
        {
            id: 'fixture',
            type: itemStyleVariations.style3,
            options: {
                heading: {
                    title: 'Long Term',
                    color: null,
                },
                content: {
                    text:
                        'Be it a small city-car to smoothly move around the busy streets of Dubai or something more substantial in stature.',
                    color: null,
                },
            },
            position: 2,
        },
    ],
});
summary.style3__list_column_3_light = createSectionFixture({
    title: 'Properties list with 3 columns, item style 3, light',
    preview: require('@/assets/images/sections/properties/property-style3prev2.jpg'),
    options: {
        section: {
            width: 'full',
            padding: {
                desktop: { top: 28, right: 0, bottom: 28, left: 0 },
                tablet: { top: 28, right: 0, bottom: 28, left: 0 },
                mobile: { top: 16, right: 0, bottom: 16, left: 0 },
            },
            borderRadius: {
                desktop: 0,
                tablet: 0,
                mobile: 0,
            },
            background: 'middle',
        },
        container: {
            width: 'content',
            padding: {
                desktop: null,
                tablet: null,
                mobile: null,
            },
            borderRadius: {
                desktop: 0,
                tablet: 0,
                mobile: 0,
            },
            background: 'none',
        },
        heading: {
            title: 'Our Flexible Car Rental Plans',
            colorTitle: null,
            note: null,
            colorNote: null,
            textAlign: 'center',
            marginBottom: {
                desktop: null,
                tablet: null,
                mobile: null,
            },
        },
        content: {
            htmlBefore: null,
            htmlAfter: null,
            colorText: null,
        },
        footer: {
            note: null,
            colorNote: null,
            textAlign: 'center',
            marginTop: {
                desktop: null,
                tablet: null,
                mobile: null,
            },
            button: {
                isShow: true,
                styles: 'primary',
                text: 'Get in touch!',
                textColor: null,
                bgColor: null,
                link: null,
                iconLeft: null,
                iconRight: null,
            },
        },
    },
    itemLayout: {
        desktop: createLayoutVariation({ type: 'list', columns: 3 }),
        tablet: createLayoutVariation({ type: 'list', columns: 2 }),
        mobile: createLayoutVariation({ type: 'list', columns: 1 }),
    },
    itemsTemplate: itemStyleVariations.style3,
    items: [
        {
            id: 'fixture',
            type: itemStyleVariations.style3,
            options: {
                heading: {
                    title: 'Short Term',
                    color: null,
                },
                content: {
                    text:
                        'From daily plans to weekly ones, we pride ourselves in offering a short-term car rental deal to suit varied occasions.',
                    color: null,
                },
            },
            position: 0,
        },
        {
            id: 'fixture',
            type: itemStyleVariations.style3,
            options: {
                heading: {
                    title: 'Monthly',
                    color: null,
                },
                content: {
                    text: 'Whatever your car hire requirements, if you need a car for an extended period.',
                    color: null,
                },
            },
            position: 1,
        },
        {
            id: 'fixture',
            type: itemStyleVariations.style3,
            options: {
                heading: {
                    title: 'Long Term',
                    color: null,
                },
                content: {
                    text:
                        'Be it a small city-car to smoothly move around the busy streets of Dubai or something more substantial in stature.',
                    color: null,
                },
            },
            position: 2,
        },
    ],
});
summary.style4__list_column_4 = createSectionFixture({
    title: 'Properties list with 4 columns, item style 4',
    preview: require('@/assets/images/sections/properties/property-style4prev1.jpg'),
    options: {
        section: {
            width: 'full',
            padding: {
                desktop: { top: 28, right: 0, bottom: 28, left: 0 },
                tablet: { top: 28, right: 0, bottom: 28, left: 0 },
                mobile: { top: 16, right: 0, bottom: 16, left: 0 },
            },
            borderRadius: {
                desktop: 0,
                tablet: 0,
                mobile: 0,
            },
            background: 'none',
        },
        container: {
            width: 'content',
            padding: {
                desktop: null,
                tablet: null,
                mobile: null,
            },
            borderRadius: {
                desktop: 0,
                tablet: 0,
                mobile: 0,
            },
            background: 'none',
        },
        heading: {
            title: 'What should you pay attention to?',
            colorTitle: null,
            note: null,
            colorNote: null,
            textAlign: 'center',
            marginBottom: {
                desktop: null,
                tablet: null,
                mobile: null,
            },
        },
        content: {
            htmlBefore: null,
            htmlAfter: null,
            colorText: null,
        },
        footer: {
            note: null,
            colorNote: null,
            textAlign: 'center',
            marginTop: {
                desktop: null,
                tablet: null,
                mobile: null,
            },
            button: {
                isShow: false,
                styles: 'primary',
                text: 'Button',
                textColor: null,
                bgColor: null,
                link: null,
                iconLeft: null,
                iconRight: null,
                type: 'Link',
                url: null,
            },
        },
    },
    itemLayout: {
        desktop: createLayoutVariation({ type: 'list', columns: 4 }),
        tablet: createLayoutVariation({ type: 'list', columns: 2 }),
        mobile: createLayoutVariation({ type: 'list', columns: 1 }),
    },
    itemsTemplate: itemStyleVariations.style4,
    items: [
        {
            id: 'fixture',
            type: itemStyleVariations.style4,
            options: {
                heading: {
                    title: 'Possibility of individual production to the size of your doorway',
                    color: null,
                },
                content: {
                    text: '1',
                    color: null,
                },
            },
            position: 0,
        },
        {
            id: 'fixture',
            type: itemStyleVariations.style4,
            options: {
                heading: {
                    title: 'A variety of finishes from solid oak, wood, MDF panels',
                    color: null,
                },
                content: {
                    text: '2',
                    color: null,
                },
            },
            position: 1,
        },
        {
            id: 'fixture',
            type: itemStyleVariations.style4,
            options: {
                heading: {
                    title: 'Pay attention to the insulation inside. How armored is the door itself or the locks',
                    color: null,
                },
                content: {
                    text: '3',
                    color: null,
                },
            },
            position: 2,
        },
        {
            id: 'fixture',
            type: itemStyleVariations.style4,
            options: {
                heading: {
                    title: 'Well, take a look at the catalog with photos',
                    color: null,
                },
                content: {
                    text: '4',
                    color: null,
                },
            },
            position: 3,
        },
    ],
});
summary.style4__list_column_3 = createSectionFixture({
    title: 'Properties list with 3 columns, item style 4',
    preview: require('@/assets/images/sections/properties/property-style4prev2.jpg'),
    options: {
        section: {
            width: 'full',
            padding: {
                desktop: { top: 28, right: 0, bottom: 28, left: 0 },
                tablet: { top: 28, right: 0, bottom: 28, left: 0 },
                mobile: { top: 16, right: 0, bottom: 16, left: 0 },
            },
            borderRadius: {
                desktop: 0,
                tablet: 0,
                mobile: 0,
            },
            background: 'middle',
        },
        container: {
            width: 'content',
            padding: {
                desktop: null,
                tablet: null,
                mobile: null,
            },
            borderRadius: {
                desktop: 0,
                tablet: 0,
                mobile: 0,
            },
            background: 'none',
        },
        heading: {
            title: 'How to buy an entrance door?',
            colorTitle: null,
            note: null,
            colorNote: null,
            textAlign: 'center',
            marginBottom: {
                desktop: null,
                tablet: null,
                mobile: null,
            },
        },
        content: {
            htmlBefore: null,
            htmlAfter: null,
            colorText: null,
        },
        footer: {
            note: null,
            colorNote: null,
            textAlign: 'center',
            marginTop: {
                desktop: null,
                tablet: null,
                mobile: null,
            },
            button: {
                isShow: true,
                styles: 'primary',
                text: 'Get in touch!',
                textColor: null,
                bgColor: null,
                link: null,
                iconLeft: null,
                iconRight: null,
            },
        },
    },
    itemLayout: {
        desktop: createLayoutVariation({ type: 'list', columns: 3 }),
        tablet: createLayoutVariation({ type: 'list', columns: 2 }),
        mobile: createLayoutVariation({ type: 'list', columns: 1 }),
    },
    itemsTemplate: itemStyleVariations.style4,
    items: [
        {
            id: 'fixture',
            type: itemStyleVariations.style4,
            options: {
                heading: {
                    title: 'Leave a request for a measurement It will take you 10 seconds',
                    color: null,
                },
                content: {
                    text: '1',
                    color: null,
                },
            },
            position: 0,
        },
        {
            id: 'fixture',
            type: itemStyleVariations.style4,
            options: {
                heading: {
                    title: 'Set a convenient time for measurement  A specialist will come and take measurements',
                    color: null,
                },
                content: {
                    text: '2',
                    color: null,
                },
            },
            position: 1,
        },
        {
            id: 'fixture',
            type: itemStyleVariations.style4,
            options: {
                heading: {
                    title: 'Conclude an agreement  At home after measurement, or in the salon',
                    color: null,
                },
                content: {
                    text: '3',
                    color: null,
                },
            },
            position: 2,
        },
    ],
});

export default summary;
