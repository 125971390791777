import PageService from '@/services/PageService';
import CatalogService from '@/services/CatalogService';
import ProductService from '@/services/ProductService';
import apiClient from '@/api/apiClient';
import CustomError from '@/entities/customError';
import { MenuItem } from '@/services/services.types';

export const OBJECT_TYPES = [
    {
        text: 'Page',
        value: 'page',
        get: PageService.getOne.bind(PageService),
    },
    {
        text: 'Catalog',
        value: 'catalog',
        get: CatalogService.getOne.bind(CatalogService),
    },
    {
        text: 'Product',
        value: 'product',
        get: ProductService.getOne.bind(ProductService),
    },
];

class MenuService {
    async getOne(id: string): Promise<[CustomError | null, MenuItem | null]> {
        const url: string = `/menus/${id}/entries`;
        const defaultValue = null;
        const errorPath: string = '[api:menu:getOne]';
        return await apiClient.admin.get({ url, defaultValue, errorPath });
    }

    async createItem(id: string, menuItem: MenuItem): Promise<[CustomError | null, MenuItem | null]> {
        const url: string = `/menus/${id}/entries`;
        const defaultValue = null;
        const errorPath: string = '[api:menu:createItem]';
        return await apiClient.admin.post({ url, defaultValue, errorPath, data: menuItem });
    }

    async updateBatch(id: string, menuItems: MenuItem[]): Promise<[CustomError | null, MenuItem[] | []]> {
        const url: string = `/menus/${id}/entries/batch`;
        const defaultValue = [];
        const errorPath: string = '[api:menu:updateBatch]';
        return await apiClient.admin.put({ url, defaultValue, errorPath, data: menuItems });
    }

    async updateItem(id: string, menuItem: MenuItem): Promise<[CustomError | null, MenuItem | null]> {
        const url: string = `/menus/${id}/entries/${menuItem.id}`;
        const defaultValue = null;
        const errorPath: string = '[api:menu:updateItem]';
        console.log('updateItem', menuItem);
        return await apiClient.admin.put({ url, defaultValue, errorPath, data: menuItem });
    }

    async removeItem(id: string, itemId: string): Promise<[CustomError | null, null]> {
        const url: string = `/menus/${id}/entries/${itemId}`;
        const defaultValue = null;
        const errorPath: string = '[api:menu:removeItem]';
        return await apiClient.admin.delete({ url, defaultValue, errorPath });
    }
}

export default new MenuService();
