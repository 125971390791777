<template>
    <div class="page review-item -column -shrunk">
        <progress-circular class="spinner" v-if="isLoading && isAvailable"></progress-circular>
        <template v-else-if="!isAvailable">
            <div class="page-header">
                <h1 class="page-header__text">{{ $tc('entities.review.title', 1) }}</h1>
            </div>
            <error-block></error-block>
        </template>
        <template v-else>
            <div class="page-header">
                <h1 class="page-header__text">
                    {{ `${currentState.name} on ${selectedProduct.title}` }}
                </h1>
            </div>
            <alert type="success" class="entity-item__success-alert" v-if="isUpdated">
                {{ $tc('notifications.updated', 1, { entity: this.$tc('entities.review.title', 1) }) }}
            </alert>
            <div class="block">
                <form class="block__body form" ref="form">
                    <template>
                        <form-item
                            class="modal__input"
                            v-for="(field, key) in currentState.fields"
                            :key="key"
                            v-bind="field.props"
                            :name="key"
                        >
                            <component
                                v-if="key !== 'productId'"
                                :is="field.component"
                                v-model="currentState[key]"
                                v-bind="field.props || {}"
                                @update-field="onUpdateFieldValue"
                                :name="key"
                                hide-details
                                v-on="getFieldEvents(field.events)"
                                outlined
                                dense
                            ></component>
                            <template v-else>
                                <component
                                    :is="field.component"
                                    v-show="!currentState.productId"
                                    v-model="currentState[key]"
                                    v-bind="field.props || {}"
                                    @change="$event ? getProduct($event) : null"
                                    return-prop="id"
                                    @update-field="onUpdateFieldValue"
                                    :name="key"
                                    hide-details
                                    v-on="getFieldEvents(field.events)"
                                ></component>
                                <div class="review-item__product" v-show="currentState.productId">
                                    <template v-if="!isProductLoading">
                                        <table-image :image="image" class="image"></table-image>
                                        <div class="text">
                                            <h4>{{ selectedProduct.title }}</h4>
                                            <Button
                                                class="delete-product-btn"
                                                type="text"
                                                icon="deleted"
                                                @click="currentState.productId = null"
                                                >{{ $t('entities.delete') }}</Button
                                            >
                                        </div>
                                    </template>
                                    <progress-circular
                                        class="modal__spinner spinner product__spinner"
                                        v-else
                                    ></progress-circular>
                                </div>
                            </template>
                        </form-item>
                    </template>
                    <Button class="ml-auto" icon="check" :disabled="!isSaveAllowed" @click="onUpdateReview">{{
                        $t('entities.save')
                    }}</Button>
                </form>
            </div>
        </template>
    </div>
</template>

<script>
import ProductService from '@/services/ProductService';
import ProductReviewService from '@/services/ProductReviewService';
import ProductReview from '@/entities/product/ProductReview';
import SearchWithImage from '@/components/common/SearchWithImage';
import TableImage from '@/components/common/TableImage';
import FormItem from '@/components/form/item';
import validateField from '@/helpers/validator';
import { debounce, cloneDeep, isEqual } from 'lodash';
import EventEmitter from '@/helpers/eventEmitter.ts';
import ErrorBlock from '@/components/common/ErrorBlock';
import { mapActions } from 'vuex';
import Product from '@/entities/product/Product';
import Button from '@/components/common/Button';
import ProgressCircular from '@/components/common/ProgressCircular';
import Alert from '@/components/common/Alert';
import Rating from '@/components/common/Rating';

export default {
    name: 'ProductReviewItem',
    components: { Alert, ProgressCircular, TableImage, SearchWithImage, FormItem, ErrorBlock, Button, Rating },

    data() {
        return {
            isValid: false,
            currentState: new ProductReview(),
            isLoading: false,
            search: '',
            selectedProduct: {},
            isProductLoading: false,
            image: {},
            fieldKeys: Object.keys(new ProductReview().fields),
            initialState: null,
            isAvailable: true,
            isSaveAllowed: false,
            isUpdated: false,
        };
    },

    computed: {
        reviewId() {
            return this.$route.params.id;
        },
    },

    async created() {
        await this.getReview();
        this.initialState = cloneDeep(this.currentState);
    },

    methods: {
        ...mapActions('reviewsCounter', {
            getCounter: 'getCounter',
        }),
        async getReview() {
            this.isLoading = true;
            const [error, result] = await ProductReviewService.getOne(this.reviewId);
            if (error) {
                error.alert();
                this.isAvailable = false;
                return;
            }
            this.currentState = new ProductReview(result);
            await this.getProduct(this.currentState.productId);
            this.isLoading = false;
        },

        async onUpdateReview() {
            const isFormValid = this.validateForm();
            if (isFormValid === false) return;
            const [error, result] = await ProductReviewService.updateOne(this.currentState.data);
            error ? this.failedHandler(result, error) : this.successHandler();
        },
        async getProduct(id) {
            this.isProductLoading = true;
            const [error, result] = await ProductService.getOne(id);
            if (error) {
                error.alert();
                this.isAvailable = false;
                return;
            }
            this.selectedProduct = new Product(result);
            const img = await Product.getDefaultImages([this.selectedProduct]);
            this.image = img[this.selectedProduct.id];
            this.isProductLoading = false;
        },

        successHandler() {
            this.isUpdated = true;
            this.initialState = cloneDeep(this.currentState);
            this.isSaveAllowed = false;
            this.getCounter({ enabled: false });
            window.scrollTo({ top: 0, behavior: 'smooth' });
        },

        validateForm() {
            let result = true;
            const fieldKeys = Object.keys(this.currentState.fields);
            fieldKeys.forEach((key) => {
                const errors = validateField(this.currentState[key], this.currentState.fields[key].props.rules);
                if (errors.length !== 0) result = false;
                this.$set(this.currentState.fields[key].props, 'errors', errors);
            });
            if (result === false)
                EventEmitter.trigger('show-noty', {
                    type: 'error',
                    text: this.$t('notifications.validation.error'),
                });
            return result;
        },

        failedHandler(response, error) {
            error.notify();
            const children = response.data?.errors?.children;
            if (children) {
                this.fieldKeys.forEach((key) => {
                    const errors = children[key] ? children[key].errors : [];
                    if (errors) this.$set(this.currentState.fields[key].props, 'errors', errors);
                });
            }
        },

        onUpdateFieldValue: debounce(function(payload) {
            const { name } = payload;
            const errors = validateField(this.currentState[name], this.currentState.fields[name].props.rules);
            this.$set(this.currentState.fields[name].props, 'errors', errors);
        }, 600),

        getFieldEvents(events) {
            const fieldEvents = {};
            for (const key in events) {
                fieldEvents[key] = events[key]?.bind(this.currentState);
            }
            return fieldEvents;
        },
    },
    watch: {
        currentState: {
            handler(val) {
                if (this.initialState) {
                    this.isSaveAllowed = !isEqual(val.data, this.initialState.data);
                }
            },
            deep: true,
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.review-item {
    &__product {
        width: 100%;
        min-height: 66px;
        display: flex;
        gap: 24px;
        & .image {
            width: 66px;
            height: 66px;
            border-radius: 4px;
        }
        & .text {
            display: flex;
            flex-direction: column;
            align-items: start;
        }
        & h4 {
            font-size: 19px;
            font-weight: 500;
        }
        & .btn {
            margin-top: -12px;
        }
        & .product__spinner {
            margin-left: 0;
        }
        .delete-product-btn {
            span:last-child {
                margin-left: -12px;
            }
        }
    }
}
</style>
