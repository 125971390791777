import { cloneDeep, merge } from 'lodash';
import { SELECT } from '@/helpers/fieldTypes.no-vuetify';
import { Container, Content, Footer, Heading, Section } from '@/entities/section/samples/_options';

const section = {
    id: '',
    type: 'SectionGaleryLogos',
    required: false,
    group: 'Gallery',
    options: {
        section: { ...Section },
        container: { ...Container },
        heading: { ...Heading },
        content: {
            htmlBefore: { ...Content.htmlBefore },
            htmlAfter: { ...Content.htmlAfter },
            colorText: { ...Content.color },
        },
        rows: {
            value: 1,
            ...SELECT,
            ...{
                props: {
                    items: [1, 2],
                },
            },
        },
        columns: {
            type: 'field',
            value: {
                // пользовательские настройки колонок
                desktop: 6,
                tablet: 4,
                mobile: 1,
            },
            component: 'CustomizeColumnsTabs',
            ...{
                props: {
                    ranges: {
                        desktop: [3, 4, 5, 6],
                        tablet: [2, 3, 4],
                        mobile: [1, 2],
                    },
                },
            },
        },
        footer: { ...Footer },
        // background: { ...Background },
    },
    items: [],
    children: {
        id: '',
        type: 'galleryItem',
        options: {
            content: {
                image: merge(Content.image, {
                    props: { note: 'JPG, PNG. Recomended size: 200х200px. No more than 20kb' },
                }),
                alt: {
                    ...Content.imageAlt,
                    value: 'Image title text',
                },
                title: {
                    ...Content.imageTitle,
                    value: 'Image title text',
                },
                note: { ...Content.note },
                link: { ...Content.link },
            },
        },
    },
};

const itemFixture = cloneDeep(section.children);
const itemsFixture = [];
itemsFixture.push(Object.assign(cloneDeep(itemFixture), { position: 0 }));
itemsFixture.push(Object.assign(cloneDeep(itemFixture), { position: 1 }));
itemsFixture.push(Object.assign(cloneDeep(itemFixture), { position: 2 }));
itemsFixture.push(Object.assign(cloneDeep(itemFixture), { position: 3 }));
itemsFixture.push(Object.assign(cloneDeep(itemFixture), { position: 4 }));
itemsFixture.push(Object.assign(cloneDeep(itemFixture), { position: 5 }));
itemsFixture.push(Object.assign(cloneDeep(itemFixture), { position: 6 }));
section.items = itemsFixture;

export default section;
