<template>
    <div class="progress-circular" :class="`-${size}`"></div>
</template>

<script>
export default {
    name: 'ProgressCircular',
    props: {
        size: { type: String, default: 'small', validator: (val) => ['small', 'medium', 'large'].includes(val) },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.progress-circular {
    animation: spin 1s linear infinite;
    &.-large {
        border: 8px solid transparent;
        border-top: 8px solid #2979ff;
        border-radius: 50%;
        width: 80px;
        height: 80px;
    }
    &.-medium {
        border: 5px solid transparent;
        border-top: 5px solid #2979ff;
        border-radius: 50%;
        width: 40px;
        height: 40px;
    }
    &.-small {
        border: 3px solid transparent;
        border-top: 3px solid #2979ff;
        border-radius: 50%;
        width: 20px;
        height: 20px;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>
