export default () => ({
    name: {
        component: 'input-text',
        props: {
            rules: ['noEmpty'],
            errors: [],
        },
    },
    zones: {
        props: {
            rules: ['selected'],
            errors: [],
        },
    },
});
