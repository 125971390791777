<template>
    <div class="page -column product__page">
        <template v-if="!isAvailable">
            <div class="page-header">
                <h1 class="page-header__text">{{ $t('entities.product.newItem') }}</h1>
            </div>
            <error-block></error-block>
        </template>
        <template v-else-if="isDataLoaded">
            <div class="product__navigation">
                <Button
                    type="large-text"
                    v-if="current.prev"
                    :to="{ name: 'Edit Product', params: { id: current.prev } }"
                    icon="chevron_left"
                    >{{ $t('entities.product.prev') }}</Button
                >
                <Button
                    v-if="current.next"
                    type="large-text"
                    :to="{ name: 'Edit Product', params: { id: current.next } }"
                    >{{ $t('entities.product.next')
                    }}<span class="material-icons-outlined btn__icon">chevron_right</span></Button
                >
            </div>
            <sticky-toolbar>
                <Button icon="check" :disabled="!isSaveAllowed" @click="onSubmit">{{ $t('entities.save') }}</Button>
                <a
                    target="_blank"
                    v-if="productId"
                    :href="`https://${instanceFull.primaryDomain}/products/${current.slug}`"
                >
                    <Button type="outlined" icon="visibility">{{ $t('entities.view') }}</Button>
                </a>
                <Button v-if="productId" type="outlined" icon="content_copy" @click="onCloneProduct">
                    {{ $t('entities.duplicate') }}
                </Button>
                <Button type="outlined" icon="delete" v-if="productId" @click="openDeletionModal">
                    {{ $t('entities.remove') }}
                </Button>
            </sticky-toolbar>
            <div class="page-header entity-item__page-header">
                <h1 class="page-header__text">
                    {{ productId ? current.title : $t('entities.product.newItem') }}
                </h1>
            </div>
            <product-alert
                @add-another="onAddAnotherOne"
                @scroll-to-variations="scrollToVariations"
                :is-created="isCreated"
                :is-duplicated="isDuplicated"
                :is-updated="isUpdated"
            ></product-alert>
            <product-item-sidebar
                @update-field="onUpdateFieldValue"
                :form="form"
                @change="onUpdateForm"
            ></product-item-sidebar>
            <div class="product__content block__content">
                <headed-block
                    class="entity-item__block"
                    v-for="block in productItemBlocks"
                    :key="block.title"
                    :class="{ seo: $t(block.title) === 'SEO' }"
                    :heading="$t(block.title)"
                >
                    <FormWrapper class="form">
                        <FormItem v-for="item in block.items" :key="item" v-bind="form.items[item].props" :name="item">
                            <label
                                slot="label"
                                v-if="form.items[item].props.tooltip"
                                :for="item"
                                class="form-item__label"
                            >
                                {{ $t(form.items[item].props.label) }}
                                <span
                                    @click="
                                        showTooltips([form.items[item].props.tooltip], {
                                            width: form.items[item].props.tooltipWidth,
                                        })
                                    "
                                    class="tooltip__btn form-item__tooltip-button material-symbols-outlined"
                                    >error_outline</span
                                >
                            </label>
                            <component
                                :key="item === 'slug' ? slugKey : null"
                                :is="form.items[item].component"
                                :name="item"
                                :showCheckbox="item === 'slug' ? !!productId : null"
                                v-model="form.items[item].value"
                                v-bind="form.items[item].props"
                                v-on="getFieldEvents(form.items[item].events)"
                                @update-field="onUpdateFieldValue"
                            />
                        </FormItem>
                    </FormWrapper>
                    <meta-preview
                        :label="$t('entities.product.fields.googlePreview')"
                        :header="metaTitle"
                        :link="`https://${instanceFull.primaryDomain}/products/${current.slug}`"
                        :description="metaDescription"
                        v-if="$t(block.title) === 'SEO'"
                    ></meta-preview>
                </headed-block>
                <default-variation
                    ref="default-variation"
                    :optionsEnabled="optionsEnabled"
                    v-model="form.items.variations.value"
                    name="variations"
                    @update-field="onUpdateFieldValue"
                    :errors="form.items.variations.props.errors"
                ></default-variation>
                <div class="block entity-item__block options__block">
                    <div class="block__body">
                        <input-checkbox
                            class="options__checkbox"
                            v-model="optionsEnabled"
                            :text="$t('entities.blocks.options')"
                            @input="resetOptions"
                        ></input-checkbox>
                    </div>
                </div>
                <headed-block v-if="optionsEnabled" class="entity-item__block" :heading="$t('entities.blocks.options')">
                    <product-options-list
                        @update-variations="updateVariations"
                        name="options"
                        @update-field="onUpdateFieldValue"
                        v-model="form.items.options.value"
                        v-bind="form.items.options.props"
                    ></product-options-list>
                </headed-block>
                <headed-block
                    class="entity-item__block custom-variations__block"
                    v-show="optionsEnabled"
                    :heading="$t('entities.blocks.variations')"
                >
                    <product-variations-list
                        ref="variations"
                        name="variations"
                        @update-field="onUpdateFieldValue"
                        v-model="form.items.variations.value"
                        v-bind="form.items.variations.props"
                    />
                </headed-block>
                <headed-block class="entity-item__block" :heading="$t('entities.blocks.properties')">
                    <product-property-list
                        name="properties"
                        @update-field="onUpdateFieldValue"
                        v-model="form.items.properties.value"
                        v-bind="form.items.properties.props"
                    ></product-property-list>
                </headed-block>
                <headed-block class="entity-item__block" :heading="$t('entities.blocks.media')">
                    <product-image-manager
                        :data="current.images"
                        :new-images="newImages"
                        v-on="getFieldEvents(form.items.images.events)"
                    ></product-image-manager>
                </headed-block>
            </div>
        </template>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Form from '@/components/form/Form';
import HeadedBlock from '@/components/common/HeadedBlock';
import FormWrapper from '@/components/form/wrapper';
import FormButtons from '@/components/form/buttons.vue';
import FormItem from '@/components/form/item';
import Button from '@/components/common/Button.vue';
import ProductImageManager from '@/components/product/ProductImageManager';
import SidebarRight from '@/components/common/SidebarRight';
import productItemBlocks from '@/views/product/productItemBlocks';
import ProductItemSidebar from '@/components/product/ProductItemSidebar';
import ProductOptionsList from '@/components/product/ProductOptionsList';
import DefaultVariation from '@/components/product/DefaultVariation';
import ProductVariationsList from '@/components/product/ProductVariationsList';
import SelectMultiple from '@/components/form/controls/SelectMultiple';
import { onUpdateVariations } from '@/entities/product/ProductVariationHelper';
import StickyToolbar from '@/components/common/StickyToolbar';
import ProductAlert from '@/components/product/ProductAlert';
import MetaPreview from '@/components/product/ProductMetaPreview';
import ErrorBlock from '@/components/common/ErrorBlock';
import EventEmitter from '@/helpers/eventEmitter.ts';
import ProductSizeInput from '@/components/product/ProductSizeInput';
import InputCheckbox from '@/components/form/controls/InputCheckbox';
import InputUrl from '@/components/form/controls/InputUrl';
import CodeEditor from '@/components/form/controls/CKEditor';
import ComboboxMultiple from '@/components/form/controls/ComboboxMultiple';
import ProductPropertyList from '@/components/product/ProductPropertyList';

export default {
    name: 'ProductItem',
    components: {
        ProductPropertyList,
        InputCheckbox,
        MetaPreview,
        ProductOptionsList,
        ProductItemSidebar,
        SidebarRight,
        HeadedBlock,
        FormWrapper,
        FormButtons,
        FormItem,
        Button,
        ProductImageManager,
        DefaultVariation,
        ProductVariationsList,
        StickyToolbar,
        SelectMultiple,
        ProductAlert,
        ErrorBlock,
        ProductSizeInput,
        InputUrl,
        CodeEditor,
        ComboboxMultiple,
    },
    filters: {},
    async created() {
        this.isDataLoaded = false;
        EventEmitter.on('delete-product', this.deleteProduct);
        const isDuplicated = localStorage.getItem('productDuplicated');
        const isCreated = localStorage.getItem('productCreated');
        if (isDuplicated) {
            this.isDuplicated = true;
            localStorage.removeItem('productDuplicated');
        }
        if (isCreated) {
            this.isCreated = true;
            localStorage.removeItem('productCreated');
        }
        await this.loadData();
        this.isDataLoaded = true;
    },
    beforeDestroy() {
        EventEmitter.off('delete-product', this.deleteProduct);
    },
    data() {
        return {
            isDataLoaded: false,
            form: null,
            productItemBlocks,
            optionsEnabled: null,
            isDuplicated: false,
            isCreated: false,
            isUpdated: false,
            //для обновления компонента при сохранении
            slugKey: 0,
        };
    },
    computed: {
        ...mapGetters('product', {
            current: 'getCurrent',
            newImages: 'getNewImages',
            isSaveAllowed: 'getIsSaveAllowed',
            isAvailable: 'getIsAvailable',
        }),
        ...mapGetters('config', { sitename: 'getSitename' }),
        ...mapGetters('globalVars', { instanceFull: 'getInstanceFull' }),
        productId() {
            return this.$route.params.id;
        },
        seo() {
            return this.$store.getters['config/getSeo'];
        },
        currency() {
            return this.$store.getters['config/getCurrency'];
        },
        metaAvailableVariables() {
            return {
                '%shop_name%': this.sitename?.value || '',
                '%product_name%': this.current.title,
                '%currency%': this.currency,
                '%price%': this.current.variations.find((item) => item.default).fullPrice || '',
            };
        },
        metaTitle() {
            return this.current.metaTitle
                ? this.current.metaTitle
                : this.formatMeta(this.seo.templateProductTitle.value);
        },
        metaDescription() {
            return this.current.metaDescription
                ? this.current.metaDescription
                : this.formatMeta(this.seo.templateProductDescription.value);
        },
    },
    methods: {
        ...mapActions('product', {
            load: 'load',
            update: 'updateProduct',
            create: 'createProduct',
            updateCurrent: 'updateCurrent',
            updateCurrentsField: 'updateCurrentsField',
            updateNewImages: 'updateNewImages',
            uploadImages: 'uploadImages',
            removeImage: 'removeImage',
            updateInitialsField: 'updateInitialsField',
            cloneProduct: 'cloneProduct',
            removeProduct: 'removeProduct',
        }),

        //FORM

        async loadData() {
            await this.load(this.productId);
            this.form = new Form({
                action: this.formAction,
                validateHandler: this.validateHandler,
                successHandler: this.successHandler,
                failedHandler: this.failedHandler,
                items: this.current.fields,
            });
            this.optionsEnabled = !!this.form.items.options.value.length;
        },

        onUpdateForm(form) {
            this.form = form;
        },

        formAction() {
            return this.productId ? this.update() : this.create();
        },

        onUpdateFieldValue: function(payload) {
            const { name, group, value } = payload;
            this.form.validateField(name, group);
            if (name === 'size') return;
            this.updateCurrentsField({ name, group, value });
        },

        successHandler() {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            if (this.productId) {
                this.isUpdated = true;
                this.isCreated = false;
                this.isDuplicated = false;
                this.slugKey++;
            } else {
                this.$router.push({
                    name: 'Edit Product',
                    params: { id: this.current.id },
                });
            }
        },

        validateHandler(isValid) {
            if (!isValid) {
                EventEmitter.trigger('show-noty', {
                    type: 'error',
                    text: this.$t('notifications.validation.error'),
                });
            }
        },
        failedHandler(result) {
            console.log(result);
        },
        onSubmit() {
            this.form.submit();
        },

        getFieldEvents(events) {
            const fieldEvents = {};
            for (const key in events) {
                fieldEvents[key] = events[key]?.bind(this);
            }
            return fieldEvents;
        },

        //PRODUCT ACTIONS

        async onAddAnotherOne() {
            this.$router.push({
                name: 'Create Product',
            });
            this.isCreated = false;
            this.isUpdated = false;
        },

        async openDeletionModal() {
            this.$store.dispatch('modals/openDeletionModal', { items: this.current, entity: 'product' });
        },

        async deleteProduct() {
            try {
                await this.removeProduct();
                this.$store.dispatch('modals/closeDeletionModal');
                this.$router.push({ name: 'Product List' });
            } catch (error) {
                console.error(error);
            }
        },

        async onCloneProduct() {
            const result = await this.cloneProduct();
            let route = this.$router.resolve({
                name: 'Edit Product',
                params: { id: result.id },
            });
            window.open(route.href);
        },

        //COMPONENT ACTIONS

        updateVariations(options) {
            const variations = onUpdateVariations(options, this.form.items.variations.value);
            const defaultVariation = variations.find((variation) => variation.default) || variations[0];
            defaultVariation.default = true;
            if (!defaultVariation.fullPrice) {
                defaultVariation.fullPrice = 0;
            }
            this.form.items.variations.value = variations;
            this.updateCurrentsField({ name: 'variations', value: variations });
        },

        resetOptions() {
            if (this.form.items.options.value.length) {
                this.form.items.options.value = [];
                this.onUpdateFieldValue({ name: 'options', value: [] });
                this.updateVariations([]);
            }
        },

        scrollToVariations() {
            const offset = this.optionsEnabled
                ? this.$refs['variations'].$el.getBoundingClientRect().top - 270
                : this.$refs['default-variation'].$el.getBoundingClientRect().top - 150;
            window.scrollTo({
                behavior: 'smooth',
                top: offset,
            });
        },

        formatMeta(string) {
            const reg = /%(.*?)%/g;
            return string.replace(reg, (match) => {
                return this.metaAvailableVariables[match];
            });
        },
        showTooltips(tooltips, config) {
            EventEmitter.trigger('toggle-tooltips', {
                isShown: true,
                tooltips,
                config,
            });
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.product {
    &__page {
        padding-right: 332px;
        position: relative;
        .entity-item__block {
            order: 1;
            &.seo {
                order: 10;
            }
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
    }
    &__navigation {
        position: absolute;
        right: 332px;
        display: flex;
        gap: 22px;
        top: -36px;
        span {
            display: flex;
            align-items: center;
            gap: 8px;
        }
        .btn__icon {
            font-size: 18px !important;
        }
    }
}
.options {
    &__block {
        .block__content {
            border-radius: 4px !important;
        }
    }
    &__checkbox {
        .v-input {
            margin-top: 0;
        }
    }
}
</style>
