import { Section, Heading, Content, Container, TextStyle } from '@/entities/section/samples/_options';
import { BUTTON } from '@/helpers/fieldTypes.no-vuetify';

const section = {
    id: '',
    type: 'SectionBannerStyle2',
    required: false,
    group: 'Banners',
    options: {
        section: {
            width: { ...Section.width },
            padding: { ...Section.padding },
        },
        container: {
            borderRadius: {
                ...Container.borderRadius,
                value: {
                    desktop: 8,
                    tablet: 8,
                    mobile: 8,
                },
            },
        },
        heading: {
            title: { ...Heading.title },
            color: {
                value: '',
                component: 'ColorPicker',
                props: {
                    labelTrans: 'builder.sectionOptions.props.color',
                    btnReset: true,
                    mode: 'hexa',
                },
            },
            textSize: { ...TextStyle.fontSizeScreen },
        },
        content: {
            text: { ...Content.html },
            color: {
                value: '',
                component: 'ColorPicker',
                props: {
                    labelTrans: 'builder.sectionOptions.props.color',
                    btnReset: true,
                    mode: 'hexa',
                },
            },
            media: {
                value: {
                    desktop: null,
                    mobile: null,
                },
                component: 'CustomizeMediaTabs',
                ...{
                    props: {
                        labelTrans: 'builder.sectionOptions.props.media',
                        screen: ['desktop', 'mobile'],
                    },
                },
            },
        },
        button: {
            value: {
                isShow: false,
                styles: 'primary',
                text: 'Button',
                textColor: null,
                bgColor: null,
                link: null,
                iconLeft: null,
                iconRight: null,
                type: 'Link',
                url: null,
            },
            ...BUTTON,
        },
    },
    items: [],
    children: null,
};

export default section;
