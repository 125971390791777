<template>
    <div class="screen-tabs">
        <div class="screen-tabs__items">
            <div
                v-for="item in items"
                :key="item.name"
                class="screen-tabs__item"
                @click="switchTab(item)"
                :class="{ 'is-active': item.isActive }"
            >
                <span class="material-symbols-outlined">{{ item.icon }}</span>
            </div>
        </div>
        <div class="screen-tabs__content">
            <div
                class="screen-tabs__content-item"
                v-for="item in items"
                :key="item.name"
                :class="{ 'is-active': item.isActive }"
            >
                <slot :name="item.name"></slot>
            </div>
        </div>
    </div>
</template>

<script>
import { proxyModelMixin } from '@/mixins/proxyModelMixin';

export default {
    name: 'ScreenTabs',
    mixins: [proxyModelMixin],
    data() {
        return {
            screens: ['desktop', 'tablet', 'mobile'],
            items: [
                { name: 'desktop', isActive: true, icon: 'desktop_windows' },
                { name: 'tablet', isActive: false, icon: 'tablet' },
                { name: 'mobile', isActive: false, icon: 'smartphone' },
            ],
        };
    },
    methods: {
        switchTab(item) {
            this.items.forEach((item) => (item.isActive = false));
            item.isActive = true;
        },
    },
};
</script>

<style scoped lang="scss">
@import '@/scss/variables.scss';

.screen-tabs {
    &__items {
        display: flex;
        margin-bottom: 8px;
    }
    &__item {
        cursor: pointer;
        flex: 1;
        height: $form-control-height;
        display: block;
        padding: 0 $form-control-padding-horizontal;
        border: $form-control-border;
        background: $form-control-bg;
        transition: $form-control-transition;
        text-align: center;
        &:first-child {
            border-radius: $form-control-border-radius 0 0 $form-control-border-radius;
            border-right: none;
        }
        &:last-child {
            border-radius: 0 $form-control-border-radius $form-control-border-radius 0;
            border-left: none;
        }
        &.is-active {
            color: $primary;
            background-color: var(--v-primary-lighten-base);
            border-color: var(--v-primary-lighten-base);
        }
        span {
            pointer-events: none;
            line-height: $form-control-height;
        }
        &:hover {
            color: $primary;
        }
    }
    &__content {
        & > * {
            display: none;
        }
        .is-active {
            display: block;
        }
    }
}
</style>
