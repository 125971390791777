export default {
    SectionMediaComments: [
        {
            sectionField: 'isShownByConfig',
            configField: 'media.pageStyle.value.showComments',
        },
    ],
    SectionMediaTableOfContents: [
        {
            sectionField: 'isShownByConfig',
            configField: 'media.pageStyle.value.showTableOfContent',
        },
    ],
    SectionMediaAuthors: [
        {
            sectionField: 'isShownByConfig',
            configField: 'media.pageStyle.value.showAuthor',
        },
    ],
};
