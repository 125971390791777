<template>
    <div class="page -width-lg">
        <div class="page-header">
            <h1 class="page-header__text">{{ $t('menus.pageHeaders.tags') }}</h1>
            <Button @click="openModal(null)" icon="add_circle_outline">
                {{ $t('entities.add') }}
            </Button>
        </div>
        <error-block v-if="!isAvailable"></error-block>
        <template v-else>
            <tags-modal @action-success="getItems" :tag-id="tagId" v-model="isTagModalOpen"></tags-modal>
            <Table
                data-cy="tags-table"
                show-select
                @delete-selected-items="openDeletionModal"
                local-search
                :sort-desc="sortDesc"
                :sort-by="sortBy"
                local-sort
                v-model="selected"
                :is-data-loaded="isLoaded"
                :columns="headers"
                :items="items"
                @update:sort="updateSort"
            >
                <!-- ACTIONS -->
                <template v-slot:item.actions="{ item }">
                    <div class="table__actions">
                        <Button @click="openModal(item.id)" icon="edit" type="icon" />
                        <Button @click="openDeletionModal(item)" icon="delete" type="icon" />
                    </div>
                </template>
                <!-- NAME -->
                <template v-slot:item.name="{ item }">
                    <span class="table-edit">
                        <a @click="openModal(item.id)" type="text" class="table-edit__link">{{ item.name }}</a>
                        <span class="table-edit__icon material-icons-outlined">edit</span>
                    </span>
                </template>
            </Table>
        </template>
    </div>
</template>

<script>
import TagService from '@/services/TagService';
import tagsModal from '@/components/blog/TagsModal';
import EventEmitter from '@/helpers/eventEmitter.ts';
import ErrorBlock from '@/components/common/ErrorBlock';
import Table from '@/components/common/Table';
import Button from '@/components/common/Button';

export default {
    name: 'TagList',
    components: {
        Button,
        ErrorBlock,
        tagsModal,
        Table,
    },

    async created() {
        EventEmitter.on('delete-tag', this.deleteItems);
        await this.getItems();
    },
    data: () => ({
        items: [],
        isTagModalOpen: false,
        tagId: null,
        selected: [],
        isLoaded: false,
        sortBy: null,
        sortDesc: null,
        isAvailable: true,
        addNewBtn: {
            title: 'entities.add',
        },
    }),
    computed: {
        headers() {
            return [
                { text: this.$t('lists.columns.name'), value: 'name', sortable: true },
                { value: 'actions', width: '114px' },
            ];
        },
    },
    methods: {
        async getItems() {
            this.isLoaded = false;
            const [error, result] = await TagService.getAll();
            if (error) {
                error.alert();
                this.isAvailable = false;
                return;
            }
            this.items = result;
            this.isLoaded = true;
        },
        openDeletionModal(items) {
            this.$store.dispatch('modals/openDeletionModal', { items, entity: 'tag' });
        },

        openModal(id) {
            this.isTagModalOpen = true;
            this.tagId = id;
        },

        async deleteItems(items) {
            this.isLoaded = false;
            const promises = items.map((item) => TagService.removeOne(item.id));
            const results = await Promise.all(promises);
            this.selected = [];
            this.$store.dispatch('modals/closeDeletionModal');
            await this.getItems();
            if (
                results.every((item) => {
                    const [error] = item;
                    return !error;
                })
            ) {
                if (items.length === 1) {
                    EventEmitter.trigger('show-noty', {
                        type: 'success',
                        text: this.$tc('notifications.deleted', 1, { entity: this.$tc('entities.tag.title', 1) }),
                    });
                } else {
                    EventEmitter.trigger('show-noty', {
                        type: 'success',
                        text: this.$tc('notifications.deleted', 2, { entity: this.$tc('entities.tag.title', 2) }),
                    });
                }
            }
            this.isLoaded = true;
        },
        updateSort(sortBy, sortDesc) {
            this.sortBy = sortBy;
            this.sortDesc = sortDesc;
        },
    },
    beforeDestroy() {
        EventEmitter.off('delete-tag', this.deleteItems);
    },
};
</script>
<style lang="scss"></style>
