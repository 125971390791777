export default {
    enabled: {
        id: null,
        type: 'boolean',
        value: true,
        position: 0,
        options: {},
    },
    url: {
        id: null,
        type: 'string',
        value: 'url',
        options: {},
        position: 0,
    },
    login: {
        id: null,
        type: 'string',
        value: 'login',
        options: {},
        position: 0,
    },
    password: {
        id: null,
        type: 'string',
        value: 'pwd',
        options: {},
        position: 0,
    },
};
