<template>
    <div id="main-sidebar" class="sidebar main-sidebar">
        <div class="main-sidebar__container">
            <template v-for="(item, i) in items">
                <template v-if="item.children">
                    <SidebarMenuGroup
                        class="icon-size"
                        :key="`group-${i}`"
                        :item="item"
                        @toggleGroup="handlerToggleGroup"
                    ></SidebarMenuGroup>
                </template>

                <SidebarMenuItem v-else :key="`item-${i}`" :item="item" @closeAllGroups="handlerCloseAllGroups" />
            </template>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import getVerticalSidebarItems from './VerticalSidebarItems';
import SidebarMenuGroup from '@/components/common/SidebarMenuGroup.vue';
import SidebarMenuItem from '@/components/common/SidebarMenuItem.vue';

export default {
    name: 'VerticalSidebar',
    components: {
        SidebarMenuGroup,
        SidebarMenuItem,
    },
    props: {},
    data() {
        return {
            items: null,
        };
    },
    async created() {
        await this.getCounter({ enabled: false });
        this.items = getVerticalSidebarItems(this.counter);
    },

    computed: {
        ...mapState('theme', ['SidebarColor', 'SidebarBg']),
        ...mapState('reviewsCounter', ['counter']),
        Sidebar_drawer: {
            get() {
                return this.$store.state.theme.Sidebar_drawer;
            },
            set(val) {
                this.$store.commit('theme/SET_SIDEBAR_DRAWER', val);
            },
        },
    },
    methods: {
        ...mapActions('reviewsCounter', {
            getCounter: 'getCounter',
        }),
        handlerCloseAllGroups() {
            this.items.forEach((item) => {
                if (item.group) item.isOpen = false;
            });
        },
        handlerToggleGroup(value) {
            this.items.forEach((item) => {
                if (item.group === value.group) item.isOpen = value.isOpen;
            });
        },
    },
};
</script>
<style lang="scss">
@import '@/scss/variables.scss';
.main-sidebar {
    &__container {
        margin-top: 12px;
    }
}
#main-sidebar {
    z-index: 6;
    left: 0;
    top: 0;
    width: 300px;
    height: calc(100% - 64px);
    background-color: var(--v-surface-base);
    position: fixed;
    margin-top: 64px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        display: none;
    }
    .sidebar-logo {
        padding: 26px 58px;
    }
}
</style>
