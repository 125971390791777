import { Section, TextStyle } from '@/entities/section/samples/_options';

const section = {
    id: '',
    type: 'SectionBreadcrumbs',
    required: true,
    group: 'Breadcrumbs',
    options: {
        section: {
            padding: {
                ...Section.padding,
                value: {
                    desktop: { top: 28, right: 0, bottom: 8, left: 0 },
                    tablet: { top: 28, right: 0, bottom: 8, left: 0 },
                    mobile: { top: 16, right: 0, bottom: 6, left: 0 },
                },
            },
            background: { ...Section.background },
        },
        content: {
            colorText: { ...TextStyle.color },
        },
    },
    items: [],
    children: null,
};

export default section;
