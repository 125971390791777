export default {
    current: {
        id: null,
        position: 0,
        type: 'select',
        component: 'Select',
        value: 'en',
        options: {
            variants: [
                {
                    text: 'settings.language.current.variants.en',
                    value: 'en',
                },
                {
                    text: 'settings.language.current.variants.ru',
                    value: 'ru',
                },
            ],
            items: [
                {
                    text: 'settings.language.current.variants.en',
                    value: 'en',
                },
                {
                    text: 'settings.language.current.variants.ru',
                    value: 'ru',
                },
            ],
            translate: true,
            labelTrans: 'settings.language.current.title',
        },
    },
};
