<template>
    <div class="builder-section-new-list">
        <div class="builder-section-new-list__back">
            <Button @click="updateNewSectionListVisible(false)" icon="chevron_left" type="text">
                Back to sections
            </Button>
        </div>
        <div class="builder-section-new-list__heading">Add a new section</div>
        <div class="builder-section-new-list__scroll">
            <template v-for="groupTitle in sectionGroups">
                <BuilderSectionNewListGroup
                    :key="'new-group-' + groupTitle"
                    :isActive="groupTitle === activeGroup"
                    :title="$t('builder.sections.groups.' + groupTitle)"
                    @click="setActiveGroup(groupTitle)"
                />
            </template>
        </div>
        <transition>
            <div v-if="activeGroup && getFixtureByGroup(activeGroup)" class="builder-section-new-list__sections">
                <div class="builder-section-new-list__scroll">
                    <template v-for="(newSection, index) in Object.keys(getFixtureByGroup(activeGroup))">
                        <BuilderSectionNewListItem
                            :key="'new-secition-' + newSection"
                            :section-fixture="fixtures[activeGroup][newSection]"
                            :index="index"
                        />
                    </template>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { mapMutations } from 'vuex';
import sectionFixtures from '@/entities/section/fixtures/index.ts';
import Button from '@/components/common/Button.vue';
import BuilderSectionNewListGroup from '@/components/builder/sections/newListGroup.vue';
import BuilderSectionNewListItem from '@/components/builder/sections/newListItem.vue';

export default {
    name: 'BuilderSectionNewList',
    components: { BuilderSectionNewListGroup, BuilderSectionNewListItem, Button },
    data() {
        return {
            fixtures: sectionFixtures.fixtures,
            sectionGroups: sectionFixtures.getGroups,
            activeGroup: '',
        };
    },
    computed: {
        newSections() {
            return this.activeGroup ? this.sampleSections[this.activeGroup] : null;
        },
    },
    methods: {
        ...mapMutations('builder', {
            updateNewSectionListVisible: 'updateNewSectionListVisible',
        }),
        getFixtureByGroup(group) {
            return sectionFixtures.getFixtureByGroup(group);
        },
        setActiveGroup(group) {
            this.activeGroup = group;
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.builder-section-new-list {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    z-index: 1;

    &__back {
        display: flex;
        align-items: center;
        height: 52px;
        padding: 0 16px;
        color: var(--v-on-surface-high-base);
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }

    &__heading {
        padding: 32px 16px 24px;
        font-size: 1.3571rem !important;
        font-weight: 500;
        line-height: 1.3571rem;
        letter-spacing: 0.0073529412em !important;
    }

    &__scroll {
        width: 100%;
        overflow: auto;
        flex: auto;
    }

    &__sections {
        position: absolute;
        top: 0;
        left: 100%;
        bottom: 0;
        display: flex;
        flex-direction: column;
        width: 340px;
        overflow: auto;
        background: var(--v-surface-base);
        border-left: 1px solid rgba(0, 0, 0, 0.12);
        padding: 24px 16px;
    }
}
</style>
