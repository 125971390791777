import { uuid } from 'vue-uuid';
import initFields from '@/entities/delivery/ShippingZoneRate.fields';
import { validateNoEmpty } from '@/helpers/validator';

/**
 * Класс экземпляра рейта
 */
export default class ShippingZoneRate {
    fields = initFields();
    /**
     *
     * @param {Object}  data - объект данных рейта
     */
    constructor(
        data = {
            id: uuid.v4(),
            shippingZone: '',
            title: '',
            price: '',
            shippingSpeed: '',
            minWeight: '',
            maxWeight: '',
            minPrice: '',
            maxPrice: '',
            createdAt: new Date(),
        }
    ) {
        this.id = data.id;
        this.title = data.title;
        this.price = data.price;
        this.shippingSpeed = data.shippingSpeed;
        this.minWeight = data.minWeight;
        this.maxWeight = data.maxWeight;
        this.shippingZone = data.shippingZone;
        this.minPrice = data.minPrice;
        this.maxPrice = data.maxPrice;
        this.createdAt = data.createdAt;
    }
    get data() {
        return {
            id: this.id,
            title: this.title,
            price: this.price,
            shippingSpeed: this.shippingSpeed,
            shippingZone: this.shippingZone,
            minWeight: this.minWeight,
            maxWeight: this.maxWeight,
            minPrice: this.minPrice,
            maxPrice: this.maxPrice,
        };
    }

    validateField(item, value) {
        const rules = item.props?.rules;
        if (!rules || Object.keys(rules).length === 0) return [];
        const errors = [];

        for (const [rule, options] of Object.entries(item.props.rules)) {
            switch (rule) {
                case 'noEmpty': {
                    if (!validateNoEmpty(value)) errors.push('validator.errors.empty');
                    break;
                }
                case 'biggerThan': {
                    if (!this.validateBiggerThan(value, this[options.target]).isValid)
                        errors.push(`validator.errors.biggerThan`);
                    break;
                }
                case 'related': {
                    options.forEach((item) => {
                        this.validateRelated(this.fields[item], this[item]);
                    });
                    break;
                }
            }
        }
        return errors;
    }

    validateRelated(item, value) {
        item.props.errors = this.validateField(item, value);
    }

    validateBiggerThan(val, targetVal) {
        if (!val) return { isValid: true };
        let isValid = Number(val) > Number(targetVal);
        return {
            isValid,
        };
    }
}
