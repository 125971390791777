var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page"},[_c('div',{staticClass:"page-header"},[(_vm.categoryId !== 'all' && !_vm.isLoaded)?_c('progress-circular',{staticClass:"spinner"}):_c('h1',{staticClass:"page-header__text"},[_vm._v(" "+_vm._s(_vm.categoriesMap[_vm.categoryId] && _vm.categoryId !== 'all' ? _vm.$t('menus.pageHeaders.articlesInCategory', { category: _vm.categoriesMap[_vm.categoryId].title }) : _vm.$t('menus.pageHeaders.articles'))+" ")]),_c('Button',{attrs:{"to":{ name: "Create Article" },"icon":"add_circle_outline"}},[_vm._v(" "+_vm._s(_vm.$t('entities.add'))+" ")])],1),(!_vm.isAvailable)?_c('error-block'):_c('Table',{staticClass:"articles-table",attrs:{"search":_vm.search,"columns":_vm.headers,"items":_vm.items,"is-data-loaded":_vm.isLoaded,"show-select":"","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"pagination":!!_vm.categoryId && _vm.pagination},on:{"change-per-page":_vm.changePerPage,"update:sort":_vm.updateSort,"update-search-string":_vm.updateSearchString,"delete-selected-items":_vm.openDeletionModal},scopedSlots:_vm._u([{key:"header-select-prepend",fn:function(){return [_c('Select',{staticClass:"articles__type-select",attrs:{"item-value":"id","item-text":"title","items":_vm.categories},model:{value:(_vm.categoryId),callback:function ($$v) {_vm.categoryId=$$v},expression:"categoryId"}})]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"table__actions"},[_c('Button',{staticClass:"builder-btn",attrs:{"to":{
                        name: 'Builder Edit Article',
                        params: { categoryId: item.categoryId, id: item.id, type: 'media' },
                    },"disabled":!_vm.categoriesMap[item.categoryId],"type":"text"}},[_vm._v(_vm._s(_vm.$t('entities.editInBuilder')))]),_c('a',{attrs:{"target":"_blank","href":_vm.categoriesMap[item.categoryId] ? ("https://" + (_vm.instanceFull.primaryDomain) + "/" + (item.slug)) : null}},[_c('Button',{attrs:{"disabled":!_vm.categoriesMap[item.categoryId],"type":"icon","icon":"open_in_new"}})],1),_c('Button',{attrs:{"to":{ name: 'Edit Article', params: { id: item.id } },"icon":"edit","type":"icon"}}),_c('Button',{attrs:{"icon":"delete","type":"icon"},on:{"click":function($event){return _vm.openDeletionModal(item)}}})],1)]}},{key:"item.image",fn:function(ref){
                    var item = ref.item;
return [(_vm.hasImage(item))?_c('img',{staticClass:"table__image",attrs:{"alt":_vm.images[item.id].alt,"src":_vm.imageUrl + _vm.images[item.id].uri}}):_c('img',{staticClass:"table__image -default",attrs:{"alt":"No image","src":require("@/assets/images/no-photo.svg")}})]}},{key:"item.author",fn:function(ref){
                    var item = ref.item;
return [(!_vm.isLoaded)?_c('progress-circular'):_c('div',{staticClass:"articles__author"},[(_vm.authors[item.authorId])?_c('span',[_vm._v(_vm._s(_vm.authors[item.authorId].name))]):_vm._e(),(item.publisherId && _vm.authors[item.publisherId])?_c('span',[_vm._v(" / "+_vm._s(_vm.authors[item.publisherId].name)+" ")]):_vm._e()])]}},{key:"item.publishedAt",fn:function(ref){
                    var item = ref.item;
return [(item.publishedAt)?_c('span',[_vm._v(_vm._s(_vm._f("localDate")(item.publishedAt)))]):_c('span',[_vm._v("-")])]}},{key:"item.updatedAt",fn:function(ref){
                    var item = ref.item;
return [(item.updatedAt)?_c('span',[_vm._v(_vm._s(_vm._f("localDate")(item.updatedAt)))]):_c('span',[_vm._v("-")])]}},{key:"item.enabled",fn:function(ref){
                    var item = ref.item;
return [_c('input-switch',{attrs:{"value":item.enabled,"name":"enabled","size":"small"},on:{"input":function($event){return _vm.onUpdateFieldValue({ name: 'enabled', value: $event }, item)}}})]}},{key:"item.hidden",fn:function(ref){
                    var item = ref.item;
return [_c('input-switch',{attrs:{"value":item.hidden,"name":"hidden","size":"small"},on:{"input":function($event){return _vm.onUpdateFieldValue({ name: 'hidden', value: $event }, item)}}})]}},{key:"item.commentIds",fn:function(ref){
                    var item = ref.item;
return [_c('Button',{attrs:{"type":"text","to":{ name: 'Comment List', query: { articleId: item.id } }}},[_vm._v(" "+_vm._s(_vm.$t('lists.actions.viewAll'))+" ("+_vm._s(item.commentIds.length)+")")])]}},{key:"item.title",fn:function(ref){
                    var item = ref.item;
return [_c('span',{staticClass:"table-edit"},[_c('router-link',{staticClass:"table-edit__link",attrs:{"to":{ name: 'Edit Article', params: { id: item.id, categoryId: item.categoryId } }}},[_vm._v(_vm._s(item.title))]),_c('span',{staticClass:"table-edit__icon material-icons-outlined"},[_vm._v("edit")])],1)]}},{key:"item.tagIds",fn:function(ref){
                    var item = ref.item;
return [(!_vm.isLoaded)?_c('progress-circular'):(item.tagIds.length)?_c('div',{staticClass:"articles__tags"},[_vm._l((item.tagIds),function(id){return [(_vm.tags[id])?_c('chip',{key:id,attrs:{"type":"combobox"}},[_vm._v(_vm._s(_vm.tags[id].name))]):_vm._e()]})],2):_c('span',[_vm._v("-")])]}},{key:"item.categoryId",fn:function(ref){
                    var item = ref.item;
return [(!_vm.isLoaded)?_c('progress-circular'):_c('span',[_vm._v(" "+_vm._s(_vm.categoriesMap[item.categoryId] ? _vm.categoriesMap[item.categoryId].title : 'Удалено')+" ")])]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }