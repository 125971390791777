<template>
    <tooltip class="product-size-tooltip">
        <span class="tooltip__icon tooltip__btn material-symbols-outlined">error_outline</span>
        <div v-html="$t('entities.product.tooltips.size')"></div>
    </tooltip>
</template>

<script>
import Tooltip from '@/components/common/Tooltip';
export default {
    name: 'ProductSizeTooltip',
    components: {
        Tooltip,
    },
};
</script>

<style lang="scss">
.product-size-tooltip {
    .tooltip__list {
        font-weight: 700;
        font-size: 15px;
        margin-top: 14px;

        &-item {
            margin-bottom: 10px;
        }
    }
}
</style>
