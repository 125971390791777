<template>
    <modal header="Add comment" v-model="model">
        <form class="modal__content form" ref="form">
            <progress-circular class="modal__spinner spinner" v-if="isLoading"></progress-circular>
            <template v-else>
                <div class="form__input">
                    <Textarea v-model="commentModel" type="textarea" height="250"></Textarea>
                </div>
            </template>
        </form>
        <div class="form__input">
            <Button
                :disabled="initialValue === commentModel"
                class="modal__save-btn"
                icon="check"
                @click="onUpdateComment"
                >Save</Button
            >
        </div>
    </modal>
</template>

<script>
import Modal from '@/components/common/Modal';
import { proxyModelMixin } from '@/mixins/proxyModelMixin';
import { cloneDeep } from 'lodash';
import Textarea from '@/components/form/controls/Textarea';
import Button from '@/components/common/Button';
import ProgressCircular from '@/components/common/ProgressCircular';

export default {
    name: 'CommentModal',
    mixins: [proxyModelMixin],
    components: { Modal, Textarea, Button, ProgressCircular },

    props: {
        value: {
            type: Boolean,
            default: false,
        },
        comment: {
            type: String,
            default: null,
        },
    },

    data() {
        return {
            isLoading: false,
            commentModel: '',
            initialValue: null,
        };
    },
    methods: {
        async onUpdateComment() {
            this.$emit('update-comment', this.commentModel);
        },
    },
    watch: {
        comment(val) {
            this.commentModel = cloneDeep(val);
            this.initialValue = val;
        },
    },
};
</script>

<style lang="scss"></style>
