<template>
    <div class="customize-collumns" ref="customize" @mouseup="onMouseUp" @mousemove="onMouseMove">
        <template v-for="(column, index) in columns">
            <div
                :key="'column-' + index"
                class="customize-collumns__item"
                :data-id="column.id"
                :style="`--width: ${column.width}px`"
                :class="`${column.isActive ? `is-active -col-${column.number}` : ''}`"
            >
                {{ column.number }}
            </div>
            <div
                v-if="index !== columns.length - 1"
                :key="'separate-' + index"
                class="customize-collumns__btn"
                @mousedown="onMouseDown"
            >
                <span class="material-symbols-outlined">drag_indicator</span>
            </div>
        </template>
    </div>
</template>

<script>
import { proxyModelMixin } from '@/mixins/proxyModelMixin';
export default {
    name: 'CustomizeColumns',
    mixins: [proxyModelMixin],
    props: {
        name: { type: String, default: '' },
        group: { type: String, default: null },
        type: { type: String, default: 'array' },
        errors: { type: [Array, Object], default: () => [] },
        value: { type: [Array, String], default: () => [6, 6] },
    },
    model: {
        prop: 'value',
        event: 'change-value',
    },
    data() {
        return {
            isResizing: false,
            columns: null,
            oneColumnWidth: null,
            prev: null,
            next: null,
            separate: {
                width: 24,
                count: null,
            },
            cursor: {
                X: null,
            },
            container: {
                width: null,
                startX: null,
                endX: null,
            },
        };
    },
    mounted() {
        this.separate.count = this.value.length - 1;
        this.updContainer();
    },
    methods: {
        getColumns() {
            this.columns = this.value.map((column, index) => {
                return {
                    id: index,
                    number: column,
                    width: column * this.oneColumnWidth,
                    isActive: false,
                };
            });
        },
        getContainer() {
            this.container.width = this.$refs.customize.getBoundingClientRect().width;
            this.container.startX = this.$refs.customize.getBoundingClientRect().x;
            this.container.endX = this.container.startX + this.container.width;
        },
        updContainer() {
            this.getContainer();
            this.oneColumnWidth = (this.container.width - this.separate.count * this.separate.width) / 12;
            this.getColumns();
            this.columns.forEach((column) => {
                this.$watch(() => column.number, this.updValue, { deep: true });
            });
        },
        onMouseUp() {
            if (this.isResizing) this.isResizing = false;
        },
        onMouseMove(e) {
            e.preventDefault();
            if (!this.isResizing) return;
            this.updCursorPositionX(e.clientX);
            if (!this.checkColumnWidth()) return (this.isResizing = false);
            this.updColumnNumber();
            // если вышли за пределы блока по Х;
            if (e.clientX > this.container.endX || e.clientX <= this.container.startX) this.isResizing = false;
        },
        onMouseDown({ target, clientX }) {
            this.isResizing = true;
            this.prev = this.getColumnById(target.previousElementSibling.dataset.id);
            this.next = this.getColumnById(target.nextElementSibling.dataset.id);
            this.prev.isActive = true;
            this.next.isActive = true;
            this.cursor.X = clientX;
        },
        getColumnsWidth() {
            this.prev.width = Math.floor(this.prev.number * this.oneColumnWidth);
            this.next.width = Math.floor(this.next.number * this.oneColumnWidth);
        },
        getColumnById(id) {
            return this.columns.find((column) => column.id == id);
        },
        checkColumnWidth() {
            const min = this.oneColumnWidth;
            const max = this.container.width - this.oneColumnWidth;
            if (this.prev.width <= min || this.prev.width >= max) return false;
            if (this.next.width <= min || this.next.width >= max) return false;
            return true;
        },
        updCursorPositionX(coordX) {
            const delta = this.cursor.X - coordX;
            this.prev.width = this.prev.width - delta;
            this.next.width = this.next.width + delta;
            this.cursor.X = coordX;
        },
        updColumnNumber() {
            this.prev.number = Math.round(this.prev.width / this.oneColumnWidth);
            this.next.number = Math.round(this.next.width / this.oneColumnWidth);
        },
        updValue() {
            this.$emit(
                'change-value',
                this.columns.map((column) => column.number)
            );
            // this.getColumnsWidth(); // изменение ширины - кратна размеру колонки
        },
        updColumns(value) {
            if (value) return;
            this.prev.isActive = false;
            this.next.isActive = false;
            this.getColumnsWidth();
        },
    },
    watch: {
        isResizing: 'updColumns',
        '$store.state.builder.currentSidebar.sidebarRight': 'updContainer',
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.customize-collumns {
    display: flex;
    $collumn-width: 50%;
    $collumn-padding: 5px;
    &__item {
        width: var(--width, $collumn-width);
        display: block;
        height: $form-control-height;
        padding: 0 $collumn-padding;
        border: $form-control-border;
        border-radius: $form-control-border-radius;
        background: $form-control-bg;
        transition: $form-control-transition;
        line-height: $form-control-height;
        text-align: center;
        @for $i from 1 through 12 {
            $col-space: (100 / $i * 0.2%);
            $col-size: (100 / $i * 1%);
            &.-col-#{$i} {
                background: linear-gradient(
                    90deg,
                    transparent 20%,
                    var(--v-background-base) 20%,
                    var(--v-background-base) 80%,
                    transparent 20%
                );
                background-size: $col-size;
                background-color: #fff;
            }
        }

        &::placeholder {
            color: inherit;
            opacity: 0.7;
        }
        &.is-active {
            color: $primary;
            border-color: $primary;
        }
    }
    &__btn {
        width: 24px;
        cursor: ew-resize;
        &.is-active {
            color: $primary;
        }
        span {
            pointer-events: none;
            line-height: $form-control-height;
        }
    }
}
</style>
