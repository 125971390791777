<template>
    <div class="custom-number" :class="classes">
        <button class="custom-number__btn -minus" aria-label="Minus" @click="minus(step, $event)">
            <span class="material-symbols-outlined">remove</span>
        </button>
        <input v-on:input="onUpdateFieldValue" v-model="model" type="number" aria-label="Count" />
        <button class="custom-number__btn -plus" aria-label="Plus" @click="plus(step, $event)">
            <span class="material-symbols-outlined">add</span>
        </button>
    </div>
</template>

<script>
import { proxyModelMixin } from '@/mixins/proxyModelMixin';
import { debounce } from 'lodash';

export default {
    name: 'InputNumber',
    inheritAttrs: false,
    mixins: [proxyModelMixin],
    props: {
        name: { type: String, default: '' },
        group: { type: String, default: null },
        type: { type: String, default: 'text' },
        placeholder: { type: String, default: '' },
        errors: { type: [Array, Object], default: () => [] },
        value: { type: [Number, String], default: 0 },
        min: { type: [Number], default: 0 },
        max: { type: [Number], default: 60 },
        step: { type: [Number], default: 1 },
        disabled: { type: Boolean, default: false },
        size: {
            type: String,
            default: 'medium',
            validator: function(value) {
                return ['small', 'medium'].includes(value);
            },
        },
        orientation: {
            type: String,
            default: 'horizontal',
            validator: function(value) {
                return ['horizontal', 'vertical'].includes(value);
            },
        },
    },
    computed: {
        hasError: function() {
            return Array.isArray(this.errors) && this.errors.length > 0;
        },
        classes() {
            return {
                '-has-errors': Array.isArray(this.errors) && this.errors.length > 0,
                '-disabled': this.disabled,
                '-small': this.size === 'small',
                '-medium': this.size === 'medium',
                '-horizontal': this.orientation === 'horizontal',
                '-vertical': this.orientation === 'vertical',
            };
        },
    },
    methods: {
        onUpdateFieldValue: debounce(function(field) {
            let newValue = field.target.value;
            if (newValue > this.max) newValue = this.max;
            if (newValue < this.min) newValue = this.min;
            this.model = parseInt(newValue);
        }, 1000),
        minus(step, event) {
            if (event) event.preventDefault();
            if (this.model < this.min || this.model - step < this.min) return;
            this.model = parseInt(this.model) - step;
        },
        plus(step, event) {
            if (event) event.preventDefault();
            if (this.model >= this.max || this.model + step > this.max) return;
            this.model = parseInt(this.model) + step;
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';

.custom-number {
    $icon-width: 6rem;
    $icon-height: 6rem;
    $collumn-padding: 12px;

    display: flex;
    align-items: center;
    height: $form-control-height;
    padding: 0 $collumn-padding;
    border: $form-control-border;
    border-radius: $form-control-border-radius;
    background: $form-control-bg;
    transition: $form-control-transition;
    line-height: $form-control-height;
    width: 100%;
    max-width: 100%;
    &:hover {
        border-color: var(--v-on-surface-medium-base);
    }

    &.-has-errors {
        border-color: $error;
    }
    &.-disabled {
        background-color: rgba(0, 0, 0, 0.04);
        color: var(--v-on-surface-disabled-base);
        &:hover {
            border-color: $form-control-border-color !important;
        }
    }
    &.-small {
        height: 30px;
        line-height: 30px;
        padding: 0 8px;
        .material-symbols-outlined {
            font-size: 18px;
        }
    }
    &.-vertical {
        transform: rotate(-90deg);
        input,
        .material-symbols-outlined {
            transform: rotate(90deg);
        }
    }

    input {
        flex: 1;
        position: relative;
        padding: 0;
        margin: 0;
        text-align: center;
        border: none;
        border-radius: 0;
        background: transparent;
        width: 100%;

        &:focus {
            outline: none;
            box-shadow: none;
            border-color: transparent;
        }
    }

    button {
        display: block;
        z-index: 5;
        line-height: inherit;
        height: inherit;
        cursor: pointer;
        background-color: transparent;
        border-style: none;
        padding: 0;
        & > span {
            line-height: inherit;
        }

        &:hover {
            color: $primary;
        }

        &:active {
            color: var(--v-primary-lighten-base);
        }

        &.-plus {
            margin-left: 2px;
        }
        &.-minus {
            margin-right: 2px;
        }
    }
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
    margin: 0;
    -webkit-appearance: none;
}
input[type='number'] {
    -moz-appearance: textfield !important;
}
</style>
