var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page -width-lg"},[_c('options-modal',{attrs:{"option-id":_vm.optionId},on:{"action-success":_vm.getItems,"update:sort":_vm.updateSort},model:{value:(_vm.isOptionModalOpen),callback:function ($$v) {_vm.isOptionModalOpen=$$v},expression:"isOptionModalOpen"}}),_c('div',{staticClass:"page-header"},[_c('h1',{staticClass:"page-header__text"},[_vm._v(_vm._s(_vm.$t('menus.pageHeaders.options')))]),(_vm.isAvailable)?_c('Button',{attrs:{"icon":"add_circle_outline"},on:{"click":function($event){return _vm.openModal(null)}}},[_vm._v(" "+_vm._s(_vm.$t('entities.add'))+" ")]):_vm._e()],1),(!_vm.isAvailable)?[_c('error-block')]:_c('Table',{attrs:{"data-cy":"products-options-table","show-select":"","columns":_vm.headers,"items":_vm.items,"local-sort":"","sort-desc":_vm.sortDesc,"sort-by":_vm.sortBy,"is-data-loaded":_vm.isLoaded,"search":_vm.search},on:{"delete-selected-items":_vm.openDeletionModal,"update:sort":_vm.updateSort,"update-search-string":_vm.updateSearchString},scopedSlots:_vm._u([{key:"block-header",fn:function(){return [_c('Alert',{staticClass:"options__description-alert"},[_vm._v(_vm._s(_vm.$t('entities.option.alerts.description')))])]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"table__actions"},[_c('Button',{attrs:{"icon":"edit","type":"icon"},on:{"click":function($event){return _vm.openModal(item.id)}}}),_c('Button',{attrs:{"icon":"delete","type":"icon"},on:{"click":function($event){return _vm.openDeletionModal(item)}}})],1)]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"table-edit"},[_c('span',{staticClass:"table-edit__link",attrs:{"type":"text"},on:{"click":function($event){return _vm.openModal(item.id)}}},[_vm._v(_vm._s(item.title))]),_c('span',{staticClass:"table-edit__icon material-icons-outlined"},[_vm._v("edit")])])]}},{key:"item.view",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.view)+" ")]}},{key:"item.useInFilter",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.useInFilter === 'useInFilter' ? _vm.$t('entities.property.useInFilter.use') : _vm.$t('entities.property.useInFilter.doNotUse'))+" ")]}},{key:"item.position",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.position)+" ")]}},{key:"item.unit",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.unit || '—')+" ")]}},{key:"item.values",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"options__values"},_vm._l((item.values),function(value){return _c('chip',{key:value.id,attrs:{"type":"combobox"}},[_vm._v(_vm._s(value.title))])}),1)]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }