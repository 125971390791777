import ApiClient from '@/api/apiClient';
import CustomError from '@/entities/customError';
import { Property, PropertiesRequestParams } from '@/services/services.types';

class PropertyService {
    /**
     * Получение харакатеристик
     * @param {PropertiesRequestParams} params - параметры запроса (sort, direction, ids, etc.)
     * @returns {Promise} - массив объектов харакатеристик
     */

    async getAll(params: PropertiesRequestParams = {}): Promise<[CustomError | null, Property[] | []]> {
        const url: string = `/properties`;
        const defaultValue = [];
        const errorPath: string = '[api:property:getAll]';
        return await ApiClient.admin.get({ url, defaultValue, errorPath, config: { params } });
    }

    /**
     * Получение конкретной характеристики
     * @param {String} id - id характеристики
     * @returns {Promise} -  объект характеристики
     */

    async getOne(id: string): Promise<[CustomError | null, Property | null]> {
        const url: string = `/properties/${id}`;
        const defaultValue = null;
        const errorPath: string = '[api:property:getOne]';
        return await ApiClient.admin.get({ url, defaultValue, errorPath });
    }

    /**
     * Создание характеристики
     * @param {Object} property - объект характеристики
     * @returns {Promise} - созданный объект характеристики
     */

    async createOne(property: Property): Promise<[CustomError | null, Property | null]> {
        const url: string = `/properties`;
        const defaultValue = null;
        const errorPath: string = '[api:property:createOne]';
        return await ApiClient.admin.post({ url, defaultValue, errorPath, data: property });
    }

    /**
     * Обновление характеристики
     * @param {Object} property - объект характеристики
     * @returns {Promise} - обновлённый объект характеристики
     */

    async updateOne(property: Property): Promise<[CustomError | null, Property | null]> {
        const url: string = `/properties/${property.id}`;
        const defaultValue = null;
        const errorPath: string = '[api:property:updateOne]';
        return await ApiClient.admin.put({ url, defaultValue, errorPath, data: property });
    }

    /**
     * Обновление харакатеристик
     * @param {Array} properties - массив харакатеристик
     * @returns {Promise} - массив обновлённых харакатеристик
     */

    async updateBatch(properties: Property[]): Promise<[CustomError | null, Property[] | []]> {
        const url: string = `/properties/batch`;
        const defaultValue = [];
        const errorPath: string = '[api:property:updateBatch]';
        const config = {
            params: {
                ids: properties.map((property) => property.id),
            },
        };
        return await ApiClient.admin.put({ url, defaultValue, errorPath, data: properties, config });
    }

    /**
     * Создание нескольких харакатеристик
     * @param {Array} properties - массив харакатеристик
     * @returns {Promise} - массив созданных харакатеристик
     */

    async createBatch(properties: Property[]): Promise<[CustomError | null, Property[] | []]> {
        const url: string = `/properties/batch`;
        const defaultValue = [];
        const errorPath: string = '[api:property:createBatch]';
        return await ApiClient.admin.post({ url, defaultValue, errorPath, data: properties });
    }

    /**
     * Удаление характеристики
     * @param {String} id - id характеристики
     * @returns {Promise} - null
     */

    async removeOne(id: string): Promise<[CustomError | null, null]> {
        const url: string = `/properties/${id}`;
        const defaultValue = null;
        const errorPath: string = '[api:property:removeOne]';
        return await ApiClient.admin.delete({ url, defaultValue, errorPath });
    }
}

export default new PropertyService();
