import { cloneDeep } from 'lodash';
import { Container, Content, Footer, Heading, Section } from '@/entities/section/samples/_options';
import { SELECT } from '@/helpers/fieldTypes.no-vuetify';

const section = {
    id: '',
    type: 'SectionTestimonialStyle1',
    required: false,
    group: 'Testimonial',
    options: {
        section: { ...Section },
        container: { ...Container },
        heading: { ...Heading },
        content: {
            htmlBefore: { ...Content.htmlBefore },
            htmlAfter: { ...Content.htmlAfter },
            colorText: { ...Content.color },
            listWidth: {
                ...Content.listWidth,
                value: 'small',
            },
            useCarousel: { ...Content.useCarousel },
        },
        columns: {
            desktop: {
                value: 3,
                ...SELECT,
                ...{
                    props: {
                        labelTrans: 'builder.sectionOptions.props.desktop',
                        items: [2, 3, 4, 5, 6],
                    },
                },
            },
            tablet: {
                value: 2,
                ...SELECT,
                ...{
                    props: {
                        labelTrans: 'builder.sectionOptions.props.tablet',
                        items: [2, 3, 4],
                    },
                },
            },
            mobile: {
                value: 1,
                ...SELECT,
                ...{
                    props: {
                        labelTrans: 'builder.sectionOptions.props.mobile',
                        items: [1, 2],
                    },
                },
            },
        },
        footer: { ...Footer },
        // background: { ...Background },
    },
    items: [],
    children: {
        id: '',
        type: 'testimonial',
        options: {
            content: {
                rating: { ...Content.rating },
                text: { ...Content.text },
                author: {
                    ...Content.textSmall,
                    props: {
                        labelTrans: 'builder.sectionOptions.props.author',
                    },
                },
                avatar: {
                    ...Content.image,
                    props: {
                        labelTrans: 'builder.sectionOptions.props.avatar',
                        note: 'JPG, PNG. Recomended size: 80х80.',
                    },
                },
            },
        },
    },
};

const itemFixture = cloneDeep(section.children);
const itemsFixture = [];
itemsFixture.push(Object.assign(cloneDeep(itemFixture), { position: 0 }));
itemsFixture.push(Object.assign(cloneDeep(itemFixture), { position: 1 }));
itemsFixture.push(Object.assign(cloneDeep(itemFixture), { position: 2 }));
section.items = itemsFixture;

export default section;
