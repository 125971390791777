import { uuid } from 'vue-uuid';
import { cloneDeep } from 'lodash';
import sampleSections from './samples';
import SectionItem from './SectionItem';
import convertOptionsToValue from '@/helpers/convertOptionsToValue';
import fieldsToMapFromConfig from '@/entities/section/FieldsToMapFromConfig';
import store from '@/store/store';

/** Class representing a section. */
export default class Section {
    /**
     * Create Section
     * @param {Object} data - section data
     * @param {string} data.id - id uuid.v4
     * @param {string} data.type - section type (can check all type in sampleSections)
     * @param {string} data.position - The employee's department.
     * @param {string} data.options - options, like a title or background
     * @param {string} data.items - items
     * @param {boolean} data.required - is section required
     * @param {boolean} data.fixed - ???
     */
    constructor(sectionData) {
        const data = cloneDeep(sectionData);
        const sample = sampleSections.getArray().find((section) => section.type === data.type);
        if (!sample) throw new Error(`[Entities:Section] Invalid section type '${data.type}'`);

        this.isNew = !data.id;
        this.isFixture = data.id === 'fixture';
        this.id = data.id ? data.id : uuid.v4();
        this.id = this.isFixture ? uuid.v4() : this.id;
        this.type = data.type;
        this.group = sample.group;
        this.position = data.position;
        this.required = sample.required;
        this.children = sample.children;
        this.fixed = data.fixed; // неактуально
        this.options = this.assignOptions(cloneDeep(sample.options), data.options);
        this.items = this.assignItems(sample.items, data.items);
        this.hidden = data.hidden ? data.hidden : false;
        this.hasErrors = this.checkError();
        if (fieldsToMapFromConfig[this.type]) {
            this.mapFieldsFromConfig(fieldsToMapFromConfig[this.type]);
        }
    }

    addChildren() {
        const newItem = new SectionItem({}, this.children);
        newItem.position = this.items.length;
        if (this.options?.itemsStyle?.template?.value) newItem.type = this.options.itemsStyle.template.value;
        this.items.push(newItem);
    }

    /**
     * @param {Object} sampleOption - опции из дефолтной секции данного типа
     * @param {Object} newOption - опции пришедшие при инициализации секции
     * @returns {Object} - объединенные опции
     */
    assignOptions(sampleOption, newOption) {
        const options = sampleOption;
        const newData = cloneDeep(newOption);
        convertOptionsToValue(newData);
        Object.keys(options).forEach((group) => {
            // для одноуровневых свойств
            if (options[group].value !== undefined && newData[group] !== undefined) {
                options[group].value = newData[group];
            }
            // для двухуровеневых свойств
            if (typeof options[group] === 'object' && options[group] !== null) {
                const props = Object.keys(options[group]);
                props.forEach((prop) => {
                    this.convertOldPropsToNew(options[group], newData[group]);
                    if (
                        options[group][prop] &&
                        options[group][prop].value !== undefined &&
                        newData[group] !== undefined &&
                        newData[group][prop] !== undefined
                    ) {
                        options[group][prop].value = newData[group][prop];
                    }
                });
            }
        });
        return options;
    }

    convertOldPropsToNew(sampleGroup, group) {
        if (!group) return;
        const props = [
            { name: 'media', oldName: 'image' },
            { name: 'media', oldName: 'imageMobile' },
        ];
        props.forEach(({ oldName, name }) => {
            if (!group[name] && group[oldName] && sampleGroup[name]) {
                if (oldName === 'image') sampleGroup[name].value.desktop = { image: group[oldName] };
                if (oldName === 'imageMobile') sampleGroup[name].value.mobile = { image: group[oldName] };
            }
        });
    }
    /**
     * @param {*} childrenShema - схема данных итема
     * @param {*} sampleItems - итемы из дефолтной секции данного типа
     * @param {*} newItems - итемы пришедшие при инициализации секции
     * @returns {SectionItems[]}
     */
    assignItems(sampleItems, newItems) {
        // если итемов не должно быть
        if (this.children === null) return [];
        // создание новой секции - берем чилдов из sampleItems
        if (this.isNew)
            return sampleItems.map((item) => {
                item.id = uuid.v4();
                return new SectionItem(item, this.children);
            });
        // создание существующей секции - берем чилдов из newItems
        const items = [];
        newItems.forEach((item) => {
            if (item.id === 'fixture') item.id = uuid.v4();
            items.push(new SectionItem(item, this.children));
        });
        return items;
    }

    get data() {
        const options = cloneDeep(this.options);
        const convertOptions = convertOptionsToValue(options);
        const items = this.items.map((item) => item.data);
        return {
            id: this.id,
            type: this.type,
            options: convertOptionsToValue(convertOptions),
            items: items,
            position: this.position,
            required: this.required,
            fixed: this.fixed,
            hidden: this.hidden,
        };
    }

    checkError() {
        let result = false;
        Object.keys(this.options).forEach((group) => {
            if (this.options[group].component) {
                if (
                    this.options[group].props?.errors &&
                    Array.isArray(this.options[group].props.errors) &&
                    this.options[group].props.errors.length > 0
                )
                    result = true;
            } else {
                Object.keys(this.options[group]).forEach((optionKey) => {
                    const option = this.options[group][optionKey];
                    if (option.props?.errors && Array.isArray(option.props.errors) && option.props.errors.length > 0) {
                        const errorItems = option.props?.errors.filter((error) => Array.isArray(error));
                        if ((errorItems.length && errorItems.some((item) => item.length > 0)) || !errorItems.length)
                            result = true;
                    }
                });
            }
        });
        return result;
    }

    hasChildWithId(id) {
        let result = this.items.find((item) => item.id === id);
        return result ? true : false;
    }

    mapFieldsFromConfig(fieldsToMap) {
        fieldsToMap.forEach((item) => {
            let value = store.getters['config/getAllSettings'];
            const pathToConfigValue = item.configField.split('.');
            for (let i = 0; i < pathToConfigValue.length; ++i) {
                const fieldName = pathToConfigValue[i];
                if (!Object.hasOwn(value, fieldName)) {
                    console.error(
                        `Cannot map field ${fieldName} from config to section ${this.type}. Full path: ${item.configField}`
                    );
                    return;
                }
                value = value[fieldName];
            }
            this[item.sectionField] = value;
        });
    }
}
