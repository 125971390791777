<template>
    <modal
        :header="optionId ? $t('lists.editButton.option') : $t('lists.addButton.option')"
        width="632"
        v-model="model"
    >
        <form class="modal__content options__modal form" ref="form">
            <progress-circular class="modal__spinner spinner" v-if="isLoading"></progress-circular>
            <template v-else>
                <form-item
                    class="modal__input"
                    v-for="(field, key) in currentState.fields"
                    :key="key"
                    v-bind="field.props"
                    :name="key"
                >
                    <component
                        v-if="key !== 'values'"
                        :is="field.component"
                        v-model="currentState[key]"
                        v-bind="field.props || {}"
                        @update-field="onUpdateFieldValue"
                        :name="key"
                        hide-details
                        v-on="getFieldEvents(field.events)"
                        outlined
                        dense
                    ></component>
                    <component
                        v-else
                        :is="field.component"
                        class="modal__combobox"
                        :value="currentState.values"
                        item-text="title"
                        item-value="id"
                        v-bind="field.props || {}"
                        :items="currentState.values"
                        :itemsType="currentState.type"
                        @updateTypeErrors="handleValuesType"
                        v-on="getFieldEvents(field.events)"
                    />
                </form-item>
            </template>
        </form>
        <div class="form__input">
            <Button
                class="modal__save-btn"
                :disabled="!isSaveAllowed"
                icon="check"
                @click="optionId ? onUpdateOption() : onCreateOption()"
                >{{ $t('entities.save') }}</Button
            >
        </div>
    </modal>
</template>

<script>
import Modal from '@/components/common/Modal';
import { proxyModelMixin } from '@/mixins/proxyModelMixin';
import Option from '@/entities/option/Option';
import OptionService from '@/services/OptionService';
import ComboboxMultiple from '@/components/form/controls/ComboboxMultiple';
import FormItem from '@/components/form/item';
import validateField from '@/helpers/validator';
import { debounce, cloneDeep, isEqual } from 'lodash';
import EventEmitter from '@/helpers/eventEmitter.ts';
import Button from '@/components/common/Button';
import ProgressCircular from '@/components/common/ProgressCircular';

export default {
    name: 'OptionsModal',
    mixins: [proxyModelMixin],
    components: { FormItem, Modal, ProgressCircular, ComboboxMultiple, Button },

    props: {
        value: {
            type: Boolean,
            default: false,
        },
        optionId: {
            type: String,
            default: null,
        },
    },

    data() {
        return {
            isValid: false,
            currentState: new Option(),
            fieldKeys: Object.keys(new Option().fields),
            initialState: null,
            isLoading: false,
            typeError: null,
        };
    },
    methods: {
        async getOption() {
            this.isLoading = true;
            const [error, result] = await OptionService.getOne(this.optionId);
            if (error) {
                error.notify();
            }
            this.currentState = new Option(result);
            this.initialState = cloneDeep(this.currentState);
            this.isLoading = false;
        },
        initEmptyForm() {
            this.isLoading = true;
            setTimeout(() => (this.isLoading = false), 300);
            this.currentState = new Option();
            this.initialState = cloneDeep(this.currentState);
        },

        async onCreateOption() {
            const isFormValid = this.validateForm();
            if (isFormValid === false) return;

            const [error, result] = await OptionService.createOne(this.currentState.data);
            if (error) this.failedHandler(result, error);
            else {
                this.$emit('action-success');
                this.model = false;
                EventEmitter.trigger('show-noty', {
                    type: 'success',
                    text: this.$tc('notifications.created', 1, { entity: this.$tc('entities.option.title', 1) }),
                });
                this.currentState = new Option();
            }
        },
        async onUpdateOption() {
            const isFormValid = this.validateForm();
            if (isFormValid === false) return;
            const [error, result] = await OptionService.updateOne(this.currentState.data);
            if (error) this.failedHandler(result, error);
            else {
                this.$emit('action-success');
                this.model = false;
                EventEmitter.trigger('show-noty', {
                    type: 'success',
                    text: this.$tc('notifications.updated', 1, { entity: this.$tc('entities.option.title', 1) }),
                });
                this.currentState = new Option();
            }
        },

        validateForm() {
            let result = true;
            const fieldKeys = Object.keys(this.currentState.fields);
            fieldKeys.forEach((key) => {
                if (key !== 'values') {
                    const errors = validateField(this.currentState[key], this.currentState.fields[key].props.rules);
                    if (errors.length !== 0) result = false;
                    this.$set(this.currentState.fields[key].props, 'errors', errors);
                }
            });
            if (result === false)
                EventEmitter.trigger('show-noty', {
                    type: 'error',
                    text: this.$t('notifications.validation.error'),
                });
            return result;
        },

        failedHandler(response, error) {
            error.notify();
            const children = response.data?.errors?.children;
            if (children) {
                this.fieldKeys.forEach((key) => {
                    if (key !== 'values') {
                        const errors = children[key] ? children[key].errors : [];
                        if (errors) this.$set(this.currentState.fields[key].props, 'errors', errors);
                    }
                });
            }
        },

        handleValuesType(items) {
            if (items.length) {
                this.currentState.fields.values.props.errors = ['validator.errors.optionValuesTypeNumber'];
            } else this.currentState.fields.values.props.errors = [];
        },

        onUpdateFieldValue: debounce(function(payload) {
            const { name } = payload;
            const errors = validateField(this.currentState[name], this.currentState.fields[name].props.rules);
            this.$set(this.currentState.fields[name].props, 'errors', errors);
        }, 1000),

        getFieldEvents(events) {
            const fieldEvents = {};
            for (const key in events) {
                fieldEvents[key] = events[key]?.bind(this.currentState);
            }
            return fieldEvents;
        },
    },
    computed: {
        isSaveAllowed() {
            if (this.initialState) {
                return !isEqual(this.initialState.data, this.currentState.data);
            }
            return false;
        },
    },
    watch: {
        value(val) {
            if (!val) return;
            if (this.optionId) this.getOption();
            else this.initEmptyForm();
        },
    },
};
</script>

<style lang="scss"></style>
