<template>
    <div class="file-input">
        <heading type="body-2" v-if="label">{{ label }}</heading>
        <div class="file-input__body">
            <span class="file-input__button material-icons-outlined" @click="triggerInput">attach_file</span>
            <div class="file-input__file-block" @click="triggerInput">
                <span class="file-title" v-if="model">{{ title }}</span
                ><span class="placeholder" v-else>{{ $t(placeholder) }}</span>
                <span class="delete-icon material-icons-outlined" type="icon" v-if="model" @click.stop="model = null"
                    >close</span
                >
            </div>
        </div>
        <input
            ref="input"
            class="file-input__input"
            type="file"
            @change="handleFile"
            :placeholder="$t('menus.fileInputPlaceholder')"
            v-on="$listeners"
        />
    </div>
</template>

<script>
import { proxyModelMixin } from '@/mixins/proxyModelMixin';
import Heading from '@/components/common/Heading';

export default {
    name: 'FileInput',
    mixins: [proxyModelMixin],
    components: { Heading },
    props: {
        label: {
            type: String,
            default: 'File',
        },
        placeholder: {
            type: String,
            default: 'menus.fileInputPlaceholder',
        },
        value: {
            default: null,
        },
    },
    data: function() {
        return {
            title: '',
        };
    },
    methods: {
        handleFile(event) {
            const file = event.target.files[0];
            if (file) this.title = file.name;
            this.model = file;
        },
        triggerInput() {
            this.$refs.input.click();
        },
    },
    watch: {
        value: function(newValue) {
            this.$emit('update-field', {
                name: this.name,
                group: this.group,
                value: newValue,
            });
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.file-input {
    min-width: 30%;
    cursor: pointer;
    &:active {
        .file-input__button {
            color: var(--v-primary-accent-base);
        }
        .file-input__file-block {
            border-color: var(--v-primary-accent-base);
        }
    }
    .heading {
        margin-left: 35px;
        margin-bottom: 8px;
    }
    &__body {
        display: flex;
        align-items: center;
        gap: 4px;
    }
    &__button {
        font-size: 30px !important;
    }
    &__file-block {
        height: 40px;
        width: 100%;
        border: 1px solid gray;
        border-radius: 4px;
        padding: 0 12px;
        display: flex;
        align-items: center;
        font-size: 16px;
        .placeholder {
            color: rgba(0, 0, 0, 0.6);
        }
        .delete-icon {
            margin-left: auto;
            font-size: 16px;
            color: var(--v-primary-accent-base);
            cursor: pointer;
        }
    }
    &__input {
        display: none;
    }
}
</style>
