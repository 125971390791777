<template>
    <headed-block
        class="product-form__block catalog-related-products-list"
        :heading="$t('entities.blocks.catalogRelatedProducts')"
    >
        <catalog-products-modal
            id="catalog-related-products-modal"
            @remove-item="removeItem"
            @add-item="addItem"
            :products="items"
            v-model="isProductsModalOpen"
        ></catalog-products-modal>
        <template v-slot:header-append>
            <Button icon="add_circle_outline" @click="openProductsModal">{{ $t('entities.add') }}</Button>
        </template>
        <div class="catalog-related-products-list">
            <error-block v-if="!isAvailable"></error-block>
            <Table
                v-model="selected"
                :columns="headers"
                :items="items"
                :isDataLoaded="isDataLoaded"
                :show-selected-actions="false"
                show-select
                :show-select-all="false"
            >
                <template v-slot:toolbar>
                    <div class="catalog-related-products-list__items-info">
                        <span>{{ $t('entities.catalog.total') }}: {{ model.length }}</span>
                        <span class="catalog-related-products-list__selected-info"
                            >{{ $t('entities.catalog.selected') }}: {{ selected.length }}</span
                        >
                        <Button
                            v-if="selected.length"
                            type="text"
                            class="table__selected-action catalog-related-products-list__delete-selected"
                            icon="delete"
                            @click="removeSelected"
                        >
                            {{ $t('lists.actions.deleteSelected') }}</Button
                        >
                    </div>
                </template>
                <!--  ACTIONS  -->
                <template v-slot:item.actions="{ item }">
                    <div class="table__actions">
                        <Button
                            :title="$t('lists.deleteButton.product')"
                            @click="removeItem(item)"
                            type="icon"
                            icon="delete"
                        />
                    </div>
                </template>
                <!--  IMAGE  -->
                <template v-slot:item.image="{ item }">
                    <img
                        v-if="hasImage(item)"
                        class="table__image"
                        :alt="images[item.id].alt"
                        :src="`${imageUrl}/${images[item.id].uri}`"
                    />
                    <img v-else class="table__image -default" alt="No image" src="@/assets/images/no-photo.svg" />
                </template>
                <!--  PRICE  -->
                <template v-slot:item.price="{ item }">
                    {{ item.getListPrice(currency) }}
                </template>
                <!-- ENABLED -->
                <template v-slot:item.enabled="{ item }">
                    <status
                        :icon="item.enabled ? 'check' : 'close'"
                        :text="
                            item.enabled
                                ? $t('entities.product.fields.enabled')
                                : $t('entities.product.fields.disabled')
                        "
                        :type="item.enabled ? 'success' : 'warning'"
                    ></status>
                </template>
                <!--  STOCK  -->
                <template v-slot:item.stock="{ item }">
                    <chip v-if="item.variations.every((item) => item.stock === 0)" type="error">
                        {{ $t('entities.product.stock.outOfStock') }}
                    </chip>
                    <chip v-else-if="item.variations.some((item) => item.stock === 0)" type="warning">
                        {{ $t('entities.product.stock.partially') }}
                    </chip>
                    <chip v-else type="success">
                        {{ $t('entities.product.stock.inStock') }}
                    </chip>
                </template>
                <!--  TITLE  -->
                <template v-slot:item.title="{ item }">
                    <span class="table-edit">
                        <router-link
                            target="_blank"
                            class="table-edit__link"
                            :to="{ name: 'Edit Product', params: { id: item.id } }"
                            >{{ item.title }}</router-link
                        >
                        <span class="table-edit__icon material-icons-outlined">edit</span>
                    </span>
                </template>
            </Table>
            <div class="catalog-related-products-list__footer" v-if="hasMore">
                <Button
                    v-if="isMoreLoaded"
                    @click="showMoreProducts"
                    class="catalog-related-products-list__show-more"
                    type="text"
                    >{{ $t('entities.catalog.showMore') }}</Button
                >
                <loader-circular v-else size="medium" class="spinner"></loader-circular>
            </div>
        </div>
    </headed-block>
</template>

<script>
import ErrorBlock from '@/components/common/ErrorBlock';
import { imageUrl } from '@/helpers/values';
import HeadedBlock from '@/components/common/HeadedBlock';
import CatalogProductsModal from '@/components/catalog/CatalogProductsModal';
import Table from '@/components/common/Table';
import Button from '@/components/common/Button';
import Status from '@/components/common/Status';
import ProductService from '@/services/ProductService';
import Product from '@/entities/product/Product';
import LoaderCircular from '@/components/common/ProgressCircular';
import Chip from '@/components/common/Chip';
import { mapOrder } from '@/helpers/utils';
//import { cloneDeep } from 'lodash';

export default {
    name: 'CatalogRelatedProductsList',
    components: {
        Chip,
        LoaderCircular,
        Status,
        CatalogProductsModal,
        HeadedBlock,
        ErrorBlock,
        Table,
        Button,
    },
    props: {
        ids: {
            type: Array,
            default: () => [],
        },
    },
    async created() {
        this.model = [...this.ids];
        await this.getItems();
    },
    data: () => ({
        isDataLoaded: false,
        isMoreLoaded: true,
        model: [],
        items: [],
        images: {},
        isAvailable: true,
        isProductsModalOpen: false,
        limit: 10,
        offset: 0,
        selected: [],
        imageUrl: imageUrl(),
    }),
    computed: {
        currency() {
            return this.$store.getters['config/getCurrency'];
        },
        headers() {
            const headers = [
                { value: 'image', text: this.$t('lists.columns.product') },
                { value: 'title' },
                { text: this.$t('lists.columns.status'), value: 'enabled' },
                { text: this.$t('lists.columns.price'), value: 'price' },
                { text: this.$t('lists.columns.stock'), value: 'stock', width: '140px' },
                { value: 'actions', width: '20px' },
            ];
            return headers;
        },

        hasMore() {
            return this.model.length > this.offset + this.limit;
        },
    },
    methods: {
        async getItems() {
            this.isDataLoaded = false;
            const params = {
                limit: this.limit,
                offset: this.offset,
                ids: this.model.slice(this.offset, this.limit),
            };
            const [items, images] = await this.getProducts(params);
            const products = items.map((item) => new Product(item));
            this.items = mapOrder(products, this.model, 'id');
            this.images = images;
            this.isDataLoaded = true;
        },
        async getProducts(params) {
            if (!this.model.length) return [[], {}];

            const [error, result] = await ProductService.getAll(params);
            if (error) {
                error.alert();
                this.isAvailable = false;
                return [];
            }
            const { products } = result;
            const images = Product.getDefaultImages(products);
            return [products, images];
        },

        addItem(item) {
            if (this.limit > this.model.length) {
                this.items.push(new Product(item));
                const newImages = Product.getDefaultImages([item]);
                this.images = { ...this.images, ...newImages };
            }
            this.model.push(item.id);
            this.$emit('input', this.model);
        },

        handleSelected({ item, value }) {
            if (value) {
                this.selected.push(item);
            } else {
                const index = this.selected.findIndex((product) => product.id === item.id);
                this.selected.splice(index, 1);
            }
        },

        async showMoreProducts() {
            this.isMoreLoaded = false;
            this.limit = 100;
            await this.getItems();
            this.isMoreLoaded = true;
        },

        openProductsModal() {
            this.isProductsModalOpen = true;
        },
        getDefaultVariation(item) {
            return item.variations.find((item) => item.default);
        },
        hasImage(product) {
            return this.images[product.id]?.uri;
        },
        async removeItem(item) {
            this.items = this.items.filter((product) => product.id !== item.id);

            this.model = this.model.filter((id) => {
                return id !== item.id;
            });

            this.$emit('input', this.model);
            if (this.items.length < this.model.length && this.limit <= this.model.length) {
                this.isDataLoaded = false;
                const nextId = this.model[this.items.length];
                const params = { ids: [nextId] };
                const [products, images] = await this.getProducts(params);
                this.items.push(...products);
                this.images = { ...this.images, ...images };
                this.isDataLoaded = true;
            }
        },

        async removeSelected() {
            const selectedIds = this.selected.map((item) => item.id);
            this.items = this.items.filter((product) => !selectedIds.includes(product.id));
            this.model = this.model.filter((id) => !selectedIds.includes(id));
            this.$emit('input', this.model);
            if (this.items.length < this.model.length) {
                await this.getItems();
            }
        },
    },
};
</script>
<style lang="scss">
@import '@/scss/variables.scss';
.catalog-related-products-list {
    width: 100%;
    &__footer {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 23px;
        min-height: 36px;
    }

    &__items-info {
        margin-bottom: 23px;
        display: flex;
        align-items: center;
    }

    &__selected-info {
        color: var(--v-on-surface-medium-base) !important;
        margin-left: 12px;
    }
    &__selected-action {
        margin-left: 12px;
    }

    .table {
        padding: 0 !important;
    }
    .table__position-input {
        input {
            text-align: center !important;
        }
    }
    &__delete-selected {
        margin-left: 12px;
    }
}
</style>
