<template>
    <modal
        width="632"
        v-if="model"
        v-model="model"
        :header="userId ? $t('lists.editButton.user') : $t('lists.addButton.user')"
    >
        <form ref="form" class="modal__content form">
            <progress-circular class="modal__spinner spinner" v-if="isLoading"></progress-circular>
            <template v-else>
                <form-item class="form__input">
                    <input-switch
                        :disabled="currentState.role === 'ROLE_SUPER_ADMIN'"
                        v-model="currentState.enabled"
                        :text="$t('entities.user.fields.enabled')"
                    ></input-switch>
                </form-item>
                <form-item v-bind="currentState.fields.email.props" class="form__input -half-width">
                    <input-text
                        @update-field="onUpdateFieldValue"
                        v-model="currentState.email"
                        name="email"
                        v-bind="currentState.fields.email.props"
                    ></input-text>
                </form-item>
                <form-item v-bind="currentState.fields.role.props" class="form__input -half-width">
                    <Select
                        @update-field="onUpdateFieldValue"
                        v-model="currentState.role"
                        name="role"
                        v-bind="currentState.fields.role.props"
                        :items="userRoles"
                    ></Select>
                </form-item>
                <form-item v-bind="currentState.fields.password.props" class="form__input -half-width">
                    <input-text
                        @update-field="onUpdateFieldValue"
                        v-model="currentState.password"
                        v-bind="currentState.fields.password.props"
                        name="password"
                    ></input-text>
                </form-item>
                <form-item v-bind="currentState.fields.repeatPassword.props" class="form__input -half-width">
                    <input-text
                        @update-field="onUpdateFieldValue"
                        v-model="currentState.repeatPassword"
                        v-bind="currentState.fields.repeatPassword.props"
                        name="repeatPassword"
                    ></input-text>
                </form-item>
            </template>
        </form>
        <Button
            class="modal__save-btn"
            :disabled="!isSaveAllowed"
            icon="check"
            @click="userId ? onUpdateUser() : onCreateUser()"
            >{{ $t('entities.save') }}</Button
        >
    </modal>
</template>

<script>
import Modal from '@/components/common/Modal';
import { proxyModelMixin } from '@/mixins/proxyModelMixin';
import { USER_ROLES } from '@/services/AuthService';
import UserService from '@/services/UserService';
import User from '@/entities/user/User';
import FormItem from '@/components/form/item';
import { debounce, cloneDeep, isEqual } from 'lodash';
import EventEmitter from '@/helpers/eventEmitter.ts';
import Button from '@/components/common/Button';
import InputSwitch from '@/components/form/controls/InputSwitch';
import Select from '@/components/form/controls/Select';
import InputText from '@/components/form/controls/InputText';
import ProgressCircular from '@/components/common/ProgressCircular';

export default {
    name: 'UserModal',
    components: { InputText, ProgressCircular, Select, InputSwitch, FormItem, Modal, Button },
    mixins: [proxyModelMixin],
    userRoles: USER_ROLES,

    props: {
        value: {
            type: Boolean,
        },
        userId: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            currentState: new User(),
            isLoading: false,
            fieldKeys: Object.keys(new User().fields),
            initialState: {},
        };
    },
    computed: {
        userRoles() {
            const roles = [];
            for (const role in USER_ROLES) {
                const text = role
                    .slice(5)
                    .split('_')
                    .join('-')
                    .toLowerCase();
                roles.push({
                    text: this.$options.filters.capitalize(text),
                    value: role,
                });
            }
            return roles;
        },
        isSaveAllowed() {
            if (this.initialState) {
                return !isEqual(this.initialState.data, this.currentState.data);
            }
            return false;
        },
    },
    methods: {
        onUpdateFieldValue: debounce(function(payload) {
            const { name } = payload;
            const errors = this.currentState.validateField(this.currentState.fields[name], this.currentState[name]);
            this.currentState.fields[name].props.errors = errors;
        }, 600),

        async getUser() {
            this.isLoading = true;
            const [error, result] = await UserService.getOne(this.userId);
            if (error) {
                error.notify();
                this.isLoading = false;
                return;
            }
            this.currentState = new User(result);
            this.isLoading = false;
        },
        validateForm() {
            let result = true;
            this.fieldKeys.forEach((key) => {
                const errors = this.currentState.validateField(this.currentState.fields[key], this.currentState[key]);
                if (errors.length !== 0) result = false;
                this.$set(this.currentState.fields[key].props, 'errors', errors);
            });
            if (result === false)
                EventEmitter.trigger('show-noty', {
                    type: 'error',
                    text: this.$t('notifications.validation.error'),
                });
            return result;
        },

        failedHandler(response, error) {
            error.notify();
            const children = response.data?.errors?.children;
            if (children) {
                this.fieldKeys.forEach((key) => {
                    const errors = children[key] ? children[key].errors : [];
                    if (errors) this.currentState.fields[key].props.errors = errors;
                });
            }
        },

        async onCreateUser() {
            const isFormValid = this.validateForm();
            if (isFormValid === false) return;
            const [error, result] = await UserService.createOne(this.currentState.data);
            error ? this.failedHandler(result, error) : this.successHandler();
        },
        async onUpdateUser() {
            const isFormValid = this.validateForm();
            if (isFormValid === false) return;
            const [error, result] = await UserService.updateOne(this.currentState.data);
            error ? this.failedHandler(result, error) : this.successHandler();
        },

        successHandler() {
            this.$emit('action-success');
            this.model = false;
        },
    },
    watch: {
        userId: {
            async handler(val) {
                if (val) await this.getUser();
                else this.currentState = new User();
                this.initialState = cloneDeep(this.currentState);
            },
            immediate: true,
        },
    },
};
</script>

<style scoped></style>
