import { uuid } from 'vue-uuid';

/**
 * Класс экземпляра опции элемента в заказе
 */
export default class OrderItemOption {
    /**
     *
     * @param {Object}  data - объект данных элемента заказа
     */
    constructor({ id = uuid.v4(), title = '', value = '', unit = '' } = {}) {
        this.id = id;
        this.title = title;
        this.value = value;
        this.unit = unit;
    }

    get data() {
        return {
            id: this.id,
            title: this.title,
            value: this.value,
            unit: this.unit,
        };
    }
}
