import { Container, Section } from '@/entities/section/samples/_options';

const section = {
    id: '',
    items: [],
    type: 'SectionMediaAuthors',
    required: true,
    group: 'Media',
    options: {
        section: {
            padding: { ...Section.padding },
            background: { ...Section.background },
        },
        container: { ...Container },
    },
    children: null,
};

export default section;
