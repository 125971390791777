import { SectionFixtureType, SectionGroupFixtureType } from '@/entities/section/fixtures';
// id: 'fixture',
const style1Prev1: SectionFixtureType = {
    preview: require('@/assets/images/sections/contacts/contacts-style1.jpg'),
    title: 'Contacts',
    group: 'Contacts',
    data: {
        id: 'fixture',
        items: [],
        required: false,
        fixed: false,
        type: 'SectionContactsStyle1',
        options: {
            section: {
                width: 'full',
                padding: {
                    desktop: { top: 28, right: 0, bottom: 28, left: 0 },
                    tablet: { top: 28, right: 0, bottom: 28, left: 0 },
                    mobile: { top: 16, right: 0, bottom: 16, left: 0 },
                },
                borderRadius: {
                    desktop: 0,
                    tablet: 0,
                    mobile: 0,
                },
                background: 'middle',
            },
            container: {
                width: 'content',
                padding: {
                    desktop: null,
                    tablet: null,
                    mobile: null,
                },
                borderRadius: {
                    desktop: 0,
                    tablet: 0,
                    mobile: 0,
                },
                background: 'none',
            },
            heading: {
                title: 'Contact Us',
                colorTitle: null,
                note: null,
                colorNote: null,
                textAlign: 'center',
                marginBottom: {
                    desktop: null,
                    tablet: null,
                    mobile: null,
                },
            },
            content: {
                htmlBefore: null,
                colorText: null,
                personalSettings: false,
                address: {
                    country: null,
                    region: null,
                    locality: null,
                    street: null,
                    postalCode: null,
                },
                emails: [],
                phones: [],
                whatsapps: [],
                workTime: null,
                mapUrl:
                    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d462260.58289236523!2d55.20963516679683!3d25.155603155877923!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43496ad9c645%3A0xbde66e5084295162!2sDubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2sru!4v1673507398213!5m2!1sen!2sru',
            },
        },
    },
};

const style2Prev1: SectionFixtureType = {
    preview: require('@/assets/images/sections/contacts/contacts-style2.jpg'),
    title: 'Contacts',
    group: 'Contacts',
    data: {
        id: 'fixture',
        items: [],
        required: false,
        fixed: false,
        type: 'SectionContactsStyle2',
        options: {
            section: {
                width: 'full',
                padding: {
                    desktop: { top: 28, right: 0, bottom: 28, left: 0 },
                    tablet: { top: 28, right: 0, bottom: 28, left: 0 },
                    mobile: { top: 16, right: 0, bottom: 16, left: 0 },
                },
                borderRadius: {
                    desktop: 0,
                    tablet: 0,
                    mobile: 0,
                },
                background: {
                    desktop: {
                        color: '#2f5bb1',
                        image: null,
                    },
                    tablet: {
                        color: null,
                        image: null,
                    },
                    mobile: {
                        color: null,
                        image: null,
                    },
                },
            },
            container: {
                width: 'content',
                padding: {
                    desktop: null,
                    tablet: null,
                    mobile: null,
                },
                borderRadius: {
                    desktop: 0,
                    tablet: 0,
                    mobile: 0,
                },
                background: 'none',
            },
            heading: {
                title: 'Contact Us',
                colorTitle: '#ffffff',
                note: null,
                colorNote: null,
                textAlign: 'center',
                marginBottom: {
                    desktop: null,
                    tablet: null,
                    mobile: null,
                },
            },
            content: {
                htmlBefore: null,
                htmlAfter: null,
                colorText: null,
                personalSettings: false,
                address: {
                    country: null,
                    region: null,
                    locality: null,
                    street: null,
                    postalCode: null,
                },
                emails: [],
                phones: [],
                whatsapps: [],
                workTime: null,
                mapUrl:
                    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d462260.58289236523!2d55.20963516679683!3d25.155603155877923!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43496ad9c645%3A0xbde66e5084295162!2sDubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2sru!4v1673507398213!5m2!1sen!2sru',
            },
            footer: {
                note: null,
                colorNote: null,
                textAlign: 'center',
                marginTop: {
                    desktop: null,
                    tablet: null,
                    mobile: null,
                },
                button: {
                    isShow: true,
                    styles: 'secondary',
                    text: 'Request a call',
                    textColor: null,
                    bgColor: null,
                    link: null,
                    iconLeft: null,
                    iconRight: 'phone',
                },
            },
        },
    },
};

const style3Prev1: SectionFixtureType = {
    preview: require('@/assets/images/sections/contacts/contacts-style3.jpg'),
    title: 'Contacts',
    group: 'Contacts',
    data: {
        id: 'fixture',
        items: [],
        required: false,
        fixed: false,
        type: 'SectionContactsStyle3',
        options: {
            section: {
                width: 'full',
                padding: {
                    desktop: { top: 28, right: 0, bottom: 28, left: 0 },
                    tablet: { top: 28, right: 0, bottom: 28, left: 0 },
                    mobile: { top: 16, right: 0, bottom: 16, left: 0 },
                },
                borderRadius: {
                    desktop: 0,
                    tablet: 0,
                    mobile: 0,
                },
                background: 'none',
            },
            container: {
                width: 'content',
                padding: {
                    desktop: null,
                    tablet: null,
                    mobile: null,
                },
                borderRadius: {
                    desktop: 0,
                    tablet: 0,
                    mobile: 0,
                },
                background: 'none',
            },
            heading: {
                title: 'Contact Us',
                colorTitle: null,
                note: null,
                colorNote: null,
                textAlign: 'center',
                marginBottom: {
                    desktop: null,
                    tablet: null,
                    mobile: null,
                },
            },
            content: {
                htmlBefore: null,
                htmlAfter: null,
                colorText: null,
                width: 'content',
                personalSettings: false,
                mapUrl:
                    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d462260.58289236523!2d55.20963516679683!3d25.155603155877923!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43496ad9c645%3A0xbde66e5084295162!2sDubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2sru!4v1673507398213!5m2!1sen!2sru',
            },
            footer: {
                note: null,
                colorNote: null,
                textAlign: 'center',
                marginTop: {
                    desktop: null,
                    tablet: null,
                    mobile: null,
                },
                button: {
                    isShow: true,
                    styles: 'primary',
                    text: 'Request a call',
                    textColor: null,
                    bgColor: null,
                    link: null,
                    iconLeft: null,
                    iconRight: 'phone',
                },
            },
        },
    },
};

const style4Prev1: SectionFixtureType = {
    preview: require('@/assets/images/sections/contacts/contacts-style4.jpg'),
    title: 'Contacts',
    group: 'Contacts',
    data: {
        id: 'fixture',
        items: [],
        required: false,
        fixed: false,
        type: 'SectionContactsWithFormStyle1',
        options: {
            section: {
                width: 'full',
                padding: {
                    desktop: { top: 28, right: 0, bottom: 28, left: 0 },
                    tablet: { top: 28, right: 0, bottom: 28, left: 0 },
                    mobile: { top: 16, right: 0, bottom: 16, left: 0 },
                },
                borderRadius: {
                    desktop: 0,
                    tablet: 0,
                    mobile: 0,
                },
                background: 'none',
            },
            container: {
                width: 'content',
                padding: {
                    desktop: null,
                    tablet: null,
                    mobile: null,
                },
                borderRadius: {
                    desktop: 0,
                    tablet: 0,
                    mobile: 0,
                },
                background: 'none',
            },
            heading: {
                title: 'Contact Us',
                colorTitle: null,
                note: null,
                colorNote: null,
                textAlign: 'center',
                marginBottom: {
                    desktop: null,
                    tablet: null,
                    mobile: null,
                },
            },
            content: {
                htmlBefore: null,
                htmlAfter: null,
                colorText: null,
                personalSettings: false,
                address: {
                    country: null,
                    region: null,
                    locality: null,
                    street: null,
                    postalCode: null,
                },
                emails: [],
                phones: [],
                whatsapps: [],
                workTime: null,
            },
            footer: {
                note: null,
                colorNote: null,
                textAlign: 'center',
                marginTop: {
                    desktop: null,
                    tablet: null,
                    mobile: null,
                },
                button: {
                    isShow: true,
                    styles: 'primary',
                    text: 'Request a call',
                    textColor: null,
                    bgColor: null,
                    link: null,
                    iconLeft: null,
                    iconRight: 'phone',
                },
            },
        },
    },
};

const summary: SectionGroupFixtureType = {
    style1Prev1: style1Prev1,
    style2Prev1: style2Prev1,
    style3Prev1: style3Prev1,
    style4Prev1: style4Prev1,
};

export default summary;
