<template>
    <modal v-model="model" :header="$t('entities.payment.countries.editAvailable')">
        <progress-circular class="modal__spinner spinner" v-if="isLoading"></progress-circular>
        <template v-else>
            <form class="modal__content form">
                <template>
                    <form-item class="form__input">
                        <search-form
                            class="form__input modal__search"
                            only-model
                            v-model="search"
                            @clear-search="onClearSearch"
                        ></search-form>
                        <ZonesTree
                            v-model="selection"
                            @input="updateZones"
                            :search="search"
                            :items="countriesList"
                        ></ZonesTree>
                    </form-item>
                </template>
            </form>
            <Button class="modal__save-btn" @click="model = false" icon="check">
                {{ $t('entities.accept') }}
            </Button>
        </template>
    </modal>
</template>

<script>
import { isEqual } from 'lodash';
import { proxyModelMixin } from '@/mixins/proxyModelMixin';
import Modal from '@/components/common/Modal';
import SearchForm from '@/components/search/SearchForm';
import ShippingZone from '@/entities/delivery/ShippingZone';
import FormItem from '@/components/form/item';
import ProgressCircular from '@/components/common/ProgressCircular';
import ZonesTree from '@/components/delivery/ZonesTree';
import Button from '@/components/common/Button';
export default {
    name: 'PaymentCountriesModal',
    components: { ZonesTree, ProgressCircular, FormItem, SearchForm, Modal, Button },
    mixins: [proxyModelMixin],
    props: {
        value: {
            type: Boolean,
        },
        zones: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            countriesList: [],
            selection: null,
            search: '',
            isLoading: false,
            currentState: {},
            initialState: null,
            saved: false,
        };
    },
    methods: {
        getFlag(code) {
            return require(`@/assets/images/flags/${code.toLowerCase()}.svg`);
        },
        onClearSearch() {
            this.search = '';
        },
        updateZones(val) {
            this.$emit('update-zones', val);
        },
    },
    computed: {
        isSaveAllowed() {
            if (this.initialState) {
                return !isEqual(this.initialState.data, this.currentState.data);
            }
            return false;
        },
    },
    watch: {
        zones: {
            handler(val) {
                this.isLoading = true;
                this.selection = [...val];
                setTimeout(() => (this.isLoading = false), 300);
            },
            immediate: true,
        },
        value(val) {
            if (val) {
                // modal open
                this.countriesList = ShippingZone.formatCountriesForTree();
            } else {
                this.search = '';
                this.selection = [];
            }
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.tree {
    width: 100%;
    height: 300px;
    overflow-y: scroll;
    overflow-x: hidden;
    &__item {
        display: flex;
        gap: 4px;
    }

    .material-icons-outlined::after {
        display: none !important;
    }
    &__img {
        margin-top: -4px;
        width: 23px;
    }
}
</style>
