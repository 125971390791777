var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"link-switcher"},[(_vm.label)?_c('heading',{attrs:{"type":"body-2"}},[_vm._v(_vm._s(_vm.label))]):_vm._e(),_c('ui-nested-menu',{attrs:{"items":_vm.currentItems,"title":_vm.currentItem.title,"max-height":"306"},on:{"select-item":_vm.selectItem,"go-back":_vm.goBack},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('Search-form',_vm._g(_vm._b({staticClass:"menu__search",on:{"update-search-results":_vm.updateSearchResults,"update-search-string":_vm.updateSearchString},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},'Search-form',attrs,false),on))]}},{key:"item.title.prepend",fn:function(ref){
var item = ref.item;
return [(item.icon)?_c('span',{staticClass:"material-icons-outlined"},[_vm._v(_vm._s(item.icon))]):(_vm.currentItem.icon)?_c('span',{staticClass:"material-icons-outlined"},[_vm._v(_vm._s(_vm.currentItem.icon))]):_vm._e()]}},{key:"item.title.append",fn:function(ref){
var item = ref.item;
return [(item.items)?_c('span',{staticClass:"material-icons-outlined"},[_vm._v("navigate_next")]):_vm._e()]}}]),model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }