<template>
    <div class="page -column dashboard">
        <div v-if="false" class="dashboard__date">
            {{ $t('dashboard.date.title') }}
        </div>
        <div class="dashboard__row">
            <div v-if="false" class="dashboard__cards-container -half-width">
                <DashboardInfoCard
                    :text="$t(card.trans)"
                    v-for="card in cards"
                    :key="card.id"
                    v-bind="card"
                ></DashboardInfoCard>
            </div>
            <div class="dashboard__quick-actions -half-width">
                <div class="block__header">
                    <h4 class="block__header-text">{{ $t('dashboard.quickActions.title') }}</h4>
                </div>
                <div class="buttons-container">
                    <Button
                        type="secondary"
                        @click="$router.push({ name: 'Create', params: { type: 'textpage' } })"
                        icon="description"
                        >{{ $t('dashboard.quickActions.buttons.createNewPage') }}</Button
                    >
                    <Button type="secondary" :to="{ name: 'Create Product' }" icon="local_mall">{{
                        $t('dashboard.quickActions.buttons.addProduct')
                    }}</Button>
                    <Button type="secondary" :to="{ name: 'Create', params: { type: 'catalog' } }" icon="category">{{
                        $t('dashboard.quickActions.buttons.createCatalog')
                    }}</Button>
                </div>
            </div>
        </div>
        <div v-if="false" class="dashboard__row">
            <headed-block
                class="dashboard__products"
                :heading="$t('dashboard.bestSellingProducts.title')"
                is-half-width
            >
                <dashboard-products-list></dashboard-products-list>
            </headed-block>
            <headed-block class="dashboard__statistics" :heading="$t('dashboard.statistics.title')" is-half-width>
                <div class="dashboard__statistics-body">
                    <dashboard-chart></dashboard-chart>
                </div>
            </headed-block>
        </div>
        <headed-block class="dashboard__orders" :heading="$t('dashboard.lastOrders.title')">
            <Button slot="header-append" :to="{ name: 'Order List' }" type="text">{{
                $t('lists.actions.viewAll')
            }}</Button>
            <OrderList is-widget></OrderList>
        </headed-block>
    </div>
</template>

<script>
import OrderList from '@/views/order/OrderList';
import DashboardInfoCard from '@/components/dashboard/DashboardInfoCard';
import { uuid } from 'vue-uuid';
import Button from '@/components/common/Button';
import DashboardProductsList from '@/components/dashboard/DashboardProductsList';
import DashboardChart from '@/components/dashboard/DashboardChart';
import HeadedBlock from '@/components/common/HeadedBlock';
export default {
    name: 'DashboardMinimal',
    components: {
        HeadedBlock,
        DashboardChart,
        DashboardProductsList,
        Button,
        DashboardInfoCard,
        OrderList,
    },

    mounted() {
        this.isLoading = false;
    },

    data() {
        return {
            cards: [
                {
                    id: uuid.v4(),
                    value: 'AED 953,000',
                    text: 'Total sales',
                    difference: '2.21%',
                    className: 'info',
                    isHigher: true,
                    icon: 'credit_card|outline',
                    trans: 'dashboard.total.sales',
                },
                {
                    id: uuid.v4(),
                    value: '152',
                    text: 'Total orders',
                    difference: '0.14%',
                    className: 'primary',
                    isHigher: true,
                    icon: 'shopping_cart|outline',
                    trans: 'dashboard.total.orders',
                },
                {
                    id: uuid.v4(),
                    value: '317',
                    text: 'Total items sold',
                    difference: '2.21%',
                    className: 'success',
                    isHigher: false,
                    icon: 'list',
                    trans: 'dashboard.total.items',
                },
            ],
            isLoading: true,
        };
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.dashboard {
    $gap: 32px;
    $header-height: 96px;
    display: flex;
    flex-direction: column;
    gap: $gap;
    padding-top: 30px;

    & .-half-width {
        width: calc(50% - #{$gap} / 2);
    }

    .block__header {
        height: $header-height !important;
    }

    &__row {
        display: flex;
        gap: $gap;
        flex-wrap: wrap;
    }

    &__products {
        .block__body {
            padding: 0;
            height: calc(100% - 96px) !important;
            border-radius: 0 0 4px 4px !important;
        }
    }

    &__products-table {
        height: 100% !important;
        padding-top: 24px;
    }

    &__cards-container {
        display: flex;
        flex-grow: 1;
        gap: $gap;
        @media screen and (max-width: 1704px) {
            width: 100% !important;
        }
    }

    &__quick-actions {
        display: flex;
        flex-grow: 1;
        flex-wrap: wrap;
        padding: 24px;
        background: var(--v-surface-base);
        border-radius: 4px;
        @media screen and (max-width: 1704px) {
            width: 100%;
        }

        & .block__header {
            min-height: 0;
            padding: 0;
            height: auto !important;
            margin-bottom: 29px;
            background: var(--v-surface-base);
        }

        & .buttons-container {
            display: flex;
            align-self: flex-end;
            flex-basis: 100%;
            gap: 24px;
        }
    }

    &__date {
        display: flex;
        align-items: center;
        gap: 24px;
        padding: 12px 24px;
        font-size: 16px;
        font-weight: 500;
        background: var(--v-surface-base);
        border-radius: 4px;
    }

    &__statistics {
        position: relative;
        height: 100%;
        background: var(--v-surface-base);
        border-radius: 4px;
        &-body {
            width: 100%;
            height: calc(100% - #{$header-height});
            padding: 24px;
        }
        .block__body {
            padding: 0;
            border-radius: 0 0 4px 4px !important;
        }
    }

    &__orders {
        .block__body {
            padding: 0;
            border-radius: 0 0 4px 4px !important;
        }
    }
}
</style>
