export default (ctx) => ({
    name: {
        component: 'input-text',
        props: {
            rules: ['noEmpty'],
            labelTrans: 'entities.location.commonSettings.fields.name',
        },
    },
    country: {
        component: 'Select',
        props: {
            rules: ['noEmpty'],
            labelTrans: 'entities.location.commonSettings.fields.country',
        },
        events: {
            input: (code) => {
                ctx.getDynamicLabels(code);
            },
        },
    },
    region: {
        component: '',
        props: {},
    },
    city: {
        component: 'input-text',
        props: {
            labelTrans: 'entities.location.commonSettings.fields.city',
            rules: ['noEmpty'],
        },
    },
    address: {
        component: 'input-text',
        props: {
            labelTrans: 'entities.location.commonSettings.fields.address',
            rules: ['noEmpty'],
        },
    },
    apartment: {
        component: 'input-text',
        props: {
            labelTrans: 'entities.location.commonSettings.fields.apartment',
        },
    },
    phone: {
        component: 'input-text',
        props: {
            labelTrans: 'entities.location.commonSettings.fields.phone',
            rules: ['phone'],
        },
    },
    postalCode: {
        component: 'input-text',
        props: {
            labelTrans: '',
            rules: ['noEmpty'],
        },
    },
    pickupExpectedTime: {
        component: 'Select',
        props: {
            labelTrans: 'entities.location.pickupSettings.fields.pickupExpectedTime',
        },
    },
    pickupNotification: {
        component: 'textarea',
        props: {
            labelTrans: 'entities.location.pickupSettings.fields.pickupNotification',
        },
    },
});
