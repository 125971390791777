import { validateEmail, validateNoEmpty } from '@/helpers/validator';
import DomainService from '@/services/DomainService.ts';
import Domain from '@/entities/domain/Domain';

const fields = {
    enabled: {
        type: 'field',
        component: 'input-switch',
        props: {
            errors: [],
        },
    },
    recipients: {
        type: 'field',
        component: 'array-field',
        props: {
            label: 'settings.notifierEmail.recipients',
            noLabel: true,
            rules: ['recipients'],
            errors: {},
        },
    },
    senderEmail: {
        type: 'field',
        component: 'input-text',
        props: {
            label: 'settings.notifierEmail.senderEmail',
            rules: ['senderEmail'],
            errors: [],
        },
    },
    senderName: {
        type: 'field',
        component: 'input-text',
        props: {
            label: 'settings.notifierEmail.senderName',
            errors: [],
        },
    },
    logo: {
        type: 'field',
        component: 'InputImage',
        props: {
            label: 'settings.notifierEmail.logo',
            errors: [],
            note: 'Format - PNG',
        },
    },
};

function validateRecipients(values) {
    const errors = {};
    values.forEach((value, index) => {
        if (!validateEmail(value)) {
            if (!errors[index]) {
                errors[index] = [];
            }
            errors[index].push('validator.errors.email');
        }
        if (!validateNoEmpty(value)) {
            if (!errors[index]) {
                errors[index] = [];
            }
            errors[index].push('validator.errors.empty');
        }
    });
    console.log(errors);
    return errors;
}

export async function validateSenderEmail(value, isSubmitting) {
    const errors = [];
    this.isDomainConfirmed = true;
    this.domainCanBeUsedInEmail = true;
    this.doesDomainExist = true;
    if (!validateEmail(value)) {
        errors.push('validator.errors.email');
        return errors;
    }
    if (!isSubmitting) return;
    const domainName = value.split('@')[1];
    const [error, result] = await DomainService.getAll(this.instance);
    if (error) {
        error.notify();
    }
    const domains = result.map((item) => new Domain(item));
    const existingDomain = domains.find((item) => item.name === domainName);
    if (!existingDomain) {
        errors.push('');
        this.doesDomainExist = false;
        this.errorDomainName = domainName;
        return errors;
    }
    if (!existingDomain.canBeUsedInEmail) {
        this.domainCanBeUsedInEmail = false;
        this.errorDomainName = domainName;
        errors.push('');
    }
    if (!existingDomain.confirmed) {
        this.isDomainConfirmed = false;
        this.errorDomainName = domainName;
        errors.push('validator.errors.domainIsNotConfirmed');
    }
    return errors;
}

export async function validateField(item, value, isSubmitting) {
    const rules = item.props?.rules;
    if (!rules || rules.length === 0) return [];
    const errors = [];
    for (const rule of item.props.rules) {
        switch (rule) {
            case 'noEmpty': {
                if (!validateNoEmpty(value)) errors.push('validator.errors.empty');
                break;
            }
            case 'email': {
                if (!validateEmail(value)) errors.push('validator.errors.email');
                break;
            }
            case 'recipients': {
                return validateRecipients(value);
            }
            case 'senderEmail': {
                return await validateSenderEmail.call(this, value, isSubmitting);
            }
        }
    }
    return errors;
}

export default fields;
