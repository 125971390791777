<template>
    <div class="page -width-lg">
        <div class="page-header">
            <h1 class="page-header__text">{{ $t('menus.pageHeaders.comments') }}</h1>
        </div>
        <view-comment-modal v-model="isViewModalOpen" :item="viewItem"></view-comment-modal>
        <error-block v-if="!isAvailable"></error-block>
        <Table
            v-else
            :columns="headers"
            v-model="selected"
            :items="items"
            :is-data-loaded="isDataLoaded"
            :show-select="true"
            :pagination="pagination"
            nested
            @change-per-page="changePerPage"
            @delete-selected-items="openDeletionModal"
        >
            <!--  ENABLED  -->
            <template v-slot:item.enabled="{ item }">
                <input-switch
                    :value="item.enabled"
                    name="hidden"
                    size="small"
                    @input="onUpdateFieldValue({ name: 'enabled', value: $event }, item)"
                ></input-switch>
            </template>
            <!--  ACTIONS  -->
            <template v-slot:item.actions="{ item }">
                <div class="table__actions">
                    <Button icon="visibility" @click="openViewModal(item)" type="icon" />
                    <Button
                        :title="$t('lists.deleteButton.product')"
                        @click="openDeletionModal(item)"
                        type="icon"
                        icon="delete"
                    />
                </div>
            </template>
            <!--  TEXT  -->
            <template v-slot:item.text="{ item }">
                <div v-if="item.text" class="comments__text">{{ item.text }}</div>
                <template v-else>—</template>
            </template>

            <template v-slot:item.articleId="{ item }">
                <span class="table-edit" v-if="articlesMap[item.articleId]">
                    <router-link
                        class="table-edit__link"
                        :to="{ name: 'Edit Article', params: { id: item.articleId } }"
                        >{{ articlesMap[item.articleId].title }}</router-link
                    >
                    <span class="table-edit__icon material-icons-outlined">edit</span>
                </span>
            </template>
        </Table>
    </div>
</template>

<script>
import { Pagination } from '@/services/PaginationService';
import EventEmitter from '@/helpers/eventEmitter.ts';
import ErrorBlock from '@/components/common/ErrorBlock';
import { publicUrl } from '@/helpers/values';
import ArticleService from '@/services/ArticleService';
import Comment from '@/entities/blog/Comment';
import Image from '@/entities/image/Image';
import Table from '@/components/common/Table';
import Button from '@/components/common/Button';
import { flattenArrayOfNestedObjects } from '@/helpers/utils';
import ViewCommentModal from '@/components/blog/CommentViewModal';

export default {
    name: 'CommentList',
    components: {
        ViewCommentModal,
        Button,
        ErrorBlock,
        Table,
    },
    async created() {
        EventEmitter.on('delete-comment', this.deleteItems);
        const total = await this.getTotal();
        if (this.$route.query.limit) {
            this.perPage = this.paginationOptions.find((item) => item.value == this.$route.query.limit).value;
        }
        this.pagination = new Pagination({
            moduleName: 'comments',
            limit: 100,
            pageNumber: this.pageNumber,
            total,
        });
        await this.getItems();
        this.initialLoading = false;
    },
    data: () => ({
        isDataLoaded: false,
        items: [],
        isAvailable: true,
        initialLoading: true,
        pagination: null,
        isEditModalOpen: false,
        count: null,
        selected: [],
        articlesMap: {},
        isViewModalOpen: false,
        viewItem: { comment: {}, article: {} },
        publicUrl,
    }),
    computed: {
        headers() {
            return [
                { value: 'actions', sortable: false, width: '114px' },
                { text: this.$t('lists.columns.name'), value: 'name', width: 130, sortable: false },
                { text: this.$t('lists.columns.email'), value: 'email', width: 150, sortable: false },
                { text: this.$t('lists.columns.enabled'), value: 'enabled', width: 150 },
                { text: this.$t('lists.columns.articleId'), value: 'articleId' },
                { text: this.$t('lists.columns.text'), value: 'text', width: 110, sortable: false },
            ];
        },
        pageNumber() {
            return this.$store.getters['globalVars/getCommentsPage'] || 1;
        },
        articleId() {
            return this.$route.query.articleId;
        },
    },
    methods: {
        async getItems() {
            this.isDataLoaded = false;

            const params = {
                limit: this.pagination.limit,
                offset: this.pagination.offset,
                type: null,
            };
            if (this.articleId) {
                params.article = this.articleId;
            }
            const getter = this.getComments;
            const [error, result] = await getter(params);
            if (error) {
                error.alert();
                this.isAvailable = false;
                this.isDataLoaded = true;
                return;
            }
            this.items = flattenArrayOfNestedObjects(result);
            await this.getArticles();
            this.isDataLoaded = true;
        },

        async getComments(params) {
            return await ArticleService.getAllComments(params);
        },

        async getArticles() {
            const ids = this.items.map((item) => item.articleId);
            const [error, result] = await ArticleService.getAll({ ids });
            if (error) {
                error.notify();
                return;
            }
            this.articlesMap = result.reduce((acc, item) => {
                acc[item.id] = item;
                return acc;
            }, {});
        },

        openViewModal(item) {
            this.viewItem = { comment: item, article: this.articlesMap[item.articleId] };
            this.isViewModalOpen = true;
        },

        async getTotal() {
            const [error, total] = await Pagination.getTotal('comments');
            if (error) {
                error.notify();
            }
            return total;
        },

        changePerPage(perPage) {
            this.pagination.changeLimit(perPage.value);
            this.perPage = perPage.value;
            this.getItems();
        },

        getImage(item) {
            const image = item.image || item.productImage;
            return image ? new Image(image) : null;
        },

        async deleteItems(items) {
            this.isDataLoaded = false;
            const parents = items.filter((item) => item.children.length);
            const allChildren = flattenArrayOfNestedObjects(parents).filter((item) => item.depth !== 0);
            const promises = [];
            items.filter((item) => {
                if (!allChildren.find((child) => child.id === item.id)) {
                    promises.push(ArticleService.removeComment(item.id));
                }
            });
            const results = await Promise.all(promises);
            const normalized = await this.pagination.normalize(this.$route, this.items);
            this.selected = [];
            this.$store.dispatch('modals/closeDeletionModal');
            if (!normalized) {
                await this.getItems();
            }
            if (results.every((item) => item.success)) {
                if (items.length === 1) {
                    EventEmitter.trigger('show-noty', {
                        type: 'success',
                        text: this.$tc('notifications.deleted', 1, { entity: this.$tc('entities.comment.title', 1) }),
                    });
                } else {
                    EventEmitter.trigger('show-noty', {
                        type: 'success',
                        text: this.$tc('notifications.deleted', 2, { entity: this.$tc('entities.comment.title', 2) }),
                    });
                }
            }
            this.isDataLoaded = true;
        },

        openDeletionModal(items) {
            this.$store.dispatch('modals/openDeletionModal', { items, entity: 'comment' });
        },

        async onUpdateFieldValue(event, comment) {
            const { name, value } = event;
            comment[name] = value;
            const newComment = new Comment(comment);
            const [error] = await ArticleService.updateComment(newComment.data);
            if (error) {
                error.notify();
            }
        },
    },
    beforeDestroy() {
        EventEmitter.off('delete-comment', this.deleteItems);
    },
    watch: {
        pageNumber(newValue) {
            this.pagination.pageNumber = newValue;
            this.getItems();
        },
    },
};
</script>
<style lang="scss">
@import '@/scss/variables.scss';
.comments {
    &__product {
        display: flex;
        gap: 24px;
        align-items: center;
        cursor: pointer;
        color: var(--v-primary-base);
        .table-edit {
            overflow-wrap: break-word;
            max-width: 270px;
        }
        .product-title {
            + .table-edit__icon {
                bottom: 3px;
            }
        }
        &:hover {
            color: var(--v-primary-accent-base);
            .table-edit__icon {
                opacity: 1;
                transition: all 0.1s;
            }
        }
        &:active {
            color: var(--v-primary-darken-base);
        }
    }

    &__text {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
}
.chat-icon {
    &.-has-comment {
        color: #2979ff;
    }
}
</style>
