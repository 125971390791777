export default {
    // TEXT
    // default
    text_main: '#FFFFFF',
    text_body1: '#E9EBF2',
    text_body2: '#DCDEE5',
    text_accent: '#00A1B0',
    text_neutral: '#E9EBF2',
    // inverted
    text_main_inverted: '#FFFFFF',
    text_body1_inverted: '#D6DAE5',
    text_body2_inverted: '#B7BCCC',
    text_accent_inverted: '#00C2D3',
    text_neutral_inverted: '#B3B9C7',

    // ACTION
    // primary
    action_primary: '#00A1B0',
    action_primary_hover: '#00C2D3',
    action_primary_pressed: '#007E8A',
    action_primary_disabled: '#434559',
    // secondary
    action_secondary: '#3C70D6',
    action_secondary_hover: '#4786FF',
    action_secondary_pressed: '#325EB2',
    action_secondary_disabled: '#434559',
    // neutral_light
    header_bg: '#252736',
    header_bg_mobile: '#111219',
    header_main_menu_bg: '#111219',
    header_main_menu_dropdown_bg: '#2B2D3D',
    action_neutral_light: '#FFFFFF',
    // action_neutral_light_hover: '#4786FF',
    // action_neutral_light_pressed: '#3C70D6',
    action_neutral_light_hover: '#325EB2',
    action_neutral_light_pressed: '#4786FF',
    action_neutral_light_disabled: '#434559',
    // neutral_medium
    action_neutral_medium: '#6D7991',
    action_neutral_medium_hover: '#3C70D6',
    action_neutral_medium_pressed: '#3C70D6',
    action_neutral_medium_disabled: '#43414C',
    // neutral_dark
    action_neutral_dark: '#FFFFFF',
    action_neutral_dark_hover: '#4786FF',
    action_neutral_dark_pressed: '#325EB2',
    action_neutral_dark_disabled: '#434559',
    // system
    action_system: '#E0224F',
    action_system_hover: '#FF265A',
    action_system_pressed: '#BF1D43',
    action_system_disabled: '#43414C',

    // NEUTRAL
    neutral1: '#111219',
    neutral2: '#111219',
    neutral3: '#2B2D3D',
    neutral4: '#4D4F66',
    neutral5: '#3E4159',
    neutral6: '#3E4159',
    neutral7: '#252736',

    // SYSTEM
    success: '#00C2D3',
    success_bg: '#005259',
    warning: '#D9C300',
    warning_bg: '#3E3014',
    error: '#F9416D',
    error_bg: '#590015',

    // ACCENT
    accent1: '#BBD2FF',
    accent1_bg: '#3665BF',
    accent2: '#CDA0ED',
    accent2_bg: '#7E00D9',
    accent3: '#A4F2D1',
    accent3_bg: '#00826B',
    accent4: '#FFC7E2',
    accent4_bg: '#CC0062',

    whatsapp: '#20B051',
};
