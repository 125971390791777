<template>
    <Table
        :show-toolbar="false"
        :show-selected-actions="false"
        :show-select-all="false"
        class="variations-table"
        :columns="headers"
        :items="variations"
    >
        <!-- DEFAULT -->
        <template v-slot:item.default="{ item }">
            <input-radio
                :return-value="true"
                v-model="item.default"
                name="default"
                @input="changeDefaultVariation(item)"
            ></input-radio>
        </template>
        <!-- IMAGE -->
        <template v-if="productId" v-slot:item.imageId="{ item }">
            <product-image-field
                :upload="false"
                image-type="product"
                name="imageId"
                :owner-id="productId"
                v-model="item.imageId"
                @update-field="onUpdateFieldValue($event, item, true)"
                is-popup
            />
        </template>
        <!-- TITLE -->
        <template v-slot:item.title="{ item }">
            {{ item.title }}
        </template>
        <!-- SKU -->
        <template v-slot:item.sku="{ item }">
            <form-item v-bind="item.fields.sku.props">
                <input-text
                    v-bind="item.fields.sku.props"
                    v-model="item.sku"
                    @update-field="onUpdateFieldValue($event, item)"
                    name="sku"
                ></input-text>
            </form-item>
        </template>
        <!-- FULL PRICE -->
        <template v-slot:item.fullPrice="{ item }">
            <form-item v-bind="item.fields.fullPrice.props">
                <input-text
                    type="number"
                    :measurement="currency"
                    v-bind="item.fields.fullPrice.props"
                    @update-field="onUpdateFieldValue($event, item, true)"
                    name="fullPrice"
                    v-model.number="item.fullPrice"
                ></input-text>
            </form-item>
        </template>
        <!-- DISCOUNT -->
        <template v-slot:item.discount="{ item }">
            <form-item v-bind="item.fields.discount.props">
                <input-text
                    name="discount"
                    v-bind="item.fields.discount.props"
                    @input.native="controlDiscountInput($event, item)"
                    v-model="item.discount"
                ></input-text>
            </form-item>
        </template>
        <!-- PRICE AFTER DISCOuNT -->
        <template v-slot:item.discountPrice="{ item }">
            <form-item v-bind="item.fields.discountPrice.props">
                <input-text
                    disabled
                    name="discountPrice"
                    @update-field="onUpdateFieldValue($event, item)"
                    :measurement="currency"
                    :value="item.getFinalPrice()"
                ></input-text>
            </form-item>
        </template>
        <!-- STOCK -->
        <template v-slot:item.stock="{ item }">
            <form-item v-bind="item.fields.stock.props">
                <input-text
                    type="number"
                    @update-field="onUpdateFieldValue($event, item)"
                    v-model="item.stock"
                    name="stock"
                    null-value="∞"
                ></input-text>
            </form-item>
        </template>
        <!-- SIZE -->
        <template v-slot:item.size="{ item }">
            <form-item v-bind="item.fields.size.props">
                <product-size-input
                    disabled
                    v-bind="item.fields.size.props"
                    name="size"
                    :default-value="product.size"
                    @input="updateVariationSize($event, item)"
                    @update-field="onUpdateFieldValue($event, item, true)"
                    :value="{ length: item.length, width: item.width, height: item.height }"
                ></product-size-input>
            </form-item>
        </template>
        <!-- WEIGHT -->
        <template v-slot:item.weight="{ item }">
            <form-item v-bind="item.fields.weight.props">
                <input-text
                    v-bind="item.fields.weight.props"
                    name="weight"
                    @update-field="onUpdateFieldValue($event, item)"
                    v-model="item.weight"
                ></input-text>
            </form-item>
        </template>

        <!-- STOCK HEADER -->
        <template v-slot:header.stock>
            {{ $t('lists.columns.quantity') }}
            <tooltip-popup>
                <span>{{ $t('entities.productVariation.tooltips.quantity') }}</span>
            </tooltip-popup>
        </template>
        <!-- SIZE HEADER -->
        <template v-slot:header.size>
            <template>
                {{ $t('lists.columns.size') }}
                <tooltip-popup>
                    <span v-html="$t('entities.product.tooltips.size')"></span>
                </tooltip-popup>
            </template>
        </template>
        <!-- DISCOUNT HEADER -->
        <template v-slot:header.discount>
            {{ $t('lists.columns.discount') }}
            <tooltip-popup>
                <span>{{ $t('entities.productVariation.tooltips.discount') }}</span>
            </tooltip-popup>
        </template>
        <template v-if="!productId" v-slot:footer>
            <alert class="variations-table__alert">
                {{ $t('entities.productVariation.tooltips.pictures') }}
            </alert>
        </template>
    </Table>
</template>

<script>
import { cloneDeep, debounce } from 'lodash';
import ProductImageField from '@/components/product/ProductImageField';
import ProductVariation from '@/entities/product/ProductVariation';
import FormItem from '@/components/form/item';
import { mapGetters } from 'vuex';
import InputRadio from '@/components/form/controls/InputRadio';
import InputText from '@/components/form/controls/InputText';
import Table from '@/components/common/Table';
import ProductSizeInput from '@/components/product/ProductSizeInput';
import EventEmitter from '@/helpers/eventEmitter';
import Alert from '@/components/common/Alert';
import TooltipPopup from '@/components/common/TooltipPopup';
export default {
    name: 'ProductVariationsList',
    components: { TooltipPopup, ProductSizeInput, InputText, InputRadio, FormItem, Table, ProductImageField, Alert },

    data() {
        return {
            fieldProps: Object.keys(new ProductVariation().fields),
            variations: [],
            debouncedFieldHandler: null,
        };
    },

    props: {
        value: {
            type: Array,
            required: true,
        },
        errors: {
            type: Array,
        },
        name: {
            type: String,
        },
    },
    computed: {
        ...mapGetters('product', {
            product: 'getCurrent',
        }),
        currency() {
            return this.$store.getters['config/getCurrency'];
        },
        productId() {
            return this.$route.params.id;
        },
        headers() {
            const headers = [
                { text: this.$t('lists.columns.default'), value: 'default', width: '12px', sortable: false },
                { text: this.$t('lists.columns.options'), value: 'title', width: '117px', sortable: false },
                { text: this.$t('lists.columns.sku'), value: 'sku', width: '160px', sortable: false },
                { text: this.$t('lists.columns.fullPrice'), value: 'fullPrice', width: '130px', sortable: false },
                { value: 'discount', width: '130px', sortable: false },
                {
                    text: this.$t('lists.columns.priceAfterDiscount'),
                    value: 'discountPrice',
                    width: '130px',
                    sortable: false,
                },
                { value: 'stock', width: '130px', sortable: false },
                { text: this.$t('lists.columns.size'), value: 'size', width: '350px', sortable: false },
                {
                    text: this.$t('lists.columns.weight'),
                    value: 'weight',
                    width: '130px',
                    sortable: false,
                },
            ];
            //если мы на странице редактирования товара - отображаем картинки
            if (this.productId) {
                headers.splice(1, 0, {
                    text: this.$t('lists.columns.image'),
                    value: 'imageId',
                    sortable: false,
                    width: '30px',
                });
            }
            return headers;
        },
    },

    methods: {
        changeDefaultVariation(item) {
            item.default = true;
            this.variations.forEach((variation) => {
                if (variation.id !== item.id) variation.default = false;
            });
            this.onUpdateFieldValue({ name: 'default', value: true }, item, true);
            this.$emit('input', this.variations);
        },

        onUpdateFieldValue(payload, variation, isDebounced = false) {
            if (isDebounced) {
                if (this.debouncedFieldHandler) {
                    this.debouncedFieldHandler.cancel();
                }
                this.debouncedFieldHandler = debounce(() => {
                    this.fieldHandler(payload, variation);
                    this.debouncedFieldHandler = null;
                }, 400);
                this.debouncedFieldHandler();
            } else {
                this.fieldHandler(payload, variation);
            }
        },

        fieldHandler(payload, variation) {
            const { name } = payload;
            const cloned = cloneDeep(variation);
            const errors = cloned.validateField(cloned.fields[name], cloned[name]);
            cloned.fields[name].props.errors = errors;
            const clonedVariations = cloneDeep(this.variations);
            const oldVariationIndex = clonedVariations.findIndex((item) => item.id === cloned.id);
            clonedVariations[oldVariationIndex] = cloned;
            this.$emit('input', clonedVariations);
            this.$emit('update-field', {
                name: this.name,
                value: clonedVariations,
            });
        },

        updateVariationSize(value, item) {
            const { length, height, width } = value;
            item.length = length;
            item.height = height;
            item.width = width;
            this.fieldHandler({ name: 'size' }, item);
        },

        controlDiscountInput(e, item) {
            const val = e.target.value;
            if (!val) return;
            const regEx = /(\d+\.\d{0,2}$)|(\d+\.\d{1,2}%?)|(\d+%?)/;
            const match = val.match(regEx);
            if (!match) item.discount = '';
            else {
                item.discount = match[0];
            }
            this.onUpdateFieldValue({ value: val, name: 'discount' }, item, true);
        },
        showTooltips(tooltips) {
            EventEmitter.trigger('toggle-tooltips', {
                isShown: true,
                tooltips,
            });
        },
    },
    watch: {
        value: {
            deep: true,
            immediate: true,
            handler(val) {
                this.variations = cloneDeep(val);
            },
        },
        errors: {
            deep: true,
            handler(val) {
                if (val?.length) {
                    val.forEach((item, index) => {
                        for (const key in item.children) {
                            const field = this.variations[index].fields[key];
                            if (field) {
                                this.variations[index].fields[key].props.errors = item.children?.[key]?.errors;
                            }
                        }
                    });
                }
            },
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.variations-table {
    .table {
        padding: 0 !important;
    }
    td {
        height: 104px !important;
        vertical-align: center;
    }
    td:first-child,
    td:nth-child(3) {
        padding-right: 16px !important;
    }
    &__tooltip-btn {
        margin-left: 5.5px;
        vertical-align: middle;
        margin-bottom: 2px;
        cursor: pointer;
    }
    &__tooltip {
        max-width: 270px;
    }
    .form-item.-has-errors {
        height: 103px;
        .input-text {
            margin-top: 63px;
        }
        .product-size-input {
            margin-top: 63px;
            .input-text {
                margin-top: 0;
            }
        }
    }
    &__alert {
        margin-top: 16px;
    }
}
</style>
