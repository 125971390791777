<template>
    <modal width="750" :header="`${$t('entities.lead.title')} №${item.lead.number}`" v-model="model">
        <template v-slot:header-append>
            <Button
                :title="$t('lists.editButton.lead')"
                :to="{ name: 'Edit Lead', params: { id: item.lead.id } }"
                icon="edit"
                type="icon"
                class="view-lead-modal__edit-button"
            />
        </template>
        <div class="view-lead-modal__body">
            <template v-for="(value, key) in item.lead">
                <div
                    v-if="
                        key !== 'fields' && key !== 'id' && key !== 'number' && key !== 'product' && key !== 'createdAt'
                    "
                    :key="key"
                    class="view-lead-modal__item"
                >
                    <span class="view-lead-modal__item-header">{{ $t(`entities.lead.fields.${key}`) }}:</span>
                    <div class="view-lead-modal__item-value">{{ value || '—' }}</div>
                </div>
                <div v-if="key === 'product'" :key="key" class="view-lead-modal__item">
                    <span class="view-lead-modal__item-header">{{ $t(`entities.lead.fields.${key}`) }}:</span>
                    <router-link
                        v-if="item.product"
                        class="view-lead-modal__product"
                        :to="{ name: 'Edit Product', params: { id: item.product.productId } }"
                    >
                        <table-image class="table__image" :image="image"></table-image>
                        <span class="table-edit">
                            <span type="text" class="table-edit__link product-title">{{
                                item.product.productTitle
                            }}</span>
                        </span>
                    </router-link>
                    <template v-else>—</template>
                </div>
                <div v-if="key === 'createdAt'" :key="key" class="view-lead-modal__item">
                    <span class="view-lead-modal__item-header">{{ $t(`entities.lead.fields.${key}`) }}:</span>
                    {{ new Date(value) | localDate }}
                </div>
            </template>
        </div>
    </modal>
</template>

<script>
import Modal from '@/components/common/Modal';
import { proxyModelMixin } from '@/mixins/proxyModelMixin';
import Image from '@/entities/image/Image';
import TableImage from '@/components/common/TableImage';
import Button from '@/components/common/Button';

export default {
    name: 'ViewLeadModal',
    mixins: [proxyModelMixin],
    components: { Modal, TableImage, Button },

    props: {
        value: {
            type: Boolean,
            default: false,
        },
        item: {
            type: Object,
            default: () => {
                return { lead: {}, product: {} };
            },
        },
    },
    computed: {
        image() {
            const image = this.item.product.image || this.item.product.productImage;
            return image ? new Image(image) : null;
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.view-lead-modal {
    &__body {
        display: flex;
        flex-wrap: wrap;
        gap: 14px;
    }
    &__item {
        display: flex;
        width: 100%;
        gap: 30px;
    }
    &__item-header {
        width: 100px;
        min-width: 100px;
        font-weight: 500;
    }
    &__edit-button {
        margin-right: auto;
        margin-left: 4px;
    }
    &__product {
        display: flex;
        gap: 12px;
        align-items: center;
    }
}
</style>
