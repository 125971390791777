import { cloneDeep } from 'lodash';
import { Container, Content, Footer, Heading, Section } from '@/entities/section/samples/_options';

const section = {
    id: '',
    type: 'SectionBenefitStyle1',
    required: false,
    group: 'Benefits',
    options: {
        section: { ...Section },
        container: { ...Container },
        heading: { ...Heading },
        content: {
            htmlBefore: { ...Content.htmlBefore },
            htmlAfter: { ...Content.htmlAfter },
            colorText: { ...Content.color },
            listWidth: {
                ...Content.listWidth,
                value: 'small',
            },
            useCarousel: { ...Content.useCarousel },
        },
        columns: {
            type: 'field',
            value: {
                // пользовательские настройки колонок
                desktop: 3,
                tablet: 2,
                mobile: 1,
            },
            component: 'CustomizeColumnsTabs',
            ...{
                props: {
                    ranges: {
                        desktop: [2, 3, 4, 5, 6],
                        tablet: [2, 3, 4],
                        mobile: [1, 2],
                    },
                },
            },
        },
        footer: { ...Footer },
        // background: { ...Background },
    },
    items: [],
    children: {
        id: '',
        type: 'benefit',
        options: {
            heading: {
                title: {
                    ...Heading.title,
                    value: 'Benefit Item',
                },
                colorTitle: { ...Heading.colorTitle },
            },
            content: {
                html: { ...Content.html },
                icon: { ...Content.benefitIcon },
            },
        },
    },
};

const itemFixture = cloneDeep(section.children);
const itemsFixture = [];
itemsFixture.push(Object.assign(cloneDeep(itemFixture), { position: 0 }));
itemsFixture.push(Object.assign(cloneDeep(itemFixture), { position: 1 }));
itemsFixture.push(Object.assign(cloneDeep(itemFixture), { position: 2 }));
section.items = itemsFixture;

export default section;
