<template>
    <div class="options-view-edit">
        <heading type="body-2">{{ $t('settings.additional.optionsView') }}</heading>
        <div v-if="options.length" class="options-view-edit__list">
            <FormItem
                v-for="option in options"
                :key="option.title"
                v-bind="{ label: option.title, grid: 'row' }"
                class="options-view-edit__item"
            >
                <Select :items="viewVariants" :name="option.title" v-model="option.view" :size="'small'" />
            </FormItem>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import OptionService from '@/services/OptionService';
import EventEmitter from '@/helpers/eventEmitter.ts';
import FormItem from '@/components/form/item.vue';
import Select from '@/components/form/controls/Select.vue';
import Heading from '@/components/common/Heading';

export default {
    name: 'OptionsViewEdit',
    components: {
        FormItem,
        Select,
        Heading,
    },
    data: () => ({
        options: [],
        isLoaded: false,
        types: ['SectionProductList', 'SectionProductWidget', 'SectionProductWidgetByItems'],
        viewVariants: [
            {
                text: 'entities.option.view.button',
                value: 'buttons',
            },
            {
                text: 'entities.option.view.select',
                value: 'select',
            },
        ],
    }),
    async mounted() {
        await this.getOptions();
        if (this.options) {
            this.options.forEach((item) => {
                this.$watch(() => item, this.updOption, { deep: true });
            });
        }
    },
    computed: {
        ...mapGetters('builder', {
            sections: 'getSectionsCurrentPage',
        }),
    },
    methods: {
        ...mapActions('builder', {
            updateSection: 'updateSection',
        }),
        async getOptions() {
            this.isLoaded = false;
            let [error, result] = await OptionService.getAll();
            if (error) {
                error.alert();
                return;
            }
            this.options = result;
            this.isLoaded = true;
        },
        async updOption(newOption) {
            if (newOption) {
                const [error] = await OptionService.updateOne(this.convertOption(newOption));
                if (error) error.notify();
                else {
                    EventEmitter.trigger('show-noty', {
                        type: 'success',
                        text: this.$tc('notifications.updated', 1, { entity: this.$tc('entities.option.title', 1) }),
                    });
                    this.updProductsSections();
                }
            }
        },
        convertOption(option) {
            return {
                id: option.id,
                position: option.position,
                title: option.title,
                type: option.type,
                unit: option.unit,
                values: [...option.values],
                view: option.view,
            };
        },
        updProductsSections() {
            this.sections.forEach((section) => {
                const hasType = this.types.some((type) => type === section.type);
                if (hasType) this.updateSection(section);
            });
        },
    },
};
</script>
<style lang="scss">
@import '@/scss/variables.scss';
.options-view-edit {
    &__item {
        padding: 12px 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        &:last-child {
            padding: 12px 0 0;
            border-bottom: none;
        }
        .select {
            width: 90px;
        }
    }
}
</style>
