<template>
    <div class="account">
        <div v-if="!isLoaded || !currentUser" class="loader-wrapper">
            <ProgressCircular size="medium" />
        </div>
        <div v-else class="content-wrapper">
            <CurrentUser :user="currentUser" />
            <InstanceSelector :items="stores" />
        </div>
    </div>
</template>

<script>
import CurrentUser from '@/components/auth/CurrentUser.vue';
import InstanceSelector from '@/components/auth/InstanceSelector';
import ProgressCircular from '@/components/common/ProgressCircular';
import { mapState, mapGetters } from 'vuex';

export default {
    name: 'InstanceList',
    components: {
        ProgressCircular,
        // SignInForm,
        CurrentUser,
        InstanceSelector,
    },
    data() {
        return {
            isLoaded: false,
            user: {},
            isLogged: false,
        };
    },
    computed: {
        ...mapState('globalVars', ['currentUser', 'instances']),
        ...mapGetters('globalVars', { stores: 'getInstances' }),
    },
    async created() {
        this.isLoaded = true;
    },
};
</script>

<style lang="scss">
.account {
    h2 {
        font-size: 33px !important;
        font-weight: 400;
        margin-top: 0.83em;
    }

    .loader-wrapper {
        display: flex;
        justify-content: center;
        padding: 40px;
        width: 100%;
    }
    a {
        text-decoration: none;
    }
}
</style>
