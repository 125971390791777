export default [
    {
        title: 'entities.blocks.generalInformation',
        items: [
            'title',
            'heading',
            'text',
            'description',
            'previewDescription',
            'labelIds',
            'catalogs',
            'size',
            'weight',
        ],
    },

    {
        title: 'entities.blocks.seo',
        items: ['metaTitle', 'metaDescription', 'slug'],
    },
];
