export function getDivaceValues(value, count = 2) {
    const itemCount = count ? count : 2;
    return checkDivaceValue(value) && checkDivaceItemCount(value, itemCount) ? value : getDefaultColumns(itemCount);
}

export function checkDivaceValue(value) {
    if (!value) return false;
    const sum = value.reduce((acc, item) => (acc += item), 0);
    if (sum !== 12) return false;
    return true;
}

export function checkDivaceItemCount(value, count) {
    if (!count || value.length !== count) return false;
    return true;
}

export function getDefaultColumns(count) {
    let newValue = [];
    for (let index = 0; index < count; index++) newValue.push(12 / count);
    return newValue;
}
