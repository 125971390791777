export default {
    recipients: {
        component: 'array-field',
        props: {
            label: 'settings.notifierEmail.recipients',
            noLabel: true,
            group: 'notifierEmail',
            rules: ['recipients'],
            errors: {},
        },
    },
    senderEmail: {
        component: 'input-text',
        props: {
            labelTrans: 'settings.notifierEmail.senderEmail',
            group: 'notifierEmail',
            rules: ['senderEmail'],
            errors: [],
        },
    },
    senderName: {
        component: 'input-text',
        props: {
            labelTrans: 'settings.notifierEmail.senderName',
            group: 'notifierEmail',
            errors: [],
        },
    },
    chatId: {
        component: 'input-text',
        props: {
            labelTrans: 'settings.notifierTelegram.chatId',
            group: 'notifierTelegram',
            errors: [],
        },
    },
    token: {
        component: 'input-text',
        props: {
            labelTrans: 'settings.notifierTelegram.token',
            group: 'notifierTelegram',
            errors: [],
        },
    },
    //TODO: переделать без vuetify
    isUseTemplate: {
        component: 'input-radio',
        props: {
            group: 'notifierTelegram',
            errors: [],
        },
    },
    template: {
        component: 'textarea',
        props: {
            labelTrans: 'settings.notifierTelegram.template',
            group: 'notifierTelegram',
            errors: [],
        },
    },
};
