<template>
    <div class="page payment-view">
        <payment-countries-modal
            v-model="isCountriesModalOpen"
            :zones="editingPayment.zones"
            @update-zones="onUpdateZones"
        ></payment-countries-modal>
        <sticky-toolbar>
            <Button @click="isAddModelOpen = true" icon="add_circle_outline">{{ $t('entities.add') }}</Button>
            <Button icon="check" :disabled="!saveAllowed" @click="onSubmit">{{ $t('entities.save') }}</Button>
        </sticky-toolbar>
        <div class="page-header">
            <h1 class="page-header__text">{{ $t('menus.pageHeaders.payment') }}</h1>
        </div>
        <alert type="success" class="entity-item__success-alert" v-if="isUpdated">
            {{ $tc('notifications.updated', 2, { entity: this.$tc('entities.payment.title', 1) }) }}
        </alert>
        <payment-add-item-modal
            v-if="isDataLoaded"
            v-model="isAddModelOpen"
            :systems="availableSystems"
            @add-system="addSystem"
        ></payment-add-item-modal>
        <progress-circular class="spinner -absolute" v-if="!isDataLoaded"></progress-circular>
        <template v-else>
            <div class="cash-on-delivery">
                <div class="cash-on-delivery__info">
                    <input-switch
                        class="expansion__switch"
                        :disabled="isOnlyCashOnDelivery"
                        v-model="forms.items.paymentCashOnDelivery.value.items.enabled.value"
                    ></input-switch>
                    <div class="address-card">
                        <div class="address-card__text">
                            <h4 class="address-card__city expansion__heading-text">
                                {{ $t('entities.payment.systems.paymentCashOnDelivery') }}
                            </h4>
                        </div>
                    </div>
                </div>
                <div class="cash-on-delivery__countries">
                    <div class="divider"></div>
                    <div class="form second-form">
                        <div class="form-item -half-width" v-if="!isOnlyCashOnDelivery">
                            <div class="form-item__label">{{ $t('entities.payment.countries.availableIn') }}</div>
                            <payment-countries-input
                                system-name="paymentCashOnDelivery"
                                :allowed-locations="
                                    forms.items.paymentCashOnDelivery.value.items.allowedLocations.value
                                "
                                @open-modal="openCountriesModal"
                            ></payment-countries-input>
                        </div>
                        <form-item
                            class="method-name"
                            v-bind="forms.items.paymentCashOnDelivery.value.items.methodName.props"
                            key="methodName"
                        >
                            <input-text
                                group="paymentCashOnDelivery"
                                v-model="forms.items.paymentCashOnDelivery.value.items.methodName.value"
                                v-bind="forms.items.paymentCashOnDelivery.value.items.methodName.props || {}"
                                name="methodName"
                                @update-field="onUpdateFieldValue"
                            />
                        </form-item>
                    </div>
                </div>
            </div>
            <template v-for="(form, group) in forms.items">
                <expansion v-if="group !== 'paymentCashOnDelivery' && form.value.items.isActive.value" :key="group">
                    <template v-slot:header-left>
                        <input-switch
                            class="expansion__switch"
                            @click.native.stop
                            @input="handleEnabled(group)"
                            v-model="form.value.items.enabled.value"
                        ></input-switch>
                        <div class="address-card">
                            <div class="address-card__text">
                                <h4 class="address-card__city expansion__heading-text">
                                    {{ $t(`settings.${group}.value`) }}
                                </h4>
                            </div>
                        </div>
                    </template>
                    <template v-slot:header-right>
                        <Button
                            type="icon"
                            class="expansion__delete"
                            icon="delete"
                            @click.native.stop="deleteSystem(form)"
                        >
                        </Button>
                    </template>
                    <template v-slot:default>
                        <div class="form payment__form">
                            <template v-for="(item, key) in form.value.items">
                                <form-item
                                    class="form__input"
                                    v-if="
                                        key !== 'enabled' &&
                                            key !== 'isActive' &&
                                            key !== 'isAvailable' &&
                                            key !== 'allowedLocations' &&
                                            key !== 'methodName'
                                    "
                                    v-bind="item.props"
                                    :key="key"
                                >
                                    <component
                                        v-if="key !== 'vatCode'"
                                        :key="group + '-' + key"
                                        :group="group"
                                        :is="item.component"
                                        v-model="item.value"
                                        v-bind="item.props || {}"
                                        :name="key"
                                        @update-field="onUpdateFieldValue"
                                    />
                                    <component
                                        v-else-if="key === 'vatCode'"
                                        :key="group + '--' + key"
                                        :group="group"
                                        :is="item.component"
                                        v-model.number="item.value"
                                        v-bind="item.props || {}"
                                        :name="key"
                                        @update-field="onUpdateFieldValue"
                                    />
                                </form-item>
                            </template>
                        </div>
                        <alert class="network-genius-alert" type="primary" v-if="group === 'paymentNetworkGenius'"
                            ><a @click="showInstructions" class="alert__link">{{
                                $t('entities.payment.tooltips.networkGenius.link')
                            }}</a></alert
                        >
                        <div class="divider"></div>
                        <div class="form second-form">
                            <div class="form-item">
                                <div class="form-item__label">{{ $t('entities.payment.countries.availableIn') }}</div>
                                <payment-countries-input
                                    :system-name="group"
                                    :allowed-locations="form.value.items.allowedLocations.value"
                                    @open-modal="openCountriesModal"
                                ></payment-countries-input>
                            </div>
                            <form-item class="method-name" v-bind="form.value.items.methodName.props" key="methodName">
                                <input-text
                                    :group="group"
                                    v-model="form.value.items.methodName.value"
                                    v-bind="form.value.items.methodName.props || {}"
                                    name="methodName"
                                    @update-field="onUpdateFieldValue"
                                />
                            </form-item>
                        </div>
                    </template>
                </expansion>
            </template>
        </template>
    </div>
</template>

<script>
import { cloneDeep, isEqual, debounce, merge } from 'lodash';
import PaymentsService from '@/services/PaymentsService';
import SettingService from '@/services/SettingService';
import FormItem from '@/components/form/item';
import Expansion from '@/components/common/Expansion';
import Form from '@/components/form/Form';
import StickyToolbar from '@/components/common/StickyToolbar';
import SettingItem from '@/entities/setting/SettingItem';
import PaymentAddItemModal from '@/components/payment/PaymentAddItemModal';
import InputSwitch from '@/components/form/controls/InputSwitch';
import EventEmitter from '@/helpers/eventEmitter.ts';
import i18n from '@/plugins/i18n';
import Button from '@/components/common/Button';
import Alert from '@/components/common/Alert';
import ProgressCircular from '@/components/common/ProgressCircular';
import PaymentCountriesModal from '@/components/payment/PaymentCountriesModal';
import PaymentCountriesInput from '@/components/payment/PaymentCountriesInput';
import InputText from '@/components/form/controls/InputText';
import ShippingZone from '@/entities/delivery/ShippingZone';

export default {
    name: 'Payment',

    defaultFieldProps: {
        dense: true,
        'hide-details': true,
        outlined: true,
    },

    components: {
        InputText,
        PaymentCountriesInput,
        PaymentCountriesModal,
        ProgressCircular,
        Alert,
        InputSwitch,
        PaymentAddItemModal,
        Expansion,
        FormItem,
        StickyToolbar,
        Button,
    },

    async created() {
        this.isDataLoaded = false;

        // получаем список методов онлайн оплаты
        const [error, paymentMethodsOnline] = await PaymentsService.getImplimentedMethod();
        if (error) error.notify();
        this.paymentMethodsOnline = paymentMethodsOnline;
        // формируем общий список методов оплаты
        // запрашиваем сохраненые конфиги для списка метода оплат
        const [settingsError, paymentConfigs] = await this.getSettings(paymentMethodsOnline);
        if (settingsError) {
            settingsError.notify();
        }
        // запрашиваем settings (схему полей) для платежных систем
        const paymentSettings = await PaymentsService.getPaymentsSettings(paymentMethodsOnline);

        // сравниваем поля в полученных конфигах с settings(схемами), проверяя наличие нужных полей
        paymentMethodsOnline.forEach((method) => {
            const result = PaymentsService.comparePaymentsShema(paymentConfigs[method], paymentSettings[method]);
            result.newFields.forEach((field, index) => {
                // вывести предупреждение, если список полей изменился
                console.log('newFields', method, field.name, result.newFields[index]);
                // добавляем новые поля
                if (paymentConfigs[method][field.name] === undefined) {
                    paymentConfigs[method][field.name] = new SettingItem({
                        type: field.type,
                        value: (() => {
                            if (field.name === 'language') return 'en';
                            if (field.name === 'vatCode') return 1;
                            if (field.type === 'bool') return false;
                            if (field.type === 'array') return [];
                            return null;
                        })(),
                    });
                }
            });
        });

        // сохраняем получившиеся конфиги
        this.settings = merge(this.settings, paymentConfigs);

        // создаем форму для редактирования конфигов платежных систем
        const formData = {
            action: this.formAction,
            validateHandler: this.validateHandler,
            successHandler: this.successHandler,
            failedHandler: this.failedHandler,
            items: {},
        };
        const mapComponent = {
            string: 'InputText',
            number: 'InputText',
            int: 'InputText',
            integer: 'InputText',
            boolean: 'InputSwitch',
            bool: 'InputSwitch',
        };
        paymentMethodsOnline.forEach((item) => {
            formData.items[item] = {
                type: 'form',
                value: new Form({
                    items: Object.keys(paymentConfigs[item]).reduce((acc, prop) => {
                        acc[prop] = {
                            component: mapComponent[paymentConfigs[item][prop].type],
                            type: 'field',
                            value: paymentConfigs[item][prop].value,
                            props: {
                                labelTrans: `settings.${item}.${prop}`,
                            },
                        };
                        return acc;
                    }, {}),
                }),
            };
        });
        this.forms = new Form(formData);
        this.initialData = cloneDeep(this.forms);
        this.checkEnabledSystems();

        // формируем список платежных систем для добавления

        this.isDataLoaded = true;
    },

    data() {
        return {
            isDataLoaded: false,
            settings: {},
            initialData: {},
            forms: {},
            panels: [],
            isUpdated: false,
            isAddModelOpen: false,
            isOnlyCashOnDelivery: false,
            isCountriesModalOpen: false,
            editingPayment: { name: '', zones: [] },
            paymentMethodsOnline: [],
        };
    },

    methods: {
        async getSettings(systems) {
            return await SettingService.getAll({
                groups: systems,
            });
        },

        //на фронте не валидируем
        validateForm() {
            return function() {
                return true;
            };
        },

        handleEnabled(formName) {
            //очищаем ошибки при изменении enabled
            this.forms.items[formName].value.validate();
            this.checkEnabledSystems();
        },

        onUpdateZones(val) {
            this.forms.items[this.editingPayment.name].value.items.allowedLocations.value = val;
        },

        showInstructions() {
            EventEmitter.trigger('toggle-tooltips', {
                isShown: true,
                tooltips: ['network-genius-payment'],
                config: {
                    width: 1000,
                },
            });
        },

        openCountriesModal(name) {
            this.editingPayment.name = name;
            this.editingPayment.zones = this.forms.items[name].value.items.allowedLocations.value;
            this.isCountriesModalOpen = true;
        },

        checkEnabledSystems() {
            if (Object.keys(this.forms.items).length === 1) {
                this.isOnlyCashOnDelivery = true;
                this.forms.items.paymentCashOnDelivery.value.items.enabled.value = true;
                this.forms.items.paymentCashOnDelivery.value.items.allowedLocations.value = [];
                return;
            }
            const systemsStatuses = [];
            for (const key in this.forms.items) {
                if (key === 'paymentCashOnDelivery') continue;
                systemsStatuses.push(
                    this.forms.items[key].value.items.enabled.value && this.forms.items[key].value.items.isActive.value
                );
            }
            const isSomeSystemActive = systemsStatuses.some((item) => item);
            this.isOnlyCashOnDelivery = !isSomeSystemActive;
            if (!isSomeSystemActive) {
                this.forms.items.paymentCashOnDelivery.value.items.enabled.value = true;
                this.forms.items.paymentCashOnDelivery.value.items.allowedLocations.value = [];
            }
        },

        deleteSystem(form) {
            form.value.items.isActive.value = false;
            form.value.items.enabled.value = false;
            this.checkEnabledSystems();
        },

        onSubmit() {
            this.forms.submit();
        },

        validateHandler() {
            this.$forceUpdate();
        },

        async formAction(formData) {
            const settings = {};
            const groupsAll = Object.keys(this.forms.items);
            groupsAll.forEach((key) => {
                settings[key] = {};
                Object.keys(formData[key]).forEach((item, index) => {
                    const value = formData[key][item];
                    settings[key][item] = new SettingItem({
                        value,
                        position: index,
                        type: (typeof value).toLowerCase(),
                    });
                });
            });
            return SettingService.updateBatch(groupsAll, settings);
        },

        async successHandler() {
            const [error, settings] = await this.getSettings();
            if (error) {
                error.notify();
            }
            this.settings = settings;
            this.initialData = cloneDeep(this.forms);
            this.isUpdated = true;
            window.scrollTo({ top: 0, behavior: 'smooth' });
        },

        onUpdateFieldValue: debounce(function(payload) {
            const { name, group } = payload;
            this.forms.validateField(name, group);
        }, 600),

        failedHandler() {
            this.isUpdated = false;
            EventEmitter.trigger('show-noty', {
                type: 'error',
                text: i18n.t('notifications.validation.error'),
            });
        },

        addSystem(system) {
            this.forms.items[system].value.items.isActive.value = true;
            if (!this.forms.items[system].value.items.allowedLocations.value.length) {
                this.forms.items[system].value.items.allowedLocations.value = ShippingZone.getAllCodes();
            }
            this.isAddModelOpen = false;
        },
    },
    computed: {
        saveAllowed() {
            return !isEqual(this.forms, this.initialData);
        },
        availableSystems() {
            return this.paymentMethodsOnline.reduce((accumulator, currentValue) => {
                const paymentSystem = this.forms.items[currentValue].value.items;
                if (!paymentSystem.isActive.value && currentValue !== 'paymentCashOnDelivery')
                    accumulator.push({
                        text: this.$t(`entities.payment.systems.${currentValue}`),
                        value: currentValue,
                    });
                return accumulator;
            }, []);
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.payment-view {
    .page-header {
        margin-top: -45px;
    }
    .divider {
        margin: 24px 0;
    }
    .payment__form {
        display: grid;
        gap: 24px;
        grid-template-columns: 1fr 1fr 1fr;

        .form__input .switch {
            margin: 5px 0;
        }
    }

    .second-form {
        display: flex;
        flex-direction: column !important;
    }

    .network-genius-alert {
        margin-top: 16px;
    }
    .method-name {
        width: 30%;
    }
    .cash-on-delivery {
        background-color: #ffffff;
        margin-bottom: 32px;
        &__info {
            display: flex;
            align-items: center;
            border-radius: 4px;
            padding: 24px;
            min-height: 96px;
        }
        &__countries {
            padding: 0 24px 32px 24px;
        }
        .divider {
            margin-top: 0;
        }
    }
    .payment-countries-input {
        margin-top: 12px;
    }
}
</style>
