<template>
    <div class="tree-node" :style="nodeMargin">
        <div class="tree-node__item">
            <input-checkbox
                v-if="!node.isNode"
                @input="$emit('toggle-select', { node, value: !node.isSelected })"
                class="tree-node__checkbox"
                :value="node.isSelected"
            ></input-checkbox>
            <input-checkbox
                v-else
                multiple
                @input="$emit('toggle-select', { node, value: !areAllChildrenSelected })"
                class="tree-node__checkbox"
                :items="checkBoxItems"
                :value="selectedChildren"
            ></input-checkbox>
            <img v-if="node.hasFlag" class="tree__img" :src="getFlag(node.code)" :alt="node.code" />
            {{ node.name }}
            <span
                v-if="hasChildren"
                :class="{ '-is-open': showChildren }"
                @click="toggleChildren"
                @keypress="toggleChildren"
                class="material-icons-outlined tree-node__toggle-icon"
                >keyboard_arrow_down</span
            >
        </div>
        <div v-if="hasChildren" v-show="showChildren">
            <ZonesTreeNode
                v-for="child in node.children"
                @toggle-select="$emit('toggle-select', $event)"
                :key="child.id"
                :node="child"
                :spacing="spacing + 10"
            />
        </div>
    </div>
</template>

<script>
import InputCheckbox from '@/components/form/controls/InputCheckbox';
import Tree from '@/components/delivery/Tree';
export default {
    name: 'ZonesTreeNode',
    components: { InputCheckbox },
    props: {
        node: {
            type: Object,
            required: true,
        },
        spacing: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            showChildren: false,
        };
    },
    computed: {
        nodeMargin() {
            return {
                'margin-left': `${this.spacing}px`,
            };
        },
        areAllChildrenSelected() {
            return this.selectedChildren.length === this.checkBoxItems.length;
        },
        hasChildren() {
            const { children } = this.node;
            return children && children.length > 0;
        },
        selectedChildren() {
            return Tree.getFlatNodeByCondition(this.node, 'isSelected', true);
        },
        checkBoxItems() {
            return Tree.getFlatNode(this.node);
        },
    },
    methods: {
        toggleChildren() {
            this.showChildren = !this.showChildren;
        },
        getFlag(code) {
            return require(`@/assets/images/flags/${code.toLowerCase()}.svg`);
        },
    },
};
</script>

<style lang="scss">
.tree-node {
    &__item {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 12px;
        min-height: 48px;
    }
    &__toggle-icon {
        margin-left: auto;
        margin-right: 8px;
        cursor: pointer;
        transition: all 0.2s ease;
        &.-is-open {
            transform: rotate(180deg);
            transition: all 0.2s ease;
        }
    }
    &__checkbox {
        .checkbox__icon {
            font-size: 22px;
        }
    }
}
</style>
