<template>
    <sidebar-right :heading="$t('entities.settings')">
        <form class="sidebar__form">
            <div>
                <label class="form-item__label switches-label"> {{ $t('entities.product.fields.pageStatus') }}</label>
                <form-item class="sidebar__switch">
                    <input-switch
                        :text="'builder.pageStatus.enabled'"
                        size="small"
                        name="enabled"
                        @input="updateForm"
                        @update-field="$emit('update-field', $event)"
                        v-model="currentState.items.enabled.value"
                    />
                </form-item>
                <form-item class="sidebar__switch">
                    <input-switch
                        :text="'builder.pageStatus.hidden'"
                        size="small"
                        @input="updateForm"
                        @update-field="$emit('update-field', $event)"
                        name="hidden"
                        v-model="currentState.items.hidden.value"
                    />
                </form-item>
                <form-item class="sidebar__switch">
                    <input-switch
                        :text="'builder.pageStatus.noIndex'"
                        size="small"
                        @input="updateForm"
                        @update-field="$emit('update-field', $event)"
                        name="noIndex"
                        v-model="currentState.items.noIndex.value"
                    />
                </form-item>
            </div>
        </form>
    </sidebar-right>
</template>

<script>
import SidebarRight from '@/components/common/SidebarRight';
import { cloneDeep, isEqual, debounce } from 'lodash';
import FormItem from '@/components/form/item';
import InputSwitch from '@/components/form/controls/InputSwitch';

export default {
    name: 'ProductItemSidebar',
    components: { InputSwitch, FormItem, SidebarRight },
    props: {
        name: {
            type: String,
        },
        form: {
            type: Object,
        },
    },
    created() {
        this.currentState = cloneDeep(this.form);
    },
    data() {
        return {
            renderComponent: true,
            currentState: {},
        };
    },
    methods: {
        onUpdateFieldValue: debounce(function(payload) {
            const { name, group, value } = payload;
            this.form.validateField(name, group);
            this.updateCurrentsField({ name, group, value });
            this.updateForm();
        }, 300),
        updateForm() {
            this.$emit('change', this.currentState);
        },
    },

    watch: {
        form: {
            handler(val) {
                if (!isEqual(val, this.currentState)) {
                    this.currentState = cloneDeep(val);
                }
            },
            deep: true,
        },
    },
};
</script>

<style lang="scss">
.sidebar {
    z-index: 6;
    &__form {
        padding: 16px;
    }
    &__switch {
        margin-bottom: 15px;
    }
    .switches-label {
        margin-bottom: 15px;
    }
}
</style>
