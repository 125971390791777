<template>
    <div class="options-form">
        <div class="options-form__row" v-for="(option, index) in productOptions" :key="option.id">
            <form-item class="options-form__item" :label="$t('entities.option.fields.position')">
                <input-text @input="onUpdatePosition(option)" v-model="option.entry.position"> </input-text>
            </form-item>

            <form-item class="options-form__item" :label="$t('entities.option.fields.title')">
                <combobox
                    :value="option.option.id"
                    :items="options"
                    :items-to-ignore="selectedOptions"
                    item-text="title"
                    name="name"
                    item-value="id"
                    @input="onChangeOption($event, index)"
                />
            </form-item>

            <form-item
                class="options-form__item"
                :errors="valuesErrors[option.id]"
                :label="$t('entities.option.fields.values')"
            >
                <combobox-multiple
                    name="values"
                    :items="productOptionsValuesItems[option.id]"
                    :item-text="(item) => item.optionValue.title"
                    :value="option.values"
                    :disabled="!option.option.title"
                    :errors="valuesErrors[option.id]"
                    :itemsType="option.option.type"
                    @updateTypeErrors="handleValuesType($event, option.id)"
                    @input="onChangeOptionValue($event, option)"
                    item-value="id"
                />
            </form-item>

            <Button class="options-form__item" @click="onRemoveProductOption(index)" type="icon" icon="delete"></Button>
        </div>

        <Button class="options-form__add-btn" icon="add_circle_outline" @click="onClickAddNewOption">{{
            $t('lists.addButton.option')
        }}</Button>
    </div>
</template>

<script>
import Button from '@/components/common/Button';
import { mapActions, mapGetters } from 'vuex';
import { cloneDeep, uniqBy } from 'lodash';
import ProductOption from '@/entities/product/ProductOption';
import ProductOptionValue from '@/entities/product/ProductOptionValue';
import ProductOptionEntry from '@/entities/product/ProductOptionEntry';
import Option from '@/entities/option/Option';
import { handleOptionValues } from '@/entities/product/ProductOptionHelper';
import { changePosition } from '@/helpers/utils';
import Combobox from '@/components/form/controls/Combobox';
import ComboboxMultiple from '@/components/form/controls/ComboboxMultiple';
import InputText from '@/components/form/controls/InputText';
import FormItem from '@/components/form/item';

export default {
    name: 'ProductOptionsList',
    components: {
        FormItem,
        InputText,
        ComboboxMultiple,
        Combobox,
        Button,
    },
    created() {
        this.optionsEnabled = this.value.length;
        this.productOptions = this.value.map((entry) => {
            const relatedOption = this.options.find((option) => {
                return option.id === entry.optionId;
            });
            return new ProductOption({ entry: cloneDeep(entry), option: cloneDeep(relatedOption) });
        });
    },
    props: {
        value: {
            type: Array,
            required: true,
        },
        name: {
            type: String,
        },
        errors: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            productOptions: null,
            optionsEnabled: null,
            valuesErrors: {},
        };
    },
    computed: {
        ...mapGetters('product', {
            options: 'getOptionsList',
            product: 'getCurrent',
        }),
        selectedOptions() {
            return this.options.filter((option) => {
                return this.productOptions.find((productOption) => productOption.option.id === option.id);
            });
        },
        productOptionsValuesItems() {
            const items = this.productOptions.map((option) => {
                const newValues = option.option.values.map((value) => new ProductOptionValue({ optionValue: value }));
                return [option.id, uniqBy([...option.values, ...newValues], 'optionValue.title')];
            });
            return Object.fromEntries(items);
        },
    },
    methods: {
        ...mapActions('product', {
            updateOptionsToUpdate: 'updateOptionsToUpdate',
        }),
        onClickAddNewOption() {
            const entry = new ProductOptionEntry();
            const option = new Option();
            entry.position = this.productOptions.length;
            this.productOptions.push(
                new ProductOption({
                    entry,
                    option,
                })
            );
        },
        handleValuesType(items, optionId) {
            if (items.length) {
                this.$set(this.valuesErrors, optionId, [this.$t('validator.errors.optionValuesTypeNumber')]);
            } else {
                this.$delete(this.valuesErrors, optionId);
            }
        },
        onChangeOption(value, index) {
            if (!value) return;
            const newOption = typeof value === 'object' ? cloneDeep(value) : new Option({ title: value });
            this.productOptions[index].option = newOption;
            this.productOptions[index].values = [];
        },
        onChangeOptionValue(values, option) {
            //проверяет существует ли ли уже выбраное значение и если нет, то создаёт новое
            let optionValues = handleOptionValues(values, option, this.productOptionsValuesItems);
            optionValues = uniqBy(optionValues, 'optionValue.title');
            optionValues.forEach((item, index) => (item.entryValue.position = index));
            option.values = optionValues;
            option.entry.values = optionValues.map((value) => value.entryValue);
            const initialOption = this.options.find((item) => {
                return item.id === option.option.id;
            });
            //добавляем только новые значения для сохранения
            //если существующая опция
            if (initialOption) {
                const newOptionValues = optionValues
                    .filter((item) => {
                        return !initialOption.values.find((value) => {
                            return value.id === item.optionValue.id;
                        });
                    })
                    .map((item) => item.optionValue);
                if (newOptionValues.length) {
                    option.option.values = [...initialOption.values, ...newOptionValues];
                }
            }
            //Если новая опция
            else {
                option.option.values = optionValues.map((item) => item.optionValue);
            }
            if (!optionValues.length) {
                this.onRemoveProductOption(this.productOptions.findIndex((item) => item.optionId === option.id));
            }
        },
        onRemoveProductOption(index) {
            this.productOptions.splice(index, 1);
        },

        saveOptions() {
            this.updateOptionsToUpdate(this.productOptions.map(({ option }) => cloneDeep(option).data));
            const optionEntries = this.productOptions.map(({ entry }) => entry);
            this.$emit('input', cloneDeep(optionEntries));
            this.$emit('update-field', {
                name: this.name,
                value: cloneDeep(optionEntries),
            });
            this.$emit('update-variations', this.productOptions);
        },

        onUpdatePosition(option) {
            changePosition(option, this.productOptions, this.productOptions, 'entry.position');
        },
    },
    watch: {
        productOptions: {
            deep: true,
            handler(newProductOptions, oldProductOptions) {
                if (!oldProductOptions) return; // Если это первое изменение(например загрузка страницы), то ничего делать не надо
                this.saveOptions();
            },
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.options-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    &__row {
        display: flex;
        align-items: start;
        gap: 20px;
        width: 100%;
    }
    &__item {
        width: 100%;
        &:first-child {
            width: 10%;
        }
        &:last-child {
            max-width: 10%;
            margin-top: 29px;
        }
        &:nth-child(2) {
            width: 30%;
        }
    }
    &__add-btn {
        min-width: 64px;
        width: fit-content;
    }
}
</style>
