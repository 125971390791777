<template>
    <div class="page order__page">
        <progress-circular class="spinner -absolute" v-if="isLoading && isAvailable"></progress-circular>
        <template v-if="!isAvailable">
            <div class="page-header">
                <h1 class="page-header__text">{{ $tc('entities.order.title', 1) }}</h1>
            </div>
            <error-block></error-block>
        </template>
        <template v-else-if="!isLoading">
            <div class="page-header">
                <h1 class="page-header__text">{{ $tc('entities.order.title', 1) }} {{ order.number }}</h1>
            </div>
            <order-item-sidebar v-model="order"></order-item-sidebar>
            <div class="order__table order__product-search">
                <h2>{{ $t('entities.order.addProduct') }}</h2>
                <order-products-search @add-items="onAddNewOrderItem"></order-products-search>
            </div>
            <order-items-list
                @update-items="onUpdateItems"
                @remove-items="onRemoveItems"
                :errors="itemsErrors"
                :products="products"
                :items="order.items"
            ></order-items-list>
            <div
                class="block__body order__errors-block"
                ref="errors-block"
                v-show="showErrors && Object.keys(this.errors).length"
            >
                <alert type="error" class="entity-item__success-alert -break-line"
                    >{{ $t('entities.order.errors.header') }}
                    <div v-for="(block, key) in errors" :key="key">
                        <div class="errors-list">
                            <h4>
                                {{
                                    key === 'delivery'
                                        ? $t('entities.order.errors.block', {
                                              block: $t(`entities.order.${key}`),
                                          })
                                        : $t('entities.order.errors.rightBlock', {
                                              block: $t(`entities.order.${key}`),
                                          })
                                }}
                            </h4>
                            <ul>
                                <li v-for="error in block" :key="error">
                                    {{
                                        key === 'delivery'
                                            ? $t(`entities.order.deliveryAddress.fields.${error}`)
                                            : $t(`entities.order.fields.${error}`)
                                    }}
                                </li>
                            </ul>
                        </div>
                    </div>
                </alert>
            </div>
            <delivery-form
                v-if="order.items.length && !isServiceCart"
                v-model="order.deliveryInformation"
                :subtotal="orderSubtotal"
                :weight="orderWeight"
            ></delivery-form>
            <div class="order__footer">
                <div class="order__prices">
                    <div>
                        {{ $t('entities.order.weight') }}: {{ orderWeight }} {{ $t('entities.product.weight.metric') }}
                    </div>
                    <div>{{ $t('entities.order.subtotal') }}: {{ currency }} {{ orderSubtotal }}</div>
                    <div>{{ $t('entities.order.delivery') }}: {{ currency }} {{ deliveryPrice }}</div>

                    <div class="order__total">
                        {{ $t('entities.order.total') }}:
                        <span class="total__price">
                            {{ currency }} {{ (orderSubtotal + deliveryPrice).toFixed(2) }}
                        </span>
                    </div>
                </div>
                <Button :disabled="!saveAllowed" icon="check" @click="orderId ? onUpdateOrder() : onCreateOrder()">
                    {{ $t('entities.save') }}
                </Button>
            </div>
        </template>
    </div>
</template>

<script>
import Order from '@/entities/order/Order';
import ProductService from '@/services/ProductService';
import OrderService from '@/services/OrderService';
import { isEqual, debounce, cloneDeep } from 'lodash';
import OrderProductsSearch from '@/components/orders/OrderProductsSearch';
import OrderItem from '@/entities/order/OrderItem';
import OrderItemsList from '@/components/orders/OrderItemsList';
import DeliveryForm from '@/components/orders/DeliveryForm';
import ErrorBlock from '@/components/common/ErrorBlock';
import OrderItemOption from '@/entities/order/OrderItemOption';
import OrderItemSidebar from '@/components/orders/OrderItemSidebar';
import EventEmitter from '@/helpers/eventEmitter.ts';
import Product from '@/entities/product/Product';
import Button from '@/components/common/Button';
import ProgressCircular from '@/components/common/ProgressCircular';
import Alert from '@/components/common/Alert';
import { mapGetters } from 'vuex';

export default {
    name: 'OrderItem',

    components: {
        OrderItemSidebar,
        DeliveryForm,
        OrderItemsList,
        OrderProductsSearch,
        ErrorBlock,
        Button,
        Alert,
        ProgressCircular,
    },

    async created() {
        this.isLoading = true;
        if (this.orderId) {
            await this.getOrder();
            await this.getProducts();
        }
        this.initialValue = cloneDeep(this.order);
        this.isLoading = false;
    },
    data() {
        return {
            isValid: false,
            products: [],
            order: new Order(),
            isLoading: true,
            initialValue: {},
            isAvailable: true,
            form: null,
            fieldKeys: Object.keys(new Order().fields),
            itemsErrors: [],
            showErrors: false,
        };
    },
    computed: {
        ...mapGetters('config', { isServiceCart: 'getServiceCartIsAvalible' }),
        orderId() {
            return this.$route.params.id;
        },

        orderItemProductIds() {
            return this.order.items.map((orderItem) => orderItem.productId);
        },
        orderSubtotal() {
            return this.order.items.reduce((acc, item) => acc + item.getFinalPrice(), 0);
        },
        deliveryPrice() {
            return this.order.items.length ? this.order.deliveryInformation.price : 0;
        },
        saveAllowed() {
            const isShippingSelected =
                this.order.deliveryInformation.type === 'delivery'
                    ? this.order.deliveryInformation.rate
                    : this.order.deliveryInformation.location;
            if (!this.allStockAbalible()) return false;
            if (this.isServiceCart) return this.order.items.length && !isEqual(this.order.data, this.initialValue.data);
            return this.order.items.length && isShippingSelected && !isEqual(this.order.data, this.initialValue.data);
        },
        orderWeight() {
            const result = this.order.items.reduce((acc, item) => {
                const product = this.products.find((product) => product.id === item.productId);
                if (!product) {
                    return acc;
                }
                const weight = Product.getDeliverySizeOrWeight(product, item.variationId);
                return weight ? acc + Number(weight) * item.quantity : acc;
            }, 0);
            return Math.round((result + Number.EPSILON) * 10) / 10;
        },
        currency() {
            return this.$store.getters['config/getCurrency'];
        },
        errors() {
            const deliveryFormErrors = this.getErrors(this.order.deliveryInformation.deliveryAddress.fields);
            const sidebarErrors = this.getErrors(this.order.fields);
            return { ...deliveryFormErrors, ...sidebarErrors };
        },
    },
    methods: {
        getErrors(obj) {
            const map = {
                region: 'delivery',
                city: 'delivery',
                address: 'delivery',
                stateId: 'order',
                name: 'client',
                phone: 'client',
                email: 'client',
            };

            const result = {};
            for (const key in obj) {
                if (obj[key]?.props?.errors?.length) {
                    const blockName = map[key];
                    result[blockName] ? result[blockName].push(key) : (result[blockName] = [key]);
                }
            }
            return result;
        },
        async getOrder() {
            const [error, result] = await OrderService.getOne(this.orderId);
            if (error) {
                this.isAvailable = false;
                return;
            }
            this.order = new Order(result);
        },
        async onUpdateOrder() {
            const isFormValid = this.order.validateEntity();
            if (Object.keys(this.errors).length) {
                this.showErrors = true;
                this.$nextTick(() => this.scrollToErrors());
            }
            if (isFormValid === false) return;
            const [error, result] = await OrderService.updateOne(this.order.data);
            error ? this.failedHandler(result, error) : this.successHandler();
        },
        async onCreateOrder() {
            const isFormValid = this.order.validateEntity();
            if (Object.keys(this.errors).length) {
                this.showErrors = true;
                this.$nextTick(() => this.scrollToErrors());
            }
            if (isFormValid === false) return;
            const [error, result] = await OrderService.createOne(this.order.data);
            error ? this.failedHandler(result, error) : this.successHandler();
        },

        getFieldEvents(events) {
            const fieldEvents = {};
            for (const key in events) {
                fieldEvents[key] = events[key]?.bind(this.order);
            }
            return fieldEvents;
        },

        async getProducts() {
            const [error, result] = await ProductService.getAll({
                ids: this.orderItemProductIds,
            });
            if (error) {
                this.isAvailable = false;
                return;
            }
            this.products = result.products.map((item) => new Product(item));
        },

        onRemoveItems(ids) {
            const productsIds = this.order.items.filter((item) => ids.includes(item.id)).map((item) => item.productId);
            this.order.items = this.order.items.filter((item) => !ids.includes(item.id));
            this.products = this.products.filter((item) => !productsIds.includes(item.id));
        },

        scrollToErrors() {
            const offset = this.$refs['errors-block'].getBoundingClientRect().top - 70 + window.pageYOffset;
            window.scrollTo({ behavior: 'smooth', top: offset });
        },

        checkProductsByOrderItem(products, item) {
            let hasVariationId = false;
            products.forEach((newProduct) => {
                hasVariationId = newProduct.variations.find((variation) => {
                    return variation.default && variation.id === item.variationId;
                });
            });
            return Boolean(hasVariationId);
        },
        getOrderItemsByProducts(products) {
            return this.order.items.filter((item) => this.checkProductsByOrderItem(products, item));
        },
        checkStockProductsByOrderItem(item) {
            let hasVariationId = false;
            this.products.forEach((product) => {
                product.variations.find((variation) => {
                    if (variation.id === item.variationId) {
                        return (hasVariationId = item.quantity <= variation.stock + 1);
                    }
                });
            });
            return hasVariationId;
        },
        allStockAbalible() {
            return this.order.items.every((item) => this.checkStockProductsByOrderItem(item));
        },
        async onAddNewOrderItem(products) {
            if (!products.length) return;
            const orderItems = this.getOrderItemsByProducts(products);
            if (orderItems.length) return orderItems.forEach((item) => item.quantity++);
            this.products.push(...products);
            //TODO: уменьшить вложенность, если возможно
            products.forEach((product) => {
                const defaultVariation = product.variations.find((item) => item.default);
                const options = [];
                defaultVariation.productOptionValueIds.forEach((defValue) => {
                    let optionValue;
                    const option = product.options.find((option) => {
                        optionValue = option.values.find((value) => {
                            return value.id === defValue;
                        });
                        return optionValue;
                    });
                    options.push(
                        new OrderItemOption({
                            title: option.title,
                            value: optionValue.title,
                            unit: option.unit,
                        })
                    );
                });
                this.order.addItem(
                    new OrderItem({
                        title: product.title,
                        variationId: defaultVariation.id,
                        productId: product.id,
                        fullPrice: defaultVariation.fullPrice,
                        discountPrice: defaultVariation.discountPrice,
                        quantity: 1,
                        imageId: defaultVariation.imageId,
                        options: options,
                    })
                );
            });
        },

        successHandler() {
            if (!this.orderId) {
                EventEmitter.trigger('show-noty', {
                    type: 'success',
                    text: this.$tc('notifications.created', 1, { entity: this.$tc('entities.order.title', 1) }),
                });
                this.$router.push({
                    name: 'Edit Order',
                    params: { id: this.order.id },
                });
            } else {
                EventEmitter.trigger('show-noty', {
                    type: 'success',
                    text: this.$tc('notifications.updated', 1, { entity: this.$tc('entities.order.title', 1) }),
                });
                this.initialValue = cloneDeep(this.order);
            }
            window.scrollTo({ top: 0, behavior: 'smooth' });
        },

        failedHandler(response, error) {
            error.notify();
            const children = response.data?.errors?.children;
            if (children) {
                this.fieldKeys.forEach((key) => {
                    const errors = children[key] ? children[key].errors : [];
                    if (errors) this.$set(this.order.fields[key].props, 'errors', errors);
                });
                //костыль для прокидывания ошибок variation is out of stock
                this.itemsErrors = children.items.children.map((item) => {
                    return item.children.variationId?.errors || item.children.quantity?.errors
                        ? 'validator.errors.orderQuantity'
                        : null;
                });
            }
        },

        onUpdateFieldValue: debounce(function(payload) {
            const { name } = payload;
            this.currentState.validateField(name);
        }, 600),

        onUpdateItems(items) {
            this.order.items = cloneDeep(items);
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.sidebar {
    position: fixed;
    margin-top: 62px;
    gap: 16px;
    z-index: 4;
    &__header {
        font-size: 19px;
        font-weight: 500;
        padding: 22px 16px;
    }
    &__tabs {
        margin-top: 16px;
    }
    &__tab-content {
        padding: 16px;
    }
    &__form {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }
}
.tabs {
    &__tab {
        padding: 11px 40px 13px 40px;
        font-size: 16px;
        font-weight: 400;
        text-transform: initial;

        &.-has-errors {
            color: var(--v-warning-base) !important;
        }
    }
}

.order {
    &__page {
        padding-right: 332px;
        position: relative;
    }
    &__table {
        padding: 32px 24px;
        .v-card__text {
            padding-right: 0 !important;
            padding-left: 0 !important;
        }
    }
    &__product-search {
        background-color: var(--v-surface-base);
        margin-bottom: 24px;
    }
    &__delivery {
        margin-top: 24px;
    }
    &__errors-block {
        margin-top: 24px;
        .alert {
            margin-bottom: 0;
        }
        .errors-list {
            h4 {
                color: var(--v-warning-base);
                margin-top: 12px;
                font-size: 16px;
            }
            ul {
                margin-top: 0;
                padding-left: 20px;
                li {
                    margin-bottom: 4px;
                }
            }
        }
    }
    &__prices {
        display: flex;
        font-weight: 500;
        flex-basis: 70%;
        gap: 10px;
        justify-content: space-between;
        align-items: center;
        .order__total {
            font-size: 20px;
            .total__price {
                color: var(--v-primary-base);
            }
        }
    }

    &__footer {
        background: var(--v-primary-lighten-base);
        width: calc(100% - 664px);
        box-sizing: content-box;
        margin-left: -32px;
        justify-content: space-between;
        align-items: center;
        padding: 16px 32px;
        position: fixed;
        bottom: 58px;
        display: flex;
        z-index: 6;
    }
}
</style>
