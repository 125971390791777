<template>
    <div class="link-switcher">
        <heading type="body-2" v-if="label">{{ label }}</heading>
        <ui-nested-menu
            v-model="isOpen"
            :items="currentItems"
            :title="currentItem.title"
            max-height="306"
            @select-item="selectItem"
            @go-back="goBack"
        >
            <template #activator="{ attrs, on }">
                <Search-form
                    v-model="search"
                    class="menu__search"
                    v-bind="attrs"
                    v-on="on"
                    @update-search-results="updateSearchResults"
                    @update-search-string="updateSearchString"
                />
            </template>

            <template #item.title.prepend="{ item }">
                <span v-if="item.icon" class="material-icons-outlined">{{ item.icon }}</span>
                <span v-else-if="currentItem.icon" class="material-icons-outlined">{{ currentItem.icon }}</span>
            </template>
            <template #item.title.append="{ item }">
                <span v-if="item.items" class="material-icons-outlined">navigate_next</span>
            </template>
        </ui-nested-menu>
    </div>
</template>

<script>
import CatalogService from '@/services/CatalogService';
import PageService from '@/services/PageService';
import SearchForm from '@/components/search/SearchForm';
import Heading from '@/components/common/Heading';
import { isUrl } from '@/helpers/utils';

export default {
    name: 'CategorySwitcher',
    model: {
        prop: 'value',
        event: 'change-link', // не очевидная вещь, найти где обрабатывается || переписать проще
    },
    components: { SearchForm, Heading },
    pageTypes: {
        textpage: {
            get: PageService.getAll.bind(PageService),
        },
        catalog: {
            get: CatalogService.getAll.bind(CatalogService),
        },
    },
    props: {
        label: {
            type: String,
        },
        value: {
            type: [String, Object],
        },
    },
    data() {
        return {
            isOpen: false,
            config: [
                {
                    id: 'd2ebce61-11d3-4558-9661-1c5287afb8d9',
                    title: '',
                    type: 'link',
                    path: '',
                },
                {
                    id: '00f03bf5-7a33-4354-a475-aedcb3593b9b',
                    type: 'catalog',
                    title: 'Catalogs',
                    items: [],
                    icon: 'shopping_bag|outline',
                    path: '/catalogs',
                },
                {
                    id: '3e9eb3dd-0243-4457-98c6-98459af82d6f',
                    type: 'textpage',
                    title: 'Text pages',
                    items: [],
                    icon: 'article|outline',
                },
            ],
            items: [],
            currentItem: {},
        };
    },

    computed: {
        currentItems() {
            if (this.items.length) return this.items;
            return this.config.filter((item) => item.type !== 'link' || item.path);
        },
        search: {
            get() {
                return typeof this.value === 'string' ? this.value : this.value.title;
            },
            set() {},
        },
    },

    methods: {
        async getData(type) {
            const [error, result] = await this.$options.pageTypes[type]?.get();
            if (error) {
                error.alert();
            }
            this.items = result;
        },
        async selectItem(item) {
            if (!item.items) {
                this.isOpen = false;
                return this.$emit('change-link', this.getItemSlug(item)); // не очевидная вещь, найти где обрабатывается || переписать проще
            }
            await this.getData(item.type);
            this.currentItem = item;
        },
        getItemSlug(item) {
            const path = this.getPathByType(item.type) ? this.getPathByType(item.type) : '';
            const slug = item.slug ? '/' + item.slug : '';
            return path + slug;
        },
        getPathByType(type) {
            const item = this.config.find((item) => item.type === type);
            if (item) return item.path;
        },
        goBack() {
            this.items = [];
            this.currentItem = {};
        },
        updateSearchResults(results) {
            this.items = results;
            this.currentItem = {};
        },
        updateSearchString(searchString) {
            if (!searchString) return [];
            const q = this.transformString(searchString);

            // проверяем является ли ссылкой на сторонний ресурс
            if (isUrl(q)) this.config[0] = { ...this.createLinkByString(q) };

            this.items = this.items.filter((item) => item.title.toLowerCase().includes(q)); // фильтруем по title
            this.items = this.items.filter((item) => item.type !== 'product'); // убираем product
        },
        transformString(str) {
            const newStr = String(str).toLowerCase();

            // если ссылка относительная, ведем поиск по сайту
            if (str.charAt(0) === '/') return newStr.slice(1);
            return newStr;
        },
        createLinkByString(str) {
            return {
                id: 'd2ebce61-11d3-4558-9661-1c5287afb8d9',
                title: str,
                type: 'link',
                path: str,
            };
        },
    },
};
</script>

<style lang="scss"></style>
