<template>
    <div class="customize-media-tabs" :class="{ '-disabled': disabled }">
        <div class="customize-media-tabs__items">
            <template v-for="item in items">
                <div
                    :key="item.name"
                    class="customize-media-tabs__item"
                    @click="switchTab(item)"
                    :class="{ 'is-active': item.isActive }"
                >
                    <span class="material-symbols-outlined">{{ item.icon }}</span>
                </div>
            </template>
        </div>
        <div class="customize-media-tabs__content">
            <template v-for="item in items">
                <div
                    class="customize-media-tabs__content__item"
                    :class="{ 'is-active': item.isActive }"
                    :key="item.name"
                >
                    <CustomizeMedia v-model="item.value" />
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import { proxyModelMixin } from '@/mixins/proxyModelMixin';
export default {
    name: 'CustomizeMediaTabs',
    mixins: [proxyModelMixin],
    props: {
        name: { type: String, default: '' },
        group: { type: String, default: null },
        type: { type: String, default: 'array' },
        errors: { type: [Array, Object], default: () => [] },
        value: { type: [Number, String, Object], default: null },
        screen: { type: [Array], default: () => [] },
        disabled: { type: Boolean, default: false },
    },
    model: {
        prop: 'value',
        event: 'change-value',
    },
    data() {
        return {
            device: {
                desktop: { icon: 'desktop_windows' },
                tablet: { icon: 'tablet' },
                mobile: { icon: 'smartphone' },
            },
            items: null,
        };
    },
    mounted() {
        this.items = this.getItems();
        this.updItems();
        this.items.forEach((item) => {
            this.$watch(() => item.value, this.updItems, { deep: true });
        });
    },
    methods: {
        getAvalibleScreens() {
            const fildtered = this.screen.filter((screenSize) => this.device[screenSize]);
            return fildtered.length ? fildtered : Object.keys(this.device);
        },
        getItems() {
            const avalibleScreens = this.getAvalibleScreens();
            return avalibleScreens.map((screenSize, index) => {
                return {
                    name: screenSize,
                    value: this.value[screenSize] ? this.value[screenSize] : {},
                    icon: this.device[screenSize].icon,
                    isActive: !index ? true : false,
                };
            });
        },
        switchTab(item) {
            this.items.forEach((item) => (item.isActive = false));
            item.isActive = true;
        },
        getValue() {
            const newValue = this.items.map(({ name, value }) => [name, value]);
            return Object.fromEntries(newValue);
        },
        updItems() {
            this.$emit('change-value', this.getValue());
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.customize-media-tabs {
    &.-disabled {
        pointer-events: none;
        filter: grayscale(1);
        opacity: 0.7;
        input,
        textarea {
            color: var(--v-on-surface-disabled) !important;
            pointer-events: none;
            cursor: not-allowed;
        }
    }
    &__items {
        display: flex;
        margin-bottom: 8px;
    }
    &__item {
        cursor: pointer;
        flex: 1;
        height: $form-control-height;
        display: block;
        padding: 0 $form-control-padding-horizontal;
        border: $form-control-border;
        background: $form-control-bg;
        transition: $form-control-transition;
        text-align: center;
        &:first-child {
            border-radius: $form-control-border-radius 0 0 $form-control-border-radius;
            border-right: none;
        }
        &:last-child {
            border-radius: 0 $form-control-border-radius $form-control-border-radius 0;
            border-left: none;
        }
        &.is-active {
            color: $primary;
            background-color: var(--v-primary-lighten-base);
            border-color: var(--v-primary-lighten-base);
        }
        span {
            pointer-events: none;
            line-height: $form-control-height;
        }
        &:hover {
            color: $primary;
        }
    }
    &__content {
        & > * {
            display: none;
        }
        .is-active {
            display: block;
        }
    }
}
</style>
