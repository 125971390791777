<template>
    <modal
        :header="products ? $t('entities.catalog.addRelatedProducts') : $t('entities.catalog.addProducts')"
        width="870px"
        content-class="catalog-products-modal"
        v-model="model"
        eager
    >
        <error-block v-if="!isAvailable"></error-block>
        <Table
            class="catalog-products-modal__table"
            v-else-if="model && initialDataLoaded"
            :search="search"
            :columns="headers"
            v-model="selectedProducts"
            :items="items"
            :is-data-loaded="isDataLoaded"
            :show-select="true"
            multiple-page-selection
            :show-selected-actions="false"
            :pagination="pagination"
            @select-item="handleSelected"
            :show-select-all="false"
            no-delete
            :sort-by="sortBy"
            :sort-desc="sortDesc"
            @update:sort="updateSort"
            @change-per-page="changePerPage"
            @update-search-string="updateSearchString"
        >
            <!--  IMAGE  -->
            <template v-slot:item.image="{ item }">
                <img
                    v-if="hasImage(item)"
                    class="table__image"
                    :alt="images[item.id].alt"
                    :src="imageUrl + images[item.id].uri"
                />
                <img v-else class="table__image -default" alt="No image" src="@/assets/images/no-photo.svg" />
            </template>
            <!--  PRICE  -->
            <template v-slot:item.price="{ item }">
                <span> {{ item.getListPrice(currency) }}</span>
            </template>
            <!--  STOCK  -->
            <template v-slot:item.stock="{ item }">
                <chip v-if="item.variations.every((item) => item.stock === 0)" type="error">
                    {{ $t('entities.product.stock.outOfStock') }}
                </chip>
                <chip v-else-if="item.variations.some((item) => item.stock === 0)" type="warning">
                    {{ $t('entities.product.stock.partially') }}
                </chip>
                <chip v-else type="success">
                    {{ $t('entities.product.stock.inStock') }}
                </chip>
            </template>
            <!-- ENABLED -->
            <template v-slot:item.enabled="{ item }">
                <status
                    :icon="item.enabled ? 'check' : 'close'"
                    :text="
                        item.enabled ? $t('entities.product.fields.enabled') : $t('entities.product.fields.disabled')
                    "
                    :type="item.enabled ? 'success' : 'warning'"
                ></status>
            </template>
            <!--  TITLE  -->
            <template v-slot:item.title="{ item }">
                <span class="table-edit">
                    <router-link
                        target="_blank"
                        class="table-edit__link"
                        :to="{ name: 'Edit Product', params: { id: item.id } }"
                        >{{ item.title }}</router-link
                    >
                    <span class="table-edit__icon material-icons-outlined">edit</span>
                </span>
            </template>
            <!--  ENABLED  -->
            <!--            <template v-slot:item.enabled="{ item }">
                <ui-page-status :value="item" @change="changeProduct(item)"></ui-page-status>
            </template>-->
            <!--  REVIEWS  -->
            <template v-slot:item.reviews="{ item }">
                <Button type="text" :to="{ name: 'Product Review List', query: { product: item.id } }">
                    {{ $t('lists.actions.viewAll') }}({{ item.reviewCount }})
                </Button>
            </template>
        </Table>
        <progress-circular class="spinner -absolute" v-else></progress-circular>
    </modal>
</template>

<script>
import ProductService from '@/services/ProductService';
import { Pagination } from '@/services/PaginationService';
import SearchService from '@/services/SearchService';
import ErrorBlock from '@/components/common/ErrorBlock';
import { imageUrl } from '@/helpers/values';
import Modal from '@/components/common/Modal';
import { proxyModelMixin } from '@/mixins/proxyModelMixin';
import CatalogService from '@/services/CatalogService';
import Status from '@/components/common/Status';
import Table from '@/components/common/Table';
import Product from '@/entities/product/Product';
import Button from '@/components/common/Button';
import ProgressCircular from '@/components/common/ProgressCircular';
import Chip from '@/components/common/Chip';
import { cloneDeep } from 'lodash';

export default {
    name: 'CatalogProductsModal',
    components: {
        Chip,
        Button,
        Modal,
        ErrorBlock,
        Status,
        Table,
        ProgressCircular,
    },
    mixins: [proxyModelMixin],
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        products: {
            type: Array,
            default: () => null,
        },
    },
    async created() {
        this.isDataLoaded = false;
        const total = await this.getTotal();
        const limit = this.$store.getters['globalVars/getCatalogProductsLimit'];
        /*     TODO: при рефакторинге модалок подумать об этом моменте
        Сейчас пришлось добавлять проп eager модалке, чтобы получить к ней доступ*/
        const customContainer = document.querySelector('.modal');
        this.pagination = new Pagination({
            moduleName: 'products',
            limit,
            pageNumber: this.pageNumber,
            total,
            storeModuleName: 'CatalogProducts',
            container: customContainer,
        });
        await this.getItems();
        this.isDataLoaded = true;
    },
    data: () => ({
        isDataLoaded: false,
        items: [],
        images: {},
        isAvailable: true,
        search: { searchable: 'product', q: '' },
        previousSearch: '',
        pagination: null,
        imageUrl: imageUrl(),
        sortBy: 'title',
        sortDesc: false,
        selectedProducts: [],
        initialDataLoaded: false,
    }),
    computed: {
        currency() {
            return this.$store.getters['config/getCurrency'];
        },
        catalogId() {
            return this.$route.params.id;
        },
        headers() {
            const headers = [
                { text: this.$t('lists.columns.image'), value: 'image', sortable: false },
                { text: this.$t('lists.columns.title'), value: 'title' },
                { text: this.$t('lists.columns.status'), value: 'enabled', width: '100px' },
                { text: this.$t('lists.columns.price'), value: 'price', width: 150, sortable: false },
                { text: this.$t('lists.columns.stock'), value: 'stock', sortable: false },
            ];

            return headers;
        },
        pageNumber() {
            return this.$store.getters['globalVars/getCatalogProductsPage'] || 1;
        },
    },
    methods: {
        async getItems() {
            this.isDataLoaded = false;
            const isSearching = !!this.search.q?.length;
            const searchChanged = this.search.q !== this.previousSearch;

            const params = {
                limit: this.pagination.limit,
                offset: searchChanged ? null : this.pagination.offset,
                sort: this.sortBy,
                direction: this.sortDesc ? 'desc' : this.sortDesc === false ? 'asc' : null,
                q: isSearching ? this.search.q : null,
            };
            const getter = isSearching ? this.searchProducts : this.getProducts;
            const [error, result] = await getter(params);
            if (error) {
                this.isAvailable = false;
                return;
            }
            if (searchChanged) {
                this.pagination.pushToHistory(1);
                this.pagination.total = isSearching ? result.count : await this.getTotal();
                this.previousSearch = this.search.q;
            }
            const items = isSearching ? result.data : result.products;
            this.items = items.map((item) => new Product(item));
            if (!this.items) {
                this.isAvailable = false;
                return;
            }
            this.images = await Product.getDefaultImages(this.items);
            this.isDataLoaded = true;
        },
        async getProducts(params) {
            return ProductService.getAll(params);
        },

        handleSelected({ item, value }) {
            if (value) {
                this.$emit('add-item', item);
            } else {
                this.$emit('remove-item', item);
            }
        },

        async getTotal() {
            const [error, total] = await Pagination.getTotal('products');
            if (error) {
                error.notify();
                return 0;
            }
            return total;
        },
        async searchProducts(params) {
            return await SearchService.entitySearch('product', params);
        },
        updateSearchString({ searchString }) {
            this.search.q = searchString;
            if (searchString?.length) this.pagination.reset();
            this.getItems();
        },
        changePerPage(perPage) {
            this.pagination.changeLimit(perPage.value);
            this.perPage = perPage.value;
            this.getItems();
        },

        getDefaultVariation(item) {
            return item.variations.find((item) => item.default);
        },
        hasImage(product) {
            return this.images[product.id]?.uri;
        },

        updateSort(sortBy, sortDesc) {
            this.sortBy = sortBy;
            this.sortDesc = sortDesc;
            this.getItems();
        },
    },
    watch: {
        pageNumber(newValue) {
            this.pagination.pageNumber = newValue;
            this.getItems();
        },
        async model(val) {
            if (val) {
                //получаем продукты, которые уже добавлены в каталог
                if (!this.products) {
                    this.initialDataLoaded = false;

                    const [error, result] = await CatalogService.getProductsInCatalog(this.catalogId);
                    if (error) {
                        error.alert();
                        this.isAvailable = false;
                        return;
                    }
                    this.selectedProducts = result.products;
                    this.initialDataLoaded = true;
                } else {
                    this.selectedProducts = cloneDeep(this.products);
                    this.initialDataLoaded = true;
                }
            }
        },
    },
};
</script>
<style lang="scss">
@import '@/scss/variables.scss';
.catalog-products-modal {
    .modal {
        min-height: 572px;
    }
    .table {
        height: 100%;
        padding: 0 !important;
    }
}
</style>
