<template>
    <modal :header="$t('lists.editButton.product')" width="1300" v-model="model">
        <Table
            v-if="isLoaded"
            :show-toolbar="false"
            :show-selected-actions="false"
            :show-select-all="false"
            class="variations-table"
            :columns="headers"
            :items.sync="localProductVariations"
        >
            <!-- DEFAULT -->
            <template v-slot:item.default="{ item }">
                <input-radio
                    class="variations-table__radio"
                    :value="item.default"
                    @input="changeDefaultVariation(item, $event)"
                >
                </input-radio>
            </template>
            <!-- IMAGE -->
            <template v-slot:item.imageId="{ item }">
                <product-image-field
                    :upload="false"
                    image-type="product"
                    :owner-id="product.id"
                    v-model="item.imageId"
                    is-popup
                />
            </template>
            <!-- TITLE -->
            <template v-slot:item.title="{ item }">
                {{ item.title }}
            </template>
            <!-- FULL PRICE -->
            <template v-slot:item.fullPrice="{ item }">
                <form-item v-bind="item.fields.fullPrice.props">
                    <input-text
                        type="number"
                        :measurement="currency"
                        v-bind="item.fields.fullPrice.props"
                        @update-field="onUpdateFieldValue($event, item)"
                        name="fullPrice"
                        v-model.number="item.fullPrice"
                    ></input-text>
                </form-item>
            </template>
            <!-- DISCOUNT -->
            <template v-slot:item.discount="{ item }">
                <form-item v-bind="item.fields.discount.props">
                    <input-text
                        @update-field="onUpdateFieldValue($event, item)"
                        name="discount"
                        v-bind="item.fields.discount.props"
                        @input.native="controlDiscountInput($event, item)"
                        v-model="item.discount"
                    ></input-text>
                </form-item>
            </template>
            <!-- PRICE AFTER DISCOuNT -->
            <template v-slot:item.discountPrice="{ item }">
                <form-item v-bind="item.fields.discountPrice.props">
                    <input-text
                        disabled
                        name="discountPrice"
                        @update-field="item.fields.discountPrice.props.errors = []"
                        :measurement="currency"
                        :value="item.getFinalPrice()"
                    ></input-text>
                </form-item>
            </template>
            <!-- STOCK -->
            <template v-slot:item.stock="{ item }">
                <form-item v-bind="item.fields.stock.props">
                    <input-text
                        type="number"
                        v-bind="item.fields.stock.props"
                        @update-field="onUpdateFieldValue($event, item)"
                        name="stock"
                        v-model="item.stock"
                        null-value="∞"
                    ></input-text>
                </form-item>
            </template>
            <!-- STOCK HEADER -->
            <template v-slot:header.stock>
                {{ $t('lists.columns.quantity') }}
                <tooltip-popup>
                    <span>{{ $t('entities.productVariation.tooltips.quantity') }}</span>
                </tooltip-popup>
            </template>
            <template v-slot:header.discount>
                {{ $t('lists.columns.discount') }}
                <tooltip-popup>
                    <span>{{ $t('entities.productVariation.tooltips.discount') }}</span>
                </tooltip-popup>
            </template>
        </Table>
        <progress-circular class="quick-edit__loading" v-else color="primary"></progress-circular>
        <Button :disabled="!isSaveAllowed" @click="updateProduct">{{ $t('entities.save') }}</Button>
    </modal>
</template>

<script>
import { debounce, cloneDeep, isEqual } from 'lodash';
import Table from '@/components/common/Table';
import ProductImageField from '@/components/product/ProductImageField';
import ProductVariation from '@/entities/product/ProductVariation';
import FormItem from '@/components/form/item';
import { proxyModelMixin } from '@/mixins/proxyModelMixin';
import Modal from '@/components/common/Modal';
import ProductService from '@/services/ProductService';
import EventEmitter from '@/helpers/eventEmitter.ts';
import Product from '@/entities/product/Product';
import Button from '@/components/common/Button';
import InputRadio from '@/components/form/controls/InputRadio';
import InputText from '@/components/form/controls/InputText';
import ProgressCircular from '@/components/common/ProgressCircular';
import TooltipPopup from '@/components/common/TooltipPopup';

export default {
    name: 'ProductQuickEdit',
    mixins: [proxyModelMixin],
    components: {
        TooltipPopup,
        InputText,
        ProgressCircular,
        InputRadio,
        Button,
        FormItem,
        Table,
        ProductImageField,
        Modal,
    },

    data() {
        return {
            fieldProps: Object.keys(new ProductVariation().fields),
            localProductVariations: cloneDeep(this.product.variations),
            //TODO: localProduct убрать
            localProduct: {},
            fieldKeys: Object.keys(new ProductVariation().fields),
            initialState: null,
            isLoaded: false,
        };
    },

    props: {
        value: {
            type: Boolean,
            default: false,
        },
        product: {
            type: Object,
            default: () => {},
        },
    },
    computed: {
        currency() {
            return this.$store.getters['config/getCurrency'];
        },
        headers() {
            return [
                { text: this.$t('lists.columns.default'), value: 'default', width: '32px' },
                { text: this.$t('lists.columns.image'), value: 'imageId', width: '30px' },
                { text: this.$t('lists.columns.options'), value: 'title', width: '117px' },
                { text: this.$t('lists.columns.fullPrice'), value: 'fullPrice', width: '160px' },
                { value: 'discount', width: '160px' },
                { text: this.$t('lists.columns.priceAfterDiscount'), value: 'discountPrice', width: '160px' },
                { value: 'stock', width: '160px' },
            ];
        },
        isSaveAllowed() {
            if (this.initialState) {
                const current = this.localProductVariations.map((item) => item.data);
                return !isEqual(this.initialState, current);
            }
            return false;
        },
    },

    methods: {
        changeDefaultVariation(item) {
            this.localProductVariations.forEach((variation) => {
                if (variation.id !== item.id) {
                    variation.default = false;
                } else {
                    variation.default = true;
                }
            });
        },

        onUpdateFieldValue: debounce(function(payload, variation) {
            const { name } = payload;
            const errors = variation.validateField(variation.fields[name], variation[name]);
            variation.fields[name].props.errors = errors;
        }, 600),

        controlDiscountInput(e, item) {
            const val = e.target.value;
            if (!val) return;
            const regEx = /(\d+\.\d{0,2}$)|(\d+\.\d{1,2}%?)|(\d+%?)/;
            const match = val.match(regEx);
            if (!match) item.discount = '';
            else {
                item.discount = match[0];
            }
        },
        setDefaultVariation(variationId) {
            const defaultVariation = variationId
                ? this.localProductVariations.find((variation) => variation.default)
                : this.localProductVariations.find((variation) => variation.id === variationId) ||
                  this.localProductVariations[0];

            defaultVariation.default = true;
            if (!defaultVariation.fullPrice) {
                defaultVariation.fullPrice = 0;
            }
        },

        validateForm() {
            let result = true;
            this.localProductVariations.forEach((variation) => {
                const varResult = variation.validateEntity();
                if (!varResult) result = false;
            });
            if (!result) {
                EventEmitter.trigger('show-noty', {
                    type: 'error',
                    text: this.$t('notifications.validation.error'),
                });
            }
            return result;
        },

        async updateProduct() {
            const isFormValid = this.validateForm();
            if (isFormValid === false) return;
            //TODO:   const product = cloneDeep(this.product);
            const product = cloneDeep(this.localProduct);
            product.variations = this.localProductVariations;
            const [error, result] = await ProductService.updateOne(product.data);
            error ? this.failedHandler(result, error) : await this.successUpdateHandler(result);
        },
        async successUpdateHandler(product) {
            this.$emit('product-updated', new Product(product));
            this.model = false;
            EventEmitter.trigger('show-noty', {
                type: 'success',
                text: this.$tc('notifications.updated', 1, { entity: this.$tc('entities.product.title', 1) }),
            });
        },
        failedHandler(response, error) {
            //TODO: найти места, где надо так же в цикле обрабатывать ошибки с бэка
            error.alert();
            const children = response.data?.errors?.children?.variations.children;
            if (children) {
                children.forEach((child, index) => {
                    this.fieldKeys.forEach((key) => {
                        const errors = child.children[key] ? child.children[key].errors : [];
                        if (errors) {
                            this.$set(this.localProductVariations[index].fields[key].props, 'errors', errors);
                        }
                    });
                });
            }
        },
    },
    watch: {
        async product(val) {
            if (val) {
                //TODO: вернуть закоменченное после обновления товаров
                // this.localProductVariations = cloneDeep(val.variations);
                // this.initialState = cloneDeep(this.localProductVariations.map((item) => item.data));
                this.isLoaded = false;
                const [error, result] = await ProductService.getOne(val.id);
                console.log(error);
                this.localProduct = new Product(result);
                this.localProductVariations = cloneDeep(this.localProduct.variations);
                this.initialState = cloneDeep(this.localProductVariations.map((item) => item.data));
                this.isLoaded = true;
            }
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.quick-edit {
    &__modal {
        min-height: 300px;
    }

    &__loading {
        display: flex;
        margin: 100px auto 50px auto;
    }
}
.variations-table {
    .table {
        padding: 0 !important;
    }
    td {
        height: 104px !important;
        vertical-align: center;
    }
    td:first-child,
    td:nth-child(3) {
        padding-right: 16px !important;
    }
    &__tooltip-btn {
        margin-left: 5.5px;
        vertical-align: middle;
        margin-bottom: 2px;
        cursor: pointer;
    }
    &__radio {
        & .material-icons-outlined {
            font-size: 28px;
        }
    }
    &__tooltip {
        max-width: 270px;
    }
    .form-item.-has-errors {
        height: 103px;
        .input-text {
            margin-top: 63px;
        }
    }
}

.tooltip {
    &__btn {
        color: var(--v-primary-base) !important;
    }
    &__icon {
        margin-right: 11px;
        margin-top: 2px;
    }
    &__body {
        display: flex;
        align-items: flex-start;
        padding: 13px 17px;
        margin-top: 4px;
        background: var(--v-primary-lighten-base);
        color: var(--v-primary-base);
    }
}
</style>
