<template>
    <div>
        <progress-circular class="spinner delivery-form__spinner" v-if="isLoading && isAvailable"></progress-circular>
        <div v-if="isAvailable">
            <template v-if="!isLoading">
                <div class="order__delivery order__table">
                    <h2>{{ $t('entities.order.delivery') }}</h2>
                    <alert
                        type="error"
                        class="delivery-form__alert"
                        v-if="value.type === 'delivery' && isDeleted.shippingZone"
                    >
                        {{ $t('entities.order.unavailable.shippingZone') }}
                    </alert>
                    <alert
                        type="error"
                        class="delivery-form__alert"
                        v-if="value.type === 'pickup' && isDeleted.location"
                    >
                        {{ $t('entities.order.unavailable.location') }}
                    </alert>
                    <div class="order__radio-group">
                        <input-radio
                            :value="value.type"
                            @input="handleType"
                            class="order__radio"
                            :text="$t('entities.order.delivery')"
                            return-value="delivery"
                        ></input-radio>
                        <input-radio
                            :value="value.type"
                            @input="handleType"
                            class="order__radio"
                            :text="$t('entities.order.pickup')"
                            return-value="pickup"
                        ></input-radio>
                    </div>
                    <form v-show="value.type === 'delivery'" class="order__delivery-form form">
                        <template v-for="(item, key) in value.deliveryAddress.fields">
                            <form-item
                                class="form__input -half-width"
                                :key="key"
                                v-bind="item.props"
                                v-if="key !== 'region' && key !== 'postalCode'"
                            >
                                <component
                                    v-if="key !== 'country'"
                                    :is="item.component"
                                    :value="value.deliveryAddress[key]"
                                    @input="updateField(key, $event, 'deliveryAddress')"
                                    :name="key"
                                    v-bind="item.props || {}"
                                    hide-details
                                    outlined
                                    dense
                                ></component>

                                <Select
                                    v-else
                                    autocomplete
                                    name="country"
                                    :items="countriesTranslated"
                                    item-value="code"
                                    item-text="name"
                                    @input="handleCountry"
                                    :value="value.deliveryAddress.country"
                                ></Select>
                            </form-item>
                            <template v-else-if="key === 'region'">
                                <form-item
                                    class="form__input -half-width"
                                    :key="key"
                                    v-bind="item.props"
                                    v-if="value.deliveryAddress.country && regions[value.deliveryAddress.country]"
                                    :labelTrans="getCountryLabels(value.deliveryAddress.country).zone"
                                >
                                    <Select
                                        name="region"
                                        autocomplete
                                        :items="regionsTranslated"
                                        v-bind="item.props"
                                        :value="value.deliveryAddress.region"
                                        @input="handleRegion"
                                        item-value="code"
                                        item-text="name"
                                    ></Select>
                                </form-item>
                            </template>
                            <template v-if="key === 'postalCode'">
                                <form-item
                                    v-bind="item.props"
                                    class="form__input -half-width"
                                    :key="key"
                                    v-if="value.deliveryAddress.country"
                                    :labelTrans="getCountryLabels(value.deliveryAddress.country).postalCode"
                                >
                                    <input-text
                                        name="postalCode"
                                        :value="value.deliveryAddress.postalCode"
                                        @input="updateField(key, $event, 'deliveryAddress')"
                                    ></input-text>
                                </form-item>
                            </template>
                        </template>
                    </form>
                    <form v-show="value.type === 'pickup'" class="order__delivery-form modal__content">
                        <div class="modal__radio-group" v-if="Object.keys(locationsAddresses).length">
                            <input-radio
                                v-for="(address, id) in locationsAddresses"
                                :return-value="id"
                                :key="id"
                                size="small"
                                :value="value.location"
                                @input="updateField('location', $event)"
                                :text="address"
                                :disabled="checkIsPickupLocationDisabled(id)"
                            ></input-radio>
                        </div>
                        <p v-else>{{ $t('entities.order.unavailable.pickup') }}</p>
                    </form>
                </div>

                <div class="order__rates" v-if="rates.length && value.type === 'delivery'">
                    <h2>{{ $t('entities.order.shippingMethod') }}</h2>
                    <alert class="delivery-form__alert" type="error" v-if="isDeleted.rate">
                        {{ $t('entities.order.unavailable.rate') }}
                    </alert>
                    <div class="rates">
                        <input-radio
                            :disabled="checkIfRateDisabled(rate)"
                            v-for="rate in rates"
                            :key="rate.id"
                            size="small"
                            @input="handleRate"
                            :value="value.rate"
                            :return-value="rate.id"
                        >
                            <template v-slot:text>
                                <div class="input-radio__text">
                                    <div>
                                        {{ rate.title }}
                                        <span class="rate__price">{{ currency }} {{ rate.price }}</span>
                                    </div>
                                    <p class="rate__info">
                                        {{ rate.shippingSpeed }};
                                        <span v-if="rate.minWeight || rate.maxWeight">
                                            {{ rate.minWeight ? `min ${rate.minWeight} kg` : null }}
                                            {{ rate.minWeight && rate.maxWeight ? '—' : null }}
                                            {{ rate.maxWeight ? `max ${rate.maxWeight} kg` : null }}</span
                                        >
                                        <br
                                            v-if="
                                                (rate.minWeight || rate.maxWeight) && (rate.minPrice || rate.maxPrice)
                                            "
                                        />
                                        <span v-if="rate.minPrice || rate.maxPrice">
                                            {{ rate.minPrice ? `min ${rate.minPrice} ${currency}` : null }}
                                            {{ rate.minPrice && rate.maxPrice ? '—' : null }}
                                            {{ rate.maxPrice ? `max ${rate.maxPrice} ${currency}` : null }}
                                        </span>
                                    </p>
                                </div>
                            </template>
                        </input-radio>
                    </div>
                </div>
                <error-block
                    v-else-if="value.deliveryAddress.country && !rates.length && value.type === 'delivery'"
                    class="order__error"
                >
                    <template v-slot>
                        <div>{{ $t('entities.order.unavailable.delivery') }}</div>
                    </template>
                </error-block>
            </template>
        </div>

        <error-block v-else class="order__error">
            {{ rates }}
            <template v-slot>
                <div>{{ $t('entities.order.delivery') }}</div>
                <div>{{ $t('notifications.apiIsUnavailable') }}</div>
            </template>
        </error-block>
    </div>
</template>

<script>
import OrderDeliveryAddress from '@/entities/order/OrderDeliveryAddress';
import LocationService from '@/services/LocationService.ts';
import ShippingZoneService from '@/services/ShippingZoneService';
import { isEmpty, uniqBy, cloneDeep } from 'lodash';
import countries from '@/helpers/countries';
import ErrorBlock from '@/components/common/ErrorBlock';
import FormItem from '@/components/form/item';
import Location from '@/entities/delivery/Location';
import ShippingZone from '@/entities/delivery/ShippingZone';
import InputText from '@/components/form/controls/InputText';
import Select from '@/components/form/controls/Select';
import InputRadio from '@/components/form/controls/InputRadio';
import ProgressCircular from '@/components/common/ProgressCircular';
import Alert from '@/components/common/Alert';

export default {
    name: 'DeliveryForm',
    components: { Alert, InputRadio, Select, ProgressCircular, InputText, FormItem, ErrorBlock },
    props: {
        value: {
            type: Object,
            default: () => {},
        },
        subtotal: {
            type: Number,
            default: 0,
        },
        weight: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            locations: [],
            locationsAddresses: {},
            shippingZones: [],
            countries: [],
            regions: {},
            rates: [],
            isLoading: true,
            isAvailable: true,
            isDeleted: {
                location: false,
                rate: false,
                shippingZone: false,
            },
        };
    },
    async created() {
        this.isLoading = true;
        const [locationsError, locations] = await LocationService.getAll();
        if (locationsError) {
            locationsError.alert();
            this.isAvailable = false;
            return;
        }
        this.locations = locations.map((item) => new Location(item));
        const addresses = {};
        const currentLocation = this.locations.find((item) => item.id === this.value.location);
        if (this.value.location && !currentLocation) {
            this.isDeleted.location = true;

            this.value.location = null;
        }
        this.locations.forEach((item) => {
            const address = new OrderDeliveryAddress(item);
            addresses[item.id] = OrderDeliveryAddress.formatAddress(address);
        });
        this.locationsAddresses = addresses;
        const [shippingZonesError, shippingZones] = await ShippingZoneService.getAll();
        if (shippingZonesError) {
            shippingZonesError.alert();

            this.isAvailable = false;
            return;
        }
        this.shippingZones = shippingZones.map((item) => new ShippingZone(item));
        this.shippingZones.forEach((zone) => {
            const { countries: c, regions: r } = zone.formatForSelect();
            this.countries = uniqBy([...this.countries, ...c], 'code');
            if (!isEmpty(r)) {
                //проверяем, нет ли уже регионов у стран, чтобы их не затереть
                for (const countryName in r) {
                    if (this.regions[countryName]) {
                        this.regions[countryName] = uniqBy([...this.regions[countryName], ...r[countryName]], 'code');
                    } else {
                        this.regions[countryName] = r[countryName];
                    }
                }
            }
        });
        if (this.value.deliveryAddress.country && !this.value.deliveryAddress.region) {
            this.getRatesByCountry(this.value.deliveryAddress.country);
            if (!this.countries.find((item) => item.code === this.value.deliveryAddress.country)) {
                this.isDeleted.shippingZone = true;
                this.value.deliveryAddress.country = null;
                this.value.rate = null;
            }
            if (!this.rates.find((item) => item.id === this.value.rate)) {
                this.isDeleted.rate = true;
                this.value.rate = null;
            }
        } else if (this.value.deliveryAddress.region) {
            this.getRatesByRegion(this.value.deliveryAddress.region);
            if (
                !this.regions[this.value.deliveryAddress.country]?.find(
                    (item) => item.code === this.value.deliveryAddress.region
                )
            ) {
                this.isDeleted.shippingZone = true;
                this.value.rate = null;
            }
            if (!this.rates.find((item) => item.id === this.value.rate)) {
                this.isDeleted.rate = true;
                this.value.rate = null;
            }
        }

        if (!this.rates.length) {
            this.updateField('price', 0);
        }
        this.isLoading = false;
    },

    methods: {
        getCountryLabels(code) {
            const country = this.countries.find((item) => item.code === code);
            return country ? country.labels : countries.find((item) => item.code === code).labels;
        },
        getRatesByCountry(code) {
            this.rates = [];
            // const country = countries.find((item) => item.code === code);
            // if (country?.zones?.length) return;

            const zones = this.shippingZones.filter((shippingZone) => {
                return shippingZone.zones.find((zone) => {
                    return zone.startsWith(code);
                });
            });

            zones.forEach((zone) => {
                this.rates = [...this.rates, ...zone.rates];
            });
        },
        getRatesByRegion(code) {
            this.rates = [];
            const zones = this.shippingZones.filter((item) => {
                return item.zones.find((zone) => {
                    return zone === `${this.value.deliveryAddress.country}:${code}`;
                });
            });
            zones.forEach((zone) => {
                this.rates = [...this.rates, ...zone.rates];
            });
        },

        checkIfRateDisabled(rate) {
            return (
                (rate.minPrice && this.subtotal < rate.minPrice) ||
                (rate.maxPrice && this.subtotal > rate.maxPrice) ||
                (rate.minWeight && this.weight < rate.minWeight) ||
                (rate.maxWeight && this.weight > rate.maxWeight)
            );
        },
        checkIsPickupLocationDisabled(id) {
            const location = this.locations.find((loc) => loc.id === id);
            if (!location) return false;
            return !location.enabled;
        },

        getFieldEvents(events) {
            const fieldEvents = {};
            for (const key in events) {
                fieldEvents[key] = events[key]?.bind(this.value);
            }
            return fieldEvents;
        },

        updateField(fieldKey, value, groupKey = null) {
            const deliveryInfo = cloneDeep(this.value);
            if (groupKey) {
                deliveryInfo[groupKey][fieldKey] = value;
                deliveryInfo.deliveryAddress.validateField(fieldKey);
            } else deliveryInfo[fieldKey] = value;
            this.$emit('input', deliveryInfo);
        },

        handleCountry(code) {
            this.isDeleted.shippingZone = false;
            this.isDeleted.rate = false;
            const deliveryInfo = cloneDeep(this.value);
            this.getRatesByCountry(code);
            const rate = this.rates.find((rate) => {
                return !this.checkIfRateDisabled(rate);
            });
            deliveryInfo.deliveryAddress.region = null;
            deliveryInfo.deliveryAddress.country = code;
            this.$emit('input', deliveryInfo);
            this.$nextTick(() => {
                this.handleRate(rate?.id);
            });
        },

        handleRegion(code) {
            if (code) {
                this.getRatesByRegion(code);
            }
            this.isDeleted.shippingZone = false;
            this.isDeleted.rate = false;
            const rate = this.rates.find((rate) => {
                return !this.checkIfRateDisabled(rate);
            });
            this.updateField('region', code, 'deliveryAddress');
            this.$nextTick(() => {
                this.handleRate(rate?.id);
            });
        },

        handleType(val) {
            const deliveryInfo = cloneDeep(this.value);
            deliveryInfo.type = val;
            if (val === 'pickup') {
                deliveryInfo.price = 0;
                //очищаем ошибки delivery
                for (const key in deliveryInfo.deliveryAddress.fields) {
                    deliveryInfo.deliveryAddress.fields[key].props.errors = [];
                }
                if (!deliveryInfo.location) {
                    deliveryInfo.location = Object.keys(this.locationsAddresses)?.[0];
                }
            } else {
                deliveryInfo.price = this.rates.find((item) => deliveryInfo.rate === item.id)?.price || 0;
            }
            this.$emit('input', deliveryInfo);
        },

        handleRate(val) {
            this.isDeleted.rate = false;
            const deliveryInfo = cloneDeep(this.value);
            let rate = this.rates.find((item) => item.id === val);
            if (rate) {
                const disabled = this.checkIfRateDisabled(rate);
                if (disabled) {
                    rate = this.rates.find((rate) => {
                        return !this.checkIfRateDisabled(rate);
                    });
                }
            }
            deliveryInfo.price = val ? rate?.price : 0;
            deliveryInfo.rate = rate?.id;
            this.$emit('input', deliveryInfo);
        },
    },
    computed: {
        currency() {
            return this.$store.getters['config/getCurrency'];
        },
        regionsTranslated() {
            const country = this.value.deliveryAddress.country;
            if (country === 'RU') {
                return this.regions[country].map((item) => {
                    return {
                        ...item,
                        name: this.$t(`countries.${country}.zones.${item.code}`),
                    };
                });
            } else return this.regions[country];
        },
        countriesTranslated() {
            return this.countries.map((item) => {
                if (item.code === 'RU') {
                    return { ...item, name: this.$t(`countries.${item.code}.title`) };
                }
                return { ...item, name: this.$t(`countries.${item.code}`) };
            });
        },
    },
    watch: {
        subtotal() {
            //если после изменения суммы заказа выбранный рейт оказался disabled - меняем рейт на первый доступный
            if (this.value.type === 'delivery' && this.value.rate) {
                const rate = this.rates.find((item) => item.id === this.value.rate);
                const isRateDisabled = this.checkIfRateDisabled(rate);
                if (isRateDisabled) this.handleRate(rate.id);
            }
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.order {
    &__radio-group {
        margin-top: 16px;
        .input-radio {
            margin-right: 60px !important;
        }
    }

    &__delivery {
        background-color: var(--v-surface-base);
    }

    &__delivery-form {
        margin-top: 20px;
    }
    &__rates {
        padding: 32px 20px;
        margin-top: 24px;
        background: var(--v-surface-base);
        .rates {
            margin-top: 16px;
            display: flex;
            gap: 32px;
            flex-wrap: wrap;
        }
        .input-radio {
            padding: 16px;
            border-radius: 4px;
            align-items: start;
            flex-basis: calc(50% - 16px);
            border: 1px solid var(--v-outline-base);
            .input-radio__text {
                font-weight: 500;
                font-size: 16px;
                display: block;
                .rate__price {
                    color: var(--v-primary-base);
                }
                .rate__info {
                    font-weight: 400;
                    font-size: 14px;
                    margin-top: 8px;
                    margin-bottom: 0;
                    color: var(--v-on-surface-medium-base);
                }
            }
        }
        .input-radio.-disabled {
            background-color: rgba(#e8eaeb, 0.6);
            cursor: not-allowed !important;
            pointer-events: initial;
            * {
                cursor: not-allowed !important;
            }
        }
    }
    &__error {
        margin-top: 24px;
    }
}
.delivery-form {
    &__spinner {
        position: relative;
        margin-top: 32px;
        left: calc(50% - 16px);
    }
    &__alert {
        margin-top: 16px;
    }
}
</style>
