<template>
    <Table :headers="columns" :items="items" class="file-manager-table">
        <template v-slot:item.name="{ item }">
            <div class="file-manager-table__name" :class="{ 'is-active': item.selected }" @click="onSelectItem(item)">
                <span class="material-icons-outlined">{{ item.children ? 'mdi-folder' : 'mdi-file' }}</span>
                {{ item.children ? item.name : item.name + '.' + item.extension }}
            </div>
        </template>
        <template v-slot:item.size="{ item }">
            <span v-if="item.size">{{ item.size | size }}</span>
        </template>
        <template v-slot:item.createdAt="{ item }">
            <span v-if="item.createdAt">{{ item.createdAt | date }}</span>
        </template>
        <template v-slot:item.actions="{ item }">
            <div v-if="!item.children">
                <!-- <Button:to="{ name: 'Edit Page', params: { id: item.id, type: item.type } }" icon
                    ><span class="material-icons-outline">edit</span></Button
                > -->
            </div>
        </template>
    </Table>
</template>

<script>
import Table from '@/components/common/Table';
export default {
    name: 'FilesTable',
    components: { Table },
    props: {
        columns: {
            type: Array,
            required: true,
        },
        items: {
            type: Array,
            default: () => [],
        },
        value: {
            type: Array,
        },
    },
    methods: {
        onSelectItem(item) {
            if (item.children) this.$emit('input', [...this.value, item.id]);
        },
        remove(item) {
            this.$emit('remove', {
                item,
                location: this.value,
            });
        },
    },
    filters: {
        size(size, unit = 'kb') {
            const expressions = {
                kb: (size) => size / 1024,
                mb: (size) => size / 1024 / 1024,
                gb: (size) => size / 1024 / 1024 / 1024,
            };
            const resultSize = Math.floor(expressions[unit](size) * 10) / 10;
            return `${resultSize} ${unit}`;
        },
        date(date) {
            return new Date(date).toLocaleDateString();
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.file-manager-table {
    &__name {
        cursor: pointer;

        &.is-active {
            color: var(--v-primary-base);
        }
    }
}
</style>
