<template>
    <div class="page author -column -shrunk">
        <progress-circular class="product-form__spinner spinner" v-if="isLoading && isAvailable"></progress-circular>
        <template v-else-if="!isAvailable">
            <div class="page-header">
                <h1 class="page-header__text">{{ $t('entities.author.title') }}</h1>
            </div>
            <error-block></error-block>
        </template>
        <template v-else>
            <sticky-toolbar>
                <Button
                    icon="check"
                    :disabled="!isSaveAllowed"
                    @click="isEditing ? onUpdateAuthor() : onCreateAuthor()"
                >
                    {{ $t('entities.save') }}</Button
                >
                <Button type="outlined" icon="delete" v-if="isEditing" @click="openDeletionModal">
                    {{ $t('entities.remove') }}</Button
                >
            </sticky-toolbar>
            <div class="page-header">
                <h1 class="page-header__text">{{ authorId ? currentState.name : $t('entities.author.newItem') }}</h1>
            </div>
            <alert type="success" class="entity-item__success-alert" v-if="isUpdated">
                {{ $tc('notifications.updated', 1, { entity: this.$tc('entities.author.title', 1) }) }}
            </alert>
            <headed-block class="entity-item__block image-block" :heading="$t('entities.blocks.image')">
                <image-field image-type="author" v-model="currentState.photoId" is-popup></image-field>
            </headed-block>
            <template>
                <headed-block
                    class="entity-item__block"
                    v-for="(block, index) in blocks"
                    :style="{ order: index + 1 }"
                    :key="block.title"
                    :heading="block.title"
                >
                    <div class="form">
                        <template v-for="(item, key) in block.items" :class="item.class">
                            <form-item class="modal__input" :key="key" v-bind="item.props" :name="key">
                                <component
                                    v-if="key !== 'color'"
                                    :is="item.component"
                                    v-model="currentState[key]"
                                    v-bind="item.props || {}"
                                    @update-field="onUpdateFieldValue"
                                    :name="key"
                                    hide-details
                                    v-on="getFieldEvents(item.events)"
                                    outlined
                                    dense
                                ></component>
                            </form-item>
                        </template>
                    </div>
                </headed-block>
            </template>
        </template>
    </div>
</template>

<script>
import AuthorService from '@/services/AuthorService';
import Author from '@/entities/blog/Author';
import FormItem from '@/components/form/item';
import ErrorBlock from '@/components/common/ErrorBlock';
import { debounce, cloneDeep, isEqual } from 'lodash';
import EventEmitter from '@/helpers/eventEmitter.ts';
import InputSwitch from '@/components/form/controls/InputSwitch';
import InputText from '@/components/form/controls/InputText';
import Select from '@/components/form/controls/Select';
import Button from '@/components/common/Button';
import ProgressCircular from '@/components/common/ProgressCircular';
import Alert from '@/components/common/Alert';
import validateField from '@/helpers/validator';
import imageField from '@/components/form/controls/ImageField';
import HeadedBlock from '@/components/common/HeadedBlock';
import StickyToolbar from '@/components/common/StickyToolbar';
import Textarea from '@/components/form/controls/Textarea';
export default {
    name: 'AuthorItem',
    components: {
        Alert,
        ProgressCircular,
        Select,
        InputText,
        InputSwitch,
        FormItem,
        Button,
        ErrorBlock,
        imageField,
        HeadedBlock,
        StickyToolbar,
        Textarea,
    },

    data() {
        return {
            isValid: false,
            currentState: new Author(),
            fieldKeys: Object.keys(new Author().fields),
            isLoading: false,
            initialState: null,
            isAvailable: true,
            isSaveAllowed: false,
            isUpdated: false,
        };
    },
    computed: {
        authorId() {
            return this.$route.params.id;
        },
        isEditing() {
            return this.$route.name !== 'Create Author';
        },
        blocks() {
            return [
                {
                    title: this.$t('entities.blocks.generalInformation'),
                    items: {
                        name: this.currentState.fields.name,
                        type: {
                            ...this.currentState.fields.type,
                            props: {
                                ...this.currentState.fields.type.props,
                                items: [
                                    { text: this.$t('entities.author.type.organization'), value: 'organization' },
                                    { text: this.$t('entities.author.type.person'), value: 'person' },
                                ],
                            },
                        },
                        jobTitle: this.currentState.fields.jobTitle,
                        bio: this.currentState.fields.bio,
                    },
                },
                {
                    title: this.$t('entities.blocks.socials'),
                    items: {
                        website: this.currentState.fields.website,
                        facebookUrl: this.currentState.fields.facebookUrl,
                        instagramUrl: this.currentState.fields.instagramUrl,
                        xUrl: this.currentState.fields.xUrl,
                        linkedInUrl: this.currentState.fields.linkedInUrl,
                        tiktokUrl: this.currentState.fields.tiktokUrl,
                        pinterestUrl: this.currentState.fields.pinterestUrl,
                    },
                },
            ];
        },
    },

    async created() {
        if (this.authorId) {
            await this.getAuthor();
        }
        this.initialState = cloneDeep(this.currentState);
        EventEmitter.on('delete-author', this.deleteAuthor);
    },

    beforeDestroy() {
        EventEmitter.off('delete-author', this.deleteAuthor);
    },
    methods: {
        async getAuthor() {
            this.isLoading = true;
            const [error, result] = await AuthorService.getOne(this.authorId);
            this.isLoading = false;
            if (error) {
                error.alert();
                this.isAvailable = false;
                return;
            }
            this.currentState = new Author(result);
        },

        async onUpdateAuthor() {
            const isFormValid = this.validateForm();
            if (isFormValid === false) return;
            const [error, result] = await AuthorService.updateOne(this.currentState.data);
            error ? this.failedHandler(result, error) : this.successHandler();
        },

        async onCreateAuthor() {
            const isFormValid = this.validateForm();
            if (isFormValid === false) return;
            const [error, result] = await AuthorService.createOne(this.currentState.data);
            error ? this.failedHandler(result, error) : this.successHandler();
        },

        successHandler() {
            this.isUpdated = true;
            this.initialState = cloneDeep(this.currentState);
            this.isSaveAllowed = false;
            window.scrollTo({ top: 0, behavior: 'smooth' });
        },

        async deleteAuthor(items) {
            const [error] = await AuthorService.removeOne(items[0].id);
            if (error) {
                error.notify();
            }
            this.$store.dispatch('modals/closeDeletionModal');
            this.$router.push({ name: 'Author List' });
        },

        validateForm() {
            let result = true;
            this.fieldKeys.forEach((key) => {
                const errors = validateField(this.currentState.fields[key], this.currentState[key]);
                if (errors.length !== 0) result = false;
                this.$set(this.currentState.fields[key].props, 'errors', errors);
            });
            if (result === false)
                EventEmitter.trigger('show-noty', {
                    type: 'error',
                    text: this.$t('notifications.validation.error'),
                });
            return result;
        },

        onUpdateFieldValue: debounce(function(payload) {
            const { name } = payload;

            const errors = validateField(this.currentState[name], this.currentState.fields[name].props.rules);
            console.log(errors);
            this.currentState.fields[name].props.errors = errors;
        }, 600),
        failedHandler(response, error) {
            error.notify();
            const children = response.data?.errors?.children;
            if (children) {
                this.fieldKeys.forEach((key) => {
                    const errors = children[key] ? children[key].errors : [];
                    if (errors) this.$set(this.currentState.fields[key].props, 'errors', errors);
                });
            }
        },
        getFieldEvents(events) {
            const fieldEvents = {};
            for (const key in events) {
                fieldEvents[key] = events[key]?.bind(this.currentState);
            }
            return fieldEvents;
        },

        async openDeletionModal() {
            this.$store.dispatch('modals/openDeletionModal', { items: this.currentState, entity: 'article' });
        },
    },
    watch: {
        currentState: {
            handler(val) {
                if (this.initialState) {
                    this.isSaveAllowed = !isEqual(val.data, this.initialState.data);
                }
            },
            deep: true,
        },
    },
};
</script>

<style lang="scss">
.author {
    &.page.-shrunk {
        padding-right: 32px;
    }
    .image-block {
        order: 2;
    }
    .block {
        padding-right: 17vw;
    }
}
</style>
