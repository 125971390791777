export interface ISectionOptionsTemplateItem {
    id: string;
    component: string;
    classes?: string[];
    props?: any;
    items?: ISectionOptionsTemplateItem[];
}

export interface ISectionOptionsTemplate {
    header: ISectionOptionsTemplateItem | null;
    content: ISectionOptionsTemplateItem | null;
    footer: ISectionOptionsTemplateItem | null;
}

const createTemplatePart = (data) => {
    const part: ISectionOptionsTemplateItem = {
        id: data.id,
        component: data.component,
    };
    if (data.classes) part.classes = data.classes;
    if (data.props) part.props = data.props;
    if (data.items) part.items = data.items;
    return part;
};

export const templateParts = {
    line: {
        header: (items) => createTemplatePart({ id: 'header', component: 'sectionHeader', items }),
        content: (items) => createTemplatePart({ id: 'content', component: 'sectionContent', items }),
        footer: (items) => createTemplatePart({ id: 'footer', component: 'sectionFooter', items }),
    },
    common: {
        headingH2: () => createTemplatePart({ id: 'H2', component: 'HeadingH2' }),
        headingNote: () => createTemplatePart({ id: 'H2', component: 'HeadingNote' }),
        htmlBefore: () => createTemplatePart({ id: 'htmlBefore', component: 'SectionText' }),
        htmlAfter: () => createTemplatePart({ id: 'htmlAfter', component: 'SectionText' }),
        media: () => createTemplatePart({ id: 'Media', component: 'SectionMedia' }),
        columns: (items) => createTemplatePart({ id: 'Columns', component: 'Columns', items }),
        columnsItem: (items) => createTemplatePart({ id: 'ColumnsItem', component: 'ColumnsItem', items }),
        columnsItemImage: (items) =>
            createTemplatePart({ id: 'columnsItemImage', component: 'ColumnsItem', classes: ['image'], items }),
        columnText: () => createTemplatePart({ id: 'columnText', component: 'SectionText' }),
        columnImage: () => createTemplatePart({ id: 'columnImage', component: 'SectionImage' }),
    },
    benefits: {
        base: () => createTemplatePart({ id: 'benefitsBase', component: 'benefitsBase' }),
    },
};

export const createTemplate = ({ header, content, footer }): ISectionOptionsTemplate => {
    // console.log({ header, content, footer });
    return {
        header,
        content,
        footer,
    };
};
// content: templateParts.line.content([templateParts.common.columnText()]),

export const hasNodeByComponent = (template, name) => {
    let result = false;
    if (template.component === name) result = true;
    else if (Array.isArray(template.items))
        template.items.forEach((item) => {
            const itemResult = hasNodeByComponent(item, name);
            if (itemResult) result = itemResult;
        });
    return result;
};

export default createTemplate;
