<template>
    <div class="meta-preview">
        <h3 class="meta-preview__title">{{ header }}</h3>
        <p class="meta-preview__link">{{ link }}</p>
        <span class="meta-preview__description">{{ description }}</span>
    </div>
</template>

<script>
export default {
    name: 'MetaPreview',
    props: {
        header: {
            type: String,
        },
        link: {
            type: String,
        },
        description: {
            type: String,
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.meta-preview {
    padding: 12px;
    border-radius: 4px;
    width: 100%;
    border: 1px solid var(--v-outline-base);
    :hover {
        cursor: default;
    }
    &__title {
        font-size: 19px;
        line-height: 24px;
        color: #433c7e;
        margin-bottom: 10px;
    }
    &__link {
        margin-bottom: 10px !important;
        font-size: 16px;
        line-height: 24px;
        color: #5e8562;
    }
    &__description {
        font-size: 14px;
        line-height: 20px;
        color: #9a9ea3;
    }
}
</style>
