<template>
    <div class="page -width-lg">
        <div class="page-header">
            <h1 class="page-header__text">{{ $t('menus.pageHeaders.locations') }}</h1>
            <div class="page-header__buttons">
                <Button @click="addLocation" v-if="isAvailable" icon="add_circle_outline">{{
                    $t('entities.add')
                }}</Button>
            </div>
        </div>
        <progress-circular class="spinner -absolute" v-if="!isLoaded && isAvailable"></progress-circular>
        <error-block v-if="!isAvailable"></error-block>
        <div v-else-if="isLoaded" class="page__content-container">
            <location-form
                @toggle-enabled="handleLocation"
                v-for="location in locations"
                :location="location"
                ref="locations"
                :countries="countries"
                @update-location="handleLocation"
                @delete-location="openDeletionModal"
                :key="location.id"
            ></location-form>
        </div>
        <span class="locations__hint">{{ $t('entities.location.hint') }}</span>
    </div>
</template>

<script>
import countries from '@/helpers/countries';
import LocationService from '@/services/LocationService.ts';
import Location from '@/entities/delivery/Location';
import LocationForm from '@/components/location/locationForm';
import EventEmitter from '@/helpers/eventEmitter.ts';
import ErrorBlock from '@/components/common/ErrorBlock';
import validateField from '@/helpers/validator';
import Button from '@/components/common/Button';
import ProgressCircular from '@/components/common/ProgressCircular';
export default {
    name: 'Locations',
    components: { LocationForm, ProgressCircular, ErrorBlock, Button },
    data() {
        return {
            locations: [],
            countries: [],
            panels: [],
            newLocationsIds: [],
            isAvailable: true,
            isLoaded: false,
            fieldKeys: Object.keys(new Location().fields),
        };
    },
    async created() {
        this.isLoaded = false;
        EventEmitter.on('delete-location', this.deleteLocation);

        this.countries = countries.map((item) => {
            return { text: item.name, value: item.code };
        });
        await this.getLocations();
        this.isLoaded = true;
    },
    methods: {
        async getLocations() {
            const [error, result] = await LocationService.getAll();
            if (error) {
                error.alert();
                this.isAvailable = false;
                return [];
            }
            this.locations = result.map((item) => new Location(item));
        },
        openDeletionModal(items) {
            this.$store.dispatch('modals/openDeletionModal', { items, entity: 'location' });
        },

        onUpdateLocation(location) {
            const index = this.locations.findIndex((item) => item.id === location.id);
            this.$set(this.locations, index, location);
        },

        addLocation() {
            const location = new Location();
            this.locations.push(location);
            this.newLocationsIds.push(location.id);
            //открываем добавленную панель
            this.panels = [...this.panels, this.locations.length - 1];
            this.$nextTick(() => {
                this.scrollToForm();
            });
        },

        validateForm(location) {
            let result = true;
            this.fieldKeys.forEach((key) => {
                const errors = validateField(location[key], location.fields[key].props.rules);
                if (errors.length !== 0) result = false;
                this.$set(location.fields[key].props, 'errors', errors);
            });
            if (result === false)
                EventEmitter.trigger('show-noty', {
                    type: 'error',
                    text: this.$t('notifications.validation.error'),
                });
            return result;
        },

        async successCreateHandler(location) {
            EventEmitter.trigger('show-noty', {
                type: 'success',
                text: this.$tc('notifications.created', 1, { entity: this.$tc('entities.location.title', 1) }),
            });
            this.newLocationsIds = this.newLocationsIds.filter((id) => id !== location.id);
            await this.getLocations();
        },
        async successUpdateHandler() {
            EventEmitter.trigger('show-noty', {
                type: 'success',
                text: this.$tc('notifications.updated', 1, { entity: this.$tc('entities.location.title', 1) }),
            });
            await this.getLocations();
        },

        failedHandler(response, location, error) {
            error.notify();
            const children = response.data?.errors?.children;
            if (children) {
                this.fieldKeys.forEach((key) => {
                    const errors = children[key] ? children[key].errors : [];
                    if (errors) location.fields[key].props.errors = errors;
                });
            }
        },

        async createLocation(location) {
            const isFormValid = this.validateForm(location);
            if (isFormValid === false) return;
            const [error, result] = await LocationService.createOne(location.data);
            error ? this.failedHandler(result, location, error) : await this.successCreateHandler(location);
        },

        async updateLocation(location) {
            const isFormValid = this.validateForm(location);
            if (isFormValid === false) return;
            const [error, result] = await LocationService.updateOne(location.data);
            error ? this.failedHandler(result, location, error) : await this.successUpdateHandler(location);
        },

        async handleLocation(location) {
            if (this.newLocationsIds.includes(location.id)) {
                await this.createLocation(location);
            } else {
                await this.updateLocation(location);
            }
        },
        async deleteLocation(items) {
            const index = this.newLocationsIds.findIndex((id) => {
                return id === items[0].id;
            });
            if (index !== -1) {
                this.newLocationsIds.splice(index, 1);
                const locationsIndex = this.locations.findIndex((item) => {
                    return item.id === items[0].id;
                });
                this.locations.splice(locationsIndex, 1);
                this.$store.dispatch('modals/closeDeletionModal');
                return;
            }
            const [error] = await LocationService.removeOne(items[0].id);
            if (!error) {
                EventEmitter.trigger('show-noty', {
                    type: 'success',
                    text: this.$tc('notifications.deleted', 1, { entity: this.$tc('entities.location.title', 1) }),
                });
                this.$store.dispatch('modals/closeDeletionModal');
                await this.getLocations();
            }
        },
        scrollToForm() {
            const index = this.locations.length - 1;
            if (index === 0) return;
            const offset = this.$refs.locations[index].$el.getBoundingClientRect().top - 70 + window.pageYOffset;
            window.scrollTo({
                behavior: 'smooth',
                top: offset,
            });
        },
    },
    beforeDestroy() {
        EventEmitter.off('delete-location', this.deleteLocation);
    },
};
</script>

<style lang="scss">
.locations {
    &__hint {
        font-weight: 500;
    }
}
</style>
