<template>
    <div class="builder-preview">
        <iframe
            class="builder-preview__iframe"
            v-show="iframeIsLoaded"
            ref="iframe"
            :style="iframeStyle"
            :src="`${previewSrc}?disable-interaction=true`"
        ></iframe>
        <progress-circular v-show="!iframeIsLoaded" class="builder-preview__loader" color="primary"></progress-circular>
    </div>
</template>

<script>
import EventEmitter from '@/helpers/eventEmitter.ts';
import { mapActions, mapGetters, mapState } from 'vuex';
import { cloneDeep } from 'lodash';
import ProgressCircular from '@/components/common/ProgressCircular';
import { removeFunctionsFromObject } from '@/helpers/utils';

export default {
    name: 'Preview',
    components: {
        ProgressCircular,
    },
    created() {
        if (['admin.runshop.test'].includes(document.domain)) document.domain = 'runshop.test';
        EventEmitter.on('to-public', this.onToPublic);
        EventEmitter.on('refresh-preview', this.onRefreshPreview);
    },
    mounted() {
        this.iframeIsLoaded = false;
        this.checkIframeScale();
        window.addEventListener('resize', this.checkIframeScale);
        // Дожидаемся загрузки страницы в iframe и только после этого вешаем другие обработчики
        if (this.$refs.iframe) this.$refs.iframe.addEventListener('load', this.onFrameLoad);
    },
    destroyed() {
        EventEmitter.off('to-public', this.onToPublic);
        EventEmitter.off('refresh-preview', this.onRefreshPreview);
        window.removeEventListener('resize', this.checkIframeScale);
        // this.$refs.iframe.removeEventListener('load', this.onFrameLoad);
    },
    props: {
        route: {
            type: Object,
            default: function() {
                return {
                    type: 'homepage',
                };
            },
        },
        resizeValue: { type: Number, default: 0 },
    },
    data() {
        return {
            iframeScale: 1,
            iframeIsLoaded: false,
        };
    },
    computed: {
        ...mapState('builder', {
            previewMode: 'previewMode',
            currentPage: 'currentPage',
            isNewPage: 'isNewPage',
        }),
        ...mapGetters('globalVars', { instanceFull: 'getInstanceFull' }),
        previewSrc() {
            let domain = `https://${this.instanceFull.primaryDomain}`;
            if (['runshop.test'].includes(document.domain)) {
                domain = 'https://runshop.test';
            }
            const { type, id } = this.route;
            // существующие страницы
            if (type === 'textpage' && id) return `${domain}/${this.currentPage?.slug}`;
            if (type === 'catalog' && id) return `${domain}/catalogs/${this.currentPage?.slug}`;
            if (type === 'product' && id) return `${domain}/products/${this.currentPage?.slug}`;
            // создание новых страниц
            if (type === 'textpage' && !id) return `${domain}/new-page`;
            if (type === 'catalog' && !id) return `${domain}/catalogs/new-page`;
            // вспомогательные страницы
            if (type === 'cart') return `${domain}/cart`;
            if (type === 'checkout') return `${domain}/checkout`;
            if (type === 'media') return `${domain}/${this.currentPage?.slug}`;
            return `${domain}`;
        },
        previewResolution() {
            const screens = {
                fullscreen: '100%',
                desktop: '1400px',
                laptop: '1025px',
                tablet: '768px',
                mobile: '414px',
            };
            return screens[this.previewMode] ? screens[this.previewMode] : screens.desktop;
        },
        iframeStyle() {
            return {
                width: this.previewResolution,
                height: this.iframeScale ? `calc(100%/${this.iframeScale * 100}*100)` : '100%',
                transform: this.iframeScale ? `scale(${this.iframeScale}) translateX(-50%)` : ' translateX(-50%)',
            };
        },
    },
    methods: {
        ...mapActions('history', {
            resetHistory: 'RESET_HISTORY',
        }),
        sendEventToPublic(detail, timeout = 0) {
            setTimeout(() => {
                const clearDetail = removeFunctionsFromObject(cloneDeep(detail));
                const iframe = this.$refs.iframe;
                iframe?.contentWindow?.postMessage(
                    {
                        author: 'runshop',
                        detail: clearDetail,
                    },
                    '*'
                );
            }, timeout);
        },
        checkIframeScale() {
            // Считаем на сколько надо уменьшить ширину iframe
            const previewResolution = parseInt(this.previewResolution);
            if (this.previewResolution !== '100%' && previewResolution > this.$el.offsetWidth) {
                this.iframeScale = this.$el.offsetWidth / (previewResolution / 100) / 100;
            } else {
                this.iframeScale = 1;
            }
        },
        updIframeScale(value) {
            if (value) return this.checkIframeScale();
        },
        onToPublic({ action, payload, timeout }) {
            const detail = {
                action: action,
                payload: {
                    ...payload,
                },
            };
            const domainsWithLogs = ['127.0.0.1', 'stg1.runshop.dev', 'stg2.runshop.dev', 'stg5.runshop.dev'];
            if (domainsWithLogs.includes(document.domain)) console.log({ action, payload });
            if (this.iframeIsLoaded) {
                this.sendEventToPublic(detail, timeout);
            } else {
                let intervalUpdate = setInterval(() => {
                    if (this.iframeIsLoaded) {
                        this.sendEventToPublic(detail, timeout);
                        clearInterval(intervalUpdate);
                    }
                }, 2000);
            }
        },
        onRefreshPreview() {
            // eslint-disable-next-line no-self-assign
            this.$refs.iframe.src = this.$refs.iframe.src;
        },
        onFrameLoad() {
            this.iframeIsLoaded = true;
            if (this.isNewPage) {
                setTimeout(() => {
                    this.currentPage.sections.forEach((section) => {
                        EventEmitter.sendToPublic('add-section', { data: cloneDeep(section.data) });
                    });
                }, 500);
            }
            this.resetHistory();
            for (let i = 1; i < 10; i++) {
                setTimeout(() => {
                    this.resetHistory();
                }, i * 100);
            }
        },
    },
    watch: {
        route: {
            deep: true,
            immediate: true,
            handler() {
                this.iframeIsLoaded = false;
            },
        },
        resizeValue: 'updIframeScale',
    },
};
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';
.builder-preview {
    position: relative;
    height: 100%;
    width: 100%;
    max-width: 100%;
    border: none;
    overflow: hidden;

    &__iframe {
        position: absolute;
        left: 50%;
        height: 100%;
        transform-origin: left top;
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
        border: none;
        //pointer-events: none;
    }

    &__loader {
        position: absolute;
        left: 50%;
        top: 50%;
    }
}
</style>
