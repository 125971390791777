<template>
    <div class="page -width-lg">
        <div class="page-header">
            <h1 class="page-header__text">{{ $t('menus.pageHeaders.robots') }}</h1>
        </div>
        <alert type="success" v-if="isUpdated" class="entity-item__success-alert">
            {{ $tc('notifications.updated', 1, { entity: this.$tc('entities.seo.robots.title', 1) }) }}</alert
        >
        <div class="robots__container" v-if="settings">
            <div class="robots__editor">
                <!-- <CodeEditor
                    wrap_code
                    width="80%"
                    height="auto"
                    v-model="settings.robots.value"
                    :languages="['plaintext']"
                ></CodeEditor> -->
                <form-item class="form__input" v-bind="fields.robots.props">
                    <Textarea
                        v-bind="fields.robots.props || {}"
                        @update-field="onUpdateFieldValue"
                        name="robots"
                        v-on="getFieldEvents(fields.robots.events)"
                        v-model="settings.robots.value"
                    ></Textarea>
                </form-item>
            </div>
            <Button class="robots__save-btn" icon="check" @click="onUpdateSettings" :disabled="!hasChange">{{
                $t('entities.save')
            }}</Button>
        </div>
    </div>
</template>

<script>
import { cloneDeep, debounce } from 'lodash';
// import CodeEditor from 'simple-code-editor';
import SettingService from '@/services/SettingService';
import fields from '@/entities/seo/robotsTxt.fields';
import validateField from '@/helpers/validator';
import EventEmitter from '@/helpers/eventEmitter.ts';
import FormItem from '@/components/form/item';
import Textarea from '@/components/form/controls/Textarea';
import Button from '@/components/common/Button';
import Alert from '@/components/common/Alert';

export default {
    name: 'RobotsTxt',

    components: {
        Alert,
        // CodeEditor,
        FormItem,
        Textarea,
        Button,
    },

    data() {
        return {
            default: {},
            hasChange: false,
            fields,
            fieldKeys: Object.keys(fields),
            isUpdated: false,
        };
    },

    computed: {
        settings() {
            return this.$store.getters['config/getAllSettings'].seo;
        },
    },

    created() {
        setTimeout(() => {
            this.default = cloneDeep(this.$store.getters['config/getAllSettings'].seo);
            this.checkChanges(this.settings);
        }, 0);
    },

    watch: {
        settings: {
            handler(newValue) {
                this.checkChanges(newValue);
            },
            deep: true,
        },
    },

    methods: {
        checkChanges(newValue) {
            this.hasChange = JSON.stringify(newValue) !== JSON.stringify(this.default);
        },
        async onUpdateSettings() {
            const isFormValid = this.validateForm();
            if (isFormValid === false) return;
            const [error, result] = await SettingService.updateGroup('seo', this.settings);
            error ? this.failedHandler(result) : this.successUpdateHandler();
        },

        successUpdateHandler() {
            this.default = cloneDeep(this.settings);
            this.checkChanges(this.settings);
            this.isUpdated = true;
        },

        validateForm() {
            let result = true;
            const fieldKeys = Object.keys(this.fields);
            fieldKeys.forEach((key) => {
                const errors = validateField(this.fields[key].value, this.settings[key].options.rules);
                if (errors.length !== 0) result = false;
                this.$set(this.fields[key].props, 'errors', errors);
            });
            if (result === false)
                EventEmitter.trigger('show-noty', {
                    type: 'error',
                    text: this.$t('notifications.validation.error'),
                });
            return result;
        },

        failedHandler(response) {
            const children = response.data?.errors?.children;
            if (children) {
                this.fieldKeys.forEach((key) => {
                    const errors = children[key] ? children[key].errors : [];
                    if (errors) this.$set(this.fields[key].props, 'errors', errors);
                });
            }
            EventEmitter.trigger('show-noty', {
                type: 'error',
                text: `Error occurred. Status ${response.error.statusCode}: ${response.error.message}`,
            });
        },

        onUpdateFieldValue: debounce(function(payload) {
            const { name } = payload;
            const errors = validateField(this.fields[name].value, this.settings[name].options.rules);
            this.$set(this.fields[name].props, 'errors', errors);
        }, 600),

        getFieldEvents(events) {
            const fieldEvents = {};
            for (const key in events) {
                fieldEvents[key] = events[key]?.bind(this.settings);
            }
            return fieldEvents;
        },
    },

    beforeDestroy() {
        if (this.hasChange) this.$store.dispatch('config/setSettings', { seo: cloneDeep(this.default) });
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.robots {
    &__container {
        display: flex;
        padding: 32px 24px;
        gap: 24px;
        flex-direction: column;
        background-color: var(--v-surface-base);
    }

    &__editor {
        textarea {
            display: block;
            width: 100%;
            border-radius: 4px;
            min-height: 300px !important;
            height: auto;
        }
    }
    &__save-btn {
        margin-left: auto;
    }
}
</style>
