<template>
    <modal
        :header="orderStateId ? $t('lists.editButton.orderState') : $t('lists.addButton.orderState')"
        width="632"
        v-model="model"
    >
        <form class="modal__content form" ref="form">
            <progress-circular class="modal__spinner spinner" v-if="isLoading"></progress-circular>
            <template v-else>
                <form-item
                    class="modal__input"
                    v-for="(field, key) in currentState.fields"
                    :key="key"
                    v-bind="field.props"
                    :name="key"
                >
                    <component
                        :is="field.component"
                        v-model="currentState[key]"
                        v-bind="field.props || {}"
                        @update-field="onUpdateFieldValue"
                        :name="key"
                        hide-details
                        v-on="getFieldEvents(field.events)"
                        outlined
                        dense
                    ></component>
                </form-item>
            </template>
        </form>
        <div class="form__input">
            <Button
                class="modal__save-btn"
                :disabled="!isSaveAllowed"
                icon="check"
                @click="orderStateId ? onUpdateOrderState() : onCreateOrderState()"
            >
                {{ $t('entities.save') }}</Button
            >
        </div>
    </modal>
</template>

<script>
import { debounce, isEqual, cloneDeep } from 'lodash';
import Modal from '@/components/common/Modal';
import { proxyModelMixin } from '@/mixins/proxyModelMixin';
import OrderStateService from '@/services/OrderStateService';

import OrderState from '@/entities/order/OrderState';
import FormItem from '@/components/form/item';
import validateField from '@/helpers/validator';
import EventEmitter from '@/helpers/eventEmitter.ts';
import Button from '@/components/common/Button';
import ProgressCircular from '@/components/common/ProgressCircular';
import Select from '@/components/form/controls/Select';
export default {
    name: 'OrderStateModal',
    mixins: [proxyModelMixin],
    components: { ProgressCircular, Select, FormItem, Modal, Button },

    props: {
        value: {
            type: Boolean,
            default: false,
        },
        orderStateId: {
            type: String,
            default: null,
        },
    },

    data() {
        return {
            isValid: false,
            currentState: new OrderState(),
            isLoading: false,
            fieldKeys: Object.keys(new OrderState().fields),
            initialState: null,
        };
    },
    methods: {
        async getOrderState() {
            this.isLoading = true;
            const [error, result] = await OrderStateService.getOne(this.orderStateId);
            if (error) {
                error.notify();
            }
            this.currentState = new OrderState(result);
            this.initialState = cloneDeep(this.currentState);
            this.isLoading = false;
        },
        initEmptyForm() {
            this.isLoading = true;
            setTimeout(() => (this.isLoading = false), 300);
            this.currentState = new OrderState();
            this.initialState = cloneDeep(this.currentState);
        },
        async onCreateOrderState() {
            const isFormValid = this.validateForm();
            if (isFormValid === false) return;
            const [error, result] = await OrderStateService.createOne(this.currentState.data);
            if (error) this.failedHandler(result, error);
            else {
                this.$emit('action-success');
                this.model = false;
                EventEmitter.trigger('show-noty', {
                    type: 'success',
                    text: this.$tc('notifications.created', 1, { entity: this.$tc('entities.order.state.title', 1) }),
                });
                this.currentState = new OrderState();
            }
        },
        async onUpdateOrderState() {
            const isFormValid = this.validateForm();
            if (isFormValid === false) return;
            const [error, result] = await OrderStateService.updateOne(this.currentState.data);
            if (error) this.failedHandler(result, error);
            else {
                this.$emit('action-success');
                this.model = false;
                EventEmitter.trigger('show-noty', {
                    type: 'success',
                    text: this.$tc('notifications.updated', 1, { entity: this.$tc('entities.order.state.title', 1) }),
                });
                this.currentState = new OrderState();
            }
        },
        validateForm() {
            let result = true;
            const fieldKeys = Object.keys(this.currentState.fields);

            fieldKeys.forEach((key) => {
                const errors = validateField(this.currentState[key], this.currentState.fields[key].props.rules);
                if (errors.length !== 0) result = false;
                this.$set(this.currentState.fields[key].props, 'errors', errors);
            });
            if (result === false)
                EventEmitter.trigger('show-noty', {
                    type: 'error',
                    text: this.$t('notifications.validation.error'),
                });
            return result;
        },

        failedHandler(response, error) {
            error.alert();
            const children = response.data?.errors?.children;
            if (children) {
                this.fieldKeys.forEach((key) => {
                    const errors = children[key] ? children[key].errors : [];
                    if (errors) this.$set(this.currentState.fields[key].props, 'errors', errors);
                });
            }
        },

        onUpdateFieldValue: debounce(function(payload) {
            const { name } = payload;
            const errors = validateField(this.currentState[name], this.currentState.fields[name].props.rules);
            this.$set(this.currentState.fields[name].props, 'errors', errors);
        }, 600),

        getFieldEvents(events) {
            const fieldEvents = {};
            for (const key in events) {
                fieldEvents[key] = events[key]?.bind(this.currentState);
            }
            return fieldEvents;
        },
    },
    computed: {
        isSaveAllowed() {
            if (this.initialState) {
                return !isEqual(this.initialState.data, this.currentState.data);
            }
            return false;
        },
    },
    watch: {
        value(val) {
            if (!val) return;
            if (this.orderStateId) this.getOrderState();
            else this.initEmptyForm();
        },
    },
};
</script>

<style lang="scss"></style>
