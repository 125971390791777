export default (ctx) => ({
    notification: {
        type: 'field',
        value: ctx.notification,
        props: {
            errors: [],
        },
    },
    enabled: {
        type: 'field',
        value: ctx.enabled,
        component: 'input-switch',
        props: {
            errors: [],
        },
    },
    subject: {
        type: 'field',
        value: ctx.subject,
        component: 'input-text',
        props: {
            errors: [],
            label: 'entities.email.fields.subject',
        },
    },
    content: {
        type: 'field',
        value: ctx.content,
        component: 'textarea',
        props: {
            errors: [],
            label: 'entities.email.fields.content',
        },
    },
});
