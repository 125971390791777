import { uuid } from 'vue-uuid';
import initFields from '@/entities/blog/Article.fields';
import Redirect from '@/entities/Redirect';
import Page from '@/entities/page/Page';
import Section from '../section/Section';

export default class Article extends Page {
    id: string;
    previewImageId: string | null;
    enabled: boolean;
    hidden: boolean;
    noIndex: boolean;
    title: string;
    authorId: string;
    categoryId: string;
    heading: string;
    annotation: string;
    publisherId: string;
    publishedAt: string;
    updatedAt: string;
    featuredImageId: string;
    socialMediaImageId: string;
    commentIds: string[];
    redirect: Redirect | null;
    tagIds: string[];
    slug: string;
    metaTitle: string;
    metaDescription: string;
    sections: Section[];
    fields: any;
    type = 'article';
    constructor({
        id = uuid.v4(),
        heading = '',
        previewImageId = '',
        authorId = '',
        enabled = true,
        hidden = false,
        noIndex = false,
        categoryId = '',
        title = '',
        annotation = '',
        publisherId = '',
        publishedAt = '',
        updatedAt = '',
        featuredImageId = '',
        socialMediaImageId = '',
        commentIds = [],
        redirect = null,
        tagIds = [],
        slug = '',
        metaTitle = '',
        metaDescription = '',
        sections = [],
    } = {}) {
        super({
            id,
            title,
            heading,
            previewImageId,
            enabled,
            hidden,
            noIndex,
            redirect,
            slug,
            metaTitle,
            metaDescription,
            sections,
            defaultSections: [
                'SectionBreadcrumbs',
                'SectionHeadingH1',
                'SectionMediaTableOfContents',
                'SectionMediaAuthors',
                'SectionMediaComments',
            ],
        });

        this.authorId = authorId;
        this.categoryId = categoryId;
        this.annotation = annotation;
        this.publisherId = publisherId;
        this.publishedAt = publishedAt;
        this.updatedAt = updatedAt;
        this.featuredImageId = featuredImageId;
        this.socialMediaImageId = socialMediaImageId;
        this.commentIds = commentIds;
        this.tagIds = tagIds;
        this.fields = initFields(this);
    }

    get data() {
        return {
            id: this.id,
            heading: this.heading,
            previewImageId: this.previewImageId,
            authorId: this.authorId,
            enabled: this.enabled,
            hidden: this.hidden,
            noIndex: this.noIndex,
            categoryId: this.categoryId,
            title: this.title,
            annotation: this.annotation,
            publisherId: this.publisherId,
            publishedAt: this.enabled ? (this.publishedAt ? this.publishedAt : new Date()) : null,
            featuredImageId: this.featuredImageId,
            socialMediaImageId: this.socialMediaImageId,
            commentIds: this.commentIds,
            redirect: this.redirect,
            tagIds: this.tagIds,
            slug: this.slug,
            metaTitle: this.metaTitle,
            metaDescription: this.metaDescription,
            sections: this.sections.map((section) => section.data),
        };
    }
}
