import ApiClient from '@/api/apiClient';
import CustomError from '@/entities/customError';
import { Domain } from '@/services/services.types';

class DomainService {
    /**
     * Получение всзе доменов инстанса
     * @param {String} instanceId - id инстанса
     * @returns {Promise} массив объектов доменов
     */
    async getAll(instanceId: string): Promise<[CustomError | null, Domain[] | []]> {
        const url: string = `/${instanceId}/domain`;
        const defaultValue = [];
        const errorPath: string = '[api:domain:getAll]';
        return await ApiClient.account.get({ url, defaultValue, errorPath });
    }

    /**
     * Создание домена
     * @param {String} instanceId - id инстанса
     * @param {Domain} domain - объект домена
     * @returns {Promise} объект созданного домена
     */
    async createOne(instanceId: string, domain: Domain): Promise<[CustomError | null, Domain | null]> {
        const url: string = `/${instanceId}/domain/`;
        const defaultValue = null;
        const errorPath: string = '[api:domain:createOne]';
        return await ApiClient.account.post({ url, defaultValue, errorPath, data: domain });
    }

    /**
     * Изменение основного домена
     * @param {String} instanceId - id инстанса
     * @param {String} domainName - имя домена
     * @returns {Promise} объект созданного домена
     */
    async updatePrimary(instanceId: string, domainName: string): Promise<[CustomError | null, Domain | null]> {
        const url: string = `/${instanceId}/domain/${domainName}/primary`;
        const defaultValue = null;
        const errorPath: string = '[api:domain:updatePrimary]';
        return await ApiClient.account.post({ url, defaultValue, errorPath });
    }

    /**
     * Установка домена как email
     * @param {String} instanceId - id инстанса
     * @param {String} domainName - имя домена
     * @returns {Promise} объект созданного домена
     */
    async setAsEmail(instanceId: string, domainName: string): Promise<[CustomError | null, Domain | null]> {
        const url: string = `/${instanceId}/domain/${domainName}/email`;
        const defaultValue = null;
        const errorPath: string = '[api:domain:setAsEmail]';
        return await ApiClient.account.post({ url, defaultValue, errorPath });
    }

    /**
     * Удаление домена
     * @param {String} instanceId - id инстанса
     * @param {String} domainName - имя домена
     * @returns {Promise} массив объектов доменов
     */
    async removeOne(instanceId: string, domainName: string): Promise<[CustomError | null, null]> {
        const url: string = `/${instanceId}/domain/${domainName}`;
        const defaultValue = null;
        const errorPath: string = '[api:domain:removeOne]';
        return await ApiClient.account.delete({ url, defaultValue, errorPath });
    }
}

export default new DomainService();
