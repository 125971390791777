<template>
    <div class="stretch-content-item">
        <slot></slot>
        <div class="stretch-content-item__btn" :class="classes" @mousedown="onMouseDown"></div>
    </div>
</template>

<script>
export default {
    name: 'StretchContentItem',
    props: {
        item: {
            type: Object,
            default() {
                return {};
            },
        },
        screenWidth: {
            type: Number,
            default: 0,
        },
    },
    computed: {
        classes() {
            if (this.item.side === 'left') return '-left';
            if (this.item.side === 'right') return '-right';
            return '';
        },
    },
    methods: {
        onMouseDown(e) {
            if (!this.checkCoord()) return;
            return this.$emit('onResizing', {
                slotName: this.item.slotName,
                isResizing: true,
                resizeWidth: this.getResizeWidth(e.clientX),
            });
        },
        getResizeWidth(coordX) {
            let width = coordX;
            if (this.item.side === 'left') width = this.screenWidth - coordX;
            if (width <= this.item.minWidth) width = this.item.minWidth;
            if (this.item.maxWidth && width >= this.item.maxWidth) width = this.item.maxWidth;
            return width;
        },
        checkCoord() {
            const rightCoordX = this.item.node.offsetLeft + this.item.node.offsetWidth;
            const leftCoordX = this.item.node.offsetLeft;
            if (this.item.side === 'right' && rightCoordX >= this.screenWidth) return;
            if (this.item.side === 'left' && leftCoordX <= 0) return;
            return true;
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.stretch-content-item {
    position: relative;
    //cursor: pointer;

    &__btn {
        cursor: ew-resize;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 4px;
        z-index: 2;
        &.-right {
            right: 0;
            left: auto;
        }
        &.-left {
            right: auto;
            left: 0;
        }
    }
}
</style>
