import Page from './Page';
import HomepageFields from '@/entities/page/Homepage.fields';

/**
 * Класс экземпляра главной страницы
 */
export default class Homepage extends Page {
    type = 'homepage';
    title = 'Homepage';

    fields = HomepageFields;

    requiredField = [];

    /**
     * Create HomePage Entity
     * @param {Object}  data - объект данных главной страницы
     * @param {String} data.heading -
     * @param {String} data.metaTitle -
     * @param {String} data.metaDescription -
     * @param {String} data.previewImageId -
     * @param {Array} data.sections -
     */
    constructor(data) {
        data.title = 'Homepage';
        super({ ...data, defaultSections: ['SectionHeadingH1'] });
        this.id = 'homepage';
    }

    get data() {
        return {
            heading: this.heading,
            metaTitle: this.metaTitle,
            metaDescription: this.metaDescription,
            previewImageId: this.previewImageId,
            sections: this.sections.map((section) => section.data),
        };
    }
}
