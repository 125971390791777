import { cloneDeep, merge } from 'lodash';
import { Container, Content, Footer, Heading, Section } from '@/entities/section/samples/_options';

const section = {
    id: '',
    type: 'SectionCategoryStyle3',
    required: false,
    group: 'Category',
    options: {
        section: { ...Section },
        container: { ...Container },
        heading: { ...Heading },
        content: {
            htmlBefore: { ...Content.htmlBefore },
            htmlAfter: { ...Content.htmlAfter },
            colorText: { ...Content.color },
        },
        columns: {
            type: 'field',
            value: {
                desktop: 3,
                tablet: 2,
                mobile: 1,
            },
            component: 'CustomizeColumnsTabs',
            ...{
                props: {
                    ranges: {
                        desktop: [2, 3, 4, 5],
                        tablet: [2, 3],
                        mobile: [1, 2],
                    },
                },
            },
        },
        footer: { ...Footer },
    },
    items: [],
    children: {
        id: '',
        type: 'category',
        options: {
            heading: {
                title: {
                    ...Heading.title,
                    value: 'Category Name',
                },
                textColor: Heading.colorTitle,
                background: {
                    ...Heading.colorTitle,
                    props: {
                        labelTrans: 'builder.sectionOptions.props.background',
                        btnReset: true,
                        mode: 'hexa',
                    },
                },
                textAlign: { ...Heading.textAlign, value: 'left' },
            },
            content: {
                link: { ...Content.categoryLink },
                image: merge(Content.image, {
                    props: { note: 'JPG, PNG. Recomended size: 400х400px. No more than 40kb' },
                }),
                alt: {
                    ...Content.imageAlt,
                    value: 'Category Name',
                },
            },
        },
    },
};

const itemFixture = cloneDeep(section.children);
const itemsFixture = [];
itemsFixture.push(Object.assign(cloneDeep(itemFixture), { position: 0 }));
itemsFixture.push(Object.assign(cloneDeep(itemFixture), { position: 1 }));
itemsFixture.push(Object.assign(cloneDeep(itemFixture), { position: 2 }));
section.items = itemsFixture;

export default section;
