import ProductOptionValue from '@/entities/product/ProductOptionValue';

export function handleOptionValues(values, option, valueItems) {
    return values.map((value) => {
        //если выбрали из существующих
        if (typeof value === 'object') {
            return new ProductOptionValue(value);
        } else {
            //если ввели название значения руками и такая опция есть - возвращаем его
            const searchedValue = valueItems[option.id].find((valueItem) => valueItem?.optionValue?.title === value);
            //если нет - создаём новое
            return searchedValue ? searchedValue : new ProductOptionValue({ optionValue: { title: value } });
        }
    });
}
