import ProductReviewService from '@/services/ProductReviewService';

const state = {
    counter: null,
};

const mutations = {
    SET_COUNTER(state, payload) {
        state.counter = payload;
    },
};
const actions = {
    async getCounter({ commit }, { enabled, product }) {
        const [error, result] = await ProductReviewService.getCount({ enabled, product });
        if (error) {
            error.notify();
            return;
        }
        commit('SET_COUNTER', result);
    },
};

const getters = {
    getCounterState: (state) => state.deletion,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
