import { SectionFixtureType, SectionGroupFixtureType } from '@/entities/section/fixtures';

const style1Prev1: SectionFixtureType = {
    preview: require('@/assets/images/sections/banners/banner-style1prev1.jpg'),
    title: 'Banners',
    group: 'Banners',
    data: {
        id: 'fixture',
        items: [],
        required: false,
        fixed: false,
        type: 'SectionBannerStyle1',
        options: {
            section: {
                width: 'full',
                padding: {
                    desktop: { top: 28, right: 0, bottom: 28, left: 0 },
                    tablet: { top: 28, right: 0, bottom: 28, left: 0 },
                    mobile: { top: 16, right: 0, bottom: 16, left: 0 },
                },
            },
            container: {
                alignContent: 'center',
                borderRadius: {
                    desktop: 8,
                    tablet: 8,
                    mobile: 8,
                },
            },
            heading: {
                title: 'Become our partner and get results in 14 days!',
                color: null,
            },
            content: {
                text:
                    'We will carry out a range of work for you that will increase the number of calls and applications.',
                color: null,
                media: {
                    desktop: {
                        image: '979d5f41-123c-4d69-bd55-04f59fb4a36e',
                        ratio: '2',
                        type: 'image',
                    },
                    mobile: {
                        image: '979d5f41-123c-4d69-bd55-04f59fb4a36e',
                        ratio: 'original',
                        type: 'image',
                    },
                },
            },
            button: {
                isShow: true,
                styles: 'primary',
                text: 'Start collaborating',
                textColor: null,
                bgColor: null,
                link: null,
                iconLeft: null,
                iconRight: 'arrow_forward_ios',
            },
        },
    },
};

const style2Prev1: SectionFixtureType = {
    preview: require('@/assets/images/sections/banners/banner-style2prev1.jpg'),
    title: 'Banners',
    group: 'Banners',
    data: {
        id: 'fixture',
        items: [],
        required: false,
        fixed: false,
        type: 'SectionBannerStyle2',
        options: {
            section: {
                width: 'full',
                padding: {
                    desktop: { top: 28, right: 0, bottom: 28, left: 0 },
                    tablet: { top: 28, right: 0, bottom: 28, left: 0 },
                    mobile: { top: 16, right: 0, bottom: 16, left: 0 },
                },
            },
            container: {
                borderRadius: {
                    desktop: 8,
                    tablet: 8,
                    mobile: 8,
                },
            },
            heading: {
                title: 'We bring clients, not settings',
                color: null,
            },
            content: {
                text:
                    'We work with targeted traffic and leads. Client cost in our projects: from 150 to 2000 rubles. We measure and increase ROI',
                color: null,
                media: {
                    desktop: {
                        image: '48420e56-fc9a-41ef-b41f-7f38009cff88',
                        ratio: 'original',
                        type: 'image',
                    },
                    mobile: {
                        image: '48420e56-fc9a-41ef-b41f-7f38009cff88',
                        ratio: 'original',
                        type: 'image',
                    },
                },
            },
            button: {
                isShow: true,
                styles: 'primary',
                text: 'Submit',
                textColor: null,
                bgColor: null,
                link: null,
                iconLeft: null,
                iconRight: null,
            },
        },
    },
};

const style2Prev2: SectionFixtureType = {
    preview: require('@/assets/images/sections/banners/banner-style2prev2.jpg'),
    title: 'Banners',
    group: 'Banners',
    data: {
        id: 'fixture',
        items: [],
        required: false,
        fixed: false,
        type: 'SectionBannerStyle2',
        options: {
            section: {
                width: 'content',
                padding: {
                    desktop: {
                        top: 100,
                        right: 0,
                        bottom: 100,
                        left: 0,
                    },
                    tablet: null,
                    mobile: null,
                },
            },
            container: {
                borderRadius: {
                    desktop: 25,
                    tablet: 8,
                    mobile: 8,
                },
            },
            heading: {
                title: 'Choose our interior designs for your home',
                color: '#ffffff',
            },
            content: {
                text: 'The best masters of interior design and architects are ready to offer you their best solutions',
                color: '#dbdbdb',
                media: {
                    desktop: {
                        image: '256e4142-8b5f-423c-a3ad-1d8065a43ecd',
                        ratio: 'original',
                        type: 'image',
                    },
                    mobile: {
                        image: null,
                        ratio: 'original',
                        type: 'image',
                    },
                },
            },
            button: {
                isShow: true,
                styles: 'custom',
                text: 'Contact the designers',
                textColor: '#ffffff',
                bgColor: '#ff7b24',
                link: null,
                iconLeft: null,
                iconRight: null,
            },
        },
    },
};

const style1Prev2: SectionFixtureType = {
    preview: require('@/assets/images/sections/banners/banner-style1prev2.jpg'),
    title: 'Banners',
    group: 'Banners',
    data: {
        id: 'fixture',
        items: [],
        required: false,
        fixed: false,
        type: 'SectionBannerStyle1',
        options: {
            section: {
                width: 'content',
                padding: {
                    desktop: {
                        top: 0,
                        right: 100,
                        bottom: 100,
                        left: 100,
                    },
                    tablet: null,
                    mobile: null,
                },
            },
            container: {
                alignContent: 'center',
                borderRadius: {
                    desktop: 2,
                    tablet: 8,
                    mobile: 8,
                },
            },
            heading: {
                title: 'Furniture sale \u2014 20% OFF',
                color: '#ffffff',
            },
            content: {
                text:
                    'Have time to pick up the chair of your dreams. And also get cashback for each item in the receipt',
                color: '#ffdec7',
                media: {
                    desktop: {
                        image: '82a29be1-26fd-4b2c-81a9-47aa086823b8',
                        ratio: '1.78',
                        type: 'image',
                    },
                    mobile: {
                        image: '82a29be1-26fd-4b2c-81a9-47aa086823b8',
                        ratio: 'original',
                        type: 'image',
                    },
                },
            },
            button: {
                isShow: true,
                styles: 'critical',
                text: 'Go to sale',
                textColor: null,
                bgColor: null,
                link: null,
                iconLeft: null,
                iconRight: 'arrow_forward',
            },
        },
    },
};

const summary: SectionGroupFixtureType = {
    style1Prev1: style1Prev1,
    style1Prev2: style1Prev2,
    style2Prev1: style2Prev1,
    style2Prev2: style2Prev2,
};

export default summary;
