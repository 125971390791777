<template>
    <div class="input-image-array-builder" :class="classes">
        <div v-if="!isShowManager" class="input-image-array-builder__items">
            <template v-if="images.length">
                <div
                    v-for="(image, index) in images"
                    :key="index"
                    :data-id="image.id"
                    class="input-image-array-builder__item"
                >
                    <div
                        class="input-image-array-builder__item-img"
                        :class="{ 'is-selected': value && image.id === value.id }"
                    >
                        <img :src="getPreviewURI(image)" alt="preview" />
                    </div>
                </div>
            </template>
            <div v-else class="input-image-array-builder__item">
                <div class="input-image-array-builder__item-img">
                    <img :src="placeholder.uri" alt="preview" />
                </div>
            </div>
        </div>

        <div v-else class="input-image-array-builder__items">
            <ImageArrayManager
                v-model="model"
                :image-type="imageType"
                @delete-image="onDeleteImage"
                @close="closeImagesManager"
            />
        </div>
        <Button icon="upload" v-if="!isShowManager" fullwidth @click="openImagesManager">{{
            $t('entities.image.change')
        }}</Button>
        <div v-if="note" class="input-image-array-builder__note">{{ note }}</div>
    </div>
</template>

<script>
import ImageService from '@/services/ImageService';
import Button from '@/components/common/Button.vue';
import { imageUrl } from '@/helpers/values';
import ImageArrayManager from '@/components/form/controls/ImageArrayManager';
import { mapGetters } from 'vuex';

export default {
    name: 'InputImageArrayBuilder',
    inheritAttrs: false,
    props: {
        name: { type: String, default: '' },
        errors: { type: [Array, Object], default: () => [] },
        value: { type: Array, default: () => [] },
        imageType: { type: String, default: 'content' },
        note: { type: String, default: 'JPG, PNG. Recomended size: 320х432px. No more than 500kb' },
    },
    components: {
        ImageArrayManager,
        Button,
    },
    created() {
        this.getImages();
    },
    data() {
        return {
            images: [],
            isShowManager: false,
            placeholder: {
                uri: require('@/assets/images/image-input-placeholder.svg'),
                width: 100,
                height: 50,
            },
        };
    },
    computed: {
        model: {
            get() {
                return this.images;
            },
            set(images) {
                this.images = images;
                const imageIds = images.map((item) => item.id);
                this.$emit('input', imageIds);
            },
        },

        classes() {
            return {
                '-has-errors': Array.isArray(this.errors) && this.errors.length > 0,
                '-disabled': this.disabled,
            };
        },
        ...mapGetters('globalVars', { instanceFull: 'getInstanceFull' }),
    },
    methods: {
        async getImages() {
            if (this.value.length) {
                const [error, images] = await ImageService.getAll({ ids: this.value });
                if (error) {
                    error.notify();
                    return;
                }
                this.images = images;
            }
        },
        getPreviewURI(image) {
            if (image === null) return this.placeholder.uri;
            const publicUrl = `https://${this.instanceFull.primaryDomain}`;
            if (image.uri === publicUrl + '/helper/placeholder.svg') return image.uri;
            return image
                ? imageUrl() + '/thumbs/inset/300x300' + image.uri
                : require('@/assets/images/product-no-photo.png');
        },
        openImagesManager() {
            this.isShowManager = true;
        },
        closeImagesManager() {
            this.isShowManager = false;
        },
        onDeleteImage(image) {
            if (this.model.find((item) => item.id === image.id)) {
                this.model = this.model.filter((item) => item.id !== image.id);
            }
        },
    },
    watch: {
        value: function(newValue) {
            this.$emit('update-field', {
                name: this.name,
                group: this.group,
                value: newValue,
            });
        },
    },
};
</script>
<style lang="scss">
@import '@/scss/variables.scss';

.input-image-array-builder {
    &__items {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 8px;
    }
    &__item {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%;
        padding: 4px;
        &-img {
            position: relative;
            height: 0;
            padding-bottom: 100%;
            border: 1px solid var(--v-outline-base);
            border-radius: $border-radius-root;
            background: var(--v-surfase-base);

            img {
                display: block;
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                object-fit: contain;
            }
        }
    }
    &__note {
        color: $disabled;
        margin-top: 8px;
        font-size: 12px;
        line-height: 16px;
    }
}
</style>
