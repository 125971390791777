<template>
    <div v-if="pagination.hasPrevPage || pagination.hasNextPage" class="pagination">
        <Button
            :class="pagination.pageNumber === 1 ? '-active' : null"
            type="navigation"
            class="pagination__btn"
            @click="changePage('first')"
            >1</Button
        >
        <Button
            v-for="(item, index) in pages"
            :key="`${item}-${index}`"
            :class="item && pagination.pageNumber === item ? '-active' : null"
            :disabled="!item"
            type="navigation"
            class="pagination__btn"
            @click="item ? changePage(null, item) : null"
        >
            {{ item ? item : '...' }}
        </Button>
        <Button
            :class="pagination.lastPageNumber === pagination.pageNumber ? '-active' : null"
            type="navigation"
            class="pagination__btn"
            @click="changePage('last')"
            >{{ pagination.lastPageNumber }}</Button
        >
    </div>
</template>

<script>
import Button from '@/components/common/Button';
export default {
    name: 'PagePagination',
    props: {
        pagination: {
            type: Object,
        },
        history: {
            type: Boolean,
            default: true,
        },
    },
    components: {
        Button,
    },

    created() {
        if (!this.history) this.emitChangeEvent();
    },

    methods: {
        changePage(direction, number) {
            const page = direction ? this.pagination.directions[direction] : number;
            this.pagination.pushToHistory(page);
            this.pagination.scrollToTop();
            this.$emit('update:page-number', page);
        },
    },
    computed: {
        pages() {
            const page = this.pagination.pageNumber;
            const lastPage = this.pagination.lastPageNumber;
            //null - disabled-кнопка с "...", первая и последняя страницы выводятся всегда, если страниц больше 1
            //Если страниц 7 или меньше - просто выводим все номера по порядку
            if (lastPage <= 7) {
                const arr = [];
                for (let x = 2; x < lastPage; x++) {
                    arr.push(x);
                }
                return arr;
            }
            //Если страниц больше 7, но текущая страница меньше 5 - выводим [1, 2, 3, 4, 5, "...", lastPage]
            else if (page < 5) {
                return [2, 3, 4, 5, null];
            }
            //Если страниц больше 7, текущая страница больше 5 и больше или равно lastPage - 3
            //выводим [1, "...", lastPage - 4, lastPage - 3, lastPage - 2, lastPage - 1, lastPage]
            else if (page >= lastPage - 3) {
                return [null, lastPage - 4, lastPage - 3, lastPage - 2, lastPage - 1];
            }
            //Иначе выводим [1, "...", page - 1, page, page + 1, "...", lastPage]
            return [null, page - 1, page, page + 1, null];
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.pagination {
    display: flex;
    gap: 10px;
    &__btn {
        background-color: var(--v-surface-base);
        width: 37px;
        &.-active.btn {
            background-color: var(--v-primary-accent-base) !important;
            color: white !important;
        }

        .btn {
            min-width: 0 !important;
        }
        .icon {
            margin-right: 0 !important;
        }
        &:first-child {
            margin-left: 0 !important;
        }
    }
}
</style>
