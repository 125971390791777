<template>
    <sidebar-right :heading="$t('entities.settings')">
        <form class="sidebar__form">
            <div>
                <label class="form-item__label switches-label"> {{ $t('entities.page.fields.pageStatus') }}</label>
                <form-item class="sidebar__switch">
                    <input-switch
                        :text="'builder.pageStatus.enabled'"
                        size="small"
                        name="enabled"
                        @input="updateCatalog"
                        v-model="currentState.enabled"
                    />
                </form-item>
                <form-item class="sidebar__switch">
                    <input-switch
                        :text="'builder.pageStatus.hidden'"
                        size="small"
                        @input="updateCatalog"
                        name="hidden"
                        v-model="currentState.hidden"
                    />
                </form-item>
                <form-item class="sidebar__switch">
                    <input-switch
                        :text="'builder.pageStatus.noIndex'"
                        size="small"
                        @input="updateCatalog"
                        name="noIndex"
                        v-model="currentState.noIndex"
                    />
                </form-item>
            </div>
        </form>
    </sidebar-right>
</template>

<script>
import SidebarRight from '@/components/common/SidebarRight';
import { cloneDeep, isEqual, debounce } from 'lodash';
import FormItem from '@/components/form/item';
import InputSwitch from '@/components/form/controls/InputSwitch';

export default {
    name: 'CatalogItemSidebar',
    components: { InputSwitch, FormItem, SidebarRight },
    props: {
        catalog: {
            type: Object,
        },
    },
    created() {
        this.currentState = cloneDeep(this.catalog);
    },
    data() {
        return {
            renderComponent: true,
            currentState: {},
        };
    },
    methods: {
        onUpdateFieldValue: debounce(function(payload) {
            const { name } = payload;
            this.currentState.validateField(name);
            this.updateCatalog();
        }, 300),

        updateCatalog() {
            this.$emit('change', this.currentState);
        },
    },

    watch: {
        catalog: {
            handler(val) {
                if (!isEqual(val, this.currentState)) {
                    this.currentState = cloneDeep(val);
                }
            },
            deep: true,
        },
    },
};
</script>

<style lang="scss">
.sidebar {
    &__form {
        padding: 16px;
    }
    &__switch {
        margin-bottom: 15px;
    }
    .switches-label {
        margin-bottom: 15px;
    }
}
</style>
