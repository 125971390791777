<template>
    <div id="inspire" :class="{ horizontalstyle: setHorizontalLayout }">
        <VerticalHeader v-if="!setHorizontalLayout" v-model="expandOnHover"></VerticalHeader>

        <main>
            <progress-linear class="global-loader" v-if="showGlobalLoader"></progress-linear>
            <div class="page-wrapper">
                <router-link v-if="currentRouteName !== 'Dashboard' && !backRoute" :to="{ name: 'Dashboard' }">
                    <Button class="back-btn" type="large-text" icon="chevron_left">{{
                        $t('menus.topBar.backToAdmin')
                    }}</Button>
                </router-link>
                <Button
                    v-else-if="currentRouteName !== 'Dashboard'"
                    class="back-btn"
                    @click="$router.go(-1)"
                    type="large-text"
                    icon="chevron_left"
                >
                    {{ $t('menus.topBar.back') }}
                </Button>
                <router-view></router-view>

                <!-- <Button
                    bottom
                    color="success"
                    dark
                    fab
                    fixed
                    right
                    @click.stop="setCustomizerDrawer(!Customizer_drawer)"
                >
                    <span class="material-icons-outline">mdi-cog</span>
                </Button> -->
            </div>
        </main>
        <VerticalSidebar v-if="!setHorizontalLayout" :expand-on-hover.sync="expandOnHover"></VerticalSidebar>
        <!-- <Customizer v-model="expandOnHover"></Customizer> -->

        <!-- <Footer v-if="!setHorizontalLayout"></Footer>-->
        <!-- <HorizontalFooter v-else></HorizontalFooter>-->
        <Footer></Footer>
        <transition name="overlay">
            <div v-if="showTooltips" @click="closeTooltips" class="overlay"></div>
        </transition>
        <transition name="tooltip">
            <tooltips-container
                :tooltips="tooltips"
                v-if="showTooltips"
                :config="tooltipsConfig"
                :width="tooltipsConfig.width"
            ></tooltips-container>
        </transition>
    </div>
</template>

<script>
import VerticalHeader from './vertical-header/VerticalHeader';
import VerticalSidebar from './vertical-sidebar/VerticalSidebar';
import Footer from './footer/Footer';
// import HorizontalFooter from './horizontal-footer/HorizontalFooter';
// import Customizer from './customizer/Customizer';
import { mapState } from 'vuex';
import Button from '@/components/common/Button';
import ProgressLinear from '@/components/common/ProgressLinear';
import EventEmitter from '@/helpers/eventEmitter.ts';
import TooltipsContainer from '@/layouts/full-layout/tooltipsContainer/tooltipsContainer';
export default {
    name: 'Layout',

    components: {
        TooltipsContainer,
        ProgressLinear,
        Button,
        VerticalHeader,
        VerticalSidebar,
        Footer,
        // HorizontalFooter,
        // Customizer,
    },
    created() {
        EventEmitter.on('toggle-loader', ({ isLoading }) => {
            this.showGlobalLoader = isLoading;
        });
        EventEmitter.on('toggle-tooltips', ({ isShown, tooltips = [], config = {} }) => {
            this.showTooltips = isShown;
            this.tooltipsConfig = config;
            this.tooltips = tooltips;
        });
    },

    props: {
        source: String,
    },
    data: () => ({
        expandOnHover: false,
        itemsRoutes: {
            'Product List': ['Create Product', 'Edit Product'],
            'Catalog List': ['Create Catalog', 'Edit Catalog'],
            'Category List': ['Create Category', 'Edit Category'],
            'Article List': ['Create Article', 'Edit Article'],
            'Author List': ['Create Author', 'Edit Author'],
            'Page List': ['Create Page', 'Edit Page'],
            'Lead List': ['Edit Lead'],
        },
        showGlobalLoader: false,
        showTooltips: false,
        tooltips: [],
        tooltipsConfig: {},
    }),
    computed: {
        // ...mapState(['Customizer_drawer', 'setHorizontalLayout']),
        ...mapState(['setHorizontalLayout']),
        currentRouteName() {
            return this.$route.name;
        },
        backRoute() {
            for (const route in this.itemsRoutes) {
                if (this.itemsRoutes[route]?.includes(this.currentRouteName)) {
                    return route;
                }
            }
            return null;
        },
    },

    methods: {
        // ...mapMutations({
        //     setCustomizerDrawer: 'SET_CUSTOMIZER_DRAWER',
        // }),
        closeTooltips() {
            if (this.showTooltips) {
                this.showTooltips = false;
            }
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
#inspire {
    position: relative;
}
.page-wrapper {
    padding: 0;
}
.global-loader {
    position: fixed;
}
main {
    padding: 64px 0 57px 300px !important;
    background-color: var(--v-background-base);
}
a {
    text-decoration: none;
}
.back-btn {
    & .btn {
        font-weight: 500 !important;
    }
    margin-left: 32px;
    margin-top: 25px;
    box-sizing: border-box;
    position: relative;
}
.tooltip {
    &-enter,
    &-leave-to {
        opacity: 0;
        transform: translateX(300px);
    }
    &-enter-active,
    &-leave-active {
        transition: all 0.3s ease;
    }
}

.overlay {
    position: absolute;
    z-index: 11;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.25;
    background-color: #2046a0;
    &-enter,
    &-leave-to {
        opacity: 0 !important;
    }
    &-enter-active,
    &-leave-active {
        transition: all 0.3s;
    }
}
</style>
