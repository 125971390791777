import { cloneDeep } from 'lodash';
import { Container, Content, Footer, Heading, Section } from '@/entities/section/samples/_options';
import { SELECT } from '@/helpers/fieldTypes.no-vuetify';

const section = {
    id: '',
    type: 'SectionPropertiesStyle1',
    required: false,
    group: 'Properties',
    options: {
        section: { ...Section },
        container: { ...Container },
        heading: { ...Heading },
        content: {
            htmlBefore: { ...Content.htmlBefore },
            htmlAfter: { ...Content.htmlAfter },
            colorText: { ...Content.color },
        },
        columns: {
            desktop: {
                value: 4,
                ...SELECT,
                ...{
                    props: {
                        labelTrans: 'builder.sectionOptions.props.desktop',
                        items: [2, 3, 4, 5, 6],
                    },
                },
            },
            tablet: {
                value: 2,
                ...SELECT,
                ...{
                    props: {
                        labelTrans: 'builder.sectionOptions.props.tablet',
                        items: [2, 3, 4],
                    },
                },
            },
            mobile: {
                value: 1,
                ...SELECT,
                ...{
                    props: {
                        labelTrans: 'builder.sectionOptions.props.mobile',
                        items: [1, 2],
                    },
                },
            },
        },
        footer: { ...Footer },
        // background: { ...Background },
    },
    items: [],
    children: {
        id: '',
        type: 'property',
        options: {
            heading: {
                title: {
                    ...Heading.title,
                    value: 'property name',
                },
                color: Heading.colorTitle,
            },
            content: {
                text: {
                    ...Content.textSmall,
                    value: 'property value',
                },
                color: Content.color,
            },
        },
    },
};

const itemFixture = cloneDeep(section.children);
const itemsFixture = [];
itemsFixture.push(Object.assign(cloneDeep(itemFixture), { position: 0 }));
itemsFixture.push(Object.assign(cloneDeep(itemFixture), { position: 1 }));
itemsFixture.push(Object.assign(cloneDeep(itemFixture), { position: 2 }));
itemsFixture.push(Object.assign(cloneDeep(itemFixture), { position: 3 }));
section.items = itemsFixture;

export default section;
