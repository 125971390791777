<template>
    <div class="product-image-manager  -infinite">
        <div class="product-image-manager__content -full">
            <span
                class="product-image-manager__no-images"
                v-html="$t('entities.product.noImages')"
                v-if="!isLoading && !images.length"
            ></span>
            <progress-circular size="medium" class="image-manager__progress" v-if="isLoading"></progress-circular>
            <Img
                v-else
                :controls="false"
                :image="image"
                v-for="image in images"
                :key="image.id"
                :data-id="image.id"
                :selected="value && image.id === value.id"
                @select-image="selectImage"
            ></Img>
        </div>
    </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import ImageService from '@/services/ImageService';
import Image from '@/entities/image/Image';
import { proxyModelMixin } from '@/mixins/proxyModelMixin';
import Img from '@/components/common/Img';
import { imageManagerMixin } from '@/mixins/imageManagerMixin';
import EntityImage from '@/entities/image/EntityImage';
import ProgressCircular from '@/components/common/ProgressCircular';

export default {
    name: 'ProductInfiniteImageManager',
    components: { ProgressCircular, Img },

    async mounted() {
        await this.getImages();
    },

    mixins: [proxyModelMixin, imageManagerMixin],

    props: {
        value: {
            type: Object,
            default: () => new Image(),
        },
        imageType: {
            type: String,
            default: 'product',
        },
        ownerId: {
            type: String,
            default: null,
        },
    },

    data() {
        return {
            images: [],
            selectable: true,
        };
    },

    methods: {
        async getImages() {
            this.isLoading = true;
            if (!this.images.length && this.value) this.images.push(cloneDeep(this.value));
            const [error, result] = await ImageService.getAll({
                type: this.imageType,
                notIds: [this.value?.id],
                ownerId: this.ownerId,
            });
            if (!error) {
                //TODO: << убрать после обновления товоара?
                const images = result[0]?.imageId
                    ? result.map((image) => new EntityImage(image))
                    : result.map((image) => new Image(image));
                //TODO: убрать после обновления товоара? >>
                this.images = [...this.images, ...images];
                this.isLoading = false;
            } else {
                error.notify();
                this.isLoading = false;
            }
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.infinite-loading-container {
    min-width: 150px;
}
.product-image-manager {
    &.-infinite {
        width: 100%;
        & .product-image-manager__content {
            display: flex;
            flex-wrap: wrap;
            min-height: 380px;
            height: 100%;
            gap: 24px !important;
        }
        & .image__image {
            width: 150px !important;
            height: 150px !important;
        }
    }
    &__no-images {
        width: 100%;
        text-align: center;
        font-style: italic;
        margin: auto 0;
        padding-bottom: 40px;
        font-size: 16px;
        color: var(--v-on-surface-medium-base);
        line-height: 30px;
    }
}
</style>
