<template>
    <div :class="[{ 'is-loading': loading }, 'dropzone']">
        <div class="dropzone__content">
            <span v-if="!loading" class="dropzone__icon material-icons-outlined">cloud_upload</span>
            <loader-circular v-else />
            <span class="dropzone__text text-caption">
                {{ loading ? $t('entities.image.loading') : $t('entities.image.upload') }}
            </span>
            <div slot="text" class="dropzone__text text-caption">
                <slot name="text"> </slot>
            </div>
            <input
                v-if="!loading"
                class="dropzone__input"
                type="file"
                @change="onChange"
                accept=".png, .jpg, .jpeg, .svg"
                :name="name"
                :id="id"
            />
        </div>
    </div>
</template>

<script>
import LoaderCircular from '@/components/common/ProgressCircular';
export default {
    name: 'Dropzone',
    components: { LoaderCircular },
    props: {
        name: {
            type: String,
            default: 'file',
        },
        id: {
            type: String,
            default: 'ds-dropzone',
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },

    methods: {
        onChange(event) {
            this.$emit('change', event.target.files);
        },
    },
};
</script>
