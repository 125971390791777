var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page"},[_c('div',{staticClass:"page-header"},[(_vm.productId && _vm.isLoaded)?_c('h1',{staticClass:"page-header__text"},[_vm._v("Reviews: "+_vm._s(_vm._f("capitalize")(_vm.product.title)))]):(_vm.productId && !_vm.isLoaded)?_c('progress-circular',{staticClass:"spinner"}):_c('h1',{staticClass:"page-header__text"},[_vm._v(_vm._s(_vm.$t('menus.pageHeaders.reviews')))]),_c('Button',{attrs:{"icon":"add_circle_outline"},on:{"click":function($event){return _vm.openModal(null)}}},[_vm._v(" "+_vm._s(_vm.$t('entities.add'))+" ")])],1),(!_vm.isAvailable)?_c('error-block'):[_c('ReviewModal',{attrs:{"review-id":_vm.editing.id},on:{"action-success":_vm.getItems},model:{value:(_vm.editing.openModal),callback:function ($$v) {_vm.$set(_vm.editing, "openModal", $$v)},expression:"editing.openModal"}}),_c('Table',{attrs:{"search":_vm.search,"columns":_vm.headers,"items":_vm.items,"is-data-loaded":_vm.isLoaded,"show-select":true,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"pagination":_vm.pagination},on:{"change-per-page":_vm.changePerPage,"update:sort":_vm.updateSort,"update-search-string":_vm.updateSearchString,"delete-selected-items":_vm.openDeletionModal},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"table__actions"},[_c('Button',{attrs:{"icon":"edit","type":"icon"},on:{"click":function($event){return _vm.openModal(item.id)}}}),_c('Button',{attrs:{"icon":"delete","type":"icon"},on:{"click":function($event){return _vm.openDeletionModal(item)}}})],1)]}},{key:"item.published",fn:function(ref){
var item = ref.item;
return [_c('input-switch',{on:{"input":function($event){return _vm.changeProductReview(item)}},model:{value:(item.enabled),callback:function ($$v) {_vm.$set(item, "enabled", $$v)},expression:"item.enabled"}})]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("localDate")(new Date(item.createdAt)))+" ")]}},{key:"item.text",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"reviews__text"},[_vm._v(_vm._s(item.text))])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('a',{staticClass:"reviews__email",attrs:{"title":item.email,"href":("mailto:" + (item.email)),"target":"_blank"}},[_vm._v(_vm._s(item.email))])]}},{key:"item.product",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"reviews__product",on:{"click":function($event){return _vm.openModal(item.id)}}},[_c('table-image',{attrs:{"image":_vm.getImage(item)}}),_c('span',{staticClass:"table-edit"},[(item.product)?_c('span',{staticClass:"table-edit__link product-title",attrs:{"type":"text"}},[_vm._v(_vm._s(item.product.title))]):_vm._e(),_c('span',{staticClass:"material-icons-outlined table-edit__icon"},[_vm._v("edit")])])],1)]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }