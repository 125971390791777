import { localStorageService } from '@/helpers/localStorageService';

export interface IClipboardModuleState {
    type: 'section' | 'sections' | null;
    value: any;
}

const LOCALSTOREGE_KEY: string = 'runshop_store_clipboard';

const state: IClipboardModuleState = {
    type: null,
    value: null,
};

const getters = {
    getClipboard: (state: IClipboardModuleState): IClipboardModuleState => ({
        type: state.type,
        value: state.value,
    }),
    hasContent: (state: IClipboardModuleState): boolean => !!state.type && !!state.value,
};

const mutations = {
    SET_CONTENT(state: IClipboardModuleState, payload): void {
        state.type = payload?.type ? payload.type : null;
        state.value = payload?.value ? payload.value : null;
    },
    RESET_CONTENT(state: IClipboardModuleState): void {
        state.type = null;
        state.value = null;
    },
};

const actions = {
    loadClipboard({ commit }): void {
        const loadedData = localStorageService.getItem(LOCALSTOREGE_KEY);
        const state = JSON.parse(loadedData);
        commit('SET_CONTENT', state);
    },
    saveClipboard({ getters }): void {
        const state = getters['getClipboard'];
        localStorageService.setItem(LOCALSTOREGE_KEY, JSON.stringify(state));
    },
    copyToClipboard({ commit, dispatch }, payload: IClipboardModuleState): void {
        commit('SET_CONTENT', payload);
        dispatch('saveClipboard');
    },
    resetClipboard({ commit, dispatch }): void {
        commit('RESET_CONTENT');
        dispatch('saveClipboard');
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
