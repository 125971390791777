<template>
    <div class="customizer">
        <builder />
    </div>
</template>

<script>
import Builder from '@/components/builder';

export default {
    name: 'Customizer',
    components: {
        Builder,
    },
    data() {
        return {
            // defaultSettings: getDefaultSettings({ groups: this.$options.settingGroups || [] }),
        };
    },
    computed: {},
    methods: {},
};
</script>
