<template>
    <div class="input-address" :class="classes">
        <template v-if="model !== null">
            <label class="input-address__item">
                <span class="input-address__title">Country</span>
                <Select
                    :items="countryList"
                    v-model="model.country"
                    placeholder="Choose"
                    v-on:change="onChange"
                    :disabled="disabled"
                />
            </label>
            <label v-if="regionList.length > 1" class="input-address__item">
                <span class="input-address__title">Region</span>
                <Select
                    :items="regionList"
                    v-model="model.region"
                    placeholder="Choose"
                    v-on:change="onChange"
                    :disabled="disabled"
                />
            </label>
            <label class="input-address__item">
                <span class="input-address__title">Locality</span>
                <input
                    class="input-address__field"
                    v-on:change="onChange"
                    v-model="model.locality"
                    name="address.locality"
                    :disabled="disabled"
                />
            </label>
            <label class="input-address__item">
                <span class="input-address__title">Street</span>
                <input
                    class="input-address__field"
                    v-on:change="onChange"
                    v-model="model.street"
                    name="address.street"
                    :disabled="disabled"
                />
            </label>
            <label class="input-address__item">
                <span class="input-address__title">Postal Code</span>
                <input
                    class="input-address__field"
                    v-on:change="onChange"
                    v-model="model.postalCode"
                    name="address.postalCode"
                    :disabled="disabled"
                />
            </label>
        </template>
    </div>
</template>

<script>
import { proxyModelMixin } from '@/mixins/proxyModelMixin';
import countries from '@/helpers/countries';
import Select from '@/components/form/controls/Select.vue';

export default {
    name: 'InputAddress',
    inheritAttrs: false,
    mixins: [proxyModelMixin],
    props: {
        name: { type: String, default: '' },
        group: { type: String, default: null },
        type: { type: String, default: 'text' },
        errors: { type: [Array, Object], default: () => [] },
        value: {
            type: [String, Object],
            default: () => ({
                country: '',
                region: '',
                locality: '',
                street: '',
                postalCode: '',
            }),
        },
        disabled: { type: Boolean, default: false },
    },
    components: { Select },
    data() {
        return {
            currentCountry: this.value?.country
                ? countries.find((country) => country.code === this.value?.country)
                : '',
        };
    },
    computed: {
        countryList: function() {
            return countries.map((country) => ({ text: country.name, value: country.code }));
        },
        regionList: function() {
            if (this.currentCountry) {
                const emptyVariant = [{ text: 'Empty', value: '' }];
                const currentZones = this.currentCountry.zones.map((zone) => ({ text: zone.name, value: zone.code }));
                const zones = emptyVariant.concat(currentZones);
                return zones;
            } else return [];
        },
        hasError: function() {
            return Array.isArray(this.errors) && this.errors.length > 0;
        },
        classes() {
            return {
                '-has-errors': Array.isArray(this.errors) && this.errors.length > 0,
            };
        },
    },
    beforeMount() {
        if (typeof this.value === 'string' || this.value === null) {
            const street = this.model;
            this.model = {
                country: '',
                region: '',
                locality: '',
                street: street,
                postalCode: '',
            };
        }
    },
    methods: {
        onChange(e) {
            this.$emit('change', e.target?.value);
        },
    },
    watch: {
        value: {
            handler(newValue) {
                if (newValue?.country !== this.currentCountry?.code) {
                    this.currentCountry = countries.find((country) => country.code === newValue.country);
                    this.model.region = '';
                }
                this.$emit('update-field', {
                    name: this.name,
                    group: this.group,
                    value: newValue,
                });
            },
            deep: true,
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';

.input-address {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: nowrap;
    gap: 12px;
    //height: 40px;
    transition: $form-control-transition;

    &:hover {
        border-color: var(--v-on-surface-medium-base);
    }
    &:focus-within {
        border-color: var(--v-primary-accent-base);
    }

    &__item {
        display: block;
        position: relative;
        width: 100%;
        &:hover {
            z-index: 15;
        }
    }

    &__title {
        display: inline-block;
        padding: 0 5px;
        font-size: 0.75em;
        background: #fff;
        position: relative;
        z-index: 6;
        margin-left: 8px;
        border-radius: 4px;
    }

    .select {
        width: 100%;
    }

    .select,
    input {
        margin-top: -10px;
    }

    &__field {
        display: block;
        width: 100%;
        height: 40px;
        border: $form-control-border;
        border-radius: $form-control-border-radius;
        padding: 8px $form-control-padding-horizontal;
        background: $form-control-bg;
        transition: $form-control-transition;
        &:focus {
            outline: none;
        }
        &:hover {
            border-color: var(--v-on-surface-medium-base);
        }
        &:focus-within {
            border-color: var(--v-primary-accent-base);
        }
        appearance: none;
        &::placeholder {
            color: inherit;
            opacity: 0.7;
        }

        &:disabled {
            background-color: rgba(0, 0, 0, 0.04);
            color: var(--v-on-surface-disabled-base);
            &:hover {
                border-color: $form-control-border-color !important;
            }
        }
    }

    &.-has-errors {
        border-color: $error;
    }
    &.-disabled {
        background-color: rgba(0, 0, 0, 0.04);
        color: var(--v-on-surface-disabled-base);
        &:hover {
            border-color: $form-control-border-color !important;
        }
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}
</style>
