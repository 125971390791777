<template>
    <div class="page -width-lg">
        <div class="page-header">
            <h1 class="page-header__text">{{ $t('menus.pageHeaders.authors') }}</h1>
            <Button color="primary" :to="{ name: 'Create Author' }" icon="add_circle_outline">
                {{ $t('entities.add') }}
            </Button>
        </div>

        <Table
            class="authors-table"
            :search="search"
            :is-data-loaded="isLoaded"
            :show-select="true"
            v-model="selected"
            :columns="headers"
            :items="items"
            :sort-by="sortBy"
            :sort-desc="sortDesc"
            @update:sort="updateSort"
            :pagination="pagination"
            @change-per-page="changePerPage"
            @update-search-string="updateSearchString"
            @delete-selected-items="openDeletionModal"
        >
            <template v-slot:header-select-prepend
                ><Select
                    class="author-list__type-select"
                    item-value="value"
                    item-text="text"
                    v-model="type"
                    :items="typeItems"
                ></Select
            ></template>
            <!--  PHOTO ID  -->
            <template v-slot:item.photoId="{ item }">
                <img
                    v-if="hasImage(item)"
                    class="table__image"
                    :alt="images[item.id].alt"
                    :src="imageUrl + images[item.id].uri"
                />
                <img v-else class="table__image -default" alt="No image" src="@/assets/images/no-photo.svg" />
            </template>
            <!-- NAME -->
            <template v-slot:item.name="{ item }">
                <span class="table-edit">
                    <router-link class="table-edit__link" :to="{ name: 'Edit Author', params: { id: item.id } }">{{
                        item.name
                    }}</router-link>
                    <span class="table-edit__icon material-icons-outlined">edit</span>
                </span>
            </template>
            <!-- BIO -->
            <template v-slot:item.bio="{ item }">
                <div class="author-list__bio">
                    {{ item.bio }}
                </div>
            </template>
            <!-- ACTIONS -->
            <template v-slot:item.actions="{ item }">
                <Button
                    :to="{ name: 'Edit Author', params: { id: item.id } }"
                    class="table__edit-btn"
                    icon="edit"
                    type="icon"
                />
                <Button type="icon" icon="delete" @click="openDeletionModal(item)"></Button>
            </template>
            <template v-slot:item.articlesCreatedIds="{ item }">
                <Button type="text" :to="{ name: 'Article List', query: { authorId: item.id } }">
                    {{ $t('lists.actions.viewAll') }}({{ item.articlesCreatedIds.length }})
                </Button>
            </template>
        </Table>
    </div>
</template>

<script>
import AuthorService from '@/services/AuthorService';
import SearchService from '@/services/SearchService';
import EventEmitter from '@/helpers/eventEmitter.ts';
import Table from '@/components/common/Table';
import Button from '@/components/common/Button';
import { Pagination } from '@/services/PaginationService';
import Select from '@/components/form/controls/Select';
import { imageUrl } from '@/helpers/values';

export default {
    name: 'AuthorList',
    components: {
        Select,
        Button,
        Table,
    },
    async created() {
        EventEmitter.on('delete-author', this.deleteItems);
        await this.init();
    },
    data: () => ({
        isLoaded: false,
        search: { searchable: 'author', q: '' },
        items: [],
        images: {},
        selected: [],
        authorId: null,
        sortBy: null,
        sortDesc: null,
        isAvailable: true,
        pagination: null,
        type: 'all',
        imageUrl: imageUrl(),
    }),
    computed: {
        searchString() {
            return this.$route.query.q;
        },
        pageNumber() {
            return this.$store.getters['globalVars/getAuthorsPage'];
        },
        headers() {
            return [
                { text: this.$t('lists.columns.photoId'), value: 'photoId' },
                { text: this.$t('lists.columns.name'), value: 'name' },
                { text: this.$t('lists.columns.jobTitle'), value: 'jobTitle' },
                { text: this.$t('lists.columns.bio'), value: 'bio' },
                { text: this.$t('lists.columns.articlesCreatedIds'), value: 'articlesCreatedIds', width: '110px' },
                { value: 'actions', width: '120px', sortable: false, class: 'table__actions' },
            ];
        },
        typeItems() {
            return [
                { text: this.$t('entities.author.type.all'), value: 'all' },
                { text: this.$t('entities.author.type.organization'), value: 'organization' },
                { text: this.$t('entities.author.type.person'), value: 'person' },
            ];
        },
    },
    methods: {
        async init() {
            const total = await this.getTotal();
            const limit = this.$store.getters['globalVars/getAuthorsLimit'];
            this.pagination = new Pagination({
                moduleName: 'authors',
                limit,
                pageNumber: this.pageNumber,
                total,
            });
            if (this.$route.query.q) {
                this.search.q = this.$route.query.q;
                this.previousSearch = this.$route.query.q;
            }
            await this.getItems();
        },
        async getItems() {
            this.isLoaded = false;
            this.isLoaded = false;
            const isSearching = !!this.search.q?.length;
            const searchChanged = this.search.q !== this.previousSearch;
            const params = {
                limit: this.pagination.limit,
                offset: searchChanged ? null : this.pagination.offset,
                sort: this.sortBy,
                direction: this.sortDesc ? 'desc' : this.sortDesc === false ? 'asc' : null,
                q: isSearching ? this.search.q : null,
                fields: isSearching ? ['name'] : null,
            };
            if (this.search.q?.length) {
                params.q = this.search.q;
            }
            const getter = isSearching ? this.searchAuthors : this.getAuthors;
            const [error, result] = await getter(params);
            if (error) {
                error.alert();
                this.isAvailable = false;
                return;
            }
            if (searchChanged) {
                this.pagination.pushToHistory(1);
                this.pagination.total = isSearching ? result.count : await this.getTotal();
                this.previousSearch = this.search.q;
            }
            this.items = isSearching ? result.data : result;
            const [imageError, imageResult] = await AuthorService.getImages(this.items);
            if (imageError) imageError.notify();
            this.images = imageResult;
            this.isLoaded = true;
        },
        async getAuthors(params) {
            return await AuthorService.getAll(params, this.type === 'all' ? null : this.type);
        },
        async searchAuthors(params) {
            return await SearchService.entitySearch('author', params);
        },
        async getTotal() {
            const [error, total] = await Pagination.getTotal('authors');
            if (error) {
                error.notify();
            }
            return total;
        },
        updateSearchString({ searchString }) {
            this.search.q = searchString;
            if (searchString?.length) this.pagination.reset();
            this.getItems();
        },

        hasImage(author) {
            return this.images[author.id]?.uri;
        },

        changePerPage(perPage) {
            this.pagination.changeLimit(perPage.value);
            this.perPage = perPage.value;
            this.getItems();
        },

        updateSort(sortBy, sortDesc) {
            this.sortBy = sortBy;
            this.sortDirection = sortDesc;
            this.getItems();
        },

        openDeletionModal(items) {
            this.$store.dispatch('modals/openDeletionModal', { items, entity: 'author' });
        },

        async deleteItems(items) {
            this.isLoaded = false;
            const promises = items.map((item) => AuthorService.removeOne(item.id));
            const results = await Promise.all(promises);
            await this.getItems();
            this.$store.dispatch('modals/closeDeletionModal');
            const normalized = await this.pagination.normalize(this.items);
            if (!normalized) {
                await this.getItems();
            }
            this.selected = [];
            if (
                results.every((item) => {
                    const [error] = item;
                    return !error;
                })
            ) {
                if (items.length === 1) {
                    EventEmitter.trigger('show-noty', {
                        type: 'success',
                        text: this.$tc('notifications.deleted', 1, { entity: this.$tc('entities.author.title', 1) }),
                    });
                } else {
                    EventEmitter.trigger('show-noty', {
                        type: 'success',
                        text: this.$tc('notifications.created', 2, { entity: this.$tc('entities.author.title', 2) }),
                    });
                }
            }
            this.isLoaded = true;
        },
    },
    beforeDestroy() {
        EventEmitter.off('delete-author', this.deleteItems);
    },
    watch: {
        type() {
            this.init();
        },
        pageNumber(newValue) {
            this.pagination.pageNumber = newValue;
            this.getItems();
        },
    },
};
</script>
<style lang="scss">
.author-list {
    &__type-select {
        width: 270px;
        margin-left: auto;
        margin-right: 12px;
    }
    &__bio {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 300px;
    }
}
</style>
