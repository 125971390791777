<template>
    <modal v-if="model" v-model="model" :header="isEditing ? $t('lists.editButton.rate') : $t('lists.addButton.rate')">
        <form class="modal__content form">
            <progress-circular class="modal__spinner spinner" v-if="isLoading"></progress-circular>
            <template v-else>
                <form-item class="form__input" v-bind="currentState.fields.title.props">
                    <input-text
                        @update-field="onUpdateFieldValue"
                        v-model="currentState.title"
                        name="title"
                        :label="$t('entities.rate.fields.name')"
                    ></input-text>
                </form-item>
                <form-item class="form__input" v-bind="currentState.fields.price.props">
                    <input-text
                        @update-field="onUpdateFieldValue"
                        v-model="currentState.price"
                        type="number"
                        :prefix="currency"
                        name="price"
                    ></input-text>
                </form-item>
                <form-item class="form__input" v-bind="currentState.fields.shippingSpeed.props">
                    <Select
                        @update-field="onUpdateFieldValue"
                        v-model="currentState.shippingSpeed"
                        :items="shippingSpeedItems"
                        translate
                        name="shippingSpeed"
                        :label="$t('entities.rate.fields.shippingSpeed')"
                    ></Select>
                </form-item>
                <Button
                    class="modal__pricing-btn"
                    type="text"
                    @click="handleConditions"
                    :icon="hasConditions ? 'delete' : null"
                    >{{
                        hasConditions ? $t('entities.rate.removeConditions') : $t('entities.rate.addConditions')
                    }}</Button
                >
                <template v-if="hasConditions">
                    <div class="modal__radio-group">
                        <input-radio
                            :text="$t('entities.rate.basedOnPrice')"
                            size="small"
                            v-model="basedOn"
                            return-value="price"
                        ></input-radio>
                        <input-radio
                            :text="$t('entities.rate.basedOnWeight')"
                            size="small"
                            v-model="basedOn"
                            return-value="weight"
                        ></input-radio>
                    </div>
                    <div class="range-input">
                        <form-item
                            v-bind="currentState.fields[`min${capitalize(basedOn)}`].props"
                            :label="$t(`entities.rate.fields.min${$options.filters.capitalize(basedOn)}`)"
                        >
                            <input-text
                                :prefix="basedOn === 'price' ? currency : 'kg'"
                                type="number"
                                :name="`max${capitalize(basedOn)}`"
                                @update-field="onUpdateFieldValue"
                                v-model="currentState[`min${$options.filters.capitalize(basedOn)}`]"
                            ></input-text>
                        </form-item>
                        <form-item
                            v-bind="currentState.fields[`max${capitalize(basedOn)}`].props"
                            :label="$t(`entities.rate.fields.max${$options.filters.capitalize(basedOn)}`)"
                        >
                            <input-text
                                :prefix="basedOn === 'price' ? currency : 'kg'"
                                type="number"
                                :name="`max${capitalize(basedOn)}`"
                                @update-field="onUpdateFieldValue"
                                v-model="currentState[`max${$options.filters.capitalize(basedOn)}`]"
                            ></input-text>
                        </form-item>
                    </div>
                </template>
            </template>
        </form>
        <Button class="modal__save-btn" :disabled="!isSaveAllowed" icon="check" @click="save">{{
            $t('entities.save')
        }}</Button>
    </modal>
</template>

<script>
import { proxyModelMixin } from '@/mixins/proxyModelMixin';
import Modal from '@/components/common/Modal';
import { cloneDeep, debounce, isEqual } from 'lodash';
import FormItem from '@/components/form/item';
import ShippingZoneRate from '@/entities/delivery/ShippingZoneRate';
import InputText from '@/components/form/controls/InputText';
import Select from '@/components/form/controls/Select';
import Button from '@/components/common/Button';
import InputRadio from '@/components/form/controls/InputRadio';
import ProgressCircular from '@/components/common/ProgressCircular';
import { capitalize } from '@/helpers/filters';
import EventEmitter from '@/helpers/eventEmitter';

export default {
    name: 'rateModal',
    components: { ProgressCircular, InputRadio, Select, InputText, FormItem, Modal, Button },
    mixins: [proxyModelMixin],
    data() {
        return {
            shippingSpeedItems: [
                { text: 'entities.rate.shippingSpeed.day', value: '1 day' },
                { text: 'entities.rate.shippingSpeed.upTo2Days', value: 'Up to 2 days' },
                { text: 'entities.rate.shippingSpeed.upTo3Days', value: 'Up to 3 days' },
                { text: 'entities.rate.shippingSpeed.upTo4Days', value: 'Up to 4 days' },
                { text: 'entities.rate.shippingSpeed.upTo5Days', value: 'Up to 5 days' },
                { text: 'entities.rate.shippingSpeed.upTo6Days', value: 'Up to 6 days' },
                { text: 'entities.rate.shippingSpeed.upTo1Week', value: 'Up to 1 week' },
                { text: 'entities.rate.shippingSpeed.upTo2Weeks', value: 'Up to 2 weeks' },
                { text: 'entities.rate.shippingSpeed.upTo3Weeks', value: 'Up to 3 weeks' },
                { text: 'entities.rate.shippingSpeed.upTo1Month', value: 'Up to 1 month' },
            ],
            basedOn: 'price',
            hasConditions: false,
            currentState: new ShippingZoneRate(),
            initialState: null,
            isLoading: false,
            fieldKeys: Object.keys(new ShippingZoneRate().fields),
            saved: false,
        };
    },
    props: {
        value: {
            type: Boolean,
        },
        rate: {
            type: Object,
            default: null,
        },
        isEditing: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        currency() {
            return this.$store.getters['config/getCurrency'];
        },
        isSaveAllowed() {
            if (this.initialState) {
                return !isEqual(this.initialState.data, this.currentState.data);
            }
            return false;
        },
    },
    methods: {
        save() {
            const isValid = this.validateForm();
            if (!isValid) return;
            if (this.isEditing) this.$emit('update-rate', this.currentState);
            else this.$emit('create-rate', this.currentState);
            this.saved = true;
        },
        handleConditions() {
            this.hasConditions = !this.hasConditions;
            if (!this.hasConditions) {
                this.currentState.minWeight = '';
                this.currentState.maxWeight = '';
                this.currentState.minPrice = '';
                this.currentState.maxPrice = '';
            }
        },
        onUpdateFieldValue: debounce(function(payload) {
            const { name } = payload;
            const errors = this.currentState.validateField(this.currentState.fields[name], this.currentState[name]);
            this.currentState.fields[name].props.errors = errors;
        }, 1000),
        capitalize(value) {
            return capitalize(value);
        },
        validateForm() {
            let result = true;
            const fieldKeys = Object.keys(this.currentState.fields);

            fieldKeys.forEach((key) => {
                const errors = this.currentState.validateField(this.currentState.fields[key], this.currentState[key]);
                if (errors.length !== 0) result = false;
                this.$set(this.currentState.fields[key].props, 'errors', errors);
            });
            if (result === false)
                EventEmitter.trigger('show-noty', {
                    type: 'error',
                    text: this.$t('notifications.validation.error'),
                });
            return result;
        },
    },
    watch: {
        rate(val) {
            this.isLoading = true;
            const rate = new ShippingZoneRate(val);
            this.currentState = cloneDeep(rate);
            this.initialState = cloneDeep(rate);
            if (val) {
                this.hasConditions = val.maxPrice || val.minPrice || val.maxWeight || val.minWeight;
                if (val.maxPrice || val.minPrice) {
                    this.basedOn = 'price';
                } else if (val.maxWeight || val.minWeight) {
                    this.basedOn = 'weight';
                }
            }
            setTimeout(() => (this.isLoading = false), 300);
        },
        value(val) {
            if (val) {
                // modal open
                // this.countriesList = ShippingZoneService.formatCountriesForTree();
            } else {
                // modal close
                if (!this.saved) {
                    // reset changes in modal on close without save
                    this.currentState = cloneDeep(this.initialState);
                    // this.selection = [...this.currentState.zones];
                }
                this.saved = false;
            }
        },
    },
};
</script>

<style lang="scss"></style>
