import { uuid } from 'vue-uuid';
import PropertyValue from '@/entities/property/PropertyValue';
import initFields from '@/entities/property/Property.fields';
import { uniqBy } from 'lodash';
/**
 * Класс экземпляра характеристики
 */
export default class Property {
    type = 'property';
    #isProductEntityProperty;

    /**
     *
     * @param {Object}  data - объект данных характеристики
     */
    constructor(
        {
            id = uuid.v4(),
            title = '',
            position = 0,
            global = false,
            catalogIds = [],
            values = [],
            icon = '',
            useInFilter = true,
            unit = '',
            value = {},
            propertyId = null,
        } = {},
        isProductEntityProperty = false
    ) {
        this.isProductEntityProperty = isProductEntityProperty;
        this.id = id;
        this.title = title;
        this.position = position;
        this.icon = icon;
        this.unit = unit;

        if (isProductEntityProperty) {
            this.value = new PropertyValue(value, true);
            this.propertyId = propertyId;
        } else {
            this.global = global;
            this.useInFilter = useInFilter ? 'useInFilter' : 'doNotUseInFilter';
            this.catalogIds = catalogIds;
            this.values = values.map((value) => new PropertyValue(value));
        }
        this.usage = global ? 'global' : catalogIds.length ? 'catalogs' : 'none';
        this.fields = initFields(this);
    }

    get data() {
        if (this.isProductEntityProperty) {
            return {
                id: this.id,
                //  title: this.title,
                position: this.position,
                propertyId: this.propertyId,
                value: this.value.data,
            };
        }
        return {
            id: this.id,
            title: this.title,
            position: this.position,
            global: this.global,
            useInFilter: this.useInFilter === 'useInFilter',
            icon: this.icon,
            unit: this.unit,
            catalogIds: this.global ? [] : this.catalogIds,
            values: this.values.map((propertyValue) => propertyValue.data),
        };
    }

    /**
     * Добавляем значение характеристики
     * @param {Object} valueData - объект с данными значения характеристики
     */
    addValue(valueData) {
        this.values.push(new PropertyValue(valueData));
    }

    onChangePropertyValue(values) {
        const propertyValues = values.map((value) => {
            return typeof value === 'object' ? new PropertyValue(value) : new PropertyValue({ title: value });
        });
        this.values = uniqBy(propertyValues, 'title');
    }
}
