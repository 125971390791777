<template>
    <component :is="to ? 'RouterLink' : 'OptionalWrapper'" :to="to">
        <button
            @mouseover="toggleHover"
            @mouseleave="toggleHover"
            class="btn"
            :class="classes"
            type="button"
            :title="title"
            @click="handlerClick"
        >
            <span v-if="icon" class="btn__icon material-symbols-outlined" :class="{ '-fill': iconFill }">{{
                icon
            }}</span>
            <span v-if="hasDefaultSlot">
                <img v-if="loading" src="@/assets/images/loader.svg" width="22px" alt="loader"/>
                <template v-else> <slot v-if="showHover" name="hover"></slot> <slot v-else></slot> </template
            ></span>
        </button>
    </component>
</template>

<script>
import OptionalWrapper from '@/components/common/OptionalWrapper';
export default {
    name: 'Button',
    components: { OptionalWrapper },
    props: {
        type: {
            type: String,
            default: 'primary',
            validator: (value) =>
                [
                    'primary',
                    'secondary',
                    'outlined',
                    'icon',
                    'text',
                    'large-text',
                    'navigation',
                    'success',
                    'warn',
                    'error',
                    'error-outlined',
                ].includes(value),
        },
        icon: String,
        iconFill: Boolean,
        disabled: Boolean,
        fullwidth: Boolean,
        loading: Boolean,
        small: Boolean,
        to: { type: [Object, String], default: null },
        title: String,
    },
    data() {
        return {
            showHover: false,
        };
    },
    computed: {
        hasDefaultSlot() {
            return !!this.$slots.default;
        },
        typeClass: function() {
            const obj = {};
            obj['btn-' + this.type] = true;
            return obj;
        },
        classes: function() {
            return {
                'btn-small': this.small,
                'btn-disabled': this.disabled,
                'btn-fullwidth': this.fullwidth,
                '-is-loading': this.loading,
                ...this.typeClass,
            };
        },
    },
    methods: {
        handlerClick: function(event) {
            if (!this.disabled) this.$emit('click', event);
        },
        toggleHover() {
            if (this.$slots.hover) {
                this.showHover = !this.showHover;
            }
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables/system-colors.scss';
@import '@/scss/variables.scss';
@mixin btn-color($color-default, $color-hover: $color-default, $color-pressed: $color-default) {
    color: $color-default;

    @if $color-hover != $color-default {
        &:hover {
            color: $color-hover;
        }
    }

    @if $color-pressed != $color-default {
        &:active {
            color: $color-pressed;
        }
    }
}

@mixin btn-fill($color-default, $color-hover: $color-default, $color-pressed: $color-default) {
    background-color: $color-default;

    @if $color-hover !=$color-default {
        &:hover {
            background-color: $color-hover;
        }
    }

    @if $color-pressed !=$color-hover {
        &:active {
            background-color: $color-pressed;
        }
    }
}

@mixin btn-border($color-default, $color-hover: $color-default, $color-pressed: $color-default) {
    border-color: $color-default;

    @if $color-hover != $color-default {
        &:hover {
            border-color: $color-hover;
        }
    }

    @if $color-pressed != $color-default {
        &:active {
            border-color: $color-pressed;
        }
    }
}

// styles
.btn {
    align-items: center;
    display: inline-flex;
    justify-content: center;
    flex: 0 0 auto;
    padding: 0 16px;
    font-size: 1rem;
    font-weight: 500;
    line-height: 34px;
    outline: 0;
    position: relative;
    text-decoration: none;
    transition-duration: 0.28s;
    transition-property: box-shadow, transform, opacity, background-color, color;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;
    border: 1px solid transparent;
    border-radius: 4px;
    cursor: pointer;
    font-family: Inter, sans-serif;

    span {
        // flex: 1;
        pointer-events: none;
    }

    &-fullwidth {
        width: 100%;

        span {
            flex: 0;
        }
    }

    &-primary {
        @include btn-color($text-inverted);
        @include btn-fill($primary, $primary-hover, $primary);

        &.btn-disabled {
            @include btn-color($text-inverted);
            @include btn-fill($disabled);
        }
    }

    &-secondary {
        @include btn-color($primary, $text-inverted, $text-inverted);
        @include btn-fill($secondary, $secondary-hover, $primary);

        &.btn-disabled {
            @include btn-color($text-inverted);
            @include btn-fill($disabled);
        }
    }

    &-outlined {
        @include btn-color($primary, $primary-hover, $primary);
        @include btn-fill(transparent);
        @include btn-border($primary, $primary-hover, $primary);

        &.btn-disabled {
            @include btn-color($disabled);
            @include btn-border($disabled);
        }
    }

    &-icon {
        @include btn-color($text, $primary-hover, $primary);
        @include btn-fill(transparent, $secondary, $secondary);
        width: 36px;
        height: 36px;
        border-radius: 50%;
        padding: 0;

        &.btn-disabled {
            @include btn-color($disabled);
        }
    }

    &-text {
        @include btn-color($primary, $primary-hover, $primary);
        @include btn-fill(transparent);
        @include btn-border(transparent);
        padding: 0;

        &.btn-disabled {
            @include btn-color($disabled);
        }
    }

    &-large-text {
        @include btn-color($text, $primary-hover, $primary);
        @include btn-fill(transparent);
        @include btn-border(transparent);
        padding: 0;

        &.btn-disabled {
            @include btn-color($disabled);
        }
    }

    &-success {
        @include btn-color($text-inverted);
        @include btn-fill($success, $success-hover, $success);

        &.btn-disabled {
            @include btn-color($text-inverted);
            @include btn-fill($disabled);
        }
    }

    &-warn {
        @include btn-color($text-inverted);
        @include btn-fill($warning, $warning-hover, $warning);

        &.btn-disabled {
            @include btn-color($text-inverted);
            @include btn-fill($disabled);
        }
    }

    &-error {
        @include btn-color($text-inverted);
        @include btn-fill($error, $error-hover, $error);

        &.btn-disabled {
            @include btn-color($text-inverted);
            @include btn-fill($disabled);
        }
    }

    &-error-outlined {
        @include btn-color($error, $error-hover, $error);
        @include btn-fill(transparent);
        @include btn-border($error, $error-hover, $error);

        &.btn-disabled {
            @include btn-color($disabled);
            @include btn-border($disabled);
        }
    }

    &-navigation {
        @include btn-color($text);
        @include btn-fill(transparent, $item-active, $secondary-hover);

        &.btn-disabled {
            @include btn-color($text-inverted);
            @include btn-fill($disabled);
        }
    }

    &__icon {
        width: auto;
        color: inherit;
        font-size: 18px;

        & + span {
            margin-left: 8px;
        }

        &.material-symbols-outlined {
            font-size: 18px;
            font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 48;
            &.-fill {
                font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 0;
            }
        }
    }

    &.btn-small {
        height: 24px;
        line-height: 24px;
        padding: 0 8px;
    }
    &.-is-loading {
        background-color: transparent !important;
        &:hover {
            background-color: transparent !important;
            cursor: initial;
        }
    }
}

.btn-disabled {
    cursor: not-allowed;
}
</style>
