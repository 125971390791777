import ApiClient from '@/api/apiClient';
import CustomError from '@/entities/customError';
import { SortingRequestParams } from '@/services/services.types';
import Tag from '@/entities/blog/Tag';

class TagService {
    /**
     * Получение тегов
     * @param {OptionsRequestParams} params - параметры запроса (sort, direction, ids, etc.)
     * @returns {Promise} - массив объектов тегов
     */

    async getAll(params: SortingRequestParams = {}): Promise<[CustomError | null, Tag[] | []]> {
        const url: string = `/tags`;
        const defaultValue = [];
        const errorPath: string = '[api:tag:getAll]';
        return await ApiClient.admin.get({ url, defaultValue, errorPath, config: { params } });
    }

    /**
     * Получение конкретной тега
     * @param {String} id - id тега
     * @returns {Promise} -  объект тега
     */

    async getOne(id: string): Promise<[CustomError | null, Tag | null]> {
        const url: string = `/tags/${id}`;
        const defaultValue = null;
        const errorPath: string = '[api:tag:getOne]';
        return await ApiClient.admin.get({ url, defaultValue, errorPath });
    }

    /**
     * Создание тега
     * @param {Object} tag - объект тега
     * @returns {Promise} - созданный объект тега
     */

    async createOne(tag: Tag): Promise<[CustomError | null, Tag | null]> {
        const url: string = `/tags`;
        const defaultValue = null;
        const errorPath: string = '[api:tag:createOne]';
        return await ApiClient.admin.post({ url, defaultValue, errorPath, data: tag });
    }

    /**
     * Обновление тега
     * @param {Object} tag - объект тега
     * @returns {Promise} - обновлённый объект тега
     */

    async updateOne(tag: Tag): Promise<[CustomError | null, Tag | null]> {
        const url: string = `/tags/${tag.id}`;
        const defaultValue = null;
        const errorPath: string = '[api:tag:updateOne]';
        return await ApiClient.admin.put({ url, defaultValue, errorPath, data: tag });
    }

    /**
     * Удаление тега
     * @param {String} id - id тега
     * @returns {Promise} - null
     */

    async removeOne(id: string): Promise<[CustomError | null, null]> {
        const url: string = `/tags/${id}`;
        const defaultValue = null;
        const errorPath: string = '[api:tag:removeOne]';
        return await ApiClient.admin.delete({ url, defaultValue, errorPath });
    }
}

export default new TagService();
