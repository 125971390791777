<template>
    <div v-click-outside="closeDropdown" class="select-catalog" :class="classes">
        <div class="select-catalog__field" :class="title.type" @click="toggleDropdown" ref="select">
            <span class="select-catalog__icon material-symbols-outlined">shopping_bag</span>
            <span>{{ title.text }}</span>
        </div>
        <span class="select-catalog__arrow material-symbols-outlined" :class="{ 'is-open': isDropdownShow }">
            expand_more
        </span>
        <div class="select-catalog__dropdown" :class="{ 'is-open': isDropdownShow, '-top': dropdownDirectionTop }">
            <div
                v-for="(option, index) in options"
                :key="index"
                class="select-catalog__option"
                @click="selectOption(option)"
            >
                <span>{{ $t(option.text) }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import { proxyModelMixin } from '@/mixins/proxyModelMixin';
import CatalogService from '@/services/CatalogService';

export default {
    name: 'SelectCatalog',
    inheritAttrs: false,
    mixins: [proxyModelMixin],
    props: {
        name: { type: String, default: '' },
        group: { type: String, default: null },
        placeholder: { type: String, default: 'Choose' },
        errors: { type: [Array, Object], default: () => [] },
        value: { type: [String, Number, Object, Date] },
    },
    data() {
        return {
            dropdownDirectionTop: false,
            isDropdownShow: false,
            options: [],
        };
    },
    created() {
        CatalogService.getAll().then((result) => {
            const [error, catalogs] = result;
            if (error) {
                error.alert();
                this.options = [];
                return;
            }
            this.options = catalogs
                .map((catalog) => ({
                    text: catalog.title,
                    id: catalog.id,
                    slug: catalog.slug,
                }))
                .sort((a, b) => a.text.localeCompare(b.text));
        });
    },
    computed: {
        classes() {
            return {
                '-has-errors': Array.isArray(this.errors) && this.errors.length > 0,
                '-is-open': this.isDropdownShow,
            };
        },
        title() {
            // как бы тут не было значения 0, но пока оставлю
            const selectedItem = this.options.find((option) => this.value.id === option.id);
            if (selectedItem) return { type: '-value', text: this.$t(selectedItem.text) };
            if (this.placeholder) return { type: '-placeholder', text: this.$t(this.placeholder) };
            return { type: '-placeholder', text: this.name };
        },
    },
    watch: {
        value: function(newValue) {
            this.$emit('update-field', {
                name: this.name,
                group: this.group,
                value: newValue,
            });
        },
    },
    methods: {
        selectOption(option) {
            this.model = {
                id: option.id,
                slug: option.slug,
            };
            this.closeDropdown();
        },
        openDropdown() {
            this.chooseDropdownDirection();
            this.isDropdownShow = true;
        },
        closeDropdown() {
            this.isDropdownShow = false;
        },
        toggleDropdown() {
            if (this.isDropdownShow) this.closeDropdown();
            else this.openDropdown();
        },
        chooseDropdownDirection() {
            const offsetTop = this.$refs.select.getBoundingClientRect().x;
            this.dropdownDirectionTop = window.innerHeight - offsetTop < 350;
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';

.select-catalog {
    position: relative;

    &.-is-open {
        z-index: 5;
    }

    &__field {
        display: block;
        width: 100%;
        height: $form-control-height;
        padding: 0 $form-control-padding-horizontal 0 $form-control-padding-horizontal + 24px;
        border: $form-control-border;
        border-radius: $form-control-border-radius;
        background: $form-control-bg;
        transition: $form-control-transition;
        appearance: none;
        line-height: calc(#{$form-control-height} - 2px);
        cursor: pointer;

        &:hover,
        &:focus {
            border-color: $form-control-border-color-hover;
        }

        &.-placeholder {
            color: inherit;
            opacity: 0.7;
        }
    }

    &__icon {
        position: absolute;
        left: 8px;
        top: 7px;
        pointer-events: none;
    }

    &.-has-errors &__field {
        border-color: $error;
    }

    &__arrow {
        position: absolute;
        right: 8px;
        top: 7px;
        translate: $transition-fast;
        pointer-events: none;

        &.is-open {
            transform: rotate(180deg);
        }
    }

    &__dropdown {
        position: absolute;
        left: 0;
        top: 100%;
        display: block;
        width: 100%;
        border: 1px solid #cececf;
        border-radius: $input-border-radius;
        padding: 6px 0;
        background: $input-bg;
        max-height: 300px;
        overflow-y: scroll;

        @include smooth-dropdown-hide();

        &.is-open {
            @include smooth-dropdown-show();
        }

        &.-top {
            bottom: 100%;
            top: auto;
            transform-origin: 0% 100%;
        }
    }

    &__option {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        padding: 8px 40px 8px 12px;
        transition: $transition-fast;
        font-size: 16px;
        line-height: 32px;
        text-align: left;
        cursor: pointer;

        &-icon {
            position: relative;
            top: 0;
            color: inherit;
            line-height: 24px;
            margin-right: 10px;
        }

        &:hover {
            background-color: #f4f6f7;
        }

        &.-current {
            border-bottom: 1px solid #e0e0e0;
        }

        &.-highlighted {
            color: #2979ff;
        }
    }
}
</style>
