import { Container, Content, Footer, Heading, Section } from '@/entities/section/samples/_options';
import { CHECKBOX, STRING, ADDRESS } from '@/helpers/fieldTypes.no-vuetify';

const section = {
    id: '',
    type: 'SectionContactsWithFormStyle1',
    required: false,
    group: 'Contacts',
    options: {
        section: { ...Section },
        container: { ...Container },
        heading: { ...Heading },
        content: {
            htmlBefore: { ...Content.htmlBefore },
            htmlAfter: { ...Content.htmlAfter },
            colorText: { ...Content.color },
            personalSettings: {
                value: false,
                ...CHECKBOX,
                props: {
                    noLabel: true,
                    labelTrans: 'builder.sectionOptions.props.btnPersonalSettings',
                    note: 'builder.sectionOptions.props.btnPersonalSettingsNote',
                },
            },
            address: {
                value: '',
                ...ADDRESS,
                props: {
                    labelTrans: 'Address',
                    rules: ['noEmpty'],
                    disabledBy: 'content.personalSettings',
                },
            },
            emails: {
                type: 'array',
                component: 'InputArray',
                value: [],
                props: {
                    rules: ['noEmpty', 'email'],
                    labelTrans: 'settings.common.emails',
                    disabledBy: 'content.personalSettings',
                },
            },
            phones: {
                type: 'array',
                component: 'InputArray',
                value: [],
                props: {
                    labelTrans: 'Phones',
                    rules: ['noEmpty', 'phone'],
                    disabledBy: 'content.personalSettings',
                },
            },
            whatsapps: {
                type: 'array',
                component: 'InputArray',
                value: [],
                props: {
                    labelTrans: 'WhatsApps',
                    rules: ['noEmpty', 'phone'],
                    disabledBy: 'content.personalSettings',
                },
            },
            workTime: {
                value: '',
                ...STRING,
                props: {
                    labelTrans: 'Work time',
                    rules: ['noEmpty'],
                    disabledBy: 'content.personalSettings',
                },
            },
        },
        footer: { ...Footer },
    },
    items: [],
    children: null,
};

export default section;
