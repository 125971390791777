import { uuid } from 'vue-uuid';
import OptionValue from '../option/OptionValue';
import ProductOptionEntryValue from './ProductOptionEntryValue';

/**
 * Класс экземпляра значения опции
 */
export default class ProductOptionValue {
    entryValue = null;

    /**
     *
     * @param {Object}  data - объект данных значения опции
     */
    constructor({ id = uuid.v4(), optionValue = {}, entryValue = {} } = {}) {
        this.id = id;
        this.optionValue = new OptionValue(optionValue);
        this.entryValue = new ProductOptionEntryValue(entryValue);
        this.entryValue.optionValueId = this.optionValue.id;
        this.entryValue.title = this.optionValue.title;
    }

    get data() {
        return {
            id: this.id,
            title: this.title,
            position: this.position,
        };
    }
}
