<template>
    <div class="directory-tree">
        <directory-tree-list :list="items" v-model="modelValue" />
    </div>
</template>

<script>
export default {
    name: 'DirectoryTree',
    components: {
        DirectoryTreeList: () => import('@/components/base/SimpleFileManager/DirectoryTree/DirectoryTreeList.vue'),
    },
    props: {
        items: {
            type: Array,
            required: true,
        },
        value: {
            type: Array,
        },
        onSelect: {
            type: Function,
            default: () => {},
        },
    },
    computed: {
        modelValue: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.directory-tree {
}
</style>
