/**
 * Конвертирует объекты опций с value
 * Рекурсивно обходим объект, если находим поля являющиеся объектами с полем value, то присваиваем значение value полю
 * ВАЖНО: функция меняет объект, передавать лучше клон
 * @param {Object} obj - начальный объект
 * @returns {Object}
 */
export default function convertOptionsToValue(obj) {
    for (var property in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, property)) {
            if (typeof obj[property] == 'object' && obj[property] !== null) {
                if (obj[property].value !== undefined) obj[property] = obj[property].value;
                else convertOptionsToValue(obj[property]);
            }
        }
    }
    return obj;
}
