// export function instanceMiddleware({ next, to, from }) {
export function instanceMiddleware({ next, to }) {
    // console.log('instanceMiddleware');

    // если в сторе нет инстанса, то переходим на страницу выбора инстансов
    const instanceId = localStorage.getItem('runshop_instance_id');
    if (!instanceId && to.name !== 'Instance List') return next({ name: 'Instance List' });

    return next();
}
