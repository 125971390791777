<template>
    <div class="status" :class="classes">
        <span v-if="icon" class="material-icons-outlined status__icon">{{ icon }}</span> {{ text }}
    </div>
</template>

<script>
export default {
    name: 'Status',
    props: {
        text: { type: String, default: '' },
        icon: { type: String, default: '' },
        type: { type: String, default: 'success' },
    },
    computed: {
        classes() {
            return `-${this.type}`;
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.status {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 12px 2px 8px;
    gap: 4px;
    width: fit-content;
    border-radius: 50px;
    cursor: default;
    &.-success {
        color: var(--v-success-base);
        background: var(--v-success-lighten-base);
    }
    &.-warning {
        color: var(--v-warning-base);
        background: var(--v-warning-lighten-base);
    }
    &__icon {
        font-size: 18px;
    }
}
</style>
