import { debounce } from 'lodash';
import validateField from '@/helpers/validator';

export default class Form {
    /**
     * Create Form
     * @param {Object} options
     * @param {Object} options.items
     * @param {Promise} options.action - экшен, вызывается при отправке формы
     * @param {Function} options.validateHandler - срабатывает при отправление формы, если в полях есть ошибка
     * @param {Function} options.failedHandler - срабатывает при отправление формы, если в ответе есть ошибки
     * @param {Function} options.successHandler - срабатывает при успешной отправке формы
     * @param {Function} options.collectData - переопределяет функцию сбора данный формы
     * @param {Function} options.validate- переопределяет функцию валидации формы
     */
    constructor(options) {
        this.items = options.items;
        this.fieldKeys = this.createFieldKeys();
        this.initFields();
        this.errors = [];
        this.action = options.action;
        this.validateHandler = options.validateHandler;
        this.successHandler = options.successHandler;
        this.failedHandler = options.failedHandler;
        if (options.collectData) this.collectData = options.collectData(this);
        if (options.validate) this.validate = options.validate(this);
    }

    /**  получаем массив ключей полей (item с type === 'field') */
    createFieldKeys() {
        const itemKeys = Object.keys(this.items);
        const fieldKeys = [];
        itemKeys.forEach((key) => {
            if (this.items[key].type === 'field') fieldKeys.push(key);
            if (this.items[key].type === 'form') fieldKeys.push(key);
            if (this.items[key].type === 'arrayForm') fieldKeys.push(key);
        });
        return fieldKeys;
    }

    /** добавляем свойство value, если оно отсутствует */
    initFields() {
        this.fieldKeys.forEach((key) => {
            const item = this.items[key];
            const type = item.type;
            if (item.value === undefined) item.value = null;
            if (type === 'field' && item.props.rules === undefined) item.props.rules = [];
            if (type === 'field' && item.props.errors === undefined) item.props.errors = [];
        });
    }

    get hasErrorsInFields() {
        const data = this.fieldKeys.map((key) => this.items[key]);
        const fields = data.filter((item) => item.type === 'field');
        return fields.reduce((previous, current) => {
            if (Array.isArray(current.props.errors) && current.props.errors.length > 0) previous = true;
            return previous;
        }, false);
    }

    validate() {
        this.errors = []; // костыль
        const data = this.fieldKeys.map((key) => this.items[key]);
        return data.reduce((previous, current) => {
            const type = current.type;
            if (type === 'field') {
                if (current.props.validateFunction) {
                    current.props.errors = current.props.validateFunction(current, this);
                } else {
                    const isValueArray = Array.isArray(current.value);
                    // если значение - массив, то проходим по каждому элементу массива
                    /* TODO: Сейчас всегда будет ошибка, если в поле что-то есть, потому что на каждый элемент
                     TODO: массива будет возвращаться пучстой массив. Хотим ли мы вообще обходить весь массив?*/
                    if (isValueArray) current.props.errors = [];
                    //    current.props.errors = current.value.map((value) => validateField(value, current.props.rules));
                    else current.props.errors = validateField(current.value, current.props.rules);
                }
                if (current.props.errors?.length > 0) previous = false;
            }
            if (type === 'form') {
                if (!current.value.validate()) previous = false;
            }
            if (type === 'arrayForm') {
                current.value.forEach((subform) => {
                    if (!subform.validate()) previous = false;
                });
            }
            return previous;
        }, true);
    }

    // фронтовые ошибки
    validateField(fieldKey, groupKey = null, index = null) {
        let item;
        if (groupKey === null) item = this.items[fieldKey];
        if (groupKey && index === null) item = this.items[groupKey].value.items[fieldKey];
        if (index !== null) item = this.items[groupKey].value[index].items[fieldKey];

        const type = item.type;
        if (type === 'field') {
            // если есть кастамная функция валидации, то отдаем item целиком
            if (item.props.validateFunction) item.props.errors = item.props.validateFunction(item, this);
            // в противном случае обрабатываем заначение дефолтной валидацией
            else {
                const isValueArray = Array.isArray(item.value);
                // если значение - массив, то проходим по каждому элементу массива
                if (isValueArray)
                    item.props.errors = item.value.reduce((acc, value) => {
                        const errors = validateField(value, item.props.rules).filter((error) => !acc.includes(error));
                        return [...acc, ...errors];
                    }, []);
                else item.props.errors = validateField(item.value, item.props.rules);
            }
        }
        if (type === 'form') item.value.validate();
        if (type === 'arrayForm') console.log('validateField arrayForm', fieldKey); // TODO: доделать и проверить
    }

    validateFieldDebounced = debounce((fieldKey, groupKey, index) => {
        this.validateField(fieldKey, groupKey, index);
    }, 500);

    // прокидываем ошибки от бэка
    updateErrorsFromAPI(children) {
        if (!children) return;
        this.fieldKeys.forEach((key) => {
            const item = this.items[key];
            const type = item.type;
            if (type === 'field') item.props.errors = children[key] ? children[key].errors : [];
            if (type === 'form') item.value.updateErrorsFromAPI(children[key].children);
            if (type === 'arrayForm') {
                item.value.forEach((subform, index) => {
                    subform.updateErrorsFromAPI(children[key].children[index]);
                });
            }
        });
    }

    collectData() {
        return this.fieldKeys.reduce((previous, current) => {
            if (this.items[current].type === 'field') previous[current] = this.items[current].value;
            if (this.items[current].type === 'form') previous[current] = this.items[current].value.collectData();
            if (this.items[current].type === 'arrayForm')
                previous[current] = this.items[current].value.map((subform) => subform.collectData());
            return previous;
        }, {});
    }

    async submit() {
        const isValid = this.validate();
        this.validateHandler(isValid);
        if (!isValid) throw new Error('Validation Failed');
        const data = this.collectData();
        await this.action(data).then((response) => {
            const [error, result] = response;
            if (!error) this.successHandler(result);
            else {
                error.notify();
                this.errors = result.data?.errors?.errors;
                const children = result.data?.errors?.children;
                if (children) this.updateErrorsFromAPI(children);
                this.failedHandler(result);
            }
        });
    }
}
