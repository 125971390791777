import {
    Container,
    Content,
    Footer,
    Heading,
    Section,
    SectionItemsStyle,
    SectionLayout,
} from '@/entities/section/samples/_options';
import { CATEGORIES, NUMBER } from '@/helpers/fieldTypes.no-vuetify';

const section = {
    id: '',
    items: [],
    type: 'SectionMediaWidget',
    required: false,
    group: 'Media',
    options: {
        section: { ...Section },
        container: { ...Container },
        heading: { ...Heading },
        content: {
            htmlBefore: { ...Content.htmlBefore },
            htmlAfter: { ...Content.htmlAfter },
        },
        categorySlug: {
            value: '',
            ...CATEGORIES,
            props: {
                noLabel: true,
                labelTrans: 'builder.sectionOptions.props.categorySlug',
            },
        },
        limit: {
            value: 6,
            ...NUMBER,
        },
        itemsLayout: {
            ...SectionLayout,
            props: {
                types: ['list', 'carousel'],
            },
        },
        itemsStyle: {
            template: {
                ...SectionItemsStyle.createTemplate('default', [
                    { text: 'default', value: 'default' },
                    { text: 'style1', value: 'style1' },
                    { text: 'style2', value: 'style2' },
                    { text: 'style3', value: 'style3' },
                ]),
            },
            borderRadius: {
                ...SectionItemsStyle.borderRadius,
                value: 12,
                visibleCondition: (section) => {
                    const itemsStyle = section.options?.itemsStyle?.template?.value;
                    if (!itemsStyle) return false;
                    return itemsStyle !== 'default';
                },
            },
            textAlign: {
                ...Heading.textAlign,
                value: 'left',
                visibleCondition: (section) => {
                    const itemsStyle = section.options?.itemsStyle?.template?.value;
                    if (!itemsStyle) return false;
                    return itemsStyle !== 'default';
                },
            },
        },
        footer: { ...Footer },
    },
    children: null,
};

export default section;
