<template>
    <expansion>
        <template v-slot:header-left>
            <input-switch
                v-model="currentState.enabled"
                class="expansion__switch"
                @click.native.stop
                @input="toggleEnabled"
            ></input-switch>
            <div class="address-card">
                <div class="address-card__img-container" v-if="currentState.country">
                    <img class="address-card__img" :src="flag" :alt="currentState.name" />
                </div>
                <div class="address-card__text">
                    <h4 class="address-card__city expansion__heading-text">{{ currentState.name }}</h4>
                    <p class="address-card__address">{{ addressString }}</p>
                </div>
            </div>
        </template>
        <template v-slot:header-right>
            <Button
                type="icon"
                class="expansion__delete"
                icon="delete"
                @click.native.stop="$emit('delete-location', currentState)"
            >
            </Button>
        </template>
        <template v-slot>
            <div class="expansion__sub-container">
                <div class="expansion__subheader">
                    <h4>{{ $t('entities.location.commonSettings.title') }}</h4>
                </div>
                <div>
                    <form class="location-form form location-form__list">
                        <form-item class="form__input" v-bind="currentState.fields.name.props">
                            <input-text
                                name="name"
                                v-model="currentState.name"
                                @update-field="onUpdateFieldValue"
                            ></input-text>
                        </form-item>
                        <form-item class="form__input -half-width" v-bind="currentState.fields.country.props">
                            <Select
                                autocomplete
                                :items="countriesTranslated"
                                @update-field="onUpdateFieldValue"
                                v-on="getFieldEvents(currentState.fields.country.events)"
                                v-model="currentState.country"
                                @input="onChangeCountry"
                                name="country"
                            ></Select>
                        </form-item>
                        <form-item
                            v-if="regions.length"
                            class="form__input -half-width"
                            v-bind="currentState.fields.region.props"
                        >
                            <Select
                                autocomplete
                                :items="regionsTranslated"
                                @update-field="onUpdateFieldValue"
                                v-model="currentState.region"
                                item-value="value"
                                item-text="text"
                                name="region"
                            ></Select>
                        </form-item>
                        <form-item class="form__input -half-width" v-bind="currentState.fields.city.props">
                            <input-text
                                @update-field="onUpdateFieldValue"
                                v-model="currentState.city"
                                name="city"
                            ></input-text>
                        </form-item>
                        <form-item class="form__input -half-width" v-bind="currentState.fields.address.props">
                            <input-text
                                @update-field="onUpdateFieldValue"
                                v-model="currentState.address"
                                name="address"
                            ></input-text> </form-item
                        ><form-item class="form__input -half-width" v-bind="currentState.fields.apartment.props">
                            <input-text
                                @update-field="onUpdateFieldValue"
                                v-model="currentState.apartment"
                                name="apartment"
                            ></input-text>
                        </form-item>
                        <form-item
                            v-if="currentState.country"
                            class="form__input -half-width"
                            v-bind="currentState.fields.postalCode.props"
                        >
                            <input-text
                                @update-field="onUpdateFieldValue"
                                v-model="currentState.postalCode"
                                name="postalCode"
                            ></input-text>
                        </form-item>
                        <form-item class="form__input -half-width" v-bind="currentState.fields.phone.props">
                            <input-text
                                @update-field="onUpdateFieldValue"
                                v-model="currentState.phone"
                                name="phone"
                            ></input-text>
                        </form-item>
                    </form>
                </div>
                <div>
                    <span class="divider"></span>
                    <div class="expansion__subheader">
                        <input-switch
                            v-model="currentState.pickupSettings"
                            class="expansion__switch"
                            size="medium"
                            @click.native.stop
                        ></input-switch>
                        <h4 class="expansion__heading-text">{{ $t('entities.location.pickupSettings.title') }}</h4>
                    </div>
                    <div>
                        <form class="location-form form">
                            <form-item class="form__input" v-bind="currentState.fields.pickupExpectedTime.props">
                                <Select
                                    @update-field="onUpdateFieldValue"
                                    v-model="currentState.pickupExpectedTime"
                                    item-value="value"
                                    item-text="text"
                                    :items="expectedTimeItems"
                                    name="pickupExpectedTime"
                                ></Select>
                            </form-item>
                            <form-item class="form__input" v-bind="currentState.fields.pickupNotification.props">
                                <input-text
                                    @update-field="onUpdateFieldValue"
                                    v-model="currentState.pickupNotification"
                                    name="pickupNotification"
                                    type="textarea"
                                ></input-text>
                            </form-item>
                        </form>
                    </div>
                </div>
                <Button v-if="showSave" @click="onSave" icon="check" class="location-form__save-btn">{{
                    $t('entities.save')
                }}</Button>
            </div>
        </template>
    </expansion>
</template>

<script>
import countries from '@/helpers/countries';
import { isEqual, cloneDeep, debounce } from 'lodash';
import FormItem from '@/components/form/item';
import validateField from '@/helpers/validator';
import Button from '@/components/common/Button';
import Expansion from '@/components/common/Expansion';
import InputSwitch from '@/components/form/controls/InputSwitch';
import Select from '@/components/form/controls/Select';

export default {
    name: 'locationForm',
    props: {
        location: {
            type: Object,
            default: () => new Location(),
        },
        countries: {
            type: Array,
            default: () => [],
        },
    },
    components: { Select, InputSwitch, Button, Expansion, FormItem },
    created() {
        this.currentState = cloneDeep(this.location);
        this.initialState = cloneDeep(this.location);
        if (this.currentState.country) {
            this.getFlag(this.currentState.country);
            this.currentState.getDynamicLabels(this.currentState.country);
            this.currentCountry = countries.find((item) => item.code === this.currentState.country.toUpperCase());
            this.regions = this.currentCountry.zones?.map((item) => {
                return { text: item.name, value: item.code };
            });
        }
    },
    data() {
        return {
            currentCountry: null,
            regions: [],
            flag: null,
            showSave: false,
            currentState: null,
            initialState: null,
            expectedTimeItems: [
                { text: 'from 2 to 4 days', value: 'from 2 to 4 days' },
                { text: 'from 4 to 6 days', value: 'from 4 to 6 days' },
                { text: 'from 1 to 2 weeks', value: 'from 1 to 2 weeks' },
            ],
        };
    },
    computed: {
        addressString() {
            if (this.currentState.country) {
                const arr = [
                    this.currentState.address,
                    this.currentState.apartment,
                    this.currentState.city,
                    this.currentState.postalCode,
                    this.currentCountry.name,
                ];
                return arr.filter((item) => item).join(', ');
            }
            return '-';
        },
        regionsTranslated() {
            if (this.currentState.country === 'RU') {
                return this.regions.map((item) => {
                    return { ...item, text: `countries.${this.currentState.country}.zones.${item.value}` };
                });
            } else return this.regions;
        },
        countriesTranslated() {
            return this.countries.map((item) => {
                if (item.value === 'RU') {
                    return { ...item, text: `countries.${item.value}.title` };
                }
                return { ...item, text: `countries.${item.value}` };
            });
        },
    },
    methods: {
        getFlag(code) {
            this.flag = require(`@/assets/images/flags/${code.toLowerCase()}.svg`);
        },

        onSave() {
            this.$emit('update-location', this.currentState);
            this.initialState = cloneDeep(this.currentState);
        },

        onUpdateFieldValue: debounce(function(payload) {
            const { name } = payload;
            const errors = validateField(this.currentState[name], this.currentState.fields[name].props.rules);
            this.$set(this.currentState.fields[name].props, 'errors', errors);
        }, 1000),

        toggleEnabled: debounce(function() {
            this.$emit('toggle-enabled', this.currentState);
            this.initialState = cloneDeep(this.currentState);
        }, 300),

        getFieldEvents(events) {
            const fieldEvents = {};
            for (const key in events) {
                fieldEvents[key] = events[key]?.bind(this.currentState);
            }
            return fieldEvents;
        },
        onChangeCountry(newValue) {
            if (!newValue) return;
            const country = countries.find((item) => item.code === newValue);
            this.currentCountry = country;
            this.getFlag(newValue);
            this.regions = country.zones.map((item) => {
                return { text: item.name, value: item.code };
            });
            this.currentState.region = '';
            this.currentState.getDynamicLabels(this.currentState.country);
        },
    },
    watch: {
        currentState: {
            handler(newVal, oldVal) {
                this.showSave = !isEqual(newVal.data, this.initialState.data);
                if (!isEqual(newVal, oldVal)) {
                    this.$emit('updateLocation', newVal);
                }
            },
            deep: true,
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.location-form {
    padding-top: 0;
    padding-bottom: 60px;
    .form__input {
        label {
            font-size: 1rem !important;
            font-weight: 400;
            line-height: 1.4256rem;
            letter-spacing: 0.0178571429em !important;
            transform: none;
            opacity: 1;
            color: rgba(0, 0, 0, 0.87) !important;
            top: -30px;
        }

        input[type='text'] {
            font-size: 14px;
        }

        .material-icons-outlined {
            transform: scale(1.3);
            color: var(--v-on-surface-high-base);
            margin-right: 16px;
        }
    }
    &__save-btn {
        margin-top: -30px;
    }
}
</style>
