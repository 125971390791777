<template>
    <modal width="432" v-model="model" :header="$t('entities.domain.primaryModal.header')">
        <div v-if="domain" class="modal__content -deletion">
            <img src="@/assets/images/alarm.svg" alt="alarm" />
            {{ $t('entities.domain.primaryModal.text', { domain: domain.name }) }}
        </div>
        <div class="modal__footer">
            <Button class="modal__back-btn" type="secondary" icon="chevron_left" @click="model = false">{{
                $t('entities.back')
            }}</Button>
            <Button class="modal__delete-btn" icon="check" @click="changePrimary">{{ $t('entities.confirm') }}</Button>
        </div>
    </modal>
</template>

<script>
import Modal from '@/components/common/Modal';
import { proxyModelMixin } from '@/mixins/proxyModelMixin';
import Button from '@/components/common/Button';
export default {
    name: 'DomainPrimaryModal',
    components: { Button, Modal },
    mixins: [proxyModelMixin],
    props: {
        value: {
            type: Boolean,
        },
        domain: {
            type: Object,
            default: null,
        },
    },
    methods: {
        changePrimary() {
            this.$emit(`change-primary`);
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.modal {
    &__content {
        &.-deletion {
            flex-direction: row;
            font-size: 16px;
            gap: 16px;
            flex-wrap: nowrap;
        }
    }
    &__footer {
        margin-top: 32px;
        display: flex;
        justify-content: space-between;
    }
    &__delete-btn {
        width: 168px;
    }
    &__back-btn {
        width: 168px;
    }
}
</style>
