<template>
    <div class="page -width-lg">
        <div class="page-header">
            <h1 class="page-header__text">{{ $t('menus.pageHeaders.labels') }}</h1>
            <Button @click="openModal(null)" icon="add_circle_outline">
                {{ $t('entities.add') }}
            </Button>
        </div>
        <error-block v-if="!isAvailable"></error-block>
        <template v-else>
            <labels-modal @action-success="getItems" :label-id="labelId" v-model="isLabelModalOpen"></labels-modal>
            <Table
                show-select
                data-cy="products-labels-table"
                @delete-selected-items="openDeletionModal"
                local-search
                :sort-desc="sortDesc"
                :sort-by="sortBy"
                local-sort
                v-model="selected"
                :is-data-loaded="isLoaded"
                :columns="headers"
                :items="items"
                @update:sort="updateSort"
            >
                <!-- ACTIONS -->
                <template v-slot:item.actions="{ item }">
                    <div class="table__actions">
                        <Button @click="openModal(item.id)" icon="edit" type="icon" />
                        <Button @click="openDeletionModal(item)" icon="delete" type="icon" />
                    </div>
                </template>
                <!-- TITLE -->
                <template v-slot:item.title="{ item }">
                    <span class="table-edit">
                        <a @click="openModal(item.id)" type="text" class="table-edit__link">{{ item.title }}</a>
                        <span class="table-edit__icon material-icons-outlined">edit</span>
                    </span>
                </template>
                <!-- COLOR -->
                <template v-slot:item.color="{ item }">
                    <span
                        v-if="colors[item.color]"
                        class="label-list__color"
                        :style="{ backgroundColor: colors[item.color] }"
                    ></span
                    >{{ item.color }}
                </template>
            </Table>
        </template>
    </div>
</template>

<script>
import ProductLabelService from '@/services/ProductLabelService';
import LabelsModal from '@/components/product/LabelsModal';
import EventEmitter from '@/helpers/eventEmitter.ts';
import ErrorBlock from '@/components/common/ErrorBlock';
import Table from '@/components/common/Table';
import Button from '@/components/common/Button';
import ProductLabel from '@/entities/product/ProductLabel';
import { mapGetters } from 'vuex';

export default {
    name: 'ProductLabelList',
    components: {
        Button,
        ErrorBlock,
        LabelsModal,
        Table,
    },

    async created() {
        EventEmitter.on('delete-label', this.deleteItems);
        await this.getItems();
    },
    data: () => ({
        items: [],
        isLabelModalOpen: false,
        labelId: null,
        selected: [],
        isLoaded: false,
        sortBy: 'title',
        sortDesc: false,
        isAvailable: true,
        addNewBtn: {
            title: 'entities.add',
        },
    }),
    computed: {
        ...mapGetters('config', { config: 'getAllSettings' }),
        headers() {
            return [
                { text: this.$t('lists.columns.title'), value: 'title', sortable: true },
                { text: this.$t('lists.columns.color'), value: 'color', sortable: true },
                { text: this.$t('lists.columns.position'), value: 'position', sortable: true },
                { value: 'actions', width: '114px' },
            ];
        },
        colors() {
            const items = new ProductLabel().fields.color.props.items;
            return items.reduce((prev, item) => {
                prev[item] = this.config.styles[item]?.value;
                return prev;
            }, {});
        },
    },
    methods: {
        async getItems() {
            this.isLoaded = false;
            const [error, result] = await ProductLabelService.getAll();
            if (error) {
                error.alert();
                this.isAvailable = false;
                return;
            }
            this.items = result;
            this.isLoaded = true;
        },
        openDeletionModal(items) {
            this.$store.dispatch('modals/openDeletionModal', { items, entity: 'label' });
        },

        openModal(id) {
            this.isLabelModalOpen = true;
            this.labelId = id;
        },

        async deleteItems(items) {
            this.isLoaded = false;
            const promises = items.map((item) => ProductLabelService.removeOne(item.id));
            const results = await Promise.all(promises);
            this.selected = [];
            this.$store.dispatch('modals/closeDeletionModal');
            await this.getItems();
            if (
                results.every((item) => {
                    const [error] = item;
                    return !error;
                })
            ) {
                if (items.length === 1) {
                    EventEmitter.trigger('show-noty', {
                        type: 'success',
                        text: this.$tc('notifications.deleted', 1, { entity: this.$tc('entities.label.title', 1) }),
                    });
                } else {
                    EventEmitter.trigger('show-noty', {
                        type: 'success',
                        text: this.$tc('notifications.deleted', 2, { entity: this.$tc('entities.label.title', 2) }),
                    });
                }
            }
            this.isLoaded = true;
        },
        updateSort(sortBy, sortDesc) {
            this.sortBy = sortBy;
            this.sortDesc = sortDesc;
        },
    },
    beforeDestroy() {
        EventEmitter.off('delete-label', this.deleteItems);
    },
};
</script>
<style lang="scss">
.label-list {
    &__color {
        display: inline-block;
        width: 15px;
        height: 10px;
        margin-right: 8px;
        margin-bottom: -1px;
    }
}
</style>
