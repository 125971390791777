<template>
    <div class="orders" :class="{ page: !isWidget }">
        <div class="page-header" v-if="!isWidget">
            <h1 class="page-header__text">{{ $t('menus.pageHeaders.orders') }}</h1>
            <Button :to="{ name: 'Create Order' }" icon="add_circle_outline">
                {{ $t('entities.add') }}
            </Button>
        </div>
        <error-block v-if="!isAvailable"></error-block>
        <template v-else>
            <order-comment-modal
                v-if="editingOrder.orderComment"
                :comment="editingOrder.orderComment"
                v-model="isCommentModalOpen"
            ></order-comment-modal>
            <Table
                class="orders-table"
                :class="{ '-dashboard-orders': isWidget }"
                :search="search"
                :is-data-loaded="isLoaded"
                :columns="headers"
                :show-toolbar="!isWidget"
                :show-selected-actions="!isWidget"
                :show-select-all="!isWidget"
                :show-select="!isWidget"
                v-model="selected"
                :items="items"
                :sort-by="sortBy"
                :sort-desc="sortDesc"
                :pagination="pagination"
                @change-per-page="changePerPage"
                @update:sort="updateSort"
                @update-search-string="updateSearchString"
                @delete-selected-items="openDeletionModal"
            >
                <!-- ACTIONS -->
                <template v-slot:item.actions="{ item }">
                    <div class="table__actions">
                        <Button
                            class="chat-icon"
                            icon="chat"
                            @click="openCommentModal(item)"
                            type="icon"
                            :class="getIconCommentClasses(item)"
                        />
                        <Button icon="edit" :to="{ name: 'Edit Order', params: { id: item.id } }" type="icon" />
                        <Button icon="delete" @click="openDeletionModal(item)" type="icon" />
                    </div>
                </template>
                <!--  CREATED AT  -->
                <template v-slot:item.createdAt="{ item }"> {{ item.createdAt | localDate }}</template>
                <!--  CLIENT INFO  -->
                <template v-slot:item.clientInfo="{ item }">
                    {{ item.name }}<br />
                    <span>{{ item.phone }}</span>
                </template>
                <!--  TOTAL -->
                <template v-slot:item.total="{ item }"> {{ getTotalPrice(item) }}</template>
                <!--  STATUS  -->
                <template v-slot:item.status="{ item }">
                    <span class="orders__status" :style="getStatusColor(item)">{{ getStatus(item) }}</span>
                </template>
                <!--  MANAGER  -->
                <template v-slot:item.manager="{ item }">
                    <a
                        v-if="item.manager"
                        class="orders__email"
                        :title="item.manager.email"
                        :href="`mailto:${item.manager.email}`"
                        target="_blank"
                        type="text"
                        >{{ item.manager.email }}</a
                    >
                    <span v-else>—</span>
                </template>
                <!--  ORDER  -->
                <template v-slot:item.order="{ item }">
                    <span class="table-edit">
                        <router-link
                            :to="{ name: 'Edit Order', params: { id: item.id } }"
                            type="text"
                            class="table-edit__link orders__edit-link"
                            >{{ item.number }}</router-link
                        >
                        <span class="material-icons-outlined table-edit__icon">edit</span>
                    </span>
                </template>
                <!-- DELIVERY -->
                <template v-slot:item.delivery="{ item }">
                    {{ addresses[item.id] }}
                </template>
                <!--  PAYMENT  -->
                <template v-slot:item.payment="{ item }">
                    <!--                    <span class="-font-success d-block mb-1">Paid <br /></span>
                    <br />
                    Paypal-->
                    {{ $t(`entities.order.paymentMethods.${item.method}`) }}
                </template>
            </Table>
        </template>
    </div>
</template>

<script>
import OrderStateService from '@/services/OrderStateService';
import OrderService from '@/services/OrderService';
import SearchService from '@/services/SearchService';
import { Pagination } from '@/services/PaginationService';
import OrderCommentModal from '@/components/orders/OrderCommentModal';
import ErrorBlock from '@/components/common/ErrorBlock';
import EventEmitter from '@/helpers/eventEmitter.ts';
import OrderDeliveryAddress from '@/entities/order/OrderDeliveryAddress';
import Order from '@/entities/order/Order';
import Table from '@/components/common/Table';
import Button from '@/components/common/Button';
import { mapGetters } from 'vuex';

export default {
    name: 'OrderList',
    components: {
        Button,
        ErrorBlock,
        OrderCommentModal,
        Table,
    },
    async created() {
        EventEmitter.on('delete-order', this.deleteItems);
        if (!this.isWidget) {
            if (this.$route.query.q) {
                this.search.q = this.$route.query.q;
                this.previousSearch = this.$route.query.q;
            }
            const total = await this.getTotal();

            const limit = this.$store.getters['globalVars/getOrdersLimit'];
            this.pagination = new Pagination({
                moduleName: 'orders',
                limit,
                pageNumber: this.pageNumber,
                total,
            });
        }
        await this.getItems();
        await this.getStatuses();
        if (!this.isAvailable) return;
    },
    props: {
        isWidget: {
            type: Boolean,
            default: false,
        },
        userId: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            isLoaded: false,
            search: { searchable: 'order', q: '' },
            pagination: null,
            sortBy: null,
            sortDesc: null,
            isAvailable: true,
            items: [],
            previousSearch: '',
            editingOrder: {},
            isCommentModalOpen: false,
            selected: [],
            statuses: [],
        };
    },
    computed: {
        ...mapGetters('config', { isServiceCart: 'getServiceCartIsAvalible' }),
        pageNumber() {
            return this.$store.getters['globalVars/getOrdersPage'];
        },
        addresses() {
            const addresses = {};
            for (const order of this.items) {
                if (order.deliveryInformation.type === 'delivery') {
                    addresses[order.id] = OrderDeliveryAddress.formatAddress(order.deliveryInformation.deliveryAddress);
                } else addresses[order.id] = this.$t('entities.order.pickup');
            }
            return addresses;
        },
        headers() {
            const headers = [
                { text: this.$t('lists.columns.order'), value: 'order' },
                { text: this.$t('lists.columns.createdAt'), value: 'createdAt', sortable: !this.isWidget },
                { text: this.$t('lists.columns.total'), value: 'total' },
                { text: this.$t('lists.columns.status'), value: 'status', sortable: !this.isWidget },
                /*
                { text: this.$t('lists.columns.manager'), value: 'manager', sortable: !this.isWidget },
*/
                { text: this.$t('lists.columns.delivery'), value: 'delivery' },
                { text: this.$t('lists.columns.payment'), value: 'payment' },
                { value: 'actions' },
            ];
            if (this.isServiceCart) headers.splice(4, 1);
            if (!this.userId) {
                headers.splice(2, 0, { text: this.$t('lists.columns.clientInfo'), value: 'clientInfo' });
            }
            return headers;
        },
    },
    methods: {
        async getItems() {
            this.isLoaded = false;
            const isSearching = !!this.search.q?.length;
            const searchChanged = this.search.q !== this.previousSearch;
            const params = {
                limit: this.userId ? null : this.isWidget ? 5 : this.pagination.limit,
                offset: this.isWidget || searchChanged ? null : this.pagination.offset,
                product: this.productId,
                sort: this.sortBy,
                direction: this.sortDesc ? 'desc' : this.sortDesc === false ? 'asc' : null,
                q: isSearching ? this.search.q : null,
                users: this.userId ? [this.userId] : null,
            };
            const getter = isSearching ? this.searchOrders : this.getOrders;
            const [error, result] = await getter(params);
            if (searchChanged) {
                this.pagination.pushToHistory(1);
                this.pagination.total = isSearching ? result.count : await this.getTotal();
                this.previousSearch = this.search.q;
            }
            const data = isSearching ? result.data : result;
            this.items = data?.map((item) => new Order(item)) || [];
            if (error) {
                this.isAvailable = false;
                this.isLoaded = true;
                return;
            }
            this.isLoaded = true;
        },
        async getStatuses() {
            this.isLoaded = false;
            const [error, result] = await OrderStateService.getAll();
            if (error) {
                error.alert();
                this.isAvailable = false;
                return;
            }
            this.statuses = result.map((item) => ({
                value: item.id,
                text: item.serviceName,
                color: item.color,
            }));
            this.isLoaded = true;
        },
        getStatus(item) {
            if (!this.statuses.length) return '-';
            const status = this.statuses.find(({ value }) => value === item.stateId);
            return status ? status.text : '-';
        },
        getStatusColor(item) {
            if (!this.statuses.length) return {};
            const status = this.statuses.find(({ value }) => value === item.stateId);
            const hexAlpha = '1a'; // 10% прозрачности
            return status ? { backgroundColor: status.color + hexAlpha, color: status.color } : {};
        },
        openCommentModal(order) {
            this.editingOrder = order;
            this.isCommentModalOpen = true;
        },

        openDeletionModal(items) {
            this.$store.dispatch('modals/openDeletionModal', { items, entity: 'order' });
        },

        async getOrders(params) {
            return await OrderService.getAll(params);
        },

        async getTotal() {
            const [error, total] = await Pagination.getTotal('orders');
            if (error) {
                error.notify();
            }
            return total;
        },
        async searchOrders(params) {
            return await SearchService.entitySearch('order', params);
        },

        updateSearchString({ searchString }) {
            this.search.q = searchString;
            if (searchString?.length) this.pagination.reset();
            this.getItems();
        },

        updateSort(sortBy, sortDesc) {
            this.sortBy = sortBy;
            this.sortDesc = sortDesc;
            this.getItems();
        },

        changePageNumber(event) {
            this.getProductReviews(event);
        },

        async deleteItems(items) {
            this.isLoaded = false;
            const promises = [];
            items.forEach((item) => {
                promises.push(OrderService.removeOne(item.id));
                const position = this.items.indexOf(item);
                this.items.splice(position, 1);
            });
            const results = await Promise.all(promises);
            const normalized = this.isWidget ? false : await this.pagination.normalize(this.items);
            if (!this.isWidget) {
                this.pagination.total = await this.getTotal();
            }
            this.$store.dispatch('modals/closeDeletionModal');
            if (!normalized) {
                await this.getItems();
            }
            if (
                results.every((item) => {
                    const error = item[0];
                    return !error;
                })
            ) {
                if (items.length === 1) {
                    EventEmitter.trigger('show-noty', {
                        type: 'success',
                        text: this.$tc('notifications.deleted', 1, { entity: this.$tc('entities.order.title', 1) }),
                    });
                } else {
                    EventEmitter.trigger('show-noty', {
                        type: 'success',
                        text: this.$tc('notifications.deleted', 2, { entity: this.$tc('entities.order.title', 2) }),
                    });
                }
            }
            this.isLoaded = true;
        },
        changePerPage(perPage) {
            this.pagination.changeLimit(perPage.value);
            this.getItems();
        },

        getTotalPrice(order) {
            const total = order.items.reduce((acc, item) => {
                return acc + item.getFinalPrice();
            }, 0);
            return (total + order.deliveryInformation.price).toFixed(2);
        },
        getIconCommentClasses(item) {
            return {
                '-has-comment': item.orderComment,
                'btn-disabled': !item.orderComment,
            };
        },
    },
    watch: {
        pageNumber(newValue) {
            this.pagination.pageNumber = newValue;
            this.getItems();
        },
    },
    beforeDestroy() {
        EventEmitter.off('delete-order', this.deleteItems);
    },
};
</script>
<style lang="scss">
@import '@/scss/variables.scss';
@mixin overflow-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.orders {
    width: 100%;
    &__email {
        @include overflow-ellipsis;
        display: block;
        max-width: 100px;
    }
    &__edit-link {
        @include overflow-ellipsis;
        max-width: 150px;
        display: block;
        + .table-edit__icon {
            bottom: 3px;
        }
    }
    .-dashboard-orders {
        padding-top: 24px;
    }
    &__status {
        display: inline-block;
        border-radius: 50px;
        padding: 2px 12px;
        font-size: 12px;
        font-weight: 500;
    }
}
.chat-icon {
    &.-has-comment {
        color: #2979ff;
    }
}
</style>
