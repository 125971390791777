export default {
    heading: 'Billing',
    paymentMethods: {
        heading: 'Payment Methods',
        btnAdd: 'Add payment method',
        paymentTerms: 'Payment Terms: <span>Automatically pay every cycle</span>',
        columns: {
            card: 'Credit cards',
            using: 'Using',
            edit: 'Edit',
        },
        addModal: {
            heading: 'Payment Information',
        },
        form: {
            cardNumber: 'Card number',
            expiryDate: 'Expiry date',
            cvv: 'CVV',
            nameOnCard: 'Name on card',
            country: 'Country or region',
            btnSave: 'Save',
        },
    },
    subscription: {
        heading: 'Active subscription',
        btnCancel: 'Cancel subscription',
        manage: 'Manage subscription',
        cancelModal: {
            heading: 'Cancel subscription',
            text:
                "After 30 days of your subscription cancellation, all your information will be permanently deleted, and you won't be able to retrieve your information.",
            btnYes: 'Yes',
            btnNo: "No, don't want",
        },
    },
    invoice: {
        heading: 'Invoice',
        btnDownload: 'Download',
    },
};
