import { Container, Content, Heading, Footer, Section, TextStyle } from '@/entities/section/samples/_options';

const section = {
    id: '',
    type: 'SectionTextBlockStyle1',
    required: false,
    group: 'TextBlocks',
    options: {
        section: { ...Section },
        container: { ...Container },
        heading: {
            title: { ...Heading.title },
            fontStyleTitle: { ...TextStyle.fontStyle },
            colorTitle: { ...Heading.colorTitle },
        },
        content: {
            html: { ...Content.html },
        },
        footer: { ...Footer },
        // background: { ...Background },
    },
    items: [],
    children: null,
};

export default section;
