import Vue from 'vue';

/**
 * Меняет первую букву строки на заглавную
 */
export function capitalize(value) {
    if (!value) return '';
    value = value.toString();
    return value.charAt(0).toUpperCase() + value.slice(1);
}
Vue.filter('capitalize', capitalize);

/**
 * Преобразует объект даты к строке в локальном формате
 */
export function localDate(value) {
    const printDate = new Date(value);
    return new Intl.DateTimeFormat('ru', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
    }).format(printDate);
}
Vue.filter('localDate', localDate);

/**
 * Добавляет цене текущую валюту в нужном формате
 */
export function priceFormat(value, currency) {
    const template = {
        AED: `${currency} ${value}`,
        RUB: `${value} ₽`,
    };
    if (template[currency]) return template[currency];
    return `${currency} ${value}`;
}
Vue.filter('priceFormat', priceFormat);
