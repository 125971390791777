<template>
    <div class="expansion" ref="expansion" :class="{ '-open': isOpen }">
        <div class="expansion__header" ref="header" @click="toggleOpen">
            <slot name="header-left"></slot>
            <div class="header-right">
                <slot name="header-right"></slot>
                <div class="expansion-header__icon">
                    <span class="material-icons-outlined">keyboard_arrow_down</span>
                </div>
            </div>
        </div>
        <div class="expansion__body" ref="body">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Expansion',
    data() {
        return {
            isOpen: false,
        };
    },
    mounted() {
        const expansion = this.$refs.expansion;
        const header = this.$refs.header.getBoundingClientRect().height;
        expansion.style.height = header + 'px';
    },
    methods: {
        toggleOpen() {
            this.isOpen = !this.isOpen;
            const expansion = this.$refs.expansion;
            const body = this.$refs.body.getBoundingClientRect().height;
            const header = this.$refs.header.getBoundingClientRect().height;
            if (this.isOpen) {
                expansion.style.height = 'auto';
                expansion.style.minHeight = body + header + 'px';
            } else {
                expansion.style.height = header + 'px';
                expansion.style.minHeight = header + 'px';
            }
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.expansion {
    overflow: hidden;
    transition: all 0.3s;
    margin-bottom: 32px;
    border-radius: 4px;
    &__header {
        min-height: 96px;
        background-color: var(--v-surface-base);
        display: flex;
        align-items: center;
        padding: 24px;
        transition-duration: 0.3s;
        cursor: pointer;
        .expansion-header__icon {
            transition-duration: 0.3s;
            position: relative;
        }
    }
    .header-right {
        margin-left: auto;
        display: flex;
        align-items: center;
    }
    &__body {
        padding: 38px 24px;
        background-color: var(--v-surface-base);
    }
    &.-open {
        .expansion__header {
            background-color: var(--v-primary-lighten-base);
            transition-duration: 0.3s;
        }
        .expansion-header__icon {
            transform: rotate(180deg);
            transition-duration: 0.3s;
        }
    }
}
</style>
