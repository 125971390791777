<template>
    <div class="input-range" :class="classes">
        <input
            :disabled="disabled"
            type="range"
            :min="min"
            :max="max"
            class="input-range__field"
            v-on:change="onChange"
            v-model="model"
            v-bind="inputProps"
        />
        <div>Value: {{ model }}</div>
    </div>
</template>

<script>
import { proxyModelMixin } from '@/mixins/proxyModelMixin';

export default {
    name: 'InputRange',
    inheritAttrs: false,
    mixins: [proxyModelMixin],
    props: {
        name: { type: String, default: '' },
        group: { type: String, default: null },
        type: { type: String, default: 'text' },
        placeholder: { type: [String, Number], default: '' },
        errors: { type: [Array, Object], default: () => [] },
        value: { type: [String, Number, Object, Date] },
        disabled: { type: Boolean, default: false },
        min: { type: Number, default: 0 },
        max: { type: Number, default: 100 },
    },
    computed: {
        inputProps() {
            return {
                type: this.type,
                name: this.name,
                placeholder: this.placeholder,
            };
        },
        hasError: function() {
            return Array.isArray(this.errors) && this.errors.length > 0;
        },
        classes() {
            return {
                '-has-errors': Array.isArray(this.errors) && this.errors.length > 0,
                '-disabled': this.disabled,
            };
        },
    },
    methods: {
        onChange(e) {
            this.$emit('change', e.target.value);
        },
    },
    watch: {
        value: function(newValue) {
            this.$emit('update-field', {
                name: this.name,
                group: this.group,
                value: newValue,
            });
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';

.input-range {
    position: relative;
    display: block;

    &:hover {
        border-color: var(--v-on-surface-medium-base);
    }
    &:focus-within {
        border-color: var(--v-primary-accent-base);
    }
    &__field {
        display: block;
        width: 100%;
    }

    &.-has-errors {
        border-color: $error;
    }
}
</style>
