import { cloneDeep } from 'lodash';

export default class Tree {
    static createTree(items, initiallySelected = [], selectionKey) {
        const result = [];
        items.forEach((item) => {
            const node = { ...item, isNode: !!item.children };
            if (item.children) {
                node.children = Tree.createTree(node.children, initiallySelected, selectionKey);
            }
            //У узлов с потомками нет поля isSelected - они выбраны, когда выбраны все потомки
            else if (initiallySelected.length) {
                node.isSelected = initiallySelected.includes(node[selectionKey]);
            } else {
                node.isSelected = false;
            }
            result.push(node);
        });

        return result;
    }
    static getFlatNode(node) {
        const result = [];
        if (node.isNode) {
            node.children.forEach((item) => {
                result.push(...Tree.getFlatNode(item));
            });
        } else {
            result.push(node);
        }
        return result;
    }
    static getFlatNodeByCondition(node, key, value) {
        const flattened = Tree.getFlatNode(node);
        return flattened.filter((item) => item[key] === value);
    }
    static toggleNodeObject(item, key, value) {
        if (item.isNode) {
            item.children.forEach((child) => {
                Tree.toggleNodeObject(child, key, value);
            });
        } else {
            item[key] = value;
        }
    }
    static getFlatTree(tree) {
        const arr = [];
        tree.forEach((item) => {
            arr.push(...Tree.getFlatNode(item));
        });
        return arr;
    }

    static searchInNode(node, search) {
        const result = [];
        if (node.name.toLowerCase().includes(search.toLowerCase())) {
            result.push(node);
        } else {
            if (node.isNode) {
                const resultNode = cloneDeep(node);
                resultNode.children = [];
                node.children.forEach((item) => {
                    const childResult = Tree.searchInNode(item, search);
                    if (childResult.length) {
                        resultNode.children.push(...childResult);
                    }
                });
                if (resultNode.children.length) {
                    result.push(resultNode);
                }
            }
        }
        return result;
    }
}
