import ApiClient from '@/api/apiClient';
import CustomError from '@/entities/customError';
import { Location } from '@/services/services.types';

class LocationService {
    /**
     * Получение локации
     * @param {String} id - ID локации
     * @returns {Promise} объект локации
     */
    async getOne(id: string): Promise<[CustomError | null, Location | null]> {
        const url: string = `/locations/${id}`;
        const defaultValue = null;
        const errorPath: string = '[api:location:getOne]';
        return await ApiClient.admin.get({ url, defaultValue, errorPath });
    }

    /**
     * Получение локаций
     * @returns {Promise} массив объектов локаций
     */
    async getAll(): Promise<[CustomError | null, Location[] | []]> {
        const url: string = `/locations`;
        const defaultValue = [];
        const errorPath: string = '[api:location:getAll]';
        return await ApiClient.admin.get({ url, defaultValue, errorPath });
    }

    /**
     * Создание локации
     * @param {Object} location- Объект локации
     */
    async createOne(location: Location): Promise<[CustomError | null, Location | null]> {
        const url: string = `/locations`;
        const defaultValue = null;
        const errorPath: string = '[api:location:createOne]';
        return await ApiClient.admin.post({ url, defaultValue, errorPath, data: location });
    }

    /**
     * Обновление локации
     * @param {Object} location- Объект локации
     */
    async updateOne(location: Location): Promise<[CustomError | null, Location | null]> {
        const url: string = `/locations/${location.id}`;
        const defaultValue = null;
        const errorPath: string = '[api:location:updateOne]';
        return await ApiClient.admin.put({ url, defaultValue, errorPath, data: location });
    }

    /**
     * Удаление локации
     * @param {String} id- id локации
     */
    async removeOne(id: string): Promise<[CustomError | null, null]> {
        const url: string = `/locations/${id}`;
        const defaultValue = null;
        const errorPath: string = '[api:location:removeOne]';
        return await ApiClient.admin.delete({ url, defaultValue, errorPath });
    }
}

export default new LocationService();
