import { getAccessToken } from '@/api/auth';
import store from '@/store/store';
import { AuthTypes } from '@/store/Types';
import { createErrorResult, createSuccessResult } from '@/api/ResultContainer';
import { NetworkError } from '@/api/NetworkError';
import { AxiosResponse } from 'axios';

export const addJwtRequestInterceptor = async (config) => {
    return getAccessToken()
        .then(({ token }) => {
            if (config.headers === undefined) {
                config.headers = {};
            }
            config.headers.Authorization = `Bearer ${token?.value}`;
            store.commit(AuthTypes.mutations.ADD_LAST_FAILED_REQUEST, null);
            return Promise.resolve(config);
        })
        .catch(() => {
            console.log('Unable to retrieve access token. Redirecting to auth page');
            store.commit(AuthTypes.mutations.ADD_LAST_FAILED_REQUEST, config);
            store.dispatch(AuthTypes.actions.LOGOUT).then(() => {});
            return Promise.reject('Unable to retrieve access token. Redirecting to auth page');
        });
};

export const authResponseInterceptor = (response: AxiosResponse) => {
    if (response.status >= 400) {
        if (response.status === 401) {
            store.commit(AuthTypes.mutations.ADD_LAST_FAILED_REQUEST, response.config);
            store.dispatch(AuthTypes.actions.LOGOUT).then(() => {});
        }

        return createErrorResult(
            {
                statusCode: response.status,
                message: response.data.message ?? response.data ?? response.statusText,
            },
            response
        );
    } else {
        return createSuccessResult(response.data, response);
    }
};

export const axiosResponseInterceptorOnError = (error) => {
    if (!error.response || error.code === 'ECONNABORTED') {
        return Promise.reject(new NetworkError(error.request));
    } else {
        return Promise.reject(error);
    }
};
