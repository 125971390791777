import axios from 'axios';
import { addJwtRequestInterceptor, authResponseInterceptor, axiosResponseInterceptorOnError } from '@/api/interceptors';

const accountHttp = axios.create({
    baseURL: process.env.VUE_APP_ACCOUNT_API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
    validateStatus(status) {
        /**
         * 405 Method Not Allowed
         * 408 Request Timeout
         * 429 Too Many Requests
         */

        return ![405, 408, 429].includes(status) && status < 500;
    },
});

accountHttp.interceptors.response.use(authResponseInterceptor, axiosResponseInterceptorOnError);
accountHttp.interceptors.request.use(addJwtRequestInterceptor);

export default accountHttp;
