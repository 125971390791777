export default {
    type: {
        id: null,
        position: 2,
        type: 'select',
        component: 'Select',
        value: 'ecommerce',
        options: {
            variants: [
                { text: 'settings.common.types.ecommerce', value: 'ecommerce' },
                { text: 'settings.common.types.service', value: 'service' },
                { text: 'settings.common.types.serviceCart', value: 'services_cart' },
            ],
            items: [
                { text: 'settings.common.types.ecommerce', value: 'ecommerce' },
                { text: 'settings.common.types.service', value: 'service' },
                { text: 'settings.common.types.serviceCart', value: 'services_cart' },
            ],
            translate: true,
            labelTrans: 'settings.common.types.title',
        },
    },
    showAccount: {
        id: null,
        position: 2,
        type: 'boolean',
        component: 'InputCheckbox',
        value: true,
        options: {
            noLabel: true,
            text: 'settings.common.showAccount',
        },
    },
    sitename: {
        id: null,
        position: 0,
        type: 'string',
        component: 'InputText',
        value: '',
        options: {
            rules: ['noEmpty'],
            labelTrans: 'settings.common.sitename',
        },
    },
    description: {
        id: null,
        position: 1,
        type: 'string',
        component: 'InputText',
        value: '',
        options: {
            labelTrans: 'settings.common.description',
        },
    },
    phones: {
        id: null,
        position: 2,
        type: 'array',
        component: 'InputArray',
        value: [],
        options: {
            rules: ['noEmpty', 'phone'],
            labelTrans: 'settings.common.phones',
        },
    },
    phoneShowOnWidget: {
        id: null,
        position: 2,
        type: 'boolean',
        component: 'InputCheckbox',
        value: false,
        options: {
            noLabel: true,
            text: 'settings.common.phoneShowOnWidget',
        },
    },
    phonesShowOnContactsHeader: {
        id: null,
        position: 2,
        type: 'boolean',
        component: 'InputCheckbox',
        value: true,
        options: {
            noLabel: true,
            text: 'settings.common.phonesShowOnContactsHeader',
        },
    },
    phoneShowOnMobileMenu: {
        id: null,
        position: 2,
        type: 'boolean',
        component: 'InputCheckbox',
        value: true,
        options: {
            noLabel: true,
            text: 'settings.common.phoneShowOnMobileMenu',
        },
    },
    phoneShowOnMobileHeader: {
        id: null,
        position: 2,
        type: 'boolean',
        component: 'InputCheckbox',
        value: false,
        options: {
            noLabel: true,
            text: 'settings.common.phoneShowOnMobileHeader',
        },
    },
    whatsapps: {
        id: null,
        position: 3,
        type: 'array',
        component: 'InputArray',
        value: [],
        options: {
            rules: ['noEmpty', 'phone'],
            labelTrans: 'settings.common.whatsapps',
        },
    },
    whatsappShowOnWidget: {
        id: null,
        position: 2,
        type: 'boolean',
        component: 'InputCheckbox',
        value: false,
        options: {
            noLabel: true,
            text: 'settings.common.whatsappShowOnWidget',
        },
    },
    whatsappShowOnWidgetDesktop: {
        id: null,
        position: 2,
        type: 'boolean',
        component: 'InputCheckbox',
        value: false,
        options: {
            noLabel: true,
            text: 'settings.common.whatsappShowOnWidgetDesktop',
        },
    },
    whatsappShowOnContactsHeader: {
        id: null,
        position: 2,
        type: 'boolean',
        component: 'InputCheckbox',
        value: true,
        options: {
            noLabel: true,
            text: 'settings.common.whatsappShowOnContactsHeader',
        },
    },
    whatsappShowOnMobileMenu: {
        id: null,
        position: 2,
        type: 'boolean',
        component: 'InputCheckbox',
        value: true,
        options: {
            noLabel: true,
            text: 'settings.common.whatsappShowOnMobileMenu',
        },
    },
    whatsappShowOnMobileHeader: {
        id: null,
        position: 2,
        type: 'boolean',
        component: 'InputCheckbox',
        value: false,
        options: {
            noLabel: true,
            text: 'settings.common.whatsappShowOnMobileHeader',
        },
    },
    whatsappMessage: {
        id: null,
        position: 3,
        type: 'string',
        component: 'InputText',
        value: '',
        options: {
            labelTrans: 'settings.common.whatsappMessage',
        },
    },
    telegram: {
        id: null,
        type: 'url',
        component: 'InputText',
        value: '',
        options: {
            labelTrans: 'settings.socials.telegram',
            note: 'Example: https://t.me/username',
        },
        position: 5,
    },
    telegramShowOnWidget: {
        id: null,
        position: 2,
        type: 'boolean',
        component: 'InputCheckbox',
        value: false,
        options: {
            noLabel: true,
            text: 'settings.common.telegramShowOnWidget',
        },
    },
    telegramShowOnContactsHeader: {
        id: null,
        position: 2,
        type: 'boolean',
        component: 'InputCheckbox',
        value: true,
        options: {
            noLabel: true,
            text: 'settings.common.telegramShowOnContactsHeader',
        },
    },
    telegramShowOnMobileMenu: {
        id: null,
        position: 2,
        type: 'boolean',
        component: 'InputCheckbox',
        value: true,
        options: {
            noLabel: true,
            text: 'settings.common.telegramShowOnMobileMenu',
        },
    },
    telegramShowOnMobileHeader: {
        id: null,
        position: 2,
        type: 'boolean',
        component: 'InputCheckbox',
        value: false,
        options: {
            noLabel: true,
            text: 'settings.common.telegramShowOnMobileHeader',
        },
    },
    emails: {
        id: null,
        position: 4,
        type: 'array',
        component: 'InputArray',
        value: [],
        options: {
            rules: ['noEmpty', 'email'],
            labelTrans: 'settings.common.emails',
        },
    },
    emailsShowOnContactsHeader: {
        id: null,
        position: 2,
        type: 'boolean',
        component: 'InputCheckbox',
        value: true,
        options: {
            noLabel: true,
            text: 'settings.common.emailsShowOnContactsHeader',
        },
    },
    emailShowOnMobileMenu: {
        id: null,
        position: 2,
        type: 'boolean',
        component: 'InputCheckbox',
        value: true,
        options: {
            noLabel: true,
            text: 'settings.common.emailShowOnMobileMenu',
        },
    },
    emailShowOnMobileHeader: {
        id: null,
        position: 2,
        type: 'boolean',
        component: 'InputCheckbox',
        value: false,
        options: {
            noLabel: true,
            text: 'settings.common.emailShowOnMobileHeader',
        },
    },
    address: {
        id: null,
        position: 5,
        type: 'object',
        component: 'InputAddress',
        value: {
            country: '',
            region: '',
            locality: '',
            street: '',
            postalCode: '',
        },
        options: {
            labelTrans: 'settings.common.address',
        },
    },
    mapUrl: {
        id: null,
        position: 6,
        type: 'string',
        component: 'InputText',
        value:
            'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d462260.58289236523!2d55.20963516679683!3d25.155603155877923!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43496ad9c645%3A0xbde66e5084295162!2sDubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2sru!4v1673507398213!5m2!1sen!2sru',
        options: {
            labelTrans: 'builder.sectionOptions.props.mapUrl',
            note: 'builder.sectionOptions.props.mapUrlNote',
        },
        events: {
            change: function(data) {
                const isTag = data.indexOf('content="');
                if (isTag > 0) {
                    const contentStart = data.indexOf('src="') + 5;
                    let content = data.slice(contentStart);
                    const contentEnd = content.indexOf('"');
                    content = content.slice(0, contentEnd);
                    if (this?.common?.mapUrl) this.common.mapUrl.value = content;
                }
            },
        },
    },
    workTime: {
        id: null,
        position: 7,
        type: 'string',
        component: 'InputText',
        value: '<p>Saturday – Thursday<br />8:00 – 21:00<br />Friday<br />10:00 – 19:00</p>',
        options: {
            labelTrans: 'settings.common.workTime',
        },
    },
    // privacyPolicyLink: {
    //     id: null,
    //     position: 8,
    //     type: 'string',
    //     component: 'InputText',
    //     value: '/privacy-policy',
    //     options: {
    //         labelTrans: 'settings.common.privacyPolicyLink',
    //     },
    // },
    favicon: {
        id: null,
        position: 9,
        type: 'image',
        component: 'InputImageBuilder',
        value: '',
        options: {
            note: 'ICO, PNG. Recomended size: 32х32px.',
            labelTrans: 'settings.common.favicon',
        },
    },
    sharedImage: {
        id: null,
        position: 9,
        type: 'image',
        component: 'InputImageBuilder',
        value: '',
        options: {
            note: 'JPEG, PNG. Recomended size: 1200х630px.',
            labelTrans: 'settings.common.sharedImage',
        },
    },
};
