<template>
    <div class="page -column -shrunk">
        <progress-circular class="product-form__spinner spinner" v-if="isLoading && isAvailable"></progress-circular>
        <template v-else-if="!isAvailable">
            <div class="page-header">
                <h1 class="page-header__text">{{ $t('entities.user.title') }}</h1>
            </div>
            <error-block></error-block>
        </template>
        <template v-else>
            <div class="page-header">
                <h1 class="page-header__text">
                    {{ currentState.email }}
                </h1>
            </div>
            <alert type="success" class="entity-item__success-alert" v-if="isUpdated">
                {{ $tc('notifications.updated', 1, { entity: this.$tc('entities.user.title', 1) }) }}
            </alert>
            <div class="block">
                <form ref="form" class="block__body form">
                    <template>
                        <form-item class="form__input">
                            <input-switch
                                :disabled="currentState.role === 'ROLE_SUPER_ADMIN'"
                                v-model="currentState.enabled"
                                :text="$t('entities.user.fields.enabled')"
                            ></input-switch>
                        </form-item>
                        <form-item v-bind="currentState.fields.email.props" class="form__input -half-width">
                            <input-text
                                @update-field="onUpdateFieldValue"
                                v-model="currentState.email"
                                name="email"
                                v-bind="currentState.fields.email.props"
                            ></input-text>
                        </form-item>
                        <form-item v-bind="currentState.fields.role.props" class="form__input -half-width">
                            <Select
                                @update-field="onUpdateFieldValue"
                                v-model="currentState.role"
                                name="role"
                                v-bind="currentState.fields.role.props"
                                :items="userRoles"
                            ></Select>
                        </form-item>
                        <form-item v-bind="currentState.fields.password.props" class="form__input -half-width">
                            <input-text
                                @update-field="onUpdateFieldValue"
                                v-model="currentState.password"
                                v-bind="currentState.fields.password.props"
                                name="password"
                            ></input-text>
                        </form-item>
                        <form-item v-bind="currentState.fields.repeatPassword.props" class="form__input -half-width">
                            <input-text
                                @update-field="onUpdateFieldValue"
                                v-model="currentState.repeatPassword"
                                v-bind="currentState.fields.repeatPassword.props"
                                name="repeatPassword"
                            ></input-text>
                        </form-item>
                    </template>
                    <Button class="ml-auto" icon="check" :disabled="!isSaveAllowed" @click="onUpdateUser()">{{
                        $t('entities.save')
                    }}</Button>
                </form>
            </div>
        </template>
    </div>
</template>

<script>
import { USER_ROLES } from '@/services/AuthService';
import UserService from '@/services/UserService';
import User from '@/entities/user/User';
import FormItem from '@/components/form/item';
import { debounce, cloneDeep, isEqual } from 'lodash';
import EventEmitter from '@/helpers/eventEmitter.ts';
import InputSwitch from '@/components/form/controls/InputSwitch';
import InputText from '@/components/form/controls/InputText';
import Select from '@/components/form/controls/Select';
import Button from '@/components/common/Button';
import ProgressCircular from '@/components/common/ProgressCircular';
import Alert from '@/components/common/Alert';

export default {
    name: 'UserItem',
    components: { Alert, ProgressCircular, Select, InputText, InputSwitch, FormItem, Button },
    userRoles: USER_ROLES,

    data() {
        return {
            isValid: false,
            currentState: new User(),
            fieldKeys: Object.keys(new User().fields),
            isLoading: false,
            initialState: null,
            isAvailable: true,
            isSaveAllowed: false,
            isUpdated: false,
        };
    },
    computed: {
        userRoles() {
            const roles = [];
            for (const role in USER_ROLES) {
                const text = role
                    .slice(5)
                    .split('_')
                    .join('-')
                    .toLowerCase();
                roles.push({
                    text: this.$options.filters.capitalize(text),
                    value: role,
                });
            }
            return roles;
        },
        userId() {
            return this.$route.params.id;
        },
    },

    async created() {
        await this.getUser();
        this.initialState = cloneDeep(this.currentState);
    },

    methods: {
        async getUser() {
            this.isLoading = true;
            const [error, result] = await UserService.getOne(this.userId);
            this.isLoading = false;
            if (error) {
                error.alert();
                this.isAvailable = false;
                return;
            }
            this.currentState = new User(result);
        },

        async onUpdateUser() {
            const isFormValid = this.validateForm();
            if (isFormValid === false) return;
            const [error, result] = await UserService.updateOne(this.currentState.data);
            error ? this.failedHandler(result) : this.successHandler();

            this.model = false;
        },

        successHandler() {
            this.isUpdated = true;
            this.initialState = cloneDeep(this.currentState);
            this.isSaveAllowed = false;
            window.scrollTo({ top: 0, behavior: 'smooth' });
        },

        validateForm() {
            let result = true;
            this.fieldKeys.forEach((key) => {
                const errors = this.currentState.validateField(this.currentState.fields[key], this.currentState[key]);
                if (errors.length !== 0) result = false;
                this.$set(this.currentState.fields[key].props, 'errors', errors);
            });
            if (result === false)
                EventEmitter.trigger('show-noty', {
                    type: 'error',
                    text: this.$t('notifications.validation.error'),
                });
            return result;
        },

        onUpdateFieldValue: debounce(function(payload) {
            const { name } = payload;
            const errors = this.currentState.validateField(this.currentState.fields[name], this.currentState[name]);
            this.currentState.fields[name].props.errors = errors;
        }, 600),

        failedHandler(response, error) {
            error.notify();
            const children = response.data?.errors?.children;
            if (children) {
                this.fieldKeys.forEach((key) => {
                    const errors = children[key] ? children[key].errors : [];
                    if (errors) this.currentState.fields[key].props.errors = errors;
                });
            }
        },
    },
    watch: {
        currentState: {
            handler(val) {
                if (this.initialState) {
                    this.isSaveAllowed = !isEqual(val.data, this.initialState.data);
                }
            },
            deep: true,
        },
    },
};
</script>

<style scoped></style>
