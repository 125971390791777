import SectionBenefitBase from './benefits/BenefitBase';
import SectionBenefitStyle1 from './BenefitStyle1';
import SectionBenefitStyle2 from './BenefitStyle2';
import SectionBenefitStyle3 from './BenefitStyle3';
import SectionBenefitStyle4 from './BenefitStyle4';
import SectionBenefitStyle5 from './BenefitStyle5';

import SectionBreadcrumbs from './Breadcrumbs';

import SectionCategoryBase from './category/CategoryBase';
import SectionCategoryStyle1 from './category/CategoryStyle1';
import SectionCategoryStyle2 from './category/CategoryStyle2';
import SectionCategoryStyle3 from './category/CategoryStyle3';
import SectionCategoryStyle4 from './category/CategoryStyle4';
import SectionCategoryStyle5 from './category/CategoryStyle5';
import SectionCategoryStyle6 from './category/CategoryStyle6';
import SectionCategoryStyle7 from './category/CategoryStyle7';
import SectionCategoryStyle8 from './category/CategoryStyle8';

import SectionContactsStyle1 from './ContactsStyle1';
import SectionContactsStyle2 from './ContactsStyle2';
import SectionContactsStyle3 from './ContactsStyle3';
import SectionContactsWithFormStyle1 from './ContactsWithFormStyle1';

import SectionFormStyle1 from './FormStyle1';

import SectionFAQBase from './faq/FAQBase';
import SectionFAQStyle1 from './FAQStyle1';
import SectionFAQStyle2 from './FAQStyle2';

import SectionGalleryBase from './gallery/GalleryBase';
import SectionGaleryLogos from './GaleryLogos';
import SectionGaleryStyle1 from './GaleryStyle1';
import SectionGaleryStyle2 from './GaleryStyle2';
import SectionGaleryStyle3 from './GaleryStyle3';

import SectionHeadingH1 from './HeadingH1';

import SectionProductList from './ProductList';
import SectionProductWidget from './ProductWidget';
import SectionProductWidgetByItems from './ProductWidgetByItems';

import SectionSlider from './Slider';
import SectionSliderBase from './slider/SliderBase';

import SectionTextBlockBase from './textblocks/TextblockBase';

import SectionTextBlockPictureLeft from './TextBlockPictureLeft';
import SectionTextBlockPictureRight from './TextBlockPictureRight';
import SectionTextBlockPictureCenter from './TextBlockPictureCenter';
import SectionTextBlockGalleryMasonry from './TextBlockGalleryMasonry';
import SectionTextBlockStyle1 from './TextBlockStyle1';
import SectionTextBlockStyle2 from './TextBlockStyle2';
import SectionTextBlockGrid from './TextBlockGrid';
import SectionTextCustomContent from './TextCustomContent';
import SectionHoverTextWithBgStyle1 from './TextHoverWithBgStyle1';
// import SectionTestGrid from './TestGrid';
// import SectionTestList from './TestList';

import SectionPropertyBase from './properties/PropertyBase';
import PropertiesStyle1 from './PropertiesStyle1';
import PropertiesStyle2 from './PropertiesStyle2';
import PropertiesStyle3 from './PropertiesStyle3';
import PropertiesStyle4 from './PropertiesStyle4';

import SectionTestimonialsBase from './testimonials/TestimonialsBase';
import TestimonialStyle1 from './TestimonialStyle1';

import SectionBannerStyle1 from './BannerStyle1';
import SectionBannerStyle2 from './BannerStyle2';
import SectionMediaTableOfContents from './media/MediaTableOfContents';
import SectionMediaTagsList from './media/MediaTagsList';
import SectionMediaComments from './media/MediaComments';
import SectionMediaAuthors from './media/MediaAuthors';
import SectionMediaList from './media/MediaList';
import SectionMediaWidget from './media/MediaWidget';

/**
 * @typedef {Object} SectionSample
 * @property {String} type - section type
 * @property {String} group - section group
 * @property {Object} options - section options
 */

/**
 * Section Sample
 */
const SectionSamples = (() => {
    const sections = [];
    sections.push(SectionBenefitBase);
    sections.push(SectionBannerStyle1);
    sections.push(SectionBannerStyle2);
    sections.push(SectionBenefitStyle1);
    sections.push(SectionBenefitStyle2);
    sections.push(SectionBenefitStyle3);
    sections.push(SectionBenefitStyle4);
    sections.push(SectionBenefitStyle5);
    sections.push(SectionBreadcrumbs);
    sections.push(SectionCategoryBase);
    sections.push(SectionCategoryStyle1);
    sections.push(SectionCategoryStyle2);
    sections.push(SectionCategoryStyle3);
    sections.push(SectionCategoryStyle4);
    sections.push(SectionCategoryStyle5);
    sections.push(SectionCategoryStyle6);
    sections.push(SectionCategoryStyle7);
    sections.push(SectionCategoryStyle8);
    sections.push(SectionContactsStyle1);
    sections.push(SectionContactsStyle2);
    sections.push(SectionContactsStyle3);
    sections.push(SectionContactsWithFormStyle1);
    sections.push(SectionFormStyle1);
    sections.push(SectionFAQBase);
    sections.push(SectionFAQStyle1);
    sections.push(SectionFAQStyle2);
    sections.push(SectionGalleryBase);
    sections.push(SectionGaleryLogos);
    sections.push(SectionGaleryStyle1);
    sections.push(SectionGaleryStyle2);
    sections.push(SectionGaleryStyle3);
    sections.push(SectionHeadingH1);
    sections.push(SectionProductList);
    sections.push(SectionProductWidget);
    sections.push(SectionProductWidgetByItems);
    sections.push(SectionSlider);
    sections.push(SectionSliderBase);
    sections.push(SectionTextBlockBase);
    sections.push(SectionTextBlockPictureLeft);
    sections.push(SectionTextBlockPictureRight);
    sections.push(SectionTextBlockPictureCenter);
    sections.push(SectionTextBlockGalleryMasonry);
    sections.push(SectionTextBlockStyle1);
    sections.push(SectionTextBlockStyle2);
    sections.push(SectionTextBlockGrid);
    sections.push(SectionTextCustomContent);
    sections.push(SectionHoverTextWithBgStyle1);
    // sections.push(SectionTestGrid);
    sections.push(SectionPropertyBase);
    sections.push(PropertiesStyle1);
    sections.push(PropertiesStyle2);
    sections.push(PropertiesStyle3);
    sections.push(PropertiesStyle4);
    sections.push(SectionTestimonialsBase);
    sections.push(TestimonialStyle1);
    sections.push(SectionMediaTableOfContents);
    sections.push(SectionMediaTagsList);
    sections.push(SectionMediaAuthors);
    sections.push(SectionMediaList);
    sections.push(SectionMediaWidget);
    sections.push(SectionMediaComments);
    // sections.push(SectionTestList);

    const groups = sections.map((section) => section.group).filter((item, i, ar) => ar.indexOf(item) === i);

    /** @returns {SectionSample[]} Array of sections */
    const getSectionArray = () => sections;

    /** @returns {Object.<string, SectionSample>} Sections */
    const getSectionList = () => sections;

    /** @returns {String[]} Sections group */
    const getSectionGroups = () => groups;

    /** @returns {Object.<string, SectionSample[]>} */
    const getSectionsByGroups = () => {
        const results = {};
        groups.forEach((group) => {
            results[group] = sections.filter((section) => section.group === group);
        });
        return results;
    };

    /** @returns {Object.<string, SectionSample[]>} */
    const getNotRequiredSectionsByGroups = () => {
        const results = getSectionsByGroups();
        groups.forEach((group) => {
            results[group] = results[group].filter((section) => section.required === false);
            if (results[group].length === 0) delete results[group];
        });
        return results;
    };

    /** @returns {SectionSample|null} Section */
    const getSectiomByType = (type) => {
        const result = sections.find((section) => section.type === type);
        return result ? result : null;
    };

    return Object.freeze({
        getArray: getSectionArray,
        getList: getSectionList,
        getGroups: getSectionGroups,
        getByGroups: getSectionsByGroups,
        getNotRequiredByGroups: getNotRequiredSectionsByGroups,
        getByType: getSectiomByType,
    });
})();

export default SectionSamples;
