export default () => ({
    title: {
        component: 'input-text',
        props: {
            rules: { noEmpty: true },
            errors: [],
            label: 'entities.rate.fields.name',
        },
    },
    price: {
        props: {
            rules: { noEmpty: true },
            errors: [],
            label: 'entities.rate.fields.price',
        },
    },
    shippingSpeed: {
        props: {
            rules: { noEmpty: true },
            errors: [],
            label: 'entities.rate.fields.shippingSpeed',
        },
    },
    minWeight: {
        component: 'input-text',
        props: {
            rules: {
                related: ['maxWeight'],
            },
            errors: [],
        },
    },
    maxWeight: {
        component: 'input-text',
        props: {
            rules: { biggerThan: { target: 'minWeight' } },
            errors: [],
        },
    },
    minPrice: {
        component: 'input-text',
        props: {
            rules: {
                related: ['maxPrice'],
            },
            errors: [],
        },
    },
    maxPrice: {
        component: 'input-text',
        props: {
            rules: { biggerThan: { target: 'minPrice' } },
            errors: [],
        },
    },
});
