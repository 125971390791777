<template>
    <headed-block heading="Telegram" ref="telegram">
        <template v-slot:header-prepend>
            <input-switch
                v-model="form.items.enabled.value"
                class="expansion__switch"
                @click.native.stop
            ></input-switch>
            <div class="address-card">
                <div class="address-card__img-container">
                    <img class="address-card__img" src="@/assets/images/telegram.png" />
                </div>
            </div>
        </template>
        <alert type="success" class="notifications__alert" v-if="isUpdated">
            {{ $tc('notifications.updated', 2, { entity: this.$tc('entities.notifications.title', 1) }) }}
        </alert>
        <div class="form">
            <form-item class="form__input" v-bind="form.items.chatId.props">
                <input-text
                    v-model="form.items.chatId.value"
                    v-bind="form.items.chatId.props"
                    @update-field="onUpdateFieldValue"
                    name="chatId"
                ></input-text>
            </form-item>
            <form-item class="form__input" v-bind="form.items.token.props">
                <input-text
                    v-model="form.items.token.value"
                    v-bind="form.items.token.props"
                    @update-field="onUpdateFieldValue"
                    name="token"
                ></input-text>
            </form-item>
            <form-item class="form__input" v-bind="form.items.isUseTemplate.props">
                <input-checkbox
                    v-model="form.items.isUseTemplate.value"
                    :text="$t('settings.notifierTelegram.templateCustom')"
                ></input-checkbox>
            </form-item>
            <form-item class="form__input -notifierTelegramTemplate" v-bind="form.items.template.props">
                <label slot="label" class="form-item__label">
                    {{ $t(form.items.template.props.label) }}
                    <span
                        @click="showVariables()"
                        class="tooltip__btn form-item__tooltip-button material-symbols-outlined"
                        >error_outline</span
                    >
                </label>
                <Textarea
                    v-model="form.items.template.value"
                    v-bind="form.items.template.props"
                    @update-field="onUpdateFieldValue"
                    name="template"
                    :disabled="!form.items.isUseTemplate.value"
                ></Textarea>
            </form-item>
            <Button class="notifications__save-button" :disabled="!isSaveAllowed" @click="onSubmit">{{
                $t('entities.save')
            }}</Button>
        </div>
    </headed-block>
</template>

<script>
import SettingService from '@/services/SettingService';
import Form from '@/components/form/Form';
import initItems from '@/entities/notifications/TelegramNotification.fields';
import { debounce, cloneDeep, isEqual } from 'lodash';
import HeadedBlock from '@/components/common/HeadedBlock';
import FormItem from '@/components/form/item.vue';
import InputText from '@/components/form/controls/InputText';
import Textarea from '@/components/form/controls/Textarea';
import InputCheckbox from '@/components/form/controls/InputCheckbox';
import SettingItem from '@/entities/setting/SettingItem';
import EventEmitter from '@/helpers/eventEmitter';
import Button from '@/components/common/Button';
import Alert from '@/components/common/Alert';

export default {
    name: 'TelegramNotifications',
    components: {
        Button,
        Alert,
        InputCheckbox,
        InputText,
        HeadedBlock,
        FormItem,
        Textarea,
    },
    props: {
        settings: {
            type: Object,
            default: () => {},
        },
    },
    created() {
        this.form = new Form({
            action: this.formAction,
            validateHandler: this.validateHandler,
            successHandler: this.successHandler,
            failedHandler: this.failedHandler,
            items: initItems(this.settings),
        });
        this.initialState = this.form.collectData();
    },
    data() {
        return {
            form: {},
            initialState: {},
            isUpdated: false,
        };
    },
    computed: {
        isSaveAllowed() {
            return !isEqual(this.initialState, this.form.collectData());
        },
    },
    methods: {
        onSubmit() {
            this.form.submit();
        },

        validateHandler() {
            this.$forceUpdate();
            this.isUpdated = false;
        },

        async formAction(formData) {
            const settings = {};
            Object.keys(formData).forEach((key, index) => {
                const value = formData[key];
                settings[key] = new SettingItem({
                    value,
                    position: index,
                    type: (typeof value).toLowerCase(),
                });
            });
            return SettingService.updateGroup('notifierTelegram', settings);
        },

        successHandler() {
            this.initialState = cloneDeep(this.form.collectData());
            this.isUpdated = true;
            const top = this.$refs.telegram.$el.getBoundingClientRect().top;
            const offset = window.scrollY + top - 80;
            window.scrollTo({
                behavior: 'smooth',
                top: offset,
            });
        },

        onUpdateFieldValue: debounce(function(payload) {
            const { name } = payload;
            this.form.validateField(name);
        }, 1000),

        failedHandler() {
            this.isUpdated = false;
        },
        showVariables() {
            EventEmitter.trigger('toggle-tooltips', {
                isShown: true,
                tooltips: ['telegram-notification-variables'],
            });
        },
    },
};
</script>

<style scoped></style>
