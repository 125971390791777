//import RedirectService from '@/services/RedirectService';
import apiClient from '@/api/apiClient';
import CustomError from '@/entities/customError';
import { Page, SortingRequestParams } from '@/services/services.types';

class PageService {
    /**
     * Получение страниц
     * @returns {Promise} - массив объектов текстовых страниц
     * @param {SortingRequestParams} params - параметры запроса (sort, direction, ids, etc.)
     */
    async getAll(params: SortingRequestParams = {}): Promise<[CustomError | null, Page[] | []]> {
        const url: string = `/pages`;
        const defaultValue = [];
        const errorPath: string = '[api:page:getAll]';
        const config = { params };
        return await apiClient.admin.get({ url, defaultValue, errorPath, config });
    }

    /**
     * Получение конкретной страницы
     * @param {String} id - id страницы
     * @returns {Promise} - объект страницы
     */

    async getOne(id: string): Promise<[CustomError | null, Page | null]> {
        const url: string = `/pages/${id}`;
        const defaultValue = null;
        const errorPath: string = '[api:page:getOne]';
        return await apiClient.admin.get({ url, defaultValue, errorPath });
    }

    /**
     * Получение домашней страницы
     * @returns {Promise} - объект страницы
     */

    async getHomepage(): Promise<[CustomError | null, Page | null]> {
        const url: string = `/homepage`;
        const defaultValue = null;
        const errorPath: string = '[api:page:getHomepage]';
        return await apiClient.admin.get({ url, defaultValue, errorPath });
    }

    /**
     * Обновление домашней страницы
     * @param {Page} homepage - объект страницы
     * @returns {Promise} - объект обновленной страницы
     */

    async updateHomepage(homepage: Page): Promise<[CustomError | null, Page | null]> {
        const url: string = `/homepage`;
        const defaultValue = null;
        const errorPath: string = '[api:page:updateHomepage]';
        return await apiClient.admin.put({ url, defaultValue, errorPath, data: homepage });
    }

    /**
     * Создание страницы
     * @param {Page} page - объект страницы
     * @returns {Promise} - объект созданной страницы
     */

    async createOne(page: Page): Promise<[CustomError | null, Page | null]> {
        const url: string = `/pages`;
        const defaultValue = null;
        const errorPath: string = '[api:page:createOne]';
        return await apiClient.admin.post({ url, defaultValue, errorPath, data: page });
    }

    /**
     * Обновление страницы
     * @param {Page} page - объект страницы
     * @returns {Promise} - объект обновлённной страницы
     */

    async updateOne(page: Page): Promise<[CustomError | null, Page | null]> {
        const url: string = `/pages/${page.id}`;
        const defaultValue = null;
        const errorPath: string = '[api:page:updateOne]';
        const result = await apiClient.admin.put({
            url,
            defaultValue,
            errorPath,
            data: page,
        }); /*
        const redirect = page.redirect;
        if (redirect) {
            const [error] = await RedirectService.createOne(redirect);
            if (error) error.notify();
        }*/
        return result;
    }

    /**
     * Обновление нескольких страниц
     * @param {Array} pages - массив объектов страницы
     * @returns {Promise} - массив объектов обновлённных страниц
     */

    async updateBatch(pages: Page[]): Promise<[CustomError | null, Page[] | []]> {
        const url: string = `/pages/batch`;
        const defaultValue = [];
        const errorPath: string = '[api:page:updateBatch]';
        const config = { params: { ids: pages.map((item) => item.id) } };
        return await apiClient.admin.put({ url, defaultValue, errorPath, config, data: pages });
    }

    /**
     * Удаление страницы
     * @param {Array} id - id страницы
     * @returns {Promise} - null
     */

    async removeOne(id: string): Promise<[CustomError | null, null]> {
        const url: string = `/pages/${id}`;
        const defaultValue = null;
        const errorPath: string = '[api:page:removeOne]';
        return await apiClient.admin.delete({ url, defaultValue, errorPath });
    }
}

export default new PageService();
