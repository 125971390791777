import { OAuth2AuthCodePKCE } from '@bity/oauth2-auth-code-pkce';
import detectInstanceId from '@/api/detectInstanceId';

const auth = (async () => {
    const id = detectInstanceId();
    // console.log('instanceDetails', instanceDetails);
    console.log(process.env.VUE_APP_OAUTH_CLIENT_ID ?? id);
    return new OAuth2AuthCodePKCE({
        authorizationUrl: process.env.VUE_APP_OAUTH_AUTHORIZATION_URL ?? '',
        tokenUrl: process.env.VUE_APP_OAUTH_TOKEN_URL ?? '',
        clientId: process.env.VUE_APP_OAUTH_CLIENT_ID ?? id,
        scopes: ['openid', 'profile'],
        // redirectUrl: window.location.origin + '/' + id + '/' + process.env.VUE_APP_OAUTH_REDIRECT_URI ?? '',
        redirectUrl: window.location.origin + '/' + process.env.VUE_APP_OAUTH_REDIRECT_URI ?? '',
        onAccessTokenExpiry(refreshAccessToken) {
            console.log('Expired! Access token needs to be renewed.');
            return refreshAccessToken();
        },
        onInvalidGrant(refreshAuthCodeOrRefreshToken) {
            console.log('Expired! Auth code or refresh token needs to be renewed.');
            return refreshAuthCodeOrRefreshToken();
        },
    });
})();

export const getAccessToken = async () => auth.then((a) => a.getAccessToken());
export const isReturningFromAuthServer = async () => auth.then((a) => a.isReturningFromAuthServer());
export const fetchAuthorizationCode = async () => auth.then((a) => a.fetchAuthorizationCode());
export const reset = async () => auth.then((a) => a.reset());
