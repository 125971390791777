var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page -width-lg"},[_c('div',{staticClass:"page-header"},[_c('h1',{staticClass:"page-header__text"},[_vm._v(_vm._s(_vm.$t('menus.pageHeaders.catalogs')))]),_c('Button',{attrs:{"to":{ name: 'Create Catalog' },"icon":"add_circle_outline"}},[_vm._v(" "+_vm._s(_vm.$t('entities.add'))+" ")])],1),(!_vm.isAvailable)?_c('error-block'):_c('Table',{staticClass:"catalogs-table",attrs:{"search":_vm.search,"columns":_vm.headers,"items":_vm.items,"is-data-loaded":_vm.isLoaded,"show-select":"","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"pagination":_vm.pagination},on:{"change-per-page":_vm.changePerPage,"update:sort":_vm.updateSort,"update-search-string":_vm.updateSearchString,"delete-selected-items":_vm.openDeletionModal},scopedSlots:_vm._u([{key:"selected-actions",fn:function(){return [(_vm.selected.length)?[_c('Button',{staticClass:"table__selected-action",attrs:{"icon":"unpublished","type":"text"},on:{"click":function($event){return _vm.changeSelected('enabled', false)}}},[_vm._v(" "+_vm._s(_vm.$t('lists.actions.disableSelected')))]),_c('Button',{staticClass:"table__selected-action",attrs:{"type":"text","icon":"check_circle"},on:{"click":function($event){return _vm.changeSelected('enabled', true)}}},[_vm._v(" "+_vm._s(_vm.$t('lists.actions.enableSelected')))]),_c('Button',{staticClass:"table__selected-action",attrs:{"type":"text","icon":"visibility_off"},on:{"click":function($event){return _vm.changeSelected('hidden', true)}}},[_vm._v(" "+_vm._s(_vm.$t('lists.actions.hideSelected')))]),_c('Button',{staticClass:"table__selected-action",attrs:{"type":"text","icon":"visibility"},on:{"click":function($event){return _vm.changeSelected('hidden', false)}}},[_vm._v(_vm._s(_vm.$t('lists.actions.showSelected')))])]:_vm._e()]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"table__actions"},[_c('router-link',{attrs:{"to":{ name: 'Builder Edit Catalog', params: { id: item.id, type: 'catalog' } }}},[_c('Button',{staticClass:"builder-btn",attrs:{"type":"text"}},[_vm._v(_vm._s(_vm.$t('entities.editInBuilder')))])],1),_c('a',{attrs:{"target":"_blank","href":("https://" + (_vm.instanceFull.primaryDomain) + "/catalogs/" + (item.slug))}},[_c('Button',{attrs:{"type":"icon","icon":"open_in_new"}})],1),_c('Button',{attrs:{"to":{ name: 'Edit Catalog', params: { id: item.id } },"icon":"edit","type":"icon"}}),_c('Button',{attrs:{"icon":"delete","type":"icon"},on:{"click":function($event){return _vm.openDeletionModal(item)}}})],1)]}},{key:"item.image",fn:function(ref){
var item = ref.item;
return [(_vm.hasImage(item))?_c('img',{staticClass:"table__image",attrs:{"alt":_vm.images[item.id].alt,"src":_vm.imageUrl + _vm.images[item.id].uri}}):_c('img',{staticClass:"table__image -default",attrs:{"alt":"No image","src":require("@/assets/images/no-photo.svg")}})]}},{key:"item.enabled",fn:function(ref){
var item = ref.item;
return [_c('input-switch',{attrs:{"value":item.enabled,"name":"enabled","size":"small"},on:{"input":function($event){return _vm.onUpdateFieldValue({ name: 'enabled', value: $event }, item)}}})]}},{key:"item.hidden",fn:function(ref){
var item = ref.item;
return [_c('input-switch',{attrs:{"value":item.hidden,"name":"hidden","size":"small"},on:{"input":function($event){return _vm.onUpdateFieldValue({ name: 'hidden', value: $event }, item)}}})]}},{key:"item.products",fn:function(ref){
var item = ref.item;
return [_c('Button',{attrs:{"type":"text","to":{ name: 'Catalog Products', params: { id: item.id } }}},[_vm._v(" "+_vm._s(_vm.$t('lists.actions.viewAll')))])]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"table-edit"},[_c('router-link',{staticClass:"table-edit__link",attrs:{"to":{ name: 'Edit Catalog', params: { id: item.id, type: item.type } }}},[_vm._v(_vm._s(item.title))]),_c('span',{staticClass:"table-edit__icon material-icons-outlined"},[_vm._v("edit")])],1)]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }