<template>
    <div v-click-outside="hideDropdown" ref="search" class="header-search">
        <div class="search">
            <input-text
                class="search__input"
                :placeholder="$t('search.main')"
                v-model="searchString"
                @focus="showDropdown"
                @input="search"
                v-on="$listeners"
            ></input-text>
            <span v-show="!searchString" class="search__icon material-icons-outlined">search</span>
            <span class="material-icons-outlined search__icon -close" v-show="searchString" @click="clearSearch"
                >close</span
            >
        </div>
        <div class="header-search__results" v-if="isDropdownShown">
            <template v-if="isLoading">
                <div class="header-search__results-item">
                    <div class="header-search__results-item-description">{{ $t('search.isSearching') }}</div>
                </div>
            </template>
            <template v-else>
                <template v-if="items.length">
                    <template v-for="(group, key) in itemsInfo">
                        <div v-if="group.items.length" :key="key">
                            <div class="header-search__results-group">
                                {{ $tc(group.translation, 2).toUpperCase() }}
                            </div>
                            <router-link
                                :key="item.id"
                                v-for="item in group.items"
                                :to="{ name: item.slug, params: { id: item.id } }"
                            >
                                <div class="header-search__results-item" @click="hideDropdown">
                                    <div class="header-search__results-item-icon">
                                        <span class="material-icons-outlined">{{ group.icon }}</span>
                                    </div>
                                    <div class="header-search__results-item-text">
                                        <div class="header-search__results-item-heading">{{ item.title }}</div>
                                        <div v-if="item.description" class="header-search__results-item-description">
                                            {{ item.description }}
                                        </div>
                                    </div>
                                </div>
                            </router-link>
                        </div>
                    </template>
                </template>
                <template v-else-if="isActive && items.length === 0">
                    <div class="header-search__results-item">
                        <div class="header-search__results-item-description">{{ $t('search.noData') }}</div>
                    </div>
                </template>
            </template>
        </div>
    </div>
</template>

<script>
import ProductService from '@/services/ProductService';
import SearchService from '@/services/SearchService';
import { debounce } from 'lodash';
import itemsInfo from '@/components/search/headerSearch/itemsInfo';
import InputText from '@/components/form/controls/InputText';
import { capitalize } from '@/helpers/filters';

export default {
    name: 'HeaderSearch',
    components: { InputText },
    data() {
        return {
            isLoading: true,
            items: [],
            searchString: '',
            itemsInfo,
            isDropdownShown: false,
            reviewProducts: [],
            capitalize,
        };
    },

    computed: {
        isActive() {
            return this.searchString && this.searchString.length >= 3;
        },
    },

    methods: {
        search: debounce(async function() {
            if (!this.isActive) return;
            this.isLoading = true;
            for (const key in this.itemsInfo) {
                this.itemsInfo[key].items = [];
            }
            const params = {
                q: this.searchString,
                limit: 10,
            };
            const [error, searchResults] = await SearchService.globalSearch(params);
            if (error) {
                error.notify();
                return;
            }
            const reviewsProductIds = [];
            //получаем продукты для reviews
            searchResults.results.forEach((item) => {
                if (item.searchable === 'product_review') {
                    reviewsProductIds.push(item.object.productId);
                }
            });
            if (reviewsProductIds.length) {
                const [error, result] = await ProductService.getAll({ ids: reviewsProductIds });
                if (error) {
                    error.notify();
                    return;
                }
                this.reviewProducts = result.products;
            }
            searchResults.results.forEach((item) => {
                const itemInfo = this.getSearchItemInfo(item);
                this.itemsInfo[item.searchable].items.push(itemInfo);
            });
            this.isLoading = false;
            this.items = searchResults.results;
        }, 500),

        clearSearch() {
            this.searchString = '';
            this.searchItems = [];
        },

        showDropdown() {
            if (this.searchString.length >= 3) {
                this.isDropdownShown = true;
            }
        },

        hideDropdown() {
            this.isDropdownShown = false;
        },

        getSearchItemInfo({ object, searchable }) {
            let info = {};
            switch (searchable) {
                case 'order': {
                    info = {
                        title: `Order ${object.number}`,
                        slug: 'Edit Order',
                        description: `${object.createdAt} ${object.name}`,
                    };
                    break;
                }
                case 'user': {
                    info = {
                        title: object.email,
                        slug: 'Edit User',
                    };
                    break;
                }
                case 'product_review': {
                    const product = this.reviewProducts.find((product) => product.id === object.productId);
                    info = {
                        title: `${object.name}`,
                        slug: 'Edit Review',
                        description: `on ${product.title}`,
                    };
                    break;
                }
                default: {
                    info = {
                        title: object.title,
                        slug: `Edit ${capitalize(searchable)}`,
                    };
                }
            }
            info.id = object.id;
            return info;
        },

        async updateResults(results) {
            this.isLoading = true;
            this.searchItems = results;
            this.isLoading = false;
        },
        updateSearchString(val) {
            this.searchString = val.searchString;
        },
    },
    watch: {
        searchString(val) {
            if (val.length >= 3) {
                this.isDropdownShown = true;
            } else {
                this.isDropdownShown = false;
                this.isLoading = true;
            }
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.header-search {
    position: relative;
    width: 100%;

    &__results {
        position: absolute;
        padding: 12px;
        background-color: var(--v-surface-base);
        width: 100%;
        border-radius: 0 0 4px 4px;
        border: 1px solid var(--v-outline-base);
        max-height: 500px;
        overflow-y: scroll;
    }
    &__results-item {
        padding: 10px 12px;
        color: black !important;
        display: flex;
        justify-content: start;
        border-radius: 4px;
        &:hover {
            background-color: var(--v-surface-overlay-base);
            cursor: pointer;
        }
    }
    &__results-item-text {
        flex-grow: 0;
        flex-basis: 100%;
    }
    &__results-item-heading {
        font-size: 1.1rem;
        font-weight: 500;
    }
    &__results-item-description {
        font-size: 14px;
        line-height: 20px;
        /* identical to box height, or 143% */

        display: flex;
        align-items: center;

        /* On surface | On background/Medium Emphasis */

        color: #767778;
    }
    &__results-item-icon {
        width: 32px;
    }
}

.search {
    position: relative;
    &__icon {
        font-size: 18px;
        position: absolute !important;
        right: 13px;
        top: calc(50% - 9px);
        color: var(--v-on-surface-medium-base) !important;
    }
}
.-close {
    cursor: pointer;
    &:hover {
        color: var(--v-primary-accent-base) !important;
    }
}
</style>
