import LocationService from '@/services/LocationService.ts';
import OrderDeliveryAddress from '@/entities/order/OrderDeliveryAddress';
import Location from '@/entities/delivery/Location';

/**
 * Класс экземпляра информацци о заказе
 */
export default class OrderDeliveryInformation {
    /**
     *
     * @param {Object}  data - объект данных  информацци о заказе
     */
    constructor(
        data = {
            type: 'delivery',
            location: '',
            rate: '',
            deliveryAddress: {
                country: null,
                region: null,
                city: null,
                address: null,
                apartment: null,
                postalCode: null,
                phone: null,
            },
            price: 0,
        }
    ) {
        this.type = data.type;
        this.location = data.location;
        this.rate = data.rate;
        this.deliveryAddress = new OrderDeliveryAddress(data.deliveryAddress);
        this.price = data.price;
    }

    get data() {
        return {
            type: this.type,
            location: this.location,
            rate: this.rate,
            deliveryAddress: this.deliveryAddress.data,
            price: this.price,
        };
    }

    async getLocation() {
        const [error, result] = await LocationService.getOne(this.location);
        if (error) {
            error.notify();
            return {};
        }
        return new Location(result);
    }
}
