export default {
    templateProductH1: {
        component: 'input-text',
        props: {
            labelTrans: 'settings.seo.metatags.h1',
            rules: ['noEmpty'],
            errors: [],
        },
    },
    templateProductTitle: {
        component: 'input-text',
        props: {
            labelTrans: 'settings.seo.metatags.title',
            errors: [],
        },
    },
    templateProductDescription: {
        component: 'input-text',
        props: {
            labelTrans: 'settings.seo.metatags.description',
            errors: [],
        },
    },
    templateCatalogH1: {
        component: 'input-text',
        props: {
            labelTrans: 'settings.seo.metatags.h1',
            errors: [],
        },
    },
    templateCatalogTitle: {
        component: 'input-text',
        props: {
            labelTrans: 'settings.seo.metatags.title',
            errors: [],
        },
    },
    templateCatalogDescription: {
        component: 'input-text',
        props: {
            labelTrans: 'settings.seo.metatags.description',
            errors: [],
        },
    },
    templatePageH1: {
        component: 'input-text',
        props: {
            labelTrans: 'settings.seo.metatags.h1',
            errors: [],
        },
    },
    templatePageTitle: {
        component: 'input-text',
        props: {
            labelTrans: 'settings.seo.metatags.title',
            errors: [],
        },
    },
    templatePageDescription: {
        component: 'input-text',
        props: {
            labelTrans: 'settings.seo.metatags.description',
            errors: [],
        },
    },
    templateCategoryH1: {
        component: 'input-text',
        props: {
            labelTrans: 'settings.seo.metatags.h1',
            errors: [],
        },
    },
    templateCategoryTitle: {
        component: 'input-text',
        props: {
            labelTrans: 'settings.seo.metatags.title',
            errors: [],
        },
    },
    templateCategoryDescription: {
        component: 'input-text',
        props: {
            labelTrans: 'settings.seo.metatags.description',
            errors: [],
        },
    },
    templateArticleH1: {
        component: 'input-text',
        props: {
            labelTrans: 'settings.seo.metatags.h1',
            errors: [],
        },
    },
    templateArticleTitle: {
        component: 'input-text',
        props: {
            labelTrans: 'settings.seo.metatags.title',
            errors: [],
        },
    },
    templateArticleDescription: {
        component: 'input-text',
        props: {
            labelTrans: 'settings.seo.metatags.description',
            errors: [],
        },
    },
};
