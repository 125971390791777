export default [
    {
        path: '/',
        component: () => import('@/layouts/full-layout/Layout'),
        children: [
            {
                path: '/',
                name: 'Dashboard',
                component: () => import('@/views/dashboards/minimal/Minimal.vue'),
                // component: () => import('@/layouts/full-layout/Layout'),
            },
        ],
    },
    {
        path: '/auth',
        component: () => import('@/layouts/account-layout/AccountLayout'),
        children: [
            {
                path: 'login',
                name: 'Login',
                component: () => import('@/views/auth/LoginPage.vue'),
            },
            {
                path: 'instances',
                name: 'Instance List',
                component: () => import('@/views/auth/InstanceList.vue'),
            },
        ],
    },
    /*    {
        path: '/:instanceid/auth',
        component: () => import('@/layouts/auth-layout/AuthLayout'),
        children: [
            {
                path: 'login',
                name: 'Login',
                component: () => import('@/views/auth/LoginPage.vue'),
            },
        ],
    },*/
    {
        path: '/catalogs',
        component: () => import('@/layouts/full-layout/Layout'),
        children: [
            {
                path: '/',
                name: 'Catalog List',
                component: () => import('@/views/catalog/CatalogList'),
            },
            {
                path: 'edit/:id',
                name: 'Edit Catalog',
                component: () => import('@/views/catalog/CatalogItem'),
            },
            {
                path: 'create',
                name: 'Create Catalog',
                component: () => import('@/views/catalog/CatalogItem'),
            },
            {
                path: ':id/products',
                name: 'Catalog Products',
                component: () => import('@/views/product/ProductList'),
            },
        ],
    },
    {
        path: '/pages',
        component: () => import('@/layouts/full-layout/Layout'),
        children: [
            {
                path: '/',
                name: 'Page List',
                component: () => import('@/views/page/PageList'),
            },
            {
                path: 'edit/:id',
                name: 'Edit Page',
                component: () => import('@/views/page/PageItem'),
            },
            {
                path: 'create',
                name: 'Create Page',
                component: () => import('@/views/page/PageItem'),
            },
        ],
    },
    {
        path: '/products',
        component: () => import('@/layouts/full-layout/Layout'),
        children: [
            {
                path: '',
                name: 'Product List',
                component: () => import('@/views/product/ProductList'),
            },
            {
                path: 'disabled',
                name: 'Product Disabled List',
                component: () => import('@/views/product/ProductList'),
            },
            {
                path: 'edit/:id',
                name: 'Edit Product',
                component: () => import('@/views/product/ProductItem'),
            },
            {
                path: 'create',
                name: 'Create Product',
                component: () => import('@/views/product/ProductItem'),
            },
            {
                path: 'reviews',
                name: 'Product Review List',
                component: () => import('@/views/product/ProductReviewList'),
            },
            {
                path: 'reviews/edit/:id',
                name: 'Edit Review',
                component: () => import('@/views/product/ProductReviewItem'),
            },
        ],
    },
    {
        path: '/media',
        component: () => import('@/layouts/full-layout/Layout'),
        children: [
            // CATEGORIES
            {
                path: 'categories',
                name: 'Category List',
                component: () => import('@/views/blog/CategoryList'),
            },
            {
                path: 'categories/create',
                name: 'Create Category',
                component: () => import('@/views/blog/CategoryItem'),
            },
            {
                path: 'categories/edit/:id',
                name: 'Edit Category',
                component: () => import('@/views/blog/CategoryItem'),
            },
            // ARTICLES
            {
                path: 'articles',
                name: 'Article List',
                component: () => import('@/views/blog/ArticleList'),
            },
            {
                path: 'articles/create',
                name: 'Create Article',
                component: () => import('@/views/blog/ArticleItem'),
            },
            {
                path: 'articles/edit/:id',
                name: 'Edit Article',
                component: () => import('@/views/blog/ArticleItem'),
            },
            // COMMENTS
            {
                path: 'comments',
                name: 'Comment List',
                component: () => import('@/views/blog/CommentList'),
            },
            // AUTHORS
            {
                path: 'authors',
                name: 'Author List',
                component: () => import('@/views/blog/AuthorList'),
            },
            {
                path: 'authors/edit/:id',
                name: 'Edit Author',
                component: () => import('@/views/blog/AuthorItem'),
            },
            {
                path: 'authors/create',
                name: 'Create Author',
                component: () => import('@/views/blog/AuthorItem'),
            },
            // TAGS
            {
                path: 'tags',
                name: 'Tag List',
                component: () => import('@/views/blog/TagList'),
            },
        ],
    },
    {
        path: '/leads',
        component: () => import('@/layouts/full-layout/Layout'),
        children: [
            {
                path: '',
                name: 'Lead List',
                component: () => import('@/views/lead/LeadList'),
            },
            {
                path: 'edit/:id',
                name: 'Edit Lead',
                component: () => import('@/views/lead/LeadItem'),
            },
        ],
    },
    {
        path: '/options',
        component: () => import('@/layouts/full-layout/Layout'),
        children: [
            {
                path: '',
                name: 'Option List',
                component: () => import('@/views/option/OptionList'),
            },
            {
                path: 'edit/:id',
                name: 'Edit Option',
                component: () => import('@/views/option/OptionItem'),
            },
        ],
    },
    {
        path: '/detail',
        component: () => import('@/layouts/full-layout/Layout'),
        children: [
            {
                path: '',
                name: 'Property List',
                component: () => import('@/views/property/PropertyList'),
            },
            /*     {
                path: 'edit/:id',
                name: 'Edit Option',
                component: () => import('@/views/option/OptionItem'),
            },*/
        ],
    },
    {
        path: '/product-labels',
        component: () => import('@/layouts/full-layout/Layout'),
        children: [
            {
                path: '',
                name: 'Label List',
                component: () => import('@/views/product/ProductLabelList'),
            },
        ],
    },
    {
        path: '/order-states',
        component: () => import('@/layouts/full-layout/Layout'),
        children: [
            {
                path: '',
                name: 'Order States',
                component: () => import('@/views/order/OrderStateList'),
            },
        ],
    },
    {
        path: '/orders',
        component: () => import('@/layouts/full-layout/Layout'),
        children: [
            {
                path: '/',
                name: 'Order List',
                component: () => import('@/views/order/OrderList'),
            },
            {
                path: 'edit/:id',
                name: 'Edit Order',
                component: () => import('@/views/order/OrderItem'),
            },
            {
                path: 'create',
                name: 'Create Order',
                component: () => import('@/views/order/OrderItem'),
            },
        ],
    },
    {
        path: '/customers',
        component: () => import('@/layouts/full-layout/Layout'),
        children: [
            {
                path: '/',
                name: 'Customer List',
                component: () => import('@/views/customer/CustomerList'),
            },
            {
                path: 'view/:id',
                name: 'View Customer',
                component: () => import('@/views/customer/CustomerItem'),
            },
        ],
    },
    {
        path: '/users',
        component: () => import('@/layouts/full-layout/Layout'),
        children: [
            {
                path: '/',
                name: 'User List',
                component: () => import('@/views/user/UserList'),
            },
            {
                path: 'edit/:id',
                name: 'Edit User',
                component: () => import('@/views/user/UserItem'),
            },
        ],
    },
    {
        path: '/settings',
        component: () => import('@/layouts/blank-layout/BlankLayout'),
        children: [
            {
                path: 'customize',
                component: () => import('@/layouts/blank-layout/BlankLayout'),
                children: [
                    {
                        name: 'Create',
                        path: 'create/:type',
                        component: () => import('@/views/settings/customize/Customize'),
                    },
                    {
                        path: 'edit/:type',
                        component: () => import('@/views/settings/customize/Customize'),
                        children: [
                            {
                                name: 'Edit Homepage',
                                path: '',
                                // component: () => import('@/views/settings/customize/Customize'),
                            },
                            {
                                name: 'Builder Edit Page',
                                path: ':id',
                            },
                            {
                                name: 'Edit Ecommerce Page',
                                path: '',
                            },
                            {
                                name: 'Builder Edit Catalog',
                                path: ':id',
                            },
                            {
                                name: 'Builder Edit Category',
                                path: ':id',
                            },
                            {
                                name: 'Builder Edit Article',
                                path: ':categoryId/:id',
                            },
                            {
                                path: 'settings/customize/create/catalog',
                                name: 'Builder Create Catalog',
                                component: () => import('@/views/settings/customize/Customize'),
                            },
                        ],
                    },
                ],
            },
            {
                path: 'file-manager',
                component: () => import('@/layouts/full-layout/Layout'),
                children: [
                    {
                        path: '/',
                        name: 'FileManager',
                        component: () => import('@/views/settings/FileManager'),
                    },
                ],
            },
            {
                path: 'notification',
                component: () => import('@/layouts/full-layout/Layout'),
                children: [
                    {
                        path: '/',
                        name: 'Notification Settings',
                        component: () => import('@/views/settings/NotificationSettings'),
                    },
                ],
            },
            {
                path: 'domain',
                component: () => import('@/layouts/full-layout/Layout'),
                children: [
                    {
                        path: '/',
                        name: 'Domains',
                        component: () => import('@/views/settings/DomainList'),
                    },
                ],
            },
            {
                path: 'erp',
                component: () => import('@/layouts/full-layout/Layout'),
                children: [
                    {
                        path: '/',
                        name: 'ERP',
                        component: () => import('@/views/settings/ERP'),
                    },
                ],
            },
        ],
    },
    {
        path: '/seo',
        component: () => import('@/layouts/full-layout/Layout'),
        children: [
            {
                path: 'tag-manager',
                name: 'Tag manager',
                component: () => import('@/views/seo/TagManager'),
            },
            // {
            //     path: 'sitemap',
            //     name: 'Sitemap',
            //     component: () => import('@/views/order/OrderItem'),
            // },
            {
                path: 'robots',
                name: 'Robots.txt',
                component: () => import('@/views/seo/RobotsTxt'),
            },
            {
                path: 'redirects',
                name: 'Redirects',
                component: () => import('@/views/seo/RedirectList'),
            },
            {
                path: 'metatags',
                name: 'MetaTags',
                component: () => import('@/views/seo/MetaTags'),
            },
        ],
    },
    {
        path: '/shipping',
        component: () => import('@/layouts/full-layout/Layout'),
        children: [
            {
                path: 'locations',
                name: 'Locations',
                component: () => import('@/views/shipping/Locations'),
            },
            {
                path: 'delivery',
                name: 'Shipping and Delivery',
                component: () => import('@/views/shipping/Delivery'),
            },
        ],
    },
    {
        path: '/payment',
        component: () => import('@/layouts/full-layout/Layout'),
        children: [
            {
                path: '/',
                name: 'Payment',
                component: () => import('@/views/payment/Payment'),
            },
        ],
    },
    {
        path: '/billing',
        component: () => import('@/layouts/full-layout/Layout'),
        children: [
            {
                path: '/',
                name: 'Billing',
                component: () => import('@/views/billing'),
            },
        ],
    },
    {
        path: '/bulk-editor',
        component: () => import('@/layouts/full-layout/Layout'),
        children: [
            {
                path: '/',
                name: 'Bulk Editor',
                component: () => import('@/views/BulkEditor'),
            },
        ],
    },
    {
        path: '/test',
        component: () => import('@/layouts/full-layout/Layout'),
        children: [
            {
                path: 'forms',
                name: 'TestForms',
                component: () => import('@/views/test/forms'),
            },
            {
                path: 'buttons',
                name: 'TestButtons',
                component: () => import('@/views/test/buttons'),
            },
            {
                path: 'sentry',
                name: 'sentry',
                component: () => import('@/views/test/sentryTest'),
            },
        ],
    },
    {
        path: '*',
        component: () => import('@/layouts/full-layout/Layout'),
        children: [
            {
                path: '/',
                name: 'Error404',
                component: () => import('@/views/errors/Error404'),
            },
        ],
    },
];
