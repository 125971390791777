<template>
    <div class="select-background">
        <Select v-model="selected" :items="items" @input="changeValue" />
        <CustomizeBackgroundTabs v-if="selected === 'custom'" v-model="model" class="select-background__customize" />
    </div>
</template>

<script>
import { proxyModelMixin } from '@/mixins/proxyModelMixin';
import Select from '@/components/form/controls/Select';
import CustomizeBackgroundTabs from '@/components/form/controls/CustomizeBackgroundTabs';

export default {
    name: 'SelectBackground',
    components: {
        Select,
        CustomizeBackgroundTabs,
    },
    inheritAttrs: false,
    mixins: [proxyModelMixin],
    props: {
        name: { type: String, default: '' },
        group: { type: String, default: null },
        errors: { type: [Array, Object], default: () => [] },
        items: { type: [Array], default: () => [] },
        value: { type: [String, Number, Object, Date] },
        disabled: { type: Boolean, default: false },
    },
    data() {
        return {
            selected: null,
        };
    },
    created() {
        this.selected = typeof this.value !== 'string' ? 'custom' : this.value;
    },
    methods: {
        changeValue(value) {
            this.model = value === 'custom' ? this.model : value;
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';

.select-background {
    &__customize {
        margin-top: 8px;
    }
}
</style>
