import { uuid } from 'vue-uuid';
import { validateNoEmpty, validateEmail } from '@/helpers/validator';
import initFields from '@/entities/user/User.fields';

function validateEqualTo(val, targetVal) {
    let isValid = true;
    if (val !== targetVal) isValid = false;
    return {
        isValid,
    };
}

/**
 * Класс экземпляра пользователя
 */
export default class User {
    isReady = true;
    repeatPassword = null;
    type = 'user';
    fields = initFields(this);

    /**
     * Create User Entity
     * @param {Object}  data - объект данных пользователя
     * @param {String} data.id - uuid.v4(),
     * @param {String} data.email - email пользователя
     * @param {String|null} data.password - пароль пользователя
     * @param {String} data.role - роль пользователя ([ROLE_USER, ROLE_ADMIN, ROLE_SUPER_ADMIN])
     * @param {Boolean} data.enabled - активен ли пользователь
     * @param {String} data.registeredAt - дата регистрации
     * @param {String} data.profile - информация о пользователе
     */
    constructor(data = {}) {
        this.id = data.id || uuid.v4();
        this.email = data.email || '';
        this.password = data.password || null;
        this.role = data.role || '';
        this.enabled = data.enabled;
        this.registeredAt = new Date(data.registeredAt);
        this.profile = data.profile;
    }

    get data() {
        return {
            id: this.id,
            email: this.email,
            enabled: this.enabled,
            password: this.password,
            role: this.role,
        };
    }

    get fullName() {
        return this.profile ? `${this.profile.firstName} ${this.profile.lastName}` : '-';
    }

    get phone() {
        return this.profile?.phone || '-';
    }

    validateField(item, value) {
        const rules = item.props?.rules;
        if (!rules || rules.length === 0) return [];
        const errors = [];

        for (const [rule, options] of Object.entries(item.props.rules)) {
            switch (rule) {
                case 'noEmpty': {
                    if (!validateNoEmpty(value)) errors.push('validator.errors.empty');
                    break;
                }
                case 'email': {
                    if (!validateEmail(value)) errors.push('validator.errors.email');
                    break;
                }
                case 'related': {
                    options.forEach((item) => {
                        this.validateRelated(this.fields[item], this[item]);
                    });
                    break;
                }
                case 'equalTo': {
                    if (!validateEqualTo(value, this[options.target]).isValid) errors.push(`validator.errors.equalTo`);
                    break;
                }
            }
        }
        return errors;
    }
    validateRelated(item, value) {
        if (value) {
            item.props.errors = this.validateField(item, value);
        }
    }
}
