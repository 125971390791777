<template>
    <div class="select-benefit-icon">
        <ButtonToggles v-model="model.type" :items="options" :name="name" @input="onTypeSwitch" />
        <InputIcon
            v-if="model.type === 'icon'"
            class="select-benefit-icon__value"
            v-model="model.data"
            :items="icons"
            :color="model.color"
        />
        <ColorPicker v-if="model.type === 'icon'" v-model="model.color" class="select-benefit-icon__value" />
        <InputImageBuilder v-if="model.type === 'image'" class="select-benefit-icon__value" v-model="model.data" />
        <InputText v-if="model.type === 'text'" class="select-benefit-icon__value" v-model="model.data" />
        <!--        {{ value }}-->
    </div>
</template>

<script>
import ButtonToggles from '@/components/form/controls/ButtonToggles.vue';
import InputImageBuilder from '@/components/form/controls/InputImageBuilder.vue';
import InputText from '@/components/form/controls/InputText.vue';
import InputIcon from '@/components/form/controls/InputIcon.vue';
import ColorPicker from '@/components/form/controls/ColorPicker.vue';

export default {
    name: 'SelectBenefitIcon',
    inheritAttrs: false,
    props: {
        name: { type: String, default: '' },
        group: { type: String, default: null },
        placeholder: { type: String, default: 'Choose' },
        options: { type: [Array], default: () => [] },
        icons: { type: [Array], default: () => [] },
        errors: { type: [Array, Object], default: () => [] },
        value: { type: [String, Object], default: () => {} },
    },
    components: {
        ButtonToggles,
        InputImageBuilder,
        InputText,
        InputIcon,
        ColorPicker,
    },
    data() {
        return {
            dropdownDirectionTop: false,
            isDropdownShow: false,
        };
    },
    created() {
        if (typeof this.value === 'string') this.model = { type: 'icon', data: this.value, color: '' };
    },
    computed: {
        model: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
    },
    watch: {},
    methods: {
        onTypeSwitch() {
            //  if (!this.value) return;
            console.log(this.value);
            this.value.color = '';
            if (this.value.type === 'icon') this.value.data = 'person';
            if (this.value.type === 'image') this.value.data = '';
            if (this.value.type === 'text') this.value.data = '';
            if (this.value.type === 'none') this.value.data = '';
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';

.select-benefit-icon {
    &__value {
        margin-top: 1rem;
    }
}
</style>
