<template>
    <sidebar-right :heading="$t('entities.order.information')">
        <tabs class="sidebar__tabs" :tabs="tabItems" v-model="activeTab">
            <template v-for="tab in tabItems" v-slot:[`header.${tab.key}`]>
                <div :class="{ '-has-errors': getErrors(tab.key) }" :key="tab.key" class="tabs__tab">
                    {{ tab.title }}
                </div>
            </template>
            <template v-for="tab in tabItems" v-slot:[`item.${tab.key}`] class="tabs__item">
                <form class="tabs__form" :key="tab.key">
                    <template v-for="(item, key) in tab.items">
                        <form-item
                            v-if="checkFieldIsCustomer(key)"
                            :ref="`${tab.key}:${key}`"
                            v-bind="item.props"
                            :key="key"
                        >
                            <component
                                :is="value.fields[key].component"
                                :value="value[key]"
                                @input="updateField(key, $event)"
                                :name="key"
                                v-bind="item.props"
                                hide-details
                                outlined
                                dense
                            ></component>
                        </form-item>
                        <form-item
                            v-else
                            :ref="`${tab.key}:${key}`"
                            v-bind="item.props"
                            :key="key"
                            :noLabel="!value.isOtherReceiver"
                        >
                            <component
                                v-if="value.isOtherReceiver"
                                :is="value.fields[key].component"
                                :value="value[key]"
                                @input="updateField(key, $event)"
                                :name="key"
                                v-bind="item.props"
                                hide-details
                                outlined
                                dense
                            ></component>
                        </form-item>
                    </template>
                    <form-item v-bind="value.createdAt.props" v-if="tab.key === 'order' && orderId">
                        <input-text
                            name="createdAt"
                            :placeholder="$options.filters.localDate(value.createdAt)"
                            v-bind="value.createdAt.props"
                            hide-details
                            outlined
                            dense
                        >
                        </input-text>
                    </form-item>
                </form>
            </template>
        </tabs>
    </sidebar-right>
</template>

<script>
import { cloneDeep } from 'lodash';
import FormItem from '@/components/form/item';
import SidebarRight from '@/components/common/SidebarRight';
import InputText from '@/components/form/controls/InputText';
import Textarea from '@/components/form/controls/Textarea';
import Tabs from '@/components/common/Tabs';
import Select from '@/components/form/controls/Select';
import OrderStateService from '@/services/OrderStateService';

export default {
    name: 'OrderItemSidebar',
    components: { Tabs, InputText, SidebarRight, FormItem, Textarea, Select },
    props: {
        value: {
            type: Object,
        },
    },
    data() {
        return {
            isLoaded: false,
            activeTab: 0,
            statuses: [],
        };
    },
    computed: {
        orderId() {
            return this.$route.params.id;
        },
        tabItems() {
            return [
                {
                    key: 'order',
                    title: this.$tc('entities.order.title', 1),
                    items: {
                        manager: this.value.fields.manager,
                        stateId: {
                            ...this.value.fields.stateId,
                            props: {
                                ...this.value.fields.stateId.props,
                                items: this.statuses,
                                translate: true,
                            },
                        },
                        method: {
                            type: 'field',
                            component: 'Select',
                            value: this.value.fields.method,
                            props: {
                                labelTrans: 'entities.order.fields.payment',
                                items: [
                                    { text: this.$t('entities.order.paymentMethods.cash'), value: 'cash' },
                                    { text: this.$t('entities.order.paymentMethods.online'), value: 'online' },
                                ],
                            },
                        },
                        orderComment: this.value.fields.orderComment,
                    },
                },
                {
                    key: 'client',
                    title: this.$t('entities.order.client'),
                    items: {
                        name: this.value.fields.name,
                        phone: this.value.fields.phone,
                        email: this.value.fields.email,
                        customerComment: this.value.fields.customerComment,
                        isOtherReceiver: this.value.fields.isOtherReceiver,
                        receiverName: this.value.fields.receiverName,
                        receiverPhone: this.value.fields.receiverPhone,
                        receiverComment: this.value.fields.receiverComment,
                    },
                },
            ];
        },
    },
    async created() {
        await this.getStatuses();
    },
    methods: {
        async getStatuses() {
            this.isLoaded = false;
            const [error, result] = await OrderStateService.getAll();
            if (error) {
                error.alert();
                return;
            }
            this.statuses = result.map((item) => ({
                value: item.id,
                text: item.serviceName,
            }));
            this.isLoaded = true;
        },
        getErrors(tabKey) {
            const tabItems = [];
            for (const key in this.$refs) {
                if (key.startsWith(tabKey)) {
                    tabItems.push(this.$refs[key][0]);
                }
            }
            return tabItems.some((item) => item.errors.length);
        },
        updateField(fieldKey, value) {
            const order = cloneDeep(this.value);
            order[fieldKey] = value;
            order.validateField(fieldKey);
            this.$emit('input', order);
        },
        checkFieldIsCustomer(key) {
            return key !== 'receiverName' && key !== 'receiverPhone' && key !== 'receiverComment';
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.order__page {
    .sidebar__tabs {
        overflow-y: overlay;
        height: calc(100% - 225px);
    }
}
.sidebar {
    &__tabs {
        margin-top: 16px;
    }
    &__tab-content {
        padding: 16px;
    }
    .tabs {
        &__form {
            display: flex;
            flex-direction: column;
            gap: 24px;
        }
    }
}
</style>
