<template>
    <div v-if="current && current.fields && current.type !== 'product'" class="option-list-page">
        <template v-for="(field, key) in current.fields">
            <div
                :key="key"
                class="section-option-group"
                v-if="!['slug', 'heading', 'authorId', 'categoryId', 'publisherId', 'tagIds'].includes(key)"
            >
                <div v-if="key === 'metaTitle'" class="section-option-group__separator" />
                <FormItem v-bind="field.props" :name="key">
                    <component
                        v-if="key === 'pageStatus'"
                        :is="field.component"
                        :name="key"
                        v-on="getFieldEvents(field.events)"
                        v-model="current"
                        v-bind="field.props"
                        @update-field="onUpdateFieldValue"
                    />
                    <InputImageBuilder
                        v-else-if="key === 'previewImageId'"
                        :name="key"
                        v-on="getFieldEvents(field.events)"
                        v-model="current[key]"
                        v-bind="field.props"
                    />
                    <component
                        v-else
                        :is="field.component"
                        :name="key"
                        v-on="getFieldEvents(field.events)"
                        v-model="current[key]"
                        v-bind="field.props"
                        @update-field="onUpdateFieldValue"
                    />
                </FormItem>
            </div>
        </template>
        <div v-if="!current.required" class="option-list-page__btns">
            <Button @click="copyContent" type="primary" icon="copy" fullwidth>
                {{ $t('builder.global.btns.copyContent') }}
            </Button>
            <Button :disabled="isPasteButtonDisabled" @click="pasteContent" type="primary" icon="add_circle" fullwidth>
                {{ $t('builder.global.btns.pasteContent') }}
            </Button>
        </div>
    </div>
</template>

<script>
import { debounce, cloneDeep } from 'lodash';
import { mapActions, mapGetters, mapState } from 'vuex';
import EventEmitter from '@/helpers/eventEmitter';
import validateField from '@/helpers/validator';
import FormItem from '@/components/form/item.vue';
import Button from '@/components/common/Button.vue';

export default {
    name: 'OptionListPage',
    components: {
        FormItem,
        Button,
    },
    data() {
        return {
            clipboardChecker: null,
        };
    },
    computed: {
        ...mapState('builder', {
            isHistory: 'isHistory',
        }),
        ...mapGetters('builder', {
            current: 'getSidebarRightCurrent',
        }),
        ...mapGetters('clipboard', {
            clipboard: 'getClipboard',
        }),
        hasSectionsInClipboard() {
            return !!(this.clipboard && this.clipboard.type === 'sections');
        },
        isPasteButtonDisabled() {
            return !this.hasSectionsInClipboard;
        },
    },
    methods: {
        ...mapActions('builder', {
            updatePage: 'updatePage',
            addSection: 'addSection',
        }),
        ...mapActions('clipboard', {
            copyToClipboard: 'copyToClipboard',
            resetClipboard: 'resetClipboard',
        }),
        updatePageDebounced: debounce(function(newPage) {
            this.updatePage(newPage);
        }, 300),
        getFieldEvents(events) {
            const fieldEvents = {};
            for (const key in events) {
                fieldEvents[key] = events[key]?.bind(this.current);
            }
            return fieldEvents;
        },
        onUpdateFieldValue: debounce(function(payload) {
            if (!this.current) return;
            const { name } = payload;
            const errors = validateField(this.current[name], this.current.fields[name].props.rules);
            this.$set(this.current.fields[name].props, 'errors', errors);
        }, 1000),
        copyContent: function() {
            let sections = cloneDeep(this.current.sections);
            sections = sections.filter((section) => !section.required);
            sections.forEach((section) => {
                section.id = 'fixture';
                section.items.forEach((item) => {
                    item.id = 'fixture';
                });
            });
            this.copyToClipboard({
                type: 'sections',
                value: sections,
            });
            EventEmitter.trigger('show-noty', {
                type: 'success',
                text: 'The content of the page has been copied',
            });
        },
        pasteContent: function() {
            const sections = this.clipboard.value;
            if (Array.isArray(sections) && sections.length) {
                sections.forEach((section) => this.addSection(section));
            }
            this.resetClipboard();
        },
    },
    created() {},
    watch: {
        current: {
            handler(newPage) {
                if (this.isHistory) this.updatePageDebounced(newPage);
            },
            deep: true,
        },
    },
    destroyed() {
        clearInterval(this.clipboardChecker);
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.section-option-group {
    margin: 0 0 $spacer * 8;

    &__heading {
        margin: 0 0 $spacer * 5;
    }

    &__separator {
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        margin: 20px 0;
    }
}

.option-list-page {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;

    &__btns {
        border-top: 1px solid rgba(0, 0, 0, 0.12);
        margin-top: auto;
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 12px 0 12px;
    }
}
</style>
