<template>
    <div class="alert" :class="classes">
        <div class="alert__overlay"></div>
        <span class="alert__icon icon" v-if="iconToUse">{{ iconToUse }}</span>
        <div class="alert__content">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Alert',
    props: {
        type: {
            type: String,
            default: 'primary',
            validator: (value) => ['primary', 'warning', 'info', 'error', 'success', ''].includes(value),
        },
        elevated: {
            type: Boolean,
            default: false,
        },
        icon: {
            type: String,
            default: null,
        },
    },
    computed: {
        iconToUse() {
            if (this.icon) return this.icon;
            const map = {
                primary: 'error_outline',
                info: 'info',
                success: 'check_circle',
                warning: 'warning',
                error: 'warning',
            };
            return map[this.type];
        },

        classes() {
            const classes = [];
            if (this.type) {
                classes.push(`-${this.type}`);
            }
            if (this.elevated) {
                classes.push('-elevated');
            }
            return classes;
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.alert {
    position: relative;
    width: 100%;
    padding: 16px;
    border-radius: 4px;
    font-size: 16px;
    overflow: hidden;
    background-color: var(--v-surface-base);
    display: flex;
    &__content {
        display: flex;
        position: relative;
        align-items: center;
        gap: 8px;
        font-size: 16px;
    }
    &__icon {
        font-size: 24px;
        margin-right: 16px;
    }
    &__overlay {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: 0.12;
    }
    &.-elevated {
        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
            0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
    }
    &.-primary {
        color: var(--v-primary-base);
        caret-color: var(--v-primary-base);
        .alert__overlay {
            background-color: var(--v-primary-base);
        }
    }
    &.-warning {
        color: #ffa000;
        caret-color: #ffa000;
        .alert__overlay {
            background-color: #ffa000;
        }
    }
    &.-error {
        color: var(--v-warning-base);
        caret-color: var(--v-warning-base);
        .alert__overlay {
            background-color: var(--v-warning-base);
        }
    }
    &.-info {
        background-color: var(--v-surface-base);
        border-left: 8px solid var(--v-primary-base);
        .alert__icon {
            color: var(--v-primary-base);
        }
    }

    &.-success {
        background-color: var(--v-success-base);
        color: var(--v-surface-base);
    }
    .alert__link {
        text-decoration: underline;
        font-size: 16px;
        cursor: pointer;
        line-height: 23px;
    }
}
</style>
