<template>
    <div :class="[{ 'is-loading': loading }, 'multi-dropzone']">
        <div class="multi-dropzone__content">
            <div class="multi-dropzone__text" :class="{ '-open': images.length }">
                <span v-if="!loading" class="material-icons-outlined dropzone__icon">cloud_upload</span>
                <progress-circular v-else />
                <span class="dropzone__text text-caption">
                    {{ loading ? $t('entities.image.loading') : $t('entities.image.upload') }}
                </span>
                <div slot="text" class="dropzone__text text-caption">
                    <slot name="text"> </slot>
                </div>
            </div>
            <div class="multi-dropzone__images" v-if="images.length">
                <div class="multi-dropzone__image-container" v-for="image in images" :key="image.id">
                    <img class="multi-dropzone__image" :src="image.uri" />
                    <Button
                        type="icon"
                        class="product-image-manager__controls-btn image__controls-btn  multi-dropzone__controls-btn"
                        icon="delete"
                        @click="$emit('delete-image', image)"
                    ></Button>
                </div>
            </div>
            <input
                v-if="!loading"
                multiple
                class="multi-dropzone__input"
                type="file"
                @change="onChange"
                :name="name"
                accept=".jpeg,.jpg,.png"
                :id="id"
            />
        </div>
    </div>
</template>

<script>
import Button from '@/components/common/Button';
import ProgressCircular from '@/components/common/ProgressCircular';
export default {
    name: 'MultiDropzone',
    components: { ProgressCircular, Button },
    props: {
        name: {
            type: String,
            default: 'file',
        },
        id: {
            type: String,
            default: 'ds-dropzone',
        },
        loading: {
            type: Boolean,
            default: false,
        },
        images: {
            type: Array,
            default: () => [],
        },
    },

    methods: {
        onChange(event) {
            this.$emit('change', event.target.files);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';
.multi-dropzone {
    &__input {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        &:hover {
            cursor: pointer;
        }
    }

    &__content {
        //    display: flex;
        width: 100%;
        height: 100%;
        position: relative;
    }
    &__images {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;
        min-height: 167px;
        padding: 10px;
        gap: 10px;
        div:first-child {
            margin-left: 170px;
        }
    }
    &__image-container {
        position: relative;
        z-index: 1;
        border-radius: 4px;
        border: 1px solid var(--v-outline-base);
        &:hover {
            border-color: var(--v-primary-accent-base);
        }
    }
    &__image {
        width: 130px;
        height: 130px;
        object-fit: contain;
        z-index: 3;
        cursor: default;
    }
    &__text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;
        width: 167px;
        top: 0;
        left: 0;
        height: 167px;
        position: absolute;
    }
    &__controls-btn {
        position: absolute;
        right: 5px;
        top: 5px;
    }
}
</style>
