<template>
    <div class="progress-linear">
        <div class="progress-linear__progress"></div>
    </div>
</template>

<script>
export default {
    name: 'ProgressLinear',
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.progress-linear {
    width: 100%;
    height: 5px;
    background-color: var(--v-primary-lighten-base);
    position: absolute;
    z-index: 5;
    overflow: hidden;

    :before {
        content: '';
        position: absolute;
        height: 100%;
        background-color: var(--v-primary-base);
        animation: indeterminate_first 1.5s infinite ease-out;
    }

    :after {
        content: '';
        position: absolute;
        height: 100%;
        background-color: var(--v-primary-base);
        animation: indeterminate_second 1.5s infinite ease-in;
    }

    @keyframes indeterminate_first {
        0% {
            left: -100%;
            width: 100%;
        }
        100% {
            left: 100%;
            width: 10%;
        }
    }

    @keyframes indeterminate_second {
        0% {
            left: -150%;
            width: 100%;
        }
        100% {
            left: 100%;
            width: 10%;
        }
    }
}
</style>
