export default {
    order: {
        translation: 'entities.order.title',
        icon: 'shopping_cart',
        items: [],
    },
    product: {
        translation: 'entities.product.title',
        icon: 'local_mall',
        items: [],
    },
    product_review: {
        translation: 'entities.review.title',
        icon: 'chat_black',
        items: [],
    },
    user: {
        translation: 'entities.user.title',
        icon: 'person',
        items: [],
    },
    option: {
        translation: 'entities.option.title',
        icon: 'tune',
        items: [],
    },
    page: {
        translation: 'entities.page.title',
        icon: 'description',
        items: [],
    },
    catalog: {
        translation: 'entities.catalog.title',
        icon: 'category_black',
        items: [],
    },
    article: {
        translation: 'entities.article.title',
        icon: 'article',
        items: [],
    },
    category: {
        translation: 'entities.category.title',
        icon: 'feed',
        items: [],
    },
};
