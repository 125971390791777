export default {
    topbar: {
        back: 'Back',
        backToAdmin: 'Go to Dashboard',
        pageSwitcher: {
            search: 'Search',
            addNew: 'Add New',
        },
    },
    pageType: {
        homepage: 'Homepage',
        textPages: 'Text pages',
        catalogs: 'Catalogs',
        product: 'Item page',
        cart: 'Cart',
        checkout: 'Checkout',
        addNew: 'Add New',
        category: 'Media',
        article: 'Articles in category {category}',
    },
    sidebarLeft: {
        header: 'Header',
        footer: 'Footer',
    },
    sidebarRigth: {
        title: 'Settings',
        pageSettings: 'Page Settings',
        websiteSettings: 'Website Settings',
        tabs: {
            page: 'Page',
            website: 'Website',
        },
    },
    pageStatus: {
        hidden: 'Hidden',
        enabled: 'Enabled',
        noIndex: 'Not indexing in search engines',
        turned_off: 'Turned off',
        hidden_no_indexed: 'Hidden, not Indexed',
        open_no_indexed: 'Open, not Indexed',
        open_indexed: 'Open, Indexed',
        hidden_indexed: 'Hidden, Indexed',
    },
    sections: {
        groups: {
            Banners: 'Banners',
            Benefits: 'Benefits',
            Breadcrumbs: 'Breadcrumbs',
            Category: 'Category',
            Contacts: 'Contacts',
            FAQ: 'FAQ',
            Forms: 'Forms',
            Gallery: 'Gallery',
            Heading: 'Heading',
            ProductList: 'ProductList',
            ProductWidget: 'ProductWidget',
            Properties: 'Properties',
            Slider: 'Slider',
            TextBlocks: 'TextBlocks',
            Testimonial: 'Testimonial',
            Media: 'Media',
            MediaWidget: 'Media',
        },
        SectionHeadingH1: 'Heading H1',
        SectionBreadcrumbs: 'Breadcrumbs',

        SectionSlider: 'Slider',
        SectionSliderBase: 'Slider Base',
        SectionProductWidget: 'Product Widget Catalogs',
        SectionProductWidgetByItems: 'Product Widget By Items',
        SectionProductList: 'Product List',
        SectionTextBlockPictureRight: 'TextBlock Picture Right',
        SectionTextBlockPictureLeft: 'TextBlock Picture Left',
        SectionTextBlockPictureCenter: 'TextBlock Picture Center',
        SectionTextBlockGalleryMasonry: 'TextBlock Gallery Masonry',
        SectionTextBlockStyle1: 'TextBlock Style 1',
        SectionTextBlockStyle2: 'TextBlock Style 2',
        SectionTextBlockGrid: 'TextBlock Grid',
        SectionTextCustomContent: 'Text Custom Content',
        SectionHoverTextWithBgStyle1: 'Hover Text Block With Background',

        SectionFAQBase: 'FAQ Base',
        SectionFAQStyle1: 'FAQ Style 1',
        SectionFAQStyle2: 'FAQ Style 2',

        SectionCategoryBase: 'Category Base',
        SectionCategoryStyle1: 'Category Style 1',
        SectionCategoryStyle2: 'Category Style 2',
        SectionCategoryStyle3: 'Category Style 3',
        SectionCategoryStyle4: 'Category Style 4',
        SectionCategoryStyle5: 'Category Style 5',
        SectionCategoryStyle6: 'Category Style 6',
        SectionCategoryStyle7: 'Category Style 7',
        SectionCategoryStyle8: 'Category Style 8',

        SectionGalleryBase: 'Gallery Base',
        SectionGaleryLogos: 'Galery Logos',
        SectionGaleryStyle1: 'Galery Style 1',
        SectionGaleryStyle2: 'Galery Style 2',
        SectionGaleryStyle3: 'Galery Style 3',

        SectionBenefitBase: 'Benefit Base',
        SectionBenefitStyle1: 'Benefit Style 1',
        SectionBenefitStyle2: 'Benefit Style 2',
        SectionBenefitStyle3: 'Benefit Style 3',
        SectionBenefitStyle4: 'Benefit Style 4',
        SectionBenefitStyle5: 'Benefit Style 5',

        SectionContactsStyle1: 'Contacts Style 1',
        SectionContactsStyle2: 'Contacts Style 2',
        SectionContactsStyle3: 'Contacts Map',
        SectionContactsWithFormStyle1: 'Contacts With Form Style 1',

        SectionFormStyle1: 'Form Style 1',

        SectionPropertiesStyle1: 'Properties Style 1',
        SectionPropertiesStyle2: 'Properties Style 2',
        SectionPropertiesStyle3: 'Properties Style 3',
        SectionPropertiesStyle4: 'Properties Style 4',

        SectionTestimonialBase: 'TestimonialBase',
        SectionTestimonialStyle1: 'TestimonialStyle1',

        SectionBannerStyle1: 'Banner Style 1',
        SectionBannerStyle2: 'Banner Style 2',

        SectionMediaComments: 'Comments',
        SectionMediaTableOfContents: 'Table of Contents',
        SectionMediaTagsList: 'List of Tags',
        SectionMediaList: 'List of Articles',
        SectionMediaAuthors: 'Authors',
        SectionMediaWidget: 'Widget of Articles',

        style1: 'Style 1',
        style2: 'Style 2',
        style3: 'Style 3',
        style4: 'Style 4',
        style5: 'Style 5',
        style6: 'Style 6',
        style7: 'Style 7',
        html: 'HTML Content',
        image: 'Image Content',
        slide: 'Slide',
        FAQItem: 'FAQ Element',
        benefit: 'Benefit',
        category: 'Category',
        galleryItem: 'Gallery Item',
        productItem: 'Product Item',
        hoverTextItem: 'Item',
        customContent: 'Content Item',
        property: 'property',
        testimonial: 'testimonial',
        textGridItem: 'textGrid Item',

        noHeading: 'No heading',
        align: 'Align',
    },
    sectionOptions: {
        groups: {
            heading: 'Headline',
            content: 'Content',
            columns: 'Item per Row',
            list: 'Item per Row', // вместо columns
            background: 'Background',
            limit: 'Limit',
            seeAll: 'Add See All Button',
            btnSeeCatalog: 'Button "See All"',
            btnLoadMore: 'Button "Load More"',
            personalSettings: 'Set personal settings',
            catalogSlug: 'Choose Catalog',
            categorySlug: 'Choose Category',
            product: 'Choose product',
            rows: 'Rows',
            button: 'Button',
            align: 'Align',
            template: 'Template',
            grid: 'Grid',
            section: 'Section',
            container: 'Container',
            footer: 'Footer',
            itemsStyle: 'Items style',
            itemsLayout: 'Items layout',
        },
        props: {
            align: 'Align',
            avatar: 'Avatar',
            author: 'Author',
            alignContent: 'Align Content',
            alignHorizontal: 'Align Horizontal',
            alignVertical: 'Align Vertical',
            borderRadius: 'Border Radius',
            button: 'Button',
            container: 'Container',
            containerWidth: 'Container Width',
            title: 'Title',
            textAlign: 'Text Align',
            note: 'Text',
            btnText: 'Text',
            btnConfirm: 'Text of confirm button',
            btnReject: 'Text of reject button',
            enabled: 'Enabled',
            text: 'Text',
            html: 'Text',
            htmlBefore: 'Text Before',
            htmlAfter: 'Text After',
            icon: 'Icon',
            image: 'Image',
            imageMobile: 'Image Mobile',
            imageAlt: 'Image Alt text',
            imageTitle: 'Image title text',
            fontStyle: 'Font style',
            desktop: 'Desktop',
            tablet: 'Tablet',
            mobile: 'Mobile',
            link: 'Link',
            mapUrl: 'Google Map URL',
            mapUrlNote: 'You should use google embed map (iframe). Copy and paste the "src" attributes into this field',
            btnSeeAll: 'Add button "See All"',
            btnSeeAllText: 'Button Text',
            btnSeeCatalogShow: 'Is button show',
            btnSeeCatalogText: 'Button Text',
            btnPersonalSettings: 'Personal settings',
            btnPersonalSettingsNote: 'By default, all options are taken from common settings.',
            btnPersonalSettingsText: 'Use personal settings',
            color: 'Color',
            tag: 'Tag',
            listWidth: 'List Width',
            useCarousel: 'Use Carousel',
            order: 'Order',
            orderMobile: 'Mobile Order',
            limitCustom: 'Use Custom',
            limitCustomCount: 'Custom number item per page',
            rating: 'Rating',
            showButton: 'Show Button',
            padding: 'Padding',
            width: 'Width',
            marginTop: 'Margin top',
            marginBottom: 'Margin bottom',
            useDefault: 'Use default settings',
            imageRatio: 'Image aspect ratio',
            imageMobileRatio: 'Image aspect ratio mobile',
            showArrow: 'Show carousel arrows on hover',
            showDots: 'Show carousel dots',
            colorText: 'Text color',
            colorTag: 'Tag color',
            background: 'Background',
            opacity: 'Opacity',
            height: 'Height',
            heightDesktop: 'Desktop Height',
            heightMobile: 'Mobile Height',
            video: 'Video ID',
            videoNote: 'You should use YouTube or Vimeo videos. To embed, use an iframe, link or Id.',
            media: 'Video or image',
            colorOverlay: 'Overlay color',
            source: 'Source',
            ratio: 'Aspect ratio',
            opacityMobile: 'Opacity mobile',
            sectionLayout: 'Section layout',
            autoplay: 'Autoplay',
            autoplaySpeed: 'Autoplay speed',
            itemTemplate: 'Items template',
            minHeight: 'Minimum height',
            imageProportions: 'Image proportions',
            colorInput: 'Color input',
            colorLabel: 'Color label',
            colorError: 'Color error',
            borderColor: 'Color border',
            previewImage: 'Preview Image',
            usePreviewImage: 'Preview Image',
            usePreviewImageText: 'Use Preview Image',
        },
        items: {
            light: 'Light',
            middle: 'Middle',
            dark: 'Dark',
            left: 'Left',
            center: 'Center',
            right: 'Right',
            useNo: 'No use',
            useMobile: 'On Mobile',
            useBoth: 'On Mobile and Desktop',
            overlay: 'Center Overlay',
            overlayLeft: 'Left Overlay',
            overlayRight: 'Right Overlay',
            textFirst: 'Text First',
            imageFirst: 'Image First',
            noText: 'Without Text',
            small: 'Small',
            normal: 'Normal',
            fullwidth: 'Fullwidth',
            default: 'Default',
            custom: 'Custom',
            none: 'None',
        },
    },
    addNew: {
        block: {
            btn: 'Add item',
        },
        section: {
            btn: 'Add section',
        },
        pasteSection: 'Paste section',
    },
    menuEditor: {
        add: 'Add',
        edit: 'Edit',
        remove: 'Remove',
        title: 'Title',
        link: 'Link',
        sectionId: 'Section Id',
    },
    global: {
        btns: {
            pageSettings: 'Page Settings',
            settings: 'Settings',
            settingsPage: 'Page Settings',
            settingsSite: 'Website Settings',
            savePage: 'Save',
            createPage: 'Publish',
            remove: 'Remove',
            copy: 'Сopy',
            copyContent: 'Сopy Content',
            pasteContent: 'Paste Content',
            dublicate: 'Dublicate',
            hidden: 'Hidden section',
        },
    },
};
