export default {
    // TEXT
    // default
    text_main: '#1A1A1A',
    text_body1: '#494949',
    text_body2: '#4A5264',
    text_accent: '#747DAA',
    text_neutral: '#D7D9E1',
    // inverted
    text_main_inverted: '#FFFFFF',
    text_body1_inverted: '#2ABAC6',
    text_body2_inverted: '#A2A9BA',
    text_accent_inverted: '#B3B9C7',
    text_neutral_inverted: '#959AA6',

    // ACTION
    // primary
    action_primary: '#2ABAC6',
    action_primary_hover: '#42CCC0',
    action_primary_pressed: '#13666D',
    action_primary_disabled: '#B0BBD1',
    // secondary
    action_secondary: '#2ABAC6',
    action_secondary_hover: '#097E88',
    action_secondary_pressed: '#24A3AE',
    action_secondary_disabled: '#B0BBD1',
    // neutral_light
    header_bg: '#FFFFFF',
    header_bg_mobile: '#FFFFFF',
    header_bg_top_bar: '#ACEDE5',
    header_main_menu_bg: '#FFFFFF',
    action_neutral_light: '#1A1A1A',
    header_main_menu_dropdown_bg: '#AAE6E0',
    action_neutral_light_hover: '#8ACFC8',
    action_neutral_light_pressed: '#00838F',
    action_neutral_light_disabled: '#98A9D0',
    // neutral_medium
    action_neutral_medium: '#8794C0',
    action_neutral_medium_hover: '#849BE8',
    action_neutral_medium_pressed: '#636C8C',
    action_neutral_medium_disabled: '#636C8C',
    // neutral_dark
    action_neutral_dark: '#404969',
    action_neutral_dark_hover: '#5A6794',
    action_neutral_dark_pressed: '#1F2333',
    action_neutral_dark_disabled: '#D7D9E1',
    // system
    action_system: '#D96E95',
    action_system_hover: '#D5628B',
    action_system_pressed: '#CE4A79',
    action_system_disabled: '#B3B9C7',

    // NEUTRAL
    neutral1: '#1C2135',
    neutral2: '#ACEDE5',
    neutral3: '#ACEDE5',
    neutral4: '#7FD2D7',
    neutral5: '#ACEDE5',
    neutral6: '#F6F7F8',
    neutral7: '#FFFFFF',

    // SYSTEM
    success: '#3EDACC',
    success_bg: '#EFF5F1',
    warning: '#F0B748',
    warning_bg: '#FFF9EE',
    error: '#D96E95',
    error_bg: '#F8EAEF',

    // ACCENT
    accent1: '#DDC178',
    accent1_bg: '#FFF7E3',
    accent2: '#F3E381',
    accent2_bg: '#FFFBE5',
    accent3: '#3EDACC',
    accent3_bg: '#E0FFF9',
    accent4: '#7D76DF',
    accent4_bg: '#E7E6F9',

    whatsapp: '#2ABAC6',
};
