export default {
    previewImageId: {
        component: 'image-field',
        ...{
            props: {
                labelTrans: 'entities.page.fields.previewImageId',
                imageType: 'page',
                type: 'content',
                note: 'JPG, PNG. Recomended size: 1200х630px. No more than 500kb',
            },
        },
    },
    metaTitle: {
        component: 'input-text',
        props: {
            labelTrans: 'entities.page.fields.metaTitle',
        },
    },
    metaDescription: {
        component: 'Textarea',
        props: {
            labelTrans: 'entities.page.fields.metaDescription',
        },
    },
};
