<template>
    <div class="page notifications -width-lg">
        <progress-circular class="spinner -absolute" v-if="!isDataLoaded"></progress-circular>
        <template v-else>
            <div class="page-header">
                <h1 class="page-header__text">{{ $t('menus.pageHeaders.notification') }}</h1>
            </div>
            <div class="notifications__container" v-if="isDataLoaded">
                <email-notifications :domains="domains" :instance="instance" :settings="settings.notifierEmail">
                </email-notifications>
                <!--                <notification :settings="paymentNotification"></notification>-->
                <!--                <notification :settings="orderNotification"></notification>-->
                <telegram-notifications :settings="settings.notifierTelegram"></telegram-notifications>
            </div>
        </template>
    </div>
</template>

<script>
import SettingService from '@/services/SettingService';
import fields from '@/entities/notifications.fields';
import { merge } from 'lodash';
import { mapGetters } from 'vuex';
import EmailNotifications from '@/components/notifications/EmailNotifications';
import TelegramNotifications from '@/components/notifications/TelegramNotifications';
import DomainService from '@/services/DomainService';
import EmailService from '@/services/EmailService';
import Domain from '@/entities/domain/Domain';
import Email from '@/entities/email/Email';
import { getDefaultSettings } from '@/entities/setting/SettingHelper';
import ProgressCircular from '@/components/common/ProgressCircular';
// import Notification from '@/components/notifications/Notification';

export default {
    name: 'NotificationSettings',
    components: {
        ProgressCircular,
        // Notification,
        TelegramNotifications,
        EmailNotifications,
    },

    async created() {
        this.isDataLoaded = false;
        this.getDefaultSettings();
        await this.getSettings();
        await this.getEmails();
        await this.getDomains();
        this.isDataLoaded = true;
    },

    data() {
        return {
            isDataLoaded: false,
            settings: {},
            domainCanBeUsedInEmail: true,
            isDomainConfirmed: true,
            orderNotification: {},
            paymentNotification: {},
            domains: [],
            fields,
            fieldKeys: Object.keys(fields),
        };
    },

    computed: {
        ...mapGetters('globalVars', {
            instance: 'getInstance',
        }),
    },

    methods: {
        async getDomains() {
            const [error, result] = await DomainService.getAll(this.instance);
            if (error) {
                error.notify();
            }
            return result.map((item) => new Domain(item));
        },
        async getEmails() {
            const [error, data] = await EmailService.getAll();
            if (error) {
                error.notify();
            } else {
                const emails = data.map((item) => new Email(item));
                this.orderNotification = emails.find((item) => item.notification === 'order/created');
                this.paymentNotification = emails.find((item) => item.notification === 'payment/event');
            }
        },
        async getSettings() {
            const [error, settings] = await SettingService.getAll({
                groups: ['notifierEmail', 'notifierTelegram'],
            });
            if (error) error.notify();
            const defaultSettings = this.getDefaultSettings();
            for (const groupName in settings) {
                if (Object.keys(settings[groupName]).length) {
                    this.settings[groupName] = merge(defaultSettings[groupName], settings[groupName]);
                }
            }
        },

        getDefaultSettings() {
            return getDefaultSettings({
                groups: ['notifierEmail', 'notifierTelegram'],
            });
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.notifications {
    &__container {
        display: flex;
        flex-direction: column;
        gap: 32px;
    }
    .address-card {
        &__img-container {
            margin-right: 16px;
        }
        &__img {
            width: 100%;
            height: 100%;
        }
    }

    .tooltip__link {
        text-decoration: underline;
    }

    &__save-button {
        margin-left: auto;
    }

    .-notifierTelegramTemplate textarea {
        height: 370px;
    }
}
</style>
