import { cloneDeep } from 'lodash';
import { HistoryTypes, removeNamespace } from '@/store/Types.js';
import EventEmitter from '@/helpers/eventEmitter.ts';

const Types = removeNamespace(HistoryTypes);

const state = {
    lastHistoryItem: null,
    historyTree: [],
    currentHistiryPosition: 0,
};

const mutations = {
    /**
     * Обновление текущей позиции в массиве истории изменений для перемещения вперед/назад
     * @param {Object} state
     * @param {Object} payload
     */
    [Types.mutations.UPDATE_HISTORY_POSITION](state, payload) {
        if (payload.type === 'undo') state.currentHistiryPosition -= 1;
        else if (payload.type === 'redo') state.currentHistiryPosition += 1;
    },
    /**
     * Добавление элемента в массив истории изменений
     * @param {Object} state
     * @param {Object} payload
     */
    [Types.mutations.ADD_HISTORY_ITEM](state, { page, settings }) {
        const historyItem = {
            page: cloneDeep(page),
            settings: cloneDeep(settings),
        };
        state.historyTree.push(historyItem);
        state.lastHistoryItem = historyItem;
        state.currentHistiryPosition = state.historyTree.length - 1;
    },
    /**
     * Очистка истории изменений страницы/настроек
     * @param {Object} state
     */
    [Types.mutations.CLEAR_HISTORY](state) {
        state.historyTree = state.historyTree.slice(-1);
        state.currentHistiryPosition = 0;
    },
};
const actions = {
    [Types.actions.UPDATE_HISTORY]({ commit, getters }, payload) {
        const page = payload.page || getters[Types.getters.CURRENT_PAGE];
        const settings = payload.settings || getters[Types.getters.CURRENT_SETTINGS];
        commit(Types.mutations.ADD_HISTORY_ITEM, { page, settings });
    },
    [Types.actions.RESET_HISTORY]({ commit }) {
        commit(Types.mutations.CLEAR_HISTORY);
    },
    [Types.actions.UPDATE_CURRENT]({ getters, commit }) {
        const mutation = 'builder/updateFromHistory';
        const payload = {
            page: getters[Types.getters.CURRENT_PAGE],
            settings: getters[Types.getters.CURRENT_SETTINGS],
        };
        commit(mutation, cloneDeep(payload), { root: true });
        EventEmitter.sendToPublic('update-settings', { data: cloneDeep(getters[Types.getters.CURRENT_SETTINGS]) });
        EventEmitter.sendToPublic('update-page-options', { data: cloneDeep(getters[Types.getters.CURRENT_PAGE].data) });
        EventEmitter.sendToPublic('update-sections', {
            data: getters[Types.getters.CURRENT_PAGE].sections.map((section) => section.data),
        });
    },
};

const getters = {
    [Types.getters.CURRENT_PAGE](state) {
        const historyPosition = state.currentHistiryPosition;
        if (state.historyTree[historyPosition]) return state.historyTree[historyPosition].page;
        else if (state.lastHistoryItem) return state.lastHistoryItem.page;
        else return {};
    },
    [Types.getters.CURRENT_SETTINGS](state) {
        const historyPosition = state.currentHistiryPosition;
        if (state.historyTree[historyPosition]) return state.historyTree[historyPosition].settings;
        else if (state.lastHistoryItem) return state.lastHistoryItem.settings;
        else return {};
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
