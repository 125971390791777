import UserService from '@/services/UserService.ts';
import detectInstanceId from '@/api/detectInstanceId';

const state = {
    instance: null,
    instances: [],
    catalogProductsPage: 1,
    catalogProductsLimit: 10,
    productsPage: 1,
    productsLimit: 50,
    catalogsPage: 1,
    catalogsLimit: 100,
    pagesPage: 1,
    pagesLimit: 100,
    ordersPage: 1,
    ordersLimit: 100,
    productReviewsPage: 1,
    productReviewsLimit: 100,
    leadsPage: 1,
    leadsLimit: 100,
    articlesPage: 1,
    articlesLimit: 100,
    authorsPage: 1,
    authorsLimit: 100,
    categoriesPage: 1,
    categoriesLimit: 100,
    commentsPage: 1,
    commentsLimit: 100,
    currentUser: null,
    device: null,
};

const mutations = {
    SET_INSTANCE(state, payload) {
        state.instance = payload;
    },
    SET_INSTANCES(state, payload) {
        state.instances = payload;
    },
    SET_CURRENT_USER(state, payload) {
        state.currentUser = payload;
    },
    SET_CATALOG_PRODUCTS_PAGE(state, payload) {
        state.catalogProductsPage = payload;
    },
    SET_CATALOG_PRODUCTS_LIMIT(state, payload) {
        state.catalogProductsLimit = payload;
    },
    SET_PRODUCTS_PAGE(state, payload) {
        state.productsPage = payload;
    },
    SET_PRODUCTS_LIMIT(state, payload) {
        state.productsLimit = payload;
    },
    SET_CATALOGS_PAGE(state, payload) {
        state.catalogsPage = payload;
    },
    SET_CATALOGS_LIMIT(state, payload) {
        state.catalogsLimit = payload;
    },
    SET_PAGES_PAGE(state, payload) {
        state.pagesPage = payload;
    },
    SET_PAGES_LIMIT(state, payload) {
        state.pagesLimit = payload;
    },
    SET_ORDERS_PAGE(state, payload) {
        state.ordersPage = payload;
    },
    SET_ORDERS_LIMIT(state, payload) {
        state.ordersLimit = payload;
    },
    SET_PRODUCT_REVIEWS_PAGE(state, payload) {
        state.productReviewsPage = payload;
    },
    SET_PRODUCT_REVIEWS_LIMIT(state, payload) {
        state.productReviewsLimit = payload;
    },
    SET_LEADS_PAGE(state, payload) {
        state.leadsPage = payload;
    },
    SET_LEADS_LIMIT(state, payload) {
        state.leadsLimit = payload;
    },
    SET_COMMENTS_PAGE(state, payload) {
        state.commentsPage = payload;
    },
    SET_COMMENTS_LIMIT(state, payload) {
        state.commentsLimit = payload;
    },
    SET_ARTICLES_PAGE(state, payload) {
        state.articlesPage = payload;
    },
    SET_ARTICLES_LIMIT(state, payload) {
        state.articlesLimit = payload;
    },
    SET_CATEGORIES_PAGE(state, payload) {
        state.catalogsPage = payload;
    },
    SET_CATEGORIES_LIMIT(state, payload) {
        state.categoriesLimit = payload;
    },
    SET_AUTHORS_PAGE(state, payload) {
        state.authorsPage = payload;
    },
    SET_AUTHORS_LIMIT(state, payload) {
        state.authorsLimit = payload;
    },
    SET_DEVICE(state, payload) {
        state.device = payload;
    },
};
const actions = {
    async loadInstance({ commit }) {
        const instanceId = detectInstanceId();
        commit('SET_INSTANCE', instanceId);
    },
    async loadInstances({ commit }) {
        const [error, result] = await UserService.getInctancesAll();
        if (error) return error;
        commit('SET_INSTANCES', result);
    },
    async loadUser({ commit }) {
        const [error, user] = await UserService.getCurrent();
        if (error) console.log(error);
        else commit('SET_CURRENT_USER', user);
    },
    changeCatalogProductsPage({ commit }, page) {
        commit('SET_CATALOG_PRODUCTS_PAGE', page);
    },
    changeCatalogProductsLimit({ commit }, limit) {
        commit('SET_CATALOG_PRODUCTS_LIMIT', limit);
    },
    changeProductsPage({ commit }, page) {
        commit('SET_PRODUCTS_PAGE', page);
    },
    changeProductsLimit({ commit }, limit) {
        commit('SET_PRODUCTS_LIMIT', limit);
    },
    changeCatalogsPage({ commit }, page) {
        commit('SET_CATALOGS_PAGE', page);
    },
    changeCatalogsLimit({ commit }, limit) {
        commit('SET_CATALOGS_LIMIT', limit);
    },
    changePagesPage({ commit }, page) {
        commit('SET_PAGES_PAGE', page);
    },
    changePagesLimit({ commit }, limit) {
        commit('SET_PAGES_LIMIT', limit);
    },
    changeOrdersPage({ commit }, page) {
        commit('SET_ORDERS_PAGE', page);
    },
    changeOrdersLimit({ commit }, limit) {
        commit('SET_ORDERS_LIMIT', limit);
    },
    changeProductReviewsPage({ commit }, page) {
        commit('SET_PRODUCT_REVIEWS_PAGE', page);
    },
    changeProductReviewsLimit({ commit }, limit) {
        commit('SET_PRODUCT_REVIEWS_LIMIT', limit);
    },
    changeLeadsPage({ commit }, page) {
        commit('SET_LEADS_PAGE', page);
    },
    changeLeadsLimit({ commit }, limit) {
        commit('SET_LEADS_LIMIT', limit);
    },
    changeCommentsPage({ commit }, page) {
        commit('SET_COMMENTS_PAGE', page);
    },
    changeCommentsLimit({ commit }, limit) {
        commit('SET_COMMENTS_LIMIT', limit);
    },
    changeArticlesPage({ commit }, page) {
        commit('SET_ARTICLES_PAGE', page);
    },
    changeArticlesLimit({ commit }, limit) {
        commit('SET_ARTICLES_LIMIT', limit);
    },
    changeCategoriesPage({ commit }, page) {
        commit('SET_CATEGORIES_PAGE', page);
    },
    changeCategoriesLimit({ commit }, limit) {
        commit('SET_CATEGORIES_LIMIT', limit);
    },
    changeAuthorsPage({ commit }, page) {
        commit('SET_AUTHORS_PAGE', page);
    },
    changeAuthorsLimit({ commit }, limit) {
        commit('SET_AUTHORS_LIMIT', limit);
    },
    setDevice({ commit }, device) {
        commit('SET_DEVICE', device);
    },
};

const getters = {
    getInstance: (state) => state.instance || '',
    getInstances: (state) => state.instances || [],
    getInstanceFull: (state) => {
        return state.instances.find((item) => item.instanceId === state.instance);
    },
    getCurrentUser: (state) => state.currentUser || 'loading...',
    getCatalogProductsPage: (state) => state.catalogProductsPage,
    getCatalogProductsLimit: (state) => state.catalogProductsLimit,
    getProductsPage: (state) => state.productsPage,
    getProductsLimit: (state) => state.productsLimit,
    getCatalogsPage: (state) => state.catalogsPage,
    getCatalogsLimit: (state) => state.catalogsLimit,
    getPagesPage: (state) => state.pagesPage,
    getPagesLimit: (state) => state.pagesLimit,
    getOrdersPage: (state) => state.ordersPage,
    getOrdersLimit: (state) => state.ordersLimit,
    getProductReviewsPage: (state) => state.productReviewsPage,
    getProductReviewsLimit: (state) => state.productReviewsLimit,
    getCommentsPage: (state) => state.commentsPage,
    getCommentsLimit: (state) => state.commentsLimit,
    getArticlesPage: (state) => state.articlesPage,
    getArticlesLimit: (state) => state.articlesLimit,
    getAuthorsPage: (state) => state.authorsPage,
    getAuthorsLimit: (state) => state.authorsLimit,
    getCategoriesPage: (state) => state.categoriesPage,
    getCategoriesLimit: (state) => state.categoriesLimit,
    getDevice: (state) => state.device,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
