export default {
    // TEXT
    // default
    text_main: '#3B3938',
    text_body1: '#4A4948',
    text_body2: '#757372',
    text_accent: '#FF6E03',
    text_neutral: '#969696',
    // inverted
    text_main_inverted: '#FFFFFF',
    text_body1_inverted: '#6C6C6C',
    text_body2_inverted: '#898988',
    text_accent_inverted: '#FF6E03',
    text_neutral_inverted: '#F5F5F5',

    // ACTION
    // primary
    action_primary: '#202020',
    action_primary_hover: '#7C7979',
    action_primary_pressed: '#403E3D',
    action_primary_disabled: '#E7E7E7',
    // secondary
    action_secondary: '#7C7979',
    action_secondary_hover: '#ABABAB',
    action_secondary_pressed: '#3F372E',
    action_secondary_disabled: '#E7E7E7',
    // neutral_light
    header_bg: '#FFFFFF',
    header_bg_mobile: '#3C3C3E',
    header_main_menu_bg: '#3C3C3E',
    header_main_menu_dropdown_bg: '#6E6E72',
    action_neutral_light: '#FFFFFF',
    // action_neutral_light_hover: '#E4E4E4',
    // action_neutral_light_pressed: '#383838',
    action_neutral_light_hover: '#7C7979',
    action_neutral_light_pressed: '#ABABAB',
    action_neutral_light_disabled: '#A1A1A5',
    // neutral_medium
    action_neutral_medium: '#B5BABF',
    action_neutral_medium_hover: '#7C7979',
    action_neutral_medium_pressed: '#403E3D',
    action_neutral_medium_disabled: '#E7E7E7',
    // neutral_dark
    action_neutral_dark: '#202020',
    action_neutral_dark_hover: '#5E5B5A',
    action_neutral_dark_pressed: '#474544',
    action_neutral_dark_disabled: '#E7E7E7',
    // system
    action_system: '#EC4722',
    action_system_hover: '#BD391B',
    action_system_pressed: '#902B14',
    action_system_disabled: '#E7E7E7',

    // NEUTRAL
    neutral1: '#202020',
    neutral2: '#3C3C3E',
    neutral3: '#6E6E72',
    neutral4: '#A1A1A5',
    neutral5: '#D5D5D7',
    neutral6: '#F5F5F5',
    neutral7: '#FFFFFF',

    // SYSTEM
    success: '#41B619',
    success_bg: '#F6FAF5',
    warning: '#FFA000',
    warning_bg: '#FFF9EE',
    error: '#EC4722',
    error_bg: '#FBDAD3',

    // ACCENT
    accent1: '#FF6E03',
    accent1_bg: '#FFE2CD',
    accent2: '#F7C100',
    accent2_bg: '#FFF8CC',
    accent3: '#19B585',
    accent3_bg: '#D1F0E7',
    accent4: '#534CE0',
    accent4_bg: '#DDDBF9',

    whatsapp: '#71D062',
};
