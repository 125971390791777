import { cloneDeep } from 'lodash';
import { Background, Container, Content, Footer, Heading, Section } from '@/entities/section/samples/_options';
import { BUTTON } from '@/helpers/fieldTypes.no-vuetify';

const section = {
    id: '',
    type: 'SectionTextBlockGrid',
    required: false,
    group: 'TextBlocks',
    options: {
        section: { ...Section },
        container: { ...Container },
        heading: { ...Heading },
        footer: { ...Footer },
    },
    items: [],
    children: {
        id: '',
        type: 'textGridItem',
        options: {
            heading: {
                title: {
                    ...Heading.title,
                    value: 'Block Name',
                },
            },
            content: {
                html: { ...Content.html },
                image: {
                    ...Content.image,
                    props: {
                        note: 'JPG, PNG. Recomended size: 585х585px. No more than 250kb',
                    },
                },
                alt: { ...Content.imageAlt },
                background: { ...Background },
            },
            button: {
                value: {
                    isShow: false,
                    styles: 'primary',
                    text: 'Button',
                    textColor: null,
                    bgColor: null,
                    link: null,
                    iconLeft: null,
                    iconRight: null,
                    type: 'Link',
                    url: null,
                },
                ...BUTTON,
            },
        },
    },
};

const itemFixture = cloneDeep(section.children);
const itemsFixture = [];
itemsFixture.push(cloneDeep(itemFixture));
itemsFixture.push(cloneDeep(itemFixture));
itemsFixture.push(cloneDeep(itemFixture));
section.items = itemsFixture;

export default section;
