export default function detectInstanceId(): string {
    // const paths = window.location.pathname.split('/');
    // if (paths.length < 2) {
    //     throw new Error('Unable to detect instance id');
    // }
    // console.log('Instance id detected: ' + paths[1]);
    // return paths[1];
    return localStorage.getItem('runshop_instance_id') || '';
}

export function buildApiUrl(): string {
    const id = detectInstanceId();
    return `${process.env.VUE_APP_API_URL}`.replace('{id}', id);
}
