export const validateNoEmpty = (value) => {
    return Array.isArray(value) ? value.length !== 0 : value !== '' && value !== null && value !== undefined;
};

export const validateEmail = (value) => {
    if (!value) return true;
    //eslint-disable-next-line
    const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    return reg.test(value);
};

export const validatePhone = (value) => {
    if (!value) return true;
    let isValid = false;
    const reg = /^\+?\d+\s*(\(\s*\d+\s*\))?\s*(\d+(-|\s+))*\d+$/im;
    isValid = reg.test(value);
    if (isValid) {
        const numbers = value.replace(/\D/g, '');
        isValid = numbers.length >= 4;
    }
    return isValid;
};

export const validateUrl = (value) => {
    if (!value) return { isValid: true };
    //eslint-disable-next-line
    const expression = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi;
    const regex = new RegExp(expression);
    const isValid = regex.test(value);
    return isValid;
};

const validateRules = {
    noEmpty: {
        errorMessage: 'validator.errors.empty',
        test: validateNoEmpty,
    },
    email: {
        errorMessage: 'validator.errors.email',
        test: validateEmail,
    },
    phone: {
        errorMessage: 'validator.errors.phone',
        test: validatePhone,
    },
    url: {
        errorMessage: 'validator.errors.url',
        test: validateUrl,
    },
};

/**
 * Validate fields value according to the rules
 * @param value field value
 * @param rules validate rules
 * @returns {*[]} array of errors
 */
const validateField = (value, rules) => {
    if (!Array.isArray(rules)) return [];
    const errors = [];
    rules.forEach((rule) => {
        const validateRule = validateRules[rule];
        if (validateRule && !validateRule.test(value)) errors.push(validateRule.errorMessage);
    });
    return errors;
};

export default validateField;
