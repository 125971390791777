<template>
    <div class="zones-tree">
        <zones-tree-node @toggle-select="toggleSelect" v-for="continent in tree" :node="continent" :key="continent.key">
        </zones-tree-node>
    </div>
</template>

<script>
import ZonesTreeNode from '@/components/delivery/zonesTreeNode';
import Tree from '@/components/delivery/Tree';
import { proxyModelMixin } from '@/mixins/proxyModelMixin';
import { cloneDeep } from 'lodash';
export default {
    name: 'ZonesTree',
    components: { ZonesTreeNode },
    mixins: [proxyModelMixin],
    props: {
        items: {
            type: Array,
            default: () => [],
        },
        value: {
            type: Array,
            default: () => [],
        },
        search: {
            type: String,
            default: '',
        },
    },
    data: function() {
        return {
            tree: [],
            initialTree: [],
        };
    },
    created() {
        this.tree = Tree.createTree(this.items, this.model, 'code');
        this.initialTree = cloneDeep(this.tree);
    },
    methods: {
        toggleSelect({ node, value }) {
            Tree.toggleNodeObject(node, 'isSelected', value);
            const selected = [];
            this.tree.forEach((item) => {
                selected.push(...Tree.getFlatNodeByCondition(item, 'isSelected', true));
            });
            const countriesMap = {};
            const result = [];
            selected.forEach((item) => {
                if (item.code.includes(':')) {
                    const countryCode = item.code.split(':')[0];
                    if (countriesMap[countryCode] === undefined) {
                        countriesMap[countryCode] = true;
                        result.push(countryCode);
                    }
                }
                result.push(item.code);
            });
            this.model = result;
        },
    },
    watch: {
        search(val) {
            if (val.length >= 3) {
                const result = [];
                this.initialTree.forEach((item) => {
                    result.push(...Tree.searchInNode(item, val));
                });
                this.tree = result;
            } else {
                this.tree = cloneDeep(this.initialTree);
            }
        },
    },
};
</script>

<style lang="scss">
.zones-tree {
    height: 300px;
    overflow: auto;
    padding: 8px;
    margin-top: 24px;
}
</style>
