<template>
    <div>
        <!--        <v-toolbar class="mb-5">
            <Button type="success" @click="updateResources">Update</Button>
        </v-toolbar>
        <v-toolbar class="mb-5">
            <v-autocomplete
                v-model="currentFields"
                :items="Object.entries(fields)"
                :item-text="([key, value]) => key"
                :item-value="([key, value]) => key"
                :item-disabled="([key]) => $options.defaultColumns.includes(key)"
                outlined
                dense
                chips
                small-chips
                deletable-chips
                label="Fields"
                multiple
            ></v-autocomplete>
        </v-toolbar>
        <v-data-table
            :headers="columns"
            :items="localResources"
            hide-default-footer
            disable-pagination
            :items-per-page="-1"
        >
            <template v-if="resourceType === 'product'" #item="{item, headers}">
                <tr>
                    <td v-for="(header, index) in headers" :key="index">
                        <component
                            :is="fields[header.value].component"
                            v-model="item[header.value]"
                            v-bind="getProps(fields[header.value])"
                        ></component>
                    </td>
                </tr>
                <template v-if="item.variations">
                    <tr v-for="variation in item.variations" :key="variation.id" class="subrow">
                        <td v-for="(header, index) in headers" :key="index">
                            <component
                                :is="fields[header.value].component"
                                v-if="hasField(variation, header.value)"
                                v-model="variation[header.value]"
                                v-bind="getProps(fields[header.value])"
                                :disabled="header.value === 'title'"
                            ></component>
                        </td>
                    </tr>
                </template>
            </template>
            <template v-else #item="{item, headers}">
                <tr>
                    <td v-for="(header, index) in headers" :key="index">
                        <component
                            :is="fields[header.value].component"
                            v-model="item[header.value]"
                            v-bind="getProps(fields[header.value])"
                        ></component>
                    </td>
                </tr>
            </template>
        </v-data-table>-->
    </div>
</template>

<script>
import ProductService from '@/services/ProductService';
import Product from '@/entities/product/Product';
import CatalogService from '@/services/CatalogService';
import { CATALOG_FIELDS } from '@/entities/catalog/Catalog.fields';
import PageService from '@/services/PageService';
import { TEXT_PAGE_FIELDS } from '@/entities/page/Textpage.fields';
//TODO: нам вообще нужен этот компонент?
export default {
    name: 'BulkEditor',

    fieldProps: {
        dense: true,
        'hide-details': true,
        outlined: true,
    },

    availableResourceTypes: {
        product: {
            fields: new Product().fields,
            get: ProductService.getAll.bind(ProductService),
            update: ProductService.updateBatch.bind(ProductService),
        },
        catalog: {
            fields: CATALOG_FIELDS,
            get: CatalogService.getAll.bind(CatalogService),
            update: CatalogService.updateBatch.bind(CatalogService),
        },
        textpage: {
            fields: TEXT_PAGE_FIELDS,
            get: PageService.getAll.bind(PageService),
            update: PageService.updateBatch.bind(PageService),
        },
    },

    defaultColumns: ['title'],

    created() {
        this.getResources();
    },

    data() {
        return {
            localResources: [],
            currentFields: [...this.$options.defaultColumns],
        };
    },

    computed: {
        resourceType() {
            return this.$route.query.resourceType;
        },
        resourceIds() {
            return this.$route.query.resourceIds;
        },
        fields() {
            return Object.fromEntries(
                Object.entries(this.$options.availableResourceTypes[this.resourceType].fields).filter((field) => {
                    return field[1].bulkEditable;
                })
            );
        },
        columns() {
            if (!this.currentFields.length) return [];

            return this.currentFields.map((fieldName) => {
                return {
                    text: fieldName,
                    value: fieldName,
                };
            });
        },
    },

    methods: {
        async getResources() {
            try {
                const resources = await this.$options.availableResourceTypes[this.resourceType].get({
                    ids: this.resourceIds,
                });
                this.localResources = resources;
            } catch (error) {
                console.error(error);
            }
        },
        async updateResources() {
            try {
                await this.$options.availableResourceTypes[this.resourceType].update(this.localResources);
            } catch (error) {
                console.error(error);
            }
        },
        hasField(item, field) {
            return field in item;
        },
        getProps(field) {
            return {
                ...this.$options.fieldProps,
                ...field.props,
            };
        },
    },
};
</script>

<style lang="scss">
.subrow {
    td:first-of-type {
        // Временный костыль для выделения "дочерних" строк
        padding-left: 30px !important;
    }
}
</style>
