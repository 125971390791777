<template>
    <div class="tooltip-popup">
        <div
            ref="activator"
            class="tooltip-popup__activator"
            @mouseover="isTooltipShown = true"
            @mouseleave="isTooltipShown = false"
        >
            <slot name="activator">
                <span class="tooltip-popup__btn material-symbols-outlined">error_outline</span>
            </slot>
        </div>
        <transition name="content">
            <div
                v-show="isTooltipShown"
                ref="content"
                class="tooltip-popup__content"
                :class="classes"
                :style="{ width: `${width}px` }"
            >
                <div class="tooltip-popup__content-prepend">
                    <slot name="content-prepend">
                        <span class="tooltip-popup__content-icon material-symbols-outlined">error_outline</span>
                    </slot>
                </div>
                <div class="tooltip-popup__content-text"><slot></slot></div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: 'TooltipPopup',
    props: {
        width: {
            type: String,
            default: '270',
        },
        position: {
            type: String,
            default: 'bottom',
            validator: (value) => ['bottom', 'top', 'left', 'right'].includes(value),
        },
    },
    mounted() {
        const activator = this.$refs.activator;
        const content = this.$refs.content;
        const activatorRect = activator.getBoundingClientRect();
        const contentWidth = this.width;
        const activatorWidth = activatorRect.width;
        const activatorHeight = activatorRect.height;
        if (this.position === 'top' || this.position === 'bottom') {
            if (this.position === 'top') {
                content.style.bottom = activatorHeight + 7 + 'px';
            } else {
                content.style.top = activatorHeight + 7 + 'px';
            }
            content.style.left = -contentWidth / 2 + activatorWidth / 2 + 'px';
        } else {
            if (this.position === 'left') {
                //content.style.transform = 'translateX(-100% - 7px)';
                //content.style.bottom = activatorHeight + 7 + 'px';
                content.style.left = -contentWidth - 7 + 'px';
            } else {
                content.style.left = activatorWidth + 7 + 'px';
            }
            content.style.bottom = activatorHeight / 2 + 'px';
            content.style.transform = 'translateY(50%)';
        }
    },
    computed: {
        classes() {
            return `-${this.position}`;
        },
    },
    data() {
        return {
            isTooltipShown: false,
        };
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.tooltip-popup {
    position: relative;
    display: inline-block;
    &__btn,
    &__content-icon {
        color: var(--v-primary-base);
        font-size: 16px;
    }
    &__btn {
        display: block;
        cursor: pointer;
    }
    &__activator {
        width: fit-content;
    }
    &__content {
        position: absolute;
        display: flex;
        gap: 10px;
        height: auto;
        border-radius: 4px;
        padding: 12px 16px;
        background-color: var(--v-primary-lighten-base);
        color: var(--v-primary-accent-base);
        z-index: 100;
        opacity: 1;
    }
    &__content-text {
        white-space: pre-line;
    }
    .content-enter-active,
    .content-leave-active {
        transition: all 0.4s ease;
    }
    .content-enter {
        transform: scale(0);
        opacity: 0;
    }
    .content-leave-to {
        opacity: 0;
    }
    .content-enter-to {
        transform: scale(1);
        opacity: 1;
    }
}
</style>
