<template>
    <div class="builder-sidebar-left">
        <transition mode="out-in" name="sidebar" tag="div">
            <div key="sidebar" v-if="!isShowNewSectionList" class="builder-sidebar-left__container">
                <div class="builder-sidebar-left__header">
                    <BuilderSidebarLeftHeader />
                </div>
                <div class="builder-sidebar-left__scroll">
                    <BuilderSectionCurrentList />
                </div>
                <div class="builder-sidebar-left__footer">
                    <BuilderSidebarLeftFooter />
                </div>
            </div>

            <div v-if="isShowNewSectionList">
                <BuilderSectionNewList />
            </div>
        </transition>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BuilderSidebarLeftHeader from '@/components/builder/sidebarLeft/header.vue';
import BuilderSectionCurrentList from '@/components/builder/sections/currentList.vue';
import BuilderSectionNewList from '@/components/builder/sections/newList.vue';
import BuilderSidebarLeftFooter from '@/components/builder/sidebarLeft/footer.vue';

export default {
    name: 'BuilderSidebarLeft',
    components: {
        BuilderSidebarLeftHeader,
        BuilderSectionCurrentList,
        BuilderSectionNewList,
        BuilderSidebarLeftFooter,
    },
    computed: {
        ...mapGetters('builder', {
            isShowNewSectionList: 'isShowNewSectionList',
        }),
    },
};
</script>

<style scoped lang="scss">
@import '@/scss/variables.scss';
.builder-sidebar-left {
    position: relative;
    height: 100%;
    &__container {
        display: flex;
        flex-direction: column;
        max-height: 100%;
        height: 100%;
    }

    &__header {
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }

    &__scroll {
        width: 100%;
        overflow: auto;
        flex: auto;
    }

    &__footer {
        border-top: 1px solid rgba(0, 0, 0, 0.12);
    }
    .sidebar-enter-active,
    .sidebar-leave-active {
        transition: all 0.1s ease;
    }
    .sidebar-enter {
        opacity: 0;
        transform: translateX(-30px);
    }
    .sidebar-leave-to {
        transform: translateX(-30px);
        opacity: 0;
    }
    .sidebar-leave {
        transform: translateX(0);
        opacity: 1;
    }
    .sidebar-enter-to {
        opacity: 1;
        transform: translateX(0);
    }
}
</style>
