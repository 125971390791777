var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page -width-lg"},[_c('div',{staticClass:"page-header"},[_c('h1',{staticClass:"page-header__text"},[_vm._v(_vm._s(_vm.$t('menus.pageHeaders.comments')))])]),_c('view-comment-modal',{attrs:{"item":_vm.viewItem},model:{value:(_vm.isViewModalOpen),callback:function ($$v) {_vm.isViewModalOpen=$$v},expression:"isViewModalOpen"}}),(!_vm.isAvailable)?_c('error-block'):_c('Table',{attrs:{"columns":_vm.headers,"items":_vm.items,"is-data-loaded":_vm.isDataLoaded,"show-select":true,"pagination":_vm.pagination,"nested":""},on:{"change-per-page":_vm.changePerPage,"delete-selected-items":_vm.openDeletionModal},scopedSlots:_vm._u([{key:"item.enabled",fn:function(ref){
var item = ref.item;
return [_c('input-switch',{attrs:{"value":item.enabled,"name":"hidden","size":"small"},on:{"input":function($event){return _vm.onUpdateFieldValue({ name: 'enabled', value: $event }, item)}}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"table__actions"},[_c('Button',{attrs:{"icon":"visibility","type":"icon"},on:{"click":function($event){return _vm.openViewModal(item)}}}),_c('Button',{attrs:{"title":_vm.$t('lists.deleteButton.product'),"type":"icon","icon":"delete"},on:{"click":function($event){return _vm.openDeletionModal(item)}}})],1)]}},{key:"item.text",fn:function(ref){
var item = ref.item;
return [(item.text)?_c('div',{staticClass:"comments__text"},[_vm._v(_vm._s(item.text))]):[_vm._v("—")]]}},{key:"item.articleId",fn:function(ref){
var item = ref.item;
return [(_vm.articlesMap[item.articleId])?_c('span',{staticClass:"table-edit"},[_c('router-link',{staticClass:"table-edit__link",attrs:{"to":{ name: 'Edit Article', params: { id: item.articleId } }}},[_vm._v(_vm._s(_vm.articlesMap[item.articleId].title))]),_c('span',{staticClass:"table-edit__icon material-icons-outlined"},[_vm._v("edit")])],1):_vm._e()]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }