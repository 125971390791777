import { cloneDeep } from 'lodash';
import {
    Background,
    Container,
    Content,
    Heading,
    Section,
    SectionItemsStyle,
} from '@/entities/section/samples/_options';
import { BUTTON } from '@/helpers/fieldTypes.no-vuetify';

const section = {
    id: '',
    type: 'SectionHoverTextWithBgStyle1',
    required: false,
    group: 'TextBlocks',
    options: {
        section: { ...Section },
        container: { ...Container },
        heading: { ...Heading },
        content: {
            imageBorderRadius: { ...SectionItemsStyle.borderRadius },
        },
        columns: {
            type: 'field',
            value: {
                desktop: 3,
                tablet: 2,
                mobile: 1,
            },
            component: 'CustomizeColumnsTabs',
            ...{
                props: {
                    ranges: {
                        desktop: [3, 4],
                        tablet: [2, 3],
                        mobile: [1, 2],
                    },
                },
            },
        },
        background: { ...Background },
    },
    items: [],
    children: {
        type: 'hoverTextItem',
        options: {
            heading: {
                title: { ...Heading.title },
            },
            content: {
                html: { ...Content.html },
                image: {
                    ...Content.image,
                    props: {
                        note: 'JPG, PNG. Recomended size: 832х832px. No more than 100kb',
                    },
                },
                alt: { ...Content.imageAlt },
                opacity: { ...Content.opacity },
            },
            button: {
                ...BUTTON,
                value: {
                    isShow: false,
                    styles: 'text',
                    text: 'Button',
                    textColor: null,
                    bgColor: null,
                    link: null,
                    iconLeft: null,
                    iconRight: 'arrow_forward',
                    type: 'Link',
                    url: null,
                },
            },
        },
    },
};

const itemFixture = cloneDeep(section.children);
const itemsFixture = [];
itemsFixture.push(Object.assign(cloneDeep(itemFixture), { position: 0 }));
itemsFixture.push(Object.assign(cloneDeep(itemFixture), { position: 1 }));
itemsFixture.push(Object.assign(cloneDeep(itemFixture), { position: 2 }));
section.items = itemsFixture;

export default section;
