<script>
// inspired by a workaround for missing "optional wrapper" functionality in vue
// see: https://github.com/vuejs/rfcs/discussions/448#discussioncomment-5487435

export default {
    name: 'OptionalWrapper',

    render() {
        if (this.$slots && this.$slots.default) {
            return this.$slots.default;
        }
        return null;
    },
};
</script>

<style scoped></style>
