<template>
    <div class="search-form">
        <heading type="body-2" v-if="label">{{ label }}</heading>
        <Select
            v-if="autocomplete && !multiple"
            v-model="modelValue"
            :items="entries"
            :is-loading="isLoading"
            :search-string.sync="searchString"
            autocomplete
            has-search
            backend-search
            item-text="title"
            item-value="id"
            :placeholder="placeholderTrans"
            @change="$emit('change', $event)"
            v-on="$listeners"
        >
        </Select>
        <SelectMultiple
            v-else-if="autocomplete && multiple"
            v-model="modelValue"
            :items="entries"
            :is-loading="isLoading"
            has-search
            @update-search-string="searchString = $event"
            item-text="title"
            item-value="id"
            :return-object="false"
            :placeholder="placeholderTrans"
            v-on="$listeners"
        >
        </SelectMultiple>
        <div class="search" v-else>
            <input-text
                class="search__input"
                :placeholder="placeholderTrans"
                v-model="searchString"
                v-on="$listeners"
            ></input-text>
            <span v-show="!searchString" class="search__icon material-icons-outlined">search</span>
            <span v-show="searchString" @click="clearSearch" class="search__icon material-icons-outlined -close"
                >close</span
            >
        </div>
    </div>
</template>

<script>
import { debounce } from 'lodash';
import SearchService from '@/services/SearchService';
import Select from '@/components/form/controls/Select';
import InputText from '@/components/form/controls/InputText';
import Heading from '@/components/common/Heading';
import SelectMultiple from '@/components/form/controls/SelectMultiple';

export default {
    name: 'SearchForm',
    components: { SelectMultiple, InputText, Select, Heading },

    searchableTypes: {
        textpage: 'page',
        page: 'page',
        catalog: 'catalog',
        product: 'product',
        property: 'property',
    },

    props: {
        q: {
            type: String,
        },
        label: {
            type: String,
        },
        searchable: {
            type: String,
            default: '',
        },
        autocomplete: {
            type: Boolean,
            default: false,
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        fields: {
            type: Array,
            default: () => [],
        },
        value: {
            type: [Object, String, Array],
            default: () => ({}),
        },
        returnProp: {
            type: String,
        },
        placeholder: {
            type: String,
            default: 'search.main',
        },
        errorMessages: {
            type: [Array],
        },
        onlyModel: {
            type: Boolean,
            default: false,
        },
        name: {
            type: String,
        },
        group: {
            type: String,
        },
        pagination: {
            type: [Object, Boolean],
        },
        initiallySelected: {
            type: [Object, Array],
            default: null,
        },
    },

    data() {
        return {
            searchString: this.q,
            entries: [],
            isLoading: false,
        };
    },

    mounted() {
        if (this.initiallySelected) {
            this.entries = this.multiple ? [...this.initiallySelected] : [this.initiallySelected];
        }
    },

    computed: {
        isActive() {
            return this.searchString && this.searchString.length >= 3;
        },
        placeholderTrans() {
            return this.$t(this.placeholder);
        },
        modelValue: {
            get() {
                return this.value;
            },
            set(value) {
                let resultValue;
                if (this.multiple) {
                    resultValue = this.returnProp ? value.map((item) => item?.[this.returnProp]) : value;
                } else {
                    resultValue = this.returnProp ? value?.[this.returnProp] : value;
                }
                console.log(resultValue, this.value);

                this.$emit('input', resultValue);
            },
        },
    },

    methods: {
        async search() {
            this.isLoading = true;

            const params = {
                q: this.searchString,
                limit: this.pagination?.limit,
                offset: this.pagination?.offset,
                fields: this.fields,
            };
            const [error, result] = await SearchService.entitySearch(
                this.$options.searchableTypes[this.searchable],
                params
            );
            this.isLoading = false;
            if (error) {
                error.notify();
            }
            return result.data;
        },

        groupingSearchResults(searchResults) {
            return Object.entries(searchResults).reduce((acc, [key, value]) => {
                if (value.length) {
                    acc.push({
                        header: key,
                    });
                    acc.push(
                        ...value.map((item) => {
                            return {
                                text: item.title,
                                value: item.id,
                            };
                        })
                    );
                }
                return acc;
            }, []);
        },

        clearSearch() {
            this.searchString = '';
            this.$emit('clear-search');
        },
    },

    watch: {
        searchable() {
            this.searchString = '';
        },

        searchString: debounce(async function(newValue, oldValue) {
            if (newValue === oldValue) return;
            if (this.pagination) this.pagination.reset();
            if (this.autocomplete) {
                if (this.isActive) {
                    const searchResults = await this.search();
                    this.entries = searchResults;
                } else this.entries = [];
            } else if (this.isActive || !newValue) {
                if (!this.onlyModel) {
                    const searchResults = newValue ? await this.search() : [];
                    this.$emit('update-search-results', searchResults);
                }
                this.$emit('update-search-string', { searchString: newValue });
            }
        }, 500),
        modelValue: function(newValue) {
            this.$emit('update-field', {
                name: this.name,
                group: this.group,
                value: newValue,
            });
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.search-form {
    fieldset {
        color: var(--v-outline-base) !important;
    }
    .heading {
        margin-bottom: 8px;
    }
}

.search {
    position: relative;
    &__icon {
        position: absolute !important;
        right: 13px;
        top: calc(50% - 9px);
        color: var(--v-on-surface-medium-base) !important;
    }
}
.-close {
    cursor: pointer;
    &:hover {
        color: var(--v-primary-accent-base) !important;
    }
}
</style>
