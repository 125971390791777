<template>
    <div class="builder-save-btn">
        <Button v-if="isNewPage" @click="save" :loading="isLoading" icon="check">
            {{ $t('builder.global.btns.createPage') }}
        </Button>
        <Button v-else @click="save" :loading="isLoading" :disabled="isDisabled" icon="check">
            {{ $t('builder.global.btns.savePage') }}
        </Button>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import EventEmitter from '@/helpers/eventEmitter.ts';
import validateField from '@/helpers/validator';
import Button from '@/components/common/Button.vue';

export default {
    name: 'SaveBtn',
    components: { Button },
    data() {
        return {
            isLoading: false,
        };
    },

    computed: {
        ...mapState('builder', {
            currentPage: 'currentPage',
            isNewPage: 'isNewPage',
        }),
        hasUndo() {
            return this.$store.state.history.currentHistiryPosition > 0;
        },
        hasRedo() {
            return this.$store.state.history.currentHistiryPosition < this.$store.state.history.historyTree.length - 1;
        },
        isDisabled() {
            return !this.hasRedo && !this.hasUndo;
        },
    },

    methods: {
        ...mapActions('builder', {
            saveCurrent: 'saveCurrent',
            addHistoryPoint: 'addHistoryPoint',
            updateInitialSettings: 'updateInitialSettings',
        }),
        validateForm() {
            let result = true;
            if (!this.currentPage?.fields) return result;
            if (this.currentPage?.fields?.heading) delete this.currentPage.fields.heading;
            const fieldKeys = Object.keys(this.currentPage.fields);
            fieldKeys.forEach((key) => {
                const errors = validateField(this.currentPage[key], this.currentPage.fields[key].props.rules);
                if (errors.length !== 0) result = false;
                this.$set(this.currentPage.fields[key].props, 'errors', errors);
            });
            if (this.currentPage.sections)
                this.currentPage.sections.forEach((section) => {
                    if (section.hasErrors) result = false;
                    section.items.forEach((item) => {
                        if (item.hasErrors) result = false;
                    });
                });
            if (result) return result;
            else {
                EventEmitter.sendNotyError(this.$t('notifications.validation.error'));
                return false;
            }
        },
        failHandler(responce) {
            const pageResult = responce.data[1];
            const children = pageResult.data?.errors?.children;
            if (children) {
                const fieldKeys = Object.keys(this.currentPage.fields);
                fieldKeys.forEach((key) => {
                    const errors = children[key] ? children[key].errors : [];
                    if (errors) this.$set(this.currentPage.fields[key].props, 'errors', errors);
                });
            }
        },
        async save() {
            setTimeout(() => {
                const isFormValid = this.validateForm();
                if (isFormValid === false) return;
                this.isLoading = true;
                EventEmitter.trigger('progress-bar__in-progress');
                this.saveCurrent()
                    .then((result) => {
                        if (!result?.success) this.failHandler(result);
                        else this.updateInitialSettings();
                        if (this.isNewPage && result?.success) {
                            const { type, id } = this.currentPage;
                            this.$router.push(`/settings/customize/edit/${type}/${id}`);
                        } else EventEmitter.trigger('refresh-preview');
                        this.addHistoryPoint();
                    })
                    .catch((err) => {
                        console.error(err);
                    })
                    .finally(() => {
                        this.isLoading = false;
                        EventEmitter.trigger('progress-bar__done');
                    });
            }, 250);
        },
    },

    watch: {
        isDisabled(newValue) {
            if (newValue) this.isLoading = false;
        },
    },
};
</script>

<style lang="scss">
.builder-save-btn {
    display: inline-block;
}
</style>
