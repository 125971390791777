var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Table',{staticClass:"file-manager-table",attrs:{"headers":_vm.columns,"items":_vm.items},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"file-manager-table__name",class:{ 'is-active': item.selected },on:{"click":function($event){return _vm.onSelectItem(item)}}},[_c('span',{staticClass:"material-icons-outlined"},[_vm._v(_vm._s(item.children ? 'mdi-folder' : 'mdi-file'))]),_vm._v(" "+_vm._s(item.children ? item.name : item.name + '.' + item.extension)+" ")])]}},{key:"item.size",fn:function(ref){
var item = ref.item;
return [(item.size)?_c('span',[_vm._v(_vm._s(_vm._f("size")(item.size)))]):_vm._e()]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [(item.createdAt)?_c('span',[_vm._v(_vm._s(_vm._f("date")(item.createdAt)))]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(!item.children)?_c('div'):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }