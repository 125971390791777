import { uuid } from 'vue-uuid';
import Image from '@/entities/image/Image';
import initFields from '@/entities/image/EntityImage.fields';
/**
 * Класс экземпляра картинки сущности
 */
export default class EntityImage {
    fields = initFields();

    /**
     *
     * @param {Object}  data - объект данных картинки
     */
    constructor(
        data = {
            id: uuid.v4(),
            imageId: '',
            position: '',
            file: {},
        }
    ) {
        this.id = data.id;
        this.imageId = data.imageId;
        this.position = data.position;
        this.file = new Image(data.file);
    }

    get src() {
        const baseUrl = process.env.VUE_APP_IMAGE_BASE_URL || '';
        return `${baseUrl}${this.file.uri}`;
    }
}
