// ПЕРЕХОДНЫЙ ФАЙЛ, потом заменит оригинал
/**
 * Типы полей форм
 */
import icons from '@/entities/icons';
export const STRING = {
    type: 'STRING',
    component: 'InputText',
};

export const BIG_STRING = {
    type: 'BIG_STRING',
    component: 'Textarea',
    props: {},
};

export const NUMBER = {
    type: 'NUMBER',
    component: 'input-text',
    props: {
        type: 'number',
    },
};

export const INPUT_SWITCH = {
    type: 'CHECKBOX',
    component: 'InputSwitch',
};

export const CHECKBOX = {
    type: 'CHECKBOX',
    component: 'input-checkbox',
};

export const RADIO = {
    type: 'RADIO',
    component: 'input-radio',
};

export const SELECT = {
    type: 'SELECT',
    component: 'Select',
};

export const SELECT_SCREEN = {
    type: 'SELECT',
    component: 'SelectScreenTabs',
};

export const DATE = {
    type: 'DATE',
    component: 'DatePicker',
};

export const AUTOCOMPLETE = {
    type: 'AUTOCOMPLETE',
    component: 'autocomplete',
};

export const SEARCH = {
    type: 'SEARCH',
    component: 'search-form',
};

export const HTML = {
    type: 'HTML',
    component: 'CodeEditor',
};

export const HTML_SCREEN = {
    type: 'HTML',
    component: 'CodeEditorScreenTabs',
};

export const COLOR = {
    type: 'COLOR',
    component: 'input-checkbox',
};

export const COMBOBOX = {
    type: 'COMBOBOX',
    component: 'combobox',
};

export const IMAGE = {
    type: 'IMAGE',
    component: 'image-field',
    props: {
        type: 'content',
        note: 'JPG, PNG. Recomended size: 320х432px. No more than 500kb',
    },
};

export const IMAGE_BUILDER = {
    type: 'IMAGE',
    component: 'InputImageBuilder',
    props: {
        type: 'content',
        btnReset: true,
        note: 'JPG, PNG. Recomended size: 320х432px. No more than 500kb',
    },
};

export const IMAGE_SCREEN_BUILDER = {
    type: 'IMAGE',
    component: 'InputImageScreenBuilder',
    props: {
        type: 'content',
        btnReset: true,
        note: 'JPG, PNG. Recomended size: 320х432px. No more than 500kb',
    },
};

export const ICON = {
    type: 'ICON',
    component: 'InputIcon',
    props: {
        items: [...icons],
    },
};

export const BUTTON = {
    type: 'Button',
    component: 'ButtonBuilder',
    props: {
        icons: [...icons],
        types: [
            { text: 'link', value: 'link' },
            { text: 'popup', value: 'popup' },
        ],
        labelTrans: 'builder.sectionOptions.props.button',
    },
};

export const SECTION_LAYOUT = {
    type: 'SECTION_LAYOUT',
    component: 'InputSectionLayout',
    props: {
        labelTrans: 'builder.sectionOptions.props.sectionLayout',
    },
};

export const CATALOGS = {
    type: 'CATALOGS',
    // component: 'ui-select-catalogs',
    component: 'SelectCatalog',
};
export const CATEGORIES = {
    type: 'CATALOGS',
    component: 'SelectCategory',
};

export const CATEGORY = {
    type: 'CATEGORY',
    component: 'category-switcher',
};

export const SWITCH = {
    type: 'SWITCH',
    component: 'input-switch',
};
export const LINK = {
    type: 'LINK',
    component: 'InputLink',
};
export const URL = {
    type: 'URL',
    component: 'input-url',
};

export const PAGE_STATUS = {
    type: 'PAGE_STATUS',
    component: 'page-status',
};

export const RATING = {
    type: 'RATING',
    component: 'rating',
};

export const PRODUCT = {
    type: 'PRODUCT',
    component: 'SelectProduct',
};

export const ADDRESS = {
    type: 'ADDRESS',
    component: 'InputAddress',
};

export default {
    STRING,
    BIG_STRING,
    NUMBER,
    CHECKBOX,
    SELECT,
    DATE,
    AUTOCOMPLETE,
    HTML,
    COLOR,
    IMAGE,
    ICON,
    SWITCH,
    CATALOGS,
    PAGE_STATUS,
    CATEGORY,
    COMBOBOX,
    URL,
    RATING,
    PRODUCT,
    ADDRESS,
};
