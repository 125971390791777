import apiClient from '@/api/apiClient';
import { Page, Product, Catalog } from '@/services/services.types';
import CustomError from '@/entities/customError';

/**
 * API методы для создания slug
 */

const API_METHODS = {
    product: (title) => {
        const url: string = `/products/slugify/${title}`;
        const defaultValue = {};
        const errorPath: string = '[api:slug:createOne:product]';
        return apiClient.admin.get({ url, defaultValue, errorPath });
    },
    catalog: (title) => {
        const url: string = `/catalogs/slugify/${title}`;
        const defaultValue = {};
        const errorPath: string = '[api:slug:createOne:catalog]';
        return apiClient.admin.get({ url, defaultValue, errorPath });
    },
    textpage: (title) => {
        const url: string = `/pages/slugify/${title}`;
        const defaultValue = {};
        const errorPath: string = '[api:slug:createOne:page]';
        return apiClient.admin.get({ url, defaultValue, errorPath });
    },
    category: (title) => {
        const url: string = `/categories/slugify/${title}`;
        const defaultValue = {};
        const errorPath: string = '[api:slug:createOne:category]';
        return apiClient.admin.get({ url, defaultValue, errorPath });
    },
    article: (title) => {
        const url: string = `/articles/slugify/${title}`;
        const defaultValue = {};
        const errorPath: string = '[api:slug:createOne:article]';
        return apiClient.admin.get({ url, defaultValue, errorPath });
    },
};

class SlugService {
    /**
     * Создание slug на основе title
     * @param {string} text - строка на основе которой создается slug
     * @param {Object} resource - объект ресурса для которого создается slug, например страница, товар, каталог и тп
     * @returns {Promise} - slug
     */
    async changeSlugFromTitle(
        text: string,
        resource: Page | Catalog | Product
    ): Promise<[CustomError | null, string | null]> {
        if (resource.slugDisabled) return [null, null];
        return await this.createOne(text, resource.type);
    }

    /**
     * Создание slug на основе slug
     * @param {string} text - строка на основе которой создается slug
     * @param {Object} resource - объект ресурса для которого создается slug, например страница, товар, каталог и тп
     * @returns {Promise} - slug
     */
    async changeSlugFromSlug(
        text: string,
        resource: Page | Catalog | Product
    ): Promise<[CustomError | null, string | null]> {
        return await this.createOne(text, resource.type);
    }

    /**
     * Создание slug
     * @param {string} text - строка на основе которой будет создан slug
     * @param {string} resourceType - тип ресурса для которого создается slug(product, catalog, textpage)
     * @returns {Promise} slug
     */
    async createOne(text: string, resourceType: string): Promise<[CustomError | null, string | null]> {
        return await API_METHODS[resourceType](text);
    }
}

export default new SlugService();
