import icons from '@/entities/icons';
export default {
    template: {
        id: null,
        position: 1,
        type: 'select',
        component: 'Select',
        value: 'Header 2',
        options: {
            variants: [
                'Header 1',
                'Header 2',
                'Header 2 Compact',
                'Header 3',
                'Header 4',
                'Header 5',
                'Header 6',
                'Header 7',
            ],
            items: [
                'Header 1',
                'Header 2',
                'Header 2 Compact',
                'Header 3',
                'Header 4',
                'Header 5',
                'Header 6',
                'Header 7',
            ],
            labelTrans: 'settings.header.template',
        },
    },
    logo: {
        id: null,
        position: 3,
        type: 'image',
        component: 'InputImageBuilder',
        value: '2773fb07-c76a-4435-9a7b-e0040425c2c0',
        options: {
            labelTrans: 'settings.header.logo',
            note: 'JPG, PNG. Recomended size: 166х86px. No more than 10kb',
        },
    },
    logoMobile: {
        id: null,
        position: 4,
        type: 'image',
        component: 'InputImageBuilder',
        value: '',
        options: {
            labelTrans: 'settings.header.logoMobile',
            note: 'JPG, PNG. Recomended size: 166х44px. No more than 10kb',
        },
    },
    logoAlt: {
        id: null,
        position: 5,
        type: 'string',
        component: 'InputText',
        value: 'Best Company Ever',
        options: {
            labelTrans: 'settings.header.logoAlt',
        },
    },

    menu: {
        id: null,
        position: 10,
        type: 'menu',
        component: 'MenuEditor',
        value: 'main',
        options: {
            depth: 3,
            labelTrans: 'settings.header.menu',
        },
    },
    menuOptions: {
        id: null,
        position: 11,
        type: 'object',
        component: 'CompositeField',
        value: {
            size: 15,
            uppercase: false,
        },
        options: {
            labelTrans: 'settings.header.menuOptions',
            fields: [
                {
                    name: 'size',
                    type: 'number',
                    component: 'InputNumberAndRange',
                    value: 15,
                    props: {
                        min: 12,
                        max: 18,
                        labelTrans: 'settings.header.menuOptionsTextSize',
                    },
                },
                {
                    name: 'uppercase',
                    type: 'boolean',
                    component: 'InputSwitch',
                    value: false,
                    props: {
                        noLabel: true,
                        size: 'small',
                        text: 'settings.header.menuOptionsTextUppercase',
                    },
                },
                {
                    name: 'color',
                    value: '',
                    component: 'ColorPicker',
                    props: {
                        labelTrans: 'settings.header.menuOptionsTextColor',
                        btnReset: true,
                        mode: 'hexa',
                    },
                },
            ],
        },
    },

    menuSubOptions: {
        id: null,
        position: 12,
        type: 'object',
        component: 'CompositeField',
        value: {
            size: 15,
            uppercase: false,
        },
        options: {
            labelTrans: 'settings.header.menuSubOptions',
            fields: [
                {
                    name: 'size',
                    type: 'number',
                    component: 'InputNumberAndRange',
                    value: 15,
                    props: {
                        min: 12,
                        max: 18,
                        labelTrans: 'settings.header.menuOptionsTextSize',
                    },
                },
                {
                    name: 'uppercase',
                    type: 'boolean',
                    component: 'InputSwitch',
                    value: true,
                    props: {
                        noLabel: true,
                        size: 'small',
                        text: 'settings.header.menuOptionsTextUppercase',
                    },
                },
                {
                    name: 'color',
                    value: '',
                    component: 'ColorPicker',
                    props: {
                        labelTrans: 'settings.header.menuOptionsTextColor',
                        btnReset: true,
                        mode: 'hexa',
                    },
                },
            ],
        },
    },
    menuInfo: {
        id: null,
        position: 20,
        type: 'menu',
        component: 'MenuEditor',
        value: 'info',
        options: {
            depth: 2,
            labelTrans: 'settings.header.menuInfo',
        },
    },
    menuInfoOptions: {
        id: null,
        position: 21,
        type: 'object',
        component: 'CompositeField',
        value: {
            size: 14,
            uppercase: false,
        },
        options: {
            labelTrans: 'settings.header.menuInfoOptions',
            fields: [
                {
                    name: 'size',
                    type: 'number',
                    component: 'InputNumberAndRange',
                    value: 14,
                    props: {
                        min: 12,
                        max: 18,
                        labelTrans: 'settings.header.menuOptionsTextSize',
                    },
                },
                {
                    name: 'uppercase',
                    type: 'boolean',
                    component: 'InputSwitch',
                    value: false,
                    props: {
                        noLabel: true,
                        size: 'small',
                        text: 'settings.header.menuOptionsTextUppercase',
                    },
                },
                {
                    name: 'color',
                    value: '',
                    component: 'ColorPicker',
                    props: {
                        labelTrans: 'settings.header.menuOptionsTextColor',
                        btnReset: true,
                        mode: 'hexa',
                    },
                },
            ],
        },
    },

    contactsSize: {
        id: null,
        position: 30,
        type: 'select',
        component: 'Select',
        value: 'Default',
        options: {
            variants: ['Default', 'Compact'],
            items: ['Default', 'Compact'],
            labelTrans: 'settings.header.contactsSize',
        },
    },
    contactsStyles: {
        id: null,
        position: 31,
        type: 'object',
        component: 'CompositeField',
        value: {
            size: 14,
        },
        options: {
            labelTrans: 'settings.header.contactsStyles',
            fields: [
                {
                    name: 'size',
                    type: 'number',
                    component: 'InputNumberAndRange',
                    value: 14,
                    props: {
                        min: 12,
                        max: 18,
                        labelTrans: 'settings.header.contactsTextSize',
                    },
                },
            ],
        },
    },

    showSearchMobile: {
        id: null,
        position: 40,
        type: 'boolean',
        component: 'InputCheckbox',
        value: false,
        options: {
            noLabel: true,
            text: 'settings.header.showSearchMobile',
        },
    },

    sendEnquiry: {
        id: null,
        position: 50,
        type: 'Button',
        component: 'ButtonBuilder',
        value: { isShow: false, type: 'popup' },
        options: {
            icons: [...icons],
            types: [
                { text: 'link', value: 'link' },
                { text: 'popup', value: 'popup' },
            ],
            labelTrans: 'settings.header.sendEnquiry',
        },
    },
};
