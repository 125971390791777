export default {
    enabled: {
        id: null,
        type: 'boolean',
        value: false,
        options: {},
        position: 0,
    },
    chatId: {
        id: null,
        type: 'string',
        value: '',
        options: {},
        position: 1,
    },
    token: {
        id: null,
        type: 'string',
        value: '',
        options: {},
        position: 2,
    },
    isUseTemplate: {
        id: null,
        type: 'boolean',
        value: false,
        options: {},
        position: 3,
    },
    template: {
        id: null,
        type: 'string',
        value: `**Order:** {order_number} from {domain}
**Date:** {Date}

**Email:** {email}
**Name:** {First Name} {Second Name}
**Phone:** {Phone}
**Country:** {Country}
**Region:** {Region}
**City:** {City}
**Address:** {Address1}, {Address2}
**Postcode:** {Postcode}
        
**Order List:**

{Order_List}
        
**Total:** {Total}
**Delivery:** {Delivery}`,
        options: {},
        position: 4,
    },
};
