<template>
    <div class="payment-countries-input">
        <div class="payment-countries-input__countries">
            <div class="countries__flag address-card__img-container">
                <img v-if="flag" class="address-card__img" :src="flag" />
                <img v-else class="address-card__img -default" src="@/assets/images/country-default.svg" />
            </div>
            <div class="countries__list">
                <div v-if="!allowedLocations.length">
                    <span>
                        {{ $t('entities.payment.countries.choose') }}
                    </span>
                </div>
                <div v-else-if="allowedLocations.length < 1319">
                    <span>
                        {{ countriesList }}
                    </span>
                </div>
                <div v-else>{{ $t('entities.payment.countries.worldwide') }}</div>
            </div>
        </div>
        <Button @click="$emit('open-modal', systemName)">{{ $t('entities.edit') }}</Button>
    </div>
</template>

<script>
import Button from '@/components/common/Button';
export default {
    name: 'PaymentCountriesInput',
    components: { Button },
    props: {
        allowedLocations: {
            type: Array,
            default: () => [],
        },
        systemName: {
            type: String,
            default: '',
        },
    },
    computed: {
        flag() {
            if (this.allowedLocations.length) {
                const country = this.allowedLocations[0].slice(0, 2);
                if (
                    this.allowedLocations.length === 1 ||
                    this.allowedLocations.every((item) => item.startsWith(country))
                ) {
                    return require(`@/assets/images/flags/${country.toLowerCase()}.svg`);
                }
            }
            return null;
        },
        countriesList() {
            const countries = this.allowedLocations.reduce((acc, item) => {
                const country = item.slice(0, 2);
                if (!acc.includes(country)) {
                    acc.push(country);
                }
                return acc;
            }, []);
            const countriesString = countries
                .slice(0, 4)
                .map((item) => (item === 'RU' ? this.$t(`countries.${item}.title`) : this.$t(`countries.${item}`)))
                .join(', ');
            if (countries.length <= 4) {
                return countriesString;
            }
            return `${countriesString} ${this.$t('entities.payment.countries.andOther', { count: countries.length })}`;
        },
    },
    methods: {
        requireFlag(code) {
            this.flag = require(`@/assets/images/flags/${code.toLowerCase()}.svg`);
        },
    },
};
</script>

<style lang="scss">
.payment-countries-input {
    &__countries {
        display: flex;
        gap: 12px;
        align-items: center;
        margin-right: auto;
    }
    display: flex;
    gap: 16px;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
    .btn {
        margin-right: auto;
        min-width: 100px;
    }
}
</style>
