<template>
    <div class="product-image-manager">
        <product-image-properties-modal
            @update-image-props="updateImageProps"
            :image="editingImage"
            v-model="isImagePropsModalOpen"
        />
        <div class="product-image-manager__content">
            <draggable
                v-if="structure.length"
                @start="drag = true"
                @end="dragEnd"
                :disabled="!draggable"
                class="product-image-manager__items-container"
                ref="images"
                v-model="structure"
                draggable=".image__drag-item"
            >
                <Img
                    :controls="controls"
                    :draggable="draggable"
                    :image="image"
                    :src="getSrc(image.id)"
                    v-for="image in structure"
                    :key="image.id"
                    :data-id="image.id"
                    @edit-image="openImagePropsModal"
                    @delete-image="openDeletionModal(image)"
                    @select-image="selectImage"
                    :selected="value && image.id === value.id && selectable"
                ></Img>
            </draggable>
            <div class="product-image-manager__dropzone -multi">
                <multi-dropzone
                    :images="newImages"
                    @change="addImage"
                    @delete-image="deleteNewImage"
                    class="image__image"
                    :class="{ '-open': newImages.length }"
                    :loading="loading"
                >
                    <template v-slot:text>
                        {{ $t('entities.image.formats') }}, <br />
                        {{ $t('entities.image.resolution') }}, <br />
                        {{ $t('entities.image.size') }}
                    </template>
                </multi-dropzone>
            </div>
        </div>
        <Button v-if="saveAllowed" @click="$emit('update-images')" class="product-image-manager__view-more">{{
            $t('entities.image.updateCollection')
        }}</Button>
    </div>
</template>

<script>
import draggable from 'vuedraggable';
import ImageService from '@/services/ImageService';
import Image from '@/entities/image/Image';
import { proxyModelMixin } from '@/mixins/proxyModelMixin';
import { imageManagerMixin } from '@/mixins/imageManagerMixin';
import Img from '@/components/common/Img';
import MultiDropzone from '@/components/form/controls/MultiDropzone';
import ProductImagePropertiesModal from '@/components/product/ProductImagePropertiesModal';
import EntityImage from '@/entities/image/EntityImage';
import Button from '@/components/common/Button';
import EventEmitter from '@/helpers/eventEmitter';

export default {
    name: 'ProductImageManager',
    components: { Img, Button, draggable, MultiDropzone, ProductImagePropertiesModal },

    mixins: [proxyModelMixin, imageManagerMixin],

    props: {
        value: {
            type: Object,
            default: () => new Image(),
        },
        imageType: {
            type: String,
            default: 'product',
        },
        data: {
            type: Array,
            default: () => [],
        },
        draggable: {
            type: Boolean,
            default: true,
        },
        controls: {
            type: Boolean,
            default: true,
        },
        selectable: {
            type: Boolean,
            default: false,
        },
        newImages: {
            type: Array,
            default: () => [],
        },
        ownerId: {
            type: String,
            default: null,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            images: {},
            structure: [],
            drag: false,
        };
    },

    created() {
        EventEmitter.on('delete-image', this.deleteImage);
    },

    computed: {
        productId() {
            return this.$route.params.id;
        },
        order() {
            //новый итем добавляется в начало массива
            //пока что нумеруем от большего к меньшему здесь, потом добавить нормальную сортировку?
            return this.structure.map((image, index) => {
                return new EntityImage({ ...image, position: index });
            });
        },
        saveAllowed() {
            return this.productId && this.newImages.length;
        },
    },

    methods: {
        openDeletionModal(items) {
            this.$store.dispatch('modals/openDeletionModal', { items, entity: 'image' });
        },
        async getImages() {
            if (!this.data.length) return;
            this.isLoading = true;
            const imagesMap = {};
            this.data.forEach((image) => {
                imagesMap[image.id] = new EntityImage(image);
            });
            this.images = imagesMap;
            this.isLoading = false;
        },

        deleteNewImage(image) {
            this.$emit('delete-new-image', image);
        },

        getSrc(id) {
            return this.images?.[id]?.src;
        },

        dragEnd() {
            this.drag = false;
            this.$emit('change', this.order);
        },
        async addImage(files) {
            if (this.offset) {
                this.offset += 1;
            }
            this.$emit('add-image', files);
        },

        deleteImage([image]) {
            const index = this.structure.findIndex((item) => item.imageId === image.imageId);
            this.structure.splice(index, 1);
            this.$emit('delete-image', image);
            this.$store.dispatch('modals/closeDeletionModal');
            EventEmitter.trigger('show-noty', {
                type: 'success',
                text: this.$tc('notifications.deleted', 1, { entity: this.$tc('entities.image.title', 1) }),
            });
        },

        async updateImageProps(image) {
            const [error, result] = await ImageService.updateImageProps(image.id, image);
            if (error) {
                error.notify();
                return;
            }
            const index = this.structure.findIndex((item) => item.file.id === image.id);
            const newImage = new EntityImage({ ...this.structure[index], file: new Image(result) });
            this.structure.splice(index, 1, newImage);
            this.isImagePropsModalOpen = false;
        },
    },
    watch: {
        data: {
            handler(val, prevVal) {
                if (!prevVal || val.length !== prevVal.length) {
                    this.getImages();
                }
                this.structure = val.map((item) => new EntityImage(item)).sort((a, b) => a.position - b.position);
            },
            immediate: true,
        },
    },
    beforeDestroy() {
        EventEmitter.off('delete-image', this.deleteImage);
    },
};
</script>

<style lang="scss">
.product-image-manager__dropzone {
    width: 100%;
}
.-multi {
    .image__image {
        &.-open {
            width: 100% !important;
            height: auto !important;
        }
    }
}
</style>
