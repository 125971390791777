import { uuid } from 'vue-uuid';
import initFields from '@/entities/product/ProductLabel.fields';

/**
 * Класс экземпляра лейбла товаров
 */
export default class ProductLabel {
    fields = initFields();
    /**
     *
     * @param {Object}  data - объект данных опции
     */
    constructor({ id = uuid.v4(), title = '', color = '', position = 0 } = {}) {
        this.id = id;
        this.title = title;
        this.color = color;
        this.position = position;
    }

    get data() {
        return {
            id: this.id,
            title: this.title,
            color: this.color,
            position: this.position,
        };
    }
}
