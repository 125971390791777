import { SectionFixtureType } from '@/entities/section/fixtures';
import { cloneDeep } from 'lodash';
import { createLayoutVariation } from '@/entities/section/fixtures/_base';

const itemStyleVariations = {
    style1: 'style1',
    style2: 'style2',
    style3: 'style3',
    style5: 'style5',
    style6: 'style6',
    multicolor: 'multicolor',
};

const itemsWithIcon = [
    {
        title: 'Experienced Team',
        html:
            '<p>Our company boasts a highly skilled and experienced team of professionals who are dedicated to delivering exceptional results.</p>',
        icon: {
            type: 'icon',
            data: 'group',
        },
        background: null,
    },
    {
        title: 'Customer Satisfaction',
        html:
            '<p>Our goal is to build long-term relationships based on trust, reliability, and mutual success. Our top priority is customer satisfaction. </p>',
        icon: {
            type: 'icon',
            data: 'verified',
        },
        background: null,
    },
    {
        title: 'Timely Delivery',
        html:
            '<p>We can guarantee you timely and fast delivery, we have the best couriers. We understand the importance of meeting deadlines.</p>',
        icon: {
            type: 'icon',
            data: 'local_shipping',
        },
        background: null,
    },
    {
        title: 'Cutting-edge Technology',
        html:
            '<p>We stay up-to-date with the latest advancements in technology, allowing us to utilize the most innovative tools and solutions. </p>',
        icon: {
            type: 'icon',
            data: 'forum',
        },
        background: null,
    },
    {
        title: 'Collaborative Approach',
        html:
            '<p>We believe in working closely with our clients throughout the process. By understanding their goals and requirements, we can tailor our solutions to meet their specific needs.</p>',
        icon: {
            type: 'icon',
            data: 'thumb_up',
        },
        background: null,
    },
    {
        title: 'Attention to Detail',
        html:
            '<p>From the initial planning stages to the final implementation, we meticulously analyze every aspect of our projects to ensure accuracy, precision, and excellence.</p>',
        icon: {
            type: 'icon',
            data: 'auto_awesome',
        },
        background: null,
    },
];

const itemsBackground = [
    {
        title: 'Experienced Team',
        html:
            '<p>Our company boasts a highly skilled and experienced team of professionals who are dedicated to delivering exceptional results.</p>',
        icon: null,
        background: {
            color: '#5E47CA',
            image: '2d78929f-5a08-4d13-bbbd-8fe8cf8c93b7',
        },
    },
    {
        title: 'Customer Satisfaction',
        html:
            '<p>Our goal is to build long-term relationships based on trust, reliability, and mutual success. Our top priority is customer satisfaction. </p>',
        icon: null,
        background: {
            color: '#FD9463',
            image: 'e5b2083d-4a8d-4e1a-9c69-8d8fc31f8573',
        },
    },
    {
        title: 'Timely Delivery',
        html:
            '<p>We can guarantee you timely and fast delivery, we have the best couriers. We understand the importance of meeting deadlines.</p>',
        icon: null,
        background: {
            color: '#EBB430',
            image: 'f82b5cb0-78f5-4238-bf37-87d9cc4c1bb6',
        },
    },
    {
        title: 'Cutting-edge Technology',
        html:
            '<p>We stay up-to-date with the latest advancements in technology, allowing us to utilize the most innovative tools and solutions. </p>',
        icon: null,
        background: {
            color: '#35A6E5',
            image: 'a5e9b0c6-d2e2-491a-82d0-7de1f5dd2baf',
        },
    },
    {
        title: 'Collaborative Approach',
        html:
            '<p>We believe in working closely with our clients throughout the process. By understanding their goals and requirements, we can tailor our solutions to meet their specific needs.</p>',
        icon: null,
        background: {
            color: '#2ABC7E',
            image: '6c8c9a6b-82df-42ec-9e3c-4f9d3b66ee0d',
        },
    },
    {
        title: 'Experienced Team',
        html:
            '<p>Our company boasts a highly skilled and experienced team of professionals who are dedicated to delivering exceptional results.</p>',
        icon: null,
        background: {
            color: '#986BE2',
            image: 'b4090c7a-cc99-4c81-a3cb-ff6bf76f3d4f',
        },
    },
    {
        title: 'Customer Satisfaction',
        html:
            '<p>Our goal is to build long-term relationships based on trust, reliability, and mutual success. Our top priority is customer satisfaction. </p>',
        icon: null,
        background: {
            color: '#AE6ABD',
            image: '7f583f63-94d7-4e92-8a5d-8e13fa2b9ef8',
        },
    },
];

const createItem = (args: {
    template: string;
    position: number;
    title: string;
    html: string;
    icon: { type: string; data: string } | null;
    background: { color: string; image: string } | null;
}) => {
    return {
        id: 'fixture',
        type: args.template,
        options: {
            heading: {
                title: args.title,
                color: args.template === 'multicolor' ? '#ffffff' : '',
                textSize: 20,
            },
            content: {
                html: args.html,
                color: args.template === 'multicolor' ? '#ffffff' : '',
                icon: args.icon,
                verticalAlign: 'flex-end',
                button: {
                    isShow: false,
                    styles: 'primary',
                    text: 'Button',
                    textColor: null,
                    bgColor: null,
                    link: null,
                    iconLeft: null,
                    iconRight: null,
                    type: 'Link',
                    url: null,
                },
            },
            background: args.background,
        },
        position: args.position,
    };
};

const createSectionFixture = (args: {
    preview: string;
    title: string;
    itemLayout: any;
    itemsTemplate: string;
    items: any[];
    additionalContent: boolean;
}): SectionFixtureType => {
    const additionalContent = {
        htmlBefore:
            '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>',
        htmlAfter:
            '<p>Risus viverra adipiscing at in tellus integer. Morbi tincidunt augue interdum velit euismod in pellentesque massa. Sed euismod nisi porta lorem. Diam maecenas ultricies mi eget. Lectus quam id leo in vitae turpis massa sed elementum. Ut venenatis tellus in metus vulputate eu scelerisque felis imperdiet. Vitae auctor eu augue ut lectus arcu bibendum. Amet massa vitae tortor condimentum lacinia quis vel eros.</p>',
        button: {
            isShow: true,
            styles: 'primary',
            text: 'Send Enquiry',
            textColor: null,
            bgColor: null,
            link: null,
            iconLeft: null,
            iconRight: null,
            type: 'popup',
        },
    };
    const defaultContent = {
        htmlBefore: null,
        htmlAfter: null,
        button: {
            isShow: false,
            styles: 'primary',
            text: null,
            textColor: null,
            bgColor: null,
            link: null,
            iconLeft: null,
            iconRight: null,
            type: 'link',
        },
    };
    return {
        preview: args.preview,
        title: args.title,
        group: 'Benefits',
        data: {
            id: 'fixture',
            items: args.items,
            required: false,
            fixed: false,
            type: 'SectionBenefitBase',
            options: {
                section: {
                    width: 'full',
                    padding: {
                        desktop: { top: 28, right: 0, bottom: 28, left: 0 },
                        tablet: { top: 28, right: 0, bottom: 28, left: 0 },
                        mobile: { top: 16, right: 0, bottom: 16, left: 0 },
                    },
                    borderRadius: {
                        desktop: 0,
                        tablet: 0,
                        mobile: 0,
                    },
                    background: 'none',
                },
                container: {
                    width: 'content',
                    padding: {
                        desktop: null,
                        tablet: null,
                        mobile: null,
                    },
                    borderRadius: {
                        desktop: 0,
                        tablet: 0,
                        mobile: 0,
                    },
                    background: 'none',
                },
                heading: {
                    title: 'Advantages of our company',
                    colorTitle: null,
                    note: null,
                    colorNote: null,
                    textAlign: 'center',
                    marginBottom: {
                        desktop: null,
                        tablet: null,
                        mobile: null,
                    },
                },
                content: {
                    htmlBefore: args.additionalContent ? additionalContent.htmlBefore : defaultContent.htmlBefore,
                    htmlAfter: args.additionalContent ? additionalContent.htmlAfter : defaultContent.htmlAfter,
                },
                itemsLayout: args.itemLayout,
                itemsStyle: {
                    template: args.itemsTemplate,
                    gap: 20,
                    borderRadius: 20,
                    minHeight: args.itemsTemplate === 'multicolor' ? 260 : 200,
                },
                footer: {
                    note: null,
                    colorNote: null,
                    textAlign: 'center',
                    marginTop: {
                        desktop: null,
                        tablet: null,
                        mobile: null,
                    },
                    button: args.additionalContent ? additionalContent.button : defaultContent.button,
                },
            },
        },
    };
};

const summary: any = {};

summary.style1__list_column_3__icon = createSectionFixture({
    title: 'Benefits list with 3 columns, item style 1',
    preview: require('@/assets/images/sections/benefits/style1__list_column_3__icon.png'),
    itemLayout: {
        desktop: createLayoutVariation({ type: 'list', columns: 3 }),
        tablet: createLayoutVariation({ type: 'list', columns: 2 }),
        mobile: createLayoutVariation({ type: 'list', columns: 1 }),
    },
    itemsTemplate: itemStyleVariations.style1,
    items: (() => {
        const items: any[] = [];
        for (let i = 0; i < 3; i++) {
            items.push(
                createItem({
                    template: itemStyleVariations.style1,
                    position: i,
                    ...itemsWithIcon[i],
                })
            );
        }
        return items;
    })(),
    additionalContent: false,
});
summary.style1__list_column_3__text_icon = createSectionFixture({
    title: 'Benefits list with 3 columns, item style 1 with text icon',
    preview: require('@/assets/images/sections/benefits/style1__list_column_3__text_icon.png'),
    itemLayout: {
        desktop: createLayoutVariation({ type: 'list', columns: 3 }),
        tablet: createLayoutVariation({ type: 'list', columns: 2 }),
        mobile: createLayoutVariation({ type: 'list', columns: 1 }),
    },
    itemsTemplate: itemStyleVariations.style1,
    items: (() => {
        const items: any[] = [];
        for (let i = 0; i < 3; i++) {
            items.push(
                createItem({
                    template: itemStyleVariations.style1,
                    position: i,
                    ...Object.assign(cloneDeep(itemsWithIcon[i]), {
                        icon: {
                            type: 'text',
                            data: '0' + (i + 1),
                        },
                    }),
                })
            );
        }
        return items;
    })(),
    additionalContent: false,
});
summary.style1__list_column_3__icon__text__button = createSectionFixture({
    title: 'Benefits list with 3 columns, item style 1, with addition content',
    preview: require('@/assets/images/sections/benefits/style1__list_column_3__icon__text__button.png'),
    itemLayout: {
        desktop: createLayoutVariation({ type: 'list', columns: 3 }),
        tablet: createLayoutVariation({ type: 'list', columns: 2 }),
        mobile: createLayoutVariation({ type: 'list', columns: 1 }),
    },
    itemsTemplate: itemStyleVariations.style1,
    items: (() => {
        const items: any[] = [];
        for (let i = 0; i < 3; i++) {
            items.push(
                createItem({
                    template: itemStyleVariations.style1,
                    position: i,
                    ...itemsWithIcon[i],
                })
            );
        }
        return items;
    })(),
    additionalContent: true,
});

summary.style2__list_column_3__icon = createSectionFixture({
    title: 'Benefits list with 3 columns, item style 2',
    preview: require('@/assets/images/sections/benefits/style2__list_column_3__icon.png'),
    itemLayout: {
        desktop: createLayoutVariation({ type: 'list', columns: 3 }),
        tablet: createLayoutVariation({ type: 'list', columns: 2 }),
        mobile: createLayoutVariation({ type: 'list', columns: 1 }),
    },
    itemsTemplate: itemStyleVariations.style2,
    items: (() => {
        const items: any[] = [];
        for (let i = 0; i < 3; i++) {
            items.push(
                createItem({
                    template: itemStyleVariations.style2,
                    position: i,
                    ...itemsWithIcon[i],
                })
            );
        }
        return items;
    })(),
    additionalContent: false,
});
summary.style2__list_column_3__text_icon = createSectionFixture({
    title: 'Benefits list with 3 columns, item style 2 with text icon',
    preview: require('@/assets/images/sections/benefits/style2__list_column_3__text_icon.png'),
    itemLayout: {
        desktop: createLayoutVariation({ type: 'list', columns: 3 }),
        tablet: createLayoutVariation({ type: 'list', columns: 2 }),
        mobile: createLayoutVariation({ type: 'list', columns: 1 }),
    },
    itemsTemplate: itemStyleVariations.style2,
    items: (() => {
        const items: any[] = [];
        for (let i = 0; i < 3; i++) {
            items.push(
                createItem({
                    template: itemStyleVariations.style2,
                    position: i,
                    ...Object.assign(cloneDeep(itemsWithIcon[i]), {
                        icon: {
                            type: 'text',
                            data: '0' + (i + 1),
                        },
                    }),
                })
            );
        }
        return items;
    })(),
    additionalContent: false,
});
summary.style2__list_column_3__icon__text__button = createSectionFixture({
    title: 'Benefits list with 3 columns, item style 2, with addition content',
    preview: require('@/assets/images/sections/benefits/style2__list_column_3__icon__text__button.png'),
    itemLayout: {
        desktop: createLayoutVariation({ type: 'list', columns: 3 }),
        tablet: createLayoutVariation({ type: 'list', columns: 2 }),
        mobile: createLayoutVariation({ type: 'list', columns: 1 }),
    },
    itemsTemplate: itemStyleVariations.style2,
    items: (() => {
        const items: any[] = [];
        for (let i = 0; i < 3; i++) {
            items.push(
                createItem({
                    template: itemStyleVariations.style2,
                    position: i,
                    ...itemsWithIcon[i],
                })
            );
        }
        return items;
    })(),
    additionalContent: true,
});

summary.style3__centred_column_1__text_icon = createSectionFixture({
    title: 'Benefits list with 1 columns, item style 3 with text icon',
    preview: require('@/assets/images/sections/benefits/style3__centred_column_1__text_icon.png'),
    itemLayout: {
        desktop: createLayoutVariation({ type: 'centeredColumns', columns: 1 }),
        tablet: createLayoutVariation({ type: 'centeredColumns', columns: 1 }),
        mobile: createLayoutVariation({ type: 'centeredColumns', columns: 1 }),
    },
    itemsTemplate: itemStyleVariations.style3,
    items: (() => {
        const items: any[] = [];
        for (let i = 0; i < 3; i++) {
            items.push(
                createItem({
                    template: itemStyleVariations.style3,
                    position: i,
                    ...Object.assign(cloneDeep(itemsWithIcon[i]), {
                        icon: {
                            type: 'text',
                            data: '0' + (i + 1),
                        },
                    }),
                })
            );
        }
        return items;
    })(),
    additionalContent: false,
});
summary.style3__centred_column_1__icon = createSectionFixture({
    title: 'Benefits list with 1 columns, item style 3',
    preview: require('@/assets/images/sections/benefits/style3__centred_column_1__icon.png'),
    itemLayout: {
        desktop: createLayoutVariation({ type: 'centeredColumns', columns: 1 }),
        tablet: createLayoutVariation({ type: 'centeredColumns', columns: 1 }),
        mobile: createLayoutVariation({ type: 'centeredColumns', columns: 1 }),
    },
    itemsTemplate: itemStyleVariations.style3,
    items: (() => {
        const items: any[] = [];
        for (let i = 0; i < 3; i++) {
            items.push(
                createItem({
                    template: itemStyleVariations.style3,
                    position: i,
                    ...itemsWithIcon[i],
                })
            );
        }
        return items;
    })(),
    additionalContent: false,
});
summary.style3__centred_column_1__icon__text__button = createSectionFixture({
    title: 'Benefits list with 1 columns, item style 3, with addition content',
    preview: require('@/assets/images/sections/benefits/style3__centred_column_1__icon__text__button.png'),
    itemLayout: {
        desktop: createLayoutVariation({ type: 'centeredColumns', columns: 1 }),
        tablet: createLayoutVariation({ type: 'centeredColumns', columns: 1 }),
        mobile: createLayoutVariation({ type: 'centeredColumns', columns: 1 }),
    },
    itemsTemplate: itemStyleVariations.style3,
    items: (() => {
        const items: any[] = [];
        for (let i = 0; i < 3; i++) {
            items.push(
                createItem({
                    template: itemStyleVariations.style3,
                    position: i,
                    ...itemsWithIcon[i],
                })
            );
        }
        return items;
    })(),
    additionalContent: true,
});

summary.style3__centred_column_2__text_icon = createSectionFixture({
    title: 'Benefits list with 2 columns, item style 3 with text icon',
    preview: require('@/assets/images/sections/benefits/style3__centred_column_2__text_icon.png'),
    itemLayout: {
        desktop: createLayoutVariation({ type: 'centeredColumns', columns: 2 }),
        tablet: createLayoutVariation({ type: 'centeredColumns', columns: 2 }),
        mobile: createLayoutVariation({ type: 'centeredColumns', columns: 1 }),
    },
    itemsTemplate: itemStyleVariations.style3,
    items: (() => {
        const items: any[] = [];
        for (let i = 0; i < 6; i++) {
            items.push(
                createItem({
                    template: itemStyleVariations.style3,
                    position: i,
                    ...Object.assign(cloneDeep(itemsWithIcon[i]), {
                        icon: {
                            type: 'text',
                            data: '0' + (i + 1),
                        },
                    }),
                })
            );
        }
        return items;
    })(),
    additionalContent: false,
});
summary.style3__centred_column_2__icons = createSectionFixture({
    title: 'Benefits list with 2 columns, item style 3',
    preview: require('@/assets/images/sections/benefits/style3__centred_column_2__icons.png'),
    itemLayout: {
        desktop: createLayoutVariation({ type: 'centeredColumns', columns: 2 }),
        tablet: createLayoutVariation({ type: 'centeredColumns', columns: 2 }),
        mobile: createLayoutVariation({ type: 'centeredColumns', columns: 1 }),
    },
    itemsTemplate: itemStyleVariations.style3,
    items: (() => {
        const items: any[] = [];
        for (let i = 0; i < 6; i++) {
            items.push(
                createItem({
                    template: itemStyleVariations.style3,
                    position: i,
                    ...itemsWithIcon[i],
                })
            );
        }
        return items;
    })(),
    additionalContent: false,
});
summary.style3__centred_column_2__icons__text__button = createSectionFixture({
    title: 'Benefits list with 2 columns, item style 3, with addition content',
    preview: require('@/assets/images/sections/benefits/style3__centred_column_2__icons__text__button.png'),
    itemLayout: {
        desktop: createLayoutVariation({ type: 'centeredColumns', columns: 2 }),
        tablet: createLayoutVariation({ type: 'centeredColumns', columns: 2 }),
        mobile: createLayoutVariation({ type: 'centeredColumns', columns: 1 }),
    },
    itemsTemplate: itemStyleVariations.style3,
    items: (() => {
        const items: any[] = [];
        for (let i = 0; i < 6; i++) {
            items.push(
                createItem({
                    template: itemStyleVariations.style3,
                    position: i,
                    ...itemsWithIcon[i],
                })
            );
        }
        return items;
    })(),
    additionalContent: true,
});

summary.style5__list_column_4__icon = createSectionFixture({
    title: 'Benefits list with 4 columns, item style 5',
    preview: require('@/assets/images/sections/benefits/style5__list_column_4__icon.png'),
    itemLayout: {
        desktop: createLayoutVariation({ type: 'list', columns: 4 }),
        tablet: createLayoutVariation({ type: 'list', columns: 2 }),
        mobile: createLayoutVariation({ type: 'list', columns: 1 }),
    },
    itemsTemplate: itemStyleVariations.style1,
    items: (() => {
        const items: any[] = [];
        for (let i = 0; i < 4; i++) {
            items.push(
                createItem({
                    template: itemStyleVariations.style1,
                    position: i,
                    ...itemsWithIcon[i],
                })
            );
        }
        return items;
    })(),
    additionalContent: false,
});
summary.style3__list_column_4__text_icon = createSectionFixture({
    title: 'Benefits list with 4 columns, item style 4 with text icon',
    preview: require('@/assets/images/sections/benefits/style3__list_column_4__text_icon.png'),
    itemLayout: {
        desktop: createLayoutVariation({ type: 'list', columns: 4 }),
        tablet: createLayoutVariation({ type: 'list', columns: 2 }),
        mobile: createLayoutVariation({ type: 'list', columns: 1 }),
    },
    itemsTemplate: itemStyleVariations.style1,
    items: (() => {
        const items: any[] = [];
        for (let i = 0; i < 4; i++) {
            items.push(
                createItem({
                    template: itemStyleVariations.style1,
                    position: i,
                    ...Object.assign(cloneDeep(itemsWithIcon[i]), {
                        icon: {
                            type: 'text',
                            data: '0' + (i + 1),
                        },
                    }),
                })
            );
        }
        return items;
    })(),
    additionalContent: false,
});
summary.style5__list_column_4__icon__text__button = createSectionFixture({
    title: 'Benefits list with 4 columns, item style 5, with addition content',
    preview: require('@/assets/images/sections/benefits/style5__list_column_4__icon__text__button.png'),
    itemLayout: {
        desktop: createLayoutVariation({ type: 'list', columns: 4 }),
        tablet: createLayoutVariation({ type: 'list', columns: 2 }),
        mobile: createLayoutVariation({ type: 'list', columns: 1 }),
    },
    itemsTemplate: itemStyleVariations.style1,
    items: (() => {
        const items: any[] = [];
        for (let i = 0; i < 4; i++) {
            items.push(
                createItem({
                    template: itemStyleVariations.style1,
                    position: i,
                    ...itemsWithIcon[i],
                })
            );
        }
        return items;
    })(),
    additionalContent: true,
});

summary.multicolor__grid_2x1x1 = createSectionFixture({
    title: 'Benefits grid',
    preview: require('@/assets/images/sections/benefits/multicolor__grid_2x1x1.png'),
    itemLayout: {
        desktop: createLayoutVariation({ type: 'grid', grid: '2x1x1' }),
        tablet: createLayoutVariation({ type: 'list', columns: 2 }),
        mobile: createLayoutVariation({ type: 'list', columns: 1 }),
    },
    itemsTemplate: itemStyleVariations.multicolor,
    items: (() => {
        const items: any[] = [];
        for (let i = 0; i < 5; i++) {
            items.push(
                createItem({
                    template: itemStyleVariations.multicolor,
                    position: i,
                    ...itemsBackground[i],
                })
            );
        }
        return items;
    })(),
    additionalContent: false,
});

summary.multicolor__grid_1x2 = createSectionFixture({
    title: 'Benefits grid',
    preview: require('@/assets/images/sections/benefits/multicolor__grid_1x2.png'),
    itemLayout: {
        desktop: createLayoutVariation({ type: 'grid', grid: '1x2' }),
        tablet: createLayoutVariation({ type: 'list', columns: 2 }),
        mobile: createLayoutVariation({ type: 'list', columns: 1 }),
    },
    itemsTemplate: itemStyleVariations.multicolor,
    items: (() => {
        const items: any[] = [];
        for (let i = 0; i < 3; i++) {
            items.push(
                createItem({
                    template: itemStyleVariations.multicolor,
                    position: i,
                    ...itemsBackground[i],
                })
            );
        }
        return items;
    })(),
    additionalContent: false,
});

summary.multicolor__grid_3x3_2x2x2 = createSectionFixture({
    title: 'Benefits grid',
    preview: require('@/assets/images/sections/benefits/multicolor__grid_3x3_2x2x2.png'),
    itemLayout: {
        desktop: createLayoutVariation({ type: 'grid', grid: '3x3-2x2x2' }),
        tablet: createLayoutVariation({ type: 'list', columns: 2 }),
        mobile: createLayoutVariation({ type: 'list', columns: 1 }),
    },
    itemsTemplate: itemStyleVariations.multicolor,
    items: (() => {
        const items: any[] = [];
        for (let i = 0; i < 5; i++) {
            items.push(
                createItem({
                    template: itemStyleVariations.multicolor,
                    position: i,
                    ...itemsBackground[i],
                })
            );
        }
        return items;
    })(),
    additionalContent: false,
});

summary.style6__list_column_4__icon = createSectionFixture({
    title: 'Benefits list with 3 columns, item style 2',
    preview: require('@/assets/images/sections/benefits/style6__list_column_4__icon.png'),
    itemLayout: {
        desktop: createLayoutVariation({ type: 'list', columns: 4 }),
        tablet: createLayoutVariation({ type: 'list', columns: 2 }),
        mobile: createLayoutVariation({ type: 'list', columns: 1 }),
    },
    itemsTemplate: itemStyleVariations.style6,
    items: (() => {
        const items: any[] = [];
        for (let i = 0; i < 4; i++) {
            items.push(
                createItem({
                    template: itemStyleVariations.style6,
                    position: i,
                    ...itemsWithIcon[i],
                })
            );
        }
        return items;
    })(),
    additionalContent: false,
});

// console.log('test', summary);

export default summary;
