export default class AbstractEntity {
    constructor() {
        this.requiredProps = [];
    }

    setRequiredProps(arrayProps) {
        this.requiredProps = arrayProps;
    }

    validateData(data) {
        if (typeof data === 'object' && data !== null) {
            this.requiredProps.forEach((prop) => {
                if (Object.prototype.hasOwnProperty.call(data, prop) === false)
                    throw new Error(`Required prop "${prop}" is undefinded`);
            });
            return data;
        } else return {};
    }
}
