import { getAccessToken } from '@/api/auth';
//import detectInstanceId from '@/api/detectInstanceId';

// export function authMiddleware({ to, next }) {
export function authMiddleware({ next }) {
    // если url содержит instance_id
    const url = new URL(window.location.href);
    const param = url.searchParams.get('instance_id');
    if (param) {
        localStorage.setItem('runshop_instance_id', param);
        window.location.href = '/';
    }

    // console.log('authMiddleware');
    return getAccessToken()
        .then(() => Promise.resolve(next()))
        .catch((e) => {
            console.log('authMiddleware getAccessToken catch', e);
            window.location.href = '/auth/login';
        });
    // .then(() => Promise.resolve(next()))
    // return getAccessToken()
    //     .then(() => Promise.resolve(next()))
    //     .catch((e) => {
    //         console.log('sh');
    //         console.log(e);
    //         localStorage.setItem('redirect-after-auth', to.path);
    //         console.log(localStorage.getItem('redirect-after-auth'), 'authMiddleware');
    //         window.location = '/auth/login';
    //     });
}
