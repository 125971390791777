
import { defineComponent } from 'vue';
import Button from '@/components/common/Button.vue';
import detectInstanceId from '@/api/detectInstanceId';
import { accountUrl } from '@/helpers/values';
import EventEmitter from '@/helpers/eventEmitter';
import { mapGetters } from 'vuex';

interface OptionalData {
    items: { value: string; text: string }[];
    currentId: string | null;
    model: string;
    isOpen: boolean;
    publicUrl: string;
}

export default defineComponent({
    name: 'userInstancesSelect',
    components: { Button },
    data(): OptionalData {
        return {
            publicUrl: '',
            items: [],
            currentId: localStorage.getItem('runshop_instance_id'),
            model: '',
            isOpen: false,
        };
    },
    async created() {
        const instanceId = detectInstanceId();
        this.publicUrl = `https://${this.instanceFull.primaryDomain}`;
        if (!this.instances.find((item) => item.instanceId === instanceId)) {
            localStorage.removeItem('runshop_instance_id');
            // this.$router.push({ name: 'Instance List' });
        }
        if (this.instances && this.instances.length)
            this.items = this.instances.map((item) => ({
                value: item.instanceId,
                text: item.primaryDomain,
            }));
        this.model = instanceId || '';
    },
    computed: {
        current: function() {
            const instance = this.items.find(({ value }) => value === this.currentId);
            return instance ? instance.text : '';
        },
        ...mapGetters('globalVars', { instances: 'getInstances' }),
        ...mapGetters('globalVars', { instanceFull: 'getInstanceFull' }),
    },
    methods: {
        changeInstance(instanceId) {
            if (this.currentId !== instanceId.value) {
                localStorage.setItem('runshop_instance_id', instanceId.value);
                EventEmitter.trigger('runshop_instance_id', instanceId.value);
                // window.location.href = '/';
            }
        },
        toggleDropdown() {
            this.isOpen = !this.isOpen;
        },
        closeDropdown() {
            this.isOpen = false;
        },
        createStore() {
            location.href = `${accountUrl}/create`;
        },
    },
});
