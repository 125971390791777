<template>
    <div class="page-switcher-list">
        <template v-if="!currentItem">
            <div v-for="item in items" :key="item.id" class="page-switcher-list__item" @click="clickPage(item)">
                <span class="page-switcher-list__item-icon material-icons-outlined">{{ item.icon }}</span>
                {{ $t(item.title) }}
                <span v-if="item.items" class="material-icons-outlined -next">navigate_next</span>
            </div>
        </template>
        <template v-else>
            <div class="page-switcher-list__item -current" @click="clickBefore">
                <span class="material-icons-outlined">navigate_before</span>
                {{
                    $t(currentItem.titleParams) ? $t(currentItem.title, currentItem.titleParams) : $t(currentItem.title)
                }}
            </div>
            <div
                class="page-switcher-list__item-container"
                v-for="item in getFilteredItems(currentItem.items)"
                :key="item.id"
                :class="item.class"
            >
                <div class="page-switcher-list__item" @click="clickPage(item)">
                    <span class="page-switcher-list__item-icon material-icons-outlined">
                        {{ item.icon || currentItem.icon }}
                    </span>
                    {{ $t(item.title) }}
                </div>
                <span v-if="item.nested" @click="openNestedItems(item)" class="material-icons-outlined -next"
                    >keyboard_double_arrow_right</span
                >
            </div>
        </template>
    </div>
</template>

<script>
export default {
    name: 'PageSwitcherList',
    props: {
        items: {
            type: Array,
            default: () => [],
        },
        type: {
            type: [String, null],
            default: null,
        },
        search: {
            type: [String, null],
            default: null,
        },
    },
    computed: {
        currentItem() {
            if (this.type !== null) return this.items.find((item) => item.type === this.type);
            else return null;
        },
    },
    methods: {
        clickPage(item) {
            if (item.items) this.$emit('select-type', item.type);
            else this.$emit('switch-page', item);
        },
        clickBefore() {
            this.$emit('select-type', null);
        },
        getFilteredItems(items) {
            if (this.search) {
                return items.filter((item) => {
                    const title = item.title.toLowerCase();
                    const searchTerm = this.search.toLowerCase();
                    return title.indexOf(searchTerm) >= 0;
                });
                // return items;
            }
            return items;
        },
        openNestedItems(item) {
            this.$emit('open-nested', item);
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/mixins';
@import '@/scss/variables.scss';
.page-switcher-list {
    &__item-container {
        display: flex;
        align-items: center;

        .page-switcher-list__item {
            padding: 8px 12px;
            width: 100%;
            &:hover {
                background-color: #f4f6f7;
            }
        }
        .material-icons-outlined.-next {
            line-height: 32px;
            padding: 8px 12px;
            cursor: pointer;
            transition: $transition-fast;

            &:hover {
                background-color: #f4f6f7;
            }
        }
    }
    &__item {
        position: relative;
        display: flex;
        padding: 8px 40px 8px 12px;
        transition: $transition-fast;
        font-size: 16px;
        line-height: 32px;
        cursor: pointer;

        .material-icons-outlined {
            width: 24px;
            position: relative;
            top: -2px;
            color: inherit;
            line-height: 32px;

            &.-next {
                position: absolute;
                right: 12px;
                top: 10px;
            }
        }

        &-icon {
            margin-right: 8px;
        }

        &:hover {
            background-color: #f4f6f7;
        }

        &.-current {
            border-bottom: 1px solid #e0e0e0;
            .material-icons-outlined {
                top: -1px;
            }
        }

        &.-highlighted {
            color: #2979ff;
        }
    }
}
</style>
