<template>
    <img class="sidebar-tooltip__preview-image" src="@/assets/images/description.jpg" />
</template>

<script>
export default {
    name: 'ProductDescription',
};
</script>

<style lang="scss">
.sidebar-tooltip {
    &__preview-image {
        width: 900px;
        height: auto;
        margin-top: 60px;
    }
}
</style>
