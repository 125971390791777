import banners from '@/entities/section/fixtures/banners';
import benefits from '@/entities/section/fixtures/benefits';
import categories from '@/entities/section/fixtures/categories';
import contacts from '@/entities/section/fixtures/contacts';
import faq from '@/entities/section/fixtures/faq';
import form from '@/entities/section/fixtures/form';
import gallery from '@/entities/section/fixtures/gallery';
import mediaWidget from '@/entities/section/fixtures/media';
import productWidget from '@/entities/section/fixtures/productWidget';
import properties from '@/entities/section/fixtures/properties';
import slider from '@/entities/section/fixtures/slider';
import testimonials from '@/entities/section/fixtures/testimonials';
import textBlocks from '@/entities/section/fixtures/textBlocks';

export type SectionFixtureType = {
    title: string;
    preview: string;
    group: string;
    data: Object;
};
export type SectionGroupFixtureType = {
    [key: string]: SectionFixtureType;
};

export default (() => {
    const fixtures: Object = {
        Banners: banners,
        Benefits: benefits,
        Category: categories,
        Contacts: contacts,
        FAQ: faq,
        Forms: form,
        Gallery: gallery,
        MediaWidget: mediaWidget,
        ProductWidget: productWidget,
        Properties: properties,
        Slider: slider,
        Testimonial: testimonials,
        TextBlocks: textBlocks,
    };

    const getGroups: string[] = Object.keys(fixtures);

    const getFixtureByGroup = (groupName: string): SectionGroupFixtureType | null => {
        if (fixtures[groupName]) return fixtures[groupName];
        else return null;
    };

    return Object.freeze({
        fixtures,
        getGroups,
        getFixtureByGroup,
    });
})();
