<template>
    <tooltip class="sidebar-tooltip">
        <span class="icon sidebar-tooltip__icon tooltip__icon -outlined">error_outline</span>
        <div>
            <div class="sidebar-tooltip__header">
                {{ $t('entities.domain.tooltips.emailStatus') }}
            </div>
            <div class="sidebar-tooltip__text">
                {{
                    domain
                        ? $t('entities.domain.tooltips.emailCname.domain', { domain })
                        : $t('entities.domain.tooltips.emailCname.general')
                }}
            </div>
            <div class="sidebar-tooltip__items">
                <div class="sidebar-tooltip__item">
                    <div class="sidebar-tooltip__item-text">
                        <b> CNAME:</b>
                        runshopmail._domainkey
                    </div>
                    <div class="sidebar-tooltip__item-text"><b>Value:</b> dkim.mail.runshop.io</div>
                </div>
            </div>
            <!--            <div v-if="!domain" class="sidebar-tooltip__help">
                {{ $t('entities.domain.tooltips.replaceName') }}
            </div>-->
        </div>
    </tooltip>
</template>

<script>
import Tooltip from '@/components/common/Tooltip';
export default {
    name: 'DomainEmailTooltip',
    components: {
        Tooltip,
    },
    props: {
        domain: {
            type: String,
            default: null,
        },
    },
};
</script>

<style lang="scss"></style>
