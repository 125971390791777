<template>
    <modal header="Order comment" v-model="model">
        <div class="modal__content form" ref="form">
            <progress-circular class="modal__spinner spinner" v-if="isLoading"></progress-circular>
            <span v-else>{{ comment }}</span>
        </div>
    </modal>
</template>

<script>
import Modal from '@/components/common/Modal';
import { proxyModelMixin } from '@/mixins/proxyModelMixin';
import ProgressCircular from '@/components/common/ProgressCircular';

export default {
    name: 'OrderCommentModal',
    mixins: [proxyModelMixin],
    components: { Modal, ProgressCircular },

    props: {
        value: { type: Boolean, default: false },
        comment: { type: String, default: '' },
    },

    data() {
        return {
            isLoading: false,
        };
    },
};
</script>

<style lang="scss"></style>
