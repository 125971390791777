<template>
    <div class="tooltips-container" :style="{ width: `${width}px` }">
        <span class="tooltips-container__close icon" @click="close">close</span>
        <template v-if="tooltips.length">
            <component v-for="tooltip in tooltips" v-bind="config[tooltip]" :key="tooltip" :is="tooltip"></component>
        </template></div
></template>

<script>
import EventEmitter from '@/helpers/eventEmitter.ts';
export default {
    name: 'tooltipsContainer',

    components: {
        'variation-quantity': () => import('@/components/tooltips/VariationQuantityTooltip'),
        'product-size': () => import('@/components/tooltips/ProductSizeTooltip'),
        'domain-confirmed': () => import('@/components/tooltips/DomainConfirmedTooltip'),
        'domain-email': () => import('@/components/tooltips/DomainEmailTooltip'),
        'domain-type': () => import('@/components/tooltips/DomainTypeTooltip'),
        'product-description': () => import('@/components/tooltips/ProductDescriptionTooltip'),
        'product-preview-description': () => import('@/components/tooltips/ProductPreviewDescriptionTooltip'),
        'telegram-notification-variables': () => import('@/components/tooltips/TelegramNotificationVariablesTooltip'),
        'network-genius-payment': () => import('@/components/tooltips/NetworkGeniusPaymentTooltip'),
    },

    props: {
        tooltips: {
            type: Array,
            default: () => [],
        },
        width: {
            type: Number,
            default: 544,
        },
        config: {
            type: Object,
            default: () => {},
        },
    },
    methods: {
        close() {
            EventEmitter.trigger('toggle-tooltips', { isShown: false });
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.tooltips-container {
    position: fixed;
    right: 0;
    top: 0;
    padding: 37px 0 36px 36px;
    z-index: 12;
    height: 100%;
    background-color: var(--v-surface-base);
    overflow-y: scroll;
    &__close {
        font-size: 24px;
        position: absolute;
        top: 42px;
        right: 42px;
        z-index: 8;
        &:hover {
            opacity: 0.7;
            cursor: pointer;
        }
    }
    .tooltip__content {
        padding-right: 70px;
    }
}
</style>
