import { isObject, merge } from 'lodash';

import * as validators from 'vuelidate/lib/validators';
export { validators };

function findFrontendErrors(data = {}) {
    const scheme = data.scheme || this.$options.validations;
    const lastKey = data.lastKey || null;
    const formState = data.formState || this.$v;
    const result = data.result || {};

    for (const key in scheme) {
        if (typeof scheme[key] === 'function' && !formState[key]) {
            result[lastKey || key] = ['error'];
        } else {
            findFrontendErrors({
                scheme: scheme[key],
                lastKey: key,
                formState: formState[key],
                result,
            });
        }
    }
    return result;
}

function findBackendErrors(data = {}) {
    const errors = data.errors || this.backendErrors;
    const result = data.result || {};
    for (const key in errors) {
        if (errors[key].errors?.length) {
            result[key] = errors[key].errors;
        } else if (isObject(errors[key])) {
            findBackendErrors({
                errors: errors[key],
                result,
            });
        }
    }
    return result;
}

export function validationErrors() {
    const frontendErrors = findFrontendErrors.call(this);
    const backendErrors = findBackendErrors.call(this);
    return merge(frontendErrors, backendErrors);
}

export function checkValidate() {
    return !Object.keys(validationErrors.call(this)).length;
}

export class Validator {
    constructor(context) {
        this.context = context;
    }

    findFrontendErrors(data = {}) {
        const scheme = data.scheme || this.context.$options.validations;
        const lastKey = data.lastKey || null;
        const formState = data.formState || this.context.$v;
        const result = data.result || {};

        for (const key in scheme) {
            if (typeof scheme[key] === 'function' && !formState[key]) {
                result[lastKey || key] = ['error'];
            } else {
                this.findFrontendErrors({
                    scheme: scheme[key],
                    lastKey: key,
                    formState: formState[key],
                    result,
                });
            }
        }
        return result;
    }

    findBackendErrors(data = {}) {
        const errors = data.errors || this.context.backendErrors;
        const result = data.result || {};
        for (const key in errors) {
            if (errors[key].errors?.length) {
                result[key] = errors[key].errors;
            } else if (isObject(errors[key])) {
                this.findBackendErrors({
                    errors: errors[key],
                    result,
                });
            }
        }
        return result;
    }

    validationErrors() {
        const frontendErrors = this.findFrontendErrors();
        const backendErrors = this.findBackendErrors();
        return merge(frontendErrors, backendErrors);
    }
}
