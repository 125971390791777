<template>
    <div class="menu">
        <div ref="activator" class="menu__activator" @mouseover="toggleMenu(true)" @mouseleave="toggleMenu(false)">
            <slot name="activator"> </slot>
        </div>
        <transition v-show="false" name="content">
            <div ref="content" class="menu__content">
                <slot></slot>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: 'Menu',
    props: {
        width: {
            default: 270,
            type: Number,
        },
        coverActivator: {
            default: false,
            type: Boolean,
        },
        fixed: {
            default: false,
            type: Boolean,
        },
        position: {
            type: String,
            default: 'bottom',
            validator: (value) => ['bottom', 'top', 'left', 'right'].includes(value),
        },
    },
    mounted() {
        const div = document.createElement('div');
        div.classList.add('menu__popup');
        div.addEventListener('mouseover', () => this.toggleMenu(true));
        div.addEventListener('mouseleave', () => this.toggleMenu(false));
        div.appendChild(this.$refs.content);
        this.element = div;
        document.body.appendChild(div);
    },
    methods: {
        toggleMenu(val) {
            if (val) {
                this.element.style.display = 'block';
                this.placeElement();
                this.element.classList.add('-shown');
            } else {
                this.element.style.display = 'none';
                this.element.classList.remove('-shown');
            }
        },
        placeElement() {
            const activator = this.$refs.activator;
            const activatorRect = this.getCoords(activator);
            const div = this.element;
            if (this.fixed) {
                div.style.position = 'fixed';
                div.style.top = activatorRect.box.bottom + 'px';
                div.style.left = activatorRect.left + 'px';
                return;
            }
            if (this.coverActivator) {
                div.style.top = activatorRect.top + 'px';
            } else {
                div.style.top = activatorRect.bottom + 'px';
            }

            div.style.left = activatorRect.left + 'px';
        },
        getCoords(elem) {
            // crossbrowser version
            const box = elem.getBoundingClientRect();

            const body = document.body;
            const docEl = document.documentElement;

            const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
            const scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

            const clientTop = docEl.clientTop || body.clientTop || 0;
            const clientLeft = docEl.clientLeft || body.clientLeft || 0;

            const top = box.top + scrollTop - clientTop;
            const left = box.left + scrollLeft - clientLeft;
            const bottom = box.top + box.height + scrollTop - clientTop;

            return { top: Math.round(top), left: Math.round(left), bottom: Math.round(bottom), box };
        },
    },
    computed: {
        classes() {
            return `-${this.position}`;
        },
    },
    data() {
        return {
            isMenuShown: false,
            element: null,
        };
    },
    beforeDestroy() {
        this.element.remove();
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.menu {
    position: relative;
    display: inline-block;
    &__activator {
        width: fit-content;
        cursor: pointer;
    }
    &__popup {
        position: absolute;
        display: none;
        border-radius: 4px;
        border: 1px solid lightgray;
        overflow: hidden;
        opacity: 1;
        z-index: 100;
        transition: opacity 0.2s ease;
        &.-shown {
            transition: opacity 0.5s ease;
            opacity: 1;
        }
    }
    /*    &__content {
        display: flex;
        gap: 10px;
        height: auto;
        border-radius: 4px;
        padding: 12px 16px;
        background-color: var(--v-primary-lighten-base);
        color: var(--v-primary-accent-base);
        z-index: 100;
        opacity: 1;
        border: 1px solid gray;
    }*/
    .content-enter-active,
    .content-leave-active {
        transition: all 0.4s ease;
    }
    .content-enter {
        transform: scale(0);
        opacity: 0;
    }
    .content-leave-to {
        opacity: 0;
    }
    .content-enter-to {
        transform: scale(1);
        opacity: 1;
    }
}
</style>
