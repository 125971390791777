import apiClient from '@/api/apiClient';
import { ShippingZone } from '@/services/services.types';
import CustomError from '@/entities/customError';

class ShippingZoneService {
    /**
     * Получение всех зон доставки
     * @returns {Promise} - массив зон
     */

    async getAll(): Promise<[CustomError | null, ShippingZone[] | []]> {
        const url: string = '/shipping-zones';
        const defaultValue = [];
        const errorPath: string = '[api:shippingZone:getAll]';
        return await apiClient.admin.get({ url, defaultValue, errorPath });
    }

    /**
     * Создание зоны зоставки
     * @param {ShippingZone} shippingZone - объект зоны доставки
     * @returns {Promise} - созданный объект доставки
     */

    async createOne(shippingZone: ShippingZone): Promise<[CustomError | null, ShippingZone | null]> {
        const url: string = '/shipping-zones';
        const defaultValue = null;
        const errorPath: string = '[api:shippingZone:createOne]';
        return await apiClient.admin.post({ url, defaultValue, errorPath, data: shippingZone });
    }

    /**
     * Обновление зоны зоставки
     * @param {ShippingZone} shippingZone - объект зоны доставки
     * @returns {Promise} - обновленный объект зоны доставки
     */

    async updateOne(shippingZone: ShippingZone): Promise<[CustomError | null, ShippingZone | null]> {
        const url: string = `/shipping-zones/${shippingZone.id}`;
        const defaultValue = null;
        const errorPath: string = '[api:shippingZone:updateOne]';
        return await apiClient.admin.put({ url, defaultValue, errorPath, data: shippingZone });
    }

    /**
     * Удаление зоны зоставки
     * @param {ShippingZone} id - id зоны доставки
     * @returns {Promise} - null
     */

    async removeOne(id: string): Promise<[CustomError | null, null]> {
        const url: string = `/shipping-zones/${id}`;
        const defaultValue = null;
        const errorPath: string = '[api:shippingZone:removeOne]';
        return await apiClient.admin.delete({ url, defaultValue, errorPath });
    }
}

export default new ShippingZoneService();
