import { cloneDeep } from 'lodash';
import {
    Background,
    Container,
    Content,
    Heading,
    Section,
    SectionItemsStyle,
    TextStyle,
} from '@/entities/section/samples/_options';

const section = {
    id: '',
    type: 'SectionTextBlockGalleryMasonry',
    required: false,
    group: 'TextBlocks',
    options: {
        section: { ...Section },
        container: { ...Container },
        heading: {
            title: { ...Heading.title },
            fontStyleTitle: { ...TextStyle.fontStyle },
            colorTitle: { ...Heading.colorTitle },
            textAlign: { ...Heading.textAlign },
        },
        content: {
            html: { ...Content.html },
            imageBorderRadius: { ...SectionItemsStyle.borderRadius },
        },
        button: {
            title: { ...Content.btnText },
            link: { ...Content.link },
        },
        grid: {
            type: 'field',
            value: {},
            component: 'CustomizeColumnsTabs',
            ...{
                props: {
                    screen: ['desktop', 'tablet'], // 'desktop', 'tablet', 'mobile'
                },
            },
        },
        background: { ...Background },
    },
    items: [],
    children: {
        id: '',
        type: 'galleryItem',
        options: {
            content: {
                image: {
                    ...Content.image,
                    props: {
                        note: 'JPG, PNG. Recomended size: 382х382px. No more than 40kb',
                    },
                },
                alt: {
                    ...Content.imageAlt,
                    value: 'Image title text',
                },
                title: {
                    ...Content.imageTitle,
                    value: 'Image title text',
                },
            },
        },
    },
};

const itemFixture = cloneDeep(section.children);
const itemsFixture = [];
itemsFixture.push(cloneDeep(itemFixture));
itemsFixture.push(cloneDeep(itemFixture));
itemsFixture.push(cloneDeep(itemFixture));
itemsFixture.push(cloneDeep(itemFixture));
itemsFixture.push(cloneDeep(itemFixture));
section.items = itemsFixture;

export default section;
