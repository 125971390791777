<template>
    <router-link class="sidebar-menu-item" :to="item.to" @click="closeAll" exact>
        <div class="sidebar-menu-item__icon" v-if="item.icon">
            <span class="material-icons-outlined">{{ item.icon }}</span>
        </div>
        <div class="sidebar-menu-item__content" v-if="$t(item.title)">
            <span
                class="sidebar-menu-item__title"
                v-text="item.variable ? $t(item.title, item.variable) : $t(item.title)"
            ></span>
        </div>
    </router-link>
</template>

<script>
export default {
    name: 'SidebarMenuItem',
    props: {
        item: {
            type: Object,
            default: () => ({
                icon: undefined,
                title: undefined,
                to: undefined,
            }),
        },
    },

    methods: {
        closeAll() {
            this.$emit('closeAllGroups');
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.sidebar-menu-item {
    align-items: center;
    display: flex;
    flex: 1 1 100%;
    letter-spacing: normal;
    outline: none;
    position: relative;
    text-decoration: none;
    min-height: 35px;
    border-radius: 0;
    padding: 5px 15px 5px 11px;
    margin-bottom: 0;
    transition: $transition-fast;
    color: var(--v-on-surface-high-base) !important;
    border-left: 2px solid transparent;
    &__icon {
        width: 20px;
        margin: 12px 15px 12px 0;
        &:first-child {
            justify-content: center;
            text-align: center;
        }
        .material-icons-outlined {
            color: var(--v-on-surface-high-base);
            font-size: 22px;
        }
    }
    &__content {
        align-items: center;
        align-self: center;
        display: flex;
        flex-wrap: wrap;
        flex: 1 1;
        overflow: hidden;
        padding: 12px 0;
    }
    &__title {
        line-height: 1.2;
        font-size: 16px;
        color: var(--v-on-surface-high-base);
        align-self: center;
        flex: 1 1 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    &:hover {
        background: var(--v-surface-overlay-base);
    }
    &.router-link-active {
        background: var(--v-primary-lighten-base) !important;
        border-left-color: var(--v-primary-base) !important;
        & .sidebar-menu-item__title,
        & .sidebar-menu-item__icon .material-icons-outlined {
            color: var(--v-primary-base);
        }
    }
}
</style>
