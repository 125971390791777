export default () => ({
    serviceName: {
        component: 'input-text',
        props: {
            rules: ['noEmpty'],
            class: '-half-width',
            labelTrans: 'entities.order.state.fields.serviceName',
            errors: [],
        },
    },
    publicName: {
        component: 'input-text',
        props: {
            rules: ['noEmpty'],
            class: '-half-width',
            labelTrans: 'entities.order.state.fields.publicName',
            errors: [],
        },
    },
    position: {
        component: 'input-text',
        props: {
            labelTrans: 'entities.order.state.fields.position',
            class: '-half-width',
            errors: [],
        },
    },
    color: {
        value: '#0dbd65',
        component: 'ColorPicker',
        props: {
            rules: ['noEmpty'],
            labelTrans: 'entities.order.state.fields.color',
            class: '-half-width',
            btnReset: true,
            mode: 'hexa',
            errors: [],
        },
    },
});
