export default {
    date: {
        title: 'Choose the dates',
        buttons: {
            day: 'Day',
            week: 'Week',
            month: 'Month',
        },
    },
    quickActions: {
        title: 'Quick Actions',
        buttons: {
            createNewPage: 'Create New Page',
            addProduct: 'Add Product',
            createCatalog: 'Create Catalog',
        },
    },
    total: {
        sales: 'Total sales',
        orders: 'Total orders',
        items: 'Total items sold',
    },
    statistics: {
        title: 'Sales Statistics',
    },
    bestSellingProducts: {
        title: 'Best Selling Products',
    },
    lastOrders: {
        title: 'Last Orders',
    },
};
