export default {
    heading: 'Биллинг',
    paymentMethods: {
        heading: 'Методы оплаты',
        btnAdd: 'Добавить метод оплаты',
        paymentTerms: 'Условия опалты: <span>Автоматически оплачивать каждый цикл</span>',
        columns: {
            card: 'Кредитные карты',
            using: 'Текущая карта',
            edit: 'Изменить',
        },
        addModal: {
            heading: 'Информация об оплате',
        },
        form: {
            cardNumber: 'Номер карты',
            expiryDate: 'Истечение срока действия',
            cvv: 'CVV',
            nameOnCard: 'Имя на карте',
            country: 'Страна или регион',
            btnSave: 'Сохранить',
        },
    },
    subscription: {
        heading: 'Активная подписка',
        btnCancel: 'Отменить подписку',
        manage: 'Управлять подпиской',
        cancelModal: {
            heading: 'Отменить подписку',
            text:
                'По прошествии 30 дней с отмены вашей подписки, вся ваша информация будет удалена без возможности восстановления',
            btnYes: 'Да',
            btnNo: 'Нет',
        },
    },
    invoice: {
        heading: 'Счёт',
        btnDownload: 'Скачать',
    },
};
