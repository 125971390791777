import { merge } from 'lodash';
import {
    Container,
    Content,
    Heading,
    Section,
    SectionItemsStyle,
    TextStyle,
} from '@/entities/section/samples/_options';
import { SELECT } from '@/helpers/fieldTypes.no-vuetify';

const section = {
    id: '',
    type: 'SectionTextBlockPictureCenter',
    required: false,
    group: 'TextBlocks',
    options: {
        section: { ...Section },
        container: { ...Container },
        heading: {
            title: { ...Heading.title },
            fontStyleTitle: { ...TextStyle.fontStyle },
            colorTitle: { ...Heading.colorTitle },
            textAlign: { ...Heading.textAlign },
        },
        content: {
            html: { ...Content.html },
            image: merge(Content.image, {
                props: { note: 'JPG, PNG. Recomended size: 1170х550px. No more than 160kb' },
            }),
            alt: { ...Content.imageAlt },
            imageBorderRadius: { ...SectionItemsStyle.borderRadius },
            order: {
                value: 'text-first',
                ...SELECT,
                ...{
                    props: {
                        labelTrans: 'builder.sectionOptions.props.order',
                        items: [
                            { text: 'Image first', value: 'img-first' },
                            { text: 'Text first', value: 'text-first' },
                        ],
                    },
                },
            },
        },
        button: {
            title: { ...Content.btnText },
            link: { ...Content.link },
        },
        // background: { ...Background },
    },
    items: [],
    children: null,
};

export default section;
