import { SectionFixtureType, SectionGroupFixtureType } from '@/entities/section/fixtures';
// id: 'fixture',
const style1Prev1: SectionFixtureType = {
    preview: require('@/assets/images/sections/forms/form-style1prev1.jpg'),
    title: 'Form',
    group: 'Form',
    data: {
        id: 'fixture',
        items: [],
        required: false,
        fixed: false,
        type: 'SectionFormStyle1',
        options: {
            section: {
                width: 'full',
                padding: {
                    desktop: { top: 28, right: 0, bottom: 28, left: 0 },
                    tablet: { top: 28, right: 0, bottom: 28, left: 0 },
                    mobile: { top: 16, right: 0, bottom: 16, left: 0 },
                },
                borderRadius: {
                    desktop: 0,
                    tablet: 0,
                    mobile: 0,
                },
                background: 'none',
            },
            container: {
                width: 'content',
                padding: {
                    desktop: null,
                    tablet: null,
                    mobile: null,
                },
                borderRadius: {
                    desktop: 0,
                    tablet: 0,
                    mobile: 0,
                },
                background: 'none',
            },
            heading: {
                title: 'Leave your contact details',
            },
        },
    },
};

const style1Prev2: SectionFixtureType = {
    preview: require('@/assets/images/sections/forms/form-style1prev2.jpg'),
    title: 'Form',
    group: 'Form',
    data: {
        id: 'fixture',
        items: [],
        required: false,
        fixed: false,
        type: 'SectionFormStyle1',
        options: {
            section: {
                width: 'full',
                padding: {
                    desktop: { top: 28, right: 0, bottom: 28, left: 0 },
                    tablet: { top: 28, right: 0, bottom: 28, left: 0 },
                    mobile: { top: 16, right: 0, bottom: 16, left: 0 },
                },
                borderRadius: {
                    desktop: 0,
                    tablet: 0,
                    mobile: 0,
                },
                background: {
                    desktop: {
                        color: null,
                        image: '48ef2a0e-ba0f-482f-84d9-f0554bc5ac22',
                    },
                    tablet: {
                        color: null,
                        image: null,
                    },
                    mobile: {
                        color: null,
                        image: null,
                    },
                },
            },
            container: {
                width: 'content',
                padding: {
                    desktop: null,
                    tablet: null,
                    mobile: null,
                },
                borderRadius: {
                    desktop: 0,
                    tablet: 0,
                    mobile: 0,
                },
                background: 'none',
            },
            heading: {
                title: 'Leave your contact details',
            },
        },
    },
};

const summary: SectionGroupFixtureType = {
    style1Prev1,
    style1Prev2,
};

export default summary;
