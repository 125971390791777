import AbstractEntity from '@/entities/abstractEntity';
import initFields from '@/entities/email/Email.fields';

export default class Email extends AbstractEntity {
    fields = initFields(this);

    constructor(data) {
        super();
        this.notification = data.notification || null;
        this.enabled = data.enabled;
        this.subject = data.subject || '';
        this.content = data.content || '';
    }
}
