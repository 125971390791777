<template>
    <div class="input-radio" @click="handleClick" :class="classObject">
        <input :name="name" type="radio" ref="radio" :value="returnValue" :disabled="disabled" v-model="model" />
        <slot name="text">
            <span class="input-radio__text" v-if="text">{{ $t(text) }}</span>
        </slot>
    </div>
</template>

<script>
import { proxyModelMixin } from '@/mixins/proxyModelMixin';

export default {
    name: 'InputRadio',
    mixins: [proxyModelMixin],
    props: {
        returnValue: {
            type: null,
            default: true,
        },
        value: {
            type: null,
        },
        name: {
            type: String,
        },
        group: {
            type: String,
        },
        size: {
            type: String,
            default: 'medium',
            validator: function(value) {
                return ['small', 'medium', 'large'].includes(value);
            },
        },
        disabled: {
            type: Boolean,
        },
        text: {
            type: String,
        },
    },
    methods: {
        handleClick() {
            if (!this.disabled) {
                this.$refs.radio.click();
            }
        },
    },
    computed: {
        classObject() {
            return {
                '-disabled': this.disabled,
                '-small': this.size === 'small',
                '-medium': this.size === 'medium',
                '-large': this.size === 'large',
            };
        },
    },
    watch: {
        model: function(newValue) {
            this.$emit('update-field', {
                name: this.name,
                group: this.group,
                value: newValue,
            });
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.input-radio {
    display: inline-flex;
    align-items: center;
    gap: 10px;
    &:hover {
        cursor: pointer;
    }
    &.-large {
        input[type='radio'] {
            width: 35px;
            height: 35px;
            &::before {
                width: 15px;
                height: 15px;
            }
        }
    }
    &.-medium {
        input[type='radio'] {
            width: 20px;
            height: 20px;
            &::before {
                width: 9px;
                height: 9px;
            }
        }
    }
    &.-small {
        input[type='radio'] {
            width: 15px;
            height: 15px;
            &::before {
                width: 7px;
                height: 7px;
            }
        }
    }
    &.-disabled {
        input[type='radio'] {
            border-color: var(--v-on-surface-disabled-base) !important;
        }
        input[type='radio']::before {
            box-shadow: inset 1em 1em var(--v-on-surface-disabled-base) !important;
        }
    }
    input[type='radio'] {
        &:hover {
            cursor: pointer;
        }
        //remove native
        -webkit-appearance: none;
        appearance: none;
        background-color: #fff;
        margin: 0;
        //create custom
        font: inherit;
        color: var(--v-primary-base);
        border: 2px solid var(--v-primary-base);
        border-radius: 50%;
        transform: translateY(-0.075em);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    input[type='radio']::before {
        content: '';
        border-radius: 50%;
        transform: scale(0);
        transition: 120ms transform ease-in-out;
        box-shadow: inset 10px 10px var(--v-primary-base);
    }

    input[type='radio']:checked::before {
        transform: scale(1);
    }
    &__text {
        font-size: 16px;
    }
}
</style>
