var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page domains -width-lg"},[_c('domain-primary-modal',{attrs:{"domain":_vm.primaryModalDomain},on:{"change-primary":_vm.changePrimary},model:{value:(_vm.showPrimaryModal),callback:function ($$v) {_vm.showPrimaryModal=$$v},expression:"showPrimaryModal"}}),(!_vm.isAvailable)?[_c('div',{staticClass:"page-header"},[_c('h1',{staticClass:"page-header__text"},[_vm._v(_vm._s(_vm.$t('menus.pageHeaders.domains')))])])]:_vm._e(),(!_vm.isAvailable)?_c('error-block'):[_c('domain-modal',{on:{"action-success":_vm.getItems},model:{value:(_vm.isCreateModalOpen),callback:function ($$v) {_vm.isCreateModalOpen=$$v},expression:"isCreateModalOpen"}}),_c('div',{staticClass:"page-header"},[_c('h1',{staticClass:"page-header__text"},[_vm._v(_vm._s(_vm.$t('menus.pageHeaders.domains')))]),_c('Button',{on:{"click":_vm.openCreateModal}},[_vm._v(_vm._s(_vm.$t('entities.add')))])],1),(_vm.showWarning)?_c('div',{staticClass:"domains__alert alert -warning"},[_c('span',{staticClass:"alert__icon icon"},[_vm._v("warning")]),_c('div',[_c('p',{staticClass:"alert__title"},[_vm._v(_vm._s(_vm.$t('entities.domain.alert.issues')))]),_c('p',{staticClass:"alert__text",on:{"click":function($event){return _vm.showTooltips(['domain-confirmed'], { width: 700 })}}},[_vm._v(" "+_vm._s(_vm.$t('entities.domain.alert.details'))+" "),_c('span',{staticClass:"icon"},[_vm._v("keyboard_arrow_right")])])]),_c('span',{staticClass:"alert__close icon",on:{"click":function($event){_vm.showWarning = false}}},[_vm._v("close")])]):_vm._e(),_c('Table',{staticClass:"domains__table",attrs:{"columns":_vm.headers,"show-selected-actions":false,"items":_vm.items,"is-data-loaded":_vm.isLoaded},on:{"delete-selected-items":_vm.openDeletionModal},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"table__actions"},[(!item.primary)?_c('Button',{attrs:{"icon":"delete","type":"icon"},on:{"click":function($event){return _vm.openDeletionModal(item)}}}):_vm._e()],1)]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"domains__chips"},[(item.confirmed)?_c('chip',{attrs:{"type":"success","icon":"check"}},[_vm._v(_vm._s(_vm.$t('entities.domain.tooltips.isConfirmed')))]):_c('chip',{attrs:{"type":"error","icon":"close"},on:{"click":function($event){return _vm.showTooltips(['domain-confirmed'], {
                                width: 700,
                                'domain-confirmed': { domain: item.name },
                            })}}},[_vm._v(_vm._s(_vm.$t('entities.domain.tooltips.isNotConfirmed'))+" "),_c('span',{staticClass:"material-icons-outlined chip__info-icon"},[_vm._v("error_outline")])]),(item.canBeUsedInEmail)?_c('chip',{attrs:{"type":"success","icon":"check"}},[_vm._v(" "+_vm._s(_vm.$t('entities.domain.tooltips.canBeUsedInEmail'))+" ")]):_c('chip',{attrs:{"icon":"close","type":"error"},on:{"click":function($event){return _vm.showTooltips(['domain-email'], { width: 700, 'domain-email': { domain: item.name } })}}},[_vm._v(" "+_vm._s(_vm.$t('entities.domain.tooltips.cantBeUsedInEmail'))+" "),_c('span',{staticClass:"material-icons-outlined chip__info-icon"},[_vm._v("error_outline")])]),(item.email)?_c('chip',{attrs:{"icon":"email"}},[_vm._v(" "+_vm._s(_vm.$t('entities.domain.tooltips.asEmail'))+" ")]):_vm._e()],1)]}},{key:"item.type",fn:function(ref){
                            var item = ref.item;
return [_c('div',{staticClass:"domains__primary-column"},[(item.primary)?_c('Button',{staticClass:"domains__status-button -active"},[_vm._v("Primary")]):_c('Button',{staticClass:"domains__status-button",attrs:{"disabled":!item.confirmed,"loading":_vm.primaryLoadingName === item.name},on:{"click":function($event){return _vm.openPrimaryModal(item)}},scopedSlots:_vm._u([{key:"hover",fn:function(){return [_vm._v("Set as primary")]},proxy:true}],null,true)},[[_vm._v("Redirecting")]],2)],1)]}},{key:"item.name",fn:function(ref){
                            var item = ref.item;
return [_c('div',{staticClass:"domains__title"},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"header.status",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('lists.columns.status'))+" "),_c('span',{staticClass:"tooltip__btn table-header__tooltip-btn material-symbols-outlined",on:{"click":function($event){return _vm.showTooltips(['domain-confirmed', 'domain-email'], { width: 700 })}}},[_vm._v("error_outline")])]},proxy:true},{key:"header.type",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('lists.columns.type'))+" "),_c('span',{staticClass:"tooltip__btn table-header__tooltip-btn material-symbols-outlined",on:{"click":function($event){return _vm.showTooltips(['domain-type'], { width: 700 })}}},[_vm._v("error_outline")])]},proxy:true}])})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }