<template>
    <tooltip class="sidebar-tooltip">
        <span class="icon sidebar-tooltip__icon -warning tooltip__icon">warning</span>
        <div>
            <div class="sidebar-tooltip__header">
                {{ $t('entities.domain.tooltips.domainStatus') }}
            </div>
            <div class="sidebar-tooltip__text">
                {{
                    domain
                        ? $t('entities.domain.tooltips.confirmedCname.domain', { domain })
                        : $t('entities.domain.tooltips.confirmedCname.general')
                }}
            </div>
            <div class="sidebar-tooltip__items">
                <div v-for="(item, index) in cnames" :key="index" class="sidebar-tooltip__item">
                    <div class="sidebar-tooltip__item-text"><b> CNAME:</b> {{ item.cname }}</div>
                    <div class="sidebar-tooltip__item-text"><b>Value:</b> {{ item.value }}</div>
                </div>
            </div>
            <div v-if="!domain" class="sidebar-tooltip__help">
                {{ $t('entities.domain.tooltips.replaceName') }}
            </div>
        </div>
    </tooltip>
</template>

<script>
import tooltip from '@/components/common/Tooltip';
export default {
    name: 'domainConfirmedTooltip',
    components: { tooltip },
    data() {
        return {
            cnames: [
                {
                    cname: `${this.domain ? this.domain : this.$t('entities.domain.tooltips.domainName')}`,
                    value: 'shops.runshop.io',
                },
                /* {
                    cname: `runshopmail._domainkey.${
                        this.domain ? this.domain : this.$t('entities.domain.tooltips.domainName')
                    }`,
                    value: 'dkim.mail.runshop.io',
                },
                {
                    cname: `runshopmail._domainkey.${
                        this.domain ? this.domain : this.$t('entities.domain.tooltips.domainName')
                    }`,
                    value: 'dkim.mail.runshop.io',
                },
                {
                    cname: `runshopmail._domainkey.${
                        this.domain ? this.domain : this.$t('entities.domain.tooltips.domainName')
                    }`,
                    value: 'dkim.mail.runshop.io',
                },*/
            ],
        };
    },
    props: {
        domain: {
            type: String,
            default: null,
        },
    },
};
</script>

<style lang="scss"></style>
