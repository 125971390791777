export default {
    blocks: {
        generalInformation: 'Общая информация',
        options: 'Опции',
        variations: 'Вариации',
        seo: 'SEO',
        image: 'Картинка',
        media: 'Медиа',
        products: 'Товары',
        catalogs: 'Каталоги',
        pages: 'Страницы',
        catalogProducts: 'Товары в каталоге',
        catalogRelatedProducts: 'Рекоммендованные товары',
        content: 'Содержимое',
        orders: 'Заказы',
        socials: 'Социальные сети',
        properties: 'Характеристики',
    },
    page: {
        title: 'Страница | Страницы',
        fields: {
            title: 'Название',
            heading: 'Заголовок (h1)',
            slug: 'Ссылка',
            pageStatus: 'Статус страницы',
            metaTitle: 'Meta Title',
            metaDescription: 'Meta Description',
            previewImageId: 'Картинка',
            googlePreview: 'Google Preview',
            enabled: 'Включено',
            hidden: 'Скрыто',
        },
        alertText: {
            createAnother: 'Создать ещё одну страницу',
        },
        newItem: 'Новая страница',
    },
    option: {
        title: 'Опция | Опции',
        fields: {
            title: 'Название',
            position: 'Позиция',
            view: 'Вид',
            type: 'Тип',
            unit: 'Единицы измерения',
            values: 'Значения',
        },
        type: {
            string: 'Строка',
            integer: 'Число',
        },
        view: {
            select: 'Селект',
            button: 'Кнопка',
        },
        alerts: {
            description:
                'Опции описывают основную информацию о товаре и, в отличие от характеристик, влияют на создание вариаций и могут влиять на цену',
        },
    },
    property: {
        title: 'Характеристика | Характеристики',
        fields: {
            title: 'Название',
            position: 'Позиция',
            view: 'Вид',
            type: 'Тип',
            usage: 'Использование',
            useInFilter: 'Использование в фильтрах',
            global: 'Глобальная характеристика',
            catalogIds: 'Каталоги',
            unit: 'Единицы измерения',
            values: 'Значения',
            icon: 'Иконка',
        },
        usage: {
            global: 'Глобально',
            catalogs: 'В каталогах',
            none: '-',
        },

        useInFilter: {
            use: 'Использовать в фильтрах',
            doNotUse: 'Не использовать в фильтрах',
        },
        alerts: {
            description:
                'Характеристики описывают дополнительную информацию о товаре и, в отличие от опций, не влияют на создание вариаций и не могут влиять на цену',
        },
    },
    catalog: {
        title: 'Каталог | Каталоги',
        alertText: {
            createAnother: 'Создать ещё один каталог',
        },
        newItem: 'Новый каталог',
        sort: 'Сортировка',
        total: 'Всего',
        selected: 'Выбрано',
        lowerPrice: 'Убывание цены',
        higherPrice: 'Возрастание цены',
        alphabetical: 'По алфавиту',
        manual: 'Ручная сортировка',
        addProducts: 'Добавить товары в каталог',
        addRelatedProducts: 'Добавить рекомендованные товары в каталог',
        showMore: 'Показать больше товаров',
        saving: 'Сохранение...',
    },
    product: {
        title: 'Товар | Товары',
        fields: {
            title: 'Название',
            heading: 'Заголовок (h1)',
            shortDescription: 'Короткое описание',
            listingDescription: 'Описание на странице списка',
            fullDescription: 'Полное описание',
            text: 'Текст',
            catalogs: 'Каталоги',
            labelIds: 'Лейблы',
            imageId: 'Картинка',
            metaTitle: 'Meta Title',
            metaDescription: 'Meta Description',
            enabled: 'Активен',
            disabled: 'Неактивен',
            hidden: 'Скрыто',
            pageStatus: 'Статус страницы',
            slug: 'Ссылка',
            googlePreview: 'Google preview',
            size: 'Размер (см)',
            weight: 'Вес (кг)',
        },
        tooltips: {
            size:
                'Размер должен быть указан в сантиметрах в формате "<b>Д x Ш x В</b>", где<div class="tooltip__list"><div class="tooltip__list-item">Д - это длина</div><div class="tooltip__list-item">Ш - это ширина</div><div class="tooltip__list-item">В - это высота</div></div>',
        },
        weight: {
            metric: 'кг',
        },
        stock: {
            inStock: 'В наличии',
            outOfStock: 'Нет в наличии',
            partially: 'Частично',
        },
        buttons: {
            importButton: 'Импорт',
            exportButton: 'Экспорт',
        },
        alertText: {
            createAnother: 'Вы можете создать ещё один товар',
            or: 'или',
            addImages: 'добавить изображения к вариациям текущего товара.',
        },
        size: {
            metric: 'см',
            width: 'Ш',
            length: 'Д',
            height: 'В',
        },
        import: {
            title: 'Источник импорта',
            options: {
                shopify: 'Импортировать из Shopify',
                runshop: 'Импортировать из Runshop',
            },
            tooltips: {
                failed: 'Импорт был прерван из-за ошибки',
                success: 'Импорт завершён успешно',
            },
            buttons: {
                tryAgain: 'Попробовать снова',
                close: 'Закрыть',
            },
        },
        newItem: 'Новый товар',
        total: 'Всего',
        next: 'Следующий товар',
        prev: 'Предыдущий товар',
        noImages:
            'Для этого товара не загружено изображений<br/>Вы можете загрузить их ниже, в блоке "Медиа" этой страницы',
        avalible: 'Доступно',
        pcs: 'шт.',
    },
    productVariation: {
        title: 'Вариация | Вариации',
        fullPrice: 'Цена',
        tooltips: {
            discount: 'Скидка может быть указана в процентах (10%, 3.14%) или в абсолютных значениях (5, 10, 100)',
            quantity: 'Пустое значение будет равно бесконечному количеству',
            pictures: 'Вы сможете добавить картинки к вариациям текущего товара после его сохранения',
        },
    },
    label: {
        title: 'Лейбл | Лейблы',
        fields: {
            title: 'Название',
            position: 'Позиция',
            color: 'Цвет',
        },
    },
    review: {
        title: 'Отзыв | Отзывы',
        fields: {
            productId: 'Товар',
            createdAt: 'Дата Создания',
            rating: 'Рейтинг',
            name: 'Клиент',
            email: 'Email',
            text: 'Текст',
        },
    },
    order: {
        title: 'Заказ | Заказы',
        fields: {
            name: 'Имя',
            email: 'Email',
            phone: 'Телефон',
            comment: 'Комментарий',
            orderComment: 'Комментарий',
            manager: 'Менеджер',
            state: 'Статус',
            stateId: 'Статус',
            payment: 'Оплата',
            createdAt: 'Создан',
            isOtherReceiver: 'Получатель другой человек',
            receiverName: 'Имя получателя',
            receiverPhone: 'Телефон получателя',
            customerComment: 'Комментарий пользователя',
            receiverComment: 'Комментарий получателя',
        },
        deliveryAddress: {
            fields: {
                country: 'Страна',
                region: 'Регион',
                address: 'Адрес',
                apartment: 'Квартира',
                phone: 'Телефон',
                city: 'Город',
                postalCode: 'Почтовый индекс',
            },
        },
        statuses: {
            open: 'Открыт',
            archived: 'Архивирован',
            canceled: 'Отменён',
        },
        paymentMethods: {
            cash: 'Наличными',
            online: 'Онлайн',
        },
        addProduct: 'Добавить товар в заказ',
        removeSelected: 'Убрать выбранные',
        delivery: 'Доставка',
        pickup: 'Самовывоз',
        pickupUnavailable: 'В данный момент самовывоз недоступен',
        shippingMethod: 'Способ доставки',
        information: 'Информация',
        client: 'Клиент',
        order: 'Заказ',
        subtotal: 'Цена',
        total: 'Всего',
        weight: 'Вес',
        payment: 'Наличными при получении',
        unavailable: {
            pickup: 'Самовывоз в данный момент недоступен',
            delivery: 'Доставка в выбранную зону в данный момент недоступна',
            shippingZone:
                'Выбранная зона доставки была удалена. Если вы хотите обновить товар - пожалуйста, выберите другую',
            location:
                'Выбранный пункт самовывоза был удален. Если вы хотите обновить товар - пожалуйста, выберите другой',
            rate: 'Выбранный тариф был удален. Если вы хотите обновить товар - пожалуйста, выберите другой',
        },
        state: {
            title: 'Статус заказа | Статусы заказа',
            fields: {
                publicName: 'Публичное имя',
                serviceName: 'Имя',
                position: 'Позиция',
                color: 'Цвет',
            },
        },
        errors: {
            header: 'Следующие обязательные поля не были корректно заполнены:',
            block: 'В блоке "{block}"',
            rightBlock: 'В блоке "{block}" справа',
        },
    },
    location: {
        title: 'Пункт самовывоза | Пункты самовывоза',
        commonSettings: {
            title: 'Общие настройки',
            fields: {
                name: 'Название',
                country: 'Страна',
                region: {
                    region: 'Регион',
                    province: 'Провинция',
                    stateTerritory: 'Штат/территория',
                    state: 'Штат',
                    governorate: 'Провинция',
                    county: 'Округ',
                    prefecture: 'Префектура',
                    emirate: 'Эмират',
                },
                address: 'Адрес',
                apartment: 'Квартира',
                phone: 'Телефон',
                city: 'Город',
                postalCode: {
                    postalCode: 'Почтовый индекс',
                    postcode: 'Почтовый индекс',
                    pinCode: 'PIN-код',
                    zipCode: 'ZIP-код',
                },
            },
        },
        pickupSettings: {
            title: 'Настройки самовывоза',
            fields: {
                pickupExpectedTime: 'Ожидаемое время получения',
                pickupNotification: 'Уведедомление о готовности заказа к выдаче',
            },
        },
        hint: 'Если вы предоставляете возможность самовывоза, вы можете добавить адреса своих складов или магазинов',
    },
    shippingZone: {
        title: 'Зона доставки | Зоны доставки',
        fields: {
            name: 'Название зоны',
        },
        hint:
            'Укажите в какие страны и регионы вы осуществляете доставку и выберите стоимость доставки согласно вашим условиям',
    },
    rate: {
        title: 'Тариф | Тарифы',
        fields: {
            name: 'Название тарифа',
            price: 'Цена',
            shippingSpeed: 'Срок доставки',
            minPrice: 'Минимальная цена',
            maxPrice: 'Максимальная цена',
            minWeight: 'Минимальный вес',
            maxWeight: 'Максимальный вес',
        },
        shippingSpeed: {
            day: '1 день',
            upTo2Days: 'До 2 дней',
            upTo3Days: 'До 3 дней',
            upTo4Days: 'До 4 дней',
            upTo5Days: 'До 5 дней',
            upTo6Days: 'До 6 дней',
            upTo1Week: 'До 1 недели',
            upTo2Weeks: 'До 2 недель',
            upTo3Weeks: 'До 3 недель',
            upTo1Month: 'До 1 месяца',
        },
        basedOnWeight: 'На основе веса',
        basedOnPrice: 'На основе стоимости',
        addConditions: 'Добавить условия',
        removeConditions: 'Убрать условия',
    },
    seo: {
        tagManager: {
            title: 'Tag Manager',
            fields: {
                googleAnalytics: 'Google Analytics',
                googleVerification: 'Google Verification Code',
            },
        },
        metaTags: {
            title: 'Meta-теги',
        },
        robots: {
            title: 'Robots.txt',
        },
        redirect: {
            title: 'Редирект | Редиректы',
            manually: 'Добавить редиректы вручную',
            upload: 'Загрузить редиректы из файла',
            tooltips: {
                importFormat:
                    'Используйте файл с расширением .csv, содержащий две колонки (from, to), разделённых запятыми\n' +
                    '<a class="tooltip__link" href="/files/sample_redirects.csv" download>(Скачать образец)</a>.<br />' +
                    '<div class="tooltip__important">Важно, используйте только относительные ссылки:<br />' +
                    'Неправильно - https://domain.com/old_url, https://domain.com/new_url<br />' +
                    'Правильно - /old_url, /new_url</div>',
                importSuccess:
                    ' Редиректы импортированы успешно: <br /><div class="tooltip__important">Создано: {created}<br /> Обновлено: {updated}</div>',
            },
            example: 'Например: {val}',
        },
    },
    user: {
        title: 'Пользователь | Пользователи',
        customerTitle: 'Покупатель | Покупатели',
        fields: {
            email: 'Email',
            password: 'Пароль',
            repeatPassword: 'Повторите пароль',
            role: 'Роль',
            enabled: 'Активен',
            name: 'Имя',
            birthDate: 'Дата рождения',
            registeredAt: 'Дата регистрации',
            phone: 'Номер телефона',
            deliveryAddresses: 'Адрес доставки',
        },
    },
    image: {
        title: 'Изобаржение | Изображения',
        fields: {
            name: 'Название',
            alt: 'Alt текст',
            title: 'Title текст',
        },
        updateCollection: 'Обновить коллекцию изображений',
        properties: 'Свойства картинки',
        change: 'Изменить картинку',
        upload: 'Загрузить',
        formats: 'JPG, PNG',
        resolution: 'оптимальный размер: 1000x1000px',
        loading: 'Загрузка',
        size: 'не более 500kb',
        reset: 'Сбросить',
        close: 'Закрыть',
        showMore: 'Загрузить ещё',
    },
    payment: {
        title: 'Методы оплаты',
        add: 'Добавить платёжную систему',
        tooltips: {
            networkGenius: {
                link: 'Показать дополнительные настройки для работы с этой платёжной системой',
                block1:
                    '<h2>Инструкция по настройке платежной системы Network genius на Runshop</h2>' +
                    '<p>Для настройки платежной системы Network Genius (<a href="https://www.network.ae/en">https://www.network.ae/en</a>) важно выполнить следующие действия:</p>' +
                    '<ol>' +
                    '<li>Подписание договора с провайдером и получения доступа к производственному личному кабинету.</li>' +
                    '<li>Получение настроек</li>' +
                    '<li>Настройка  обновления статусов и headerKey</li>' +
                    '</ol>' +
                    '<h4 class="-margin-top">2. Получение настроек</h4>' +
                    '<p>reference - Settings (Правый верхний угол) -> Organizational hierarchy -> Выбрать свою компанию</p>',
                block2:
                    '<p>-  secretKey - нужно создать новый сервисный аккаунт</p>' +
                    '<p>Settings -> Integrations -> Service Account->New</p>',
                block3:
                    '<p>Заполняем данные</p>' +
                    '<ol>' +
                    '<li>Name - Название компании</li>' +
                    '<li>Description - Описание компании</li>' +
                    '</ol>' +
                    '<p>Нажимаем сохранить</p>' +
                    '<p class="-margin-top">В списке получаем Service Account находится secretKey (Раскрываем содержимое, копируем и вставляем в параметр secretKey)</p>',
                block4:
                    '<h4>3. Настройка  обновления статусов и headerKey</h4>' +
                    '<p>Для получения уведомлений на сайте о изменении статусов платежей необходимо настроить Webhook</p>' +
                    '<p>Для этого переходим Settings->Integrations->View All -> Webhooks->New</p>' +
                    '<ol>' +
                    '<li>Name - произвольное, например название компании</li>' +
                    '<li>Url - указываем в формате https://domainname.com/payment/notify, <b>domainname.com нужно заменить на Ваш домен</b></li>' +
                    '<li><b>headerKey</b> - <b>Secure</b></li>' +
                    '<li><b>headerValue</b> - произвольное значение, это и есть <b>headerToken</b></li>' +
                    '<li>Нажимаем сохранить</li>' +
                    '</ol>',
                block5:
                    '<p class="-margin-top">После этого необходимо перенести данные и произвести тестирование платежа</p>',
            },
        },
        countries: {
            availableIn: 'Доступно в странах',
            andOther: 'и другие... (всего {count})',
            editAvailable: 'Редактировать доступные зоны',
            worldwide: 'По всему миру',
            choose: 'Выберите страны',
        },
        systems: {
            paymentCashOnDelivery: 'Наличными при получении',
            paymentStripe: 'Stripe',
            paymentNetworkGenius: 'NetworkGenius',
            paymentYooKassa: 'YooKassa',
        },
    },
    erp: {
        title: 'ERP системы',
    },
    notifications: {
        title: 'Уведомления',
    },
    domain: {
        title: 'Домен | Домены',
        fields: {
            name: 'Название домена',
            primary: 'Установить как primary',
        },
        settings: 'Настройки домена',
        modalCaption: 'Новый домен будет автоматически переправлять на primary-домен',
        alert: {
            issues: 'С одним или более доменами обнаружены неполадки',
            details: 'Детали',
        },
        primaryModal: {
            header: 'Изменить primary-домен',
            text:
                'Хотите сделать {domain} основным доменом? \n' +
                'Вы будете перенаправлены на страницу авторизации для нового домена, где вам необходимо будет войти еще раз.',
        },
        tooltips: {
            primaryCaption: 'Отображается в строке поиска, когда пользователи ищут онлайн-магазин',
            redirectingCaption: 'Направляет пользователей на основной домен онлайн-магазина',
            emailCname: {
                general: 'Чтобы использовать домен как email, добавьте на него следующую CNAME-запись:',
                domain: 'Чтобы использовать {domain} как email, добавьте на него следующую CNAME-запись:',
            },

            confirmedCname: {
                general: 'Если домен не подтверждён, добавьте ему следующие CNAME-записи:',
                domain: 'Чтобы подтвердить и использовать домен {domain}, добавьте ему следующие CNAME-записи:',
            },
            canBeUsedInEmail: 'Может быть использован как email',
            cantBeUsedInEmail: 'Не может быть использован как email',
            isConfirmed: 'Подтверждён',
            isNotConfirmed: 'Не подтверджён',
            createDomain:
                'Домен не найден. Добавьте этот домен на вкладке {0}, затем добавьте на него следующую CNAME-запись, чтобы использовать его как email:',
            asEmail: 'Используется как email',
            domainStatus: 'Статус домена',
            emailStatus: 'Статус email',
            primaryDomain: 'Primary-домен',
            redirectingDomain: 'Redirecting-домен',
            types: 'Типы доменов',
            domainName: '{имя домена}',
            replaceName: 'Замените {имя домена} на имя вашего домена',
        },
    },

    email: {
        fields: {
            subject: 'Тема',
            content: 'Содержание',
        },
        preview: 'Предпросмотр',
    },

    lead: {
        title: 'Заявка',
        fields: {
            name: 'Имя',
            phone: 'Телефон',
            email: 'Email',
            comment: 'Комментарий',
            manager: 'Менеджер',
            state: 'Статус',
            text: 'Сообщение от клиента',
            type: 'Тип',
            product: 'Товар',
            createdAt: 'Создано',
        },
        state: {
            open: 'Открыта',
            archived: 'Архивирована',
            canceled: 'Отменена',
        },
    },

    billing: {
        active: 'Активная подписка',
        cancel: 'Отменить подписку',
    },

    article: {
        title: 'Статья',
        fields: {
            heading: 'Heading (h1)',
            previewImageId: 'Картинка',
            authorId: 'Автор',
            categoryId: 'Категория',
            publisherId: 'Опубликовавший',
            enabled: 'Включено',
            hidden: 'Скрыто',
            noIndex: 'Отключить индексацию',
            type: 'Тип',
            title: 'Название',
            annotation: 'Тизер поста',
            publishedAt: 'Дата публикации',
            tagIds: 'Теги',
            metaTitle: 'Meta title',
            metaDescription: 'Meta description',
            slug: 'Ссылка',
            googlePreview: 'Google Preview',
        },
        alertText: {
            createAnother: 'Создать ещё одну статью',
        },
        pageStatus: 'Статус страницы',
        newItem: 'Новая статья',
    },

    category: {
        title: 'Категория',
        fields: {
            heading: 'Heading (h1)',
            previewImageId: 'Картинка',
            enabled: 'Включено',
            hidden: 'Скрыто',
            noIndex: 'Отключить индексацию',
            title: 'Название',
            metaTitle: 'Meta title',
            metaDescription: 'Meta description',
            slug: 'Ссылка',
            googlePreview: 'Google Preview',
        },
        alertText: {
            createAnother: 'Создать ещё одну категорию',
        },
        pageStatus: 'Статус страницы',
        newItem: 'Новая категория',
    },

    author: {
        title: 'Автор',
        fields: {
            name: 'Имя',
            type: 'Тип',
            photoId: 'Фото',
            bio: 'О себе',
            jobTitle: 'Должность',
            website: 'Ссылка на сайт',
            facebookUrl: 'Ссылка на Facebook',
            instagramUrl: 'Ссылка на Instagram',
            xUrl: 'Ссылка на X',
            linkedInUrl: 'Ссылка на LinkedIn',
            tiktokUrl: 'Ссылка на Tiktok',
            pinterestUrl: 'Ссылка на Pinterest',
        },
        alertText: {
            createAnother: 'Создать ещё одного автора',
        },
        newItem: 'Новый автор',
        type: {
            all: 'Все',
            organization: 'Организация',
            person: 'Индивидуальный автор',
        },
    },
    comment: {
        title: 'Комментарий',
        fields: {
            name: 'Имя',
            email: 'Email',
            text: 'Текст',
            articleId: 'Статья',
            createdAt: 'Создан',
        },
    },
    tag: {
        title: 'Тег',
        fields: {
            name: 'Имя',
        },
    },

    save: 'Сохранить',
    accept: 'Принять',
    remove: 'Удалить',
    delete: 'Удалить',
    deleteAll: 'Удалить все',
    edit: 'Редактировать',
    confirm: 'Подтвердить',
    add: 'Добавить',
    back: 'Назад',
    view: 'Посмотреть',
    duplicate: 'Копировать',
    editInBuilder: 'Изменить в билдере',
    createRedirect: 'Cоздать редирект для',
    settings: 'Настройки',
    customize: 'Пользовательские настройки',
    padding: {
        resetTop: 'Убрать верхний отступ',
        resetBottom: 'Убрать нижний отступ',
    },
};
