<template>
    <div>test</div>
</template>

<script>
export default {
    name: 'SentryTest',
    created() {
        throw new Error('Another sentry error to get rid of duplication');
    },
};
</script>

<style lang="scss"></style>
