<template>
    <component :is="tagName" :class="['heading', `text-${type}`]"><slot></slot></component>
</template>

<script>
export default {
    name: 'Heading',

    inheritAttrs: false,

    props: {
        type: {
            type: String,
            default: 'h2',
            validator: (value) =>
                ['h1', 'h2', 'h3', 'h4', 'body-1', 'body-2', 'subtitle-1', 'subtitle-2'].includes(value),
        },
    },

    computed: {
        tagName() {
            return {
                h1: 'h1',
                h2: 'h2',
                h3: 'h3',
                h4: 'h4',
                'body-1': 'div',
                'body-2': 'div',
                'subtitle-1': 'div',
                'subtitle-2': 'div',
            }[this.type];
        },
    },
};
</script>

<style lang="scss" scoped></style>
