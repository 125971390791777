export default {
    blocks: {
        generalInformation: 'General Information',
        options: 'Options',
        variations: 'Variations',
        seo: 'SEO',
        image: 'Image',
        media: 'Media',
        products: 'Products',
        catalogs: 'Catalogs',
        pages: 'Pages',
        catalogProducts: 'Products in catalog',
        catalogRelatedProducts: 'Recommended products',
        showMore: 'Show more products',
        content: 'Content',
        orders: 'Orders',
        socials: 'Socials',
        properties: 'Characteristics',
    },
    page: {
        title: 'Page | Pages',
        fields: {
            title: 'Name',
            heading: 'Headline (h1)',
            slug: 'URL handler',
            pageStatus: 'Page Status',
            metaTitle: 'Meta Title',
            metaDescription: 'Meta Description',
            previewImageId: 'Preview Image',
            googlePreview: 'Google Preview',
            enabled: 'Enabled',
            hidden: 'Hidden',
        },
        alertText: {
            createAnother: 'Create another page',
        },
        newItem: 'New Page',
    },
    option: {
        title: 'Option | Options',
        fields: {
            title: 'Name',
            position: 'Position',
            view: 'View',
            type: 'Type',
            unit: 'Unit',
            values: 'Values',
        },
        type: {
            string: 'Text',
            integer: 'Number',
        },
        view: {
            select: 'Select',
            button: 'Button',
        },
        alerts: {
            description:
                "Details describe main info about product and, unlike characteristics, do affect it's variations and can affect price",
        },
    },
    property: {
        title: 'Characteristic | Characteristics',
        fields: {
            title: 'Name',
            position: 'Position',
            unit: 'Unit',
            usage: 'Usage',
            useInFilter: 'Use in filter',
            catalogIds: 'Catalogs',
            global: 'Global detail',
            values: 'Values',
            icon: 'Icon',
        },

        usage: {
            global: 'Global',
            catalogs: 'Catalogs',
            none: '-',
        },

        useInFilter: {
            use: 'Use in filter',
            doNotUse: 'Do not use in filter',
        },

        alerts: {
            description:
                "Characteristics describe additional info about product and, unlike options, do not affect it's variations and cannot affect price",
        },
    },
    catalog: {
        title: 'Catalog | Catalogs',
        alertText: {
            createAnother: 'Create another catalog',
        },
        newItem: 'New Catalog',
        sort: 'Sorting on page',
        total: 'Total items',
        selected: 'Selected',
        lowerPrice: 'Lower price',
        higherPrice: 'Higher price',
        alphabetical: 'Alphabetical',
        manual: 'Manual sort',
        addProducts: 'Add Products to Catalog',
        addRelatedProducts: 'Add recommended Products to Catalog',
        showMore: 'Show more products',
        saving: 'Saving...',
    },
    product: {
        title: 'Product | Products',
        fields: {
            title: 'Name',
            heading: 'Headline (h1)',
            shortDescription: 'Short Description',
            listingDescription: 'Listing Description',
            fullDescription: 'Full Description',
            text: 'Text',
            catalogs: 'Catalogs',
            labelIds: 'Labels',
            imageId: 'Image',
            metaTitle: 'Meta Title',
            metaDescription: 'Meta Desciption',
            enabled: 'Enabled',
            disabled: 'Disabled',
            hidden: 'Hidden',
            pageStatus: 'Page Status',
            slug: 'URL handler',
            googlePreview: 'Google Preview',
            size: 'Size (cm)',
            weight: 'Weight (kg)',
        },
        tooltips: {
            size:
                'Size must be specified in centimeters in format of "<b>L x W x H</b>", where <div class="tooltip__list"><div class="tooltip__list-item">L is for length</div><div class="tooltip__list-item">W is for width</div><div class="tooltip__list-item">H is for height</div></div>',
        },
        weight: {
            metric: 'kg',
        },
        stock: {
            inStock: 'In stock',
            outOfStock: 'Out of Stock',
            partially: 'Partially',
        },
        buttons: {
            importButton: 'Import',
            exportButton: 'Export',
        },
        alertText: {
            createAnother: 'Create another product',
            or: 'or',
            addImages: 'add pictures to current product variations.',
        },
        size: {
            metric: 'cm',
            width: 'W',
            length: 'L',
            height: 'H',
        },
        import: {
            title: 'Source of import',
            options: {
                shopify: 'Import from Shopify',
                runshop: 'Import from Runshop',
            },
            tooltips: {
                failed: 'Import was not finished due to an error',
                success: 'Import is completed successfully',
            },
            buttons: {
                tryAgain: 'Try again',
                close: 'Close',
            },
        },
        newItem: 'New Product',
        total: 'Total items',
        next: 'Next item',
        prev: 'Previous item',
        noImages: 'No images found for this product<br/>You can upload them with the block "Media" of this page',
        avalible: 'Avalible',
        pcs: 'pcs',
    },
    productVariation: {
        title: 'Variation | Variations',
        fullPrice: 'Full Price',
        tooltips: {
            discount: 'Discount may be specified in percent (e.g. 10%, 3.14%) or in absolute value (e.g. 5, 10, 100)',
            quantity: 'Empty value will be equal to infinite quantity',
            pictures: "You will be able to add pictures to current product's variations after saving the product",
        },
    },
    label: {
        title: 'Label | Labels',
        fields: {
            title: 'Name',
            position: 'Position',
            color: 'Color',
        },
    },
    review: {
        title: 'Review | Reviews',
        fields: {
            productId: 'Product',
            createdAt: 'Date',
            rating: 'Rating',
            name: 'Client',
            email: 'Email',
            text: 'Text',
        },
    },
    order: {
        title: 'Order | Orders',
        fields: {
            name: 'Name',
            email: 'Email',
            phone: 'Phone',
            comment: 'Comment',
            orderComment: 'Comment',
            manager: 'Manager',
            state: 'Status',
            stateId: 'Status',
            payment: 'Payment',
            createdAt: 'Created At',
            isOtherReceiver: 'The recipient is another person',
            receiverName: 'Recipient name',
            receiverPhone: 'Recipient phone',
            customerComment: 'Customer comment',
            receiverComment: 'Receiver comment',
        },
        deliveryAddress: {
            fields: {
                country: 'Country',
                region: 'Region',
                address: 'Address',
                apartment: 'Apartment, suite, etc.',
                phone: 'Phone',
                city: 'City',
                postalCode: 'Postal Code',
            },
        },
        statuses: {
            open: 'Open',
            archived: 'Archived',
            canceled: 'Canceled',
        },
        paymentMethods: {
            cash: 'Cash',
            online: 'Online',
        },
        addProduct: 'Add product to order',
        removeSelected: 'Remove selected',
        delivery: 'Delivery',
        pickup: 'Pickup',
        pickupUnavailable: 'Pickup is currently unavailable',
        shippingMethod: 'Shipping Method',
        information: 'Information',
        client: 'Client',
        order: 'Order',
        subtotal: 'Subtotal',
        total: 'Total',
        weight: 'Weight',
        payment: 'Cash on delivery',
        unavailable: {
            pickup: 'Pickup is currently unavailable',
            delivery: 'Delivery to selected zone is currently unavailable',
            shippingZone: 'Selected shipping zone was deleted. If you want to update order, please choose another',
            location: 'Selected location was deleted. If you want to update order, please choose another',
            rate: 'Selected rate was deleted. If you want to update order, please choose another',
        },
        state: {
            title: 'Order Status | Order Statuses',
            fields: {
                publicName: 'Public name',
                serviceName: 'Name',
                position: 'Position',
                color: 'Color',
            },
        },
        errors: {
            header: 'These fields were not filled in or were filled in incorrectly:',
            block: 'In block {block}',
            rightBlock: 'In block {block} on the right sidebar',
        },
    },
    location: {
        title: 'Location | Locations',
        commonSettings: {
            title: 'Common Settings',
            fields: {
                name: 'Name',
                country: 'Country',
                region: {
                    region: 'Region',
                    province: 'Province',
                    stateTerritory: 'State/territory',
                    state: 'State',
                    governorate: 'Governorate',
                    county: 'County',
                    prefecture: 'Prefecture',
                    emirate: 'Emirate',
                },
                address: 'Address',
                apartment: 'Apartment, suite, etc.',
                phone: 'Phone',
                city: 'City',
                postalCode: {
                    postalCode: 'Postal code',
                    postcode: 'Postcode',
                    pinCode: 'PIN code',
                    zipCode: 'ZIP code',
                },
            },
        },
        pickupSettings: {
            title: 'Pickup Settings',
            fields: {
                pickupExpectedTime: 'Expected pickup time',
                pickupNotification: 'Order ready for pickup notification',
            },
        },
        hint: 'If you have the option of self-pickup, you can add locations of your warehouses or stores',
    },
    shippingZone: {
        title: 'Shipping Zone | Shipping Zones',
        fields: {
            name: 'Zone name',
        },
        hint:
            'Specify which countries and regions you deliver to and select delivery prices according to your conditions',
    },
    rate: {
        title: 'Rate | Rates',
        fields: {
            name: 'Rate name',
            price: 'Price',
            shippingSpeed: 'Shipping speed',
            minPrice: 'Minimum price',
            maxPrice: 'Maximum price',
            minWeight: 'Minimum weight',
            maxWeight: 'Maximum weight',
        },
        shippingSpeed: {
            day: '1 day',
            upTo2Days: 'Up to 2 days',
            upTo3Days: 'Up to 3 days',
            upTo4Days: 'Up to 4 days',
            upTo5Days: 'Up to 5 days',
            upTo6Days: 'Up to 6 days',
            upTo1Week: 'Up to 1 week',
            upTo2Weeks: 'Up to 2 weeks',
            upTo3Weeks: 'Up to 3 weeks',
            upTo1Month: 'Up to 1 month',
        },
        basedOnWeight: 'Based on item weight',
        basedOnPrice: 'Based on order price',
        addConditions: 'Add conditional pricing',
        removeConditions: 'Remove conditional pricing',
    },
    seo: {
        tagManager: {
            title: 'Tag Manager',
            fields: {
                googleAnalytics: 'Google Analytics',
                googleVerification: 'Google Verification Code',
            },
        },
        metaTags: {
            title: 'Meta Tags',
        },
        robots: {
            title: 'Robots.txt',
        },
        redirect: {
            title: 'Redirect | Redirects',
            manually: 'Add redirects manually',
            upload: 'Upload redirects from file',
            tooltips: {
                importFormat:
                    'Use a CSV file with two columns (from, to) separated by commas' +
                    ' <a class="tooltip__link" href="/files/sample_redirects.csv" download>(Download a Sample)</a>.<br />' +
                    '<div class="tooltip__important">Important, use only relative links:<br />' +
                    'Wrong - https://domain.com/old_url, https://domain.com/new_url<br />' +
                    'Right - /old_url, /new_url</div>',
                importSuccess:
                    'Redirects were imported successfully: <br /><div class="tooltip__important">Created: {created}<br /> Updated: {updated}</div>',
            },
            example: 'Example: {val}',
        },
    },
    user: {
        title: 'User | Users',
        customerTitle: 'Customer | Customers',
        fields: {
            email: 'Email',
            password: 'Password',
            repeatPassword: 'Repeat Password',
            role: 'Role',
            name: 'Name',
            birthDate: 'Birth Date',
            registeredAt: 'Registered at',
            phone: 'Phone',
            deliveryAddresses: 'Delivery Addresses',
            enabled: 'Enabled',
        },
    },
    image: {
        title: 'Image | Images',
        fields: {
            name: 'Name',
            alt: 'Alt text',
            title: 'Title text',
        },
        properties: 'Image Properties',
        updateCollection: 'Update Image Collection',
        change: 'Change Image',
        upload: 'Upload',
        loading: 'Loading',
        formats: 'JPG, PNG',
        resolution: 'recommended size: 1000x1000px',
        size: 'no more than 500kb',
        reset: 'Reset',
        close: 'Close',
        showMore: 'Show More Images',
    },
    payment: {
        title: 'Payment methods',
        add: 'Add payment system',
        tooltips: {
            networkGenius: {
                link: 'Show additional instructions to setup this payment system',
                block1:
                    '<h2>Instructions for setting up the Network Genius payment system for Runshop</h2>' +
                    '<p>To set up the Network Genius payment system (<a href="https://www.network.ae/en">https://www.network.ae/en</a>), it is important to perform the following steps:</p>' +
                    '<ol>' +
                    '<li>Signing an agreement with the provider and gaining access to the personal account.</li>' +
                    '<li>Receiving settings</li>' +
                    '<li>Setting up status updates and headerKey</li>' +
                    '</ol>' +
                    '<h4 class="-margin-top">2. Receiving Settings</h4>' +
                    '<p>reference - Settings (Top right corner) -> Organizational hierarchy -> Select your company</p>',
                block2:
                    '<p>-  secretKey - you need to create a new service account</p>' +
                    '<p>Settings -> Integrations -> Service Account->New</p>',
                block3:
                    '<p>Fill out the form</p>' +
                    '<ol>' +
                    '<li>Name - Company name</li>' +
                    '<li>Description - Company description</li>' +
                    '</ol>' +
                    '<p>Press the save button</p>' +
                    '<p class="-margin-top">In the list we get Service Account there is secretKey (Expand the contents, copy and paste into the secretKey parameter)</p>',
                block4:
                    '<h4>3. Setting up status updates and headerKey</h4>' +
                    '<p>To receive notifications on the site about changes in payment statuses, you need to configure Webhook</p>' +
                    '<p>To do this, go to Settings->Integrations->View All -> Webhooks->New</p>' +
                    '<ol>' +
                    '<li>Name - arbitrary, for example company name</li>' +
                    '<li>Url - specify in the format https://domainname.com/payment/notify, <b>domainname.com must be replaced with your domain</b></li>' +
                    '<li><b>headerKey</b> - <b>Secure</b></li>' +
                    '<li><b>headerValue</b> - arbitrary value, this is <b>headerToken</b></li>' +
                    '<li>Press the save button</li>' +
                    '</ol>',
                block5: '<p class="-margin-top">After this, you need to transfer the data and test the payment</p>',
            },
        },
        countries: {
            availableIn: 'Available in',
            andOther: 'and other... ({count} total)',
            editAvailable: 'Edit available zones',
            worldwide: 'Worldwide',
            choose: 'Choose countries',
        },
        systems: {
            paymentCashOnDelivery: 'Cash on delivery',
            paymentStripe: 'Stripe',
            paymentNetworkGenius: 'NetworkGenius',
            paymentYooKassa: 'YooKassa',
        },
    },
    erp: {
        title: 'ERP Systems',
    },
    notifications: {
        title: 'Notifications',
    },
    domain: {
        title: 'Domain | Domains',
        fields: {
            name: 'Domain name',
            primary: 'Set as primary',
        },
        settings: 'Domain settings',
        modalCaption: 'New domain will automatically redirect to the primary',
        alert: {
            issues: 'One or more domains have issues',
            details: 'More details',
        },
        primaryModal: {
            header: 'Change primary domain',
            text:
                'Would you like to make {domain} as a primary domain? The new domain will be forwarded from your current one.\n' +
                'You will be directed to the authorization page for the new domain, where you must log in once more.',
        },
        tooltips: {
            primaryCaption: 'Displayed in the address bar when visitors are browsing Online Store',
            redirectingCaption: 'Directs users to the primary domain for Online Store',
            emailCname: {
                general: 'To use domain as email add next CNAME record to it:',
                domain: 'To use {domain} as email add next CNAME record to it:',
            },

            confirmedCname: {
                general: 'If domain is not confirmed, add next CNAME records to it in order to use it:',
                domain: 'To confirm and use domain {domain}, add next CNAME records to it',
            },
            canBeUsedInEmail: 'Can be used as email',
            cantBeUsedInEmail: "Can't be used as email",
            isConfirmed: 'Confirmed',
            isNotConfirmed: 'Not confirmed',
            createDomain:
                'Domain is not found. Add this domain on the {0} page, then add next CNAME record to it in order to use this domain as email:',
            asEmail: 'Used as email',
            domainStatus: 'Domain status',
            emailStatus: 'Email status',
            primaryDomain: 'Primary domain',
            redirectingDomain: 'Redirecting domain',
            types: 'Type of redirections',
            domainName: '{domainname}',
            replaceName: "Replace {domainname} with your domain's name",
        },
    },
    email: {
        fields: {
            subject: 'Subject',
            content: 'Content',
        },
        preview: 'Preview',
    },
    lead: {
        title: 'Lead',
        fields: {
            name: 'Name',
            phone: 'Phone',
            email: 'Email',
            comment: 'Comment',
            manager: 'Manager',
            state: 'State',
            text: 'Message from client',
            type: 'Type',
            product: 'Product',
            createdAt: 'Created At',
        },
        state: {
            open: 'Open',
            archived: 'Archived',
            canceled: 'Canceled',
        },
    },
    billing: {
        active: 'Active Subscription',
        cancel: 'Cancel subscription',
    },
    article: {
        title: 'Article',
        fields: {
            heading: 'Heading (h1)',
            previewImageId: 'Preview image',
            authorId: 'Author',
            categoryId: 'Category',
            publisherId: 'Publisher',
            enabled: 'Enabled',
            hidden: 'Hidden',
            noIndex: 'Not indexing in search engines',
            type: 'Type',
            title: 'Title',
            annotation: 'Post teaser',
            publishedAt: 'Published at',
            tagIds: 'Tags',
            metaTitle: 'Meta title',
            metaDescription: 'Meta description',
            slug: 'URL handler',
            googlePreview: 'Google Preview',
        },
        alertText: {
            createAnother: 'Create another article',
        },
        pageStatus: 'Page status',
        newItem: 'New Article',
    },
    category: {
        title: 'Category',
        fields: {
            heading: 'Heading (h1)',
            previewImageId: 'Image',
            enabled: 'Enabled',
            hidden: 'Hidden',
            noIndex: 'No index',
            title: 'Title',
            metaTitle: 'Meta title',
            metaDescription: 'Meta description',
            slug: 'URL handler',
            googlePreview: 'Google Preview',
        },
        alertText: {
            createAnother: 'Create another category',
        },
        pageStatus: 'Page status',
        newItem: 'New category',
    },
    author: {
        title: 'Author',
        fields: {
            name: 'Name',
            type: 'Type',
            photoId: 'Photo',
            bio: 'Bio',
            jobTitle: 'Job title',
            website: 'Website link',
            facebookUrl: 'Facebook link',
            instagramUrl: 'Instagram link',
            xUrl: 'X link',
            linkedInUrl: 'LinkedIn link',
            tiktokUrl: 'Tiktok link',
            pinterestUrl: 'Pinterest link',
        },
        alertText: {
            createAnother: 'Create another author',
        },
        newItem: 'New Author',
        type: {
            all: 'All',
            organization: 'Organization',
            person: 'Person',
        },
    },
    comment: {
        title: 'Comment',
        fields: {
            name: 'Name',
            email: 'Email',
            text: 'Text',
            articleId: 'Article',
            createdAt: 'Created at',
        },
    },
    tag: {
        title: 'Tag',
        fields: {
            name: 'Name',
        },
    },
    save: 'Save',
    accept: 'Accept',
    remove: 'Remove',
    delete: 'Delete',
    deleteAll: 'Delete All',
    edit: 'Edit',
    add: 'Add',
    view: 'View',
    confirm: 'Confirm',
    back: 'Back',
    duplicate: 'Duplicate',
    editInBuilder: 'Edit in Builder',
    createRedirect: 'Create a URL redirect for',
    settings: 'Settings',
    customize: 'Customize',
    padding: {
        resetTop: 'Reset top padding',
        resetBottom: 'Reset bottom padding',
    },
};
