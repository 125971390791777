import { SectionFixtureType, SectionGroupFixtureType } from '@/entities/section/fixtures';
// id: 'fixture',
const style1Prev1: SectionFixtureType = {
    preview: require('@/assets/images/sections/sliders/slider-style1prev1.jpg'),
    title: 'Slider',
    group: 'Slider',
    data: {
        id: 'fixture',
        items: [
            {
                id: 'fixture',
                type: 'slide',
                options: {
                    heading: {
                        title: 'The best furniture from manufacturer',
                        color: '#ffffff',
                    },
                    content: {
                        image: '83f069ac-e58a-4f7f-98cb-089c1ec96a89',
                        imageMobile: '83f069ac-e58a-4f7f-98cb-089c1ec96a89',
                        background: null,
                        text: null,
                        colorText: null,
                        tag: 'For your interior',
                        colorTag: '#0055ff',
                    },
                    align: {
                        mobile: '-mobile-text-overlay',
                        desktop: '-desktop-text-left',
                    },
                    button: {
                        isShow: true,
                        styles: 'primary',
                        text: 'See catalog',
                        textColor: '#102cfe',
                        bgColor: '#102cfe',
                        link: null,
                        iconLeft: null,
                        iconRight: null,
                    },
                },
                position: 0,
            },
            {
                id: 'fixture',
                type: 'slide',
                options: {
                    heading: {
                        title: 'Heading H2',
                        color: null,
                    },
                    content: {
                        image: null,
                        imageMobile: null,
                        background: null,
                        text: null,
                        colorText: null,
                        tag: 'Tag',
                        colorTag: null,
                    },
                    align: {
                        mobile: '-mobile-text-overlay',
                        desktop: '-desktop-text-center',
                    },
                    button: {
                        isShow: false,
                        styles: 'primary',
                        text: 'Button',
                        textColor: null,
                        bgColor: null,
                        link: null,
                        iconLeft: null,
                        iconRight: null,
                        type: 'Link',
                        url: null,
                    },
                },
                position: 1,
            },
        ],
        required: false,
        fixed: false,
        type: 'SectionSlider',
        options: {
            section: {
                width: 'full',
                padding: {
                    desktop: {
                        top: 0,
                        right: 0,
                        bottom: 100,
                        left: 0,
                    },
                    tablet: {
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                    },
                    mobile: {
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                    },
                },
            },
        },
    },
};

const style1Prev2: SectionFixtureType = {
    preview: require('@/assets/images/sections/sliders/slider-style1prev2.jpg'),
    title: 'Slider',
    group: 'Slider',
    data: {
        id: 'fixture',
        items: [
            {
                id: 'fixture',
                type: 'slide',
                options: {
                    heading: {
                        title: 'High-quality car detailing',
                        color: '#ffffff',
                    },
                    content: {
                        image: 'f738f9fa-293c-48d0-92da-16e1cb848654',
                        imageMobile: 'f738f9fa-293c-48d0-92da-16e1cb848654',
                        background: null,
                        text:
                            'We will conduct a comprehensive inspection of your car, offer first-class body protection, polishing, as well as dry cleaning of the interior',
                        colorText: '#e8e8e8',
                        tag: '\u2014 10% off on the first visit',
                        colorTag: '#cbff5c',
                    },
                    align: {
                        mobile: '-mobile-text-overlay',
                        desktop: '-desktop-text-center',
                    },
                    button: {
                        isShow: true,
                        styles: 'primary',
                        text: 'Make an appointment',
                        textColor: null,
                        bgColor: null,
                        link: null,
                        iconLeft: null,
                        iconRight: null,
                    },
                },
                position: 0,
            },
            {
                id: 'fixture',
                type: 'slide',
                options: {
                    heading: {
                        title: 'Car detailing',
                        color: '#ffffff',
                    },
                    content: {
                        image: '3f7c5447-3e9f-4cbf-8abe-97897f492a12',
                        imageMobile: null,
                        background: null,
                        text: null,
                        colorText: null,
                        tag: '15% off',
                        colorTag: '#ffffff',
                    },
                    align: {
                        mobile: '-mobile-text-overlay',
                        desktop: '-desktop-text-center',
                    },
                    button: {
                        isShow: true,
                        styles: 'custom',
                        text: 'Button',
                        textColor: null,
                        bgColor: null,
                        link: null,
                        iconLeft: null,
                        iconRight: null,
                    },
                },
                position: 1,
            },
            {
                id: 'fixture',
                type: 'slide',
                options: {
                    heading: {
                        title: 'Heading H2',
                        color: null,
                    },
                    content: {
                        image: null,
                        imageMobile: null,
                        background: null,
                        text: null,
                        colorText: null,
                        tag: 'Tag',
                        colorTag: null,
                    },
                    align: {
                        mobile: '-mobile-text-overlay',
                        desktop: '-desktop-text-center',
                    },
                    button: {
                        isShow: false,
                        styles: 'primary',
                        text: 'Button',
                        textColor: null,
                        bgColor: null,
                        link: null,
                        iconLeft: null,
                        iconRight: null,
                        type: 'Link',
                        url: null,
                    },
                },
                position: 2,
            },
            {
                id: 'fixture',
                type: 'slide',
                options: {
                    heading: {
                        title: 'Heading H2',
                        color: null,
                    },
                    content: {
                        image: null,
                        imageMobile: null,
                        background: null,
                        text: null,
                        colorText: null,
                        tag: 'Tag',
                        colorTag: null,
                    },
                    align: {
                        mobile: '-mobile-text-overlay',
                        desktop: '-desktop-text-center',
                    },
                    button: {
                        isShow: false,
                        styles: 'primary',
                        text: 'Button',
                        textColor: null,
                        bgColor: null,
                        link: null,
                        iconLeft: null,
                        iconRight: null,
                        type: 'Link',
                        url: null,
                    },
                },
                position: 3,
            },
        ],
        required: false,
        fixed: false,
        type: 'SectionSlider',
        options: {
            section: {
                width: 'full',
                padding: {
                    desktop: {
                        top: 0,
                        right: 0,
                        bottom: 100,
                        left: 0,
                    },
                    tablet: {
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                    },
                    mobile: {
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                    },
                },
            },
            container: {
                width: 'full',
                padding: {
                    desktop: null,
                    tablet: null,
                    mobile: null,
                },
                borderRadius: {
                    desktop: 0,
                    tablet: 0,
                    mobile: 0,
                },
                background: 'none',
            },
        },
    },
};

const baseStyle1: SectionFixtureType = {
    preview: require('@/assets/images/sections/sliders/slider-style1prev2.jpg'),
    title: 'Slider',
    group: 'Slider',
    data: {
        id: 'fixture',
        items: [
            {
                id: 'fixture',
                type: 'slide',
                options: {
                    heading: {
                        title: 'High-quality car detailing',
                        color: '#ffffff',
                    },
                    content: {
                        images: {
                            desktop: 'f738f9fa-293c-48d0-92da-16e1cb848654',
                            tablet: null,
                            mobile: null,
                        },
                        text:
                            'We will conduct a comprehensive inspection of your car, offer first-class body protection, polishing, as well as dry cleaning of the interior',
                        colorText: '#e8e8e8',
                    },
                    button: {
                        isShow: true,
                        styles: 'primary',
                        text: 'Make an appointment',
                        textColor: null,
                        bgColor: null,
                        link: null,
                        iconLeft: null,
                        iconRight: null,
                    },
                },
                position: 0,
            },
        ],
        required: false,
        fixed: false,
        type: 'SectionSliderBase',
        options: {
            section: {
                width: 'full',
                padding: {
                    desktop: { top: 0, right: 0, bottom: 0, left: 0 },
                    tablet: { top: 0, right: 0, bottom: 0, left: 0 },
                    mobile: { top: 0, right: 0, bottom: 0, left: 0 },
                },
            },
        },
    },
};

const summary: SectionGroupFixtureType = {
    style1Prev1,
    style1Prev2,
    baseStyle1,
};

export default summary;
