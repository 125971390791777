<template>
    <modal :header="$t('lists.editButton.redirect')" width="432" v-model="model">
        <form class="form">
            <progress-circular class="modal__spinner spinner" v-if="!isDataLoaded"></progress-circular>
            <template v-else>
                <form-item class="form__input" v-bind="form.fields.from.props">
                    <input-text
                        v-bind="form.fields.from.props || {}"
                        @update-field="onUpdateFieldValue"
                        name="from"
                        v-on="getFieldEvents(form.fields.from.events)"
                        v-model="form.from"
                        placeholder="URL"
                    ></input-text>
                </form-item>
                <form-item class="form__input" v-if="!form.subject.name" v-bind="form.fields.to.props">
                    <input-text
                        v-bind="form.fields.to.props || {}"
                        @update-field="onUpdateFieldValue"
                        name="to"
                        v-on="getFieldEvents(form.fields.to.events)"
                        v-model="form.to"
                        placeholder="URL"
                    ></input-text>
                </form-item>
                <form-item class="form__input" v-if="!form.to" v-bind="form.fields.name.props">
                    <Select
                        :items="$options.objectTypes"
                        @change="onChangeObjectType"
                        v-model="form.subject.name"
                        @input="handleNameInput"
                        v-bind="form.fields.name.props || {}"
                        @update-field="onUpdateFieldValue"
                        name="name"
                        v-on="getFieldEvents(form.fields.name.events)"
                        clearable
                        placeholder="Select"
                    ></Select>
                </form-item>
                <form-item
                    class="form__input redirects__select-entity"
                    v-if="form.subject.name && isDataLoaded"
                    v-bind="form.fields.id.props"
                >
                    <div class="select-entity__input">
                        <search-form
                            :label="form.subject.name | capitalize"
                            v-model="form.subject.id"
                            :searchable="form.subject.name"
                            :fields="['title']"
                            only-model
                            v-bind="form.fields.id.props || {}"
                            @update-field="onUpdateFieldValue"
                            name="id"
                            :initially-selected="selectedEntity"
                            autocomplete
                        ></search-form>
                        <Button type="icon" @click="removeSubject" icon="delete"></Button>
                    </div>
                </form-item>
                <div class="form__input">
                    <Button icon="check" @click="updateRedirect">
                        {{ $t('entities.save') }}
                    </Button>
                </div>
            </template>
        </form>
    </modal>
</template>

<script>
import Modal from '@/components/common/Modal';
import { proxyModelMixin } from '@/mixins/proxyModelMixin';
import RedirectService, { OBJECT_TYPES } from '@/services/RedirectService';
import EventEmitter from '@/helpers/eventEmitter';
import Redirect from '@/entities/Redirect';
import { validateField } from '@/entities/seo/redirectList.fields';
import { debounce } from 'lodash';
import FormItem from '@/components/form/item.vue';
import Button from '@/components/common/Button';
import SearchForm from '@/components/search/SearchForm';
import PageService from '@/services/PageService';
import ProductService from '@/services/ProductService';
import CatalogService from '@/services/CatalogService';
import ProgressCircular from '@/components/common/ProgressCircular';

export default {
    name: 'RedirectModal',
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        redirectId: {
            type: String,
            default: '',
        },
    },
    components: { ProgressCircular, Modal, FormItem, Button, SearchForm },
    mixins: [proxyModelMixin],
    objectTypes: OBJECT_TYPES,

    data() {
        return {
            isDataLoaded: false,
            form: new Redirect(),
            selectedEntity: null,
            isAvailable: true,
            fieldKeys: Object.keys(new Redirect().fields),
        };
    },

    methods: {
        async getRedirect() {
            const [error, result] = await RedirectService.getOne(this.redirectId);
            if (error) {
                error.alert();
                this.isAvailable = false;
                return;
            }
            this.form = new Redirect(result);
        },

        onChangeObjectType() {
            this.form.to = '';
        },

        async updateRedirect() {
            const isFormValid = this.validateForm();
            if (isFormValid === false) return;
            const [error, result] = await RedirectService.updateOne(this.form.data);
            error ? this.failedHandler(result) : this.successHandler();
        },

        validateForm() {
            let result = true;
            const fieldKeys = Object.keys(this.form.fields);
            fieldKeys.forEach((key) => {
                const errors = validateField(key, this.form.fields, this.form);
                if (errors.length !== 0) result = false;
                this.$set(this.form.fields[key].props, 'errors', errors);
            });
            if (result === false)
                EventEmitter.trigger('show-noty', {
                    type: 'error',
                    text: this.$t('notifications.validation.error'),
                });
            return result;
        },

        handleIdInput(val) {
            this.form.subject.id = val?.id;
        },

        handleNameInput() {
            this.form.subject.id = null;
            this.selectedEntity = null;
        },

        async resetForm() {
            this.form = new Redirect();
        },

        successHandler() {
            this.resetForm();
            EventEmitter.trigger('show-noty', {
                type: 'success',
                text: this.$tc('notifications.created', 1, { entity: this.$tc('entities.seo.redirect.title', 1) }),
            });
            this.$emit('action-success');
            this.model = false;
        },

        failedHandler(response) {
            const children = response.data?.errors?.children;
            if (children) {
                this.fieldKeys.forEach((key) => {
                    const errors = children[key] ? children[key].errors : [];
                    if (errors) this.$set(this.form.fields[key].props, 'errors', errors);
                });
            }
            EventEmitter.trigger('show-noty', {
                type: 'error',
                text: `Error occurred. Status ${response.error.statusCode}: ${response.error.message}`,
            });
        },

        onUpdateFieldValue: debounce(function(payload) {
            const { name } = payload;
            const errors = validateField(name, this.form.fields, this.form);
            this.$set(this.form.fields[name].props, 'errors', errors);
        }, 600),

        getFieldEvents(events) {
            const fieldEvents = {};
            for (const key in events) {
                fieldEvents[key] = events[key]?.bind(this.form);
            }
            return fieldEvents;
        },

        removeSubject() {
            this.form.subject.name = null;
            this.form.subject.id = null;
            this.selectedEntity = null;
        },
    },
    watch: {
        async value(val) {
            if (!val) return;
            if (this.redirectId) {
                this.isDataLoaded = false;
                await this.getRedirect();
                if (this.form.subject.id) {
                    const services = {
                        page: PageService,
                        product: ProductService,
                        catalog: CatalogService,
                    };
                    const service = services[this.form.subject.name];
                    const [error, result] = await service.getOne(this.form.subject.id);
                    if (error) {
                        error.notify();
                        this.selectedEntity = null;
                        this.form.subject = { name: null, id: null };
                        this.isDataLoaded = true;
                        return;
                    }
                    this.selectedEntity = result;
                }
            } else this.resetForm();
            this.isDataLoaded = true;
        },
    },
};
</script>

<style lang="scss">
.redirect-modal {
}
</style>
