import { uuid } from 'vue-uuid';

import ProductOptionEntryValue from './ProductOptionEntryValue.js';
/**
 * Класс экземпляра опции товаров
 */
export default class ProductOptionEntry {
    /**
     *
     * @param {Object}  data - объект данных опции
     */
    constructor({
        id = uuid.v4(),
        optionId = '',
        position = 0,
        values = [],
        valueId = '',
        unit = '',
        title = '',
    } = {}) {
        this.id = id;
        this.optionId = optionId;
        this.position = position;
        this.title = title;
        this.unit = unit;
        this.valueId = valueId;
        this.values = values.map((value) => new ProductOptionEntryValue(value));
    }

    get data() {
        return {
            id: this.id,
            optionId: this.optionId,
            customizable: this.customizable,
            position: this.position,
            values: this.values.map((optionValue) => optionValue.data),
        };
    }
}
