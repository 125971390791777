export default {
    // TEXT
    // default
    text_main: '#FFFFFF',
    text_body1: '#E9EBF2',
    text_body2: '#DCDEE5',
    text_accent: '#00BF6F',
    text_neutral: '#E9EBF2',
    // inverted
    text_main_inverted: '#FFFFFF',
    text_body1_inverted: '#FFFFFF',
    text_body2_inverted: '#FFFFFF',
    text_accent_inverted: '#00BF6F',
    text_neutral_inverted: '#B3B9C7',

    // ACTION
    // primary
    action_primary: '#FF3209',
    action_primary_hover: '#ED3612',
    action_primary_pressed: '#ED3612',
    action_primary_disabled: '#AFB2B1',
    // secondary
    action_secondary: '#FF3209',
    action_secondary_hover: '#ED3612',
    action_secondary_pressed: '#ED3612',
    action_secondary_disabled: '#4B545E',
    // neutral_light
    header_bg: '#302A2B',
    header_bg_mobile: '#C34A3F',
    header_main_menu_bg: '#3C3536',
    header_main_menu_dropdown_bg: '#454545',
    action_neutral_light: '#FFFFFF',
    action_neutral_light_hover: '#FF3209',
    action_neutral_light_pressed: '#FFFFFF',
    action_neutral_light_disabled: '#505359',
    // neutral_medium
    action_neutral_medium: '#838891',
    action_neutral_medium_hover: '#FF3209',
    action_neutral_medium_pressed: '#476659',
    action_neutral_medium_disabled: '#505359',
    // neutral_dark
    action_neutral_dark: '#FFFFFF',
    action_neutral_dark_hover: '#2BE094',
    action_neutral_dark_pressed: '#00BF6F',
    action_neutral_dark_disabled: '#959E9B',
    // system
    action_system: '#E0224F',
    action_system_hover: '#FF265A',
    action_system_pressed: '#BF1D43',
    action_system_disabled: '#4B545E',

    // NEUTRAL
    neutral1: '#302A2B',
    neutral2: '#C34A3F',
    neutral3: '#B13128',
    neutral4: '#FFFFFF',
    neutral5: '#3A3A3A',
    neutral6: '#454545',
    neutral7: '#302A2B',

    // SYSTEM
    success: '#FFFFFF',
    success_bg: '#004D2C',
    warning: '#D99100',
    warning_bg: '#4D3300',
    error: '#F9416D',
    error_bg: '#590015',

    // ACCENT
    accent1: '#80FFCA',
    accent1_bg: '#00804A',
    accent2: '#F5C6AB',
    accent2_bg: '#E55300',
    accent3: '#A8C5FF',
    accent3_bg: '#0D337F',
    accent4: '#FFABBF',
    accent4_bg: '#FFFFFF',

    whatsapp: '#20B051',
};
