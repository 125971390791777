//import SettingItem from '@/entities/setting/SettingItem';
//import { uuid } from 'vue-uuid';
/*
class SettingItem {
    /!**
     *
     * @param {Object}  data - объект данных айтема настроек
     *!/
    constructor({ id, position = 0, type = '', value = '', options = [] } = {}) {
        this.id = id;
        this.position = position;
        this.type = type;
        this.value = value;
        this.options = options;
    }

    get data() {
        return {
            id: this.id,
            type: this.type,
            position: this.position,
            value: this.value,
            options: this.options,
        };
    }
}

const defaultCssVariables = {
    'scrollbar-width': '0px',
    text_main: '#020305',
    text_body1: '#4A5264',
    text_body2: '#67728B',
    text_accent: '#00838F',
    text_neutral: '#98A0B4',

    text_main_inverted: '#FFFFFF',
    text_body1_inverted: '#C7CCD6',
    text_body2_inverted: '#9196A1',
    text_accent_inverted: '#00838F',
    text_neutral_inverted: '#B3B9C7',

    action_primary: '#00838F',
    action_primary_hover: '#4FB3BF',
    action_primary_pressed: '#005662',
    action_primary_disabled: '#E9EAEA',

    action_secondary: '#6FC1CB',
    action_secondary_hover: '#B3DCE1',
    action_secondary_pressed: '#429BA6',
    action_secondary_disabled: '#E9EAEA',

    action_neutral_light: '#FFFFFF',
    action_neutral_light_hover: '#8BE1F0',
    action_neutral_light_pressed: '#007C91',
    action_neutral_light_disabled: '#E9EAEA',

    action_neutral_medium: '#9DADAF',
    action_neutral_medium_hover: '#51C1C1',
    action_neutral_medium_pressed: '#007C91',
    action_neutral_medium_disabled: '#E9EAEA',

    action_neutral_dark: '#2D323D',
    action_neutral_dark_hover: '#1D9FB5',
    action_neutral_dark_pressed: '#007C91',
    action_neutral_dark_disabled: '#E9EAEA',

    action_system: '#E64A19',
    action_system_hover: '#F88D50',
    action_system_pressed: '#AC0800',
    action_system_disabled: '#E9EAEA',

    neutral1: '#252932',
    neutral2: '#2D323D',
    neutral3: '#3D4352',
    neutral4: '#B3B9C7',
    neutral5: '#DADDE4',
    neutral6: '#F6F7F9',
    neutral7: '#FFFFFF',

    success: '#388E3C',
    success_bg: ' #F6FAF5',
    warning: '#FFA000',
    warning_bg: '#FFF9EE',
    error: '#E64A19',
    error_bg: '#FEF2EE',

    accent1: '#F57C00',
    accent1_bg: '#FFEDDB',
    accent2: '#FBC02D',
    accent2_bg: '#FEF3D7',
    accent3: '#00B08D',
    accent3_bg: '#E0FFF9',
    accent4: '#7D76DF',
    accent4_bg: '#E7E6F9',
    whatsapp: '#43D854',
    'whatsapp-hover': '#08a045' /!* цвет ховера для whatsapp *!/,
    'whatsapp-text': '#fff' /!* цвет текста для whatsapp *!/,
    content_width: '1170px' /!* ширина контента *!/,

    border_radius_block: '16',
    border_radius_btn: '8',
    border_radius_input: '8',
};

const obj = {
    // const currentVarsObj = { ...defaultCssVariables, ...'}';
    common: {
        scheme: 'scheme',
        content_width: 'content_width',
        border_radius_block: 'border_radius_block' || 16,
        border_radius_btn: 'border_radius_btn' || 8,
        border_radius_input: 'border_radius_input' || 8,
    },
    fonts: {
        font_family_head: 'font_family_head',
        font_family_body: 'font_family_body',
        font_style_head: 'font_style_head',
        font_style_body: 'font_style_body',
        // размеры и стили для h1 2 3 4
    },
    // GENERALS ===================================================================
    generals: {
        // main -----------------------------------------------------------------------
        main_background: 'neutral7',
        main_headline_color: 'text_main',
        main_content_text: 'text_body1',
        main_content_link: 'action_secondary',
        main_content_link_hover: 'action_secondary_hover',
        main_content_link_active: 'action_secondary_pressed',
        // inverted block -------------------------------------------------------------
        inverted_background: 'neutral2',
        inverted_headline_color: 'text_main_inverted',
        inverted_content_text: 'text_body1_inverted',
        inverted_content_link: 'action_secondary',
        inverted_content_link_hover: 'action_secondary_hover',
        inverted_content_link_active: 'action_secondary_pressed',
        // middle block ---------------------------------------------------------------
        middle_background: 'neutral6',
        middle_headline_color: 'text_main',
        middle_content_text: 'text_body1',
        middle_content_link: 'action_secondary',
        middle_content_link_hover: 'action_secondary_hover',
        middle_content_link_active: 'action_secondary_pressed',
    },
    // BUTTONS ====================================================================
    buttons: {
        // primary --------------------------------------------------------------------
        button_primary_background: 'action_primary',
        button_primary_background_hover: 'action_primary_hover',
        button_primary_background_active: 'action_primary_pressed',
        button_primary_background_disabled: 'action_primary_disabled',
        button_primary_text: 'text_main_inverted',
        button_primary_text_hover: 'text_main_inverted',
        button_primary_text_active: 'text_main_inverted',
        // secondary ------------------------------------------------------------------
        button_secondary_background: 'action_secondary',
        button_secondary_background_hover: 'action_secondary_hover',
        button_secondary_background_active: 'action_secondary_pressed',
        button_secondary_background_disabled: 'action_secondary_disabled',
        button_secondary_text: 'text_main_inverted',
        button_secondary_text_hover: 'text_main_inverted',
        button_secondary_text_active: 'text_main_inverted',
        // outline --------------------------------------------------------------------
        button_outline_background: 'action_neutral_medium',
        button_outline_background_hover: 'action_neutral_hover_medium',
        button_outline_background_active: 'action_neutral_pressed_medium',
        button_outline_background_disabled: 'action_outline_disabled',
        button_outline_text: 'action_neutral_medium',
        button_outline_text_hover: 'action_neutral_medium_hover',
        button_outline_text_active: 'action_neutral_medium_pressed',
    },
    // HEADER =====================================================================
    header: {
        // mobile ---------------------------------------------------------------------
        header_mobile_main_background: 'header_bg_mobile',
        header_mobile_main_icon: 'action_neutral_light',
        header_mobile_main_text_links: 'action_neutral_light', _text_links
        header_mobile_separator: 'text_body1_inverted',
        header_mobile_bottom_background: 'header_bg_mobile',
        header_mobile_bottom_icon: 'text_body1_inverted',
        header_mobile_bottom_text_links: 'text_main_inverted',
        header_mobile_info_background: 'header_bg_mobile', // ? добавить
        header_mobile_info_icon: 'action_neutral_light',  // ? добавить
        header_mobile_info_text_links: 'action_neutral_light',  // ? добавить
        header_mobile_bottom_text_links_background: 'action_secondary', // ? убрать
        // mobile menu ----------------------------------------------------------------
        header_mobile_menu_background: 'neutral2',
        header_mobile_menu_lines: 'action_neutral_light_disabled',
        header_mobile_menu_icons: 'action_neutral_light',
        header_mobile_menu_text_links: 'action_neutral_light',
        header_mobile_menu_text_links_active: 'action_neutral_light_pressed',
        // mobile contacts ------------------------------------------------------------
        header_mobile_contacts_text: 'action_neutral_light',
        // desktop --------------------------------------------------------------------
        header_desktop_main_background: 'header_bg',
        header_desktop_top_background: 'header_bg_top_bar',
        header_desktop_bottom_background: 'header_main_menu_bg',
        header_desktop_separator_background: 'neutral5',
        header_desktop_main_icon: 'action_secondary',
        // --header-desktop-main-icon: var();
        // --header-desktop-bottom-icon: var();
        // --header-desktop-main-text-links: var();
        // --header-desktop-bottom-text-links: var();
        // desktop menu ---------------------------------------------------------------
        header_desktop_menu_text_link: 'action_neutral_light',
        header_desktop_menu_text_link_hover: 'action_neutral_light_hover',
        header_desktop_menu_text_link_active: 'action_neutral_light_pressed',
        header_desktop_submenu_background: 'header_main_menu_dropdown_bg',
        header_desktop_submenu_text_link: 'action_neutral_light',
        header_desktop_submenu_text_link_hover: 'action_neutral_light_hover',
        header_desktop_submenu_text_link_active: 'action_neutral_light_pressed',
        // desktop menu ---------------------------------------------------------------
        header_desktop_menu_info_text_link: 'text_main',
        header_desktop_menu_info_text_link_hover: 'action_primary_hover',
        header_desktop_menu_info_text_link_active: 'action_primary_pressed',
        // search ---------------------------------------------------------------------
        header_desktop_search_background: 'neutral6',
        header_desktop_search_border: 'neutral6',
        header_desktop_search_text: 'text_body1',
        header_desktop_search_icon: 'action_primary',
        header_desktop_search_background_hover: 'neutral6',
        header_desktop_search_border_hover: 'action_primary',
        header_desktop_search_text_hover: 'text_body1',
        header_desktop_search_icon_hover: 'action_primary_hover',
    },
    // FOOTER =====================================================================
    footer: {
        // main --------------------------------------------------------------------
        footer_main_background: 'neutral7',
        footer_bottom_background: 'neutral6',
        footer_lines_background: 'neutral5',
        footer_icon_color: 'neutral4',
        footer_icon_background: 'neutral6',
        footer_text: 'text_body2',
        footer_link: 'action_neutral_dark',
        footer_link_hover: 'action_secondary_hover',
        footer_link_active: 'action_secondary_pressed',
        footer_bottom_text: 'text_body2',
        footer_social_icon: 'action_secondary',
        footer_social_icon_hover: 'action_secondary_hover',
        footer_social_icon_active: 'action_secondary_pressed',
        // menu -----------------------------------------------------------------------
        footer_menu_text_link: 'action_neutral_dark',
        footer_menu_text_link_hover: 'action_secondary_hover',
        footer_menu_text_link_active: 'action_secondary_pressed',
        footer_submenu_text_link: 'action_neutral_dark',
        footer_submenu_text_link_hover: 'action_secondary_hover',
        footer_submenu_text_link_active: 'action_secondary_pressed',
    },
    // console.log(options.footer.background); // 'light', 'middle', 'dark'
    /!*    if (options.footer.background === 'middle') {
        newFooter.footer_main_background = 'neutral6';
        newFooter.footer_bottom_background = 'neutral5';
        newFooter.footer_icon_background = 'neutral7';
    }
    if (options.footer.background === 'dark') {
        newFooter.footer_main_background = 'neutral2';
        newFooter.footer_bottom_background = 'neutral1';
        newFooter.footer_lines_background = 'neutral3';
        newFooter.footer_text = 'text_body2_inverted';
        newFooter.footer_icon_background = 'neutral1';
        newFooter.footer_menu_text_link = 'action_neutral_light';
        newFooter.footer_submenu_text_link = 'action_neutral_light';
    }*!/
    // FORM =======================================================================
    form: {
        // main -----------------------------------------------------------------------
        form_input_background: 'neutral6',
        form_input_border: 'action_neutral_medium',
        form_input_border_hover: 'action_neutral_medium_hover',
        form_input_text: 'text_body2',
        form_input_text_placeholder: 'neutral7',
        // select ---------------------------------------------------------------------
        form_select_background: 'neutral6',
        form_select_border: 'action_neutral_medium',
        form_select_text: 'text_main',
        form_select_icon: 'action_secondary',
        form_select_option_text: 'text_main',
        form_select_option_text_hover: 'text_body1_inverted',
        form_select_option_background_hover: 'action_neutral_medium_hover',
    },
    // SYSTEM =====================================================================
    system: {
        success: 'success',
        success_bg: 'success_bg',
        warning: 'warning',
        warning_bg: 'warning_bg',
        error: 'error',
        error_bg: 'error_bg',
    },
    // ACCENT =====================================================================
    accent: {
        accent1: 'accent1',
        accent1_bg: 'accent1_bg',
        accent2: 'accent2',
        accent2_bg: 'accent2_bg',
        accent3: 'accent3',
        accent3_bg: 'accent3_bg',
        accent4: 'accent4',
        accent4_bg: 'accent4_bg',
    },
    // PRODUCT_LIST ===============================================================
    productList: {
        // common ---------------------------------------------------------------------
        product_list_background: 'neutral7',
        product_list_title: 'text_main',
        product_list_description: 'text_body2',
        product_list_price: 'text_main',
        product_list_price_discount: 'text_body2',
        product_list_reviews_start: 'accent2',
        product_list_reviews_text: 'text_body1',
        product_list_availability: 'text_body1',
        // buttons --------------------------------------------------------------------
        product_list_button_buy_background: 'action_primary',
        product_list_button_buy_background_hover: 'action_primary_hover',
        product_list_button_buy_background_active: 'action_primary_pressed',
        product_list_button_buy_background_disabled: 'action_primary_disabled',
        product_list_button_buy_text: 'text_main_inverted',
        product_list_button_buy_text_hover: 'text_main_inverted',
        product_list_button_buy_text_active: 'text_main_inverted',
        // counter --------------------------------------------------------------------
        product_list_counter_background: 'neutral6',
        product_list_counter_color: 'text_main',
        product_list_counter_btn_background: 'neutral7',
        product_list_counter_btn_color: 'action_secondary',
        product_list_counter_btn_background_hover: 'neutral7',
        product_list_counter_btn_color_hover: 'action_secondary_hover',
        product_list_counter_btn_background_active: 'neutral7',
        product_list_counter_btn_color_active: 'action_secondary_pressed',
        // // parameters --------------------------------------------------------------
        product_list_parameters_background: 'neutral7',
        product_list_parameters_icon_color: 'text_main_inverted',
        product_list_parameters_icon_background: 'action_secondary',
        product_list_parameters_text_color: 'text_main',
        product_list_parameters_text_border: 'neutral4',
        // options - buttons ----------------------------------------------------------
        product_list_options_button_background: 'action_secondary_disabled',
        product_list_options_button_border: 'action_secondary_disabled',
        product_list_options_button_text: 'text_main',
        product_list_options_button_background_hover: 'action_secondary_hover',
        product_list_options_button_border_hover: 'action_secondary_hover',
        product_list_options_button_text_hover: 'text_main_inverted',
        product_list_options_button_background_selected: 'action_secondary',
        product_list_options_button_border_selected: 'action_secondary',
        product_list_options_button_text_selected: 'text_main_inverted',
        // options - selects ----------------------------------------------------------
        product_list_options_select_background: 'neutral7',
        product_list_options_select_border: 'action_neutral_medium',
        product_list_options_select_text: 'text_main',
        product_list_options_select_icon: 'action_secondary',
        product_list_options_select_option_text: 'text_main',
        product_list_options_select_option_text_hover: 'text_body1_inverted',
        product_list_options_select_option_background_hover: 'action_neutral_medium_hover',
    },
    // FILTER =====================================================================
    filter: {
        filter_desktop_background: 'neutral7',
        filter_desktop_border: 'neutral7',
        // button ---------------------------------------------------------------------
        filter_button_background: 'action_primary',
        filter_button_background_hover: 'action_primary_hover',
        filter_button_background_active: 'action_primary_pressed',
        filter_button_background_disabled: 'action_primary_disabled',
        filter_button_text: 'text_main_inverted',
        filter_button_text_hover: 'text_main_inverted',
        filter_button_text_active: 'text_main_inverted',
    },
    // PAGING =====================================================================
    paging: {
        paging_background: 'neutral7',
        paging_background_hover: 'action_primary_hover',
        paging_background_active: 'action_primary',
        paging_background_disabled: 'action_primary_disabled',
        paging_text: 'text_main',
        paging_text_hover: 'text_main_inverted',
        paging_text_active: 'text_main_inverted',
        paging_text_disabled: 'text_main',
    },
    // PRODUCT_CARD ===============================================================
    productCard: {
        // common ---------------------------------------------------------------------
        product_card_background: 'neutral7',
        product_card_title: 'text_main',
        product_card_description: 'text_body2',
        product_card_price: 'text_main',
        product_card_price_discount: 'text_body2',
        product_card_reviews_start: 'accent2',
        product_card_reviews_text: 'text_body1',
        product_card_availability: 'text_body1',
        // buttons --------------------------------------------------------------------
        product_card_button_buy_background: 'action_primary',
        product_card_button_buy_background_hover: 'action_primary_hover',
        product_card_button_buy_background_active: 'action_primary_pressed',
        product_card_button_buy_background_disabled: 'action_primary_disabled',
        product_card_button_buy_text: 'text_main_inverted',
        product_card_button_buy_text_hover: 'text_main_inverted',
        product_card_button_buy_text_active: 'text_main_inverted',
        // counter --------------------------------------------------------------------
        product_card_counter_background: 'neutral6',
        product_card_counter_color: 'text_main',
        product_card_counter_btn_background: 'neutral7',
        product_card_counter_btn_color: 'action_secondary',
        product_card_counter_btn_background_hover: 'neutral7',
        product_card_counter_btn_color_hover: 'action_secondary_hover',
        product_card_counter_btn_background_active: 'neutral7',
        product_card_counter_btn_color_active: 'action_secondary_pressed',
        // // parameters --------------------------------------------------------------
        product_card_parameters_background: 'neutral7',
        product_card_parameters_icon_color: 'text_main_inverted',
        product_card_parameters_icon_background: 'action_secondary',
        product_card_parameters_text_color: 'text_main',
        product_card_parameters_text_border: 'neutral4',
        // options - buttons ----------------------------------------------------------
        product_card_options_button_background: 'action_secondary_disabled',
        product_card_options_button_border: 'action_secondary_disabled',
        product_card_options_button_text: 'text_main',
        product_card_options_button_background_hover: 'action_secondary_hover',
        product_card_options_button_border_hover: 'action_secondary_hover',
        product_card_options_button_text_hover: 'text_main_inverted',
        product_card_options_button_background_selected: 'action_secondary',
        product_card_options_button_border_selected: 'action_secondary',
        product_card_options_button_text_selected: 'text_main_inverted',
        // options - selects ----------------------------------------------------------
        product_card_options_select_background: 'neutral7',
        product_card_options_select_border: 'action_neutral_medium',
        product_card_options_select_text: 'text_main',
        product_card_options_select_icon: 'action_secondary',
        product_card_options_select_option_text: 'text_main',
        product_card_options_select_option_text_hover: 'text_body1_inverted',
        product_card_options_select_option_background_hover: 'action_neutral_medium_hover',
    },
    // CART =======================================================================
    cart: {
        cart_border: 'neutral5',
    },
};
function createConfig(obj, vars) {
    const result = {};
    let index = 0;
    const inputs = {
        content_width: {
            id: null,
            position: 64,
            type: 'select',
            component: 'Select',
            value: '1170px',
            options: {
                variants: [
                    { text: '1170px', value: '1170px' },
                    { text: '1280px', value: '1280px' },
                    { text: '1360px', value: '1360px' },
                    { text: '1440px', value: '1440px' },
                ],
                items: [
                    { text: '1170px', value: '1170px' },
                    { text: '1280px', value: '1280px' },
                    { text: '1360px', value: '1360px' },
                    { text: '1440px', value: '1440px' },
                ],
                labelTrans: 'settings.styles.content_width',
            },
        },
        border_radius_block: {
            id: null,
            position: 70,
            type: 'number',
            component: 'InputNumberAndRange',
            value: 16,
            options: {
                labelTrans: 'settings.styles.border_radius_block',
                min: 0,
                max: 30,
            },
        },
        border_radius_btn: {
            id: null,
            position: 70,
            type: 'number',
            component: 'InputNumberAndRange',
            value: 8,
            options: {
                labelTrans: 'settings.styles.border_radius_btn',
                min: 0,
                max: 25,
            },
        },
        border_radius_input: {
            id: null,
            position: 70,
            type: 'number',
            component: 'InputNumberAndRange',
            value: 8,
            options: {
                labelTrans: 'settings.styles.border_radius_input',
                min: 0,
                max: 25,
            },
        },
        scheme: {
            id: null,
            position: 0,
            type: 'select',
            component: 'Select',
            value: 'Scheme 1',
            options: {
                variants: [
                    { text: 'Theme 1', value: 'Scheme 1' },
                    { text: 'Theme 2', value: 'Scheme 2' },
                    { text: 'Theme 3', value: 'Scheme 3' },
                    { text: 'Theme 4', value: 'Scheme 4' },
                    { text: 'Theme 5', value: 'Scheme 5' },
                    { text: 'Theme 6', value: 'Scheme 6' },
                    { text: 'Theme 7', value: 'Scheme 7' },
                    { text: 'Theme 8', value: 'Scheme 8' },
                    { text: 'Theme 9', value: 'Scheme 9' },
                    { text: 'Theme 10', value: 'Scheme 10' },
                    { text: 'Theme 11', value: 'Scheme 11' },
                    { text: 'Theme Custom', value: 'Custom' },
                ],
                items: [
                    { text: 'Theme 1', value: 'Scheme 1' },
                    { text: 'Theme 2', value: 'Scheme 2' },
                    { text: 'Theme 3', value: 'Scheme 3' },
                    { text: 'Theme 4', value: 'Scheme 4' },
                    { text: 'Theme 5', value: 'Scheme 5' },
                    { text: 'Theme 6', value: 'Scheme 6' },
                    { text: 'Theme 7', value: 'Scheme 7' },
                    { text: 'Theme 8', value: 'Scheme 8' },
                    { text: 'Theme 9', value: 'Scheme 9' },
                    { text: 'Theme 10', value: 'Scheme 10' },
                    { text: 'Theme 11', value: 'Scheme 11' },
                    { text: 'Theme Custom', value: 'Custom' },
                ],
                labelTrans: 'settings.styles.scheme',
            },
        },
    };
    for (const group in obj) {
        for (const item in obj[group]) {
            const value = obj[group][item];
            if (inputs[value]) {
                result[item] = inputs[value];
            } else {
                result[item] = new SettingItem({
                    id: null,
                    type: 'color',
                    value: vars[obj[group][item]],
                    position: index,
                }).data;
            }
            index++;
        }
    }
    for (const group in result) {
        console.log(JSON.stringify({ [group]: result[group] }));
    }
    return result;
}

export default createConfig(obj, defaultCssVariables);*/
/*

const obj = {
    // const currentVarsObj = { ...defaultCssVariables, ...'}';
    common: {
        scheme: 'scheme',
        content_width: 'content_width',
        'border/radius_block': 'border_radius_block' || 16,
        'border/radius_btn': 'border_radius_btn' || 8,
        'border/radius_input': 'border_radius_input' || 8,
    },
    fonts: {
        font_family_head: 'font_family_head',
        font_family_body: 'font_family_body',
        font_style_head: 'font_style_head',
        font_style_body: 'font_style_body',
        // размеры и стили для h1 2 3 4
    },
    // GENERALS ===================================================================
    generals: {
        // main -----------------------------------------------------------------------
        'main/background': 'neutral7',
        'main/headline_color': 'text_main',
        'main/content_text': 'text_body1',
        'main/content_link': 'action_secondary',
        'main/content_link_hover': 'action_secondary_hover',
        'main/content_link_active': 'action_secondary_pressed',
        // inverted block -------------------------------------------------------------
        'inverted/background': 'neutral2',
        'inverted/headline_color': 'text_main_inverted',
        'inverted/content_text': 'text_body1_inverted',
        'inverted/content_link': 'action_secondary',
        'inverted/content_link_hover': 'action_secondary_hover',
        'inverted/content_link_active': 'action_secondary_pressed',
        // middle block ---------------------------------------------------------------
        'middle/background': 'neutral6',
        'middle/headline_color': 'text_main',
        'middle/content_text': 'text_body1',
        'middle/content_link': 'action_secondary',
        'middle/content_link_hover': 'action_secondary_hover',
        'middle/content_link_active': 'action_secondary_pressed',
    },
    // BUTTONS ====================================================================
    buttons: {
        // primary --------------------------------------------------------------------
        'button_primary/background': 'action_primary',
        'button_primary/background_hover': 'action_primary_hover',
        'button_primary/background_active': 'action_primary_pressed',
        'button_primary/background_disabled': 'action_primary_disabled',
        'button_primary/text': 'text_main_inverted',
        'button_primary/text_hover': 'text_main_inverted',
        'button_primary/text_active': 'text_main_inverted',
        // secondary ------------------------------------------------------------------
        'button_secondary/background': 'action_secondary',
        'button_secondary/background_hover': 'action_secondary_hover',
        'button_secondary/background_active': 'action_secondary_pressed',
        'button_secondary/background_disabled': 'action_secondary_disabled',
        'button_secondary/text': 'text_main_inverted',
        'button_secondary/text_hover': 'text_main_inverted',
        'button_secondary/text_active': 'text_main_inverted',
        // outline --------------------------------------------------------------------
        'button_outline/background': 'action_neutral_medium',
        'button_outline/background_hover': 'action_neutral_hover_medium',
        'button_outline/background_active': 'action_neutral_pressed_medium',
        'button_outline/background_disabled': 'action_outline_disabled',
        'button_outline/text': 'action_neutral_medium',
        'button_outline/text_hover': 'action_neutral_medium_hover',
        'button_outline/text_active': 'action_neutral_medium_pressed',
    },
    // HEADER =====================================================================
    header: {
        // mobile ---------------------------------------------------------------------
        'header_mobile/main_background': 'header_bg_mobile',
        'header_mobile/bottom_background': 'header_bg_mobile',
        'header_mobile/separator': 'text_body1_inverted',
        'header_mobile/main_icon': 'action_neutral_light',
        'header_mobile/bottom_icon': 'text_body1_inverted',
        'header_mobile/bottom_text_links': 'text_main_inverted',
        'header_mobile/bottom_text_links_background': 'action_secondary',
        // mobile menu ----------------------------------------------------------------
        'header_mobile/menu_background': 'neutral2',
        'header_mobile/menu_lines': 'action_neutral_light_disabled',
        'header_mobile/menu_icons': 'action_neutral_light',
        'header_mobile/menu_text_links': 'action_neutral_light',
        'header_mobile/menu_text_links_active': 'action_neutral_light_pressed',
        // mobile contacts ------------------------------------------------------------
        'header_mobile/contacts_text': 'action_neutral_light',
        // desktop --------------------------------------------------------------------
        'header_desktop/main_background': 'header_bg',
        'header_desktop/top_background': 'header_bg_top_bar',
        'header_desktop/bottom_background': 'header_main_menu_bg',
        'header_desktop/separator_background': 'neutral5',
        'header_desktop/main_icon': 'action_secondary',
        // --header-desktop-main-icon: var();
        // --header-desktop-bottom-icon: var();
        // --header-desktop-main-text-links: var();
        // --header-desktop-bottom-text-links: var();
        // desktop menu ---------------------------------------------------------------
        'header_desktop/menu_text_link': 'action_neutral_light',
        'header_desktop/menu_text_link_hover': 'action_neutral_light_hover',
        'header_desktop/menu_text_link_active': 'action_neutral_light_pressed',
        'header_desktop/submenu_background': 'header_main_menu_dropdown_bg',
        'header_desktop/submenu_text_link': 'action_neutral_light',
        'header_desktop/submenu_text_link_hover': 'action_neutral_light_hover',
        'header_desktop/submenu_text_link_active': 'action_neutral_light_pressed',
        // desktop menu ---------------------------------------------------------------
        'header_desktop/menu_info_text_link': 'text_main',
        'header_desktop/menu_info_text_link_hover': 'action_primary_hover',
        'header_desktop/menu_info_text_link_active': 'action_primary_pressed',
        // search -----/---------------------------------------------------------------
        'header_desktop/search_background': 'neutral6',
        'header_desktop/search_border': 'neutral6',
        'header_desktop/search_text': 'text_body1',
        'header_desktop/search_icon': 'action_primary',
        'header_desktop/search_background_hover': 'neutral6',
        'header_desktop/search_border_hover': 'action_primary',
        'header_desktop/search_text_hover': 'text_body1',
        'header_desktop/search_icon_hover': 'action_primary_hover',
    },
    // FOOTER =====================================================================
    footer: {
        // main --------------------------------------------------------------------
        footer_main_background: 'neutral7',
        footer_bottom_background: 'neutral6',
        footer_lines_background: 'neutral5',
        'footer_icon/color': 'neutral4',
        'footer_icon/background': 'neutral6',
        footer_text: 'text_body2',
        footer_link: 'action_neutral_dark',
        footer_link_hover: 'action_secondary_hover',
        footer_link_active: 'action_secondary_pressed',
        footer_bottom_text: 'text_body2',
        footer_social_icon: 'action_secondary',
        footer_social_icon_hover: 'action_secondary_hover',
        footer_social_icon_active: 'action_secondary_pressed',
        // menu -----------------------------------------------------------------------
        'footer_menu/text_link': 'action_neutral_dark',
        'footer_menu/text_link_hover': 'action_secondary_hover',
        'footer_menu/text_link_active': 'action_secondary_pressed',
        'footer_submenu/text_link': 'action_neutral_dark',
        'footer_submenu/text_link_hover': 'action_secondary_hover',
        'footer_submenu/text_link_active': 'action_secondary_pressed',
    },
    // FORM =======================================================================
    form: {
        // main -----------------------------------------------------------------------
        'form_input/background': 'neutral6',
        'form_input/border': 'action_neutral_medium',
        'form_input/border_hover': 'action_neutral_medium_hover',
        'form_input/text': 'text_body2',
        'form_input/text_placeholder': 'neutral7',
        // select ---------------------------------------------------------------------
        'form_select/background': 'neutral6',
        'form_select/border': 'action_neutral_medium',
        'form_select/text': 'text_main',
        'form_select/icon': 'action_secondary',
        'form_select/option_text': 'text_main',
        'form_select/option_text_hover': 'text_body1_inverted',
        'form_select/option_background_hover': 'action_neutral_medium_hover',
    },
    // SYSTEM =====================================================================
    system: {
        success: 'success',
        success_bg: 'success_bg',
        warning: 'warning',
        warning_bg: 'warning_bg',
        error: 'error',
        error_bg: 'error_bg',
    },
    // ACCENT =====================================================================
    accent: {
        accent1: 'accent1',
        accent1_bg: 'accent1_bg',
        accent2: 'accent2',
        accent2_bg: 'accent2_bg',
        accent3: 'accent3',
        accent3_bg: 'accent3_bg',
        accent4: 'accent4',
        accent4_bg: 'accent4_bg',
    },
    // PRODUCT_LIST ===============================================================
    product_list: {
        // common ---------------------------------------------------------------------
        product_list_background: 'neutral7',
        product_list_title: 'text_main',
        product_list_description: 'text_body2',
        product_list_price: 'text_main',
        product_list_price_discount: 'text_body2',
        product_list_reviews_start: 'accent2',
        product_list_reviews_text: 'text_body1',
        product_list_availability: 'text_body1',
        // buttons --------------------------------------------------------------------
        'product_list_button_buy/background': 'action_primary',
        'product_list_button_buy/background_hover': 'action_primary_hover',
        'product_list_button_buy/background_active': 'action_primary_pressed',
        'product_list_button_buy/background_disabled': 'action_primary_disabled',
        'product_list_button_buy/text': 'text_main_inverted',
        'product_list_button_buy/text_hover': 'text_main_inverted',
        'product_list_button_buy/text_active': 'text_main_inverted',
        // counter --------------------------------------------------------------------
        'product_list_counter/background': 'neutral6',
        'product_list_counter/color': 'text_main',
        'product_list_counter/btn_background': 'neutral7',
        'product_list_counter/btn_color': 'action_secondary',
        'product_list_counter/btn_background_hover': 'neutral7',
        'product_list_counter/btn_color_hover': 'action_secondary_hover',
        'product_list_counter/btn_background_active': 'neutral7',
        'product_list_counter/btn_color_active': 'action_secondary_pressed',
        // // parameters --------------------------------------------------------------
        'product_list_parameters/background': 'neutral7',
        'product_list_parameters/icon_color': 'text_main_inverted',
        'product_list_parameters/icon_background': 'action_secondary',
        'product_list_parameters/text_color': 'text_main',
        'product_list_parameters/text_border': 'neutral4',
        // options - buttons ----------------------------------------------------------
        'product_list_options_button/background': 'action_secondary_disabled',
        'product_list_options_button/border': 'action_secondary_disabled',
        'product_list_options_button/text': 'text_main',
        'product_list_options_button/background_hover': 'action_secondary_hover',
        'product_list_options_button/border_hover': 'action_secondary_hover',
        'product_list_options_button/text_hover': 'text_main_inverted',
        'product_list_options_button/background_selected': 'action_secondary',
        'product_list_options_button/border_selected': 'action_secondary',
        'product_list_options_button/text_selected': 'text_main_inverted',
        // options - selects ----------------------------------------------------------
        'product_list_options_select/background': 'neutral7',
        'product_list_options_select/border': 'action_neutral_medium',
        'product_list_options_select/text': 'text_main',
        'product_list_options_select/icon': 'action_secondary',
        'product_list_options_select/option_text': 'text_main',
        'product_list_options_select/option_text_hover': 'text_body1_inverted',
        'product_list_options_select/option_background_hover': 'action_neutral_medium_hover',
    },
    // FILTER =====================================================================
    filter: {
        filter_desktop_background: 'neutral7',
        filter_desktop_border: 'neutral7',
        // button ---------------------------------------------------------------------
        'filter_button/background': 'action_primary',
        'filter_button/background_hover': 'action_primary_hover',
        'filter_button/background_active': 'action_primary_pressed',
        'filter_button/background_disabled': 'action_primary_disabled',
        'filter_button/text': 'text_main_inverted',
        'filter_button/text_hover': 'text_main_inverted',
        'filter_button/text_active': 'text_main_inverted',
    },
    // PAGING =====================================================================
    paging: {
        paging_background: 'neutral7',
        paging_background_hover: 'action_primary_hover',
        paging_background_active: 'action_primary',
        paging_background_disabled: 'action_primary_disabled',
        paging_text: 'text_main',
        paging_text_hover: 'text_main_inverted',
        paging_text_active: 'text_main_inverted',
        paging_text_disabled: 'text_main',
    },
    // PRODUCT_CARD ===============================================================
    product_card: {
        // common ---------------------------------------------------------------------
        product_card_background: 'neutral7',
        product_card_title: 'text_main',
        product_card_description: 'text_body2',
        product_card_price: 'text_main',
        product_card_price_discount: 'text_body2',
        product_card_reviews_start: 'accent2',
        product_card_reviews_text: 'text_body1',
        product_card_availability: 'text_body1',
        // buttons --------------------------------------------------------------------
        'product_card_button_buy/background': 'action_primary',
        'product_card_button_buy/background_hover': 'action_primary_hover',
        'product_card_button_buy/background_active': 'action_primary_pressed',
        'product_card_button_buy/background_disabled': 'action_primary_disabled',
        'product_card_button_buy/text': 'text_main_inverted',
        'product_card_button_buy/text_hover': 'text_main_inverted',
        'product_card_button_buy/text_active': 'text_main_inverted',
        // counter --------------------------------------------------------------------
        'product_card_counter/background': 'neutral6',
        'product_card_counter/color': 'text_main',
        'product_card_counter/btn_background': 'neutral7',
        'product_card_counter/btn_color': 'action_secondary',
        'product_card_counter/btn_background_hover': 'neutral7',
        'product_card_counter/btn_color_hover': 'action_secondary_hover',
        'product_card_counter/btn_background_active': 'neutral7',
        'product_card_counter/btn_color_active': 'action_secondary_pressed',
        // // parameters --------------------------------------------------------------
        'product_card_parameters/background': 'neutral7',
        'product_card_parameters/icon_color': 'text_main_inverted',
        'product_card_parameters/icon_background': 'action_secondary',
        'product_card_parameters/text_color': 'text_main',
        'product_card_parameters/text_border': 'neutral4',
        // options - buttons ----------------------------------------------------------
        'product_card_options_button/background': 'action_secondary_disabled',
        'product_card_options_button/border': 'action_secondary_disabled',
        'product_card_options_button/text': 'text_main',
        'product_card_options_button/background_hover': 'action_secondary_hover',
        'product_card_options_button/border_hover': 'action_secondary_hover',
        'product_card_options_button/text_hover': 'text_main_inverted',
        'product_card_options_button/background_selected': 'action_secondary',
        'product_card_options_button/border_selected': 'action_secondary',
        'product_card_options_button/text_selected': 'text_main_inverted',
        // options - selects ----------------------------------------------------------
        'product_card_options_select/background': 'neutral7',
        'product_card_options_select/border': 'action_neutral_medium',
        'product_card_options_select/text': 'text_main',
        'product_card_options_select/icon': 'action_secondary',
        'product_card_options_select/option_text': 'text_main',
        'product_card_options_select/option_text_hover': 'text_body1_inverted',
        'product_card_options_select/option_background_hover': 'action_neutral_medium_hover',
    },
    // CART =======================================================================
    cart: {
        cart_border: 'neutral5',
    },
};

function convert(object) {
    const result = {};

    for (let key in object) {
        const fields = Object.keys(object[key]);
        result[key] = fields.reduce((acc, item) => {
            const levels = item.split('/');
            let target = acc;
            if (levels.length === 2) {
                let subgroupName = '';
                if (levels[0].startsWith(key)) {
                    subgroupName = levels[0].substring(key.length + 1);
                    console.log(subgroupName);
                } else {
                    subgroupName = levels[0];
                }
                if (acc[subgroupName] === undefined) {
                    acc[subgroupName] = {};
                }
                target = acc[subgroupName];
            }
            const valueLevel = levels[levels.length - 1];
            target[valueLevel] = item.includes('/') ? item.split('/').join('_') : item;

            return acc;
        }, {});
    }
    return result;
}

function translateToEng(object) {
    const result = {};

    for (let key in object) {
        const fields = Object.keys(object[key]);
        fields.forEach((item) => {
            let target = result;
            /!*        if (levels.length === 2) {
                let subgroupName = '';
                if (levels[0].startsWith(key)) {
                    subgroupName = levels[0].substring(key.length + 1);
                    console.log(subgroupName);
                } else {
                    subgroupName = levels[0];
                }
                if (result[subgroupName] === undefined) {
                    result[subgroupName] = { value: subgroupName.split('_').join(' ') };
                }
                target = result[subgroupName];
            }*!/
            const splitItem = item.split('/').length - 1;
            let finalItem = item.includes('/')
                ? item
                      .split('/')
                      [splitItem].split('_')
                      .join(' ')
                : item.split('_').join(' ');
            const name = item.includes('/') ? item.split('/').join('_') : item;
            if (finalItem.startsWith(key.split('_').join(' '))) {
                finalItem = finalItem.substring(key.length + 1);
            }
            target[name] = `${finalItem[0]?.toUpperCase()}${finalItem?.substring(1)}`;
            if (item.split('/').length) {
                const groupName = item
                    .split('/')[0]
                    .split('_')
                    .join(' ');
                target[item.split('/')[0]] = `${groupName[0]?.toUpperCase()}${groupName?.substring(1)}`;
            }
        }, {});
    }
    return result;
}
translateToEng(obj);
*/

export default {
    scheme: 'scheme',
    common: {
        content_width: 'content_width',
        border: {
            radius_block: 'border_radius_block',
            radius_btn: 'border_radius_btn',
            radius_input: 'border_radius_input',
        },
    },
    fonts: {
        font_family_head: 'font_family_head',
        font_style_head: 'font_style_head',
        font_weight_head: 'font_weight_head',
        font_size_head: 'font_size_head',
        font_family_body: 'font_family_body',
        // font_style_body: 'font_style_body',
    },
    generals: {
        main: {
            background: 'main_background',
            headline_color: 'main_headline_color',
            content_text: 'main_content_text',
            content_link: 'main_content_link',
            content_link_hover: 'main_content_link_hover',
            content_link_active: 'main_content_link_active',
        },
        inverted: {
            background: 'inverted_background',
            headline_color: 'inverted_headline_color',
            content_text: 'inverted_content_text',
            content_link: 'inverted_content_link',
            content_link_hover: 'inverted_content_link_hover',
            content_link_active: 'inverted_content_link_active',
        },
        middle: {
            background: 'middle_background',
            headline_color: 'middle_headline_color',
            content_text: 'middle_content_text',
            content_link: 'middle_content_link',
            content_link_hover: 'middle_content_link_hover',
            content_link_active: 'middle_content_link_active',
        },
    },
    buttons: {
        button_primary: {
            background: 'button_primary_background',
            background_hover: 'button_primary_background_hover',
            background_active: 'button_primary_background_active',
            background_disabled: 'button_primary_background_disabled',
            text: 'button_primary_text',
            text_hover: 'button_primary_text_hover',
            text_active: 'button_primary_text_active',
        },
        button_secondary: {
            background: 'button_secondary_background',
            background_hover: 'button_secondary_background_hover',
            background_active: 'button_secondary_background_active',
            background_disabled: 'button_secondary_background_disabled',
            text: 'button_secondary_text',
            text_hover: 'button_secondary_text_hover',
            text_active: 'button_secondary_text_active',
        },
        button_outline: {
            background: 'button_outline_background',
            background_hover: 'button_outline_background_hover',
            background_active: 'button_outline_background_active',
            background_disabled: 'button_outline_background_disabled',
            text: 'button_outline_text',
            text_hover: 'button_outline_text_hover',
            text_active: 'button_outline_text_active',
        },
    },
    header: {
        mobile: {
            main_background: 'header_mobile_main_background',
            bottom_background: 'header_mobile_bottom_background',
            separator: 'header_mobile_separator',
            main_icon: 'header_mobile_main_icon',
            bottom_icon: 'header_mobile_bottom_icon',
            bottom_text_links: 'header_mobile_bottom_text_links',
            bottom_text_links_background: 'header_mobile_bottom_text_links_background',
            contacts_text: 'header_mobile_contacts_text',
            menu: {
                background: 'header_mobile_menu_background',
                lines: 'header_mobile_menu_lines',
                icons: 'header_mobile_menu_icons',
                text_links: 'header_mobile_menu_text_links',
                text_links_active: 'header_mobile_menu_text_links_active',
            },
        },
        desktop: {
            main_background: 'header_desktop_main_background',
            top_background: 'header_desktop_top_background',
            bottom_background: 'header_desktop_bottom_background',
            separator_background: 'header_desktop_separator_background',
            main_icon: 'header_desktop_main_icon',
            main_icon_hover: 'header_desktop_main_icon_hover',
            main_text_link: 'header_desktop_main_text_link',
            main_text_link_hover: 'header_desktop_main_text_link_hover',
            search_background: 'header_desktop_search_background',
            search_border: 'header_desktop_search_border',
            search_text: 'header_desktop_search_text',
            search_icon: 'header_desktop_search_icon',
            search_background_hover: 'header_desktop_search_background_hover',
            search_border_hover: 'header_desktop_search_border_hover',
            search_text_hover: 'header_desktop_search_text_hover',
            search_icon_hover: 'header_desktop_search_icon_hover',
            menu: {
                text_link: 'header_desktop_menu_text_link',
                text_link_hover: 'header_desktop_menu_text_link_hover',
                text_link_active: 'header_desktop_menu_text_link_active',
                nu_background: 'header_desktop_submenu_background',
                info_text_link: 'header_desktop_menu_info_text_link',
                info_text_link_hover: 'header_desktop_menu_info_text_link_hover',
                info_text_link_active: 'header_desktop_menu_info_text_link_active',
                submenu_text_link: 'header_desktop_submenu_text_link',
                submenu_text_link_hover: 'header_desktop_submenu_text_link_hover',
                submenu_text_link_active: 'header_desktop_submenu_text_link_active',
            },
        },
    },
    footer: {
        main_background: 'footer_main_background',
        bottom_background: 'footer_bottom_background',
        lines_background: 'footer_lines_background',
        text: 'footer_text',
        link: 'footer_link',
        link_hover: 'footer_link_hover',
        link_active: 'footer_link_active',
        bottom_text: 'footer_bottom_text',
        social_icon: 'footer_social_icon',
        social_icon_hover: 'footer_social_icon_hover',
        social_icon_active: 'footer_social_icon_active',
        icon: {
            color: 'footer_icon_color',
            background: 'footer_icon_background',
        },
        menu: {
            text_link: 'footer_menu_text_link',
            text_link_hover: 'footer_menu_text_link_hover',
            text_link_active: 'footer_menu_text_link_active',
        },
        submenu: {
            text_link: 'footer_submenu_text_link',
            text_link_hover: 'footer_submenu_text_link_hover',
            text_link_active: 'footer_submenu_text_link_active',
        },
    },
    form: {
        input: {
            background: 'form_input_background',
            border: 'form_input_border',
            border_hover: 'form_input_border_hover',
            text: 'form_input_text',
            text_placeholder: 'form_input_text_placeholder',
        },
        select: {
            background: 'form_select_background',
            border: 'form_select_border',
            text: 'form_select_text',
            icon: 'form_select_icon',
            option_text: 'form_select_option_text',
            option_text_hover: 'form_select_option_text_hover',
            option_background_hover: 'form_select_option_background_hover',
        },
    },
    system: {
        success: 'success',
        success_bg: 'success_bg',
        warning: 'warning',
        warning_bg: 'warning_bg',
        error: 'error',
        error_bg: 'error_bg',
    },
    accent: {
        accent1: 'accent1',
        accent1_bg: 'accent1_bg',
        accent2: 'accent2',
        accent2_bg: 'accent2_bg',
        accent3: 'accent3',
        accent3_bg: 'accent3_bg',
        accent4: 'accent4',
        accent4_bg: 'accent4_bg',
    },
    product_list: {
        background: 'product_list_background',
        title: 'product_list_title',
        description: 'product_list_description',
        price: 'product_list_price',
        price_discount: 'product_list_price_discount',
        reviews_start: 'product_list_reviews_start',
        reviews_text: 'product_list_reviews_text',
        availability: 'product_list_availability',
        button_buy: {
            background: 'product_list_button_buy_background',
            background_hover: 'product_list_button_buy_background_hover',
            background_active: 'product_list_button_buy_background_active',
            background_disabled: 'product_list_button_buy_background_disabled',
            text: 'product_list_button_buy_text',
            text_hover: 'product_list_button_buy_text_hover',
            text_active: 'product_list_button_buy_text_active',
        },
        counter: {
            background: 'product_list_counter_background',
            color: 'product_list_counter_color',
            btn_background: 'product_list_counter_btn_background',
            btn_color: 'product_list_counter_btn_color',
            btn_background_hover: 'product_list_counter_btn_background_hover',
            btn_color_hover: 'product_list_counter_btn_color_hover',
            btn_background_active: 'product_list_counter_btn_background_active',
            btn_color_active: 'product_list_counter_btn_color_active',
        },
        parameters: {
            background: 'product_list_parameters_background',
            icon_color: 'product_list_parameters_icon_color',
            icon_background: 'product_list_parameters_icon_background',
            text_color: 'product_list_parameters_text_color',
            text_border: 'product_list_parameters_text_border',
        },
        options_button: {
            background: 'product_list_options_button_background',
            border: 'product_list_options_button_border',
            text: 'product_list_options_button_text',
            background_hover: 'product_list_options_button_background_hover',
            border_hover: 'product_list_options_button_border_hover',
            text_hover: 'product_list_options_button_text_hover',
            background_selected: 'product_list_options_button_background_selected',
            border_selected: 'product_list_options_button_border_selected',
            text_selected: 'product_list_options_button_text_selected',
        },
        options_select: {
            background: 'product_list_options_select_background',
            border: 'product_list_options_select_border',
            text: 'product_list_options_select_text',
            icon: 'product_list_options_select_icon',
            option_text: 'product_list_options_select_option_text',
            option_text_hover: 'product_list_options_select_option_text_hover',
            option_background_hover: 'product_list_options_select_option_background_hover',
        },
    },
    filter: {
        mobile: {
            background: 'filter_mobile_background',
            border: 'filter_mobile_border',
            text: 'filter_mobile_text',
            icon: 'filter_mobile_icon',
        },
        desktop: {
            background: 'filter_desktop_background',
            border: 'filter_desktop_border',
            text: 'filter_desktop_text',
            icon: 'filter_desktop_icon',
        },
        button: {
            background: 'filter_button_background',
            background_hover: 'filter_button_background_hover',
            background_active: 'filter_button_background_active',
            background_disabled: 'filter_button_background_disabled',
            text: 'filter_button_text',
            text_hover: 'filter_button_text_hover',
            text_active: 'filter_button_text_active',
        },
    },
    paging: {
        background: 'paging_background',
        background_hover: 'paging_background_hover',
        background_active: 'paging_background_active',
        background_disabled: 'paging_background_disabled',
        text: 'paging_text',
        text_hover: 'paging_text_hover',
        text_active: 'paging_text_active',
        text_disabled: 'paging_text_disabled',
    },
    product_card: {
        background: 'product_card_background',
        title: 'product_card_title',
        description: 'product_card_description',
        price: 'product_card_price',
        price_discount: 'product_card_price_discount',
        reviews_start: 'product_card_reviews_start',
        reviews_text: 'product_card_reviews_text',
        availability: 'product_card_availability',
        button_buy: {
            background: 'product_card_button_buy_background',
            background_hover: 'product_card_button_buy_background_hover',
            background_active: 'product_card_button_buy_background_active',
            background_disabled: 'product_card_button_buy_background_disabled',
            text: 'product_card_button_buy_text',
            text_hover: 'product_card_button_buy_text_hover',
            text_active: 'product_card_button_buy_text_active',
        },
        counter: {
            background: 'product_card_counter_background',
            color: 'product_card_counter_color',
            btn_background: 'product_card_counter_btn_background',
            btn_color: 'product_card_counter_btn_color',
            btn_background_hover: 'product_card_counter_btn_background_hover',
            btn_color_hover: 'product_card_counter_btn_color_hover',
            btn_background_active: 'product_card_counter_btn_background_active',
            btn_color_active: 'product_card_counter_btn_color_active',
        },
        parameters: {
            background: 'product_card_parameters_background',
            icon_color: 'product_card_parameters_icon_color',
            icon_background: 'product_card_parameters_icon_background',
            text_color: 'product_card_parameters_text_color',
            text_border: 'product_card_parameters_text_border',
        },
        options_button: {
            background: 'product_card_options_button_background',
            border: 'product_card_options_button_border',
            text: 'product_card_options_button_text',
            background_hover: 'product_card_options_button_background_hover',
            border_hover: 'product_card_options_button_border_hover',
            text_hover: 'product_card_options_button_text_hover',
            background_selected: 'product_card_options_button_background_selected',
            border_selected: 'product_card_options_button_border_selected',
            text_selected: 'product_card_options_button_text_selected',
        },
        options_select: {
            background: 'product_card_options_select_background',
            border: 'product_card_options_select_border',
            text: 'product_card_options_select_text',
            icon: 'product_card_options_select_icon',
            option_text: 'product_card_options_select_option_text',
            option_text_hover: 'product_card_options_select_option_text_hover',
            option_background_hover: 'product_card_options_select_option_background_hover',
        },
    },
    cart: {
        cart_border: 'cart_border',
    },
    depricated: {
        neutral1: 'neutral1',
        neutral4: 'neutral4',
        neutral5: 'neutral5',
    },
};
