<template>
    <div class="sections-group" :class="{ highlight: isOpen }" :ref="group">
        <button class="sections-group__header" @click="toggleItem(group)" ref="header">
            <span class="sections-group__header-title">{{ $t(`builder.sectionOptions.groups.${group}`) }}</span>
            <span class="sections-group__header-more material-icons" :class="{ 'is-open': isOpen }">
                expand_more
            </span>
        </button>
        <div class="sections-group__content" :class="{ 'is-open': isOpen }">
            <div class="sections-group__content-wrapper">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
    name: 'SectionsGroup',
    props: {
        group: { type: String, default: 'Item' },
        index: { type: Number, default: 0 },
    },
    data() {
        return {
            position: 0,
            scrollBlock: null,
            firstGroupPosition: 156, // костыль
        };
    },
    computed: {
        ...mapState('builder', {
            activeSectionsGroup: 'activeSectionsGroup',
        }),
        isOpen() {
            return this.group === this.activeSectionsGroup;
        },
    },
    mounted() {
        this.scrollBlock = document.querySelector('.builder-sidebar-right__scroll');
        // this.firstGroupPosition = document.querySelector('.sections-group').getBoundingClientRect().top;
        this.position = this.getPosition();
    },
    methods: {
        ...mapActions('builder', {
            updateActiveSectionsGroup: 'updateActiveSectionsGroup',
        }),
        toggleItem(group) {
            if (!this.isOpen && this.scrollBlock) {
                this.scrollBlock.scrollTo({ behavior: 'smooth', top: this.position - 56 });
            }
            if (group === this.activeSectionsGroup) this.updateActiveSectionsGroup(null);
            else this.updateActiveSectionsGroup(group);
        },
        getPosition() {
            if (!Object.keys(this.$refs).length) return 0;
            const header = this.$refs.header.getBoundingClientRect().height;
            if (this.index === 0) return this.firstGroupPosition;
            return this.firstGroupPosition + this.index * header;
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
@import '@/scss/variables/system-colors.scss';

.sections-group + .section-option-group {
    margin-top: 16px;
}
.sections-group {
    margin: 0 -16px;
    &.highlight {
        background: $item-hover;
        z-index: 2;
    }
    &__header {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 16px;
        min-height: 56px;
        background: none;
        cursor: pointer;
        position: relative;
        transition: $transition-fast;
        font-weight: 500;
        border: none;

        &:hover {
            background: #edf5fc;
        }

        &-title {
            font-size: 1.1429rem;
            color: #2c2d2e;
            cursor: pointer;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            margin-right: auto;
        }

        &-more {
            cursor: pointer;
            color: inherit;
            &.is-open {
                transform: rotate(180deg);
            }
        }
    }

    &__content {
        display: block;
        max-height: 0;
        opacity: 0;
        pointer-events: none;
        transition: $transition-default;

        &.is-open {
            max-height: 5000px;
            opacity: 1;
            pointer-events: auto;
            .sections-group__content-wrapper {
                display: block;
            }
        }

        &-wrapper {
            padding: 16px;
            display: none;
        }
    }
}
</style>
