import apiClient from '@/api/apiClient';
import CustomError from '@/entities/customError';
import { Lead } from '@/services/services.types';

class LeadService {
    /**
     * Получение лидов
     * @returns {Promise} - массив объектов лидов
     * @param {SortingRequestParams} params - параметры запроса (sort, direction, ids, etc.)
     */
    //TODO: type для параметров лида
    async getAll(params): Promise<[CustomError | null, Lead[] | []]> {
        const url: string = `/leads`;
        const defaultValue = [];
        const errorPath: string = '[api:lead:getAll]';
        const config = { params };
        return await apiClient.admin.get({ url, defaultValue, errorPath, config });
    }

    /**
     * Получение конкретного лида
     * @param {String} id - id лида
     * @returns {Promise} - объект лида
     */

    async getOne(id: string): Promise<[CustomError | null, Lead | null]> {
        const url: string = `/leads/${id}`;
        const defaultValue = null;
        const errorPath: string = '[api:lead:getOne]';
        return await apiClient.admin.get({ url, defaultValue, errorPath });
    }

    /**
     * Создание лида
     * @param {Lead} lead - объект лида
     * @returns {Promise} - объект созданного лида
     */

    async createOne(lead: Lead): Promise<[CustomError | null, Lead | null]> {
        const url: string = `/leads`;
        const defaultValue = null;
        const errorPath: string = '[api:lead:createOne]';
        return await apiClient.admin.post({ url, defaultValue, errorPath, data: lead });
    }

    /**
     * Обновление лида
     * @param {Lead} lead - объект лида
     * @returns {Promise} - объект обновлённого лида
     */

    async updateOne(lead: Lead): Promise<[CustomError | null, Lead | null]> {
        const url: string = `/leads/${lead.id}`;
        const defaultValue = null;
        const errorPath: string = '[api:lead:updateOne]';
        return await apiClient.admin.put({ url, defaultValue, errorPath, data: lead });
    }
    /**
     * Удаление лида
     * @param {Array} id - id лида
     * @returns {Promise} - null
     */

    async removeOne(id: string): Promise<[CustomError | null, null]> {
        const url: string = `/leads/${id}`;
        const defaultValue = null;
        const errorPath: string = '[api:lead:removeOne]';
        return await apiClient.admin.delete({ url, defaultValue, errorPath });
    }
}

export default new LeadService();
