<template>
    <div class="page">
        <div class="page-header">
            <h1 class="page-header__text" v-if="catalogId && !initialLoading">
                Catalog: {{ catalog.title | capitalize }}
            </h1>
            <progress-circular v-else-if="catalogId && !isLoaded" class="spinner"></progress-circular>
            <h1 class="page-header__text" v-else>
                {{ onlyDisabled ? $t('menus.pageHeaders.disabledProducts') : $t('menus.pageHeaders.products') }}
            </h1>
            <div class="product-list__header-buttons">
                <Button type="outlined" :to="importBtn.to" v-on="importBtn.listeners" icon="file_upload">
                    {{ $t(importBtn.title) }}
                </Button>
                <Button type="outlined" v-on="exportBtn.listeners" icon="file_download">
                    {{ $t(exportBtn.title) }}
                </Button>
                <Button :to="{ name: 'Create Product' }" icon="add_circle_outline">
                    {{ $t('entities.add') }}
                </Button>
            </div>
        </div>
        <ImportModal v-model="isImportModalShow" />
        <product-quick-edit
            v-model="isEditModalOpen"
            :product="editingProduct"
            @product-updated="onUpdateProduct"
        ></product-quick-edit>
        <error-block v-if="!isAvailable"></error-block>
        <Table
            data-cy="products-table"
            v-else
            :search="search"
            :columns="headers"
            :items="items"
            :is-data-loaded="isLoaded"
            :show-select="true"
            :items-info="itemsInfo"
            v-model="selected"
            :pagination="pagination"
            :sort-by="sortBy"
            :sort-desc="sortDesc"
            :show-remove-all="!catalogId && !onlyDisabled"
            @change-per-page="changePerPage"
            @update:sort="updateSort"
            @update-search-string="updateSearchString"
            @delete-selected-items="openDeletionModal({ items: $event })"
            @delete-all-items="openDeletionModal({ deleteAll: true })"
        >
            <template v-slot:selected-actions>
                <template v-if="selected.length">
                    <Button
                        icon="unpublished"
                        type="text"
                        class="table__selected-action"
                        @click="changeSelected('enabled', false)"
                    >
                        {{ $t('lists.actions.disableSelected') }}</Button
                    >
                    <Button
                        type="text"
                        icon="check_circle"
                        class="table__selected-action"
                        @click="changeSelected('enabled', true)"
                    >
                        {{ $t('lists.actions.enableSelected') }}</Button
                    >
                    <Button
                        type="text"
                        icon="visibility_off"
                        class="table__selected-action"
                        @click="changeSelected('hidden', true)"
                    >
                        {{ $t('lists.actions.hideSelected') }}</Button
                    >
                    <Button
                        type="text"
                        icon="visibility"
                        class="table__selected-action"
                        @click="changeSelected('hidden', false)"
                        >{{ $t('lists.actions.showSelected') }}</Button
                    >
                </template>
            </template>
            <!--  ACTIONS  -->
            <template v-slot:item.actions="{ item }">
                <div class="table__actions">
                    <a target="_blank" :href="`https://${instanceFull.primaryDomain}/products/${item.slug}`">
                        <Button type="icon" icon="open_in_new" />
                    </a>
                    <Button
                        :title="$t('lists.editButton.product')"
                        :to="{ name: 'Edit Product', params: { id: item.id } }"
                        icon="edit"
                        type="icon"
                    />
                    <Button
                        :title="$t('lists.deleteButton.product')"
                        @click="openDeletionModal({ items: item })"
                        type="icon"
                        icon="delete"
                    />
                </div>
            </template>
            <!--  IMAGE  -->
            <template v-slot:item.image="{ item }">
                <img
                    v-if="item.images.length"
                    class="table__image"
                    :alt="getImage(item).alt"
                    :src="imageUrl + '/thumbs/inset/80x80' + getImage(item).uri"
                />
                <img v-else class="table__image -default" alt="No image" src="@/assets/images/no-photo.svg" />
            </template>
            <!--  PRICE  -->
            <template v-slot:item.price="{ item }">
                <span class="table-edit" @click="openEditModal(item)">
                    <span class="table-edit__link"> {{ item.getListPrice(currency) }}</span>
                    <span class="material-icons-outlined table-edit__icon">edit</span>
                </span>
            </template>
            <!--  STOCK  -->
            <template v-slot:item.stock="{ item }">
                <a class="quick-edit__btn" @click="openEditModal(item)">
                    <chip v-if="item.variations.every((item) => item.stock === 0)" type="error">
                        {{ $t('entities.product.stock.outOfStock') }}
                    </chip>
                    <chip v-else-if="item.variations.some((item) => item.stock === 0)" type="warning">
                        {{ $t('entities.product.stock.partially') }}
                        {{ item.stockSummary }}
                    </chip>
                    <chip v-else type="success">
                        {{ $t('entities.product.stock.inStock') }}
                        {{ item.stockSummary }}
                    </chip>
                </a>
            </template>
            <!--  TITLE  -->
            <template v-slot:item.title="{ item }">
                <span class="table-edit">
                    <router-link class="table-edit__link" :to="{ name: 'Edit Product', params: { id: item.id } }">{{
                        item.title
                    }}</router-link>
                    <span class="material-icons-outlined table-edit__icon">edit</span>
                </span>
                <div>
                    <template v-for="(catalog, index) in item.catalogs">
                        <router-link
                            class="product-table__catalogs-link"
                            :key="`${catalog.id}-${index}`"
                            :to="{ name: 'Edit Catalog', params: { id: catalog.id } }"
                        >
                            {{ catalog.title }}</router-link
                        ><template v-if="index !== item.catalogs.length - 1">,</template>
                    </template>
                </div>
            </template>
            <!--  ENABLED  -->
            <template v-slot:item.enabled="{ item }">
                <input-switch
                    :value="item.enabled"
                    name="enabled"
                    size="small"
                    @input="onUpdateFieldValue({ name: 'enabled', value: $event }, item)"
                ></input-switch>
            </template>
            <!-- <template v-slot:item.enabled="{ item }">
                <ui-page-status :value="item" @change="changeProduct(item)"></ui-page-status>
            </template> -->
            <!--  HIDDEN  -->
            <template v-slot:item.hidden="{ item }">
                <input-switch
                    :value="item.hidden"
                    name="hidden"
                    size="small"
                    @input="onUpdateFieldValue({ name: 'hidden', value: $event }, item)"
                ></input-switch>
            </template>
            <!--  REVIEWS  -->
            <template v-slot:item.reviews="{ item }">
                <Button type="text" :to="{ name: 'Product Review List', query: { product: item.id } }">
                    {{ $t('lists.actions.viewAll') }}({{ item.reviewCount }})
                </Button>
            </template>
        </Table>
    </div>
</template>

<script>
import ProductService from '@/services/ProductService';
import CatalogService from '@/services/CatalogService';
import { Pagination } from '@/services/PaginationService';
import SearchService from '@/services/SearchService';
import { debounce } from 'lodash';
import EventEmitter from '@/helpers/eventEmitter.ts';
import ErrorBlock from '@/components/common/ErrorBlock';
import { imageUrl } from '@/helpers/values';
import ImportModal from '@/components/product/ImportModal';
import ProductQuickEdit from '@/components/product/ProductQuickEdit';
import Product from '@/entities/product/Product';
import InputSwitch from '@/components/form/controls/InputSwitch.vue';
import EntityImage from '@/entities/image/EntityImage';
import Table from '@/components/common/Table';
import Button from '@/components/common/Button';
import { mapActions, mapGetters } from 'vuex';
import ProgressCircular from '@/components/common/ProgressCircular';
import Chip from '@/components/common/Chip';

export default {
    name: 'ProductList',
    components: {
        Chip,
        ProgressCircular,
        ProductQuickEdit,
        ErrorBlock,
        ImportModal,
        InputSwitch,
        Table,
        Button,
    },
    async created() {
        await this.init();
    },
    data: () => ({
        isLoaded: false,
        items: [],
        images: {},
        isAvailable: true,
        search: { searchable: 'product', q: '' },
        isImportModalShow: false,
        initialLoading: true,
        sortBy: 'title',
        previousSearch: '',
        sortDesc: false,
        pagination: null,
        isEditModalOpen: false,
        count: null,
        stock: null,
        editingProduct: new Product(),
        catalog: null,
        selected: [],
        imageUrl: imageUrl(),
    }),
    computed: {
        catalogId() {
            return this.$route.params.id;
        },
        currency() {
            return this.$store.getters['config/getCurrency'];
        },
        onlyDisabled() {
            return this.$route.name === 'Product Disabled List';
        },
        headers() {
            const headers = [
                { text: this.$t('lists.columns.image'), value: 'image', width: '80px' },
                { text: this.$t('lists.columns.title'), value: 'title', sortable: true },
                { text: this.$t('lists.columns.price'), value: 'price', width: '150px', sortable: true },
                { text: this.$t('lists.columns.stock'), value: 'stock', width: '150px', sortable: true },
                /*          { text: this.$t('lists.columns.published'), value: 'enabled', width: '10%' },*/
                { text: this.$t('lists.columns.reviews'), value: 'reviews', width: '150px' },
                { text: this.$t('lists.columns.enabled'), value: 'enabled', width: '70px', sortable: true },
                { text: this.$t('lists.columns.hidden'), value: 'hidden', width: '70px', sortable: true },
                { value: 'actions', width: '114px' },
            ];
            return headers;
        },
        pageNumber() {
            return this.$store.getters['globalVars/getProductsPage'];
        },
        searchString() {
            return this.$route.query.q;
        },
        exportBtn() {
            return {
                title: 'entities.product.buttons.exportButton',
                listeners: {
                    click: this.exportBtnHandler,
                },
            };
        },
        importBtn() {
            return {
                title: 'entities.product.buttons.importButton',
                listeners: {
                    click: this.importBtnHandler,
                },
            };
        },
        itemsInfo() {
            if (this.stock !== null && this.count !== null) {
                return [
                    { title: this.$t('entities.product.stock.inStock'), value: this.stock },
                    { title: this.$t('entities.product.total'), value: this.count },
                ];
            }
            return null;
        },
        ...mapGetters('globalVars', { instanceFull: 'getInstanceFull' }),
    },
    methods: {
        async init() {
            EventEmitter.on('delete-product', this.deleteItems);
            //приходится скидывать пагинацию, чтобы решить проблему с переходом на товары из конкретного каталога
            this.changeProductsPage(1);
            const limit = this.$store.getters['globalVars/getProductsLimit'];
            //total получаем и проставляем в getItems
            this.pagination = new Pagination({
                moduleName: 'products',
                limit,
                pageNumber: this.pageNumber,
                options: [10, 30, 50],
            });
            if (this.$route.query.q) {
                this.search.q = this.$route.query.q;
                this.previousSearch = this.$route.query.q;
            }
            await this.getItems();
            EventEmitter.on('refresh-products-page', async () => {
                await this.getItems();
            });
            this.initialLoading = false;
        },
        ...mapActions('globalVars', { changeProductsPage: 'changeProductsPage' }),
        async getItems() {
            this.isLoaded = false;
            const isSearching = !!this.search.q?.length;
            const searchChanged = this.search.q !== this.previousSearch;

            const params = {
                sort: this.sortBy,
                limit: this.pagination.limit,
                offset: searchChanged ? null : this.pagination.offset,
                direction: this.sortDesc ? 'desc' : this.sortDesc === false ? 'asc' : null,
                q: isSearching ? this.search.q : null,
                fields: isSearching ? ['title'] : null,
            };
            if (this.onlyDisabled) {
                params.enabled = false;
            }
            const getter = isSearching ? this.searchProducts : this.getProducts;
            const [error, result] = await getter(params);
            if (error) {
                error.alert();
                this.isAvailable = false;
                this.isLoaded = true;
                return;
            }
            this.count = isSearching ? null : result?.count;
            this.pagination.total = result?.count;
            this.stock = isSearching ? null : result?.stock;
            if (this.catalogId) {
                await this.getCatalog(this.catalogId);
            }
            if (searchChanged) {
                this.pagination.pushToHistory(1);
                this.previousSearch = this.search.q;
            }
            this.items = isSearching
                ? result.data.map((item) => new Product(item))
                : result.products.map((item) => new Product(item));

            this.images = Product.getDefaultImages(this.items);
            this.isLoaded = true;
        },
        async getProducts(params) {
            return this.catalogId
                ? await CatalogService.getProductsInCatalog(this.catalogId, params)
                : await ProductService.getAll(params);
        },

        async getTotal() {
            const [error, total] = await Pagination.getTotal('products');
            if (error) {
                error.notify();
            }
            return total;
        },
        async searchProducts(params) {
            return await SearchService.entitySearch('product', params);
        },
        async getCatalog(catalogId) {
            const [error, result] = await CatalogService.getOne(catalogId);
            if (error) {
                error.notify();
                this.isAvailable = false;
                return;
            }
            this.catalog = result;
        },
        updateSearchString({ searchString }) {
            this.search.q = searchString;
            if (searchString?.length) this.pagination.reset();
            this.getItems();
        },
        changePerPage(perPage) {
            this.pagination.changeLimit(perPage.value);
            this.getItems();
        },
        updateSort(sortBy, sortDesc) {
            this.sortBy = sortBy;
            this.sortDesc = sortDesc;
            this.getItems();
        },
        async deleteItems(items, deleteAll) {
            this.isLoaded = false;
            if (deleteAll) {
                const [error] = await ProductService.removeAll();
                if (!error) {
                    await this.getItems();
                    EventEmitter.trigger('show-noty', {
                        type: 'success',
                        text: this.$tc('notifications.deleted', 2, {
                            entity: this.$tc('entities.product.title', 2),
                        }),
                    });
                }
                this.$store.dispatch('modals/closeDeletionModal');
            } else {
                const promises = items.map((item) => ProductService.removeOne(item.id));
                const results = await Promise.all(promises);
                const normalized = await this.pagination.normalize(this.items);
                if (!this.catalogId) {
                    this.pagination.total = await this.getTotal();
                }
                this.$store.dispatch('modals/closeDeletionModal');
                if (!normalized) {
                    await this.getItems();
                }
                console.log(results);
                if (
                    results.every((item) => {
                        const [error] = item;
                        return !error;
                    })
                ) {
                    if (items.length === 1) {
                        EventEmitter.trigger('show-noty', {
                            type: 'success',
                            text: this.$tc('notifications.deleted', 1, {
                                entity: this.$tc('entities.product.title', 1),
                            }),
                        });
                    } else {
                        EventEmitter.trigger('show-noty', {
                            type: 'success',
                            text: this.$tc('notifications.deleted', 2, {
                                entity: this.$tc('entities.product.title', 2),
                            }),
                        });
                    }
                }
            }
            this.isLoaded = true;
        },
        changeProduct: debounce(async function(product) {
            await ProductService.updateOne(product);
        }, 300),
        openDeletionModal({ items, deleteAll = false }) {
            if (deleteAll) {
                // ProductService.removeAll();
                this.$store.dispatch('modals/openDeletionModal', { items, entity: 'product', deleteAll });
            } else {
                this.$store.dispatch('modals/openDeletionModal', { items, entity: 'product' });
            }
        },
        async exportBtnHandler() {
            const [error, result] = await ProductService.export();
            if (error) {
                error.notify();
                return null;
            }
            const blob = new Blob([result], { type: 'text/csv;charset=utf-8;' });
            const link = document.createElement('a');
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', 'export.csv');
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        importBtnHandler() {
            this.isImportModalShow = true;
        },
        openEditModal(item) {
            this.isEditModalOpen = true;
            this.editingProduct = item;
        },
        async changeSelected(name, value) {
            this.isLoaded = false;

            const ids = this.selected.filter((item) => item[name] !== value).map((item) => item.id);
            if (!ids.length) {
                this.selected = [];
                this.isLoaded = true;
                return;
            }
            this.selected = [];
            const promises = ids.map((id) => ProductService.getOne(id));
            const result = await Promise.all(promises);
            const products = result.map((item) => new Product(item[1]));
            const newProducts = products.map((item) => {
                item[name] = value;
                return item.data;
            });
            const [error] = await ProductService.updateBatch(newProducts);
            if (error) {
                this.isLoaded = true;
                error.notify();
                return;
            }
            this.getItems();
        },
        //arg - product
        async onUpdateProduct() {
            //this.pagination.reset();
            this.getItems();
            //TODO: раскомментить после обновления продуктов
            //верхнее убрать
            /*            const index = this.items.findIndex((item) => {
                return item.id === product.id;
            });
            const items = cloneDeep(this.items);
            this.items = items;
            this.images = await ProductService.getDefaultImages(this.items);*/
        },
        //TODO: убрать после обновления продукта
        getImage(item) {
            return item.images[0] instanceof EntityImage ? item.images[0].file : item.images[0];
        },

        async onUpdateFieldValue(event, product) {
            const { name, value } = event;
            if (product[name] === value) return;
            //TODO: убрать после обновления продукта
            product[name] = value;
            const [error, result] = await ProductService.getOne(product.id);
            if (error) console.log(error);
            const productToUpdate = new Product(result);
            productToUpdate[name] = value;
            await ProductService.updateOne(productToUpdate.data);
            //TODO: раскоментить после обновления продукта
            // await ProductService.updateOnae(product.data)
        },
    },
    beforeDestroy() {
        EventEmitter.off('delete-product', this.deleteItems);
    },
    watch: {
        catalogId() {
            this.getProducts();
        },
        pageNumber(newValue) {
            this.pagination.pageNumber = newValue;
            this.getItems();
        },
        onlyDisabled() {
            this.init();
        },
    },
};
</script>
<style lang="scss">
@import '@/scss/variables.scss';
.quick-edit__btn {
    .chip {
        cursor: pointer !important;
    }
}
.product-table {
    &__catalogs-link {
        color: var(--v-on-surface-medium-base) !important;
        &:hover {
            color: var(--v-on-surface-high-base) !important;
        }
    }
}
.product-list {
    &__header-buttons {
        display: flex;
        gap: 22px;
    }
}
</style>
