<template>
    <div class="builder-section-new-list-item" v-on="$listeners" @click="onAddSection">
        <div class="builder-section-new-list-item__preview">
            <img :src="sectionFixture.preview" style="display: block; width: 100%; margin-bottom: 10px;" />
        </div>
        <div class="builder-section-new-list-item__info">
            <span class="builder-section-new-list-item__title text-body-2">
                {{ sectionFixture.title }} {{ index }}
            </span>
        </div>
    </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';

export default {
    name: 'BuilderSectionNewListItem',
    props: {
        sectionFixture: { type: Object, required: true },
        index: { type: Number, required: true },
    },
    methods: {
        ...mapMutations('builder', {
            updateNewSectionListVisible: 'updateNewSectionListVisible',
        }),
        ...mapActions('builder', {
            addSection: 'addSection',
            updateActiveItem: 'updateActiveItem',
        }),
        onAddSection() {
            this.addSection(this.sectionFixture.data);
            this.updateNewSectionListVisible(false);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/scss/variables';
@import '@/scss/variables/system-colors';

.builder-section-new-list-item {
    cursor: pointer;
    padding: 20px 0;
    transition: $transition-default;
    text-align: center;

    &:hover {
        color: $primary-hover;
    }
}

.builder-section-new-list-item + .builder-section-new-list-item {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
}
</style>
