import {
    BIG_STRING,
    BUTTON,
    CHECKBOX,
    HTML,
    HTML_SCREEN,
    ICON,
    IMAGE_BUILDER,
    IMAGE_SCREEN_BUILDER,
    LINK,
    SECTION_LAYOUT,
    SELECT,
    SELECT_SCREEN,
    STRING,
} from '@/helpers/fieldTypes.no-vuetify';
import PageService from '@/services/PageService';
import CatalogService from '@/services/CatalogService';
import icons from '@/entities/icons';

export const BaseFields = {
    color: (labelTrans) => ({
        value: '',
        component: 'ColorPicker',
        props: {
            labelTrans: labelTrans,
            btnReset: true,
            mode: 'hexa',
        },
    }),
    image: (labelTrans) => ({
        value: '',
        ...IMAGE_BUILDER,
        props: {
            btnReset: true,
            labelTrans: labelTrans,
        },
    }),
    html: (labelTrans) => ({
        value: '',
        ...HTML,
        props: {
            labelTrans: labelTrans,
            rules: [],
            errors: [],
        },
    }),
    text: (labelTrans, value = '') => ({
        value: value,
        ...STRING,
        props: {
            labelTrans: labelTrans,
            rules: [],
        },
    }),
    textBig: (labelTrans) => ({
        value: '',
        ...BIG_STRING,
        props: {
            labelTrans: labelTrans,
            rules: [],
        },
    }),
};

export const Section = {
    width: {
        value: 'full',
        component: 'ButtonToggles',
        ...{
            props: {
                labelTrans: 'builder.sectionOptions.props.width',
                translate: true,
                items: [
                    { icon: 'width_full', text: 'Full', value: 'full' },
                    { icon: 'width_wide', text: 'Content', value: 'content' },
                ],
            },
        },
    },
    padding: {
        value: {
            desktop: { top: 28, right: 0, bottom: 28, left: 0 },
            tablet: { top: 28, right: 0, bottom: 28, left: 0 },
            mobile: { top: 16, right: 0, bottom: 16, left: 0 },
        },
        component: 'CustomizePaddingTabs',
        ...{
            props: {
                max: 250,
                labelTrans: 'builder.sectionOptions.props.padding',
            },
        },
    },
    borderRadius: {
        value: {
            desktop: 0,
        },
        component: 'CustomizeBorderRadiusTabs',
        ...{
            props: {
                max: 100,
                labelTrans: 'builder.sectionOptions.props.borderRadius',
            },
        },
    },
    background: {
        type: 'field',
        value: 'none',
        component: 'SelectBackground',
        ...{
            props: {
                translate: true,
                items: [
                    { text: 'builder.sectionOptions.items.none', value: 'none' },
                    { text: 'builder.sectionOptions.items.light', value: 'light' },
                    { text: 'builder.sectionOptions.items.middle', value: 'middle' },
                    { text: 'builder.sectionOptions.items.dark', value: 'dark' },
                    { text: 'builder.sectionOptions.items.custom', value: 'custom' },
                ],
            },
        },
    },
};

export const Container = {
    width: {
        value: 'content',
        component: 'ButtonToggles',
        ...{
            props: {
                labelTrans: 'builder.sectionOptions.props.width',
                translate: true,
                items: [
                    { icon: 'width_full', text: 'Full', value: 'full' },
                    { icon: 'width_wide', text: 'Content', value: 'content' },
                ],
            },
        },
    },
    padding: {
        value: {
            desktop: null,
            tablet: null,
            mobile: null,
        },
        component: 'CustomizePaddingTabs',
        ...{
            props: {
                max: 100,
                labelTrans: 'builder.sectionOptions.props.padding',
            },
        },
    },
    borderRadius: {
        value: {
            desktop: 0,
        },
        component: 'CustomizeBorderRadiusTabs',
        ...{
            props: {
                max: 100,
                labelTrans: 'builder.sectionOptions.props.borderRadius',
            },
        },
    },
    background: {
        type: 'field',
        value: 'none',
        component: 'SelectBackground',
        ...{
            props: {
                translate: true,
                items: [
                    { text: 'builder.sectionOptions.items.none', value: 'none' },
                    { text: 'builder.sectionOptions.items.light', value: 'light' },
                    { text: 'builder.sectionOptions.items.middle', value: 'middle' },
                    { text: 'builder.sectionOptions.items.dark', value: 'dark' },
                    { text: 'builder.sectionOptions.items.custom', value: 'custom' },
                ],
            },
        },
    },
};

export const TextStyle = {
    color: BaseFields.color('builder.sectionOptions.props.color'),
    size: {
        type: 'number',
        component: 'InputNumberAndRange',
        value: 15,
        props: {
            min: 12,
            max: 40,
            labelTrans: 'settings.header.menuOptionsTextSize',
        },
    },
    fontStyle: {
        value: 'normal',
        ...SELECT,
        ...{
            props: {
                translate: true,
                labelTrans: 'builder.sectionOptions.props.fontStyle',
                items: [
                    { text: 'Normal', value: 'normal' },
                    { text: 'Italic', value: 'italic' },
                ],
            },
        },
    },
    textAlign: {
        value: 'center',
        component: 'ButtonToggles',
        ...{
            props: {
                labelTrans: 'builder.sectionOptions.props.textAlign',
                translate: true,
                items: [
                    { icon: 'format_align_left', value: 'left' },
                    { icon: 'format_align_center', value: 'center' },
                    { icon: 'format_align_right', value: 'right' },
                ],
            },
        },
    },
    fontSizeScreen: {
        type: 'object',
        component: 'InputNumberAndRangeScreen',
        value: {
            desktop: 31,
            tablet: 28,
            mobile: 24,
        },
        props: {
            min: 12,
            max: 50,
            labelTrans: 'settings.header.menuOptionsTextSize',
        },
    },
};

export const Heading = {
    title: BaseFields.text('builder.sectionOptions.props.title', 'Heading H2'),
    colorTitle: BaseFields.color('builder.sectionOptions.props.color'),
    fontStyleTitle: { ...TextStyle.fontStyle },
    note: BaseFields.textBig('builder.sectionOptions.props.note'),
    colorNote: BaseFields.color('builder.sectionOptions.props.color'),
    fontStyleNote: { ...TextStyle.fontStyle },
    textAlign: { ...TextStyle.textAlign },
    marginBottom: {
        type: 'field',
        value: {
            desktop: 5,
        },
        component: 'CustomizeMarginTabs',
        ...{
            props: {
                max: 100,
                labelTrans: 'builder.sectionOptions.props.marginBottom',
            },
        },
    },
};

export const Content = {
    alignHorizontalScreen: {
        value: {
            desktop: 'center',
            tablet: 'center',
            mobile: 'center',
        },
        ...SELECT_SCREEN,
        ...{
            props: {
                translate: true,
                labelTrans: 'builder.sectionOptions.props.alignHorizontal',
                items: [
                    { text: 'left', value: 'flex-start' },
                    { text: 'center', value: 'center' },
                    { text: 'right', value: 'flex-end' },
                ],
            },
        },
    },
    alignVerticalScreen: {
        value: {
            desktop: 'center',
            tablet: 'center',
            mobile: 'center',
        },
        ...SELECT_SCREEN,
        ...{
            props: {
                translate: true,
                labelTrans: 'builder.sectionOptions.props.alignVertical',
                items: [
                    { text: 'top', value: 'flex-start' },
                    { text: 'center', value: 'center' },
                    { text: 'bottom', value: 'flex-end' },
                ],
            },
        },
    },
    link: {
        value: '',
        ...LINK,
        props: {
            labelTrans: 'builder.sectionOptions.props.link',
        },
    },
    categoryLink: {
        value: '',
        ...LINK,
        props: {
            labelTrans: 'builder.sectionOptions.props.link',
        },
        events: {
            linkChanged: async function(link) {
                const currentCategoryItem = this;
                if (link.title) {
                    currentCategoryItem.options.heading.title.value = link.title;
                    currentCategoryItem.options.content.imageAlt.value = link.title;
                }
                let targetResponse;
                if (link.type === 'page') targetResponse = await PageService.getOne(link.id);
                if (link.type === 'pages') targetResponse = await PageService.getOne(link.id);
                if (link.type === 'catalog') targetResponse = await CatalogService.getOne(link.id);
                if (link.type === 'catalogs') targetResponse = await CatalogService.getOne(link.id);
                if (Array.isArray(targetResponse) && targetResponse[1]) {
                    const target = targetResponse[1];
                    if (target.previewImageId) currentCategoryItem.options.content.image.value = target.previewImageId;
                }
            },
        },
    },
    categoryLinkCangeImage: {
        value: '',
        ...LINK,
        props: {
            labelTrans: 'builder.sectionOptions.props.link',
        },
        events: {
            linkChanged: async function(link) {
                const currentCategoryItem = this;
                let targetResponse;
                if (link.type === 'page') targetResponse = await PageService.getOne(link.id);
                if (link.type === 'pages') targetResponse = await PageService.getOne(link.id);
                if (link.type === 'catalog') targetResponse = await CatalogService.getOne(link.id);
                if (link.type === 'catalogs') targetResponse = await CatalogService.getOne(link.id);
                if (Array.isArray(targetResponse) && targetResponse[1]) {
                    const target = targetResponse[1];
                    if (target.previewImageId) currentCategoryItem.options.content.image.value = target.previewImageId;
                }
            },
        },
    },
    note: {
        value: '',
        ...STRING,
        props: {
            labelTrans: 'builder.sectionOptions.props.note',
        },
    },
    textSmall: BaseFields.text('builder.sectionOptions.props.note'),
    text: BaseFields.text('builder.sectionOptions.props.note'),
    btnText: BaseFields.text('builder.sectionOptions.props.btnText'),
    html: BaseFields.html('builder.sectionOptions.props.html'),
    htmlScreen: {
        value: {
            desktop: '',
            tablet: '',
            mobile: '',
        },
        ...HTML_SCREEN,
        props: {
            labelTrans: 'builder.sectionOptions.props.html',
            errors: [],
        },
    },
    htmlBefore: BaseFields.html('builder.sectionOptions.props.htmlBefore'),
    htmlAfter: BaseFields.html('builder.sectionOptions.props.htmlAfter'),
    icon: {
        value: 'person',
        ...ICON,
        props: {
            ...ICON.props,
            labelTrans: 'builder.sectionOptions.props.icon',
        },
    },
    iconAlign: { ...TextStyle.textAlign },
    benefitIcon: {
        // empty, icon, image, text
        value: { type: 'icon', data: 'person' },
        component: 'SelectBenefitIcon',
        props: {
            icons: [...icons],
            options: [
                { text: 'icon', value: 'icon' },
                { text: 'image', value: 'image' },
                { text: 'text', value: 'text' },
                { text: 'none', value: 'none' },
            ],
            labelTrans: 'builder.sectionOptions.props.icon',
        },
    },
    image: BaseFields.image('builder.sectionOptions.props.image'),
    imageMobile: BaseFields.image('builder.sectionOptions.props.imageMobile'),
    imagesByScreen: {
        value: '',
        ...IMAGE_SCREEN_BUILDER,
        props: {
            btnReset: true,
            labelTrans: 'builder.sectionOptions.props.image',
        },
    },
    imageAlt: BaseFields.text('builder.sectionOptions.props.imageAlt'),
    imageTitle: BaseFields.text('builder.sectionOptions.props.imageTitle'),
    opacity: {
        type: 'NUMBER',
        component: 'InputRange',
        value: 75,
        props: {
            type: 'number',
            min: 0,
            max: 100,
            labelTrans: 'builder.sectionOptions.props.opacity',
        },
    },
    mapUrl: {
        value:
            'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d462260.58289236523!2d55.20963516679683!3d25.155603155877923!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43496ad9c645%3A0xbde66e5084295162!2sDubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2sru!4v1673507398213!5m2!1sen!2sru',
        ...STRING,
        ...{
            props: {
                labelTrans: 'builder.sectionOptions.props.mapUrl',
                note: 'builder.sectionOptions.props.mapUrlNote',
            },
            events: {
                change: function(data) {
                    const isTag = data.indexOf('src="');
                    if (isTag > 0) {
                        const contentStart = data.indexOf('src="') + 5;
                        let content = data.slice(contentStart);
                        const contentEnd = content.indexOf('"');
                        content = content.slice(0, contentEnd);
                        if (this?.options?.content?.mapUrl) this.options.content.mapUrl.value = content;
                    }
                },
            },
        },
    },
    listWidth: {
        value: 'normal',
        ...SELECT,
        ...{
            props: {
                labelTrans: 'builder.sectionOptions.props.listWidth',
                translate: true,
                items: [
                    { text: 'builder.sectionOptions.items.normal', value: 'normal' },
                    { text: 'builder.sectionOptions.items.small', value: 'small' },
                ],
            },
        },
    },
    containerWidth: {
        value: 'normal',
        ...SELECT,
        ...{
            props: {
                labelTrans: 'builder.sectionOptions.props.containerWidth',
                translate: true,
                items: [
                    { text: 'builder.sectionOptions.items.fullwidth', value: 'fullwidth' },
                    { text: 'builder.sectionOptions.items.normal', value: 'normal' },
                    // { text: 'builder.sectionOptions.items.small', value: 'small' },
                ],
            },
        },
    },
    useCarousel: {
        value: 'no',
        ...SELECT,
        ...{
            props: {
                translate: true,
                labelTrans: 'builder.sectionOptions.props.useCarousel',
                items: [
                    { text: 'builder.sectionOptions.items.useNo', value: 'no' },
                    { text: 'builder.sectionOptions.items.useMobile', value: 'mobile' },
                    { text: 'builder.sectionOptions.items.useBoth', value: 'both' },
                ],
            },
        },
    },
    rating: {
        value: 5,
        ...SELECT,
        ...{
            props: {
                labelTrans: 'builder.sectionOptions.props.rating',
                translate: true,
                items: [0, 1, 2, 3, 4, 5],
            },
        },
    },
    showButton: {
        value: false,
        ...CHECKBOX,
        props: {
            noLabel: true,
            labelTrans: 'builder.sectionOptions.props.showButton',
            text: 'builder.sectionOptions.props.showButton',
        },
    },
    borderRadius: {
        value: false,
        ...CHECKBOX,
        props: {
            noLabel: true,
            labelTrans: 'builder.sectionOptions.props.borderRadius',
            text: 'builder.sectionOptions.props.borderRadius',
        },
    },
    color: BaseFields.color('builder.sectionOptions.props.color'),
    verticalAlign: {
        value: 'flex-start',
        ...SELECT,
        ...{
            props: {
                translate: true,
                labelTrans: 'builder.sectionOptions.props.alignVertical',
                items: [
                    { text: 'top', value: 'flex-start' },
                    { text: 'center', value: 'center' },
                    { text: 'bottom', value: 'flex-end' },
                ],
            },
        },
    },
    horizontalAlign: {
        value: 'left',
        ...SELECT,
        ...{
            props: {
                translate: true,
                labelTrans: 'builder.sectionOptions.props.alignHorizontal',
                items: [
                    { text: 'left', value: 'left' },
                    { text: 'center', value: 'center' },
                    { text: 'right', value: 'right' },
                ],
            },
        },
    },
    button: {
        value: {
            isShow: false,
            styles: 'primary',
            text: 'Button',
            textColor: null,
            bgColor: null,
            link: null,
            iconLeft: null,
            iconRight: null,
            type: 'Link',
            url: null,
        },
        ...BUTTON,
    },
};

export const Background = {
    value: 'white',
    ...SELECT,
    ...{
        props: {
            translate: true,
            items: [
                { text: 'builder.sectionOptions.items.light', value: 'light' },
                { text: 'builder.sectionOptions.items.middle', value: 'middle' },
                { text: 'builder.sectionOptions.items.dark', value: 'dark' },
            ],
        },
    },
};

export const Footer = {
    note: BaseFields.textBig('builder.sectionOptions.props.note'),
    colorNote: { ...TextStyle.color },
    textAlign: { ...TextStyle.textAlign },
    marginTop: {
        type: 'field',
        value: {
            desktop: 15,
            mobile: 5,
        },
        component: 'CustomizeMarginTabs',
        ...{
            props: {
                max: 100,
                labelTrans: 'builder.sectionOptions.props.marginTop',
            },
        },
    },
    button: {
        value: {
            isShow: false,
            styles: 'primary',
            text: 'Button',
            textColor: null,
            bgColor: null,
            link: null,
            iconLeft: null,
            iconRight: null,
            type: 'Link',
            url: null,
        },
        ...BUTTON,
    },
};

export const SectionItemsStyle = {
    template: {
        value: 'style1',
        ...SELECT,
        ...{
            props: {
                translate: true,
                labelTrans: 'builder.sectionOptions.props.itemTemplate',
                items: [
                    { text: 'style1', value: 'style1' },
                    { text: 'style2', value: 'style2' },
                    { text: 'style3', value: 'style3' },
                    { text: 'style5', value: 'style5' },
                    { text: 'multicolor', value: 'multicolor' },
                ],
            },
            events: {
                change: function(template) {
                    // console.log('SectionItemsStyle change', ctx, this);
                    if (this && Array.isArray(this.items)) {
                        this.items.forEach((item) => {
                            item.type = template.value;
                        });
                    }
                },
            },
        },
    },
    createTemplate: (value, items) => {
        return {
            value: value,
            ...SELECT,
            ...{
                props: {
                    translate: true,
                    labelTrans: 'builder.sectionOptions.props.itemTemplate',
                    items: items,
                },
                events: {
                    change: function(template) {
                        if (this && Array.isArray(this.items)) {
                            this.items.forEach((item) => {
                                item.type = template.value;
                            });
                        }
                    },
                },
            },
        };
    },
    borderRadius: {
        type: 'NUMBER',
        component: 'InputRange',
        value: 0,
        props: {
            type: 'number',
            min: 0,
            max: 40,
            labelTrans: 'builder.sectionOptions.props.borderRadius',
        },
    },
    minHeight: {
        type: 'NUMBER',
        component: 'InputRange',
        value: 260,
        props: {
            type: 'number',
            min: 0,
            max: 600,
            labelTrans: 'builder.sectionOptions.props.minHeight',
        },
    },
    imageProportions: {
        value: 'img-1x1',
        ...SELECT,
        ...{
            props: {
                translate: true,
                labelTrans: 'builder.sectionOptions.props.imageProportions',
                items: [
                    { text: '1x1', value: 'img-1x1' },
                    { text: '16x9', value: 'img-16x9' },
                    { text: '4x3', value: 'img-4x3' },
                    { text: '2x1', value: 'img-2x1' },
                    { text: '3x1', value: 'img-3x1' },
                    { text: '3x2', value: 'img-3x2' },
                ],
            },
        },
    },
};

export const SectionLayout = {
    value: {
        desktop: {
            type: 'list',
            gridTemplate: '1x1', //иконка
            listColumn: 4,
            listAlign: 'flex-start',
            gap: 12,
            columns: 1,
            carouselColumns: 1,
        },
        tablet: {
            type: 'list',
            gridTemplate: '2x1x1', //иконка
            listColumn: 3,
            listAlign: 'flex-start',
            gap: 15,
            columns: 2,
            carouselColumns: 1,
        },
        mobile: {
            type: 'centeredColumns',
            gridTemplate: '1x1', //иконка
            listColumn: 2,
            listAlign: 'flex-start',
            gap: 10,
            columns: 3,
            carouselColumns: 1,
        },
    },
    ...SECTION_LAYOUT,
    props: {
        types: ['grid', 'list', 'carousel', 'centeredColumns'],
    },
};

export const ItemsBackground = {
    color: {
        value: '',
        component: 'ColorPicker',
        props: {
            labelTrans: 'builder.sectionOptions.props.color',
            btnReset: true,
            mode: 'hexa',
        },
    },
    overlayColor: {
        value: '',
        component: 'ColorPicker',
        props: {
            labelTrans: 'builder.sectionOptions.props.colorOverlay',
            btnReset: true,
            mode: 'hexa',
        },
    },
    overlayOpacity: {
        type: 'NUMBER',
        component: 'InputRange',
        value: 0,
        props: {
            type: 'number',
            min: 0,
            max: 100,
            labelTrans: 'builder.sectionOptions.props.opacity',
        },
    },
};
