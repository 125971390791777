import apiClient from '@/api/apiClient';
import CustomError from '@/entities/customError';
import { Order, RequestParams } from '@/services/services.types';

class OrderService {
    /**
     * Получение конкретного редиректа
     * @param {string} id - id заказа
     * @returns {Promise} - объект заказа
     */

    async getOne(id: string): Promise<[CustomError | null, Order | null]> {
        const url: string = `/orders/${id}`;
        const defaultValue = null;
        const errorPath: string = '[api:order:getOne]';
        return await apiClient.admin.get({ url, defaultValue, errorPath });
    }
    /**
     * Получение заказов
     * @param {RequestParams} params - параметры запроса (sort, direction, ids, etc.)
     * @returns {Promise} - массив объектов заказов
     */

    async getAll(params: RequestParams = {}): Promise<[CustomError | null, Order[] | []]> {
        const url: string = `/orders`;
        const defaultValue = [];
        const errorPath: string = '[api:order:getAll]';
        const config = { params };
        return await apiClient.admin.get({ url, defaultValue, errorPath, config });
    }

    /**
     * Создание заказа
     * @param {Order} order - объект заказа
     * @returns {Promise} - объект созданного заказа
     */

    async createOne(order: Order): Promise<[CustomError | null, Order | null]> {
        const url: string = `/orders`;
        const defaultValue = null;
        const errorPath: string = '[api:order:createOne]';
        return await apiClient.admin.post({ url, defaultValue, errorPath, data: order });
    }

    /**
     * Обновление заказа
     * @param {Order} order - объект заказа
     * @returns {Promise} - объект обновленного заказа
     */

    async updateOne(order: Order): Promise<[CustomError | null, Order | null]> {
        const url: string = `/orders/${order.id}`;
        const defaultValue = null;
        const errorPath: string = '[api:order:updateOne]';
        return await apiClient.admin.put({ url, defaultValue, errorPath, data: order });
    }

    /**
     * Удаление заказа
     * @param {string} id - id заказа
     * @returns {Promise} - null
     */

    async removeOne(id: string): Promise<[CustomError | null, null]> {
        const url: string = `/orders/${id}`;
        const defaultValue = null;
        const errorPath: string = '[api:order:removeOne]';
        return await apiClient.admin.delete({ url, defaultValue, errorPath });
    }
}

export default new OrderService();
