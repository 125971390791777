export default (ctx) => ({
    name: {
        value: ctx.name,
        component: 'InputText',
        type: 'field',
        props: {
            rules: ['noEmpty'],
            label: 'entities.domain.fields.name',
            errors: [],
            validateFunction(item) {
                const regex = /^[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,6}$/i;
                if (!regex.test(item.value)) {
                    return ['validator.errors.domain'];
                }
            },
        },
    },
    primary: {
        value: ctx.primary,
        component: 'input-checkbox',
        type: 'field',
        props: {
            errors: [],
        },
    },
});
