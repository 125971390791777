import { NUMBER, CATALOGS, STRING } from '@/helpers/fieldTypes.no-vuetify';
import { Container, Content, Footer, Heading, Section } from '@/entities/section/samples/_options';

const section = {
    id: '',
    type: 'SectionProductWidget',
    required: false,
    group: 'ProductWidget',
    options: {
        section: { ...Section },
        container: { ...Container },
        heading: { ...Heading },
        content: {
            htmlBefore: { ...Content.htmlBefore },
            htmlAfter: { ...Content.htmlAfter },
            colorText: { ...Content.color },
        },
        catalogSlug: {
            value: '',
            ...CATALOGS,
            props: {
                noLabel: true,
                labelTrans: 'builder.sectionOptions.props.catalogSlug',
            },
        },
        limit: {
            value: 6,
            ...NUMBER,
        },
        list: {
            useDefault: {
                value: true,
                component: 'InputSwitch',
                props: {
                    size: 'small',
                    noLabel: true,
                    text: 'builder.sectionOptions.props.useDefault',
                },
            },
            columns: {
                type: 'field',
                value: {
                    desktop: 3,
                    tablet: 2,
                    mobile: 1,
                },
                component: 'CustomizeColumnsTabs',
                ...{
                    props: {
                        noLabel: true,
                        ranges: {
                            desktop: [2, 3, 4, 5, 6],
                            tablet: [2, 3],
                            mobile: [1, 2],
                        },
                        disabledBy: 'list.useDefault',
                    },
                },
            },
        },
        btnSeeCatalog: {
            isShow: {
                value: false,
                component: 'InputSwitch',
                props: {
                    size: 'small',
                    noLabel: true,
                    text: 'builder.sectionOptions.props.btnSeeCatalogShow',
                },
            },
            text: {
                value: 'See All Products',
                ...STRING,
                props: {
                    labelTrans: 'builder.sectionOptions.props.btnSeeCatalogText',
                    rules: [],
                    disabledBy: 'btnSeeCatalog.isShow',
                },
            },
        },
        btnLoadMore: {
            isShow: {
                value: false,
                component: 'InputSwitch',
                props: {
                    size: 'small',
                    noLabel: true,
                    text: 'builder.sectionOptions.props.btnSeeCatalogShow',
                },
            },
            text: {
                value: '',
                ...STRING,
                props: {
                    labelTrans: 'builder.sectionOptions.props.text',
                    rules: [],
                    disabledBy: 'btnLoadMore.isShow',
                },
            },
        },
        footer: { ...Footer },
        // background: { ...Background },
    },
    items: [],
    children: null,
};

export default section;
