<template>
    <vue-final-modal classes="modal-container" v-model="model" v-bind="$attrs" :content-style="{ width: `${width}px` }">
        <div class="modal">
            <div class="modal-header">
                <h3 class="modal-header__text">{{ header }}</h3>
                <slot name="header-append"></slot>
                <Button type="icon" icon="close" class="modal-header__close-btn" @click="handleClose"></Button>
            </div>
            <slot></slot>
        </div>
    </vue-final-modal>
</template>

<script>
import Button from '@/components/common/Button';
import { VueFinalModal } from 'vue-final-modal';
import { proxyModelMixin } from '@/mixins/proxyModelMixin';

export default {
    name: 'Modal',
    components: { Button, VueFinalModal },
    mixins: [proxyModelMixin],
    data() {
        return {};
    },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        width: {
            type: String,
            default: '432',
        },
        header: {
            type: String,
        },
    },
    methods: {
        handleClose() {
            this.model = false;
            this.$emit('modal-closed');
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
.modal {
    $flex-gap: 24px;
    max-height: 80vh;
    overflow: auto;
    &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: var(--v-on-surface-high-base);
        &__text {
            margin: 0;
            font-size: 23px;
        }
        margin-bottom: 26px;
    }
    background: var(--v-surface-base);
    padding: 32px;
    align-self: center;
    &__content {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        gap: $flex-gap;
    }
    &__input {
        margin-top: 0;
        width: 100%;
    }
    &__pricing-btn {
        margin-top: 5px;
    }

    &__radio-group {
        margin-left: -2px;
        width: 100%;
    }
    &__combobox {
        margin-top: 15px !important;
        width: 100%;
    }
}
.min-max-input {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}
</style>
