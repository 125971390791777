var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page -width-lg"},[_c('div',{staticClass:"page-header"},[_c('h1',{staticClass:"page-header__text"},[_vm._v(_vm._s(_vm.$t('menus.pageHeaders.customers')))]),_c('Button',{attrs:{"color":"primary","icon":"add_circle_outline"},on:{"click":function($event){return _vm.openUserModal(null)}}},[_vm._v(" "+_vm._s(_vm.$t('entities.add'))+" ")])],1),_c('user-modal',{attrs:{"user-id":_vm.userId},on:{"action-success":_vm.getItems},model:{value:(_vm.isUserModalActive),callback:function ($$v) {_vm.isUserModalActive=$$v},expression:"isUserModalActive"}}),_c('Table',{attrs:{"search":_vm.search,"is-data-loaded":_vm.isLoaded,"show-select":true,"columns":_vm.headers,"items":_vm.items,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"update:sort":_vm.updateSort,"update-search-string":_vm.updateSearchString,"delete-selected-items":_vm.openDeletionModal},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.fullName)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('Button',{staticClass:"table__edit-btn",attrs:{"to":{ name: 'View Customer', params: { id: item.id } },"icon":"visibility","type":"icon"}}),_c('Button',{attrs:{"type":"icon","icon":"delete"},on:{"click":function($event){return _vm.openDeletionModal(item)}}})]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.email)+" ")]}},{key:"item.phone",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.phone)+" ")]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }