export default {
    // TEXT
    // default
    text_main: '#431235',
    text_body1: '#61495A',
    text_body2: '#695964',
    text_accent: '#733762',
    text_neutral: '#B3B9C7',
    // inverted
    text_main_inverted: '#FFFFFF',
    text_body1_inverted: '#FEFBFD',
    text_body2_inverted: '#EED7E7',
    text_accent_inverted: '#733762',
    text_neutral_inverted: '#B3B9C7',

    // ACTION
    // primary
    action_primary: '#733762',
    action_primary_hover: '#A46F9A',
    action_primary_pressed: '#502143',
    action_primary_disabled: '#ECE4EB',
    // secondary
    action_secondary: '#B97DAD',
    action_secondary_hover: '#E77582',
    action_secondary_pressed: '#B65C66',
    action_secondary_disabled: '#ECE4EB',
    // neutral_light
    header_bg: '#FFFFFF',
    header_bg_mobile: '#5A2E4D',
    header_main_menu_bg: '#5A2E4D',
    header_main_menu_dropdown_bg: '#7A3F69',
    action_neutral_light: '#FFFFFF',
    // action_neutral_light_hover: '#E77582',
    // action_neutral_light_pressed: '#B65C66',
    action_neutral_light_hover: '#B65C66',
    action_neutral_light_pressed: '#E77582',
    action_neutral_light_disabled: '#A46F9A',
    // neutral_medium
    action_neutral_medium: '#BEB5BC',
    action_neutral_medium_hover: '#A46F9A',
    action_neutral_medium_pressed: '#502143',
    action_neutral_medium_disabled: '#E7E8E8',
    // neutral_dark
    action_neutral_dark: '#502143',
    action_neutral_dark_hover: '#A46F9A',
    action_neutral_dark_pressed: '#502143',
    action_neutral_dark_disabled: '#ECE4EB',
    // system
    action_system: '#E61963',
    action_system_hover: '#F88C50',
    action_system_pressed: '#AC0800',
    action_system_disabled: '#E9EAEA',

    // NEUTRAL
    neutral1: '#552648',
    neutral2: '#5A2E4D',
    neutral3: '#7A3F69',
    neutral4: '#B3B9C7',
    neutral5: '#DADDE4',
    neutral6: '#FAF8F8',
    neutral7: '#FFFFFF',

    // SYSTEM
    success: '#388E3C',
    success_bg: '#F6FAF5',
    warning: '#FFA000',
    warning_bg: '#FFF9EE',
    error: '#E61963',
    error_bg: '#FEF2EE',

    // ACCENT
    accent1: '#E61963',
    accent1_bg: '#FFF1F6',
    accent2: '#FBC02D',
    accent2_bg: '#FEF3D7',
    accent3: '#00B08D',
    accent3_bg: '#E0FFF9',
    accent4: '#7D76DF',
    accent4_bg: '#E7E6F9',

    whatsapp: '#43D854',
};
