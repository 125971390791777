export default (ctx) => ({
    name: {
        type: 'field',
        value: ctx.name,
        component: 'InputText',
        props: {
            labelTrans: 'entities.author.fields.name',
            rules: ['noEmpty'],
            errors: [],
            class: '-half-width',
        },
    },
    type: {
        type: 'field',
        value: ctx.type,
        component: 'Select',
        props: {
            items: [],
            labelTrans: 'entities.author.fields.type',
            errors: [],
            class: '-half-width',
        },
    },
    photoId: {
        type: 'field',
        value: ctx.photoId,
        component: 'image-field',
        props: {
            labelTrans: 'entities.author.fields.photoId',
            errors: [],
        },
    },
    bio: {
        type: 'field',
        value: ctx.bio,
        component: 'Textarea',
        props: {
            labelTrans: 'entities.author.fields.bio',
            errors: [],
        },
    },
    jobTitle: {
        type: 'field',
        value: ctx.jobTitle,
        component: 'input-text',
        props: {
            labelTrans: 'entities.author.fields.jobTitle',
            errors: [],
        },
    },

    website: {
        type: 'field',
        value: ctx.website,
        component: 'InputText',
        props: {
            labelTrans: 'entities.author.fields.website',
            errors: [],
            class: '-half-width',
        },
    },
    facebookUrl: {
        type: 'field',
        value: ctx.facebookUrl,
        component: 'InputText',
        props: {
            labelTrans: 'entities.author.fields.facebookUrl',
            errors: [],
            class: '-half-width',
        },
    },
    instagramUrl: {
        type: 'field',
        value: ctx.instagramUrl,
        component: 'InputText',
        props: {
            labelTrans: 'entities.author.fields.instagramUrl',
            errors: [],
            class: '-half-width',
        },
    },
    xUrl: {
        type: 'field',
        value: ctx.xUrl,
        component: 'InputText',
        props: {
            labelTrans: 'entities.author.fields.xUrl',
            errors: [],
            class: '-half-width',
        },
    },
    linkedInUrl: {
        type: 'field',
        value: ctx.linkedInUrl,
        component: 'InputText',
        props: {
            labelTrans: 'entities.author.fields.linkedInUrl',
            errors: [],
            class: '-half-width',
        },
    },
    tiktokUrl: {
        type: 'field',
        value: ctx.tiktokUrl,
        component: 'InputText',
        props: {
            labelTrans: 'entities.author.fields.tiktokUrl',
            errors: [],
            class: '-half-width',
        },
    },
    pinterestUrl: {
        type: 'field',
        value: ctx.pinterestUrl,
        component: 'InputText',
        props: {
            labelTrans: 'entities.author.fields.pinterestUrl',
            errors: [],
            class: '-half-width',
        },
    },
});
