<template>
    <div class="billing-subscription">
        <headed-block class="billing-subscription__block" :heading="$t('billing.subscription.heading')">
            <div class="billing-subscription__container">
                <Table
                    :show-selected-actions="false"
                    :show-toolbar="false"
                    :columns="headers"
                    :items="items"
                    :is-data-loaded="isLoaded"
                >
                    <!--  TITLE  -->
                    <template v-slot:item.plan="{ item }">
                        {{ item.plan }}
                    </template>
                    <!--  STATUS  -->
                    <template v-slot:item.status="{ item }">
                        {{ item.status }}
                    </template>
                    <!--  DATE_End  -->
                    <!-- <template v-slot:item.dateEnd="{ item }"> - {{ item.dateEnd }} </template> -->
                    <!--  DATE_NEXT_BILLING  -->
                    <template v-slot:item.expiredAt="{ item }">
                        {{ getDate(item.expiredAt) }}
                    </template>
                </Table>
                <Button @click="checkout">{{ $t('billing.subscription.manage') }}</Button>
            </div>
        </headed-block>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BillingService from '@/services/BillingService';
import Button from '@/components/common/Button.vue';
import HeadedBlock from '@/components/common/HeadedBlock.vue';
import Table from '@/components/common/Table';
import detectInstanceId from '@/api/detectInstanceId';
import { localDate } from '@/helpers/filters';

export default {
    name: 'BillingSubscription',
    components: {
        Button,
        HeadedBlock,
        Table,
    },
    data() {
        return {
            isLoaded: false,
            plan: {
                plan: 'INTERNAL',
                status: 'ACTIVE',
                expiredAt: new Date(new Date().setMonth(new Date().getMonth() + 8)),
            },
            items: [],
        };
    },
    async created() {
        const [error, instance] = await BillingService.getInstanceSubscription(this.instance);
        if (error) error.alert();
        if (instance && instance.subscription) {
            this.plan = instance.subscription;
            this.items = [this.plan];
        }
        this.isLoaded = true;
    },
    computed: {
        ...mapGetters('globalVars', {
            instance: 'getInstance',
        }),
        headers() {
            return [
                { text: this.$t('lists.columns.item'), value: 'plan', width: '170px' },
                { text: this.$t('lists.columns.status'), value: 'status', width: '100px' },
                {
                    text: this.$t('lists.columns.dateNextBilling'),
                    value: 'expiredAt',
                    width: '170px',
                },
            ];
        },
    },
    methods: {
        async checkout() {
            const instanceId = detectInstanceId();
            localStorage.setItem('instanceId', instanceId);
            const [error, result] = await BillingService.getBillingPortalSession();
            if (error) {
                error.notify();
                return;
            }
            location.replace(result.portalUrl);
        },
        getDate(date) {
            return date ? localDate(new Date(date)) : null;
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.billing-subscription {
    &__container {
        width: 100%;
        .table {
            padding: 0 !important;
            margin: 0 0 20px !important;
        }
    }

    .btn__icon {
        font-size: 24px;
    }
}
</style>
