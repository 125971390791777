<template>
    <div class="input-section-layout">
        <div class="customize-padding-tabs__items">
            <template v-for="(item, key) in model">
                <div
                    :key="key"
                    class="customize-padding-tabs__item"
                    @click="currentActive = key"
                    :class="{ 'is-active': currentActive === key }"
                >
                    <span class="material-symbols-outlined">{{ deviceIcons[key] }}</span>
                </div>
            </template>
        </div>
        <div class="button-builder">
            <label class="button-builder__item">
                <span class="button-builder__title">Type:</span>
                <Select v-model="model[currentActive].type" :items="getAvalibleTypes()"></Select>
            </label>
            <label class="button-builder__item" v-if="hasInput('gridTemplate')">
                <span class="button-builder__title">Layout:</span>
                <Select
                    :use-material-icons="false"
                    v-model="model[currentActive].gridTemplate"
                    :items="gridTemplateItems"
                ></Select>
            </label>
            <label class="button-builder__item" v-if="hasInput('listColumns')">
                <span class="button-builder__title">List columns:</span>
                <InputText :min="1" :max="7" v-model="model[currentActive].listColumn" type="number"></InputText>
            </label>
            <label class="button-builder__item" v-if="hasInput('carouselColumns')">
                <span class="button-builder__title">Columns:</span>
                <InputText :min="1" :max="7" v-model="model[currentActive].carouselColumns" type="number"></InputText>
            </label>
            <label class="button-builder__item" v-if="hasInput('columns')">
                <span class="button-builder__title">Columns:</span>
                <Select v-model="model[currentActive].columns" :items="columns"></Select>
            </label>
            <label class="button-builder__item" v-if="hasInput('listAlign')">
                <span class="button-builder__title">List align:</span>
                <Select v-model="model[currentActive].listAlign" :items="listAlignItems"></Select>
            </label>
            <label class="button-builder__item" v-if="hasInput('gap')">
                <span class="button-builder__title">Gap:</span>
                <InputText :min="1" :max="200" v-model="model[currentActive].gap" type="number"></InputText>
            </label>
            <label class="button-builder__item" v-if="hasInput('rows')">
                <span class="button-builder__title">Rows:</span>
                <Select :use-material-icons="false" v-model="model[currentActive].rows" :items="rows"></Select>
            </label>
            <label class="button-builder__item" v-if="hasInput('arrows')">
                <InputSwitch
                    v-model="model[currentActive].arrows"
                    class="button-builder__switch"
                    size="small"
                    @click.native.stop
                    :text="'show arrows'"
                ></InputSwitch>
            </label>
            <label class="button-builder__item" v-if="hasInput('dots')">
                <InputSwitch
                    v-model="model[currentActive].dots"
                    class="button-builder__switch"
                    size="small"
                    @click.native.stop
                    :text="'show dots'"
                ></InputSwitch>
            </label>
        </div>
    </div>
</template>

<script>
import Select from '@/components/form/controls/Select.vue';
import InputText from '@/components/form/controls/InputText.vue';
import InputSwitch from '@/components/form/controls/InputSwitch.vue';
import { mapActions } from 'vuex';
import { cloneDeep } from 'lodash';

export default {
    name: 'InputSectionLayout',
    props: {
        name: { type: String, default: '' },
        group: { type: String, default: null },
        type: { type: String, default: 'text' },
        types: { type: [Array], default: () => [] },
        errors: { type: [Array, Object], default: () => [] },
        value: { type: [Object] },
    },
    components: { InputText, Select, InputSwitch },
    data() {
        return {
            currentActive: 'desktop',
            typeItems: [
                { value: 'grid', text: 'Grid' },
                { value: 'list', text: 'List' },
                { value: 'carousel', text: 'Carousel' },
                { value: 'centeredColumns', text: 'Centered Columns' },
                { value: 'masonry', text: 'Masonry' },
            ],
            typeInputs: {
                grid: { gridTemplate: true, gap: true },
                list: { listColumns: true, listAlign: true, gap: true },
                carousel: { gap: true, carouselColumns: true, arrows: true, dots: true, rows: 1 },
                centeredColumns: { columns: true },
                masonry: { gap: true, columns: true },
            },
            gridTemplateItems: [
                { value: '1x1', text: '1x1', icon: require('@/assets/images/icon/section-layout/1x1.svg') },
                { value: '2x1', text: '2x1', icon: require('@/assets/images/icon/section-layout/2x1.svg') },
                { value: '1x2', text: '1x2', icon: require('@/assets/images/icon/section-layout/1x2.svg') },
                { value: '1x1x1', text: '1x1x1', icon: require('@/assets/images/icon/section-layout/1x1x1.svg') },
                { value: '2x1x1', text: '2x1x1', icon: require('@/assets/images/icon/section-layout/2x1x1.svg') },
                { value: '1x1x2', text: '1x1x2', icon: require('@/assets/images/icon/section-layout/1x1x1.svg') },
                {
                    value: '3x3-2x2x2',
                    text: '3x3-2x2x2',
                    icon: require('@/assets/images/icon/section-layout/3x3-2x2x2.svg'),
                },
                { value: '4x3-3x4', text: '4x3-3x4', icon: require('@/assets/images/icon/section-layout/4x3-3x4.svg') },
                { value: '3x4-4x3', text: '3x4-4x3', icon: require('@/assets/images/icon/section-layout/3x4-4x3.svg') },
            ],
            listAlignItems: [
                { value: 'flex-start', text: 'Start' },
                { value: 'center', text: 'Center' },
                { value: 'flex-end', text: 'End' },
                { value: 'flex-around', text: 'Around' },
            ],
            deviceIcons: {
                desktop: 'desktop_windows',
                tablet: 'tablet',
                mobile: 'smartphone',
            },
            columns: [1, 2, 3],
            values: cloneDeep(this.value),
            rows: [
                { value: 1, text: '1' },
                { value: 2, text: '2' },
            ],
        };
    },
    methods: {
        ...mapActions('globalVars', {
            setDevice: 'setDevice',
        }),
        getAvalibleTypes() {
            const fildtered = this.typeItems.filter((item) => this.types.find((type) => item.value === type));
            return fildtered.length ? fildtered : this.typeItems;
        },
        hasInput(name) {
            this.setDevice(this.currentActive);
            const activeModel = this.model[this.currentActive];
            return this.typeInputs[activeModel.type][name];
        },
    },
    computed: {
        model: {
            get() {
                return this.values;
            },
            set(value) {
                console.log('set', value);
                this.$emit('input', this.values);
            },
        },
    },
    watch: {
        values: {
            handler: function() {
                this.$emit('update-field', {
                    name: this.name,
                    group: this.group,
                    value: this.values,
                });
                this.$emit('input', this.values);
            },
            deep: true,
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';

.button-builder {
    &__item {
        display: block;
        position: relative;
        width: 100%;
        &:hover {
            z-index: 15;
        }

        .select,
        .color-picker,
        .input-icon,
        .input-text {
            margin-top: -10px;
        }
        .input-icon {
            i {
                margin-top: 4px;
            }
        }
    }

    &__title {
        display: inline-block;
        padding: 0 5px;
        font-size: 0.75em;
        background: #fff;
        position: relative;
        z-index: 1;
        margin-left: 8px;
        border-radius: 4px;
    }
    &__switch {
        margin-top: 8px;
    }
}
</style>
