<template>
    <div class="page">
        <div class="page-header">
            <h1 class="page-header__text" v-if="productId && isLoaded">Reviews: {{ product.title | capitalize }}</h1>
            <progress-circular v-else-if="productId && !isLoaded" class="spinner"></progress-circular>
            <h1 class="page-header__text" v-else>{{ $t('menus.pageHeaders.reviews') }}</h1>
            <Button @click="openModal(null)" icon="add_circle_outline">
                {{ $t('entities.add') }}
            </Button>
        </div>
        <error-block v-if="!isAvailable"></error-block>
        <template v-else>
            <ReviewModal @action-success="getItems" :review-id="editing.id" v-model="editing.openModal"></ReviewModal>
            <Table
                :search="search"
                :columns="headers"
                :items="items"
                v-model="selected"
                :is-data-loaded="isLoaded"
                :show-select="true"
                :sort-by="sortBy"
                :sort-desc="sortDesc"
                :pagination="pagination"
                @change-per-page="changePerPage"
                @update:sort="updateSort"
                @update-search-string="updateSearchString"
                @delete-selected-items="openDeletionModal"
            >
                <!-- ACTIONS -->
                <template v-slot:item.actions="{ item }">
                    <div class="table__actions">
                        <Button @click="openModal(item.id)" icon="edit" type="icon" />
                        <Button @click="openDeletionModal(item)" icon="delete" type="icon" />
                    </div>
                </template>
                <!-- PUBLISHED -->
                <template v-slot:item.published="{ item }">
                    <input-switch v-model="item.enabled" @input="changeProductReview(item)"></input-switch>
                </template>
                <!-- CREATED AT -->
                <template v-slot:item.createdAt="{ item }">
                    {{ new Date(item.createdAt) | localDate }}
                </template>
                <!-- TEXT -->
                <template v-slot:item.text="{ item }">
                    <div class="reviews__text">{{ item.text }}</div>
                </template>
                <!-- NAME -->
                <template v-slot:item.name="{ item }">
                    {{ item.name }}
                </template>
                <!-- EMAIL -->
                <template v-slot:item.email="{ item }">
                    <a :title="item.email" :href="`mailto:${item.email}`" target="_blank" class="reviews__email">{{
                        item.email
                    }}</a>
                </template>
                <!-- PRODUCT -->
                <template v-slot:item.product="{ item }">
                    <div class="reviews__product" @click="openModal(item.id)">
                        <table-image :image="getImage(item)"></table-image>
                        <span class="table-edit">
                            <span type="text" v-if="item.product" class="table-edit__link product-title">{{
                                item.product.title
                            }}</span>
                            <span class="material-icons-outlined table-edit__icon">edit</span>
                        </span>
                    </div>
                </template>
            </Table>
        </template>
    </div>
</template>

<script>
import ProductService from '@/services/ProductService';
import ProductReviewService from '@/services/ProductReviewService';
import SearchService from '@/services/SearchService';
import EventEmitter from '@/helpers/eventEmitter.ts';
import { Pagination } from '@/services/PaginationService';
import ReviewModal from '@/components/review/ReviewModal';
import ErrorBlock from '@/components/common/ErrorBlock';
import Button from '@/components/common/Button';
import InputSwitch from '@/components/form/controls/InputSwitch';
import { mapActions } from 'vuex';
import Product from '@/entities/product/Product';
import ProductReview from '@/entities/product/ProductReview';
import Table from '@/components/common/Table';
import TableImage from '@/components/common/TableImage';
import ProgressCircular from '@/components/common/ProgressCircular';

export default {
    name: 'ProductReviewList',
    components: {
        ProgressCircular,
        TableImage,
        InputSwitch,
        ErrorBlock,
        ReviewModal,
        Table,
        Button,
    },

    async created() {
        EventEmitter.on('delete-review', this.deleteItems);
        const total = await this.getTotal();
        const limit = this.$store.getters['globalVars/getProductReviewsLimit'];
        this.pagination = new Pagination({
            moduleName: 'product-reviews',
            limit,
            pageNumber: this.pageNumber,
            total,
        });
        if (this.$route.query.q) {
            this.search.q = this.$route.query.q;
            this.previousSearch = this.$route.query.q;
        }
        await this.getItems();
    },

    data: () => ({
        isLoaded: false,
        items: [],
        images: {},
        search: { searchable: 'product-review', q: '' },
        sortBy: null,
        previousSearch: '',
        sortDesc: null,
        pagination: null,
        editing: {
            id: null,
            openModal: false,
        },
        isAvailable: true,
        product: null,
        selected: [],
    }),

    computed: {
        productId() {
            return this.$route.query.product;
        },
        pageNumber() {
            return this.$store.getters['globalVars/getProductReviewsPage'] || 1;
        },
        headers() {
            return [
                { text: this.$t('lists.columns.product'), value: 'product', width: '220px' },
                { text: this.$t('lists.columns.createdAt'), value: 'createdAt', width: '150px', sortable: true },
                { text: this.$t('lists.columns.name'), value: 'name', width: '210px', sortable: true },
                { text: this.$t('lists.columns.email'), value: 'email', width: '210px' },
                { text: this.$t('lists.columns.text'), value: 'text' },
                { text: this.$t('lists.columns.published'), value: 'published', width: '80px' },
                { value: 'actions', width: '114px' },
            ];
        },
    },

    methods: {
        ...mapActions('reviewsCounter', {
            getCounter: 'getCounter',
        }),
        async getItems() {
            this.isLoaded = false;
            const isSearching = !!this.search.q?.length;
            const searchChanged = this.search.q !== this.previousSearch;
            const params = {
                limit: this.pagination.limit,
                offset: searchChanged ? null : this.pagination.offset,
                product: this.productId,
                sort: this.sortBy,
                direction: this.sortDesc ? 'desc' : this.sortDesc === false ? 'asc' : null,
                q: isSearching ? this.search.q : null,
            };
            const getter = isSearching ? this.searchProductReviews : this.getProductReviews;
            const [error, result] = await getter(params);
            if (error) {
                error.alert();
                this.isAvailable = false;
                return;
            }
            const items = isSearching ? result.data : result;
            if (searchChanged) {
                this.pagination.pushToHistory(1);
                this.pagination.total = isSearching ? result.count : await this.getTotal();
                this.previousSearch = this.search.q;
            }

            if (this.productId) {
                await this.getProduct(this.productId);
            }
            if (items.length) {
                const ids = items.map((item) => item.productId);
                const [productsError, productsResult] = await ProductService.getAll({ ids });
                if (productsError) {
                    productsError.alert();
                    this.isAvailable = false;
                    return;
                }
                items.forEach((review) => {
                    review.product = productsResult.products.find((product) => product.id === review.productId);
                });
                this.images = await Product.getDefaultImages(productsResult.products);
            }
            this.items = items;
            this.isLoaded = true;
        },

        async getProductReviews(params) {
            return await ProductReviewService.getAll(params);
        },

        async getTotal() {
            const [error, total] = await Pagination.getTotal('product-reviews');
            if (error) {
                error.notify();
            }
            return total;
        },

        async searchProductReviews(params) {
            return await SearchService.entitySearch('product-review', params);
        },

        async changeProductReview(productReview) {
            const review = new ProductReview(productReview);
            const [error] = await ProductReviewService.updateOne(review.data);
            if (error) {
                error.notify();
            }
            await this.getCounter({ enabled: false });
        },

        updateSearchString({ searchString }) {
            this.search.q = searchString;
            if (searchString?.length) this.pagination.reset();
            this.getItems();
        },

        openDeletionModal(items) {
            this.$store.dispatch('modals/openDeletionModal', { items, entity: 'review' });
        },
        updateSort(sortBy, sortDesc) {
            this.sortBy = sortBy;
            this.sortDesc = sortDesc;
            this.getItems();
        },

        changePageNumber(event) {
            this.getProductReviews(event);
        },

        async deleteItems(items) {
            this.isLoaded = false;
            const promises = items.map((item) => ProductReviewService.removeOne(item.id));
            const results = await Promise.all(promises);
            const normalized = await this.pagination.normalize(this.items);
            this.pagination.total = await this.getTotal();
            this.selected = [];
            this.$store.dispatch('modals/closeDeletionModal');
            if (!normalized) {
                await this.getItems();
            }
            if (
                results.every((item) => {
                    const [error] = item;
                    return !error;
                })
            ) {
                if (items.length === 1) {
                    EventEmitter.trigger('show-noty', {
                        type: 'success',
                        text: this.$tc('notifications.deleted', 1, { entity: this.$tc('entities.review.title', 1) }),
                    });
                } else {
                    EventEmitter.trigger('show-noty', {
                        type: 'success',
                        text: this.$tc('notifications.deleted', 2, { entity: this.$tc('entities.review.title', 2) }),
                    });
                }
            }
            await this.getCounter({ enabled: false });
            this.isLoaded = true;
        },
        changePerPage(perPage) {
            this.pagination.changeLimit(perPage.value);
            this.getItems();
        },
        getImage(review) {
            return this.images[review.product?.id];
        },
        openModal(id) {
            this.editing.openModal = true;
            this.editing.id = id;
        },
        async getProduct(productId) {
            const [error, result] = await ProductService.getOne(productId);
            if (error) {
                error.alert();
                this.isAvailable = false;
                return;
            }
            this.product = result;
        },
    },
    watch: {
        pageNumber(newValue) {
            this.pagination.pageNumber = newValue;
            this.getItems();
        },
    },
    beforeDestroy() {
        EventEmitter.off('delete-review', this.deleteItems);
    },
};
</script>
<style lang="scss">
@import '@/scss/variables.scss';
@mixin overflow-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.reviews {
    &__product {
        display: flex;
        gap: 24px;
        align-items: center;
        cursor: pointer;
        color: var(--v-primary-base);
        .product-title {
            @include overflow-ellipsis;
            max-width: 180px;
            + .table-edit__icon {
                bottom: 3px;
            }
        }
        &:hover {
            color: var(--v-primary-accent-base);
            .table-edit__icon {
                opacity: 1;
                transition: all 0.1s;
            }
        }
        &:active {
            color: var(--v-primary-darken-base);
        }
    }
    &__text {
        @include overflow-ellipsis;
        max-width: 350px;
    }
    &__email {
        @include overflow-ellipsis;
        display: block;
        max-width: 180px;
    }
}
</style>
