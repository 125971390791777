<template>
    <div class="input-number-and-range" :class="classes">
        <input
            class="input-number-and-range__number"
            v-on:input="onUpdateFieldValue"
            v-model="model"
            type="number"
            aria-label="Count"
        />
        <input
            :disabled="disabled"
            type="range"
            :min="min"
            :max="max"
            class="input-number-and-range__field"
            v-on:change="onChange"
            v-model="model"
            v-bind="inputProps"
        />
    </div>
</template>

<script>
import { proxyModelMixin } from '@/mixins/proxyModelMixin';
import { debounce } from 'lodash';

export default {
    name: 'InputNumberAndRange',
    inheritAttrs: false,
    mixins: [proxyModelMixin],
    props: {
        name: { type: String, default: '' },
        group: { type: String, default: null },
        type: { type: String, default: 'text' },
        placeholder: { type: [String, Number], default: '' },
        errors: { type: [Array, Object], default: () => [] },
        value: { type: [String, Number, Object, Date] },
        disabled: { type: Boolean, default: false },
        min: { type: Number, default: 0 },
        max: { type: Number, default: 100 },
    },
    computed: {
        inputProps() {
            return {
                type: this.type,
                name: this.name,
                placeholder: this.placeholder,
            };
        },
        hasError: function() {
            return Array.isArray(this.errors) && this.errors.length > 0;
        },
        classes() {
            return {
                '-has-errors': Array.isArray(this.errors) && this.errors.length > 0,
                '-disabled': this.disabled,
            };
        },
    },
    created() {
        if (typeof this.value !== 'number') this.model = this.min;
    },
    methods: {
        onUpdateFieldValue: debounce(function(field) {
            let newValue = field.target.value;
            if (newValue > this.max) newValue = this.max;
            if (newValue < this.min) newValue = this.min;
            this.model = parseInt(newValue);
        }, 1000),
        onChange(e) {
            this.model = parseInt(e.target.value);
        },
    },
    watch: {
        value: function(newValue) {
            this.$emit('update-field', {
                name: this.name,
                group: this.group,
                value: newValue,
            });
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';

.input-number-and-range {
    position: relative;
    display: block;

    &:hover {
        border-color: var(--v-on-surface-medium-base);
    }
    &:focus-within {
        border-color: var(--v-primary-accent-base);
    }
    &__number {
        border: $form-control-border;
        border-radius: $form-control-border-radius;
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        height: 40px;
        padding: 0 $form-control-padding-horizontal;
        background: $form-control-bg;
        transition: $form-control-transition;
        width: 100%;
        appearance: none;
        &:focus {
            outline: none;
        }
        &::placeholder {
            color: inherit;
            opacity: 0.7;
        }

        &:hover {
            border-color: var(--v-on-surface-medium-base);
        }
        &:focus-within {
            border-color: var(--v-primary-accent-base);
        }
    }
    &__field {
        margin-top: -10px;
        margin-left: -1px;
        margin-right: -1px;
        display: block;
        width: calc(100% + 2px);
    }

    &.-has-errors {
        border-color: $error;
    }
}
</style>
