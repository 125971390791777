import axios from 'axios';
import { addJwtRequestInterceptor, authResponseInterceptor, axiosResponseInterceptorOnError } from '@/api/interceptors';
import { buildApiUrl } from '@/api/detectInstanceId';

function getBaseUrl() {
    return `${buildApiUrl()}/api/admin/`;
}

const adminHttp = axios.create({
    baseURL: getBaseUrl(),
    headers: {
        'Content-Type': 'application/json',
    },
    validateStatus(status) {
        /**
         * 405 Method Not Allowed
         * 408 Request Timeout
         * 429 Too Many Requests
         */

        return ![405, 408, 429].includes(status) && status < 500;
    },
});

adminHttp.interceptors.response.use(authResponseInterceptor, axiosResponseInterceptorOnError);
adminHttp.interceptors.request.use(addJwtRequestInterceptor);

export default adminHttp;
