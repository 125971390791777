<template>
    <tooltip class="sidebar-tooltip telegram-variables-tooltip">
        <div>
            <div class="sidebar-tooltip__header">
                {{ $t('settings.notifierTelegram.availableVariables') }}
            </div>
            <div class="sidebar-tooltip__items">
                <div v-for="(group, key) in variables" :key="key" class="sidebar-tooltip__item">
                    <div class="sidebar-tooltip__item-name">{{ $t(`settings.notifierTelegram.${key}`) }}</div>
                    <div v-for="(variable, key) in group" :key="variable">
                        <div class="sidebar-tooltip__item-text">
                            <b>{{ variable }}</b> - {{ $t(`settings.notifierTelegram.variables.${key}`) }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </tooltip>
</template>

<script>
import Tooltip from '@/components/common/Tooltip';
export default {
    name: 'TelegramNotificationVariablesTooltip',
    components: { Tooltip },
    data() {
        return {
            variables: {
                clientInfo: { name: '[NAME]', email: '[EMAIL]', phone: '[PHONE]' },
                orderInfo: {
                    orderNumber: '[ORDER_NUMBER]',
                    domain: '[DOMAIN]',
                    total: '[TOTAL]',
                    orderList: '[ORDER_LIST]',
                    date: '[DATE]',
                },
                shippingAndDeliveryInfo: {
                    country: '[COUNTRY]',
                    region: '[REGION]',
                    city: '[CITY]',
                    address: '[ADDRESS]',
                    apartment: '[APARTMENT]',
                    postCode: '[POSTCODE]',
                    deliveryOrPickupAddress: '[DELIVERY_OR_PICKUP_ADDRESS]',
                    deliveryPrice: '[DELIVERY_PRICE]',
                    location: '[LOCATION]',
                },
                yourInfo: { text: '[TEXT]', copyright: '[COPYRIGHT]', logo: '[LOGO]' },
            },
        };
    },
};
</script>

<style lang="scss">
.telegram-variables-tooltip {
    .sidebar-tooltip__item-name {
        margin-bottom: 8px;
    }
}
</style>
