//используется только в BulkEditor. Сущность каталог наследуется от страницы и использует её филды

export const CATALOG_FIELDS = {
    title: {
        bulkEditable: true,
    },
    slug: {
        bulkEditable: false,
        label: 'URL',
        class: '-half-width',
        props: {
            pageType: 'catalog',
        },
    },
    heading: {
        bulkEditable: true,
    },
    previewImageId: {
        bulkEditable: false,
        props: {
            'is-popup': true,
            'max-width': '250px',
            'image-type': 'product',
        },
    },
    metaTitle: {
        label: 'Meta Title',
        bulkEditable: true,
    },
    metaDescription: {
        label: 'Meta Description',
        bulkEditable: true,
    },
    enabled: {
        bulkEditable: true,
    },
    hidden: {
        bulkEditable: true,
    },
    pageStatus: {
        label: 'Page Status',
        class: '-half-width',
    },
    relatedProductsIds: {},
};
