<template>
    <div class="customize-collumns-range">
        <div class="customize-collumns-range__container">
            <template v-for="column in columns">
                <div :key="'column-' + column.id" class="customize-collumns-range__item">{{ column.number }}</div>
            </template>
        </div>
        <input
            class="customize-collumns-range__input"
            type="range"
            :min="min"
            :max="max"
            step="1"
            v-model="columnCount"
            :style="backgroundSize"
        />
        <div class="customize-collumns-range__count">
            {{ $t('settings.ecommerce.columnsCount') }}: {{ columnCount }}
        </div>
    </div>
</template>

<script>
import { proxyModelMixin } from '@/mixins/proxyModelMixin';

export default {
    name: 'CustomizeColumnsRange',
    inheritAttrs: false,
    mixins: [proxyModelMixin],
    props: {
        name: { type: String, default: '' },
        group: { type: String, default: null },
        errors: { type: [Array, Object], default: () => [] },
        ranges: { type: [Array], default: () => [] },
        value: { type: Number },
    },
    model: {
        prop: 'value',
        event: 'change-value',
    },
    data() {
        return {
            columnCount: null,
            columns: null,
        };
    },
    created() {
        this.columnCount = this.checkValue() ? this.value : this.getDefaultValue();
        this.columns = this.getColumns();
    },
    computed: {
        min() {
            return Math.min(...this.ranges);
        },
        max() {
            return Math.max(...this.ranges);
        },
        backgroundSize() {
            const width = Math.round(((this.columnCount - this.min) * 100) / (this.max - this.min));
            return `backgroundSize: ${width}% 100%`;
        },
    },
    watch: {
        columnCount(newValue) {
            this.columns = this.getColumns();
            this.$emit('change-value', Number(newValue));
        },
    },
    methods: {
        getColumns() {
            let items = [];
            for (let index = 0; index < this.columnCount; index++) {
                const percent = (100 / this.columnCount).toFixed(0);
                items.push({
                    id: index,
                    number: `${percent}%`,
                });
            }
            return items;
        },
        checkValue() {
            if (this.value) return true;
            if (this.value >= this.min && this.value <= this.max) return true;
            return false;
        },
        getDefaultValue() {
            return this.min;
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.customize-collumns-range {
    $collumn-padding: 4px;
    &__container {
        display: flex;
        gap: 8px;
    }
    &__item {
        display: block;
        height: $form-control-height;
        padding: 0 $collumn-padding;
        border: $form-control-border;
        border-radius: $form-control-border-radius;
        background: $form-control-bg;
        transition: $form-control-transition;
        line-height: $form-control-height;
        text-align: center;
        font-size: 12px;
        flex: 1;
    }
    &__input {
        -webkit-appearance: none;
        display: block;
        width: 100%;
        margin: 16px 0;
        background: var(--v-primary-lighten-base);
        background-image: linear-gradient(to right, $primary 0%, $primary 100%);
        background-image: -webkit-linear-gradient(left, $primary 0%, $primary 100%);
        background-image: -moz-linear-gradient(left, $primary 0%, $primary 100%);
        background-image: -o-linear-gradient(to right, $primary 0%, $primary 100%);
        background-image: linear-gradient(to right, $primary 0%, $primary 100%);
        background-repeat: no-repeat;
        height: 6px;
        border-radius: $form-control-border-radius;
    }
    input[type='range']:focus {
        outline: none;
    }
    input[type='range']::-webkit-slider-runnable-track {
        width: 100%;
        height: 6px;
        cursor: pointer;
        box-shadow: none;
        background: transparent;
        border-radius: $form-control-border-radius;
        border: none;
    }
    input[type='range']::-webkit-slider-thumb {
        box-shadow: none;
        border: 0px;
        height: 16px;
        width: 16px;
        border-radius: 10px;
        background: $primary;
        cursor: pointer;
        -webkit-appearance: none;
        margin-top: -5px;
    }
    input[type='range']:focus::-webkit-slider-runnable-track {
        background: transparent;
    }
    input[type='range']::-moz-range-track {
        width: 100%;
        height: 6px;
        cursor: pointer;
        box-shadow: none;
        background: transparent;
        border-radius: $form-control-border-radius;
        border: none;
    }
    input[type='range']::-moz-range-thumb {
        box-shadow: none;
        border: 0px;
        height: 16px;
        width: 16px;
        border-radius: 10px;
        background: $primary;
        cursor: pointer;
    }
    input[type='range']::-ms-track {
        width: 100%;
        height: 6px;
        cursor: pointer;
        background: transparent;
        border-color: transparent;
        border-radius: $form-control-border-radius;
        color: transparent;
    }
    input[type='range']::-ms-fill-lower {
        background: transparent;
        border: none;
        border-radius: 0px;
        box-shadow: none;
    }
    input[type='range']::-ms-fill-upper {
        background: transparent;
        border: none;
        border-radius: 0px;
        box-shadow: none;
    }
    input[type='range']::-ms-thumb {
        box-shadow: none;
        border: 0px;
        height: 16px;
        width: 16px;
        border-radius: 10px;
        background: $primary;
        cursor: pointer;
    }
    input[type='range']:focus::-ms-fill-lower {
        background: transparent;
    }
    input[type='range']:focus::-ms-fill-upper {
        background: transparent;
    }
}
</style>
