import { cloneDeep } from 'lodash';
import { PRODUCT } from '@/helpers/fieldTypes.no-vuetify';
import { Container, Content, Footer, Heading, Section } from '@/entities/section/samples/_options';

const section = {
    id: '',
    type: 'SectionProductWidgetByItems',
    required: false,
    group: 'ProductWidget',
    options: {
        section: { ...Section },
        container: { ...Container },
        heading: { ...Heading },
        content: {
            htmlBefore: { ...Content.htmlBefore },
            htmlAfter: { ...Content.htmlAfter },
            colorText: { ...Content.color },
        },
        list: {
            useDefault: {
                value: true,
                component: 'InputSwitch',
                props: {
                    size: 'small',
                    noLabel: true,
                    text: 'builder.sectionOptions.props.useDefault',
                },
            },
            columns: {
                type: 'field',
                value: {
                    desktop: 3,
                    tablet: 2,
                    mobile: 1,
                },
                component: 'CustomizeColumnsTabs',
                ...{
                    props: {
                        noLabel: true,
                        ranges: {
                            desktop: [2, 3, 4, 5, 6],
                            tablet: [2, 3],
                            mobile: [1, 2],
                        },
                        disabledBy: 'list.useDefault',
                    },
                },
            },
        },
        footer: { ...Footer },
        // background: { ...Background },
    },
    items: [],
    children: {
        id: '',
        type: 'productItem',
        options: {
            product: {
                value: {},
                ...PRODUCT,
                props: {
                    noLabel: true,
                    labelTrans: 'builder.sectionOptions.props.product',
                },
            },
        },
    },
};

const itemFixture = cloneDeep(section.children);
const itemsFixture = [];
itemsFixture.push(Object.assign(cloneDeep(itemFixture), { position: 0 }));
itemsFixture.push(Object.assign(cloneDeep(itemFixture), { position: 1 }));
itemsFixture.push(Object.assign(cloneDeep(itemFixture), { position: 2 }));
section.items = itemsFixture;

export default section;
