export default () => ({
    country: {
        component: 'Select',
        props: {
            rules: ['noEmpty'],
            labelTrans: 'entities.order.deliveryAddress.fields.country',
            errors: [],
        },
    },
    region: {
        component: 'Select',
        props: {
            rules: ['noEmpty'],
            errors: [],
        },
    },
    city: {
        component: 'input-text',
        props: {
            rules: ['noEmpty'],
            labelTrans: 'entities.order.deliveryAddress.fields.city',
            errors: [],
        },
    },
    address: {
        component: 'input-text',
        props: {
            rules: ['noEmpty'],
            labelTrans: 'entities.order.deliveryAddress.fields.address',
            errors: [],
        },
    },
    apartment: {
        component: 'input-text',
        props: {
            labelTrans: 'entities.order.deliveryAddress.fields.apartment',
            errors: [],
        },
    },
    postalCode: {
        component: 'input-text',
        props: {
            rules: [],
            errors: [],
        },
    },
});
