<template>
    <div class="sticky-toolbar" ref="toolbar" :class="{ '-stuck': stuck }">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'StickyToolbar',
    data() {
        return {
            stuck: false,
        };
    },
    mounted() {
        window.addEventListener('scroll', this.toggleStick);
    },
    props: {
        offset: {
            type: Number,
            default: 60,
        },
    },
    methods: {
        toggleStick() {
            this.stuck = window.scrollY >= this.offset;
        },
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.toggleStick);
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.sticky-toolbar {
    display: flex;
    justify-content: flex-end;
    gap: 18px;
    width: 100%;
    box-sizing: content-box;
    margin-left: -32px;
    margin-top: 38px;
    top: 64px;
    z-index: 4;
    padding: 16px 32px;
    transition: background-color 0.2s;
    &.-stuck {
        position: sticky;
        background-color: var(--v-surface-base);
        transition: background-color 0.2s;
        box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.15);
    }
    &.-shrunk-full {
        padding-right: 17vw;
    }
}
</style>
