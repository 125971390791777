export default () => ({
    name: {
        props: {
            rules: ['noEmpty'],
            labelTrans: 'entities.image.fields.name',
            errors: [],
        },
    },
    alt: {
        props: {
            rules: ['noEmpty'],
            labelTrans: 'entities.image.fields.alt',
            errors: [],
        },
    },
    title: {
        props: {
            rules: ['noEmpty'],
            labelTrans: 'entities.image.fields.title',
            errors: [],
        },
    },
});
