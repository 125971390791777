import Page from './Page';
import SampleSections from '@/entities/section/samples/';

const DEFAULT_SECTIONS = [];
DEFAULT_SECTIONS.push(SampleSections.getByType('SectionBreadcrumbs'));
DEFAULT_SECTIONS.push(SampleSections.getByType('SectionHeadingH1'));

/**
 * Класс экземпляра текстовой страницы
 */
export default class TextPage extends Page {
    type = 'textpage';

    /**
     * Create TextPage Entity
     * @param {Object} data - объект данных страницы
     * @param {String} data.id - uuid.v4(),
     * @param {String} data.title -
     * @param {String} data.slug -
     * @param {String} data.heading -
     * @param {String} data.metaTitle -
     * @param {String} data.metaDescription -
     * @param {String} data.previewImageId -
     * @param {Boolean} data.enabled -
     * @param {Boolean} data.hidden -
     * @param {Boolean} data.noIndex -
     * @param {Array} data.sections -
     */
    constructor(data) {
        super(data);
        this.addDefaultSections(DEFAULT_SECTIONS);
    }
}
