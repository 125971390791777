<template>
    <div class="page -width-lg">
        <div class="page-header">
            <h1 class="page-header__text">{{ $t('menus.pageHeaders.catalogs') }}</h1>
            <Button :to="{ name: 'Create Catalog' }" icon="add_circle_outline">
                {{ $t('entities.add') }}
            </Button>
        </div>
        <error-block v-if="!isAvailable"></error-block>
        <Table
            class="catalogs-table"
            v-else
            :search="search"
            :columns="headers"
            :items="items"
            :is-data-loaded="isLoaded"
            v-model="selected"
            show-select
            :sort-by="sortBy"
            :sort-desc="sortDesc"
            :pagination="pagination"
            @change-per-page="changePerPage"
            @update:sort="updateSort"
            @update-search-string="updateSearchString"
            @delete-selected-items="openDeletionModal"
        >
            <template v-slot:selected-actions>
                <template v-if="selected.length">
                    <Button
                        icon="unpublished"
                        type="text"
                        class="table__selected-action"
                        @click="changeSelected('enabled', false)"
                    >
                        {{ $t('lists.actions.disableSelected') }}</Button
                    >
                    <Button
                        type="text"
                        icon="check_circle"
                        class="table__selected-action"
                        @click="changeSelected('enabled', true)"
                    >
                        {{ $t('lists.actions.enableSelected') }}</Button
                    >
                    <Button
                        type="text"
                        icon="visibility_off"
                        class="table__selected-action"
                        @click="changeSelected('hidden', true)"
                    >
                        {{ $t('lists.actions.hideSelected') }}</Button
                    >
                    <Button
                        type="text"
                        icon="visibility"
                        class="table__selected-action"
                        @click="changeSelected('hidden', false)"
                        >{{ $t('lists.actions.showSelected') }}</Button
                    >
                </template>
            </template>
            <!--  ACTIONS  -->
            <template v-slot:item.actions="{ item }">
                <div class="table__actions">
                    <router-link :to="{ name: 'Builder Edit Catalog', params: { id: item.id, type: 'catalog' } }"
                        ><Button class="builder-btn" type="text">{{ $t('entities.editInBuilder') }}</Button>
                    </router-link>
                    <a target="_blank" :href="`https://${instanceFull.primaryDomain}/catalogs/${item.slug}`">
                        <Button type="icon" icon="open_in_new" />
                    </a>
                    <Button :to="{ name: 'Edit Catalog', params: { id: item.id } }" icon="edit" type="icon" />
                    <Button @click="openDeletionModal(item)" icon="delete" type="icon" />
                </div>
            </template>
            <!--  IMAGE  -->
            <template v-slot:item.image="{ item }">
                <img
                    v-if="hasImage(item)"
                    class="table__image"
                    :alt="images[item.id].alt"
                    :src="imageUrl + images[item.id].uri"
                />
                <img v-else class="table__image -default" alt="No image" src="@/assets/images/no-photo.svg" />
            </template>
            <!--  ENABLED  -->
            <template v-slot:item.enabled="{ item }">
                <input-switch
                    :value="item.enabled"
                    name="enabled"
                    size="small"
                    @input="onUpdateFieldValue({ name: 'enabled', value: $event }, item)"
                ></input-switch>
            </template>
            <!--  HIDDEN  -->
            <template v-slot:item.hidden="{ item }">
                <input-switch
                    :value="item.hidden"
                    name="hidden"
                    size="small"
                    @input="onUpdateFieldValue({ name: 'hidden', value: $event }, item)"
                ></input-switch>
            </template>
            <!--  PRODUCTS  -->
            <template v-slot:item.products="{ item }">
                <Button type="text" :to="{ name: 'Catalog Products', params: { id: item.id } }">
                    {{ $t('lists.actions.viewAll') }}</Button
                >
            </template>
            <!--  TITLE  -->
            <template v-slot:item.title="{ item }">
                <span class="table-edit">
                    <router-link
                        class="table-edit__link"
                        :to="{ name: 'Edit Catalog', params: { id: item.id, type: item.type } }"
                        >{{ item.title }}</router-link
                    >
                    <span class="table-edit__icon material-icons-outlined">edit</span>
                </span>
            </template>
        </Table>
    </div>
</template>

<script>
import CatalogService from '@/services/CatalogService.ts';
import { Pagination } from '@/services/PaginationService';
import SearchService from '@/services/SearchService';
import { debounce } from 'lodash';
import EventEmitter from '@/helpers/eventEmitter.ts';
import ErrorBlock from '@/components/common/ErrorBlock';
import { imageUrl } from '@/helpers/values';
import Table from '@/components/common/Table';
import Button from '@/components/common/Button';
import Catalog from '@/entities/catalog/Catalog';
import { mapGetters } from 'vuex';

export default {
    name: 'CatalogList',
    components: {
        ErrorBlock,
        Table,
        Button,
    },
    async created() {
        const total = await this.getTotal();
        const limit = this.$store.getters['globalVars/getCatalogsLimit'];
        EventEmitter.on('delete-catalog', this.deleteItems);
        this.pagination = new Pagination({
            moduleName: 'catalogs',
            limit,
            pageNumber: this.pageNumber,
            total,
        });
        if (this.$route.query.q) {
            this.search.q = this.$route.query.q;
            this.previousSearch = this.$route.query.q;
        }
        await this.getItems();
    },
    data: () => ({
        isLoaded: false,
        search: { searchable: 'catalog', q: '' },
        sortBy: 'title',
        previousSearch: '',
        sortDesc: false,
        currency: null,
        selected: [],
        items: [],
        images: [],
        pagination: null,
        imageUrl: imageUrl(),
        isAvailable: true,
    }),
    computed: {
        ...mapGetters('globalVars', { instanceFull: 'getInstanceFull' }),
        pageNumber() {
            return this.$store.getters['globalVars/getCatalogsPage'];
        },
        searchString() {
            return this.$route.query.q;
        },
        headers() {
            return [
                { text: this.$t('lists.columns.image'), value: 'image', width: '50px' },
                { text: this.$t('lists.columns.title'), value: 'title', sortable: true },
                { text: this.$t('lists.columns.enabled'), value: 'enabled', width: '70px', sortable: true },
                { text: this.$t('lists.columns.hidden'), value: 'hidden', width: '70px', sortable: true },
                { text: this.$t('lists.columns.products'), value: 'products', width: '110px' },
                { value: 'actions', width: '184px' },
            ];
        },
    },
    methods: {
        async getItems() {
            this.isLoaded = false;
            const isSearching = !!this.search.q?.length;
            const searchChanged = this.search.q !== this.previousSearch;
            const params = {
                limit: this.pagination.limit,
                offset: searchChanged ? null : this.pagination.offset,
                sort: this.sortBy,
                direction: this.sortDesc ? 'desc' : this.sortDesc === false ? 'asc' : null,
                q: isSearching ? this.search.q : null,
                fields: isSearching ? ['title'] : null,
            };
            const getter = isSearching ? this.searchCatalogs : this.getCatalogs;
            const [error, result] = await getter(params);
            if (error) {
                error.alert();
                this.isAvailable = false;
                return;
            }
            if (searchChanged) {
                this.pagination.pushToHistory(1);
                this.pagination.total = isSearching ? result.count : await this.getTotal();
                this.previousSearch = this.search.q;
            }
            this.items = isSearching ? result.data : result;
            const [imageError, imageResult] = await CatalogService.getImages(this.items);
            if (imageError) imageError.notify();
            this.images = imageResult;
            this.isLoaded = true;
        },
        async getCatalogs(params) {
            return await CatalogService.getAll(params);
        },
        async searchCatalogs(params) {
            return await SearchService.entitySearch('catalog', params);
        },

        changePerPage(perPage) {
            this.pagination.changeLimit(perPage.value);
            this.perPage = perPage.value;
            this.getItems();
        },
        async getTotal() {
            const [error, total] = await Pagination.getTotal('catalogs');
            if (error) {
                error.notify();
            }
            return total;
        },

        async changeSelected(name, value) {
            this.isLoaded = false;
            const catalogs = this.selected
                .filter((item) => item[name] !== value)
                .map((item) => {
                    const catalog = new Catalog(item);
                    catalog[name] = value;
                    return catalog;
                });
            this.selected = [];
            if (!catalogs.length) {
                this.isLoaded = true;
                return;
            }
            const [error] = await CatalogService.updateBatch(catalogs.map((item) => item.data));
            if (error) {
                this.isLoaded = true;
                error.notify();
                return;
            }
            this.getItems();
        },

        async onUpdateFieldValue(event, catalog) {
            //TODO: костыль, у некоторых котологов почему-то sortCriteria = null
            //когда поправят см. pageList
            const { name, value } = event;
            catalog[name] = value;
            const newCatalog = new Catalog(catalog);
            const [error] = await CatalogService.updateOne(newCatalog.data);
            if (error) {
                error.notify();
            }
        },

        updateSearchString({ searchString }) {
            this.search.q = searchString;
            if (searchString?.length) this.pagination.reset();
            this.getItems();
        },
        updateSort(sortBy, sortDesc) {
            this.sortBy = sortBy;
            this.sortDesc = sortDesc;
            this.getItems();
        },
        async deleteItems(items) {
            this.isLoaded = false;
            const promises = [];
            items.forEach((item) => {
                promises.push(CatalogService.removeOne(item.id));
                const position = this.items.indexOf(item);
                this.items.splice(position, 1);
            });
            const results = await Promise.all(promises);
            const normalized = await this.pagination.normalize(this.items);
            this.pagination.total = await this.getTotal();
            this.selected = [];
            this.$store.dispatch('modals/closeDeletionModal');
            if (!normalized) {
                await this.getItems();
            }
            if (
                results.every((item) => {
                    const [error] = item;
                    return !error;
                })
            ) {
                if (items.length === 1) {
                    EventEmitter.trigger('show-noty', {
                        type: 'success',
                        text: this.$tc('notifications.deleted', 1, { entity: this.$tc('entities.catalog.title', 1) }),
                    });
                } else {
                    EventEmitter.trigger('show-noty', {
                        type: 'success',
                        text: this.$tc('notifications.created', 2, { entity: this.$tc('entities.catalog.title', 2) }),
                    });
                }
            }
            this.isLoaded = true;
        },
        hasImage(catalog) {
            return this.images[catalog.id]?.uri;
        },
        changeCatalog: debounce(async function(catalog) {
            const [error] = await CatalogService.updateOne(catalog);
            if (error) {
                error.notify();
            }
        }, 300),
        openDeletionModal(items) {
            this.$store.dispatch('modals/openDeletionModal', { items, entity: 'catalog' });
        },
    },
    beforeDestroy() {
        EventEmitter.off('delete-catalog', this.deleteItems);
    },
    watch: {
        pageNumber(newValue) {
            this.pagination.pageNumber = newValue;
            this.getItems();
        },
    },
};
</script>
