import { cloneDeep, merge } from 'lodash';
import { SELECT } from '@/helpers/fieldTypes.no-vuetify';
import { Container, Content, Footer, Heading, Section, SectionItemsStyle } from '@/entities/section/samples/_options';

const section = {
    id: '',
    type: 'SectionTextCustomContent',
    required: false,
    group: 'TextBlocks',
    options: {
        section: { ...Section },
        container: { ...Container },
        heading: { ...Heading },
        content: {
            imageBorderRadius: { ...SectionItemsStyle.borderRadius },
        },
        footer: { ...Footer },
        // background: { ...Background },
    },
    items: [],
    children: {
        id: '',
        type: 'customContent',
        options: {
            columns: {
                desktop: {
                    value: 3,
                    ...SELECT,
                    ...{
                        props: {
                            labelTrans: 'builder.sectionOptions.props.desktop',
                            items: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
                        },
                    },
                },
                tablet: {
                    value: 2,
                    ...SELECT,
                    ...{
                        props: {
                            labelTrans: 'builder.sectionOptions.props.tablet',
                            items: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
                        },
                    },
                },
                mobile: {
                    value: 1,
                    ...SELECT,
                    ...{
                        props: {
                            labelTrans: 'builder.sectionOptions.props.mobile',
                            items: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
                        },
                    },
                },
            },
            content: {
                html: { ...Content.html },
                image: merge(Content.image, {
                    props: { note: 'JPG, PNG. Recomended size: 832х832px. No more than 100kb' },
                }),
                alt: { ...Content.imageAlt },
                order: {
                    value: 'imageFirst',
                    ...SELECT,
                    ...{
                        props: {
                            translate: true,
                            labelTrans: 'builder.sectionOptions.props.order',
                            items: [
                                { text: 'builder.sectionOptions.items.imageFirst', value: 'imageFirst' },
                                { text: 'builder.sectionOptions.items.textFirst', value: 'textFirst' },
                            ],
                        },
                    },
                },
            },
            button: { ...Content.button },
        },
    },
};

const itemFixture = cloneDeep(section.children);
const itemsFixture = [];
itemsFixture.push(Object.assign(cloneDeep(itemFixture), { position: 0 }));
itemsFixture.push(Object.assign(cloneDeep(itemFixture), { position: 1 }));
itemsFixture.push(Object.assign(cloneDeep(itemFixture), { position: 2 }));
itemsFixture.push(Object.assign(cloneDeep(itemFixture), { position: 3 }));
section.items = itemsFixture;

export default section;
