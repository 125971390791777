export default {
    main: {
        dashboard: 'Главная',
        pages: 'Страницы',
        products: {
            title: 'Товары',
            all: 'Все товары',
            disabled: 'Неактивные товары',
            options: 'Опции',
            properties: 'Характеристики',
            labels: 'Лейблы',
            reviews: 'Отзывы ({count})',
        },
        catalogs: 'Каталоги',
        orders: 'Заказы',
        menu: {
            title: 'Меню',
            info: 'Информация',
            main: 'Главная',
            footer: 'Footer',
            mobile: 'Mobile',
        },
        users: 'Доступ и безопасность',
        customers: 'Покупатели',
        settings: {
            title: 'Настройки',
            customize: 'Builder',
            file_manager: 'Файловый менеджер',
            notification: 'Уведомления',
            domains: 'Домены',
            erp: 'ERP',
            websiteSettings: 'Настройки сайта',
            orderStates: 'Статусы заказа',
        },
        seo: {
            title: 'SEO-модули',
            tag_manager: 'Tag Manager',
            sitemap: 'Sitemap',
            robots: 'Robots.txt',
            redirects: 'Редиректы',
            metatags: 'Meta-теги',
        },
        delivery: {
            title: 'Доставка и самовывоз',
            locations: 'Пункты самовывоза',
            delivery: 'Зоны доставки',
        },
        payment: {
            title: 'Оплата',
            yookassa: 'YooKassa',
        },
        billing: {
            title: 'Биллинг',
        },
        leads: {
            title: 'Заявки',
            all: 'Все заявки',
        },
        blog: {
            title: 'Медиа',
            categories: 'Категории',
            articles: 'Статьи',
            authors: 'Авторы',
            tags: 'Теги',
            comments: 'Комментарии',
        },
    },
    user: {
        account: 'Настройки аккаунта',
        logout: 'Выйти',
    },
    pageHeaders: {
        products: 'Товары',
        disabledProducts: 'Неактивные товары',
        orders: 'Заказы',
        pages: 'Страницы',
        options: 'Опции',
        labels: 'Лейблы',
        reviews: 'Отзывы',
        catalogs: 'Каталоги',
        accessAndSecurity: 'Пользователи',
        notification: 'Уведомления',
        domains: 'Домены',
        tagManager: 'Tag Manager',
        metaTags: 'Шаблоны Meta-тегов',
        robots: 'Robots.txt',
        redirects: 'Редиректы',
        locations: 'Пункты самовывоза',
        shippingAndDelivery: 'Зоны доставки',
        payment: 'Оплата',
        billing: 'Биллинг',
        dashboard: 'Главная',
        erp: 'ERP',
        leads: 'Заявки',
        customers: 'Покупатели',
        orderStates: 'Статусы заказа',
        categories: 'Категории',
        articles: 'Статьи',
        authors: 'Авторы',
        tags: 'Теги',
        comments: 'Комментарии',
        articlesInCategory: 'Статьи в категории «{category}»',
        properties: 'Характеристики',
    },
    topBar: {
        back: 'Назад',
        backToAdmin: 'На главную',
    },
    deletionModal: {
        header: 'Удалить {item}',
        deleteAllHeader: 'Удалить все {item}',
        text:
            'Вы уверены, что хотите удалить {item}? Все данные будут удалены навсегда. Это действие нельзя отменить. ' +
            '| Вы уверены, что хотите удалить эти {item}? Все данные будут удалены навсегда. Это действие нельзя отменить.',
        deleteAllText:
            'Вы уверены, что хотите удалить все (!) загруженные {item} и полностью очистить таблицу? Все данные будут удалены навсегда. Это действие нельзя отменить.',
        entities: {
            page: 'страницу | страницы',
            option: 'опцию | опции',
            catalog: 'каталог | каталоги',
            product: 'товар | товары',
            productVariation: 'вариацию | вариации',
            label: 'лейбл | лейблы',
            review: 'отзыв | отзывы',
            order: 'заказ | заказы',
            location: 'пункт самовывоза | пункты самовывоза',
            shippingZone: 'зону доставки | зоны доставки',
            rate: 'тариф | тарифы',
            redirect: 'редирект | редиректы',
            user: 'пользователя | пользователей',
            image: 'изображение | изображения',
            domain: 'домен | домены',
            orderState: 'статус заказа | статусы заказа',
            lead: 'заявку | заявки',
            category: 'категорию | категории',
            article: 'статью | статьи',
            author: 'автора | авторов',
            comment: 'комментарий | комментарии',
            tag: 'тег | теги',
            property: 'характеристику | характеристики',
        },
    },
    selectPlaceholder: 'Выбрать',
    fileInputPlaceholder: 'Нажмите, чтобы выбрать файл',
    auth: {
        description:
            'Создайте интернет-магазин с мощными инструментами, которые помогут вам находить клиентов, стимулировать продажи и управлять повседневными делами',
        login: {
            header: 'Вход',
            password: 'Забыли ваш пароль?',
            submit: 'Войти',
            remember: 'Запомнить меня',
        },
        restorePassword: {
            description: 'Введите ваш email, указанный при регистрации, чтобы восстановить пароль',
            header: 'Забыли пароль?',
            back: 'Назад',
            submit: 'Отправить',
            success: {
                description: 'Мы выслали новый пароль на указанный вами email',
                header: 'Проверьте вашу почту',
            },
        },
    },
};
