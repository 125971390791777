<template>
    <div class="builder-sidebar-left-footer">
        <Button icon="settings" type="secondary" @click="openSettingsPanelTab('page')" fullwidth>
            {{ $t('builder.global.btns.settingsPage') }}
        </Button>
        <Button icon="settings" type="secondary" @click="openSettingsPanelTab('settings')" fullwidth>
            {{ $t('builder.global.btns.settingsSite') }}
        </Button>
    </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import Button from '@/components/common/Button.vue';

export default {
    name: 'BuilderSidebarLeftFooter',
    components: { Button },
    computed: {
        ...mapGetters('builder', {
            settings: 'getSettings',
            currentPage: 'getCurrentPage',
        }),
    },
    methods: {
        ...mapActions('builder', {
            updateActiveItem: 'updateActiveItem',
        }),
        openSettingsPanelTab(tab) {
            const payload = {
                type: tab === 'page' ? 'page' : 'settings',
                data: tab === 'page' ? cloneDeep(this.currentPage) : cloneDeep(this.settings),
            };
            this.updateActiveItem(payload);
        },
    },
};
</script>

<style lang="scss">
.builder-sidebar-left-footer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 12px 12px 16px;
}
</style>
