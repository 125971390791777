export default (ctx) => ({
    name: {
        value: ctx.name,
        type: 'field',
        component: 'InputText',
        props: {
            labelTrans: 'entities.tag.fields.name',
            errors: [],
        },
    },
});
