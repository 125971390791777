import { SectionFixtureType } from '@/entities/section/fixtures';
import { createLayoutVariation } from '@/entities/section/fixtures/_base';

const itemStyleVariations = {
    style1: 'style1',
    style2: 'style2',
};

const itemsFixture = [
    {
        title: 'What is your company all about?',
        html: '<p>Lorem ipsum dolor sit amet</p>',
    },
    {
        title: 'What products or services do you offer?',
        html: '<p>Lorem ipsum dolor sit amet</p>',
    },
    {
        title: 'Where are you located?',
        html: '<p>Lorem ipsum dolor sit amet</p>',
    },
    {
        title: 'How do users contact customer service or support?',
        html: '<p>Lorem ipsum dolor sit amet</p>',
    },
];

const createItem = (args: { template: string; position: number; title: string; html: string }) => {
    return {
        id: 'fixture',
        type: args.template,
        options: {
            heading: {
                title: args.title,
                color: '',
            },
            content: {
                html: args.html,
                color: '',
            },
        },
        position: args.position,
    };
};

const createSectionFixture = (args: {
    preview: string;
    title: string;
    itemLayout: any;
    itemsTemplate: string;
    items: any[];
    options: any;
}): SectionFixtureType => {
    return {
        preview: args.preview,
        title: args.title,
        group: 'FAQ',
        data: {
            id: 'fixture',
            items: args.items,
            required: false,
            fixed: false,
            type: 'SectionFAQBase',
            options: {
                ...args.options,
                itemsLayout: args.itemLayout,
                itemsStyle: {
                    template: args.itemsTemplate,
                    gap: 20,
                },
            },
        },
    };
};

const summary: any = {};

summary.style1__list_column_1_white = createSectionFixture({
    title: 'FAQ list with 1 columns, item style 1',
    preview: require('@/assets/images/sections/faq/faq-style1prev1.jpg'),
    options: {
        section: {
            width: 'full',
            padding: {
                desktop: { top: 28, right: 0, bottom: 28, left: 0 },
                tablet: { top: 28, right: 0, bottom: 28, left: 0 },
                mobile: { top: 16, right: 0, bottom: 16, left: 0 },
            },
            borderRadius: {
                desktop: 0,
                tablet: 0,
                mobile: 0,
            },
            background: {
                desktop: {
                    color: null,
                    image: '4b89405a-3ef9-45ae-b575-daf8f889011d',
                },
                tablet: {
                    color: null,
                    image: null,
                },
                mobile: {
                    color: null,
                    image: null,
                },
            },
        },
        container: {
            width: 'content',
            padding: {
                desktop: {
                    top: 25,
                    right: 50,
                    bottom: 25,
                    left: 50,
                },
                tablet: null,
                mobile: null,
            },
            borderRadius: {
                desktop: 6,
                tablet: 0,
                mobile: 0,
            },
            background: 'light',
        },
        heading: {
            title: 'Frequently Asked Questions',
            colorTitle: null,
            note: null,
            colorNote: null,
            textAlign: 'center',
            marginBottom: {
                desktop: null,
                tablet: null,
                mobile: null,
            },
        },
        content: {
            htmlBefore: null,
            htmlAfter: null,
            colorText: null,
        },
        footer: {
            note: null,
            colorNote: null,
            textAlign: 'center',
            marginTop: {
                desktop: null,
                tablet: null,
                mobile: null,
            },
            button: {
                isShow: true,
                styles: 'primary',
                text: 'Request a call',
                textColor: null,
                bgColor: null,
                link: null,
                iconLeft: null,
                iconRight: 'phone',
            },
        },
    },
    itemLayout: {
        desktop: createLayoutVariation({ type: 'list', columns: 1 }),
        tablet: createLayoutVariation({ type: 'list', columns: 1 }),
        mobile: createLayoutVariation({ type: 'list', columns: 1 }),
    },
    itemsTemplate: itemStyleVariations.style1,
    items: (() => {
        const items: any[] = [];
        for (let i = 0; i < 4; i++) {
            items.push(
                createItem({
                    template: itemStyleVariations.style1,
                    position: i,
                    ...itemsFixture[i],
                })
            );
        }
        return items;
    })(),
});

summary.style1__list_column_1_dark = createSectionFixture({
    title: 'FAQ list with 1 columns, item style 1, dark',
    preview: require('@/assets/images/sections/faq/faq-style1prev2.jpg'),
    options: {
        section: {
            width: 'full',
            padding: {
                desktop: { top: 28, right: 0, bottom: 28, left: 0 },
                tablet: { top: 28, right: 0, bottom: 28, left: 0 },
                mobile: { top: 16, right: 0, bottom: 16, left: 0 },
            },
            borderRadius: {
                desktop: 0,
                tablet: 0,
                mobile: 0,
            },
            background: 'dark',
        },
        container: {
            width: 'content',
            padding: {
                desktop: {
                    top: 25,
                    right: 50,
                    bottom: 25,
                    left: 50,
                },
                tablet: null,
                mobile: null,
            },
            borderRadius: {
                desktop: 6,
                tablet: 0,
                mobile: 0,
            },
            background: 'none',
        },
        heading: {
            title: 'Frequently Asked Questions',
            colorTitle: '#ffffff',
            note: null,
            colorNote: '#ffffff',
            textAlign: 'center',
            marginBottom: {
                desktop: null,
                tablet: null,
                mobile: null,
            },
        },
        content: {
            htmlBefore: null,
            htmlAfter: null,
            colorText: '#ffffff',
        },
        footer: {
            note: null,
            colorNote: '#ffffff',
            textAlign: 'center',
            marginTop: {
                desktop: null,
                tablet: null,
                mobile: null,
            },
            button: {
                isShow: true,
                styles: 'primary',
                text: 'Request a call',
                textColor: null,
                bgColor: null,
                link: null,
                iconLeft: null,
                iconRight: 'phone',
            },
        },
    },
    itemLayout: {
        desktop: createLayoutVariation({ type: 'list', columns: 1 }),
        tablet: createLayoutVariation({ type: 'list', columns: 1 }),
        mobile: createLayoutVariation({ type: 'list', columns: 1 }),
    },
    itemsTemplate: itemStyleVariations.style1,
    items: (() => {
        const items: any[] = [];
        for (let i = 0; i < 4; i++) {
            items.push(
                createItem({
                    template: itemStyleVariations.style1,
                    position: i,
                    ...itemsFixture[i],
                })
            );
        }
        return items;
    })(),
});

summary.style1__list_column_2 = createSectionFixture({
    title: 'FAQ list with 2 columns, item style 2',
    preview: require('@/assets/images/sections/faq/faq-style2prev1.jpg'),
    options: {
        section: {
            width: 'full',
            padding: {
                desktop: { top: 28, right: 0, bottom: 28, left: 0 },
                tablet: { top: 28, right: 0, bottom: 28, left: 0 },
                mobile: { top: 16, right: 0, bottom: 16, left: 0 },
            },
            borderRadius: {
                desktop: 0,
                tablet: 0,
                mobile: 0,
            },
            background: 'none',
        },
        container: {
            width: 'content',
            padding: {
                desktop: null,
                tablet: null,
                mobile: null,
            },
            borderRadius: {
                desktop: 0,
                tablet: 0,
                mobile: 0,
            },
            background: 'none',
        },
        heading: {
            title: 'Frequently Asked Questions',
            colorTitle: null,
            note: null,
            colorNote: null,
            textAlign: 'center',
            marginBottom: {
                desktop: null,
                tablet: null,
                mobile: null,
            },
        },
        content: {
            htmlBefore: null,
            htmlAfter: null,
            colorText: null,
        },
        footer: {
            note: null,
            colorNote: null,
            textAlign: 'center',
            marginTop: {
                desktop: null,
                tablet: null,
                mobile: null,
            },
            button: {
                isShow: false,
                styles: 'primary',
                text: 'Button',
                textColor: null,
                bgColor: null,
                link: null,
                iconLeft: null,
                iconRight: null,
                type: 'Link',
                url: null,
            },
        },
    },
    itemLayout: {
        desktop: createLayoutVariation({ type: 'list', columns: 2 }),
        tablet: createLayoutVariation({ type: 'list', columns: 1 }),
        mobile: createLayoutVariation({ type: 'list', columns: 1 }),
    },
    itemsTemplate: itemStyleVariations.style2,
    items: (() => {
        const items: any[] = [];
        for (let i = 0; i < 4; i++) {
            items.push(
                createItem({
                    template: itemStyleVariations.style2,
                    position: i,
                    ...itemsFixture[i],
                })
            );
        }
        return items;
    })(),
});

summary.style1__list_column_2_middle = createSectionFixture({
    title: 'FAQ list with 2 columns, item style 2, middle',
    preview: require('@/assets/images/sections/faq/faq-style2prev1.jpg'),
    options: {
        section: {
            width: 'full',
            padding: {
                desktop: { top: 28, right: 0, bottom: 28, left: 0 },
                tablet: { top: 28, right: 0, bottom: 28, left: 0 },
                mobile: { top: 16, right: 0, bottom: 16, left: 0 },
            },
            borderRadius: {
                desktop: 0,
                tablet: 0,
                mobile: 0,
            },
            background: 'middle',
        },
        container: {
            width: 'content',
            padding: {
                desktop: null,
                tablet: null,
                mobile: null,
            },
            borderRadius: {
                desktop: 0,
                tablet: 0,
                mobile: 0,
            },
            background: 'none',
        },
        heading: {
            title: 'Frequently Asked Questions',
            colorTitle: null,
            note: null,
            colorNote: null,
            textAlign: 'center',
            marginBottom: {
                desktop: null,
                tablet: null,
                mobile: null,
            },
        },
        content: {
            htmlBefore: '<p style="text-align:center;"><br></p>',
            htmlAfter: null,
            colorText: null,
        },
        footer: {
            note: 'Please, feel free to contact us for any questions',
            colorNote: '#4a4a4a',
            textAlign: 'center',
            marginTop: {
                desktop: null,
                tablet: null,
                mobile: null,
            },
            button: {
                isShow: true,
                styles: 'primary',
                text: 'Get in touch',
                textColor: null,
                bgColor: null,
                link: null,
                iconLeft: null,
                iconRight: null,
            },
        },
    },
    itemLayout: {
        desktop: createLayoutVariation({ type: 'list', columns: 2 }),
        tablet: createLayoutVariation({ type: 'list', columns: 1 }),
        mobile: createLayoutVariation({ type: 'list', columns: 1 }),
    },
    itemsTemplate: itemStyleVariations.style2,
    items: (() => {
        const items: any[] = [];
        for (let i = 0; i < 4; i++) {
            items.push(
                createItem({
                    template: itemStyleVariations.style2,
                    position: i,
                    ...itemsFixture[i],
                })
            );
        }
        return items;
    })(),
});

export default summary;
