<template>
    <headed-block v-if="isDataLoaded" heading="Email">
        <template v-slot:header-prepend>
            <input-switch
                v-model="currentState.enabled.value"
                class="expansion__switch"
                @click.native.stop
            ></input-switch>
            <div class="address-card">
                <div class="address-card__img-container">
                    <img class="address-card__img" src="@/assets/images/mail.png" />
                </div>
            </div>
        </template>
        <alert type="success" class="notifications__alert" v-if="isUpdated">
            {{ $tc('notifications.updated', 2, { entity: this.$tc('entities.notifications.title', 1) }) }}
        </alert>
        <div class="form" v-if="isDataLoaded">
            <form-item class="form__input" v-bind="fields.recipients.props">
                <array-field
                    class="email-notification__array-field"
                    :new-btn-label="$t('settings.notifierEmail.addRecipient')"
                    v-model="currentState.recipients.value"
                    v-bind="fields.recipients.props"
                    :items="domains"
                    @update-field="onUpdateFieldValue"
                    name="recipients"
                ></array-field>
            </form-item>
            <form-item class="form__input" v-bind="fields.senderEmail.props">
                <input-text
                    v-model="currentState.senderEmail.value"
                    v-bind="fields.senderEmail.props"
                    @update-field="onUpdateFieldValue"
                    name="senderEmail"
                ></input-text>
            </form-item>
            <alert class="email-notification__alert -error" v-if="!domainCanBeUsedInEmail">
                <div class="alert__text">
                    <div v-html="$t('entities.domain.tooltips.emailCname.general')"></div>
                </div>
                <div class="alert__items">
                    <div class="alert__item">
                        <div class="alert__item-text">
                            <b> CNAME:</b>
                            runshopmail._domainkey
                        </div>
                        <div class="alert__item-text"><b>Value:</b> dkim.mail.runshop.io</div>
                    </div>
                </div>
            </alert>
            <alert class="email-notification__alert -error" v-if="!doesDomainExist">
                <div class="alert__text">
                    <i18n path="entities.domain.tooltips.createDomain" tag="div">
                        <router-link class="alert__link" :to="{ name: 'Domains' }" target="_blank">{{
                            $t('menus.main.settings.domains').toLowerCase()
                        }}</router-link>
                    </i18n>
                </div>
                <div class="alert__items">
                    <div class="alert__item">
                        <div class="alert__item-text">
                            <b> CNAME:</b>
                            runshopmail._domainkey
                        </div>
                        <div class="alert__item-text"><b>Value:</b> dkim.mail.runshop.io</div>
                    </div>
                </div>
            </alert>
            <form-item class="form__input" v-bind="fields.senderName.props">
                <input-text
                    v-model="currentState.senderName.value"
                    v-bind="fields.senderName.props"
                    @update-field="onUpdateFieldValue"
                    name="senderName"
                ></input-text>
            </form-item>
            <form-item class="form__input" v-bind="fields.logo.props">
                <input-image
                    v-model="currentState.logo.value"
                    v-bind="fields.logo.props"
                    @update-field="onUpdateFieldValue"
                    name="logo"
                    style="width: 200px !important"
                ></input-image>
            </form-item>

            <Button class="notifications__save-button" :disabled="!isSaveAllowed" @click="onUpdateSettings">{{
                $t('entities.save')
            }}</Button>
        </div>
    </headed-block>
</template>

<script>
import { cloneDeep, debounce, isEqual } from 'lodash';
import headedBlock from '@/components/common/HeadedBlock';
import FormItem from '@/components/form/item.vue';
import InputText from '@/components/form/controls/InputText';
import fields, { validateField } from '@/entities/notifications/EmailNotification.fields';
import EventEmitter from '@/helpers/eventEmitter.ts';
import SettingService from '@/services/SettingService';
import DomainService from '@/services/DomainService.ts';
import Button from '@/components/common/Button';
import Alert from '@/components/common/Alert';
import ArrayField from '@/components/form/controls/ArrayField';
export default {
    name: 'EmailNotifications',
    components: {
        Alert,
        Button,
        InputText,
        headedBlock,
        FormItem,
        ArrayField,
    },
    props: {
        settings: {
            type: Object,
            default: () => {},
        },
        domains: {
            type: Array,
            default: () => [],
        },
        instance: {
            type: String,
        },
    },
    data() {
        return {
            isDataLoaded: false,
            initialValue: {},
            currentState: {},
            isDomainConfirmed: true,
            domainCanBeUsedInEmail: true,
            doesDomainExist: true,
            errorDomainName: '',
            fields,
            fieldKeys: Object.keys(fields),
            isUpdated: false,
        };
    },

    async created() {
        this.isDataLoaded = false;
        this.currentState = cloneDeep(this.settings);
        this.initialValue = cloneDeep(this.currentState);
        this.isDataLoaded = true;
    },
    computed: {
        isSaveAllowed() {
            return !isEqual(this.currentState, this.initialValue);
        },
    },
    methods: {
        async validateForm() {
            this.isUpdated = false;
            let result = true;
            for (const name of this.fieldKeys) {
                const errors = await validateField.call(this, this.fields[name], this.currentState[name].value, true);
                if (errors.length !== 0 && Object.keys(errors).length !== 0) result = false;
                this.$set(this.fields[name].props, 'errors', errors);
            }
            if (result === false)
                EventEmitter.trigger('show-noty', {
                    type: 'error',
                    text: this.$t('notifications.validation.error'),
                });
            return result;
        },

        async onUpdateSettings() {
            const isFormValid = await this.validateForm();
            if (isFormValid === false) return;
            const domain = this.currentState.senderEmail.value.split('@')[1];
            const [domainError] = await DomainService.setAsEmail(this.instance, domain);
            if (domainError) {
                domainError.notify();
                return;
            }
            const [configError, configResponse] = await SettingService.updateGroup('notifierEmail', this.currentState);
            configError ? this.failedHandler(configResponse) : await this.successUpdateHandler();
        },

        async successUpdateHandler() {
            this.initialValue = cloneDeep(this.currentState);
            this.isUpdated = true;
        },
        failedHandler(response) {
            this.isUpdated = false;
            const children = response.data?.errors?.children;
            if (children) {
                this.fieldKeys.forEach((key) => {
                    const errors = children[key] ? children[key].errors : [];
                    if (errors) this.$set(this.currentState.fields[key].props, 'errors', errors);
                });
            }
            EventEmitter.trigger('show-noty', {
                type: 'error',
                text: `Error occurred. Status ${response.error.statusCode}: ${response.error.message}`,
            });
        },

        onUpdateFieldValue: debounce(async function(payload) {
            const { name } = payload;
            this.isDomainConfirmed = true;
            this.doesDomainExist = true;
            this.domainCanBeUsedInEmail = true;
            const errors = await validateField.call(this, this.fields[name], this.currentState[name].value);
            this.$set(this.fields[name].props, 'errors', errors);
        }, 1000),
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.email-notification {
    &__array-field {
        .form-item-error__list {
            margin: 0 0 0 16px;
            padding: 0;
        }
    }
    &__alert {
        margin-top: -15px;

        .alert__content {
            flex-wrap: wrap;
        }

        .alert__text {
            display: flex;
            gap: 10px;

            .icon {
                margin-top: 2px;
                align-self: start;
            }

            a {
                color: var(--v-warning-base);
            }
        }

        .alert__items {
            width: 100%;
            margin-left: 20%;
        }
    }
}
</style>
