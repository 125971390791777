export default {
    validation: {
        error: 'Validation Failed',
    },
    created: '{entity} was created successfully | {entity} were created successfully',
    updated: '{entity} was updated successfully | {entity} were updated successfully',
    deleted: '{entity} was deleted successfully | {entity} were deleted successfully',
    duplicated: '{entity} was duplicated successfully | {entity} were duplicated successfully',
    imported: 'Products are successfully imported',
    apiIsUnavailable: 'API is currently unavailable',
};
