export default (ctx) => ({
    title: {
        component: 'InputText',
        props: {
            rules: ['noEmpty'],
            labelTrans: 'entities.page.fields.title',
        },
        events: {
            change: (title) => {
                ctx.changeSlugFromTitle(title);
                ctx.changeHeadingFromTitle(title);
            },
        },
    },
    enabled: {
        component: 'InputSwitch',
        props: {
            text: 'entities.page.fields.enabled',
            noLabel: true,
            size: 'small',
        },
    },
    hidden: {
        component: 'InputSwitch',
        props: {
            text: 'entities.page.fields.hidden',
            noLabel: true,
            size: 'small',
        },
    },
    heading: {
        component: 'InputText',
        props: {
            // rules: ['noEmpty'],
            labelTrans: 'entities.page.fields.heading',
        },
        events: {
            input: ctx.changeHeading,
        },
    },
    previewImageId: {
        component: 'image-field',
        props: {
            labelTrans: 'entities.page.fields.previewImageId',
            imageType: 'page',
            note: 'JPG, PNG. Recommended size: 1200х630px. No more than 500kb',
            type: 'content',
        },
        events: {
            upload: ctx.updateCatalogImages,
            change: ctx.updateCatalogImages,
        },
    },
    metaTitle: {
        component: 'InputText',
        props: {
            labelTrans: 'entities.page.fields.metaTitle',
        },
    },
    metaDescription: {
        component: 'Textarea',
        props: {
            labelTrans: 'entities.page.fields.metaDescription',
        },
    },
    // pageStatus: {
    //     component: 'page-status',
    //     related: ['enabled', 'hidden', 'noIndex'],
    //     props: {
    //         labelTrans: 'entities.page.fields.pageStatus',
    //     },
    // },
    slug: {
        component: 'input-url',
        props: {
            rules: ['noEmpty'],
            labelTrans: 'entities.page.fields.slug',
        },
        events: {
            checkRedirect: ctx.createRedirectOnSave,
        },
    },
});
