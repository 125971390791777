var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.current)?_c('div',{key:_vm.current.id,staticClass:"option-list-sections"},[_vm._l((_vm.current.options),function(groupValue,groupName,index){return [(
                _vm.current.options[groupName].visibleCondition
                    ? _vm.current.options[groupName].visibleCondition(_vm.current, _vm.getDevice)
                    : true
            )?[(_vm.groups.includes(groupName))?_c('SectionsGroup',{key:groupName,attrs:{"group":groupName,"index":index}},[(groupValue.component)?_c('div',[_c('FormItem',_vm._b({},'FormItem',_vm.current.options[groupName].props,false),[_c(_vm.current.options[groupName].component,_vm._g(_vm._b({tag:"component",attrs:{"name":groupName,"disabled":_vm.disabledByHandler(_vm.current.options[groupName])},on:{"update-field":_vm.onUpdateFieldValue},model:{value:(_vm.current.options[groupName].value),callback:function ($$v) {_vm.$set(_vm.current.options[groupName], "value", $$v)},expression:"current.options[groupName].value"}},'component',_vm.current.options[groupName].props,false),_vm.getFieldEvents(_vm.current.options[groupName].events)))],1)],1):_vm._l((_vm.current.options[groupName]),function(field,key){return _c('div',{key:key,staticClass:"section-option-group__item-field"},[(
                                _vm.current.options[groupName][key].visibleCondition
                                    ? _vm.current.options[groupName][key].visibleCondition(_vm.current, _vm.getDevice)
                                    : true
                            )?_c('FormItem',_vm._b({attrs:{"name":key}},'FormItem',_vm.current.options[groupName][key].props,false),[_c(field.component,_vm._g(_vm._b({tag:"component",attrs:{"name":key,"group":groupName,"disabled":_vm.disabledByHandler(_vm.current.options[groupName], key)},on:{"update-field":_vm.onUpdateFieldValue},model:{value:(_vm.current.options[groupName][key].value),callback:function ($$v) {_vm.$set(_vm.current.options[groupName][key], "value", $$v)},expression:"current.options[groupName][key].value"}},'component',_vm.current.options[groupName][key].props,false),_vm.getFieldEvents(_vm.current.options[groupName][key].events)))],1):_vm._e()],1)})],2):_c('div',{key:groupName,staticClass:"section-option-group"},[_c('div',{staticClass:"section-option-group__heading"},[_c('span',{staticClass:"text-subtitle"},[_vm._v(_vm._s(_vm.$t(("builder.sectionOptions.groups." + groupName))))])]),(groupValue.component)?_c('div',[_c('CompositeField'),_c('FormItem',_vm._b({},'FormItem',_vm.current.options[groupName].props,false),[_c(_vm.current.options[groupName].component,_vm._g(_vm._b({tag:"component",attrs:{"name":groupName,"disabled":_vm.disabledByHandler(_vm.current.options[groupName])},on:{"update-field":_vm.onUpdateFieldValue},model:{value:(_vm.current.options[groupName].value),callback:function ($$v) {_vm.$set(_vm.current.options[groupName], "value", $$v)},expression:"current.options[groupName].value"}},'component',_vm.current.options[groupName].props,false),_vm.getFieldEvents(_vm.current.options[groupName].events)))],1)],1):_vm._l((_vm.current.options[groupName]),function(field,key){return _c('div',{key:groupName + '-' + key,staticClass:"section-option-group__item-field"},[(
                                _vm.current.options[groupName][key].visibleCondition
                                    ? _vm.current.options[groupName][key].visibleCondition(_vm.current, _vm.getDevice)
                                    : true
                            )?_c('FormItem',_vm._b({attrs:{"name":key}},'FormItem',_vm.current.options[groupName][key].props,false),[_c(field.component,_vm._g(_vm._b({tag:"component",attrs:{"name":key,"group":groupName,"disabled":_vm.disabledByHandler(_vm.current.options[groupName], key)},on:{"update-field":_vm.onUpdateFieldValue},model:{value:(_vm.current.options[groupName][key].value),callback:function ($$v) {_vm.$set(_vm.current.options[groupName][key], "value", $$v)},expression:"current.options[groupName][key].value"}},'component',_vm.current.options[groupName][key].props,false),_vm.getFieldEvents(_vm.current.options[groupName][key].events)))],1):_vm._e()],1)})],2)]:_vm._e()]}),(!_vm.current.required)?_c('div',{staticClass:"option-list-sections__btns"},[_c('input-switch',{staticClass:"expansion__switch",attrs:{"size":"small","text":"builder.global.btns.hidden"},nativeOn:{"click":function($event){$event.stopPropagation();}},model:{value:(_vm.current.hidden),callback:function ($$v) {_vm.$set(_vm.current, "hidden", $$v)},expression:"current.hidden"}}),_c('Button',{staticClass:"option-list-sections__copy-id",attrs:{"type":"secondary","icon":"copy","fullwidth":"","title":"Copy section ID"},on:{"click":function($event){return _vm.copySectionId(_vm.current.id)}}},[_vm._v(" "+_vm._s(_vm.current.id)+" ")]),_c('Button',{attrs:{"type":"primary","icon":"copy","fullwidth":""},on:{"click":_vm.dublicateSection}},[_vm._v(" "+_vm._s(_vm.$t('builder.global.btns.dublicate'))+" ")]),_c('Button',{attrs:{"type":"primary","icon":"copy","fullwidth":""},on:{"click":function($event){return _vm.copySection(_vm.current)}}},[_vm._v(" "+_vm._s(_vm.$t('builder.global.btns.copy'))+" ")]),_c('Button',{attrs:{"type":"secondary","icon":"delete","fullwidth":""},on:{"click":function($event){return _vm.removeSection(_vm.current.id)}}},[_vm._v(" "+_vm._s(_vm.$t('builder.global.btns.remove'))+" ")])],1):_vm._e()],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }