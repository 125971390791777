import { uuid } from 'vue-uuid';
import initFields from '@/entities/seo/redirectList.fields';

/**
 * Класс экземпляра редиректа
 */
export default class Redirect {
    /**
     *
     * @param {Object}  param0 - объект данных редиректа
     */
    constructor({
        id = uuid.v4(),
        subject = {
            name: '',
            id: '',
        },
        from = '',
        to = '',
    } = {}) {
        this.id = id;
        this.subject = subject;
        this.from = from;
        this.to = to;
        this.fields = initFields(this);
    }

    get data() {
        return {
            id: this.id,
            subject: this.subject,
            from: this.from && !this.from.startsWith('/') ? '/' + this.from : this.from,
            to: this.to && !this.to.startsWith('/') ? '/' + this.to : this.to,
        };
    }
}
