import { uuid } from 'vue-uuid';
import initFields from '@/entities/blog/Comment.fields';

//images в content через text-editor? Или не нужны text-editor и images?
//enabled ?
//updatedAt?
export default class Comment {
    constructor({
        id = uuid.v4(),
        name = '',
        email = '',
        text = '',
        articleId = '',
        parentId = '',
        enabled = false,
        children = [],
        createdAt = '',
    } = {}) {
        this.id = id;
        this.name = name;
        this.email = email;
        this.text = text;
        this.articleId = articleId;
        this.parentId = parentId;
        this.children = children;
        this.enabled = enabled;
        this.createdAt = createdAt;
        this.fields = initFields(this);
    }

    get data() {
        return {
            id: this.id,
            name: this.name,
            email: this.email,
            text: this.text,
            articleId: this.articleId,
            parentId: this.parentId,
            enabled: this.enabled,
            createdAt: this.createdAt,
        };
    }
}
