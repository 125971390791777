import common from '@/configs/groups/common';
import ecommerce from '@/configs/groups/ecommerce';
import footer from '@/configs/groups/footer';
import header from '@/configs/groups/header';
import notifierEmail from '@/configs/groups/notifierEmail';
import notifierTelegram from '@/configs/groups/notifierTelegram';
import paymentCashOnDelivery from '@/configs/groups/paymentCashOnDelivery';
import seo from '@/configs/groups/seo';
import socials from '@/configs/groups/socials';
import media from '@/configs/groups/media';
import styles from '@/configs/groups/styles';
import language from '@/configs/groups/language';
import erpFirstBit from '@/configs/groups/erpFirstBit';
import bitrix from '@/configs/groups/bitrix';

export default {
    common,
    ecommerce,
    footer,
    header,
    notifierEmail,
    notifierTelegram,
    paymentCashOnDelivery,
    seo,
    socials,
    media,
    styles,
    language,
    erpFirstBit,
    bitrix,
};
