import { SectionFixtureType } from '@/entities/section/fixtures';
// import { cloneDeep } from 'lodash';
import { createLayoutVariation } from '@/entities/section/fixtures/_base';

const itemStyleVariations = {
    style1: 'style1',
    style2: 'style2',
    style3: 'style3',
    style4: 'style4',
    style5: 'style5',
    style6: 'style6',
    style7: 'style7',
};

const createSectionFixture = (args: {
    preview: string;
    title: string;
    itemLayout: any;
    itemsTemplate: string;
    items: any[];
    additionalContent: boolean;
    imageProportions?: string;
}): SectionFixtureType => {
    const additionalContent = {
        htmlBefore:
            '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>',
        htmlAfter:
            '<p>Risus viverra adipiscing at in tellus integer. Morbi tincidunt augue interdum velit euismod in pellentesque massa. Sed euismod nisi porta lorem. Diam maecenas ultricies mi eget. Lectus quam id leo in vitae turpis massa sed elementum. Ut venenatis tellus in metus vulputate eu scelerisque felis imperdiet. Vitae auctor eu augue ut lectus arcu bibendum. Amet massa vitae tortor condimentum lacinia quis vel eros.</p>',
        button: {
            isShow: true,
            styles: 'primary',
            text: 'Send Enquiry',
            textColor: null,
            bgColor: null,
            link: null,
            iconLeft: null,
            iconRight: null,
            type: 'popup',
        },
    };
    const defaultContent = {
        htmlBefore: null,
        htmlAfter: null,
        button: {
            isShow: false,
            styles: 'primary',
            text: null,
            textColor: null,
            bgColor: null,
            link: null,
            iconLeft: null,
            iconRight: null,
            type: 'link',
        },
    };
    return {
        preview: args.preview,
        title: args.title,
        group: 'Category',
        data: {
            id: 'fixture',
            items: args.items,
            required: false,
            fixed: false,
            type: 'SectionCategoryBase',
            options: {
                section: {
                    width: 'full',
                    padding: {
                        desktop: { top: 28, right: 0, bottom: 28, left: 0 },
                        tablet: { top: 28, right: 0, bottom: 28, left: 0 },
                        mobile: { top: 16, right: 0, bottom: 16, left: 0 },
                    },
                    borderRadius: {
                        desktop: 0,
                        tablet: 0,
                        mobile: 0,
                    },
                    background: 'none',
                },
                container: {
                    width: 'content',
                    padding: {
                        desktop: null,
                        tablet: null,
                        mobile: null,
                    },
                    borderRadius: {
                        desktop: 0,
                        tablet: 0,
                        mobile: 0,
                    },
                    background: 'none',
                },
                heading: {
                    title: 'Categories',
                    colorTitle: null,
                    note: null,
                    colorNote: null,
                    textAlign: 'center',
                    marginBottom: {
                        desktop: null,
                        tablet: null,
                        mobile: null,
                    },
                },
                content: {
                    htmlBefore: args.additionalContent ? additionalContent.htmlBefore : defaultContent.htmlBefore,
                    htmlAfter: args.additionalContent ? additionalContent.htmlAfter : defaultContent.htmlAfter,
                    color: null,
                },
                itemsLayout: args.itemLayout,
                itemsStyle: {
                    template: args.itemsTemplate,
                    gap: 20,
                    imageProportions: args.imageProportions || 'img-1x1',
                },
                footer: {
                    note: null,
                    colorNote: null,
                    textAlign: 'center',
                    marginTop: {
                        desktop: null,
                        tablet: null,
                        mobile: null,
                    },
                    button: args.additionalContent ? additionalContent.button : defaultContent.button,
                },
            },
        },
    };
};

const summary: any = {};

summary.style1__list_column_3 = createSectionFixture({
    title: 'Category style 1 list',
    preview: require('@/assets/images/sections/categories/style1Prev1.jpg'),
    itemLayout: {
        desktop: createLayoutVariation({ type: 'list', columns: 3 }),
        tablet: createLayoutVariation({ type: 'list', columns: 2 }),
        mobile: createLayoutVariation({ type: 'list', columns: 1 }),
    },
    itemsTemplate: itemStyleVariations.style1,
    items: [
        {
            id: 'fixture',
            type: 'style1',
            options: {
                heading: {
                    title: 'Running Shoes',
                    colorTitle: null,
                    textAlign: 'left',
                },
                content: {
                    link: null,
                    image: '5f86800f-da98-4371-8d6d-4d1d228c9a06',
                },
            },
            position: 0,
        },
        {
            id: 'fixture',
            type: 'style1',
            options: {
                heading: {
                    title: 'Basketball Shoes',
                    colorTitle: null,
                    textAlign: 'left',
                },
                content: {
                    link: null,
                    image: '03ffced5-e07a-4ae6-a8cd-6be9b23da3f1',
                },
            },
            position: 1,
        },
        {
            id: 'fixture',
            type: 'style1',
            options: {
                heading: {
                    title: 'Football Shoes',
                    colorTitle: null,
                    textAlign: 'left',
                },
                content: {
                    link: null,
                    image: '1f6c014f-29ef-42d8-9806-244161a5e2cc',
                },
            },
            position: 2,
        },
    ],
    additionalContent: false,
});

summary.style1__grid = createSectionFixture({
    title: 'Category style 1 grid',
    preview: require('@/assets/images/sections/categories/style1Prev2.png'),
    itemLayout: {
        desktop: createLayoutVariation({ type: 'grid', grid: '2x1x1' }),
        tablet: createLayoutVariation({ type: 'grid', grid: '2x1' }),
        mobile: createLayoutVariation({ type: 'list', columns: 1 }),
    },
    itemsTemplate: itemStyleVariations.style1,
    items: [
        {
            id: 'fixture',
            type: 'style2',
            options: {
                heading: {
                    title: 'Accessories',
                    colorTitle: null,
                    textAlign: 'center',
                },
                content: {
                    link: null,
                    image: '8283b829-6b4d-4121-a7c6-3ae9fd78f5df',
                },
            },
            position: 0,
        },
        {
            id: 'fixture',
            type: 'style2',
            options: {
                heading: {
                    title: 'Laptops',
                    colorTitle: null,
                    textAlign: 'center',
                },
                content: {
                    link: null,
                    image: '4344c2a1-ee87-478b-beb2-f1eaa5d32861',
                },
            },
            position: 1,
        },
        {
            id: 'fixture',
            type: 'style2',
            options: {
                heading: {
                    title: 'Phones',
                    colorTitle: null,
                    textAlign: 'center',
                },
                content: {
                    link: null,
                    image: 'f4572a42-cc49-41b8-807c-44d4eb1b316d',
                },
            },
            position: 2,
        },
        {
            id: 'fixture',
            type: 'style2',
            options: {
                heading: {
                    title: 'TV',
                    colorTitle: null,
                    textAlign: 'center',
                },
                content: {
                    link: null,
                    image: '7889521b-f193-48cb-9334-0439b00cff83',
                },
            },
            position: 3,
        },
        {
            id: 'fixture',
            type: 'style2',
            options: {
                heading: {
                    title: 'Games',
                    colorTitle: null,
                    textAlign: 'center',
                },
                content: {
                    link: null,
                    image: 'a5928333-8fa7-450f-a448-825e2d0281e7',
                },
            },
            position: 4,
        },
    ],
    additionalContent: false,
    imageProportions: 'img-3x2',
});

summary.style2__list_column_4__prev1 = createSectionFixture({
    title: 'Category style 2 list',
    preview: require('@/assets/images/sections/categories/style2Prev1.jpg'),
    itemLayout: {
        desktop: createLayoutVariation({ type: 'list', columns: 4 }),
        tablet: createLayoutVariation({ type: 'list', columns: 2 }),
        mobile: createLayoutVariation({ type: 'list', columns: 1 }),
    },
    itemsTemplate: itemStyleVariations.style2,
    items: [
        {
            id: 'fixture',
            type: 'style2',
            options: {
                heading: {
                    title: 'For Face',
                    colorTitle: null,
                    textAlign: 'center',
                },
                content: {
                    link: null,
                    image: 'f6a93346-8893-4635-b25a-e5849568d460',
                },
            },
            position: 0,
        },
        {
            id: 'fixture',
            type: 'style2',
            options: {
                heading: {
                    title: 'For Hair',
                    colorTitle: null,
                    textAlign: 'center',
                },
                content: {
                    link: null,
                    image: '7f3d984e-9c01-49c4-b6d8-409b06da1070',
                },
            },
            position: 1,
        },
        {
            id: 'fixture',
            type: 'style2',
            options: {
                heading: {
                    title: 'For Body',
                    colorTitle: null,
                    textAlign: 'center',
                },
                content: {
                    link: null,
                    image: '8dfeb5e0-22ab-4bfb-b376-4f895c80c362',
                },
            },
            position: 2,
        },
        {
            id: 'fixture',
            type: 'style2',
            options: {
                heading: {
                    title: 'Gift Set',
                    colorTitle: null,
                    textAlign: 'center',
                },
                content: {
                    link: null,
                    image: '1d960db5-d070-4cb7-99cc-b2aa8ab7aafe',
                },
            },
            position: 3,
        },
    ],
    additionalContent: false,
});

summary.style2__list_column_4__prev2 = createSectionFixture({
    title: 'Category style 2 list',
    preview: require('@/assets/images/sections/categories/style2Prev2.jpg'),
    itemLayout: {
        desktop: createLayoutVariation({ type: 'list', columns: 4 }),
        tablet: createLayoutVariation({ type: 'list', columns: 2 }),
        mobile: createLayoutVariation({ type: 'list', columns: 1 }),
    },
    itemsTemplate: itemStyleVariations.style2,
    items: [
        {
            id: 'fixture',
            type: 'style2',
            options: {
                heading: {
                    title: 'Bags',
                    colorTitle: null,
                    textAlign: 'center',
                },
                content: {
                    link: null,
                    image: '55206999-cb6a-41d3-a593-3af57bee85ba',
                },
            },
            position: 0,
        },
        {
            id: 'fixture',
            type: 'style2',
            options: {
                heading: {
                    title: 'Wallets',
                    colorTitle: null,
                    textAlign: 'center',
                },
                content: {
                    link: null,
                    image: 'dd115889-d4ab-4c9c-8de8-bc582fb76bbb',
                },
            },
            position: 1,
        },
        {
            id: 'fixture',
            type: 'style2',
            options: {
                heading: {
                    title: 'Backpacks',
                    colorTitle: null,
                    textAlign: 'center',
                },
                content: {
                    link: null,
                    image: 'e22663ea-0b37-42e1-aa80-0122f5edc390',
                },
            },
            position: 2,
        },
        {
            id: 'fixture',
            type: 'style2',
            options: {
                heading: {
                    title: 'Clutches',
                    colorTitle: null,
                    textAlign: 'center',
                },
                content: {
                    link: null,
                    image: 'e964f85e-38d4-4230-9b51-42bfead70b01',
                },
            },
            position: 3,
        },
    ],
    additionalContent: false,
});

summary.style2__list_column_5__prev1 = createSectionFixture({
    title: 'Category style 2 list',
    preview: require('@/assets/images/sections/categories/style2Prev3.jpg'),
    itemLayout: {
        desktop: createLayoutVariation({ type: 'list', columns: 5 }),
        tablet: createLayoutVariation({ type: 'list', columns: 3 }),
        mobile: createLayoutVariation({ type: 'list', columns: 1 }),
    },
    itemsTemplate: itemStyleVariations.style2,
    items: [
        {
            id: 'fixture',
            type: 'style2',
            options: {
                heading: {
                    title: 'Laptops',
                    colorTitle: null,
                    textAlign: 'center',
                },
                content: {
                    link: null,
                    image: '4344c2a1-ee87-478b-beb2-f1eaa5d32861',
                },
            },
            position: 0,
        },
        {
            id: 'fixture',
            type: 'style2',
            options: {
                heading: {
                    title: 'Phones',
                    colorTitle: null,
                    textAlign: 'center',
                },
                content: {
                    link: null,
                    image: 'f4572a42-cc49-41b8-807c-44d4eb1b316d',
                },
            },
            position: 1,
        },
        {
            id: 'fixture',
            type: 'style2',
            options: {
                heading: {
                    title: 'TV',
                    colorTitle: null,
                    textAlign: 'center',
                },
                content: {
                    link: null,
                    image: '7889521b-f193-48cb-9334-0439b00cff83',
                },
            },
            position: 2,
        },
        {
            id: 'fixture',
            type: 'style2',
            options: {
                heading: {
                    title: 'Games',
                    colorTitle: null,
                    textAlign: 'center',
                },
                content: {
                    link: null,
                    image: 'a5928333-8fa7-450f-a448-825e2d0281e7',
                },
            },
            position: 3,
        },
        {
            id: 'fixture',
            type: 'style2',
            options: {
                heading: {
                    title: 'Accessories',
                    colorTitle: null,
                    textAlign: 'center',
                },
                content: {
                    link: null,
                    image: '8283b829-6b4d-4121-a7c6-3ae9fd78f5df',
                },
            },
            position: 4,
        },
    ],
    additionalContent: false,
});

summary.style3__list_column_3 = createSectionFixture({
    title: 'Category style 3 list',
    preview: require('@/assets/images/sections/categories/style3Prev1.jpg'),
    itemLayout: {
        desktop: createLayoutVariation({ type: 'list', columns: 3 }),
        tablet: createLayoutVariation({ type: 'list', columns: 2 }),
        mobile: createLayoutVariation({ type: 'list', columns: 1 }),
    },
    itemsTemplate: itemStyleVariations.style3,
    items: [
        {
            id: 'fixture',
            type: 'style3',
            options: {
                heading: {
                    title: 'Upholstered Chairs',
                    textColor: null,
                    background: null,
                    textAlign: 'left',
                },
                content: {
                    link: null,
                    image: '4470eada-b6f9-4591-80c0-078efa3687c5',
                },
            },
            position: 0,
        },
        {
            id: 'fixture',
            type: 'style3',
            options: {
                heading: {
                    title: 'Chairs',
                    textColor: null,
                    background: null,
                    textAlign: 'left',
                },
                content: {
                    link: null,
                    image: '2ec541fb-ba2b-4be8-bf7d-37539dc95b1f',
                },
            },
            position: 1,
        },
        {
            id: 'fixture',
            type: 'style3',
            options: {
                heading: {
                    title: 'Stools',
                    textColor: null,
                    background: null,
                    textAlign: 'left',
                },
                content: {
                    link: null,
                    image: 'd8270029-6787-4cc1-8243-cd3d1eb29a2e',
                },
            },
            position: 2,
        },
    ],
    additionalContent: false,
});

summary.style3__list_column_2 = createSectionFixture({
    title: 'Category style 3 list',
    preview: require('@/assets/images/sections/categories/style3Prev2.jpg'),
    itemLayout: {
        desktop: createLayoutVariation({ type: 'list', columns: 2 }),
        tablet: createLayoutVariation({ type: 'list', columns: 2 }),
        mobile: createLayoutVariation({ type: 'list', columns: 1 }),
    },
    itemsTemplate: itemStyleVariations.style3,
    items: [
        {
            id: 'fixture',
            type: 'style3',
            options: {
                heading: {
                    title: "Men's Perfumes",
                    textColor: null,
                    background: null,
                    textAlign: 'left',
                },
                content: {
                    link: null,
                    image: 'a743a4c5-4f5e-4a3a-803c-9bfa51e3e899',
                },
            },
            position: 0,
        },
        {
            id: 'fixture',
            type: 'style3',
            options: {
                heading: {
                    title: "Women's Perfumes",
                    textColor: null,
                    background: null,
                    textAlign: 'left',
                },
                content: {
                    link: null,
                    image: '2f57fd86-5f05-44ea-821a-5c917da174db',
                },
            },
            position: 1,
        },
    ],
    additionalContent: false,
});

summary.style3__grid = createSectionFixture({
    title: 'Category style 3 grid',
    preview: require('@/assets/images/sections/categories/style3Prev3.png'),
    itemLayout: {
        desktop: createLayoutVariation({ type: 'grid', grid: '3x4-4x3' }),
        tablet: createLayoutVariation({ type: 'list', columns: 2 }),
        mobile: createLayoutVariation({ type: 'list', columns: 1 }),
    },
    itemsTemplate: itemStyleVariations.style3,
    items: [
        {
            id: 'fixture',
            type: 'style3',
            options: {
                heading: {
                    title: 'Rings',
                    colorTitle: null,
                    textAlign: 'left',
                },
                content: {
                    link: null,
                    image: 'eb781e32-f999-40f5-ab27-f35d62c33030',
                    note: null,
                    showButton: true,
                    btnText: null,
                },
            },
            position: 0,
        },
        {
            id: 'fixture',
            type: 'style3',
            options: {
                heading: {
                    title: 'Pendants',
                    colorTitle: null,
                    textAlign: 'left',
                },
                content: {
                    link: null,
                    image: 'fd0bc9ba-90ca-4144-ab07-840c817aac29',
                    note: null,
                    showButton: true,
                    btnText: null,
                },
            },
            position: 1,
        },
        {
            id: 'fixture',
            type: 'style3',
            options: {
                heading: {
                    title: 'Bracelets',
                    colorTitle: null,
                    textAlign: 'left',
                },
                content: {
                    link: null,
                    image: '9edc9d1a-ede3-4925-9b7d-d4ffa7ec3709',
                    note: null,
                    showButton: true,
                    btnText: null,
                },
            },
            position: 2,
        },
        {
            id: 'fixture',
            type: 'style3',
            options: {
                heading: {
                    title: 'Earrings',
                    colorTitle: null,
                    textAlign: 'left',
                },
                content: {
                    link: null,
                    image: 'd5288303-a9ee-49d8-8274-c0cc5e6f3635',
                    note: null,
                    showButton: true,
                    btnText: null,
                },
            },
            position: 3,
        },
    ],
    additionalContent: false,
    imageProportions: 'img-2x1',
});

summary.style4__carousel__prev1 = createSectionFixture({
    title: 'Category style 4 list',
    preview: require('@/assets/images/sections/categories/style4Prev1.jpg'),
    itemLayout: {
        desktop: createLayoutVariation({ type: 'list', columns: 6 }),
        tablet: createLayoutVariation({ type: 'list', columns: 4 }),
        mobile: createLayoutVariation({ type: 'list', columns: 2 }),
    },
    itemsTemplate: itemStyleVariations.style4,
    items: [
        {
            id: 'fixture',
            type: 'style4',
            options: {
                heading: {
                    title: 'For him',
                    colorTitle: null,
                    textAlign: 'center',
                },
                content: {
                    link: null,
                    image: '398baaf9-d6ae-4539-9684-ec18e4bd8cd3',
                },
            },
            position: 0,
        },
        {
            id: 'fixture',
            type: 'style4',
            options: {
                heading: {
                    title: 'For her',
                    colorTitle: null,
                    textAlign: 'center',
                },
                content: {
                    link: null,
                    image: 'f6eadc4d-4bb7-4c9b-bd4e-22fee90682af',
                },
            },
            position: 1,
        },
        {
            id: 'fixture',
            type: 'style4',
            options: {
                heading: {
                    title: 'T-shirts',
                    colorTitle: null,
                    textAlign: 'center',
                },
                content: {
                    link: null,
                    image: '1ef1ece4-8013-4a42-8b48-c021a5d35851',
                },
            },
            position: 2,
        },
        {
            id: 'fixture',
            type: 'style4',
            options: {
                heading: {
                    title: 'Trousers',
                    colorTitle: null,
                    textAlign: 'center',
                },
                content: {
                    link: null,
                    image: '3f117432-bce8-40cf-98e8-ea7652876568',
                },
            },
            position: 3,
        },
        {
            id: 'fixture',
            type: 'style4',
            options: {
                heading: {
                    title: 'Costumes',
                    colorTitle: null,
                    textAlign: 'center',
                },
                content: {
                    link: null,
                    image: '331a2f57-b8e9-46c0-bcdf-3bcea5bcf005',
                },
            },
            position: 4,
        },
        {
            id: 'fixture',
            type: 'style4',
            options: {
                heading: {
                    title: 'Skirts',
                    colorTitle: null,
                    textAlign: 'center',
                },
                content: {
                    link: null,
                    image: 'a9c0f348-e72a-4b81-ab34-78e3d5f51c3a',
                },
            },
            position: 5,
        },
        {
            id: 'fixture',
            type: 'style4',
            options: {
                heading: {
                    title: 'Shirts',
                    colorTitle: null,
                    textAlign: 'center',
                },
                content: {
                    link: null,
                    image: '00fd4132-f85c-48b8-96f2-4aff12683c96',
                },
            },
            position: 6,
        },
    ],
    additionalContent: false,
});

summary.style5__list_column_2 = createSectionFixture({
    title: 'Category style 5 list',
    preview: require('@/assets/images/sections/categories/style5Prev1.jpg'),
    itemLayout: {
        desktop: createLayoutVariation({ type: 'list', columns: 2 }),
        tablet: createLayoutVariation({ type: 'list', columns: 2 }),
        mobile: createLayoutVariation({ type: 'list', columns: 1 }),
    },
    itemsTemplate: itemStyleVariations.style5,
    items: [
        {
            id: 'fixture',
            type: 'style5',
            options: {
                heading: {
                    title: "Women's clothing",
                    colorTitle: null,
                    textAlign: 'left',
                },
                content: {
                    link: null,
                    image: 'c4882e8f-3807-49ea-9f2e-be34997549b1',
                },
            },
            position: 0,
        },
        {
            id: 'fixture',
            type: 'style5',
            options: {
                heading: {
                    title: "Men's clothing",
                    colorTitle: null,
                    textAlign: 'left',
                },
                content: {
                    link: null,
                    image: '442871f1-261f-436d-b756-c938f5434a3e',
                },
            },
            position: 1,
        },
    ],
    additionalContent: false,
});

summary.style5__list_column_3 = createSectionFixture({
    title: 'Category style 5 list',
    preview: require('@/assets/images/sections/categories/style5Prev2.jpg'),
    itemLayout: {
        desktop: createLayoutVariation({ type: 'list', columns: 3 }),
        tablet: createLayoutVariation({ type: 'list', columns: 2 }),
        mobile: createLayoutVariation({ type: 'list', columns: 1 }),
    },
    itemsTemplate: itemStyleVariations.style5,
    items: [
        {
            id: 'fixture',
            type: 'style5',
            options: {
                heading: {
                    title: 'Sofas',
                    colorTitle: null,
                    textAlign: 'left',
                },
                content: {
                    link: null,
                    image: 'ff34307d-cca6-4b11-95b5-36634e7c25ce',
                },
            },
            position: 0,
        },
        {
            id: 'fixture',
            type: 'style5',
            options: {
                heading: {
                    title: 'Camodes',
                    colorTitle: null,
                    textAlign: 'left',
                },
                content: {
                    link: null,
                    image: '4267dd54-28cd-4379-83ce-548efe31b047',
                },
            },
            position: 1,
        },
        {
            id: 'fixture',
            type: 'style5',
            options: {
                heading: {
                    title: 'Tables',
                    colorTitle: null,
                    textAlign: 'left',
                },
                content: {
                    link: null,
                    image: 'd28b1a8f-f8e6-4cac-a7b1-dfbb9834e619',
                },
            },
            position: 2,
        },
    ],
    additionalContent: false,
});

summary.style5__grid = createSectionFixture({
    title: 'Category style 5 grid',
    preview: require('@/assets/images/sections/categories/style5Prev3.png'),
    itemLayout: {
        desktop: createLayoutVariation({ type: 'grid', grid: '3x3-2x2x2' }),
        tablet: createLayoutVariation({ type: 'grid', grid: '3x3-2x2x2' }),
        mobile: createLayoutVariation({ type: 'list', columns: 1 }),
    },
    itemsTemplate: itemStyleVariations.style5,
    items: [
        {
            id: 'fixture',
            type: 'style1',
            options: {
                heading: {
                    title: 'Running Shoes',
                    colorTitle: null,
                    textAlign: 'left',
                },
                content: {
                    link: null,
                    image: '5f86800f-da98-4371-8d6d-4d1d228c9a06',
                },
            },
            position: 0,
        },
        {
            id: 'fixture',
            type: 'style1',
            options: {
                heading: {
                    title: 'Basketball Shoes',
                    colorTitle: null,
                    textAlign: 'left',
                },
                content: {
                    link: null,
                    image: '03ffced5-e07a-4ae6-a8cd-6be9b23da3f1',
                },
            },
            position: 1,
        },
        {
            id: 'fixture',
            type: 'style1',
            options: {
                heading: {
                    title: 'Football Shoes',
                    colorTitle: null,
                    textAlign: 'left',
                },
                content: {
                    link: null,
                    image: '1f6c014f-29ef-42d8-9806-244161a5e2cc',
                },
            },
            position: 2,
        },
        {
            id: 'fixture',
            type: 'style1',
            options: {
                heading: {
                    title: 'Running Shoes',
                    colorTitle: null,
                    textAlign: 'left',
                },
                content: {
                    link: null,
                    image: '5f86800f-da98-4371-8d6d-4d1d228c9a06',
                },
            },
            position: 3,
        },
        {
            id: 'fixture',
            type: 'style1',
            options: {
                heading: {
                    title: 'Basketball Shoes',
                    colorTitle: null,
                    textAlign: 'left',
                },
                content: {
                    link: null,
                    image: '03ffced5-e07a-4ae6-a8cd-6be9b23da3f1',
                },
            },
            position: 4,
        },
    ],
    additionalContent: false,
    imageProportions: 'img-3x2',
});

summary.style6__list_column_3 = createSectionFixture({
    title: 'Category style 6 list',
    preview: require('@/assets/images/sections/categories/style6Prev1.jpg'),
    itemLayout: {
        desktop: createLayoutVariation({ type: 'list', columns: 3 }),
        tablet: createLayoutVariation({ type: 'list', columns: 2 }),
        mobile: createLayoutVariation({ type: 'list', columns: 1 }),
    },
    itemsTemplate: itemStyleVariations.style6,
    items: [
        {
            id: 'fixture',
            type: 'style6',
            options: {
                heading: {
                    title: 'France',
                    colorTitle: null,
                    textAlign: 'left',
                },
                content: {
                    link: null,
                    image: '18027e33-68f1-4f5e-9586-c61fd18580ed',
                    alt: null,
                    note: null,
                },
            },
            position: 0,
        },
        {
            id: 'fixture',
            type: 'style6',
            options: {
                heading: {
                    title: 'America',
                    colorTitle: null,
                    textAlign: 'left',
                },
                content: {
                    link: null,
                    image: '0e34fc64-9ce7-43de-8586-db2a304ce470',
                    alt: null,
                    note: null,
                },
            },
            position: 1,
        },
        {
            id: 'fixture',
            type: 'style6',
            options: {
                heading: {
                    title: 'Germany',
                    colorTitle: null,
                    textAlign: 'left',
                },
                content: {
                    link: null,
                    image: '521edaeb-44dc-4290-8f0e-38e6f3ad6959',
                    alt: null,
                    note: null,
                },
            },
            position: 2,
        },
    ],
    additionalContent: false,
});

summary.style7__list_column_3__prev1 = createSectionFixture({
    title: 'Category style 7 list',
    preview: require('@/assets/images/sections/categories/style7Prev1.jpg'),
    itemLayout: {
        desktop: createLayoutVariation({ type: 'list', columns: 3 }),
        tablet: createLayoutVariation({ type: 'list', columns: 2 }),
        mobile: createLayoutVariation({ type: 'list', columns: 1 }),
    },
    itemsTemplate: itemStyleVariations.style7,
    items: [
        {
            id: 'fixture',
            type: 'style7',
            options: {
                heading: {
                    title: 'Plates',
                    colorTitle: null,
                    textAlign: 'left',
                },
                content: {
                    link: null,
                    image: '3ab40287-fac6-4f6f-a7e6-0b4ed8f0248e',
                    note: null,
                    showButton: true,
                    btnText: null,
                },
            },
            position: 0,
        },
        {
            id: 'fixture',
            type: 'style7',
            options: {
                heading: {
                    title: 'Cutting boards',
                    colorTitle: null,
                    textAlign: 'left',
                },
                content: {
                    link: null,
                    image: 'e8c16787-a91a-4576-8d03-3b10b9246a2e',
                    note: null,
                    showButton: true,
                    btnText: null,
                },
            },
            position: 1,
        },
        {
            id: 'fixture',
            type: 'style7',
            options: {
                heading: {
                    title: 'Cups',
                    colorTitle: null,
                    textAlign: 'left',
                },
                content: {
                    link: null,
                    image: 'b305c73b-3944-4743-9540-1b13ddfc0266',
                    note: null,
                    showButton: true,
                    btnText: null,
                },
            },
            position: 2,
        },
        {
            id: 'fixture',
            type: 'style7',
            options: {
                heading: {
                    title: 'Kitchen spatulas',
                    colorTitle: null,
                    textAlign: 'left',
                },
                content: {
                    link: null,
                    image: '97deb271-c661-44b9-85dd-270456b5a35a',
                    note: null,
                    showButton: true,
                    btnText: null,
                },
            },
            position: 3,
        },
        {
            id: 'fixture',
            type: 'style7',
            options: {
                heading: {
                    title: 'Knives',
                    colorTitle: null,
                    textAlign: 'left',
                },
                content: {
                    link: null,
                    image: 'b0c0e66a-c34c-4f1f-ab41-13ea7d160b73',
                    note: null,
                    showButton: true,
                    btnText: null,
                },
            },
            position: 4,
        },
        {
            id: 'fixture',
            type: 'style7',
            options: {
                heading: {
                    title: 'Set of dishes',
                    colorTitle: null,
                    textAlign: 'left',
                },
                content: {
                    link: null,
                    image: '3301845c-5a09-4c97-885f-e5740351b772',
                    note: null,
                    showButton: true,
                    btnText: null,
                },
            },
            position: 5,
        },
    ],
    additionalContent: false,
});

summary.style7__list_column_3__prev2 = createSectionFixture({
    title: 'Category style 7 list',
    preview: require('@/assets/images/sections/categories/style7Prev2.jpg'),
    itemLayout: {
        desktop: createLayoutVariation({ type: 'list', columns: 4 }),
        tablet: createLayoutVariation({ type: 'list', columns: 2 }),
        mobile: createLayoutVariation({ type: 'list', columns: 1 }),
    },
    itemsTemplate: itemStyleVariations.style7,
    items: [
        {
            id: 'fixture',
            type: 'style7',
            options: {
                heading: {
                    title: 'Rings',
                    colorTitle: null,
                    textAlign: 'left',
                },
                content: {
                    link: null,
                    image: 'eb781e32-f999-40f5-ab27-f35d62c33030',
                    note: null,
                    showButton: true,
                    btnText: null,
                },
            },
            position: 0,
        },
        {
            id: 'fixture',
            type: 'style7',
            options: {
                heading: {
                    title: 'Pendants',
                    colorTitle: null,
                    textAlign: 'left',
                },
                content: {
                    link: null,
                    image: 'fd0bc9ba-90ca-4144-ab07-840c817aac29',
                    note: null,
                    showButton: true,
                    btnText: null,
                },
            },
            position: 1,
        },
        {
            id: 'fixture',
            type: 'style7',
            options: {
                heading: {
                    title: 'Bracelets',
                    colorTitle: null,
                    textAlign: 'left',
                },
                content: {
                    link: null,
                    image: '9edc9d1a-ede3-4925-9b7d-d4ffa7ec3709',
                    note: null,
                    showButton: true,
                    btnText: null,
                },
            },
            position: 2,
        },
        {
            id: 'fixture',
            type: 'style7',
            options: {
                heading: {
                    title: 'Earrings',
                    colorTitle: null,
                    textAlign: 'left',
                },
                content: {
                    link: null,
                    image: 'd5288303-a9ee-49d8-8274-c0cc5e6f3635',
                    note: null,
                    showButton: true,
                    btnText: null,
                },
            },
            position: 3,
        },
    ],
    additionalContent: false,
});

summary.style7__list_column_3__prev3 = createSectionFixture({
    title: 'Category style 7 list',
    preview: require('@/assets/images/sections/categories/style8Prev1.jpg'),
    itemLayout: {
        desktop: createLayoutVariation({ type: 'list', columns: 3 }),
        tablet: createLayoutVariation({ type: 'list', columns: 2 }),
        mobile: createLayoutVariation({ type: 'list', columns: 1 }),
    },
    itemsTemplate: itemStyleVariations.style7,
    items: [
        {
            id: 'fixture',
            type: 'style7',
            options: {
                heading: {
                    title: 'Lipsticks',
                    colorTitle: null,
                    textAlign: 'left',
                },
                content: {
                    link: null,
                    image: 'ac677e83-4b69-4aa2-838d-c8a81fc5480c',
                    note: null,
                    showButton: true,
                    btnText: null,
                },
            },
            position: 0,
        },
        {
            id: 'fixture',
            type: 'style7',
            options: {
                heading: {
                    title: 'Eye Shadow',
                    colorTitle: null,
                    textAlign: 'left',
                },
                content: {
                    link: null,
                    image: 'a86a0248-5288-4631-bb15-05caf1435855',
                    note: null,
                    showButton: true,
                    btnText: null,
                },
            },
            position: 1,
        },
        {
            id: 'fixture',
            type: 'style7',
            options: {
                heading: {
                    title: 'Eyeliner',
                    colorTitle: null,
                    textAlign: 'left',
                },
                content: {
                    link: null,
                    image: '7e4d883a-59ab-466d-9f4e-9098f7a20c4e',
                    note: null,
                    showButton: true,
                    btnText: null,
                },
            },
            position: 2,
        },
    ],
    additionalContent: false,
});

export default summary;
