<template>
    <draggable
        v-bind="dragOptions"
        tag="div"
        class="item-container"
        :list="list"
        :value="value"
        @input="emitter"
        :component-data="getComponentData()"
        handle=".handle"
    >
        <div class="item-group" :key="el.id" v-for="el in realValue">
            <MenuEditorListItem
                class="item"
                :item="el"
                :canBeParent="depth > level"
                @add-child-for-menu-item="$emit('add-child-for-menu-item', $event)"
                @edit-menu-item="$emit('edit-menu-item', $event)"
                @remove-menu-item="$emit('remove-menu-item', $event)"
            />
            <MenuEditorList
                v-if="depth > level"
                class="item-sub"
                :list="el.elements"
                :level="level + 1"
                :depth="depth"
                @add-child-for-menu-item="$emit('add-child-for-menu-item', $event)"
                @edit-menu-item="$emit('edit-menu-item', $event)"
                @remove-menu-item="$emit('remove-menu-item', $event)"
                @end-changed="$emit('end-changed', $event)"
            />
        </div>
    </draggable>
</template>
<script>
import draggable from 'vuedraggable';
import MenuEditorListItem from '@/components/builder/menuEditor/listItem.vue';

export default {
    name: 'MenuEditorList',
    components: { draggable, MenuEditorListItem },
    props: {
        value: { required: false, type: Array, default: null },
        level: { required: false, type: Number, default: 1 },
        depth: { required: false, type: Number, default: 1 },
        list: { required: false, type: Array, default: null },
    },
    computed: {
        dragOptions() {
            return {
                // animation: 0,
                group: 'description',
                disabled: false,
                ghostClass: 'ghost',
            };
        },
        realValue() {
            return this.value ? this.value : this.list;
        },
    },
    methods: {
        emitter(value) {
            this.$emit('input', value);
        },
        handleChange() {
            // console.log('handleChange');
        },
        inputChanged() {
            // console.log('inputChanged');
        },
        endChanged() {
            this.$emit('end-changed', 1);
        },
        getComponentData() {
            return {
                on: {
                    // change: this.handleChange,
                    // input: this.inputChanged,
                    end: this.endChanged,
                },
                attrs: {
                    wrap: true,
                },
            };
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';
.item-group {
    &.ghost > .item {
        background: $item-active;
        border-color: darken($item-active, 5%);
    }
}
.item {
    &-sub {
        margin: 0 0 0 2rem;
        transition: $transition-fast;
    }
}
</style>
