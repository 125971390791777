<template>
    <div class="tabs">
        <div class="tabs__header">
            <div
                class="tabs__header-item"
                :class="{ '-active': index === model }"
                :key="item.title"
                @click="changeActiveTab(index)"
                v-for="(item, index) in tabs"
            >
                <slot :name="`header.${item.key}`" :item="item" />
            </div>
        </div>
        <div class="tabs__items">
            <template v-for="(item, index) in tabs">
                <div
                    class="tabs__item"
                    :class="{ '-active': index === model }"
                    v-show="index === model"
                    :key="item.key"
                >
                    <slot :name="`item.${item.key}`" :item="item" />
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import { proxyModelMixin } from '@/mixins/proxyModelMixin';

export default {
    name: 'Tabs',
    mixins: [proxyModelMixin],
    props: {
        tabs: {
            type: Array,
            default: () => [],
        },
        value: {
            type: Number,
            default: 0,
        },
    },
    created() {},
    methods: {
        changeActiveTab(index) {
            this.model = index;
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.tabs {
    overflow: hidden;
    &__header {
        display: flex;
        justify-content: center;
        align-items: center;
        &-item {
            font-weight: 400;
            height: 48px;
            letter-spacing: 1.42857px;
            cursor: pointer;
            position: relative;
            &:before {
                bottom: 0;
                content: '';
                left: 0;
                opacity: 0;
                pointer-events: none;
                position: absolute;
                right: 0;
                top: 0;
                -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
                transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
                background-color: currentColor;
            }
            &:hover {
                &:before {
                    opacity: 0.04;
                }
            }
            &.-active {
                color: var(--v-primary-base);
                border-bottom: 2px solid var(--v-primary-base);
            }
        }
    }
    &__item {
        padding: 16px;
    }
}
</style>
