var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('expansion',{staticClass:"shipping-zone",scopedSlots:_vm._u([{key:"header-left",fn:function(){return [_c('input-switch',{staticClass:"expansion__switch",on:{"input":_vm.toggleEnabled},nativeOn:{"click":function($event){$event.stopPropagation();}},model:{value:(_vm.enabled),callback:function ($$v) {_vm.enabled=$$v},expression:"enabled"}}),_c('div',{staticClass:"address-card"},[(_vm.shippingZone.zones)?_c('div',{staticClass:"address-card__img-container"},[(_vm.flag)?_c('img',{staticClass:"address-card__img",attrs:{"src":_vm.flag}}):_c('img',{staticClass:"address-card__img -default",attrs:{"src":require("@/assets/images/country-default.svg")}})]):_vm._e(),_c('div',{staticClass:"address-card__text"},[_c('h4',{staticClass:"address-card__city expansion__heading-text"},[_vm._v(_vm._s(_vm.shippingZone.name))])])])]},proxy:true},{key:"header-right",fn:function(){return [_c('Button',{staticClass:"shipping-zone__add-btn",attrs:{"type":"text","icon":"add_circle_outline"},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.$emit('open-rate-modal', _vm.shippingZone)}}},[_vm._v(" "+_vm._s(_vm.$t('lists.addButton.rate')))]),_c('Menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"material-icons-outlined expansion__more"},'span',attrs,false),on),[_vm._v("more_vert ")])]}}])},[_c('div',{staticClass:"edit-list list"},[_c('div',{staticClass:"list__item",on:{"click":function($event){return _vm.$emit('start-zone-editing', _vm.shippingZone)}}},[_c('span',{staticClass:"material-icons-outlined"},[_vm._v("edit")]),_vm._v(_vm._s(_vm.$t('entities.edit'))+" ")]),_c('div',{staticClass:"list__item",on:{"click":function($event){return _vm.$emit('start-deletion', _vm.shippingZone, 'shippingZone')}}},[_c('span',{staticClass:"material-icons-outlined"},[_vm._v("delete")]),_vm._v(_vm._s(_vm.$t('entities.delete'))+" ")])])])]},proxy:true},{key:"default",fn:function(){return [_c('Table',{attrs:{"columns":_vm.headers,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"local-sort":"","show-toolbar":false,"show-selected-actions":false,"items":_vm.shippingZone.rates},on:{"update:sort":_vm.updateSort},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('Menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"material-icons-outlined rate__more-icon"},'span',attrs,false),on),[_vm._v("more_vert")])]}}],null,true)},[_c('div',{staticClass:"edit-list list"},[_c('div',{staticClass:"list__item",on:{"click":function($event){return _vm.$emit('start-rate-editing', item)}}},[_c('span',{staticClass:"material-icons-outlined"},[_vm._v("edit")]),_vm._v(_vm._s(_vm.$t('entities.edit'))+" ")]),_c('div',{staticClass:"list__item",on:{"click":function($event){return _vm.$emit('start-deletion', item, 'rate')}}},[_c('span',{staticClass:"material-icons-outlined"},[_vm._v("delete")]),_vm._v(_vm._s(_vm.$t('entities.delete'))+" ")])])])]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.price)+" "+_vm._s(_vm.currency)+" ")]}},{key:"item.conditions",fn:function(ref){
var item = ref.item;
return [(item.minWeight || item.maxWeight)?_c('div',[_vm._v(" "+_vm._s(item.minWeight ? ("min " + (item.minWeight) + " kg") : null)+" "+_vm._s(item.minWeight && item.maxWeight ? '—' : null)+" "+_vm._s(item.maxWeight ? ("max " + (item.maxWeight) + " kg") : null)+" ")]):_vm._e(),(item.minPrice || item.maxPrice)?_c('div',[_vm._v(" "+_vm._s(item.minPrice ? ("min " + (item.minPrice) + " " + _vm.currency) : null)+" "+_vm._s(item.minPrice && item.maxPrice ? '—' : null)+" "+_vm._s(item.maxPrice ? ("max " + (item.maxPrice) + " " + _vm.currency) : null)+" ")]):_vm._e()]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.title))]}},{key:"item.shippingSpeed",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.shippingSpeed)+" ")]}}])})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }