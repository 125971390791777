<template>
    <div class="page">
        <div class="page-header">
            <h1 class="page-header__text">
                {{ $t('menus.pageHeaders.categories') }}
            </h1>
            <Button :to="{ name: 'Create Category' }" icon="add_circle_outline">
                {{ $t('entities.add') }}
            </Button>
        </div>
        <error-block v-if="!isAvailable"></error-block>
        <Table
            class="categories-table"
            v-else
            :search="search"
            :columns="headers"
            :items="items"
            :is-data-loaded="isLoaded"
            v-model="selected"
            show-select
            :sort-by="sortBy"
            :sort-desc="sortDesc"
            :pagination="pagination"
            @change-per-page="changePerPage"
            @update:sort="updateSort"
            @update-search-string="updateSearchString"
            @delete-selected-items="openDeletionModal"
        >
            <!--  ACTIONS  -->
            <template v-slot:item.actions="{ item }">
                <div class="table__actions">
                    <router-link :to="{ name: 'Builder Edit Category', params: { id: item.id, type: 'media' } }"
                        ><Button class="builder-btn" type="text">{{ $t('entities.editInBuilder') }}</Button>
                    </router-link>
                    <a target="_blank" :href="`https://${instanceFull.primaryDomain}/${item.slug}`">
                        <Button type="icon" icon="open_in_new" />
                    </a>
                    <Button :to="{ name: 'Edit Category', params: { id: item.id } }" icon="edit" type="icon" />
                    <Button @click="openDeletionModal(item)" icon="delete" type="icon" />
                </div>
            </template>
            <!--  IMAGE  -->
            <template v-slot:item.image="{ item }">
                <img
                    v-if="hasImage(item)"
                    class="table__image"
                    :alt="images[item.id].alt"
                    :src="imageUrl + images[item.id].uri"
                />
                <img v-else class="table__image -default" alt="No image" src="@/assets/images/no-photo.svg" />
            </template>
            <!--  ENABLED  -->
            <template v-slot:item.enabled="{ item }">
                <input-switch
                    :value="item.enabled"
                    name="enabled"
                    size="small"
                    @input="onUpdateFieldValue({ name: 'enabled', value: $event }, item)"
                ></input-switch>
            </template>
            <!--  HIDDEN  -->
            <template v-slot:item.hidden="{ item }">
                <input-switch
                    :value="item.hidden"
                    name="hidden"
                    size="small"
                    @input="onUpdateFieldValue({ name: 'hidden', value: $event }, item)"
                ></input-switch>
            </template>
            <!--  TITLE  -->
            <template v-slot:item.title="{ item }">
                <span class="table-edit">
                    <router-link class="table-edit__link" :to="{ name: 'Edit Category', params: { id: item.id } }">{{
                        item.title
                    }}</router-link>
                    <span class="table-edit__icon material-icons-outlined">edit</span>
                </span>
            </template>
            <!-- ARTICLE IDS -->
            <template v-slot:item.articleIds="{ item }">
                <Button type="text" :to="{ name: 'Article List', query: { categoryId: item.id } }">
                    {{ $t('lists.actions.viewAll') }} ({{ item.articleIds.length }})
                </Button>
            </template>
        </Table>
    </div>
</template>

<script>
import CategoryService from '@/services/CategoryService.ts';
import { Pagination } from '@/services/PaginationService';
import SearchService from '@/services/SearchService';
import EventEmitter from '@/helpers/eventEmitter.ts';
import ErrorBlock from '@/components/common/ErrorBlock';
import { imageUrl, publicUrl } from '@/helpers/values';
import Table from '@/components/common/Table';
import Button from '@/components/common/Button';
import Category from '@/entities/blog/Category';
import { capitalize } from '@/helpers/filters';
import { mapGetters } from 'vuex';

export default {
    name: 'CategoryList',
    components: {
        ErrorBlock,
        Table,
        Button,
    },
    async created() {
        EventEmitter.on('delete-category', this.deleteItems);
        await this.init();
    },
    data: () => ({
        isLoaded: false,
        search: { searchable: 'category', q: '' },
        sortBy: null,
        previousSearch: '',
        sortDesc: false,
        currency: null,
        selected: [],
        items: [],
        images: [],
        pagination: {},
        publicUrl,
        imageUrl: imageUrl(),
        isAvailable: true,
        capitalize,
    }),
    computed: {
        ...mapGetters('globalVars', { instanceFull: 'getInstanceFull' }),
        pageNumber() {
            return this.$store.getters['globalVars/getCategoriesPage'];
        },
        searchString() {
            return this.$route.query.q;
        },
        headers() {
            return [
                { text: this.$t('lists.columns.image'), value: 'image', width: '50px' },
                { text: this.$t('lists.columns.title'), value: 'title', sortable: true },
                { text: this.$t('lists.columns.articleIds'), value: 'articleIds' },
                { text: this.$t('lists.columns.enabled'), value: 'enabled', width: '70px', sortable: true },
                { text: this.$t('lists.columns.hidden'), value: 'hidden', width: '70px', sortable: true },
                { value: 'actions', width: '184px' },
            ];
        },
    },
    methods: {
        async init() {
            const total = await this.getTotal();
            const limit = this.$store.getters['globalVars/getCategoriesLimit'];
            this.pagination = new Pagination({
                moduleName: 'categories',
                limit,
                pageNumber: this.pageNumber,
                total,
            });

            if (this.$route.query.q) {
                this.search.q = this.$route.query.q;
                this.previousSearch = this.$route.query.q;
            }
            await this.getItems();
        },
        async getItems() {
            this.isLoaded = false;
            const isSearching = !!this.search.q?.length;
            const searchChanged = this.search.q !== this.previousSearch;
            const params = {
                limit: this.pagination.limit,
                offset: searchChanged ? null : this.pagination.offset,
                sort: this.sortBy,
                direction: this.sortDesc ? 'desc' : this.sortDesc === false ? 'asc' : null,
                q: isSearching ? this.search.q : null,
                fields: isSearching ? ['title'] : null,
            };
            const getter = isSearching ? this.searchCategories : this.getCategories;
            const [error, result] = await getter(params);
            if (error) {
                error.alert();
                this.isAvailable = false;
                return;
            }
            if (searchChanged) {
                this.pagination.pushToHistory(1);
                this.pagination.total = isSearching ? result.count : await this.getTotal();
                this.previousSearch = this.search.q;
            }
            this.items = isSearching ? result.data : result;
            const [imageError, imageResult] = await CategoryService.getImages(this.items);
            if (imageError) imageError.notify();
            this.images = imageResult;
            this.isLoaded = true;
        },
        async getCategories(params) {
            return await CategoryService.getAll(params);
        },
        async searchCategories(params) {
            return await SearchService.entitySearch('category', params);
        },

        changePerPage(perPage) {
            this.pagination.changeLimit(perPage.value);
            this.perPage = perPage.value;
            this.getItems();
        },
        async getTotal() {
            const [error, total] = await Pagination.getTotal(`categories`);
            if (error) {
                error.notify();
            }
            return total;
        },

        async onUpdateFieldValue(event, category) {
            const { name, value } = event;
            category[name] = value;
            const newCategory = new Category(category);
            const [error] = await CategoryService.updateOne(newCategory.data);
            if (error) {
                error.notify();
            }
        },

        updateSearchString({ searchString }) {
            this.search.q = searchString;
            if (searchString?.length) this.pagination.reset();
            this.getItems();
        },
        updateSort(sortBy, sortDesc) {
            this.sortBy = sortBy;
            this.sortDesc = sortDesc;
            this.getItems();
        },
        async deleteItems(items) {
            this.isLoaded = false;
            const promises = [];
            items.forEach((item) => {
                promises.push(CategoryService.removeOne(item.id));
                const position = this.items.indexOf(item);
                this.items.splice(position, 1);
            });
            const results = await Promise.all(promises);
            const normalized = await this.pagination.normalize(this.items);
            this.pagination.total = await this.getTotal();
            this.selected = [];
            this.$store.dispatch('modals/closeDeletionModal');
            if (!normalized) {
                await this.getItems();
            }
            if (
                results.every((item) => {
                    const [error] = item;
                    return !error;
                })
            ) {
                if (items.length === 1) {
                    EventEmitter.trigger('show-noty', {
                        type: 'success',
                        text: this.$tc('notifications.deleted', 1, { entity: this.$tc('entities.category.title', 1) }),
                    });
                } else {
                    EventEmitter.trigger('show-noty', {
                        type: 'success',
                        text: this.$tc('notifications.created', 2, { entity: this.$tc('entities.category.title', 2) }),
                    });
                }
            }
            this.isLoaded = true;
        },
        hasImage(category) {
            return this.images[category.id]?.uri;
        },
        openDeletionModal(items) {
            this.$store.dispatch('modals/openDeletionModal', { items, entity: 'category' });
        },
    },
    beforeDestroy() {
        EventEmitter.off('delete-category', this.deleteItems);
    },
    watch: {
        pageNumber(newValue) {
            this.pagination.pageNumber = newValue;
            this.getItems();
        },
    },
};
</script>
<style lang="scss">
.categories {
    &__annotation {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 300px;
    }
    &__tags {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
    }
}
</style>
