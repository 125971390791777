import ApiClient from '@/api/apiClient';
import { uuid } from 'vue-uuid';
import { Image, ImageProps, ImagesRequestParams } from '@/services/services.types';
import CustomError from '@/entities/customError';

class ImageService {
    /**
     * Получение отдельного изображения
     * @param {String} id - ID изображения, которое нужно получить
     * @returns {Promise} объект Image
     */
    async getOne(id: string): Promise<[CustomError | null, Image | null]> {
        const url: string = `/images/${id}`;
        const defaultValue = null;
        const errorPath: string = '[api:image:getOne]';
        return await ApiClient.admin.get({ url, defaultValue, errorPath });
    }

    /**
     * Получение изображений
     * @param {ImagesRequestParams} params - параметры запроса (sort, direction, ids, etc.)
     * @returns {Promise} массив объектов изображений
     */
    async getAll(params: ImagesRequestParams = {}): Promise<[CustomError | null, Image[] | []]> {
        const url: string = `/images`;
        const defaultValue = [];
        const errorPath: string = '[api:image:getAll]';
        const config = { params };
        return await ApiClient.admin.get({ url, defaultValue, errorPath, config });
    }

    /**
     * Получение изображений
     * @param {String} type - сущность, к которой относится изображение
     * @param {Object} file - объект файла изображения
     * @param {Object} ownerId - id сущности, которой пренадлежит изображение
     * @returns {Promise} объект созданного изображения
     */

    async uploadOne(type: string, file: File, ownerId: string): Promise<[CustomError | null, Image | null]> {
        const url: string = `/images`;
        const defaultValue = null;
        const errorPath: string = '[api:image:uploadOne]';
        const formData = new FormData();
        formData.set('id', uuid.v4());
        formData.set('file', file);
        formData.set('type', type);
        if (type === 'product') {
            formData.set('ownerId', ownerId);
        }
        return await ApiClient.admin.post({ url, defaultValue, errorPath, data: formData });
    }

    /**
     * Обновление свойств изображения
     * @param {String} id - ID изображения, свойства которого нужно обновить
     * @param {Object} props - объект свойств изображения
     * @returns {Promise} объект обновленного изображения
     */
    async updateImageProps(id: string, props: ImageProps): Promise<[CustomError | null, Image | null]> {
        const url: string = `/images/${id}`;
        const defaultValue = null;
        const errorPath: string = '[api:image:updateImageProps]';
        return await ApiClient.admin.put({ url, defaultValue, errorPath, data: props });
    }
    /**
     * Получение изображений конкретной сущности
     * @param {String} entityName - название сущности, к которой относится изображение (product/page/catalog)
     * @param {String} entityId - id сущности
     * @returns {Promise} массив объектов изображений
     */
    async getEntityImages(entityName: string, entityId: string): Promise<[CustomError | null, Image[] | []]> {
        const url: string = `/images/${entityName}-images/${entityId}`;
        const defaultValue = [];
        const errorPath: string = '[api:image:getEntityImages]';
        return await ApiClient.admin.get({ url, defaultValue, errorPath });
    }

    /**
     * Удаление изображений
     * @param {String} id - id изобаржения
     * @returns {Promise} массив объектов изображений
     */
    async deleteImage(id: string): Promise<[CustomError | null, null]> {
        const url: string = `/images/${id}`;
        const defaultValue = null;
        const errorPath: string = '[api:image:deleteImage]';
        return await ApiClient.admin.delete({ url, defaultValue, errorPath });
    }

    async removeBatch(ids: string[] = []): Promise<[CustomError | null, null]> {
        const url: string = `/images/batch-delete`;
        const defaultValue = null;
        const errorPath: string = '[api:image:removeBatch]';
        const config = { params: { ids } };
        return await ApiClient.admin.delete({ url, defaultValue, errorPath, config });
    }

    /**
     * Удаление изображений конкретной сущности
     * @param {String} entityName - название сущности, к которой относится изображение (product/page/catalog)
     * @param {String} imageId - id изобаржения
     * @returns {Promise} массив объектов изображений
     */
    async deleteEntityImages(entityName: string, imageId: string): Promise<[CustomError | null, null]> {
        const url: string = `/images/${entityName}-image/${imageId}`;
        const defaultValue = null;
        const errorPath: string = '[api:image:deleteEntityImages]';
        return await ApiClient.admin.delete({ url, defaultValue, errorPath });
    }
}

export default new ImageService();
