export default function(reviewCount) {
    return [
        {
            model: false,
            icon: 'dashboard',
            title: 'menus.main.dashboard',
            to: { name: 'Dashboard' },
        },
        {
            model: false,
            icon: 'description',
            title: 'menus.main.pages',
            to: { name: 'Page List' },
        },
        {
            group: '/products',
            model: false,
            icon: 'local_mall',
            title: 'menus.main.products.title',
            isOpen: false,
            children: [
                {
                    title: 'menus.main.products.all',
                    to: { name: 'Product List' },
                },
                {
                    title: 'menus.main.products.disabled',
                    to: { name: 'Product Disabled List' },
                },
                {
                    title: 'menus.main.products.options',
                    to: { name: 'Option List' },
                },
                {
                    title: 'menus.main.products.properties',
                    to: { name: 'Property List' },
                },
                {
                    title: 'menus.main.products.labels',
                    to: { name: 'Label List' },
                },
                {
                    title: 'menus.main.products.reviews',
                    variable: { count: reviewCount },
                    to: { name: 'Product Review List' },
                },
            ],
        },
        {
            group: '/media',
            model: false,
            icon: 'article',
            title: 'menus.main.blog.title',
            isOpen: false,
            children: [
                {
                    title: 'menus.main.blog.categories',
                    to: { name: 'Category List' },
                },
                {
                    title: 'menus.main.blog.articles',
                    to: { name: 'Article List' },
                },
                {
                    title: 'menus.main.blog.authors',
                    to: { name: 'Author List' },
                },
                {
                    title: 'menus.main.blog.comments',
                    to: { name: 'Comment List' },
                },
                {
                    title: 'menus.main.blog.tags',
                    to: { name: 'Tag List' },
                },
            ],
        },
        {
            model: false,
            icon: 'category',
            title: 'menus.main.catalogs',
            to: { name: 'Catalog List' },
        },
        {
            model: false,
            icon: 'build_circle',
            title: 'menus.main.settings.customize',
            to: { name: 'Edit Homepage', params: { type: 'homepage' } },
        },
        {
            model: false,
            icon: 'shopping_cart',
            title: 'menus.main.orders',
            to: { name: 'Order List' },
        },
        {
            model: false,
            icon: 'person',
            title: 'menus.main.customers',
            to: { name: 'Customer List' },
        },
        {
            group: '/leads',
            model: false,
            isOpen: false,
            icon: 'local_mall',
            title: 'menus.main.leads.title',
            children: [
                {
                    title: 'menus.main.leads.all',
                    to: { name: 'Lead List' },
                },
            ],
        },
        // {
        //     group: '/menu',
        //     model: false,
        //     icon: 'menu',
        //     title: 'menus.main.menu.title',
        //     to: '/menu',
        //     children: [
        //         {
        //             title: 'menus.main.menu.info',
        //             to: { name: 'Menu List', params: { menu: 'info' } },
        //         },
        //         {
        //             title: 'menus.main.menu.main',
        //             to: { name: 'Menu List', params: { menu: 'main' } },
        //         },
        //         {
        //             title: 'menus.main.menu.footer',
        //             to: { name: 'Menu List', params: { menu: 'footer' } },
        //         },
        //     ],
        // },

        {
            model: false,
            icon: 'verified_user',
            title: 'menus.main.users',
            to: { name: 'User List' },
        },
        {
            group: '/settings',
            model: false,
            icon: 'settings',
            title: 'menus.main.settings.title',
            isOpen: false,
            children: [
                // {
                //     title: 'menus.main.settings.customize',
                //     to: { name: 'Edit Homepage', params: { type: 'homepage' } },
                // },
                {
                    title: 'menus.main.settings.notification',
                    to: { name: 'Notification Settings' },
                },
                {
                    title: 'menus.main.settings.domains',
                    to: { name: 'Domains' },
                },
                {
                    title: 'menus.main.settings.erp',
                    to: { name: 'ERP' },
                },
                {
                    title: 'menus.main.settings.websiteSettings',
                    to: { name: 'Edit Homepage', params: { type: 'homepage', activeItem: 'settings' } },
                },
                // {
                //     icon: 'file',
                //     title: 'menus.main.settings.file_manager',
                //     to: { name: 'FileManager' },
                // },
                {
                    title: 'menus.main.settings.orderStates',
                    to: { name: 'Order States' },
                },
            ],
        },
        {
            group: '/seo',
            model: false,
            icon: 'insert_chart',
            title: 'menus.main.seo.title',
            isOpen: false,
            children: [
                {
                    title: 'menus.main.seo.tag_manager',
                    to: { name: 'Tag manager' },
                },
                {
                    title: 'menus.main.seo.metatags',
                    to: { name: 'MetaTags' },
                },
                // {
                //     icon: 'chevron-right',
                //     title: 'menus.main.seo.sitemap',
                //     to: 'sitemap',
                // },
                {
                    title: 'menus.main.seo.robots',
                    to: { name: 'Robots.txt' },
                },
                {
                    title: 'menus.main.seo.redirects',
                    to: { name: 'Redirects' },
                },
            ],
        },
        {
            group: '/shipping',
            model: false,
            icon: 'local_shipping',
            title: 'menus.main.delivery.title',
            isOpen: false,
            children: [
                {
                    title: 'menus.main.delivery.locations',
                    to: { name: 'Locations' },
                },
                {
                    title: 'menus.main.delivery.delivery',
                    to: { name: 'Shipping and Delivery' },
                },
            ],
        },
        {
            model: false,
            icon: 'payments',
            title: 'menus.main.payment.title',
            to: { name: 'Payment' },
        },
        {
            model: false,
            icon: 'credit_card',
            title: 'menus.main.billing.title',
            to: { name: 'Billing' },
        },
    ];
}
