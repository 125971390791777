<template>
    <div class="error-box blue-grey lighten-5">
        <div class="py-12">
            <div class="text-center">
                <h1 class="error-title error--text">404</h1>
                <h3 class="text-uppercase error-subtitle">PAGE NOT FOUND !</h3>
                <p class="text-muted mt-4 mb-4">
                    YOU SEEM TO BE TRYING TO FIND HIS WAY HOME
                </p>
                <Button type="primary" :to="{ name: 'Dashboard' }">Go to Home</Button>
            </div>
        </div>
    </div>
</template>

<script>
import Button from '@/components/common/Button';
export default {
    name: 'Error404',
    components: { Button },
    data: () => ({}),
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.error-box {
    height: 100%;
    width: 100%;
    // position: fixed;
    text-align: center;
    background-position: top center;
    background-repeat: no-repeat;
    background: url('../../assets/images/background/error-bg.jpg');
}
.error-title {
    font-size: 110px;
    font-weight: 900;
    text-shadow: 4px 4px 0 #fff, 6px 6px 0 #343a40;
    line-height: 110px;
    text-align: center;
}
.error-subtitle {
    text-align: center;
}

@media (max-width: 991px) {
    .error-title {
        font-size: 70px;
        line-height: 80px;
    }
}

@media (max-width: 767px) {
    .error-title {
        font-size: 20px;
        line-height: 25px;
    }
}
</style>
