import { Section, Heading, TextStyle } from '@/entities/section/samples/_options';

const section = {
    id: '',
    type: 'SectionHeadingH1',
    required: true,
    group: 'Heading',
    options: {
        section: {
            padding: { ...Section.padding },
            background: { ...Section.background },
        },
        content: {
            title: {
                ...Heading.title,
                value: 'Heading H1',
            },
            fontStyleTitle: { ...TextStyle.fontStyle },
            colorTitle: { ...Heading.colorTitle },
            textAlign: { ...Heading.textAlign },
        },
    },
    items: [],
    children: null,
};

export default section;
