import Vue from 'vue';
import Router from 'vue-router';
import NProgress from 'nprogress';
import store from '@/store/store';
import { authMiddleware, instanceMiddleware } from './middleware';
import middlewarePipeline from './middlewarePipeline';
import routes from './routes';

Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    // This is for the scroll top when click on any router link
    /*    scrollBehavior: (to, from, savedPosition) => {
        if (to.matched.some((m) => m.meta.disableScroll)) return;

        let scrollTo = 0;

        if (to.hash) {
            scrollTo = to.hash;
        } else if (savedPosition) {
            scrollTo = savedPosition.y;
        }

        return goTo(scrollTo);
    },*/
    // This is for the scroll top when click on any router link
    routes,
});

router.beforeResolve((to, from, next) => {
    // If this isn't an initial page load.
    if (to.name) {
        // Start the route progress bar.
        NProgress.start(800);
    }
    next();
});

router.beforeEach((to, from, next) => {
    const excludeBaseMiddlewareRoutes = ['Login']; // массив имен роутов, к которым не применяются базовые middleware
    console.log('router', to.name);
    const baseMiddleware = excludeBaseMiddlewareRoutes.includes(to.name) ? [] : [authMiddleware, instanceMiddleware];
    const middleware = to.meta?.middleware?.length ? [...baseMiddleware, ...to.meta.middleware] : baseMiddleware;
    // Если нет middleware, то сразу переходим к следующему хуку
    if (!middleware.length) return next();

    const context = {
        to,
        from,
        next,
        store,
    };
    return middleware[0]({
        ...context,
        next: middlewarePipeline(context, middleware, 1),
    });
});

router.afterEach(() => {
    // Complete the animation of the route progress bar.
    NProgress.done();
});

export default router;
