export default {
    anton: `@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');`,
    opensans: `@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600&display=swap');`,
    oxygen: `@import url('https://fonts.googleapis.com/css2?family=Oxygen:wght@300;400;700&display=swap');`,
    oswald: `@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap');`,
    petrona: `@import url('https://fonts.googleapis.com/css2?family=Petrona:wght@300;400;500;600;700&display=swap');`,
    ptsans: `@import url('https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;700&display=swap');`,
    ubuntu: `@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap');`,
    unbounded: `@import url('https://fonts.googleapis.com/css2?family=Unbounded:wght@300;400;500;600;700&display=swap');`,
    manrope: `@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');`,
    roboto: `@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');`,
    barlowsemicondensed: `@import url('https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');`,
    firasans: `@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');`,
    poppins: `@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');`,
};
