import { Section, Container, Content } from '@/entities/section/samples/_options';
import { SELECT, NUMBER } from '@/helpers/fieldTypes.no-vuetify';

const section = {
    id: '',
    type: 'SectionProductList',
    required: true,
    group: 'ProductList',
    options: {
        section: { ...Section },
        container: { ...Container },
        content: {
            htmlBefore: { ...Content.htmlBefore },
            htmlAfter: { ...Content.htmlAfter },
        },
        limit: {
            isCustom: {
                value: false,
                component: 'InputSwitch',
                props: {
                    size: 'small',
                    noLabel: true,
                    text: 'builder.sectionOptions.props.limitCustom',
                },
            },
            count: {
                value: 12,
                ...NUMBER,
                props: {
                    size: 'small',
                    labelTrans: 'builder.sectionOptions.props.limitCustomCount',
                },
            },
        },
        template: {
            value: 1,
            ...SELECT,
            ...{
                props: {
                    items: [1, 2, 3],
                },
            },
        },
        list: {
            useDefault: {
                value: true,
                component: 'InputSwitch',
                props: {
                    size: 'small',
                    noLabel: true,
                    text: 'builder.sectionOptions.props.useDefault',
                },
            },
            columns: {
                type: 'field',
                value: {
                    desktop: 3,
                    tablet: 2,
                    mobile: 1,
                },
                component: 'CustomizeColumnsTabs',
                ...{
                    props: {
                        noLabel: true,
                        ranges: {
                            desktop: [2, 3, 4, 5, 6],
                            tablet: [2, 3],
                            mobile: [1, 2],
                        },
                        disabledBy: 'list.useDefault',
                    },
                },
            },
        },
    },
    items: [],
    children: null,
};

export default section;
