export default () => ({
    productId: {
        component: 'search-with-image',
        props: {
            labelTrans: 'entities.review.fields.productId',
            entityName: 'product',
            rules: ['noEmpty'],
            errors: [],
        },
    },
    /*        createdAt: {
        ...STRING,
        props: {
            class: '-half-width',
            placeholder: '01.01.01',
            labelTrans: 'entities.review.fields.createdAt',
        },
    },*/
    rating: {
        component: 'rating',
        props: {
            rules: ['noEmpty'],
            labelTrans: 'entities.review.fields.rating',
            class: '-half-width',
            errors: [],
        },
    },
    name: {
        component: 'input-text',
        props: {
            rules: ['noEmpty'],
            labelTrans: 'entities.review.fields.name',
            class: '-half-width',
            errors: [],
        },
    },
    email: {
        component: 'input-text',
        props: {
            rules: ['noEmpty'],
            labelTrans: 'entities.review.fields.email',
            class: '-half-width',
            errors: [],
        },
    },
    createdAt: {
        component: 'input-text',
        props: {
            type: 'date',
            rules: ['noEmpty'],
            labelTrans: 'entities.review.fields.createdAt',
            class: '-half-width',
            errors: [],
        },
    },
    text: {
        component: 'Textarea',
        props: {
            rules: ['noEmpty'],
            labelTrans: 'entities.review.fields.text',
            errors: [],
        },
    },
});
