<template>
    <div class="sidebar sidebar-right">
        <span class="divider" />
        <div class="sidebar__header">
            {{ heading }}
        </div>
        <span class="divider" />
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'SidebarRight',
    props: {
        heading: {
            type: String,
            default: '',
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.sidebar {
    &-right {
        width: 300px;
        right: 0;
        top: 0;
        height: 100%;
        margin-top: 64px;
        position: fixed;
        background-color: var(--v-surface-base);
        gap: 16px;
        z-index: 4;
    }
    &__header {
        font-size: 19px;
        font-weight: 500;
        padding: 22px 16px;
    }
}
</style>
