export default {
    account: {
        heading: 'Account',
        buttons: {
            changeAccount: 'Change account',
        },
    },
    instanceList: {
        heading: 'Stores',
        table: {
            title: 'Title',
            primaryDomain: 'Primary domain',
            status: 'Status',
            actions: 'Actions',
        },
        actions: {
            edit: 'Edit',
        },
        buttons: {
            add: 'Add Instance',
            createAnotherStore: 'Create another store',
            createStore: 'Create store',
            open: 'Open',
            manage: 'Manage',
        },
    },
    payment: {
        buttons: {
            pay: 'Pay',
        },
    },
};
