import { isObject, mergeWith, isArray } from 'lodash';

export const validationMixin = {
    data() {
        return {
            validation: {
                errors: {
                    backend: {},
                    frontend: {},
                },
            },
        };
    },

    computed: {
        validationErrors() {
            return mergeWith(
                this.validation.errors.frontend.form,
                this.validation.errors.backend,
                (objValue, srcValue) => {
                    if (isArray(objValue)) {
                        return objValue.concat(srcValue);
                    }
                }
            );
        },
        isValid() {
            return !this.$v.$invalid;
        },
    },

    methods: {
        setBackendErrors(errors, result = {}) {
            for (const key in errors) {
                if (errors[key].errors?.length) {
                    result[key] = errors[key].errors;
                } else if (isObject(errors[key])) {
                    const currentResult = this.setBackendErrors(errors[key]);
                    const isEmtptyResults = Object.keys(currentResult).length;
                    if (key === 'children') {
                        result = isEmtptyResults ? currentResult : [];
                    } else {
                        result[key] = isEmtptyResults ? currentResult : [];
                    }
                }
            }
            this.validation.errors.backend = result;
            return result;
        },
        checkFrontendErrors(scheme = this.$options.validations, lastKey = null, formState = this.$v, result = {}) {
            for (const key in scheme) {
                const currentKey = lastKey || key;
                if (typeof scheme[key] === 'function' && !formState[key]) {
                    const errorText = this.$t(`validator.errors.${key}`, { name: currentKey });
                    result = result[currentKey]?.length ? [...result[currentKey], errorText] : [errorText];
                } else if (typeof scheme[key] === 'object') {
                    const currentResult = this.checkFrontendErrors(scheme[key], key, formState[key]);
                    const isEmtptyResults = Object.keys(currentResult).length;
                    result[key] = isEmtptyResults ? currentResult : [];
                }
            }
            this.validation.errors.frontend = result;
            return result;
        },
        validate() {
            this.$v.$touch();
            this.checkFrontendErrors();
        },
        clearValidationErrors(group) {
            if (group) {
                this.validation.errors.frontend[group] = {};
                this.validation.errors.backend[group] = {};
            } else {
                this.validation.errors.frontend = {};
                this.validation.errors.backend = {};
            }
        },
        getErrors(path) {
            return path.split('.').reduce((result, pathElement) => {
                if (result[pathElement]) return result[pathElement];
                if (isArray(result) && typeof result[0] === 'string') return result;
                return [];
            }, this.validationErrors);
        },
        // validateField(path) {
        //     const field = path.split('.').reduce((result, pathElem) => {
        //         return result[pathElem];
        //     }, this.$v.form);
        //     field.$touch();
        // },
    },
};
