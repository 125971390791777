export default {
    account: {
        heading: 'Аккаунт',
        buttons: {
            changeAccount: 'Сменить аккаунт',
        },
    },
    instanceList: {
        heading: 'Instance List',
        actions: {
            edit: 'Edit',
        },
        buttons: {
            add: 'Добавить сайт',
            createAnotherStore: 'Создать другой магазин',
            createStore: 'Создать магазин',
            open: 'Открыть',
            manage: 'Управлять',
        },
    },
    payment: {
        buttons: {
            pay: 'Оплатить',
        },
    },
};
