var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page"},[_c('div',{staticClass:"page-header"},[_c('h1',{staticClass:"page-header__text"},[_vm._v(_vm._s(_vm.$t('menus.pageHeaders.leads')))])]),_c('view-lead-modal',{attrs:{"item":_vm.viewItem},model:{value:(_vm.isViewModalOpen),callback:function ($$v) {_vm.isViewModalOpen=$$v},expression:"isViewModalOpen"}}),_c('comment-modal',{attrs:{"comment":_vm.editingLead.comment},on:{"update-comment":_vm.onUpdateComment},model:{value:(_vm.isCommentModalOpen),callback:function ($$v) {_vm.isCommentModalOpen=$$v},expression:"isCommentModalOpen"}}),(!_vm.isAvailable)?_c('error-block'):_c('Table',{attrs:{"columns":_vm.headers,"items":_vm.items,"is-data-loaded":_vm.isDataLoaded,"show-select":true,"pagination":_vm.pagination},on:{"change-per-page":_vm.changePerPage,"delete-selected-items":_vm.openDeletionModal},scopedSlots:_vm._u([{key:"item.product",fn:function(ref){
var item = ref.item;
return [(item.product && _vm.products[item.product])?_c('router-link',{staticClass:"lead__product-info",attrs:{"to":{ name: 'Edit Product', params: { id: _vm.products[item.product].productId } }}},[_c('div',{staticClass:"leads__product"},[_c('table-image',{staticClass:"table__image",attrs:{"image":_vm.getImage(_vm.products[item.product])}}),_c('span',{staticClass:"table-edit"},[_c('span',{staticClass:"table-edit__link product-title",attrs:{"type":"text"}},[_vm._v(_vm._s(_vm.products[item.product].productTitle))])])],1)]):(!item.product)?[_vm._v("—")]:(_vm.products[item.product] === null)?[_vm._v(_vm._s(_vm.$t('lists.columns.deleted')))]:_c('progress-circular',{staticClass:"lead__spinner spinner lead__spinner"})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"table__actions"},[_c('Button',{attrs:{"icon":"visibility","type":"icon"},on:{"click":function($event){return _vm.openViewModal(item)}}}),_c('Button',{staticClass:"chat-icon",class:{ '-has-comment': item.comment },attrs:{"icon":"chat","type":"icon"},on:{"click":function($event){return _vm.openCommentModal(item)}}}),_c('Button',{attrs:{"title":_vm.$t('lists.editButton.lead'),"to":{ name: 'Edit Lead', params: { id: item.id } },"icon":"edit","type":"icon"}}),_c('Button',{attrs:{"title":_vm.$t('lists.deleteButton.product'),"type":"icon","icon":"delete"},on:{"click":function($event){return _vm.openDeletionModal(item)}}})],1)]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("localDate")(new Date(item.createdAt)))+" ")]}},{key:"item.text",fn:function(ref){
var item = ref.item;
return [(item.text)?_c('div',{staticClass:"leads__text"},[_vm._v(_vm._s(item.text))]):[_vm._v("—")]]}},{key:"item.manager",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.manager || '—')+" ")]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }