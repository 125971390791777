export const TEXT_PAGE_FIELDS = {
    title: {
        type: 'STRING',
        component: 'input-text',
        bulkEditable: true,
    },
    slug: {
        type: 'URL',
        component: 'input-url',
        bulkEditable: false,
        label: 'URL',
        class: '-half-width',
    },
    heading: {
        type: 'STRING',
        component: 'input-text',
        bulkEditable: true,
    },
    metaTitle: {
        type: 'STRING',
        component: 'input-text',
        label: 'Meta Title',
        bulkEditable: true,
    },
    metaDescription: {
        label: 'Meta Description',
        type: 'STRING',
        component: 'textarea',
        bulkEditable: true,
        props: {},
    },

    enabled: {
        type: 'CHECKBOX',
        component: 'input-checkbox',
        bulkEditable: true,
    },
    hidden: {
        type: 'CHECKBOX',
        component: 'input-checkbox',
        bulkEditable: true,
    },
    pageStatus: {
        type: 'PAGE_STATUS',
        component: 'page-status',
        label: 'Page Status',
        class: '-half-width',
    },
};
