<template>
    <alert type="success" class="entity-item__success-alert" :class="{ '-break-line': isCreated }" v-if="text">
        {{ text }}
        <br />
        <template v-if="isCreated">
            <a class="alert__link" @click="$emit('add-another')">
                {{ $t('entities.product.alertText.createAnother') }}
            </a>
            {{ $t('entities.product.alertText.or') }}

            <a class="alert__link" @click="$emit('scroll-to-variations')">
                {{ $t('entities.product.alertText.addImages') }}</a
            >
        </template>
    </alert>
</template>

<script>
import Alert from '@/components/common/Alert';
export default {
    name: 'ProductAlert',
    components: { Alert },
    props: {
        isCreated: {
            type: Boolean,
            default: false,
        },
        isUpdated: {
            type: Boolean,
            default: false,
        },
        isDuplicated: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        text() {
            if (this.isCreated) {
                return this.$tc('notifications.created', 1, { entity: this.$tc('entities.product.title', 1) });
            }
            if (this.isUpdated) {
                return this.$tc('notifications.updated', 1, { entity: this.$tc('entities.product.title', 1) });
            }
            if (this.isDuplicated) {
                return this.$tc('notifications.duplicated', 1, { entity: this.$tc('entities.product.title', 1) });
            }
            return null;
        },
    },
};
</script>

<style></style>
