import { BUTTON, SELECT } from '@/helpers/fieldTypes.no-vuetify';
import { Section, Heading, Content, Container, TextStyle } from '@/entities/section/samples/_options';

const section = {
    id: '',
    type: 'SectionBannerStyle1',
    required: false,
    group: 'Banners',
    options: {
        section: {
            width: { ...Section.width },
            padding: { ...Section.padding },
        },
        container: {
            alignContent: {
                value: 'center',
                ...SELECT,
                ...{
                    props: {
                        translate: true,
                        labelTrans: 'builder.sectionOptions.props.align',
                        items: [
                            { text: 'builder.sectionOptions.items.left', value: 'left' },
                            { text: 'builder.sectionOptions.items.center', value: 'center' },
                            { text: 'builder.sectionOptions.items.right', value: 'right' },
                        ],
                    },
                },
            },
            borderRadius: {
                ...Container.borderRadius,
                value: {
                    desktop: 8,
                    tablet: 8,
                    mobile: 8,
                },
            },
        },
        heading: {
            title: { ...Heading.title },
            color: {
                value: '',
                component: 'ColorPicker',
                props: {
                    labelTrans: 'builder.sectionOptions.props.color',
                    btnReset: true,
                    mode: 'hexa',
                },
            },
            textSize: { ...TextStyle.fontSizeScreen },
        },
        content: {
            text: { ...Content.html },
            color: {
                value: '',
                component: 'ColorPicker',
                props: {
                    labelTrans: 'builder.sectionOptions.props.color',
                    btnReset: true,
                    mode: 'hexa',
                },
            },
            media: {
                value: {
                    desktop: null,
                    mobile: null,
                },
                component: 'CustomizeMediaTabs',
                ...{
                    props: {
                        labelTrans: 'builder.sectionOptions.props.media',
                        screen: ['desktop', 'mobile'],
                    },
                },
            },
        },
        button: {
            value: {
                isShow: false,
                styles: 'primary',
                text: 'Button',
                textColor: null,
                bgColor: null,
                link: null,
                iconLeft: null,
                iconRight: null,
                type: 'Link',
                url: null,
            },
            ...BUTTON,
        },
    },
    items: [],
    children: null,
};

export default section;
