import { merge } from 'lodash';
import {
    Container,
    Content,
    Heading,
    Section,
    SectionItemsStyle,
    TextStyle,
} from '@/entities/section/samples/_options';
import { SELECT } from '@/helpers/fieldTypes.no-vuetify';

const section = {
    id: '',
    type: 'SectionTextBlockPictureRight',
    required: false,
    group: 'TextBlocks',
    options: {
        section: { ...Section },
        container: { ...Container },
        heading: {
            title: { ...Heading.title },
            fontStyleTitle: { ...TextStyle.fontStyle },
            colorTitle: { ...Heading.colorTitle },
        },
        content: {
            html: { ...Content.html },
            image: merge(Content.image, {
                props: { note: 'JPG, PNG. Recomended size: 550х550px. No more than 50kb' },
            }),
            alt: { ...Content.imageAlt },
            imageBorderRadius: { ...SectionItemsStyle.borderRadius },
            orderMobile: {
                value: 'imageFirst',
                ...SELECT,
                ...{
                    props: {
                        translate: true,
                        labelTrans: 'builder.sectionOptions.props.orderMobile',
                        items: [
                            { text: 'builder.sectionOptions.items.imageFirst', value: 'imageFirst' },
                            { text: 'builder.sectionOptions.items.textFirst', value: 'textFirst' },
                        ],
                    },
                },
            },
        },
        button: {
            title: { ...Content.btnText },
            link: { ...Content.link },
        },
        // background: { ...Background },
        grid: {
            type: 'field',
            value: {},
            component: 'CustomizeColumnsTabs',
            ...{
                props: {
                    screen: ['desktop', 'tablet'], // 'desktop', 'tablet', 'mobile'
                },
            },
        },
    },
    items: [],
    children: null,
};

export default section;
