import { Container, Heading, Section, Content } from '@/entities/section/samples/_options';

const section = {
    id: '',
    type: 'SectionFormStyle1',
    required: false,
    group: 'Forms',
    options: {
        section: { ...Section },
        container: { ...Container },
        heading: {
            title: { ...Heading.title },
            colorTitle: { ...Heading.colorTitle },
        },
        content: {
            colorInput: {
                ...Content.color,
                props: {
                    labelTrans: 'builder.sectionOptions.props.colorInput',
                },
            },
            colorLabel: {
                ...Content.color,
                props: {
                    labelTrans: 'builder.sectionOptions.props.colorLabel',
                },
            },
            colorError: {
                ...Content.color,
                props: {
                    labelTrans: 'builder.sectionOptions.props.colorError',
                },
            },
            borderColor: {
                value: '',
                component: 'ColorPicker',
                props: {
                    labelTrans: 'builder.sectionOptions.props.borderColor',
                    btnReset: true,
                    mode: 'hexa',
                },
            },
        },
        // footer: { ...Footer },
    },
    items: [],
    children: null,
};

export default section;
