<template>
    <div class="tooltip" :class="classes">
        <div class="tooltip__overlay"></div>
        <div class="tooltip__content">
            <span class="tooltip__icon icon" v-if="iconToUse">{{ iconToUse }}</span>
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Tooltip',
    props: {
        type: {
            type: String,
            default: '',
            validator: (value) => ['primary', 'warning', 'info', 'error', 'success', ''].includes(value),
        },
        elevated: {
            type: Boolean,
            default: false,
        },
        icon: {
            type: String,
            default: null,
        },
    },
    computed: {
        iconToUse() {
            if (this.icon) return this.icon;
            const map = {
                primary: 'error_outline',
                info: 'info',
                success: 'check_circle',
                error: 'warning',
            };
            return map[this.type];
        },

        classes() {
            const classes = [];
            if (this.type) {
                classes.push(`-${this.type}`);
            }
            if (this.elevated) {
                classes.push('-elevated');
            }
            return classes;
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.tooltip {
    position: relative;
    width: 100%;
    padding: 16px;
    border-radius: 4px;
    font-size: 16px;
    overflow: hidden;
    background-color: var(--v-surface-base);
    &__content {
        position: relative;
        display: flex;
        align-items: center;
        gap: 8px;
        z-index: 2;
    }
    &__icon {
        font-size: 24px;
    }
    &__overlay {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: 0.12;
        z-index: 1;
    }
    &.-elevated {
        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
            0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
    }
    &.-primary {
        color: var(--v-primary-base);
        caret-color: var(--v-primary-base);
        .tooltip__overlay {
            background-color: var(--v-primary-base);
        }
    }
    &.-error {
        color: var(--v-warning-base);
        caret-color: var(--v-warning-base);
        .tooltip__overlay {
            background-color: var(--v-warning-base);
        }
    }
    &.-info {
        background-color: var(--v-surface-base);
        border-left: 8px solid var(--v-primary-base);
        .tooltip__icon {
            color: var(--v-primary-base);
        }
    }

    &.-success {
        background-color: var(--v-success-base);
        color: var(--v-surface-base);
    }
    .tooltip__link {
        text-decoration: underline;
    }
}
</style>
