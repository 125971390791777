<template>
    <div class="input-link" :class="classes" v-click-outside="closeDropdown">
        <span v-if="icon" class="input-link__icon material-symbols-outlined">{{ icon }}</span>
        <input
            class="input-link__field"
            v-model="model"
            v-bind="inputProps"
            @click="openDropdown"
            ref="input"
            autocomplete="off"
        />
        <Button v-if="icon" class="input-link__btn-clear" type="icon" icon="cancel" @click="clearCurrentLink" />
        <div class="input-link__dropdown" :class="{ 'is-open': isDropdownShow, '-top': dropdownDirectionTop }">
            <template v-if="categorySelected === null">
                <template v-for="category in categories">
                    <button
                        v-if="!category.nested"
                        :key="category.title"
                        class="input-link__dropdown-item"
                        @click="selectCategory(category.type)"
                    >
                        <span class="input-link__dropdown-item-icon material-symbols-outlined">
                            {{ category.icon }}
                        </span>
                        {{ $t(category.title) }}
                    </button>
                </template>
            </template>
            <template v-else>
                <button class="input-link__dropdown-item" @click="selectBack">
                    <span class="input-link__dropdown-item-icon material-symbols-outlined">navigate_before</span>
                    {{ $t('builder.topbar.back') }}
                </button>
                <button v-for="item in items" :key="item.title" class="input-link__dropdown-nested-item">
                    <div class="input-link__dropdown-item" @click="selectItem(item, currentCategory)">
                        <span class="input-link__dropdown-item-icon material-symbols-outlined">
                            {{ currentCategory.icon }}
                        </span>
                        {{ $t(item.title) }}
                    </div>
                    <span
                        v-if="item.nested"
                        @click="selectCategory(currentCategory.children, getRequestParams(item))"
                        class="material-icons-outlined -next"
                        >keyboard_double_arrow_right</span
                    >
                </button>
            </template>
            <progress-linear v-if="isLoading"></progress-linear>
        </div>
    </div>
</template>

<script>
import httpClient from '@/api/httpClient';
import Button from '@/components/common/Button.vue';
import ProgressLinear from '@/components/common/ProgressLinear';

export default {
    name: 'InputLink',
    inheritAttrs: false,
    props: {
        name: { type: String, default: '' },
        group: { type: String, default: null },
        type: { type: String, default: 'text' },
        placeholder: { type: String, default: 'menus.selectPlaceholder' },
        errors: { type: [Array, Object], default: () => [] },
        value: { type: [String, Number, Object, Date] },
    },
    components: { Button, ProgressLinear },
    data() {
        return {
            dropdownDirectionTop: false,
            isDropdownShow: false,
            categorySelected: null,
            isLoading: false,
            categories: [
                {
                    type: 'homepage',
                    title: 'builder.pageType.homepage',
                    icon: 'location_on',
                },
                {
                    type: 'page',
                    title: 'builder.pageType.textPages',
                    icon: 'article',
                },
                {
                    type: 'catalog',
                    title: 'builder.pageType.catalogs',
                    icon: 'shopping_bag',
                },
                {
                    type: 'category',
                    title: 'builder.pageType.category',
                    icon: 'feed',
                    children: 'article',
                    childrenRequestParam: {
                        name: 'category',
                        value: 'id',
                    },
                },
                {
                    type: 'article',
                    title: 'builder.pageType.article',
                    icon: 'article',
                    nested: true,
                    parent: 'category',
                },
                {
                    type: 'link',
                    title: 'Url',
                    icon: 'link',
                },
            ],
            items: null,
            currentLink: {
                type: this.value?.type || null,
                id: this.value?.id || null,
                title: this.value?.title || null,
                url: this.value?.url || null,
            },
        };
    },
    computed: {
        model: {
            get() {
                return this.currentLink.title ? this.currentLink.title : this.currentLink.url;
            },
            set(value) {
                if (this.currentLink.type === 'link') this.currentLink.url = value;
                this.$emit('input', this.currentLink);
                this.$emit('linkChanged', this.currentLink);
            },
        },
        inputProps() {
            return {
                type: this.type,
                name: this.name,
                placeholder: this.$t(this.placeholder),
            };
        },
        classes() {
            return {
                '-has-errors': Array.isArray(this.errors) && this.errors.length > 0,
                '-selected-homepage': this.currentLink.type === 'homepage',
                '-selected-page': this.currentLink.type === 'page',
                '-selected-catalog': this.currentLink.type === 'catalog',
                '-selected-url': this.currentLink.type === 'link',
            };
        },
        icon() {
            if (this.currentLink.type === 'homepage') return 'location_on';
            if (this.currentLink.type === 'page') return 'article';
            if (this.currentLink.type === 'catalog') return 'shopping_bag';
            if (this.currentLink.type === 'link') return 'link';
            return null;
        },
        currentCategory: function() {
            return this.categories.find((category) => category.type === this.categorySelected);
        },
    },
    watch: {
        value: function() {
            this.$emit('update-field', {
                name: this.name,
                group: this.group,
                value: this.currentLink,
            });
        },
    },
    methods: {
        async selectCategory(type, params = null) {
            if (this.isLoading) return;
            this.isLoading = true;
            if (type === 'link') {
                this.currentLink = {
                    type: type,
                    id: null,
                    title: null,
                    url: 'https://your-url-here',
                };
                this.model = 'https://your-url-here';
                this.categorySelected = null;
                this.closeDropdown();
                this.$nextTick(() => {
                    this.$refs.input.focus();
                });
            } else if (type === 'homepage') {
                this.currentLink = {
                    type: type,
                    id: null,
                    title: 'Homepage',
                    url: '/',
                };
                this.model = 'Homepage';
                this.categorySelected = null;
                this.closeDropdown();
                this.$nextTick(() => {
                    this.$refs.input.focus();
                });
            } else {
                let responses;
                if (type === 'category') {
                    responses = await httpClient.admin.get('/categories');
                } else {
                    responses = await httpClient.admin.get(`/${type}s`, { params });
                }
                const data =
                    type === 'category'
                        ? responses.result.map((item) => {
                              return { ...item, nested: true, childrenType: 'article' };
                          })
                        : responses.result;
                this.categorySelected = type;
                this.$set(
                    this,
                    'items',
                    data.sort((a, b) => a.title.localeCompare(b.title))
                );
                this.isLoading = false;
            }
        },
        selectItem(item, category) {
            this.currentLink = {
                type: category.type,
                id: item.id,
                title: item.title,
                url: item.slug,
            };
            this.model = item.title;
            this.categorySelected = null;
            this.closeDropdown();
        },
        clearCurrentLink() {
            this.currentLink = {
                type: null,
                id: null,
                title: null,
                url: null,
            };
            this.$emit('input', this.currentLink);
        },
        selectBack() {
            if (this.currentCategory.parent) {
                this.selectCategory(this.currentCategory.parent);
                return;
            }
            this.categorySelected = null;
            this.$set(this, 'items', null);
        },
        openDropdown() {
            this.chooseDropdownDirection();
            this.isDropdownShow = true;
        },
        closeDropdown() {
            this.isDropdownShow = false;
        },
        chooseDropdownDirection() {
            const offsetTop = this.$refs.input.getBoundingClientRect().y;
            this.dropdownDirectionTop = window.innerHeight - offsetTop < 350;
        },
        getRequestParams(item) {
            const param = this.currentCategory.childrenRequestParam;
            return { [param.name]: item[param.value] };
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';

.input-link {
    position: relative;
    z-index: 3;
    cursor: not-allowed;

    &__icon {
        position: absolute;
        left: 9px;
        top: 7px;
    }

    &__btn-clear {
        position: absolute;
        right: 3px;
        top: 2px;
    }

    &__field {
        display: block;
        width: 100%;
        height: $form-control-height;
        padding: 0 $form-control-padding-horizontal;
        border: $form-control-border;
        border-radius: $form-control-border-radius;
        background: $form-control-bg;
        transition: $form-control-transition;
        appearance: none;

        &:hover,
        &:focus {
            border-color: var(--v-accent-base);
            outline: none;
        }

        &::placeholder {
            color: inherit;
            opacity: 0.7;
        }
    }

    &.-selected-url &__field,
    &.-selected-homepage &__field,
    &.-selected-page &__field,
    &.-selected-catalog &__field {
        padding-left: 40px;
        padding-right: 40px;
    }

    &.-selected-homepage &__field,
    &.-selected-page &__field,
    &.-selected-catalog &__field {
        pointer-events: none;
    }

    &__dropdown {
        position: absolute;
        left: 0;
        top: 100%;
        display: block;
        width: 100%;
        border: 1px solid #cececf;
        border-radius: $input-border-radius;
        padding: 6px 0;
        background: $input-bg;
        max-height: 300px;
        overflow-y: auto;

        @include smooth-dropdown-hide();

        &.is-open {
            @include smooth-dropdown-show();
        }
        .progress-linear {
            top: 0;
            height: 2px;
            width: 100%;
            border-radius: 0 0 4px 4px;
        }
        &.-top {
            bottom: 100%;
            top: auto;
            transform-origin: 0% 100%;
            .progress-linear {
                top: auto;
                bottom: 0;
            }
        }

        &-item {
            display: flex;
            align-items: center;
            width: 100%;
            padding: 12px 40px 8px 16px;
            transition: $transition-fast;
            font-size: 16px;
            line-height: 24px;
            text-align: left;
            cursor: pointer;
            background-color: var(--v-surface-base);
            border: none;
            &-icon {
                position: relative;
                top: 0;
                color: inherit;
                line-height: 24px;
                margin-right: 10px;
            }

            &:hover {
                background-color: #f4f6f7;
            }

            &.-current {
                border-bottom: 1px solid #e0e0e0;
            }

            &.-highlighted {
                color: #2979ff;
            }
        }

        &-nested-item {
            position: relative;
            display: flex;
            align-items: center;
            text-align: left;
            padding: 0;
            justify-content: space-between;
            width: 100%;
            cursor: pointer;
            background-color: var(--v-surface-base);
            border: none;
            line-height: 24px;
            .input-link__dropdown-item {
                padding-right: 0;
            }
            .-next {
                padding: 8px 12px;
                &:hover {
                    background-color: #f4f6f7;
                }
            }
        }
    }

    &.-has-errors &__field {
        border-color: $error;
    }
}
</style>
