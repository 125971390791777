<template>
    <div class="page">
        <div class="page-header">
            <progress-circular v-if="categoryId !== 'all' && !isLoaded" class="spinner"></progress-circular>
            <h1 v-else class="page-header__text">
                {{
                    categoriesMap[categoryId] && categoryId !== 'all'
                        ? $t('menus.pageHeaders.articlesInCategory', { category: categoriesMap[categoryId].title })
                        : $t('menus.pageHeaders.articles')
                }}
            </h1>
            <Button :to="{ name: `Create Article` }" icon="add_circle_outline">
                {{ $t('entities.add') }}
            </Button>
        </div>
        <error-block v-if="!isAvailable"></error-block>
        <Table
            v-else
            class="articles-table"
            :search="search"
            :columns="headers"
            :items="items"
            :is-data-loaded="isLoaded"
            v-model="selected"
            show-select
            :sort-by="sortBy"
            :sort-desc="sortDesc"
            :pagination="!!categoryId && pagination"
            @change-per-page="changePerPage"
            @update:sort="updateSort"
            @update-search-string="updateSearchString"
            @delete-selected-items="openDeletionModal"
        >
            <template v-slot:header-select-prepend
                ><Select
                    class="articles__type-select"
                    item-value="id"
                    item-text="title"
                    v-model="categoryId"
                    :items="categories"
                ></Select
            ></template>
            <!--  ACTIONS  -->
            <template v-slot:item.actions="{ item }">
                <div class="table__actions">
                    <Button
                        :to="{
                            name: 'Builder Edit Article',
                            params: { categoryId: item.categoryId, id: item.id, type: 'media' },
                        }"
                        :disabled="!categoriesMap[item.categoryId]"
                        class="builder-btn"
                        type="text"
                        >{{ $t('entities.editInBuilder') }}</Button
                    >
                    <a
                        target="_blank"
                        :href="
                            categoriesMap[item.categoryId] ? `https://${instanceFull.primaryDomain}/${item.slug}` : null
                        "
                    >
                        <Button :disabled="!categoriesMap[item.categoryId]" type="icon" icon="open_in_new" />
                    </a>
                    <Button :to="{ name: 'Edit Article', params: { id: item.id } }" icon="edit" type="icon" />
                    <Button @click="openDeletionModal(item)" icon="delete" type="icon" />
                </div>
            </template>
            <!--  IMAGE  -->
            <template v-slot:item.image="{ item }">
                <img
                    v-if="hasImage(item)"
                    class="table__image"
                    :alt="images[item.id].alt"
                    :src="imageUrl + images[item.id].uri"
                />
                <img v-else class="table__image -default" alt="No image" src="@/assets/images/no-photo.svg" />
            </template>
            <!--  AUTHOR/PUBLISHER  -->
            <template v-slot:item.author="{ item }">
                <progress-circular v-if="!isLoaded"></progress-circular>
                <div class="articles__author" v-else>
                    <span v-if="authors[item.authorId]">{{ authors[item.authorId].name }}</span>
                    <span v-if="item.publisherId && authors[item.publisherId]">
                        / {{ authors[item.publisherId].name }}
                    </span>
                </div>
            </template>
            <!--  PUBLISHED AT  -->
            <template v-slot:item.publishedAt="{ item }">
                <span v-if="item.publishedAt">{{ item.publishedAt | localDate }}</span>
                <span v-else>-</span>
            </template>
            <!--  UPDATED AT  -->
            <template v-slot:item.updatedAt="{ item }">
                <span v-if="item.updatedAt">{{ item.updatedAt | localDate }}</span>
                <span v-else>-</span>
            </template>
            <!--  ENABLED  -->
            <template v-slot:item.enabled="{ item }">
                <input-switch
                    :value="item.enabled"
                    name="enabled"
                    size="small"
                    @input="onUpdateFieldValue({ name: 'enabled', value: $event }, item)"
                ></input-switch>
            </template>
            <!--  HIDDEN  -->
            <template v-slot:item.hidden="{ item }">
                <input-switch
                    :value="item.hidden"
                    name="hidden"
                    size="small"
                    @input="onUpdateFieldValue({ name: 'hidden', value: $event }, item)"
                ></input-switch>
            </template>
            <!--  COMMENTS  -->
            <template v-slot:item.commentIds="{ item }">
                <Button type="text" :to="{ name: 'Comment List', query: { articleId: item.id } }">
                    {{ $t('lists.actions.viewAll') }} ({{ item.commentIds.length }})</Button
                >
            </template>
            <!--  TITLE  -->
            <template v-slot:item.title="{ item }">
                <span class="table-edit">
                    <router-link
                        class="table-edit__link"
                        :to="{ name: 'Edit Article', params: { id: item.id, categoryId: item.categoryId } }"
                        >{{ item.title }}</router-link
                    >
                    <span class="table-edit__icon material-icons-outlined">edit</span>
                </span>
            </template>
            <!-- TAGS -->
            <template v-slot:item.tagIds="{ item }">
                <progress-circular v-if="!isLoaded"></progress-circular>
                <div class="articles__tags" v-else-if="item.tagIds.length">
                    <template v-for="id in item.tagIds">
                        <chip v-if="tags[id]" type="combobox" :key="id">{{ tags[id].name }}</chip>
                    </template>
                </div>
                <span v-else>-</span>
            </template>
            <!-- CATEGORY ID -->
            <template v-slot:item.categoryId="{ item }">
                <progress-circular v-if="!isLoaded"></progress-circular>
                <span v-else>
                    {{ categoriesMap[item.categoryId] ? categoriesMap[item.categoryId].title : 'Удалено' }}
                </span>
            </template>
        </Table>
    </div>
</template>

<script>
import ArticleService from '@/services/ArticleService.ts';
import TagService from '@/services/TagService';
import AuthorService from '@/services/AuthorService';
import { Pagination } from '@/services/PaginationService';
import SearchService from '@/services/SearchService';
import EventEmitter from '@/helpers/eventEmitter.ts';
import ErrorBlock from '@/components/common/ErrorBlock';
import { imageUrl, publicUrl } from '@/helpers/values';
import Table from '@/components/common/Table';
import Button from '@/components/common/Button';
import Chip from '@/components/common/Chip';
import Article from '@/entities/blog/Article';
import { capitalize } from '@/helpers/filters';
import { uniq } from 'lodash';
import CategoryService from '@/services/CategoryService';
import ProgressCircular from '@/components/common/ProgressCircular';
import { mapGetters } from 'vuex';

export default {
    name: 'ArticleList',
    components: {
        ErrorBlock,
        Table,
        Button,
        Chip,
        ProgressCircular,
    },
    async created() {
        EventEmitter.on('delete-article', this.deleteItems);
        const categoryId = this.$route.query.categoryId;
        this.categories.push({ title: this.$t('entities.author.type.all'), id: 'all' });
        if (categoryId) {
            this.categoryId = categoryId;
        }
        await this.init();
    },
    data: () => ({
        isLoaded: false,
        search: { searchable: 'article', q: '' },
        sortBy: null,
        previousSearch: '',
        sortDesc: false,
        currency: null,
        selected: [],
        items: [],
        images: [],
        tags: {},
        authors: {},
        pagination: {},
        publicUrl,
        imageUrl: imageUrl(),
        isAvailable: true,
        categories: [],
        categoriesMap: {},
        categoryId: 'all',
        isViewModalOpen: false,
        viewItem: { comment: {}, article: {} },
        capitalize,
    }),
    computed: {
        ...mapGetters('globalVars', { instanceFull: 'getInstanceFull' }),

        authorId() {
            return this.$route.query.authorId;
        },
        pageNumber() {
            return this.$store.getters['globalVars/getArticlesPage'];
        },
        searchString() {
            return this.$route.query.q;
        },
        headers() {
            return [
                { text: this.$t('lists.columns.image'), value: 'image', width: '50px' },
                { text: this.$t('lists.columns.title'), value: 'title', width: '150px', sortable: true },
                { text: this.$t('lists.columns.categoryId'), value: 'categoryId' },
                { text: this.$t('lists.columns.author/publisher'), width: '70px', value: 'author' },
                { text: this.$t('lists.columns.updatedAt'), value: 'updatedAt', sortable: true },
                { text: this.$t('lists.columns.publishedAt'), value: 'publishedAt', sortable: true },
                { text: this.$t('lists.columns.tagIds'), value: 'tagIds', width: '250px' },
                { text: this.$t('lists.columns.comments'), value: 'commentIds' },
                { text: this.$t('lists.columns.enabled'), value: 'enabled', width: '70px', sortable: true },
                { text: this.$t('lists.columns.hidden'), value: 'hidden', width: '70px', sortable: true },
                { value: 'actions', width: '184px' },
            ];
        },
    },
    methods: {
        async init() {
            this.isLoaded = false;
            if (this.categoryId) {
                const total = await this.getTotal();
                const limit = this.$store.getters['globalVars/getArticlesLimit'];
                this.pagination = new Pagination({
                    moduleName: 'articles',
                    limit,
                    pageNumber: this.pageNumber,
                    total,
                });
            }
            if (this.$route.query.q) {
                this.search.q = this.$route.query.q;
                this.previousSearch = this.$route.query.q;
            }
            await this.getItems();
        },
        async getItems() {
            this.isLoaded = false;
            const isSearching = !!this.search.q?.length;
            const searchChanged = this.search.q !== this.previousSearch;
            const params = {
                limit: this.pagination.limit,
                offset: searchChanged ? null : this.pagination.offset,
                sort: this.sortBy,
                direction: this.sortDesc ? 'desc' : this.sortDesc === false ? 'asc' : null,
                q: isSearching ? this.search.q : null,
                fields: isSearching ? ['title'] : null,
            };
            if (this.authorId) {
                params.author = this.authorId;
            }
            if (this.categoryId !== 'all') {
                params.category = this.categoryId;
            }
            const getter = isSearching ? this.searchArticles : this.getArticles;
            const [error, result] = await getter(params);
            if (error) {
                error.alert();
                this.isAvailable = false;
                return;
            }
            if (searchChanged && this.type) {
                this.pagination.pushToHistory(1);
                this.pagination.total = isSearching ? result.count : await this.getTotal();
                this.previousSearch = this.search.q;
            }
            this.items = isSearching ? result.data : result;
            const [imageError, imageResult] = await ArticleService.getImages(this.items);
            if (imageError) imageError.notify();
            this.images = imageResult;
            await this.getTags();
            await this.getAuthors();
            await this.getCategories();
            this.isLoaded = true;
        },
        async getArticles(params) {
            return await ArticleService.getAll(params);
        },
        async searchArticles(params) {
            return await SearchService.entitySearch('article', params);
        },
        async getCategories() {
            const [error, result] = await CategoryService.getAll();
            if (error) {
                error.notify();
                return;
            }
            this.categories = result;
            this.categories.push({ title: this.$t('entities.author.type.all'), id: 'all' });
            this.categoriesMap = result.reduce((acc, item) => {
                acc[item.id] = item;
                return acc;
            }, {});
        },
        async getTags() {
            const ids = uniq(this.items.reduce((acc, item) => [...acc, ...item.tagIds], []));
            const [error, result] = await TagService.getAll({ ids });
            if (error) {
                error.notify();
                return;
            }
            this.tags = result.reduce((acc, item) => {
                acc[item.id] = item;
                return acc;
            }, {});
        },

        async getAuthors() {
            const ids = uniq(
                this.items.reduce((acc, item) => {
                    const ids = [...acc, item.authorId];
                    if (item.publisherId) ids.push(item.publisherId);
                    return ids;
                }, [])
            );
            const [error, result] = await AuthorService.getAll({ ids });
            if (error) {
                error.notify();
                return;
            }
            this.authors = result.reduce((acc, item) => {
                acc[item.id] = item;
                return acc;
            }, {});
        },

        changePerPage(perPage) {
            this.pagination.changeLimit(perPage.value);
            this.perPage = perPage.value;
            this.getItems();
        },
        async getTotal() {
            const [error, total] = await Pagination.getTotal('articles', `/articles/count/${this.categoryId}`);
            if (error) {
                error.notify();
            }
            return total;
        },

        async onUpdateFieldValue(event, article) {
            const { name, value } = event;
            article[name] = value;
            const newArticle = new Article(article);
            const [error] = await ArticleService.updateOne(newArticle.data);
            if (error) {
                error.notify();
            }
        },

        updateSearchString({ searchString }) {
            this.search.q = searchString;
            if (searchString?.length && this.categoryId) this.pagination.reset();
            this.getItems();
        },
        updateSort(sortBy, sortDesc) {
            this.sortBy = sortBy;
            this.sortDesc = sortDesc;
            this.getItems();
        },
        async deleteItems(items) {
            this.isLoaded = false;
            const promises = [];
            items.forEach((item) => {
                promises.push(ArticleService.removeOne(item.id));
                const position = this.items.indexOf(item);
                this.items.splice(position, 1);
            });
            const results = await Promise.all(promises);
            const normalized = await this.pagination.normalize(this.items);
            this.pagination.total = await this.getTotal();
            this.selected = [];
            this.$store.dispatch('modals/closeDeletionModal');
            if (!normalized) {
                await this.getItems();
            }
            if (
                results.every((item) => {
                    const [error] = item;
                    return !error;
                })
            ) {
                if (items.length === 1) {
                    EventEmitter.trigger('show-noty', {
                        type: 'success',
                        text: this.$tc('notifications.deleted', 1, { entity: this.$tc('entities.article.title', 1) }),
                    });
                } else {
                    EventEmitter.trigger('show-noty', {
                        type: 'success',
                        text: this.$tc('notifications.created', 2, { entity: this.$tc('entities.article.title', 2) }),
                    });
                }
            }
            this.isLoaded = true;
        },
        hasImage(article) {
            return this.images[article.id]?.uri;
        },

        openDeletionModal(items) {
            this.$store.dispatch('modals/openDeletionModal', { items, entity: 'article' });
        },
    },
    beforeDestroy() {
        EventEmitter.off('delete-article', this.deleteItems);
    },
    watch: {
        pageNumber(newValue) {
            this.pagination.pageNumber = newValue;
            this.getItems();
        },
        categoryId() {
            this.init();
        },
    },
};
</script>
<style lang="scss">
.articles {
    &__tags {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
    }
    &__type-select {
        width: 270px;
        margin-left: auto;
        margin-right: 12px;
    }
    &__author {
        white-space: pre-wrap;
    }
}
</style>
