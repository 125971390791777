<template>
    <div class="page -width-lg">
        <div class="page-header">
            <h1 class="page-header__text">{{ $t('menus.pageHeaders.orderStates') }}</h1>
            <Button @click="openModal(null)" icon="add_circle_outline">
                {{ $t('entities.add') }}
            </Button>
        </div>
        <error-block v-if="!isAvailable"></error-block>
        <template v-else>
            <OrderStateModal @action-success="getItems" :order-state-id="orderStateId" v-model="isModalOpen" />
            <Table
                show-select
                @delete-selected-items="openDeletionModal"
                :sort-desc="sortDesc"
                :sort-by="sortBy"
                local-sort
                v-model="selected"
                :is-data-loaded="isLoaded"
                :columns="headers"
                :items="items"
                @update:sort="updateSort"
            >
                <!-- ACTIONS -->
                <template v-slot:item.actions="{ item }">
                    <div class="table__actions">
                        <Button @click="openModal(item.id)" icon="edit" type="icon" />
                        <Button v-if="!isRequired(item)" @click="openDeletionModal(item)" icon="delete" type="icon" />
                    </div>
                </template>
                <!-- SERVICE NAME -->
                <template v-slot:item.service-name="{ item }">
                    <span class="table-edit">
                        <a @click="openModal(item.id)" type="text" class="table-edit__link">{{ item.serviceName }}</a>
                        <span class="table-edit__icon material-icons-outlined">edit</span>
                    </span>
                </template>
                <!-- PUBLIC NAME -->
                <template v-slot:item.public-name="{ item }">
                    <span class="table-edit">
                        <a @click="openModal(item.id)" type="text" class="table-edit__link">{{ item.publicName }}</a>
                        <span class="table-edit__icon material-icons-outlined">edit</span>
                    </span>
                </template>
                <!-- COLOR -->
                <template v-slot:item.color="{ item }">
                    <span class="order-state-list__color" :style="{ backgroundColor: getColor(item) }"></span>
                    {{ getColor(item) }}
                </template>
            </Table>
        </template>
    </div>
</template>

<script>
import OrderStateService from '@/services/OrderStateService';
import OrderStateModal from '@/components/orders/OrderStateModal';
import EventEmitter from '@/helpers/eventEmitter.ts';
import ErrorBlock from '@/components/common/ErrorBlock';
import Table from '@/components/common/Table';
import Button from '@/components/common/Button';
import OrderState from '@/entities/order/OrderState';

export default {
    name: 'OrderStateList',
    components: {
        Button,
        ErrorBlock,
        OrderStateModal,
        Table,
    },

    async created() {
        EventEmitter.on('delete-orderState', this.deleteItems);
        await this.getItems();
    },
    data: () => ({
        items: [],
        isModalOpen: false,
        orderStateId: null,
        selected: [],
        isLoaded: false,
        sortBy: null,
        sortDesc: null,
        isAvailable: true,
        addNewBtn: {
            title: 'entities.add',
        },
        requireStatusIds: [
            'fd885607-1a0e-4d15-9437-b7d2ac77f3c8',
            'aed8345a-d2d9-473c-8f57-6e67f64a3aef',
            'ef9a94b8-8bf4-4a88-86e3-229c073edf4b',
        ],
    }),
    computed: {
        headers() {
            return [
                { text: this.$t('lists.columns.serviceName'), value: 'service-name', sortable: true },
                { text: this.$t('lists.columns.publicName'), value: 'public-name', sortable: true },
                { text: this.$t('lists.columns.color'), value: 'color', sortable: true },
                { text: this.$t('lists.columns.position'), value: 'position', sortable: true },
                { value: 'actions', width: '114px' },
            ];
        },
    },
    methods: {
        isRequired(item) {
            return this.requireStatusIds.includes(item.id);
        },
        getColor(item) {
            return item.color ? item.color : new OrderState().fields.color.value;
        },
        async getItems() {
            this.isLoaded = false;
            const [error, result] = await OrderStateService.getAll();
            if (error) {
                error.alert();
                this.isAvailable = false;
                return;
            }
            this.items = result;
            this.isLoaded = true;
        },
        openDeletionModal(items) {
            this.$store.dispatch('modals/openDeletionModal', { items, entity: 'orderState' });
        },

        openModal(id) {
            this.isModalOpen = true;
            this.orderStateId = id;
        },

        async deleteItems(items) {
            this.isLoaded = false;
            const promises = items.map((item) => OrderStateService.removeOne(item.id));
            const results = await Promise.all(promises);
            this.selected = [];
            this.$store.dispatch('modals/closeDeletionModal');
            await this.getItems();
            if (
                results.every((item) => {
                    const [error] = item;
                    return !error;
                })
            ) {
                if (items.length === 1) {
                    EventEmitter.trigger('show-noty', {
                        type: 'success',
                        text: this.$tc('notifications.deleted', 1, {
                            entity: this.$tc('entities.order.state.title', 1),
                        }),
                    });
                } else {
                    EventEmitter.trigger('show-noty', {
                        type: 'success',
                        text: this.$tc('notifications.deleted', 2, {
                            entity: this.$tc('entities.order.state.title', 2),
                        }),
                    });
                }
            }
            this.isLoaded = true;
        },

        updateSort(sortBy, sortDesc) {
            this.sortBy = sortBy;
            this.sortDesc = sortDesc;
        },
    },
    beforeDestroy() {
        EventEmitter.off('delete-orderState', this.deleteItems);
    },
};
</script>
<style lang="scss">
.order-state-list {
    &__color {
        display: inline-block;
        width: 15px;
        height: 10px;
        margin-bottom: -1px;
    }
}
</style>
