<template>
    <div class="product-image-field" :style="`max-width: ${maxWidth}`">
        <heading type="body-2" v-if="label">{{ label }}</heading>
        <div class="product-image-field__content">
            <img
                @click="changeDialog = true"
                :aspect-ratio="aspectRatio"
                :src="src"
                class="product-image-field__image"
            />
        </div>
        <modal :header="$t('entities.image.change')" v-model="changeDialog" width="600">
            <template v-slot:header-append
                ><Button
                    class="image-modal__remove-btn"
                    @click="model = null"
                    type="text"
                    icon="delete"
                    v-if="model && model.uri"
                    >{{ $t('entities.remove') }}</Button
                ></template
            >
            <div class="modal__content" v-if="changeDialog">
                <product-image-selector infinite :owner-id="ownerId" v-model="model" @close="changeDialog = false" />
            </div>
        </modal>
    </div>
</template>

<script>
import ImageService from '@/services/ImageService';
import Modal from '@/components/common/Modal';
import ProductImageSelector from '@/components/product/ProductImageSelector';
import EntityImage from '@/entities/image/EntityImage';
import Image from '@/entities/image/Image';
import Button from '@/components/common/Button';
import Heading from '@/components/common/Heading';
import { imageUrl } from '@/helpers/values';

export default {
    name: 'ProductImageField',

    inheritAttrs: false,

    components: {
        Button,
        ProductImageSelector,
        Modal,
        Heading,
    },

    props: {
        label: {
            type: String,
        },
        value: {
            type: String,
        },
        aspectRatio: {
            type: Number,
            default: 19 / 9.2,
        },
        maxWidth: {
            type: String,
            default: '100%',
        },
        ownerId: {
            type: String,
            default: null,
        },
        name: {
            type: String,
        },
    },

    async created() {
        await this.getImage();
    },

    data() {
        return {
            image: null,
            imagesManager: false,
            changeDialog: false,
            propertiesDialog: false,
            imageUrl,
        };
    },

    computed: {
        model: {
            get() {
                return this.image;
            },
            set(image) {
                this.image = image;
                const imageId = this.image ? this.image.id : null;
                this.$emit('input', imageId);
            },
        },
        src() {
            return this.image
                ? `${this.imageUrl()}/${this.image.src}`
                : require('@/assets/images/product-no-photo.png');
        },
    },

    methods: {
        async getImage() {
            if (!this.value) {
                this.image = null;
                return;
            }
            const [error, result] = await ImageService.getOne(this.value);
            if (error) {
                error.notify();
                return;
            }
            this.image = result.imageId ? new EntityImage(result) : new Image(result);
        },
    },

    watch: {
        value(newValue) {
            if (newValue !== this.image?.id) this.getImage();
            this.$emit('update-field', {
                name: this.name,
                value: newValue,
            });
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.product-image-field {
    .heading {
        margin-bottom: 8px;
    }

    &__content {
        position: relative;
        width: 80px;
    }

    &__controls {
        padding: 0 !important;
        width: auto !important;
        right: 1px;
        top: 1px;
    }

    &__image {
        width: 60px;
        height: 60px;
        border: 1px solid var(--v-outline-base);
        border-radius: $border-radius-root;
        &:hover {
            border-color: var(--v-primary-accent-base);
            cursor: pointer;
        }
    }
    &__controls-btn {
        background: rgba(#ffffff, 0.5) !important;
        border-radius: 4px;
    }

    &__note {
        color: var(--v-on-surface-medium-base);
        margin-top: $spacer * 3;
    }
}
.image-modal {
    &__remove-btn {
        margin-right: auto;
        margin-left: 20px;
        .btn {
            height: auto !important;
        }
    }
}
</style>
