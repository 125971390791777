<template>
    <div class="error-block">
        <slot>
            {{ $t(text) }}
        </slot>
    </div>
</template>

<script>
export default {
    name: 'ErrorBlock',
    props: {
        text: {
            type: String,
            default: 'notifications.apiIsUnavailable',
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.error-block {
    background: var(--v-surface-base);
    border-radius: 4px;
    padding: 16px;
}
</style>
