export function initItems(state) {
    return {
        url: {
            component: 'InputText',
            type: 'field',
            value: state?.url.value,
            props: {
                labelTrans: 'settings.erpFirstBit.url',
            },
        },
        login: {
            component: 'InputText',
            type: 'field',
            value: state?.login.value,
            props: {
                labelTrans: 'settings.erpFirstBit.login',
            },
        },
        password: {
            component: 'InputText',
            type: 'field',
            value: state?.password.value,
            props: {
                labelTrans: 'settings.erpFirstBit.password',
            },
        },
        enabled: {
            component: 'InputSwitch',
            type: 'field',
            value: state?.enabled.value,
            props: {
                labelTrans: 'settings.erpFirstBit.enabled',
                noLabel: true,
            },
        },
    };
}
