<template>
    <div class="page -column -shrunk">
        <progress-circular class="product-form__spinner spinner" v-if="isLoading && isAvailable"></progress-circular>
        <template v-else-if="!isAvailable">
            <div class="page-header">
                <h1 class="page-header__text">{{ $t('entities.product.title') }}</h1>
            </div>
            <error-block></error-block>
        </template>
        <template v-else>
            <div class="page-header">
                <h1 class="page-header__text">
                    {{ currentState.title }}
                </h1>
            </div>
            <alert type="success" class="entity-item__success-alert" v-if="isUpdated">
                {{ $tc('notifications.updated', 1, { entity: this.$tc('entities.option.title', 1) }) }}
            </alert>

            <div class="block">
                <form class="block__body form" ref="form">
                    <template>
                        <form-item
                            class="modal__input"
                            v-for="(field, key) in currentState.fields"
                            :key="key"
                            v-bind="field.props"
                            :name="key"
                        >
                            <component
                                v-if="key === 'values'"
                                :is="field.component"
                                class="modal__combobox"
                                :value="currentState.values"
                                item-text="title"
                                item-value="id"
                                v-bind="field.props || {}"
                                :items="currentState.values"
                                v-on="getFieldEvents(field.events)"
                                multiple
                                outlined
                                dense
                                small-chips
                                deletable-chips
                            />
                            <component
                                v-else-if="key === 'position'"
                                :is="field.component"
                                v-model.number="currentState[key]"
                                v-bind="field.props || {}"
                                @update-field="onUpdateFieldValue"
                                :name="key"
                                hide-details
                                v-on="getFieldEvents(field.events)"
                                outlined
                                dense
                            ></component>
                            <component
                                v-else
                                :is="field.component"
                                v-model="currentState[key]"
                                v-bind="field.props || {}"
                                @update-field="onUpdateFieldValue"
                                :name="key"
                                hide-details
                                v-on="getFieldEvents(field.events)"
                                outlined
                                dense
                            ></component>
                        </form-item>
                    </template>

                    <Button :disabled="!isSaveAllowed" class="ml-auto" icon="check" @click="onUpdateOption">
                        {{ $t('entities.save') }}
                    </Button>
                </form>
            </div>
        </template>
    </div>
</template>

<script>
import Modal from '@/components/common/Modal';
import Option from '@/entities/option/Option';
import OptionService from '@/services/OptionService';
import FormItem from '@/components/form/item';
import validateField from '@/helpers/validator';
import { debounce, cloneDeep, isEqual } from 'lodash';
import EventEmitter from '@/helpers/eventEmitter.ts';
import Button from '@/components/common/Button';
import ProgressCircular from '@/components/common/ProgressCircular';
import Alert from '@/components/common/Alert';
import ComboboxMultiple from '@/components/form/controls/ComboboxMultiple';

export default {
    name: 'OptionsItem',
    components: { Alert, FormItem, Modal, Button, ProgressCircular, ComboboxMultiple },

    data() {
        return {
            isValid: false,
            currentState: new Option(),
            fieldKeys: Object.keys(new Option().fields),
            isLoading: false,
            initialState: null,
            isAvailable: true,
            isSaveAllowed: false,
            isUpdated: false,
        };
    },

    computed: {
        optionId() {
            return this.$route.params.id;
        },
    },

    async created() {
        await this.getOption();
        this.initialState = cloneDeep(this.currentState);
    },

    methods: {
        async getOption() {
            this.isLoading = true;
            const [error, result] = await OptionService.getOne(this.optionId);
            if (error) {
                error.alert();
                this.isAvailable = false;
                return;
            }
            this.currentState = new Option(result);
            this.isLoading = false;
        },

        async onUpdateOption() {
            const isFormValid = this.validateForm();
            if (isFormValid === false) return;
            const [error, result] = await OptionService.updateOne(this.currentState.data);
            error ? this.failedHandler(result, error) : this.successHandler();
        },

        successHandler() {
            this.isUpdated = true;
            this.initialState = cloneDeep(this.currentState);
            this.isSaveAllowed = false;
            window.scrollTo({ top: 0, behavior: 'smooth' });
        },

        validateForm() {
            let result = true;
            const fieldKeys = Object.keys(this.currentState.fields);
            fieldKeys.forEach((key) => {
                const errors = validateField(this.currentState[key], this.currentState.fields[key].props.rules);
                if (errors.length !== 0) result = false;
                this.$set(this.currentState.fields[key].props, 'errors', errors);
            });
            if (result === false)
                EventEmitter.trigger('show-noty', {
                    type: 'error',
                    text: this.$t('notifications.validation.error'),
                });
            return result;
        },

        failedHandler(response, error) {
            error.notify();
            const children = response.data?.errors?.children;
            if (children) {
                this.fieldKeys.forEach((key) => {
                    const errors = children[key] ? children[key].errors : [];
                    if (errors) this.$set(this.currentState.fields[key].props, 'errors', errors);
                });
            }
        },

        onUpdateFieldValue: debounce(function(payload) {
            const { name } = payload;
            const errors = validateField(this.currentState[name], this.currentState.fields[name].props.rules);
            this.$set(this.currentState.fields[name].props, 'errors', errors);
        }, 600),

        getFieldEvents(events) {
            const fieldEvents = {};
            for (const key in events) {
                fieldEvents[key] = events[key]?.bind(this.currentState);
            }
            return fieldEvents;
        },
    },
    watch: {
        currentState: {
            handler(val) {
                if (this.initialState) {
                    this.isSaveAllowed = !isEqual(val.data, this.initialState.data);
                }
            },
            deep: true,
        },
    },
};
</script>

<style scoped></style>
