import { uuid } from 'vue-uuid';
import OrderItem from '@/entities/order/OrderItem';
import User from '@/entities/user/User';
import OrderDeliveryInformation from '@/entities/order/OrderDeliveryInformation';
import validateField from '@/helpers/validator';
import initFields from '@/entities/order/Order.fields';
import countries from '@/helpers/countries';

/**
 * Класс экземпляра заказа
 */
export default class Order {
    type = 'order';
    fields = initFields();
    /**
     *
     * @param {Object}  data - объект данных заказа
     */
    constructor(
        data = {
            id: uuid.v4(),
            number: '',
            createdAt: '',
            email: '',
            payments: [],
            name: '',
            phone: '',
            deliveryInformation: undefined,
            items: [],
            manager: null,
            orderComment: '',
            method: '',
            customerComment: '',
            receiverComment: '',
            isOtherReceiver: false,
            receiverName: '',
            receiverPhone: '',
            stateId: '',
        }
    ) {
        this.id = data.id;
        this.number = data.number;
        this.createdAt = new Date(data.createdAt);
        this.email = data.email;
        this.name = data.name;
        this.phone = data.phone;
        // когда добавят payment
        //   this.payments = [new OrderPayment({ orderId: this.id })];
        this.deliveryInformation = new OrderDeliveryInformation(data.deliveryInformation);
        this.items = data.items.map((item) => new OrderItem(item));
        this.method = data.method;
        this.manager = data.manager ? new User(data.manager) : null;
        this.orderComment = data.orderComment || '';
        this.customerComment = data.customerComment || '';
        this.receiverComment = data.receiverComment || '';
        this.isOtherReceiver = data.isOtherReceiver || false;
        this.receiverName = data.receiverName || '';
        this.receiverPhone = data.receiverPhone || '';
        this.stateId = data.stateId;
    }

    get totalPrice() {
        return this.items.reduce((price, item) => (price += item.quantity * item.discountPrice), 0);
    }

    get data() {
        return {
            id: this.id,
            email: this.email,
            name: this.name,
            phone: this.phone,
            //          payments: this.payments.map((item) => item.data),
            deliveryInformation: this.deliveryInformation.data,
            items: this.items.map((item) => item.data),
            manager: this.manager,
            method: this.method,
            orderComment: this.orderComment,
            customerComment: this.customerComment,
            receiverComment: this.receiverComment,
            isOtherReceiver: this.isOtherReceiver,
            receiverName: this.isOtherReceiver ? this.receiverName : '',
            receiverPhone: this.isOtherReceiver ? this.receiverPhone : '',
            stateId: this.stateId,
        };
    }

    addItem(item = null) {
        const orderItem = item ? item : new OrderItem();
        this.items = [...this.items, orderItem];
    }

    validateField(name) {
        this.fields[name].props.errors = validateField(this[name], this.fields[name].props.rules);
    }

    validateEntity() {
        let result = true;
        for (const key in this.fields) {
            if (key === 'receiverName' && !this.isOtherReceiver) continue;
            if (key === 'receiverPhone' && !this.isOtherReceiver) continue;
            const errors = validateField(this[key], this.fields[key].props.rules);
            if (errors.length !== 0) result = false;
            this.fields[key].props.errors = errors;
        }
        const country = this.deliveryInformation.deliveryAddress.country;
        //если выбран самовывоз - не вылидируем адресс
        const toValidateDelivery = this.deliveryInformation.type === 'delivery';
        //если выбрана страна без регионов - не валидируем регион
        const toValidateRegion =
            country &&
            countries.find((item) => item.code === country).zones?.length &&
            this.deliveryInformation.type === 'delivery';

        const addressResult = this.deliveryInformation.deliveryAddress.validateEntity(
            toValidateDelivery,
            toValidateRegion
        );
        if (!addressResult) result = false;
        return result;
    }
}
