import { uuid } from 'vue-uuid';
import initFields from '@/entities/blog/Author.fields';

export default class Author {
    constructor({
        id = uuid.v4(),
        name = '',
        type = '',
        photoId = '',
        bio = '',
        jobTitle = '',
        website = '',
        articlesCreatedIds = [],
        articlesPublishedIds = [],
        facebookUrl = '',
        instagramUrl = '',
        xUrl = '',
        linkedInUrl = '',
        tiktokUrl = '',
        pinterestUrl = '',
    } = {}) {
        this.id = id;
        this.name = name;
        this.type = type;
        this.photoId = photoId;
        this.bio = bio;
        this.jobTitle = jobTitle;
        this.website = website;
        this.articlesCreatedIds = articlesCreatedIds;
        this.articlesPublishedIds = articlesPublishedIds;
        this.facebookUrl = facebookUrl;
        this.instagramUrl = instagramUrl;
        this.xUrl = xUrl;
        this.linkedInUrl = linkedInUrl;
        this.tiktokUrl = tiktokUrl;
        this.pinterestUrl = pinterestUrl;
        this.fields = initFields(this);
    }

    get data() {
        return {
            id: this.id,
            name: this.name,
            type: this.type,
            photoId: this.photoId,
            bio: this.bio,
            jobTitle: this.jobTitle,
            website: this.website,
            articlesCreatedIds: this.articlesCreatedIds,
            articlesPublishedIds: this.articlesPublishedIds,
            facebookUrl: this.facebookUrl,
            instagramUrl: this.instagramUrl,
            xUrl: this.xUrl,
            linkedInUrl: this.linkedInUrl,
            tiktokUrl: this.tiktokUrl,
            pinterestUrl: this.pinterestUrl,
        };
    }
}
