import defaultConfig from '@/configs/default';
import SettingItem from '@/entities/setting/SettingItem';
import { cloneDeep } from 'lodash';

export function getDefaultSettings({ groups = [] } = {}) {
    const settings = groups.length
        ? Object.fromEntries(groups.map((group) => [group, defaultConfig[group]]))
        : defaultConfig;
    return createSettingsList(settings);
}

export function createSettingsList(settings) {
    return cloneDeep(
        Object.fromEntries(
            Object.entries(settings).map(([groupName, value]) => [
                groupName,
                Object.fromEntries(Object.entries(value).map(([key, value]) => [key, new SettingItem(value)])),
            ])
        )
    );
}
