<template>
    <tooltip class="sidebar-tooltip">
        <span class="icon sidebar-tooltip__icon tooltip__icon -primary -outlined">help</span>
        <div>
            <div class="sidebar-tooltip__header">
                {{ $t('entities.domain.tooltips.types') }}
            </div>
            <div class="sidebar-tooltip__items">
                <div class="sidebar-tooltip__item">
                    <div class="sidebar-tooltip__item-name">{{ $t('entities.domain.tooltips.primaryDomain') }}</div>
                    <div class="sidebar-tooltip__item-text">
                        {{ $t('entities.domain.tooltips.primaryCaption') }}
                    </div>
                </div>
                <div class="sidebar-tooltip__item">
                    <div class="sidebar-tooltip__item-name">{{ $t('entities.domain.tooltips.redirectingDomain') }}</div>
                    <div class="sidebar-tooltip__item-text">
                        {{ $t('entities.domain.tooltips.redirectingCaption') }}
                    </div>
                </div>
            </div>
        </div>
    </tooltip>
</template>

<script>
import Tooltip from '@/components/common/Tooltip';
export default {
    name: 'DomainTypeTooltip',
    components: { Tooltip },
};
</script>

<style lang="scss"></style>
