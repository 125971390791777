export default (ctx) => ({
    text: {
        type: 'field',
        value: ctx.text,
        component: 'code-editor',
        props: {
            labelTrans: 'entities.comment.fields.text',
            errors: [],
        },
    },
    name: {
        type: 'field',
        value: ctx.name,
        component: 'input-text',
        props: {
            labelTrans: 'entities.comment.fields.name',
            errors: [],
        },
    },
    email: {
        type: 'field',
        value: ctx.email,
        component: 'input-text',
        props: {
            labelTrans: 'entities.comment.fields.email',
            errors: [],
            type: 'email',
        },
    },
    parentId: {}, //????
});
