<template>
    <div class="page">
        <div class="page-header">
            <h1 class="page-header__text">{{ $t('menus.pageHeaders.leads') }}</h1>
        </div>
        <view-lead-modal :item="viewItem" v-model="isViewModalOpen"></view-lead-modal>
        <comment-modal
            :comment="editingLead.comment"
            @update-comment="onUpdateComment"
            v-model="isCommentModalOpen"
        ></comment-modal>
        <error-block v-if="!isAvailable"></error-block>
        <Table
            v-else
            :columns="headers"
            v-model="selected"
            :items="items"
            :is-data-loaded="isDataLoaded"
            :show-select="true"
            :pagination="pagination"
            @change-per-page="changePerPage"
            @delete-selected-items="openDeletionModal"
        >
            <!--  PRODUCT  -->
            <template v-slot:item.product="{ item }">
                <router-link
                    v-if="item.product && products[item.product]"
                    class="lead__product-info"
                    :to="{ name: 'Edit Product', params: { id: products[item.product].productId } }"
                >
                    <div class="leads__product">
                        <table-image class="table__image" :image="getImage(products[item.product])"></table-image>
                        <span class="table-edit">
                            <span type="text" class="table-edit__link product-title">{{
                                products[item.product].productTitle
                            }}</span>
                        </span>
                    </div>
                </router-link>
                <template v-else-if="!item.product">—</template>
                <template v-else-if="products[item.product] === null">{{ $t('lists.columns.deleted') }}</template>
                <progress-circular class="lead__spinner spinner lead__spinner" v-else></progress-circular>
            </template>
            <!--  ACTIONS  -->
            <template v-slot:item.actions="{ item }">
                <div class="table__actions">
                    <Button icon="visibility" @click="openViewModal(item)" type="icon" />
                    <Button
                        class="chat-icon"
                        icon="chat"
                        @click="openCommentModal(item)"
                        type="icon"
                        :class="{ '-has-comment': item.comment }"
                    />
                    <Button
                        :title="$t('lists.editButton.lead')"
                        :to="{ name: 'Edit Lead', params: { id: item.id } }"
                        icon="edit"
                        type="icon"
                    />
                    <Button
                        :title="$t('lists.deleteButton.product')"
                        @click="openDeletionModal(item)"
                        type="icon"
                        icon="delete"
                    />
                </div>
            </template>
            <!--  CREATED AT  -->
            <template v-slot:item.createdAt="{ item }">
                {{ new Date(item.createdAt) | localDate }}
            </template>
            <!--  TEXT  -->
            <template v-slot:item.text="{ item }">
                <div v-if="item.text" class="leads__text">{{ item.text }}</div>
                <template v-else>—</template>
            </template>
            <!--  MANAGER  -->
            <template v-slot:item.manager="{ item }">
                {{ item.manager || '—' }}
            </template>
        </Table>
    </div>
</template>

<script>
import { Pagination } from '@/services/PaginationService';
import { cloneDeep } from 'lodash';
import EventEmitter from '@/helpers/eventEmitter.ts';
import ErrorBlock from '@/components/common/ErrorBlock';
import LeadService from '@/services/LeadService';
import Lead from '@/entities/lead/Lead';
import ProductService from '@/services/ProductService';
import TableImage from '@/components/common/TableImage';
import Image from '@/entities/image/Image';
import CommentModal from '@/components/orders/CommentModal';
import ViewLeadModal from '@/components/lead/ViewLeadModal';
import Table from '@/components/common/Table';
import Button from '@/components/common/Button';
import ProgressCircular from '@/components/common/ProgressCircular';

export default {
    name: 'LeadList',
    components: {
        Button,
        ViewLeadModal,
        ErrorBlock,
        TableImage,
        Table,
        CommentModal,
        ProgressCircular,
    },
    async created() {
        EventEmitter.on('delete-lead', this.deleteItems);
        const total = await this.getTotal();
        if (this.$route.query.limit) {
            this.perPage = this.paginationOptions.find((item) => item.value == this.$route.query.limit).value;
        }
        this.pagination = new Pagination({
            moduleName: 'leads',
            limit: 100,
            pageNumber: this.pageNumber,
            total,
        });
        await this.getItems();
        this.initialLoading = false;
    },
    data: () => ({
        isDataLoaded: false,
        items: [],
        isAvailable: true,
        initialLoading: true,
        pagination: null,
        isEditModalOpen: false,
        count: null,
        selected: [],
        editingLead: new Lead(),
        isViewModalOpen: false,
        viewItem: { lead: {}, product: {} },
        isCommentModalOpen: false,
        products: {},
    }),
    computed: {
        headers() {
            return [
                { value: 'actions', sortable: false, width: '114px' },
                { text: this.$t('lists.columns.number'), value: 'number', width: 110, sortable: false },
                { text: this.$t('lists.columns.createdAt'), value: 'createdAt', width: 140, sortable: false },
                { text: this.$t('lists.columns.type'), value: 'type', width: 120, sortable: false },
                { text: this.$t('lists.columns.name'), value: 'name', width: 130, sortable: false },
                { text: this.$t('lists.columns.phone'), value: 'phone', width: 170, sortable: false },
                { text: this.$t('lists.columns.email'), value: 'email', width: 150, sortable: false },
                { text: this.$t('lists.columns.manager'), value: 'manager', width: 110, sortable: false },
                { text: this.$t('lists.columns.state'), value: 'state', width: 110, sortable: false },
                { text: this.$t('lists.columns.messageFromClient'), value: 'text', width: 200, sortable: false },
                { text: this.$t('lists.columns.product'), value: 'product', width: 300, sortable: false },
            ];
        },
        pageNumber() {
            return this.$store.getters['globalVars/getLeadsPage'] || 1;
        },
    },
    methods: {
        async getItems() {
            this.isDataLoaded = false;

            const params = {
                limit: this.pagination.limit,
                offset: this.pagination.offset,
                type: null,
            };
            const getter = this.getLeads;
            const [error, result] = await getter(params);
            if (error) {
                error.alert();
                this.isAvailable = false;
                this.isDataLoaded = true;
                return;
            }
            this.items = result.map((item) => new Lead(item));

            const variationIds = this.items.map((item) => item.product).filter((item) => item);
            if (variationIds.length) {
                this.products = await this.getProductVariations(variationIds);
            }
            this.isDataLoaded = true;
        },
        async getLeads(params) {
            return await LeadService.getAll(params);
        },

        async getTotal() {
            const [error, total] = await Pagination.getTotal('leads');
            if (error) {
                error.notify();
            }
            return total;
        },

        changePerPage(perPage) {
            this.pagination.changeLimit(perPage.value);
            this.perPage = perPage.value;
            this.getItems();
        },

        getImage(item) {
            const image = item.image || item.productImage;
            return image ? new Image(image) : null;
        },

        async deleteItems(items) {
            this.isDataLoaded = false;
            const promises = items.map((item) => LeadService.removeOne(item.id));
            const results = await Promise.all(promises);
            const normalized = await this.pagination.normalize(this.$route, this.items);
            this.selected = [];
            this.$store.dispatch('modals/closeDeletionModal');
            if (!normalized) {
                await this.getItems();
            }
            if (results.every((item) => item.success)) {
                if (items.length === 1) {
                    EventEmitter.trigger('show-noty', {
                        type: 'success',
                        text: this.$tc('notifications.deleted', 1, { entity: this.$tc('entities.lead.title', 1) }),
                    });
                } else {
                    EventEmitter.trigger('show-noty', {
                        type: 'success',
                        text: this.$tc('notifications.deleted', 2, { entity: this.$tc('entities.lead.title', 2) }),
                    });
                }
            }
            this.isDataLoaded = true;
        },

        openCommentModal(lead) {
            this.editingLead = lead;
            this.isCommentModalOpen = true;
        },

        openViewModal(item) {
            this.viewItem = { lead: item, product: this.products[item.product] };
            this.isViewModalOpen = true;
        },
        async onUpdateComment(comment) {
            const lead = cloneDeep(this.editingLead);
            lead.comment = comment;
            const [error, result] = await LeadService.updateOne(lead.data);
            if (error) {
                error.notify();
                return;
            }
            const itemIndex = this.items.findIndex((item) => item.id === result.id);
            this.items.splice(itemIndex, 1, new Lead(result));
            this.isCommentModalOpen = false;
        },
        openDeletionModal(items) {
            this.$store.dispatch('modals/openDeletionModal', { items, entity: 'lead' });
        },

        async getProductVariations(ids) {
            const promises = ids.map((id) =>
                ProductService.getOneVariation(id).then((data) => {
                    if (data[0]) {
                        data[0].notify();
                        return { [id]: null };
                    } else {
                        const variation = data[1].variations.find((item) => item.id === id);
                        variation.productTitle = data[1].title;
                        variation.productId = data[1].id;
                        variation.productImage = data[1].images[0];
                        return { [id]: variation };
                    }
                })
            );
            const results = await Promise.all(promises);
            let resultObj = {};
            results.forEach((item) => (resultObj = { ...resultObj, ...item }));
            return resultObj;
        },

        openEditModal(item) {
            this.isEditModalOpen = true;
            this.editingLead = item;
        },
        async onUpdateLead(lead) {
            const index = this.items.findIndex((item) => {
                return item.id === lead.id;
            });
            const items = cloneDeep(this.items);
            items[index] = lead;
            this.items = items;
        },
    },
    beforeDestroy() {
        EventEmitter.off('delete-lead', this.deleteItems);
    },
    watch: {
        pageNumber(newValue) {
            this.pagination.pageNumber = newValue;
            this.getItems();
        },
    },
};
</script>
<style lang="scss">
@import '@/scss/variables.scss';
.leads {
    &__product {
        display: flex;
        gap: 24px;
        align-items: center;
        cursor: pointer;
        color: var(--v-primary-base);
        .table-edit {
            overflow-wrap: break-word;
            max-width: 270px;
        }
        .product-title {
            + .table-edit__icon {
                bottom: 3px;
            }
        }
        &:hover {
            color: var(--v-primary-accent-base);
            .table-edit__icon {
                opacity: 1;
                transition: all 0.1s;
            }
        }
        &:active {
            color: var(--v-primary-darken-base);
        }
    }

    &__text {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
}
.chat-icon {
    &.-has-comment {
        color: #2979ff;
    }
}
</style>
