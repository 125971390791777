const state = {
    deletion: {
        open: false,
        items: [],
        entity: null,
        deleteAll: false,
    },
};

const mutations = {
    SET_DELETION_DATA(state, payload) {
        state.deletion = payload;
    },
};
const actions = {
    openDeletionModal({ commit }, { items, entity, deleteAll = false }) {
        //на случай, если передаём не массив, а один элемент
        const entries = Array.isArray(items) ? [...items] : [items];
        commit('SET_DELETION_DATA', { open: true, items: entries, entity, deleteAll });
    },
    closeDeletionModal({ commit }) {
        const payload = {
            open: false,
            items: [],
            entity: null,
            deleteAll: false,
        };
        commit('SET_DELETION_DATA', payload);
    },
};

const getters = {
    getDeletionState: (state) => state.deletion,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
