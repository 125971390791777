<template>
    <div v-click-outside="closeDropdown" class="select" :name="name" @click.prevent :class="classes">
        <div class="select__field" :class="title.type" @click="toggleDropdown" ref="select">
            <!--            Костыль с v-model для реактивности переводов-->
            <input
                @focus="isInputFocused = true"
                @blur="isInputFocused = false"
                v-if="autocomplete"
                :placeholder="$t(placeholder)"
                @input="handleInput"
                class="select__search"
                :value="inputValue"
                ref="input"
            />
            <template v-else>
                <slot name="title-prepend" :item="selected"></slot>
                <span
                    v-if="title.icon"
                    :class="{ 'material-icons': useMaterialIcons, 'select__custom-icon': !useMaterialIcons }"
                    >{{ useMaterialIcons ? title.icon : '' }} <img v-if="!useMaterialIcons" :src="title.icon"
                /></span>
                <span v-if="title.color" class="select__item-color" :style="{ backgroundColor: title.color }"></span>
                <span>{{ title.text }}</span>
            </template>
        </div>
        <span class="select__arrow material-symbols-outlined" :class="{ 'is-open': isDropdownShow }">
            expand_more
        </span>
        <div
            class="select__dropdown"
            ref="dropdown"
            :class="{ 'is-open': isDropdownShow, '-top': dropdownDirectionTop }"
        >
            <div
                v-for="(option, index) in searched"
                :class="{
                    '-disabled': option.disabled,
                    '-selected': value === option.value,
                    '-empty': option.value === null,
                }"
                :key="index"
                class="select__option"
                @click="selectOption(option)"
            >
                <slot name="item" :item="option">
                    <!--
                    <span v-if="option.icon" class="material-icons">{{ option.icon }}</span>
-->
                    <span
                        v-if="option.icon"
                        :class="{ 'material-icons': useMaterialIcons, 'select__custom-icon': !useMaterialIcons }"
                        >{{ useMaterialIcons ? option.icon : '' }} <img v-if="!useMaterialIcons" :src="option.icon"
                    /></span>
                    <span
                        v-if="option.color"
                        class="select__item-color"
                        :style="{ backgroundColor: option.color }"
                    ></span>
                    <span>{{ option.translation }}</span>
                    <i v-if="value === option.value" class="material-icons">done</i>
                </slot>
            </div>
            <div class="select__option" v-if="!searched.length">{{ $t('search.noData') }}</div>
        </div>
        <progress-linear v-if="isLoading"></progress-linear>
    </div>
</template>

<script>
import { proxyModelMixin } from '@/mixins/proxyModelMixin';
import ProgressLinear from '@/components/common/ProgressLinear';
export default {
    name: 'Select',
    components: { ProgressLinear },
    inheritAttrs: false,
    mixins: [proxyModelMixin],
    props: {
        name: { type: String, default: '' },
        group: { type: String, default: null },
        placeholder: { type: String, default: 'menus.selectPlaceholder' },
        errors: { type: [Array, Object], default: () => [] },
        items: { type: [Array], default: () => [] },
        value: { type: [String, Number, Object, Date, Boolean] },
        translated: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
        hasSearch: { type: Boolean, default: false },
        isLoading: { type: Boolean, default: false },
        searchString: { type: String, default: '' },
        itemText: { type: String },
        itemValue: { type: String },
        backendSearch: { type: Boolean, default: false },
        canBeEmpty: { type: Boolean, default: false },
        autocomplete: { type: Boolean, default: false },
        size: {
            type: String,
            default: 'medium',
            validator: function(value) {
                return ['small', 'medium'].includes(value);
            },
        },
        useMaterialIcons: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            dropdownDirectionTop: false,
            isDropdownShow: false,
            searchText: '',
            isInputFocused: false,
        };
    },
    created() {
        this.searchText = this.selected?.translation;
    },
    computed: {
        classes() {
            return {
                '-has-errors': Array.isArray(this.errors) && this.errors.length > 0,
                '-small': this.size === 'small',
                '-is-open': this.isDropdownShow,
                '-disabled': this.disabled,
            };
        },
        searched() {
            if (this.backendSearch) return this.options;
            const result = this.canBeEmpty ? [{ translation: this.$t('search.emptyValue'), value: null }] : [];
            let items = [];
            if (!this.autocomplete || !this.searchText || this.searchText === this.selected?.translation) {
                // items = this.options.filter((item) => this.model !== item.value);
                items = this.options;
            } else {
                items = this.options.filter((item) => {
                    // if (this.model === item.value) return false;
                    return item.translation.toLowerCase().startsWith(this.searchText.toLowerCase());
                });
            }
            return items.length ? [...result, ...items] : [];
        },
        options() {
            return this.items
                .map((item) => {
                    const obj = {
                        icon: item.icon || false,
                        color: item.color || false,
                        text: item[this.itemText] || item.text || item,
                        value: item[this.itemValue] || item.value || item,
                        disabled: item.disabled,
                    };
                    const text = item[this.itemText] || item.text || item;
                    const translation = this.translated ? text : this.$t(text);
                    obj.text = text;
                    obj.translation = translation;
                    return obj;
                })
                .sort((a, b) => {
                    if (typeof a.text === 'number' || typeof b.text === 'number') return 0;
                    else return a.text.localeCompare(b.text);
                });
        },
        title() {
            const selectedItem = this.options.find((option) => this.value === option.value);
            if (selectedItem?.value)
                return {
                    type: '-value',
                    text: this.$t(selectedItem.text),
                    icon: selectedItem.icon || false,
                    color: selectedItem.color || false,
                };
            if (this.placeholder) return { type: '-placeholder', text: this.$t(this.placeholder) };
            return { type: '-placeholder', text: this.name };
        },
        selected() {
            return this.options.find((item) => item.value === this.model);
        },
        inputValue() {
            return this.isDropdownShow ? this.searchText : this.selected?.translation;
        },
    },
    watch: {
        value: function(newValue) {
            //если используем как search, то только через searchForm, которая и вызывает update-field
            if (this.hasSearch) return;
            this.$emit('update-field', {
                name: this.name,
                group: this.group,
                value: newValue,
            });
            this.$emit('change', {
                name: this.name,
                group: this.group,
                value: newValue,
            });
        },
    },
    methods: {
        selectOption(option) {
            if (!option.disabled) {
                console.log('select change before');
                this.model = option.value;
                this.searchText = option.translation;
                this.closeDropdown();
            }
        },
        handleInput(e) {
            this.searchText = e.target.value;
            if (this.hasSearch) {
                this.$emit('update:searchString', e.target.value);
            }
        },
        openDropdown() {
            if (this.isDropdownShow) return;
            this.searchText = this.selected?.translation;
            this.chooseDropdownDirection();
            this.isDropdownShow = true;
            // this.scrollToSelected();
        },
        //Вернуть и заменить filter на map в options,  если нужно показывать выбранное
        /*        scrollToSelected() {
            const selected = this.$refs.dropdown.querySelector('.-selected');
            if (!selected) return;
            const mustScroll = this.$refs.dropdown.clientHeight < selected.offsetTop + selected.offsetHeight;
            if (mustScroll) {
                /!* убрать "- this.$refs.dropdown.clientHeight / 2 + selected.offsetHeight", если нужно, чтобы
                скроллилось ровно до выбранного элемента, не показывая ничего выше*!/
                this.$refs.dropdown.scrollTop =
                    selected.offsetTop - this.$refs.dropdown.clientHeight / 2 + selected.offsetHeight;
            }
        },*/
        closeDropdown() {
            if (!this.isDropdownShow) return;
            this.isDropdownShow = false;
            if (!this.autocomplete) return;
            if (!this.searchText) {
                this.model = null;
            }
        },
        toggleDropdown() {
            if (this.isInputFocused && this.isDropdownShow) return;
            if (this.isDropdownShow) this.closeDropdown();
            else this.openDropdown();
        },
        chooseDropdownDirection() {
            const offsetTop = this.$refs.select.getBoundingClientRect().y;
            this.dropdownDirectionTop = window.innerHeight - offsetTop < 350;
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';

.select {
    position: relative;
    &.-is-open {
        z-index: 5;
    }
    &.-small & {
        &__field {
            height: 32px;
            line-height: 30px;
        }
        &__arrow {
            top: 7px;
            font-size: 18px;
        }
    }

    &__field {
        display: flex;
        align-items: center;
        box-sizing: border-box;
        width: 100%;
        height: $form-control-height;
        padding: 0 2 * $form-control-padding-horizontal 0 $form-control-padding-horizontal;
        border: $form-control-border;
        border-radius: $form-control-border-radius;
        background: $form-control-bg;
        transition: $form-control-transition;
        appearance: none;
        line-height: calc(#{$form-control-height} - 2px);
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:hover,
        &:focus {
            border-color: $form-control-border-color-hover;
        }

        &.-placeholder {
            color: inherit;
            opacity: 0.7;
        }

        .material-icons,
        .material-icons-outlined {
            margin-right: 6px;
        }
    }

    &__search {
        display: block;
        height: 24px;
        outline: none !important;
        appearance: none;
        width: 100%;
        flex-shrink: 2;
        line-height: calc(#{$form-control-height} - 2px);
        &.-placeholder {
            color: inherit;
            opacity: 0.7;
        }
    }

    &.-disabled &__field {
        background-color: rgba(0, 0, 0, 0.04);
        color: var(--v-on-surface-disabled-base);
        pointer-events: none;
        &:hover {
            border-color: $form-control-border-color !important;
        }
    }

    &.-has-errors &__field {
        border-color: $error;
    }

    &__arrow {
        position: absolute;
        right: 8px;
        top: 9px;
        translate: $transition-fast;
        pointer-events: none;

        &.is-open {
            transform: rotate(180deg);
        }
    }

    input {
        border: none;
    }

    &__dropdown {
        position: absolute;
        left: 0;
        top: 100%;
        display: block;
        width: 100%;
        border: 1px solid #cececf;
        border-radius: $input-border-radius;
        padding: 6px 0;
        background: $input-bg;
        max-height: 300px;
        overflow-y: auto;

        @include smooth-dropdown-hide();

        &.is-open {
            @include smooth-dropdown-show();
        }

        &.-top {
            bottom: 100%;
            top: auto;
            transform-origin: 0 100%;
        }
    }

    &__option {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 8px 40px 8px 12px;
        transition: $transition-fast;
        font-size: 16px;
        line-height: 24px;
        text-align: left;
        cursor: pointer;

        &.-disabled {
            background-color: rgba(0, 0, 0, 0.04);
            color: var(--v-on-surface-disabled-base) !important;
        }

        &.-selected {
            //background-color: var(--v-primary-lighten-base);
            background-color: var(--v-background-base);
            &:hover {
                background-color: var(--v-primary-lighten-base);
            }
            i {
                position: relative;
                right: -24px;
                font-size: 16px;
                line-height: 20px;
            }
        }

        &.-empty {
            color: var(--v-on-surface-disabled-base);
        }

        .material-icons,
        .material-icons-outlined {
            margin-right: 6px;
        }

        &:hover {
            background-color: #f4f6f7;
        }

        &.-current {
            border-bottom: 1px solid #e0e0e0;
        }

        &.-highlighted {
            color: #2979ff;
        }
    }
    &__item-color {
        width: 15px;
        height: 10px;
        margin-right: 8px;
        margin-bottom: -2px;
    }
    .progress-linear {
        bottom: 0;
        height: 2px;
        border-radius: 0 0 4px 4px;
    }
    &__custom-icon {
        margin-right: 6px;
    }
}
</style>
