<template>
    <div class="product-size-input" :class="{ '-has-errors': errors.length }">
        <template v-for="(metric, key, index) in value">
            <input-text
                :name="key"
                :prefix="$t(`entities.product.size.${key}`) + ':'"
                ref="input"
                :key="key"
                :placeholder="defaultValue ? defaultValue[key] : null"
                @keydown.native="handleDelete(index, key, $event)"
                @keydown.native.enter="focusOnInput(index, $event)"
                :value="value[key]"
                @input.native="handleNumbers"
                @input="handleInput($event, key)"
                class="form-item__field product-size-input__item"
            />
            <span v-if="index + 1 < Object.keys(value).length" :key="`${key}-separator`">x</span>
        </template>
    </div>
</template>

<script>
//import { clickOutside } from '@/plugins/directives';
import { cloneDeep, isEqual } from 'lodash';
import InputText from '@/components/form/controls/InputText';
export default {
    //  directives: { clickOutside },
    name: 'ProductSizeInput',
    components: { InputText },
    props: {
        value: {
            type: Object,
            default: () => {
                return { length: '', width: '', height: '' };
            },
        },
        defaultValue: {
            type: Object,
            default: () => {},
        },
        name: { type: String, default: '' },
        group: { type: String, default: null },
        errors: { type: Array, default: () => [] },
    },
    methods: {
        handleInput(value, name) {
            const newValue = cloneDeep(this.value);
            const regEx = /\d+/;
            //принимаем только цифры
            const numbers = value.match(regEx)?.[0];
            newValue[name] = numbers;
            this.$emit('input', newValue);
        },
        handleDelete(index, valueKey, e) {
            //обрабатываем backspace на пустом инпуте
            const keyPressed = e.key;
            if (keyPressed === 'Backspace' || keyPressed === 'Delete') {
                const prevInput = this.$refs.input[index - 1]?.$el?.querySelector('input');
                //если вводом ., то возвращается компонентом text-input пустое значение
                const currentInputValue = this.$refs.input[index].$el.querySelector('input').value;
                if (!currentInputValue && prevInput) {
                    prevInput.focus();
                }
            }
        },
        handleNumbers(e) {
            //костыль для избавления от бага с точкой в number инпуте
            const regEx = /\d+/;
            const match = e.target.value.match(regEx)?.[0];
            e.target.value = match ? match : '';
        },
        focusOnInput(index, event) {
            const nextInput = this.$refs.input[index + 1];
            if (nextInput) {
                nextInput.$el.querySelector('input').focus();
            } else {
                event.target.blur();
            }
        },
    },
    watch: {
        value: function(newValue, oldValue) {
            if (!isEqual(newValue, oldValue)) {
                this.$emit('update-field', {
                    name: this.name,
                    group: this.group,
                    value: newValue,
                });
            }
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.product-size-input {
    &.-has-errors {
        .input-text {
            border-color: #e64a19;
        }
    }
    &.form-item__field {
        &:active {
            caret-color: transparent !important;
        }
    }

    display: flex;
    gap: 8px;
    align-items: center;
    &__item {
        // width: 80px;
        input {
            //   text-align: center;
        }
    }
    &.-focused {
    }
}
</style>
