export default () => ({
    manager: {
        component: 'InputText',
        props: {
            class: '-half-width',
            labelTrans: 'entities.lead.fields.manager',
            errors: [],
        },
    },
    state: {
        component: 'Select',
        props: {
            class: '-half-width',
            rules: ['noEmpty'],
            labelTrans: 'entities.lead.fields.state',
            errors: [],
            items: [
                {
                    text: 'entities.lead.state.open',
                    value: 'open',
                },
                {
                    text: 'entities.lead.state.archived',
                    value: 'archived',
                },
                {
                    text: 'entities.lead.state.canceled',
                    value: 'canceled',
                },
            ],
        },
    },
    name: {
        component: 'InputText',
        props: {
            rules: ['noEmpty'],
            class: '-third-width',
            labelTrans: 'entities.lead.fields.name',
            errors: [],
        },
    },
    email: {
        component: 'InputText',
        props: {
            rules: ['email', 'noEmpty'],
            class: '-third-width',
            labelTrans: 'entities.lead.fields.email',
            errors: [],
        },
    },
    phone: {
        component: 'InputText',
        props: {
            rules: ['phone', 'noEmpty'],
            class: '-third-width',
            labelTrans: 'entities.lead.fields.phone',
            errors: [],
        },
    },

    comment: {
        component: 'Textarea',
        props: {
            labelTrans: 'entities.lead.fields.comment',
            errors: [],
        },
    },
});
