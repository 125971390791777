<template>
    <div class="button-toggles">
        <label class="button-toggles__label" v-for="(item, index) in items" :key="index" :for="labelId(item)">
            <input type="radio" :id="labelId(item)" :name="name + '-' + id" :value="item.value" v-model="model" />
            <span class="button-toggles__btn">
                <span v-if="item.icon" class="material-icons">{{ item.icon }}</span>
                <span v-if="item.text">{{ $t(item.text) }}</span>
            </span>
        </label>
    </div>
</template>

<script>
import { proxyModelMixin } from '@/mixins/proxyModelMixin';
import { uuid } from 'vue-uuid';

export default {
    name: 'ButtonToggles',
    inheritAttrs: false,
    mixins: [proxyModelMixin],
    props: {
        name: { type: String, default: '' },
        group: { type: String, default: null },
        items: { type: [Array], default: () => [] },
        placeholder: { type: String, default: 'Choose' },
        errors: { type: [Array, Object], default: () => [] },
        value: { type: [String, Number, Object, Date] },
    },
    data() {
        return {
            id: uuid.v1(),
        };
    },
    computed: {},
    watch: {},
    methods: {
        labelId: function(item) {
            return this.id + '-' + item.text + '-' + item.icon;
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.button-toggles {
    display: flex;
    border: 1px solid $form-control-border-color;
    border-radius: 4px;
    overflow: hidden;
    &__label {
        display: block;
        flex: 1;
        cursor: pointer;
    }
    &__btn {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        text-align: center;
        line-height: $form-control-height;
        cursor: pointer;
        border-left: 1px solid $form-control-border-color;
        transition: $transition-fast;
        pointer-events: none;
        background: #ffffff;

        &:hover {
            color: $text-inverted;
            background: $primary-hover;
            border-left: 1px solid $primary-hover;
        }

        .material-icons {
        }
    }
    &__label:first-child &__btn {
        border-left: none !important;
    }
    input {
        display: none;
    }
    input:checked + &__btn {
        color: $text-inverted;
        background: $primary;
        border-left: 1px solid $primary;
    }
}
</style>
