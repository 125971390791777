<template>
    <div class="info-card">
        <div class="info-card__body">
            <div class="info-card__icon-container">
                <span class="material-icons-outlined" :class="className ? `-font-${className}` : null">{{ icon }}</span>
                <div class="info-card__icon-overlay" :class="className ? `-bg-${className}` : null"></div>
            </div>
            <div class="info-card__text-container">
                <p class="info-card__text">{{ text }}</p>
                <span class="info-card__difference" :class="isHigher ? '-font-success' : '-font-warning'">
                    <span class="-font-success material-icons-outlined" v-if="isHigher">expand_less</span>
                    <span class="-font-warning material-icons-outlined" v-else>expand_more</span>
                    {{ difference }}</span
                >
            </div>
        </div>
        <h3 class="info-card__value">{{ value }}</h3>
        <div class="info-card__overlay" :class="className ? `-bg-${className}` : null"></div>
    </div>
</template>

<script>
export default {
    name: 'DashboardInfoCard',
    props: {
        difference: {
            type: String,
            default: '',
        },
        text: {
            type: String,
            default: '',
        },
        value: {
            type: String,
            default: '',
        },
        icon: {
            type: String,
            default: '',
        },
        className: {
            type: String,
            default: '',
        },
        isHigher: {
            type: Boolean,
            default: true,
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.info-card {
    position: relative;
    width: calc(100% / 3);
    padding: 24px 16px 24px 24px;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    background: var(--v-surface-base);
    border-radius: 4px;
    &__body {
        display: flex;
        gap: 20px;
        z-index: 2;
    }
    &__overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var(--v-surface-base);
        opacity: 0.04;
    }
    &__icon-container {
        position: relative;
        width: 52px;
        height: 52px;
        flex-shrink: 0;
        border-radius: 100%;
        background: var(--v-surface-base);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &__icon-overlay {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0.1;
        width: 100%;
        height: 100%;
        border-radius: 100%;
        background-color: var(--v-surface-base);
    }
    &__value {
        z-index: 2;
        font-weight: 500;
        font-size: 23px;
        position: relative;
        bottom: 0;
        align-self: flex-end;
        margin-bottom: 0;
        flex-basis: 100%;
    }
    &__text {
        font-weight: 500;
        font-size: 16px;
        margin-bottom: 6px !important;
        color: var(--v-on-surface-medium-base);
    }
}
</style>
