import { validateNoEmpty } from '@/helpers/validator';
import store from '@/store/store';
export default (ctx) => {
    const publicUrl = `https://${store.getters['globalVars/getInstanceFull']?.primaryDomain}`;
    return {
        from: {
            component: 'input-text',
            props: {
                labelTrans: 'settings.seo.redirects.fromUrl',
                rules: { noEmpty: true },
                errors: [],
            },
            events: {
                input(val) {
                    const isFull = val.startsWith('http') || val.includes(publicUrl.split('//')[1]);
                    if (isFull) {
                        ctx.from = val.replace(/^(?:\/\/|[^/]+)*/, '');
                    }
                },
            },
        },
        to: {
            component: 'input-text',
            props: {
                labelTrans: 'settings.seo.redirects.toUrl',
                rules: {
                    atLeastOne: { onlyIfNo: 'name' },
                },
                errors: [],
            },
            events: {
                input(val) {
                    const isFull = val.startsWith('http') || val.includes(publicUrl.split('//')[1]);
                    if (isFull) {
                        ctx.to = val.replace(/^(?:\/\/|[^/]+)*/, '');
                    }
                },
            },
        },
        id: {
            component: 'Select',
            props: {
                group: 'subject',
                rules: {
                    noEmpty: { onlyIf: 'name' },
                },
                errors: [],
            },
        },
        name: {
            component: 'search-form',
            props: {
                group: 'subject',
                labelTrans: 'settings.seo.redirects.toObject',
                errors: [],
            },
            events: {
                change(text) {
                    console.log(text, ctx);
                },
            },
        },
    };
};

export function validateField(key, fields, form) {
    const item = fields[key];
    const group = item.props.group || null;
    const value = group ? form[group][key] : form[key];
    const rules = item.props?.rules;
    if (!rules || rules.length === 0) return [];
    const errors = [];

    for (const [rule, options] of Object.entries(item.props.rules)) {
        if (options.onlyIf && !form.subject[options.onlyIf]) continue;
        if (options.onlyIfNo && form.subject[options.onlyIfNo]) continue;
        switch (rule) {
            case 'noEmpty': {
                if (!validateNoEmpty(value)) errors.push('validator.errors.empty');
                break;
            }
            case 'atLeastOne': {
                if (!validateNoEmpty(value)) errors.push('validator.errors.atLeastOne');
                break;
            }
        }
    }
    return errors;
}
