import { Container, Content, Heading, Section } from '@/entities/section/samples/_options';

const section = {
    id: '',
    type: 'SectionTextBlockStyle2',
    required: false,
    group: 'TextBlocks',
    options: {
        section: { ...Section },
        container: { ...Container },
        heading: { ...Heading },
        content: {
            html: { ...Content.html },
        },
        // background: { ...Background },
        grid: {
            type: 'field',
            value: {},
            component: 'CustomizeColumnsTabs',
            ...{
                props: {
                    screen: ['desktop', 'tablet'], // 'desktop', 'tablet', 'mobile'
                },
            },
        },
    },
    items: [],
    children: null,
};

export default section;
