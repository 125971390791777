<template>
    <div class="table__wrapper">
        <div class="table">
            <slot name="block-header"></slot>
            <slot name="toolbar" v-if="showToolbar">
                <div class="table__toolbar" :class="{ '-large': !showSelectedActions }">
                    <search-form
                        only-model
                        v-model="searchString"
                        @update-search-string="handleSearchString"
                        v-if="localSearch"
                    >
                    </search-form>
                    <search-form
                        :pagination="pagination"
                        v-else-if="search"
                        :label="search.label"
                        :searchable="search.searchable"
                        :q="search.q"
                        only-model
                        @update-search-string="handleSearchString"
                    ></search-form>
                    <div v-if="itemsInfo" class="table__items-info">
                        <span v-for="item in itemsInfo" :key="item.title"> {{ item.title }}: {{ item.value }} </span>
                    </div>
                    <slot name="header-select-prepend"></slot>
                    <Select
                        v-if="pagination"
                        class="table__per-page"
                        :items="pagination.options"
                        translated
                        v-model="perPageModel"
                    ></Select>
                </div>
            </slot>
            <div class="table__selected-actions" v-if="showSelectedActions">
                <slot name="remove-selected">
                    <Button
                        v-if="model.length"
                        icon="delete"
                        type="text"
                        @click="removeSelected"
                        class="table__selected-action"
                    >
                        {{ $t('lists.actions.deleteSelected') }}</Button
                    >
                </slot>

                <slot name="selected-actions"></slot>
                <slot name="remove-all" v-if="showRemoveAll">
                    <Button
                        v-if="model.length"
                        icon="delete_forever"
                        type="text"
                        @click="removeAll"
                        class="table__selected-action delete-all-button"
                    >
                        {{ $t('lists.actions.deleteAll') }}</Button
                    >
                </slot>
            </div>
            <div class="table-body__wrapper">
                <table class="table-body">
                    <thead>
                        <tr>
                            <th v-if="draggable"></th>
                            <th v-if="showSelect" class="table__checkbox-column">
                                <input-checkbox
                                    v-if="showSelectAll"
                                    v-model="model"
                                    :items="items"
                                    @input="toggleSelectAll"
                                    multiple
                                ></input-checkbox>
                            </th>
                            <th
                                v-for="(column, index) in columns"
                                :class="{ '-sortable': column.sortable }"
                                :style="{ width: column.width }"
                                @click="handleSort(column)"
                                :key="index"
                            >
                                <template v-if="hasHeaderSlot(column.value)">
                                    <slot @click.prevent.stop :name="`header.${column.value}`" />
                                </template>
                                <template v-else>{{ column.text }}</template>
                                <span
                                    class="icon table__sort-icon"
                                    :class="{
                                        '-active': sortBy === column.value,
                                        '-asc': sortBy === column.value && sortDesc === false,
                                    }"
                                    v-if="column.sortable"
                                    >sort</span
                                >
                            </th>
                        </tr>
                        <table-loader :is-data-loaded="isDataLoaded" :colspan="loaderColspan"></table-loader>
                    </thead>
                    <draggable
                        ghost-class="ghost"
                        tag="tbody"
                        drag-class="ghost"
                        :animation="200"
                        v-if="dragItems.length"
                        chosen-class="ghost"
                        @start="handleDragStart"
                        @end="handleDragEnd"
                        :move="handleDragMove"
                        :disabled="!draggable"
                        v-model="dragItems"
                        handle=".table-body__draggable-icon"
                    >
                        <!--                    TODO: придумать что-то с key-->
                        <template v-for="(item, itemIndex) in dragItems">
                            <slot
                                @click.prevent.stop
                                name="item"
                                :item="item"
                                :is-selected="selected[itemIndex]"
                                :handle-checkbox="handleCheckbox"
                                :item-index="itemIndex"
                            >
                                <tr
                                    @click.prevent.stop
                                    class="table__row"
                                    :class="{ '-selected': selected[itemIndex] }"
                                    :key="`${item.id}-${itemIndex}`"
                                >
                                    <td v-if="draggable" class="table-body__draggable-cell">
                                        <span class="material-icons-outlined table-body__draggable-icon"
                                            >drag_indicator</span
                                        >
                                    </td>
                                    <td
                                        v-if="showSelect"
                                        :style="nested && { 'padding-left': `${12 + 25 * item.depth}px !important` }"
                                    >
                                        <span
                                            :style="nested && { left: `${12 + 25 * (item.depth - 1)}px` }"
                                            class="material-icons-outlined depth-icon"
                                            v-if="item.depth && dragItems[itemIndex - 1].depth !== item.depth"
                                            >shortcut</span
                                        >
                                        <input-checkbox
                                            :value="selected[itemIndex]"
                                            @input="handleCheckbox(item, itemIndex)"
                                        ></input-checkbox>
                                    </td>
                                    <td
                                        v-for="column of columns"
                                        :style="{ width: column.width, minWidth: column.width }"
                                        :key="`${column.value}-${itemIndex}`"
                                        @click.stop
                                        :class="column.class"
                                    >
                                        <template v-if="hasSlot(column.value)">
                                            <slot @click.prevent.stop :name="`item.${column.value}`" :item="item" />
                                        </template>
                                        <template v-else>{{ getItemValue(item, column.value) || '—' }}</template>
                                    </td>
                                </tr>
                            </slot>
                        </template>
                    </draggable>
                </table>
                <div class="table__no-items-found" v-if="!isDataLoaded && !dragItems.length">
                    {{ $t('lists.loadingItems') }}
                </div>
                <div class="table__no-items-found" v-if="isDataLoaded && !dragItems.length">
                    {{ $t('lists.noItems') }}
                </div>
            </div>
            <slot name="footer"></slot>
        </div>
        <page-pagination class="table__pagination" v-if="pagination" :pagination="pagination"></page-pagination>
    </div>
</template>

<script>
import InputCheckbox from '@/components/form/controls/InputCheckbox';
import draggable from 'vuedraggable';
import { proxyModelMixin } from '@/mixins/proxyModelMixin';
import { cloneDeep, isEqual } from 'lodash';
import SearchForm from '@/components/search/SearchForm';
import PagePagination from '@/components/pagination/PagePagination';
import Button from '@/components/common/Button';
import TableLoader from '@/components/common/TableLoader';
import { flattenArrayOfNestedObjects } from '@/helpers/utils';
export default {
    name: 'Table',
    components: { TableLoader, InputCheckbox, draggable, PagePagination, SearchForm, Button },
    mixins: [proxyModelMixin],
    props: {
        columns: {
            type: Array,
            default: () => [],
        },
        items: {
            type: Array,
            default: () => [],
        },
        isDataLoaded: {
            type: Boolean,
            default: true,
        },
        showSelect: {
            type: Boolean,
            default: false,
        },
        draggable: {
            type: Boolean,
            default: false,
        },
        showSelectAll: {
            type: Boolean,
            default: true,
        },
        localSearch: {
            type: Boolean,
            default: false,
        },
        localSort: {
            type: Boolean,
            default: false,
        },
        pagination: {
            type: [Object, Boolean],
            default: null,
        },
        itemsInfo: {
            type: Array,
            default: null,
        },
        value: {
            type: Array,
            default: () => [],
        },
        search: {
            type: Object,
        },
        showToolbar: {
            type: Boolean,
            default: true,
        },
        showSelectedActions: {
            type: Boolean,
            default: true,
        },
        showRemoveAll: {
            type: Boolean,
            default: false,
        },
        sortBy: {
            type: [String, null],
            default: null,
        },
        sortDesc: {
            type: [Boolean, null],
            default: null,
        },
        multiplePageSelection: {
            type: Boolean,
            default: false,
        },
        nested: {
            type: Boolean,
            default: false,
        },
        childrenProperty: {
            type: String,
            default: 'children',
        },
    },
    data() {
        return {
            drag: false,
            dragItems: [],
            dragEvent: null,
            draggedCopy: null,
            searchString: '',
            selected: {},
        };
    },
    computed: {
        loaderColspan() {
            let colSpan = this.columns.length;
            if (this.showSelect) {
                colSpan += 1;
            }
            if (this.draggable) {
                colSpan += 1;
            }
            return colSpan;
        },
        areAllSelected() {
            return this.model.length === this.items.length;
        },
        selectedArr() {
            return Object.values(this.model).filter((item) => item);
        },
        passedHeadersTitles() {
            const map = {};
            this.columns.forEach((item) => {
                map[item.value] = true;
            });
            return map;
        },
        activeSearchString() {
            //для единообразного поведения между таблицами с поиском на бэке и на фронте
            //у обоих вариантов поиск производится при вводе 3 или более символов
            return this.searchString.length >= 3 ? this.searchString : null;
        },
        perPageModel: {
            get() {
                return this?.pagination.limit;
            },
            set(val) {
                const newValue = cloneDeep(this.pagination.options).find((item) => item.value === val);
                this.$emit('change-per-page', newValue);
            },
        },
    },
    methods: {
        handleCheckbox(item, index) {
            const value = !this.selected[index];
            console.log(value);
            if (this.nested) {
                if (item.children?.length) {
                    const childrenIds = [];
                    this.selected[index] = value;
                    flattenArrayOfNestedObjects(item.children).forEach((child) => {
                        childrenIds.push(child.id);
                        const index = this.items.findIndex((item) => item.id === child.id);
                        this.selected[index] = value;
                    });
                    const children = this.items.filter((item) => childrenIds.includes(item.id));
                    this.model = value
                        ? [...this.model, item, ...children]
                        : this.model.filter((modelItem) => {
                              return modelItem.id !== item.id && !childrenIds.includes(modelItem.id);
                          });
                    console.log(this.model);
                }
            }
            if (!this.nested || (this.nested && !item.children?.length)) {
                this.selected[index] = value;
                this.model = value ? [...this.model, item] : this.model.filter((modelItem) => modelItem.id !== item.id);
            }
            if (this.nested && !value && item.parentId) {
                this.$nextTick(() => this.unselectParents(item));
            }
            this.$emit('select-item', { item, value });
        },
        unselectParents(child) {
            console.log(this.model);
            let indexOfParent;
            const parent = this.items.find((item, index) => {
                if (item.id === child.parentId) {
                    indexOfParent = index;
                    return true;
                }
            });
            this.selected[indexOfParent] = false;
            this.model = this.model.filter((modelItem) => modelItem.id !== parent.parentId);
            if (parent.parentId) this.unselectParents(parent);
        },
        toggleSelectAll(value) {
            //приходится делать локальную areAllSelected, потому что изменение модели отрабатывает после вызова этого обработчика
            const areAllSelected = value.length === this.items.length;
            this.$emit('select-all', areAllSelected);
            this.selected = areAllSelected ? this.getAllToggled(true) : this.getAllToggled(false);
        },
        hasSlot(value) {
            return !!this.$scopedSlots[`item.${value}`];
        },
        hasHeaderSlot(value) {
            return !!this.$scopedSlots[`header.${value}`];
        },
        getItemValue(item, valueName) {
            if (valueName.includes('.')) {
                let val = item;
                const keys = valueName.split('.');
                keys.forEach((key) => {
                    val = val?.[key];
                });
                return val;
            } else {
                return item[valueName];
            }
        },
        getAllToggled(value) {
            return this.items.reduce((prev, item, index) => {
                prev[index] = value;
                return prev;
            }, {});
        },
        handleDragEnd(event) {
            this.drag = false;
            this.$emit('drag-end', { event, items: this.dragItems });
        },
        handleDragStart(event) {
            this.drag = true;
            this.$emit('drag-start', { event, items: this.dragItems });
        },
        handleDragMove(event) {
            this.drag = false;
            this.$emit('drag-move', { event, items: this.dragItems });
        },
        handleSearchString(val) {
            if (this.localSearch) {
                this.searchInTable(val);
            }
            this.$emit('update-search-string', val);
        },
        searchInTable(val) {
            const searchString = val.searchString.toLowerCase();
            this.dragItems = this.items.filter((item) => {
                const data = item.data ? item.data : item;
                const values = Object.values(data);
                return values.some((value) => {
                    if (typeof value === 'string') {
                        return value.toLowerCase().includes(searchString);
                    } else return false;
                });
            });
        },
        removeSelected() {
            this.$emit('delete-selected-items', this.model);
        },
        removeAll() {
            this.$emit('delete-all-items');
        },
        handleSort(column) {
            if (!column.sortable) return;
            if (this.sortBy !== column.value) {
                this.$emit('update:sort', column.value, true);
            } else if (this.sortDesc === true) {
                this.$emit('update:sort', column.value, false);
            } else {
                this.$emit('update:sort', null, null);
            }

            if (this.localSort) {
                this.$nextTick(() => {
                    this.sortItems();
                });
            }
        },
        sortItems() {
            if (!this.sortBy) {
                this.dragItems = this.items;
                return;
            }
            const areNumbers = this.dragItems.every((item) => !isNaN(Number(item[this.sortBy])));
            if (areNumbers) {
                this.dragItems.sort((a, b) => {
                    const valA = a[this.sortBy];
                    const valB = b[this.sortBy];
                    return this.sortDesc ? valB - valA : valA - valB;
                });
            } else {
                this.dragItems.sort((a, b) => {
                    const valA = a[this.sortBy];
                    const valB = b[this.sortBy];
                    if (!valA) return this.sortDesc ? 1 : -1;
                    else if (!valB) return this.sortDesc ? -1 : 1;
                    const strA = String(valA).toLowerCase();
                    const strB = String(valB).toLowerCase();
                    if (this.sortDesc) {
                        return strA > strB ? -1 : 1;
                    } else {
                        return strA > strB ? 1 : -1;
                    }
                });
            }
        },
    },
    watch: {
        items: {
            handler(val) {
                if (!isEqual(val, this.dragItems)) {
                    this.dragItems = cloneDeep(val);
                    this.selected = this.getAllToggled(false);
                    if (this.multiplePageSelection) {
                        this.model.forEach((selectedItem) => {
                            const index = val.findIndex((item) => item.id === selectedItem.id);
                            if (index !== -1) {
                                this.selected[index] = true;
                            }
                        });
                    } else {
                        this.model = [];
                    }
                    if (this.localSort && this.sortBy && this.sortDesc !== null) {
                        this.sortItems();
                    }
                }
            },
            immediate: true,
            deep: true,
        },
        value: {
            handler(val) {
                if (!val.length) {
                    this.selected = this.getAllToggled(false);
                }
            },
            deep: true,
            immediate: true,
        },
        dragItems: {
            handler(val) {
                if (!isEqual(val, this.items)) {
                    this.$emit('update:items', this.dragItems);
                }
            },
            deep: true,
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.table-body {
    width: 100%;
    &__wrapper {
        width: 100%;
        overflow-x: auto;
        overflow-y: hidden;
    }
    .checkbox__icon {
        font-size: 24px;
    }
    .ghost {
        cursor: grabbing !important;
        /*   ;
        border: 4px solid var(--v-surface-base);*/
        transform: scale(1.025) !important;
        box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.1);
        transition: transform 0.2s;
        z-index: 300;
        background-color: var(--v-primary-lighten-base);
    }
    tr {
        border-bottom: 0.8px solid;
        border-color: rgba(0, 0, 0, 0.12);
    }
    td {
        vertical-align: middle;
        padding: 12px;
        position: relative;
        .depth-icon {
            transform: rotate(270deg);
            position: absolute;
            bottom: 20px;
            font-size: 24px;
            color: var(--v-on-surface-medium-base);
        }
    }
    tbody {
        tr:first-child {
            .table-body__draggable-cell {
                width: 1%;
                white-space: nowrap;
            }
        }
    }
    tr:last-child {
        border: none;
    }
    th {
        padding: 0 12px 6px 12px !important;
        white-space: pre-wrap;
        text-align: left;
        &.-sortable {
            white-space: nowrap;
        }
    }
    &__draggable-icon {
        font-size: 18px !important;
        display: block;
        cursor: grab;
        &:active {
            cursor: grabbing;
        }
    }
}
table {
    th.table-loader__cell {
        padding: 0 !important;
        height: 5px;
    }
    border-collapse: collapse;
}
.table-loader {
    border-bottom: 0.8px solid rgba(0, 0, 0, 0.12) !important;
    &__body {
        width: 100%;
        height: 5px;
        background-color: var(--v-primary-lighten-base);
        position: relative;
        z-index: 5;
        overflow: hidden;
        &:before {
            content: '';
            position: absolute;
            height: 100%;
            background-color: var(--v-primary-base);
            animation: indeterminate_first 1.5s infinite ease-out;
        }

        &:after {
            content: '';
            position: absolute;
            height: 100%;
            background-color: var(--v-primary-base);
            animation: indeterminate_second 1.5s infinite ease-in;
        }
    }

    @keyframes indeterminate_first {
        0% {
            left: -100%;
            width: 100%;
        }
        100% {
            left: 100%;
            width: 10%;
        }
    }

    @keyframes indeterminate_second {
        0% {
            left: -150%;
            width: 100%;
        }
        100% {
            left: 100%;
            width: 10%;
        }
    }
}
</style>
