// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/background/error-bg.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Inter:300,400,500,600,700);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".error-box{height:100%;width:100%;text-align:center;background-position:top;background-repeat:no-repeat;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.error-title{font-size:110px;font-weight:900;text-shadow:4px 4px 0 #fff,6px 6px 0 #343a40;line-height:110px}.error-subtitle,.error-title{text-align:center}@media(max-width:991px){.error-title{font-size:70px;line-height:80px}}@media(max-width:767px){.error-title{font-size:20px;line-height:25px}}", ""]);
// Exports
module.exports = exports;
