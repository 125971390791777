export function initItems(state) {
    return {
        domain: {
            component: 'InputText',
            type: 'field',
            value: state?.domain.value,
            props: {
                labelTrans: 'settings.bitrix.domain',
            },
        },
        userId: {
            component: 'InputText',
            type: 'field',
            value: state?.userId.value,
            props: {
                labelTrans: 'settings.bitrix.userId',
            },
        },
        secret: {
            component: 'InputText',
            type: 'field',
            value: state?.secret.value,
            props: {
                labelTrans: 'settings.bitrix.secret',
            },
        },
        enabled: {
            component: 'InputSwitch',
            type: 'field',
            value: state?.enabled.value,
            props: {
                labelTrans: 'settings.bitrix.enabled',
                noLabel: true,
            },
        },
    };
}
