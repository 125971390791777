<template>
    <div class="page-status">
        <Select v-model="status" @change="onChange(value)" v-bind="statusProps" :label="label" />
    </div>
</template>

<script>
import { proxyModelMixin } from '@/mixins/proxyModelMixin';
import Select from '@/components/form/controls/Select';

const pageStatusMap = {
    turned_off: { enabled: false, hidden: true, noIndex: true },
    hidden_no_indexed: { enabled: true, hidden: true, noIndex: true },
    hidden_indexed: { enabled: true, hidden: true, noIndex: false },
    open_no_indexed: { enabled: true, hidden: false, noIndex: true },
    open_indexed: { enabled: true, hidden: false, noIndex: false },
};

export default {
    name: 'PageStatus',
    components: { Select },
    props: {
        label: {
            type: String,
        },
        name: {
            type: String,
        },
        value: {
            type: [Object],
        },
    },
    data() {
        return {
            renderComponent: true,
            status: null,
        };
    },

    computed: {
        statusProps() {
            return {
                items: Object.keys(pageStatusMap).map((key) => ({
                    text: this.$t(`builder.pageStatus.${key}`),
                    value: key,
                })),
            };
        },
    },

    created() {
        this.status = this.getStatusByValues(this.value.enabled, this.value.hidden, this.value.noIndex);
    },
    mixins: [proxyModelMixin],
    methods: {
        getStatusByValues(enabled, hidden, noIndex) {
            let result = null;
            Object.keys(pageStatusMap).forEach((status) => {
                if (
                    pageStatusMap[status].enabled === enabled &&
                    pageStatusMap[status].hidden === hidden &&
                    pageStatusMap[status].noIndex === noIndex
                ) {
                    result = status;
                }
            });
            return result;
        },
        setStatusByKey(key) {
            this.value.enabled = pageStatusMap[key].enabled;
            this.value.hidden = pageStatusMap[key].hidden;
            this.value.noIndex = pageStatusMap[key].noIndex;
        },
        onChange(value) {
            this.$emit('change', value);
        },
    },
    watch: {
        value: function() {
            this.status = this.getStatusByValues(this.value.enabled, this.value.hidden, this.value.noIndex);
        },
        status: function(newValue) {
            this.setStatusByKey(newValue);
        },
    },
};
</script>

<style lang="scss">
.page-status {
    display: block;
}
</style>
