<template>
    <div class="image-manager  -infinite">
        <product-image-properties-modal
            @update-image-props="updateImageProps"
            :image="editingImage"
            v-model="isImagePropsModalOpen"
        />
        <div infinite-wrapper class="image-manager__content -full">
            <div class="image-manager__dropzone">
                <dropzone @change="uploadImage" class="image-manager__image" :loading="isUploading">
                    <template v-slot:text>
                        {{ $t('entities.image.formats') }}, <br />
                        {{ $t('entities.image.resolution') }}, <br />
                        {{ $t('entities.image.size') }}
                    </template>
                </dropzone>
            </div>
            <Img
                :controls="true"
                :image="image"
                v-for="image in images"
                :key="image.id"
                :data-id="image.id"
                :selected="value && image.id === value.id && selectable"
                @edit-image="openImagePropsModal(getEntityImage($event))"
                @select-image="selectImage"
                @delete-image="openDeletionModal"
            ></Img>
            <infinite-loading :forceUseInfiniteWrapper="true" @infinite="getImages">
                <div slot="spinner"></div>
                <div slot="no-results"></div>
                <div slot="no-more"></div>
            </infinite-loading>
        </div>
    </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import ImageService from '@/services/ImageService';
import Image from '@/entities/image/Image';
import { proxyModelMixin } from '@/mixins/proxyModelMixin';
import Img from '@/components/common/Img';
import ProductImagePropertiesModal from '@/components/product/ProductImagePropertiesModal';
import InfiniteLoading from 'vue-infinite-loading';
import { imageManagerMixin } from '@/mixins/imageManagerMixin';
import EntityImage from '@/entities/image/EntityImage';
import EventEmitter from '@/helpers/eventEmitter.ts';
import Dropzone from '@/components/common/Dropzone';

export default {
    name: 'InfiniteImageManager',
    components: { Dropzone, InfiniteLoading, Img, ProductImagePropertiesModal },

    created() {
        EventEmitter.on('delete-image', this.deleteImage);
    },

    async mounted() {
        if (this.$refs.images?.$el?.clientHeight > 300) {
            this.hasMore = true;
        }
    },

    mixins: [proxyModelMixin, imageManagerMixin],

    props: {
        value: {
            type: Object,
            default: () => new Image(),
        },
        imageType: {
            type: String,
            default: 'content',
        },
        controls: {
            type: Boolean,
            default: true,
        },
        selectable: {
            type: Boolean,
            default: false,
        },
        infinite: {
            type: Boolean,
            default: false,
        },
        ownerId: {
            type: String,
            default: null,
        },
    },

    data() {
        return {
            images: [],
            page: 1,
            offset: 0,
            limit: 20,
        };
    },

    methods: {
        async getImages($state) {
            this.isLoading = true;
            if (!this.images.length && this.value) this.images.push(cloneDeep(this.value));
            const [error, result] = await ImageService.getAll({
                type: this.imageType,
                offset: this.offset,
                limit: this.limit,
                notIds: [this.value?.id],
                ownerId: this.ownerId,
            });
            if (error) {
                error.notify();
            }
            if (result.length) {
                const images = result.map((item) => new Image(item));
                this.images = [...this.images, ...images];
                this.isLoading = false;
                this.page += 1;
                this.offset += this.limit;
                $state.loaded();
            } else {
                this.isLoading = false;
                $state.complete();
            }
        },

        getEntityImage(image) {
            return new EntityImage({ file: image });
        },

        openDeletionModal(items) {
            this.$store.dispatch('modals/openDeletionModal', { items, entity: 'image' });
        },
        async deleteImage([image]) {
            const [error] = await ImageService.removeBatch([image.id]);
            if (error) {
                error.notify();
                return;
            }
            const index = this.images.findIndex((item) => item.id === image.id);
            this.images.splice(index, 1);
            this.$store.dispatch('modals/closeDeletionModal');
            EventEmitter.trigger('show-noty', {
                type: 'success',
                text: this.$tc('notifications.deleted', 1, { entity: this.$tc('entities.image.title', 1) }),
            });
            this.$emit('delete-image', image);
        },
        async updateImageProps(image) {
            const [error, result] = await ImageService.updateImageProps(image.id, image);
            if (error) {
                error.notify();
                return;
            }
            const index = this.images.findIndex((item) => item.id === image.id);
            const newImage = new Image(result);
            this.images.splice(index, 1, newImage);
            this.isImagePropsModalOpen = false;
        },
        async uploadImage(files) {
            this.isUploading = true;
            const [error, result] = await ImageService.uploadOne(this.imageType, files[0]);
            if (error) {
                error.notify();
                this.isUploading = false;
                return;
            }
            const image = new Image(result);
            const searchElement = this.images.find((item) => item.id === image.id);
            if (!searchElement) {
                this.images.unshift(image);
            }
            this.isUploading = false;
            if (this.offset) {
                this.offset += 1;
            }
        },
    },
    beforeDestroy() {
        EventEmitter.off('delete-image', this.deleteImage);
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
/* внутренний класс vue-infinite-loader
если в последней строке равное количество элементов, у
этого элемента ширина становится равной 0 и событие infinite перестаёт срабатывать*/
.infinite-loading-container {
    min-width: 150px;
}
.image-manager {
    &.-infinite {
        & .image-manager__content {
            display: flex;
            flex-wrap: wrap;
            overflow: auto !important;
            height: 380px !important;
            gap: 24px !important;
        }
        & .image-manager__image {
            width: 150px !important;
            height: 150px !important;
        }
        & .image__image {
            width: 150px !important;
            height: 150px !important;
        }
        & .image__controls {
            justify-content: flex-end;
            width: 150px !important;
        }
        .image__controls-btn:nth-child(2) {
            margin-left: 0;
        }
    }
}
</style>
