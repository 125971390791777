import EventEmitter from '@/helpers/eventEmitter';
import { IEventEmitter } from '@/helpers/eventEmitter';

class LocalStorageService {
    eventEmitter: IEventEmitter;
    constructor() {
        this.eventEmitter = EventEmitter;

        this.handleStore = this.handleStore.bind(this);
        window.addEventListener('storage', this.handleStore);
    }

    getItem(key: string): any {
        return window.localStorage.getItem(key);
    }

    setItem(key: string, value: any): void {
        const oldValue = this.getItem(key);
        if (oldValue === value) return;

        window.localStorage.setItem(key, value);
        this.eventEmitter.trigger(key, value);
    }

    subscribe(key: string, callback: Function): () => void {
        this.eventEmitter.on(key, callback);
        return () => this.eventEmitter.off(key, callback);
    }

    handleStore(event: StorageEvent): void {
        if (event.key) this.eventEmitter.trigger(event.key, event.newValue);
    }
}

export const localStorageService: LocalStorageService = new LocalStorageService();
