export default {
    // TEXT
    // default
    text_main: '#020305',
    text_body1: '#2F3440',
    text_body2: '#4A5264',
    text_accent: '#2553B0',
    text_neutral: '#7A8399',
    // inverted
    text_main_inverted: '#FFFFFF',
    text_body1_inverted: '#C7CCD6',
    text_body2_inverted: '#A2A9BA',
    text_accent_inverted: '#6F96E5',
    text_neutral_inverted: '#B3B9C7',

    // ACTION
    // primary
    action_primary: '#2553B0',
    action_primary_hover: '#4171D9',
    action_primary_pressed: '#1D3B7A',
    action_primary_disabled: '#B0BBD1',
    // secondary
    action_secondary: '#6791E5',
    action_secondary_hover: '#73A2FF',
    action_secondary_pressed: '#4772C9',
    action_secondary_disabled: '#D3D7E5',
    // neutral_light
    header_bg: '#FFFFFF',
    header_bg_mobile: '#2D323D',
    header_main_menu_bg: '#2D323D',
    header_main_menu_dropdown_bg: '#3D4352',
    action_neutral_light: '#FFFFFF',
    // action_neutral_light_hover: '#A2C1FF',
    // action_neutral_light_pressed: '#638DE0',
    action_neutral_light_hover: '#4772C9',
    action_neutral_light_pressed: '#73A2FF',
    action_neutral_light_disabled: '#838AA3',
    // neutral_medium
    action_neutral_medium: '#707D99',
    action_neutral_medium_hover: '#879ECC',
    action_neutral_medium_pressed: '#536280',
    action_neutral_medium_disabled: '#D3D7E5',
    // neutral_dark
    action_neutral_dark: '#4A5466',
    action_neutral_dark_hover: '#4D6399',
    action_neutral_dark_pressed: '#2F3440',
    action_neutral_dark_disabled: '#6E758A',
    // system
    action_system: '#D92B55',
    action_system_hover: '#F73160',
    action_system_pressed: '#C40C37',
    action_system_disabled: '#B0BBD1',

    // NEUTRAL
    neutral1: '#22262E',
    neutral2: '#2D323D',
    neutral3: '#3D4352',
    neutral4: '#B3B9C7',
    neutral5: '#DADDE4',
    neutral6: '#F5F8FB',
    neutral7: '#FFFFFF',

    // SYSTEM
    success: '#00B489',
    success_bg: '#EDFAF6',
    warning: '#F7BA40',
    warning_bg: '#FFF8EB',
    error: '#F9416D',
    error_bg: '#FFF1F4',

    // ACCENT
    accent1: '#F57C00',
    accent1_bg: '#FFEDDB',
    accent2: '#F9B100',
    accent2_bg: '#FEF3D7',
    accent3: '#5285EC',
    accent3_bg: '#E8EFFF',
    accent4: '#7D76DF',
    accent4_bg: '#E7E6F9',

    whatsapp: '#20B051',
};
