import { uuid } from 'vue-uuid';
import initFields from '@/entities/lead/Lead.fields';

export default class Lead {
    fields = initFields();
    constructor({
        id = uuid.v4(),
        number = '',
        type = '',
        name = '',
        phone = '',
        email = '',
        comment = '',
        product = '',
        manager = '',
        state = '',
        text = '',
        createdAt = '',
    } = {}) {
        this.id = id;
        this.number = number;
        this.type = type;
        this.name = name;
        this.phone = phone;
        this.email = email;
        this.comment = comment;
        this.product = product;
        this.manager = manager;
        this.state = state;
        this.text = text;
        this.createdAt = createdAt;
    }

    get data() {
        return {
            id: this.id,
            type: this.type,
            product: this.product,
            name: this.name,
            phone: this.phone,
            email: this.email,
            comment: this.comment,
            manager: this.manager,
            state: this.state,
            text: this.text,
        };
    }
}
