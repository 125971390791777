import { cloneDeep } from 'lodash';
import { Container, Section, Content, SectionItemsStyle } from '@/entities/section/samples/_options';

const section = {
    id: '',
    type: 'SectionSliderBase',
    required: false,
    group: 'Slider',
    options: {
        section: { ...Section },
        container: {
            width: {
                ...Container.width,
                value: 'full',
            },
            padding: {
                ...Container.padding,
                value: {
                    desktop: { top: 0, right: 0, bottom: 0, left: 0 },
                    tablet: { top: 0, right: 0, bottom: 0, left: 0 },
                    mobile: { top: 0, right: 0, bottom: 0, left: 0 },
                },
            },
        },
        content: {
            heightSlide: {
                component: 'InputRangeScreenBuilder',
                value: {
                    desktop: 494,
                    tablet: 420,
                    mobile: 385,
                },
                props: {
                    type: 'number',
                    min: 300,
                    max: 800,
                    labelTrans: 'builder.sectionOptions.props.height',
                },
            },
            autoplay: {
                type: 'boolean',
                component: 'InputSwitch',
                value: true,
                props: {
                    size: 'small',
                    type: 'boolean',
                    labelTrans: 'builder.sectionOptions.props.autoplay',
                },
            },
            autoplaySpeed: {
                type: 'number',
                component: 'InputText',
                value: 5000,
                props: {
                    type: 'number',
                    labelTrans: 'builder.sectionOptions.props.autoplaySpeed',
                },
            },
        },
        // itemsLayout: { ...SectionLayout },
        itemsStyle: {
            template: {
                ...SectionItemsStyle.createTemplate('style2', [{ text: 'style2', value: 'style2' }]),
            },
        },
    },
    items: [],
    children: {
        id: '',
        type: 'slide',
        options: {
            content: {
                images: {
                    ...Content.imagesByScreen,
                    props: {
                        note: 'JPG, PNG. Recomended size: 1920х494px. No more than 500kb',
                    },
                },
                htmls: { ...Content.htmlScreen },
                alignHorizontal: { ...Content.alignHorizontalScreen },
                alignVertical: { ...Content.alignVerticalScreen },
                innerPadding: {
                    ...Container.padding,
                    value: {
                        desktop: { top: 20, right: 20, bottom: 20, left: 20 },
                        tablet: { top: 20, right: 20, bottom: 20, left: 20 },
                        mobile: { top: 20, right: 20, bottom: 20, left: 20 },
                    },
                },
            },
            button: { ...Content.button },
        },
    },
};

const itemFixture = cloneDeep(section.children);
const itemsFixture = [];
// @ts-ignore start
itemsFixture.push(Object.assign(cloneDeep(itemFixture), { position: 0 }));
section.items = itemsFixture;

export default section;
