export default () => ({
    email: {
        component: 'input-text',
        props: {
            rules: {
                noEmpty: true,
                email: true,
            },
            errors: [],
            labelTrans: 'entities.user.fields.email',
        },
    },
    password: {
        component: 'input-text',
        props: {
            rules: {
                related: ['repeatPassword'],
                noEmpty: true,
            },
            errors: [],
            labelTrans: 'entities.user.fields.password',
            type: 'password',
        },
    },
    repeatPassword: {
        component: 'input-text',
        props: {
            rules: {
                noEmpty: true,
                equalTo: { target: 'password' },
            },
            errors: [],
            labelTrans: 'entities.user.fields.repeatPassword',
            type: 'password',
        },
    },
    role: {
        component: 'input-text',
        props: {
            rules: {
                noEmpty: true,
            },
            errors: [],
            labelTrans: 'entities.user.fields.role',
        },
    },
});
