<template>
    <Select
        v-model="model"
        :items="entries"
        :is-loading="isLoading"
        has-search
        autocomplete
        :search-string.sync="searchString"
        :placeholder="$t('search.main')"
        :item-text="itemText"
        :item-value="returnProp"
        class="autocomplete"
        v-on="$listeners"
    >
        <template v-slot:item="{ item }">
            <div class="autocomplete__search-item">
                <table-image class="table__image" :image="images[item.id]"></table-image>
                <div>{{ item.translation }}</div>
            </div>
        </template>
    </Select>
</template>

<script>
import SearchService from '@/services/SearchService';
import { debounce } from 'lodash';
import { proxyModelMixin } from '@/mixins/proxyModelMixin';
import TableImage from '@/components/common/TableImage';
import { imageUrl } from '@/helpers/values';
import Product from '@/entities/product/Product';
import Select from '@/components/form/controls/Select';
import AuthorService from '@/services/AuthorService';

export default {
    name: 'ReviewProductSearch',
    components: { Select, TableImage },
    props: {
        name: { type: String },
        value: {
            type: String,
            default: null,
        },
        returnProp: {
            type: String,
        },
        entityName: {
            type: String,
            required: true,
            validator(val) {
                return ['product', 'author'].includes(val);
            },
        },
    },
    mixins: [proxyModelMixin],
    data: function() {
        return {
            searchString: '',
            entries: [],
            images: [],
            isLoading: false,
            imageUrl: imageUrl(),
        };
    },
    computed: {
        isActive() {
            return this.searchString && this.searchString.length >= 3;
        },
        services() {
            return { author: AuthorService };
        },
        itemText() {
            switch (this.entityName) {
                case 'author':
                    return 'name';
                default:
                    return 'title';
            }
        },
    },
    methods: {
        async search() {
            this.isLoading = true;

            const params = {
                q: this.searchString,
                limit: 20,
                offset: 0,
            };
            const [error, result] = await SearchService.entitySearch(this.entityName.toLowerCase(), params);
            if (error) {
                error.alert();
                this.isLoading = false;
                return;
            }
            this.entries = result.data;
            console.log(this.entries);
            if (this.entityName.toLowerCase() === 'product') this.images = Product.getDefaultImages(this.entries);
            else {
                const [imageError, imageResult] = await this.services[this.entityName].getImages(this.entries);
                if (imageError) imageError.notify();
                this.images = imageResult;
            }
            this.isLoading = false;
        },
        hasImage(item) {
            return this.images[item.id]?.uri;
        },
    },
    watch: {
        searchString: debounce(async function(newValue, oldValue) {
            if (newValue === oldValue) return;

            if (this.isActive) {
                await this.search();
            }
        }, 500),

        model: function(newValue) {
            this.$emit('update-field', {
                name: this.name,
                value: newValue,
            });
        },
    },
};
</script>
<style lang="scss">
@import '@/scss/variables.scss';
.autocomplete {
    &__search-item {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 24px;
    }
    input {
        border: none;
    }
}
</style>
