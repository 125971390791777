<template>
    <li class="directory-tree__list-item" :data-type="isFolder ? 'folder' : 'file'">
        <div class="directory-tree__list-item-inner" :class="{ 'is-active': isActive }" @click="onClick">
            <span class="material-icons-outlined directory-tree__list-item-icon">{{ icon }}</span>
            <span class="directory-tree__list-item-text">{{ item.name }}</span>
        </div>
        <directory-tree-list
            v-if="item.children && item.children.length"
            v-show="isVisibleList"
            :list="item.children"
            v-model="modelValue"
            class="directory-tree__list--nested"
            :parent="location"
        />
    </li>
</template>

<script>
export default {
    name: 'DirectoryTreeListItem',
    components: {
        DirectoryTreeList: () => import('@/components/base/SimpleFileManager/DirectoryTree/DirectoryTreeList.vue'),
    },
    props: {
        item: {
            type: Object,
            required: true,
        },
        value: {
            type: Array,
        },
        parent: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {};
    },
    computed: {
        modelValue: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
        isFolder() {
            return this.item.children;
        },
        icon() {
            if (this.isFolder) return 'mdi-folder';
            else return 'mdi-file';
        },
        location() {
            return [...this.parent, this.item.id];
        },
        isVisibleList() {
            return this.location.includes(this.item.id);
        },
        isActive() {
            return this.value[this.value.length - 1] === this.item.id;
        },
    },
    methods: {
        onClick() {
            if (this.isFolder) this.toggleList();
            this.modelValue = this.location;
        },
        toggleList() {
            this.item.isOpen = !this.item.isOpen;
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.directory-tree__list-item {
    &[data-type='file'] {
        display: none;
    }
    &-inner {
        display: flex;
        align-items: center;
        cursor: pointer;

        &.is-active {
            color: var(--v-primary-base);
            font-weight: 600;
        }
    }
    &-icon {
        margin-right: 10px;
    }
    &-text {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
</style>
