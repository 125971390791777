<template>
    <div class="page">
        <div class="page-header">
            <h1 class="page-header__text">Forms</h1>
        </div>

        <HeadedBlock :header="'Test Form Controls'">
            <div class="block__content">
                <div>
                    <br />
                    <br />
                    <br />
                    <Button>Primary</Button>
                    <Button type="secondary">secondary</Button>
                    <Button type="outlined">outlined</Button>
                    <Button type="warn">warn</Button>
                    <Button type="error">error</Button>
                    <Button type="icon"></Button>
                    <Button type="text">text</Button>
                    <Button type="large-text">large-text</Button>
                    <Button type="navigation">navigation</Button>
                    <br />
                    <br />
                    <br />
                    <Button icon="delete">Primary</Button>
                    <Button icon="delete" type="secondary">secondary</Button>
                    <Button icon="delete" type="outlined">outlined</Button>
                    <Button icon="delete" type="icon"></Button>
                    <Button icon="delete" type="text">text</Button>
                    <Button icon="delete" type="large-text">large-text</Button>
                    <Button icon="delete" type="navigation">navigation</Button>
                    <br />
                    <br />
                    <br />
                    <Button disabled>Primary</Button>
                    <Button type="secondary" disabled>secondary</Button>
                    <Button type="outlined" disabled>outlined</Button>
                    <Button type="icon" disabled></Button>
                    <Button type="text" disabled>text</Button>
                    <Button type="large-text" disabled>large-text</Button>
                    <Button type="navigation" disabled>navigation</Button>
                    <br />
                    <br />
                    <br />
                    <Button icon="delete" disabled>Primary</Button>
                    <Button icon="delete" type="secondary" disabled>secondary</Button>
                    <Button icon="delete" type="outlined" disabled>outlined</Button>
                    <Button icon="delete" type="icon" disabled></Button>
                    <Button icon="delete" type="text" disabled>text</Button>
                    <Button icon="delete" type="large-text" disabled>large-text</Button>
                    <Button icon="delete" type="navigation" disabled>navigation</Button>
                    <br />
                    <br />
                    <br />
                    <Button type="navigation" class="pagination__btn" @click="changePage('prev')">
                        <span class="material-icons-outlined">chevron_left</span>
                    </Button>
                </div>
            </div>
        </HeadedBlock>
    </div>
</template>

<script>
import HeadedBlock from '@/components/common/HeadedBlock';
import Button from '@/components/common/Button.vue';

export default {
    name: 'TestForms',
    components: {
        HeadedBlock,
        Button,
    },
    data() {},
    computed: {},
    methods: {},
};
</script>

<style lang="scss"></style>
