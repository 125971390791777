<template>
    <div class="page -width-lg">
        <properties-modal
            @action-success="getItems"
            :property-id="propertyId"
            v-model="isPropertyModalOpen"
            @update:sort="updateSort"
        ></properties-modal>
        <div class="page-header">
            <h1 class="page-header__text">{{ $t('menus.pageHeaders.properties') }}</h1>
            <Button v-if="isAvailable" @click="openModal(null)" icon="add_circle_outline">
                {{ $t('entities.add') }}
            </Button>
        </div>

        <template v-if="!isAvailable">
            <error-block></error-block>
        </template>
        <!--       :search="search"
                    @update-search-string="updateSearchString"
        -->
        <Table
            v-else
            data-cy="properties-table"
            show-select
            :columns="headers"
            :items="items"
            local-sort
            local-search
            :sort-desc="sortDesc"
            :sort-by="sortBy"
            v-model="selected"
            :is-data-loaded="isLoaded"
            @delete-selected-items="openDeletionModal"
            @update:sort="updateSort"
        >
            <template v-slot:block-header>
                <Alert class="properties__description-alert">{{ $t('entities.property.alerts.description') }}</Alert>
            </template>
            <!-- ACTIONS -->
            <template v-slot:item.actions="{ item }">
                <div class="table__actions">
                    <Button @click="openModal(item.id)" icon="edit" type="icon" />
                    <Button @click="openDeletionModal(item)" icon="delete" type="icon" />
                </div>
            </template>
            <!-- TITLE -->
            <template v-slot:item.title="{ item }">
                <span class="table-edit">
                    <span @click="openModal(item.id)" type="text" class="table-edit__link">{{ item.title }}</span>
                    <span class="table-edit__icon material-icons-outlined">edit</span>
                </span>
            </template>
            <!-- POSITION -->
            <template v-slot:item.position="{ item }">
                {{ item.position }}
            </template>
            <!-- ICON -->
            <template v-slot:item.icon="{ item }">
                <span class="material-icons-outlined" v-if="item.icon">{{ item.icon }}</span>
                <template v-else>—</template>
            </template>
            <!-- USE IN FILTER -->
            <template v-slot:item.useInFilter="{ item }">
                {{
                    item.useInFilter === 'useInFilter'
                        ? $t('entities.property.useInFilter.use')
                        : $t('entities.property.useInFilter.doNotUse')
                }}
            </template>
            <!-- USAGE -->
            <template v-slot:item.usage="{ item }">
                <div v-if="item.catalogIds.length">
                    {{ $tc('entities.catalog.title', 2) }}:
                    <template v-for="(id, index) in item.catalogIds">
                        <router-link
                            class="properties__catalogs-link"
                            :key="`${id}-${index}`"
                            v-if="catalogsMap[id]"
                            :to="{ name: 'Edit Catalog', params: { id } }"
                        >
                            {{ catalogsMap[id].title }}</router-link
                        ><template v-if="index !== item.catalogIds.length - 1">,</template>
                    </template>
                </div>
                <div v-else-if="item.global">Global</div>
                <div v-else>—</div>
            </template>
            <!-- UNIT -->
            <template v-slot:item.unit="{ item }">
                {{ item.unit || '—' }}
            </template>
            <!-- VALUES -->
            <template v-slot:item.values="{ item }">
                <div class="properties__values">
                    <chip type="combobox" v-for="value in item.values" :key="value.id">{{ value.title }}</chip>
                </div>
            </template>
        </Table>
    </div>
</template>

<script>
import PropertyService from '@/services/PropertyService';
import EventEmitter from '@/helpers/eventEmitter.ts';
import SearchService from '@/services/SearchService';
import ErrorBlock from '@/components/common/ErrorBlock';
import Table from '@/components/common/Table';
import Button from '@/components/common/Button';
import Chip from '@/components/common/Chip';
import Property from '@/entities/property/Property';
import PropertiesModal from '@/components/property/PropertyModal';
import CatalogService from '@/services/CatalogService';
import Alert from '@/components/common/Alert';
import { uniq } from 'lodash';

export default {
    name: 'PropertyList',
    components: {
        Alert,
        PropertiesModal,
        Chip,
        Button,
        Table,
        ErrorBlock,
    },
    created() {
        EventEmitter.on('delete-property', this.deleteItems);
        this.getItems();
    },
    computed: {
        headers() {
            return [
                { text: this.$t('lists.columns.title'), value: 'title', sortable: true },
                { text: this.$t('lists.columns.icon'), value: 'icon' },
                { text: this.$t('lists.columns.position'), value: 'position', sortable: true },
                { text: this.$t('lists.columns.useInFilter'), value: 'useInFilter', sortable: true },
                { text: this.$t('lists.columns.usage'), value: 'usage', width: '200px' },
                { text: this.$t('lists.columns.values'), value: 'values', width: '300px' },
                { text: this.$t('lists.columns.unit'), value: 'unit', sortable: true },
                { value: 'actions', width: '114px' },
            ];
        },
    },
    data: () => ({
        items: [],
        isLoaded: false,
        isPropertyModalOpen: false,
        propertyId: null,
        search: { searchable: 'property', q: '' },
        sortDesc: false,
        sortBy: 'title',
        isAvailable: true,
        selected: [],
        catalogsMap: {},
    }),
    methods: {
        async getItems() {
            this.isLoaded = false;
            const isSearching = !!this.search.q.length;
            const params = {
                q: isSearching ? this.search.q : null,
                fields: isSearching ? ['title'] : null,
            };
            const getter = isSearching ? this.searchProperties : this.getProperties;
            let [error, result] = await getter(params);
            if (error) {
                error.alert();
                this.isAvailable = false;
                return;
            }
            const items = isSearching ? result.data : result;
            this.items = items.map((item) => new Property(item));
            const [catalogsError, catalogsResult] = await this.getCatalogs();
            if (catalogsError) {
                catalogsError.notify();
            }
            catalogsResult.forEach((item) => {
                this.catalogsMap[item.id] = item;
            });
            this.isLoaded = true;
        },
        async getCatalogs() {
            const ids = this.items.reduce((acc, item) => {
                acc.push(...item.catalogIds);
                return acc;
            }, []);
            const uniqIds = uniq(ids);
            return CatalogService.getAll({ ids: uniqIds });
        },
        async getProperties() {
            return await PropertyService.getAll();
        },

        updateSort(sortBy, sortDesc) {
            this.sortBy = sortBy;
            this.sortDesc = sortDesc;
        },

        async searchProperties(params) {
            return await SearchService.entitySearch('property', params);
        },

        openDeletionModal(items) {
            this.$store.dispatch('modals/openDeletionModal', { items, entity: 'property' });
        },

        openModal(id) {
            this.isPropertyModalOpen = true;
            this.propertyId = id;
        },

        async deleteItems(items) {
            this.isLoaded = false;
            const promises = items.map((item) => PropertyService.removeOne(item.id));
            const results = await Promise.all(promises);
            this.selected = [];
            this.$store.dispatch('modals/closeDeletionModal');
            await this.getItems();
            if (
                results.every((item) => {
                    const error = item[0];
                    if (!error) {
                        return true;
                    } else {
                        error.notify();
                    }
                })
            ) {
                if (items.length === 1) {
                    EventEmitter.trigger('show-noty', {
                        type: 'success',
                        text: this.$tc('notifications.deleted', 1, { entity: this.$tc('entities.property.title', 1) }),
                    });
                } else {
                    EventEmitter.trigger('show-noty', {
                        type: 'success',
                        text: this.$tc('notifications.deleted', 2, { entity: this.$tc('entities.property.title', 2) }),
                    });
                }
            }
            this.isLoaded = true;
        },
        updateSearchString({ searchString }) {
            this.search.q = searchString;
            this.getItems();
        },
    },
    beforeDestroy() {
        EventEmitter.off('delete-property', this.deleteItems);
    },
};
</script>
<style lang="scss">
@import '@/scss/variables.scss';
.properties {
    &__description-alert {
        margin-bottom: 24px;
    }
    &__catalogs-link {
        color: var(--v-primary-base) !important;
        &:hover {
            color: var(--v-primary-accent-base) !important;
        }
    }
    &__values {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        .v-chip {
            &:hover {
                background: #e6e8eb !important;
                color: var(--v-on-surface-high-base) !important;
            }
            &:active {
                background: #e6e8eb !important;
            }
        }
    }
    &__modal {
        min-height: 333px !important;
    }
}
</style>
