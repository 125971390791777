import { uuid } from 'vue-uuid';
import initFields from '@/entities/order/OrderState.fields';

/**
 * Класс экземпляра статуса заказа
 */
export default class OrderState {
    fields = initFields();
    /**
     *
     * @param {Object}  data - объект данных статуса
     */
    constructor({ id = uuid.v4(), publicName = '', serviceName = '', color = '', position = 0 } = {}) {
        this.id = id;
        this.publicName = publicName;
        this.serviceName = serviceName;
        this.color = color || '#0dbd65';
        this.position = position;
    }

    get data() {
        return {
            id: this.id,
            publicName: this.publicName,
            serviceName: this.serviceName,
            color: this.color,
            position: this.position,
        };
    }
}
