<template>
    <div class="instance-list">
        <h2>{{ $t('template.instanceList.heading') }}</h2>
        <div v-if="availabilityTypes.length > 1" class="instance-list__filter">
            <template v-for="type in availabilityTypes">
                <Button v-if="type === currentType" :key="type" type="primary" @click="changeCurrentType(type)">{{
                    type
                }}</Button>
                <Button v-else type="secondary" :key="type" @click="changeCurrentType(type)">{{ type }}</Button>
            </template>
        </div>
        <div v-if="Array.isArray(items) && items.length" class="instance-list__list">
            <div v-for="item in itemsFiltred" :key="item.instanceId" class="instance-list__item">
                <div class="instance-list__item-info">
                    <span class="instance-list__item-icon">storefront</span>
                    <span class="instance-list__item-name">{{ item.instanceId }}</span>
                    <a class="instance-list__item-domain" :href="'https://' + item.primaryDomain" target="_blank">
                        {{ item.primaryDomain }}
                    </a>
                </div>
                <div class="instance-list__item-link">
                    <Button type="secondary" @click="openStore(item.instanceId)">
                        {{ $t('template.instanceList.buttons.manage') }}
                    </Button>
                    <!--                    <Button v-else type="secondary" @click="checkout(item.instanceId)">
                        {{ $t('template.payment.buttons.pay') }}
                    </Button>-->
                </div>
            </div>
        </div>
        <Button class="instance-list__btn-add" fullwidth icon="add_circle" @click="createStore">
            {{
                Array.isArray(items) && items.length
                    ? $t('template.instanceList.buttons.createAnotherStore')
                    : $t('template.instanceList.buttons.createStore')
            }}
        </Button>
    </div>
</template>

<script>
import Button from '@/components/common/Button';
import CheckoutService from '@/services/CheckoutService.ts';
import { accountUrl } from '@/helpers/values';

export default {
    name: 'InstanceSelector',
    props: {
        items: {
            type: Array,
            default: () => [],
        },
    },
    components: {
        Button,
    },
    data() {
        return { currentType: 'ALL' };
    },
    computed: {
        availabilityTypes: function() {
            const result = [];
            this.items.forEach((item) => {
                if (!result.find((type) => type === item.availabilityType)) result.push(item.availabilityType);
            });
            if (result.length > 1) result.unshift('ALL');
            return result;
        },
        itemsFiltred: function() {
            if (this.currentType === 'ALL') return this.items;
            else return this.items.filter((item) => item.availabilityType === this.currentType);
        },
    },
    methods: {
        openStore(id) {
            localStorage.setItem('runshop_instance_id', id);
            window.location.href = '/';
        },
        createStore() {
            location.href = `${accountUrl}/create`;
        },
        changeCurrentType(type) {
            this.currentType = type;
        },
        async checkout(instanceId) {
            localStorage.setItem('instanceId', instanceId);
            const [error, result] = await CheckoutService.checkout(instanceId, `${accountUrl}/payment-success`);
            if (error) {
                error.notify();
                return;
            }
            localStorage.setItem('sessionId', result.sessionId);
            localStorage.setItem('sessionIdExpiredAt', result.expiredAt);
            location.replace(result.url);
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.instance-list {
    &__list {
        margin-bottom: 32px;
        border: 1px solid #f5f7fa;
    }

    &__filter {
        margin-bottom: 15px;

        button {
            margin-right: 10px;
        }
    }

    &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #f5f7fa;
        min-height: 64px;

        &:last-child {
            border-bottom: none;
        }
        &-info {
            position: relative;
            padding-left: 54px;
        }
        &-link {
            padding: 0 10px 0 0;
        }

        &-icon {
            position: absolute;
            left: 18px;
            top: 50%;
            margin-top: -13px;
            font-family: 'Material Icons Outlined';
            font-size: 18px;
            width: 20px;
            height: 20px;
            color: $primary;
            opacity: 0.6;
        }
        &-name {
            display: block;
            font-size: 16px;
            line-height: 24px;
            color: #2c2d2e;
        }
        &-domain {
            display: block;
            font-size: 14px;
            line-height: 20px;
            color: #767778;
            &:hover {
                color: #5292ff;
            }
        }
    }

    &__btn-add {
        width: 100%;
        height: 42px;
        justify-content: center;
        span {
            flex: 0;
        }
    }
}
</style>
