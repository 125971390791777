<template>
    <div>
        <!-- кнопки для теста custom-noty -->
        <!-- <div style="position: absolute; top: 25vh; left: 50%; z-index: 1000; display: flex; flex-direction: column;">
            <Button type="primary" @click="createMessage()">Create info custom noty</Button>
            <br />
            <Button type="secondary" @click="createMessage('success', 30000)">Create success custom noty</Button>
            <br />
            <Button type="outlined" @click="createMessage('error', 5000)">Create error custom noty</Button>
        </div> -->

        <!-- custom-noty -->
        <div class="custom-noty">
            <transition-group name="custom-noty-animate" class="custom-noty__list">
                <div
                    v-for="message in messages"
                    :class="getClassNames(message)"
                    :key="message.id"
                    @click="removeMessage(message.id, $event)"
                >
                    <div class="custom-noty__item-timer" :style="getTimer(message)"></div>
                    <div class="custom-noty__item-text" v-html="message.text"></div>
                </div>
            </transition-group>
        </div>
    </div>
</template>

<script>
import EventEmitter from '@/helpers/eventEmitter.ts';
import { uuid } from 'vue-uuid';

export default {
    name: 'CustomNoty',
    data() {
        return {
            messages: [],
        };
    },
    methods: {
        getTimer(item) {
            return `--custom-noty-timer: ${item.duration / 1000}s`;
        },
        getClassNames(item) {
            if (item.type === 'info') return 'custom-noty__item --info';
            if (item.type === 'success') return 'custom-noty__item --success';
            if (item.type === 'error') return 'custom-noty__item --error';
            return 'custom-noty__item';
        },
        hideMessage(message) {
            if (this.messages.length) {
                setTimeout(() => this.removeMessageById(message.id), message.duration);
            }
        },
        removeMessage(id, event) {
            event.preventDefault();
            this.removeMessageById(id);
        },
        removeMessageById(id) {
            this.messages.forEach((item, index) => {
                if (item.id !== id) return;
                this.messages.splice(index, 1);
            });
        },
        createMessage(type = 'info', text = 'Message', duration = 2000) {
            const id = uuid.v4();
            const message = {
                id,
                text,
                type,
                duration,
            };
            this.messages.push(message);
            this.hideMessage(message);
        },
    },
    created() {
        EventEmitter.on('show-noty', ({ type, text }) => {
            this.createMessage(type, text);
        });
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.custom-noty {
    width: 325px;
    position: fixed;
    top: 70px;
    right: 20px;
    z-index: 1000;
    &__list {
        display: flex;
        flex-direction: column-reverse;
    }
    &__item {
        display: block;
        position: relative;
        width: 100%;
        padding: 10px;
        margin: 10px 5px;
        border-radius: 2px;
        box-sizing: border-box;
        box-shadow: 0 2px 8px 1px rgba(0, 0, 0, 0.15);
        color: #ffffff;
        font-size: $font-size-root;
        line-height: 24px;
        cursor: pointer;
        transition: all 0.6s ease;

        &-timer {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            width: 0;
            height: 3px;
            border-radius: 3px 3px 0 0;
            filter: contrast(0.6);
            background: inherit;
            animation: custom-noty-timer var(--custom-noty-timer, 1s) ease-in-out;
        }

        /* Success */
        &.--success {
            background: var(--v-success-base);
        }

        /* Error */
        &.--error {
            background: var(--v-error-base);
        }

        /* Info */
        &.--info {
            background: var(--v-info-base);
        }
    }
}

@keyframes custom-noty-timer {
    0% {
        width: 100%;
    }
    100% {
        width: 0;
    }
}
.custom-noty-animate {
    &-enter {
        transform: translateX(120px);
        opacity: 0;
    }
    &-enter-to {
        opacity: 1;
    }

    &-leave {
        height: 100%;
        opacity: 1;
    }
    &-leave-active {
        position: absolute;
        height: 40px;
        overflow: hidden;
        transition: transform 0.6s ease, opacity 0.6s, height 0.6s 0.2s;
    }
    &-leave-to {
        height: 0;
        transform: translateX(120px);
        opacity: 0;
    }
}
</style>
