<template>
    <div class="builder-section-current-list">
        <BuilderSectionCurrentListItem :element="{ type: 'Header' }" @click="openSettingsPanel('header')" no-touch />
        <hr class="builder-section-current-list__sep" />
        <draggable
            v-if="canHasSection"
            v-model="mySection"
            class="builder-section-current-list__container"
            @start="dragging = true"
            @end="onDragSectionEnd"
        >
            <transition-group>
                <div
                    v-for="section in mySection"
                    :key="section.id"
                    class="builder-section-current-list__item"
                    :id="`section-${section.id}`"
                >
                    <BuilderSectionCurrentListItem
                        :element="section"
                        type="section"
                        :no-hover:="isDragging"
                        :has-child="section.children !== null"
                    />
                    <div
                        v-if="section.children !== null"
                        class="builder-section-current-list__dropdown"
                        :style="`max-height: ${section.items.length * 64 + 80}px`"
                    >
                        <draggable v-model="section.items" @start="dragging = true" @end="onDragItemsEnd(section.id)">
                            <transition-group ghost-class="ghost">
                                <template v-for="item in section.items">
                                    <div :key="item.id" class="builder-section-current-list__subitem">
                                        <BuilderSectionCurrentListItem
                                            :element="item"
                                            type="sectionItem"
                                            no-icon
                                            :no-hover:="isDragging"
                                        />
                                    </div>
                                </template>
                            </transition-group>
                        </draggable>
                        <Button icon="add_circle" icon-fill type="text" @click="addSectionItem(section)">
                            {{ $t('builder.addNew.block.btn') }}
                        </Button>
                    </div>
                </div>
            </transition-group>
        </draggable>
        <div v-if="canHasSection" class="builder-section-current-list__btn-add">
            <Button icon="add_circle" icon-fill type="outlined" @click="updateNewSectionListVisible(true)" fullwidth>
                {{ $t('builder.addNew.section.btn') }}
            </Button>
            <br /><br />
            <Button v-if="hasSectionInClipboard" icon="add_circle" icon-fill @click="pasteSection" fullwidth>
                {{ $t('builder.addNew.pasteSection') }}
            </Button>
        </div>
        <hr v-if="canHasSection" class="builder-section-current-list__sep" />
        <BuilderSectionCurrentListItem :element="{ type: 'Footer' }" @click="openSettingsPanel('footer')" no-touch />
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import draggable from 'vuedraggable';
import { cloneDeep } from 'lodash';
import Button from '@/components/common/Button.vue';
import BuilderSectionCurrentListItem from '@/components/builder/sections/currentListItem.vue';
import EventEmitter from '@/helpers/eventEmitter';

export default {
    name: 'BuilderSectionCurrentList',
    components: { BuilderSectionCurrentListItem, Button, draggable },
    data() {
        return {
            dragging: false,
        };
    },
    computed: {
        ...mapGetters('builder', {
            activeItem: 'getActiveItem',
            currentPage: 'getCurrentPage',
            settings: 'getSettings',
            sections: 'getSectionsCurrentPage',
        }),
        ...mapGetters('clipboard', {
            clipboard: 'getClipboard',
        }),
        canHasSection() {
            if (!this.currentPage) return false;
            const validTypes = ['homepage', 'textpage', 'catalog', 'category', 'article'];
            return validTypes.includes(this.currentPage.type);
        },
        isDragging() {
            return false;
        },
        mySection: {
            get() {
                return this.sections;
            },
            set(value) {
                this.updateSectionPositions(value.map((item) => item.id));
            },
        },
        hasSectionInClipboard() {
            return this.clipboard && this.clipboard.type === 'section' && !!this.clipboard.value;
        },
    },
    methods: {
        ...mapMutations('builder', {
            updateNewSectionListVisible: 'updateNewSectionListVisible',
        }),
        ...mapActions('builder', {
            addSection: 'addSection',
            addSectionItem: 'addSectionItem',
            updateActiveItem: 'updateActiveItem',
            updateActiveSectionsGroup: 'updateActiveSectionsGroup',
            updatePositions: 'updateSectionPositions',
        }),
        pasteSection() {
            this.addSection(this.clipboard.value);
        },
        openSettingsPanel(block = '') {
            if (!block) return;
            this.updateActiveItem({
                type: 'settings',
                data: cloneDeep(this.settings),
            });
            this.updateActiveSectionsGroup(block);
        },
        updateSectionPositions(positions) {
            positions.forEach((id, index) => {
                const section = this.sections.find((section) => section.id === id);
                if (section) section.position = index;
            });
            this.sections.sort((a, b) => a.position - b.position);
        },
        updateItemPositions(sectionId) {
            const section = this.sections.find((section) => section.id === sectionId);
            if (Array.isArray(section.items) && section.items.length > 0) {
                section.items.forEach((item, index) => {
                    item.position = index;
                });
                section.items.sort((a, b) => a.position - b.position);
            }
        },
        onDragSectionEnd(event) {
            this.dragging = false;
            const { newIndex } = event;
            const section = this.sections.find((section) => section.position === newIndex);
            if (section) this.updatePositions(section.id);
        },
        onDragItemsEnd(sectionId) {
            this.dragging = false;
            const section = this.sections.find((section) => section.id === sectionId);
            if (section) this.updateItemPositions(sectionId);
        },
    },
    created() {
        // костыльно но работает, в идеале вообще избавиться от перерисовки элементов списка заново при добавление нового
        EventEmitter.on('to-public', ({ action, payload }) => {
            if (action === 'add-section') {
                setTimeout(() => {
                    this.$nextTick(() => {
                        const ref = `section-${payload.data.id}`;
                        const newItem = document.querySelector('#' + ref);
                        if (newItem) newItem.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
                    });
                }, 100);
            }
        });
    },
    watch: {},
};
</script>

<style lang="scss">
@import '@/scss/variables';
@import '@/scss/variables/system-colors';

.builder-section-current-list {
    &__sep {
        border: none;
        border-top: 1px solid rgba(0, 0, 0, 0.12);
    }
    &__btn-add {
        padding: 12px;
    }
    &__container {
        display: block;
    }
    &__item {
        &.sortable-chosen {
            background: $item-hover;
        }
        &.sortable-ghost {
            opacity: 0.7;
            background: $item-active;
        }
    }
    &__dropdown {
        overflow: hidden;
        padding: 0 0 0 32px;
        transition: all 0.3s ease;
    }
    &__subitem {
        border-left: 1px solid rgba(0, 0, 0, 0.12);
        &.sortable-chosen {
            background: $item-hover;
        }
        &.sortable-ghost {
            opacity: 0.7;
            background: $item-active;
        }
    }
}
.builder-section-current-list-item.is-close + .builder-section-current-list__dropdown {
    max-height: 0 !important;
}
</style>
