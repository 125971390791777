<template>
    <div class="form-buttons">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'FormButtons',
};
</script>

<style lang="scss">
.form-buttons {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    gap: 16px;
}
</style>
