export default {
    facebook: {
        id: null,
        position: 1,
        type: 'url',
        component: 'InputText',
        value: '',
        options: {
            labelTrans: 'settings.socials.facebook',
            note: 'Example: https://www.facebook.com/username',
        },
    },
    instagram: {
        id: null,
        position: 2,
        type: 'url',
        component: 'InputText',
        value: '',
        options: {
            labelTrans: 'settings.socials.instagram',
            note: 'Example: https://www.instagram.com/username',
        },
    },
    linkedIn: {
        id: null,
        position: 3,
        type: 'url',
        component: 'InputText',
        value: '',
        options: {
            labelTrans: 'settings.socials.linkedIn',
            note: 'Example: https://www.linkedin.com/company/username',
        },
    },
    twitter: {
        id: null,
        position: 4,
        type: 'url',
        component: 'InputText',
        value: '',
        options: {
            labelTrans: 'settings.socials.twitter',
            note: 'Example: https://x.com/username',
        },
    },
    medium: {
        id: null,
        type: 'url',
        component: 'InputText',
        value: '',
        options: {
            labelTrans: 'settings.socials.medium',
            note: 'Example: https://www.medium.com/username',
        },
        position: 5,
    },
    telegram: {
        id: null,
        type: 'url',
        component: 'InputText',
        value: '',
        options: {
            labelTrans: 'settings.socials.telegram',
            note: 'Example: https://t.me/username',
        },
        position: 5,
    },
    tiktok: {
        id: null,
        type: 'url',
        component: 'InputText',
        value: '',
        options: {
            labelTrans: 'settings.socials.tiktok',
            note: 'Example: https://www.tiktok.com/@username',
        },
        position: 5,
    },
    vk: {
        id: null,
        type: 'url',
        component: 'InputText',
        value: '',
        options: {
            labelTrans: 'settings.socials.vk',
            note: 'Example: https://vk.com/username',
        },
        position: 5,
    },
};
