<template>
    <div class="sidebar-menu-group" :class="{ 'is-open': item.isOpen }">
        <div class="sidebar-menu-group__header" @click="toggle">
            <div class="sidebar-menu-group__header-icon" v-if="item.icon">
                <span class="material-icons-outlined">{{ item.icon }}</span>
            </div>
            <div class="sidebar-menu-group__header-content" v-if="$t(item.title)">
                <span
                    class="sidebar-menu-group__header-title"
                    v-text="item.variable ? $t(item.title, item.variable) : $t(item.title)"
                ></span>
            </div>
            <div class="sidebar-menu-group__header-btn">
                <span class="material-icons-outlined">keyboard_arrow_down</span>
            </div>
        </div>
        <div class="sidebar-menu-group__items">
            <template v-for="(child, i) in item.children">
                <SidebarMenuItem v-if="item.isOpen" :key="`item-${i}`" :item="child" />
            </template>
        </div>
    </div>
</template>

<script>
import SidebarMenuItem from '@/components/common/SidebarMenuItem.vue';
export default {
    name: 'SidebarMenuGroup',
    components: {
        SidebarMenuItem,
    },
    props: {
        item: {
            type: Object,
            default: () => ({
                group: undefined,
                title: undefined,
                children: [],
            }),
        },
    },
    created() {
        this.$emit('toggleGroup', { group: this.item.group, isOpen: this.checkActiveGroup() });
    },
    computed: {
        paths() {
            return this.getChildPaths();
        },
    },
    methods: {
        checkActiveGroup() {
            const currentPath = this.$router.currentRoute.path;
            const hasActiveGroup = this.paths?.some((path) => path === currentPath || currentPath.startsWith(path));
            return hasActiveGroup;
        },
        getChildPaths() {
            return this.item.children
                .filter((item) => item.to)
                .map(
                    (item) =>
                        this.$router.resolve({
                            name: item.to.name,
                            params: item.to.params,
                        }).resolved.path
                );
        },
        toggle() {
            this.$emit('toggleGroup', { group: this.item.group, isOpen: !this.item.isOpen });
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.sidebar-menu-group {
    position: relative;
    &.is-open & {
        &__header-btn .material-icons-outlined {
            transform: rotate(180deg);
            color: var(--v-primary-base);
        }
        &__items {
            opacity: 1;
            max-height: 400px;
        }
        &__header-icon .material-icons-outlined,
        &__header-title {
            color: var(--v-primary-base);
        }
    }
    &__header {
        align-items: center;
        display: flex;
        flex: 1 1 100%;
        outline: none;
        padding: 5px 15px 5px 11px;
        position: relative;
        text-decoration: none;
        cursor: pointer;
        &-btn {
            align-self: center;
            margin: 0;
            min-width: 48px;
            justify-content: flex-end;
            display: inline-flex;
            margin: 12px 0;
            .material-icons-outlined {
                font-size: 22px;
                width: 20px;
                color: var(--v-on-surface-high-base);
            }
        }
        &-icon {
            width: 20px;
            margin: 12px 15px 12px 0;
            &:first-child {
                justify-content: center;
                text-align: center;
            }
            .material-icons-outlined {
                color: var(--v-on-surface-high-base);
                font-size: 22px;
            }
        }
        &-content {
            align-items: center;
            align-self: center;
            display: flex;
            flex-wrap: wrap;
            flex: 1 1;
            overflow: hidden;
            padding: 12px 0;
        }
        &-title {
            line-height: 1.2;
            font-size: 16px;
            color: var(--v-on-surface-high-base);
            align-self: center;
            flex: 1 1 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        &:hover {
            background: var(--v-surface-overlay-base);
        }
    }
    &__items {
        display: block;
        opacity: 0;
        max-height: 0;
        overflow: hidden;
        transition: $transition-default;
        & > .sidebar-menu-item {
            border-left-color: transparent;
            // border-left-color: var(--v-on-surface-disabled-base);
            margin-left: 33px;
            padding-left: 15px;
            height: 54px;
        }
    }
}
</style>
