import { uuid } from 'vue-uuid';

/**
 * Класс экземпляра значения опции
 */
export default class OptionValue {
    /**
     *
     * @param {Object}  data - объект данных значения опции
     */
    constructor({ id = uuid.v4(), title = '', position = null } = {}) {
        this.id = id;
        this.title = title;
        this.position = position;
    }

    get data() {
        return {
            id: this.id,
            title: this.title,
            position: this.position,
        };
    }
}
