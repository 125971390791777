<template>
    <div class="page -width-lg">
        <sticky-toolbar>
            <Button icon="check" :disabled="!saveAllowed" @click="onSubmit">{{ $t('entities.save') }}</Button>
        </sticky-toolbar>
        <div class="page-header erp__page-header">
            <h1 class="page-header__text">{{ $t('menus.pageHeaders.erp') }}</h1>
            <!--
            <div class="page-header__buttons">
                <Button @click="isAddModelOpen = true" icon="add_circle_outline">{{ $t('entities.add') }}</Button>
            </div>
            -->
        </div>
        <alert type="success" class="entity-item__success-alert" v-if="isUpdated">
            {{ $tc('notifications.updated', 2, { entity: this.$tc('entities.erp.title', 1) }) }}
        </alert>

        <progress-circular class="spinner -absolute" v-if="!isDataLoaded"></progress-circular>

        <template v-else>
            <template v-for="(form, group) in forms.items">
                <expansion :key="group">
                    <template v-slot:header-left>
                        <input-switch
                            class="expansion__switch"
                            @click.native.stop
                            @input="handleEnabled(group)"
                            v-model="form.value.items.enabled.value"
                        ></input-switch>
                        <div class="address-card erp__adress-card">
                            <div class="address-card__text">
                                <h4 class="address-card__city expansion__heading-text">
                                    {{ $t(`settings.${group}.value`) }}
                                </h4>
                            </div>
                        </div>
                    </template>

                    <template v-slot:default>
                        <div class="expansion__sub-container">
                            <div class="form erp__form">
                                <template v-for="(item, key) in form.value.items">
                                    <form-item
                                        class="form__input"
                                        v-if="key !== 'enabled'"
                                        v-bind="item.props"
                                        :key="key"
                                    >
                                        <component
                                            :key="key"
                                            :group="group"
                                            :is="item.component"
                                            v-model="item.value"
                                            v-bind="item.props || {}"
                                            :name="key"
                                            @update-field="onUpdateFieldValue"
                                        />
                                    </form-item>
                                </template>
                            </div>
                        </div>
                    </template>
                </expansion>
            </template>
        </template>
    </div>
</template>

<script>
import { cloneDeep, isEqual, debounce, merge } from 'lodash';
import i18n from '@/plugins/i18n';
import HeadedBlock from '@/components/common/HeadedBlock';
import FormItem from '@/components/form/item';
import Expansion from '@/components/common/Expansion';
import Modal from '@/components/common/Modal';
import StickyToolbar from '@/components/common/StickyToolbar';
import Form from '@/components/form/Form';
import PaymentAddItemModal from '@/components/payment/PaymentAddItemModal';
import InputSwitch from '@/components/form/controls/InputSwitch';

import SettingService from '@/services/SettingService';
import fields from '@/entities/erp/index';
import SettingItem from '@/entities/setting/SettingItem';
import { getDefaultSettings } from '@/entities/setting/SettingHelper';
import EventEmitter from '@/helpers/eventEmitter.ts';
import Button from '@/components/common/Button';
import ProgressCircular from '@/components/common/ProgressCircular';
import Alert from '@/components/common/Alert';

export default {
    name: 'ERP',

    defaultFieldProps: {
        dense: true,
        'hide-details': true,
        outlined: true,
    },

    components: {
        Alert,
        ProgressCircular,
        InputSwitch,
        PaymentAddItemModal,
        Expansion,
        FormItem,
        HeadedBlock,
        Modal,
        StickyToolbar,
        Button,
    },

    async created() {
        this.isDataLoaded = false;
        const systems = Object.keys(fields);
        this.getDefaultSettings(systems);
        const [error, settings] = await this.getSettings(systems);
        if (error) {
            error.notify();
        }
        //мержим дефолтные настройки и настройки с бэка
        this.settings = merge(this.settings, settings);
        const config = {
            action: this.formAction,
            validateHandler: this.validateHandler,
            successHandler: this.successHandler,
            failedHandler: this.failedHandler,
            items: {},
        };
        systems.forEach((item) => {
            config.items[item] = {
                type: 'form',
                value: new Form({
                    items: fields[item].initItems(this.settings[item]),
                }),
            };
        });
        this.forms = new Form(config);
        this.initialData = cloneDeep(this.forms);
        this.isDataLoaded = true;
    },

    data() {
        return {
            isDataLoaded: false,
            settings: {},
            initialData: {},
            forms: {},
            panels: [],
            isUpdated: false,
            isAddModelOpen: false,
            isOnlyCashOnDelivery: false,
        };
    },

    methods: {
        async getSettings(systems) {
            return await SettingService.getAll({
                groups: systems,
            });
        },

        //на фронте не валидируем
        validateForm() {
            return function() {
                return true;
            };
        },

        getDefaultSettings(systems) {
            this.settings = getDefaultSettings({ groups: systems });
        },

        handleEnabled(formName) {
            //очищаем ошибки при изменении enabled
            this.forms.items[formName].value.validate();
        },

        deleteSystem(form) {
            console.log('deleteSystem form', form);
            // form.value.items.isActive.value = false;
        },

        onSubmit() {
            this.forms.submit();
        },

        validateHandler() {
            this.$forceUpdate();
        },

        async formAction(formData) {
            const settings = {};
            Object.keys(this.forms.items).forEach((key) => {
                settings[key] = {};
                Object.keys(formData[key]).forEach((item, index) => {
                    const value = formData[key][item];
                    settings[key][item] = new SettingItem({
                        value,
                        position: index,
                        type: (typeof value).toLowerCase(),
                    });
                });
            });
            return SettingService.updateBatch(Object.keys(this.forms.items), settings);
        },

        async successHandler() {
            const [error, settings] = await this.getSettings();
            if (error) {
                error.notify();
            }
            this.settings = settings;
            this.initialData = cloneDeep(this.forms);
            this.isUpdated = true;
            window.scrollTo({ top: 0, behavior: 'smooth' });
        },

        onUpdateFieldValue: debounce(function(payload) {
            const { name, group } = payload;
            this.forms.validateField(name, group);
        }, 600),

        failedHandler() {
            this.isUpdated = false;
            EventEmitter.trigger('show-noty', {
                type: 'error',
                text: i18n.t('notifications.validation.error'),
            });
        },
    },
    computed: {
        saveAllowed() {
            return !isEqual(this.forms, this.initialData);
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.erp {
    &__page-header {
        margin-top: -45px;
        padding-right: 520px;
    }
    &__form {
        display: grid;
        gap: 24px;
        grid-template-columns: 2fr 1fr 1fr;
    }
    &__address-card {
        margin-left: auto;
    }
}
</style>
