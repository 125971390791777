import SlugService from '@/services/SlugService';
import { cloneDeep } from 'lodash';
import { uuid } from 'vue-uuid';
import store from '@/store/store';
import Catalog from '@/entities/catalog/Catalog';
import ProductLabel from '@/entities/product/ProductLabel';
import Property from '@/entities/property/Property';
//вызываем события на изменения title таким образом, чтобы не потерять контекст

export async function handleTitleChange(text) {
    await changeSlugFromTitle.call(this, text);
    changeHeadingFromTitle.call(this, text);
}

export function changeHeadingFromTitle(text) {
    if (this.current.heading !== '') return;
    this.form.items.heading.value = text;
    this.onUpdateFieldValue({ name: 'heading', value: text });
}

export async function changeSlugFromTitle(text) {
    const product = cloneDeep(this.current);
    const [error, result] = await SlugService.changeSlugFromTitle(text, product);
    if (error) {
        error.notify();
        return;
    }
    if (result) {
        this.updateCurrent(product);
        this.form.items.slug.value = result;
    }
}

export function createRedirectOnSave(redirect) {
    this.updateCurrentsField({ name: 'redirect', value: redirect });
}

//работа с картинками

export function deleteNewImage(image) {
    const index = this.newImages.findIndex((item) => item.id === image.id);
    const updatedNewImages = cloneDeep(this.newImages);
    updatedNewImages.splice(index, 1);
    this.updateNewImages(updatedNewImages);
}

export function addImage(files) {
    const updatedNewImages = cloneDeep(this.newImages);
    Object.values(files).forEach((file) => {
        updatedNewImages.push({
            uri: URL.createObjectURL(file),
            id: uuid.v4(),
            file: file,
        });
    });
    this.updateNewImages(updatedNewImages);
}

export async function deleteImage(image) {
    //удаляем изобаржение
    await this.removeImage(image.id);
    //удаляем изображение из вариаций
    const variations = cloneDeep(this.current.variations);
    variations.forEach((item) => {
        if (item.imageId === image.imageId) {
            item.imageId = null;
        }
    });
    //удаляем изображение из массива images
    const images = this.current.images.filter((item) => {
        return item.imageId !== image.imageId;
    });
    //обновляем и форму и current в сторе
    this.form.items.variations.value = cloneDeep(variations);
    this.onUpdateFieldValue({
        name: 'images',
        value: cloneDeep(images),
    });
    this.onUpdateFieldValue({
        name: 'variations',
        value: cloneDeep(variations),
    });
    //обновляем initialState, чтобы не тригерить кнопку Save
    this.updateInitialsField({ name: 'images', value: cloneDeep(images) });
    this.updateInitialsField({ name: 'variations', value: cloneDeep(variations) });
}

export function updateCurrentImages(images) {
    //можем обновлять только current, т.к валидации на картинки нет и в imageManager используются images
    // из current, а не из form
    this.updateCurrentsField({ name: 'images', value: cloneDeep(images) });
}

export async function updateImages() {
    try {
        this.imagesLoading = true;
        await this.uploadImages();
        this.imagesLoading = false;
    } catch (e) {
        console.error(e);
    }
}

export const getStockSummary = (item) => {
    const count = item.variations.reduce((previous, current) => {
        return (previous += current.stock ? current.stock : 0);
    }, 0);
    return count ? `(${count})` : '';
};

export function formatForEditor(product) {
    if (!product) return {};
    if (product.description) {
        product.description = product.description.replace(/\r?\n|\r/gm, '');
        product.description = product.description.replace(/\t/g, '');
        // product.description = product.description.replace(/<\/?div?[^>]+(>|$)/g, '');
    }

    if (product.text) {
        product.text = product.text.replace(/\r?\n|\r/gm, '');
        product.text = product.text.replace(/\t/g, '');
        // product.text = product.text.replace(/<\/?div?[^>]+(>|$)/g, '');
    }
    return product;
}

export function handleSizeInput(size) {
    store.dispatch('product/updateCurrentsField', { name: 'length', value: size.length });
    store.dispatch('product/updateCurrentsField', { name: 'width', value: size.width });
    store.dispatch('product/updateCurrentsField', { name: 'height', value: size.height });
}

export function validateSizeInput({ value }) {
    if (!value) return [];
    const metrics = Object.values(value).filter((item) => item);
    if (metrics.length > 0 && metrics.length < 3) {
        return ['validator.errors.size'];
    }
    return [];
}

export async function addNewCatalogs(item) {
    if (item.isNew) {
        const newCatalogs = store.getters['product/getCatalogsToCreate'];
        const catalog = new Catalog({ id: item.id, title: item.text, heading: item.text });
        const [error, result] = await SlugService.changeSlugFromTitle(catalog.title, catalog);
        if (error) {
            error.notify();
            return;
        }
        catalog.slug = result;
        store.dispatch('product/updateCatalogsToCreate', [...newCatalogs, catalog]);
    }
    //addCatalogProperties.call(this, item);
}

export function removeNewCatalogs(item) {
    if (item.isNew) {
        const newCatalogs = store.getters['product/getCatalogsToCreate'];
        store.dispatch(
            'product/updateCatalogsToCreate',
            newCatalogs.filter((catalog) => catalog.id !== item.id)
        );
    }
    //removeCatalogProperties.call(this, item);
}

export async function addNewLabels(item) {
    if (item.isNew) {
        const newLabels = store.getters['product/getLabelsToCreate'];
        const label = new ProductLabel({ id: item.id, title: item.text, color: 'accent1' });
        store.dispatch('product/updateLabelsToCreate', [...newLabels, label]);
    }
}

export function removeNewLabels(item) {
    if (item.isNew) {
        const newLabels = store.getters['product/getLabelsToCreate'];
        store.dispatch(
            'product/updateLabelsToCreate',
            newLabels.filter((label) => label.id !== item.id)
        );
    }
}

export function addCatalogProperties(catalog) {
    const product = store.getters['product/getCurrent'];
    if (!catalog.item.propertyIds.length) return;
    const properties = store.getters['product/getPropertiesList'];
    const propertiesToAdd = properties
        .filter((item) => catalog.item.propertyIds.includes(item.id))
        .filter((item) => !product.properties.find((productProperty) => productProperty.propertyId === item.id))
        .map(
            (item) =>
                new Property(
                    {
                        title: item.title,
                        propertyId: item.id,
                    },
                    true
                )
        );
    const productProperties = product.properties;
    this.form.items.properties.value = [...productProperties, ...propertiesToAdd];

    store.dispatch('product/updateCurrentsField', {
        name: 'properties',
        value: [...productProperties, ...propertiesToAdd],
    });
}

export function removeCatalogProperties(catalog) {
    if (!catalog.item.propertyIds.length) return;
    const product = store.getters['product/getCurrent'];
    const productProperties = product.properties;
    const newProperties = productProperties.filter((item) => !catalog.item.propertyIds.includes(item.propertyId));
    this.form.items.properties.value = newProperties;

    store.dispatch('product/updateCurrentsField', {
        name: 'properties',
        value: newProperties,
    });
}
