<template>
    <tr class="table-loader">
        <th class="table-loader__cell" :colspan="colspan">
            <div v-if="!isDataLoaded" class="table-loader__body">
                <div class="table-loader__progress"></div>
            </div>
        </th>
    </tr>
</template>

<script>
export default {
    name: 'TableLoader',
    props: {
        isDataLoaded: {
            type: Boolean,
            default: true,
        },
        colspan: {
            type: Number,
        },
    },
};
</script>

<style scoped></style>
