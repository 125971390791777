var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('headed-block',{staticClass:"product-form__block catalog-related-products-list",attrs:{"heading":_vm.$t('entities.blocks.catalogRelatedProducts')},scopedSlots:_vm._u([{key:"header-append",fn:function(){return [_c('Button',{attrs:{"icon":"add_circle_outline"},on:{"click":_vm.openProductsModal}},[_vm._v(_vm._s(_vm.$t('entities.add')))])]},proxy:true}])},[_c('catalog-products-modal',{attrs:{"id":"catalog-related-products-modal","products":_vm.items},on:{"remove-item":_vm.removeItem,"add-item":_vm.addItem},model:{value:(_vm.isProductsModalOpen),callback:function ($$v) {_vm.isProductsModalOpen=$$v},expression:"isProductsModalOpen"}}),_c('div',{staticClass:"catalog-related-products-list"},[(!_vm.isAvailable)?_c('error-block'):_vm._e(),_c('Table',{attrs:{"columns":_vm.headers,"items":_vm.items,"isDataLoaded":_vm.isDataLoaded,"show-selected-actions":false,"show-select":"","show-select-all":false},scopedSlots:_vm._u([{key:"toolbar",fn:function(){return [_c('div',{staticClass:"catalog-related-products-list__items-info"},[_c('span',[_vm._v(_vm._s(_vm.$t('entities.catalog.total'))+": "+_vm._s(_vm.model.length))]),_c('span',{staticClass:"catalog-related-products-list__selected-info"},[_vm._v(_vm._s(_vm.$t('entities.catalog.selected'))+": "+_vm._s(_vm.selected.length))]),(_vm.selected.length)?_c('Button',{staticClass:"table__selected-action catalog-related-products-list__delete-selected",attrs:{"type":"text","icon":"delete"},on:{"click":_vm.removeSelected}},[_vm._v(" "+_vm._s(_vm.$t('lists.actions.deleteSelected')))]):_vm._e()],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"table__actions"},[_c('Button',{attrs:{"title":_vm.$t('lists.deleteButton.product'),"type":"icon","icon":"delete"},on:{"click":function($event){return _vm.removeItem(item)}}})],1)]}},{key:"item.image",fn:function(ref){
var item = ref.item;
return [(_vm.hasImage(item))?_c('img',{staticClass:"table__image",attrs:{"alt":_vm.images[item.id].alt,"src":(_vm.imageUrl + "/" + (_vm.images[item.id].uri))}}):_c('img',{staticClass:"table__image -default",attrs:{"alt":"No image","src":require("@/assets/images/no-photo.svg")}})]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.getListPrice(_vm.currency))+" ")]}},{key:"item.enabled",fn:function(ref){
var item = ref.item;
return [_c('status',{attrs:{"icon":item.enabled ? 'check' : 'close',"text":item.enabled
                            ? _vm.$t('entities.product.fields.enabled')
                            : _vm.$t('entities.product.fields.disabled'),"type":item.enabled ? 'success' : 'warning'}})]}},{key:"item.stock",fn:function(ref){
                            var item = ref.item;
return [(item.variations.every(function (item) { return item.stock === 0; }))?_c('chip',{attrs:{"type":"error"}},[_vm._v(" "+_vm._s(_vm.$t('entities.product.stock.outOfStock'))+" ")]):(item.variations.some(function (item) { return item.stock === 0; }))?_c('chip',{attrs:{"type":"warning"}},[_vm._v(" "+_vm._s(_vm.$t('entities.product.stock.partially'))+" ")]):_c('chip',{attrs:{"type":"success"}},[_vm._v(" "+_vm._s(_vm.$t('entities.product.stock.inStock'))+" ")])]}},{key:"item.title",fn:function(ref){
                            var item = ref.item;
return [_c('span',{staticClass:"table-edit"},[_c('router-link',{staticClass:"table-edit__link",attrs:{"target":"_blank","to":{ name: 'Edit Product', params: { id: item.id } }}},[_vm._v(_vm._s(item.title))]),_c('span',{staticClass:"table-edit__icon material-icons-outlined"},[_vm._v("edit")])],1)]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),(_vm.hasMore)?_c('div',{staticClass:"catalog-related-products-list__footer"},[(_vm.isMoreLoaded)?_c('Button',{staticClass:"catalog-related-products-list__show-more",attrs:{"type":"text"},on:{"click":_vm.showMoreProducts}},[_vm._v(_vm._s(_vm.$t('entities.catalog.showMore')))]):_c('loader-circular',{staticClass:"spinner",attrs:{"size":"medium"}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }