export default {
    enabled: {
        id: null,
        type: 'boolean',
        value: true,
        position: 0,
        options: {},
    },
    domain: {
        id: null,
        type: 'string',
        value: 'domain',
        options: {},
        position: 0,
    },
    userId: {
        id: null,
        type: 'string',
        value: 'userId',
        options: {},
        position: 0,
    },
    secret: {
        id: null,
        type: 'string',
        value: 'secret',
        options: {},
        position: 0,
    },
};
