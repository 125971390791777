import ApiClient from '@/api/apiClient';
import store from '@/store/store';
import { capitalize } from '@/helpers/filters';
import i18n from '@/plugins/i18n';
export class Pagination {
    constructor({
        total,
        moduleName,
        limit,
        pageNumber = 1,
        container = null,
        options = [10, 30, 100],
        storeModuleName,
        storeless = false,
    } = {}) {
        this.limit = limit || 100;
        this.offset = 0;
        this.pageNumber = pageNumber;
        this.total = total;
        this.container = container || null;
        this.moduleName = moduleName;
        this.storeless = storeless;
        if (!storeless) {
            this.storeModuleName =
                storeModuleName ||
                moduleName
                    .split('-')
                    .map((item) => capitalize(item))
                    .join('');
        }
        this.options = options.map((number) => {
            return {
                text: i18n.t('lists.perPage', { count: number }),
                value: number,
            };
        });
    }

    static async getTotal(moduleName, customRoute) {
        const url = customRoute ? customRoute : `/${moduleName}/count`;
        const defaultValue = 0;
        const errorPath = `[api::pagination:${moduleName}:getTotal]`;
        return await ApiClient.admin.get({ url, defaultValue, errorPath });
    }

    get pageNumber() {
        return this.offset / this.limit + 1;
    }

    set pageNumber(value) {
        this.offset = (value - 1) * this.limit;
    }

    get hasNextPage() {
        return this.total > this.limit * this.pageNumber;
    }

    get lastPageNumber() {
        return this.directions.last;
    }

    get hasPrevPage() {
        return this.pageNumber > 1;
    }

    get directions() {
        return {
            next: +this.pageNumber + 1,
            prev: +this.pageNumber - 1,
            first: 1,
            last: this.total % this.limit ? Math.floor(this.total / this.limit) + 1 : this.total / this.limit,
        };
    }

    scrollToTop() {
        this.container
            ? this.container.scrollTo({ top: 0, behavior: 'smooth' })
            : window.scroll({ top: 0, behavior: 'smooth' });
    }

    changeLimit(val) {
        /**
         * если total меньше нового значения limit - переходим на первую страницу
         */
        if (this.total < val) {
            this.limit = val;
            this.pageNumber = 1;
            if (!this.storeless) {
                this.pushToHistory(this.directions.first);
            }
            /**
             * если total меньше нового значения limit * текущую страницу,
             * то высчитываем новую последнюю страницу и если она отличается от текущей
             * переходим на неё
             */
        } else if (this.total < val * this.pageNumber) {
            this.limit = val;
            this.pageNumber = Math.floor(this.total / val) + 1;
            if (!this.storeless) {
                this.pushToHistory(this.directions.last);
            }
        } else {
            /**
             * в остальных случаях остаёмся на текущей странице и просто меняем limit
             */
            const prevPage = this.pageNumber;
            this.limit = val;
            this.pageNumber = prevPage;
            if (!this.storeless) {
                this.pushToHistory(this.pageNumber);
            }
        }
    }

    reset() {
        this.offset = 0;
    }

    async normalize(items) {
        if (!items.length && this.pageNumber !== 1) {
            this.pushToHistory(this.pageNumber - 1);
            return true;
        }
        return false;
    }
    /*
    handleNextPage(items) {
        if (items.length < this.limit + 1) {
            this.hasNextPage = false;
            return false;
        } else {
            this.hasNextPage = true;
            return true;
        }
    }*/

    pushToHistory(page) {
        store.dispatch(`globalVars/change${this.storeModuleName}Page`, page);
        store.dispatch(`globalVars/change${this.storeModuleName}Limit`, this.limit);
    }
}
