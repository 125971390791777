<template>
    <modal width="750" :header="$t('entities.comment.title')" v-model="model">
        <div v-if="model" class="view-comment-modal__body">
            <template v-for="key in fieldsToShow">
                <div v-if="key !== 'articleId' && key !== 'createdAt'" :key="key" class="view-comment-modal__item">
                    <span class="view-comment-modal__item-header">{{ $t(`entities.comment.fields.${key}`) }}:</span>
                    <div class="view-comment-modal__item-value">{{ item.comment[key] || '—' }}</div>
                </div>
                <div v-if="key === 'articleId'" :key="key" class="view-comment-modal__item">
                    <span class="view-comment-modal__item-header">{{ $t(`entities.comment.fields.${key}`) }}:</span>
                    <router-link
                        v-if="item.article"
                        class="view-comment-modal__article"
                        :to="{ name: 'Edit Article', params: { id: item.article.articleId } }"
                    >
                        <table-image class="table__image" :image="image"></table-image>
                        <span class="table-edit">
                            <span type="text" class="table-edit__link article-title">{{ item.article.title }}</span>
                        </span>
                    </router-link>
                    <template v-else>—</template>
                </div>
                <div v-if="key === 'createdAt'" :key="key" class="view-comment-modal__item">
                    <span class="view-comment-modal__item-header">{{ $t(`entities.comment.fields.${key}`) }}:</span>
                    {{ new Date(item.comment.createdAt) | localDate }}
                </div>
            </template>
        </div>
    </modal>
</template>

<script>
import Modal from '@/components/common/Modal';
import { proxyModelMixin } from '@/mixins/proxyModelMixin';
import Image from '@/entities/image/Image';
import TableImage from '@/components/common/TableImage';

export default {
    name: 'ViewCommentModal',
    mixins: [proxyModelMixin],
    components: { Modal, TableImage },

    props: {
        value: {
            type: Boolean,
            default: false,
        },
        item: {
            type: Object,
            default: () => {
                return { comment: {}, article: {} };
            },
        },
    },
    computed: {
        image() {
            const image = this.item.article.image;
            return image ? new Image(image) : null;
        },
        fieldsToShow() {
            return ['name', 'email', 'text', 'articleId', 'createdAt'];
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.view-comment-modal {
    &__body {
        display: flex;
        flex-wrap: wrap;
        gap: 14px;
    }
    &__item {
        display: flex;
        width: 100%;
        gap: 30px;
    }
    &__item-header {
        width: 100px;
        min-width: 100px;
        font-weight: 500;
    }
    &__edit-button {
        margin-right: auto;
        margin-left: 4px;
    }
    &__article {
        display: flex;
        gap: 12px;
        align-items: center;
    }
}
</style>
