import apiClient from '@/api/apiClient';
import { ShippingZoneRate } from '@/services/services.types';
import CustomError from '@/entities/customError';

class ShippingZoneRateService {
    /**
     * Получение всех рейтов
     * @param {String} shippingZoneId - id зоны, к которой относится рейт
     * @returns {Promise} массив рейтов
     */

    async getAll(shippingZoneId: string): Promise<[CustomError | null, ShippingZoneRate[] | []]> {
        const url: string = `/rates/${shippingZoneId}`;
        const defaultValue = [];
        const errorPath: string = '[api:shippingZoneRate:getAll]';
        return await apiClient.admin.get({ url, defaultValue, errorPath });
    }

    /**
     * Создание рейта
     * @param {ShippingZoneRate} shippingZoneRate - объект рейта
     * @returns {Promise} созданный объект рейта
     */

    async createOne(shippingZoneRate: ShippingZoneRate): Promise<[CustomError | null, ShippingZoneRate | null]> {
        const url: string = `/rates`;
        const defaultValue = null;
        const errorPath: string = '[api:shippingZoneRate:getOne]';
        return await apiClient.admin.post({ url, defaultValue, errorPath, data: shippingZoneRate });
    }

    /**
     * Обновление рейта
     * @param {ShippingZoneRate} shippingZoneRate - объект рейта
     * @returns {Promise} обновленный объект рейта
     */

    async updateOne(shippingZoneRate: ShippingZoneRate): Promise<[CustomError | null, ShippingZoneRate | null]> {
        const url: string = `/rates/${shippingZoneRate.id}`;
        const defaultValue = null;
        const errorPath: string = '[api:shippingZoneRate:updateOne]';
        return await apiClient.admin.put({ url, defaultValue, errorPath, data: shippingZoneRate });
    }

    /**
     * Удаление рейта
     * @param {String} id - id рейта
     * @returns {Promise} null
     */

    async removeOne(id: string): Promise<[CustomError | null, null]> {
        const url: string = `/rates/${id}`;
        const defaultValue = null;
        const errorPath: string = '[api:shippingZoneRate:removeOne]';
        return await apiClient.admin.delete({ url, defaultValue, errorPath });
    }
}

export default new ShippingZoneRateService();
