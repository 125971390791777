import { uuid } from 'vue-uuid';
import initFields from '@/entities/delivery/Location.fields';
import countries from '@/helpers/countries';

/**
 * Класс экземпляра пункта
 */
export default class Location {
    fields = initFields(this);
    /**
     *
     * @param {Object}  data - объект данных пункта
     */
    constructor(
        data = {
            id: uuid.v4(),
            enabled: true,
            name: '',
            country: '',
            region: '',
            city: '',
            address: '',
            apartment: '',
            postalCode: '',
            phone: '',
            pickupSettings: true,
            pickupExpectedTime: '',
            pickupNotification: '',
            createdAt: new Date(),
        }
    ) {
        this.id = data.id;
        this.enabled = data.enabled;
        this.name = data.name;
        this.country = data.country;
        this.region = data.region;
        this.city = data.city;
        this.address = data.address;
        this.apartment = data.apartment;
        this.postalCode = data.postalCode;
        this.phone = data.phone;
        this.pickupSettings = data.pickupSettings;
        this.pickupExpectedTime = data.pickupExpectedTime;
        this.pickupNotification = data.pickupNotification;
        this.createdAt = data.createdAt;
    }
    get data() {
        return {
            id: this.id,
            enabled: this.enabled,
            name: this.name,
            country: this.country,
            region: this.region,
            city: this.city,
            address: this.address,
            apartment: this.apartment,
            postalCode: this.postalCode,
            phone: this.phone,
            pickupSettings: this.pickupSettings,
            pickupExpectedTime: this.pickupExpectedTime,
            pickupNotification: this.pickupNotification,
        };
    }
    getDynamicLabels(code) {
        if (!code) return;
        const country = countries.find((country) => {
            return country.code === code;
        });
        this.fields.postalCode.props.labelTrans = country.labels.postalCode;
        this.fields.region.props.labelTrans = country.labels.zone;
    }
}
