import router from '@/router';
import { AuthTypes, removeNamespace } from '@/store/Types.js';
import { reset } from '@/api/auth';
import detectInstanceId from '@/api/detectInstanceId';

const Types = removeNamespace(AuthTypes);

const state = {
    token: null,
    id: '',
    email: '',
    lastFailedRequest: null,
    role: '',
    backendErrors: null,
};

const mutations = {
    [Types.mutations.ADD_LAST_FAILED_REQUEST](state, request) {
        state.lastFailedRequest = request;
    },
};

const actions = {
    [Types.actions.LOGOUT]() {
        reset().then(() => {});
        router.push({ name: 'Login', params: { instanceid: detectInstanceId() } }).then(() => {});
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
