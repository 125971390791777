<template>
    <div class="builder-section-current-list-item" :class="classes" v-on="$listeners" @click="onClick">
        <div class="builder-section-current-list-item__icon" :class="{ 'no-icon': noTouch }">
            <span class="material-symbols-outlined">drag_indicator</span>
        </div>
        <div class="builder-section-current-list-item__icon" :class="{ 'no-icon': noIcon }">
            <span class="material-symbols-outlined">{{ icon }}</span>
        </div>
        <div class="builder-section-current-list-item__text">
            <span class="builder-section-current-list-item__text-title">{{ title }}</span>
            <span v-if="note" class="builder-section-current-list-item__text-note">{{ note }}</span>
        </div>
        <div v-if="hasChild" class="builder-section-current-list-item__more">
            <span class="material-symbols-outlined">expand_more</span>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import icons from '@/components/builder/sections/icons';

export default {
    name: 'BuilderSectionCurrentListItem',
    props: {
        element: { type: Object, default: () => ({}) },
        type: { type: String, default: 'section' },
        noIcon: { type: Boolean },
        noTouch: { type: Boolean },
        noHover: { type: Boolean },
        hasChild: { type: Boolean, default: false },
    },
    data() {
        return {
            isOpen: false,
        };
    },
    created() {
        if (this.element.isNew && this.hasChild) this.isOpen = true;
    },
    computed: {
        ...mapGetters('builder', {
            activeItem: 'getActiveItem',
        }),
        isActive() {
            if (!this.element.id) return false;
            const isActiveSection = this.element.id === this.activeItem.section;
            const isActiveSectionItem = this.element.id === this.activeItem.sectionItem;
            return isActiveSection || isActiveSectionItem;
        },
        icon() {
            if (this.element.hidden || this.element.isShownByConfig === false) return 'visibility_off';
            const iconKey = Object.keys(icons).find((icon) => this.element.type.includes(icon));
            return iconKey ? icons[iconKey] : icons.default;
        },
        title() {
            if (this.element.type === 'Header') return this.$t('builder.sidebarLeft.header');
            if (this.element.type === 'Footer') return this.$t('builder.sidebarLeft.footer');
            const useType = ['SectionBreadcrumbs', 'SectionHeadingH1', 'SectionProductList'];
            if (useType.includes(this.element.type)) return this.$t(`builder.sections.${this.element.type}`);
            const options = this.element.options;
            const optionTitle = options?.heading?.title?.value || options?.product?.value?.title;
            return optionTitle ? optionTitle : this.$t(`builder.sections.noHeading`);
        },
        note() {
            if (this.element.type === 'Header' || this.element.type === 'Footer') return '';
            const useType = ['SectionBreadcrumbs', 'SectionHeadingH1', 'SectionProductList'];
            if (useType.includes(this.element.type)) return '';
            return this.$t(`builder.sections.${this.element.type}`);
        },
        classes() {
            return {
                'is-active': this.isActive,
                'no-hover': this.noHover,
                'is-open': this.isOpen,
                'is-close': !this.isOpen,
                'is-hidden': this.element.hidden || this.element.isShownByConfig === false,
            };
        },
    },
    methods: {
        ...mapActions('builder', {
            updateActiveItem: 'updateActiveItem',
        }),
        openItemSettings() {
            if (this.element.id)
                this.updateActiveItem({
                    type: this.type,
                    data: this.element.id,
                });
        },
        onClick() {
            this.openItemSettings();
            if (this.hasChild) this.isOpen = !this.isOpen;
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables';
@import '@/scss/variables/system-colors';

.builder-section-current-list-item {
    display: flex;
    align-items: center;
    padding: 16px;
    cursor: pointer;
    &:hover {
        background: $item-hover;
    }
    &.no-hover:hover {
        background: transparent;
    }
    &.is-active {
        color: $primary;
        background: $item-active;
    }

    &__icon {
        display: flex;
        align-items: center;
        padding: 0 12px 0 0;
        &.no-icon {
            display: none;
        }
        span {
            font-size: 22px;
            position: relative;
            top: 1px;
        }
    }
    &__text {
        flex: 1 1 auto;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        cursor: pointer;

        &-title {
            display: block;
            font-size: 1.1429rem;
            line-height: 1.2429rem;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
        &-note {
            display: block;
            font-size: 12px;
            line-height: 14px;
            opacity: 0.8;
        }
    }
    &__more {
        display: flex;
        align-items: center;
        margin-left: auto;
        span {
            transition: all 0.3s ease;
        }
    }
    &.is-open &__more {
        span {
            transform: rotate(180deg);
        }
    }
    &.is-hidden {
        opacity: 0.6;
        &.is-active {
            opacity: 1;
        }
    }
}
</style>
