<template>
    <footer class="footer">
        <span class="font-weight-medium"
            >© {{ new Date().getFullYear() }} — RUNSHOP by <a href="https://www.beontop.ae/">BeOnTop</a></span
        >
    </footer>
</template>
<script>
export default {
    name: 'Footer',
    data: () => ({}),
};
</script>
<style lang="scss">
@import '@/scss/variables.scss';
.footer {
    border-top: 1px solid rgba(0, 0, 0, 0.04) !important;
    border-left: 1px solid rgba(0, 0, 0, 0.08) !important;
    margin-left: 300px;
    position: fixed;
    left: 0px;
    right: 0px;
    bottom: 0px;
    align-items: center;
    display: flex;
    flex: 0 1 auto !important;
    flex-wrap: wrap;
    padding: 6px 40px;
    height: 45px;
    box-sizing: content-box;
    background-color: var(--v-surface-base);
    z-index: 10;
}
.theme--light.v-footer {
    background: white;
    z-index: 10;
}
</style>
