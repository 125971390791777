<template>
    <div :name="name" class="switch" :class="{ '-disabled': disabled }" @click="handleClick">
        <label class="switch__button" :class="classObject">
            <input type="checkbox" disabled :checked="model" />
            <span class="slider round"></span>
        </label>
        <span class="switch__text" v-if="text">{{ $t(text) }}</span>
    </div>
</template>

<script>
import { proxyModelMixin } from '@/mixins/proxyModelMixin';

export default {
    name: 'InputSwitch',
    mixins: [proxyModelMixin],
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        size: {
            type: String,
            default: 'medium',
            validator: function(value) {
                return ['small', 'medium', 'large'].includes(value);
            },
        },
        disabled: {
            type: Boolean,
        },
        name: {
            type: String,
        },
        group: {
            type: String,
        },
        text: {
            type: String,
        },
    },
    methods: {
        handleClick() {
            if (!this.disabled) {
                this.model = !this.model;
            }
        },
    },
    computed: {
        classObject() {
            return {
                '-disabled': this.disabled,
                '-small': this.size === 'small',
                '-medium': this.size === 'medium',
                '-large': this.size === 'large',
            };
        },
    },
    watch: {
        value: function(newValue) {
            this.$emit('update-field', {
                name: this.name,
                group: this.group,
                value: newValue,
            });
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.switch {
    display: inline-flex;
    align-items: center;
    flex-grow: 0 !important;
    flex-shrink: 0 !important;
    justify-content: start;
    gap: 7px;
    &:hover {
        cursor: pointer;
    }
    input {
        opacity: 0;
        width: 0;
        height: 0;
        &:checked + .slider {
            background-color: var(--v-primary-base);
        }
    }

    .slider {
        position: absolute;
        cursor: pointer;
        background-color: var(--v-on-surface-medium-base);
        opacity: 1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        &:before {
            position: absolute;
            content: '';
            background-color: var(--v-on-primary-high-base);
            -webkit-transition: 0.4s;
            transition: 0.4s;
        }
    }

    /* Rounded sliders */
    .slider.round {
        border-radius: 34px;
    }

    .slider.round:before {
        border-radius: 50%;
    }
    &.-disabled {
        .slider {
            background-color: var(--v-on-surface-disabled-base) !important;
        }
        .slider:before {
            background-color: var(--v-on-primary-high-base) !important;
        }
        &:hover {
            .slider:before {
                box-shadow: none !important;
            }
        }
    }
    &__button {
        position: relative;
        display: inline-block;
        &:hover {
            .slider:before {
                box-shadow: 0 0 0 6px var(--v-primary-base);
            }
        }
        &.-large {
            width: 60px;
            height: 34px;
            flex-basis: 60px;
            .slider:before {
                height: 26px;
                width: 26px;
                left: 4px;
                bottom: 4px;
            }
            input:checked + .slider:before {
                -webkit-transform: translateX(26px);
                -ms-transform: translateX(26px);
                transform: translateX(26px);
            }
        }
        &.-medium {
            width: 48px;
            height: 28px;
            flex-basis: 48px;
            .slider:before {
                height: 20px;
                width: 20px;
                left: 4px;
                bottom: 4px;
            }
            input:checked + .slider:before {
                -webkit-transform: translateX(20px);
                -ms-transform: translateX(20px);
                transform: translateX(20px);
            }
        }
        &.-small {
            width: 30px;
            height: 18px;
            flex-basis: 30px;
            .slider:before {
                height: 14px;
                width: 14px;
                left: 2px;
                bottom: 2px;
            }
            input:checked + .slider:before {
                -webkit-transform: translateX(12px);
                -ms-transform: translateX(12px);
                transform: translateX(12px);
            }
            &:hover {
                .slider:before {
                    box-shadow: 0 0 0 3px var(--v-primary-base);
                }
            }
        }
    }
}
</style>
