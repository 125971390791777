import ApiClient from '@/api/apiClient';
import CustomError from '@/entities/customError';

class BillingService {
    /**
     * Получение всзе доменов инстанса
     * @param {String} instanceId - id инстанса
     * @returns {Promise} массив объектов доменов
     */
    async getInstanceSubscription(instanceId: string): Promise<[CustomError | null, {}]> {
        const url: string = `/instances/${instanceId}`;
        const defaultValue = {};
        const errorPath: string = '[api:billing:getInstanceSubscription]';
        return await ApiClient.account.get({ url, defaultValue, errorPath });
    }

    async getBillingPortalSession(): Promise<[CustomError | null, {}]> {
        const url: string = `/subscription/billing`;
        const defaultValue = {};
        const errorPath: string = '[api:billing:getBillingPortalSession]';
        const data: Object = {
            returnUrl: document.location.origin,
        };
        return await ApiClient.account.post({ url, defaultValue, errorPath, data });
    }
}

export default new BillingService();
