export default () => ({
    title: {
        component: 'input-text',
        props: {
            rules: ['noEmpty'],
            labelTrans: 'entities.label.fields.title',
            errors: [],
        },
    },
    position: {
        component: 'input-text',
        props: {
            labelTrans: 'entities.label.fields.position',
            class: '-half-width',
            errors: [],
        },
    },
    color: {
        component: 'Select',
        props: {
            rules: ['noEmpty'],
            labelTrans: 'entities.label.fields.color',
            class: '-half-width',
            items: ['accent1', 'accent2', 'accent3', 'accent4'],
            errors: [],
        },
    },
});
