<template>
    <div class="customize-padding">
        <div class="customize-padding__switches">
            <input-switch
                v-model="isCustom"
                @click="onSwitch"
                :text="$t('entities.customize')"
                size="small"
            ></input-switch>
            <input-switch
                v-if="!isCustom"
                v-model="resetTop"
                @click="onResetTop"
                :text="$t('entities.padding.resetTop')"
                size="small"
            />
            <input-switch
                v-if="!isCustom"
                v-model="resetBottom"
                @click="onResetBottom"
                :text="$t('entities.padding.resetBottom')"
                size="small"
            />
        </div>
        <div v-if="isCustom" class="customize-padding__container" :class="{ '-disabled': disabled }">
            <template v-for="position in positions">
                <div :key="position" class="customize-padding__item" :class="position">
                    <input-number
                        :min="min"
                        :max="max"
                        :step="step"
                        v-model="padding[position]"
                        :disabled="disabled"
                        size="small"
                        :orientation="position === 'right' || position === 'left' ? 'vertical' : 'horizontal'"
                    />
                </div>
            </template>
            <div class="customize-padding__preview">Content</div>
        </div>
    </div>
</template>

<script>
import { proxyModelMixin } from '@/mixins/proxyModelMixin';

export default {
    name: 'CustomizePadding',
    inheritAttrs: false,
    mixins: [proxyModelMixin],
    props: {
        name: { type: String, default: '' },
        group: { type: String, default: null },
        type: { type: String, default: 'text' },
        placeholder: { type: String, default: '' },
        errors: { type: [Array, Object], default: () => [] },
        value: { type: [Object], default: () => {} },
        min: { type: [Number], default: 0 },
        max: { type: [Number], default: 60 },
        step: { type: [Number], default: 1 },
        disabled: { type: Boolean, default: false },
    },
    model: {
        prop: 'value',
        event: 'change-value',
    },
    data() {
        return {
            positions: ['top', 'right', 'bottom', 'left'],
            isCustom: this.checkIsCustom(),
            resetTop: false,
            resetBottom: false,
            curent: null,
            config: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
            },
        };
    },
    mounted() {
        this.curent = { ...this.config, ...this.value };
        this.setSwitches();
        this.$emit('change-value', this.getValue());
    },
    computed: {
        padding() {
            return this.value ? this.value : this.curent;
        },
    },
    methods: {
        setSwitches() {
            if (!this.value) return;
            const keys = Object.keys(this.value);
            if (keys.length > 2) return (this.isCustom = true);
            if (keys.includes('bottom') && keys.includes('top')) {
                this.resetBottom = true;
                this.resetTop = true;
            }
            if (keys.includes('top')) this.resetTop = true;
            if (keys.includes('bottom')) this.resetBottom = true;
        },
        onSwitch() {
            this.isCustom = !this.isCustom;
        },
        onResetTop() {
            this.resetTop = !this.resetTop;
        },
        onResetBottom() {
            this.resetBottom = !this.resetBottom;
        },
        getValue() {
            if (!this.isCustom) {
                this.curent = { ...this.config, ...this.value };
                if (this.resetTop && this.resetBottom) return { top: 0, bottom: 0 };
                if (this.resetTop) return { top: 0 };
                if (this.resetBottom) return { bottom: 0 };
                return null;
            }
            return { ...this.curent };
        },
        checkIsCustom() {
            return typeof this.value?.left === 'number';
        },
    },
    watch: {
        isCustom() {
            this.$emit('change-value', this.getValue());
        },
        resetTop() {
            this.$emit('change-value', this.getValue());
        },
        resetBottom() {
            this.$emit('change-value', this.getValue());
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';

.customize-padding {
    $gap: 8px;
    position: relative;
    &__switches {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }
    &__container {
        position: relative;
        padding: 56px 56px;
        &.-disabled {
            pointer-events: none;
            filter: grayscale(1);
            opacity: 0.7;
            input,
            textarea {
                color: var(--v-on-surface-disabled) !important;
                pointer-events: none;
                cursor: not-allowed;
            }
        }
        &::after,
        &::before {
            content: '';
            position: absolute;
            right: 0;
            bottom: 0;
            display: block;
            background-color: var(--v-primary-lighten-base);
        }
        &::before {
            top: 50%;
            width: 100%;
            height: 1px;
        }
        &::after {
            left: 50%;
            width: 1px;
            height: 100%;
        }
    }
    &__item {
        $space: 10px;
        position: absolute;
        z-index: 1;
        width: 85px;
        &.top,
        &.bottom {
            left: 50%;
            transform: translateX(-50%);
        }
        &.top {
            top: $space;
        }
        &.bottom {
            bottom: $space;
        }
        &.right,
        &.left {
            top: 50%;
            transform: translateY(-50%);
        }
        &.right {
            right: -14px;
        }
        &.left {
            left: -14px;
        }
    }
    &__preview {
        background-color: var(--v-primary-lighten-base);
        position: relative;
        z-index: 1;
        color: $primary;
        text-align: center;
        line-height: $form-control-height;
        height: $form-control-height;
        border: 1px dashed $primary;
        border-radius: $form-control-border-radius;
    }
}
</style>
