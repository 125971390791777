<template>
    <div class="block" :class="{ '-half-width': isHalfWidth }">
        <div class="block__header">
            <slot name="header-prepend"></slot>
            <h4 class="block__header-text">{{ heading }}</h4>
            <slot name="header-append"></slot>
        </div>
        <div class="block__body">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HeadedBlock',
    props: {
        heading: {
            type: String,
            default: '',
        },
        isHalfWidth: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
</style>
