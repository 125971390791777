<template>
    <login-form class="auth-page__form login-page__form" />
</template>

<script>
import LoginForm from '@/components/auth/LoginForm.vue';

export default {
    name: 'LoginPage',
    components: {
        LoginForm,
    },
};
</script>

<style lang="scss"></style>
