import icons from '@/entities/icons';
export default {
    currency: {
        id: null,
        position: 0,
        type: 'select',
        component: 'Select',
        value: 'AED',
        options: {
            variants: ['AED', '$', '€', 'RUB'],
            items: ['AED', '$', '€', 'RUB'], // TODO: выбрать что-то одно items/variant
            labelTrans: 'settings.ecommerce.currency',
        },
    },
    template: {
        id: null,
        position: 1,
        type: 'select',
        component: 'Select',
        value: 3,
        options: {
            variants: [
                { text: 'item-template-1', value: 1 },
                { text: 'item-template-2', value: 2 },
                { text: 'item-template-3', value: 3 },
                { text: 'item-template-4', value: 4 },
                { text: 'template-phone-whatsapp', value: 10 },
                { text: 'template-phone-whatsapp-2', value: 12 },
            ],
            items: [
                { text: 'item-template-1', value: 1 },
                { text: 'item-template-2', value: 2 },
                { text: 'item-template-3', value: 3 },
                { text: 'item-template-4', value: 4 },
                { text: 'template-phone-whatsapp', value: 10 },
                { text: 'template-phone-whatsapp-2', value: 12 },
            ],
            labelTrans: 'settings.ecommerce.template',
        },
    },
    templateListing: {
        id: null,
        position: 2,
        type: 'select',
        component: 'Select',
        value: 1,
        options: {
            variants: [
                { text: 'item-listing-template-1', value: 1 },
                { text: 'item-listing-template-2', value: 2 },
                { text: 'item-listing-template-3', value: 3 },
                { text: 'template-phone-whatsapp', value: 10 },
                { text: 'template-phone-whatsapp-2', value: 12 },
            ],
            items: [
                { text: 'item-listing-template-1', value: 1 },
                { text: 'item-listing-template-2', value: 2 },
                { text: 'item=listing-template-3', value: 3 },
                { text: 'template-phone-whatsapp', value: 10 },
                { text: 'template-phone-whatsapp-2', value: 12 },
            ],
            labelTrans: 'settings.ecommerce.templateListing',
        },
    },
    itemPerPage: {
        id: null,
        position: 3,
        type: 'number',
        component: 'InputText',
        value: 12,
        options: {
            rules: ['noEmpty'],
            type: 'number',
            labelTrans: 'settings.ecommerce.itemPerPage',
        },
    },
    defaultColumns: {
        id: null,
        type: 'field',
        position: 4,
        value: {
            desktop: 4,
            tablet: 2,
            mobile: 2,
        },
        component: 'CustomizeColumnsTabs',
        ...{
            options: {
                labelTrans: 'settings.ecommerce.defaultColumns',
                ranges: {
                    desktop: [1, 2, 3, 4, 5, 6],
                    tablet: [1, 2, 3, 4],
                    mobile: [1, 2],
                },
            },
        },
    },
    filter: {
        id: null,
        position: 4,
        type: 'object',
        component: 'CompositeField',
        value: {
            enabled: false,
            template: 'style-v-1',
        },
        options: {
            labelTrans: 'settings.ecommerce.filter',
            fields: [
                {
                    name: 'enabled',
                    type: 'boolean',
                    component: 'InputSwitch',
                    value: 'Load More',
                    props: {
                        size: 'small',
                        noLabel: true,
                        text: 'builder.sectionOptions.props.enabled',
                    },
                },
                {
                    name: 'template',
                    type: 'text',
                    component: 'Select',
                    value: 'style-v-1',
                    props: {
                        items: [
                            { text: 'vertical - style 1', value: 'style-v-1' },
                            { text: 'vertical - style 2', value: 'style-v-2' },
                            { text: 'vertical popup - style-1', value: 'style-spoiler-v-1' },
                            { text: 'horizontal spoiler - style-1', value: 'style-spoiler-h-1' },
                        ],
                        labelTrans: 'settings.ecommerce.template',
                    },
                },
            ],
        },
    },
    productDetails: {
        id: null,
        position: 4,
        type: 'object',
        component: 'CompositeField',
        value: {
            templateInProductList: null,
            templateInProductCard: null,
        },
        options: {
            labelTrans: 'settings.ecommerce.productDetails',
            fields: [
                {
                    name: 'templateInProductList',
                    type: 'text',
                    component: 'Select',
                    value: 'none',
                    props: {
                        items: [
                            { text: 'none', value: 'none' },
                            { text: 'style 1', value: 'style-1' },
                        ],
                        labelTrans: 'settings.ecommerce.templateInProductList',
                    },
                },
                {
                    name: 'templateInProductCard',
                    type: 'text',
                    component: 'Select',
                    value: 'none',
                    props: {
                        items: [
                            { text: 'none', value: 'none' },
                            { text: 'style 1', value: 'style-1' },
                        ],
                        labelTrans: 'settings.ecommerce.templateInProductCard',
                    },
                },
            ],
        },
    },
    pageNavigationType: {
        id: null,
        position: 5,
        type: 'select',
        component: 'Select',
        value: 'paging',
        options: {
            items: [
                { text: 'Default Paging', value: 'paging' },
                { text: 'Button Load More', value: 'loadmore' },
                { text: 'Paging + Button Load More', value: 'paging+loadmore' },
            ],
            labelTrans: 'settings.ecommerce.pageNavigationType',
        },
    },
    btnLoadMore: {
        id: null,
        position: 5,
        type: 'object',
        component: 'CompositeField',
        value: {
            text: 'Load More',
        },
        options: {
            labelTrans: 'settings.ecommerce.btnLoadMore',
            fields: [
                {
                    name: 'text',
                    type: 'text',
                    component: 'InputText',
                    value: 'Load More',
                    props: {
                        labelTrans: 'builder.sectionOptions.props.text',
                    },
                },
            ],
            showByCondition: (settings) => {
                const siteType = settings.ecommerce.pageNavigationType.value;
                return siteType === 'loadmore' || siteType === 'paging+loadmore';
            },
        },
    },
    shippingPolicy: {
        id: null,
        position: 5,
        type: 'code',
        component: 'code-editor',
        value: '',
        options: {
            labelTrans: 'settings.ecommerce.shippingPolicy',
        },
    },
    refundPolicy: {
        id: null,
        position: 6,
        type: 'code',
        component: 'code-editor',
        value: '',
        options: {
            labelTrans: 'settings.ecommerce.refundPolicy',
        },
    },
    // imageCrop: {
    //     id: null,
    //     position: 7,
    //     type: 'select',
    //     component: 'Select',
    //     value: 'inset',
    //     options: {
    //         // variants: ['inset', 'outbound'],
    //         // items: ['inset', 'outbound'],
    //         variants: [
    //             {
    //                 text: 'settings.ecommerce.imageCropVariants.inset',
    //                 value: 'inset',
    //             },
    //             {
    //                 text: 'settings.ecommerce.imageCropVariants.outbound',
    //                 value: 'outbound',
    //             },
    //             {
    //                 text: 'settings.ecommerce.imageCropVariants.original',
    //                 value: 'original',
    //             },
    //         ],
    //         items: [
    //             {
    //                 text: 'settings.ecommerce.imageCropVariants.inset',
    //                 value: 'inset',
    //             },
    //             {
    //                 text: 'settings.ecommerce.imageCropVariants.outbound',
    //                 value: 'outbound',
    //             },
    //             {
    //                 text: 'settings.ecommerce.imageCropVariants.original',
    //                 value: 'original',
    //             },
    //         ],
    //         translate: true,
    //         labelTrans: 'settings.ecommerce.imageCrop',
    //     },
    // },
    previewSizeInListing: {
        id: null,
        position: 2,
        type: 'select',
        component: 'SelectPreviewSizeList',
        value: '600x600',
        options: {
            variants: ['600x600', '600x800', '800x600', '800x800', 'custom'],
            items: ['600x600', '600x800', '800x600', '800x800', 'custom'],
            labelTrans: 'settings.ecommerce.previewSizeInListing',
            // disabledBy: 'ecommerce.imageCrop',
        },
    },
    previewCustomSizeInListing: {
        id: null,
        position: 2,
        type: 'string',
        component: 'InputRatio',
        value: '600x600',
        options: {
            min: 1,
            max: 1000,
            labelTrans: 'settings.ecommerce.previewCustomSizeInListing.title',
            note: 'settings.ecommerce.previewCustomSizeInListing.note',
            showByCondition: (settings) => {
                const previewSizeInListing = settings.ecommerce.previewSizeInListing.value;
                return previewSizeInListing === 'custom';
            },
            validateFunction: (value) => {
                const regexp = new RegExp('^\\d+x\\d+$');
                if (regexp.test(value)) {
                    const values = value.split('x');
                    return values.some((item) => item < 1 || item > 1000) ? ['validator.errors.resolution'] : [];
                } else return ['validator.errors.resolution'];
            },
        },
    },
    previewSizeInItem: {
        id: null,
        position: 2,
        type: 'select',
        component: 'Select',
        value: '1x1',
        options: {
            variants: ['10x16', '3x4', '1x1', '4x3', '16x10', 'custom'],
            items: ['10x16', '3x4', '1x1', '4x3', '16x10', 'custom'],
            labelTrans: 'settings.ecommerce.previewSizeInItem',
            // disabledBy: 'ecommerce.imageCrop',
        },
    },
    previewCustomSizeInItem: {
        id: null,
        position: 2,
        type: 'string',
        component: 'InputRatio',
        value: '1x1',
        options: {
            labelTrans: 'settings.ecommerce.previewCustomSizeInItem.title',
            note: 'settings.ecommerce.previewCustomSizeInItem.note',
            min: 1,
            max: 1000,
            showByCondition: (settings) => {
                const previewSizeInItem = settings.ecommerce.previewSizeInItem.value;
                return previewSizeInItem === 'custom';
            },
            validateFunction: (value) => {
                const regexp = new RegExp('^\\d+x\\d+$');
                if (regexp.test(value)) {
                    const values = value.split('x');
                    return values.some((item) => item < 1 || item > 1000) ? ['validator.errors.resolution'] : [];
                } else return ['validator.errors.resolution'];
            },
        },
    },
    skuIsShow: {
        id: null,
        position: 8,
        type: 'boolean',
        component: 'input-checkbox',
        value: false,
        options: {
            noLabel: true,
            text: 'settings.ecommerce.skuIsShow',
        },
    },
    showPriceForOutOfStock: {
        id: null,
        position: 8,
        type: 'boolean',
        component: 'input-checkbox',
        value: false,
        options: {
            noLabel: true,
            text: 'settings.ecommerce.showPriceForOutOfStock',
        },
    },
    /*showLeftSidebarInCatalog: {
        id: null,
        position: 9,
        type: 'boolean',
        component: 'input-checkbox',
        value: false,
        options: {
            noLabel: true,
            text: 'settings.ecommerce.showLeftSidebarInCatalog',
        },
    },*/
    showSortingInCatalog: {
        id: null,
        position: 10,
        type: 'boolean',
        component: 'input-checkbox',
        value: false,
        options: {
            noLabel: true,
            text: 'settings.ecommerce.showSortingInCatalog',
        },
    },
    showRelatedProducts: {
        id: null,
        position: 11,
        type: 'boolean',
        component: 'input-checkbox',
        value: false,
        options: {
            noLabel: true,
            text: 'settings.ecommerce.showRelatedProducts',
        },
    },
    leadsPopupTitle: {
        id: null,
        position: 12,
        type: 'string',
        component: 'InputText',
        value: '',
        options: {
            text: 'settings.ecommerce.leadsPopupTitle',
            labelTrans: 'settings.ecommerce.leadsPopupTitle',
        },
    },
    showLeadsPopupPolicy: {
        id: null,
        position: 13,
        type: 'boolean',
        component: 'input-checkbox',
        value: false,
        options: {
            noLabel: true,
            text: 'settings.ecommerce.showLeadsPopupPolicy',
        },
    },
    leadsPopupEnabledByDefault: {
        id: null,
        position: 13,
        type: 'boolean',
        component: 'input-checkbox',
        value: true,
        options: {
            noLabel: true,
            text: 'settings.ecommerce.leadsPopupEnabledByDefault',
        },
    },
    leadsPopupPolicyText: {
        id: null,
        position: 14,
        type: 'code',
        component: 'code-editor',
        value: '',
        options: {
            labelTrans: 'settings.ecommerce.leadsPopupPolicyText',
        },
    },
    showCheckoutPolicy: {
        id: null,
        position: 15,
        type: 'boolean',
        component: 'input-checkbox',
        value: true,
        options: {
            noLabel: true,
            text: 'settings.ecommerce.showCheckoutPolicy',
        },
    },
    checkoutPolicyEnabledByDefault: {
        id: null,
        position: 16,
        type: 'boolean',
        component: 'input-checkbox',
        value: true,
        options: {
            noLabel: true,
            text: 'settings.ecommerce.checkoutPolicyEnabledByDefault',
        },
    },
    checkoutPolicyText: {
        id: null,
        position: 16,
        type: 'code',
        component: 'code-editor',
        value: '',
        options: {
            labelTrans: 'settings.ecommerce.checkoutPolicyText',
        },
    },
    checkoutFormMessage: {
        id: null,
        position: 17,
        type: 'code',
        component: 'code-editor',
        value: '',
        options: {
            labelTrans: 'settings.ecommerce.checkoutFormMessage',
        },
    },
    checkoutOrderMessage: {
        id: null,
        position: 18,
        type: 'code',
        component: 'code-editor',
        value: '',
        options: {
            labelTrans: 'settings.ecommerce.checkoutOrderMessage',
        },
    },
    showCheckoutReceiver: {
        id: null,
        position: 19,
        type: 'boolean',
        component: 'input-checkbox',
        value: false,
        options: {
            noLabel: true,
            text: 'settings.ecommerce.showCheckoutReceiver',
        },
    },
    fastOrder: {
        id: null,
        position: 20,
        type: 'Button',
        component: 'ButtonBuilder',
        value: { type: 'enquiry' },
        options: {
            icons: [...icons],
            types: [{ text: 'enquiry', value: 'enquiry' }],
            labelTrans: 'settings.ecommerce.fastOrder',
        },
    },
    productWidgetRelatedPosition: {
        id: null,
        position: 21,
        type: 'select',
        component: 'Select',
        value: 'none',
        options: {
            items: [
                { text: 'top', value: 'top' },
                { text: 'bottom', value: 'bottom' },
                { text: 'none', value: 'none' },
            ],
            labelTrans: 'settings.ecommerce.productWidgetRelatedPosition',
        },
    },
    productReviewsEnabled: {
        id: null,
        position: 22,
        type: 'boolean',
        component: 'input-checkbox',
        value: true,
        options: {
            noLabel: true,
            text: 'settings.ecommerce.productReviewsEnabled',
        },
    },
    showQuantity: {
        id: null,
        position: 23,
        type: 'select',
        component: 'Select',
        value: 'always',
        options: {
            variants: [
                { text: 'Always', value: 'always' },
                { text: 'Never', value: 'never' },
                { text: 'Only if finite', value: 'onlyIfFinite' },
            ],
            items: [
                { text: 'Always', value: 'always' },
                { text: 'Never', value: 'never' },
                { text: 'Only if finite', value: 'onlyIfFinite' },
            ],
            labelTrans: 'settings.ecommerce.showQuantity',
        },
    },
    showPriceFrom: {
        id: null,
        position: 24,
        type: 'boolean',
        component: 'input-checkbox',
        value: false,
        options: {
            noLabel: true,
            text: 'settings.ecommerce.showPriceFrom',
            showByCondition: (settings) => {
                const siteType = settings.common.type.value;
                return siteType === 'service' || siteType === 'services_cart';
            },
        },
    },
    priceFormatSeparator: {
        id: null,
        position: 25,
        type: 'select',
        component: 'Select',
        value: 'none',
        options: {
            variants: ['none', 'comma', 'space'],
            items: ['none', 'comma', 'space'],
            labelTrans: 'settings.ecommerce.priceFormatSeparator',
        },
    },
    modalAgeVerification: {
        id: null,
        position: 50,
        type: 'object',
        component: 'CompositeField',
        value: {
            enabled: false,
            title: 'Are you 18 years of age or older?',
            text:
                'You must be 18 years or older to access and use this website. By clicking "Yes" below, you certify that you are 18 years or older.',
            btnConfirm: 'Yes',
            btnReject: 'No',
        },
        options: {
            labelTrans: 'settings.ecommerce.modalAgeVerification',
            fields: [
                {
                    name: 'enabled',
                    type: 'boolean',
                    component: 'InputSwitch',
                    value: 'Load More',
                    props: {
                        size: 'small',
                        noLabel: true,
                        text: 'builder.sectionOptions.props.enabled',
                    },
                },
                {
                    name: 'title',
                    type: 'text',
                    component: 'InputText',
                    value: 'Load More',
                    props: {
                        labelTrans: 'builder.sectionOptions.props.title',
                    },
                },
                {
                    name: 'text',
                    type: 'text',
                    component: 'Textarea',
                    value: 'Load More',
                    props: {
                        labelTrans: 'builder.sectionOptions.props.text',
                    },
                },
                {
                    name: 'btnConfirm',
                    type: 'text',
                    component: 'InputText',
                    value: 'Load More',
                    props: {
                        labelTrans: 'builder.sectionOptions.props.btnConfirm',
                    },
                },
                {
                    name: 'btnReject',
                    type: 'text',
                    component: 'InputText',
                    value: 'Load More',
                    props: {
                        labelTrans: 'builder.sectionOptions.props.btnReject',
                    },
                },
            ],
        },
    },
    modalCookieAccept: {
        id: null,
        position: 51,
        type: 'object',
        component: 'CompositeField',
        value: {
            enabled: false,
            title: 'This website uses cookies',
            text:
                "We use essential cookies to make our site work. With your consent, we may also use non-essential cookies to improve user experience and analyze website traffic. By clicking “Accept”, you agree to our website's cookie use as described in our Cookie Policy. You can change your cookie settings at any time by clicking “Preferences.”",
            btnConfirm: 'Accept',
        },
        options: {
            labelTrans: 'settings.ecommerce.modalCookieAccept',
            fields: [
                {
                    name: 'enabled',
                    type: 'boolean',
                    component: 'InputSwitch',
                    value: 'Load More',
                    props: {
                        size: 'small',
                        noLabel: true,
                        text: 'builder.sectionOptions.props.enabled',
                    },
                },
                {
                    name: 'title',
                    type: 'text',
                    component: 'InputText',
                    value: 'Load More',
                    props: {
                        labelTrans: 'builder.sectionOptions.props.title',
                    },
                },
                {
                    name: 'text',
                    type: 'text',
                    component: 'Textarea',
                    value: 'Load More',
                    props: {
                        labelTrans: 'builder.sectionOptions.props.text',
                    },
                },
                {
                    name: 'btnConfirm',
                    type: 'text',
                    component: 'InputText',
                    value: 'Load More',
                    props: {
                        labelTrans: 'builder.sectionOptions.props.btnConfirm',
                    },
                },
            ],
        },
    },
};
