













// import BillingPaymentMethods from '@/components/billing/paymentMethods/index.vue';
import BillingSubscription from '@/components/billing/subscription/index.vue';
import { mapGetters } from 'vuex';
// import BillingInvoices from '@/components/billing/invoices.vue';

export default {
    name: 'BillingPage',
    // components: { BillingInvoices, BillingSubscription, BillingPaymentMethods },
    components: { BillingSubscription },
    computed: {
        ...mapGetters('globalVars', {
            instance: 'getInstance',
        }),
    },
};
