<template>
    <div class="page -column -with-sidebar-right">
        <progress-circular class="entity-item__spinner spinner" v-if="!isDataLoaded && isAvailable"></progress-circular>
        <template v-if="!isAvailable">
            <div class="page-header">
                <h1 class="page-header__text">{{ $t('entities.page.newItem') }}</h1>
            </div>
            <error-block></error-block>
        </template>
        <template v-else-if="isDataLoaded">
            <page-item-sidebar :page="currentState" @change="onChangePage"></page-item-sidebar>
            <sticky-toolbar>
                <Button icon="check" :disabled="!isSaveAllowed" @click="isEditing ? onUpdatePage() : onCreatePage()">{{
                    $t('entities.save')
                }}</Button>
                <a
                    target="_blank"
                    v-if="isEditing"
                    :href="`https://${instanceFull.primaryDomain}/${currentState.slug}`"
                >
                    <Button type="outlined" icon="visibility">{{ $t('entities.view') }}</Button>
                </a>
                <Button
                    v-if="isEditing"
                    @click="
                        $router.push({
                            name: 'Builder Edit Page',
                            params: { id: currentState.id, type: currentState.type },
                        })
                    "
                    type="outlined"
                    icon="edit"
                    >{{ $t('entities.editInBuilder') }}</Button
                ><!--
                TODO: пока нет метода API-->
                <!--                <Button type="outlined" disabled icon="content_copy" v-if="isEditing">{{
                    $t('entities.duplicate')
                }}</Button>-->
                <Button type="outlined" icon="delete" v-if="isEditing" @click="openDeletionModal">{{
                    $t('entities.remove')
                }}</Button>
            </sticky-toolbar>
            <div class="page-header entity-item__page-header">
                <h1 class="page-header__text">
                    {{ pageId ? currentState.title : $t('entities.page.newItem') }}
                </h1>
            </div>
            <alert
                type="success"
                class="entity-item__success-alert"
                :class="{ '-break-line': isCreated }"
                v-if="isCreated || isUpdated"
            >
                {{
                    isCreated
                        ? $tc('notifications.created', 1, { entity: this.$tc('entities.page.title', 1) })
                        : $tc('notifications.updated', 1, { entity: this.$tc('entities.page.title', 1) })
                }}
                <br />
                <a class="entity-item_create-another alert__link" @click="onAddMoreOne" v-if="isCreated">
                    {{ $t('entities.page.alertText.createAnother') }}</a
                >
            </alert>
            <template>
                <headed-block v-if="pageId" :heading="$t('entities.blocks.content')" class="edit-content-block">
                    <template v-slot:header-append
                        ><Button
                            :to="{ name: 'Builder Edit Page', params: { id: pageId, type: 'textpage' } }"
                            icon="edit"
                            >{{ $t('entities.editInBuilder') }}</Button
                        ></template
                    >
                </headed-block>
                <headed-block class="entity-item__block image-block" :heading="$t('entities.blocks.image')">
                    <image-field image-type="page" v-model="currentState.previewImageId" is-popup></image-field>
                </headed-block>
                <headed-block
                    class="entity-item__block"
                    v-for="(block, index) in blocks"
                    :style="{ order: index + 1 }"
                    :key="block.title"
                    :heading="block.title"
                >
                    <div class="form">
                        <div v-for="(item, key) in block.items" class="form__input" :class="item.class" :key="key">
                            <FormItem v-bind="item.props" :name="key">
                                <component
                                    v-if="key === 'pageStatus'"
                                    :is="item.component"
                                    v-model="currentState"
                                    v-on="getFieldEvents(item.events)"
                                    v-bind="item.props || {}"
                                    :name="key"
                                ></component>
                                <component
                                    v-else
                                    :is="item.component"
                                    :key="key === 'slug' ? slugKey : null"
                                    v-model="currentState[key]"
                                    v-bind="item.props || {}"
                                    v-on="getFieldEvents(item.events)"
                                    @update-field="onUpdateFieldValue"
                                    :name="key"
                                    hide-details
                                    outlined
                                    dense
                                ></component>
                            </FormItem>
                        </div>
                        <form-item
                            v-if="block.title === 'SEO'"
                            :label="$t('entities.page.fields.googlePreview')"
                            class="form__input"
                        >
                            <MetaPreview
                                v-bind="block.props"
                                :header="metaTitle"
                                :link="`https://${instanceFull.primaryDomain}/${currentState.slug}`"
                                :description="metaDescription"
                            />
                        </form-item>
                    </div>
                </headed-block>
            </template>
        </template>
    </div>
</template>

<script>
import Page from '@/entities/page/Page';
import { capitalize } from '@/helpers/filters';
import { cloneDeep, isEqual, debounce } from 'lodash';
import validateField from '@/helpers/validator';
import MetaPreview from '@/components/product/ProductMetaPreview';
import ProductImageManager from '@/components/product/ProductImageManager';
import PageService from '@/services/PageService';
import DeletionModal from '@/components/common/DeletionModal';
import EventEmitter from '@/helpers/eventEmitter.ts';
import StickyToolbar from '@/components/common/StickyToolbar';
import HeadedBlock from '@/components/common/HeadedBlock';
import ErrorBlock from '@/components/common/ErrorBlock';
import FormItem from '@/components/form/item.vue';
import ImageField from '@/components/form/controls/ImageField';
import PageItemSidebar from '@/views/page/PageItemSidebar';
import Button from '@/components/common/Button';
import ProgressCircular from '@/components/common/ProgressCircular';
import Alert from '@/components/common/Alert';
import InputUrl from '@/components/form/controls/InputUrl';
import { mapGetters } from 'vuex';

export default {
    name: 'PageItem',
    components: {
        Alert,
        ProgressCircular,
        PageItemSidebar,
        ImageField,
        ErrorBlock,
        HeadedBlock,
        StickyToolbar,
        DeletionModal,
        MetaPreview,
        ProductImageManager,
        FormItem,
        Button,
        InputUrl,
    },
    filters: { capitalize },

    async created() {
        const isCreated = localStorage.getItem('pageCreated');
        if (isCreated) {
            this.isCreated = true;
            localStorage.removeItem('pageCreated');
        }
        await this.loadData();
        EventEmitter.on('delete-page', this.deletePage);
    },

    beforeDestroy() {
        EventEmitter.off('delete-page', this.deletePage);
    },
    data() {
        const newPage = new Page();
        return {
            isDataLoaded: false,
            isSaveAllowed: false,
            currentState: newPage,
            initialState: null,
            fieldKeys: Object.keys(newPage.fields),
            images: [],
            isAvailable: true,
            isUpdated: false,
            isCreated: false,
            slugKey: 0,
        };
    },
    computed: {
        ...mapGetters('globalVars', { instanceFull: 'getInstanceFull' }),
        ...mapGetters('config', { sitename: 'getSitename' }),
        metaAvailableVariables() {
            return {
                '%shop_name%': this.sitename?.value || '',
                '%page_name%': this.currentState.title,
            };
        },
        isEditing() {
            return this.$route.name !== 'Create Page';
        },
        pageId() {
            return this.$route.params.id;
        },
        currency() {
            return this.$store.getters['config/getCurrency'];
        },
        seo() {
            return this.$store.getters['config/getSeo'];
        },
        blocks() {
            return [
                {
                    title: this.$t('entities.blocks.generalInformation'),
                    items: {
                        title: this.currentState.fields.title,
                        heading: this.currentState.fields.heading,
                    },
                },
                {
                    title: this.$t('entities.blocks.seo'),
                    items: {
                        metaTitle: this.currentState.fields.metaTitle,
                        metaDescription: this.currentState.fields.metaDescription,
                        slug: {
                            ...this.currentState.fields.slug,
                            props: {
                                ...this.currentState.fields.slug.props,
                                showCheckbox: !!this.pageId,
                            },
                        },
                    },
                },
            ];
        },
        metaTitle() {
            return this.currentState.metaTitle
                ? this.currentState.metaTitle
                : this.formatMeta(this.seo.templatePageTitle.value);
        },
        metaDescription() {
            return this.currentState.metaDescription
                ? this.currentState.metaDescription
                : this.formatMeta(this.seo.templatePageDescription.value);
        },
    },
    methods: {
        createRedirectOnSave(redirect) {
            this.currentState.redirectOnSave = redirect;
        },
        formatMeta(string) {
            const reg = /%(.*?)%/g;
            return string.replace(reg, (match) => {
                return this.metaAvailableVariables[match];
            });
        },

        async loadData() {
            if (this.pageId) {
                const [error, result] = await PageService.getOne(this.pageId);
                if (error) {
                    this.isAvailable = false;
                    this.isDataLoaded = true;
                    return;
                }
                this.currentState = new Page(result);
            }
            this.initialState = cloneDeep(this.currentState);
            this.isDataLoaded = true;
        },

        validateForm() {
            let result = true;
            this.fieldKeys.forEach((key) => {
                const errors = validateField(this.currentState[key], this.currentState.fields[key].props?.rules);
                if (errors.length !== 0) result = false;
                this.$set(this.currentState.fields[key].props, 'errors', errors);
            });
            if (result === false)
                EventEmitter.trigger('show-noty', {
                    type: 'error',
                    text: this.$t('notifications.validation.error'),
                });
            return result;
        },
        async onUpdatePage() {
            const isFormValid = this.validateForm();
            if (isFormValid === false) return;
            const [error, result] = await PageService.updateOne(this.currentState.data);
            error ? this.failedHandler(result) : await this.successUpdateHandler();
        },
        async onCreatePage() {
            const isFormValid = this.validateForm();
            if (isFormValid === false) return;
            const [error, result] = await PageService.createOne(this.currentState.data);
            error ? this.failedHandler(result) : this.successCreateHandler();
        },
        successCreateHandler() {
            // Редиректим на редактирование созданной страницы
            localStorage.setItem('pageCreated', true);
            this.isUpdated = false;
            this.$router.push({
                name: 'Edit Page',
                params: { id: this.currentState.id },
            });
            window.scrollTo({ top: 0, behavior: 'smooth' });
        },
        async successUpdateHandler() {
            this.isCreated = false;
            this.isUpdated = true;
            this.slugKey++;
            window.scrollTo({ top: 0, behavior: 'smooth' });
            await this.loadData();
            this.isSaveAllowed = false;
        },
        failedHandler(responce) {
            const children = responce.data?.errors?.children;
            if (children) {
                this.fieldKeys.forEach((key) => {
                    const errors = children[key] ? children[key].errors : [];
                    if (errors) this.$set(this.currentState.fields[key].props, 'errors', errors);
                });
            }
            EventEmitter.trigger('show-noty', {
                type: 'error',
                text: `Error occurred. Status ${responce.error.statusCode}: ${responce.error.message}`,
            });
        },
        onUpdateFieldValue: debounce(function(payload) {
            const { name } = payload;
            const errors = validateField(this.currentState[name], this.currentState.fields[name].props?.rules);
            this.$set(this.currentState.fields[name].props, 'errors', errors);
        }, 600),

        async openDeletionModal() {
            this.$store.dispatch('modals/openDeletionModal', { items: this.currentState, entity: 'page' });
        },

        async deletePage(items) {
            const [error] = await PageService.removeOne(items[0].id);
            if (error) {
                error.notify();
            }
            this.$store.dispatch('modals/closeDeletionModal');
            this.$router.push({ name: 'Page List' });
        },

        getFieldEvents(events) {
            const fieldEvents = {};
            for (const key in events) {
                fieldEvents[key] = events[key]?.bind(this.currentState);
            }
            return fieldEvents;
        },
        getAnnotationImg(annotation) {
            return require(`@/assets/images/${annotation}`);
        },
        async onAddMoreOne() {
            this.$router.push({
                name: 'Create Page',
            });
            this.currentState = new Page();
            this.isCreated = false;
            this.isUpdated = false;
            await this.loadData();
        },
        onChangePage(page) {
            this.currentState = cloneDeep(page);
        },
    },
    watch: {
        currentState: {
            handler(val) {
                if (this.initialState) {
                    this.isSaveAllowed = !isEqual(val.data, this.initialState.data);
                }
            },
            deep: true,
        },
    },
};
</script>

<style lang="scss">
.edit-content-block {
    order: 2;
    margin-bottom: 64px;
    .block__body {
        display: none;
    }
}
.image-block {
    order: 2;
}
</style>
