<template>
    <div class="page -width-lg">
        <div class="page-header">
            <h1 class="page-header__text">{{ $t('menus.pageHeaders.pages') }}</h1>
            <Button :to="{ name: 'Create Page' }" icon="add_circle_outline">
                {{ $t('entities.add') }}
            </Button>
        </div>
        <error-block v-if="!isAvailable"></error-block>
        <Table
            class="pages-table"
            v-else
            :search="search"
            :columns="headers"
            :items="items"
            :is-data-loaded="isLoaded"
            :pagination="pagination"
            show-select
            v-model="selected"
            :sort-by="sortBy"
            :sort-desc="sortDesc"
            @update:sort="updateSort"
            @change-per-page="changePerPage"
            @update-search-string="updateSearchString"
            @delete-selected-items="openDeletionModal"
        >
            <template v-slot:selected-actions>
                <template v-if="selected.length">
                    <Button
                        icon="unpublished"
                        type="text"
                        class="table__selected-action"
                        @click="changeSelected('enabled', false)"
                    >
                        {{ $t('lists.actions.disableSelected') }}</Button
                    >
                    <Button
                        type="text"
                        icon="check_circle"
                        class="table__selected-action"
                        @click="changeSelected('enabled', true)"
                    >
                        {{ $t('lists.actions.enableSelected') }}</Button
                    >
                    <Button
                        type="text"
                        icon="visibility_off"
                        class="table__selected-action"
                        @click="changeSelected('hidden', true)"
                    >
                        {{ $t('lists.actions.hideSelected') }}</Button
                    >
                    <Button
                        type="text"
                        icon="visibility"
                        class="table__selected-action"
                        @click="changeSelected('hidden', false)"
                        >{{ $t('lists.actions.showSelected') }}</Button
                    >
                </template>
            </template>
            <!--  ACTIONS  -->
            <template v-slot:item.actions="{ item }">
                <router-link :to="{ name: 'Builder Edit Page', params: { id: item.id, type: 'textpage' } }"
                    ><Button class="builder-btn" type="text">{{ $t('entities.editInBuilder') }}</Button>
                </router-link>
                <a target="_blank" :href="`https://${instanceFull.primaryDomain}/${item.slug}`">
                    <Button icon="open_in_new" :type="'icon'" />
                </a>
                <router-link :to="{ name: 'Edit Page', params: { id: item.id } }">
                    <Button icon="edit" type="icon" />
                </router-link>
                <Button @click="openDeletionModal(item)" icon="delete" type="icon" />
            </template>
            <!-- IMAGE -->
            <template v-slot:item.image="{ item }">
                <img
                    v-if="hasImage(item)"
                    class="table__image"
                    :alt="images[item.id].alt"
                    :src="imageUrl + images[item.id].uri"
                />
                <img v-else class="table__image -default" alt="No image" src="@/assets/images/no-photo.svg" />
            </template>
            <!--  TITLE  -->
            <template v-slot:item.title="{ item }">
                <span class="table-edit">
                    <router-link
                        class="table-edit__link"
                        :to="{ name: 'Edit Page', params: { id: item.id, type: item.type } }"
                        >{{ item.title }}</router-link
                    >
                    <span class="material-icons-outlined table-edit__icon">edit</span>
                </span>
            </template>
            <!--  ENABLED  -->
            <template v-slot:item.enabled="{ item }">
                <input-switch
                    :value="item.enabled"
                    name="enabled"
                    size="small"
                    @input="onUpdateFieldValue({ name: 'enabled', value: $event }, item)"
                ></input-switch>
            </template>
            <!--  HIDDEN  -->
            <template v-slot:item.hidden="{ item }">
                <input-switch
                    :value="item.hidden"
                    name="hidden"
                    size="small"
                    @input="onUpdateFieldValue({ name: 'hidden', value: $event }, item)"
                ></input-switch>
            </template>
        </Table>
    </div>
</template>

<script>
import PageService from '@/services/PageService';
import { Pagination } from '@/services/PaginationService';
import SearchService from '@/services/SearchService';
import EventEmitter from '@/helpers/eventEmitter.ts';
import { debounce } from 'lodash';
import ErrorBlock from '@/components/common/ErrorBlock';
import { imageUrl } from '@/helpers/values';
import Table from '@/components/common/Table';
import Button from '@/components/common/Button';
import CatalogService from '@/services/CatalogService';
import { mapGetters } from 'vuex';

export default {
    name: 'PageList',
    components: {
        ErrorBlock,
        Table,
        Button,
    },

    async created() {
        EventEmitter.on('delete-page', this.deleteItems);
        const total = await this.getTotal();
        const limit = this.$store.getters['globalVars/getPagesLimit'];
        this.pagination = new Pagination({
            moduleName: 'pages',
            limit,
            pageNumber: this.pageNumber,
            total,
        });
        if (this.$route.query.q) {
            this.search.q = this.$route.query.q;
            this.previousSearch = this.$route.query.q;
        }
        await this.getItems();
    },
    data: () => ({
        isLoaded: false,
        search: { searchable: 'page', q: '' },
        items: [],
        pagination: null,
        total: null,
        sortBy: 'title',
        previousSearch: '',
        sortDesc: false,
        imageUrl: imageUrl(),
        selected: [],
        images: {},
        isAvailable: true,
    }),
    computed: {
        pageNumber() {
            return this.$store.getters['globalVars/getPagesPage'];
        },
        searchString() {
            return this.$route.query.q;
        },
        headers() {
            return [
                { text: this.$t('lists.columns.image'), value: 'image', width: '50px' },
                { text: this.$t('lists.columns.title'), value: 'title', sortable: true },
                { text: this.$t('lists.columns.enabled'), value: 'enabled', width: '70px', sortable: true },
                { text: this.$t('lists.columns.hidden'), value: 'hidden', width: '70px', sortable: true },
                { value: 'actions', width: '224px', class: 'table__actions' },
            ];
        },
        ...mapGetters('globalVars', { instanceFull: 'getInstanceFull' }),
    },
    methods: {
        async getItems() {
            this.isLoaded = false;
            const isSearching = !!this.search.q?.length;
            const searchChanged = this.search.q !== this.previousSearch;
            const params = {
                limit: this.pagination.limit,
                offset: searchChanged ? null : this.pagination.offset,
                sort: this.sortBy,
                direction: this.sortDesc ? 'desc' : this.sortDesc === false ? 'asc' : null,
                q: isSearching ? this.search.q : null,
                fields: isSearching ? ['title'] : null,
            };
            const getter = isSearching ? this.searchTextPages : this.getTextPages;
            const [error, result] = await getter(params);
            if (searchChanged) {
                this.pagination.pushToHistory(1);
                this.pagination.total = isSearching ? result.count : await this.getTotal();
                this.previousSearch = this.search.q;
            }
            if (error) {
                error.alert();
                this.isAvailable = false;
            }
            this.items = isSearching ? result.data : result;
            //TODO: вынести в imageService? Сделал изначально для catalog, но т.к catalog неследует page, метод работает для обеих сущностей
            const [imageError, imageResult] = await CatalogService.getImages(this.items);
            if (imageError) imageError.notify();
            this.images = imageResult;
            this.isLoaded = true;
        },

        openDeletionModal(items) {
            this.$store.dispatch('modals/openDeletionModal', { items, entity: 'page' });
        },
        async getTotal() {
            const [error, total] = await Pagination.getTotal('pages');
            if (error) {
                error.notify();
            }
            return total;
        },
        async getTextPages(params) {
            return await PageService.getAll(params);
        },
        async searchTextPages(params) {
            return await SearchService.entitySearch('page', params);
        },

        changeTextPage: debounce(async function(page) {
            const [error] = await PageService.updateOne(page);
            if (error) {
                error.notify();
            }
        }, 300),

        updateSearchString({ searchString }) {
            this.search.q = searchString;
            if (searchString?.length) this.pagination.reset();
            this.getItems();
        },

        hasImage(page) {
            return this.images[page.id]?.uri;
        },
        async deleteItems(items) {
            this.isLoaded = false;
            const promises = [];
            items.forEach((item) => {
                promises.push(PageService.removeOne(item.id));
                const position = this.items.indexOf(item);
                this.items.splice(position, 1);
            });
            const results = await Promise.all(promises);
            const normalized = await this.pagination.normalize(this.items);
            this.pagination.total = await this.getTotal();
            this.selected = [];
            this.$store.dispatch('modals/closeDeletionModal');
            if (!normalized) {
                await this.getItems();
            }
            if (
                results.every((item) => {
                    const error = item[0];
                    return !error;
                })
            ) {
                if (items.length === 1) {
                    EventEmitter.trigger('show-noty', {
                        type: 'success',
                        text: this.$tc('notifications.deleted', 1, { entity: this.$tc('entities.page.title', 1) }),
                    });
                } else {
                    EventEmitter.trigger('show-noty', {
                        type: 'success',
                        text: this.$tc('notifications.deleted', 2, { entity: this.$tc('entities.page.title', 2) }),
                    });
                }
            }
            this.isLoaded = true;
        },
        changePerPage(perPage) {
            this.pagination.changeLimit(perPage.value);
            this.perPage = perPage.value;
            this.getItems();
        },
        updateSort(sortBy, sortDesc) {
            this.sortBy = sortBy;
            this.sortDesc = sortDesc;
            this.getItems();
        },

        async changeSelected(name, value) {
            this.isLoaded = false;
            const pages = this.selected.filter((item) => item[name] !== value);
            pages.forEach((item) => {
                item[name] = value;
            });
            this.selected = [];
            if (!pages.length) {
                this.isLoaded = true;
                return;
            }
            const [error] = await PageService.updateBatch(pages.map((item) => item));
            if (error) {
                this.isLoaded = true;
                error.notify();
                return;
            }
            this.getItems();
        },

        async onUpdateFieldValue(event, page) {
            const { name, value } = event;
            page[name] = value;
            const [error] = await PageService.updateOne(page);
            if (error) {
                error.notify();
            }
        },
    },
    beforeDestroy() {
        EventEmitter.off('delete-page', this.deleteItems);
    },
    watch: {
        pageNumber(newValue) {
            this.pagination.pageNumber = newValue;
            this.getItems();
        },
    },
};
</script>
<style lang="scss"></style>
