export default () => ({
    name: {
        component: 'input-text',
        props: {
            rules: ['noEmpty'],
            bulkEditable: true,
            labelTrans: 'entities.order.fields.name',
            errors: [],
        },
    },
    email: {
        component: 'input-text',
        props: {
            rules: ['noEmpty', 'email'],
            bulkEditable: false,
            labelTrans: 'entities.order.fields.email',
            errors: [],
        },
    },
    phone: {
        component: 'input-text',
        props: {
            rules: ['noEmpty', 'phone'],
            labelTrans: 'entities.order.fields.phone',
            bulkEditable: true,
            errors: [],
        },
    },
    isOtherReceiver: {
        component: 'InputCheckbox',
        props: {
            rules: [],
            bulkEditable: true,
            labelTrans: 'entities.order.fields.isOtherReceiver',
            errors: [],
        },
    },
    receiverName: {
        component: 'input-text',
        props: {
            rules: ['noEmpty'],
            bulkEditable: true,
            labelTrans: 'entities.order.fields.receiverName',
            errors: [],
        },
    },
    receiverPhone: {
        component: 'input-text',
        props: {
            rules: ['noEmpty', 'phone'],
            labelTrans: 'entities.order.fields.receiverPhone',
            bulkEditable: true,
            errors: [],
        },
    },
    stateId: {
        component: 'Select',
        bulkEditable: false,
        props: {
            'item-text': 'text',
            'item-value': 'value',
            labelTrans: 'entities.order.fields.state',
            rules: ['noEmpty'],
            errors: [],
        },
    },
    manager: {
        component: 'Select',
        props: {
            bulkEditable: true,
            labelTrans: 'entities.order.fields.manager',
            errors: [],
        },
    },
    method: {
        component: 'Select',
        props: {
            labelTrans: 'entities.order.fields.payment',
            errors: [],
        },
    },
    orderComment: {
        component: 'Textarea',
        props: {
            bulkEditable: true,
            labelTrans: 'entities.order.fields.orderComment',
            errors: [],
        },
    },
    customerComment: {
        component: 'Textarea',
        props: {
            bulkEditable: true,
            labelTrans: 'entities.order.fields.customerComment',
            errors: [],
        },
    },
    receiverComment: {
        component: 'Textarea',
        props: {
            bulkEditable: true,
            labelTrans: 'entities.order.fields.receiverComment',
            errors: [],
        },
    },
});
