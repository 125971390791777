<template>
    <img class="sidebar-tooltip__preview-description-image" src="@/assets/images/preview-description.jpg"
/></template>

<script>
export default {
    name: 'ProductPreviewDescriptionTooltip',
};
</script>

<style lang="scss">
.sidebar-tooltip {
    &__preview-description-image {
        width: 900px;
        height: auto;
        margin-top: 60px;
    }
}
</style>
