export default function initItems(ctx) {
    return {
        enabled: {
            type: 'field',
            value: ctx.enabled.value,
            component: 'input-switch',
            props: {
                errors: [],
            },
        },
        chatId: {
            type: 'field',
            value: ctx.chatId.value,
            component: 'input-text',
            props: {
                label: 'settings.notifierTelegram.chatId',
                group: 'notifierTelegram',
                errors: [],
            },
        },
        token: {
            type: 'field',
            value: ctx.token.value,
            component: 'input-text',
            props: {
                label: 'settings.notifierTelegram.token',
                group: 'notifierTelegram',
                errors: [],
            },
        },
        isUseTemplate: {
            type: 'field',
            value: ctx.isUseTemplate.value,
            component: 'input-radio',
            props: {
                group: 'notifierTelegram',
                errors: [],
            },
        },
        template: {
            type: 'field',
            value: ctx.template.value,
            component: 'textarea',
            props: {
                label: 'settings.notifierTelegram.template',
                group: 'notifierTelegram',
                errors: [],
            },
        },
    };
}
