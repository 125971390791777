<template>
    <div v-if="isReady">
        <simple-file-manager :files="fileList" @remove="onFileRemove" @rename="onFileRename" @upload="onFileUpload" />
    </div>
</template>

<script>
//TODO: Пока закостылено получение файлов(не более 100шт), потому что без ограничения загружаются все файлы, а их оооочень много. Нужно подумать как лучше реализовать получение файлов и пагинацию.
export default {
    name: 'FileManager',
    created() {
        (async () => {
            await Promise.all([this.getFiles('content'), this.getImages('content'), this.getImages('product')]);
            this.isReady = true;
        })();
    },
    actions: {
        file: {
            upload: 'file/uploadFile',
            remove: 'file/removeFile',
        },
        image: {
            upload: 'image/uploadImage',
            remove: 'image/removeImage',
        },
    },
    data() {
        return {
            isReady: false,
            image: {
                content: [],
                product: [],
            },
            file: {
                content: [],
            },
        };
    },
    computed: {
        fileList() {
            return [
                {
                    id: 'cb73cd50-9c2e-46ca-bc60-2e779f29c9d1',
                    name: 'files',
                    isOpen: false,
                    children: [
                        {
                            id: '8609a298-4170-444f-9a5f-e6ff966b0b86',
                            name: 'content',
                            isOpen: false,
                            children: this.file.content,
                            type: 'file',
                        },
                    ],
                },
                {
                    id: 'd929bd07-2c54-4d8a-bdc2-66e23ac33bcb',
                    name: 'images',
                    children: [
                        {
                            id: 'fac144ef-c15b-4b20-822f-e187cb506feb',
                            name: 'content',
                            children: this.image.content,
                            type: 'image',
                        },
                        {
                            id: '28377dc2-4fbc-4105-8631-6adc735b1bcb',
                            name: 'product',
                            children: this.image.product,
                            type: 'image',
                        },
                    ],
                },
            ];
        },
    },
    methods: {
        async onFileRemove(data) {
            try {
                const targetDir = this.getTargetDir(data.location, this.fileList);
                const targetArray = this[targetDir.type][targetDir.name];
                const file = targetArray.find((file) => file.id === data.item.id);
                const filePosition = targetArray.indexOf(file);
                const action = this.$options.actions[targetDir.type].remove;
                await this.$store.dispatch(action, {
                    id: data.item.id,
                });
                targetArray.splice(filePosition, 1);
                data.promise.resolve();
            } catch (error) {
                console.error(error);
                data.promise.reject();
            }
        },
        async onFileRename(data) {
            try {
                // await fileOperations[data.type](data.file, data.currentLocation);
                data.promise.resolve();
            } catch (error) {
                console.error(error);
                data.promise.reject();
            }
        },
        async onFileUpload(data) {
            try {
                const targetDir = this.getTargetDir(data.location, this.fileList);
                const newFiles = await Promise.all(
                    data.files.map((file) => {
                        const action = this.$options.actions[targetDir.type].upload;
                        return this.$store.dispatch(action, {
                            type: targetDir.name,
                            file: file,
                        });
                    })
                );
                this[targetDir.type][targetDir.name].unshift(...newFiles);
                data.promise.resolve();
            } catch (error) {
                console.error(error);
                throw error;
            }
        },
        async getFiles(type) {
            const files = await this.$store.dispatch('file/getFiles', {
                params: {
                    type: type,
                    limit: 100,
                    offset: 0,
                },
            });
            this.file[type] = files;
            return files;
        },
        async getImages(type) {
            const images = await this.$store.dispatch('image/getImages', {
                params: {
                    type: type,
                    limit: 100,
                    offset: 0,
                },
            });
            this.image[type] = images;
            return images;
        },
        getTargetDir(location, accumulator) {
            return location.reduce(
                (acc, val) => {
                    return acc.children.find((item) => item.id === val);
                },
                { children: accumulator }
            );
        },
        // addToFileList(file, location = []) {
        //     const rel = location.reduce((acc, value) => acc[value], this);
        //     rel.unshift(file);
        // },
    },
};
</script>

<style></style>
