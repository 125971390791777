<template>
    <div class="menu-editor-list-item" :class="classes">
        <div class="menu-editor-list-item__main handle" @dblclick="editMenuItem">
            <span class="menu-editor-list-item__move">
                <i class="material-icons">drag_indicator</i>
            </span>
            <span class="menu-editor-list-item__title" :title="item.title">{{ item.title }}</span>
            <div class="menu-editor-list-item__btns">
                <Button
                    v-if="canBeParent"
                    type="icon"
                    icon="add_circle_outline"
                    class="btn-add"
                    @click="addChildForMenuItem"
                    :title="$t('builder.menuEditor.add')"
                ></Button>
                <Button type="icon" icon="mode" @click="editMenuItem" :title="$t('builder.menuEditor.edit')"></Button>
                <Button
                    type="icon"
                    icon="delete_outline"
                    class="btn-remove"
                    @click="removeMenuItem"
                    :title="$t('builder.menuEditor.remove')"
                ></Button>
            </div>
        </div>
        <div class="menu-editor-list-item__edit">
            <FormItem v-if="isLoad" class="menu-editor-list-item__edit-field" :label="'builder.menuEditor.link'">
                <InputLink v-model="link" @update-field="updateField" />
            </FormItem>
            <FormItem class="menu-editor-list-item__edit-field" :label="'builder.menuEditor.title'">
                <InputText v-model="item.title" />
            </FormItem>
            <input-switch
                v-if="link && link.type !== 'link' && link.type !== null"
                v-model="item.subject.useJumpLink"
                class="expansion__switch"
                size="small"
                text="Use jump link"
                @click.native.stop
            ></input-switch>
            <FormItem
                v-if="item.subject.useJumpLink"
                class="menu-editor-list-item__edit-field"
                :label="'builder.menuEditor.sectionId'"
            >
                <InputPaste
                    v-model="item.subject.sectionId"
                    :pastedValue="clipboard.type === 'ancor' ? clipboard.value : ''"
                    :disabled="clipboard.type !== 'ancor'"
                />
            </FormItem>
            <Button :fullwidth="true" @click="updateMenuItem">Save</Button>
        </div>
    </div>
</template>

<script>
import PageService from '@/services/PageService.ts';
import CatalogService from '@/services/CatalogService.ts';
import FormItem from '@/components/form/item.vue';
import InputText from '@/components/form/controls/InputText.vue';
import InputLink from '@/components/form/controls/InputLink.vue';
import InputPaste from '@/components/form/controls/InputPaste.vue';
import InputSwitch from '@/components/form/controls/InputSwitch';
import Button from '@/components/common/Button.vue';
import { mapGetters } from 'vuex';

export default {
    name: 'MenuEditorListItem',
    components: { Button, FormItem, InputText, InputLink, InputPaste, InputSwitch },
    props: {
        item: { required: false, type: Object, default: () => {} },
        canBeParent: { required: false, type: Boolean, default: false },
    },
    data() {
        return {
            isLoad: false,
            link: null,
            isEdit: false,
        };
    },
    computed: {
        ...mapGetters('clipboard', {
            clipboard: 'getClipboard',
        }),
        classes() {
            let classes = 'is-section-id';
            if (this.isEdit) classes += ' is-edit';
            // if (this.clipboard.type === 'ancor') classes += ' is-section-id';
            return classes;
        },
    },
    async created() {
        let title;

        if (this.item.subject.name === 'homepage' && this.item.subject.id) {
            this.$set(this, 'homepage', {
                type: this.item.subject.name,
                id: this.item.subject.id,
                url: '/',
                title: title,
                sectionId: this.item?.sectionId || null,
            });
        }
        if (this.item.subject.name === 'page' && this.item.subject.id) {
            const [errorPage, page] = await PageService.getOne(this.item.subject.id);
            if (errorPage) errorPage.alert();
            else title = page.title;
        }
        if (this.item.subject.name === 'catalog' && this.item.subject.id) {
            const [errorCatalog, catalog] = await CatalogService.getOne(this.item.subject.id);
            if (errorCatalog) errorCatalog.alert();
            else title = catalog.title;
        }
        this.$set(this, 'link', {
            type: this.item.subject.name,
            id: this.item.subject.id,
            url: this.item.uri,
            title: title,
            sectionId: this.item?.sectionId || null,
        });
        this.isLoad = true;
    },
    methods: {
        addChildForMenuItem() {
            this.$emit('add-child-for-menu-item', this.item.id);
        },
        editMenuItem() {
            this.isEdit = !this.isEdit;
        },
        updateField({ value }) {
            if (value.title) this.item.title = value.title;
        },
        updateMenuItem() {
            this.item.subject.name = this.link.type;
            // if (this.item.subject.name === 'catalog') this.item.subject.name += 's';
            this.item.subject.id = this.link.id;
            this.item.uri = this.link.type === 'link' ? this.link.url : null;
            this.$emit('edit-menu-item', this.item);
            this.isEdit = false;
        },
        removeMenuItem() {
            this.$emit('remove-menu-item', this.item.id);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';
@import '@/scss/variables/system-colors.scss';
.menu-editor-list-item {
    margin-top: -1px;
    border-radius: 0.5rem;
    border: 1px solid darken($item-active, 5%);
    // border: 1px solid $item-active;
    transition: $transition-fast;
    line-height: 24px;
    background: $bg-default;

    &:hover {
        background-color: $item-active;
    }
    &__main {
        position: relative;
        display: flex;
        padding: 1rem 1rem 1rem 0.75rem;
    }
    &__move {
        display: block;
        cursor: grab;
        line-height: 24px;
        height: 24px;
        i {
            font-size: 20px;
            line-height: 24px;
        }
    }

    .btn-remove {
        &:hover {
            color: $error-hover;
        }
    }
    .btn-add {
        .builder.in-progress & {
            opacity: 0.8;
            pointer-events: none;
        }
    }
    &__title {
        display: block;
        margin: 0 auto 0 0.5rem;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: calc(100% - 40px);
        font-size: 16px;
        line-height: 24px;
        pointer-events: none;
        cursor: pointer;
        position: relative;
        z-index: 2;
        // transition: $transition-fast;
    }
    &:hover &__title {
        width: calc(100% - 100px);
    }
    &__btns {
        position: absolute;
        display: flex;
        right: 0.75rem;
        top: 50%;
        transform: translateY(-50%);
        opacity: 0;
        // transition: $transition-fast;
        button {
            width: 24px;
            height: 24px;
            font-size: 20px;
            line-height: 24px;
        }
    }
    &:hover &__btns {
        opacity: 1;
    }

    &__edit {
        opacity: 0;
        padding: 1rem 1rem 1rem 0.75rem;
        border-top: 1px solid darken($item-active, 5%);
        transition: $transition-fast;

        &-field {
            margin: 0 0 1rem;
        }
    }
}

.menu-editor-list-item {
    overflow: hidden;
    max-height: 52px;
    &.is-edit {
        overflow: visible;
        max-height: 300px;
        background-color: $item-active;
    }
    &.is-edit.is-section-id {
        max-height: 405px;
    }
    &.is-edit &__edit {
        opacity: 1;
    }
}

.item-sub .menu-editor-list-item {
    max-height: 45px;
    &.is-edit {
        max-height: 300px;
    }
    &.is-edit.is-section-id {
        max-height: 380px;
    }
    &__main {
        padding: 0.75rem;
    }
    &__title {
        font-size: 14px;
    }
}
</style>
