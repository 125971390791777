import SlugService from '@/services/SlugService';
import AbstractEntity from '@/entities/abstractEntity';
import initFields from '@/entities/domain/Domain.fields';

export default class Domain extends AbstractEntity {
    fields = initFields(this);

    constructor(data) {
        super();
        data = this.validateData(data);

        this.name = data.name || '';
        this.internal = data.internal || false;
        this.primary = data.primary || false;
        this.email = data.email || '';
        this.confirmed = data.confirmed || false;
        this.canBeUsedInEmail = data.canBeUsedInEmail || false;
        this.createdAt = data.createdAt || '';
        this.updatedAt = data.updatedAt || '';
    }

    get data() {
        return {
            name: this.name,
            primary: this.primary,
        };
    }

    //методы для изменения email и primary
    async changeSlugFromTitle(text) {
        return await SlugService.changeSlugFromTitle(text, this);
    }
}
