<template>
    <img
        v-if="image"
        class="table__image"
        :alt="image.alt ? image.alt : null"
        :src="image.uri ? imageUrl + '/thumbs/inset/80x80' + image.uri : null"
    />
    <img v-else class="table__image -default" alt="No image" src="@/assets/images/no-photo.svg" />
</template>

<script>
import { imageUrl } from '@/helpers/values';
export default {
    name: 'TableImage',
    data: function() {
        return {
            imageUrl: imageUrl(),
        };
    },
    props: {
        image: {
            type: Object,
            default: () => null,
        },
    },
};
</script>

<style scoped></style>
