var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal',{attrs:{"header":_vm.products ? _vm.$t('entities.catalog.addRelatedProducts') : _vm.$t('entities.catalog.addProducts'),"width":"870px","content-class":"catalog-products-modal","eager":""},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},[(!_vm.isAvailable)?_c('error-block'):(_vm.model && _vm.initialDataLoaded)?_c('Table',{staticClass:"catalog-products-modal__table",attrs:{"search":_vm.search,"columns":_vm.headers,"items":_vm.items,"is-data-loaded":_vm.isDataLoaded,"show-select":true,"multiple-page-selection":"","show-selected-actions":false,"pagination":_vm.pagination,"show-select-all":false,"no-delete":"","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"select-item":_vm.handleSelected,"update:sort":_vm.updateSort,"change-per-page":_vm.changePerPage,"update-search-string":_vm.updateSearchString},scopedSlots:_vm._u([{key:"item.image",fn:function(ref){
var item = ref.item;
return [(_vm.hasImage(item))?_c('img',{staticClass:"table__image",attrs:{"alt":_vm.images[item.id].alt,"src":_vm.imageUrl + _vm.images[item.id].uri}}):_c('img',{staticClass:"table__image -default",attrs:{"alt":"No image","src":require("@/assets/images/no-photo.svg")}})]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.getListPrice(_vm.currency)))])]}},{key:"item.stock",fn:function(ref){
var item = ref.item;
return [(item.variations.every(function (item) { return item.stock === 0; }))?_c('chip',{attrs:{"type":"error"}},[_vm._v(" "+_vm._s(_vm.$t('entities.product.stock.outOfStock'))+" ")]):(item.variations.some(function (item) { return item.stock === 0; }))?_c('chip',{attrs:{"type":"warning"}},[_vm._v(" "+_vm._s(_vm.$t('entities.product.stock.partially'))+" ")]):_c('chip',{attrs:{"type":"success"}},[_vm._v(" "+_vm._s(_vm.$t('entities.product.stock.inStock'))+" ")])]}},{key:"item.enabled",fn:function(ref){
var item = ref.item;
return [_c('status',{attrs:{"icon":item.enabled ? 'check' : 'close',"text":item.enabled ? _vm.$t('entities.product.fields.enabled') : _vm.$t('entities.product.fields.disabled'),"type":item.enabled ? 'success' : 'warning'}})]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"table-edit"},[_c('router-link',{staticClass:"table-edit__link",attrs:{"target":"_blank","to":{ name: 'Edit Product', params: { id: item.id } }}},[_vm._v(_vm._s(item.title))]),_c('span',{staticClass:"table-edit__icon material-icons-outlined"},[_vm._v("edit")])],1)]}},{key:"item.reviews",fn:function(ref){
var item = ref.item;
return [_c('Button',{attrs:{"type":"text","to":{ name: 'Product Review List', query: { product: item.id } }}},[_vm._v(" "+_vm._s(_vm.$t('lists.actions.viewAll'))+"("+_vm._s(item.reviewCount)+") ")])]}}]),model:{value:(_vm.selectedProducts),callback:function ($$v) {_vm.selectedProducts=$$v},expression:"selectedProducts"}}):_c('progress-circular',{staticClass:"spinner -absolute"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }