<template>
    <div class="code-editor">
        <!--<ckeditor :editor="editor" v-model="model" :config="editorConfig"></ckeditor>-->
        <ckeditor :editor="editor" v-model="model" :key="key"></ckeditor>
    </div>
</template>

<script>
import { uuid } from 'vue-uuid';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import Editor from 'ckeditor5-custom-build/build/ckeditor.js';
// import FullScreen from '@pikulinpw/ckeditor5-fullscreen';

export default {
    name: 'CodeEditor',
    props: {
        value: { type: [String, Number, Object] },
        name: { type: String },
        group: { type: String },
        errors: { type: [Array, Object], default: () => [] },
    },
    components: {
        // Use the <ckeditor> component in this view.
        ckeditor: CKEditor.component,
    },
    data() {
        return {
            editor: Editor,
            editorConfig: {
                toolbar: {
                    items: [
                        'heading',
                        '|',
                        'bold',
                        'italic',
                        'underline',
                        'strikethrough',
                        'subscript',
                        'superscript',
                        '|',
                        'alignment',
                        'fontColor',
                        'fontSize',
                        '|',
                        'blockQuote',
                        'link',
                        'insertTable',
                        'numberedList',
                        'bulletedList',
                        '|',
                        'removeFormat',
                        'sourceEditing',
                        'fullScreen',
                    ],
                    shouldNotGroupWhenFull: true,
                },
            },
            key: uuid.v4(),
        };
    },
    created() {
        // console.log('created');
    },
    computed: {
        model: {
            get() {
                const newValue = this.value?.replace(/[\u200B-\u200D\uFEFF]/g, '');
                return newValue;
            },
            set(value) {
                const regex = new RegExp('\u2028|\u2029');
                const newValue = value.replace(regex, '');
                this.$emit('input', newValue);
                this.$emit('update-field', {
                    name: this.name,
                    group: this.group,
                    value: newValue,
                });
            },
        },
    },
};
</script>

<style lang="scss">
.code-editor {
}
.ck.ck-powered-by {
    display: none !important;
}

.ck.ck-editor__main {
    .ck-content {
        min-height: 160px;
    }
}
</style>
