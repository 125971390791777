var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page -column -with-sidebar-right"},[(!_vm.isDataLoaded && _vm.isAvailible)?_c('progress-circular',{staticClass:"entity-item__spinner spinner"}):_vm._e(),(!_vm.isAvailible)?[_c('div',{staticClass:"page-header"},[_c('h1',{staticClass:"page-header__text"},[_vm._v(_vm._s(_vm.$t('entities.article.newItem')))])]),_c('error-block')]:(_vm.isDataLoaded)?[_c('entity-item-sidebar',{attrs:{"fields":_vm.sidebarBlocks,"entity":_vm.currentState},on:{"change":_vm.updateFromSidebar}}),_c('sticky-toolbar',[_c('Button',{attrs:{"icon":"check","disabled":!_vm.isSaveAllowed},on:{"click":function($event){_vm.isEditing ? _vm.onUpdateArticle() : _vm.onCreateArticle()}}},[_vm._v(" "+_vm._s(_vm.$t('entities.save')))]),(_vm.isEditing)?_c('a',{attrs:{"target":"_blank","href":_vm.hasCategory ? ("https://" + (_vm.instanceFull.primaryDomain) + "/" + (_vm.initialState.slug)) : null}},[_c('Button',{attrs:{"type":"outlined","disabled":!_vm.hasCategory,"icon":"visibility"}},[_vm._v(_vm._s(_vm.$t('entities.view')))])],1):_vm._e(),(_vm.isEditing)?_c('Button',{attrs:{"disabled":!_vm.hasCategory,"type":"outlined","icon":"edit"},on:{"click":function($event){return _vm.$router.push({
                        name: 'Builder Edit Article',
                        params: {
                            categoryId: _vm.currentState.categoryId,
                            id: _vm.currentState.id,
                            type: 'media',
                        },
                    })}}},[_vm._v(_vm._s(_vm.$t('entities.editInBuilder')))]):_vm._e(),(_vm.isEditing)?_c('Button',{attrs:{"type":"outlined","icon":"delete"},on:{"click":_vm.openDeletionModal}},[_vm._v(" "+_vm._s(_vm.$t('entities.remove')))]):_vm._e()],1),_c('div',{staticClass:"page-header entity-item__page-header"},[_c('h1',{staticClass:"page-header__text"},[_vm._v(_vm._s(_vm.articleId ? _vm.currentState.title : _vm.$t('entities.article.newItem')))])]),(_vm.isCreated || _vm.isUpdated)?_c('alert',{staticClass:"entity-item__success-alert",class:{ '-break-line': _vm.isCreated },attrs:{"type":"success"}},[_vm._v(" "+_vm._s(_vm.isCreated ? _vm.$tc('notifications.created', 1, { entity: this.$tc('entities.article.title', 1) }) : _vm.$tc('notifications.updated', 1, { entity: this.$tc('entities.article.title', 1) }))+" "),(_vm.isCreated)?[_c('br'),_c('span',{staticClass:"alert__link",on:{"click":_vm.onAddMoreOne}},[_vm._v(" "+_vm._s(_vm.$t('entities.article.alertText.createAnother')))])]:_vm._e()],2):_vm._e(),(_vm.articleId)?_c('headed-block',{staticClass:"edit-content-block",attrs:{"heading":_vm.$t('entities.blocks.content')},scopedSlots:_vm._u([{key:"header-append",fn:function(){return [_c('Button',{attrs:{"disabled":!_vm.hasCategory,"to":{
                        name: 'Builder Edit Article',
                        params: { categoryId: _vm.currentState.categoryId, id: _vm.articleId, type: 'media' },
                    },"icon":"edit"}},[_vm._v(_vm._s(_vm.$t('entities.editInBuilder')))])]},proxy:true}],null,false,3836023028)}):_vm._e(),_c('headed-block',{staticClass:"entity-item__block image-block",attrs:{"heading":_vm.$t('entities.blocks.image')}},[_c('image-field',{attrs:{"image-type":"article","is-popup":""},model:{value:(_vm.currentState.previewImageId),callback:function ($$v) {_vm.$set(_vm.currentState, "previewImageId", $$v)},expression:"currentState.previewImageId"}})],1),_vm._l((_vm.blocks),function(block,index){return _c('headed-block',{key:block.title,staticClass:"entity-item__block",style:({ order: index + 1 }),attrs:{"heading":block.title}},[_c('div',{staticClass:"form"},[_vm._l((block.items),function(item,key){return [_c('FormItem',_vm._b({key:key,attrs:{"name":key}},'FormItem',item.props,false),[_c(item.component,_vm._g(_vm._b({key:key === 'slug' ? _vm.slugKey : null,tag:"component",attrs:{"name":key,"hide-details":"","outlined":"","dense":""},on:{"update-field":_vm.onUpdateFieldValue},model:{value:(_vm.currentState[key]),callback:function ($$v) {_vm.$set(_vm.currentState, key, $$v)},expression:"currentState[key]"}},'component',item.props || {},false),_vm.getFieldEvents(item.events)))],1)]}),(block.title === _vm.$t('entities.blocks.seo'))?_c('form-item',{staticClass:"form__input",attrs:{"label":_vm.$t('entities.article.fields.googlePreview')}},[_c('MetaPreview',{attrs:{"header":_vm.metaTitle,"link":("https://" + (_vm.instanceFull.primaryDomain) + "/" + (_vm.currentState.slug)),"description":_vm.metaDescription}})],1):_vm._e()],2)])})]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }