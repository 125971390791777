<template>
    <div class="input-url">
        <div v-if="isEdit" class="input-url__container">
            <div class="input-url__field">
                <input-text v-model="text" :name="name" @input="handleInput" type="text" :prefix="prefixUrl" />
            </div>
            <div class="input-url__field" v-if="showCheckbox">
                <input-checkbox
                    :disabled="defaultSlug === model"
                    v-model="hasCreateRedirect"
                    hide-details
                    @input="changeRedirect"
                >
                    <template v-slot>
                        <div>
                            {{ $t('entities.createRedirect') }}
                            <mark>{{ fullUrlFrom }}</mark> → <mark>{{ fullUrl }}</mark>
                        </div>
                    </template>
                </input-checkbox>
            </div>
        </div>

        <div v-if="!isEdit">
            <div class="input-url__row">
                <div class="input-url__text">{{ fullUrl }}</div>
                <Button class="input-url__edit" type="secondary" icon="edit" @click="edit"></Button>
            </div>
        </div>
    </div>
</template>

<script>
import Redirect from '@/entities/Redirect';
import { OBJECT_TYPES } from '@/services/RedirectService';
import { validators } from '@/services/validationService';
import { validationMixin } from '@/mixins/validationMixin';
import { proxyModelMixin } from '@/mixins/proxyModelMixin';
import InputCheckbox from '@/components/form/controls/InputCheckbox';
import Button from '@/components/common/Button';
import InputText from '@/components/form/controls/InputText';
import { mapGetters } from 'vuex';
export default {
    name: 'InputUrl',
    components: { InputText, Button, InputCheckbox },
    mixins: [proxyModelMixin, validationMixin],

    objectTypes: OBJECT_TYPES,

    props: {
        value: {
            type: String,
        },
        label: {
            type: String,
        },
        pageType: {
            type: String,
            default: 'page',
        },
        name: {
            type: String,
        },
        group: {
            type: String,
        },
        showCheckbox: {
            type: Boolean,
            default: true,
        },
    },
    validations: {
        form: {
            from: {
                required: validators.required,
            },
            to: {
                required: validators.requiredUnless(function() {
                    return !!this.form.subject.id;
                }),
            },
            subject: {
                name: {
                    required: validators.requiredUnless(function() {
                        return !!this.form.to;
                    }),
                },
                id: {
                    required: validators.requiredUnless(function() {
                        return !!this.form.to;
                    }),
                },
            },
        },
    },
    data: () => ({
        hasCreateRedirect: false,
        isEdit: false,
        showAbsolutePath: true, // выводим ли https://stg1.runshop.io/
        defaultSlug: '',
        form: new Redirect(),
        text: '',
    }),
    computed: {
        ...mapGetters('globalVars', { instanceFull: 'getInstanceFull' }),
        pageSlug() {
            if (this.pageType === 'page') return '/';
            if (this.pageType === 'catalog') return '/catalogs/';
            if (this.pageType === 'product') return '/products/';
            if (this.pageType === 'blog') return '/blog/';
            if (this.pageType === 'news') return '/news/';
            return '';
        },
        prefixUrl() {
            if (this.showAbsolutePath) return `https://${this.instanceFull.primaryDomain}` + this.pageSlug;
            return this.pageSlug;
        },
        pageRouteId() {
            return this.$route.params.id;
        },
        fullUrl() {
            return this.prefixUrl + this.value;
        },
        fullUrlFrom() {
            return `https://${this.instanceFull.primaryDomain}` + this.pageSlug + this.defaultSlug;
        },
    },
    mounted() {
        this.defaultSlug = this.value;
        this.text = this.value;
        this.getRedirectObj();
    },
    methods: {
        changeRedirect() {
            this.getRedirectObj();
            if (this.hasCreateRedirect && this.defaultSlug !== this.value) {
                this.validate();
                this.$emit('checkRedirect', this.isValid ? this.form : null);
            } else this.$emit('checkRedirect', null);
        },
        getRedirectObj() {
            this.form.from = (this.pageSlug + this.defaultSlug).slice(1);
            this.form.subject.id = this.pageRouteId;
            this.form.subject.name = this.pageType;
        },
        edit() {
            this.isEdit = !this.isEdit;
        },
        handleInput(newValue) {
            this.$nextTick(() => {
                const replaced = newValue.replace(/([^A-Za-z0-9/]+[-]?)/g, '-');
                const finalValue = replaced.toLowerCase();
                this.text = finalValue;
                if (this.model !== newValue) this.model = finalValue;
            });
        },
    },
    watch: {
        model: function(newValue) {
            this.text = newValue;
            this.$emit('update-field', {
                name: this.name,
                group: this.group,
                value: newValue,
            });
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.input-url {
    &__container {
    }
    &__row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 40px;
    }
    &__text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 10px;
    }
    .heading {
        margin-bottom: 0.5em;
    }
    .button .button__content .material-icons-outlined {
        margin-right: 0;
    }
    &__edit {
        padding: 0;
        min-width: 36px;
        min-height: 36px;
    }
    .button:not(.button--round) {
        padding: 0;
        min-width: 36px;
    }
    .checkbox {
        margin-top: 20px;
    }
}
</style>
