import { cloneDeep, merge } from 'lodash';
import { Container, Content, Footer, Heading, Section } from '@/entities/section/samples/_options';

const section = {
    id: '',
    type: 'SectionGaleryStyle1',
    required: false,
    group: 'Gallery',
    options: {
        section: { ...Section },
        container: { ...Container },
        heading: { ...Heading },
        content: {
            htmlBefore: { ...Content.htmlBefore },
            htmlAfter: { ...Content.htmlAfter },
            colorText: { ...Content.color },
        },
        footer: { ...Footer },
        // background: { ...Background },
    },
    items: [],
    children: {
        id: '',
        type: 'galleryItem',
        options: {
            content: {
                image: merge(Content.image, {
                    props: { note: 'JPG, PNG. Recomended size: 550х550px. No more than 50kb' },
                }),
                alt: {
                    ...Content.imageAlt,
                    value: 'Image title text',
                },
                title: {
                    ...Content.imageTitle,
                    value: 'Image title text',
                },
                note: { ...Content.note },
            },
        },
    },
};

const itemFixture = cloneDeep(section.children);
const itemsFixture = [];
itemsFixture.push(Object.assign(cloneDeep(itemFixture), { position: 0 }));
itemsFixture.push(Object.assign(cloneDeep(itemFixture), { position: 1 }));
itemsFixture.push(Object.assign(cloneDeep(itemFixture), { position: 2 }));
itemsFixture.push(Object.assign(cloneDeep(itemFixture), { position: 3 }));
section.items = itemsFixture;

export default section;
