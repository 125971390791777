import countries from '@/helpers/countries';
import initFields from '@/entities/order/OrderDeliveryAddress.fields';
import validateField from '@/helpers/validator';

/**
 * Класс экземпляра адреса доставки
 */
export default class OrderDeliveryAddress {
    fields = initFields();

    /**
     *
     * @param {Object}  data - объект данных адреса доставки
     */
    constructor(
        data = {
            country: '',
            region: '',
            city: '',
            address: '',
            apartment: '',
            postalCode: '',
        }
    ) {
        this.country = data.country;
        this.region = data.region;
        this.city = data.city;
        this.address = data.address;
        this.apartment = data.apartment;
        this.postalCode = data.postalCode;
    }

    get data() {
        return {
            country: this.country,
            region: this.region,
            city: this.city,
            address: this.address,
            apartment: this.apartment,
            postalCode: this.postalCode,
        };
    }
    static formatAddress(deliveryAddress) {
        const country = countries.find((item) => item.code === deliveryAddress.country);
        const region = deliveryAddress?.region
            ? country.zones.find((item) => item.code === deliveryAddress.region)
            : null;

        const addressFinalObj = {
            address: deliveryAddress.address,
            apartment: deliveryAddress.apartment,
            city: deliveryAddress.city,
            phone: deliveryAddress.phone,
            postalCode: deliveryAddress.postalCode,
            country: country?.name,
            region: region?.name,
        };
        return Object.values(addressFinalObj)
            .filter((item) => item)
            .join(', ');
    }
    validateField(name) {
        this.fields[name].props.errors = validateField(this[name], this.fields[name].props.rules);
    }
    validateEntity(validateDelivery, validateRegion) {
        let result = true;
        if (!validateDelivery) return true;
        for (const key in this.fields) {
            if (key === 'region' && !validateRegion) continue;
            const errors = validateField(this[key], this.fields[key].props.rules);
            if (errors.length !== 0) result = false;
            this.fields[key].props.errors = errors;
        }
        return result;
    }
}
