export default (ctx) => ({
    title: {
        type: 'field',
        component: 'InputText',
        props: {
            labelTrans: 'entities.category.fields.title',
            class: '-half-width',
            rules: ['noEmpty'],
            errors: [],
        },
        events: {
            change: (title) => {
                ctx.changeSlugFromTitle(title);
                ctx.changeHeadingFromTitle(title);
            },
        },
    },
    enabled: {
        type: 'field',
        component: 'input-switch',
        props: {
            noLabel: true,
            text: 'entities.category.fields.enabled',
            class: 'sidebar__switch',
            size: 'small',
            errors: [],
        },
    },
    hidden: {
        type: 'field',
        component: 'input-switch',
        props: {
            noLabel: true,
            text: 'entities.category.fields.hidden',
            class: 'sidebar__switch',
            size: 'small',
            errors: [],
        },
    },
    noIndex: {
        type: 'field',
        component: 'input-switch',
        props: {
            noLabel: true,
            text: 'entities.category.fields.noIndex',
            class: 'sidebar__switch',
            size: 'small',
            errors: [],
        },
    },
    heading: {
        type: 'field',
        component: 'InputText',
        props: {
            labelTrans: 'entities.category.fields.heading',
            class: '-half-width',
            rules: ['noEmpty'],
            errors: [],
        },
        events: {
            input: ctx.changeHeading,
        },
    },
    slug: {
        label: 'URL',
        class: '-half-width',
        component: 'input-url',
        props: {
            label: 'entities.category.fields.slug',
            rules: ['noEmpty'],
            errors: [],
        },
        events: {
            checkRedirect: ctx.createRedirectOnSave,
        },
    },
    previewImageId: {
        component: 'image-field',
        props: {
            labelTrans: 'entities.page.fields.previewImageId',
            imageType: 'category',
            note: 'JPG, PNG. Recommended size: 1200х630px. No more than 500kb',
            type: 'content',
        },
    },

    metaTitle: {
        component: 'input-text',
        props: {
            labelTrans: 'entities.category.fields.metaTitle',
        },
    },
    metaDescription: {
        component: 'Textarea',
        props: {
            labelTrans: 'entities.category.fields.metaDescription',
        },
    },
});
