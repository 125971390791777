import { cloneDeep } from 'lodash';
import {
    Container,
    Content,
    TextStyle,
    Footer,
    Heading,
    ItemsBackground,
    Section,
    SectionItemsStyle,
    SectionLayout,
} from '@/entities/section/samples/_options';

const section = {
    id: '',
    type: 'SectionBenefitBase',
    required: false,
    group: 'Benefits',
    options: {
        section: { ...Section },
        container: { ...Container },
        heading: { ...Heading },
        content: {
            htmlBefore: { ...Content.htmlBefore },
            htmlAfter: { ...Content.htmlAfter },
            colorText: { ...Content.color },
        },
        itemsLayout: { ...SectionLayout },
        itemsStyle: {
            template: {
                ...SectionItemsStyle.createTemplate('style1', [
                    { text: 'style1', value: 'style1' },
                    { text: 'style2', value: 'style2' },
                    { text: 'style3', value: 'style3' },
                    { text: 'style5', value: 'style5' },
                    { text: 'style6', value: 'style6' },
                    { text: 'multicolor', value: 'multicolor' },
                ]),
            },
            borderRadius: { ...SectionItemsStyle.borderRadius },
            minHeight: { ...SectionItemsStyle.minHeight },
            textAlign: {
                ...Heading.textAlign,
                value: 'left',
                visibleCondition: (section) => {
                    const itemsStyle = section.options?.itemsStyle?.template?.value;
                    if (!itemsStyle) return false;
                    return ['style2'].includes(itemsStyle);
                },
            },
        },
        footer: { ...Footer },
    },
    items: [],
    children: {
        id: '',
        type: 'benefit',
        template: null,
        options: {
            heading: {
                title: {
                    ...Heading.title,
                    value: 'Benefit Title',
                },
                color: { ...Content.color },
                textSize: {
                    ...TextStyle.size,
                    value: 20,
                },
            },
            content: {
                html: { ...Content.html },
                color: { ...Content.color },
                icon: {
                    ...Content.benefitIcon,
                    visibleCondition: (sectionItem) => {
                        const itemsStyle = sectionItem.type;
                        let iconOptions = sectionItem.options.content.icon.props.options;
                        let position;
                        if (iconOptions.length > 3 && itemsStyle === 'style6') {
                            iconOptions.forEach((option, index) =>
                                option.value === 'text' ? (position = index) : null
                            );
                            iconOptions.splice(position, 1);
                        } else if (iconOptions.length < 4 && itemsStyle !== 'style6') {
                            iconOptions.splice(position, 0, { text: 'text', value: 'text' });
                        }
                        if (!itemsStyle) return false;
                        return itemsStyle !== 'multicolor';
                    },
                },
                verticalAlign: {
                    ...Content.verticalAlign,
                    visibleCondition: (sectionItem) => {
                        const itemsStyle = sectionItem.type;
                        if (!itemsStyle) return false;
                        return itemsStyle === 'multicolor';
                    },
                },
                horizontalAlign: {
                    ...Content.horizontalAlign,
                    visibleCondition: (sectionItem) => {
                        const itemsStyle = sectionItem.type;
                        if (!itemsStyle) return false;
                        return itemsStyle === 'multicolor';
                    },
                },
                button: {
                    ...Content.button,
                    visibleCondition: (sectionItem) => {
                        const itemsStyle = sectionItem.type;
                        if (!itemsStyle) return false;
                        return itemsStyle === 'multicolor';
                    },
                },
            },
            background: {
                color: { ...ItemsBackground.color },
                image: { ...Content.image },
                imageMobile: { ...Content.imageMobile },
                overlayColor: { ...ItemsBackground.overlayColor },
                overlayOpacity: { ...ItemsBackground.overlayOpacity },
            },
        },
    },
};

const itemFixture = cloneDeep(section.children);
const itemsFixture = [];
// @ts-ignore start
itemsFixture.push(Object.assign(cloneDeep(itemFixture), { position: 0 }));
// @ts-ignore start
itemsFixture.push(Object.assign(cloneDeep(itemFixture), { position: 1 }));
// @ts-ignore start
itemsFixture.push(Object.assign(cloneDeep(itemFixture), { position: 2 }));
section.items = itemsFixture;

export default section;
