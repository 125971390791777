<template>
    <Menu fixed>
        <template v-slot:activator="{ on, attrs }">
            <Button class="screen-switcher__btn" type="icon" :icon="iconName" v-bind="attrs" v-on="on"></Button>
        </template>
        <div class="screen-switcher__list list">
            <div
                class="list__item"
                v-for="(item, index) in $options.previewVariants"
                :key="index"
                @click="onChangePreviewMode(item.name)"
            >
                <span class="material-icons-outlined">{{ item.icon }}</span>
                <div class="list__item-title">{{ item.name }}</div>
            </div>
        </div>
    </Menu>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import Button from '@/components/common/Button';
import Menu from '@/components/common/Menu';

export default {
    name: 'ScreenSwitcher',
    components: { Menu, Button },
    previewVariants: [
        { name: 'mobile', icon: 'smartphone' },
        { name: 'tablet', icon: 'tablet' },
        { name: 'desktop', icon: 'desktop_windows' },
        { name: 'fullscreen', icon: 'screenshot_monitor' },
    ],

    computed: {
        ...mapState('builder', {
            previewMode: 'previewMode',
        }),
        iconName() {
            const previewVariant = this.$options.previewVariants.find((item) => item.name === this.previewMode);
            return previewVariant ? previewVariant.icon : 'desktop';
        },
    },
    methods: {
        ...mapMutations('builder', {
            onChangePreviewMode: 'setPreviewMode',
        }),
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.screen-switcher {
    &__btn {
        .btn__icon {
            font-size: 20px !important;
        }
    }
    &__list {
        .material-icons-outlined {
            margin-right: 8px;
        }
    }
}
</style>
