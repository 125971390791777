export default {
    date: {
        title: 'Выберите период',
        buttons: {
            day: 'День',
            week: 'Неделя',
            month: 'Месяц',
        },
    },
    quickActions: {
        title: 'Быстрые действия',
        buttons: {
            createNewPage: 'Добавить страницу',
            addProduct: 'Добавить товар',
            createCatalog: 'Добавить каталог',
        },
    },
    total: {
        sales: 'Всего продаж',
        orders: 'Всего заказов',
        items: 'Всего продано',
    },
    statistics: {
        title: 'Статистика продаж',
    },
    bestSellingProducts: {
        title: 'Самые продаваемые товары',
    },
    lastOrders: {
        title: 'Последние заказы',
    },
};
