export default {
    // TEXT
    // default
    text_main: '#1D140C',
    text_body1: '#37312A',
    text_body2: '#5E564B',
    text_accent: '#E4A244',
    text_neutral: '#91897D',
    // inverted
    text_main_inverted: '#FFFFFF',
    text_body1_inverted: '#EEE9E3',
    text_body2_inverted: '#E0D6CC',
    text_accent_inverted: '#E4A244',
    text_neutral_inverted: '#D9CDBB',

    // ACTION
    // primary
    action_primary: '#8C653E',
    action_primary_hover: '#B27E49',
    action_primary_pressed: '#65492C',
    action_primary_disabled: '#EDEAE3',
    // secondary
    action_secondary: '#DAB590',
    action_secondary_hover: '#DAB590',
    action_secondary_pressed: '#3F2F1B',
    action_secondary_disabled: '#EDEAE3',
    // neutral_light
    header_bg: '#FFFFFF',
    header_bg_mobile: '#2B1E12',
    header_main_menu_bg: '#2B1E12',
    header_main_menu_dropdown_bg: '#8E663E',
    action_neutral_light: '#FFFFFF',
    // action_neutral_light_hover: '#C78D52',
    // action_neutral_light_pressed: '#825C35',
    action_neutral_light_hover: '#B27E49',
    action_neutral_light_pressed: '#DAB590',
    action_neutral_light_disabled: '#CDAD8E',
    // neutral_medium
    action_neutral_medium: '#D1CAC1',
    action_neutral_medium_hover: '#B27E49',
    action_neutral_medium_pressed: '#65492C',
    action_neutral_medium_disabled: '#EDEAE3',
    // neutral_dark
    action_neutral_dark: '#1D140C',
    action_neutral_dark_hover: '#B27E49',
    action_neutral_dark_pressed: '#65492C',
    action_neutral_dark_disabled: '#EDEAE3',
    // system
    action_system: '#F85C50',
    action_system_hover: '#D44F44',
    action_system_pressed: '#AD4138',
    action_system_disabled: '#E3E5E8',

    // NEUTRAL
    neutral1: '#1C140D',
    neutral2: '#2B1E12',
    neutral3: '#8E663E',
    neutral4: '#CDAD8E',
    neutral5: '#E6D6C6',
    neutral6: '#F9F5F1',
    neutral7: '#FFFFFF',

    // SYSTEM
    success: '#41B619',
    success_bg: '#F6FAF5',
    warning: '#FFA000',
    warning_bg: '#FFF9EE',
    error: '#F85C50',
    error_bg: '#FFF4F8',

    // ACCENT
    accent1: '#F89865',
    accent1_bg: '#FEEAE0',
    accent2: '#237994',
    accent2_bg: '#D3E4EA',
    accent3: '#19B585',
    accent3_bg: '#D1F0E7',
    accent4: '#4C70E0',
    accent4_bg: '#DBE2F9',

    whatsapp: '#71D062',
};
