<template>
    <expansion class="shipping-zone">
        <template v-slot:header-left>
            <input-switch
                v-model="enabled"
                class="expansion__switch"
                @click.native.stop
                @input="toggleEnabled"
            ></input-switch>
            <div class="address-card">
                <div class="address-card__img-container" v-if="shippingZone.zones">
                    <img v-if="flag" class="address-card__img" :src="flag" />
                    <img v-else class="address-card__img -default" src="@/assets/images/country-default.svg" />
                </div>
                <div class="address-card__text">
                    <h4 class="address-card__city expansion__heading-text">{{ shippingZone.name }}</h4>
                </div>
            </div>
        </template>
        <template v-slot:header-right>
            <Button
                type="text"
                class="shipping-zone__add-btn"
                icon="add_circle_outline"
                @click.native.stop="$emit('open-rate-modal', shippingZone)"
            >
                {{ $t('lists.addButton.rate') }}</Button
            >
            <Menu>
                <template v-slot:activator="{ on, attrs }">
                    <span class="material-icons-outlined expansion__more" v-bind="attrs" v-on="on">more_vert </span>
                </template>
                <div class="edit-list list">
                    <div class="list__item" @click="$emit('start-zone-editing', shippingZone)">
                        <span class="material-icons-outlined">edit</span>{{ $t('entities.edit') }}
                    </div>
                    <div class="list__item" @click="$emit('start-deletion', shippingZone, 'shippingZone')">
                        <span class="material-icons-outlined">delete</span>{{ $t('entities.delete') }}
                    </div>
                </div>
            </Menu>
        </template>
        <template v-slot:default>
            <Table
                :columns="headers"
                :sort-by="sortBy"
                :sort-desc="sortDesc"
                local-sort
                :show-toolbar="false"
                :show-selected-actions="false"
                :items="shippingZone.rates"
                @update:sort="updateSort"
            >
                <!--  ACTIONS  -->
                <template v-slot:item.actions="{ item }">
                    <Menu>
                        <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" class="material-icons-outlined rate__more-icon" v-on="on"
                                >more_vert</span
                            >
                        </template>
                        <div class="edit-list list">
                            <div class="list__item" @click="$emit('start-rate-editing', item)">
                                <span class="material-icons-outlined">edit</span>{{ $t('entities.edit') }}
                            </div>
                            <div class="list__item" @click="$emit('start-deletion', item, 'rate')">
                                <span class="material-icons-outlined">delete</span>{{ $t('entities.delete') }}
                            </div>
                        </div>
                    </Menu>
                </template>
                <!--  PRICE  -->
                <template v-slot:item.price="{ item }"> {{ item.price }} {{ currency }} </template>
                <!--  CONDITIONS  -->
                <template v-slot:item.conditions="{ item }">
                    <div v-if="item.minWeight || item.maxWeight">
                        {{ item.minWeight ? `min ${item.minWeight} kg` : null }}
                        {{ item.minWeight && item.maxWeight ? '—' : null }}
                        {{ item.maxWeight ? `max ${item.maxWeight} kg` : null }}
                    </div>
                    <div v-if="item.minPrice || item.maxPrice">
                        {{ item.minPrice ? `min ${item.minPrice} ${currency}` : null }}
                        {{ item.minPrice && item.maxPrice ? '—' : null }}
                        {{ item.maxPrice ? `max ${item.maxPrice} ${currency}` : null }}
                    </div>
                </template>
                <!-- TITLE -->
                <template v-slot:item.title="{ item }">{{ item.title }}</template>
                <!--  SHIPPING SPEED  -->
                <template v-slot:item.shippingSpeed="{ item }">
                    {{ item.shippingSpeed }}
                </template>
            </Table>
        </template>
    </expansion>
</template>

<script>
import Table from '@/components/common/Table';
import { debounce, cloneDeep } from 'lodash';
import InputSwitch from '@/components/form/controls/InputSwitch';
import Button from '@/components/common/Button';
import Expansion from '@/components/common/Expansion';
import Menu from '@/components/common/Menu';

export default {
    name: 'shippingZoneForm',
    components: { Menu, Expansion, InputSwitch, Table, Button },
    props: {
        shippingZone: {
            type: Object,
            default: () => {},
        },
        countries: {
            type: Array,
            default: () => [],
        },
    },
    created() {
        this.getFlag();
    },
    data() {
        return {
            currentCountry: null,
            flag: null,
            enabled: this.shippingZone.enabled,
            sortBy: null,
            sortDesc: null,
            expectedTimeItems: [
                { text: 'from 2 to 4 days', value: 'from 2 to 4 days' },
                { text: 'from 4 to 6 days', value: 'from 4 to 6 days' },
                { text: 'from 1 to 2 weeks', value: 'from 1 to 2 weeks' },
            ],
        };
    },

    computed: {
        currency() {
            return this.$store.getters['config/getCurrency'];
        },
        headers() {
            return [
                { text: this.$t('lists.columns.rateName'), value: 'title', sortable: true },
                { text: this.$t('lists.columns.price'), value: 'price', sortable: true },
                { text: this.$t('lists.columns.shippingSpeed'), value: 'shippingSpeed' },
                { text: this.$t('lists.columns.conditions'), value: 'conditions', width: '25%' },
                { value: 'actions', width: '120px', align: 'end' },
            ];
        },
    },

    methods: {
        getFlag() {
            if (this.shippingZone.zones.length) {
                const country = this.shippingZone.zones[0].slice(0, 2);

                if (this.shippingZone.zones.length === 1) {
                    this.requireFlag(country);
                } else if (this.shippingZone.zones.every((item) => item.startsWith(country))) {
                    this.requireFlag(country);
                }
            }
        },
        requireFlag(code) {
            this.flag = require(`@/assets/images/flags/${code.toLowerCase()}.svg`);
        },
        toggleEnabled: debounce(function() {
            const updatedZone = cloneDeep(this.shippingZone);
            updatedZone.enabled = this.enabled;
            this.$emit('toggle-enabled', updatedZone);
        }, 300),
        updateSort(sortBy, sortDesc) {
            this.sortBy = sortBy;
            this.sortDesc = sortDesc;
        },
    },
    watch: {
        shippingZone: {
            deep: true,
            handler() {
                this.getFlag();
            },
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.rate {
    &__more-icon {
        font-size: 18px;
        cursor: pointer;
        width: fit-content;
    }
}
.shipping-zone {
    &__add-btn {
        margin-left: auto;
        margin-right: 48px;
    }
}
.edit-list {
    .list__item {
        .material-icons-outlined {
            margin-right: 8px !important;
        }
    }
}
</style>
