<template>
    <div class="code-editor-screen-builder">
        <ScreenTabs>
            <template v-for="screen in screens" #[screen]>
                <div :key="screen">
                    <CodeEditor v-model="value[screen]" />
                </div>
            </template>
        </ScreenTabs>
    </div>
</template>

<script>
import ScreenTabs from '@/components/form/controls/utils/ScreenTabs.vue';

export default {
    name: 'CodeEditorScreenTabs',
    inheritAttrs: false,
    props: {
        name: { type: String, default: '' },
        errors: { type: [Array, Object], default: () => [] },
        value: { type: [String, Number, Object, Date] },
    },
    components: { ScreenTabs },
    created() {},
    data() {
        return {
            screens: ['desktop', 'tablet', 'mobile'],
        };
    },
    computed: {},
    methods: {},
    watch: {},
};
</script>

<style scoped lang="scss">
@import '@/scss/variables.scss';

.code-editor-screen-builder {
}
</style>
