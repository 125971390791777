<template>
    <div class="network-genius-tooltip">
        <div v-html="$t('entities.payment.tooltips.networkGenius.block1')"></div>
        <img src="@/assets/images/network-genius-instructions/hierarchy.png" />
        <div v-html="$t('entities.payment.tooltips.networkGenius.block2')"></div>
        <img src="@/assets/images/network-genius-instructions/settings.png" />
        <div v-html="$t('entities.payment.tooltips.networkGenius.block3')"></div>
        <img src="@/assets/images/network-genius-instructions/service-accounts.png" />
        <div v-html="$t('entities.payment.tooltips.networkGenius.block4')"></div>
        <img src="@/assets/images/network-genius-instructions/webhooks.png" />
        <div v-html="$t('entities.payment.tooltips.networkGenius.block5')"></div>
    </div>
</template>

<script>
export default {
    name: 'NetworkGeniusPaymentTooltip',
    methods: {
        imageSrc(src) {
            return require(src);
        },
    },
};
</script>

<style lang="scss">
.network-genius-tooltip {
    img {
        width: 900px;
    }
    .-margin-top {
        margin-top: 24px;
    }
    h4 {
        margin-bottom: 20px;
    }
    ol {
        margin-bottom: 16px;
    }
}
</style>
