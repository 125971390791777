export default {
    styles: {
        value: 'Стили',
        scheme: 'Схема',
        content_width: 'Ширина контента',
        border_radius_block: 'Радиус блока',
        border: 'Граница',
        border_radius_btn: 'Радиус кнопки',
        border_radius_input: 'Радиуса инпута',
        depricated: 'Depricated',
        fonts: 'Шрифты',
        font_family_head: 'Шрифт заголовка',
        font_family_body: 'Шрифт контента',
        font_style_head: 'Стиль шрифта',
        font_style_body: 'Стиль шрифта контента',
        font_weight_head: 'Толщина заголовков',
        font_size_head: 'Размер заголовков',
        generals: 'Общее',
        main_background: 'Фон',
        main: 'Основной',
        main_headline_color: 'Цвет заголовка',
        main_content_text: 'Текст контента',
        main_content_link: 'Ссылка на контент',
        main_content_link_hover: 'Ссылка при наведении на контент',
        main_content_link_active: 'Ссылка при наведении (активна)',
        inverted_background: 'Фон',
        inverted: 'Перевернутый',
        inverted_headline_color: 'Цвет заголовка',
        inverted_content_text: 'Текст контента',
        inverted_content_link: 'Ссылка на контент',
        inverted_content_link_hover: 'Ссылка при наведении на контент',
        inverted_content_link_active: 'Ссылка при наведении (активна)',
        middle_background: 'Фон',
        middle: 'Середина',
        middle_headline_color: 'Цвет заголовка',
        middle_content_text: 'Текст контента',
        middle_content_link: 'Ссылка на контент',
        middle_content_link_hover: 'Ссылка при наведении на контент',
        middle_content_link_active: 'Ссылка при наведении (активна)',
        buttons: 'Кнопки',
        button_primary_background: 'Фон',
        button_primary: 'Кнопка основная',
        button_primary_background_hover: 'Фон при наведении',
        button_primary_background_active: 'Активный фон',
        button_primary_background_disabled: 'Фон при отключении',
        button_primary_text: 'Текст',
        button_primary_text_hover: 'Текст при наведении',
        button_primary_text_active: 'Активный текст',
        button_secondary_background: 'Фон',
        button_secondary: 'Кнопка вторичная',
        button_secondary_background_hover: 'Фон при наведении',
        button_secondary_background_active: 'Активный фон',
        button_secondary_background_disabled: 'Фон при отключении',
        button_secondary_text: 'Текст',
        button_secondary_text_hover: 'Текст при наведении',
        button_secondary_text_active: 'Активный текст',
        button_outline_background: 'Фон',
        button_outline: 'Контур кнопки',
        button_outline_background_hover: 'Фон при наведении',
        button_outline_background_active: 'Активный фон',
        button_outline_background_disabled: 'Фон при отключении',
        button_outline_text: 'Текст',
        button_outline_text_hover: 'Текст при наведении',
        button_outline_text_active: 'Активный текст',
        header_mobile_main_background: 'Основной фон',
        header_mobile: 'Заголовок на мобильном',
        header_mobile_bottom_background: 'Нижний фон',
        header_mobile_separator: 'Сепаратор',
        header_mobile_main_icon: 'Главная иконка',
        header_mobile_bottom_icon: 'Нижняя иконка',
        header_mobile_bottom_text_links: 'Нижние текстовые ссылки',
        header_mobile_bottom_text_links_background: 'Фон нижних текстовых ссылок',
        header_mobile_menu_background: 'Фон меню',
        header_mobile_menu_lines: 'Строки меню',
        header_mobile_menu_icons: 'Значки меню',
        header_mobile_menu_text_links: 'Текстовые ссылки меню',
        header_mobile_menu_text_links_active: 'Текстовые ссылки меню активны',
        header_mobile_contacts_text: 'Текст контактов',
        header_desktop_main_background: 'Основной фон',
        header_desktop: 'Заголовок рабочего стола',
        header_desktop_top_background: 'Верхний фон',
        header_desktop_bottom_background: 'Нижний фон',
        header_desktop_separator_background: 'Фон разделителя',
        header_desktop_main_icon: 'Основной цвет иконок',
        header_desktop_main_icon_hover: 'Основной цвет иконок при наведении',
        header_desktop_main_text_link: 'Текстовая ссылка',
        header_desktop_main_text_link_hover: 'Текстовая ссылка при наведении',
        header_desktop_menu_text_link: 'Текстовая ссылка меню',
        header_desktop_menu_text_link_hover: 'Текстовая ссылка меню при наведении',
        header_desktop_menu_text_link_active: 'Активная текстовая ссылка меню',
        header_desktop_submenu_background: 'Фон подменю',
        header_desktop_submenu_text_link: 'Текстовая ссылка подменю',
        header_desktop_submenu_text_link_hover: 'Текстовая ссылка подменю при наведении',
        header_desktop_submenu_text_link_active: 'Активаная текстовая ссылка подменю',
        header_desktop_menu_info_text_link: 'Текстовая ссылка информационного меню',
        header_desktop_menu_info_text_link_hover: 'Текстовая ссылка информационного меню при наведении',
        header_desktop_menu_info_text_link_active: 'Активная текстовая ссылка информационного меню',
        header_desktop_search_background: 'Фон поиска',
        header_desktop_search_border: 'Граница поиска',
        header_desktop_search_text: 'Текст поиска',
        header_desktop_search_icon: 'Иконка поиска',
        header_desktop_search_background_hover: 'Фон поиска при наведении',
        header_desktop_search_border_hover: 'Граница поиска при наведении',
        header_desktop_search_text_hover: 'Текста поиска при наведении',
        header_desktop_search_icon_hover: 'Значок поиска при наведении',
        footer_main_background: 'Основной фон',
        footer_bottom_background: 'Нижний фон',
        footer_lines_background: 'Фон линий',
        footer_icon_color: 'Цвет',
        footer_icon: 'Иконка',
        footer_icon_background: 'Фон',
        footer_text: 'Текст',
        footer_link: 'Связь',
        footer_link_hover: 'Ссылка при наведении',
        footer_link_active: 'Активна ссылка',
        footer_bottom_text: 'Нижний текст',
        footer_social_icon: 'Иконка соцсети',
        footer_social_icon_hover: 'Иконка соцсети при наведении',
        footer_social_icon_active: 'Активная иконка соцсети',
        footer_menu_text_link: 'Текстовая ссылка',
        footer_menu: 'Меню футера',
        footer_menu_text_link_hover: 'Текстовая ссылка при наведении',
        footer_menu_text_link_active: 'Активная текстовая ссылка',
        footer_submenu_text_link: 'Текстовая ссылка',
        footer_submenu: 'Подменю футера',
        footer_submenu_text_link_hover: 'Текстовая ссылка при наведении',
        footer_submenu_text_link_active: 'Активная текстовая ссылка',
        form: 'Форма',
        header: 'Заголовок',
        footer: 'Футер',
        styles: 'Стили',
        socials: 'Социальные сети',
        media: 'СМИ',
        language: 'Язык',
        additional: 'Дополнительные',
        system: 'Система',
        accent: 'Акцент',
        product_list: 'Список продуктов',
        filter: 'Фильтр',
        paging: 'Пейджинг',
        product_card: 'Карточка товара',
        cart: 'Корзина',
        form_input_background: 'Фон',
        form_input: 'Инпут',
        form_input_border: 'Граница',
        form_input_border_hover: 'Граница при наведении',
        form_input_text: 'Текст',
        form_input_text_placeholder: 'Текстовая заглушка',
        form_select_background: 'Фон',
        form_select: 'Выбор формы',
        form_select_border: 'Граница',
        form_select_text: 'Текст',
        form_select_icon: 'Иконка',
        form_select_option_text: 'Текст опции',
        form_select_option_text_hover: 'Текст опции при наведении',
        form_select_option_background_hover: 'Фон опции при наведении',
        success: 'Успех',
        success_bg: 'Успех',
        warning: 'Предупреждение',
        warning_bg: 'Предупреждение',
        error: 'Ошибка',
        error_bg: 'Ошибка',
        accent1: 'Акцент1',
        accent1_bg: 'Акцент1',
        accent2: 'Акцент2',
        accent2_bg: 'Акцент2',
        accent3: 'Акцент3',
        accent3_bg: 'Акцент3',
        accent4: 'Акцент4',
        accent4_bg: 'Акцент4',
        product_list_background: 'Фон',
        product_list_title: 'Заголовок',
        product_list_description: 'Описание',
        product_list_price: 'Цена',
        product_list_price_discount: 'Цена со скидкой',
        product_list_reviews_start: 'Начало обзоров',
        product_list_reviews_text: 'Текст отзывов',
        product_list_availability: 'Доступность',
        product_list_button_buy_background: 'Фон',
        product_list_button_buy: 'Кнопка "Купить"',
        product_list_button_buy_background_hover: 'Фон при наведении',
        product_list_button_buy_background_active: 'Активный фон',
        product_list_button_buy_background_disabled: 'Фон при отключении',
        product_list_button_buy_text: 'Текст',
        product_list_button_buy_text_hover: 'Текст при наведении',
        product_list_button_buy_text_active: 'Активный текст',
        product_list_counter_background: 'Фон',
        common: 'Общее',
        ecommerce: 'Электронная коммерция',
        mobile: 'Мобильный',
        desktop: 'Десктоп',
        icon: 'Иконка',
        menu: 'Меню',
        submenu: 'Подменю',
        input: 'Инпут',
        select: 'Селект',
        button_buy: 'Кнопка «Купить»',
        counter: 'Счётчик',
        parameters: 'Параметры',
        options_button: 'Кнопка «Параметры»',
        options_select: 'Варианты выбора',
        button: 'Кнопка',
        product_list_counter: 'Счётчик',
        product_list_counter_color: 'Цвет',
        product_list_counter_btn_background: 'Фон кнопки',
        product_list_counter_btn_color: 'Цвет кнопки',
        product_list_counter_btn_background_hover: 'Фон кнопки при наведении',
        product_list_counter_btn_color_hover: 'Цвет кнопки при наведении',
        product_list_counter_btn_background_active: 'Фон кнопки активен',
        product_list_counter_btn_color_active: 'Цвет кнопки активен',
        product_list_parameters_background: 'Фон',
        product_list_parameters: 'Параметры',
        product_list_parameters_icon_color: 'Цвет иконки',
        product_list_parameters_icon_background: 'Фон иконки',
        product_list_parameters_text_color: 'Цвет текста',
        product_list_parameters_text_border: 'Текстовая граница',
        product_list_options_button_background: 'Фон',
        product_list_options_button: 'Кнопка «Параметры»',
        product_list_options_button_border: 'Граница',
        product_list_options_button_text: 'Текст',
        product_list_options_button_background_hover: 'Фон при наведении',
        product_list_options_button_border_hover: 'Граница при наведении',
        product_list_options_button_text_hover: 'Текст при наведении',
        product_list_options_button_background_selected: 'Фон при выборе',
        product_list_options_button_border_selected: 'Граница при выборе',
        product_list_options_button_text_selected: 'Текст при выборе',
        product_list_options_select_background: 'Фон',
        product_list_options_select: 'Варианты выбора',
        product_list_options_select_border: 'Граница',
        product_list_options_select_text: 'Текст',
        product_list_options_select_icon: 'Иконка',
        product_list_options_select_option_text: 'Текст опции',
        product_list_options_select_option_text_hover: 'Текст опции при наведении',
        product_list_options_select_option_background_hover: 'Вариант фона при наведении',
        filter_desktop_background: 'Фон на десктопе',
        filter_desktop_border: 'Граница на десктопе',
        filter_desktop_text: 'Текст на десктопе',
        filter_desktop_icon: 'Иконки на десктопе',
        filter_mobile_background: 'Фон на мобильном',
        filter_mobile_border: 'Граница на мобильном',
        filter_mobile_text: 'Текст на мобильном',
        filter_mobile_icon: 'Иконки на мобильном',
        filter_button_background: 'Фон',
        filter_button: 'Кнопка',
        filter_button_background_hover: 'Фон при наведении',
        filter_button_background_active: 'Активный фон',
        filter_button_background_disabled: 'Фон при отключении',
        filter_button_text: 'Текст',
        filter_button_text_hover: 'Текст при наведении',
        filter_button_text_active: 'Активный текст',
        paging_background: 'Фон',
        paging_background_hover: 'Фон при наведении',
        paging_background_active: 'Активный фон',
        paging_background_disabled: 'Фон при отключении',
        paging_text: 'Текст',
        paging_text_hover: 'Текст при наведении',
        paging_text_active: 'Активный текст',
        paging_text_disabled: 'Текст при отключени',
        product_card_background: 'Фон',
        product_card_title: 'Заголовок',
        product_card_description: 'Описание',
        product_card_price: 'Цена',
        product_card_price_discount: 'Цена со скидкой',
        product_card_reviews_start: 'Начало обзоров',
        product_card_reviews_text: 'Текст отзывов',
        product_card_availability: 'Доступность',
        product_card_button_buy_background: 'Фон',
        product_card_button_buy: 'Кнопка купить',
        product_card_button_buy_background_hover: 'Фон при наведении',
        product_card_button_buy_background_active: 'Активный фон',
        product_card_button_buy_background_disabled: 'Фон при отключении',
        product_card_button_buy_text: 'Текст',
        product_card_button_buy_text_hover: 'Текст при наведении',
        product_card_button_buy_text_active: 'Активный текст',
        product_card_counter_background: 'Фон',
        product_card_counter: 'Счётчик',
        product_card_counter_color: 'Цвет',
        product_card_counter_btn_background: 'Фон кнопки',
        product_card_counter_btn_color: 'Цвет кнопки',
        product_card_counter_btn_background_hover: 'Фон кнопки при наведении',
        product_card_counter_btn_color_hover: 'Цвет кнопки при наведении',
        product_card_counter_btn_background_active: 'Активный фон кнопки',
        product_card_counter_btn_color_active: 'Активный цвет текста кнопки',
        product_card_parameters_background: 'Фон',
        product_card_parameters: 'Параметры',
        product_card_parameters_icon_color: 'Цвет иконки',
        product_card_parameters_icon_background: 'Фон иконки',
        product_card_parameters_text_color: 'Цвет текста',
        product_card_parameters_text_border: 'Текстовая граница',
        product_card_options_button_background: 'Фон',
        product_card_options_button: 'Кнопка «Параметры»',
        product_card_options_button_border: 'Граница',
        product_card_options_button_text: 'Текст',
        product_card_options_button_background_hover: 'Фон при наведении',
        product_card_options_button_border_hover: 'Граница при наведении',
        product_card_options_button_text_hover: 'Текст при наведении',
        product_card_options_button_background_selected: 'Фон при выборе',
        product_card_options_button_border_selected: 'Граница при выборе',
        product_card_options_button_text_selected: 'Текст при выборе',
        product_card_options_select_background: 'Фон',
        product_card_options_select: 'Варианты выбора',
        product_card_options_select_border: 'Граница',
        product_card_options_select_text: 'Текст',
        product_card_options_select_icon: 'Иконка',
        product_card_options_select_option_text: 'Текст опции',
        product_card_options_select_option_text_hover: 'Текст опции при наведении',
        product_card_options_select_option_background_hover: 'Фон опции при наведении',
        cart_border: 'Граница',
    },
    common: {
        value: 'Общее',
        description: 'Описание',
        address: 'Адрес',
        emails: 'Emails',
        phones: 'Телефоны',
        sitename: 'Название сайта',
        showAccount: 'Показывать личный кабинет',
        whatsapps: 'WhatsApps',
        workTime: 'Время работы',
        privacyPolicyLink: 'Политика конфиденциальности',
        favicon: 'Favicon',
        sharedImage: 'Shared Image',
        phoneShowOnMobileHeader: 'Показывать телефон в мобильной шапке',
        telegramShowOnMobileHeader: 'Показывать Telegram в мобильной шапке',
        whatsappShowOnMobileHeader: 'Показывать Whatsapp в мобильной шапке',
        emailShowOnMobileHeader: 'Показывать почту в мобильной шапке',
        phoneShowOnMobileMenu: 'Показывать телефон в мобильном меню',
        telegramShowOnMobileMenu: 'Показывать Telegram в мобильном меню',
        whatsappShowOnMobileMenu: 'Показывать Whatsapp в мобильном меню',
        emailShowOnMobileMenu: 'Показывать почту в мобильном меню',
        phoneShowOnWidget: 'Показывать телефон на мобильном виджете',
        telegramShowOnWidget: 'Показывать Telegram на мобильном виджете',
        whatsappShowOnWidget: 'Показывать WhatsApp на мобильном виджете',
        whatsappShowOnWidgetDesktop: 'Показывать WhatsApp на десктопном виджете',
        whatsappMessage: 'Сообщение в Whatsapp',
        type: 'Тип сайта',
        types: {
            title: 'Тип сайта',
            ecommerce: 'Интернет магазин',
            service: 'Сайт услуг',
            serviceCart: 'Сайт услуг c корзиной',
        },
        phonesShowOnContactsHeader: 'Показывать телефон в контатах, в шапке',
        whatsappShowOnContactsHeader: 'Показывать WhatsApp в контатах, в шапке',
        emailsShowOnContactsHeader: 'Показывать почту в контатах, в шапке',
        telegramShowOnContactsHeader: 'Показывать Telegram в контатах, в шапке',
    },
    socials: {
        value: 'Социальные сети',
        facebook: 'Facebook',
        instagram: 'Instagram',
        twitter: 'X',
        linkedIn: 'Linked In',
        medium: 'Medium',
        telegram: 'Телеграм',
        tiktok: 'TikTok',
        vk: 'ВКонтакте',
    },
    header: {
        value: 'Шапка',
        template: 'Шаблон',
        contactsSize: 'Контакты',
        contactsTextSize: 'Размер шрифта в контактах, px',
        logo: 'Логотип',
        logoMobile: 'Логотип на мобильных устройствах',
        logoAlt: 'alt-текст логотипа',
        menu: 'Основное меню',
        menuOptions: 'Main Menu options',
        menuSubOptions: 'Main Sub Menu options',
        menuOptionsTextSize: 'Размер текста, px',
        menuOptionsTextUppercase: 'Заглавными буквами',
        menuOptionsTextColor: 'Цвет теста',
        menuInfo: 'Верхнее меню',
        menuInfoOptions: 'Опции верхнего меню',
        contactsStyles: 'Стили контактов',
        sendEnquiry: 'Кнопк отправки заявки',
        showSearchMobile: 'Показывать поиск на мобильном',
    },
    footer: {
        value: 'Подвал',
        template: 'Шаблон',
        templates: {
            1: 'Template 1',
            2: 'Template 2',
            3: 'Template 3',
            4: 'Template 4',
        },
        background: 'Фон',
        logo: 'Логотип',
        logoAlt: 'Alt-текст для логотипа',
        copywrite: 'Копирайт',
        footerText: 'Текст',
        menuOptions: 'Footer Menu options',
        menuSubOptions: 'Footer Sub Menu options',
        menuOptionsTextSize: 'Размер текста, px',
        menuOptionsTextUppercase: 'Заглавными буквами',
        menuOptionsTextColor: 'Цвет теста',
        menuColumns: 'Кол-во колонок в меню',
        menu: 'Меню в подвале',
    },
    ecommerce: {
        currency: 'Валюта',
        value: 'Ecommerce',
        template: 'Шаблон страницы продукта',
        templates: {
            1: 'Template 1',
            2: 'Template 2',
            3: 'Template 3',
            4: 'Template 4',
        },
        templateListing: 'Шаблон карточки продукта в списке',
        itemPerPage: 'Количество продуктов на странице',
        defaultColumns: 'Элементов в ряд',
        previewSizeInListing: 'Размер картинки в списке (px)',
        previewCustomSizeInListing: {
            title: 'Размер картинки в списке (px)',
            note: 'Пример: 600x600; каждое число должно быть больше 0 и меньше 1000',
        },
        previewSizeInItem: 'Размер картинки в карточке',
        previewCustomSizeInItem: {
            title: 'Размер картинки в карточке',
            note: 'Пример: 16x9; каждое число должно быть больше 0 и меньше 1000',
        },
        defaultColumnsDesktop: 'Продуктов в строчку в списке (десктоп)',
        defaultColumnsTablet: 'Продуктов в строчку в списке (планшет)',
        defaultColumnsMobile: 'Продуктов в строчку в списке (мобильник)',
        columnsCount: 'Количество продуктов',
        shippingPolicy: 'Информация о доставке',
        refundPolicy: 'Информация о возврате',
        imageCrop: 'Метод обрезки изображений',
        imageCropVariants: {
            inset:
                'Inset - Масштабирует изображение настолько, насколько это возможно в пределах его контейнера, не обрезая и не растягивая изображение.',
            outbound:
                'Outbound - Если пропорции картинки отличаются от пропорций контейнера, изображение обрезается по вертикали',
            original: 'Original - Исходное изображение.',
        },
        skuIsShow: 'Показывать sku на сайте',
        showLeftSidebarInCatalog: 'Показывать фильтры в каталоге',
        showSortingInCatalog: 'Показывать сортировку в каталоге',
        showRelatedProducts: 'Показывать похожие товары',
        showPriceForOutOfStock: 'Показать цену на товар, которого нет в наличии',
        leadsPopupTitle: 'Заголовок всплывашки заявок',
        checkoutFormMessage: 'Собщение в оформлении заказа',
        checkoutOrderMessage: 'Собщение в чеке заказа',
        showLeadsPopupPolicy: 'Показывать политику для заявок',
        leadsPopupEnabledByDefault: 'Включено по умолчанию',
        leadsPopupPolicyText: 'Текст политики в заявке',
        showCheckoutPolicy: 'Показывать политику в форме заказа',
        checkoutPolicyEnabledByDefault: 'Включено по умолчанию',
        checkoutPolicyText: 'Текст политики в форме заказа',
        fastOrder: 'Быстрый заказ',
        showCheckoutReceiver: 'Показвать в форме другого получателя',
        pageNavigationType: 'Навигация в каталоге',
        btnLoadMore: 'Кнопка загрузить ещё',
        productWidgetRelatedPosition: 'Положение похожих товаров в корзине',
        productReviewsEnabled: 'Показывать отзывы и оценки продуктов',
        showQuantity: 'Показывать количество',
        showPriceFrom: 'Цена формата "От {цена}"',
        priceFormatSeparator: 'Формат цены - разделитель',
        modalAgeVerification: 'Всплывашка подтверждения возраста',
        modalCookieAccept: 'Всплывашка подтверждения использования cookie',
        filter: 'Фильтр',
        type: 'Тип',
        productDetails: 'Характеристики продукта',
        templateInProductList: 'Шаблон в списке продуктов',
        templateInProductCard: 'Шаблон в карточке продукта',
    },
    seo: {
        value: 'SEO',
        googleAnalytics: 'Google Аналитика (ID)',
        googleVerification: 'Код верификации Google',
        robots: 'Robots.txt',
        metaPixel: 'Meta Pixel (ID)',
        yandexMetrika: 'Яндекс Метрика (ID)',
        yandexVerification: 'Яндекс Верификация',
        callTrackingMango: 'Call Tracking Mango',
        metatags: {
            heading: 'Meta Tags Templates',
            variables: 'Доступные переменные',
            h1: 'Заголовок (h1)',
            title: 'Meta-title',
            description: 'Meta-description',
            product: 'Товары',
            catalog: 'Каталоги',
            page: 'Страницы',
            category: 'Категории',
            article: 'Статьи',
        },
        redirects: {
            value: 'Редиректы',
            create: 'Создать редирект',
            fromUrl: 'С URL',
            fromFile: 'Загрузить из файла',
            toUrl: 'На URL',
            toObject: 'На объект',
            file: 'Файл',
        },
        customCode: {
            title: 'Custom Code (JS)',
            note: 'Внимание! Сторонний код может сильно повлиять на производительность и корректность работы сайта',
        },
        customCodeFooter: {
            title: 'Custom Code Footer',
            note: 'Внимание! Сторонний код может сильно повлиять на производительность и корректность работы сайта',
        },
    },
    paymentCashOnDelivery: {
        value: 'Наличными при получении',
        methodName: 'Название',
    },
    paymentYooKassa: {
        value: 'YooKassa',
        enabled: 'Включено',
        currency: 'Валюта',
        returnUrl: 'URL возврата',
        secret: 'Secret',
        shopId: 'ShopID',
        vatCode: 'Vat-код',
        methodName: 'Название',
    },
    paymentNetworkGenius: {
        value: 'NetworkGenius',
        reference: 'reference',
        secretKey: 'secretKey',
        headerToken: 'headerToken',
        language: 'Язык',
        currency: 'Валюта',
        testMode: 'Тест-мод',
        methodName: 'Название',
    },
    paymentStripe: {
        value: 'Stripe',
        apiKey: 'Апи-ключ',
        currency: 'Валюта',
        testMode: 'Тест-мод',
        methodName: 'Название',
    },
    erpFirstBit: {
        value: 'FirstBit',
        enabled: 'Активно',
        url: 'Url',
        login: 'Логин',
        password: 'Пароль',
    },
    bitrix: {
        value: 'Bitrix',
        enabled: 'Активно',
        domain: 'Домен',
        userId: 'ID пользователя',
        secret: 'Секретный ключ',
    },
    notifierEmail: {
        value: 'Email Notification Settings',
        enabled: 'Активно',
        recipients: 'Получатели',
        senderEmail: 'Email отправителя',
        senderName: 'Имя отправителя',
        addRecipient: 'Добавить получателя',
        logo: 'Логотип для писем (png)',
    },
    notifierTelegram: {
        value: 'Telegram Notification Settings',
        enabled: 'Enable',
        chatId: 'chatId',
        token: 'Токен',
        template: 'Шаблон',
        templateStandard: 'Стандратный шаблон',
        templateCustom: 'Использовать свой шаблон',
        availableVariables: 'Доступные переменные',
        clientInfo: 'Информация о клиенте',
        orderInfo: 'Информация о заказе',
        shippingAndDeliveryInfo: 'Информация о доставке',
        yourInfo: 'Информация о магазине',
        variables: {
            orderNumber: 'Номер заказа',
            name: 'Имя клиента',
            domain: 'Домен',
            email: 'Email клиента',
            phone: 'Номер телефона клиента',
            country: 'Страна',
            region: 'Регион',
            city: 'Город',
            address: 'Адрес',
            apartment: 'Квартира',
            postCode: 'Почтовый индекс',
            deliveryOrPickupAddress: 'Адрес доставки или пункта самовывоза',
            deliveryPrice: 'Стоимость доставки',
            total: 'Стоимость заказа',
            orderList: 'Список товаров в заказе',
            date: 'Дата создания заказа',
            location: 'Пункт самовывоза',
            text: 'Текст',
            copyright: 'Политика авторского права',
            logo: 'Лого вашего магазина',
        },
    },
    language: {
        value: 'Язык сайта',
        current: {
            title: 'Язык сайта',
            variants: {
                en: 'Английский',
                ru: 'Русский',
            },
        },
    },
    additional: {
        value: 'Дополнительно',
        optionsView: 'Отображение опций',
    },
};
