<template>
    <div class="loader-wrapper"><progress-circular v-if="!isDataLoaded" size="medium"></progress-circular></div>
</template>

<script>
import { fetchAuthorizationCode, getAccessToken, isReturningFromAuthServer } from '@/api/auth';
import ProgressCircular from '@/components/common/ProgressCircular';

export default {
    components: { ProgressCircular },
    data() {
        return {
            isLogged: false,
            isDataLoaded: false,
        };
    },
    async mounted() {
        console.log('Login form mounted.');
        await isReturningFromAuthServer()
            .then((hasAuthCode) => {
                if (!hasAuthCode) {
                    console.log('No auth code, just show login screen');
                    return;
                }
                console.log('Auth code received. Getting access token...');
                return getAccessToken().then(() => {
                    this.isLogged = true;
                    // const instanceId = localStorage.getItem('runshop_instance_id');
                    // console.log(localStorage.getItem('redirect-after-auth'), 'Login Form');
                    // localStorage.removeItem('redirect-after-auth');

                    // if (!instanceId) {
                    //     this.$router.push({
                    //         name: 'Instance List',
                    //     });
                    // }
                    // this.$router.push({ name: 'Dashboard' });
                    window.location.href = '/';
                });
            })
            .catch((potentialError) => {
                if (potentialError) {
                    console.error('Callback error:' + potentialError);
                }
            });
        setTimeout(async () => {
            if (!this.isLogged) {
                console.log('loginForm');
                await fetchAuthorizationCode();
            }
        }, 500);
    },
};
</script>

<style lang="scss">
.loader-wrapper {
    display: flex;
    justify-content: center;
    padding: 40px;
    width: 100%;
}
</style>
