<template>
    <div class="builder-sidebar-left-header">
        <span class="builder-sidebar-left-header__text" @click="openPageSettings">{{ pageTitle | capitalize }}</span>
        <a class="builder-sidebar-left-header__icon" target="_blank" :href="linkToPublic">
            <Button icon="open_in_new" type="icon"></Button>
        </a>
    </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import Button from '@/components/common/Button.vue';

export default {
    name: 'BuilderSidebarLeftHeader',
    components: { Button },
    computed: {
        ...mapGetters('builder', {
            currentPage: 'getCurrentPage',
        }),
        ...mapGetters('globalVars', { instanceFull: 'getInstanceFull' }),
        linkToPublic() {
            if (!this.currentPage) return '';
            const publicUrl = `https://${this.instanceFull.primaryDomain}`;
            if (this.currentPage?.type === 'homepage') return `${publicUrl}`;
            if (this.currentPage?.type === 'catalog') return `${publicUrl}/catalogs/${this.currentPage.slug}`;
            return `${publicUrl}/${this.currentPage.slug}`;
        },
        pageTitle() {
            if (this.currentPage?.type === 'homepage') return this.$t('builder.pageType.homepage');
            if (this.currentPage?.heading) return this.currentPage.heading;
            if (this.currentPage?.title) return this.currentPage.title;
            return this.$route.params.type;
        },
    },
    methods: {
        ...mapActions('builder', {
            updateActiveItem: 'updateActiveItem',
        }),
        openPageSettings() {
            this.updateActiveItem({
                type: 'page',
                data: cloneDeep(this.currentPage),
            });
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.builder-sidebar-left-header {
    display: flex;
    align-items: center;
    min-height: 72px;
    padding: 0 20px;

    &__text {
        font-size: 1.3571rem !important;
        font-weight: 500;
        line-height: 1.3571rem;
        letter-spacing: 0.0073529412em !important;
        cursor: pointer;
    }

    &__icon {
        position: relative;
        top: 1px;
        left: 4px;
    }

    .btn__icon {
        font-size: 24px;
    }
}
</style>
