export default {
    errors: {
        empty: 'Это значение не должно быть пустым',
        atLeastOne: 'Пожалуйста, укажите URL или объект для редиректа, но не то и другое',
        url: 'Это значение - не валидный URL',
        email: 'Некорректный email',
        phone: 'Некорректный формат телефона',
        notSelected: 'Выберите по крайней мере одно',
        lessThan: 'Должно быть меньше чем цена',
        biggerThan: 'Должно быть больше минимального значения',
        discount: 'Пожалуйста, введите корректное значение',
        equalTo: 'Пароли должны совпадать',
        domainIsNotConfirmed: 'Домен не подтверждён',
        domainCantBeUsedInEmail: 'Этот домен не может быть использован как email',
        domainIsNotFound: 'Домен не найден',
        optionValuesTypeNumber: 'Значения этой опции должны быть целыми числами',
        size: 'Должны быть указаны все значения',
        domain: 'Некорректное имя домена',
        orderQuantity: 'Данный товар недоступен в таком количестве',
        maxLength: 'Значение не должно быть длинее {length} символов',
        resolution: 'Пожалуйста, введите корректное значение',
    },
};
