<template>
    <modal v-model="model" :header="$t('entities.payment.add')">
        <form class="payment-modal modal__content form">
            <template>
                <form-item class="form__input">
                    <Select v-model="selected" :items="systems"></Select>
                </form-item>
            </template>
        </form>
        <Button class="modal__save-btn" :disabled="!selected" @click="submit" icon="check">
            {{ $t('entities.add') }}
        </Button>
    </modal>
</template>

<script>
import { proxyModelMixin } from '@/mixins/proxyModelMixin';
import FormItem from '@/components/form/item';
import Modal from '@/components/common/Modal';
import Button from '@/components/common/Button';
import Select from '@/components/form/controls/Select';
export default {
    name: 'PaymentAddItemModal',
    mixins: [proxyModelMixin],
    components: {
        Select,
        FormItem,
        Modal,
        Button,
    },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        systems: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            selected: null,
        };
    },
    methods: {
        submit() {
            this.$emit('add-system', this.selected);
            this.selected = null;
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.payment-modal {
    min-height: 100px !important;
}
</style>
