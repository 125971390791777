<template>
    <div class="page redirects">
        <redirect-modal
            :redirect-id="editingRedirectId"
            @action-success="onCloseModal"
            v-model="isEditModalOpen"
        ></redirect-modal>
        <div class="page-header">
            <h1 class="page-header__text">{{ $t('menus.pageHeaders.redirects') }}</h1>
        </div>
        <error-block v-if="!isAvailable"></error-block>
        <template v-else>
            <div class="block">
                <form class="form redirects__form block__body">
                    <progress-circular class="modal__spinner spinner" v-if="isFormLoading"></progress-circular>
                    <template v-else>
                        <h2>{{ $t('entities.seo.redirect.manually') }}</h2>
                        <form-item
                            class="form__input"
                            :note="$t('entities.seo.redirect.example', { val: '/old_url' })"
                            v-bind="form.fields.from.props"
                        >
                            <input-text
                                v-bind="form.fields.from.props || {}"
                                @update-field="onUpdateFieldValue"
                                name="from"
                                class="-has-slash"
                                v-on="getFieldEvents(form.fields.from.events)"
                                v-model="form.from"
                                placeholder="/url"
                            ></input-text>
                        </form-item>
                        <form-item
                            class="form__input"
                            v-if="!form.subject.name"
                            :note="$t('entities.seo.redirect.example', { val: '/new_url' })"
                            v-bind="form.fields.to.props"
                        >
                            <input-text
                                v-bind="form.fields.to.props || {}"
                                @update-field="onUpdateFieldValue"
                                name="to"
                                class="-has-slash"
                                v-on="getFieldEvents(form.fields.to.events)"
                                v-model="form.to"
                                placeholder="/url"
                            ></input-text>
                        </form-item>
                        <form-item class="form__input" v-if="!form.to" v-bind="form.fields.name.props">
                            <Select
                                :items="$options.objectTypes"
                                @change="onChangeObjectType"
                                v-model="form.subject.name"
                                v-bind="form.fields.name.props || {}"
                                @update-field="onUpdateFieldValue"
                                name="name"
                                v-on="getFieldEvents(form.fields.name.events)"
                                clearable
                            ></Select>
                        </form-item>
                        <form-item
                            class="form__input redirects__select-entity"
                            v-if="form.subject.name"
                            v-bind="form.fields.id.props"
                        >
                            <div class="select-entity__input">
                                <search-form
                                    :label="form.subject.name | capitalize"
                                    v-model="form.subject.id"
                                    :searchable="form.subject.name"
                                    :fields="['title']"
                                    only-model
                                    v-bind="form.fields.id.props || {}"
                                    @update-field="onUpdateFieldValue"
                                    name="id"
                                    v-on="getFieldEvents(form.fields.id.events)"
                                    autocomplete
                                    returnProp="id"
                                ></search-form>
                                <Button type="icon" @click="removeSubject" icon="delete"></Button>
                            </div>
                        </form-item>
                        <div class="form__input">
                            <Button icon="add_circle_outline" @click="createRedirect">
                                {{ $t('settings.seo.redirects.create') }}
                            </Button>
                        </div>
                        <span class="divider" />
                        <h2 style="width: 100%">{{ $t('entities.seo.redirect.upload') }}</h2>
                        <div class="redirects__file-input">
                            <file-input
                                hide-details
                                :label="$t('settings.seo.redirects.file')"
                                v-model="file"
                            ></file-input>
                            <Button icon="add_circle_outline" @click="uploadFromFile">
                                {{ $t('settings.seo.redirects.fromFile') }}
                            </Button>
                        </div>
                        <alert v-if="!isImported" type="primary">
                            <div v-html="$t('entities.seo.redirect.tooltips.importFormat')"></div>
                        </alert>
                        <alert v-if="isImported" type="success">
                            <div
                                v-html="
                                    $t('entities.seo.redirect.tooltips.importSuccess', {
                                        created: importedInfo.created,
                                        updated: importedInfo.updated,
                                    })
                                "
                            ></div>
                        </alert>
                    </template>
                </form>
            </div>
            <div class="redirects__table" v-if="isLoad">
                <Table
                    @delete-selected-items="openDeletionModal"
                    show-select
                    :sort-by="sortBy"
                    :sort-desc="sortDesc"
                    :columns="headers"
                    :items="items"
                    v-model="selected"
                    local-sort
                    @update:sort="updateSort"
                    :is-data-loaded="!isTableLoading"
                >
                    <!-- ACTIONS -->
                    <template v-slot:item.actions="{ item }">
                        <div class="table__actions">
                            <Button
                                :title="$t('lists.editButton.product')"
                                @click="openModal(item.id)"
                                icon="edit"
                                type="icon"
                            />
                            <Button @click="openDeletionModal(item)" type="icon" icon="delete"> </Button>
                        </div>
                    </template>
                    <!--  subjectId  -->
                    <template v-slot:item.subject.title="{ item }">
                        <template v-if="item.subject.title">
                            <span v-if="item.subject.title === 'Not Found'">Deleted</span>
                            <template v-else>
                                <router-link
                                    v-if="item.subject.name === 'page'"
                                    :to="{ name: 'Edit Page', params: { id: item.subject.id } }"
                                >
                                    {{ item.subject.title }}
                                </router-link>
                                <router-link
                                    v-if="item.subject.name === 'product'"
                                    :to="{ name: 'Edit Product', params: { id: item.subject.id } }"
                                >
                                    {{ item.subject.title }}
                                </router-link>
                                <router-link
                                    v-if="item.subject.name === 'catalog'"
                                    :to="{ name: 'Edit Catalog', params: { id: item.subject.id } }"
                                >
                                    {{ item.subject.title }}
                                </router-link>
                            </template>
                        </template>
                    </template>
                    <!-- TITLE -->
                    <template v-slot:item.title="{ item }">
                        <router-link :to="{ name: 'Edit Page', params: { id: item.id, type: item.type } }">{{
                            item.title
                        }}</router-link>
                    </template>
                </Table>
            </div>
        </template>
    </div>
</template>

<script>
import { debounce } from 'lodash';
import RedirectService, { OBJECT_TYPES } from '@/services/RedirectService';
import Redirect from '@/entities/Redirect';
import FileInput from '@/components/form/controls/FileInput';
import EventEmitter from '@/helpers/eventEmitter.ts';
import ErrorBlock from '@/components/common/ErrorBlock';
import FormItem from '@/components/form/item';
import { validateField } from '@/entities/seo/redirectList.fields';
import Table from '@/components/common/Table';
import InputText from '@/components/form/controls/InputText';
import Select from '@/components/form/controls/Select';
import Button from '@/components/common/Button';
import PageService from '@/services/PageService';
import ProductService from '@/services/ProductService';
import CatalogService from '@/services/CatalogService';
import ProgressCircular from '@/components/common/ProgressCircular';
import Alert from '@/components/common/Alert';
import RedirectModal from '@/components/redirect/RedirectModal';

export default {
    name: 'RedirectList',

    components: {
        Alert,
        ProgressCircular,
        Select,
        InputText,
        FormItem,
        ErrorBlock,
        FileInput,
        SearchForm: () => import('@/components/search/SearchForm.vue'),
        Table,
        Button,
        RedirectModal,
    },

    objectTypes: OBJECT_TYPES,

    async created() {
        EventEmitter.on('delete-redirect', this.deleteItems);
        await this.getItems();
        await this.getRedirectSubjects();
        this.isLoad = true;
    },

    data() {
        return {
            isTableLoading: true,
            isFormLoading: true,
            isLoad: false,
            items: [],
            sortBy: null,
            sortDesc: null,
            form: new Redirect(),
            selectObject: {},
            isAvailable: true,
            selected: [],
            fieldKeys: Object.keys(new Redirect().fields),
            file: null,
            importedInfo: null,
            isEditModalOpen: false,
            editingRedirectId: '',
            isImported: false,
        };
    },

    computed: {
        headers() {
            return [
                { text: this.$t('lists.columns.fromUrl'), value: 'from', sortable: true },
                { text: this.$t('lists.columns.toUrl'), value: 'to', sortable: true },
                { text: this.$t('lists.columns.toObject'), value: 'subject.title' },
                { text: this.$t('lists.columns.subjectType'), value: 'subject.name', width: '150px' },
                { value: 'actions', width: '114px' },
            ];
        },
    },

    methods: {
        async getItems() {
            this.isTableLoading = true;
            this.isFormLoading = true;
            this.items = await this.getRedirects();
            this.isTableLoading = false;
            setTimeout(() => {
                this.isFormLoading = false;
            }, 300);
        },

        openModal(id) {
            this.editingRedirectId = id;
            this.isEditModalOpen = true;
        },

        async getRedirects(params) {
            const [error, result] = await RedirectService.getAll(params);
            if (error) {
                error.alert();
                this.isAvailable = false;
                return [];
            }
            return result;
        },

        async getRedirectSubjects() {
            // Гадость редкостная получилась, потом стоит оптимизнуть
            if (!this.items.length) return;

            const subjects = {
                product: {},
                page: {},
                catalog: {},
            };
            const subjectsItem = this.items.filter((item) => item.subject.id);
            subjectsItem.forEach((item) => {
                subjects[item.subject.name][item.subject.id] = 'Not Found';
            });
            const services = {
                page: PageService,
                product: ProductService,
                catalog: CatalogService,
            };
            for (const type of ['page', 'catalog']) {
                const [error, result] = await services[type].getAll({
                    ids: Object.keys(subjects[type]),
                });
                if (error) error.alert();
                result.forEach((item) => {
                    subjects[type][item.id] = item.title;
                });
            }
            for (const type of ['product']) {
                const [error, result] = await services[type].getAll({
                    ids: Object.keys(subjects[type]),
                });
                if (error) error.alert();
                result.products.forEach((item) => {
                    subjects[type][item.id] = item.title;
                });
            }
            this.items.forEach((item) => {
                if (item.subject?.name && item.subject?.id) {
                    const title = subjects[item.subject?.name][item.subject?.id];
                    this.$set(item.subject, 'title', title);
                }
            });
        },

        //перенос в fields получается костыльным

        onChangeObjectType() {
            this.form.to = '';
            this.selectObject = {};
        },

        async onCloseModal(val) {
            if (!val) {
                this.isTableLoading = true;
                await this.getItems();
                await this.getRedirectSubjects();
                this.isTableLoading = false;
            }
        },

        async createRedirect() {
            const isFormValid = this.validateForm();
            if (isFormValid === false) return;
            const [error, result] = await RedirectService.createOne(this.form.data);
            error ? this.failedHandler(result, error) : this.successCreateHandler();
        },

        async deleteItems(items) {
            this.isTableLoading = true;
            const promises = items.map((item) => RedirectService.removeOne(item.id));
            const results = await Promise.all(promises);
            if (
                results.every((item) => {
                    const [error] = item;
                    return !error;
                })
            ) {
                if (items.length === 1) {
                    EventEmitter.trigger('show-noty', {
                        type: 'success',
                        text: this.$tc('notifications.deleted', 1, {
                            entity: this.$tc('entities.seo.redirect.title', 1),
                        }),
                    });
                } else {
                    EventEmitter.trigger('show-noty', {
                        type: 'success',
                        text: this.$tc('notifications.deleted', 2, {
                            entity: this.$tc('entities.seo.redirect.title', 2),
                        }),
                    });
                }
                this.selected = [];
                this.$store.dispatch('modals/closeDeletionModal');
                await this.getItems();
                await this.getRedirectSubjects();
            }
            this.isTableLoading = false;
        },
        openDeletionModal(items) {
            this.$store.dispatch('modals/openDeletionModal', { items, entity: 'redirect' });
        },

        updateSort(sortBy, sortDesc) {
            this.sortBy = sortBy;
            this.sortDesc = sortDesc;
        },

        validateForm() {
            let result = true;
            const fieldKeys = Object.keys(this.form.fields);
            fieldKeys.forEach((key) => {
                const errors = validateField(key, this.form.fields, this.form);
                if (errors.length !== 0) result = false;
                this.$set(this.form.fields[key].props, 'errors', errors);
            });
            if (result === false)
                EventEmitter.trigger('show-noty', {
                    type: 'error',
                    text: this.$t('notifications.validation.error'),
                });
            return result;
        },

        async resetForm() {
            this.form = new Redirect();
        },

        successCreateHandler() {
            this.resetForm();
            this.getItems();
            this.getRedirectSubjects();
            EventEmitter.trigger('show-noty', {
                type: 'success',
                text: this.$tc('notifications.created', 1, { entity: this.$tc('entities.seo.redirect.title', 1) }),
            });
        },

        failedHandler(result, error) {
            error.notify();
            const children = result.data?.errors?.children;
            if (children) {
                this.fieldKeys.forEach((key) => {
                    const errors = children[key] ? children[key].errors : [];
                    if (errors) this.$set(this.form.fields[key].props, 'errors', errors);
                });
            }
        },

        onUpdateFieldValue: debounce(function(payload) {
            const { name } = payload;
            const errors = validateField(name, this.form.fields, this.form);
            this.$set(this.form.fields[name].props, 'errors', errors);
        }, 600),

        getFieldEvents(events) {
            const fieldEvents = {};
            for (const key in events) {
                fieldEvents[key] = events[key]?.bind(this.form);
            }
            return fieldEvents;
        },

        async uploadFromFile() {
            this.isTableLoading = true;
            if (!this.file) return;
            const [error, result] = await RedirectService.uploadFromFile(this.file);
            if (error) {
                error.notify();
                return;
            }
            this.isImported = true;
            this.importedInfo = {
                created: result.created,
                updated: result.updated,
            };
            await this.getItems();
            await this.getRedirectSubjects();
            this.isTableLoading = false;
        },
        removeSubject() {
            this.form.subject.name = null;
            this.form.subject.id = null;
        },
    },
    beforeDestroy() {
        EventEmitter.off('delete-redirect', this.deleteItems);
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.redirects {
    &__file-input {
        display: flex;
        width: 100%;
        gap: 24px;
        .btn {
            margin-top: auto;
        }
    }
    h2 {
        font-size: 21px;
    }
    .tooltip__link {
        text-decoration: underline !important;
    }
    .tooltip__important {
        font-weight: 600 !important;
        margin-top: 8px;
    }
    .input-text {
        &.-has-slash {
            .prefix {
                margin-right: 0;
            }
        }
    }
    &__select-entity {
        .select-entity__input {
            display: flex;
            gap: 10px;
            flex-wrap: nowrap;
        }
        .btn {
            align-self: end;
            margin-bottom: 3px;
        }
    }
    .file-input {
        width: 30%;
        .heading {
            margin-left: 33px;
            margin-bottom: 8px;
        }
        .v-input__prepend-outer {
            margin-top: auto;
            margin-bottom: auto;
        }
        .v-input {
            display: flex;
            align-items: center;
        }
        .v-icon {
            font-size: 30px;
        }
    }

    &__form {
        min-height: 339px;
    }
    &__table {
        margin-top: 32px;
        .v-card__text {
            padding: 0 24px 32px 24px !important;
        }
    }
}
</style>
