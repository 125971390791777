export default {
    enabled: {
        id: null,
        type: 'boolean',
        value: false,
        options: [],
        position: 0,
    },
    recipients: {
        id: null,
        type: 'array',
        component: 'InputArray',
        value: [],
        options: [],
        position: 1,
    },
    senderEmail: {
        id: null,
        type: 'string',
        value: '',
        options: [],
        position: 2,
    },
    senderName: {
        id: null,
        type: 'string',
        value: 'RunShop',
        options: [],
        position: 3,
    },
    logo: {
        id: null,
        type: 'string',
        value: '',
        options: [],
        position: 4,
    },
};
