import { cloneDeep } from 'lodash';
import { STRING, SELECT, BUTTON } from '@/helpers/fieldTypes.no-vuetify';
import { Heading, Section, Content } from '@/entities/section/samples/_options';

const section = {
    id: '',
    type: 'SectionSlider',
    required: false,
    group: 'Slider',
    options: {
        section: {
            width: { ...Section.width },
            padding: {
                ...Section.padding,
                value: {
                    desktop: { top: 0, right: 0, bottom: 0, left: 0 },
                    tablet: { top: 0, right: 0, bottom: 0, left: 0 },
                    mobile: { top: 0, right: 0, bottom: 0, left: 0 },
                },
            },
        },
        content: {
            heightDesktop: {
                type: 'NUMBER',
                component: 'InputRange',
                value: 494,
                props: {
                    type: 'number',
                    min: 300,
                    max: 800,
                    labelTrans: 'builder.sectionOptions.props.heightDesktop',
                },
            },
            heightMobile: {
                type: 'NUMBER',
                component: 'InputRange',
                value: 385,
                props: {
                    type: 'number',
                    min: 245,
                    max: 500,
                    labelTrans: 'builder.sectionOptions.props.heightMobile',
                },
            },
            autoplay: {
                type: 'boolean',
                component: 'InputSwitch',
                value: true,
                props: {
                    size: 'small',
                    type: 'boolean',
                    labelTrans: 'builder.sectionOptions.props.autoplay',
                },
            },
            autoplaySpeed: {
                type: 'number',
                component: 'InputText',
                value: 3000,
                props: {
                    type: 'number',
                    labelTrans: 'builder.sectionOptions.props.autoplaySpeed',
                },
            },
        },
    },
    items: [],
    children: {
        id: '',
        type: 'slide',
        options: {
            heading: {
                title: { ...Heading.title },
                color: {
                    value: '',
                    component: 'ColorPicker',
                    props: {
                        labelTrans: 'builder.sectionOptions.props.color',
                        btnReset: true,
                        mode: 'hexa',
                    },
                },
            },
            content: {
                image: {
                    ...Content.image,
                    props: {
                        note: 'JPG, PNG. Recomended size: 1920х494px. No more than 500kb',
                    },
                },
                imageMobile: {
                    ...Content.imageMobile,
                    props: {
                        note: 'JPG, PNG. Recomended size: 567х385px. No more than 50kb',
                    },
                },
                background: {
                    value: '',
                    component: 'ColorPicker',
                    props: {
                        labelTrans: 'builder.sectionOptions.props.background',
                        btnReset: true,
                        mode: 'hexa',
                    },
                },
                opacity: {
                    ...Content.opacity,
                    value: 0,
                },
                opacityMobile: {
                    ...Content.opacity,
                    value: 0,
                    props: {
                        labelTrans: 'builder.sectionOptions.props.opacityMobile',
                    },
                },
                text: { ...Content.text },
                colorText: {
                    value: '',
                    component: 'ColorPicker',
                    props: {
                        labelTrans: 'builder.sectionOptions.props.colorText',
                        btnReset: true,
                        mode: 'hexa',
                    },
                },
                tag: {
                    value: 'Tag',
                    ...STRING,
                    props: {
                        labelTrans: 'builder.sectionOptions.props.tag',
                    },
                },
                colorTag: {
                    value: '',
                    component: 'ColorPicker',
                    props: {
                        labelTrans: 'builder.sectionOptions.props.colorTag',
                        btnReset: true,
                        mode: 'hexa',
                    },
                },
                link: { ...Content.link },
            },
            align: {
                mobile: {
                    value: '-mobile-text-overlay',
                    ...SELECT,
                    ...{
                        props: {
                            translate: true,
                            labelTrans: 'builder.sectionOptions.props.mobile',
                            items: [
                                { text: 'builder.sectionOptions.items.overlay', value: '-mobile-text-overlay' },
                                {
                                    text: 'builder.sectionOptions.items.overlayLeft',
                                    value: '-mobile-text-overlay-left',
                                },
                                {
                                    text: 'builder.sectionOptions.items.overlayRight',
                                    value: '-mobile-text-overlay-right',
                                },
                                { text: 'builder.sectionOptions.items.textFirst', value: '-mobile-text-first' },
                                { text: 'builder.sectionOptions.items.imageFirst', value: '-mobile-text-last' },
                                { text: 'builder.sectionOptions.items.noText', value: '-mobile-no-text' },
                            ],
                        },
                    },
                },
                desktop: {
                    value: '-desktop-text-center',
                    ...SELECT,
                    ...{
                        props: {
                            translate: true,
                            labelTrans: 'builder.sectionOptions.props.desktop',
                            items: [
                                { text: 'builder.sectionOptions.items.left', value: '-desktop-text-left' },
                                { text: 'builder.sectionOptions.items.center', value: '-desktop-text-center' },
                                { text: 'builder.sectionOptions.items.right', value: '-desktop-text-right' },
                            ],
                        },
                    },
                },
            },
            button: {
                value: {
                    isShow: false,
                    styles: 'primary',
                    text: 'Button',
                    textColor: null,
                    bgColor: null,
                    link: null,
                    iconLeft: null,
                    iconRight: null,
                    type: 'Link',
                    url: null,
                },
                ...BUTTON,
            },
        },
    },
};

const itemFixture = cloneDeep(section.children);
const itemsFixture = [];
itemsFixture.push(cloneDeep(itemFixture));
section.items = itemsFixture;

export default section;
