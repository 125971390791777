<template>
    <modal
        :width="width"
        v-model="model"
        name="deletion-modal"
        :content-class="{ '-delete-all': deleteAll }"
        :header="
            deleteAll
                ? $t('menus.deletionModal.deleteAllHeader', {
                      item: $tc(`menus.deletionModal.entities.${entity}`, 2),
                  })
                : $t('menus.deletionModal.header', {
                      item: $tc(`menus.deletionModal.entities.${entity}`, 2),
                  })
        "
    >
        <div class="modal__content -deletion">
            <img src="@/assets/images/alarm.svg" alt="alarm" />
            {{
                deleteAll
                    ? $t('menus.deletionModal.deleteAllText', {
                          item: $tc(`menus.deletionModal.entities.${entity}`, 2),
                      })
                    : $tc('menus.deletionModal.text', entityCount, {
                          item: $tc(`menus.deletionModal.entities.${entity}`, entityCount),
                      })
            }}
        </div>
        <div class="modal__footer">
            <Button class="modal__back-btn" type="secondary" icon="chevron_left" @click="model = false">{{
                $t('entities.back')
            }}</Button>
            <Button class="modal__delete-btn" :icon="deleteAll ? 'delete_forever' : 'delete'" @click="deleteEntity">{{
                deleteAll ? $t('entities.deleteAll') : $t('entities.delete')
            }}</Button>
        </div>
    </modal>
</template>

<script>
import Modal from '@/components/common/Modal';
import EventEmitter from '@/helpers/eventEmitter.ts';
import { mapState } from 'vuex';
import { proxyModelMixin } from '@/mixins/proxyModelMixin';
import Button from '@/components/common/Button';
export default {
    name: 'DeletionModal',
    components: { Button, Modal },
    mixins: [proxyModelMixin],
    props: {
        value: {
            type: Boolean,
        },
        entity: {
            type: String,
            default: 'this item',
        },
        width: {
            type: String,
            default: '432',
        },
        deleteAll: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapState('modals', ['deletion']),
        entityCount() {
            return this.deletion.items.length === 1 ? 1 : 2;
        },
    },
    methods: {
        deleteEntity() {
            EventEmitter.trigger(`delete-${this.entity}`, this.deletion.items, this.deleteAll);
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.modal {
    &__content {
        &.-deletion {
            flex-direction: row;
            font-size: 16px;
            gap: 16px;
            flex-wrap: nowrap;
        }
    }
    &__footer {
        margin-top: 32px;
        display: flex;
        justify-content: space-between;
    }
    &__delete-btn {
        width: 168px;
    }
    &__back-btn {
        width: 168px;
    }
}
.-delete-all {
    .modal__delete-btn {
        background-color: var(--v-warning-lighten-base);
        color: var(--v-warning-base);
    }
}
</style>
