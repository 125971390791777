export default {
    itemsStyle: {
        id: null,
        position: 30,
        type: 'object',
        component: 'CompositeField',
        value: {
            template: 'style1',
            imageProportions: {
                desktop: '4x3',
                tablet: '4x3',
                mobile: '4x3',
            },
            borderRadius: 20,
            textAlign: 'left',
            showAuthor: true,
            showDate: true,
            showTags: true,
            showFirstWordsAsAnnotation: false,
        },
        options: {
            fields: [
                {
                    name: 'template',
                    type: 'select',
                    component: 'Select',
                    value: 'style1',
                    props: {
                        items: ['style1', 'style2', 'style3'],
                        labelTrans: 'settings.media.itemsStyleFields.template',
                    },
                },
                {
                    name: 'imageProportions',
                    type: 'select',
                    component: 'SelectScreenTabs',
                    value: '4x3',
                    props: {
                        items: ['1x1', '2x1', '3x1', '5x2', '5x3', '4x3', '5x4', '16x9'],
                        labelTrans: 'settings.media.itemsStyleFields.imageProportions',
                    },
                },
                {
                    name: 'borderRadius',
                    type: 'number',
                    component: 'InputNumberAndRange',
                    value: 20,
                    props: {
                        min: 0,
                        max: 40,
                        labelTrans: 'settings.media.itemsStyleFields.borderRadius',
                    },
                },
                {
                    name: 'textAlign',
                    type: 'select',
                    component: 'Select',
                    value: 'left',
                    props: {
                        items: ['left', 'center', 'right'],
                        labelTrans: 'settings.media.itemsStyleFields.textAlign',
                    },
                },
                {
                    name: 'showAuthor',
                    type: 'boolean',
                    component: 'InputSwitch',
                    value: true,
                    props: {
                        noLabel: true,
                        size: 'small',
                        text: 'settings.media.itemsStyleFields.showAuthor',
                    },
                },
                {
                    name: 'showDate',
                    type: 'boolean',
                    component: 'InputSwitch',
                    value: true,
                    props: {
                        noLabel: true,
                        size: 'small',
                        text: 'settings.media.itemsStyleFields.showDate',
                    },
                },
                {
                    name: 'showTags',
                    type: 'boolean',
                    component: 'InputSwitch',
                    value: true,
                    props: {
                        noLabel: true,
                        size: 'small',
                        text: 'settings.media.itemsStyleFields.showTags',
                    },
                },
                {
                    name: 'showFirstWordsAsAnnotation',
                    type: 'boolean',
                    component: 'InputSwitch',
                    value: false,
                    props: {
                        noLabel: true,
                        size: 'small',
                        text: 'settings.media.itemsStyleFields.showFirstWordsAsAnnotation',
                    },
                },
            ],
            labelTrans: 'settings.media.itemsStyle',
            labelBig: true,
        },
    },
    itemsLayout: {
        id: null,
        position: 30,
        type: 'object',
        component: 'CompositeFieldsScreenTabs',
        value: {
            desktop: {
                listColumn: 3,
                listAlign: 'flex-start',
                gap: 32,
            },
            tablet: {
                listColumn: 2,
                listAlign: 'flex-start',
                gap: 15,
            },
            mobile: {
                listColumn: 1,
                listAlign: 'flex-start',
                gap: 10,
            },
        },
        options: {
            fields: [
                {
                    name: 'listColumn',
                    type: 'number',
                    component: 'InputNumberAndRange',
                    value: 20,
                    props: {
                        min: 2,
                        max: 6,
                        labelTrans: 'settings.media.itemsLayoutFields.listColumn',
                    },
                },
                {
                    name: 'listAlign',
                    type: 'select',
                    component: 'Select',
                    value: 'left',
                    props: {
                        items: ['flex-start', 'center', 'flex-end'],
                        labelTrans: 'settings.media.itemsLayoutFields.listAlign',
                    },
                },
                {
                    name: 'gap',
                    type: 'number',
                    component: 'InputNumberAndRange',
                    value: 20,
                    props: {
                        min: 2,
                        max: 40,
                        labelTrans: 'settings.media.itemsLayoutFields.gap',
                    },
                },
            ],
            labelTrans: 'settings.media.itemsLayout',
            labelBig: true,
        },
    },
    pageStyle: {
        id: null,
        position: 30,
        type: 'object',
        component: 'CompositeField',
        value: {
            showAuthor: true,
            authorBlock: 'style1',
            showComments: true,
            commentsBlock: 'style1',
            showDate: true,
            showTableOfContent: true,
        },
        options: {
            fields: [
                {
                    name: 'showAuthor',
                    type: 'boolean',
                    component: 'InputSwitch',
                    value: true,
                    props: {
                        noLabel: true,
                        size: 'small',
                        text: 'settings.media.pageStyleFields.showAuthor',
                    },
                },
                {
                    name: 'authorBlock',
                    type: 'select',
                    component: 'Select',
                    value: 'style1',
                    props: {
                        items: ['style1', 'style2', 'style3'],
                        labelTrans: 'settings.media.pageStyleFields.authorBlock',
                    },
                },
                {
                    name: 'showComments',
                    type: 'boolean',
                    component: 'InputSwitch',
                    value: true,
                    props: {
                        noLabel: true,
                        size: 'small',
                        text: 'settings.media.pageStyleFields.showComments',
                    },
                },
                {
                    name: 'commentsBlock',
                    type: 'select',
                    component: 'Select',
                    value: 'style1',
                    props: {
                        items: ['style1', 'style2', 'style3'],
                        labelTrans: 'settings.media.pageStyleFields.commentsBlock',
                    },
                },
                {
                    name: 'showDate',
                    type: 'boolean',
                    component: 'InputSwitch',
                    value: true,
                    props: {
                        noLabel: true,
                        size: 'small',
                        text: 'settings.media.pageStyleFields.showDate',
                    },
                },
                {
                    name: 'showTableOfContent',
                    type: 'boolean',
                    component: 'InputSwitch',
                    value: true,
                    props: {
                        noLabel: true,
                        size: 'small',
                        text: 'settings.media.pageStyleFields.showTableOfContent',
                    },
                },
            ],
            labelTrans: 'settings.media.pageStyle',
            labelBig: true,
        },
    },
};
