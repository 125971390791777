<template>
    <div class="page -width-lg">
        <div class="page-header">
            <h1 class="page-header__text">{{ $t('menus.pageHeaders.customers') }}</h1>
            <Button color="primary" @click="openUserModal(null)" icon="add_circle_outline">
                {{ $t('entities.add') }}
            </Button>
        </div>
        <user-modal @action-success="getItems" v-model="isUserModalActive" :user-id="userId"></user-modal>
        <!-- add
            :pagination="pagination"
            :server-items-length="total"
            @change-per-page="changePerPage"
        -->
        <Table
            :search="search"
            :is-data-loaded="isLoaded"
            :show-select="true"
            v-model="selected"
            :columns="headers"
            :items="items"
            :sort-by="sortBy"
            :sort-desc="sortDesc"
            @update:sort="updateSort"
            @update-search-string="updateSearchString"
            @delete-selected-items="openDeletionModal"
        >
            <!-- NAME -->
            <template v-slot:item.name="{ item }">
                {{ item.fullName }}
            </template>
            <!-- ACTIONS -->
            <template v-slot:item.actions="{ item }">
                <Button
                    :to="{ name: 'View Customer', params: { id: item.id } }"
                    class="table__edit-btn"
                    icon="visibility"
                    type="icon"
                />
                <Button type="icon" icon="delete" @click="openDeletionModal(item)"></Button>
            </template>
            <!-- EMAIL -->
            <template v-slot:item.email="{ item }">
                {{ item.email }}
            </template>
            <template v-slot:item.phone="{ item }">
                {{ item.phone }}
            </template>
        </Table>
    </div>
</template>

<script>
import UserService from '@/services/UserService';
import SearchService from '@/services/SearchService';
import UserModal from '@/components/user/UserModal';
import EventEmitter from '@/helpers/eventEmitter.ts';
import { debounce } from 'lodash';
import Table from '@/components/common/Table';
import User from '@/entities/user/User';
import Button from '@/components/common/Button';
export default {
    name: 'CustomerList',
    components: {
        Button,
        UserModal,
        Table,
    },
    created() {
        /*    this.total = await Pagination.getTotal('users');
              this.pagination = new Pagination({
              total: this.total,
              moduleName: 'users',
              limit: this.perPage.value,
              pageNumber: this.pageNumber,
  });*/
        EventEmitter.on('delete-user', this.deleteItems);
        if (this.$route.query.q) this.search.q = this.$route.query.q;
        this.getItems();
    },
    data: () => ({
        isLoaded: false,
        search: { searchable: 'user', q: '' },
        isUserModalActive: false,
        items: [],
        selected: [],
        userId: null,
        sortBy: null,
        sortDesc: null,
        isAvailable: true,
        pagination: null,
    }),
    computed: {
        searchString() {
            return this.$route.query.q;
        },
        /*      pageNumber() {
        return this.$route.query.page || 1;
    },*/
        headers() {
            return [
                { text: this.$t('lists.columns.email'), value: 'email' },
                { text: this.$t('lists.columns.name'), value: 'name' },
                { text: this.$t('lists.columns.phone'), value: 'phone' },
                { value: 'actions', width: '120px', sortable: false },
            ];
        },
    },
    methods: {
        async getItems() {
            this.isLoaded = false;
            const params = {
                /*          limit: this.search.q?.length ? null : this.pagination.limit,
           offset: this.search.q?.length ? null : this.pagination.offset,*/
                sort: this.sortBy,
                direction: this.sortDesc ? 'desc' : this.sortDesc === false ? 'asc' : null,
                roles: ['ROLE_USER'],
            };
            if (this.search.q?.length) {
                params.q = this.search.q;
            }
            const getter = this.search.q?.length ? this.searchUsers : this.getUsers;
            const [error, items] = await getter(params);
            if (error) {
                error.alert();
                this.isAvailable = false;
                return;
            }
            this.items = items.map((item) => new User(item));
            this.isLoaded = true;
        },
        async getUsers(params) {
            return await UserService.getAll(params);
        },
        async searchUsers(params) {
            return await SearchService.entitySearch('user', params);
        },
        changeUser: debounce(async function(user) {
            await UserService.updateOne(new User(user).data);
        }, 300),

        updateSearchString({ searchString }) {
            this.search.q = searchString;
            /*         if (searchString?.length) this.pagination.reset();*/
            this.getItems();
        },

        openUserModal(userId = null) {
            this.isUserModalActive = true;
            this.userId = userId;
        },

        /*        changePerPage(perPage) {
     this.pagination.changeLimit( perPage.value);
     this.perPage = perPage;
     this.getItems();
 },*/

        updateSort(sortBy, sortDesc) {
            this.sortBy = sortBy;
            this.sortDirection = sortDesc;
            this.getItems();
        },

        openDeletionModal(items) {
            this.$store.dispatch('modals/openDeletionModal', { items, entity: 'user' });
        },

        async deleteItems(items) {
            this.isLoaded = false;
            const promises = items.map((item) => UserService.removeOne(item.id));
            await Promise.all(promises);
            await this.getItems();
            this.$store.dispatch('modals/closeDeletionModal');
            /*        const normalized = await this.pagination.normalize(this.items);
      if (!normalized) {
          await this.getItems();
      }*/
            this.selected = [];
            this.isLoaded = true;
        },
    },
    beforeDestroy() {
        EventEmitter.off('delete-user', this.deleteItems);
    },
    watch: {
        /*        pageNumber(newValue) {
            this.pagination.pageNumber = newValue;
            this.getItems();
        },*/
    },
};
</script>
<style></style>
