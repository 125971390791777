<template>
    <div class="builder-undo-redo">
        <Button :disabled="!hasUndo" type="icon" icon="undo" @click="onUndo" />
        <Button :disabled="!hasRedo" type="icon" icon="redo" @click="onRedo" />
    </div>
</template>

<script>
import { HistoryTypes } from '@/store/Types';
import Button from '@/components/common/Button.vue';

export default {
    name: 'UndoRedo',
    components: { Button },
    data() {
        return {
            pressed: new Set(),
            codesUndo: ['ControlLeft', 'KeyZ'],
            codesRedo: ['ControlLeft', 'KeyY'],
        };
    },
    created() {
        document.addEventListener('keydown', this.onKeydown);
        document.addEventListener('keyup', this.onKeyup);
    },
    mounted() {
        window.onbeforeunload = () => {
            if (this.hasUndo || this.hasRedo) return false;
        };
    },
    destroyed() {
        document.removeEventListener('keydown', this.onKeydown);
        document.removeEventListener('keyup', this.onKeyup);
    },
    computed: {
        hasUndo() {
            return this.$store.state.history.currentHistiryPosition > 0;
        },
        hasRedo() {
            return this.$store.state.history.currentHistiryPosition < this.$store.state.history.historyTree.length - 1;
        },
    },
    methods: {
        onUndo() {
            this.$store.commit(HistoryTypes.mutations.UPDATE_HISTORY_POSITION, { type: 'undo' });
            this.$store.dispatch(HistoryTypes.actions.UPDATE_CURRENT);
        },
        onRedo() {
            this.$store.commit(HistoryTypes.mutations.UPDATE_HISTORY_POSITION, { type: 'redo' });
            this.$store.dispatch(HistoryTypes.actions.UPDATE_CURRENT);
        },
        onKeydown: function(event) {
            this.pressed.add(event.code);
            if (this.pressed.has('ControlLeft') && this.pressed.has('KeyZ')) {
                this.pressed.delete('KeyZ');
                if (this.hasUndo) this.onUndo();
            }
            if (this.pressed.has('ControlLeft') && this.pressed.has('KeyY')) {
                this.pressed.delete('KeyY');
                if (this.hasRedo) this.onRedo();
            }
        },
        onKeyup: function(event) {
            this.pressed.delete(event.code);
        },
    },
};
</script>

<style lang="scss">
.builder-undo-redo {
    display: flex;
}
</style>
