import { uuid } from 'vue-uuid';

/**
 * Класс экземпляра значения характеристики
 */
export default class PropertyValue {
    /**
     *
     * @param {Object}  data - объект данных значения характеристики
     */
    #isProductEntityProperty;
    constructor(
        { id = uuid.v4(), title = '', position = null, propertyValueId = null } = {},
        isProductEntityProperty = false
    ) {
        this.isProductEntityProperty = isProductEntityProperty;
        this.id = id;
        this.title = title;
        if (isProductEntityProperty) {
            this.propertyValueId = propertyValueId;
        } else {
            this.position = position;
        }
    }

    get data() {
        if (this.isProductEntityProperty) {
            return {
                id: this.id,
                //   title: this.title,
                propertyValueId: this.propertyValueId,
            };
        }
        return {
            id: this.id,
            title: this.title,
            position: this.position,
        };
    }
}
