import { SectionFixtureType, SectionGroupFixtureType } from '@/entities/section/fixtures';

// id: 'fixture',
const style1Prev1: SectionFixtureType = {
    preview: require('@/assets/images/sections/products/product-style1prev1.jpg'),
    title: 'ProductWidget',
    group: 'ProductWidget',
    data: {
        id: 'fixture',
        items: [],
        required: false,
        fixed: false,
        type: 'SectionProductWidget',
        options: {
            section: {
                width: 'full',
                padding: {
                    desktop: { top: 28, right: 0, bottom: 28, left: 0 },
                    tablet: { top: 28, right: 0, bottom: 28, left: 0 },
                    mobile: { top: 16, right: 0, bottom: 16, left: 0 },
                },
                borderRadius: {
                    desktop: 0,
                    tablet: 0,
                    mobile: 0,
                },
                background: 'none',
            },
            container: {
                width: 'content',
                padding: {
                    desktop: null,
                    tablet: null,
                    mobile: null,
                },
                borderRadius: {
                    desktop: 0,
                    tablet: 0,
                    mobile: 0,
                },
                background: 'none',
            },
            heading: {
                title: 'Alpha Hookah',
                colorTitle: '#1c1c1c',
                note: null,
                colorNote: '#292929',
                textAlign: 'center',
                marginBottom: {
                    desktop: null,
                    tablet: null,
                    mobile: null,
                },
            },
            content: {
                htmlBefore: null,
                htmlAfter: null,
                colorText: null,
            },
            limit: '4',
            catalogSlug: {
                id: '148501d8-8fdd-49ee-89ba-7bb138eb49e5',
                slug: 'anima-hookah',
            },
            list: {
                useDefault: true,
                columns: {
                    desktop: 3,
                    tablet: 2,
                    mobile: 1,
                },
            },
            btnSeeCatalog: {
                isShow: true,
                text: 'See All Products',
            },
            footer: {
                note: null,
                colorNote: null,
                textAlign: 'center',
                marginTop: {
                    desktop: null,
                    tablet: null,
                    mobile: null,
                },
                button: {
                    isShow: false,
                    styles: 'primary',
                    text: 'See all products',
                    textColor: null,
                    bgColor: null,
                    link: null,
                    iconLeft: null,
                    iconRight: null,
                },
            },
        },
    },
};

const style1Prev2: SectionFixtureType = {
    preview: require('@/assets/images/sections/products/product-style1prev2.jpg'),
    title: 'ProductWidget',
    group: 'ProductWidget',
    data: {
        id: 'fixture',
        items: [],
        required: false,
        fixed: false,
        type: 'SectionProductWidget',
        options: {
            section: {
                width: 'full',
                padding: {
                    desktop: { top: 28, right: 0, bottom: 28, left: 0 },
                    tablet: { top: 28, right: 0, bottom: 28, left: 0 },
                    mobile: { top: 16, right: 0, bottom: 16, left: 0 },
                },
                borderRadius: {
                    desktop: 0,
                    tablet: 0,
                    mobile: 0,
                },
                background: 'middle',
            },
            container: {
                width: 'content',
                padding: {
                    desktop: null,
                    tablet: null,
                    mobile: null,
                },
                borderRadius: {
                    desktop: 0,
                    tablet: 0,
                    mobile: 0,
                },
                background: 'none',
            },
            heading: {
                title: 'Alpha Hookah',
                colorTitle: null,
                note: null,
                colorNote: null,
                textAlign: 'center',
                marginBottom: {
                    desktop: null,
                    tablet: null,
                    mobile: null,
                },
            },
            content: {
                htmlBefore:
                    '<p style="text-align:center;">This catalog contains Alpha Hookah products. You can choose and purchase them, as well as additional accessories you can find in the corresponding section</p>',
                htmlAfter: null,
                colorText: null,
            },
            limit: '4',
            seeAll: false,
            catalogSlug: {
                id: '604af901-ad70-4e9b-b9e9-94dd6345ae54',
                slug: 'conceptic-design-hookahs',
            },
            list: {
                useDefault: true,
                columns: {
                    desktop: 3,
                    tablet: 2,
                    mobile: 1,
                },
            },
            footer: {
                note: null,
                colorNote: null,
                textAlign: 'center',
                marginTop: {
                    desktop: null,
                    tablet: null,
                    mobile: null,
                },
                button: {
                    isShow: false,
                    styles: 'primary',
                    text: 'Button',
                    textColor: null,
                    bgColor: null,
                    link: null,
                    iconLeft: null,
                    iconRight: null,
                    type: 'Link',
                    url: null,
                },
            },
        },
    },
};

const style1Prev3: SectionFixtureType = {
    preview: require('@/assets/images/sections/products/product-style1prev3.jpg'),
    title: 'ProductWidget',
    group: 'ProductWidget',
    data: {
        id: 'fixture',
        items: [],
        required: false,
        fixed: false,
        type: 'SectionProductWidget',
        options: {
            section: {
                width: 'full',
                padding: {
                    desktop: { top: 28, right: 0, bottom: 28, left: 0 },
                    tablet: { top: 28, right: 0, bottom: 28, left: 0 },
                    mobile: { top: 16, right: 0, bottom: 16, left: 0 },
                },
                borderRadius: {
                    desktop: 0,
                    tablet: 0,
                    mobile: 0,
                },
                background: 'dark',
            },
            container: {
                width: 'content',
                padding: {
                    desktop: null,
                    tablet: null,
                    mobile: null,
                },
                borderRadius: {
                    desktop: 0,
                    tablet: 0,
                    mobile: 0,
                },
                background: 'none',
            },
            heading: {
                title: 'Accessories',
                colorTitle: null,
                note: null,
                colorNote: null,
                textAlign: 'center',
                marginBottom: {
                    desktop: null,
                    tablet: null,
                    mobile: null,
                },
            },
            content: {
                htmlBefore: null,
                htmlAfter: null,
                colorText: null,
            },
            limit: '8',
            seeAll: true,
            catalogSlug: {
                id: '28f48ebf-8c62-492f-8f2c-e21e00a52321',
                slug: 'accessories',
            },
            list: {
                useDefault: true,
                columns: {
                    desktop: 3,
                    tablet: 2,
                    mobile: 1,
                },
            },
            footer: {
                note: null,
                colorNote: null,
                textAlign: 'center',
                marginTop: {
                    desktop: null,
                    tablet: null,
                    mobile: null,
                },
                button: {
                    isShow: false,
                    styles: 'outline',
                    text: 'See all accessories',
                    textColor: null,
                    bgColor: null,
                    link: null,
                    iconLeft: null,
                    iconRight: null,
                },
            },
        },
    },
};

const style2Prev1: SectionFixtureType = {
    preview: require('@/assets/images/sections/products/product-style1prev1.jpg'),
    title: 'ProductWidget By Items',
    group: 'ProductWidget',
    data: {
        id: 'fixture',
        items: [
            {
                id: 'fixture',
                type: 'productItem',
                options: {
                    product: '6f6a0cce-6f11-4179-bcb3-10859a121ae7',
                },
            },
            {
                id: 'fixture',
                type: 'productItem',
                options: {
                    product: '53ec291f-c866-42de-90b8-6d663733b575',
                },
            },
            {
                id: 'fixture',
                type: 'productItem',
                options: {
                    product: '6f4f7952-e473-4b4b-ab15-9f6763606662',
                },
            },
            {
                id: 'fixture',
                type: 'productItem',
                options: {
                    product: 'e53800f2-7e1a-4a6b-9f3a-23c3a268c7a2',
                },
            },
        ],
        required: false,
        fixed: false,
        type: 'SectionProductWidgetByItems',
        options: {
            section: {
                width: 'full',
                padding: {
                    desktop: { top: 28, right: 0, bottom: 28, left: 0 },
                    tablet: { top: 28, right: 0, bottom: 28, left: 0 },
                    mobile: { top: 16, right: 0, bottom: 16, left: 0 },
                },
                borderRadius: {
                    desktop: 0,
                    tablet: 0,
                    mobile: 0,
                },
                background: 'none',
            },
            container: {
                width: 'content',
                padding: {
                    desktop: null,
                    tablet: null,
                    mobile: null,
                },
                borderRadius: {
                    desktop: 0,
                    tablet: 0,
                    mobile: 0,
                },
                background: 'none',
            },
            heading: {
                title: 'Alpha Hookah',
                colorTitle: '#1c1c1c',
                note: null,
                colorNote: '#292929',
                textAlign: 'center',
                marginBottom: {
                    desktop: null,
                    tablet: null,
                    mobile: null,
                },
            },
            content: {
                htmlBefore: null,
                htmlAfter: null,
                colorText: null,
            },
            list: {
                useDefault: true,
                columns: {
                    desktop: 3,
                    tablet: 2,
                    mobile: 1,
                },
            },
            footer: {
                note: null,
                colorNote: null,
                textAlign: 'center',
                marginTop: {
                    desktop: null,
                    tablet: null,
                    mobile: null,
                },
                button: {
                    isShow: true,
                    type: 'popup',
                    styles: 'primary',
                    text: 'Send Enquary',
                    textColor: null,
                    bgColor: null,
                    link: null,
                    iconLeft: null,
                    iconRight: null,
                },
            },
        },
    },
};

const summary: SectionGroupFixtureType = {
    style1Prev1,
    style1Prev2,
    style1Prev3,
    style2Prev1,
};

export default summary;
