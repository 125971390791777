<template>
    <!-- <div class="stretch-content" @mouseup.stop.prevent="onMouseUp" @mousemove="onMouseMove"> -->
    <div class="stretch-content" @mouseup="onMouseUp" @mousemove="onMouseMove">
        <template v-for="(item, index) in items">
            <StretchContentItem
                v-if="item.slotName !== 'main'"
                :key="index"
                :item="item"
                :screenWidth="screenWidth"
                @onResizing="handlerResizeItem"
                :style="getStyle(item)"
            >
                <slot :name="item.slotName"></slot>
            </StretchContentItem>
            <slot v-else :name="item.slotName"></slot>
        </template>
    </div>
</template>

<script>
/**
* Компонент изменения ширины выбранного блока
* Пример использования:
    <template>
        <StretchContent :stretchOptions="stretchOptions">
            <template v-slot:sidebarLeft> <MyComponentChild /> </template>
            // v-slot:main - обязательный слот
            <template v-slot:main> //... some content </template>
        </StretchContent>
    </template>
    stretchOptions: [
        {
            slotName: 'sidebarLeft', // обязательное свойство - название слота
            side: 'right', // необязательное свойство - расположение кнопки 'right' || 'left', если не указано = 'right';
            minWidth: 400, // необязательное свойство - минимальная ширина, если не указана = ширине блока в момент создания;
            maxWidth: 600, // необязательное свойство - максимальная ширина, если не указана = ширине экрана;
        },
        { slotName: 'main' },
    ],
* ВНИМАНИЕ!!! Компоненты будут рендериться в порядке, указанном в stretchOptions
*/
import StretchContentItem from '@/components/builder/stretch-content/stretch-content-item.vue';
import { mapActions, mapState } from 'vuex';
export default {
    name: 'StretchContent',
    components: { StretchContentItem },
    props: {
        stretchOptions: {
            type: Array,
            default() {
                return [];
            },
        },
    },
    data() {
        return {
            activeItem: {},
            items: [],
            screenWidth: null,
        };
    },
    created() {
        this.items = this.setItems();
        this.screenWidth = document.documentElement.clientWidth;
    },
    mounted() {
        const itemsNode = this.getNodes();
        const itemsDefaultWidth = this.getDefaultWidth();
        this.items.forEach((item, indx) => {
            item.node = itemsNode[indx];
            item.minWidth = !item.minWidth ? itemsDefaultWidth[indx] : item.minWidth;
        });
    },
    computed: {
        ...mapState('builder', {
            currentSidebar: 'currentSidebar',
        }),
    },
    methods: {
        ...mapActions('builder', {
            updateCurrentSidebar: 'updateCurrentSidebar',
        }),
        getStyle(item) {
            if (item.minWidth && !item.resizeWidth) return '--width:' + item.minWidth + 'px';
            if (item.resizeWidth) return '--width:' + item.resizeWidth + 'px';
            return '';
        },
        getNodes() {
            return [...this.$el.children].map((child) => child);
        },
        getDefaultWidth() {
            return [...this.$el.children].map((child) => child.offsetWidth);
        },
        setItems() {
            return this.stretchOptions.map((slot) => ({
                slotName: slot.slotName,
                side: slot.side || 'right',
                isResizing: false,
                resizeWidth: null,
                minWidth: slot.minWidth || null,
                maxWidth: slot.maxWidth || null,
            }));
        },
        getItemBySlotName(slotName) {
            return this.items.find((item) => item.slotName === slotName);
        },
        handlerResizeItem(value) {
            this.items.forEach((item, indx) => {
                if (item.slotName !== value.slotName) return;
                this.items[indx] = { ...this.items[indx], ...value };
                this.activeItem = this.items[indx];
            });
            this.items = [...this.items];
        },
        onMouseUp() {
            if (!this.activeItem.isResizing) return;

            this.handlerResizeItem({
                slotName: this.activeItem.slotName,
                isResizing: false,
                resizeWidth: this.activeItem.resizeWidth,
            });
            return this.$emit('onResizingContentEnd', this.activeItem.resizeWidth);
        },
        onMouseMove(e) {
            if (!this.activeItem.isResizing) return;
            e.preventDefault();
            if (!this.checkCoord()) return;
            this.activeItem.resizeWidth = this.getResizeWidth(e.clientX);
            this.updateCurrentSidebar({
                [this.activeItem.slotName]: this.activeItem.resizeWidth,
            });
            return this.$emit('onResizingContent', this.activeItem.resizeWidth);
        },
        getResizeWidth(coordX) {
            let width = coordX;
            if (this.activeItem.side === 'left') width = this.screenWidth - coordX;
            if (width <= this.activeItem.minWidth) width = this.activeItem.minWidth;
            if (this.activeItem.maxWidth && width >= this.activeItem.maxWidth) width = this.activeItem.maxWidth;
            return width;
        },
        checkCoord() {
            const rightCoordX = this.activeItem.node.offsetLeft + this.activeItem.node.offsetWidth;
            if (rightCoordX > this.screenWidth) return;
            return true;
        },
    },
};
</script>

<style lang="scss"></style>
