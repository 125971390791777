export default {
    continents: {
        africa: 'Africa',
        europe: 'Europe',
        asia: 'Asia',
        centralAmerica: 'Central America',
        southAmerica: 'South America',
        northAmerica: 'North America',
        oceania: 'Oceania',
        other: 'Other',
    },
    RU: {
        title: 'Russia',
        zones: {
            ALT: 'Altai Krai',
            AL: 'Altai',
            AMU: 'Amur',
            ARK: 'Arkhangelsk',
            AST: 'Astrakhan',
            BEL: 'Belgorod',
            BRY: 'Bryansk',
            CE: 'Chechen',
            CHE: 'Chelyabinsk',
            CHU: 'Chukotka Okrug',
            CU: 'Chuvash',
            IRK: 'Irkutsk',
            IVA: 'Ivanovo',
            YEV: 'Jewish',
            KB: 'Kabardino-Balkar',
            KGD: 'Kaliningrad',
            KLU: 'Kaluga',
            KAM: 'Kamchatka Krai',
            KC: 'Karachay-Cherkess',
            KEM: 'Kemerovo',
            KHA: 'Khabarovsk Krai',
            KHM: 'Khanty-Mansi',
            KIR: 'Kirov',
            KO: 'Komi',
            KOS: 'Kostroma',
            KDA: 'Krasnodar Krai',
            KYA: 'Krasnoyarsk Krai',
            KGN: 'Kurgan',
            KRS: 'Kursk',
            LEN: 'Leningrad',
            LIP: 'Lipetsk',
            MAG: 'Magadan',
            ME: 'Mari El',
            MOW: 'Moscow',
            MOS: 'Moscow Province',
            MUR: 'Murmansk',
            NIZ: 'Nizhny Novgorod',
            NGR: 'Novgorod',
            NVS: 'Novosibirsk',
            OMS: 'Omsk',
            ORE: 'Orenburg',
            ORL: 'Oryol',
            PNZ: 'Penza',
            PER: 'Perm Krai',
            PRI: 'Primorsky Krai',
            PSK: 'Pskov',
            AD: 'Adygea',
            BA: 'Bashkortostan',
            BU: 'Buryat',
            DA: 'Dagestan',
            IN: 'Ingushetia',
            KL: 'Kalmykia',
            KR: 'Karelia',
            KK: 'Khakassia',
            MO: 'Mordovia',
            SE: 'North Ossetia-Alania',
            TA: 'Tatarstan',
            ROS: 'Rostov',
            RYA: 'Ryazan',
            SPE: 'Saint Petersburg',
            SA: 'Sakha',
            SAK: 'Sakhalin',
            SAM: 'Samara',
            SAR: 'Saratov',
            SMO: 'Smolensk',
            STA: 'Stavropol Krai',
            SVE: 'Sverdlovsk',
            TAM: 'Tambov',
            TOM: 'Tomsk',
            TUL: 'Tula',
            TVE: 'Tver',
            TYU: 'Tyumen',
            TY: 'Tuva',
            UD: 'Udmurt',
            ULY: 'Ulyanovsk',
            VLA: 'Vladimir',
            VGG: 'Volgograd',
            VLG: 'Vologda',
            VOR: 'Voronezh',
            YAN: 'Yamalo-Nenets Okrug',
            YAR: 'Yaroslavl',
            ZAB: 'Zabaykalsky Krai',
        },
    },
    AF: 'Afghanistan',
    AX: 'Åland Islands',
    AL: 'Albania',
    DZ: 'Algeria',
    AD: 'Andorra',
    AO: 'Angola',
    AI: 'Anguilla',
    AG: 'Antigua & Barbuda',
    AR: 'Argentina',
    AM: 'Armenia',
    AW: 'Aruba',
    AC: 'Ascension Island',
    AU: 'Australia',
    AT: 'Austria',
    AZ: 'Azerbaijan',
    BS: 'Bahamas',
    BH: 'Bahrain',
    BD: 'Bangladesh',
    BB: 'Barbados',
    BY: 'Belarus',
    BE: 'Belgium',
    BZ: 'Belize',
    BJ: 'Benin',
    BM: 'Bermuda',
    BT: 'Bhutan',
    BO: 'Bolivia',
    BA: 'Bosnia & Herzegovina',
    BW: 'Botswana',
    BR: 'Brazil',
    IO: 'British Indian Ocean Territory',
    VG: 'British Virgin Islands',
    BN: 'Brunei',
    BG: 'Bulgaria',
    BF: 'Burkina Faso',
    BI: 'Burundi',
    KH: 'Cambodia',
    CM: 'Cameroon',
    CA: 'Canada',
    CV: 'Cape Verde',
    BQ: 'Caribbean Netherlands',
    KY: 'Cayman Islands',
    CF: 'Central African Republic',
    TD: 'Chad',
    CL: 'Chile',
    CN: 'China',
    CX: 'Christmas Island',
    CC: 'Cocos (Keeling) Islands',
    CO: 'Colombia',
    KM: 'Comoros',
    CG: 'Congo - Brazzaville',
    CD: 'Congo - Kinshasa',
    CK: 'Cook Islands',
    CR: 'Costa Rica',
    HR: 'Croatia',
    CW: 'Curaçao',
    CY: 'Cyprus',
    CZ: 'Czechia',
    CI: 'Côte d’Ivoire',
    DK: 'Denmark',
    DJ: 'Djibouti',
    DM: 'Dominica',
    DO: 'Dominican Republic',
    EC: 'Ecuador',
    EG: 'Egypt',
    SV: 'El Salvador',
    GQ: 'Equatorial Guinea',
    ER: 'Eritrea',
    EE: 'Estonia',
    SZ: 'Eswatini',
    ET: 'Ethiopia',
    FK: 'Falkland Islands',
    FO: 'Faroe Islands',
    FJ: 'Fiji',
    FI: 'Finland',
    FR: 'France',
    GF: 'French Guiana',
    PF: 'French Polynesia',
    TF: 'French Southern Territories',
    GA: 'Gabon',
    GM: 'Gambia',
    GE: 'Georgia',
    DE: 'Germany',
    GH: 'Ghana',
    GI: 'Gibraltar',
    GR: 'Greece',
    GL: 'Greenland',
    GD: 'Grenada',
    GP: 'Guadeloupe',
    GT: 'Guatemala',
    GG: 'Guernsey',
    GN: 'Guinea',
    GW: 'Guinea-Bissau',
    GY: 'Guyana',
    HT: 'Haiti',
    HN: 'Honduras',
    HK: 'Hong Kong SAR',
    HU: 'Hungary',
    IS: 'Iceland',
    IN: 'India',
    ID: 'Indonesia',
    IQ: 'Iraq',
    IE: 'Ireland',
    IM: 'Isle of Man',
    IL: 'Israel',
    IT: 'Italy',
    JM: 'Jamaica',
    JP: 'Japan',
    JE: 'Jersey',
    JO: 'Jordan',
    KZ: 'Kazakhstan',
    KE: 'Kenya',
    KI: 'Kiribati',
    XK: 'Kosovo',
    KW: 'Kuwait',
    KG: 'Kyrgyzstan',
    LA: 'Laos',
    LV: 'Latvia',
    LB: 'Lebanon',
    LS: 'Lesotho',
    LR: 'Liberia',
    LY: 'Libya',
    LI: 'Liechtenstein',
    LT: 'Lithuania',
    LU: 'Luxembourg',
    MO: 'Macao SAR',
    MG: 'Madagascar',
    MW: 'Malawi',
    MY: 'Malaysia',
    MV: 'Maldives',
    ML: 'Mali',
    MT: 'Malta',
    MQ: 'Martinique',
    MR: 'Mauritania',
    MU: 'Mauritius',
    YT: 'Mayotte',
    MX: 'Mexico',
    MD: 'Moldova',
    MC: 'Monaco',
    MN: 'Mongolia',
    ME: 'Montenegro',
    MS: 'Montserrat',
    MA: 'Morocco',
    MZ: 'Mozambique',
    MM: 'Myanmar (Burma)',
    NA: 'Namibia',
    NR: 'Nauru',
    NP: 'Nepal',
    NL: 'Netherlands',
    NC: 'New Caledonia',
    NZ: 'New Zealand',
    NI: 'Nicaragua',
    NE: 'Niger',
    NG: 'Nigeria',
    NU: 'Niue',
    NF: 'Norfolk Island',
    MK: 'North Macedonia',
    NO: 'Norway',
    OM: 'Oman',
    PK: 'Pakistan',
    PS: 'Palestinian Territories',
    PA: 'Panama',
    PG: 'Papua New Guinea',
    PY: 'Paraguay',
    PE: 'Peru',
    PH: 'Philippines',
    PN: 'Pitcairn Islands',
    PL: 'Poland',
    PT: 'Portugal',
    QA: 'Qatar',
    RE: 'Réunion',
    RO: 'Romania',
    RW: 'Rwanda',
    WS: 'Samoa',
    SM: 'San Marino',
    ST: 'São Tomé & Príncipe',
    SA: 'Saudi Arabia',
    SN: 'Senegal',
    RS: 'Serbia',
    SC: 'Seychelles',
    SL: 'Sierra Leone',
    SG: 'Singapore',
    SX: 'Sint Maarten',
    SK: 'Slovakia',
    SI: 'Slovenia',
    SB: 'Solomon Islands',
    SO: 'Somalia',
    ZA: 'South Africa',
    GS: 'South Georgia & South Sandwich Islands',
    KR: 'South Korea',
    SS: 'South Sudan',
    ES: 'Spain',
    LK: 'Sri Lanka',
    BL: 'St. Barthélemy',
    SH: 'St. Helena',
    KN: 'St. Kitts & Nevis',
    LC: 'St. Lucia',
    MF: 'St. Martin',
    PM: 'St. Pierre & Miquelon',
    VC: 'St. Vincent & Grenadines',
    SD: 'Sudan',
    SR: 'Suriname',
    SJ: 'Svalbard & Jan Mayen',
    SE: 'Sweden',
    CH: 'Switzerland',
    TW: 'Taiwan',
    TJ: 'Tajikistan',
    TZ: 'Tanzania',
    TH: 'Thailand',
    TL: 'Timor-Leste',
    TG: 'Togo',
    TK: 'Tokelau',
    TO: 'Tonga',
    TT: 'Trinidad & Tobago',
    TA: 'Tristan da Cunha',
    TN: 'Tunisia',
    TR: 'Turkey',
    TM: 'Turkmenistan',
    TC: 'Turks & Caicos Islands',
    TV: 'Tuvalu',
    UM: 'U.S. Outlying Islands',
    UG: 'Uganda',
    UA: 'Ukraine',
    AE: 'United Arab Emirates',
    GB: 'United Kingdom',
    US: 'United States',
    UY: 'Uruguay',
    UZ: 'Uzbekistan',
    VU: 'Vanuatu',
    VA: 'Vatican City',
    VE: 'Venezuela',
    VN: 'Vietnam',
    WF: 'Wallis & Futuna',
    EH: 'Western Sahara',
    YE: 'Yemen',
    ZM: 'Zambia',
    ZW: 'Zimbabwe',
};
