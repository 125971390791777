var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page -width-lg"},[_c('div',{staticClass:"page-header"},[_c('h1',{staticClass:"page-header__text"},[_vm._v(_vm._s(_vm.$t('menus.pageHeaders.authors')))]),_c('Button',{attrs:{"color":"primary","to":{ name: 'Create Author' },"icon":"add_circle_outline"}},[_vm._v(" "+_vm._s(_vm.$t('entities.add'))+" ")])],1),_c('Table',{staticClass:"authors-table",attrs:{"search":_vm.search,"is-data-loaded":_vm.isLoaded,"show-select":true,"columns":_vm.headers,"items":_vm.items,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"pagination":_vm.pagination},on:{"update:sort":_vm.updateSort,"change-per-page":_vm.changePerPage,"update-search-string":_vm.updateSearchString,"delete-selected-items":_vm.openDeletionModal},scopedSlots:_vm._u([{key:"header-select-prepend",fn:function(){return [_c('Select',{staticClass:"author-list__type-select",attrs:{"item-value":"value","item-text":"text","items":_vm.typeItems},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})]},proxy:true},{key:"item.photoId",fn:function(ref){
var item = ref.item;
return [(_vm.hasImage(item))?_c('img',{staticClass:"table__image",attrs:{"alt":_vm.images[item.id].alt,"src":_vm.imageUrl + _vm.images[item.id].uri}}):_c('img',{staticClass:"table__image -default",attrs:{"alt":"No image","src":require("@/assets/images/no-photo.svg")}})]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"table-edit"},[_c('router-link',{staticClass:"table-edit__link",attrs:{"to":{ name: 'Edit Author', params: { id: item.id } }}},[_vm._v(_vm._s(item.name))]),_c('span',{staticClass:"table-edit__icon material-icons-outlined"},[_vm._v("edit")])],1)]}},{key:"item.bio",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"author-list__bio"},[_vm._v(" "+_vm._s(item.bio)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('Button',{staticClass:"table__edit-btn",attrs:{"to":{ name: 'Edit Author', params: { id: item.id } },"icon":"edit","type":"icon"}}),_c('Button',{attrs:{"type":"icon","icon":"delete"},on:{"click":function($event){return _vm.openDeletionModal(item)}}})]}},{key:"item.articlesCreatedIds",fn:function(ref){
var item = ref.item;
return [_c('Button',{attrs:{"type":"text","to":{ name: 'Article List', query: { authorId: item.id } }}},[_vm._v(" "+_vm._s(_vm.$t('lists.actions.viewAll'))+"("+_vm._s(item.articlesCreatedIds.length)+") ")])]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }