<template>
    <div class="page-switcher-search">
        <input
            v-model="model"
            class="page-switcher-search__input"
            type="text"
            :placeholder="$t('builder.topbar.pageSwitcher.search')"
        />
        <button class="page-switcher-search__button" type="submit">
            <span class="material-icons">search</span>
        </button>
    </div>
</template>

<script>
import { proxyModelMixin } from '@/mixins/proxyModelMixin';

export default {
    name: 'PageSwitcherSearch',
    inheritAttrs: false,
    mixins: [proxyModelMixin],
    props: {
        value: {
            type: String,
            default: '',
        },
    },
    computed: {},
    methods: {},
};
</script>

<style lang="scss">
@import '@/scss/mixins';
@import '@/scss/variables.scss';
.page-switcher-search {
    position: relative;
    padding: 8px 12px;

    &__input {
        width: 100%;
        height: $input-height;
        padding: 0 12px;
        border: 1px solid #cececf;
        border-radius: $input-border-radius;
        transition: $input-transition;
        line-height: $input-height - 2px;
        cursor: pointer;
        font-size: 16px;

        &:hover {
            border-color: $input-border-color-hover;
        }
    }
    &__button {
        position: absolute;
        top: 18px;
        right: 24px;
        z-index: 4;
        width: 24px;
        height: 24px;
        font-size: 0;
        border: 0;
        background: none;
        cursor: pointer;
        color: rgba(0, 0, 0, 0.54);
    }
}
</style>
