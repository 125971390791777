<template>
    <div class="input-paste" :class="{ 'is-pasted': isPaste }" @click.prevent>
        <div class="input-paste__field">
            <div class="input-paste__field-text">
                <span>{{ text }}</span>
                <Button
                    v-if="hasCurrent"
                    class="input-icon__btn-clear"
                    type="icon"
                    icon="cancel"
                    @click="clearCurrent"
                />
            </div>
            <button class="btn btn-primary input-paste__field-btn" @click="paste" :class="{ 'btn-disabled': disabled }">
                Paste
            </button>
        </div>
    </div>
</template>

<script>
import { proxyModelMixin } from '@/mixins/proxyModelMixin';
import Button from '@/components/common/Button.vue';

export default {
    name: 'InputPaste',
    inheritAttrs: false,
    props: {
        name: { type: String, default: '' },
        group: { type: String, default: null },
        value: { type: [String, Number, Object, Date] },
        items: { type: [Array], default: () => [] },
        placeholder: { type: String, default: 'Nothing' },
        pastedValue: { type: String, default: '' },
        disabled: { type: Boolean, default: false },
    },
    mixins: [proxyModelMixin],
    components: { Button },
    data() {
        return {
            isPaste: false,
        };
    },
    computed: {
        text() {
            return this.value ? this.value : this.placeholder;
        },
        hasCurrent() {
            return !!this.model;
        },
    },
    methods: {
        paste() {
            this.isPaste = true;
            this.model = this.pastedValue;
        },
        clearCurrent(e) {
            e.preventDefault();
            this.model = null;
            this.isPaste = false;
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.input-paste {
    &.is-pasted {
        .input-paste__field-btn {
            display: none;
        }
        .input-paste__field-text {
            border-radius: 5px;
        }
    }
    &__field {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &-text {
            position: relative;
            display: flex;
            align-items: center;
            gap: 6px;
            flex: 1;
            border: 1px solid #cececf;
            border-radius: 5px 0 0 5px;
            padding: 0 12px;
            height: 40px;
            line-height: 38px;
            max-width: 100%;
            background-color: white;
            span {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                max-width: 85%;
            }
        }
        &-btn {
            height: 40px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            &.btn-disabled {
                pointer-events: none;
            }
        }
    }
    &__btn-clear {
        position: absolute;
        right: 3px;
        top: 2px;
    }
}
</style>
