import { uuid } from 'vue-uuid';
import Section from '../section/Section';
import initFields from '@/entities/page/Page.fields';
import SlugService from '@/services/SlugService';
import AbstractEntity from '@/entities/abstractEntity';
import SampleSections from '@/entities/section/samples/';
import EventEmitter from '@/helpers/eventEmitter.ts';

/**
 * базовый класс страницы, от которого наследуются другие классы текстовой страницы, каталога, товара и т.д.
 */
export default class Page extends AbstractEntity {
    type = 'textpage';
    fields = initFields(this);
    /**
     * Create Page Entity
     * @param {{}|null} data - объект данных страницы
     * @param {String} data.id - uuid.v4(),
     * @param {String} data.title -
     * @param {String} data.slug -
     * @param {String} data.heading -
     * @param {String} data.metaTitle -
     * @param {String} data.metaDescription -
     * @param {String} data.previewImageId -
     * @param {Boolean} data.enabled -
     * @param {Boolean} data.hidden -
     * @param {Boolean} data.noIndex -
     * @param {Array} data.sections -
     */
    constructor(data = {}) {
        super();
        /*      this.setRequiredProps(['title']);
        data = this.validateData(data);*/

        this.id = data.id || uuid.v4();
        this.title = data.title || '';
        this.slug = data.slug || '';
        this.heading = data.heading || '';
        this.metaTitle = data.metaTitle || '';
        this.metaDescription = data.metaDescription || '';
        this.previewImageId = data.previewImageId || null;
        this.enabled = data.enabled === undefined ? true : data.enabled;
        this.hidden = data.hidden || false;
        this.noIndex = data.noIndex || false;
        this.slugDisabled = !!this.slug;
        this.redirect = null;
        this.defaultSections = data.defaultSections || ['SectionBreadcrumbs', 'SectionHeadingH1'];
        this.handleSections(data.sections, this.defaultSections);
        this.sortSections();
    }

    /** Сортировка секций по полю position */
    sortSections() {
        this.sections.sort((a, b) => a.position - b.position);
    }

    get data() {
        return {
            id: this.id,
            title: this.title,
            slug: this.slug,
            heading: this.heading,
            metaTitle: this.metaTitle,
            metaDescription: this.metaDescription,
            previewImageId: this.previewImageId,
            enabled: this.enabled,
            hidden: this.hidden,
            noIndex: this.noIndex,
            sections: this.sections.map((section) => section.data),
            redirect: this.redirect?.data,
        };
    }

    async changeSlugFromTitle(text) {
        const [error, result] = await SlugService.changeSlugFromTitle(text, this);
        if (error) {
            error.notify();
            return;
        }
        if (result) {
            this.slug = result;
            this.fields.slug.value = result;
        }
    }

    createRedirectOnSave(redirect) {
        this.redirect = redirect;
    }
    handleSections(sections) {
        if (sections && sections.length) {
            this.sections = sections.map((section) => new Section(section));
        } else {
            this.sections = [];
            const defaultSections = this.defaultSections.map((item) => SampleSections.getByType(item));
            this.addDefaultSections(defaultSections);
        }
    }
    changeHeadingFromTitle(text) {
        if (this.heading) return;
        this.heading = text;
        const currentH1 = this.sections.find((section) => section.type === 'SectionHeadingH1');
        if (!currentH1) return;
        currentH1.options.content.title.value = text;
        EventEmitter.sendToPublic('update-section', { data: currentH1.data });
    }

    changeHeading(text) {
        const currentH1 = this.sections.find((section) => section.type === 'SectionHeadingH1');
        if (!currentH1) return;
        currentH1.options.content.title.value = text;
        EventEmitter.sendToPublic('update-section', { data: currentH1.data });
    }

    updateCatalogImages(images) {
        this.currentState.images = images;
    }

    addDefaultSections(defaultSections) {
        defaultSections.forEach((defaultSection, index) => {
            const section = this.sections.find((section) => section.type === defaultSection.type);
            if (!section) {
                const newSection = new Section(defaultSection);
                newSection.position = index;
                this.sections.unshift(newSection);
            }
        });
        this.sortSections();
    }
}
