import Vue from 'vue';
import Vuex from 'vuex';

// Modules
import auth from '@/store/modules/authModule';
import builder from '@/store/modules/builderModule';
import clipboard from '@/store/modules/clipboardModule';
import config from '@/store/modules/configModule';
import history from '@/store/modules/historyModule';
import modals from '@/store/modules/modalsModule';
import reviewsCounter from '@/store/modules/reviewsCounterModule';
import product from '@/store/modules/productModule';
import theme from '@/store/modules/themeModule';
import globalVars from '@/store/modules/globalVarsModule';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    getters: {},
    modules: {
        auth,
        builder,
        clipboard,
        config,
        history,
        modals,
        product,
        reviewsCounter,
        theme,
        globalVars,
    },
});
