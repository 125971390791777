import accountHttp from '@/api/accountHttp';
import adminHttp from '@/api/adminHttp';
import CustomError from '@/entities/customError';

type RequestConfig<T> = {
    url: string;
    defaultValue: T;
    errorPath: string;
    data?: any;
    config?: any;
};

function createRequest(type, method, hasData = false) {
    return async function<T>({
        url,
        defaultValue,
        errorPath,
        data,
        config,
    }: RequestConfig<T>): Promise<[CustomError | null, T]> {
        try {
            const { success, error, result, response } = hasData
                ? await type[method](url, data, config)
                : await type[method](url, config);
            const customError = success ? null : new CustomError(error, errorPath, response?.data);
            const resultData = success ? result : response;
            return [customError, resultData];
        } catch (error) {
            const customError = new CustomError(error, errorPath);
            return [customError, defaultValue];
        }
    };
}

export default {
    account: {
        get: createRequest(accountHttp, 'get'),
        post: createRequest(accountHttp, 'post', true),
        put: createRequest(accountHttp, 'put', true),
        delete: createRequest(accountHttp, 'delete'),
    },
    admin: {
        get: createRequest(adminHttp, 'get'),
        post: createRequest(adminHttp, 'post', true),
        put: createRequest(adminHttp, 'put', true),
        delete: createRequest(adminHttp, 'delete'),
    },
};
