<template>
    <a-layout class="file-manager">
        <a-layout-sider theme="light" class="file-manager-sider">
            <directory-tree :items="files" v-model="location" />
        </a-layout-sider>

        <a-layout class="file-manager-content">
            <!-- <a-layout-header style="background: #fff;" @contextmenu.prevent.stop>
                <a-button @click="handleCreateDirectory" type="primary" shape="circle">
                    <a-icon type="folder-add" :style="{ fontSize: '24px' }" />
                </a-button>
            </a-layout-header> -->
            <a-layout-content style="margin: 0 16px" @contextmenu.prevent.stop>
                <!-- <a-breadcrumb style="margin: 16px 0">
                    <a-breadcrumb-item v-for="item in pathSegments" :key="item">
                        <a @click.prevent="changeNavigator(item)">{{ item }}</a>
                    </a-breadcrumb-item>
                </a-breadcrumb> -->
                <div :style="{ padding: '24px', background: '#fff', minHeight: '360px' }">
                    <a-upload-dragger
                        name="file"
                        :multiple="true"
                        :fileList="fileList"
                        :remove="handleUploadRemove"
                        :beforeUpload="beforeUpload"
                        v-if="uploadEnable"
                    >
                        <p class="ant-upload-drag-icon">
                            <a-icon type="inbox" />
                        </p>
                        <p class="ant-upload-text">Click or drag the file to this area</p>
                        <p class="ant-upload-hint">Support batch file upload</p>
                    </a-upload-dragger>
                    <a-button
                        type="primary"
                        block
                        v-if="uploadEnable"
                        @click="handleUpload"
                        :disabled="fileList.length === 0"
                        :loading="uploading"
                        style="margin: 16px 0"
                        >{{ uploading ? 'Uploading' : 'Upload' }}</a-button
                    >

                    <VueContext ref="menu">
                        <template slot-scope="{ data }">
                            <li>
                                <a
                                    menuId="remove"
                                    @click.prevent="
                                        onContextMenuClick($event, $event.target.getAttribute('menuId'), data)
                                    "
                                    >Remove</a
                                >
                            </li>
                            <!-- <li>
                                <a
                                    menuId="rename"
                                    @click.prevent="
                                        onContextMenuClick($event, $event.target.getAttribute('menuId'), data)
                                    "
                                    >Rename</a
                                >
                            </li> -->
                        </template>
                    </VueContext>

                    <files-table v-model="location" :columns="columns" :items="filesList" @remove="handleRemove" />
                </div>
            </a-layout-content>
            <a-layout-footer style="text-align: center"></a-layout-footer>
        </a-layout>
    </a-layout>
</template>

<script>
export default {
    components: {
        DirectoryTree: () => import('@/components/base/SimpleFileManager/DirectoryTree/DirectoryTree.vue'),
        FilesTable: () => import('@/components/base/SimpleFileManager/FilesTable.vue'),
    },
    props: {
        files: {
            type: Array,
            required: true,
        },
        uploadEnable: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            selectedItem: this.files[0],
            columns: [
                {
                    text: 'Title',
                    dataIndex: 'name',
                    value: 'name',
                    scopedSlots: { customRender: 'name' },
                },
                {
                    text: 'Size',
                    dataIndex: 'size',
                    value: 'size',
                    scopedSlots: { customRender: 'size' },
                },
                {
                    text: 'Create Date',
                    dataIndex: 'createdAt',
                    value: 'createdAt',
                    ellipsis: true,
                    scopedSlots: { customRender: 'createdAt' },
                },
                {
                    text: 'Actions',
                    value: 'actions',
                    scopedSlots: { customRender: 'action' },
                },
            ],
            fileList: [],
            uploading: false,
            location: [this.files[0].id],
        };
    },
    computed: {
        filesList() {
            return this.location.reduce((acc, val) => {
                return acc.find((item) => item.id === val).children;
            }, this.files);
        },
    },
    methods: {
        handleCreateDirectory(event) {
            const currentLocation = this.getCurrentLocation();
            this.$confirm({
                title: 'Create a new folder',
                content: () => (
                    <a-form-item
                        label="Please enter the folder name"
                        validate-status="warning"
                        has-feedback
                        help="The folder name cannot include e.g. /,@,! And other special characters"
                    >
                        <a-input id="createDirectoryName" placeholder="Please enter the folder name" />
                    </a-form-item>
                ),
                onOk: async () => {
                    let { value: createDirectoryName } = document.getElementById('createDirectoryName');
                    createDirectoryName = createDirectoryName.trim();
                    console.info(`createDirectoryName: ${createDirectoryName}`);
                    if (!createDirectoryName) {
                        this.$message.error('Wrong folder name');
                        return;
                    }
                    console.info(`emit rename action`);
                    let promise = Promise.resolve();
                    event.waitUntil = (p) => (promise = p);
                    try {
                        this.$emit('fileOperation', event, {
                            type: 'createDirectory',
                            createDirectoryName,
                            currentLocation,
                        });
                        await promise;
                        this.$message.success('create directory successfully.');
                    } catch (error) {
                        this.uploading = false;
                        this.$message.error(`create directory failed with ${error}.`);
                    }
                },
            });
        },
        handleRemove(data) {
            this.$confirm({
                title: 'Remove',
                content: () => <div style="color:red;">Remove file ( {data.item.name} ) ?</div>,
                onOk: async () => {
                    try {
                        await new Promise((resolve, reject) => {
                            this.$emit('remove', {
                                item: data.item,
                                location: data.location,
                                promise: {
                                    resolve,
                                    reject,
                                },
                            });
                        });
                        this.$message.success('remove successfully.');
                    } catch (error) {
                        this.$message.error(`remove failed with ${error}.`);
                    }
                },
            });
        },
        handleRename(item) {
            const label = `Rename file ( ${item.name} ) `;
            this.$confirm({
                title: 'Rename',
                content: () => (
                    <a-form-item
                        label={label}
                        validate-status="warning"
                        has-feedback
                        help="The file name cannot include e.g. /,@,! And other special characters"
                    >
                        <a-input id="renameFileName" placeholder="Please enter a new file name" />
                    </a-form-item>
                ),
                onOk: async () => {
                    try {
                        await new Promise((resolve, reject) => {
                            this.$emit('rename', {
                                item,
                                promise: {
                                    resolve,
                                    reject,
                                },
                            });
                        });
                        this.$message.success('rename successfully.');
                    } catch (error) {
                        this.$message.error(`rename failed with ${error}.`);
                    }
                },
            });
        },
        handleUploadRemove(file) {
            const index = this.fileList.indexOf(file);
            const newFileList = this.fileList.slice();
            newFileList.splice(index, 1);
            this.fileList = newFileList;
        },
        beforeUpload(file) {
            this.fileList = [...this.fileList, file];
            return false;
        },
        async handleUpload() {
            const { fileList } = this;
            this.uploading = true;
            try {
                await new Promise((resolve, reject) => {
                    this.$emit('upload', {
                        files: fileList,
                        location: this.location,
                        promise: {
                            resolve,
                            reject,
                        },
                    });
                });
                this.fileList = [];
                this.$message.success('upload successfully.');
            } catch (error) {
                this.$message.error(`upload failed with ${error}.`);
            } finally {
                this.uploading = false;
            }
        },
    },
};
</script>

<style>
.file-manager {
    box-shadow: rgba(0, 0, 0, 0.5) 0 2pt 6pt;
}
.file-manager-sider .ant-layout-sider-trigger {
    position: inherit;
}
.file-manager-content {
    z-index: 1;
}
.file-manager-content /deep/ .ant-table-thead > tr.ant-table-row-selected > td,
.file-manager-content /deep/ .ant-table-tbody > tr.ant-table-row-selected > td,
.file-manager-content /deep/ .ant-table-thead > tr:hover.ant-table-row-selected > td,
.file-manager-content /deep/ .ant-table-tbody > tr:hover.ant-table-row-selected > td {
    background-color: #ccffcc;
}
</style>
