<template>
    <div v-if="current" :key="current.id" class="option-list-sections">
        <div :key="groupName" v-for="(groupValue, groupName) in current.options" class="section-option-group">
            <div class="section-option-group__heading">
                <span class="text-subtitle">{{ $t(`builder.sectionOptions.groups.${groupName}`) }}</span>
            </div>
            <!-- Если группа это уже значение (например background) -->
            <template v-if="groupValue.component">
                <FormItem v-bind="current.options[groupName].props">
                    <component
                        :is="current.options[groupName].component"
                        :name="groupName"
                        v-on="getFieldEvents(current.options[groupName].events)"
                        v-model="current.options[groupName].value"
                        v-bind="current.options[groupName].props"
                        @update-field="onUpdateFieldValue"
                    />
                </FormItem>
            </template>
            <template v-else>
                <div
                    :key="key"
                    v-for="(field, key) in current.options[groupName]"
                    class="section-option-group__item-field"
                >
                    <FormItem
                        v-if="
                            current.options[groupName][key].visibleCondition
                                ? current.options[groupName][key].visibleCondition(current)
                                : true
                        "
                        :name="key"
                        v-bind="current.options[groupName][key].props"
                    >
                        <component
                            :is="field.component"
                            :name="key"
                            :group="groupName"
                            v-on="getFieldEvents(current.options[groupName][key].events)"
                            v-model="current.options[groupName][key].value"
                            v-bind="current.options[groupName][key].props"
                            @update-field="onUpdateFieldValue"
                        />
                    </FormItem>
                </div>
            </template>
        </div>
        <div v-if="!current.required" class="option-list-sections__btns">
            <Button @click="dublicateSectionItem(current)" type="primary" icon="copy" fullwidth>
                {{ $t('builder.global.btns.dublicate') }}
            </Button>
            <Button @click="removeSectionItem(current.id)" type="secondary" icon="delete" fullwidth>
                {{ $t('builder.global.btns.remove') }}
            </Button>
        </div>
    </div>
</template>

<script>
import { debounce } from 'lodash';
import { mapActions, mapGetters, mapState } from 'vuex';
import validateField from '@/helpers/validator';
import FormItem from '@/components/form/item.vue';
import Button from '@/components/common/Button.vue';

export default {
    name: 'OptionListSectionItems',
    components: {
        CategorySwitcher: () => import('@/components/builder/optionList/category-switcher/CategorySwitcher.vue'),
        FormItem,
        Button,
    },
    computed: {
        ...mapState('builder', {
            isHistory: 'isHistory',
        }),
        ...mapGetters('builder', {
            current: 'getSidebarRightCurrent',
        }),
    },
    methods: {
        ...mapActions('builder', {
            updateSectionItem: 'updateSectionItem',
            dublicateSectionItem: 'dublicateSectionItem',
            removeSectionItem: 'removeSectionItem',
        }),
        updateSectionItemDebounced: debounce(function(sectionItem) {
            this.updateSectionItem(sectionItem);
        }, 300),
        getFieldEvents(events) {
            const fieldEvents = {};
            for (const key in events) {
                fieldEvents[key] = events[key]?.bind(this.current);
            }
            return fieldEvents;
        },
        onUpdateFieldValue: debounce(function(payload) {
            const { name, group } = payload;
            const option = group ? this.current.options?.[group]?.[name] : this.current.options?.[name];
            if (!option) return;
            const errors = validateField(option.value, option.props?.rules);
            try {
                if (group) this.$set(this.current.options[group][name].props, 'errors', errors);
                else this.$set(this.current.options[name].props, 'errors', errors);
                this.$set(this.current, 'hasErrors', this.current.checkError());
            } catch (error) {
                // костыль
            }
        }, 1000),
    },
    watch: {
        current: {
            handler(sectionItem) {
                if (this.isHistory && sectionItem) this.updateSectionItemDebounced(sectionItem);
            },
            deep: true,
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.section-option-group {
    margin: 0 0 $spacer * 8;

    &__heading {
        margin: 0 0 $spacer * 5;
    }

    &__item {
        &-field {
            margin: 0 0 $spacer * 4;

            &:last-child {
                margin: 0;
            }
        }
    }
}
</style>
