<template>
    <a :href="to"><slot /></a>
</template>

<script>
export default {
    name: 'NuxtLink',
    props: {
        to: String,
    },
};
</script>

<style></style>
