<template>
    <div class="page -width-lg">
        <sticky-toolbar>
            <Button icon="check" :disabled="!hasChange" @click="onUpdateSettings">{{ $t('entities.save') }}</Button>
        </sticky-toolbar>
        <div class="page-header meta-tags__page-header">
            <h1 class="page-header__text">{{ $t('menus.pageHeaders.metaTags') }}</h1>
        </div>
        <alert type="success" class="entity-item__success-alert" v-if="isUpdated">
            {{ $tc('notifications.updated', 2, { entity: this.$tc('entities.seo.metaTags.title', 1) }) }}
        </alert>
        <template v-if="settings">
            <headed-block
                class="entity-item__block"
                :heading="$t(`settings.seo.metatags.${key}`)"
                v-for="(block, key) in blocks"
                :key="key"
            >
                <form-item
                    v-for="(field, key) in block"
                    :name="key"
                    class="form__input"
                    :key="key"
                    v-bind="field.props"
                >
                    <component
                        :is="field.component"
                        v-bind="field.props || {}"
                        @update-field="onUpdateFieldValue"
                        :name="key"
                        v-on="getFieldEvents(field.events)"
                        v-model="settings[key].value"
                    ></component>
                </form-item>
                <alert :key="`${key}-alert`" elevated class="meta-tags__alert" type="info">
                    <small>
                        {{ $t('settings.seo.metatags.variables') }}:
                        <template v-for="(variable, index) in availableVariables[key]">
                            <b :key="variable">%{{ variable }}%</b
                            >{{ index === availableVariables[key].length - 1 ? null : ', ' }}
                        </template>
                    </small>
                </alert>
            </headed-block>
        </template>
    </div>
</template>

<script>
import { cloneDeep, debounce } from 'lodash';
import SettingService from '@/services/SettingService';
import StickyToolbar from '@/components/common/StickyToolbar';
import fields from '@/entities/seo/metatags.fields';
import FormItem from '@/components/form/item';
import validateField from '@/helpers/validator';
import EventEmitter from '@/helpers/eventEmitter.ts';
import Button from '@/components/common/Button';
import Alert from '@/components/common/Alert';
import HeadedBlock from '@/components/common/HeadedBlock';

export default {
    name: 'MetaTags',
    components: { HeadedBlock, Alert, FormItem, StickyToolbar, Button },

    data() {
        return {
            default: {},
            hasChange: false,
            availableVariables: {
                product: ['shop_name', 'product_name', 'currency', 'price'],
                catalog: ['shop_name', 'catalog_name'],
                page: ['shop_name', 'page_name'],
                category: ['shop_name', 'category_name'],
                article: ['shop_name', 'article_name'],
            },
            fields,
            fieldKeys: Object.keys(fields),
            isUpdated: false,
        };
    },

    computed: {
        settings() {
            return this.$store.getters['config/getAllSettings'].seo;
        },
        blocks() {
            return {
                product: {
                    templateProductH1: fields.templateProductH1,
                    templateProductTitle: fields.templateProductTitle,
                    templateProductDescription: fields.templateProductDescription,
                },
                catalog: {
                    templateCatalogH1: fields.templateCatalogH1,
                    templateCatalogTitle: fields.templateCatalogTitle,
                    templateCatalogDescription: fields.templateCatalogDescription,
                },
                page: {
                    templatePageH1: fields.templatePageH1,
                    templatePageTitle: fields.templatePageTitle,
                    templatePageDescription: fields.templatePageDescription,
                },
                category: {
                    templateCategoryH1: fields.templateCategoryH1,
                    templateCategoryTitle: fields.templateCategoryTitle,
                    templateCategoryDescription: fields.templateCategoryDescription,
                },
                article: {
                    templateArticleH1: fields.templateArticleH1,
                    templateArticleTitle: fields.templateArticleTitle,
                    templateArticleDescription: fields.templateArticleDescription,
                },
            };
        },
    },

    created() {
        setTimeout(() => {
            this.default = cloneDeep(this.$store.getters['config/getAllSettings'].seo);
            this.checkChanges(this.settings);
        }, 0);
    },

    watch: {
        settings: {
            handler(newValue) {
                this.checkChanges(newValue);
            },
            deep: true,
        },
    },

    methods: {
        checkChanges(newValue) {
            this.hasChange = JSON.stringify(newValue) !== JSON.stringify(this.default);
        },

        async onUpdateSettings() {
            const isFormValid = this.validateForm();
            if (isFormValid === false) return;
            const [error, result] = await SettingService.updateGroup('seo', this.settings);
            error ? this.failedHandler(result) : this.successUpdateHandler();
        },

        successUpdateHandler() {
            this.default = cloneDeep(this.settings);
            this.checkChanges(this.settings);
            this.isUpdated = true;
            window.scrollTo({ top: 0, behavior: 'smooth' });
        },

        validateForm() {
            let result = true;
            const fieldKeys = Object.keys(this.fields);
            fieldKeys.forEach((key) => {
                const errors = validateField(this.fields[key].value, this.settings[key].options.rules);
                if (errors.length !== 0) result = false;
                this.$set(this.fields[key].props, 'errors', errors);
            });
            if (result === false)
                EventEmitter.trigger('show-noty', {
                    type: 'error',
                    text: this.$t('notifications.validation.error'),
                });
            return result;
        },

        failedHandler(response) {
            const children = response.data?.errors?.children;
            if (children) {
                this.fieldKeys.forEach((key) => {
                    const errors = children[key] ? children[key].errors : [];
                    if (errors) this.$set(this.fields[key].props, 'errors', errors);
                });
            }
            EventEmitter.trigger('show-noty', {
                type: 'error',
                text: `Error occurred. Status ${response.error.statusCode}: ${response.error.message}`,
            });
        },

        onUpdateFieldValue: debounce(function(payload) {
            const { name } = payload;
            const errors = validateField(this.fields[name].value, this.settings[name].options.rules);
            this.$set(this.fields[name].props, 'errors', errors);
        }, 600),

        getFieldEvents(events) {
            const fieldEvents = {};
            for (const key in events) {
                fieldEvents[key] = events[key]?.bind(this.settings);
            }
            return fieldEvents;
        },
    },

    beforeDestroy() {
        if (this.hasChange) this.$store.dispatch('config/setSettings', { seo: cloneDeep(this.default) });
    },
};
</script>

<style lang="scss">
.meta-tags {
    &__page-header {
        margin-top: -45px;
        padding-right: 520px;
    }
    &__alert {
        width: 100%;
    }
}
</style>
