<template>
    <modal
        v-if="model"
        v-model="model"
        :width="success ? '600' : '400'"
        :header="success ? $t('entities.domain.settings') : $t('lists.addButton.domain')"
    >
        <alert v-if="success" class="entity-item__success-alert" type="success">
            {{ $tc('notifications.created', 1, { entity: this.$tc('entities.domain.title', 1) }) }}
        </alert>
        <div class="domain-modal" v-if="model">
            <template v-if="!success">
                <form class="modal__content form">
                    <template>
                        <form-item v-bind="form.items.name.props" class="form__input">
                            <input-text
                                v-model="form.items.name.value"
                                name="name"
                                v-bind="form.items.name.props"
                                @update-field="onUpdateFieldValue"
                            >
                            </input-text>
                        </form-item>
                        <!--                <form-item v-bind="form.items.primary.props" class="form__input">-->
                        <!--                    <input-checkbox-->
                        <!--                        text="Set as primary"-->
                        <!--                        class="domains__checkbox"-->
                        <!--                        v-model="form.items.primary.value"-->
                        <!--                        v-bind="form.items.primary.props"-->
                        <!--                        name="primary"-->
                        <!--                    ></input-checkbox>-->
                        <!--                    <span class="domains__checkbox-caption">{{ $t('entities.domain.modalCaption') }}</span>-->
                        <!--                </form-item>-->
                    </template>
                </form>
                <Button class="modal__save-btn domains__save-button" :disabled="!isSaveAllowed" @click="onSubmit">
                    {{ $t('entities.save') }}
                </Button>
            </template>
            <template v-else>
                <DomainConfirmedTooltip :domain="domainName"></DomainConfirmedTooltip>
                <DomainEmailTooltip :domain="domainName"></DomainEmailTooltip>
            </template>
        </div>
    </modal>
</template>

<script>
import { proxyModelMixin } from '@/mixins/proxyModelMixin';
import FormItem from '@/components/form/item';
import Modal from '@/components/common/Modal';
import Button from '@/components/common/Button';
import InputText from '@/components/form/controls/InputText';
// import InputCheckbox from '@/components/form/controls/InputCheckbox';
import Form from '@/components/form/Form';
import DomainService from '@/services/DomainService.ts';
import Domain from '@/entities/domain/Domain';
import { mapGetters } from 'vuex';
import { isEqual } from 'lodash';
import DomainConfirmedTooltip from '@/components/tooltips/DomainConfirmedTooltip';
import DomainEmailTooltip from '@/components/tooltips/DomainEmailTooltip';
import Alert from '@/components/common/Alert';
export default {
    name: 'DomainModal',
    mixins: [proxyModelMixin],
    components: {
        Alert,
        DomainEmailTooltip,
        DomainConfirmedTooltip,
        // InputCheckbox,
        InputText,
        FormItem,
        Modal,
        Button,
    },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
    },
    created() {
        this.initForm();
        this.initialData = this.form.collectData();
    },
    data() {
        return {
            form: null,
            success: false,
            initialData: {},
            domainName: null,
        };
    },
    computed: {
        ...mapGetters('globalVars', {
            instance: 'getInstance',
        }),
        isSaveAllowed() {
            return !isEqual(this.initialData, this.form.collectData());
        },
    },
    methods: {
        initForm() {
            this.form = new Form({
                action: this.createDomain,
                validateHandler: this.validateHandler,
                successHandler: this.successHandler,
                failedHandler: this.failedHandler,
                items: new Domain().fields,
            });
        },
        async createDomain(data) {
            const isFormValid = this.form.validate();
            if (!isFormValid) return;
            return await DomainService.createOne(this.instance, {
                domain: data.name,
                primary: data.primary,
            });
        },
        onUpdateFieldValue: function(payload) {
            const { name, group } = payload;
            this.form.validateField(name, group);
        },
        successHandler() {
            this.$emit('action-success');
            this.success = true;
            this.domainName = this.form.items.name.value;
        },

        validateHandler() {
            // console.log('validateHandler');
        },
        failedHandler(result) {
            console.log(result);
        },
        onSubmit() {
            this.form.submit();
        },
    },
    watch: {
        model(val) {
            if (!val) {
                this.success = false;
            } else {
                this.initForm();
            }
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.domain-modal {
    .sidebar-tooltip {
        margin-bottom: 10px !important;
    }
}
.domains {
    &__checkbox-caption {
        display: inline-block;
        margin-top: 6px;
        font-size: 12px;
        line-height: 14px;
        color: var(--v-on-surface-medium);
    }
    &__save-button {
        width: 100%;
    }
}
</style>
