export default {
    topbar: {
        back: 'Назад',
        backToAdmin: 'На главную',
        pageSwitcher: {
            search: 'Поиск',
            addNew: 'Добавить',
        },
    },
    pageType: {
        homepage: 'Главная',
        textPages: 'Текстовые страницы',
        catalogs: 'Каталоги',
        product: 'Страница продукта',
        cart: 'Корзина',
        checkout: 'Оформление заказа',
        addNew: 'Добавить',
        category: 'Медиа',
        article: 'Статьи в категории {category}',
    },
    sidebarLeft: {
        header: 'Шапка',
        footer: 'Подвал',
    },
    sidebarRigth: {
        title: 'Настройки',
        pageSettings: 'Page Settings',
        websiteSettings: 'Website Settings',
        tabs: {
            page: 'Страница',
            website: 'Сайт',
        },
    },
    pageStatus: {
        hidden: 'Скрыть',
        enabled: 'Включить',
        noIndex: 'Отключить индексацию',
        turned_off: 'Выключено',
        hidden_no_indexed: 'Скрыто, не индексировано',
        open_no_indexed: 'Показано, не индексировано',
        open_indexed: 'Показано, индексировано',
        hidden_indexed: 'Скрыто, индексировано',
    },
    sections: {
        groups: {
            Benefits: 'Преимущества',
            Breadcrumbs: 'Хлебные крошки',
            Category: 'Категории',
            Contacts: 'Контакты',
            FAQ: 'Вопросы и ответы',
            Gallery: 'Галерея',
            Heading: 'Заголовк',
            ProductList: 'Список продуктов',
            ProductWidget: 'Виджет продуктов',
            Slider: 'Слайдер',
            TextBlocks: 'Текстовый блок',
            Media: 'Media',
            MediaWidget: 'Media',
        },
        SectionHeadingH1: 'Заголовок (h1)',
        SectionBreadcrumbs: 'Хлебные крошки',

        SectionSlider: 'Слайдер',
        SectionSliderBase: 'Слайдер',
        SectionProductWidget: 'Виджет товара каталог',
        SectionProductWidgetByItems: 'Виджет товара с элемеентами',
        SectionProductList: 'Список товаров',
        SectionTextBlockPictureRight: 'Текстовый блок с изображением справа',
        SectionTextBlockPictureLeft: 'Текстовый блок с изображением слева',
        SectionTextBlockStyle1: 'Текстовый блок, стиль 1',
        SectionTextBlockStyle2: 'Текстовый блок, стиль 2',
        SectionTextCustomContent: 'Текстовый блок колонками',
        SectionHoverTextWithBgStyle1: 'Текстовый блок с фоном',

        SectionFAQBase: 'FAQ',
        SectionFAQStyle1: 'FAQ, стиль 1',
        SectionFAQStyle2: 'FAQ, стиль 2',

        SectionCategoryBase: 'Категории',
        SectionCategoryStyle1: 'Категории, стиль 1',
        SectionCategoryStyle2: 'Категории, стиль 2',
        SectionCategoryStyle3: 'Категории, стиль 3',
        SectionCategoryStyle4: 'Категории, стиль 4',
        SectionCategoryStyle5: 'Категории, стиль 5',
        SectionCategoryStyle6: 'Категории, стиль 6',

        SectionGalleryBase: 'Галерея',
        SectionGaleryLogos: 'Галерея логотипов',
        SectionGaleryStyle1: 'Галерея, стиль 1',
        SectionGaleryStyle2: 'Галерея, стиль 2',
        SectionGaleryStyle3: 'Галерея, стиль 3',

        SectionBenefitBase: 'Преимущества',
        SectionBenefitStyle1: 'Преимущества, стиль 1',
        SectionBenefitStyle2: 'Преимущества, стиль 2',
        SectionBenefitStyle3: 'Преимущества, стиль 3',
        SectionBenefitStyle4: 'Преимущества, стиль 4',
        SectionBenefitStyle5: 'Преимущества, стиль 5',

        SectionContactsStyle1: 'Контакты, стиль 1',
        SectionContactsStyle2: 'Контакты, стиль 2',
        SectionContactsWithFormStyle1: 'Контакты с формой, стиль 1',

        SectionFormStyle1: 'Форма Style 1',

        SectionPropertiesStyle1: 'Свойства Style 1',
        SectionPropertiesStyle2: 'Свойства Style 2',
        SectionPropertiesStyle3: 'Свойства Style 3',
        SectionPropertiesStyle4: 'Свойства Style 4',

        SectionTestimonialBase: 'Отзывы',
        SectionTestimonialStyle1: 'Отзывы Style1',

        SectionBannerStyle1: 'Баннер Style 1',
        SectionBannerStyle2: 'Баннер Style 2',

        SectionMediaComments: 'Комментарии',
        SectionMediaTableOfContents: 'Содержимое',
        SectionMediaTagsList: 'Теги',
        SectionMediaList: 'Список статей',
        SectionMediaAuthors: 'Авторы',
        SectionMediaWidget: 'Виджет статей',

        style1: 'стиль 1',
        style2: 'стиль 2',
        style3: 'стиль 3',
        style4: 'стиль 4',
        style5: 'стиль 5',
        style6: 'стиль 6',
        style7: 'стиль 7',
        html: 'HTML контент',
        image: 'Изображение',
        slide: 'Слайд',
        FAQItem: 'FAQ элемент',
        benefit: 'Преимущество',
        category: 'Категория',
        galleryItem: 'Элемент',
        productItem: 'Элемент',
        hoverTextItem: 'Элемент',
        customContent: 'Контент',

        noHeading: 'Без заголовка',
    },
    sectionOptions: {
        groups: {
            heading: 'Заголовок',
            content: 'Контент',
            columns: 'Элементов в ряд',
            list: 'Элементов в ряд', // вместо columns
            background: 'Фон',
            limit: 'Количество товаров',
            seeAll: 'Кнопка "Показать все"',
            btnSeeCatalog: 'Кнопка "Показать все"',
            btnLoadMore: 'Кнопка "Загрузить ещё"',
            personalSettings: 'Кнопка "Задать персональные настройки"',
            catalogSlug: 'Выберите каталог',
            categorySlug: 'Выберите категорию',
            product: 'Выберите товар',
            rows: 'Количество строк',
            button: 'Кнопка',
            align: 'Выравнивание',
            template: 'Шаблон',
            grid: 'Сетка',
            itemsStyle: 'Стиль элементов',
            itemsLayout: 'Расположение элементов',
        },
        props: {
            align: 'Align',
            avatar: 'Avatar',
            author: 'Author',
            alignContent: 'Align Content',
            alignHorizontal: 'Выравнивание по вертикали',
            alignVertical: 'Выравнивание по горизонтали',
            title: 'Текст',
            textAlign: 'Выравнивание',
            note: 'Текст',
            btnText: 'Текст',
            btnConfirm: 'Текст кнопки подтверждения',
            btnReject: 'Текст кнопки отказа',
            enabled: 'Включить',
            text: 'Текст',
            html: 'Текст',
            htmlBefore: 'Текст в начале',
            htmlAfter: 'Текст в конце',
            icon: 'Иконка',
            image: 'Картинка',
            imageMobile: 'Картинка на мобильнике',
            imageAlt: 'Alt-текст картинки',
            imageTitle: 'Title картинки',
            fontStyle: 'Стиль текста',
            desktop: 'На десктопе',
            tablet: 'На планшете',
            mobile: 'На мобильнике',
            link: 'Ссылка',
            mapUrl: 'Ссылка на Google Map',
            mapUrlNote:
                'Вы должны использовать встраиваемые Google карты (iframe). Скопируйте и вставьте атрибуты "src" в это поле',
            btnSeeAll: 'Добавить кнопку "Посмотреть все"',
            btnSeeAllText: 'Текст кнопки',
            btnSeeCatalogShow: 'Показать кнопку',
            btnSeeCatalogText: 'Текст кнопки',
            btnPersonalSettings: 'Персональные настройки',
            btnPersonalSettingsNote: 'Задать персональные настройки',
            btnPersonalSettingsText: 'По умолчанию все опции берутся из общих настроек ',
            color: 'Цвет',
            tag: 'Тэг',
            listWidth: 'Ширина списка',
            useCarousel: 'Ипользовать карусель',
            order: 'Порядок',
            orderMobile: 'Порядок на мобильном',
            limitCustom: 'Нестандартное количество',
            limitCustomCount: 'Количество продуктов на странице',
            padding: 'Отступы',
            borderRadius: 'Радиус границы',
            marginTop: 'Отступ сверху',
            marginBottom: 'Отступ снизу',
            useDefault: 'Настройки по умолчанию',
            imageRatio: 'Соотношение сторон изображения',
            imageMobileRatio: 'Соотношение сторон изображения на мобильном',
            showArrow: 'Показывать стрелки карусели при наведении',
            showDots: 'Показать точки карусели',
            colorText: 'Цвет текста',
            colorTag: 'Цвет тега',
            background: 'Фон',
            opacity: 'Opacity',
            video: 'ID видео',
            videoNote: 'Используйте YouTube или Vimeo. Для встраивания используйте iframe, ссылку или Id. ',
            media: 'Видео или картинка',
            colorOverlay: 'Наложение цвета',
            source: 'Источник',
            ratio: 'Соотношение сторон',
            opacityMobile: 'Прозрачность фона на мобильном',
            sectionLayout: 'Расположение секции',
            autoplay: 'Автовоспроизведение',
            autoplaySpeed: 'Скорость автовоспроизведения',
            itemTemplate: 'Шаблон элементов',
            minHeight: 'Минимальная высота',
            imageProportions: 'Пропорции изображения',
            colorInput: 'Цвет ввода',
            colorLabel: 'Цвет подписей',
            colorError: 'Цвет ошибок',
            borderColor: 'Цвет границы',
            previewImage: 'Картинка превью',
            usePreviewImage: 'Картинка превью',
            usePreviewImageText: 'Использовать картинку',
        },
        items: {
            light: 'Светлый',
            middle: 'Средний',
            dark: 'Темный',
            left: 'Слева',
            center: 'По центру',
            right: 'Справа',
            useNo: 'Не использовать',
            useMobile: 'На мобильниках',
            useBoth: 'На всех разрешения',
            overlay: 'Текст поверх картинки',
            textFirst: 'Сначала текст',
            imageFirst: 'Сначала картинка',
            small: 'Маленькая',
            normal: 'Нормальная',
            default: 'По умолчанию',
            custom: 'Пользовательский',
            none: 'Без фона',
        },
    },
    addNew: {
        block: {
            btn: 'Добавить',
        },
        section: {
            btn: 'Добавить секцию',
        },
        pasteSection: 'Вставить секцию',
    },
    menuEditor: {
        add: 'Добавить',
        edit: 'Редактировать',
        remove: 'Удалить',
        title: 'Название',
        link: 'Ссылка',
        sectionId: 'Id секции',
    },
    global: {
        btns: {
            pageSettings: 'Настройки страницы',
            settings: 'Настройки',
            settingsPage: 'Настройки страницы',
            settingsSite: 'Настройки сайта',
            savePage: 'Сохранить',
            createPage: 'Опубликовать',
            remove: 'Удалить',
            copy: 'Копировать',
            dublicate: 'Дублировать',
            hidden: 'Скрыть секцию',
        },
    },
};
