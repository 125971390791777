<template>
    <div
        class="select-product"
        :class="{ '-has-errors': hasErrors, '-is-open': isDropdownShow }"
        v-click-outside="closeDropdown"
    >
        <div class="select-product__field" :class="field.classes" @click="toggleDropdown" ref="select">
            <span class="select-product__icon material-symbols-outlined">shopping_bag</span>
            <span>{{ field.title }}</span>
        </div>
        <span class="select-product__arrow material-symbols-outlined" :class="{ 'is-open': isDropdownShow }">
            expand_more
        </span>
        <div class="select-product__dropdown" :class="{ 'is-open': isDropdownShow, '-top': dropdownDirectionTop }">
            <div class="select-product__search">
                <input
                    class="select-product__search-input"
                    v-model="searchQuery"
                    :placeholder="$t('builder.topbar.pageSwitcher.search')"
                    type="text"
                />
            </div>
            <template v-for="(option, index) in options">
                <div
                    v-if="value.id !== option.id"
                    :key="index"
                    class="select-product__option"
                    @click="selectOption(option)"
                >
                    <span>{{ $t(option.title) }}</span>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import { debounce } from 'lodash';
import { proxyModelMixin } from '@/mixins/proxyModelMixin';
import ProductService from '@/services/ProductService';
import SearchService from '@/services/SearchService';

export default {
    name: 'SelectProduct',
    inheritAttrs: false,
    mixins: [proxyModelMixin],
    props: {
        name: { type: String, default: '' },
        group: { type: String, default: null },
        placeholder: { type: String, default: 'Choose' },
        errors: { type: [Array, Object], default: () => [] },
        value: { type: [String, Array, Number, Object, Date] },
    },
    data() {
        return {
            dropdownDirectionTop: false,
            isDropdownShow: false,
            options: [],
            searchQuery: '',
        };
    },
    created() {
        if (this.value && this.value.id) {
            ProductService.getOne(this.value.id).then((result) => {
                this.options.push({
                    title: result.title,
                    id: result.id,
                    slug: result.slug,
                });
            });
        }
    },
    computed: {
        hasErrors() {
            return Array.isArray(this.errors) && this.errors.length > 0;
        },
        field() {
            // как бы тут не было значения 0, но пока оставлю
            const selectedItem = this.options.find((option) => this.value.id == option.id);
            if (selectedItem) return { classes: '-value', title: this.$t(selectedItem.title) };
            return {
                classes: '-placeholder',
                title: this.placeholder ? this.$t(this.placeholder) : this.name,
            };
        },
    },
    watch: {
        value: function(newValue) {
            this.$emit('update-field', {
                name: this.name,
                group: this.group,
                value: newValue,
            });
        },
        searchQuery: function(newValue) {
            if (newValue.length === 0) this.options.splice(1);
            if (newValue.length >= 3) this.updateOptionsList(newValue);
        },
    },
    methods: {
        updateOptionsList: debounce(async function(query) {
            const [error, result] = await SearchService.entitySearch('product', {
                q: query,
                limit: 30,
                offset: 0,
            });
            if (error) console.log('error', error);
            else {
                this.options.splice(1);
                const data = result.data;
                if (data.length)
                    data.forEach((item) => {
                        this.options.push(item);
                    });
            }
        }, 300),
        selectOption(option) {
            this.model = {
                id: option.id,
                slug: option.slug,
                title: option.title,
            };
            this.closeDropdown();
        },
        openDropdown() {
            this.chooseDropdownDirection();
            this.isDropdownShow = true;
        },
        closeDropdown() {
            this.isDropdownShow = false;
        },
        toggleDropdown() {
            if (this.isDropdownShow) this.closeDropdown();
            else this.openDropdown();
        },
        chooseDropdownDirection() {
            const offsetTop = this.$refs.select.getBoundingClientRect().y;
            this.dropdownDirectionTop = window.innerHeight - offsetTop < 350;
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';

.select-product {
    position: relative;

    &.-is-open {
        z-index: 5;
    }

    &__field {
        display: block;
        width: 100%;
        height: $form-control-height;
        padding: 0 $form-control-padding-horizontal;
        padding-left: $form-control-padding-horizontal + 24px;
        border: $form-control-border;
        border-radius: $form-control-border-radius;
        background: $form-control-bg;
        transition: $form-control-transition;
        appearance: none;
        line-height: calc(#{$form-control-height} - 2px);
        cursor: pointer;

        &:hover,
        &:focus {
            border-color: $form-control-border-color-hover;
        }

        &.-placeholder {
            color: inherit;
            opacity: 0.7;
        }
        &.-value {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            padding-right: 36px;
        }
    }

    &__icon {
        position: absolute;
        left: 8px;
        top: 7px;
        pointer-events: none;
    }

    &.-has-errors &__field {
        border-color: $error;
    }

    &__arrow {
        position: absolute;
        right: 8px;
        top: 7px;
        translate: $transition-fast;
        pointer-events: none;

        &.is-open {
            transform: rotate(180deg);
        }
    }

    &__dropdown {
        position: absolute;
        left: 0;
        top: 100%;
        display: block;
        width: 100%;
        border: 1px solid #cececf;
        border-radius: $input-border-radius;
        padding: 6px 0;
        background: $input-bg;
        max-height: 500px;
        overflow-y: scroll;

        @include smooth-dropdown-hide();

        &.is-open {
            @include smooth-dropdown-show();
        }

        &.-top {
            bottom: 100%;
            top: auto;
            transform-origin: 0% 100%;
        }
    }

    &__option {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        padding: 8px 16px 8px 14px;
        transition: $transition-fast;
        font-size: 16px;
        line-height: 20px;
        text-align: left;
        cursor: pointer;

        &-icon {
            position: relative;
            top: 0;
            color: inherit;
            line-height: 24px;
            margin-right: 10px;
        }

        &:hover {
            background-color: #f4f6f7;
        }

        &.-current {
            border-bottom: 1px solid #e0e0e0;
        }

        &.-highlighted {
            color: #2979ff;
        }
    }

    &__search {
        padding: 12px 12px;
        &-input {
            display: block;
            width: 100%;
            height: $input-height;
            padding: 0 12px;
            border: 1px solid #cececf;
            border-radius: $input-border-radius;
            transition: $input-transition;
            line-height: $input-height - 2px;
            cursor: pointer;
            font-size: 16px;

            &:hover {
                border-color: $input-border-color-hover;
            }
        }
    }
}
</style>
