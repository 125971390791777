<template>
    <div class="page -column -with-sidebar-right">
        <progress-circular class="entity-item__spinner spinner" v-if="!isDataLoaded && isAvailable"></progress-circular>
        <template v-if="!isAvailable">
            <div class="page-header">
                <h1 class="page-header__text">{{ $t('entities.catalog.newItem') }}</h1>
            </div>
            <error-block></error-block>
        </template>
        <template v-else-if="isDataLoaded">
            <catalog-item-sidebar :catalog="currentState" @change="onChangeCatalog"></catalog-item-sidebar>
            <sticky-toolbar>
                <Button
                    icon="check"
                    :disabled="!isSaveAllowed"
                    @click="isEditing ? onUpdateCatalog() : onCreateCatalog()"
                >
                    {{ $t('entities.save') }}</Button
                >
                <a
                    target="_blank"
                    v-if="isEditing"
                    :href="`https://${instanceFull.primaryDomain}/catalogs/${currentState.slug}`"
                >
                    <Button type="outlined" icon="visibility">{{ $t('entities.view') }}</Button>
                </a>
                <Button
                    v-if="isEditing"
                    @click="
                        $router.push({
                            name: 'Builder Edit Catalog',
                            params: { id: currentState.id, type: currentState.type },
                        })
                    "
                    type="outlined"
                    icon="edit"
                    >{{ $t('entities.editInBuilder') }}</Button
                >
                <!--
                TODO: пока нет метода API-->
                <!--                <Button type="outlined" disabled icon="content_copy" v-if="isEditing">{{
                    $t('entities.duplicate')
                }}</Button>-->
                <Button type="outlined" icon="delete" v-if="isEditing" @click="openDeletionModal">
                    {{ $t('entities.remove') }}</Button
                >
            </sticky-toolbar>
            <div class="page-header entity-item__page-header">
                <h1 class="page-header__text">{{ catalogId ? currentState.title : $t('entities.catalog.newItem') }}</h1>
            </div>
            <alert
                type="success"
                class="entity-item__success-alert"
                :class="{ '-break-line': isCreated }"
                v-if="isCreated || isUpdated"
            >
                {{
                    isCreated
                        ? $tc('notifications.created', 1, { entity: this.$tc('entities.catalog.title', 1) })
                        : $tc('notifications.updated', 1, { entity: this.$tc('entities.catalog.title', 1) })
                }}
                <template v-if="isCreated">
                    <br />
                    <span @click="onAddMoreOne" class="alert__link">
                        {{ $t('entities.catalog.alertText.createAnother') }}</span
                    >
                </template>
            </alert>
            <headed-block v-if="catalogId" :heading="$t('entities.blocks.content')" class="edit-content-block">
                <template v-slot:header-append
                    ><Button
                        :to="{ name: 'Builder Edit Catalog', params: { id: catalogId, type: 'catalog' } }"
                        icon="edit"
                        >{{ $t('entities.editInBuilder') }}</Button
                    ></template
                >
            </headed-block>
            <headed-block class="entity-item__block image-block" :heading="$t('entities.blocks.image')">
                <image-field image-type="catalog" v-model="currentState.previewImageId" is-popup></image-field>
            </headed-block>
            <template>
                <headed-block
                    class="entity-item__block"
                    v-for="(block, index) in blocks"
                    :style="{ order: index + 1 }"
                    :key="block.title"
                    :heading="block.title"
                >
                    <div class="form">
                        <div v-for="(item, key) in block.items" class="form__input" :class="item.class" :key="key">
                            <FormItem v-bind="item.props" :name="key">
                                <component
                                    v-if="key === 'pageStatus'"
                                    :is="item.component"
                                    v-model="currentState"
                                    v-on="getFieldEvents(item.events)"
                                    v-bind="item.props || {}"
                                    :name="key"
                                ></component>
                                <component
                                    v-else
                                    :is="item.component"
                                    :key="key === 'slug' ? slugKey : null"
                                    v-model="currentState[key]"
                                    v-bind="item.props || {}"
                                    v-on="getFieldEvents(item.events)"
                                    @update-field="onUpdateFieldValue"
                                    :name="key"
                                    hide-details
                                    outlined
                                    dense
                                ></component>
                            </FormItem>
                        </div>
                        <form-item
                            v-if="block.title === $t('entities.blocks.seo')"
                            :label="$t('entities.page.fields.googlePreview')"
                            class="form__input"
                        >
                            <MetaPreview
                                :header="metaTitle"
                                :link="`https://${instanceFull.primaryDomain}/catalogs/${currentState.slug}`"
                                :description="metaDescription"
                            />
                        </form-item>
                    </div>
                </headed-block>
                <catalog-related-products-list
                    :ids="currentState.relatedProductsIds"
                    @input="currentState.relatedProductsIds = $event"
                ></catalog-related-products-list>
                <catalog-products-list
                    v-if="catalogId"
                    class="catalog-products-list"
                    @change-sorting="updateSorting"
                    :sortBy="initialState.sortingCriteria"
                ></catalog-products-list>
            </template>
        </template>
    </div>
</template>

<script>
import { cloneDeep, isEqual, debounce, transform, isObject } from 'lodash';
import EventEmitter from '@/helpers/eventEmitter.ts';
import validateField from '@/helpers/validator';
import Catalog from '@/entities/catalog/Catalog';
import CatalogService from '@/services/CatalogService';
import StickyToolbar from '@/components/common/StickyToolbar';
import HeadedBlock from '@/components/common/HeadedBlock';
import ErrorBlock from '@/components/common/ErrorBlock';
import FormItem from '@/components/form/item.vue';
import MetaPreview from '@/components/product/ProductMetaPreview';
import { imageUrl } from '@/helpers/values';
import ImageField from '@/components/form/controls/ImageField';
import CatalogItemSidebar from '@/views/catalog/CatalogItemSidebar';
import CatalogProductsList from '@/components/catalog/CatalogProductsList';
import Button from '@/components/common/Button';
import ProgressCircular from '@/components/common/ProgressCircular';
import Alert from '@/components/common/Alert';
import InputUrl from '@/components/form/controls/InputUrl';
import CatalogRelatedProductsList from '@/components/catalog/CatalogRelatedProductsList';
import { mapGetters } from 'vuex';

export default {
    name: 'CatalogItem',
    components: {
        CatalogRelatedProductsList,
        Alert,
        CatalogProductsList,
        CatalogItemSidebar,
        ImageField,
        ErrorBlock,
        HeadedBlock,
        StickyToolbar,
        // DeletionModal,
        MetaPreview,
        FormItem,
        Button,
        ProgressCircular,
        InputUrl,
    },

    async created() {
        const isCreated = localStorage.getItem('catalogCreated');
        if (isCreated) {
            this.isCreated = true;
            localStorage.removeItem('catalogCreated');
        }
        await this.loadData();
        EventEmitter.on('delete-catalog', this.deleteCatalog);
    },

    beforeDestroy() {
        EventEmitter.off('delete-catalog', this.deleteCatalog);
    },
    data() {
        return {
            isDataLoaded: false,
            isSaveAllowed: false,
            currentState: new Catalog(),
            initialState: null,
            fieldKeys: Object.keys(new Catalog().fields),
            images: [],
            isUpdated: false,
            isCreated: false,
            imageUrl: imageUrl(),
            slugKey: 0,
            isAvailable: true,
        };
    },
    computed: {
        ...mapGetters('config', { sitename: 'getSitename' }),
        ...mapGetters('globalVars', { instanceFull: 'getInstanceFull' }),
        metaAvailableVariables() {
            return {
                '%shop_name%': this.sitename?.value || '',
                '%catalog_name%': this.currentState.title,
            };
        },
        isEditing() {
            return this.$route.name !== 'Create Catalog';
        },
        catalogId() {
            return this.$route.params.id;
        },
        currency() {
            return this.$store.getters['config/getCurrency'];
        },
        seo() {
            return this.$store.getters['config/getSeo'];
        },
        blocks() {
            return [
                {
                    title: this.$t('entities.blocks.generalInformation'),
                    items: {
                        title: this.currentState.fields.title,
                        heading: this.currentState.fields.heading,
                    },
                },
                {
                    title: this.$t('entities.blocks.seo'),
                    items: {
                        metaTitle: this.currentState.fields.metaTitle,
                        metaDescription: this.currentState.fields.metaDescription,
                        slug: {
                            ...this.currentState.fields.slug,
                            props: {
                                ...this.currentState.fields.slug.props,
                                showCheckbox: !!this.catalogId,
                            },
                        },
                    },
                },
            ];
        },
        metaTitle() {
            return this.currentState.metaTitle
                ? this.currentState.metaTitle
                : this.formatMeta(this.seo.templateCatalogTitle.value);
        },
        metaDescription() {
            return this.currentState.metaDescription
                ? this.currentState.metaDescription
                : this.formatMeta(this.seo.templateCatalogDescription.value);
        },
    },
    methods: {
        createRedirectOnSave(redirect) {
            this.initialState.redirectOnSave = redirect;
        },
        formatMeta(string) {
            const reg = /%(.*?)%/g;
            return string.replace(reg, (match) => {
                return this.metaAvailableVariables[match];
            });
        },

        async loadData() {
            if (this.catalogId) {
                const [error, result] = await CatalogService.getOne(this.catalogId);
                if (error) {
                    error.alert();
                    this.isAvailable = false;
                }
                this.currentState = new Catalog(result);
            }
            this.initialState = cloneDeep(this.currentState);
            this.isDataLoaded = true;
        },

        async openDeletionModal() {
            this.$store.dispatch('modals/openDeletionModal', { items: this.currentState, entity: 'catalog' });
        },

        updateSorting(criteria) {
            const newInitialState = cloneDeep(this.initialState);
            newInitialState.sortingCriteria = criteria;
            this.initialState = newInitialState;
            CatalogService.updateOne(newInitialState.data);
        },

        async deleteCatalog(items) {
            const [error] = await CatalogService.removeOne(items[0].id);
            if (error) {
                error.notify();
            }
            this.$store.dispatch('modals/closeDeletionModal');
            this.$router.push({ name: 'Catalog List' });
        },

        // Form methods
        validateForm() {
            let result = true;
            this.fieldKeys.forEach((key) => {
                const errors = validateField(this.currentState[key], this.currentState.fields[key].props.rules);
                if (errors.length !== 0) result = false;
                this.$set(this.currentState.fields[key].props, 'errors', errors);
            });
            if (!result) {
                EventEmitter.trigger('show-noty', {
                    type: 'error',
                    text: this.$t('notifications.validation.error'),
                });
            }
            return result;
        },
        async onUpdateCatalog() {
            const isFormValid = this.validateForm();
            if (isFormValid === false) return;
            const [error, result] = await CatalogService.updateOne(this.currentState.data);
            error ? this.failedHandler(result, error) : await this.successUpdateHandler();
        },
        async onCreateCatalog() {
            const isFormValid = this.validateForm();
            if (isFormValid === false) return;
            const [error, result] = await CatalogService.createOne(this.currentState.data);
            error ? this.failedHandler(result, error) : this.successCreateHandler();
        },
        successCreateHandler() {
            // Редиректим на редактирование созданного каталога
            localStorage.setItem('catalogCreated', true);
            this.isUpdated = false;
            this.$router.push({
                name: 'Edit Catalog',
                params: { id: this.currentState.id },
            });
            window.scrollTo({ top: 0, behavior: 'smooth' });
        },
        async successUpdateHandler() {
            this.isCreated = false;
            this.isUpdated = true;
            this.slugKey++;
            window.scrollTo({ top: 0, behavior: 'smooth' });
            await this.loadData();
            this.isSaveAllowed = false;
        },
        failedHandler(response, error) {
            error.notify();
            const children = response.data?.errors?.children;
            if (children) {
                this.fieldKeys.forEach((key) => {
                    const errors = children[key] ? children[key].errors : [];
                    if (errors) this.$set(this.currentState.fields[key].props, 'errors', errors);
                });
            }
        },
        getFieldEvents(events) {
            const fieldEvents = {};
            for (const key in events) {
                fieldEvents[key] = events[key]?.bind(this.currentState);
            }
            return fieldEvents;
        },
        onUpdateFieldValue: debounce(function(payload) {
            const { name } = payload;
            const errors = validateField(this.currentState[name], this.currentState.fields[name].props.rules);
            this.$set(this.currentState.fields[name].props, 'errors', errors);
        }, 600),
        async onAddMoreOne() {
            this.$router.push({
                name: 'Create Catalog',
            });
            this.currentState = new Catalog();
            this.isCreated = false;
            this.isUpdated = false;
            await this.loadData();
        },
        difference(object, base) {
            function changes(object, base) {
                return transform(object, function(result, value, key) {
                    if (!isEqual(value, base[key])) {
                        result[key] = isObject(value) && isObject(base[key]) ? changes(value, base[key]) : value;
                    }
                });
            }
            return changes(object, base);
        },
        onChangeCatalog(catalog) {
            this.currentState = cloneDeep(catalog);
        },
    },
    watch: {
        currentState: {
            handler(val) {
                if (this.initialState) {
                    this.isSaveAllowed = !isEqual(val.data, this.initialState.data);
                }
            },
            deep: true,
        },
    },
};
</script>

<style lang="scss">
.add-more {
    color: #fff !important;
    text-decoration: underline;
    cursor: pointer;
    &:hover {
        text-decoration: none;
    }
}
.image-block {
    order: 2;
}
.catalog-related-products-list {
    order: 4;
    margin-bottom: 64px;
}
.catalog-products-list {
    order: 5;
}
.edit-content-block {
    order: 2;
    margin-bottom: 64px;
    .block__body {
        display: none;
    }
}
</style>
