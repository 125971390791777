export default [
    {
        name: 'Afghanistan',
        code: 'AF',
        continent: 'Asia',
        phoneNumberPrefix: 93,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Åland Islands',
        code: 'AX',
        continent: 'Europe',
        phoneNumberPrefix: 358,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Albania',
        code: 'AL',
        continent: 'Europe',
        phoneNumberPrefix: 355,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Algeria',
        code: 'DZ',
        continent: 'Africa',
        phoneNumberPrefix: 213,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Andorra',
        code: 'AD',
        continent: 'Europe',
        phoneNumberPrefix: 376,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Angola',
        code: 'AO',
        continent: 'Africa',
        phoneNumberPrefix: 244,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Anguilla',
        code: 'AI',
        continent: 'Central America',
        phoneNumberPrefix: 1,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Antigua & Barbuda',
        code: 'AG',
        continent: 'Central America',
        phoneNumberPrefix: 1,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Argentina',
        code: 'AR',
        continent: 'South America',
        phoneNumberPrefix: 54,
        zones: [
            {
                name: 'Buenos Aires Province',
                code: 'B',
            },
            {
                name: 'Catamarca',
                code: 'K',
            },
            {
                name: 'Chaco',
                code: 'H',
            },
            {
                name: 'Chubut',
                code: 'U',
            },
            {
                name: 'Buenos Aires (Autonomous City)',
                code: 'C',
            },
            {
                name: 'Córdoba',
                code: 'X',
            },
            {
                name: 'Corrientes',
                code: 'W',
            },
            {
                name: 'Entre Ríos',
                code: 'E',
            },
            {
                name: 'Formosa',
                code: 'P',
            },
            {
                name: 'Jujuy',
                code: 'Y',
            },
            {
                name: 'La Pampa',
                code: 'L',
            },
            {
                name: 'La Rioja',
                code: 'F',
            },
            {
                name: 'Mendoza',
                code: 'M',
            },
            {
                name: 'Misiones',
                code: 'N',
            },
            {
                name: 'Neuquén',
                code: 'Q',
            },
            {
                name: 'Río Negro',
                code: 'R',
            },
            {
                name: 'Salta',
                code: 'A',
            },
            {
                name: 'San Juan',
                code: 'J',
            },
            {
                name: 'San Luis',
                code: 'D',
            },
            {
                name: 'Santa Cruz',
                code: 'Z',
            },
            {
                name: 'Santa Fe',
                code: 'S',
            },
            {
                name: 'Santiago del Estero',
                code: 'G',
            },
            {
                name: 'Tierra del Fuego',
                code: 'V',
            },
            {
                name: 'Tucumán',
                code: 'T',
            },
        ],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.province',
        },
    },
    {
        name: 'Armenia',
        code: 'AM',
        continent: 'Europe',
        phoneNumberPrefix: 374,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Aruba',
        code: 'AW',
        continent: 'Central America',
        phoneNumberPrefix: 297,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Ascension Island',
        code: 'AC',
        continent: 'Africa',
        phoneNumberPrefix: 247,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Australia',
        code: 'AU',
        continent: 'Oceania',
        phoneNumberPrefix: 61,
        zones: [
            {
                name: 'Australian Capital Territory',
                code: 'ACT',
            },
            {
                name: 'New South Wales',
                code: 'NSW',
            },
            {
                name: 'Northern Territory',
                code: 'NT',
            },
            {
                name: 'Queensland',
                code: 'QLD',
            },
            {
                name: 'South Australia',
                code: 'SA',
            },
            {
                name: 'Tasmania',
                code: 'TAS',
            },
            {
                name: 'Victoria',
                code: 'VIC',
            },
            {
                name: 'Western Australia',
                code: 'WA',
            },
        ],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'Suburb',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postcode',
            zone: 'entities.location.commonSettings.fields.region.stateTerritory',
        },
    },
    {
        name: 'Austria',
        code: 'AT',
        continent: 'Europe',
        phoneNumberPrefix: 43,
        zones: [],
        labels: {
            address1: 'Street and house number',
            address2: 'Additional address',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Azerbaijan',
        code: 'AZ',
        continent: 'Asia',
        phoneNumberPrefix: 994,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Bahamas',
        code: 'BS',
        continent: 'Central America',
        phoneNumberPrefix: 1,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Bahrain',
        code: 'BH',
        continent: 'Asia',
        phoneNumberPrefix: 973,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Bangladesh',
        code: 'BD',
        continent: 'Asia',
        phoneNumberPrefix: 880,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Barbados',
        code: 'BB',
        continent: 'Central America',
        phoneNumberPrefix: 1,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Belarus',
        code: 'BY',
        continent: 'Europe',
        phoneNumberPrefix: 375,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Belgium',
        code: 'BE',
        continent: 'Europe',
        phoneNumberPrefix: 32,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Belize',
        code: 'BZ',
        continent: 'Central America',
        phoneNumberPrefix: 501,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Benin',
        code: 'BJ',
        continent: 'Africa',
        phoneNumberPrefix: 229,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Bermuda',
        code: 'BM',
        continent: 'Central America',
        phoneNumberPrefix: 1,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Bhutan',
        code: 'BT',
        continent: 'Asia',
        phoneNumberPrefix: 975,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Bolivia',
        code: 'BO',
        continent: 'South America',
        phoneNumberPrefix: 591,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Bosnia & Herzegovina',
        code: 'BA',
        continent: 'Europe',
        phoneNumberPrefix: 387,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Botswana',
        code: 'BW',
        continent: 'Africa',
        phoneNumberPrefix: 267,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Brazil',
        code: 'BR',
        continent: 'South America',
        phoneNumberPrefix: 55,
        zones: [
            {
                name: 'Acre',
                code: 'AC',
            },
            {
                name: 'Alagoas',
                code: 'AL',
            },
            {
                name: 'Amapá',
                code: 'AP',
            },
            {
                name: 'Amazonas',
                code: 'AM',
            },
            {
                name: 'Bahia',
                code: 'BA',
            },
            {
                name: 'Ceará',
                code: 'CE',
            },
            {
                name: 'Federal District',
                code: 'DF',
            },
            {
                name: 'Espírito Santo',
                code: 'ES',
            },
            {
                name: 'Goiás',
                code: 'GO',
            },
            {
                name: 'Maranhão',
                code: 'MA',
            },
            {
                name: 'Mato Grosso',
                code: 'MT',
            },
            {
                name: 'Mato Grosso do Sul',
                code: 'MS',
            },
            {
                name: 'Minas Gerais',
                code: 'MG',
            },
            {
                name: 'Pará',
                code: 'PA',
            },
            {
                name: 'Paraíba',
                code: 'PB',
            },
            {
                name: 'Paraná',
                code: 'PR',
            },
            {
                name: 'Pernambuco',
                code: 'PE',
            },
            {
                name: 'Piauí',
                code: 'PI',
            },
            {
                name: 'Rio Grande do Norte',
                code: 'RN',
            },
            {
                name: 'Rio Grande do Sul',
                code: 'RS',
            },
            {
                name: 'Rio de Janeiro',
                code: 'RJ',
            },
            {
                name: 'Rondônia',
                code: 'RO',
            },
            {
                name: 'Roraima',
                code: 'RR',
            },
            {
                name: 'Santa Catarina',
                code: 'SC',
            },
            {
                name: 'São Paulo',
                code: 'SP',
            },
            {
                name: 'Sergipe',
                code: 'SE',
            },
            {
                name: 'Tocantins',
                code: 'TO',
            },
        ],
        labels: {
            address1: 'Street and house number',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.state',
        },
    },
    {
        name: 'British Indian Ocean Territory',
        code: 'IO',
        continent: 'Asia',
        phoneNumberPrefix: 246,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'British Virgin Islands',
        code: 'VG',
        continent: 'Central America',
        phoneNumberPrefix: 1,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Brunei',
        code: 'BN',
        continent: 'Asia',
        phoneNumberPrefix: 673,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Bulgaria',
        code: 'BG',
        continent: 'Europe',
        phoneNumberPrefix: 359,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Burkina Faso',
        code: 'BF',
        continent: 'Africa',
        phoneNumberPrefix: 226,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Burundi',
        code: 'BI',
        continent: 'Africa',
        phoneNumberPrefix: 257,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Cambodia',
        code: 'KH',
        continent: 'Asia',
        phoneNumberPrefix: 855,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Cameroon',
        code: 'CM',
        continent: 'Africa',
        phoneNumberPrefix: 237,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Canada',
        code: 'CA',
        continent: 'North America',
        phoneNumberPrefix: 1,
        zones: [
            {
                name: 'Alberta',
                code: 'AB',
            },
            {
                name: 'British Columbia',
                code: 'BC',
            },
            {
                name: 'Manitoba',
                code: 'MB',
            },
            {
                name: 'New Brunswick',
                code: 'NB',
            },
            {
                name: 'Newfoundland and Labrador',
                code: 'NL',
            },
            {
                name: 'Northwest Territories',
                code: 'NT',
            },
            {
                name: 'Nova Scotia',
                code: 'NS',
            },
            {
                name: 'Nunavut',
                code: 'NU',
            },
            {
                name: 'Ontario',
                code: 'ON',
            },
            {
                name: 'Prince Edward Island',
                code: 'PE',
            },
            {
                name: 'Quebec',
                code: 'QC',
            },
            {
                name: 'Saskatchewan',
                code: 'SK',
            },
            {
                name: 'Yukon',
                code: 'YT',
            },
        ],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.province',
        },
    },
    {
        name: 'Cape Verde',
        code: 'CV',
        continent: 'Africa',
        phoneNumberPrefix: 238,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Caribbean Netherlands',
        code: 'BQ',
        continent: 'South America',
        phoneNumberPrefix: 599,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Cayman Islands',
        code: 'KY',
        continent: 'Central America',
        phoneNumberPrefix: 1,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Central African Republic',
        code: 'CF',
        continent: 'Africa',
        phoneNumberPrefix: 236,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Chad',
        code: 'TD',
        continent: 'Africa',
        phoneNumberPrefix: 235,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Chile',
        code: 'CL',
        continent: 'South America',
        phoneNumberPrefix: 56,
        zones: [
            {
                name: 'Arica y Parinacota',
                code: 'AP',
            },
            {
                name: 'Tarapacá',
                code: 'TA',
            },
            {
                name: 'Antofagasta',
                code: 'AN',
            },
            {
                name: 'Atacama',
                code: 'AT',
            },
            {
                name: 'Coquimbo',
                code: 'CO',
            },
            {
                name: 'Valparaíso',
                code: 'VS',
            },
            {
                name: 'Santiago Metropolitan',
                code: 'RM',
            },
            {
                name: 'Libertador General Bernardo O’Higgins',
                code: 'LI',
            },
            {
                name: 'Maule',
                code: 'ML',
            },
            {
                name: 'Ñuble',
                code: 'NB',
            },
            {
                name: 'Bío Bío',
                code: 'BI',
            },
            {
                name: 'Araucanía',
                code: 'AR',
            },
            {
                name: 'Los Ríos',
                code: 'LR',
            },
            {
                name: 'Los Lagos',
                code: 'LL',
            },
            {
                name: 'Aysén',
                code: 'AI',
            },
            {
                name: 'Magallanes Region',
                code: 'MA',
            },
        ],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'China',
        code: 'CN',
        continent: 'Asia',
        phoneNumberPrefix: 86,
        zones: [
            {
                name: 'Anhui',
                code: 'AH',
            },
            {
                name: 'Beijing',
                code: 'BJ',
            },
            {
                name: 'Chongqing',
                code: 'CQ',
            },
            {
                name: 'Fujian',
                code: 'FJ',
            },
            {
                name: 'Gansu',
                code: 'GS',
            },
            {
                name: 'Guangdong',
                code: 'GD',
            },
            {
                name: 'Guangxi',
                code: 'GX',
            },
            {
                name: 'Guizhou',
                code: 'GZ',
            },
            {
                name: 'Hainan',
                code: 'HI',
            },
            {
                name: 'Hebei',
                code: 'HE',
            },
            {
                name: 'Heilongjiang',
                code: 'HL',
            },
            {
                name: 'Henan',
                code: 'HA',
            },
            {
                name: 'Hubei',
                code: 'HB',
            },
            {
                name: 'Hunan',
                code: 'HN',
            },
            {
                name: 'Inner Mongolia',
                code: 'NM',
            },
            {
                name: 'Jiangsu',
                code: 'JS',
            },
            {
                name: 'Jiangxi',
                code: 'JX',
            },
            {
                name: 'Jilin',
                code: 'JL',
            },
            {
                name: 'Liaoning',
                code: 'LN',
            },
            {
                name: 'Ningxia',
                code: 'NX',
            },
            {
                name: 'Qinghai',
                code: 'QH',
            },
            {
                name: 'Shaanxi',
                code: 'SN',
            },
            {
                name: 'Shandong',
                code: 'SD',
            },
            {
                name: 'Shanghai',
                code: 'SH',
            },
            {
                name: 'Shanxi',
                code: 'SX',
            },
            {
                name: 'Sichuan',
                code: 'SC',
            },
            {
                name: 'Tianjin',
                code: 'TJ',
            },
            {
                name: 'Xinjiang',
                code: 'XJ',
            },
            {
                name: 'Tibet',
                code: 'YZ',
            },
            {
                name: 'Yunnan',
                code: 'YN',
            },
            {
                name: 'Zhejiang',
                code: 'ZJ',
            },
        ],
        labels: {
            address1: 'Full address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.province',
        },
    },
    {
        name: 'Christmas Island',
        code: 'CX',
        continent: 'Asia',
        phoneNumberPrefix: 61,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Cocos (Keeling) Islands',
        code: 'CC',
        continent: 'Asia',
        phoneNumberPrefix: 891,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Colombia',
        code: 'CO',
        continent: 'South America',
        phoneNumberPrefix: 57,
        zones: [
            {
                name: 'Capital District',
                code: 'DC',
            },
            {
                name: 'Amazonas',
                code: 'AMA',
            },
            {
                name: 'Antioquia',
                code: 'ANT',
            },
            {
                name: 'Arauca',
                code: 'ARA',
            },
            {
                name: 'Atlántico',
                code: 'ATL',
            },
            {
                name: 'Bolívar',
                code: 'BOL',
            },
            {
                name: 'Boyacá',
                code: 'BOY',
            },
            {
                name: 'Caldas',
                code: 'CAL',
            },
            {
                name: 'Caquetá',
                code: 'CAQ',
            },
            {
                name: 'Casanare',
                code: 'CAS',
            },
            {
                name: 'Cauca',
                code: 'CAU',
            },
            {
                name: 'Cesar',
                code: 'CES',
            },
            {
                name: 'Chocó',
                code: 'CHO',
            },
            {
                name: 'Córdoba',
                code: 'COR',
            },
            {
                name: 'Cundinamarca',
                code: 'CUN',
            },
            {
                name: 'Guainía',
                code: 'GUA',
            },
            {
                name: 'Guaviare',
                code: 'GUV',
            },
            {
                name: 'Huila',
                code: 'HUI',
            },
            {
                name: 'La Guajira',
                code: 'LAG',
            },
            {
                name: 'Magdalena',
                code: 'MAG',
            },
            {
                name: 'Meta',
                code: 'MET',
            },
            {
                name: 'Nariño',
                code: 'NAR',
            },
            {
                name: 'Norte de Santander',
                code: 'NSA',
            },
            {
                name: 'Putumayo',
                code: 'PUT',
            },
            {
                name: 'Quindío',
                code: 'QUI',
            },
            {
                name: 'Risaralda',
                code: 'RIS',
            },
            {
                name: 'San Andrés & Providencia',
                code: 'SAP',
            },
            {
                name: 'Santander',
                code: 'SAN',
            },
            {
                name: 'Sucre',
                code: 'SUC',
            },
            {
                name: 'Tolima',
                code: 'TOL',
            },
            {
                name: 'Valle del Cauca',
                code: 'VAC',
            },
            {
                name: 'Vaupés',
                code: 'VAU',
            },
            {
                name: 'Vichada',
                code: 'VID',
            },
        ],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.province',
        },
    },
    {
        name: 'Comoros',
        code: 'KM',
        continent: 'Africa',
        phoneNumberPrefix: 269,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Congo - Brazzaville',
        code: 'CG',
        continent: 'Africa',
        phoneNumberPrefix: 243,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Congo - Kinshasa',
        code: 'CD',
        continent: 'Africa',
        phoneNumberPrefix: 243,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Cook Islands',
        code: 'CK',
        continent: 'Oceania',
        phoneNumberPrefix: 682,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Costa Rica',
        code: 'CR',
        continent: 'Central America',
        phoneNumberPrefix: 506,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Croatia',
        code: 'HR',
        continent: 'Europe',
        phoneNumberPrefix: 385,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Curaçao',
        code: 'CW',
        continent: 'Central America',
        phoneNumberPrefix: 599,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Cyprus',
        code: 'CY',
        continent: 'Europe',
        phoneNumberPrefix: 357,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Czechia',
        code: 'CZ',
        continent: 'Europe',
        phoneNumberPrefix: 420,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Côte d’Ivoire',
        code: 'CI',
        continent: 'Africa',
        phoneNumberPrefix: 225,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Denmark',
        code: 'DK',
        continent: 'Europe',
        phoneNumberPrefix: 45,
        zones: [],
        labels: {
            address1: 'Street and house number',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Djibouti',
        code: 'DJ',
        continent: 'Africa',
        phoneNumberPrefix: 253,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Dominica',
        code: 'DM',
        continent: 'Central America',
        phoneNumberPrefix: 1,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Dominican Republic',
        code: 'DO',
        continent: 'Central America',
        phoneNumberPrefix: 1,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Ecuador',
        code: 'EC',
        continent: 'South America',
        phoneNumberPrefix: 593,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Egypt',
        code: 'EG',
        continent: 'Africa',
        phoneNumberPrefix: 20,
        zones: [
            {
                name: '6th of October',
                code: 'SU',
            },
            {
                name: 'Al Sharqia',
                code: 'SHR',
            },
            {
                name: 'Alexandria',
                code: 'ALX',
            },
            {
                name: 'Aswan',
                code: 'ASN',
            },
            {
                name: 'Asyut',
                code: 'AST',
            },
            {
                name: 'Beheira',
                code: 'BH',
            },
            {
                name: 'Beni Suef',
                code: 'BNS',
            },
            {
                name: 'Cairo',
                code: 'C',
            },
            {
                name: 'Dakahlia',
                code: 'DK',
            },
            {
                name: 'Damietta',
                code: 'DT',
            },
            {
                name: 'Faiyum',
                code: 'FYM',
            },
            {
                name: 'Gharbia',
                code: 'GH',
            },
            {
                name: 'Giza',
                code: 'GZ',
            },
            {
                name: 'Helwan',
                code: 'HU',
            },
            {
                name: 'Ismailia',
                code: 'IS',
            },
            {
                name: 'Kafr el-Sheikh',
                code: 'KFS',
            },
            {
                name: 'Luxor',
                code: 'LX',
            },
            {
                name: 'Matrouh',
                code: 'MT',
            },
            {
                name: 'Minya',
                code: 'MN',
            },
            {
                name: 'Monufia',
                code: 'MNF',
            },
            {
                name: 'New Valley',
                code: 'WAD',
            },
            {
                name: 'North Sinai',
                code: 'SIN',
            },
            {
                name: 'Port Said',
                code: 'PTS',
            },
            {
                name: 'Qalyubia',
                code: 'KB',
            },
            {
                name: 'Qena',
                code: 'KN',
            },
            {
                name: 'Red Sea',
                code: 'BA',
            },
            {
                name: 'Sohag',
                code: 'SHG',
            },
            {
                name: 'South Sinai',
                code: 'JS',
            },
            {
                name: 'Suez',
                code: 'SUZ',
            },
        ],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.governorate',
        },
    },
    {
        name: 'El Salvador',
        code: 'SV',
        continent: 'Central America',
        phoneNumberPrefix: 503,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Equatorial Guinea',
        code: 'GQ',
        continent: 'Africa',
        phoneNumberPrefix: 240,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Eritrea',
        code: 'ER',
        continent: 'Africa',
        phoneNumberPrefix: 291,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Estonia',
        code: 'EE',
        continent: 'Europe',
        phoneNumberPrefix: 372,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Eswatini',
        code: 'SZ',
        continent: 'Africa',
        phoneNumberPrefix: 268,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Ethiopia',
        code: 'ET',
        continent: 'Africa',
        phoneNumberPrefix: 251,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Falkland Islands',
        code: 'FK',
        continent: 'South America',
        phoneNumberPrefix: 500,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Faroe Islands',
        code: 'FO',
        continent: 'Europe',
        phoneNumberPrefix: 298,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Fiji',
        code: 'FJ',
        continent: 'Oceania',
        phoneNumberPrefix: 679,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Finland',
        code: 'FI',
        continent: 'Europe',
        phoneNumberPrefix: 358,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'France',
        code: 'FR',
        continent: 'Europe',
        phoneNumberPrefix: 33,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'French Guiana',
        code: 'GF',
        continent: 'South America',
        phoneNumberPrefix: 594,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'French Polynesia',
        code: 'PF',
        continent: 'Oceania',
        phoneNumberPrefix: 689,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'French Southern Territories',
        code: 'TF',
        continent: 'Other',
        phoneNumberPrefix: 262,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Gabon',
        code: 'GA',
        continent: 'Africa',
        phoneNumberPrefix: 241,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Gambia',
        code: 'GM',
        continent: 'Africa',
        phoneNumberPrefix: 220,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Georgia',
        code: 'GE',
        continent: 'Europe',
        phoneNumberPrefix: 995,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Germany',
        code: 'DE',
        continent: 'Europe',
        phoneNumberPrefix: 49,
        zones: [],
        labels: {
            address1: 'Street and house number',
            address2: 'Additional address',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Ghana',
        code: 'GH',
        continent: 'Africa',
        phoneNumberPrefix: 233,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Gibraltar',
        code: 'GI',
        continent: 'Europe',
        phoneNumberPrefix: 350,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Greece',
        code: 'GR',
        continent: 'Europe',
        phoneNumberPrefix: 30,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Greenland',
        code: 'GL',
        continent: 'Europe',
        phoneNumberPrefix: 299,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Grenada',
        code: 'GD',
        continent: 'Central America',
        phoneNumberPrefix: 1,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Guadeloupe',
        code: 'GP',
        continent: 'Europe',
        phoneNumberPrefix: 590,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Guatemala',
        code: 'GT',
        continent: 'Central America',
        phoneNumberPrefix: 502,
        zones: [
            {
                name: 'Alta Verapaz',
                code: 'AVE',
            },
            {
                name: 'Baja Verapaz',
                code: 'BVE',
            },
            {
                name: 'Chimaltenango',
                code: 'CMT',
            },
            {
                name: 'Chiquimula',
                code: 'CQM',
            },
            {
                name: 'El Progreso',
                code: 'EPR',
            },
            {
                name: 'Escuintla',
                code: 'ESC',
            },
            {
                name: 'Guatemala',
                code: 'GUA',
            },
            {
                name: 'Huehuetenango',
                code: 'HUE',
            },
            {
                name: 'Izabal',
                code: 'IZA',
            },
            {
                name: 'Jalapa',
                code: 'JAL',
            },
            {
                name: 'Jutiapa',
                code: 'JUT',
            },
            {
                name: 'Petén',
                code: 'PET',
            },
            {
                name: 'Quetzaltenango',
                code: 'QUE',
            },
            {
                name: 'Quiché',
                code: 'QUI',
            },
            {
                name: 'Retalhuleu',
                code: 'RET',
            },
            {
                name: 'Sacatepéquez',
                code: 'SAC',
            },
            {
                name: 'San Marcos',
                code: 'SMA',
            },
            {
                name: 'Santa Rosa',
                code: 'SRO',
            },
            {
                name: 'Sololá',
                code: 'SOL',
            },
            {
                name: 'Suchitepéquez',
                code: 'SUC',
            },
            {
                name: 'Totonicapán',
                code: 'TOT',
            },
            {
                name: 'Zacapa',
                code: 'ZAC',
            },
        ],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Guernsey',
        code: 'GG',
        continent: 'Europe',
        phoneNumberPrefix: 44,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Guinea',
        code: 'GN',
        continent: 'Africa',
        phoneNumberPrefix: 224,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Guinea-Bissau',
        code: 'GW',
        continent: 'Africa',
        phoneNumberPrefix: 245,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Guyana',
        code: 'GY',
        continent: 'South America',
        phoneNumberPrefix: 592,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Haiti',
        code: 'HT',
        continent: 'Central America',
        phoneNumberPrefix: 509,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Honduras',
        code: 'HN',
        continent: 'Central America',
        phoneNumberPrefix: 504,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Hong Kong SAR',
        code: 'HK',
        continent: 'Asia',
        phoneNumberPrefix: 852,
        zones: [
            {
                name: 'Hong Kong Island',
                code: 'HK',
            },
            {
                name: 'Kowloon',
                code: 'KL',
            },
            {
                name: 'New Territories',
                code: 'NT',
            },
        ],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'District',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Hungary',
        code: 'HU',
        continent: 'Europe',
        phoneNumberPrefix: 36,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Iceland',
        code: 'IS',
        continent: 'Europe',
        phoneNumberPrefix: 354,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'India',
        code: 'IN',
        continent: 'Asia',
        phoneNumberPrefix: 91,
        zones: [
            {
                name: 'Andaman and Nicobar Islands',
                code: 'AN',
            },
            {
                name: 'Andhra Pradesh',
                code: 'AP',
            },
            {
                name: 'Arunachal Pradesh',
                code: 'AR',
            },
            {
                name: 'Assam',
                code: 'AS',
            },
            {
                name: 'Bihar',
                code: 'BR',
            },
            {
                name: 'Chandigarh',
                code: 'CH',
            },
            {
                name: 'Chhattisgarh',
                code: 'CG',
            },
            {
                name: 'Dadra and Nagar Haveli',
                code: 'DN',
            },
            {
                name: 'Daman and Diu',
                code: 'DD',
            },
            {
                name: 'Delhi',
                code: 'DL',
            },
            {
                name: 'Goa',
                code: 'GA',
            },
            {
                name: 'Gujarat',
                code: 'GJ',
            },
            {
                name: 'Haryana',
                code: 'HR',
            },
            {
                name: 'Himachal Pradesh',
                code: 'HP',
            },
            {
                name: 'Jammu and Kashmir',
                code: 'JK',
            },
            {
                name: 'Jharkhand',
                code: 'JH',
            },
            {
                name: 'Karnataka',
                code: 'KA',
            },
            {
                name: 'Kerala',
                code: 'KL',
            },
            {
                name: 'Ladakh',
                code: 'LA',
            },
            {
                name: 'Lakshadweep',
                code: 'LD',
            },
            {
                name: 'Madhya Pradesh',
                code: 'MP',
            },
            {
                name: 'Maharashtra',
                code: 'MH',
            },
            {
                name: 'Manipur',
                code: 'MN',
            },
            {
                name: 'Meghalaya',
                code: 'ML',
            },
            {
                name: 'Mizoram',
                code: 'MZ',
            },
            {
                name: 'Nagaland',
                code: 'NL',
            },
            {
                name: 'Odisha',
                code: 'OR',
            },
            {
                name: 'Puducherry',
                code: 'PY',
            },
            {
                name: 'Punjab',
                code: 'PB',
            },
            {
                name: 'Rajasthan',
                code: 'RJ',
            },
            {
                name: 'Sikkim',
                code: 'SK',
            },
            {
                name: 'Tamil Nadu',
                code: 'TN',
            },
            {
                name: 'Telangana',
                code: 'TS',
            },
            {
                name: 'Tripura',
                code: 'TR',
            },
            {
                name: 'Uttar Pradesh',
                code: 'UP',
            },
            {
                name: 'Uttarakhand',
                code: 'UK',
            },
            {
                name: 'West Bengal',
                code: 'WB',
            },
        ],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.pinCode',
            zone: 'entities.location.commonSettings.fields.region.state',
        },
    },
    {
        name: 'Indonesia',
        code: 'ID',
        continent: 'Asia',
        phoneNumberPrefix: 62,
        zones: [
            {
                name: 'Aceh',
                code: 'AC',
            },
            {
                name: 'Bali',
                code: 'BA',
            },
            {
                name: 'Bangka–Belitung Islands',
                code: 'BB',
            },
            {
                name: 'Banten',
                code: 'BT',
            },
            {
                name: 'Bengkulu',
                code: 'BE',
            },
            {
                name: 'Gorontalo',
                code: 'GO',
            },
            {
                name: 'Jakarta',
                code: 'JK',
            },
            {
                name: 'Jambi',
                code: 'JA',
            },
            {
                name: 'West Java',
                code: 'JB',
            },
            {
                name: 'Central Java',
                code: 'JT',
            },
            {
                name: 'East Java',
                code: 'JI',
            },
            {
                name: 'West Kalimantan',
                code: 'KB',
            },
            {
                name: 'South Kalimantan',
                code: 'KS',
            },
            {
                name: 'Central Kalimantan',
                code: 'KT',
            },
            {
                name: 'East Kalimantan',
                code: 'KI',
            },
            {
                name: 'North Kalimantan',
                code: 'KU',
            },
            {
                name: 'Riau Islands',
                code: 'KR',
            },
            {
                name: 'Lampung',
                code: 'LA',
            },
            {
                name: 'Maluku',
                code: 'MA',
            },
            {
                name: 'North Maluku',
                code: 'MU',
            },
            {
                name: 'North Sumatra',
                code: 'SU',
            },
            {
                name: 'West Nusa Tenggara',
                code: 'NB',
            },
            {
                name: 'East Nusa Tenggara',
                code: 'NT',
            },
            {
                name: 'Papua',
                code: 'PA',
            },
            {
                name: 'West Papua',
                code: 'PB',
            },
            {
                name: 'Riau',
                code: 'RI',
            },
            {
                name: 'South Sumatra',
                code: 'SS',
            },
            {
                name: 'West Sulawesi',
                code: 'SR',
            },
            {
                name: 'South Sulawesi',
                code: 'SN',
            },
            {
                name: 'Central Sulawesi',
                code: 'ST',
            },
            {
                name: 'Southeast Sulawesi',
                code: 'SG',
            },
            {
                name: 'North Sulawesi',
                code: 'SA',
            },
            {
                name: 'West Sumatra',
                code: 'SB',
            },
            {
                name: 'Yogyakarta',
                code: 'YO',
            },
        ],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.province',
        },
    },
    {
        name: 'Iraq',
        code: 'IQ',
        continent: 'Asia',
        phoneNumberPrefix: 964,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Ireland',
        code: 'IE',
        continent: 'Europe',
        phoneNumberPrefix: 353,
        zones: [
            {
                name: 'Carlow',
                code: 'CW',
            },
            {
                name: 'Cavan',
                code: 'CN',
            },
            {
                name: 'Clare',
                code: 'CE',
            },
            {
                name: 'Cork',
                code: 'CO',
            },
            {
                name: 'Donegal',
                code: 'DL',
            },
            {
                name: 'Dublin',
                code: 'D',
            },
            {
                name: 'Galway',
                code: 'G',
            },
            {
                name: 'Kerry',
                code: 'KY',
            },
            {
                name: 'Kildare',
                code: 'KE',
            },
            {
                name: 'Kilkenny',
                code: 'KK',
            },
            {
                name: 'Laois',
                code: 'LS',
            },
            {
                name: 'Leitrim',
                code: 'LM',
            },
            {
                name: 'Limerick',
                code: 'LK',
            },
            {
                name: 'Longford',
                code: 'LD',
            },
            {
                name: 'Louth',
                code: 'LH',
            },
            {
                name: 'Mayo',
                code: 'MO',
            },
            {
                name: 'Meath',
                code: 'MH',
            },
            {
                name: 'Monaghan',
                code: 'MN',
            },
            {
                name: 'Offaly',
                code: 'OY',
            },
            {
                name: 'Roscommon',
                code: 'RN',
            },
            {
                name: 'Sligo',
                code: 'SO',
            },
            {
                name: 'Tipperary',
                code: 'TA',
            },
            {
                name: 'Waterford',
                code: 'WD',
            },
            {
                name: 'Westmeath',
                code: 'WH',
            },
            {
                name: 'Wexford',
                code: 'WX',
            },
            {
                name: 'Wicklow',
                code: 'WW',
            },
        ],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.county',
        },
    },
    {
        name: 'Isle of Man',
        code: 'IM',
        continent: 'Europe',
        phoneNumberPrefix: 44,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Israel',
        code: 'IL',
        continent: 'Asia',
        phoneNumberPrefix: 972,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Italy',
        code: 'IT',
        continent: 'Europe',
        phoneNumberPrefix: 39,
        zones: [
            {
                name: 'Agrigento',
                code: 'AG',
            },
            {
                name: 'Alessandria',
                code: 'AL',
            },
            {
                name: 'Ancona',
                code: 'AN',
            },
            {
                name: 'Aosta Valley',
                code: 'AO',
            },
            {
                name: 'Arezzo',
                code: 'AR',
            },
            {
                name: 'Ascoli Piceno',
                code: 'AP',
            },
            {
                name: 'Asti',
                code: 'AT',
            },
            {
                name: 'Avellino',
                code: 'AV',
            },
            {
                name: 'Bari',
                code: 'BA',
            },
            {
                name: 'Barletta-Andria-Trani',
                code: 'BT',
            },
            {
                name: 'Belluno',
                code: 'BL',
            },
            {
                name: 'Benevento',
                code: 'BN',
            },
            {
                name: 'Bergamo',
                code: 'BG',
            },
            {
                name: 'Biella',
                code: 'BI',
            },
            {
                name: 'Bologna',
                code: 'BO',
            },
            {
                name: 'South Tyrol',
                code: 'BZ',
            },
            {
                name: 'Brescia',
                code: 'BS',
            },
            {
                name: 'Brindisi',
                code: 'BR',
            },
            {
                name: 'Cagliari',
                code: 'CA',
            },
            {
                name: 'Caltanissetta',
                code: 'CL',
            },
            {
                name: 'Campobasso',
                code: 'CB',
            },
            {
                name: 'Carbonia-Iglesias',
                code: 'CI',
            },
            {
                name: 'Caserta',
                code: 'CE',
            },
            {
                name: 'Catania',
                code: 'CT',
            },
            {
                name: 'Catanzaro',
                code: 'CZ',
            },
            {
                name: 'Chieti',
                code: 'CH',
            },
            {
                name: 'Como',
                code: 'CO',
            },
            {
                name: 'Cosenza',
                code: 'CS',
            },
            {
                name: 'Cremona',
                code: 'CR',
            },
            {
                name: 'Crotone',
                code: 'KR',
            },
            {
                name: 'Cuneo',
                code: 'CN',
            },
            {
                name: 'Enna',
                code: 'EN',
            },
            {
                name: 'Fermo',
                code: 'FM',
            },
            {
                name: 'Ferrara',
                code: 'FE',
            },
            {
                name: 'Florence',
                code: 'FI',
            },
            {
                name: 'Foggia',
                code: 'FG',
            },
            {
                name: 'Forlì-Cesena',
                code: 'FC',
            },
            {
                name: 'Frosinone',
                code: 'FR',
            },
            {
                name: 'Genoa',
                code: 'GE',
            },
            {
                name: 'Gorizia',
                code: 'GO',
            },
            {
                name: 'Grosseto',
                code: 'GR',
            },
            {
                name: 'Imperia',
                code: 'IM',
            },
            {
                name: 'Isernia',
                code: 'IS',
            },
            {
                name: 'L’Aquila',
                code: 'AQ',
            },
            {
                name: 'La Spezia',
                code: 'SP',
            },
            {
                name: 'Latina',
                code: 'LT',
            },
            {
                name: 'Lecce',
                code: 'LE',
            },
            {
                name: 'Lecco',
                code: 'LC',
            },
            {
                name: 'Livorno',
                code: 'LI',
            },
            {
                name: 'Lodi',
                code: 'LO',
            },
            {
                name: 'Lucca',
                code: 'LU',
            },
            {
                name: 'Macerata',
                code: 'MC',
            },
            {
                name: 'Mantua',
                code: 'MN',
            },
            {
                name: 'Massa and Carrara',
                code: 'MS',
            },
            {
                name: 'Matera',
                code: 'MT',
            },
            {
                name: 'Medio Campidano',
                code: 'VS',
            },
            {
                name: 'Messina',
                code: 'ME',
            },
            {
                name: 'Milan',
                code: 'MI',
            },
            {
                name: 'Modena',
                code: 'MO',
            },
            {
                name: 'Monza and Brianza',
                code: 'MB',
            },
            {
                name: 'Naples',
                code: 'NA',
            },
            {
                name: 'Novara',
                code: 'NO',
            },
            {
                name: 'Nuoro',
                code: 'NU',
            },
            {
                name: 'Ogliastra',
                code: 'OG',
            },
            {
                name: 'Olbia-Tempio',
                code: 'OT',
            },
            {
                name: 'Oristano',
                code: 'OR',
            },
            {
                name: 'Padua',
                code: 'PD',
            },
            {
                name: 'Palermo',
                code: 'PA',
            },
            {
                name: 'Parma',
                code: 'PR',
            },
            {
                name: 'Pavia',
                code: 'PV',
            },
            {
                name: 'Perugia',
                code: 'PG',
            },
            {
                name: 'Pesaro and Urbino',
                code: 'PU',
            },
            {
                name: 'Pescara',
                code: 'PE',
            },
            {
                name: 'Piacenza',
                code: 'PC',
            },
            {
                name: 'Pisa',
                code: 'PI',
            },
            {
                name: 'Pistoia',
                code: 'PT',
            },
            {
                name: 'Pordenone',
                code: 'PN',
            },
            {
                name: 'Potenza',
                code: 'PZ',
            },
            {
                name: 'Prato',
                code: 'PO',
            },
            {
                name: 'Ragusa',
                code: 'RG',
            },
            {
                name: 'Ravenna',
                code: 'RA',
            },
            {
                name: 'Reggio Calabria',
                code: 'RC',
            },
            {
                name: 'Reggio Emilia',
                code: 'RE',
            },
            {
                name: 'Rieti',
                code: 'RI',
            },
            {
                name: 'Rimini',
                code: 'RN',
            },
            {
                name: 'Rome',
                code: 'RM',
            },
            {
                name: 'Rovigo',
                code: 'RO',
            },
            {
                name: 'Salerno',
                code: 'SA',
            },
            {
                name: 'Sassari',
                code: 'SS',
            },
            {
                name: 'Savona',
                code: 'SV',
            },
            {
                name: 'Siena',
                code: 'SI',
            },
            {
                name: 'Syracuse',
                code: 'SR',
            },
            {
                name: 'Sondrio',
                code: 'SO',
            },
            {
                name: 'Taranto',
                code: 'TA',
            },
            {
                name: 'Teramo',
                code: 'TE',
            },
            {
                name: 'Terni',
                code: 'TR',
            },
            {
                name: 'Turin',
                code: 'TO',
            },
            {
                name: 'Trapani',
                code: 'TP',
            },
            {
                name: 'Trentino',
                code: 'TN',
            },
            {
                name: 'Treviso',
                code: 'TV',
            },
            {
                name: 'Trieste',
                code: 'TS',
            },
            {
                name: 'Udine',
                code: 'UD',
            },
            {
                name: 'Varese',
                code: 'VA',
            },
            {
                name: 'Venice',
                code: 'VE',
            },
            {
                name: 'Verbano-Cusio-Ossola',
                code: 'VB',
            },
            {
                name: 'Vercelli',
                code: 'VC',
            },
            {
                name: 'Verona',
                code: 'VR',
            },
            {
                name: 'Vibo Valentia',
                code: 'VV',
            },
            {
                name: 'Vicenza',
                code: 'VI',
            },
            {
                name: 'Viterbo',
                code: 'VT',
            },
        ],
        labels: {
            address1: 'Street and house number',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.province',
        },
    },
    {
        name: 'Jamaica',
        code: 'JM',
        continent: 'Central America',
        phoneNumberPrefix: 1,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Japan',
        code: 'JP',
        continent: 'Asia',
        phoneNumberPrefix: 81,
        zones: [
            {
                name: 'Hokkaido',
                code: 'JP-01',
            },
            {
                name: 'Aomori',
                code: 'JP-02',
            },
            {
                name: 'Iwate',
                code: 'JP-03',
            },
            {
                name: 'Miyagi',
                code: 'JP-04',
            },
            {
                name: 'Akita',
                code: 'JP-05',
            },
            {
                name: 'Yamagata',
                code: 'JP-06',
            },
            {
                name: 'Fukushima',
                code: 'JP-07',
            },
            {
                name: 'Ibaraki',
                code: 'JP-08',
            },
            {
                name: 'Tochigi',
                code: 'JP-09',
            },
            {
                name: 'Gunma',
                code: 'JP-10',
            },
            {
                name: 'Saitama',
                code: 'JP-11',
            },
            {
                name: 'Chiba',
                code: 'JP-12',
            },
            {
                name: 'Tokyo',
                code: 'JP-13',
            },
            {
                name: 'Kanagawa',
                code: 'JP-14',
            },
            {
                name: 'Niigata',
                code: 'JP-15',
            },
            {
                name: 'Toyama',
                code: 'JP-16',
            },
            {
                name: 'Ishikawa',
                code: 'JP-17',
            },
            {
                name: 'Fukui',
                code: 'JP-18',
            },
            {
                name: 'Yamanashi',
                code: 'JP-19',
            },
            {
                name: 'Nagano',
                code: 'JP-20',
            },
            {
                name: 'Gifu',
                code: 'JP-21',
            },
            {
                name: 'Shizuoka',
                code: 'JP-22',
            },
            {
                name: 'Aichi',
                code: 'JP-23',
            },
            {
                name: 'Mie',
                code: 'JP-24',
            },
            {
                name: 'Shiga',
                code: 'JP-25',
            },
            {
                name: 'Kyoto',
                code: 'JP-26',
            },
            {
                name: 'Osaka',
                code: 'JP-27',
            },
            {
                name: 'Hyogo',
                code: 'JP-28',
            },
            {
                name: 'Nara',
                code: 'JP-29',
            },
            {
                name: 'Wakayama',
                code: 'JP-30',
            },
            {
                name: 'Tottori',
                code: 'JP-31',
            },
            {
                name: 'Shimane',
                code: 'JP-32',
            },
            {
                name: 'Okayama',
                code: 'JP-33',
            },
            {
                name: 'Hiroshima',
                code: 'JP-34',
            },
            {
                name: 'Yamaguchi',
                code: 'JP-35',
            },
            {
                name: 'Tokushima',
                code: 'JP-36',
            },
            {
                name: 'Kagawa',
                code: 'JP-37',
            },
            {
                name: 'Ehime',
                code: 'JP-38',
            },
            {
                name: 'Kochi',
                code: 'JP-39',
            },
            {
                name: 'Fukuoka',
                code: 'JP-40',
            },
            {
                name: 'Saga',
                code: 'JP-41',
            },
            {
                name: 'Nagasaki',
                code: 'JP-42',
            },
            {
                name: 'Kumamoto',
                code: 'JP-43',
            },
            {
                name: 'Oita',
                code: 'JP-44',
            },
            {
                name: 'Miyazaki',
                code: 'JP-45',
            },
            {
                name: 'Kagoshima',
                code: 'JP-46',
            },
            {
                name: 'Okinawa',
                code: 'JP-47',
            },
        ],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City/ward/town/village',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.prefecture',
        },
    },
    {
        name: 'Jersey',
        code: 'JE',
        continent: 'Europe',
        phoneNumberPrefix: 44,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Jordan',
        code: 'JO',
        continent: 'Asia',
        phoneNumberPrefix: 962,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Kazakhstan',
        code: 'KZ',
        continent: 'Asia',
        phoneNumberPrefix: 7,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Kenya',
        code: 'KE',
        continent: 'Africa',
        phoneNumberPrefix: 254,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Kiribati',
        code: 'KI',
        continent: 'Oceania',
        phoneNumberPrefix: 686,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Kosovo',
        code: 'XK',
        continent: 'Europe',
        phoneNumberPrefix: 383,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Kuwait',
        code: 'KW',
        continent: 'Asia',
        phoneNumberPrefix: 965,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Kyrgyzstan',
        code: 'KG',
        continent: 'Asia',
        phoneNumberPrefix: 996,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Laos',
        code: 'LA',
        continent: 'Asia',
        phoneNumberPrefix: 856,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Latvia',
        code: 'LV',
        continent: 'Europe',
        phoneNumberPrefix: 371,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Lebanon',
        code: 'LB',
        continent: 'Asia',
        phoneNumberPrefix: 961,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Lesotho',
        code: 'LS',
        continent: 'Africa',
        phoneNumberPrefix: 266,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Liberia',
        code: 'LR',
        continent: 'Africa',
        phoneNumberPrefix: 231,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Libya',
        code: 'LY',
        continent: 'Africa',
        phoneNumberPrefix: 218,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Liechtenstein',
        code: 'LI',
        continent: 'Europe',
        phoneNumberPrefix: 423,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Lithuania',
        code: 'LT',
        continent: 'Europe',
        phoneNumberPrefix: 370,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Luxembourg',
        code: 'LU',
        continent: 'Europe',
        phoneNumberPrefix: 352,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Macao SAR',
        code: 'MO',
        continent: 'Asia',
        phoneNumberPrefix: 853,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'Area',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Madagascar',
        code: 'MG',
        continent: 'Africa',
        phoneNumberPrefix: 261,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Malawi',
        code: 'MW',
        continent: 'Africa',
        phoneNumberPrefix: 265,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Malaysia',
        code: 'MY',
        continent: 'Asia',
        phoneNumberPrefix: 60,
        zones: [
            {
                name: 'Johor',
                code: 'JHR',
            },
            {
                name: 'Kedah',
                code: 'KDH',
            },
            {
                name: 'Kelantan',
                code: 'KTN',
            },
            {
                name: 'Kuala Lumpur',
                code: 'KUL',
            },
            {
                name: 'Labuan',
                code: 'LBN',
            },
            {
                name: 'Malacca',
                code: 'MLK',
            },
            {
                name: 'Negeri Sembilan',
                code: 'NSN',
            },
            {
                name: 'Pahang',
                code: 'PHG',
            },
            {
                name: 'Penang',
                code: 'PNG',
            },
            {
                name: 'Perak',
                code: 'PRK',
            },
            {
                name: 'Perlis',
                code: 'PLS',
            },
            {
                name: 'Putrajaya',
                code: 'PJY',
            },
            {
                name: 'Sabah',
                code: 'SBH',
            },
            {
                name: 'Sarawak',
                code: 'SWK',
            },
            {
                name: 'Selangor',
                code: 'SGR',
            },
            {
                name: 'Terengganu',
                code: 'TRG',
            },
        ],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postcode',
            zone: 'entities.location.commonSettings.fields.region.stateTerritory',
        },
    },
    {
        name: 'Maldives',
        code: 'MV',
        continent: 'Asia',
        phoneNumberPrefix: 960,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Mali',
        code: 'ML',
        continent: 'Africa',
        phoneNumberPrefix: 223,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Malta',
        code: 'MT',
        continent: 'Europe',
        phoneNumberPrefix: 356,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Martinique',
        code: 'MQ',
        continent: 'Central America',
        phoneNumberPrefix: 596,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Mauritania',
        code: 'MR',
        continent: 'Africa',
        phoneNumberPrefix: 222,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Mauritius',
        code: 'MU',
        continent: 'Africa',
        phoneNumberPrefix: 230,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Mayotte',
        code: 'YT',
        continent: 'Europe',
        phoneNumberPrefix: 262,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Mexico',
        code: 'MX',
        continent: 'North America',
        phoneNumberPrefix: 52,
        zones: [
            {
                name: 'Aguascalientes',
                code: 'AGS',
            },
            {
                name: 'Baja California',
                code: 'BC',
            },
            {
                name: 'Baja California Sur',
                code: 'BCS',
            },
            {
                name: 'Campeche',
                code: 'CAMP',
            },
            {
                name: 'Chiapas',
                code: 'CHIS',
            },
            {
                name: 'Chihuahua',
                code: 'CHIH',
            },
            {
                name: 'Ciudad de Mexico',
                code: 'DF',
            },
            {
                name: 'Coahuila',
                code: 'COAH',
            },
            {
                name: 'Colima',
                code: 'COL',
            },
            {
                name: 'Durango',
                code: 'DGO',
            },
            {
                name: 'Guanajuato',
                code: 'GTO',
            },
            {
                name: 'Guerrero',
                code: 'GRO',
            },
            {
                name: 'Hidalgo',
                code: 'HGO',
            },
            {
                name: 'Jalisco',
                code: 'JAL',
            },
            {
                name: 'Mexico State',
                code: 'MEX',
            },
            {
                name: 'Michoacán',
                code: 'MICH',
            },
            {
                name: 'Morelos',
                code: 'MOR',
            },
            {
                name: 'Nayarit',
                code: 'NAY',
            },
            {
                name: 'Nuevo León',
                code: 'NL',
            },
            {
                name: 'Oaxaca',
                code: 'OAX',
            },
            {
                name: 'Puebla',
                code: 'PUE',
            },
            {
                name: 'Querétaro',
                code: 'QRO',
            },
            {
                name: 'Quintana Roo',
                code: 'Q ROO',
            },
            {
                name: 'San Luis Potosí',
                code: 'SLP',
            },
            {
                name: 'Sinaloa',
                code: 'SIN',
            },
            {
                name: 'Sonora',
                code: 'SON',
            },
            {
                name: 'Tabasco',
                code: 'TAB',
            },
            {
                name: 'Tamaulipas',
                code: 'TAMPS',
            },
            {
                name: 'Tlaxcala',
                code: 'TLAX',
            },
            {
                name: 'Veracruz',
                code: 'VER',
            },
            {
                name: 'Yucatán',
                code: 'YUC',
            },
            {
                name: 'Zacatecas',
                code: 'ZAC',
            },
        ],
        labels: {
            address1: 'Street and house number',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.state',
        },
    },
    {
        name: 'Moldova',
        code: 'MD',
        continent: 'Europe',
        phoneNumberPrefix: 373,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Monaco',
        code: 'MC',
        continent: 'Europe',
        phoneNumberPrefix: 377,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'Ward',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Mongolia',
        code: 'MN',
        continent: 'Asia',
        phoneNumberPrefix: 976,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Montenegro',
        code: 'ME',
        continent: 'Europe',
        phoneNumberPrefix: 382,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Montserrat',
        code: 'MS',
        continent: 'Central America',
        phoneNumberPrefix: 1,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Morocco',
        code: 'MA',
        continent: 'Africa',
        phoneNumberPrefix: 212,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Mozambique',
        code: 'MZ',
        continent: 'Africa',
        phoneNumberPrefix: 258,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Myanmar (Burma)',
        code: 'MM',
        continent: 'Asia',
        phoneNumberPrefix: 95,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Namibia',
        code: 'NA',
        continent: 'Africa',
        phoneNumberPrefix: 264,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Nauru',
        code: 'NR',
        continent: 'Oceania',
        phoneNumberPrefix: 674,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Nepal',
        code: 'NP',
        continent: 'Asia',
        phoneNumberPrefix: 977,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Netherlands',
        code: 'NL',
        continent: 'Europe',
        phoneNumberPrefix: 31,
        zones: [],
        labels: {
            address1: 'Street and house number',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'New Caledonia',
        code: 'NC',
        continent: 'Oceania',
        phoneNumberPrefix: 687,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'New Zealand',
        code: 'NZ',
        continent: 'Oceania',
        phoneNumberPrefix: 64,
        zones: [
            {
                name: 'Auckland',
                code: 'AUK',
            },
            {
                name: 'Bay of Plenty',
                code: 'BOP',
            },
            {
                name: 'Canterbury',
                code: 'CAN',
            },
            {
                name: 'Chatham Islands',
                code: 'CIT',
            },
            {
                name: 'Gisborne',
                code: 'GIS',
            },
            {
                name: 'Hawke’s Bay',
                code: 'HKB',
            },
            {
                name: 'Manawatu-Wanganui',
                code: 'MWT',
            },
            {
                name: 'Marlborough',
                code: 'MBH',
            },
            {
                name: 'Nelson',
                code: 'NSN',
            },
            {
                name: 'Northland',
                code: 'NTL',
            },
            {
                name: 'Otago',
                code: 'OTA',
            },
            {
                name: 'Southland',
                code: 'STL',
            },
            {
                name: 'Taranaki',
                code: 'TKI',
            },
            {
                name: 'Tasman',
                code: 'TAS',
            },
            {
                name: 'Waikato',
                code: 'WKO',
            },
            {
                name: 'Wellington',
                code: 'WGN',
            },
            {
                name: 'West Coast',
                code: 'WTC',
            },
        ],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Nicaragua',
        code: 'NI',
        continent: 'Central America',
        phoneNumberPrefix: 505,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Niger',
        code: 'NE',
        continent: 'Africa',
        phoneNumberPrefix: 227,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Nigeria',
        code: 'NG',
        continent: 'Africa',
        phoneNumberPrefix: 234,
        zones: [
            {
                name: 'Abia',
                code: 'AB',
            },
            {
                name: 'Federal Capital Territory',
                code: 'FC',
            },
            {
                name: 'Adamawa',
                code: 'AD',
            },
            {
                name: 'Akwa Ibom',
                code: 'AK',
            },
            {
                name: 'Anambra',
                code: 'AN',
            },
            {
                name: 'Bauchi',
                code: 'BA',
            },
            {
                name: 'Bayelsa',
                code: 'BY',
            },
            {
                name: 'Benue',
                code: 'BE',
            },
            {
                name: 'Borno',
                code: 'BO',
            },
            {
                name: 'Cross River',
                code: 'CR',
            },
            {
                name: 'Delta',
                code: 'DE',
            },
            {
                name: 'Ebonyi',
                code: 'EB',
            },
            {
                name: 'Edo',
                code: 'ED',
            },
            {
                name: 'Ekiti',
                code: 'EK',
            },
            {
                name: 'Enugu',
                code: 'EN',
            },
            {
                name: 'Gombe',
                code: 'GO',
            },
            {
                name: 'Imo',
                code: 'IM',
            },
            {
                name: 'Jigawa',
                code: 'JI',
            },
            {
                name: 'Kaduna',
                code: 'KD',
            },
            {
                name: 'Kano',
                code: 'KN',
            },
            {
                name: 'Katsina',
                code: 'KT',
            },
            {
                name: 'Kebbi',
                code: 'KE',
            },
            {
                name: 'Kogi',
                code: 'KO',
            },
            {
                name: 'Kwara',
                code: 'KW',
            },
            {
                name: 'Lagos',
                code: 'LA',
            },
            {
                name: 'Nasarawa',
                code: 'NA',
            },
            {
                name: 'Niger',
                code: 'NI',
            },
            {
                name: 'Ogun',
                code: 'OG',
            },
            {
                name: 'Ondo',
                code: 'ON',
            },
            {
                name: 'Osun',
                code: 'OS',
            },
            {
                name: 'Oyo',
                code: 'OY',
            },
            {
                name: 'Plateau',
                code: 'PL',
            },
            {
                name: 'Rivers',
                code: 'RI',
            },
            {
                name: 'Sokoto',
                code: 'SO',
            },
            {
                name: 'Taraba',
                code: 'TA',
            },
            {
                name: 'Yobe',
                code: 'YO',
            },
            {
                name: 'Zamfara',
                code: 'ZA',
            },
        ],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.state',
        },
    },
    {
        name: 'Niue',
        code: 'NU',
        continent: 'Oceania',
        phoneNumberPrefix: 683,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Norfolk Island',
        code: 'NF',
        continent: 'Oceania',
        phoneNumberPrefix: 672,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'North Macedonia',
        code: 'MK',
        continent: 'Europe',
        phoneNumberPrefix: 389,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Norway',
        code: 'NO',
        continent: 'Europe',
        phoneNumberPrefix: 47,
        zones: [],
        labels: {
            address1: 'Street and house number',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Oman',
        code: 'OM',
        continent: 'Asia',
        phoneNumberPrefix: 968,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Pakistan',
        code: 'PK',
        continent: 'Asia',
        phoneNumberPrefix: 92,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Palestinian Territories',
        code: 'PS',
        continent: 'Asia',
        phoneNumberPrefix: 970,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Panama',
        code: 'PA',
        continent: 'Central America',
        phoneNumberPrefix: 507,
        zones: [
            {
                name: 'Bocas del Toro',
                code: 'PA-1',
            },
            {
                name: 'Chiriquí',
                code: 'PA-4',
            },
            {
                name: 'Coclé',
                code: 'PA-2',
            },
            {
                name: 'Colón',
                code: 'PA-3',
            },
            {
                name: 'Darién',
                code: 'PA-5',
            },
            {
                name: 'Emberá',
                code: 'PA-EM',
            },
            {
                name: 'Herrera',
                code: 'PA-6',
            },
            {
                name: 'Guna Yala',
                code: 'PA-KY',
            },
            {
                name: 'Los Santos',
                code: 'PA-7',
            },
            {
                name: 'Ngöbe-Buglé',
                code: 'PA-NB',
            },
            {
                name: 'Panamá',
                code: 'PA-8',
            },
            {
                name: 'West Panamá',
                code: 'PA-10',
            },
            {
                name: 'Veraguas',
                code: 'PA-9',
            },
        ],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Papua New Guinea',
        code: 'PG',
        continent: 'Oceania',
        phoneNumberPrefix: 675,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Paraguay',
        code: 'PY',
        continent: 'South America',
        phoneNumberPrefix: 595,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Peru',
        code: 'PE',
        continent: 'South America',
        phoneNumberPrefix: 51,
        zones: [
            {
                name: 'Amazonas',
                code: 'PE-AMA',
            },
            {
                name: 'Ancash',
                code: 'PE-ANC',
            },
            {
                name: 'Apurímac',
                code: 'PE-APU',
            },
            {
                name: 'Arequipa',
                code: 'PE-ARE',
            },
            {
                name: 'Ayacucho',
                code: 'PE-AYA',
            },
            {
                name: 'Cajamarca',
                code: 'PE-CAJ',
            },
            {
                name: 'El Callao',
                code: 'PE-CAL',
            },
            {
                name: 'Cusco',
                code: 'PE-CUS',
            },
            {
                name: 'Huancavelica',
                code: 'PE-HUV',
            },
            {
                name: 'Huánuco',
                code: 'PE-HUC',
            },
            {
                name: 'Ica',
                code: 'PE-ICA',
            },
            {
                name: 'Junín',
                code: 'PE-JUN',
            },
            {
                name: 'La Libertad',
                code: 'PE-LAL',
            },
            {
                name: 'Lambayeque',
                code: 'PE-LAM',
            },
            {
                name: 'Lima (Department)',
                code: 'PE-LIM',
            },
            {
                name: 'Lima (Metropolitan)',
                code: 'PE-LMA',
            },
            {
                name: 'Loreto',
                code: 'PE-LOR',
            },
            {
                name: 'Madre de Dios',
                code: 'PE-MDD',
            },
            {
                name: 'Moquegua',
                code: 'PE-MOQ',
            },
            {
                name: 'Pasco',
                code: 'PE-PAS',
            },
            {
                name: 'Piura',
                code: 'PE-PIU',
            },
            {
                name: 'Puno',
                code: 'PE-PUN',
            },
            {
                name: 'San Martín',
                code: 'PE-SAM',
            },
            {
                name: 'Tacna',
                code: 'PE-TAC',
            },
            {
                name: 'Tumbes',
                code: 'PE-TUM',
            },
            {
                name: 'Ucayali',
                code: 'PE-UCA',
            },
        ],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Philippines',
        code: 'PH',
        continent: 'Asia',
        phoneNumberPrefix: 63,
        zones: [
            {
                name: 'Abra',
                code: 'PH-ABR',
            },
            {
                name: 'Agusan del Norte',
                code: 'PH-AGN',
            },
            {
                name: 'Agusan del Sur',
                code: 'PH-AGS',
            },
            {
                name: 'Aklan',
                code: 'PH-AKL',
            },
            {
                name: 'Albay',
                code: 'PH-ALB',
            },
            {
                name: 'Antique',
                code: 'PH-ANT',
            },
            {
                name: 'Apayao',
                code: 'PH-APA',
            },
            {
                name: 'Aurora',
                code: 'PH-AUR',
            },
            {
                name: 'Basilan',
                code: 'PH-BAS',
            },
            {
                name: 'Bataan',
                code: 'PH-BAN',
            },
            {
                name: 'Batanes',
                code: 'PH-BTN',
            },
            {
                name: 'Batangas',
                code: 'PH-BTG',
            },
            {
                name: 'Benguet',
                code: 'PH-BEN',
            },
            {
                name: 'Biliran',
                code: 'PH-BIL',
            },
            {
                name: 'Bohol',
                code: 'PH-BOH',
            },
            {
                name: 'Bukidnon',
                code: 'PH-BUK',
            },
            {
                name: 'Bulacan',
                code: 'PH-BUL',
            },
            {
                name: 'Cagayan',
                code: 'PH-CAG',
            },
            {
                name: 'Camarines Norte',
                code: 'PH-CAN',
            },
            {
                name: 'Camarines Sur',
                code: 'PH-CAS',
            },
            {
                name: 'Camiguin',
                code: 'PH-CAM',
            },
            {
                name: 'Capiz',
                code: 'PH-CAP',
            },
            {
                name: 'Catanduanes',
                code: 'PH-CAT',
            },
            {
                name: 'Cavite',
                code: 'PH-CAV',
            },
            {
                name: 'Cebu',
                code: 'PH-CEB',
            },
            {
                name: 'Cotabato',
                code: 'PH-NCO',
            },
            {
                name: 'Davao Occidental',
                code: 'PH-DVO',
            },
            {
                name: 'Davao Oriental',
                code: 'PH-DAO',
            },
            {
                name: 'Compostela Valley',
                code: 'PH-COM',
            },
            {
                name: 'Davao del Norte',
                code: 'PH-DAV',
            },
            {
                name: 'Davao del Sur',
                code: 'PH-DAS',
            },
            {
                name: 'Dinagat Islands',
                code: 'PH-DIN',
            },
            {
                name: 'Eastern Samar',
                code: 'PH-EAS',
            },
            {
                name: 'Guimaras',
                code: 'PH-GUI',
            },
            {
                name: 'Ifugao',
                code: 'PH-IFU',
            },
            {
                name: 'Ilocos Norte',
                code: 'PH-ILN',
            },
            {
                name: 'Ilocos Sur',
                code: 'PH-ILS',
            },
            {
                name: 'Iloilo',
                code: 'PH-ILI',
            },
            {
                name: 'Isabela',
                code: 'PH-ISA',
            },
            {
                name: 'Kalinga',
                code: 'PH-KAL',
            },
            {
                name: 'La Union',
                code: 'PH-LUN',
            },
            {
                name: 'Laguna',
                code: 'PH-LAG',
            },
            {
                name: 'Lanao del Norte',
                code: 'PH-LAN',
            },
            {
                name: 'Lanao del Sur',
                code: 'PH-LAS',
            },
            {
                name: 'Leyte',
                code: 'PH-LEY',
            },
            {
                name: 'Maguindanao',
                code: 'PH-MAG',
            },
            {
                name: 'Marinduque',
                code: 'PH-MAD',
            },
            {
                name: 'Masbate',
                code: 'PH-MAS',
            },
            {
                name: 'Metro Manila',
                code: 'PH-00',
            },
            {
                name: 'Misamis Occidental',
                code: 'PH-MSC',
            },
            {
                name: 'Misamis Oriental',
                code: 'PH-MSR',
            },
            {
                name: 'Mountain',
                code: 'PH-MOU',
            },
            {
                name: 'Negros Occidental',
                code: 'PH-NEC',
            },
            {
                name: 'Negros Oriental',
                code: 'PH-NER',
            },
            {
                name: 'Northern Samar',
                code: 'PH-NSA',
            },
            {
                name: 'Nueva Ecija',
                code: 'PH-NUE',
            },
            {
                name: 'Nueva Vizcaya',
                code: 'PH-NUV',
            },
            {
                name: 'Occidental Mindoro',
                code: 'PH-MDC',
            },
            {
                name: 'Oriental Mindoro',
                code: 'PH-MDR',
            },
            {
                name: 'Palawan',
                code: 'PH-PLW',
            },
            {
                name: 'Pampanga',
                code: 'PH-PAM',
            },
            {
                name: 'Pangasinan',
                code: 'PH-PAN',
            },
            {
                name: 'Quezon',
                code: 'PH-QUE',
            },
            {
                name: 'Quirino',
                code: 'PH-QUI',
            },
            {
                name: 'Rizal',
                code: 'PH-RIZ',
            },
            {
                name: 'Romblon',
                code: 'PH-ROM',
            },
            {
                name: 'Samar',
                code: 'PH-WSA',
            },
            {
                name: 'Sarangani',
                code: 'PH-SAR',
            },
            {
                name: 'Siquijor',
                code: 'PH-SIG',
            },
            {
                name: 'Sorsogon',
                code: 'PH-SOR',
            },
            {
                name: 'South Cotabato',
                code: 'PH-SCO',
            },
            {
                name: 'Southern Leyte',
                code: 'PH-SLE',
            },
            {
                name: 'Sultan Kudarat',
                code: 'PH-SUK',
            },
            {
                name: 'Sulu',
                code: 'PH-SLU',
            },
            {
                name: 'Surigao del Norte',
                code: 'PH-SUN',
            },
            {
                name: 'Surigao del Sur',
                code: 'PH-SUR',
            },
            {
                name: 'Tarlac',
                code: 'PH-TAR',
            },
            {
                name: 'Tawi-Tawi',
                code: 'PH-TAW',
            },
            {
                name: 'Zambales',
                code: 'PH-ZMB',
            },
            {
                name: 'Zamboanga Sibugay',
                code: 'PH-ZSI',
            },
            {
                name: 'Zamboanga del Norte',
                code: 'PH-ZAN',
            },
            {
                name: 'Zamboanga del Sur',
                code: 'PH-ZAS',
            },
        ],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Pitcairn Islands',
        code: 'PN',
        continent: 'Oceania',
        phoneNumberPrefix: 64,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Poland',
        code: 'PL',
        continent: 'Europe',
        phoneNumberPrefix: 48,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Portugal',
        code: 'PT',
        continent: 'Europe',
        phoneNumberPrefix: 351,
        zones: [
            {
                name: 'Azores',
                code: 'PT-20',
            },
            {
                name: 'Aveiro',
                code: 'PT-01',
            },
            {
                name: 'Beja',
                code: 'PT-02',
            },
            {
                name: 'Braga',
                code: 'PT-03',
            },
            {
                name: 'Bragança',
                code: 'PT-04',
            },
            {
                name: 'Castelo Branco',
                code: 'PT-05',
            },
            {
                name: 'Coimbra',
                code: 'PT-06',
            },
            {
                name: 'Évora',
                code: 'PT-07',
            },
            {
                name: 'Faro',
                code: 'PT-08',
            },
            {
                name: 'Guarda',
                code: 'PT-09',
            },
            {
                name: 'Leiria',
                code: 'PT-10',
            },
            {
                name: 'Lisbon',
                code: 'PT-11',
            },
            {
                name: 'Madeira',
                code: 'PT-30',
            },
            {
                name: 'Portalegre',
                code: 'PT-12',
            },
            {
                name: 'Porto',
                code: 'PT-13',
            },
            {
                name: 'Santarém',
                code: 'PT-14',
            },
            {
                name: 'Setúbal',
                code: 'PT-15',
            },
            {
                name: 'Viana do Castelo',
                code: 'PT-16',
            },
            {
                name: 'Vila Real',
                code: 'PT-17',
            },
            {
                name: 'Viseu',
                code: 'PT-18',
            },
        ],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Qatar',
        code: 'QA',
        continent: 'Asia',
        phoneNumberPrefix: 974,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Réunion',
        code: 'RE',
        continent: 'Europe',
        phoneNumberPrefix: 262,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Romania',
        code: 'RO',
        continent: 'Europe',
        phoneNumberPrefix: 40,
        zones: [
            {
                name: 'Alba',
                code: 'AB',
            },
            {
                name: 'Arad',
                code: 'AR',
            },
            {
                name: 'Argeș',
                code: 'AG',
            },
            {
                name: 'Bacău',
                code: 'BC',
            },
            {
                name: 'Bihor',
                code: 'BH',
            },
            {
                name: 'Bistriţa-Năsăud',
                code: 'BN',
            },
            {
                name: 'Botoşani',
                code: 'BT',
            },
            {
                name: 'Brăila',
                code: 'BR',
            },
            {
                name: 'Braşov',
                code: 'BV',
            },
            {
                name: 'Bucharest',
                code: 'B',
            },
            {
                name: 'Buzău',
                code: 'BZ',
            },
            {
                name: 'Caraș-Severin',
                code: 'CS',
            },
            {
                name: 'Cluj',
                code: 'CJ',
            },
            {
                name: 'Constanța',
                code: 'CT',
            },
            {
                name: 'Covasna',
                code: 'CV',
            },
            {
                name: 'Călărași',
                code: 'CL',
            },
            {
                name: 'Dolj',
                code: 'DJ',
            },
            {
                name: 'Dâmbovița',
                code: 'DB',
            },
            {
                name: 'Galați',
                code: 'GL',
            },
            {
                name: 'Giurgiu',
                code: 'GR',
            },
            {
                name: 'Gorj',
                code: 'GJ',
            },
            {
                name: 'Harghita',
                code: 'HR',
            },
            {
                name: 'Hunedoara',
                code: 'HD',
            },
            {
                name: 'Ialomița',
                code: 'IL',
            },
            {
                name: 'Iași',
                code: 'IS',
            },
            {
                name: 'Ilfov',
                code: 'IF',
            },
            {
                name: 'Maramureş',
                code: 'MM',
            },
            {
                name: 'Mehedinți',
                code: 'MH',
            },
            {
                name: 'Mureş',
                code: 'MS',
            },
            {
                name: 'Neamţ',
                code: 'NT',
            },
            {
                name: 'Olt',
                code: 'OT',
            },
            {
                name: 'Prahova',
                code: 'PH',
            },
            {
                name: 'Sălaj',
                code: 'SJ',
            },
            {
                name: 'Satu Mare',
                code: 'SM',
            },
            {
                name: 'Sibiu',
                code: 'SB',
            },
            {
                name: 'Suceava',
                code: 'SV',
            },
            {
                name: 'Teleorman',
                code: 'TR',
            },
            {
                name: 'Timiș',
                code: 'TM',
            },
            {
                name: 'Tulcea',
                code: 'TL',
            },
            {
                name: 'Vâlcea',
                code: 'VL',
            },
            {
                name: 'Vaslui',
                code: 'VS',
            },
            {
                name: 'Vrancea',
                code: 'VN',
            },
        ],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.county',
        },
    },
    {
        name: 'Russia',
        code: 'RU',
        continent: 'Asia',
        phoneNumberPrefix: 7,
        zones: [
            {
                name: 'Altai Krai',
                code: 'ALT',
            },
            {
                name: 'Altai',
                code: 'AL',
            },
            {
                name: 'Amur',
                code: 'AMU',
            },
            {
                name: 'Arkhangelsk',
                code: 'ARK',
            },
            {
                name: 'Astrakhan',
                code: 'AST',
            },
            {
                name: 'Belgorod',
                code: 'BEL',
            },
            {
                name: 'Bryansk',
                code: 'BRY',
            },
            {
                name: 'Chechen',
                code: 'CE',
            },
            {
                name: 'Chelyabinsk',
                code: 'CHE',
            },
            {
                name: 'Chukotka Okrug',
                code: 'CHU',
            },
            {
                name: 'Chuvash',
                code: 'CU',
            },
            {
                name: 'Irkutsk',
                code: 'IRK',
            },
            {
                name: 'Ivanovo',
                code: 'IVA',
            },
            {
                name: 'Jewish',
                code: 'YEV',
            },
            {
                name: 'Kabardino-Balkar',
                code: 'KB',
            },
            {
                name: 'Kaliningrad',
                code: 'KGD',
            },
            {
                name: 'Kaluga',
                code: 'KLU',
            },
            {
                name: 'Kamchatka Krai',
                code: 'KAM',
            },
            {
                name: 'Karachay-Cherkess',
                code: 'KC',
            },
            {
                name: 'Kemerovo',
                code: 'KEM',
            },
            {
                name: 'Khabarovsk Krai',
                code: 'KHA',
            },
            {
                name: 'Khanty-Mansi',
                code: 'KHM',
            },
            {
                name: 'Kirov',
                code: 'KIR',
            },
            {
                name: 'Komi',
                code: 'KO',
            },
            {
                name: 'Kostroma',
                code: 'KOS',
            },
            {
                name: 'Krasnodar Krai',
                code: 'KDA',
            },
            {
                name: 'Krasnoyarsk Krai',
                code: 'KYA',
            },
            {
                name: 'Kurgan',
                code: 'KGN',
            },
            {
                name: 'Kursk',
                code: 'KRS',
            },
            {
                name: 'Leningrad',
                code: 'LEN',
            },
            {
                name: 'Lipetsk',
                code: 'LIP',
            },
            {
                name: 'Magadan',
                code: 'MAG',
            },
            {
                name: 'Mari El',
                code: 'ME',
            },
            {
                name: 'Moscow',
                code: 'MOW',
            },
            {
                name: 'Moscow Province',
                code: 'MOS',
            },
            {
                name: 'Murmansk',
                code: 'MUR',
            },
            {
                name: 'Nizhny Novgorod',
                code: 'NIZ',
            },
            {
                name: 'Novgorod',
                code: 'NGR',
            },
            {
                name: 'Novosibirsk',
                code: 'NVS',
            },
            {
                name: 'Omsk',
                code: 'OMS',
            },
            {
                name: 'Orenburg',
                code: 'ORE',
            },
            {
                name: 'Oryol',
                code: 'ORL',
            },
            {
                name: 'Penza',
                code: 'PNZ',
            },
            {
                name: 'Perm Krai',
                code: 'PER',
            },
            {
                name: 'Primorsky Krai',
                code: 'PRI',
            },
            {
                name: 'Pskov',
                code: 'PSK',
            },
            {
                name: 'Adygea',
                code: 'AD',
            },
            {
                name: 'Bashkortostan',
                code: 'BA',
            },
            {
                name: 'Buryat',
                code: 'BU',
            },
            {
                name: 'Dagestan',
                code: 'DA',
            },
            {
                name: 'Ingushetia',
                code: 'IN',
            },
            {
                name: 'Kalmykia',
                code: 'KL',
            },
            {
                name: 'Karelia',
                code: 'KR',
            },
            {
                name: 'Khakassia',
                code: 'KK',
            },
            {
                name: 'Mordovia',
                code: 'MO',
            },
            {
                name: 'North Ossetia-Alania',
                code: 'SE',
            },
            {
                name: 'Tatarstan',
                code: 'TA',
            },
            {
                name: 'Rostov',
                code: 'ROS',
            },
            {
                name: 'Ryazan',
                code: 'RYA',
            },
            {
                name: 'Saint Petersburg',
                code: 'SPE',
            },
            {
                name: 'Sakha',
                code: 'SA',
            },
            {
                name: 'Sakhalin',
                code: 'SAK',
            },
            {
                name: 'Samara',
                code: 'SAM',
            },
            {
                name: 'Saratov',
                code: 'SAR',
            },
            {
                name: 'Smolensk',
                code: 'SMO',
            },
            {
                name: 'Stavropol Krai',
                code: 'STA',
            },
            {
                name: 'Sverdlovsk',
                code: 'SVE',
            },
            {
                name: 'Tambov',
                code: 'TAM',
            },
            {
                name: 'Tomsk',
                code: 'TOM',
            },
            {
                name: 'Tula',
                code: 'TUL',
            },
            {
                name: 'Tver',
                code: 'TVE',
            },
            {
                name: 'Tyumen',
                code: 'TYU',
            },
            {
                name: 'Tuva',
                code: 'TY',
            },
            {
                name: 'Udmurt',
                code: 'UD',
            },
            {
                name: 'Ulyanovsk',
                code: 'ULY',
            },
            {
                name: 'Vladimir',
                code: 'VLA',
            },
            {
                name: 'Volgograd',
                code: 'VGG',
            },
            {
                name: 'Vologda',
                code: 'VLG',
            },
            {
                name: 'Voronezh',
                code: 'VOR',
            },
            {
                name: 'Yamalo-Nenets Okrug',
                code: 'YAN',
            },
            {
                name: 'Yaroslavl',
                code: 'YAR',
            },
            {
                name: 'Zabaykalsky Krai',
                code: 'ZAB',
            },
        ],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Rwanda',
        code: 'RW',
        continent: 'Africa',
        phoneNumberPrefix: 250,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Samoa',
        code: 'WS',
        continent: 'Oceania',
        phoneNumberPrefix: 685,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'San Marino',
        code: 'SM',
        continent: 'Europe',
        phoneNumberPrefix: 378,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'São Tomé & Príncipe',
        code: 'ST',
        continent: 'Africa',
        phoneNumberPrefix: 239,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Saudi Arabia',
        code: 'SA',
        continent: 'Asia',
        phoneNumberPrefix: 966,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Senegal',
        code: 'SN',
        continent: 'Africa',
        phoneNumberPrefix: 221,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Serbia',
        code: 'RS',
        continent: 'Europe',
        phoneNumberPrefix: 381,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Seychelles',
        code: 'SC',
        continent: 'Africa',
        phoneNumberPrefix: 248,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Sierra Leone',
        code: 'SL',
        continent: 'Africa',
        phoneNumberPrefix: 232,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Singapore',
        code: 'SG',
        continent: 'Asia',
        phoneNumberPrefix: 65,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Sint Maarten',
        code: 'SX',
        continent: 'Central America',
        phoneNumberPrefix: 1,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Slovakia',
        code: 'SK',
        continent: 'Europe',
        phoneNumberPrefix: 421,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Slovenia',
        code: 'SI',
        continent: 'Europe',
        phoneNumberPrefix: 386,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Solomon Islands',
        code: 'SB',
        continent: 'Oceania',
        phoneNumberPrefix: 677,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Somalia',
        code: 'SO',
        continent: 'Africa',
        phoneNumberPrefix: 252,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'South Africa',
        code: 'ZA',
        continent: 'Africa',
        phoneNumberPrefix: 27,
        zones: [
            {
                name: 'Eastern Cape',
                code: 'EC',
            },
            {
                name: 'Free State',
                code: 'FS',
            },
            {
                name: 'Gauteng',
                code: 'GP',
            },
            {
                name: 'KwaZulu-Natal',
                code: 'NL',
            },
            {
                name: 'Limpopo',
                code: 'LP',
            },
            {
                name: 'Mpumalanga',
                code: 'MP',
            },
            {
                name: 'North West',
                code: 'NW',
            },
            {
                name: 'Northern Cape',
                code: 'NC',
            },
            {
                name: 'Western Cape',
                code: 'WC',
            },
        ],
        labels: {
            address1: 'Address',
            address2: 'Suburb',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.province',
        },
    },
    {
        name: 'South Georgia & South Sandwich Islands',
        code: 'GS',
        continent: 'Other',
        phoneNumberPrefix: 500,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'South Korea',
        code: 'KR',
        continent: 'Asia',
        phoneNumberPrefix: 82,
        zones: [
            {
                name: 'Busan',
                code: 'KR-26',
            },
            {
                name: 'North Chungcheong',
                code: 'KR-43',
            },
            {
                name: 'South Chungcheong',
                code: 'KR-44',
            },
            {
                name: 'Daegu',
                code: 'KR-27',
            },
            {
                name: 'Daejeon',
                code: 'KR-30',
            },
            {
                name: 'Gangwon',
                code: 'KR-42',
            },
            {
                name: 'Gwangju City',
                code: 'KR-29',
            },
            {
                name: 'North Gyeongsang',
                code: 'KR-47',
            },
            {
                name: 'Gyeonggi',
                code: 'KR-41',
            },
            {
                name: 'South Gyeongsang',
                code: 'KR-48',
            },
            {
                name: 'Incheon',
                code: 'KR-28',
            },
            {
                name: 'Jeju',
                code: 'KR-49',
            },
            {
                name: 'North Jeolla',
                code: 'KR-45',
            },
            {
                name: 'South Jeolla',
                code: 'KR-46',
            },
            {
                name: 'Sejong',
                code: 'KR-50',
            },
            {
                name: 'Seoul',
                code: 'KR-11',
            },
            {
                name: 'Ulsan',
                code: 'KR-31',
            },
        ],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.province',
        },
    },
    {
        name: 'South Sudan',
        code: 'SS',
        continent: 'Africa',
        phoneNumberPrefix: 211,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Spain',
        code: 'ES',
        continent: 'Europe',
        phoneNumberPrefix: 34,
        zones: [
            {
                name: 'A Coruña',
                code: 'C',
            },
            {
                name: 'Álava',
                code: 'VI',
            },
            {
                name: 'Albacete',
                code: 'AB',
            },
            {
                name: 'Alicante',
                code: 'A',
            },
            {
                name: 'Almería',
                code: 'AL',
            },
            {
                name: 'Asturias Province',
                code: 'O',
            },
            {
                name: 'Ávila',
                code: 'AV',
            },
            {
                name: 'Badajoz',
                code: 'BA',
            },
            {
                name: 'Balears Province',
                code: 'PM',
            },
            {
                name: 'Barcelona',
                code: 'B',
            },
            {
                name: 'Burgos',
                code: 'BU',
            },
            {
                name: 'Cáceres',
                code: 'CC',
            },
            {
                name: 'Cádiz',
                code: 'CA',
            },
            {
                name: 'Cantabria Province',
                code: 'S',
            },
            {
                name: 'Castellón',
                code: 'CS',
            },
            {
                name: 'Ceuta',
                code: 'CE',
            },
            {
                name: 'Ciudad Real',
                code: 'CR',
            },
            {
                name: 'Córdoba',
                code: 'CO',
            },
            {
                name: 'Cuenca',
                code: 'CU',
            },
            {
                name: 'Girona',
                code: 'GI',
            },
            {
                name: 'Granada',
                code: 'GR',
            },
            {
                name: 'Guadalajara',
                code: 'GU',
            },
            {
                name: 'Gipuzkoa',
                code: 'SS',
            },
            {
                name: 'Huelva',
                code: 'H',
            },
            {
                name: 'Huesca',
                code: 'HU',
            },
            {
                name: 'Jaén',
                code: 'J',
            },
            {
                name: 'La Rioja Province',
                code: 'LO',
            },
            {
                name: 'Las Palmas',
                code: 'GC',
            },
            {
                name: 'León',
                code: 'LE',
            },
            {
                name: 'Lleida',
                code: 'L',
            },
            {
                name: 'Lugo',
                code: 'LU',
            },
            {
                name: 'Madrid Province',
                code: 'M',
            },
            {
                name: 'Málaga',
                code: 'MA',
            },
            {
                name: 'Melilla',
                code: 'ML',
            },
            {
                name: 'Murcia',
                code: 'MU',
            },
            {
                name: 'Navarra',
                code: 'NA',
            },
            {
                name: 'Ourense',
                code: 'OR',
            },
            {
                name: 'Palencia',
                code: 'P',
            },
            {
                name: 'Pontevedra',
                code: 'PO',
            },
            {
                name: 'Salamanca',
                code: 'SA',
            },
            {
                name: 'Santa Cruz de Tenerife',
                code: 'TF',
            },
            {
                name: 'Segovia',
                code: 'SG',
            },
            {
                name: 'Seville',
                code: 'SE',
            },
            {
                name: 'Soria',
                code: 'SO',
            },
            {
                name: 'Tarragona',
                code: 'T',
            },
            {
                name: 'Teruel',
                code: 'TE',
            },
            {
                name: 'Toledo',
                code: 'TO',
            },
            {
                name: 'Valencia',
                code: 'V',
            },
            {
                name: 'Valladolid',
                code: 'VA',
            },
            {
                name: 'Biscay',
                code: 'BI',
            },
            {
                name: 'Zamora',
                code: 'ZA',
            },
            {
                name: 'Zaragoza',
                code: 'Z',
            },
        ],
        labels: {
            address1: 'Street and house number',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.province',
        },
    },
    {
        name: 'Sri Lanka',
        code: 'LK',
        continent: 'Asia',
        phoneNumberPrefix: 94,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'St. Barthélemy',
        code: 'BL',
        continent: 'Central America',
        phoneNumberPrefix: 590,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'St. Helena',
        code: 'SH',
        continent: 'Africa',
        phoneNumberPrefix: 290,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'St. Kitts & Nevis',
        code: 'KN',
        continent: 'Central America',
        phoneNumberPrefix: 1,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'St. Lucia',
        code: 'LC',
        continent: 'Central America',
        phoneNumberPrefix: 1,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'St. Martin',
        code: 'MF',
        continent: 'Central America',
        phoneNumberPrefix: 590,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'St. Pierre & Miquelon',
        code: 'PM',
        continent: 'Central America',
        phoneNumberPrefix: 508,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'St. Vincent & Grenadines',
        code: 'VC',
        continent: 'Central America',
        phoneNumberPrefix: 1,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Sudan',
        code: 'SD',
        continent: 'Africa',
        phoneNumberPrefix: 249,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Suriname',
        code: 'SR',
        continent: 'South America',
        phoneNumberPrefix: 597,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Svalbard & Jan Mayen',
        code: 'SJ',
        continent: 'Europe',
        phoneNumberPrefix: 47,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Sweden',
        code: 'SE',
        continent: 'Europe',
        phoneNumberPrefix: 46,
        zones: [],
        labels: {
            address1: 'Street and house number',
            address2: 'Apartment, suite, etc.',
            city: 'City/town',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Switzerland',
        code: 'CH',
        continent: 'Europe',
        phoneNumberPrefix: 41,
        zones: [],
        labels: {
            address1: 'Street and house number',
            address2: 'Additional address',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Taiwan',
        code: 'TW',
        continent: 'Asia',
        phoneNumberPrefix: 886,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Tajikistan',
        code: 'TJ',
        continent: 'Asia',
        phoneNumberPrefix: 992,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Tanzania',
        code: 'TZ',
        continent: 'Africa',
        phoneNumberPrefix: 255,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Thailand',
        code: 'TH',
        continent: 'Asia',
        phoneNumberPrefix: 66,
        zones: [
            {
                name: 'Amnat Charoen',
                code: 'TH-37',
            },
            {
                name: 'Ang Thong',
                code: 'TH-15',
            },
            {
                name: 'Bangkok',
                code: 'TH-10',
            },
            {
                name: 'Bueng Kan',
                code: 'TH-38',
            },
            {
                name: 'Buri Ram',
                code: 'TH-31',
            },
            {
                name: 'Chachoengsao',
                code: 'TH-24',
            },
            {
                name: 'Chai Nat',
                code: 'TH-18',
            },
            {
                name: 'Chaiyaphum',
                code: 'TH-36',
            },
            {
                name: 'Chanthaburi',
                code: 'TH-22',
            },
            {
                name: 'Chiang Mai',
                code: 'TH-50',
            },
            {
                name: 'Chiang Rai',
                code: 'TH-57',
            },
            {
                name: 'Chon Buri',
                code: 'TH-20',
            },
            {
                name: 'Chumphon',
                code: 'TH-86',
            },
            {
                name: 'Kalasin',
                code: 'TH-46',
            },
            {
                name: 'Kamphaeng Phet',
                code: 'TH-62',
            },
            {
                name: 'Kanchanaburi',
                code: 'TH-71',
            },
            {
                name: 'Khon Kaen',
                code: 'TH-40',
            },
            {
                name: 'Krabi',
                code: 'TH-81',
            },
            {
                name: 'Lampang',
                code: 'TH-52',
            },
            {
                name: 'Lamphun',
                code: 'TH-51',
            },
            {
                name: 'Loei',
                code: 'TH-42',
            },
            {
                name: 'Lopburi',
                code: 'TH-16',
            },
            {
                name: 'Mae Hong Son',
                code: 'TH-58',
            },
            {
                name: 'Maha Sarakham',
                code: 'TH-44',
            },
            {
                name: 'Mukdahan',
                code: 'TH-49',
            },
            {
                name: 'Nakhon Nayok',
                code: 'TH-26',
            },
            {
                name: 'Nakhon Pathom',
                code: 'TH-73',
            },
            {
                name: 'Nakhon Phanom',
                code: 'TH-48',
            },
            {
                name: 'Nakhon Ratchasima',
                code: 'TH-30',
            },
            {
                name: 'Nakhon Sawan',
                code: 'TH-60',
            },
            {
                name: 'Nakhon Si Thammarat',
                code: 'TH-80',
            },
            {
                name: 'Nan',
                code: 'TH-55',
            },
            {
                name: 'Narathiwat',
                code: 'TH-96',
            },
            {
                name: 'Nong Bua Lam Phu',
                code: 'TH-39',
            },
            {
                name: 'Nong Khai',
                code: 'TH-43',
            },
            {
                name: 'Nonthaburi',
                code: 'TH-12',
            },
            {
                name: 'Pathum Thani',
                code: 'TH-13',
            },
            {
                name: 'Pattani',
                code: 'TH-94',
            },
            {
                name: 'Pattaya',
                code: 'TH-S',
            },
            {
                name: 'Phang Nga',
                code: 'TH-82',
            },
            {
                name: 'Phatthalung',
                code: 'TH-93',
            },
            {
                name: 'Phayao',
                code: 'TH-56',
            },
            {
                name: 'Phetchabun',
                code: 'TH-67',
            },
            {
                name: 'Phetchaburi',
                code: 'TH-76',
            },
            {
                name: 'Phichit',
                code: 'TH-66',
            },
            {
                name: 'Phitsanulok',
                code: 'TH-65',
            },
            {
                name: 'Phra Nakhon Si Ayutthaya',
                code: 'TH-14',
            },
            {
                name: 'Phrae',
                code: 'TH-54',
            },
            {
                name: 'Phuket',
                code: 'TH-83',
            },
            {
                name: 'Prachin Buri',
                code: 'TH-25',
            },
            {
                name: 'Prachuap Khiri Khan',
                code: 'TH-77',
            },
            {
                name: 'Ranong',
                code: 'TH-85',
            },
            {
                name: 'Ratchaburi',
                code: 'TH-70',
            },
            {
                name: 'Rayong',
                code: 'TH-21',
            },
            {
                name: 'Roi Et',
                code: 'TH-45',
            },
            {
                name: 'Sa Kaeo',
                code: 'TH-27',
            },
            {
                name: 'Sakon Nakhon',
                code: 'TH-47',
            },
            {
                name: 'Samut Prakan',
                code: 'TH-11',
            },
            {
                name: 'Samut Sakhon',
                code: 'TH-74',
            },
            {
                name: 'Samut Songkhram',
                code: 'TH-75',
            },
            {
                name: 'Saraburi',
                code: 'TH-19',
            },
            {
                name: 'Satun',
                code: 'TH-91',
            },
            {
                name: 'Sing Buri',
                code: 'TH-17',
            },
            {
                name: 'Si Sa Ket',
                code: 'TH-33',
            },
            {
                name: 'Songkhla',
                code: 'TH-90',
            },
            {
                name: 'Sukhothai',
                code: 'TH-64',
            },
            {
                name: 'Suphanburi',
                code: 'TH-72',
            },
            {
                name: 'Surat Thani',
                code: 'TH-84',
            },
            {
                name: 'Surin',
                code: 'TH-32',
            },
            {
                name: 'Tak',
                code: 'TH-63',
            },
            {
                name: 'Trang',
                code: 'TH-92',
            },
            {
                name: 'Trat',
                code: 'TH-23',
            },
            {
                name: 'Ubon Ratchathani',
                code: 'TH-34',
            },
            {
                name: 'Udon Thani',
                code: 'TH-41',
            },
            {
                name: 'Uthai Thani',
                code: 'TH-61',
            },
            {
                name: 'Uttaradit',
                code: 'TH-53',
            },
            {
                name: 'Yala',
                code: 'TH-95',
            },
            {
                name: 'Yasothon',
                code: 'TH-35',
            },
        ],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.province',
        },
    },
    {
        name: 'Timor-Leste',
        code: 'TL',
        continent: 'Oceania',
        phoneNumberPrefix: 670,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Togo',
        code: 'TG',
        continent: 'Africa',
        phoneNumberPrefix: 228,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Tokelau',
        code: 'TK',
        continent: 'Oceania',
        phoneNumberPrefix: 690,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Tonga',
        code: 'TO',
        continent: 'Oceania',
        phoneNumberPrefix: 676,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Trinidad & Tobago',
        code: 'TT',
        continent: 'Central America',
        phoneNumberPrefix: 1,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Tristan da Cunha',
        code: 'TA',
        continent: 'Africa',
        phoneNumberPrefix: 2908,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Tunisia',
        code: 'TN',
        continent: 'Africa',
        phoneNumberPrefix: 216,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Turkey',
        code: 'TR',
        continent: 'Europe',
        phoneNumberPrefix: 90,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Turkmenistan',
        code: 'TM',
        continent: 'Asia',
        phoneNumberPrefix: 993,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Turks & Caicos Islands',
        code: 'TC',
        continent: 'Central America',
        phoneNumberPrefix: 1,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Tuvalu',
        code: 'TV',
        continent: 'Oceania',
        phoneNumberPrefix: 688,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'U.S. Outlying Islands',
        code: 'UM',
        continent: 'Central America',
        phoneNumberPrefix: 1,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Uganda',
        code: 'UG',
        continent: 'Africa',
        phoneNumberPrefix: 256,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Ukraine',
        code: 'UA',
        continent: 'Europe',
        phoneNumberPrefix: 380,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'United Arab Emirates',
        code: 'AE',
        continent: 'Asia',
        phoneNumberPrefix: 971,
        zones: [
            {
                name: 'Abu Dhabi',
                code: 'AZ',
            },
            {
                name: 'Ajman',
                code: 'AJ',
            },
            {
                name: 'Dubai',
                code: 'DU',
            },
            {
                name: 'Fujairah',
                code: 'FU',
            },
            {
                name: 'Ras al-Khaimah',
                code: 'RK',
            },
            {
                name: 'Sharjah',
                code: 'SH',
            },
            {
                name: 'Umm al-Quwain',
                code: 'UQ',
            },
        ],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.emirate',
        },
    },
    {
        name: 'United Kingdom',
        code: 'GB',
        continent: 'Europe',
        phoneNumberPrefix: 44,
        zones: [
            {
                name: 'British Forces',
                code: 'BFP',
            },
            {
                name: 'England',
                code: 'ENG',
            },
            {
                name: 'Northern Ireland',
                code: 'NIR',
            },
            {
                name: 'Scotland',
                code: 'SCT',
            },
            {
                name: 'Wales',
                code: 'WLS',
            },
        ],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postcode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'United States',
        code: 'US',
        continent: 'North America',
        phoneNumberPrefix: 1,
        zones: [
            {
                name: 'Alabama',
                code: 'AL',
            },
            {
                name: 'Alaska',
                code: 'AK',
            },
            {
                name: 'American Samoa',
                code: 'AS',
            },
            {
                name: 'Arizona',
                code: 'AZ',
            },
            {
                name: 'Arkansas',
                code: 'AR',
            },
            {
                name: 'California',
                code: 'CA',
            },
            {
                name: 'Colorado',
                code: 'CO',
            },
            {
                name: 'Connecticut',
                code: 'CT',
            },
            {
                name: 'Delaware',
                code: 'DE',
            },
            {
                name: 'Washington DC',
                code: 'DC',
            },
            {
                name: 'Micronesia',
                code: 'FM',
            },
            {
                name: 'Florida',
                code: 'FL',
            },
            {
                name: 'Georgia',
                code: 'GA',
            },
            {
                name: 'Guam',
                code: 'GU',
            },
            {
                name: 'Hawaii',
                code: 'HI',
            },
            {
                name: 'Idaho',
                code: 'ID',
            },
            {
                name: 'Illinois',
                code: 'IL',
            },
            {
                name: 'Indiana',
                code: 'IN',
            },
            {
                name: 'Iowa',
                code: 'IA',
            },
            {
                name: 'Kansas',
                code: 'KS',
            },
            {
                name: 'Kentucky',
                code: 'KY',
            },
            {
                name: 'Louisiana',
                code: 'LA',
            },
            {
                name: 'Maine',
                code: 'ME',
            },
            {
                name: 'Marshall Islands',
                code: 'MH',
            },
            {
                name: 'Maryland',
                code: 'MD',
            },
            {
                name: 'Massachusetts',
                code: 'MA',
            },
            {
                name: 'Michigan',
                code: 'MI',
            },
            {
                name: 'Minnesota',
                code: 'MN',
            },
            {
                name: 'Mississippi',
                code: 'MS',
            },
            {
                name: 'Missouri',
                code: 'MO',
            },
            {
                name: 'Montana',
                code: 'MT',
            },
            {
                name: 'Nebraska',
                code: 'NE',
            },
            {
                name: 'Nevada',
                code: 'NV',
            },
            {
                name: 'New Hampshire',
                code: 'NH',
            },
            {
                name: 'New Jersey',
                code: 'NJ',
            },
            {
                name: 'New Mexico',
                code: 'NM',
            },
            {
                name: 'New York',
                code: 'NY',
            },
            {
                name: 'North Carolina',
                code: 'NC',
            },
            {
                name: 'North Dakota',
                code: 'ND',
            },
            {
                name: 'Northern Mariana Islands',
                code: 'MP',
            },
            {
                name: 'Ohio',
                code: 'OH',
            },
            {
                name: 'Oklahoma',
                code: 'OK',
            },
            {
                name: 'Oregon',
                code: 'OR',
            },
            {
                name: 'Palau',
                code: 'PW',
            },
            {
                name: 'Pennsylvania',
                code: 'PA',
            },
            {
                name: 'Puerto Rico',
                code: 'PR',
            },
            {
                name: 'Rhode Island',
                code: 'RI',
            },
            {
                name: 'South Carolina',
                code: 'SC',
            },
            {
                name: 'South Dakota',
                code: 'SD',
            },
            {
                name: 'Tennessee',
                code: 'TN',
            },
            {
                name: 'Texas',
                code: 'TX',
            },
            {
                name: 'Utah',
                code: 'UT',
            },
            {
                name: 'Vermont',
                code: 'VT',
            },
            {
                name: 'U.S. Virgin Islands',
                code: 'VI',
            },
            {
                name: 'Virginia',
                code: 'VA',
            },
            {
                name: 'Washington',
                code: 'WA',
            },
            {
                name: 'West Virginia',
                code: 'WV',
            },
            {
                name: 'Wisconsin',
                code: 'WI',
            },
            {
                name: 'Wyoming',
                code: 'WY',
            },
            {
                name: 'Armed Forces Americas',
                code: 'AA',
            },
            {
                name: 'Armed Forces Europe',
                code: 'AE',
            },
            {
                name: 'Armed Forces Pacific',
                code: 'AP',
            },
        ],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.zipCode',
            zone: 'entities.location.commonSettings.fields.region.state',
        },
    },
    {
        name: 'Uruguay',
        code: 'UY',
        continent: 'South America',
        phoneNumberPrefix: 598,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Uzbekistan',
        code: 'UZ',
        continent: 'Asia',
        phoneNumberPrefix: 998,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Vanuatu',
        code: 'VU',
        continent: 'Oceania',
        phoneNumberPrefix: 678,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Vatican City',
        code: 'VA',
        continent: 'Europe',
        phoneNumberPrefix: 39,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Venezuela',
        code: 'VE',
        continent: 'South America',
        phoneNumberPrefix: 58,
        zones: [
            {
                name: 'Amazonas',
                code: 'VE-Z',
            },
            {
                name: 'Anzoátegui',
                code: 'VE-B',
            },
            {
                name: 'Apure',
                code: 'VE-C',
            },
            {
                name: 'Aragua',
                code: 'VE-D',
            },
            {
                name: 'Barinas',
                code: 'VE-E',
            },
            {
                name: 'Bolívar',
                code: 'VE-F',
            },
            {
                name: 'Carabobo',
                code: 'VE-G',
            },
            {
                name: 'Cojedes',
                code: 'VE-H',
            },
            {
                name: 'Delta Amacuro',
                code: 'VE-Y',
            },
            {
                name: 'Federal Dependencies',
                code: 'VE-W',
            },
            {
                name: 'Capital',
                code: 'VE-A',
            },
            {
                name: 'Falcón',
                code: 'VE-I',
            },
            {
                name: 'Guárico',
                code: 'VE-J',
            },
            {
                name: 'Vargas',
                code: 'VE-X',
            },
            {
                name: 'Lara',
                code: 'VE-K',
            },
            {
                name: 'Mérida',
                code: 'VE-L',
            },
            {
                name: 'Miranda',
                code: 'VE-M',
            },
            {
                name: 'Monagas',
                code: 'VE-N',
            },
            {
                name: 'Nueva Esparta',
                code: 'VE-O',
            },
            {
                name: 'Portuguesa',
                code: 'VE-P',
            },
            {
                name: 'Sucre',
                code: 'VE-R',
            },
            {
                name: 'Táchira',
                code: 'VE-S',
            },
            {
                name: 'Trujillo',
                code: 'VE-T',
            },
            {
                name: 'Yaracuy',
                code: 'VE-U',
            },
            {
                name: 'Zulia',
                code: 'VE-V',
            },
        ],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Vietnam',
        code: 'VN',
        continent: 'Asia',
        phoneNumberPrefix: 84,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Wallis & Futuna',
        code: 'WF',
        continent: 'Oceania',
        phoneNumberPrefix: 681,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Western Sahara',
        code: 'EH',
        continent: 'Africa',
        phoneNumberPrefix: 212,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Yemen',
        code: 'YE',
        continent: 'Asia',
        phoneNumberPrefix: 967,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Zambia',
        code: 'ZM',
        continent: 'Africa',
        phoneNumberPrefix: 260,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
    {
        name: 'Zimbabwe',
        code: 'ZW',
        continent: 'Africa',
        phoneNumberPrefix: 263,
        zones: [],
        labels: {
            address1: 'Address',
            address2: 'Apartment, suite, etc.',
            city: 'City',
            company: 'Company',
            country: 'Country/region',
            firstName: 'First name',
            lastName: 'Last name',
            phone: 'Phone',
            postalCode: 'entities.location.commonSettings.fields.postalCode.postalCode',
            zone: 'entities.location.commonSettings.fields.region.region',
        },
    },
];
