import { Container, Content, Footer, Heading, Section } from '@/entities/section/samples/_options';
import { INPUT_SWITCH } from '@/helpers/fieldTypes.no-vuetify';

const section = {
    id: '',
    type: 'SectionContactsStyle3',
    required: false,
    group: 'Contacts',
    options: {
        section: { ...Section },
        container: { ...Container },
        heading: { ...Heading },
        content: {
            htmlBefore: { ...Content.htmlBefore },
            htmlAfter: { ...Content.htmlAfter },
            colorText: { ...Content.color },
            width: {
                value: 'content',
                component: 'ButtonToggles',
                ...{
                    props: {
                        labelTrans: 'builder.sectionOptions.props.width',
                        translate: true,
                        items: [
                            { icon: 'width_full', text: 'Full', value: 'full' },
                            { icon: 'width_wide', text: 'Content', value: 'content' },
                        ],
                    },
                },
            },
            personalSettings: {
                value: false,
                ...INPUT_SWITCH,
                props: {
                    // noLabel: true,
                    size: 'small',
                    labelTrans: 'builder.sectionOptions.props.btnPersonalSettings',
                    note: 'builder.sectionOptions.props.btnPersonalSettingsNote',
                    text: 'builder.sectionOptions.props.btnPersonalSettings',
                },
            },
            mapUrl: {
                ...Content.mapUrl,
                props: {
                    disabledBy: 'content.personalSettings',
                    note: 'builder.sectionOptions.props.mapUrlNote',
                },
            },
        },
        footer: { ...Footer },
    },
    items: [],
    children: null,
};

export default section;
