import ApiClient from '@/api/apiClient';
import CustomError from '@/entities/customError';
import {
    EntitySearchResult,
    GlobalSearchResult,
    EntitySearchSearchables,
    GlobalSearchSearchables,
    SearchRequestParams,
} from '@/services/services.types';

class SearchService {
    /**
     * Поиск с учётом searchable и оборачиванием результатов в классы entity
     * @param {String} searchable - сущность для поиска
     * @param {SearchRequestParams} params - объект параметров поиска
     * @param {String} params.q - строка поиска
     * @param {Array} params.fields - массив названий полей сущности, которые нужно вернуть
     * @param {Number} params.offset - отступ для пагинации
     * @param {Number} params.limit - количество записей, которые нужно получить
     *
     */

    async entitySearch(
        searchable: string,
        params: SearchRequestParams = {}
    ): Promise<[CustomError | null, EntitySearchResult<EntitySearchSearchables> | {}]> {
        const url: string = searchable ? `/search/${searchable}` : '/search';
        const defaultValue = {};
        const errorPath: string = '[api:search:entitySearch]';
        const config = { params };
        const [error, result]: any = await ApiClient.admin.get({ url, defaultValue, errorPath, config });
        return [
            error,
            {
                data: result?.results.map((item) => item.object),
                count: result?.totalResultCount,
                searchable: result?.searchable,
            },
        ];
    }

    /**
     * Глобальный поиск по сайту без учёта searchable и с возвращением "сырого" объекта результатов
     * @param {SearchRequestParams} params - объект параметров поиска
     * @param {String} params.q - строка поиска
     * @param {Array} params.fields - массив названий полей сущности, которые нужно вернуть
     * @param {Number} params.offset - отступ для пагинации
     * @param {Number} params.limit - количество записей, которые нужно получить
     *
     */

    async globalSearch(
        params: SearchRequestParams = {}
    ): Promise<[CustomError | null, GlobalSearchResult<GlobalSearchSearchables>[] | {}]> {
        const url: string = '/search';
        const defaultValue = [];
        const errorPath: string = '[api:search:globalSearch]';
        const config = { params };
        return await ApiClient.admin.get({ url, defaultValue, errorPath, config });
    }

    /**
     * Поиск с группировкой результатов
     * @param {SearchRequestParams} params - объект параметров поиска
     * @param {String} [params.q] - строка поиска
     * @param {Array} [params.fields] - массив названий полей сущности, которые нужно вернуть
     * @param {Number} [params.offset] - отступ для пагинации
     * @param {Number} [params.limit] - количество записей, которые нужно получить
     *
     */

    async searchGrouped(
        params: SearchRequestParams = {}
    ): Promise<[CustomError | null, GlobalSearchResult<GlobalSearchSearchables>[] | []]> {
        const url: string = '/search/grouped';
        const defaultValue = [];
        const errorPath: string = '[api:search:searchGrouped]';
        const config = { params };
        return await ApiClient.admin.get({ url, defaultValue, errorPath, config });
    }
}

export default new SearchService();
