import { cloneDeep } from 'lodash';
import ProductVariation from '@/entities/product/ProductVariation';
import Product from '@/entities/product/Product';

export function refillVariations(variations, oldVariations) {
    if (!variations.length) return [];
    const newVariations = cloneDeep(variations);
    for (let i = 0; i < variations.length; i++) {
        const newVarValues = variations[i].productOptionValueIds;
        const oldVar = oldVariations.find((variation) => {
            return variation.productOptionValueIds.every((oldValue) => newVarValues.includes(oldValue));
        });
        if (oldVar) {
            newVariations[i].fullPrice = oldVar.fullPrice;
            newVariations[i].discount = oldVar.discount;
        }
    }
    return newVariations;
}

export function onUpdateVariations(options, variations) {
    const getVariations = (variations = [], options, index) => {
        if (index >= options.length) return variations;

        // Если у опции нет значений или она не участвует в вариациях, то переходим к следующей опции
        if (!options[index].values?.length) {
            return getVariations(variations, options, index + 1);
        }

        const currentVariations = getCurrentLevelVariations(options[index]);

        // Если нет исходных вариаций, значит это первая опция для вариаций и нужно перейти к следующей
        if (!variations.length) return getVariations(currentVariations, options, index + 1);

        const resultVariations = variations.reduce((acc, variation) => {
            return [
                ...acc,
                ...currentVariations.map(({ title, productOptionValueIds }) => {
                    return new ProductVariation({
                        title: `${variation.title}/${title}`,
                        productOptionValueIds: [...variation.productOptionValueIds, ...productOptionValueIds],
                    });
                }),
            ];
        }, []);

        if (options.length > ++index) return getVariations(resultVariations, options, index);
        return resultVariations;
    };
    const getCurrentLevelVariations = (option) => {
        return option.values.map(({ entryValue, optionValue }) => {
            return new ProductVariation({
                title: optionValue.title,
                productOptionValueIds: [entryValue.id],
            });
        });
    };

    let newVariations = getVariations([], options, 0);
    newVariations = refillVariations(newVariations, variations);

    return newVariations.length ? newVariations : [Product.createDefaultVariation()];
    // this.setDefaultVariation();
}
