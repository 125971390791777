<template>
    <modal :header="$t('entities.image.properties')" v-model="model" width="600px">
        <div v-if="model" class="image-properties-modal form modal__content">
            <Img max-height="167px" max-width="167px" contain class="image__image" :src="image.src" />
            <form-item class="form__input" v-bind="imageModel.fields.name.props">
                <input-text @update-field="onUpdateFieldValue" v-model="imageModel.file.name" name="name"></input-text>
            </form-item>
            <form-item class="form__input" v-bind="imageModel.fields.alt.props">
                <input-text v-model="imageModel.file.alt" name="alt" @update-field="onUpdateFieldValue"></input-text>
            </form-item>
            <form-item class="form__input" v-bind="imageModel.fields.title.props">
                <input-text v-model="imageModel.file.title" name="alt" @update-field="onUpdateFieldValue"></input-text>
            </form-item>
        </div>
        <Button class="modal__save-btn" @click="updateImage" icon="check">{{ $t('entities.save') }}</Button>
    </modal>
</template>

<script>
import { proxyModelMixin } from '@/mixins/proxyModelMixin';
import Modal from '@/components/common/Modal';
import Button from '@/components/common/Button';
import FormItem from '@/components/form/item';
import { debounce, cloneDeep } from 'lodash';
import validateField from '@/helpers/validator';
import EntityImage from '@/entities/image/EntityImage';
import InputText from '@/components/form/controls/InputText';
import Img from '@/components/common/Img';

export default {
    name: 'ProductImagePropertiesModal',
    components: {
        Img,
        InputText,
        FormItem,
        Modal,
        Button,
    },
    data() {
        return {
            imageModel: {},
        };
    },
    mixins: [proxyModelMixin],
    props: {
        value: {
            type: Boolean,
        },
        image: {
            type: Object,
            default: () => new EntityImage(),
        },
    },
    methods: {
        updateImage() {
            this.$emit('update-image-props', {
                id: this.imageModel.file.id,
                title: this.imageModel.file.title,
                name: this.imageModel.file.name,
                alt: this.imageModel.file.alt,
            });
        },
        onUpdateFieldValue: debounce(function(payload) {
            const { name } = payload;
            const errors = validateField(this.imageModel.file[name], this.imageModel.fields[name].props.rules);
            this.imageModel.fields[name].props.errors = errors;
        }, 1000),
    },
    watch: {
        model(val) {
            if (val) {
                this.imageModel = cloneDeep(this.image);
            }
        },
    },
};
</script>

<style lang="scss">
.image-properties-modal {
    flex-direction: row !important;
    gap: 24px;
    & .-horizontal {
        width: 100%;
        & .modal__input:first-child {
            margin-top: 0;
        }
    }
}
</style>
