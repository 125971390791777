import { uuid } from 'vue-uuid';
import OrderItemOption from './OrderItemOption';

/**
 * Класс экземпляра элемента в заказе
 */
export default class OrderItem {
    /**
     *
     * @param {Object}  data - объект данных элемента заказа
     */
    constructor({
        id = uuid.v4(),
        title = '',
        variationId = '',
        fullPrice = 0,
        discountPrice = 0,
        productId = '',
        imageId = '',
        quantity = 1,
        options = [],
    } = {}) {
        this.id = id;
        this.title = title;
        this.variationId = variationId;
        this.fullPrice = fullPrice;
        this.discountPrice = discountPrice;
        this.productId = productId;
        this.imageId = imageId;
        this.quantity = quantity;
        this.options = options.map((optionData) => new OrderItemOption(optionData));
    }

    get data() {
        return {
            id: this.id,
            title: this.title,
            fullPrice: this.fullPrice,
            discountPrice: this.discountPrice,
            variationId: this.variationId,
            imageId: this.imageId,
            quantity: this.quantity,
            //options: this.options.map((option) => option.data),
        };
    }

    addOption(optionData) {
        this.options.push(new OrderItemOption(optionData));
    }
    getFinalPrice() {
        return this.discountPrice * this.quantity;
    }
}
