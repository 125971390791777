<template>
    <div class="page domains -width-lg">
        <domain-primary-modal
            v-model="showPrimaryModal"
            :domain="primaryModalDomain"
            @change-primary="changePrimary"
        ></domain-primary-modal>
        <template v-if="!isAvailable">
            <div class="page-header">
                <h1 class="page-header__text">{{ $t('menus.pageHeaders.domains') }}</h1>
            </div>
        </template>
        <error-block v-if="!isAvailable"></error-block>
        <template v-else>
            <domain-modal @action-success="getItems" v-model="isCreateModalOpen"></domain-modal>
            <div class="page-header">
                <h1 class="page-header__text">{{ $t('menus.pageHeaders.domains') }}</h1>
                <Button @click="openCreateModal">{{ $t('entities.add') }}</Button>
            </div>
            <div v-if="showWarning" class="domains__alert alert -warning">
                <span class="alert__icon icon">warning</span>
                <div>
                    <p class="alert__title">{{ $t('entities.domain.alert.issues') }}</p>
                    <p class="alert__text" @click="showTooltips(['domain-confirmed'], { width: 700 })">
                        {{ $t('entities.domain.alert.details') }} <span class="icon">keyboard_arrow_right</span>
                    </p>
                </div>
                <span @click="showWarning = false" class="alert__close icon">close</span>
            </div>
            <Table
                class="domains__table"
                :columns="headers"
                :show-selected-actions="false"
                :items="items"
                :is-data-loaded="isLoaded"
                @delete-selected-items="openDeletionModal"
            >
                <!-- ACTIONS -->
                <template v-slot:item.actions="{ item }">
                    <div class="table__actions">
                        <Button v-if="!item.primary" @click="openDeletionModal(item)" icon="delete" type="icon" />
                    </div>
                </template>
                <!-- STATUS -->
                <template v-slot:item.status="{ item }">
                    <div class="domains__chips">
                        <chip v-if="item.confirmed" type="success" icon="check">{{
                            $t('entities.domain.tooltips.isConfirmed')
                        }}</chip>
                        <chip
                            v-else
                            type="error"
                            icon="close"
                            @click="
                                showTooltips(['domain-confirmed'], {
                                    width: 700,
                                    'domain-confirmed': { domain: item.name },
                                })
                            "
                            >{{ $t('entities.domain.tooltips.isNotConfirmed') }}
                            <span class="material-icons-outlined chip__info-icon">error_outline</span></chip
                        >
                        <chip v-if="item.canBeUsedInEmail" type="success" icon="check">
                            {{ $t('entities.domain.tooltips.canBeUsedInEmail') }}
                        </chip>
                        <chip
                            @click="
                                showTooltips(['domain-email'], { width: 700, 'domain-email': { domain: item.name } })
                            "
                            v-else
                            icon="close"
                            type="error"
                        >
                            {{ $t('entities.domain.tooltips.cantBeUsedInEmail') }}
                            <span class="material-icons-outlined chip__info-icon">error_outline</span>
                        </chip>
                        <chip v-if="item.email" icon="email">
                            {{ $t('entities.domain.tooltips.asEmail') }}
                        </chip>
                    </div>
                </template>
                <!-- TYPE -->
                <template v-slot:item.type="{ item }">
                    <div class="domains__primary-column">
                        <Button class="domains__status-button -active" v-if="item.primary">Primary</Button>
                        <Button
                            @click="openPrimaryModal(item)"
                            class="domains__status-button"
                            :disabled="!item.confirmed"
                            :loading="primaryLoadingName === item.name"
                            v-else
                        >
                            <template>Redirecting</template>
                            <template v-slot:hover>Set as primary</template>
                        </Button>
                    </div>
                </template>
                <!-- NAME -->
                <template v-slot:item.name="{ item }">
                    <div class="domains__title">
                        {{ item.name }}
                    </div>
                </template>
                <template v-slot:header.status>
                    {{ $t('lists.columns.status') }}
                    <span
                        @click="showTooltips(['domain-confirmed', 'domain-email'], { width: 700 })"
                        class="tooltip__btn table-header__tooltip-btn material-symbols-outlined"
                        >error_outline</span
                    >
                </template>
                <template v-slot:header.type>
                    {{ $t('lists.columns.type') }}
                    <span
                        @click="showTooltips(['domain-type'], { width: 700 })"
                        class="tooltip__btn table-header__tooltip-btn material-symbols-outlined"
                        >error_outline</span
                    >
                </template>
            </Table>
        </template>
    </div>
</template>

<script>
import DomainService from '@/services/DomainService.ts';
import EventEmitter from '@/helpers/eventEmitter.ts';
import { mapGetters } from 'vuex';
import DomainModal from '@/components/domain/DomainModal';
import Table from '@/components/common/Table';
import Button from '@/components/common/Button';
import Domain from '@/entities/domain/Domain';
import Chip from '@/components/common/Chip';
import DomainPrimaryModal from '@/components/domain/DomainPrimaryModal';
import ErrorBlock from '@/components/common/ErrorBlock';
//import { reset } from '@/api/auth';

export default {
    name: 'Domains',
    async created() {
        EventEmitter.on('delete-domain', this.deleteItems);
        await this.getItems();
    },
    components: {
        DomainPrimaryModal,
        Chip,
        Button,
        DomainModal,
        Table,
        ErrorBlock,
    },
    computed: {
        ...mapGetters('globalVars', {
            instance: 'getInstance',
        }),
        headers() {
            return [
                { text: this.$t('lists.columns.domain'), value: 'name' },
                { text: this.$t('lists.columns.status'), value: 'status' },
                { text: this.$t('lists.columns.type'), value: 'type' },
                { value: 'actions', width: '114px' },
            ];
        },
    },
    data: () => ({
        items: [],
        isLoaded: false,
        isCreateModalOpen: false,
        showWarning: false,
        primaryLoadingName: '',
        isAvailable: true,
        showPrimaryModal: false,
        primaryModalDomain: null,
    }),
    methods: {
        async getItems() {
            this.isLoaded = false;
            await this.getDomains();
            if (this.items.some((item) => !item.confirmed)) {
                this.showWarning = true;
            }
            this.isLoaded = true;
        },
        async getDomains() {
            const [error, result] = await DomainService.getAll(this.instance);
            if (error) {
                error.alert();
                this.isAvailable = false;
                return;
            }
            this.items = result.map((item) => new Domain(item));
        },

        openPrimaryModal(item) {
            this.showPrimaryModal = true;
            this.primaryModalDomain = item;
        },

        openDeletionModal(items) {
            this.$store.dispatch('modals/openDeletionModal', { items, entity: 'domain' });
        },

        async deleteItems([item]) {
            this.isLoaded = false;
            const [error] = await DomainService.removeOne(this.instance, item.name);
            this.selected = [];
            this.$store.dispatch('modals/closeDeletionModal');
            await this.getItems();
            if (!error) {
                EventEmitter.trigger('show-noty', {
                    type: 'success',
                    text: this.$tc('notifications.deleted', 1, { entity: this.$tc('entities.domain.title', 1) }),
                });
            } else {
                error.notify();
            }
            this.isLoaded = true;
        },
        openCreateModal() {
            this.isCreateModalOpen = true;
        },
        showTooltips(tooltips, config) {
            EventEmitter.trigger('toggle-tooltips', {
                isShown: true,
                tooltips,
                config,
            });
        },
        async changePrimary() {
            const domain = this.primaryModalDomain;
            if (this.primaryLoadingName) return;
            this.primaryLoadingName = domain.name;
            const [error] = await DomainService.updatePrimary(this.instance, domain.name);
            if (!error) {
                const oldPrimary = this.items.find((item) => item.primary);
                oldPrimary.primary = false;
                domain.primary = true;
                // location.replace(`https://${domain.name}/admin`);
                window.location.reload();
            } else {
                error.notify();
            }
            this.primaryLoadingName = '';
        },
    },
    beforeDestroy() {
        EventEmitter.off('delete-domain', this.deleteItems);
    },
};
</script>
<style lang="scss">
@import '@/scss/variables.scss';
.domains {
    .alert {
        display: flex;
        gap: 16px;
        padding: 25px;
        border-radius: 4px;

        &.-warning {
            background-color: rgb(253, 233, 237);
            border: 1px solid var(--v-warning-base);

            .alert__text {
                color: var(--v-warning-base);
                width: fit-content;

                &:hover {
                    cursor: pointer;
                    opacity: 0.7;
                }
            }

            .alert__icon {
                margin-top: 3px;
            }
        }

        &__icon {
            font-size: 22px;
        }

        &__title {
            font-size: 19px;
            font-weight: 500;
            margin-bottom: 11px !important;
        }

        &__text {
            margin-bottom: 0 !important;
            font-weight: 500;
            display: flex;
            align-items: center;
            gap: 6px;
        }

        &__close {
            font-size: 24px;
            align-self: start;
            margin-left: auto;

            &:hover {
                opacity: 0.7;
                cursor: pointer;
            }
        }
    }

    .table-header__tooltip-btn {
        margin-left: 5.5px;
        vertical-align: middle;
        margin-bottom: 2px;
    }
}
.domains {
    &__alert {
        margin-bottom: 24px;
    }
    .table-header__tooltip-btn {
        &:hover {
            cursor: pointer;
        }
    }
    &__table {
        td {
            padding: 20px 12px !important;
        }
        .v-card__text {
            padding-top: 36px !important;
        }
    }
    &__status-button {
        &.-active {
            background-color: var(--v-primary-darken-base) !important;
            color: var(--v-surface-base) !important;
            &:hover {
                background-color: var(--v-primary-darken-base) !important;
                color: var(--v-surface-base) !important;
                cursor: initial;
            }
        }
        &.button.-disabled:not(.button--flat):not(.button--text):not(.button--outlined) {
            background-color: #e6e8eb !important;
            color: var(--v-on-surface-disabled-base) !important;
            cursor: initial !important;
        }
        &:hover {
            transition: none;
            background-color: var(--v-primary-accent-base) !important;
            color: var(--v-on-primary-high-base) !important;
        }
        transition: none;
        background-color: #e6e8eb;
        color: black !important;
        width: 144px;
        height: 32px;
        border-radius: 50px;
    }

    &__modal {
        min-height: 333px !important;
    }
    &__title {
        height: 36px;
        display: flex;
        align-items: center;
    }
    &__chips {
        display: flex;
        align-items: center;
        gap: 8px;
    }
    .chip__info-icon {
        font-size: 16px;
        margin-left: 2px;
        margin-bottom: 1px;
    }
}
</style>
