<template>
    <div class="checkbox" @click="handleClick" :class="{ '-disabled': disabled }">
        <input type="checkbox" class="checkbox__input" :checked="isInputChecked" :disabled="disabled" />
        <span class="checkbox__icon icon" :class="{ '-checked': multiple ? model.length : model }">
            {{ icon }}
        </span>
        <slot>
            <div v-if="text" class="checkbox__text">{{ $t(text) }}</div>
        </slot>
    </div>
</template>

<script>
import { proxyModelMixin } from '@/mixins/proxyModelMixin';

export default {
    name: 'InputCheckbox',

    inheritAttrs: false,

    mixins: [proxyModelMixin],

    props: {
        errors: { type: [Array, Object], default: () => [] },
        disabled: {
            type: Boolean,
            default: false,
        },
        value: {
            type: [Boolean, Array],
        },
        name: {
            type: String,
        },
        group: {
            type: String,
        },
        text: {
            type: String,
            default: '',
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        items: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        icon() {
            if (this.multiple) {
                return this.value.length === this.items.length && this.items.length
                    ? 'check_box'
                    : this.value.length
                    ? 'indeterminate_check_box'
                    : 'check_box_outline_blank';
            }
            return this.value ? 'check_box' : 'check_box_outline_blank';
        },
        isInputChecked() {
            if (this.multiple) {
                return this.value.length === this.items.length;
            }
            return this.model;
        },
    },

    methods: {
        handleClick() {
            if (this.disabled) return;
            if (this.multiple) {
                this.model = this.model.length === this.items.length ? [] : [...this.items];
            } else {
                this.model = !this.model;
            }
        },
    },

    watch: {
        value: function(newValue) {
            this.$emit('update-field', {
                name: this.name,
                group: this.group,
                value: newValue,
            });
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.checkbox {
    display: flex;
    gap: 10px;
    flex-wrap: nowrap;
    align-items: center;
    &.-disabled {
        .checkbox__icon {
            color: var(--v-on-surface-disabled-base);
        }
    }
    &__text {
        font-size: 14px;
        color: black;
    }
    &__icon {
        font-size: 18px;
        font-variation-settings: 'FILL' 1, 'wght' 500, 'GRAD' 0, 'opsz' 48;
        color: var(--v-primary-base);
        &:hover {
            color: var(--v-primary-accent-base);
        }
    }
    &__input {
        display: none;
    }
    &:hover {
        cursor: pointer;
    }
}
</style>
