<template>
    <div class="image__drag-item">
        <div class="image__item">
            <div v-if="controls" class="image__controls">
                <Button
                    v-if="draggable"
                    type="icon"
                    class="image__controls-btn -drag-n-drop"
                    icon="drag_indicator"
                ></Button>

                <Button type="icon" class="image__controls-btn -hidden" icon="notes" @click="editImage"></Button>
                <Button
                    type="icon"
                    class="image__controls-btn -hidden"
                    icon="delete"
                    @click="$emit('delete-image', image)"
                ></Button>
            </div>
            <!--                :class="['ui-image-manager__image', { 'is-selected': image.id === value.id }]"-->
            <!--                @click="selectImage(image)"-->

            <img
                height="167px"
                :class="{ 'is-selected': selected }"
                @click="$emit('select-image', image)"
                width="167px"
                class="image__image"
                :src="finalSrc"
            />
        </div>
    </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import Button from '@/components/common/Button';
import { imageUrl } from '@/helpers/values';
export default {
    name: 'Img',
    components: { Button },
    data() {
        return {
            imageUrl,
        };
    },
    props: {
        image: {
            type: Object,
            default: () => {},
        },
        src: {
            type: String,
            default: '',
        },
        controls: {
            type: Boolean,
            default: true,
        },
        draggable: {
            type: Boolean,
            default: false,
        },
        selected: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        editImage() {
            const editingImage = cloneDeep(this.image);
            if (!editingImage.src && this.src) {
                editingImage.src = this.src;
            }
            this.$emit('edit-image', editingImage);
        },
    },
    computed: {
        finalSrc() {
            return `${this.imageUrl()}/${this.image && this.image.src ? this.image.src : this.src}`;
        },
    },
};
</script>

<style scoped></style>
