<template>
    <div class="customize-background" :class="{ '-disabled': disabled }">
        <label class="customize-background__item">
            <span class="customize-background__title">Color:</span>
            <ColorPicker v-model="value.color" />
        </label>
        <label v-for="name in Object.keys(css)" class="customize-background__item" :key="name">
            <template v-if="showOption(name)">
                <span class="customize-background__title">{{ name }}:</span>
                <Select v-model="curent[name]" :items="css[name]" :name="name" />
            </template>
        </label>
        <div class="customize-background__item">
            <InputImageBuilder v-model="value.image" note="" :btnReset="true" />
        </div>
    </div>
</template>

<script>
import { proxyModelMixin } from '@/mixins/proxyModelMixin';
export default {
    name: 'CustomizeBackground',
    mixins: [proxyModelMixin],
    props: {
        name: { type: String, default: '' },
        group: { type: String, default: null },
        type: { type: String, default: 'array' },
        errors: { type: [Array, Object], default: () => [] },
        value: { type: [Number, String, Object], default: null },
        disabled: { type: Boolean, default: false },
    },
    model: {
        prop: 'value',
        event: 'change-value',
    },
    data() {
        return {
            curent: null,
            config: {
                position: 'center',
                size: 'auto',
                repeat: 'no-repeat',
                color: null,
                image: null,
            },
            css: {
                size: [
                    { text: 'auto', value: 'auto' },
                    { text: 'contain', value: 'contain' },
                    { text: 'cover', value: 'cover' },
                ],
                position: [
                    { text: 'bottom', value: 'bottom' },
                    { text: 'center', value: 'center' },
                    { text: 'left', value: 'left' },
                    { text: 'right', value: 'right' },
                    { text: 'top', value: 'top' },
                ],
                repeat: [
                    { text: 'repeat', value: 'repeat' },
                    { text: 'no-repeat', value: 'no-repeat' },
                    { text: 'repeat-x', value: 'repeat-x' },
                    { text: 'repeat-y', value: 'repeat-y' },
                ],
            },
        };
    },
    mounted() {
        this.curent = { ...this.config, ...this.value };
        this.$emit('change-value', this.curent);
    },
    watch: {
        'curent.size'(value) {
            if (value !== 'cover') return;
            this.curent.repeat = 'no-repeat';
            this.curent.position = 'center';
        },
    },
    methods: {
        showOption(name) {
            const sizeIsCover = this.curent?.size === 'cover' ? true : false;
            if (!this.curent) return false;
            if (name !== 'size' && sizeIsCover) return false;
            return true;
        },
    },
};
</script>

<style lang="scss">
.customize-background {
    &__item {
        &:last-child {
            margin-top: 8px;
        }
    }
    &__item {
        display: block;
        position: relative;
        width: 100%;
        &:hover {
            z-index: 15;
        }

        .select,
        .color-picker,
        .input-icon,
        .input-text {
            margin-top: -10px;
        }
        .input-icon {
            i {
                margin-top: 4px;
            }
        }
    }

    &__title {
        display: inline-block;
        padding: 0 5px;
        font-size: 0.75em;
        background: #fff;
        position: relative;
        z-index: 1;
        margin-left: 8px;
        border-radius: 4px;
        text-transform: capitalize;
    }
}
</style>
