var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal',{attrs:{"header":_vm.$t('lists.editButton.product'),"width":"1300"},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},[(_vm.isLoaded)?_c('Table',{staticClass:"variations-table",attrs:{"show-toolbar":false,"show-selected-actions":false,"show-select-all":false,"columns":_vm.headers,"items":_vm.localProductVariations},on:{"update:items":function($event){_vm.localProductVariations=$event}},scopedSlots:_vm._u([{key:"item.default",fn:function(ref){
var item = ref.item;
return [_c('input-radio',{staticClass:"variations-table__radio",attrs:{"value":item.default},on:{"input":function($event){return _vm.changeDefaultVariation(item, $event)}}})]}},{key:"item.imageId",fn:function(ref){
var item = ref.item;
return [_c('product-image-field',{attrs:{"upload":false,"image-type":"product","owner-id":_vm.product.id,"is-popup":""},model:{value:(item.imageId),callback:function ($$v) {_vm.$set(item, "imageId", $$v)},expression:"item.imageId"}})]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.title)+" ")]}},{key:"item.fullPrice",fn:function(ref){
var item = ref.item;
return [_c('form-item',_vm._b({},'form-item',item.fields.fullPrice.props,false),[_c('input-text',_vm._b({attrs:{"type":"number","measurement":_vm.currency,"name":"fullPrice"},on:{"update-field":function($event){return _vm.onUpdateFieldValue($event, item)}},model:{value:(item.fullPrice),callback:function ($$v) {_vm.$set(item, "fullPrice", _vm._n($$v))},expression:"item.fullPrice"}},'input-text',item.fields.fullPrice.props,false))],1)]}},{key:"item.discount",fn:function(ref){
var item = ref.item;
return [_c('form-item',_vm._b({},'form-item',item.fields.discount.props,false),[_c('input-text',_vm._b({attrs:{"name":"discount"},on:{"update-field":function($event){return _vm.onUpdateFieldValue($event, item)}},nativeOn:{"input":function($event){return _vm.controlDiscountInput($event, item)}},model:{value:(item.discount),callback:function ($$v) {_vm.$set(item, "discount", $$v)},expression:"item.discount"}},'input-text',item.fields.discount.props,false))],1)]}},{key:"item.discountPrice",fn:function(ref){
var item = ref.item;
return [_c('form-item',_vm._b({},'form-item',item.fields.discountPrice.props,false),[_c('input-text',{attrs:{"disabled":"","name":"discountPrice","measurement":_vm.currency,"value":item.getFinalPrice()},on:{"update-field":function($event){item.fields.discountPrice.props.errors = []}}})],1)]}},{key:"item.stock",fn:function(ref){
var item = ref.item;
return [_c('form-item',_vm._b({},'form-item',item.fields.stock.props,false),[_c('input-text',_vm._b({attrs:{"type":"number","name":"stock","null-value":"∞"},on:{"update-field":function($event){return _vm.onUpdateFieldValue($event, item)}},model:{value:(item.stock),callback:function ($$v) {_vm.$set(item, "stock", $$v)},expression:"item.stock"}},'input-text',item.fields.stock.props,false))],1)]}},{key:"header.stock",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('lists.columns.quantity'))+" "),_c('tooltip-popup',[_c('span',[_vm._v(_vm._s(_vm.$t('entities.productVariation.tooltips.quantity')))])])]},proxy:true},{key:"header.discount",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('lists.columns.discount'))+" "),_c('tooltip-popup',[_c('span',[_vm._v(_vm._s(_vm.$t('entities.productVariation.tooltips.discount')))])])]},proxy:true}],null,false,232143892)}):_c('progress-circular',{staticClass:"quick-edit__loading",attrs:{"color":"primary"}}),_c('Button',{attrs:{"disabled":!_vm.isSaveAllowed},on:{"click":_vm.updateProduct}},[_vm._v(_vm._s(_vm.$t('entities.save')))])],1)}
var staticRenderFns = []

export { render, staticRenderFns }