<template>
    <div class="page -width-lg">
        <div class="page-header">
            <h1 class="page-header__text">{{ $t('menus.pageHeaders.tagManager') }}</h1>
        </div>
        <alert type="success" class="entity-item__success-alert" v-if="isUpdated">
            {{ $tc('notifications.updated', 1, { entity: this.$tc('entities.seo.tagManager.title', 1) }) }}
        </alert>
        <div class="tag-manager__form" v-if="settings">
            <template v-for="(field, key) in fields">
                <form-item
                    v-if="key === 'customCode'"
                    :key="key"
                    v-bind="field.props"
                    :name="key"
                    class="form__input -full"
                >
                    <Textarea
                        v-bind="fields.customCode.props || {}"
                        @update-field="onUpdateFieldValue"
                        name="customCode"
                        v-on="getFieldEvents(fields.customCode.events)"
                        v-model="settings.customCode.value"
                    ></Textarea>
                    <br />
                    <alert type="warning">
                        {{ $tc('settings.seo.customCode.note') }}
                    </alert>
                </form-item>
                <form-item
                    v-else-if="key === 'customCodeFooter'"
                    :key="key"
                    v-bind="field.props"
                    :name="key"
                    class="form__input -full"
                >
                    <Textarea
                        v-bind="fields.customCodeFooter.props || {}"
                        @update-field="onUpdateFieldValue"
                        name="customCode"
                        v-on="getFieldEvents(fields.customCodeFooter.events)"
                        v-model="settings.customCodeFooter.value"
                    ></Textarea>
                    <br />
                    <alert type="warning">
                        {{ $tc('settings.seo.customCode.note') }}
                    </alert>
                </form-item>
                <form-item v-else :key="key" v-bind="field.props" :name="key" class="form__input">
                    <component
                        :is="field.component"
                        v-model="settings[key].value"
                        v-bind="field.props || {}"
                        @update-field="onUpdateFieldValue"
                        :name="key"
                        v-on="getFieldEvents(field.events)"
                        hide-details
                        outlined
                        dense
                    ></component>
                </form-item>
            </template>
            <div class="tag-manager__footer">
                <Button class="tag-manager__save-btn" icon="check" @click="onUpdateSettings" :disabled="!hasChange">{{
                    $t('entities.save')
                }}</Button>
            </div>
        </div>
    </div>
</template>

<script>
import { cloneDeep, debounce } from 'lodash';
import SettingService from '@/services/SettingService';
import fields from '@/entities/seo/tagmanager.fields';
import EventEmitter from '@/helpers/eventEmitter.ts';
import FormItem from '@/components/form/item';
import validateField from '@/helpers/validator';
import Button from '@/components/common/Button';
import Alert from '@/components/common/Alert';
import Textarea from '@/components/form/controls/Textarea';

export default {
    name: 'TagManager',

    defaultFieldProps: {
        dense: true,
        'hide-details': true,
        outlined: true,
    },

    components: {
        Alert,
        FormItem,
        Button,
        Textarea,
    },

    data() {
        return {
            default: {},
            hasChange: false,
            fields,
            fieldKeys: Object.keys(fields),
            isUpdated: false,
        };
    },

    computed: {
        settings() {
            return this.$store.getters['config/getAllSettings'].seo;
        },
    },

    created() {
        setTimeout(() => {
            this.default = cloneDeep(this.$store.getters['config/getAllSettings'].seo);
            this.checkChanges(this.settings);
        }, 0);
    },

    watch: {
        settings: {
            handler(newValue) {
                this.checkChanges(newValue);
            },
            deep: true,
        },
    },

    methods: {
        checkChanges(newValue) {
            this.hasChange = JSON.stringify(newValue) !== JSON.stringify(this.default);
        },

        async onUpdateSettings() {
            const isFormValid = this.validateForm();
            if (isFormValid === false) return;
            const [error, result] = await SettingService.updateGroup('seo', this.settings);
            error ? this.failedHandler(result) : this.successUpdateHandler();
        },

        successUpdateHandler() {
            this.default = cloneDeep(this.settings);
            this.checkChanges(this.settings);
            this.isUpdated = true;
        },

        validateForm() {
            let result = true;
            const fieldKeys = Object.keys(this.fields);
            fieldKeys.forEach((key) => {
                const errors = validateField(this.fields[key].value, this.settings[key].options.rules);
                if (errors.length !== 0) result = false;
                this.$set(this.fields[key].props, 'errors', errors);
            });
            if (result === false)
                EventEmitter.trigger('show-noty', {
                    type: 'error',
                    text: this.$t('notifications.validation.error'),
                });
            return result;
        },

        failedHandler(response) {
            const children = response.data?.errors?.children;
            if (children) {
                this.fieldKeys.forEach((key) => {
                    const errors = children[key] ? children[key].errors : [];
                    if (errors) this.$set(this.fields[key].props, 'errors', errors);
                });
            }
            EventEmitter.trigger('show-noty', {
                type: 'error',
                text: `Error occurred. Status ${response.error.statusCode}: ${response.error.message}`,
            });
        },

        onUpdateFieldValue: debounce(function(payload) {
            const { name } = payload;
            const errors = validateField(this.fields[name].value, this.settings[name].options.rules);
            this.$set(this.fields[name].props, 'errors', errors);
        }, 600),

        getFieldEvents(events) {
            const fieldEvents = {};
            for (const key in events) {
                fieldEvents[key] = events[key]?.bind(this.settings);
            }
            return fieldEvents;
        },
    },

    beforeDestroy() {
        if (this.hasChange) this.$store.dispatch('config/setSettings', { seo: cloneDeep(this.default) });
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.tag-manager {
    &__form {
        display: flex;
        flex-wrap: wrap;
        gap: 24px;
        padding: 32px 24px;
        .form__input {
            width: 35%;

            &.-full {
                width: calc(70% + 24px);
            }
        }
        background-color: var(--v-surface-base);
    }
    &__footer {
        width: 100%;
        display: flex;
    }
    &__save-btn {
        margin-left: auto;
    }
}
</style>
