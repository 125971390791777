import billingEn from '@/translations/en/billing.en';
import builderEn from '@/translations/en/builder.en';
import dashboardEn from '@/translations/en/dashboard.en';
import entitiesEn from '@/translations/en/entities.en';
import listsEn from '@/translations/en/lists.en';
import menusEn from '@/translations/en/menus.en';
import searchEn from '@/translations/en/search.en';
import settingsEn from '@/translations/en/settings.en';
import validatorEn from '@/translations/en/validator.en';
import countriesEn from '@/translations/en/countries.en';
import notificationsEn from '@/translations/en/notifications.en';
import templateEn from '@/translations/en/template.en';

import billingRu from '@/translations/ru/billing.ru';
import builderRu from '@/translations/ru/builder.ru';
import dashboardRu from '@/translations/ru/dashboard.ru';
import entitiesRu from '@/translations/ru/entities.ru';
import listsRu from '@/translations/ru/lists.ru';
import menusRu from '@/translations/ru/menus.ru';
import searchRu from '@/translations/ru/search.ru';
import settingsRu from '@/translations/ru/settings.ru';
import validatorRu from '@/translations/ru/validator.ru';
import countriesRu from '@/translations/ru/countries.ru';
import notificationsRu from '@/translations/ru/notifications.ru';
import templateRu from '@/translations/ru/template.ru';

export default {
    ru: {
        billing: billingRu,
        builder: builderRu,
        dashboard: dashboardRu,
        entities: entitiesRu,
        lists: listsRu,
        menus: menusRu,
        search: searchRu,
        settings: settingsRu,
        validator: validatorRu,
        countries: countriesRu,
        notifications: notificationsRu,
        template: templateRu,
    },
    en: {
        billing: billingEn,
        builder: builderEn,
        dashboard: dashboardEn,
        entities: entitiesEn,
        lists: listsEn,
        menus: menusEn,
        search: searchEn,
        settings: settingsEn,
        validator: validatorEn,
        countries: countriesEn,
        notifications: notificationsEn,
        template: templateEn,
    },
};
