export default {
    validation: {
        error: 'Ошибка валидации',
    },
    created: '{entity} успешно создан(а) | {entity} успешно созданы',
    updated: '{entity} успешно обновлен(а) | {entity} успешно обновлены',
    deleted: '{entity} успешно удален(а) | {entity} успешно удалены',
    duplicated: '{entity} успешно скопирован(а) | {entity} успешно скопированы',
    imported: 'Продукты успешно импортированы',
    apiIsUnavailable: 'API недоступно в данный момент',
};
