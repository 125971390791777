import { uuid } from 'vue-uuid';
import countriesList from '@/helpers/countries';
import initFields from '@/entities/delivery/ShippingZone.fields';
import { validateNoEmpty } from '@/helpers/validator';
import countries from '@/helpers/countries';
import i18n from '@/plugins/i18n';
import ShippingZoneRate from '@/entities/delivery/ShippingZoneRate';

function validateSelected(val) {
    let isValid = true;
    if (!val.length) isValid = false;
    return { isValid };
}

/**
 * Класс экземпляра зоны
 */
export default class ShippingZone {
    fields = initFields();

    static formatCountriesForTree() {
        //создаём объект со странами, распределёнными по по континентам
        let continents = {};
        countries.forEach((country) => {
            let obj;
            if (country.code !== 'RU') {
                obj = { name: i18n.t(`countries.${country.code}`), code: country.code, hasFlag: true };
            } else {
                obj = { name: i18n.t(`countries.RU.title`), code: country.code, hasFlag: true };
            }
            if (country.zones.length) {
                if (country.code !== 'RU') {
                    obj.children = country.zones.map((region) => {
                        return { name: region.name, code: `${country.code}:${region.code}` };
                    });
                } else {
                    obj.children = country.zones.map((region) => {
                        return {
                            name: i18n.t(`countries.RU.zones.${region.code}`),
                            code: `${country.code}:${region.code}`,
                        };
                    });
                }
            }
            if (continents[country.continent]) {
                continents[country.continent].push(obj);
            } else {
                continents[country.continent] = [obj];
            }
        });
        //создаём из этого объекта массив, который можно скормить treeview
        const finalArr = [];
        for (const key in continents) {
            const [first, ...rest] = key.split(' ');
            const translationKey = `countries.continents.${first.toLowerCase()}${rest.join('')}`;
            const obj = {
                code: key,
                name: i18n.t(translationKey),
                children: continents[key],
            };
            finalArr.push(obj);
        }
        return finalArr;
    }

    static getAllCodes() {
        return countries.reduce((acc, country) => {
            if (country.zones) {
                const zonesCodes = country.zones.map((item) => `${country.code}:${item.code}`);
                acc.push(country.code, ...zonesCodes);
            } else {
                acc.push(country.code);
            }
            return acc;
        }, []);
    }

    /**
     *
     * @param {Object}  data - объект данных зоны
     */
    constructor(
        data = {
            id: uuid.v4(),
            enabled: true,
            name: '',
            zones: [],
            createdAt: new Date(),
            rates: [],
        }
    ) {
        this.zones = data.zones;
        this.id = data.id;
        this.enabled = data.enabled;
        this.name = data.name;
        this.rates = data.rates.map((item) => new ShippingZoneRate(item));
        this.createdAt = data.createdAt;
    }
    get data() {
        return {
            id: this.id,
            enabled: this.enabled,
            name: this.name,
            zones: this.zones,
        };
    }

    formatForSelect() {
        const countries = [];
        const regions = {};

        this.zones.forEach((zone) => {
            if (zone.length === 2) {
                const country = countriesList.find((item) => item.code === zone);
                countries.push(country);
            } else {
                const countryCode = zone.slice(0, 2);
                const country = countriesList.find((item) => item.code === countryCode);
                if (!regions[country.code]) {
                    countries.push(country);
                    regions[country.code] = [];
                }
                const regionCode = zone.slice(3);
                const region = country.zones.find((item) => item.code === regionCode);
                regions[country.code].push(region);
            }
        });
        return { countries, regions };
    }

    validateField(item, value) {
        const rules = item.props?.rules;
        if (!rules || rules.length === 0) return [];
        const errors = [];
        for (const rule of item.props.rules) {
            switch (rule) {
                case 'noEmpty': {
                    if (!validateNoEmpty(value)) errors.push('validator.errors.empty');
                    break;
                }
                case 'selected': {
                    if (!validateSelected(value).isValid) errors.push('validator.errors.notSelected');
                }
            }
        }
        return errors;
    }
}
