<template>
    <div class="image-field" :style="`max-width: ${maxWidth}`">
        <heading type="body-2" v-if="label">{{ label }}</heading>
        <div class="image-field__content">
            <img
                @click="openImagesManager"
                v-if="!imagesManager || isPopup"
                :aspect-ratio="aspectRatio"
                :src="src"
                class="image-field__image"
            />
        </div>
        <image-manager
            v-if="imagesManager && !isPopup"
            v-model="model"
            :owner-id="ownerId"
            :image-type="imageType"
            @close="closeImagesManager"
        />
        <modal :header="$t('entities.image.change')" v-if="isPopup" v-model="changeDialog" width="600">
            <template v-slot:header-append
                ><Button
                    class="image-modal__remove-btn"
                    @click="model = null"
                    type="text"
                    icon="delete"
                    v-if="model && model.uri"
                    >{{ $t('entities.remove') }}</Button
                ></template
            >
            <div class="modal__content" v-if="changeDialog">
                <infinite-image-manager
                    :upload="upload"
                    infinite
                    :controls="false"
                    selectable
                    :owner-id="ownerId"
                    v-model="model"
                    @delete-image="onDeleteImage"
                    :image-type="imageType"
                    @close="closeImagesManager"
                />
            </div>
        </modal>
    </div>
</template>

<script>
import ImageService from '@/services/ImageService';
import Modal from '@/components/common/Modal';
import InfiniteImageManager from '@/components/form/controls/InfiniteImageManager';
import Image from '@/entities/image/Image';
import Button from '@/components/common/Button';
import Heading from '@/components/common/Heading';
import ImageManager from '@/components/form/controls/ImageManager';
import { imageUrl } from '@/helpers/values';

export default {
    name: 'ImageField',

    inheritAttrs: false,

    components: {
        ImageManager,
        Button,
        InfiniteImageManager,
        Modal,
        Heading,
    },

    props: {
        label: {
            type: String,
        },
        value: {
            type: String,
        },
        aspectRatio: {
            type: Number,
            default: 19 / 9.2,
        },
        width: {
            type: String,
            default: '100%',
        },
        maxWidth: {
            type: String,
            default: '100%',
        },
        isPopup: {
            type: Boolean,
        },
        imageType: {
            type: String,
            default: 'content',
        },
        note: {
            type: String,
            default: 'JPG, PNG. Recomended size: 320х432px. No more than 500kb',
        },
        ownerId: {
            type: String,
            default: null,
        },
        upload: {
            type: Boolean,
            default: true,
        },
    },

    async created() {
        await this.getImage();
    },

    data() {
        return {
            image: null,
            imagesManager: false,
            changeDialog: false,
            propertiesDialog: false,
            imageUrl,
        };
    },

    computed: {
        model: {
            get() {
                return this.image;
            },
            set(image) {
                this.image = image;
                const imageId = this.image?.id;
                this.$emit('input', imageId);
            },
        },
        src() {
            return this.image
                ? `${this.imageUrl()}/${this.image.src}`
                : require('@/assets/images/product-no-photo.png');
        },
    },

    methods: {
        openImagesManager() {
            if (this.isPopup) this.changeDialog = true;
            else this.imagesManager = true;
        },
        closeImagesManager() {
            if (this.isPopup) this.changeDialog = false;
            else this.imagesManager = false;
        },

        async getImage() {
            if (!this.value) return;

            const [error, result] = await ImageService.getOne(this.value);
            if (error) {
                error.notify();
            }
            this.image = new Image(result);
        },
        onDeleteImage(image) {
            if (this.model?.id === image.id) {
                this.model = null;
            }
        },
    },

    watch: {
        value(newValue) {
            if (newValue !== this.image?.id) this.getImage();
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.image-field {
    .heading {
        margin-bottom: 8px;
    }

    &__content {
        position: relative;
        width: 80px;
    }

    &__controls {
        padding: 0 !important;
        width: auto !important;
        right: 1px;
        top: 1px;
    }

    &__image {
        width: 60px;
        height: 60px;
        border: 1px solid var(--v-outline-base);
        border-radius: $border-radius-root;
        aspect-ratio: 19 / 9.2;
        object-fit: contain;
        &:hover {
            border-color: var(--v-primary-accent-base);
            cursor: pointer;
        }
    }
    &__controls-btn {
        background: rgba(#ffffff, 0.5) !important;
        border-radius: 4px;
    }

    &__note {
        color: var(--v-on-surface-medium-base);
        margin-top: $spacer * 3;
    }
}
.image-modal {
    &__remove-btn {
        margin-right: auto;
        margin-left: 20px;
        .btn {
            height: auto !important;
        }
    }
}
</style>
