import { uuid } from 'vue-uuid';
import OptionValue from '../option/OptionValue';
import ProductOptionEntry from './ProductOptionEntry';
import ProductOptionValue from './ProductOptionValue';
import Option from '@/entities/option/Option';
// import { cloneDeep } from 'lodash';

/**
 * Класс экземпляра опции товаров
 */
export default class ProductOption {
    /**
     *
     * @param {Object}  data - объект данных опции
     */
    constructor({ id = uuid.v4(), entry = {}, option = {} } = {}) {
        this.id = id;
        this.entry = entry;
        this.option = new Option(option);
        this.values = [];

        if (this.entry?.values.length) {
            this.values = this.entry.values.map((entryValue) => {
                const relateOptionValue = this.option.values.find(
                    (optionValue) => optionValue.id === entryValue.optionValueId
                );
                return new ProductOptionValue({
                    optionValue: relateOptionValue,
                    entryValue: entryValue,
                });
            });
        }
    }

    get option() {
        return this._option;
    }

    set option(option) {
        this._option = new Option(option);
        this.entry.optionId = this.option.id;
    }

    getProductOptionEntry() {
        this.entry.optionId = this.option.id;
        this.entry.values = this.values.map((value) => {
            return {
                id: value.entryValueId,
                optionValueId: value.id,
                position: value.position,
            };
        });
        this.entry = new ProductOptionEntry(this.entry);
        return this.entry;
    }

    setValues(values) {
        this.values = values.map((value) => {
            const optionValue = new OptionValue(value);
            optionValue.entryValueId = '';
        });
    }
}
