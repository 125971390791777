<template>
    <div class="rating">
        <span class="material-icons-outlined rating__item" @click="model = item" v-for="item in 5" :key="item">{{
            item > model ? `star_outline` : `star`
        }}</span>
    </div>
</template>

<script>
import { proxyModelMixin } from '@/mixins/proxyModelMixin';

export default {
    name: 'Rating',
    props: {
        value: { type: Number, default: 0 },
    },
    mixins: [proxyModelMixin],
};
</script>

<style lang="scss">
.rating {
    color: rgb(245, 152, 0);
    &__item {
        display: inline-block;
        padding: 0.5rem;
        font-size: 18px;
        cursor: pointer;
        border-radius: 50px;
        transition: all 0.2s ease;
        &:active {
            transition: all 0.2s ease;
            background-color: rgba(245, 152, 0, 0.2);
        }
    }
}
</style>
