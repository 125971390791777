<template>
    <div class="form-wrapper">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'Form',
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.form-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    gap: 24px;
}
</style>
