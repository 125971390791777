<template>
    <ul class="directory-tree__list">
        <directory-tree-list-item
            v-for="(item, index) in list"
            :key="index"
            :item="item"
            v-model="modelValue"
            :parent="parent"
        />
    </ul>
</template>

<script>
export default {
    name: 'DirectoryTreeList',
    components: {
        DirectoryTreeListItem: () =>
            import('@/components/base/SimpleFileManager/DirectoryTree/DirectoryTreeListItem.vue'),
    },
    props: {
        list: {
            type: Array,
            required: true,
        },
        value: {
            type: Array,
        },
        parent: {
            type: Array,
        },
    },
    computed: {
        modelValue: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.directory-tree__list {
    margin: 0;
    padding: 0;
    list-style: none;

    &--nested {
        // display: none;
    }
}
</style>
