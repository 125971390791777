<template>
    <div class="input-number-and-range">
        <ScreenTabs>
            <template v-for="screen in screens" #[screen]>
                <div :key="screen">
                    <InputNumberAndRange v-model="value[screen]" :min="min" :max="max" />
                </div>
            </template>
        </ScreenTabs>
    </div>
</template>

<script>
import { proxyModelMixin } from '@/mixins/proxyModelMixin';
import ScreenTabs from '@/components/form/controls/utils/ScreenTabs.vue';

export default {
    name: 'InputNumberAndRangeScreen',
    components: { ScreenTabs },
    inheritAttrs: false,
    mixins: [proxyModelMixin],
    props: {
        name: { type: String, default: '' },
        group: { type: String, default: null },
        type: { type: String, default: 'text' },
        placeholder: { type: [String, Number], default: '' },
        errors: { type: [Array, Object], default: () => [] },
        value: { type: [String, Number, Object, Date] },
        disabled: { type: Boolean, default: false },
        min: { type: Number, default: 0 },
        max: { type: Number, default: 100 },
    },
    data() {
        return {
            screens: ['desktop', 'tablet', 'mobile'],
        };
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';

.input-number-and-range {
    position: relative;
    display: block;

    &:hover {
        border-color: var(--v-on-surface-medium-base);
    }
    &:focus-within {
        border-color: var(--v-primary-accent-base);
    }
    &__number {
        border: $form-control-border;
        border-radius: $form-control-border-radius;
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        height: 40px;
        padding: 0 $form-control-padding-horizontal;
        background: $form-control-bg;
        transition: $form-control-transition;
        width: 100%;
        appearance: none;
        &:focus {
            outline: none;
        }
        &::placeholder {
            color: inherit;
            opacity: 0.7;
        }

        &:hover {
            border-color: var(--v-on-surface-medium-base);
        }
        &:focus-within {
            border-color: var(--v-primary-accent-base);
        }
    }
    &__field {
        margin-top: -10px;
        margin-left: -1px;
        margin-right: -1px;
        display: block;
        width: calc(100% + 2px);
    }

    &.-has-errors {
        border-color: $error;
    }
}
</style>
