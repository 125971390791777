import { uuid } from 'vue-uuid';
// import API from '@/api';

/**
 * Класс экземпляра связи каталог-товар
 */
export default class CatalogEntry {
    /**
     * Create CatalogEntry
     * @param {Object} data - объект данных связи каталог-товар
     * @param {String} [data.id] - uuid.v4(),
     * @param {String} data.productId -
     * @param {Number} [data.position] -
     */
    constructor(data = {}) {
        this.id = data.id || uuid.v4();
        this.productId = data.productId || '';
        this.position = data.position || 0;
    }

    get data() {
        return {
            id: this.id,
            productId: this.productId,
            position: this.position,
        };
    }
}
