export const imageManagerMixin = {
    data() {
        return {
            isLoading: false,
            isUploading: false,
            lastSelected: null,
            isImagePropsModalOpen: false,
            editingImage: {},
        };
    },
    beforeRouteUpdate(to, from, next) {
        this.$store.commit('image/CLEAR_IMAGES_LIST');
        this.getImages();
        next();
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.watchWindow);
    },
    methods: {
        selectImage(image) {
            if (!this.selectable) return;
            this.$emit('input', image);
            const currentImage = this.images.find((item) => item.id === image.id);
            currentImage.selected = true;
            if (this.lastSelected) this.lastSelected.selected = false;
            this.lastSelected = currentImage;
        },
        openImagePropsModal(image) {
            this.isImagePropsModalOpen = true;
            this.editingImage = image;
        },
        close() {
            this.$emit('close');
        },
    },
};
