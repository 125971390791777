var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page -width-lg"},[_c('div',{staticClass:"page-header"},[_c('h1',{staticClass:"page-header__text"},[_vm._v(_vm._s(_vm.$t('menus.pageHeaders.orderStates')))]),_c('Button',{attrs:{"icon":"add_circle_outline"},on:{"click":function($event){return _vm.openModal(null)}}},[_vm._v(" "+_vm._s(_vm.$t('entities.add'))+" ")])],1),(!_vm.isAvailable)?_c('error-block'):[_c('OrderStateModal',{attrs:{"order-state-id":_vm.orderStateId},on:{"action-success":_vm.getItems},model:{value:(_vm.isModalOpen),callback:function ($$v) {_vm.isModalOpen=$$v},expression:"isModalOpen"}}),_c('Table',{attrs:{"show-select":"","sort-desc":_vm.sortDesc,"sort-by":_vm.sortBy,"local-sort":"","is-data-loaded":_vm.isLoaded,"columns":_vm.headers,"items":_vm.items},on:{"delete-selected-items":_vm.openDeletionModal,"update:sort":_vm.updateSort},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"table__actions"},[_c('Button',{attrs:{"icon":"edit","type":"icon"},on:{"click":function($event){return _vm.openModal(item.id)}}}),(!_vm.isRequired(item))?_c('Button',{attrs:{"icon":"delete","type":"icon"},on:{"click":function($event){return _vm.openDeletionModal(item)}}}):_vm._e()],1)]}},{key:"item.service-name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"table-edit"},[_c('a',{staticClass:"table-edit__link",attrs:{"type":"text"},on:{"click":function($event){return _vm.openModal(item.id)}}},[_vm._v(_vm._s(item.serviceName))]),_c('span',{staticClass:"table-edit__icon material-icons-outlined"},[_vm._v("edit")])])]}},{key:"item.public-name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"table-edit"},[_c('a',{staticClass:"table-edit__link",attrs:{"type":"text"},on:{"click":function($event){return _vm.openModal(item.id)}}},[_vm._v(_vm._s(item.publicName))]),_c('span',{staticClass:"table-edit__icon material-icons-outlined"},[_vm._v("edit")])])]}},{key:"item.color",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"order-state-list__color",style:({ backgroundColor: _vm.getColor(item) })}),_vm._v(" "+_vm._s(_vm.getColor(item))+" ")]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }