export default {
    googleAnalytics: {
        id: null,
        position: 0,
        type: 'string',
        value: '',
        options: {
            labelTrans: 'settings.seo.googleAnalytics',
        },
    },
    googleVerification: {
        id: null,
        position: 1,
        type: 'string',
        value: '',
        options: {
            labelTrans: 'settings.seo.googleVerification',
        },
    },
    metaPixel: {
        id: null,
        position: 2,
        type: 'string',
        value: '',
        options: {
            labelTrans: 'settings.seo.metaPixel',
        },
    },
    yandexMetrika: {
        id: null,
        position: 3,
        type: 'string',
        value: '',
        options: {
            labelTrans: 'settings.seo.yandexMetrika',
        },
    },
    yandexVerification: {
        id: null,
        position: 4,
        type: 'string',
        value: '',
        options: {
            labelTrans: 'settings.seo.yandexVerification',
        },
    },
    customCode: {
        id: null,
        position: 4,
        type: 'string',
        value: '',
        options: {
            labelTrans: 'settings.seo.customCode.title',
        },
    },
    customCodeFooter: {
        id: null,
        position: 5,
        type: 'string',
        value: '',
        options: {
            labelTrans: 'settings.seo.customCodeFooter.title',
        },
    },
    callTrackingMango: {
        id: null,
        position: 6,
        type: 'string',
        value: '',
        options: {
            labelTrans: 'settings.seo.callTrackingMango',
        },
    },
    robots: {
        id: null,
        position: 6,
        type: 'code',
        value: '',
        options: {
            labelTrans: 'settings.seo.robots',
        },
    },
    templateProductH1: {
        id: null,
        position: 100,
        type: 'string',
        value: '%product_name%',
        options: {
            labelTrans: 'settings.seo.templateProductH1',
        },
    },
    templateProductTitle: {
        id: null,
        position: 101,
        type: 'string',
        value: '%product_name% - %currency% %price% | %shop_name%',
        options: {
            labelTrans: 'settings.seo.templateProductTitle',
        },
    },
    templateProductDescription: {
        id: null,
        position: 102,
        type: 'string',
        value: 'Buy online %product_name% for %currency% %price% on %shop_name%',
        options: {
            labelTrans: 'settings.seo.templateProductDescription',
        },
    },
    templateCatalogH1: {
        id: null,
        position: 103,
        type: 'string',
        value: '%catalog_name%',
        options: {
            labelTrans: 'settings.seo.templateCatalogH1',
        },
    },
    templateCatalogTitle: {
        id: null,
        position: 104,
        type: 'string',
        value: '%catalog_name% | %shop_name%',
        options: {
            labelTrans: 'settings.seo.templateCatalogTitle',
        },
    },
    templateCatalogDescription: {
        id: null,
        position: 105,
        type: 'string',
        value: 'Buy online %catalog_name% on %shop_name%',
        options: {
            labelTrans: 'settings.seo.templateCatalogDescription',
        },
    },
    templatePageH1: {
        id: null,
        position: 106,
        type: 'string',
        value: '%page_name%',
        options: {
            labelTrans: 'settings.seo.templatePageH1',
        },
    },
    templatePageTitle: {
        id: null,
        position: 107,
        type: 'string',
        value: '%page_name% | %shop_name%',
        options: {
            labelTrans: 'settings.seo.templatePageTitle',
        },
    },
    templatePageDescription: {
        id: null,
        position: 108,
        type: 'string',
        value: '%page_name%',
        options: {
            labelTrans: 'settings.seo.templatePageDescription',
        },
    },
    templateCategoryH1: {
        id: null,
        position: 109,
        type: 'string',
        value: '%category_name%',
        options: {
            labelTrans: 'settings.seo.templateCategoryH1',
        },
    },
    templateCategoryTitle: {
        id: null,
        position: 110,
        type: 'string',
        value: '%category_name% | %shop_name%',
        options: {
            labelTrans: 'settings.seo.templateCategoryTitle',
        },
    },
    templateCategoryDescription: {
        id: null,
        position: 111,
        type: 'string',
        value: '%category_name%',
        options: {
            labelTrans: 'settings.seo.templateCategoryDescription',
        },
    },
    templateArticleH1: {
        id: null,
        position: 112,
        type: 'string',
        value: '%article_name%',
        options: {
            labelTrans: 'settings.seo.templateArticleH1',
        },
    },
    templateArticleTitle: {
        id: null,
        position: 113,
        type: 'string',
        value: '%article_name% | %shop_name%',
        options: {
            labelTrans: 'settings.seo.templateArticleTitle',
        },
    },
    templateArticleDescription: {
        id: null,
        position: 114,
        type: 'string',
        value: '%article_name%',
        options: {
            labelTrans: 'settings.seo.templateArticleDescription',
        },
    },
};
