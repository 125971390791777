<template>
    <div class="button-builder">
        <div style="padding-bottom: 10px">
            <InputSwitch v-model="model.isShow" size="small" text="is show" />
        </div>
        <label class="button-builder__item">
            <span class="button-builder__title">Type:</span>
            <ButtonToggles v-model="model.type" :items="types"></ButtonToggles>
        </label>
        <label class="button-builder__item" v-show="model.type === 'link'">
            <span class="button-builder__title">Link:</span>
            <InputLink v-model="model.url"></InputLink>
        </label>
        <label class="button-builder__item">
            <span class="button-builder__title">Text:</span>
            <InputText v-model="model.text"></InputText>
        </label>
        <label class="button-builder__item">
            <span class="button-builder__title">Style:</span>
            <Select v-model="model.styles" :items="buttonStyles"></Select>
        </label>
        <label v-show="currentButton.styles === 'custom'" class="button-builder__item">
            <span class="button-builder__title">Color Text:</span>
            <ColorPicker v-model="model.textColor" />
        </label>
        <label v-show="currentButton.styles === 'custom'" class="button-builder__item">
            <span class="button-builder__title">Color Background:</span>
            <ColorPicker v-model="model.bgColor" />
        </label>
        <label class="button-builder__item">
            <span class="button-builder__title">Icon Left:</span>
            <InputIcon v-model="model.iconLeft" :items="icons" :color="model.iconLeftColor" />
        </label>
        <label class="button-builder__item">
            <span class="button-builder__title">Icon Left Color:</span>
            <ColorPicker v-model="model.iconLeftColor" />
        </label>
        <label class="button-builder__item">
            <span class="button-builder__title">Icon Right:</span>
            <InputIcon v-model="model.iconRight" :items="icons" :color="model.iconRightColor" />
        </label>
        <label class="button-builder__item">
            <span class="button-builder__title">Icon Right Color:</span>
            <ColorPicker v-model="model.iconRightColor" />
        </label>
    </div>
</template>

<script>
import Select from '@/components/form/controls/Select.vue';
import InputText from '@/components/form/controls/InputText.vue';
import ColorPicker from '@/components/form/controls/ColorPicker.vue';
import ButtonToggles from '@/components/form/controls/ButtonToggles.vue';
import InputLink from '@/components/form/controls/InputLink.vue';
import InputIcon from '@/components/form/controls/InputIcon.vue';

export default {
    name: 'ButtonBuilder',
    inheritAttrs: false,
    props: {
        name: { type: String, default: '' },
        group: { type: String, default: null },
        type: { type: String, default: 'text' },
        placeholder: { type: String, default: '' },
        icons: { type: [Array] },
        types: { type: [Array] },
        errors: { type: [Array, Object], default: () => [] },
        value: { type: [Object] },
    },
    components: { InputLink, ButtonToggles, ColorPicker, InputText, Select, InputIcon },
    data() {
        return {
            buttonStyles: ['primary', 'secondary', 'outline', 'text', 'critical', 'custom'],
            currentButton: {
                isShow: this.value?.isShow || false,
                type: this.value?.type || 'popup',
                styles: this.value?.styles || 'primary',
                text: this.value?.text || null,
                textColor: this.value?.textColor || null,
                bgColor: this.value?.bgColor || null,
                link: this.value?.link || null,
                iconLeft: this.value?.iconLeft || null,
                iconRight: this.value?.iconRight || null,
                iconLeftColor: this.value?.iconLeftColor || null,
                iconRightColor: this.value?.iconRightColor || null,
                url: this.value?.url || null,
            },
        };
    },
    computed: {
        model: {
            get() {
                // console.log(this.currentButton);
                return this.currentButton;
            },
            set() {
                // set(value) {
                // console.log('set', value);
                this.$emit('input', this.currentButton);
            },
        },
    },
    watch: {
        currentButton: {
            handler: function() {
                this.$emit('update-field', {
                    name: this.name,
                    group: this.group,
                    value: this.currentButton,
                });
                this.$emit('input', this.currentButton);
            },
            deep: true,
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';

.button-builder {
    &__item {
        display: block;
        position: relative;
        width: 100%;
        &:hover {
            z-index: 15;
        }

        .select,
        .color-picker,
        .input-icon,
        .input-text {
            margin-top: -10px;
        }
        .input-icon {
            i {
                margin-top: 4px;
            }
        }
    }

    &__title {
        display: inline-block;
        padding: 0 5px;
        font-size: 0.75em;
        background: #fff;
        position: relative;
        z-index: 1;
        margin-left: 8px;
        border-radius: 4px;
    }
}
</style>
