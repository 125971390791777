import { Container, Content, Footer, Heading, Section } from '@/entities/section/samples/_options';
import createTemplate, { templateParts, hasNodeByComponent } from '@/entities/section/section-template';

export const templates = [
    {
        title: 'MediaLeft1',
        data: createTemplate({
            header: null,
            content: templateParts.line.content([
                templateParts.common.columns([
                    templateParts.common.columnsItemImage([templateParts.common.media()]),
                    templateParts.common.columnsItem([
                        templateParts.common.headingH2(),
                        templateParts.common.columnText(),
                    ]),
                ]),
            ]),
            footer: null,
        }),
    },
    {
        title: 'PictureLeft1',
        data: createTemplate({
            header: null,
            content: templateParts.line.content([
                templateParts.common.columns([
                    templateParts.common.columnsItemImage([templateParts.common.columnImage()]),
                    templateParts.common.columnsItem([
                        templateParts.common.headingH2(),
                        templateParts.common.columnText(),
                    ]),
                ]),
            ]),
            footer: null,
        }),
    },
    {
        title: 'PictureLeftHeader1',
        data: createTemplate({
            header: templateParts.line.content([templateParts.common.headingH2(), templateParts.common.headingNote()]),
            content: templateParts.line.content([
                templateParts.common.columns([
                    templateParts.common.columnsItemImage([templateParts.common.columnImage()]),
                    templateParts.common.columnsItem([templateParts.common.columnText()]),
                ]),
            ]),
            footer: null,
        }),
    },
    {
        title: 'PictureRight1',
        data: createTemplate({
            header: null,
            content: templateParts.line.content([
                templateParts.common.columns([
                    templateParts.common.columnsItem([
                        templateParts.common.headingH2(),
                        templateParts.common.columnText(),
                    ]),
                    templateParts.common.columnsItemImage([templateParts.common.columnImage()]),
                ]),
            ]),
            footer: null,
        }),
    },
    {
        title: 'PictureRightHeader1',
        data: createTemplate({
            header: templateParts.line.content([templateParts.common.headingH2(), templateParts.common.headingNote()]),
            content: templateParts.line.content([
                templateParts.common.columns([
                    templateParts.common.columnsItem([templateParts.common.columnText()]),
                    templateParts.common.columnsItemImage([templateParts.common.columnImage()]),
                ]),
            ]),
            footer: null,
        }),
    },
    {
        title: 'PictureCenter1',
        data: createTemplate({
            header: templateParts.line.content([templateParts.common.headingH2()]),
            content: templateParts.line.content([
                templateParts.common.columnText(),
                templateParts.common.columnImage(),
            ]),
            footer: null,
        }),
    },
    {
        title: 'PictureCenter2',
        data: createTemplate({
            header: templateParts.line.content([templateParts.common.headingH2()]),
            content: templateParts.line.content([
                templateParts.common.columnImage(),
                templateParts.common.columnText(),
            ]),
            footer: null,
        }),
    },
    {
        title: 'PictureCenter3',
        data: createTemplate({
            header: null,
            content: templateParts.line.content([
                templateParts.common.columnImage(),
                templateParts.common.headingH2(),
                templateParts.common.columnText(),
            ]),
            footer: null,
        }),
    },
];

// const templatesObj: any = (() => {
//     const titles = templates.map((template) => template.title);
//     return titles.reduce((acc, title) => {
//         acc[title] = templates.find((template) => template.title === title)?.data;
//         return acc;
//     }, {});
// })();

const section = {
    id: '',
    type: 'SectionTextBlockBase',
    required: false,
    group: 'TextBlocks',
    options: {
        section: { ...Section },
        container: { ...Container },
        template: {
            value: templates[0].data,
            component: 'Select',
            props: {
                items: templates.reduce((acc, cur) => {
                    // @ts-ignore
                    acc.push({ text: cur.title, value: cur.data });
                    return acc;
                }, []),
                labelTrans: 'SelectTemplates',
            },
        },
        heading: { ...Heading },
        content: {
            html: { ...Content.html, value: 'Text' },
            image: {
                ...Content.image,
                visibleCondition: (section) => {
                    return hasNodeByComponent(section.options.template.value?.content, 'SectionImage');
                },
            },
            alt: {
                ...Content.imageAlt,
                value: 'Image title text',
                visibleCondition: (section) => {
                    return hasNodeByComponent(section.options.template.value?.content, 'SectionImage');
                },
            },
            title: {
                ...Content.imageTitle,
                value: 'Image title text',
                visibleCondition: (section) => {
                    return hasNodeByComponent(section.options.template.value?.content, 'SectionImage');
                },
            },
            media: {
                value: {
                    desktop: null,
                    mobile: null,
                },
                component: 'CustomizeMediaTabs',
                ...{
                    props: {
                        labelTrans: 'builder.sectionOptions.props.media',
                        screen: ['desktop', 'mobile'],
                    },
                },
                visibleCondition: (section) => {
                    return hasNodeByComponent(section.options.template.value?.content, 'SectionMedia');
                },
            },
        },
        grid: {
            type: 'field',
            value: {},
            component: 'CustomizeColumnsTabs',
            ...{
                props: {
                    screen: ['desktop', 'tablet'], // 'desktop', 'tablet', 'mobile'
                },
            },
            visibleCondition: (section) => {
                return hasNodeByComponent(section.options.template.value?.content, 'Columns');
            },
        },
        footer: { ...Footer },
    },
    items: [],
    children: null,
};

export default section;
