import { SectionFixtureType } from '@/entities/section/fixtures';
import { createLayoutVariation } from '@/entities/section/fixtures/_base';

const itemStyleVariations = {
    style1: 'style1',
    style2: 'style2',
    style3: 'style3',
};

const itemsLogotypes = [
    {
        image: '7c694121-c1b6-477f-8f06-cc73d8050aee',
    },
    {
        image: '7c694121-c1b6-477f-8f06-cc73d8050aee',
    },
    {
        image: '7c694121-c1b6-477f-8f06-cc73d8050aee',
    },
    {
        image: '7c694121-c1b6-477f-8f06-cc73d8050aee',
    },
    {
        image: '7c694121-c1b6-477f-8f06-cc73d8050aee',
    },
    {
        image: '7c694121-c1b6-477f-8f06-cc73d8050aee',
    },
    {
        image: '7c694121-c1b6-477f-8f06-cc73d8050aee',
    },
];

const createItem = (args: { template: string; position: number; image: string }) => {
    return {
        id: 'fixture',
        type: args.template,
        options: {
            content: {
                image: args.image,
                alt: 'Image title text',
                title: 'Image title text',
                note: null,
                link: null,
            },
        },
        position: args.position,
    };
};

const createSectionFixture = (args: {
    preview: string;
    title: string;
    itemLayout: any;
    itemsTemplate: string;
    items: any[];
    options: any;
}): SectionFixtureType => {
    return {
        preview: args.preview,
        title: args.title,
        group: 'Gallery',
        data: {
            id: 'fixture',
            items: args.items,
            required: false,
            fixed: false,
            type: 'SectionGalleryBase',
            options: {
                ...args.options,
                itemsLayout: args.itemLayout,
                itemsStyle: {
                    template: args.itemsTemplate,
                    gap: 20,
                    borderRadius: 8,
                },
            },
        },
    };
};

const summary: any = {};

summary.style1__carousel_row_1 = createSectionFixture({
    title: 'Gallery carousel, row 1, item style 1',
    preview: require('@/assets/images/sections/gallery/gallery-logo1.jpg'),
    itemLayout: {
        desktop: createLayoutVariation({ type: 'carousel', columns: 6 }),
        tablet: createLayoutVariation({ type: 'carousel', columns: 4 }),
        mobile: createLayoutVariation({ type: 'carousel', columns: 1 }),
    },
    itemsTemplate: itemStyleVariations.style1,
    options: {
        section: {
            width: 'full',
            padding: {
                desktop: { top: 28, right: 0, bottom: 28, left: 0 },
                tablet: { top: 28, right: 0, bottom: 28, left: 0 },
                mobile: { top: 16, right: 0, bottom: 16, left: 0 },
            },
            borderRadius: {
                desktop: 0,
                tablet: 0,
                mobile: 0,
            },
            background: 'none',
        },
        container: {
            width: 'content',
            padding: {
                desktop: null,
                tablet: null,
                mobile: null,
            },
            borderRadius: {
                desktop: 0,
                tablet: 0,
                mobile: 0,
            },
            background: 'none',
        },
        heading: {
            title: 'Our partners',
            colorTitle: null,
            note: null,
            colorNote: null,
            textAlign: 'center',
            marginBottom: {
                desktop: null,
                tablet: null,
                mobile: null,
            },
        },
        content: {
            htmlBefore: null,
            htmlAfter: null,
            colorText: null,
        },
        footer: {
            note: null,
            colorNote: null,
            textAlign: 'center',
            marginTop: {
                desktop: null,
                tablet: null,
                mobile: null,
            },
            button: {
                isShow: false,
                styles: 'primary',
                text: 'Button',
                textColor: null,
                bgColor: null,
                link: null,
                iconLeft: null,
                iconRight: null,
                type: 'Link',
                url: null,
            },
        },
    },
    items: (() => {
        const items: any[] = [];
        for (let i = 0; i < 7; i++) {
            items.push(
                createItem({
                    template: itemStyleVariations.style1,
                    position: i,
                    ...itemsLogotypes[i],
                })
            );
        }
        return items;
    })(),
});
summary.style1__carousel_row_2 = createSectionFixture({
    title: 'Gallery carousel, row 2, item style 1',
    preview: require('@/assets/images/sections/gallery/gallery-logo2.jpg'),
    itemLayout: {
        desktop: createLayoutVariation({ type: 'carousel', columns: 6, rows: 2 }),
        tablet: createLayoutVariation({ type: 'carousel', columns: 4, rows: 2 }),
        mobile: createLayoutVariation({ type: 'carousel', columns: 1, rows: 2 }),
    },
    itemsTemplate: itemStyleVariations.style1,
    options: {
        section: {
            width: 'full',
            padding: {
                desktop: { top: 28, right: 0, bottom: 28, left: 0 },
                tablet: { top: 28, right: 0, bottom: 28, left: 0 },
                mobile: { top: 16, right: 0, bottom: 16, left: 0 },
            },
            borderRadius: {
                desktop: 0,
                tablet: 0,
                mobile: 0,
            },
            background: 'dark',
        },
        container: {
            width: 'content',
            padding: {
                desktop: null,
                tablet: null,
                mobile: null,
            },
            borderRadius: {
                desktop: 0,
                tablet: 0,
                mobile: 0,
            },
            background: 'none',
        },
        heading: {
            title: 'Our partners',
            colorTitle: null,
            note: null,
            colorNote: null,
            textAlign: 'center',
            marginBottom: {
                desktop: null,
                tablet: null,
                mobile: null,
            },
        },
        content: {
            htmlBefore: null,
            htmlAfter: null,
            colorText: null,
        },
        rows: 2,
        footer: {
            note: null,
            colorNote: null,
            textAlign: 'center',
            marginTop: {
                desktop: null,
                tablet: null,
                mobile: null,
            },
            button: {
                isShow: true,
                styles: 'primary',
                text: 'Become our partner',
                textColor: null,
                bgColor: null,
                link: null,
                iconLeft: null,
                iconRight: null,
            },
        },
    },
    items: (() => {
        const items: any[] = [];
        for (let i = 0; i < 2; i++) {
            for (let j = 0; j < 7; j++) {
                items.push(
                    createItem({
                        template: itemStyleVariations.style1,
                        position: i > 0 ? j * i : j,
                        ...itemsLogotypes[j],
                    })
                );
            }
        }
        return items;
    })(),
});
summary.style2__carousel_prev_1 = createSectionFixture({
    title: 'Gallery carousel, item style 2',
    preview: require('@/assets/images/sections/gallery/gallery-style1prev1.jpg'),
    itemLayout: {
        desktop: createLayoutVariation({ type: 'carousel', columns: 3 }),
        tablet: createLayoutVariation({ type: 'carousel', columns: 2 }),
        mobile: createLayoutVariation({ type: 'carousel', columns: 1 }),
    },
    itemsTemplate: itemStyleVariations.style2,
    options: {
        section: {
            width: 'full',
            padding: {
                desktop: { top: 28, right: 0, bottom: 28, left: 0 },
                tablet: { top: 28, right: 0, bottom: 28, left: 0 },
                mobile: { top: 16, right: 0, bottom: 16, left: 0 },
            },
            borderRadius: {
                desktop: 0,
                tablet: 0,
                mobile: 0,
            },
            background: 'none',
        },
        container: {
            width: 'content',
            padding: {
                desktop: null,
                tablet: null,
                mobile: null,
            },
            borderRadius: {
                desktop: 0,
                tablet: 0,
                mobile: 0,
            },
            background: 'none',
        },
        heading: {
            title: 'Gallery',
            colorTitle: null,
            note: null,
            colorNote: null,
            textAlign: 'center',
            marginBottom: {
                desktop: null,
                tablet: null,
                mobile: null,
            },
        },
        content: {
            htmlBefore: null,
            htmlAfter: null,
            colorText: null,
        },
        footer: {
            note: null,
            colorNote: null,
            textAlign: 'center',
            marginTop: {
                desktop: null,
                tablet: null,
                mobile: null,
            },
            button: {
                isShow: false,
                styles: 'primary',
                text: 'Button',
                textColor: null,
                bgColor: null,
                link: null,
                iconLeft: null,
                iconRight: null,
            },
        },
    },
    items: [
        {
            id: 'fixture',
            type: itemStyleVariations.style2,
            options: {
                content: {
                    image: 'a98bae8f-f585-4c9f-b202-7e2417345b2f',
                    alt: 'Image title text',
                    title: 'Image title text',
                    note: null,
                },
            },
            position: 1,
        },
        {
            id: 'fixture',
            type: itemStyleVariations.style2,
            options: {
                content: {
                    image: '2684c663-a1f7-4fbe-ba48-554187933a4f',
                    alt: 'Image title text',
                    title: 'Image title text',
                    note: null,
                },
            },
            position: 2,
        },
        {
            id: 'fixture',
            type: itemStyleVariations.style2,
            options: {
                content: {
                    image: '72ed4b28-99bf-468f-8660-4bc4f134f90f',
                    alt: 'Image title text',
                    title: 'Image title text',
                    note: null,
                },
            },
            position: 3,
        },
        {
            id: 'fixture',
            type: itemStyleVariations.style2,
            options: {
                content: {
                    image: 'd624e9aa-e316-48af-ae82-960f54ef24f9',
                    alt: 'Image title text',
                    title: 'Image title text',
                    note: null,
                },
            },
            position: 3,
        },
    ],
});
summary.style2__carousel_prev_2 = createSectionFixture({
    title: 'Gallery carousel, item style 2',
    preview: require('@/assets/images/sections/gallery/gallery-style1prev2.jpg'),
    itemLayout: {
        desktop: createLayoutVariation({ type: 'carousel', columns: 3 }),
        tablet: createLayoutVariation({ type: 'carousel', columns: 2 }),
        mobile: createLayoutVariation({ type: 'carousel', columns: 1 }),
    },
    itemsTemplate: itemStyleVariations.style2,
    options: {
        section: {
            width: 'full',
            padding: {
                desktop: { top: 28, right: 0, bottom: 28, left: 0 },
                tablet: { top: 28, right: 0, bottom: 28, left: 0 },
                mobile: { top: 16, right: 0, bottom: 16, left: 0 },
            },
            borderRadius: {
                desktop: 0,
                tablet: 0,
                mobile: 0,
            },
            background: 'dark',
        },
        container: {
            width: 'content',
            padding: {
                desktop: null,
                tablet: null,
                mobile: null,
            },
            borderRadius: {
                desktop: 0,
                tablet: 0,
                mobile: 0,
            },
            background: 'none',
        },
        heading: {
            title: 'Gallery',
            colorTitle: null,
            note: null,
            colorNote: null,
            textAlign: 'center',
            marginBottom: {
                desktop: null,
                tablet: null,
                mobile: null,
            },
        },
        content: {
            htmlBefore: null,
            htmlAfter: null,
            colorText: null,
        },
        footer: {
            note: null,
            colorNote: null,
            textAlign: 'center',
            marginTop: {
                desktop: null,
                tablet: null,
                mobile: null,
            },
            button: {
                isShow: true,
                styles: 'primary',
                text: 'Show more',
                textColor: null,
                bgColor: null,
                link: null,
                iconLeft: null,
                iconRight: null,
            },
        },
    },
    items: [
        {
            id: 'fixture',
            type: itemStyleVariations.style2,
            options: {
                content: {
                    image: '72ef4dbb-f945-473b-8a34-bc1665b1c0df',
                    alt: 'Image title text',
                    title: 'Image title text',
                    note: null,
                },
            },
            position: 1,
        },
        {
            id: 'fixture',
            type: itemStyleVariations.style2,
            options: {
                content: {
                    image: '7f3309fd-dc03-4fd2-8c95-fffeb4e89c02',
                    alt: 'Image title text',
                    title: 'Image title text',
                    note: null,
                },
            },
            position: 2,
        },
        {
            id: 'fixture',
            type: itemStyleVariations.style2,
            options: {
                content: {
                    image: 'd624e9aa-e316-48af-ae82-960f54ef24f9',
                    alt: 'Image title text',
                    title: 'Image title text',
                    note: null,
                },
            },
            position: 3,
        },
        {
            id: 'fixture',
            type: itemStyleVariations.style2,
            options: {
                content: {
                    image: '25ac3815-2497-4972-bc43-5c9be7c0e0d6',
                    alt: 'Image title text',
                    title: 'Image title text',
                    note: null,
                },
            },
            position: 3,
        },
    ],
});
summary.style3__carousel_prev_1 = createSectionFixture({
    title: 'Gallery carousel, item style 3',
    preview: require('@/assets/images/sections/gallery/gallery-style2prev1.jpg'),
    itemLayout: {
        desktop: createLayoutVariation({ type: 'masonry', columns: 3 }),
        tablet: createLayoutVariation({ type: 'masonry', columns: 2 }),
        mobile: createLayoutVariation({ type: 'masonry', columns: 1 }),
    },
    itemsTemplate: itemStyleVariations.style3,
    options: {
        section: {
            width: 'full',
            padding: {
                desktop: { top: 28, right: 0, bottom: 28, left: 0 },
                tablet: { top: 28, right: 0, bottom: 28, left: 0 },
                mobile: { top: 16, right: 0, bottom: 16, left: 0 },
            },
            borderRadius: {
                desktop: 0,
                tablet: 0,
                mobile: 0,
            },
            background: 'none',
        },
        container: {
            width: 'content',
            padding: {
                desktop: null,
                tablet: null,
                mobile: null,
            },
            borderRadius: {
                desktop: 0,
                tablet: 0,
                mobile: 0,
            },
            background: 'none',
        },
        heading: {
            title: 'Gallery',
            colorTitle: null,
            note: null,
            colorNote: null,
            textAlign: 'center',
            marginBottom: {
                desktop: null,
                tablet: null,
                mobile: null,
            },
        },
        content: {
            htmlBefore: null,
            htmlAfter: null,
            colorText: null,
        },
        footer: {
            note: null,
            colorNote: null,
            textAlign: 'center',
            marginTop: {
                desktop: null,
                tablet: null,
                mobile: null,
            },
            button: {
                isShow: true,
                styles: 'primary',
                text: 'Show more',
                textColor: null,
                bgColor: null,
                link: null,
                iconLeft: null,
                iconRight: 'arrow_forward_ios',
            },
        },
    },
    items: [
        {
            id: 'fixture',
            type: itemStyleVariations.style3,
            options: {
                content: {
                    image: '2029abcc-d7e1-49c4-82d1-a8294355782f',
                    alt: 'Image title text',
                    title: 'Image title text',
                    note: null,
                },
            },
            position: 0,
        },
        {
            id: 'fixture',
            type: itemStyleVariations.style3,
            options: {
                content: {
                    image: '37b2245a-c400-4c33-a999-c1562ea9a0d2',
                    alt: 'Image title text',
                    title: 'Image title text',
                    note: null,
                },
            },
            position: 1,
        },
        {
            id: 'fixture',
            type: itemStyleVariations.style3,
            options: {
                content: {
                    image: 'e43d6868-d35c-4f54-b346-399d74972c55',
                    alt: 'Image title text',
                    title: 'Image title text',
                    note: null,
                },
            },
            position: 2,
        },
        {
            id: 'fixture',
            type: itemStyleVariations.style3,
            options: {
                content: {
                    image: '3d054770-881c-4895-bf4e-5818a5bdb60a',
                    alt: 'Image title text',
                    title: 'Image title text',
                    note: null,
                },
            },
            position: 3,
        },
        {
            id: 'fixture',
            type: itemStyleVariations.style3,
            options: {
                content: {
                    image: 'cdbfac90-b431-42de-8810-8c6a8859156e',
                    alt: 'Image title text',
                    title: 'Image title text',
                    note: null,
                },
            },
            position: 4,
        },
        {
            id: 'fixture',
            type: itemStyleVariations.style3,
            options: {
                content: {
                    image: '560c0d34-9227-4b9b-ae6a-0662d7e572a3',
                    alt: 'Image title text',
                    title: 'Image title text',
                    note: null,
                },
            },
            position: 5,
        },
    ],
});
summary.style3__carousel_prev_2 = createSectionFixture({
    title: 'Gallery carousel, item style 3',
    preview: require('@/assets/images/sections/gallery/gallery-style2prev2.jpg'),
    itemLayout: {
        desktop: createLayoutVariation({ type: 'masonry', columns: 3 }),
        tablet: createLayoutVariation({ type: 'masonry', columns: 2 }),
        mobile: createLayoutVariation({ type: 'masonry', columns: 1 }),
    },
    itemsTemplate: itemStyleVariations.style3,
    options: {
        section: {
            width: 'full',
            padding: {
                desktop: { top: 28, right: 0, bottom: 28, left: 0 },
                tablet: { top: 28, right: 0, bottom: 28, left: 0 },
                mobile: { top: 16, right: 0, bottom: 16, left: 0 },
            },
            borderRadius: {
                desktop: 0,
                tablet: 0,
                mobile: 0,
            },
            background: 'middle',
        },
        container: {
            width: 'content',
            padding: {
                desktop: null,
                tablet: null,
                mobile: null,
            },
            borderRadius: {
                desktop: 0,
                tablet: 0,
                mobile: 0,
            },
            background: 'none',
        },
        heading: {
            title: 'Gallery',
            colorTitle: null,
            note: null,
            colorNote: null,
            textAlign: 'center',
            marginBottom: {
                desktop: null,
                tablet: null,
                mobile: null,
            },
        },
        content: {
            htmlBefore: null,
            htmlAfter: null,
            colorText: null,
        },
        footer: {
            note: null,
            colorNote: null,
            textAlign: 'center',
            marginTop: {
                desktop: null,
                tablet: null,
                mobile: null,
            },
            button: {
                isShow: false,
                styles: 'primary',
                text: 'Show more',
                textColor: null,
                bgColor: null,
                link: null,
                iconLeft: null,
                iconRight: 'arrow_forward_ios',
            },
        },
    },
    items: [
        {
            id: 'fixture',
            type: itemStyleVariations.style3,
            options: {
                content: {
                    image: '10ba9133-50bc-48f4-bb16-e752ea72dc1d',
                    alt: 'Image title text',
                    title: 'Image title text',
                    note: null,
                },
            },
            position: 1,
        },
        {
            id: 'fixture',
            type: itemStyleVariations.style3,
            options: {
                content: {
                    image: '76e74458-23a3-4b64-81ec-40b1697de192',
                    alt: 'Image title text',
                    title: 'Image title text',
                    note: null,
                },
            },
            position: 2,
        },
        {
            id: 'fixture',
            type: itemStyleVariations.style3,
            options: {
                content: {
                    image: 'e7f4e262-227c-41e1-85f5-e79e49970604',
                    alt: 'Image title text',
                    title: 'Image title text',
                    note: null,
                },
            },
            position: 3,
        },
        {
            id: 'fixture',
            type: itemStyleVariations.style3,
            options: {
                content: {
                    image: '9b71f02c-45a2-4a9a-9136-795609979161',
                    alt: 'Image title text',
                    title: 'Image title text',
                    note: null,
                },
            },
            position: 4,
        },
        {
            id: 'fixture',
            type: itemStyleVariations.style3,
            options: {
                content: {
                    image: '84b45dc6-adb2-442d-b813-c474df16a985',
                    alt: 'Image title text',
                    title: 'Image title text',
                    note: null,
                },
            },
            position: 5,
        },
        {
            id: 'fixture',
            type: itemStyleVariations.style3,
            options: {
                content: {
                    image: '2d53a74d-f422-4893-8745-1f4c2c7175d1',
                    alt: 'Image title text',
                    title: 'Image title text',
                    note: null,
                },
            },
            position: 5,
        },
    ],
});
summary.style4__carousel_prev_1 = {
    preview: require('@/assets/images/sections/gallery/gallery-style3prev1.jpg'),
    title: 'Gallery',
    group: 'Gallery',
    data: {
        id: 'fixture',
        items: [
            {
                id: 'fixture',
                type: 'galleryItem',
                options: {
                    heading: {
                        title: 'Green glasses',
                    },
                    content: {
                        image: ['ce97049d-c8cb-4ca1-8dc1-94c6aa6a0793'],
                    },
                },
                position: 0,
            },
            {
                id: 'fixture',
                type: 'galleryItem',
                options: {
                    heading: {
                        title: 'Beige glasses',
                    },
                    content: {
                        image: ['c4311769-5d72-4e81-a8e9-820ea0112cc9'],
                    },
                },
                position: 1,
            },
            {
                id: 'fixture',
                type: 'galleryItem',
                options: {
                    heading: {
                        title: 'Light blue glasses',
                    },
                    content: {
                        image: ['38ea0e38-6465-4cd7-bce6-b60af2e04563'],
                    },
                },
                position: 2,
            },
        ],
        required: false,
        fixed: false,
        type: 'SectionGaleryStyle3',
        options: {
            section: {
                width: 'full',
                padding: {
                    desktop: { top: 28, right: 0, bottom: 28, left: 0 },
                    tablet: { top: 28, right: 0, bottom: 28, left: 0 },
                    mobile: { top: 16, right: 0, bottom: 16, left: 0 },
                },
                borderRadius: {
                    desktop: 0,
                    tablet: 0,
                    mobile: 0,
                },
                background: 'none',
            },
            container: {
                width: 'content',
                padding: {
                    desktop: null,
                    tablet: null,
                    mobile: null,
                },
                borderRadius: {
                    desktop: 0,
                    tablet: 0,
                    mobile: 0,
                },
                background: 'none',
            },
            heading: {
                title: 'Gallery',
                colorTitle: null,
                note: null,
                colorNote: null,
                textAlign: 'center',
                marginBottom: {
                    desktop: null,
                    tablet: null,
                    mobile: null,
                },
            },
            content: {
                htmlBefore: null,
                htmlAfter: null,
                colorText: null,
                imageRatio: '100%',
                showArrow: true,
                showDots: true,
            },
            columns: {
                desktop: 3,
                tablet: 2,
                mobile: 1,
            },
            footer: {
                note: null,
                colorNote: null,
                textAlign: 'center',
                marginTop: {
                    desktop: null,
                    tablet: null,
                    mobile: null,
                },
                button: {
                    isShow: true,
                    styles: 'primary',
                    text: 'Show more',
                    textColor: null,
                    bgColor: null,
                    link: null,
                    iconLeft: null,
                    iconRight: 'arrow_forward_ios',
                },
            },
        },
    },
};
summary.style4__carousel_prev_2 = {
    preview: require('@/assets/images/sections/gallery/gallery-style3prev2.jpg'),
    title: 'Gallery',
    group: 'Gallery',
    data: {
        id: 'fixture',
        items: [
            {
                id: 'fixture',
                type: 'galleryItem',
                options: {
                    heading: {
                        title: 'A pair of green and white shoes',
                    },
                    content: {
                        image: ['06a246ea-6261-4705-ae0f-bdc9b9d85abb'],
                    },
                },
                position: 0,
            },
            {
                id: 'fixture',
                type: 'galleryItem',
                options: {
                    heading: {
                        title: 'A pair of red shoes',
                    },
                    content: {
                        image: ['bde0f717-9ee1-48b1-84ed-3f99c1747bfa'],
                    },
                },
                position: 1,
            },
            {
                id: 'fixture',
                type: 'galleryItem',
                options: {
                    heading: {
                        title: 'Shoes',
                    },
                    content: {
                        image: ['594c3335-5db2-4ebf-ade2-a627c8ef06a9'],
                    },
                },
                position: 2,
            },
        ],
        required: false,
        fixed: false,
        type: 'SectionGaleryStyle3',
        options: {
            section: {
                width: 'full',
                padding: {
                    desktop: { top: 28, right: 0, bottom: 28, left: 0 },
                    tablet: { top: 28, right: 0, bottom: 28, left: 0 },
                    mobile: { top: 16, right: 0, bottom: 16, left: 0 },
                },
                borderRadius: {
                    desktop: 0,
                    tablet: 0,
                    mobile: 0,
                },
                background: 'dark',
            },
            container: {
                width: 'content',
                padding: {
                    desktop: null,
                    tablet: null,
                    mobile: null,
                },
                borderRadius: {
                    desktop: 0,
                    tablet: 0,
                    mobile: 0,
                },
                background: 'none',
            },
            heading: {
                title: 'Gallery',
                colorTitle: null,
                note: null,
                colorNote: null,
                textAlign: 'center',
                marginBottom: {
                    desktop: null,
                    tablet: null,
                    mobile: null,
                },
            },
            content: {
                htmlBefore: null,
                htmlAfter: null,
                colorText: null,
                imageRatio: '133%',
                showArrow: true,
                showDots: true,
            },
            columns: {
                desktop: 3,
                tablet: 2,
                mobile: 1,
            },
            footer: {
                note: null,
                colorNote: null,
                textAlign: 'center',
                marginTop: {
                    desktop: null,
                    tablet: null,
                    mobile: null,
                },
                button: {
                    isShow: false,
                    styles: 'primary',
                    text: 'Button',
                    textColor: null,
                    bgColor: null,
                    link: null,
                    iconLeft: null,
                    iconRight: null,
                    type: 'Link',
                    url: null,
                },
            },
        },
    },
};

// console.log('test', summary);

export default summary;
