import { cloneDeep, merge, transform, isEqual, isObject } from 'lodash';

/**
 * Создает функцию геттер для значения из объекта по определенному пути вида "prop.nestedProp"
 * @param {String} path - путь к свойству объекта в котором хранится нужное значение
 * @returns функция геттер
 */
export const createGetValue = (path) => {
    const pathArr = path.split('.');
    return (obj) => {
        if (typeof obj !== 'object') return;
        return pathArr.reduce((acc, prop) => {
            return typeof acc === 'object' ? acc[prop] : acc;
        }, obj);
    };
};

/**
 * Создает функцию сеттер для значения из объекта по определенному пути вида "prop.nestedProp"
 * @param {String} path - путь к свойству объекта в котором хранится нужное значение
 * @returns функция сеттер
 */
export const createSetValue = (path) => {
    const pathArr = path.split('.');
    return (obj, value) => {
        if (typeof obj !== 'object') return;
        return pathArr.reduce((acc, prop) => {
            if (typeof acc[prop] === 'object') {
                return acc[prop];
            } else {
                acc[prop] = value;
            }
        }, obj);
    };
};

/**
 * Изменение позиций других элементов в массиве
 * @param {Object} item - объект элемента у которого изменили позицию
 * @param {Array} array - массив объектов
 * @param {Array} parentArray - массив
 * @param {String} key - путь к значению позиции в объекте
 * @param {Function} cb - колбэк, который вызывается после изменения позиции
 * @returns {Array} отсортированный по позиции массив объектов
 */
export const changePosition = (item = {}, array = [], parentArray = [], key = 'position', cb = () => {}) => {
    const getValue = createGetValue(key);
    const setValue = createSetValue(key);

    // Если введена позиция больше чем кол-во элементов в массиве, то ставим последнюю позицию в массиве
    if (getValue(item) > array.length - 1) setValue(item, array.length - 1);

    const currentPosition = Number(getValue(item));
    const currentIndex = parentArray.indexOf(item);
    let nextPosition = 0;

    for (const element of array) {
        if (element.id !== item.id) nextPosition++;

        const index = parentArray.indexOf(element);
        const position = Number(getValue(element));

        if (element.id === item.id) {
            setValue(parentArray[index], Number(getValue(parentArray[index])));
        } else if (position === currentPosition) {
            if (index < currentIndex) {
                setValue(parentArray[index], position + 1);
            } else {
                setValue(parentArray[index], position - 1);
            }
        } else if (position > currentPosition) {
            setValue(parentArray[index], nextPosition);
        }
        cb(parentArray[index]);
    }

    Tree.sort(array, key);
    return array;
};

/**
 * Класс для создания и сортировки дерева из одноуровнего массива данных
 */
export class Tree {
    /**
     * Сортировка массива
     * @param {Array} arr - массив данных для сортировки
     * @param {string} key - поле в котором храниться позиция
     * @returns {Array} отсортированный массив
     */
    static sort(arr = this.positioned, key = 'positionTemp') {
        const getValue = createGetValue(key);
        return arr.sort((a, b) => {
            if (getValue(a) > getValue(b)) return 1;
            else if (getValue(a) < getValue(b)) return -1;
            else return 0;
        });
    }

    sort = Tree.sort;

    /**
     *
     * @param {Array} data - массив данных
     * @returns {Array} отсортированное дерево данных
     */
    constructor(data) {
        this.data = cloneDeep(data);
        this.positioned = this._addPosition(this.sort(this._firstLevel, 'position'));
        return this.positioned;
    }

    get _firstLevel() {
        return this.data.filter((el) => !el.parentId);
    }

    /**
     * Создание и сортировка дерева
     * @param {Array} arr - массив данных
     * @param {string} parent - позиция родителя
     * @param {number} level - уровень вложенности
     * @returns {Array} отсортированное дерево данных
     */
    _addPosition(arr = [], parent = '', level = 1) {
        const result = [];
        for (const item of arr) {
            item.positionTemp = `${parent}/${arr.indexOf(item) + 1}`;
            item.level = level;
            result.push(item);
            const children = this.data.filter((el) => el.parentId === item.id);
            if (children.length) {
                result.push(...this._addPosition(this.sort(children, 'position'), item.positionTemp, level + 1));
            }
        }
        return result;
    }

    /**
     * Получение значения свойства объекта по пути в виде строки вида "prop.nestedProp"
     * @param {Object} obj - объект из которого нужно получить значение
     * @param {String} path - пусть к свойству объекта в котором хранится нужное значение
     * @returns искомое значение
     */
    static _getValue(obj, path) {
        return path.split('.').reduce((acc, val) => {
            return typeof acc === 'object' ? acc[val] : acc;
        }, obj);
    }
}

/**
 *
 */
export function deepFind(array, key, value, nestedKey) {
    let result = null;
    for (let i = 0; i < array.length; i++) {
        if (array[i][key] !== value && array[i][nestedKey]?.length) {
            result = deepFind(array[i][nestedKey], key, value, nestedKey) || result;
        } else if (array[i][key] === value) {
            result = array[i];
        }
    }
    return result;
}

export function isUrl(str) {
    const pattern = new RegExp(
        '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$',
        'i'
    ); // fragment locator
    return !!pattern.test(str);
}

/**
 * Преобразует многомерный объект в одномерный,
 * @param {Object} obj - многомерный объект
 * @param {String} separate - разделитель в названии ключа
 * @returns {Object} одномерный объект
 */
export function flatObj(obj, separate = '_') {
    const newObj = {};

    function getObj(obj, targetObj, slug = '') {
        Object.entries(obj).forEach(([key, value]) => {
            let keyName;

            if (slug) keyName = slug + separate + key;
            else keyName = key;

            if (value != null && value.constructor.name === 'Object') {
                return getObj(value, targetObj, keyName);
            }

            targetObj[keyName] = value;
        });
    }

    getObj(obj, newObj);

    return newObj;
}

/**
 * Преобразует одномерный в многомерный объект,
 * @param {Object} obj - одномерный объект
 * @param {String} separate - разделитель в названии ключа
 * @returns {Object} многомерный объект
 */
export function unflatObj(obj, separate = '_') {
    let newObj = {};

    Object.entries(obj).forEach(([key, value]) => {
        let keys = key.split(separate).reduceRight((acc, el, index, arr) => {
            if (arr.length === index + 1) return { [el]: value };
            return { [el]: acc };
        }, {});

        merge(newObj, keys);
    });

    return newObj;
}

/**
 * Находит разницу между двумя обхектами,
 * @param {Object} object - первый объект для сравнения
 * @param {String} base - второй объект для сравнения
 * @returns {Object} объект, содержащий различия
 */

export function difference(object, base) {
    function changes(object, base) {
        return transform(object, function(result, value, key) {
            if (!isEqual(value, base[key])) {
                result[key] = isObject(value) && isObject(base[key]) ? changes(value, base[key]) : value;
            }
        });
    }
    return changes(object, base);
}

/**
 * Передвигает элемент в массиве с одной позиции на другю
 * @param {Array} arr - массив
 * @param {Number} oldIndex - индекс, с которого перемешаем элемент
 * @param {Number} newIndex - индекс, на который перемешаем элемент
 * @returns {Array} - обновленный массив
 */

export function arrayMove(arr, oldIndex, newIndex) {
    if (newIndex >= arr.length) {
        arr.push(arr.splice(oldIndex, 1)[0]);
    } else {
        arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    }
    return arr; // for testing
}

/**
 * Форматирует дату для input-text с type date
 * @param {Date} date - объект Date
 * @returns {String} - дата формата yyyy.mm.dd
 */

export function formatDateForInput(date) {
    if (!(date instanceof Date)) {
        throw new Error('Invalid "date" argument. You must pass a date instance');
    }

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
}

/**
 * Сортирует массив объектов в соответствии с другим массивом по ключу
 * @param {Array} array - массив объектов для сортировки
 * @param {Number} referenceArray - массив строк или чисел, согласно которому будем сортировать
 * @param {Number, String} key - ключ, по которому будем сортировать объекты
 * @returns {Array} - обновленный массив
 */

export function mapOrder(array, referenceArray, key) {
    const newArray = cloneDeep(array);
    newArray.sort(function(a, b) {
        const A = a[key],
            B = b[key];

        if (referenceArray.indexOf(A) > referenceArray.indexOf(B)) {
            return 1;
        } else {
            return -1;
        }
    });

    return newArray;
}

/**
 * Удаляет все методы из объекта для postMessage
 * @param {Object} obj - объект с методами
 * @returns {Object} - объект без методов
 */

export function removeFunctionsFromObject(obj) {
    for (let prop in obj) {
        if (typeof obj[prop] === 'function') delete obj[prop];
        else if (typeof obj[prop] === 'object') removeFunctionsFromObject(obj[prop]);
    }
    return obj;
}

/**
 * Экранирует специальные символы для динамических RegExp
 * @param {String} text - строка будущего regexp, которую надо экранировать
 * @returns {String} - готовая строка
 */

export function escapeRegExp(text) {
    return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

export function flattenArrayOfNestedObjects(arr) {
    function flattenRecurse(obj, depth = 0, result = []) {
        result.push({ ...obj, depth });
        if (obj.children?.length) {
            depth++;
            obj.children.forEach((item) => flattenRecurse(item, depth, result));
        }
        return result;
    }
    return arr.map((item) => flattenRecurse(item)).flat();
}

export function cartesian(...args) {
    const r = [],
        max = args.length - 1;
    function helper(arr, i) {
        for (let j = 0, l = args[i].length; j < l; j++) {
            const a = arr.slice(0); // clone arr
            a.push(args[i][j]);
            if (i === max) r.push(a);
            else helper(a, i + 1);
        }
    }
    helper([], 0);
    return r;
}
