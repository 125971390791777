<template>
    <div class="page -shrunk -column customer">
        <progress-circular class=" spinner" v-if="isLoading && isAvailable"></progress-circular>
        <template v-else-if="!isAvailable">
            <div class="page-header">
                <h1 class="page-header__text">{{ $tc('entities.user.customerTitle', 1) }}</h1>
            </div>
            <error-block></error-block>
        </template>
        <template v-else>
            <div class="page-header">
                <h1 class="page-header__text">
                    {{ $tc('entities.user.customerTitle', 1) }} -
                    {{ currentState.fullName === '-' ? currentState.email : currentState.fullName }}
                </h1>
            </div>
            <div class="customer__information">
                <heading type="h2">{{ $t('entities.blocks.generalInformation') }}</heading>
                <div class="form">
                    <template v-for="(field, key) in items">
                        <div class="customer__item form-item -half-width" v-if="key !== 'deliveryAddresses'" :key="key">
                            <heading type="h3">{{ $t(`entities.user.fields.${key}`) }}</heading>
                            <div>{{ field }}</div>
                        </div>
                        <div v-else class="customer__item form-item -half-width" :key="key">
                            <heading type="h3">{{ $t(`entities.user.fields.${key}`) }}</heading>
                            <ol v-if="field.length">
                                <li v-for="address in field" :key="address">{{ address }}</li>
                            </ol>
                            <div v-else>-</div>
                        </div>
                    </template>
                </div>
            </div>
            <div class="customer__orders">
                <heading type="h2">{{ $t('entities.blocks.orders') }}</heading>
                <order-list is-widget :user-id="currentState.id"></order-list>
            </div>
        </template>
    </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import ProgressCircular from '@/components/common/ProgressCircular';
import User from '@/entities/user/User';
import UserService from '@/services/UserService';
import OrderList from '@/views/order/OrderList';
import Heading from '@/components/common/Heading';
import { localDate } from '@/helpers/filters';
import OrderDeliveryAddress from '@/entities/order/OrderDeliveryAddress';

export default {
    name: 'CustomerItem',
    components: { Heading, OrderList, ProgressCircular },

    data() {
        return {
            isValid: false,
            currentState: new User(),
            isLoading: false,
            isAvailable: true,
        };
    },

    computed: {
        userId() {
            return this.$route.params.id;
        },
        items() {
            const profile = this.currentState?.profile;
            const items = {
                name: this.currentState.fullName,
                email: this.currentState.email,
                phone: this.currentState.phone,
                registeredAt: localDate(this.currentState.registeredAt),
                birthDate: profile?.birthDate ? localDate(new Date(profile.birthDate)) : '-',
                deliveryAddresses: profile
                    ? profile?.deliveryAddresses.map((item) => {
                          return OrderDeliveryAddress.formatAddress(item.deliveryAddress);
                      })
                    : [],
            };
            return items;
        },
    },

    async created() {
        await this.getUser();
        this.initialState = cloneDeep(this.currentState);
    },

    methods: {
        async getUser() {
            this.isLoading = true;
            const [error, result] = await UserService.getOne(this.userId);
            if (error) {
                error.alert();
                this.isAvailable = false;
                return;
            }
            this.currentState = new User(result);
            this.isLoading = false;
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.customer {
    &__information {
        background-color: var(--v-surface-base);
        padding: 32px 24px;
        border-radius: 4px;
        .form {
            margin-top: 24px;
        }
    }
    ol {
        padding-inline-start: 16px !important;
        margin-top: 0;
    }
    &__orders {
        background-color: var(--v-surface-base);
        padding: 32px 24px;
        border-radius: 4px;
        margin-top: 32px;
        .table {
            padding: 0;
        }
    }
}
</style>
