var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"orders",class:{ page: !_vm.isWidget }},[(!_vm.isWidget)?_c('div',{staticClass:"page-header"},[_c('h1',{staticClass:"page-header__text"},[_vm._v(_vm._s(_vm.$t('menus.pageHeaders.orders')))]),_c('Button',{attrs:{"to":{ name: 'Create Order' },"icon":"add_circle_outline"}},[_vm._v(" "+_vm._s(_vm.$t('entities.add'))+" ")])],1):_vm._e(),(!_vm.isAvailable)?_c('error-block'):[(_vm.editingOrder.orderComment)?_c('order-comment-modal',{attrs:{"comment":_vm.editingOrder.orderComment},model:{value:(_vm.isCommentModalOpen),callback:function ($$v) {_vm.isCommentModalOpen=$$v},expression:"isCommentModalOpen"}}):_vm._e(),_c('Table',{staticClass:"orders-table",class:{ '-dashboard-orders': _vm.isWidget },attrs:{"search":_vm.search,"is-data-loaded":_vm.isLoaded,"columns":_vm.headers,"show-toolbar":!_vm.isWidget,"show-selected-actions":!_vm.isWidget,"show-select-all":!_vm.isWidget,"show-select":!_vm.isWidget,"items":_vm.items,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"pagination":_vm.pagination},on:{"change-per-page":_vm.changePerPage,"update:sort":_vm.updateSort,"update-search-string":_vm.updateSearchString,"delete-selected-items":_vm.openDeletionModal},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"table__actions"},[_c('Button',{staticClass:"chat-icon",class:_vm.getIconCommentClasses(item),attrs:{"icon":"chat","type":"icon"},on:{"click":function($event){return _vm.openCommentModal(item)}}}),_c('Button',{attrs:{"icon":"edit","to":{ name: 'Edit Order', params: { id: item.id } },"type":"icon"}}),_c('Button',{attrs:{"icon":"delete","type":"icon"},on:{"click":function($event){return _vm.openDeletionModal(item)}}})],1)]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("localDate")(item.createdAt)))]}},{key:"item.clientInfo",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)),_c('br'),_c('span',[_vm._v(_vm._s(item.phone))])]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getTotalPrice(item)))]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"orders__status",style:(_vm.getStatusColor(item))},[_vm._v(_vm._s(_vm.getStatus(item)))])]}},{key:"item.manager",fn:function(ref){
var item = ref.item;
return [(item.manager)?_c('a',{staticClass:"orders__email",attrs:{"title":item.manager.email,"href":("mailto:" + (item.manager.email)),"target":"_blank","type":"text"}},[_vm._v(_vm._s(item.manager.email))]):_c('span',[_vm._v("—")])]}},{key:"item.order",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"table-edit"},[_c('router-link',{staticClass:"table-edit__link orders__edit-link",attrs:{"to":{ name: 'Edit Order', params: { id: item.id } },"type":"text"}},[_vm._v(_vm._s(item.number))]),_c('span',{staticClass:"material-icons-outlined table-edit__icon"},[_vm._v("edit")])],1)]}},{key:"item.delivery",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.addresses[item.id])+" ")]}},{key:"item.payment",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(("entities.order.paymentMethods." + (item.method))))+" ")]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }