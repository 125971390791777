export default {
    template: {
        id: null,
        position: 0,
        type: 'select',
        component: 'Select',
        value: '1',
        options: {
            variants: ['1', '2', '3', '4'],
            items: ['1', '2', '3', '4'],
            labelTrans: 'settings.footer.template',
        },
    },
    menu: {
        id: null,
        position: 1,
        type: 'menu',
        component: 'MenuEditor',
        value: 'footer',
        options: {
            columns: 4,
            depth: 2,
            labelTrans: 'settings.footer.menu',
        },
    },
    menuOptions: {
        id: null,
        position: 2,
        type: 'object',
        component: 'CompositeField',
        value: {
            size: 20,
            uppercase: false,
        },
        options: {
            labelTrans: 'settings.footer.menuOptions',
            fields: [
                {
                    name: 'size',
                    type: 'number',
                    component: 'InputNumberAndRange',
                    value: 15,
                    props: {
                        min: 12,
                        max: 20,
                        labelTrans: 'settings.footer.menuOptionsTextSize',
                    },
                },
                {
                    name: 'uppercase',
                    type: 'boolean',
                    component: 'InputSwitch',
                    value: false,
                    props: {
                        noLabel: true,
                        text: 'settings.footer.menuOptionsTextUppercase',
                    },
                },
                {
                    name: 'color',
                    value: '',
                    component: 'ColorPicker',
                    props: {
                        labelTrans: 'settings.footer.menuOptionsTextColor',
                        btnReset: true,
                        mode: 'hexa',
                    },
                },
            ],
        },
    },
    menuSubOptions: {
        id: null,
        position: 3,
        type: 'object',
        component: 'CompositeField',
        value: {
            size: 15,
            uppercase: false,
        },
        options: {
            labelTrans: 'settings.footer.menuSubOptions',
            fields: [
                {
                    name: 'size',
                    type: 'number',
                    component: 'InputNumberAndRange',
                    value: 15,
                    props: {
                        min: 12,
                        max: 18,
                        labelTrans: 'settings.footer.menuOptionsTextSize',
                    },
                },
                {
                    name: 'uppercase',
                    type: 'boolean',
                    component: 'InputSwitch',
                    value: true,
                    props: {
                        noLabel: true,
                        text: 'settings.footer.menuOptionsTextUppercase',
                    },
                },
                {
                    name: 'color',
                    value: '',
                    component: 'ColorPicker',
                    props: {
                        labelTrans: 'settings.footer.menuOptionsTextColor',
                        btnReset: true,
                        mode: 'hexa',
                    },
                },
            ],
        },
    },
    menuColumns: {
        id: null,
        position: 4,
        type: 'number',
        component: 'InputNumberAndRange',
        value: 4,
        options: {
            min: 4,
            max: 6,
            labelTrans: 'settings.footer.menuColumns',
        },
    },
    background: {
        id: null,
        position: 5,
        type: 'select',
        component: 'Select',
        value: 'middle',
        options: {
            variants: ['light', 'middle', 'dark'],
            items: ['light', 'middle', 'dark'],
            labelTrans: 'settings.footer.background',
        },
    },
    copywrite: {
        id: null,
        position: 6,
        type: 'string',
        component: 'InputText',
        value: '© 2021, All Rights Reserved',
        options: {
            labelTrans: 'settings.footer.copywrite',
        },
    },
    footerText: {
        id: null,
        position: 7,
        type: 'code',
        component: 'code-editor',
        value: 'Some text',
        options: {
            labelTrans: 'settings.footer.footerText',
        },
    },
    logo: {
        id: null,
        position: 8,
        type: 'image',
        component: 'InputImageBuilder',
        value: '2773fb07-c76a-4435-9a7b-e0040425c2c0',
        options: {
            labelTrans: 'settings.footer.logo',
            note: 'JPG, PNG. Recomended size: 100х46px. No more than 10kb',
        },
    },
    logoAlt: {
        id: null,
        position: 9,
        type: 'string',
        component: 'InputText',
        value: 'Best Company Ever',
        options: {
            labelTrans: 'settings.footer.logoAlt',
        },
    },
};
