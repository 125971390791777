export function changeRoute({ type, id, activeItem, categoryId }) {
    if (id === this.$route.params.id || (type === 'homepage' && this.$route.params.type === 'homepage')) return;
    if (type === 'homepage') {
        this.$router.push({ name: 'Edit Homepage', params: { type, activeItem } });
    } else if (type === 'cart' || type === 'checkout') {
        this.$router.push({ name: 'Edit Ecommerce Page', params: { type } });
    } else if (type === 'article' || type === 'category') {
        const params = { id, type: 'media' };
        if (type === 'article') {
            params.categoryId = categoryId;
            this.$router.push({ name: 'Builder Edit Article', params });
        } else {
            this.$router.push({ name: 'Builder Edit Category', params });
        }
    } else {
        this.$router.push({ name: 'Builder Edit Page', params: { id, type } });
    }
}
