<template>
    <div class="textarea" :class="classes">
        <textarea
            :style="{ minHeight: `${height}px` }"
            class="textarea__field"
            :disabled="disabled"
            v-model="model"
            v-bind="inputProps"
        />
    </div>
</template>

<script>
import { proxyModelMixin } from '@/mixins/proxyModelMixin';

export default {
    name: 'Textarea',
    inheritAttrs: false,
    mixins: [proxyModelMixin],
    props: {
        name: { type: String, default: '' },
        group: { type: String, default: null },
        placeholder: { type: String, default: '' },
        errors: { type: [Array, Object], default: () => [] },
        value: { type: [String, Number, Object, Date] },
        disabled: { type: Boolean, default: false },
        height: { type: [String, Number], default: 100 },
        maxlength: { type: Number },
    },
    computed: {
        inputProps() {
            return {
                name: this.name,
                placeholder: this.placeholder,
                maxlength: this.maxlength,
            };
        },
        hasError: function() {
            return Array.isArray(this.errors) && this.errors.length > 0;
        },
        classes() {
            return {
                '-has-errors': Array.isArray(this.errors) && this.errors.length > 0,
                '-disabled': this.disabled,
            };
        },
    },
    watch: {
        value: function(newValue) {
            this.$emit('update-field', {
                name: this.name,
                group: this.group,
                value: newValue,
            });
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.textarea {
    position: relative;
    &.-disabled {
        .textarea__field {
            background-color: rgba(0, 0, 0, 0.04) !important;
            color: var(--v-on-surface-disabled-base);
            &:hover {
                border-color: $form-control-border-color !important;
            }
        }
        ::-webkit-scrollbar-track {
            background: rgba(0, 0, 0, 0) !important;
        }
    }
    &__field {
        display: block;
        width: 100%;
        height: $form-control-height-big;
        padding: $form-control-padding-vertical $form-control-padding-horizontal;
        border: $form-control-border;
        border-radius: $form-control-border-radius;
        background: $form-control-bg;
        transition: $form-control-transition;
        appearance: none;

        &:hover {
            border-color: var(--v-on-surface-medium-base);
            outline: none;
        }
        &:focus {
            border-color: var(--v-primary-accent-base);
            outline: none;
        }

        &::placeholder {
            color: inherit;
            opacity: 0.7;
        }
    }

    &.-has-errors &__field {
        border-color: $error;
    }
}
</style>
