export default {
    // TEXT
    // default
    text_main: '#020305',
    text_body1: '#343A47',
    text_body2: '#4A5264',
    text_accent: '#747DAA',
    text_neutral: '#D7D9E1',
    // inverted
    text_main_inverted: '#FFFFFF',
    text_body1_inverted: '#E5E7F1',
    text_body2_inverted: '#D7DCE9',
    text_accent_inverted: '#B3B9C7',
    text_neutral_inverted: '#959AA6',

    // ACTION
    // primary
    action_primary: '#5A6794',
    action_primary_hover: '#8794C0',
    action_primary_pressed: '#404969',
    action_primary_disabled: '#DCDEE5',
    // secondary
    action_secondary: '#8794C0',
    action_secondary_hover: '#7894F1',
    action_secondary_pressed: '#404969',
    action_secondary_disabled: '#DCDEE5',
    // neutral_light
    header_bg: '#FFFFFF',
    header_bg_mobile: '#30385A',
    header_main_menu_bg: '#30385A',
    header_main_menu_dropdown_bg: '#505773',
    action_neutral_light: '#D7DCE9',
    // action_neutral_light_hover: '#8794C0',
    // action_neutral_light_pressed: '#404969',
    action_neutral_light_hover: '#8794C0',
    action_neutral_light_pressed: '#7894F1',
    action_neutral_light_disabled: '#636C8C',
    // neutral_medium
    action_neutral_medium: '#8794C0',
    action_neutral_medium_hover: '#849BE8',
    action_neutral_medium_pressed: '#636C8C',
    action_neutral_medium_disabled: '#E7E8E8',
    // neutral_dark
    action_neutral_dark: '#404969',
    action_neutral_dark_hover: '#5A6794',
    action_neutral_dark_pressed: '#1F2333',
    action_neutral_dark_disabled: '#D7D9E1',
    // system
    action_system: '#D96E95',
    action_system_hover: '#D5628B',
    action_system_pressed: '#CE4A79',
    action_system_disabled: '#B3B9C7',

    // NEUTRAL
    neutral1: '#1C2135',
    neutral2: '#30385A',
    neutral3: '#505773',
    neutral4: '#D3CAD3',
    neutral5: '#E8E9EE',
    neutral6: '#F6F7F8',
    neutral7: '#FFFFFF',

    // SYSTEM
    success: '#7DBB95',
    success_bg: '#EFF5F1',
    warning: '#F0B748',
    warning_bg: '#FFF9EE',
    error: '#D96E95',
    error_bg: '#F8EAEF',

    // ACCENT
    accent1: '#DDC178',
    accent1_bg: '#FFF7E3',
    accent2: '#F3E381',
    accent2_bg: '#FFFBE5',
    accent3: '#00B08D',
    accent3_bg: '#E0FFF9',
    accent4: '#7D76DF',
    accent4_bg: '#E7E6F9',

    whatsapp: '#49C15C',
};
