import Page from '../page/Page';

/**
 * Класс экземпляра каталога
 */
export default class Catalog extends Page {
    type = 'catalog';

    /**
     * Create Catalog Entity
     * @param {Object} data - объект с данными каталога
     * @param {String} data.id - uuid.v4(),
     * @param {String} data.title -
     * @param {String} data.slug -
     * @param {String} data.heading -
     * @param {String} data.metaTitle -
     * @param {String} data.metaDescription -
     * @param {String} data.previewImageId -
     * @param {Boolean} data.enabled -
     * @param {Boolean} data.hidden -
     * @param {Boolean} data.noIndex -
     * @param {Array} data.sections -
     */
    constructor(data) {
        super({ ...data, defaultSections: ['SectionBreadcrumbs', 'SectionHeadingH1', 'SectionProductList'] });
        this.fields.previewImageId.props.imageType = 'catalog';
        this.fields.slug.props.pageType = 'catalog';
        this.sortingCriteria = data?.sortingCriteria || 'title';
        this.relatedProductsIds = data?.relatedProductsIds || [];
    }
    get data() {
        return {
            ...super.data,
            sortingCriteria: this.sortingCriteria || 'price_asc',
            relatedProductsIds: this.relatedProductsIds,
        };
    }
}
