export default {
    Header: 'border_top',
    Footer: 'border_bottom',
    Breadcrumbs: 'keyboard_double_arrow_right',
    Heading: 'title',
    TextBlocks: 'subject',
    'Текстовый блок': 'subject',
    FAQ: 'help_center',
    'Вопросы и ответы': 'help_center',
    Forms: 'edit_note',
    ProductWidget: 'category',
    'Виджет продуктов': 'category',
    ProductWidgetByItems: 'category',
    ProductList: 'dashboard',
    Slider: 'collections',
    Слайдер: 'collections',
    Category: 'dashboard_customize',
    Категории: 'dashboard_customize',
    Gallery: 'burst_mode',
    Галерея: 'burst_mode',
    Benefits: 'rocket',
    Преимущества: 'rocket',
    Contacts: 'where_to_vote',
    Контакты: 'where_to_vote',
    Properties: 'list_alt',
    Testimonial: 'reviews',
    Banners: 'image',
    default: 'subject',
};
