<template>
    <div class="form-item" :class="classes">
        <slot name="label">
            <label v-if="!noLabel && labelFormated" :for="name" class="form-item__label" :class="{ '-big': labelBig }">
                {{ labelFormated }}
            </label>
        </slot>
        <slot></slot>
        <div v-if="note" class="form-item__note">{{ $t(note) }}</div>
        <ul v-if="hasError" class="form-item__error">
            <template v-for="(error, index) in errors">
                <li v-if="error" :key="index">{{ $t(error) }}</li>
            </template>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'FormItem',
    inheritAttrs: false,
    props: {
        errors: {
            type: [Array, Object],
            default: () => [],
        },
        label: { type: String, default: '' },
        labelTrans: { type: String, default: '' },
        name: { type: String, default: '' },
        noLabel: { type: Boolean, default: false },
        labelBig: { type: Boolean, default: false },
        grid: {
            type: String,
            default: 'column',
            validator: function(value) {
                return ['row', 'column'].includes(value);
            },
        },
        note: { type: String, default: '' },
    },
    computed: {
        labelFormated: function() {
            const label = (() => {
                if (this.labelTrans) return this.$t(this.labelTrans);
                if (this.label) return this.$t(this.label);
                return this.name;
            })();
            const isNoEmpty = this.rules ? this.rules.includes('notEmpty') : false;
            return isNoEmpty ? `${label}*` : label;
        },
        hasError: function() {
            // костыль для инпутов с массивом в значении
            if (this.errors[0] && Array.isArray(this.errors[0])) return null;
            return Array.isArray(this.errors) && this.errors.length > 0;
        },
        classes() {
            return {
                '-has-errors': this.hasError,
                '-row': this.grid === 'row',
            };
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.form-item {
    display: block;
    width: 100%;

    &.-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .form-item__label {
            margin-bottom: 0;
        }
    }
    &.-has-errors {
        label {
            color: var(--v-warning-base);
        }
        .heading {
            color: var(--v-warning-base);
        }
        fieldset {
            border-color: var(--v-warning-base);
        }
        [role='combobox'] {
            border-color: var(--v-warning-base) !important;
        }
        .zones-tree {
            color: var(--v-warning-base);
            .tree-node__checkbox {
                .checkbox__icon {
                    color: var(--v-warning-base) !important;
                }
                color: var(--v-warning-base) !important;
            }
        }
    }
    &__label {
        display: block;
        margin: 0 0 8px;

        &.-big {
            font-size: 1.2em;
            font-weight: 600;
        }
    }

    &__error {
        color: var(--v-error-base);
        margin: 8px 0 0 16px;
        padding: 0;
    }
    &__tooltip-button {
        position: relative;
        bottom: -3px;
        cursor: pointer;
        margin-left: 3px;
    }

    &__note {
        color: $disabled;
        margin-top: 8px;
        font-size: 12px;
        line-height: 16px;
    }
}
</style>
