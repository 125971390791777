<template>
    <div class="builder-section-new-list-group" :class="{ 'is-active': this.isActive }" v-on="$listeners">
        <div class="builder-section-new-list-group__icon">
            <span class="material-symbols-outlined">{{ icon }}</span>
        </div>
        <div class="builder-section-new-list-group__text">
            {{ title }}
        </div>
    </div>
</template>

<script>
import icons from '@/components/builder/sections/icons';
export default {
    name: 'BuilderSectionNewListGroup',
    props: {
        title: { type: String, required: true },
        isActive: { type: Boolean, required: true },
    },

    data() {
        return {};
    },

    computed: {
        icon() {
            return icons[this.title] || icons.default;
        },
    },

    methods: {},
};
</script>

<style lang="scss" scoped>
@import '@/scss/variables';
@import '@/scss/variables/system-colors';

.builder-section-new-list-group {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    min-height: 56px;
    cursor: pointer;
    position: relative;
    transition: $transition-fast;

    &:hover {
        background: $item-hover;
    }
    &.is-active {
        background: $item-active;
    }

    &__icon {
        padding: 0 8px 0 0;
        span {
            color: var(--v-on-surface-high-base);
            font-size: 20px;
            line-height: 30px;
            font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 0;
        }
    }

    &__text {
        color: var(--v-on-surface-high-base);
        font-size: 1.1429rem;
        line-height: 30px;
        cursor: pointer;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}
</style>
