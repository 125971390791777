<template>
    <div class="page__content-container">
        <div>
            <div class="order__toolbar -main">
                <search-form
                    :label="search.label"
                    :searchable="search.searchable"
                    :q="search.q"
                    :fields="['title']"
                    @update-search-string="updateSearchString"
                    @update-search-results="updateResults"
                ></search-form>
            </div>
            <Table
                v-if="search.q.length >= 3"
                :columns="headers"
                class="order-products-search__table"
                :show-toolbar="false"
                :show-selected-actions="false"
                :search="searchString"
                show-select
                :items="items"
                :is-data-loaded="isLoaded"
                v-model="selected"
            >
                <template v-slot:item.image="{ item }">
                    <table-image :image="images[item.id]"></table-image>
                </template>
                <template v-slot:item.title="{ item }">
                    <a target="_blank" :href="`${imageUrl}/products/${item.slug}`">
                        <Button type="text">{{ item.title }} </Button>
                    </a>
                </template>
                <template v-slot:item.stock="{ item }">
                    <chip v-if="item.variations.every((item) => item.stock === 0)" type="error">
                        {{ $t('entities.product.stock.outOfStock') }}
                    </chip>
                    <chip v-else-if="item.variations.some((item) => item.stock === 0)" type="warning">
                        {{ $t('entities.product.stock.partially') }}
                        {{ item.stockSummary }}
                    </chip>
                    <chip v-else type="success">
                        {{ $t('entities.product.stock.inStock') }}
                        {{ item.stockSummary }}
                    </chip>
                </template>
            </Table>
            <Button icon="add_circle_outline" v-if="selected.length" @click="addItems">{{ $t('entities.add') }}</Button>
            <progress-linear v-if="search.q.length >= 3 && !isLoaded"></progress-linear>
        </div>
    </div>
</template>

<script>
import Product from '@/entities/product/Product';
import SearchForm from '@/components/search/SearchForm';
import Button from '@/components/common/Button';
import { imageUrl } from '@/helpers/values';
import Table from '@/components/common/Table';
import TableImage from '@/components/common/TableImage';
import ProgressLinear from '@/components/common/ProgressLinear';
import Chip from '@/components/common/Chip';

export default {
    name: 'OrderProductsSearch',

    components: {
        Chip,
        ProgressLinear,
        TableImage,
        SearchForm,
        Table,
        Button,
    },
    data: () => ({
        isLoaded: true,
        items: [],
        images: {},
        //чтобы очищать selected после добавления товаров в заказ
        selected: [],
        search: { searchable: 'product', q: '' },
        imageUrl: imageUrl(),
    }),
    computed: {
        headers() {
            const headers = [
                { text: this.$t('lists.columns.image'), value: 'image' },
                { text: this.$t('lists.columns.sku'), value: 'sku' },
                { text: this.$t('lists.columns.stock'), value: 'stock' },
                { text: this.$t('lists.columns.title'), value: 'title' },
            ];

            if (this.catalogId) headers.splice(1, 0, { text: 'Position', value: 'position', width: '120px' });

            return headers;
        },
        searchString() {
            return this.$route.query.q;
        },
        currency() {
            return this.$store.getters['config/getCurrency'];
        },
    },
    methods: {
        async updateResults(results) {
            this.isLoaded = false;
            this.items = results.map((item) => new Product(item));
            this.images = Product.getDefaultImages(this.items);
            this.isLoaded = true;
        },

        updateSearchString({ searchString }) {
            this.search.q = searchString;
        },

        getDefaultVariation(item) {
            return item.variations.find((item) => item.default);
        },

        addItems() {
            this.$emit('add-items', this.selected);
            this.selected = [];
        },
    },
};
</script>
<style lang="scss">
@import '@/scss/variables.scss';
.order-products-search {
    &__table {
        .table {
            padding: 16px 0;
        }
    }
}
</style>
