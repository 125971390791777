<template>
    <div class="order__items" v-if="items.length">
        <Table
            @update:items="updateItems"
            :is-data-loaded="!isLoading"
            show-select
            :columns="headers"
            v-model="selected"
            :items="models"
        >
            <template v-slot:remove-selected>
                <Button
                    icon="delete"
                    v-if="selected.length"
                    type="text"
                    @click="removeItems"
                    class="table__selected-action"
                >
                    {{ $t('lists.actions.deleteSelected') }}</Button
                >
            </template>
            <template v-slot:item="{ item, itemIndex, handleCheckbox, isSelected }">
                <tr :class="{ '-error': errors[itemIndex] }">
                    <td class="order-item__select-td">
                        <!--                        @input="onSelectItem({ item, value: !selectedItems[item.id] })"-->
                        <input-checkbox
                            :value="isSelected"
                            @input="handleCheckbox(item, itemIndex)"
                            class="v-data-table__checkbox v-simple-checkbox"
                        ></input-checkbox>
                    </td>
                    <td class="order-item__product-td">
                        <table-image class="table__image" :image="images[item.variationId]"></table-image>
                        <span v-if="!products.find((product) => product.id === item.productId)">{{ item.title }}</span>
                        <a
                            target="_blank"
                            v-else
                            class="table-edit__link"
                            :href="`/products/edit/${products.find((product) => product.id === item.productId).id}`"
                        >
                            {{ item.title }}
                        </a>
                    </td>
                    <td class="order-item__quantity-td">
                        <div class="order-item__quantity-counter">
                            <div class="order-item__quantity-number" :class="{ 'has-error': checkErrorNumber(item) }">
                                <Button
                                    :disabled="item.quantity === 1"
                                    @click="--item.quantity"
                                    icon="remove"
                                    type="large-text"
                                ></Button>
                                <span>{{ item.quantity }}</span>
                                <Button
                                    icon="add"
                                    :disabled="checkStock(item)"
                                    @click="++item.quantity"
                                    type="large-text"
                                ></Button>
                            </div>
                            <div v-if="checkErrorNumber(item)" class="order-item__quantity-error">
                                {{ $t('entities.product.avalible') }}:
                                {{ variationsMap[item.variationId].stock }}
                                {{ $t('entities.product.pcs') }}
                            </div>
                        </div>
                    </td>
                    <td>
                        <template v-if="variationsMap[item.variationId]">
                            <chip v-if="variationsMap[item.variationId].stock === 0" type="error">
                                {{ $t('entities.product.stock.outOfStock') }}
                            </chip>
                            <chip v-else-if="variationsMap[item.variationId].stock === null" type="success">
                                {{ $t('entities.product.stock.inStock') }}
                            </chip>
                            <chip v-else type="success">
                                {{ $t('entities.product.stock.inStock') }}
                                ({{ variationsMap[item.variationId].stock }})
                            </chip>
                        </template>
                    </td>
                    <td class="order-item__total-td">
                        {{ computeItemTotal(item) }}
                    </td>

                    <td class="order-item__delete-td">
                        <Button
                            @click="$emit('remove-items', [item.id])"
                            class="order-item__delete-btn"
                            icon="delete"
                            :type="'icon'"
                        >
                        </Button>
                    </td>
                    <td v-if="item.options.length && item.variationId" class="order-item__options-td">
                        <Select
                            :label="option.title"
                            v-for="option in item.options"
                            :items="getOptionsValues(option.title, options[item.productId])"
                            v-model="option.value"
                            item-text="title"
                            item-value="title"
                            :key="option.id"
                            @input="handleOptions(item)"
                        ></Select>
                    </td>
                    <td v-else-if="item.options.length" class="order-item__options-td">
                        <input-text
                            :label="option.title"
                            v-model="option.value"
                            v-for="option in item.options"
                            :key="option.id"
                            disabled
                        ></input-text>
                    </td>
                    <td v-if="errors[itemIndex]" class="order-item__error-td">{{ $t(errors[itemIndex]) }}</td>
                </tr>
            </template>
        </Table>
        <div class="order__total"></div>
    </div>
</template>

<script>
import { cloneDeep, isEqual } from 'lodash';
//import ImageService from '@/services/ImageService';
import TableImage from '@/components/common/TableImage';
import { imageUrl } from '@/helpers/values';
import Image from '@/entities/image/Image';
import Table from '@/components/common/Table';
import InputCheckbox from '@/components/form/controls/InputCheckbox';
import Button from '@/components/common/Button';
import InputText from '@/components/form/controls/InputText';
import Select from '@/components/form/controls/Select';
import Chip from '@/components/common/Chip';

export default {
    name: 'OrderItemsList',
    components: { Chip, InputCheckbox, Select, InputText, TableImage, Table, Button },
    props: {
        items: {
            type: Array,
            default: () => [],
        },
        products: {
            type: Array,
            default: () => [],
        },
        errors: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            models: [],
            options: {},
            images: {},
            isLoading: false,
            selected: [],
            initialItems: [],
            imageUrl: imageUrl(),
        };
    },
    computed: {
        currency() {
            return this.$store.getters['config/getCurrency'];
        },
        headers() {
            return [
                {
                    text: this.$t('lists.columns.title'),
                    value: 'title',
                    class: 'order-item__product-th',
                },
                {
                    text: this.$t('lists.columns.quantity'),
                    value: 'quantity',
                    class: 'order-item__quantity-th',
                },
                {
                    text: this.$t('lists.columns.stock'),
                    value: 'stock',
                    class: 'order-item__stock-th',
                },
                {
                    text: this.$t('lists.columns.total'),
                    value: 'fullPrice',
                    class: 'order-item__total-th',
                },
                { value: 'actions', sortable: false, class: 'order-item__delete-th' },
                //   { value: 'form', sortable: false, class: 'order-item__options-th' },
            ];
        },
        variationsMap() {
            return this.items.reduce((acc, item) => {
                const variation = this.products
                    .find((product) => product.id === item.productId)
                    ?.variations?.find((variation) => variation.id === item.variationId);
                //вариация может быть null, если товар удалён
                if (variation) {
                    acc[variation.id] = variation;
                }
                return acc;
            }, {});
        },
    },
    methods: {
        checkErrorNumber(item) {
            const initialItem = this.initialItems.find((initialItem) => initialItem.id === item.id);
            if (initialItem && this.variationsMap[item.variationId]) {
                return +item.quantity > +initialItem.quantity + +this.variationsMap[item.variationId].stock;
            }
            return false;
        },
        checkStock(item) {
            //если количество бесконечно
            if (this.variationsMap[item.variationId]?.stock === null) return false;
            //для итемов уже существуюшего заказа
            const initialItem = this.initialItems.find((initialItem) => initialItem.id === item.id);
            if (initialItem) {
                //если вариация удалена - выключаем кнопку
                if (!this.variationsMap[item.variationId]) return true;
                //если вариация на месте
                return +item.quantity >= +initialItem.quantity + +this.variationsMap[item.variationId].stock;
            }
            //для свежедобавленных итемов
            return item.quantity >= this.variationsMap[item.variationId]?.stock;
        },
        handleOptions(item) {
            const map = {};
            //берём опции продуктов и создаем из них map по title
            this.options[item.productId].forEach((option) => {
                map[option.title] = option.values;
            });
            //на основе опций orderItem и map получаем массив id текущих values опций
            const valuesIds = [];
            item.options.forEach((option) => {
                const valId = map[option.title].find((value) => value.title === option.value).id;
                valuesIds.push(valId);
            });
            const product = this.products.find((product) => product.id === item.productId);
            //получаем вариацию с соотвествуюшим набором выбранных values
            const variation = product.variations.find((variation) => {
                return isEqual(variation.productOptionValueIds.sort(), valuesIds.sort());
            });

            //если у вариации нет цены - берём у дефолтной вариации
            if (!variation.discountPrice) {
                const defaultVariation = product.variations.find((variation) => {
                    return variation.default;
                });
                item.discountPrice = defaultVariation.discountPrice;
                item.fullPrice = defaultVariation.fullPrice;
            } else {
                item.discountPrice = variation.discountPrice;
                item.fullPrice = variation.fullPrice;
            }

            item.imageId = variation.imageId;
            item.variationId = variation.id;
            item.quantity = 1;
            //для правильного отключения кнопки "+"
            this.initialItems = this.initialItems.filter((initialItem) => initialItem.id !== item.id);
            if (variation.image && !this.images[variation.id]) {
                this.images = { ...this.images, ...{ [variation.id]: new Image(variation.image) } };
            }
        },

        removeItems() {
            const ids = this.selected.map((item) => item.id);
            this.$emit('remove-items', ids);
            this.selected = [];
            this.hasSelectedItems = false;
        },
        getProductsOptions() {
            const map = {};
            this.products.forEach((product) => {
                map[product.id] = product.options;
            });
            this.options = map;
        },

        getOptionsValues(optionTitle, options) {
            return options?.find((option) => option.title === optionTitle)?.values;
        },

        getOptionsModel(item, id) {
            return item.options.find((option) => option.id === id);
        },

        async getImages() {
            const map = {};
            this.images = this.items.forEach((item) => {
                const image = this.products
                    .find((product) => product.id === item.productId)
                    ?.images.find((image) => image.id === item.imageId);
                if (image) {
                    map[item.variationId] = image;
                }
            });
            this.images = map;
        },

        computeItemTotal(item) {
            return item.getFinalPrice();
        },
        updateItems(items) {
            this.models = items;
        },
    },
    watch: {
        'items.length': {
            async handler(val, prevVal) {
                this.isLoading = true;
                if (!prevVal && this.$route.params.id) {
                    this.initialItems = cloneDeep(this.items);
                }
                await this.getImages();
                this.getProductsOptions();
                this.models = cloneDeep(this.items);
                this.isLoading = false;
            },
            immediate: true,
        },
        models: {
            handler(val) {
                if (!isEqual(val, this.items)) {
                    this.$emit('update-items', this.models);
                }
            },
            deep: true,
        },
        items: {
            handler(val) {
                if (!isEqual(val, this.models)) this.models = this.items;
            },
            deep: true,
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.order {
    &__items {
        .table-body__wrapper {
            overflow: visible;
        }
        tr {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            border-bottom: 1px solid var(--v-outline-base);
            &.-error {
                background-color: var(--v-warning-lighten-base);
                .order-item__product-td a {
                    color: var(--v-warning-base) !important;
                }
            }
            td {
                display: flex;
                align-items: center;
                flex-grow: 1;
                flex-basis: 0;
                border-bottom: none !important;
                height: auto !important;
            }
            th {
                flex-grow: 1;
                flex-basis: 0;
                border-bottom: none !important;
                &:first-child {
                    flex-grow: 0 !important;
                    padding-right: 37px !important;
                }
                &:last-child {
                    max-width: 60px !important;
                }
            }
        }
        .order-item {
            &__select-td {
                flex-grow: 0 !important;
                padding-right: 24px;
            }
            &__delete-td {
                flex-grow: 0 !important;
            }
            &__error-td {
                color: var(--v-warning-base);
                flex-basis: 100% !important;
                justify-content: start;
                padding-top: 0 !important;
                gap: 24px;
                padding-left: 60px !important;
            }
            &__quantity-td {
                .btn__icon {
                    width: 64px;
                    margin-right: 0 !important;
                }
            }
            &__quantity {
                &-number {
                    display: flex;
                    align-items: center;
                    flex-grow: 1;
                    flex-basis: 0;
                    border-bottom: none !important;
                    height: auto !important;
                    &.has-error {
                        & > span {
                            color: var(--v-error-base);
                            opacity: 0.7;
                        }
                    }
                }
                &-error {
                    padding: 0.5rem 0 0;
                    font-weight: bold;
                    font-size: 80%;
                    color: var(--v-error-base);
                    text-align: center;
                }
            }
            &__options-td {
                flex-basis: 100% !important;
                justify-content: start;
                gap: 24px;
                padding-left: 60px !important;
                .select {
                    width: 275px;
                }
            }
            &__product-td {
                display: flex;
                gap: 24px;
                a {
                    font-weight: 500;
                    &:hover {
                        color: var(--v-primary-accent-base);
                    }
                }
            }
            &__delete-th {
                flex-grow: 0 !important;
                padding-right: 48px !important;
            }
            &__options-th {
                display: none !important;
            }
        }
    }
}
</style>
