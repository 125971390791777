<template>
    <div class="image-manager">
        <div class="image-manager__list">
            <div class="image-manager__item">
                <dropzone @change="uploadImage" :loading="isUploading" />
            </div>
            <div v-for="(image, index) in images" :key="index" :data-id="image.id" class="image-manager__item">
                <div class="image-manager__item-img image__item" :class="{ 'is-selected': checkIfSelected(image) }">
                    <div class="image__controls">
                        <Button
                            type="icon"
                            class="image__controls-btn -hidden"
                            icon="delete"
                            @click="openDeletionModal(image)"
                        ></Button>
                    </div>
                    <img :src="getPreviewURI(image)" alt="preview" @click="selectImage(image)" />
                </div>
            </div>
        </div>
        <template v-if="hasNextPage && !isLoading">
            <Button
                class="image-manager__btn-more"
                :loading="isLoading"
                fullwidth
                icon="add_photo_alternate"
                @click="getImages"
            >
                {{ $t('entities.image.showMore') }}
            </Button>
        </template>
        <Button class="image-manager__btn-close" fullwidth @click="close">{{ $t('entities.image.close') }}</Button>
    </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import ImageService from '@/services/ImageService';
import { Pagination } from '@/services/PaginationService';
import Image from '@/entities/image/Image';
import { proxyModelMixin } from '@/mixins/proxyModelMixin';
import Button from '@/components/common/Button.vue';
import { imageUrl } from '@/helpers/values';
import Dropzone from '@/components/common/Dropzone';
import EventEmitter from '@/helpers/eventEmitter';
import { mapGetters } from 'vuex';

export default {
    name: 'ImageArrayManager',
    components: {
        Button,
        Dropzone,
    },

    async created() {
        this.isLoading = true;
        EventEmitter.on('delete-image', this.deleteImage);
        this.initialLoadIds = this.value.map((item) => item.id);
        //Если есть выбранные изображения - загружаем их все
        if (this.initialLoadIds.length) {
            const [error, images] = await ImageService.getAll({
                type: this.imageType,
                ids: this.initialLoadIds,
                offset: 0,
            });
            if (error) {
                error.notify();
                return;
            }
            this.images = images.map((item) => new Image(item));
        }
        /*  Если выбранных изображений меньше, чем лимит у пагинации - загружаем недостающее количество с учётом
        места, занятого кнопкой upload*/
        if (this.images.length < this.pagination.limit) {
            const limit = this.pagination.limit - this.images.length;
            const [error, images] = await ImageService.getAll({
                type: this.imageType,
                notIds: this.initialLoadIds,
                limit: limit,
                offset: 0,
            });
            const lastImage = images.splice(limit - 1, 1);
            if (!lastImage.length) {
                this.hasNextPage = false;
            }
            if (error) {
                error.notify();
                return;
            }
            this.images = [...this.images, ...images.map((item) => new Image(item))];
            this.pagination.offset = limit;
        }
        this.isLoading = false;
    },

    beforeRouteUpdate(to, from, next) {
        this.$store.commit('image/CLEAR_IMAGES_LIST');
        this.getImages();
        next();
    },

    mixins: [proxyModelMixin],

    props: {
        value: {
            type: Array,
            default: () => [],
        },
        imageType: {
            type: String,
            default: 'content',
        },
    },

    data() {
        return {
            images: [],
            isLoading: false,
            isUploading: false,
            lastSelected: null,
            pagination: new Pagination({ limit: 12, storeless: true }),
            hasNextPage: true,
            initialLoadIds: [],
            placeholder: {
                uri: require('@/assets/images/image-input-placeholder.svg'),
                width: 100,
                height: 50,
            },
        };
    },

    computed: {
        paginationPage() {
            return this.pagination.offset / this.pagination.limit;
        },
        ...mapGetters('globalVars', { instanceFull: 'getInstanceFull' }),
    },

    methods: {
        getPreviewURI(image) {
            if (image === null) return this.placeholder.uri;
            const publicUrl = `https://${this.instanceFull.primaryDomain}`;
            if (image.uri === publicUrl + '/helper/placeholder.svg') return image.uri;
            return image
                ? imageUrl() + '/thumbs/inset/100x100' + image.uri
                : require('@/assets/images/product-no-photo.png');
        },
        openDeletionModal(items) {
            this.$store.dispatch('modals/openDeletionModal', { items, entity: 'image' });
        },
        async deleteImage([image]) {
            const [error] = await ImageService.removeBatch([image.id]);
            if (error) {
                error.notify();
                return;
            }
            const index = this.images.findIndex((item) => item.id === image.id);
            this.images.splice(index, 1);
            this.$store.dispatch('modals/closeDeletionModal');
            EventEmitter.trigger('show-noty', {
                type: 'success',
                text: this.$tc('notifications.deleted', 1, { entity: this.$tc('entities.image.title', 1) }),
            });
            this.$emit('delete-image', image);
        },
        selectImage(image) {
            let selectedIndex;
            const alreadySelected = this.value.find((item, index) => {
                if (item.id === image.id) {
                    selectedIndex = index;
                    return true;
                }
            });
            if (alreadySelected) {
                const selected = [...this.value];
                selected.splice(selectedIndex, 1);
                this.$emit('input', selected);
            } else {
                const selected = [...this.value, image];
                this.$emit('input', selected);
            }
        },
        close() {
            this.$emit('close');
        },
        async uploadImage(files) {
            this.isUploading = true;
            const [error, image] = await ImageService.uploadOne(this.imageType, files[0]);
            if (error) {
                error.notify();
                this.isUploading = false;
                return;
            }
            const searchElement = this.images.find((item) => item.id === image.id);
            if (!searchElement) {
                this.images.unshift(image);
            }
            //добавляем в список изначально загруженных, чтобы не выдавало картинку повторно при нажатии на "показать ещё"
            this.initialLoadIds.push(image.id);
            this.isUploading = false;
        },
        checkIfSelected(image) {
            return this.value.find((item) => item.id === image.id);
        },
        async getImages() {
            this.isLoading = true;
            if (!this.images.length && this.value) this.images.push(cloneDeep(this.value));
            const [error, images] = await ImageService.getAll({
                type: this.imageType,
                notIds: this.initialLoadIds,
                limit: this.pagination.limit + 1,
                offset: this.pagination.offset,
            });
            if (error) {
                error.notify();
                return;
            }
            //проверяем, есть ли следующая страница
            const lastImage = images.splice(this.pagination.limit, 1);
            if (!lastImage.length) {
                this.hasNextPage = false;
            }
            this.images = [...this.images, ...images.map((item) => new Image(item))];
            this.pagination.offset += this.pagination.limit;
            this.isLoading = false;
        },
    },
    beforeDestroy() {
        EventEmitter.off('delete-image', this.deleteImage);
    },
};
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';
.image-manager {
    width: 100%;
    &__list {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -4px 8px;
    }
    &__item {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%;
        padding: 4px;

        &-img {
            position: relative;
            height: 0;
            padding-bottom: 100%;
            border: 1px solid var(--v-outline-base);
            border-radius: $border-radius-root;
            background: var(--v-surfase-base);
            cursor: pointer;
            transition: $btn-transition;

            &:hover {
                border-color: var(--v-primary-accent-base);
            }

            &.is-selected {
                &::after {
                    content: '';
                    width: 100%;
                    height: 100%;
                    background: #8dcefc;
                    z-index: 1;
                    position: absolute;
                    opacity: 0.3;
                    pointer-events: none;
                }
                border-color: var(--v-primary-base);

                &::before {
                    content: 'check_circle';
                    position: absolute;
                    z-index: 2;
                    top: 5px;
                    right: 5px;
                    display: block;
                    font-family: 'Material Icons';
                    font-size: 20px;
                    line-height: 20px;
                    color: var(--v-primary-base);
                }
            }
            .image__controls {
                width: 100%;
                padding: 4px;
                justify-content: end;
            }
            .image__controls-btn {
                margin-left: auto;
                width: 22px;
                height: 22px;
                font-size: 14px;
            }
        }

        img {
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            object-fit: contain;
        }
    }

    &__btn-more {
        margin-bottom: $spacer;
    }

    &__btn-close {
        //margin-bottom: $spacer;
    }
}
</style>
