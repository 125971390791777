import { Container, Content, Heading, Section } from '@/entities/section/samples/_options';
import { ADDRESS, INPUT_SWITCH, STRING } from '@/helpers/fieldTypes.no-vuetify';

const section = {
    id: '',
    type: 'SectionContactsStyle1',
    required: false,
    group: 'Contacts',
    options: {
        section: { ...Section },
        container: { ...Container },
        heading: { ...Heading },
        content: {
            htmlBefore: { ...Content.htmlBefore },
            colorText: { ...Content.color },
            personalSettings: {
                value: false,
                ...INPUT_SWITCH,
                props: {
                    // noLabel: true,
                    size: 'small',
                    labelTrans: 'builder.sectionOptions.props.btnPersonalSettings',
                    note: 'builder.sectionOptions.props.btnPersonalSettingsNote',
                    text: 'builder.sectionOptions.props.btnPersonalSettingsText',
                },
            },
            address: {
                value: '',
                ...ADDRESS,
                props: {
                    labelTrans: 'Address',
                    rules: ['noEmpty'],
                    disabledBy: 'content.personalSettings',
                },
            },
            emails: {
                type: 'array',
                component: 'InputArray',
                value: [],
                props: {
                    rules: ['noEmpty', 'email'],
                    labelTrans: 'settings.common.emails',
                    disabledBy: 'content.personalSettings',
                },
            },
            phones: {
                type: 'array',
                component: 'InputArray',
                value: [],
                props: {
                    labelTrans: 'Phones',
                    rules: ['noEmpty', 'phone'],
                    disabledBy: 'content.personalSettings',
                },
            },
            whatsapps: {
                type: 'array',
                component: 'InputArray',
                value: [],
                props: {
                    labelTrans: 'WhatsApps',
                    rules: ['noEmpty', 'phone'],
                    disabledBy: 'content.personalSettings',
                },
            },
            workTime: {
                value: '',
                ...STRING,
                props: {
                    labelTrans: 'Work time',
                    rules: ['noEmpty'],
                    disabledBy: 'content.personalSettings',
                },
            },
            mapUrl: {
                ...Content.mapUrl,
                props: {
                    disabledBy: 'content.personalSettings',
                    note: 'builder.sectionOptions.props.mapUrlNote',
                },
            },
        },
    },
    items: [],
    children: null,
};

export default section;
