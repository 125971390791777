export default {
    // TEXT
    // default
    text_main: '#FFFFFF',
    text_body1: '#E9EBF2',
    text_body2: '#DCDEE5',
    text_accent: '#A959FF',
    text_neutral: '#E9EBF2',
    // inverted
    text_main_inverted: '#FFFFFF',
    text_body1_inverted: '#D6DAE5',
    text_body2_inverted: '#B7BCCC',
    text_accent_inverted: '#6F59FF',
    text_neutral_inverted: '#B3B9C7',

    // ACTION
    // primary
    action_primary: '#8652BA',
    action_primary_hover: '#AA61F2',
    action_primary_pressed: '#5F3687',
    action_primary_disabled: '#43414C',
    // secondary
    action_secondary: '#6A6ADE',
    action_secondary_hover: '#8080FF',
    action_secondary_pressed: '#5947CC',
    action_secondary_disabled: '#43414D',
    // neutral_light
    header_bg: '#241F2B',
    header_bg_mobile: '#160F1F',
    header_main_menu_bg: '#160F1F',
    header_main_menu_dropdown_bg: '#332B3D',
    action_neutral_light: '#FFFFFF',
    // action_neutral_light_hover: '#A057D9',
    // action_neutral_light_pressed: '#862EE5',
    action_neutral_light_hover: '#5947CC',
    action_neutral_light_pressed: '#8080FF',
    action_neutral_light_disabled: '#6C5B82',
    // neutral_medium
    action_neutral_medium: '#6D7991',
    action_neutral_medium_hover: '#725CFF',
    action_neutral_medium_pressed: '#5947CC',
    action_neutral_medium_disabled: '#43414C',
    // neutral_dark
    action_neutral_dark: '#FFFFFF',
    action_neutral_dark_hover: '#9080FF',
    action_neutral_dark_pressed: '#725CFF',
    action_neutral_dark_disabled: '#6D6D80',
    // system
    action_system: '#DE3A71',
    action_system_hover: '#FF4785',
    action_system_pressed: '#B22F5A',
    action_system_disabled: '#43414C',

    // NEUTRAL
    neutral1: '#251D30',
    neutral2: '#160F1F',
    neutral3: '#332B3D',
    neutral4: '#6C5B82',
    neutral5: '#473E52',
    neutral6: '#1C1721',
    neutral7: '#241F2B',

    // SYSTEM
    success: '#00B368',
    success_bg: '#004D2C',
    warning: '#D99100',
    warning_bg: '#4D3300',
    error: '#4D3300',
    error_bg: '#590015',

    // ACCENT
    accent1: '#D7B2FF',
    accent1_bg: '#410780',
    accent2: '#FFB6EA',
    accent2_bg: '#99006E',
    accent3: '#80FFCA',
    accent3_bg: '#007041',
    accent4: '#95B2FF',
    accent4_bg: '#0B3399',

    whatsapp: '#20B051',
};
