<template>
    <div class="page">
        <div class="page-header">
            <h1 class="page-header__text">{{ $t('menus.pageHeaders.shippingAndDelivery') }}</h1>
            <div class="page-header__buttons" v-if="isAvailable">
                <Button @click="startZoneCreating" icon="add_circle_outline">{{ $t('entities.add') }}</Button>
            </div>
        </div>
        <progress-circular class="spinner -absolute" v-if="!isLoaded && isAvailable"></progress-circular>
        <error-block v-if="!isAvailable"></error-block>
        <template v-else-if="isLoaded">
            <shipping-zone-modal
                id="shipping-zone-modal"
                @create-zone="createShippingZone"
                @update-zone="updateShippingZone"
                :zone="editingZone"
                :is-editing="isZoneEditing"
                v-model="isShippingModalActive"
            ></shipping-zone-modal>
            <rate-modal
                id="rate-modal"
                :isEditing="isRateEditing"
                @create-rate="createRate"
                @update-rate="updateRate"
                :rate="editingRate"
                v-model="isRateModalActive"
            ></rate-modal>

            <div class="page__content-container">
                <shipping-zone
                    @open-rate-modal="startRateCreating"
                    @toggle-enabled="updateShippingZone"
                    v-for="zone in shippingZones"
                    :shipping-zone="zone"
                    :key="zone.id"
                    @start-deletion="openDeletionModal"
                    @start-zone-editing="startZoneEditing"
                    @start-rate-editing="startRateEditing"
                ></shipping-zone>
            </div>
        </template>
        <span class="delivery__hint">{{ $t('entities.shippingZone.hint') }}</span>
    </div>
</template>

<script>
import countries from '@/helpers/countries';
import ShippingZoneService from '@/services/ShippingZoneService';
import shippingZone from '@/components/delivery/shippingZone';
import RateModal from '@/components/delivery/rateModal';
import ShippingZoneModal from '@/components/delivery/shppingZoneModal';
import EventEmitter from '@/helpers/eventEmitter.ts';
import ShippingZoneRateService from '@/services/ShippingZoneRateService';
import ShippingZoneRate from '@/entities/delivery/ShippingZoneRate';
import ShippingZone from '@/entities/delivery/ShippingZone';
import ErrorBlock from '@/components/common/ErrorBlock';
import Button from '@/components/common/Button';
import ProgressCircular from '@/components/common/ProgressCircular';
export default {
    name: 'ShippingZones',
    components: { ProgressCircular, ErrorBlock, ShippingZoneModal, shippingZone, RateModal, Button },
    data() {
        return {
            shippingZones: [],
            countries: [],
            panels: [],
            isShippingModalActive: false,
            isDeleteModalActive: false,
            isRateEditing: false,
            editingRate: new ShippingZoneRate(),
            isRateModalActive: false,
            isZoneEditing: false,
            editingZone: new ShippingZone(),
            isAvailable: true,
            isLoaded: false,
        };
    },
    async created() {
        this.isLoaded = false;
        EventEmitter.on('delete-shippingZone', this.deleteShippingZone);
        EventEmitter.on('delete-rate', this.deleteRate);
        this.countries = countries.map((item) => {
            return { text: item.name, value: item.code };
        });
        this.shippingZones = await this.getShippingZones();
        this.isLoaded = true;
    },

    methods: {
        openDeletionModal(items, entity) {
            this.$store.dispatch('modals/openDeletionModal', { items, entity });
        },

        failedHandler(response, entity, error) {
            error.notify();
            const children = response.data?.errors?.children;
            if (children) {
                this.fieldKeys.forEach((key) => {
                    const errors = children[key] ? children[key].errors : [];
                    if (errors) entity.fields[key].props.errors = errors;
                });
            }
        },

        //ZONES

        startZoneCreating() {
            this.isZoneEditing = false;
            this.editingZone = new ShippingZone();
            this.isShippingModalActive = true;
        },

        async createShippingZone(zone) {
            const [error, result] = await ShippingZoneService.createOne(zone.data);
            error ? this.failedHandler(result, zone, error) : await this.successCreateZoneHandler();
        },

        async successCreateZoneHandler() {
            this.shippingZones = await this.getShippingZones();
            this.panels = [...this.panels, this.shippingZones.length - 1];
            this.isShippingModalActive = false;
            EventEmitter.trigger('show-noty', {
                type: 'success',
                text: this.$tc('notifications.created', 1, { entity: this.$tc('entities.shippingZone.title', 1) }),
            });
        },

        startZoneEditing(zone) {
            this.isZoneEditing = true;
            this.editingZone = zone;
            this.isShippingModalActive = true;
        },

        async updateShippingZone(shippingZone) {
            const [error, result] = await ShippingZoneService.updateOne(shippingZone.data);
            error ? this.failedHandler(result, shippingZone, error) : await this.successUpdateZoneHandler();
        },

        async getShippingZones() {
            const [error, result] = await ShippingZoneService.getAll();

            if (error) {
                error.alert();
                this.isAvailable = false;
                return [];
            }
            return result.map((item) => new ShippingZone(item));
        },

        async successUpdateZoneHandler() {
            this.shippingZones = await this.getShippingZones();
            this.isShippingModalActive = false;
            EventEmitter.trigger('show-noty', {
                type: 'success',
                text: this.$tc('notifications.updated', 1, { entity: this.$tc('entities.shippingZone.title', 1) }),
            });
        },

        async deleteShippingZone(items) {
            const [error] = await ShippingZoneService.removeOne(items[0].id);
            if (!error) {
                EventEmitter.trigger('show-noty', {
                    type: 'success',
                    text: this.$tc('notifications.deleted', 1, { entity: this.$tc('entities.shippingZone.title', 1) }),
                });
                this.$store.dispatch('modals/closeDeletionModal');
                this.shippingZones = await this.getShippingZones();
            } else {
                error.notify();
            }
        },

        //RATES

        startRateCreating(shippingZone) {
            this.isRateEditing = false;
            this.editingRate = new ShippingZoneRate();
            this.editingZone = shippingZone;
            this.isRateModalActive = true;
        },

        startRateEditing(rate) {
            this.isRateEditing = true;
            this.editingRate = rate;
            this.isRateModalActive = true;
        },

        async updateRate(rate) {
            // тут не очень понял, почему принимаем rate, но айди задем от this.editingZone
            // и вообще как-то сложно устроенно (=
            // rate.shippingZone = this.editingZone.id;
            const [error, result] = await ShippingZoneRateService.updateOne(rate.data);
            error ? this.failedHandler(result, rate, error) : await this.successUpdateRateHandler();
            this.shippingZones = await this.getShippingZones();
        },

        async createRate(rate) {
            rate.shippingZone = this.editingZone.id;
            const [error, result] = await ShippingZoneRateService.createOne(rate.data);
            error ? this.failedHandler(result, rate, error) : await this.successCreateRateHandler();
        },

        async successCreateRateHandler() {
            this.shippingZones = await this.getShippingZones();
            const index = this.shippingZones.findIndex((item) => item.id === this.editingZone.id);
            this.panels = [...this.panels, index];
            this.isRateModalActive = false;
            EventEmitter.trigger('show-noty', {
                type: 'success',
                text: this.$tc('notifications.created', 1, { entity: this.$tc('entities.rate.title', 1) }),
            });
        },

        async successUpdateRateHandler() {
            this.shippingZones = await this.getShippingZones();
            this.isRateModalActive = false;
            EventEmitter.trigger('show-noty', {
                type: 'success',
                text: this.$tc('notifications.updated', 1, { entity: this.$tc('entities.rate.title', 1) }),
            });
        },

        async deleteRate(items) {
            const [error] = await ShippingZoneRateService.removeOne(items[0].id);
            if (!error) {
                EventEmitter.trigger('show-noty', {
                    type: 'success',
                    text: this.$tc('notifications.deleted', 1, { entity: this.$tc('entities.rate.title', 1) }),
                });
                this.$store.dispatch('modals/closeDeletionModal');
                this.shippingZones = await this.getShippingZones();
            } else {
                error.notify();
            }
        },
    },
    beforeDestroy() {
        EventEmitter.off('delete-shippingZone', this.deleteShippingZone);
        EventEmitter.off('delete-rate', this.deleteRate);
    },
};
</script>

<style lang="scss">
.delivery {
    &__hint {
        font-weight: 500;
    }
}
</style>
