interface ICreateLayoutVariationProps {
    type?: 'list' | 'grid' | 'carousel' | 'masonry' | 'centeredColumns' | null | undefined;
    columns?: number | null | undefined;
    align?: 'flex-start' | 'center' | 'flex-end' | 'flex-around' | null | undefined;
    gap?: number | null | undefined;
    rows?: number | null | undefined;
    dots?: boolean | null | undefined;
    grid?: string | null | undefined;
}
interface ICreateLayoutVariationResult {
    type: string;
    listColumn: number;
    listAlign: string;
    gap: number;
    columns: number;
    carouselColumns: number;
    gridTemplate: string;
    rows: number;
    dots: boolean;
}

export const createLayoutVariation = ({
    type,
    columns,
    align,
    gap,
    rows,
    dots,
    grid,
}: ICreateLayoutVariationProps): ICreateLayoutVariationResult => {
    return {
        type: type ? type : 'list',
        listColumn: columns ? columns : 3,
        listAlign: align ? align : 'flex-start',
        gap: gap ? gap : 20,
        columns: columns ? columns : 3,
        carouselColumns: columns ? columns : 3,
        gridTemplate: grid ? grid : '1x1x1',
        rows: rows ? rows : 1,
        dots: typeof dots === 'boolean' ? dots : true,
    };
};
