<template>
    <div v-on="$listeners" v-bind="$attrs" class="chip" :class="classes">
        <slot name="prepend">
            <span class="icon" v-if="icon">{{ icon }}</span>
        </slot>
        <slot></slot>
        <slot name="append" />
    </div>
</template>

<script>
export default {
    name: 'Chip',
    props: {
        icon: {
            type: String,
            default: '',
        },
        type: {
            type: String,
            default: '',
        },
        color: {
            type: String,
            default: null,
        },
    },
    computed: {
        classes() {
            return {
                '-success': this.type === 'success',
                '-warning': this.type === 'warning',
                '-error': this.type === 'error',
                '-combobox': this.type === 'combobox',
            };
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.chip {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    padding: 2px 12px;
    gap: 6px;
    background-color: var(--v-primary-lighten-base);
    border-radius: 50px;
    height: 24px !important;
    color: var(--v-primary-base);
    font-size: 12px;
    font-weight: 500;
    position: relative;
    cursor: default;
    &.-success {
        background-color: rgba(13, 189, 101, 0.1);
        color: var(--v-success-base) !important;
    }
    &.-error {
        background-color: rgba(237, 36, 73, 0.1) !important;
        color: var(--v-warning-base) !important;
        &:hover {
            cursor: pointer;
            opacity: 0.8;
        }
    }
    &.-warning {
        background-color: rgba(246, 201, 26, 0.1) !important;
        color: var(--v-info-base);
        &:hover {
            cursor: pointer;
            opacity: 0.8;
        }
    }
    &.-combobox {
        color: rgba(0, 0, 0, 0.87);
        background: #e6e8eb !important;
        &:hover {
            cursor: pointer;
            opacity: 0.8;
        }
    }
    &.icon {
        padding: 0;
        width: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 15px;
        height: 24px !important;
        border-radius: 100%;
    }
}
</style>
