export default {
    errors: {
        empty: 'This value should not be blank.',
        atLeastOne: 'Please specify target URL or provide subject, but not both',
        url: 'This value is not a valid URL',
        email: 'Email address format is incorrect',
        phone: 'Phone format is incorrect',
        notSelected: 'Select at least one',
        lessThan: 'Should be less than full price',
        biggerThan: 'Should be bigger than minimum value',
        discount: 'Please enter correct value',
        equalTo: 'Passwords should be the same',
        domainIsNotConfirmed: 'This domain is not confirmed',
        domainCantBeUsedInEmail: "This domain can't be used as Email",
        domainIsNotFound: 'This domain is not found',
        optionValuesTypeNumber: 'The values of this option must be integers',
        size: 'All metrics should be specified',
        domain: 'Domain name is not valid',
        orderQuantity: 'This product is not available in such quantity',
        maxLength: 'This value should not be longer than {length} symbols',
        resolution: 'Please enter correct value',
    },
};
