import { uuid } from 'vue-uuid';
import initFields from '@/entities/product/ProductReview.fields';
import { formatDateForInput } from '@/helpers/utils';

/**
 * Класс экземпляра отзыва товара
 */
export default class ProductReview {
    type = 'product_review';
    fields = initFields();
    /**
     *
     * @param {Object} - объект данных значения опции
     */
    constructor({
        id = uuid.v4(),
        productId = null,
        userId = '',
        enabled = false,
        rating = null,
        name = '',
        email = '',
        text = '',
        createdAt = Date.now(),
    } = {}) {
        this.id = id;
        this.productId = productId;
        this.userId = userId;
        this.enabled = enabled;
        this.rating = rating;
        this.name = name;
        this.email = email;
        this.text = text;
        this.createdAt = formatDateForInput(new Date(createdAt));
    }

    get data() {
        return {
            id: this.id,
            productId: this.productId,
            userId: this.userId,
            enabled: this.enabled,
            rating: this.rating,
            name: this.name,
            email: this.email,
            text: this.text,
            createdAt: new Date(this.createdAt),
        };
    }
}
