<template>
    <div class="page -column -shrunk">
        <progress-circular class="product-form__spinner spinner" v-if="isLoading && isAvailable"></progress-circular>
        <template v-else-if="!isAvailable">
            <div class="page-header">
                <h1 class="page-header__text">{{ $t('entities.lead.title') }}</h1>
            </div>
            <error-block></error-block>
        </template>
        <template v-else>
            <div class="page-header">
                <h1 class="page-header__text">{{ $t('entities.lead.title') }} №{{ currentState.number }}</h1>
            </div>
            <alert type="success" class="entity-item__success-alert" v-if="isUpdated">
                {{ $tc('notifications.updated', 1, { entity: this.$tc('entities.lead.title', 1) }) }}
            </alert>

            <div class="block">
                <form class="block__body form" ref="form">
                    <div class="form-item lead__created-at">
                        <div class="form-item__label">{{ $t('entities.lead.fields.createdAt') }}</div>
                        <div>{{ new Date(currentState.createdAt) | localDate }}</div>
                    </div>
                    <template v-for="(field, key) in currentState.fields">
                        <form-item
                            class="modal__input"
                            v-if="key !== 'comment'"
                            :key="key"
                            v-bind="field.props"
                            :name="key"
                        >
                            <component
                                :is="field.component"
                                v-model="currentState[key]"
                                v-bind="field.props || {}"
                                @update-field="onUpdateFieldValue"
                                :name="key"
                                hide-details
                                outlined
                                dense
                            ></component>
                        </form-item>
                    </template>
                    <div class="form-item" :class="{ '-half-width': currentState.product }">
                        <div class="form-item__label">{{ $t('entities.lead.fields.text') }}</div>
                        <div class="lead__text">{{ currentState.text }}</div>
                    </div>
                    <div class="lead__product form-item -half-width" v-if="currentState.product">
                        <p class="lead-product__header">{{ $tc('entities.product.title', 1) }}</p>
                        <div>
                            <template v-if="product">
                                <router-link
                                    class="lead__product-info"
                                    :to="{ name: 'Edit Product', params: { id: product.id } }"
                                >
                                    <table-image :image="getImage(product)" class="image"></table-image>
                                    <div class="text">
                                        <h4>{{ product.title }}</h4>
                                    </div>
                                </router-link>
                            </template>
                            <div v-else-if="product === false">{{ $t('lists.columns.deleted') }}</div>
                            <progress-circular class="lead__spinner spinner lead__spinner" v-else></progress-circular>
                        </div>
                    </div>
                    <form-item class="modal__input" v-bind="currentState.fields.comment.props" name="comment">
                        <Textarea
                            v-model="currentState.comment"
                            v-bind="currentState.fields.comment.props || {}"
                            @update-field="onUpdateFieldValue"
                            name="comment"
                            hide-details
                            outlined
                            dense
                        ></Textarea>
                    </form-item>

                    <Button :disabled="!isSaveAllowed" class="ml-auto" icon="check" @click="onUpdateLead">
                        {{ $t('entities.save') }}
                    </Button>
                </form>
            </div>
        </template>
    </div>
</template>

<script>
import Modal from '@/components/common/Modal';
import FormItem from '@/components/form/item';
import validateField from '@/helpers/validator';
import { debounce, cloneDeep, isEqual } from 'lodash';
import EventEmitter from '@/helpers/eventEmitter.ts';
import Lead from '@/entities/lead/Lead';
import LeadService from '@/services/LeadService';
import ProductService from '@/services/ProductService';
import TableImage from '@/components/common/TableImage';
import Product from '@/entities/product/Product';
import Textarea from '@/components/form/controls/Textarea';
import Button from '@/components/common/Button';
import ProgressCircular from '@/components/common/ProgressCircular';
import Alert from '@/components/common/Alert';

export default {
    name: 'LeadItem',
    components: { Alert, FormItem, ProgressCircular, Modal, TableImage, Textarea, Button },

    data() {
        return {
            isValid: false,
            currentState: new Lead(),
            fieldKeys: Object.keys(new Lead().fields),
            isLoading: false,
            initialState: null,
            isAvailable: true,
            isSaveAllowed: false,
            isUpdated: false,
            product: null,
        };
    },

    computed: {
        leadId() {
            return this.$route.params.id;
        },
    },

    async created() {
        await this.getLead();
        this.initialState = cloneDeep(this.currentState);
        if (this.currentState.product) {
            await this.getProduct();
        }
    },

    methods: {
        async getLead() {
            this.isLoading = true;
            const [error, result] = await LeadService.getOne(this.leadId);
            if (error) {
                error.alert();
                this.isAvailable = false;
                return;
            }
            this.currentState = new Lead(result);
            this.isLoading = false;
        },

        async getProduct() {
            const id = this.currentState.product;
            const [error, product] = await ProductService.getOneVariation(id);
            if (error) {
                error.notify();
                this.product = false;
                return;
            }
            this.product = new Product(product);
        },

        getImage(item) {
            const variation = item?.variations?.find((item) => item.id === this.currentState.product);
            return variation.image ? variation.image || item.images[0]?.file : item.images?.[0];
        },

        async onUpdateLead() {
            const isFormValid = this.validateForm();
            if (isFormValid === false) return;
            const [error, result] = await LeadService.updateOne(this.currentState.data);
            error ? this.failedHandler(result, error) : this.successHandler();
        },

        successHandler() {
            this.isUpdated = true;
            this.initialState = cloneDeep(this.currentState);
            this.isSaveAllowed = false;
            window.scrollTo({ top: 0, behavior: 'smooth' });
        },

        validateForm() {
            let result = true;
            const fieldKeys = Object.keys(this.currentState.fields);
            fieldKeys.forEach((key) => {
                const errors = validateField(this.currentState[key], this.currentState.fields[key].props.rules);
                if (errors.length !== 0) result = false;
                this.$set(this.currentState.fields[key].props, 'errors', errors);
            });
            if (result === false)
                EventEmitter.trigger('show-noty', {
                    type: 'error',
                    text: this.$t('notifications.validation.error'),
                });
            return result;
        },

        failedHandler(response, error) {
            error.notify();
            const children = response.data?.errors?.children;
            if (children) {
                this.fieldKeys.forEach((key) => {
                    const errors = children[key] ? children[key].errors : [];
                    if (errors) this.$set(this.currentState.fields[key].props, 'errors', errors);
                });
            }
        },

        onUpdateFieldValue: debounce(function(payload) {
            const { name } = payload;
            const errors = validateField(this.currentState[name], this.currentState.fields[name].props.rules);
            this.$set(this.currentState.fields[name].props, 'errors', errors);
        }, 600),

        getFieldEvents(events) {
            const fieldEvents = {};
            for (const key in events) {
                fieldEvents[key] = events[key]?.bind(this.currentState);
            }
            return fieldEvents;
        },
    },
    watch: {
        currentState: {
            handler(val) {
                if (this.initialState) {
                    this.isSaveAllowed = !isEqual(val.data, this.initialState.data);
                }
            },
            deep: true,
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.lead {
    &__product {
        width: 100%;
        min-height: 66px;
        display: flex;
        flex-wrap: wrap;
        & .lead-product__header {
            width: 100%;
            margin-bottom: 8px;
            margin-top: 0;
        }
    }
    &__product-info {
        display: flex;
        &:hover {
            h4 {
                color: var(--v-primary-accent-base);
            }
        }
        & .image {
            width: 66px;
            height: 66px;
            border-radius: 4px;
            margin-right: 24px;
        }

        & .text {
            display: flex;
            flex-direction: column;
            align-items: start;
        }

        & h4 {
            font-size: 19px;
            font-weight: 500;
            color: var(--v-primary-base);
            margin-top: auto;
            margin-bottom: auto;
        }

        & .lead__spinner {
            margin-left: 0;
        }
    }
    &__text {
        padding: 12px;
        border: 1px solid #cececf;
        min-height: 66px;
        border-radius: 4px;
    }
}
</style>
