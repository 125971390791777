import ApiClient from '@/api/apiClient';
import CustomError from '@/entities/customError';
import { Option, OptionsRequestParams } from '@/services/services.types';

class OptionService {
    /**
     * Получение опций
     * @param {OptionsRequestParams} params - параметры запроса (sort, direction, ids, etc.)
     * @returns {Promise} - массив объектов опций
     */

    async getAll(params: OptionsRequestParams = {}): Promise<[CustomError | null, Option[] | []]> {
        const url: string = `/options`;
        const defaultValue = [];
        const errorPath: string = '[api:option:getAll]';
        return await ApiClient.admin.get({ url, defaultValue, errorPath, config: { params } });
    }

    /**
     * Получение конкретной опции
     * @param {String} id - id опции
     * @returns {Promise} -  объект опции
     */

    async getOne(id: string): Promise<[CustomError | null, Option | null]> {
        const url: string = `/options/${id}`;
        const defaultValue = null;
        const errorPath: string = '[api:option:getOne]';
        return await ApiClient.admin.get({ url, defaultValue, errorPath });
    }

    /**
     * Создание опции
     * @param {Object} option - объект опции
     * @returns {Promise} - созданный объект опции
     */

    async createOne(option: Option): Promise<[CustomError | null, Option | null]> {
        const url: string = `/options`;
        const defaultValue = null;
        const errorPath: string = '[api:option:createOne]';
        return await ApiClient.admin.post({ url, defaultValue, errorPath, data: option });
    }

    /**
     * Обновление опции
     * @param {Object} option - объект опции
     * @returns {Promise} - обновлённый объект опции
     */

    async updateOne(option: Option): Promise<[CustomError | null, Option | null]> {
        const url: string = `/options/${option.id}`;
        const defaultValue = null;
        const errorPath: string = '[api:option:updateOne]';
        return await ApiClient.admin.put({ url, defaultValue, errorPath, data: option });
    }

    /**
     * Обновление опций
     * @param {Array} options - массив опций
     * @returns {Promise} - массив обновлённых опций
     */

    async updateBatch(options: Option[]): Promise<[CustomError | null, Option[] | []]> {
        const url: string = `/options/batch`;
        const defaultValue = [];
        const errorPath: string = '[api:option:updateBatch]';
        const config = {
            params: {
                ids: options.map((option) => option.id),
            },
        };
        return await ApiClient.admin.put({ url, defaultValue, errorPath, data: options, config });
    }

    /**
     * Создание нескольких опций
     * @param {Array} options - массив опций
     * @returns {Promise} - массив созданных опций
     */

    async createBatch(options: Option[]): Promise<[CustomError | null, Option[] | []]> {
        const url: string = `/options/batch`;
        const defaultValue = [];
        const errorPath: string = '[api:option:createBatch]';
        return await ApiClient.admin.post({ url, defaultValue, errorPath, data: options });
    }

    /**
     * Удаление опции
     * @param {String} id - id опции
     * @returns {Promise} - null
     */

    async removeOne(id: string): Promise<[CustomError | null, null]> {
        const url: string = `/options/${id}`;
        const defaultValue = null;
        const errorPath: string = '[api:option:removeOne]';
        return await ApiClient.admin.delete({ url, defaultValue, errorPath });
    }
}

export default new OptionService();
