<template>
    <div class="color-scheme-preview">
        <span class="color-scheme-preview__icon" :style="{ background: getSchemeColor('header_bg') }"></span>
        <span class="color-scheme-preview__icon" :style="{ background: getSchemeColor('action_primary') }"></span>
        <span class="color-scheme-preview__icon" :style="{ background: getSchemeColor('text_main') }"></span>
    </div>
</template>

<script>
import * as colorSchemes from '@/configs/color-schemas';

export default {
    name: 'ColorSchemePreview',
    props: { scheme: { type: String, default: '' } },
    methods: {
        getSchemeColor(colorName) {
            const scheme = this.scheme.toLowerCase().replace(/\s/g, '');
            return colorSchemes[scheme] ? colorSchemes[scheme][colorName] : '#FFFFFF';
        },
    },
};
</script>

<style lang="scss">
.color-scheme-preview {
    display: flex;
    :not(:first-child) {
        margin-left: -6px;
    }

    &__icon {
        width: 24px;
        height: 24px;
        border-radius: 50px;
        display: block;
        border: 2px solid var(--v-outline-base);
    }
}
</style>
