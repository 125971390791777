<template>
    <modal :header="$t('entities.product.buttons.importButton')" width="632" v-model="model">
        <template v-if="isLoading">
            <div class="import-modal-loader">
                <progress-circular class="builder-preview__loader" color="primary"></progress-circular>
            </div>
        </template>
        <template v-else>
            <alert class="entity-item__success-alert" v-if="isSuccessful" type="success">{{
                $t('entities.product.import.tooltips.success')
            }}</alert>
            <alert class="entity-item__success-alert" v-if="isFailed" type="error">{{
                $t('entities.product.import.tooltips.failed')
            }}</alert>
            <div v-if="!isFailed && !isSuccessful" class="form">
                <form-item v-bind="form.items.importFrom.props">
                    <Select v-model="form.items.importFrom.value" v-bind="form.items.importFrom.props"></Select>
                </form-item>
                <form-item v-if="model" v-bind="form.items.file.props">
                    <FileInput
                        v-model="form.items.file.value"
                        :name="'file'"
                        @input="updateField('file')"
                        :label="''"
                    />
                </form-item>
            </div>
            <Button
                v-if="!isFailed && !isSuccessful"
                :disabled="!form.items.file.value"
                class="modal__import-btn"
                icon="check"
                @click="onSubmit"
                >{{ $t('entities.save') }}</Button
            >
            <Button @click="isFailed = false" icon="restart_alt" v-if="isFailed">{{
                $t('entities.product.import.buttons.tryAgain')
            }}</Button>
            <Button @click="model = false" v-if="isSuccessful" icon="close">{{
                $t('entities.product.import.buttons.close')
            }}</Button>
        </template>
    </modal>
</template>

<script>
import { proxyModelMixin } from '@/mixins/proxyModelMixin';
import EventEmitter from '@/helpers/eventEmitter.ts';
import ProductService from '@/services/ProductService';
import Form from '@/components/form/Form';
import FormItem from '@/components/form/item';
import Modal from '@/components/common/Modal';
import FileInput from '@/components/form/controls/FileInput.vue';
import Select from '@/components/form/controls/Select';
import Button from '@/components/common/Button';
import ProgressCircular from '@/components/common/ProgressCircular';
import Alert from '@/components/common/Alert';

export default {
    name: 'ImportModal',
    components: { Alert, Select, ProgressCircular, FormItem, Modal, FileInput, Button },
    mixins: [proxyModelMixin],
    props: {
        value: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            form: new Form({
                action: this.formAction,
                validateHandler: this.validateHandler,
                successHandler: this.successHandler,
                failedHandler: this.failedHandler,
                items: {
                    importFrom: {
                        type: 'field',
                        value: 'runshop',
                        props: {
                            label: 'entities.product.import.title',
                            items: [
                                { text: 'entities.product.import.options.shopify', value: 'shopify' },
                                { text: 'entities.product.import.options.runshop', value: 'runshop' },
                            ],
                        },
                    },
                    file: {
                        type: 'field',
                        value: null,
                        props: {
                            rules: ['noEmpty'],
                            labelTrans: 'File .csv',
                        },
                    },
                },
            }),
            isLoading: false,
            isFailed: false,
            isSuccessful: false,
            error: null,
        };
    },
    methods: {
        validateHandler() {},
        successHandler() {
            EventEmitter.trigger('show-noty', {
                type: 'success',
                text: this.$t('notifications.imported'),
            });
            EventEmitter.trigger('refresh-products-page');
            this.isSuccessful = true;
            this.isFailed = false;
            this.form.items.file.value = null;
            this.isLoading = false;
        },
        failedHandler() {
            EventEmitter.trigger('refresh-products-page');
            this.isFailed = true;
            this.isSuccessful = false;
            this.form.items.file.value = null;
            this.isLoading = false;
        },
        formAction(formData) {
            return formData.importFrom === 'runshop'
                ? ProductService.import(formData)
                : ProductService.importShopify(formData);
        },
        async onSubmit() {
            this.isLoading = true;
            await this.form.submit();
        },
        updateField(fieldKey, groupKey = null, index = null) {
            this.form.validateField(fieldKey, groupKey, index);
        },
    },
    watch: {
        model: function() {
            this.$set(this.form.items.file, 'value', null);
            this.$set(this.form.items.file.props, 'errors', []);
            this.isFailed = false;
            this.isSuccessful = false;
        },
    },
};
</script>

<style lang="scss">
.import-modal-loader {
    display: flex;
    justify-content: center;
    padding: 52px;
}
</style>
