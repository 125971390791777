import ApiClient from '@/api/apiClient';
import CustomError from '@/entities/customError';
import { SortingRequestParams } from '@/services/services.types';
import Category from '@/entities/blog/Category';
import ImageService from '@/services/ImageService';

class CategoryService {
    /**
     * Получение категорий
     * @param {SortingRequestParams} params - параметры запроса (sort, direction, ids, etc.)
     * @returns {Promise} - массив объектов категорий
     */

    async getAll(params: SortingRequestParams = {}): Promise<[CustomError | null, Category[] | []]> {
        const url: string = `/categories`;
        const defaultValue = [];
        const errorPath: string = '[api:categories:getAll]';
        return await ApiClient.admin.get({ url, defaultValue, errorPath, config: { params } });
    }
    /**
     * Получение отдельной категории
     * @param {String} id - id категории
     * @returns {Promise} - объект категории
     */

    async getOne(id: string): Promise<[CustomError | null, Category | null]> {
        const url: string = `/categories/${id}`;
        const defaultValue = null;
        const errorPath: string = '[api:category:getOne]';
        return await ApiClient.admin.get({ url, defaultValue, errorPath });
    }
    /**
     * Создание категории
     * @param {Object} category - объект категории
     * @returns {Promise} - кастомный объект, содержащий ответ сервера
     */

    async createOne(category: Category): Promise<[CustomError | null, Category | null]> {
        const url: string = `/categories`;
        const defaultValue = null;
        const errorPath: string = '[api:category:createOne]';
        return await ApiClient.admin.post({ url, defaultValue, errorPath, data: category });
    }
    /**
     * Обновление категории
     * @param {Object} category - объект категории
     * @returns {Promise} - кастомный объект, содержащий ответ сервера
     */

    async updateOne(category: Category): Promise<[CustomError | null, Category | null]> {
        const url: string = `/categories/${category.id}`;
        const defaultValue = null;
        const errorPath: string = '[api:category:updateOne]';
        return await ApiClient.admin.put({ url, defaultValue, errorPath, data: category });
    }

    /**
     * Обновление нескольких категорий
     * @param {Array} categories - массив объектов категорий
     */
    async updateBatch(categories: Category[]): Promise<[CustomError | null, Category[] | []]> {
        const url: string = `/categories/batch`;
        const defaultValue = [];
        const errorPath: string = '[api:catalog:updateBatch]';
        const config = { params: { ids: categories.map((category) => category.id) } };
        return await ApiClient.admin.put({
            url,
            defaultValue,
            errorPath,
            data: categories,
            config,
        });
    }

    /**
     * Удаление категории
     * @param {Object} id - объект категории
     */

    async removeOne(id: string): Promise<[CustomError | null, null]> {
        const url: string = `/categories/${id}`;
        const defaultValue = null;
        const errorPath: string = '[api:category:removeOne]';
        return await ApiClient.admin.delete({ url, defaultValue, errorPath });
    }
    async getImages(categories: Category[]): Promise<[CustomError | null, { [key: string]: string } | {}]> {
        const map = {};
        const imagesIds: string[] = [];
        categories.forEach((category) => {
            if (category.previewImageId) {
                map[category.previewImageId] = category.id;
                imagesIds.push(category.previewImageId);
            }
        });
        if (!imagesIds.length) {
            return [null, {}];
        }
        const imagesObj = {};
        const [error, images] = await ImageService.getAll({ ids: imagesIds });
        images.forEach((image) => {
            const categoryId = map[image?.id];
            imagesObj[categoryId] = image;
        });
        return [error, imagesObj];
    }
}

export default new CategoryService();
