import { uuid } from 'vue-uuid';

/**
 * Класс экземпляра картинки
 */
export default class Image {
    selected = false;

    /**
     *
     * @param {Object}  data - объект данных картинки
     */
    constructor(
        data = {
            id: uuid.v4(),
            type: 'content',
            width: 0,
            height: 0,
            alt: '',
            title: '',
            name: '',
            ownerId: '',
            extension: '',
            mime: '',
            size: 0,
            createdAt: new Date(),
            uri: '',
        }
    ) {
        this.id = data.id;
        this.type = data.type;
        this.width = data.width;
        this.height = data.height;
        this.alt = data.alt;
        this.ownerId = data.ownerId;
        this.title = data.title;
        this.name = data.name;
        this.extension = data.extension;
        this.mime = data.mime;
        this.size = data.size;
        this.uri = data.uri;
        this.createdAt = data.createdAt;
    }

    get src() {
        const baseUrl = process.env.VUE_APP_IMAGE_BASE_URL || '';
        return `${baseUrl}${this.uri}`;
    }
}
