import { cloneDeep, merge } from 'lodash';
import { SELECT } from '@/helpers/fieldTypes.no-vuetify';
import { Container, Content, Footer, Heading, Section } from '@/entities/section/samples/_options';

const section = {
    id: '',
    type: 'SectionCategoryStyle8',
    required: false,
    group: 'Category',
    options: {
        section: { ...Section },
        container: { ...Container },
        heading: { ...Heading },
        content: {
            htmlBefore: { ...Content.htmlBefore },
            htmlAfter: { ...Content.htmlAfter },
            colorText: { ...Content.color },
        },
        columns: {
            desktop: {
                value: 3,
                ...SELECT,
                ...{
                    props: {
                        labelTrans: 'builder.sectionOptions.props.desktop',
                        items: [2, 3, 4],
                    },
                },
            },
            tablet: {
                value: 1,
                ...SELECT,
                ...{
                    props: {
                        labelTrans: 'builder.sectionOptions.props.tablet',
                        items: [1, 2, 3],
                    },
                },
            },
            mobile: {
                value: 1,
                ...SELECT,
                ...{
                    props: {
                        labelTrans: 'builder.sectionOptions.props.mobile',
                        items: [1, 2],
                    },
                },
            },
        },
        footer: { ...Footer },
    },
    items: [],
    children: {
        id: '',
        type: 'category',
        options: {
            heading: {
                title: {
                    ...Heading.title,
                    value: 'Category Name',
                },
                colorTitle: { ...Heading.colorTitle },
                textAlign: { ...Heading.textAlign, value: 'left' },
            },
            content: {
                link: { ...Content.categoryLink },
                image: merge(Content.image, {
                    props: { note: 'JPG, PNG. Recomended size: 400х400px. No more than 40kb' },
                }),
                alt: {
                    ...Content.imageAlt,
                    value: 'Category Name',
                },
                note: { ...Content.text },
                showButton: { ...Content.showButton },
                btnText: { ...Content.btnText },
            },
        },
    },
};

const itemFixture = cloneDeep(section.children);
const itemsFixture = [];
itemsFixture.push(Object.assign(cloneDeep(itemFixture), { position: 0 }));
itemsFixture.push(Object.assign(cloneDeep(itemFixture), { position: 1 }));
itemsFixture.push(Object.assign(cloneDeep(itemFixture), { position: 2 }));
section.items = itemsFixture;

export default section;
