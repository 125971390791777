const FONTS = [
    'Anton',
    'Monserrat',
    'Open Sans',
    'Oswald',
    'Oxygen',
    'Petrona',
    'PT Sans',
    'Ubuntu',
    'Unbounded',
    'Manrope',
    'Roboto',
    'Barlow Semi Condensed',
    'Fira Sans',
    'Poppins',
];

export default {
    scheme: {
        id: null,
        position: 0,
        type: 'select',
        component: 'Select',
        value: 'Scheme 1',
        options: {
            variants: [
                {
                    text: 'Theme 1',
                    value: 'Scheme 1',
                },
                {
                    text: 'Theme 2',
                    value: 'Scheme 2',
                },
                {
                    text: 'Theme 3',
                    value: 'Scheme 3',
                },
                {
                    text: 'Theme 4',
                    value: 'Scheme 4',
                },
                {
                    text: 'Theme 5',
                    value: 'Scheme 5',
                },
                {
                    text: 'Theme 6',
                    value: 'Scheme 6',
                },
                {
                    text: 'Theme 7',
                    value: 'Scheme 7',
                },
                {
                    text: 'Theme 8',
                    value: 'Scheme 8',
                },
                {
                    text: 'Theme 9',
                    value: 'Scheme 9',
                },
                {
                    text: 'Theme 10',
                    value: 'Scheme 10',
                },
                {
                    text: 'Theme 11',
                    value: 'Scheme 11',
                },
                {
                    text: 'Theme Custom',
                    value: 'Custom',
                },
            ],
            items: [
                {
                    text: 'Theme 1',
                    value: 'Scheme 1',
                },
                {
                    text: 'Theme 2',
                    value: 'Scheme 2',
                },
                {
                    text: 'Theme 3',
                    value: 'Scheme 3',
                },
                {
                    text: 'Theme 4',
                    value: 'Scheme 4',
                },
                {
                    text: 'Theme 5',
                    value: 'Scheme 5',
                },
                {
                    text: 'Theme 6',
                    value: 'Scheme 6',
                },
                {
                    text: 'Theme 7',
                    value: 'Scheme 7',
                },
                {
                    text: 'Theme 8',
                    value: 'Scheme 8',
                },
                {
                    text: 'Theme 9',
                    value: 'Scheme 9',
                },
                {
                    text: 'Theme 10',
                    value: 'Scheme 10',
                },
                {
                    text: 'Theme 11',
                    value: 'Scheme 11',
                },
                {
                    text: 'Theme Custom',
                    value: 'Custom',
                },
            ],
            labelTrans: 'settings.styles.scheme',
        },
    },
    content_width: {
        id: null,
        position: 64,
        type: 'select',
        component: 'Select',
        value: '1170px',
        options: {
            variants: [
                {
                    text: '1170px',
                    value: '1170px',
                },
                {
                    text: '1280px',
                    value: '1280px',
                },
                {
                    text: '1360px',
                    value: '1360px',
                },
                {
                    text: '1440px',
                    value: '1440px',
                },
            ],
            items: [
                {
                    text: '1170px',
                    value: '1170px',
                },
                {
                    text: '1280px',
                    value: '1280px',
                },
                {
                    text: '1360px',
                    value: '1360px',
                },
                {
                    text: '1440px',
                    value: '1440px',
                },
            ],
            labelTrans: 'settings.styles.content_width',
        },
    },
    border_radius_block: {
        id: null,
        position: 70,
        type: 'number',
        component: 'InputNumberAndRange',
        value: 16,
        options: {
            labelTrans: 'settings.styles.border_radius_block',
            min: 0,
            max: 30,
        },
    },
    border_radius_btn: {
        id: null,
        position: 70,
        type: 'number',
        component: 'InputNumberAndRange',
        value: 8,
        options: {
            labelTrans: 'settings.styles.border_radius_btn',
            min: 0,
            max: 25,
        },
    },
    border_radius_input: {
        id: null,
        position: 70,
        type: 'number',
        component: 'InputNumberAndRange',
        value: 8,
        options: {
            labelTrans: 'settings.styles.border_radius_input',
            min: 0,
            max: 25,
        },
    },
    font_family_head: {
        id: null,
        type: 'select',
        component: 'Select',
        position: 5,
        value: 'Monserrat',
        options: {
            variants: FONTS,
            items: FONTS,
            labelTrans: 'settings.styles.font_family_head',
        },
    },
    font_family_body: {
        id: null,
        type: 'select',
        component: 'Select',
        position: 6,
        value: 'Monserrat',
        options: {
            variants: FONTS,
            items: FONTS,
            labelTrans: 'settings.styles.font_family_body',
        },
    },
    font_style_head: {
        id: null,
        type: 'select',
        component: 'Select',
        position: 7,
        value: 'normal',
        options: {
            items: [
                { text: 'normal', value: 'normal' },
                { text: 'italic', value: 'italic' },
            ],
            labelTrans: 'settings.styles.font_style_head',
        },
    },
    font_weight_head: {
        id: null,
        type: 'select',
        component: 'Select',
        position: 7,
        value: '400',
        options: {
            items: [
                { text: 'light', value: '300' },
                { text: 'normal', value: '400' },
                { text: 'medium', value: '500' },
                { text: 'semibold', value: '600' },
                { text: 'bold', value: '700' },
            ],
            labelTrans: 'settings.styles.font_weight_head',
        },
    },
    font_size_head: {
        id: null,
        type: 'select',
        component: 'Select',
        position: 7,
        value: 'normal',
        options: {
            items: [
                { text: 'small', value: 'small' },
                { text: 'normal', value: 'normal' },
                { text: 'big', value: 'big' },
            ],
            labelTrans: 'settings.styles.font_size_head',
        },
    },
    // font_style_body: {
    //     id: null,
    //     type: 'select',
    //     position: 8,
    //     value: '',
    //     options: {
    //         labelTrans: 'settings.styles.font_style_body',
    //     },
    // },
    main_background: {
        id: null,
        type: 'color',
        position: 9,
        value: '#FFFFFF',
        options: {
            labelTrans: 'settings.styles.main_background',
        },
    },
    main_headline_color: {
        id: null,
        type: 'color',
        position: 10,
        value: '#020305',
        options: {
            labelTrans: 'settings.styles.main_headline_color',
        },
    },
    main_content_text: {
        id: null,
        type: 'color',
        position: 11,
        value: '#4A5264',
        options: {
            labelTrans: 'settings.styles.main_content_text',
        },
    },
    main_content_link: {
        id: null,
        type: 'color',
        position: 12,
        value: '#6FC1CB',
        options: {
            labelTrans: 'settings.styles.main_content_link',
        },
    },
    main_content_link_hover: {
        id: null,
        type: 'color',
        position: 13,
        value: '#B3DCE1',
        options: {
            labelTrans: 'settings.styles.main_content_link_hover',
        },
    },
    main_content_link_active: {
        id: null,
        type: 'color',
        position: 14,
        value: '#429BA6',
        options: {
            labelTrans: 'settings.styles.main_content_link_active',
        },
    },
    inverted_background: {
        id: null,
        type: 'color',
        position: 15,
        value: '#2D323D',
        options: {
            labelTrans: 'settings.styles.inverted_background',
        },
    },
    inverted_headline_color: {
        id: null,
        type: 'color',
        position: 16,
        value: '#FFFFFF',
        options: {
            labelTrans: 'settings.styles.inverted_headline_color',
        },
    },
    inverted_content_text: {
        id: null,
        type: 'color',
        position: 17,
        value: '#C7CCD6',
        options: {
            labelTrans: 'settings.styles.inverted_content_text',
        },
    },
    inverted_content_link: {
        id: null,
        type: 'color',
        position: 18,
        value: '#6FC1CB',
        options: {
            labelTrans: 'settings.styles.inverted_content_link',
        },
    },
    inverted_content_link_hover: {
        id: null,
        type: 'color',
        position: 19,
        value: '#B3DCE1',
        options: {
            labelTrans: 'settings.styles.inverted_content_link_hover',
        },
    },
    inverted_content_link_active: {
        id: null,
        type: 'color',
        position: 20,
        value: '#429BA6',
        options: {
            labelTrans: 'settings.styles.inverted_content_link_active',
        },
    },
    middle_background: {
        id: null,
        type: 'color',
        position: 21,
        value: '#F6F7F9',
        options: {
            labelTrans: 'settings.styles.middle_background',
        },
    },
    middle_headline_color: {
        id: null,
        type: 'color',
        position: 22,
        value: '#020305',
        options: {
            labelTrans: 'settings.styles.middle_headline_color',
        },
    },
    middle_content_text: {
        id: null,
        type: 'color',
        position: 23,
        value: '#4A5264',
        options: {
            labelTrans: 'settings.styles.middle_content_text',
        },
    },
    middle_content_link: {
        id: null,
        type: 'color',
        position: 24,
        value: '#6FC1CB',
        options: {
            labelTrans: 'settings.styles.middle_content_link',
        },
    },
    middle_content_link_hover: {
        id: null,
        type: 'color',
        position: 25,
        value: '#B3DCE1',
        options: {
            labelTrans: 'settings.styles.middle_content_link_hover',
        },
    },
    middle_content_link_active: {
        id: null,
        type: 'color',
        position: 26,
        value: '#429BA6',
        options: {
            labelTrans: 'settings.styles.middle_content_link_active',
        },
    },
    button_primary_background: {
        id: null,
        type: 'color',
        position: 27,
        value: '#00838F',
        options: {
            labelTrans: 'settings.styles.button_primary_background',
        },
    },
    button_primary_background_hover: {
        id: null,
        type: 'color',
        position: 28,
        value: '#4FB3BF',
        options: {
            labelTrans: 'settings.styles.button_primary_background_hover',
        },
    },
    button_primary_background_active: {
        id: null,
        type: 'color',
        position: 29,
        value: '#005662',
        options: {
            labelTrans: 'settings.styles.button_primary_background_active',
        },
    },
    button_primary_background_disabled: {
        id: null,
        type: 'color',
        position: 30,
        value: '#E9EAEA',
        options: {
            labelTrans: 'settings.styles.button_primary_background_disabled',
        },
    },
    button_primary_text: {
        id: null,
        type: 'color',
        position: 31,
        value: '#FFFFFF',
        options: {
            labelTrans: 'settings.styles.button_primary_text',
        },
    },
    button_primary_text_hover: {
        id: null,
        type: 'color',
        position: 32,
        value: '#FFFFFF',
        options: {
            labelTrans: 'settings.styles.button_primary_text_hover',
        },
    },
    button_primary_text_active: {
        id: null,
        type: 'color',
        position: 33,
        value: '#FFFFFF',
        options: {
            labelTrans: 'settings.styles.button_primary_text_active',
        },
    },
    button_secondary_background: {
        id: null,
        type: 'color',
        position: 34,
        value: '#6FC1CB',
        options: {
            labelTrans: 'settings.styles.button_secondary_background',
        },
    },
    button_secondary_background_hover: {
        id: null,
        type: 'color',
        position: 35,
        value: '#B3DCE1',
        options: {
            labelTrans: 'settings.styles.button_secondary_background_hover',
        },
    },
    button_secondary_background_active: {
        id: null,
        type: 'color',
        position: 36,
        value: '#429BA6',
        options: {
            labelTrans: 'settings.styles.button_secondary_background_active',
        },
    },
    button_secondary_background_disabled: {
        id: null,
        type: 'color',
        position: 37,
        value: '#E9EAEA',
        options: {
            labelTrans: 'settings.styles.button_secondary_background_disabled',
        },
    },
    button_secondary_text: {
        id: null,
        type: 'color',
        position: 38,
        value: '#FFFFFF',
        options: {
            labelTrans: 'settings.styles.button_secondary_text',
        },
    },
    button_secondary_text_hover: {
        id: null,
        type: 'color',
        position: 39,
        value: '#FFFFFF',
        options: {
            labelTrans: 'settings.styles.button_secondary_text_hover',
        },
    },
    button_secondary_text_active: {
        id: null,
        type: 'color',
        position: 40,
        value: '#FFFFFF',
        options: {
            labelTrans: 'settings.styles.button_secondary_text_active',
        },
    },
    button_outline_background: {
        id: null,
        type: 'color',
        position: 41,
        value: '#9DADAF',
        options: {
            labelTrans: 'settings.styles.button_outline_background',
        },
    },
    button_outline_background_hover: {
        id: null,
        type: 'color',
        position: 42,
        value: '',
        options: {
            labelTrans: 'settings.styles.button_outline_background_hover',
        },
    },
    button_outline_background_active: {
        id: null,
        type: 'color',
        position: 43,
        value: '',
        options: {
            labelTrans: 'settings.styles.button_outline_background_active',
        },
    },
    button_outline_background_disabled: {
        id: null,
        type: 'color',
        position: 44,
        value: '',
        options: {
            labelTrans: 'settings.styles.button_outline_background_disabled',
        },
    },
    button_outline_text: {
        id: null,
        type: 'color',
        position: 45,
        value: '#9DADAF',
        options: {
            labelTrans: 'settings.styles.button_outline_text',
        },
    },
    button_outline_text_hover: {
        id: null,
        type: 'color',
        position: 46,
        value: '#51C1C1',
        options: {
            labelTrans: 'settings.styles.button_outline_text_hover',
        },
    },
    button_outline_text_active: {
        id: null,
        type: 'color',
        position: 47,
        value: '#007C91',
        options: {
            labelTrans: 'settings.styles.button_outline_text_active',
        },
    },
    header_mobile_main_background: {
        id: null,
        type: 'color',
        position: 48,
        value: '',
        options: {
            labelTrans: 'settings.styles.header_mobile_main_background',
        },
    },
    header_mobile_bottom_background: {
        id: null,
        type: 'color',
        position: 49,
        value: '',
        options: {
            labelTrans: 'settings.styles.header_mobile_bottom_background',
        },
    },
    header_mobile_separator: {
        id: null,
        type: 'color',
        position: 50,
        value: '#C7CCD6',
        options: {
            labelTrans: 'settings.styles.header_mobile_separator',
        },
    },
    header_mobile_main_icon: {
        id: null,
        type: 'color',
        position: 51,
        value: '#FFFFFF',
        options: {
            labelTrans: 'settings.styles.header_mobile_main_icon',
        },
    },
    header_mobile_bottom_icon: {
        id: null,
        type: 'color',
        position: 52,
        value: '#C7CCD6',
        options: {
            labelTrans: 'settings.styles.header_mobile_bottom_icon',
        },
    },
    header_mobile_bottom_text_links: {
        id: null,
        type: 'color',
        position: 53,
        value: '#FFFFFF',
        options: {
            labelTrans: 'settings.styles.header_mobile_bottom_text_links',
        },
    },
    header_mobile_bottom_text_links_background: {
        id: null,
        type: 'color',
        position: 54,
        value: '#6FC1CB',
        options: {
            labelTrans: 'settings.styles.header_mobile_bottom_text_links_background',
        },
    },
    header_mobile_menu_background: {
        id: null,
        type: 'color',
        position: 55,
        value: '#2D323D',
        options: {
            labelTrans: 'settings.styles.header_mobile_menu_background',
        },
    },
    header_mobile_menu_lines: {
        id: null,
        type: 'color',
        position: 56,
        value: '#E9EAEA',
        options: {
            labelTrans: 'settings.styles.header_mobile_menu_lines',
        },
    },
    header_mobile_menu_icons: {
        id: null,
        type: 'color',
        position: 57,
        value: '#FFFFFF',
        options: {
            labelTrans: 'settings.styles.header_mobile_menu_icons',
        },
    },
    header_mobile_menu_text_links: {
        id: null,
        type: 'color',
        position: 58,
        value: '#FFFFFF',
        options: {
            labelTrans: 'settings.styles.header_mobile_menu_text_links',
        },
    },
    header_mobile_menu_text_links_active: {
        id: null,
        type: 'color',
        position: 59,
        value: '#007C91',
        options: {
            labelTrans: 'settings.styles.header_mobile_menu_text_links_active',
        },
    },
    header_mobile_contacts_text: {
        id: null,
        type: 'color',
        position: 60,
        value: '#FFFFFF',
        options: {
            labelTrans: 'settings.styles.header_mobile_contacts_text',
        },
    },
    header_desktop_main_background: {
        id: null,
        type: 'color',
        position: 61,
        value: '',
        options: {
            labelTrans: 'settings.styles.header_desktop_main_background',
        },
    },
    header_desktop_top_background: {
        id: null,
        type: 'color',
        position: 62,
        value: '',
        options: {
            labelTrans: 'settings.styles.header_desktop_top_background',
        },
    },
    header_desktop_bottom_background: {
        id: null,
        type: 'color',
        position: 63,
        value: '',
        options: {
            labelTrans: 'settings.styles.header_desktop_bottom_background',
        },
    },
    header_desktop_separator_background: {
        id: null,
        type: 'color',
        position: 64,
        value: '#DADDE4',
        options: {
            labelTrans: 'settings.styles.header_desktop_separator_background',
        },
    },
    header_desktop_main_icon: {
        id: null,
        type: 'color',
        position: 65,
        value: '#6FC1CB',
        options: {
            labelTrans: 'settings.styles.header_desktop_main_icon',
        },
    },
    header_desktop_main_icon_hover: {
        id: null,
        type: 'color',
        position: 65,
        value: '#6FC1CB',
        options: {
            labelTrans: 'settings.styles.header_desktop_main_icon_hover',
        },
    },
    header_desktop_main_text_link: {
        id: null,
        type: 'color',
        position: 65,
        value: '#4A5264',
        options: {
            labelTrans: 'settings.styles.header_desktop_main_text_link',
        },
    },
    header_desktop_main_text_link_hover: {
        id: null,
        type: 'color',
        position: 65,
        value: '#4A5264',
        options: {
            labelTrans: 'settings.styles.header_desktop_main_text_link_hover',
        },
    },
    header_desktop_menu_text_link: {
        id: null,
        type: 'color',
        position: 66,
        value: '#FFFFFF',
        options: {
            labelTrans: 'settings.styles.header_desktop_menu_text_link',
        },
    },
    header_desktop_menu_text_link_hover: {
        id: null,
        type: 'color',
        position: 67,
        value: '#8BE1F0',
        options: {
            labelTrans: 'settings.styles.header_desktop_menu_text_link_hover',
        },
    },
    header_desktop_menu_text_link_active: {
        id: null,
        type: 'color',
        position: 68,
        value: '#007C91',
        options: {
            labelTrans: 'settings.styles.header_desktop_menu_text_link_active',
        },
    },
    header_desktop_submenu_background: {
        id: null,
        type: 'color',
        position: 69,
        value: '',
        options: {
            labelTrans: 'settings.styles.header_desktop_submenu_background',
        },
    },
    header_desktop_submenu_text_link: {
        id: null,
        type: 'color',
        position: 70,
        value: '#FFFFFF',
        options: {
            labelTrans: 'settings.styles.header_desktop_submenu_text_link',
        },
    },
    header_desktop_submenu_text_link_hover: {
        id: null,
        type: 'color',
        position: 71,
        value: '#8BE1F0',
        options: {
            labelTrans: 'settings.styles.header_desktop_submenu_text_link_hover',
        },
    },
    header_desktop_submenu_text_link_active: {
        id: null,
        type: 'color',
        position: 72,
        value: '#007C91',
        options: {
            labelTrans: 'settings.styles.header_desktop_submenu_text_link_active',
        },
    },
    header_desktop_menu_info_text_link: {
        id: null,
        type: 'color',
        position: 73,
        value: '#020305',
        options: {
            labelTrans: 'settings.styles.header_desktop_menu_info_text_link',
        },
    },
    header_desktop_menu_info_text_link_hover: {
        id: null,
        type: 'color',
        position: 74,
        value: '#4FB3BF',
        options: {
            labelTrans: 'settings.styles.header_desktop_menu_info_text_link_hover',
        },
    },
    header_desktop_menu_info_text_link_active: {
        id: null,
        type: 'color',
        position: 75,
        value: '#005662',
        options: {
            labelTrans: 'settings.styles.header_desktop_menu_info_text_link_active',
        },
    },
    header_desktop_search_background: {
        id: null,
        type: 'color',
        position: 76,
        value: '#F6F7F9',
        options: {
            labelTrans: 'settings.styles.header_desktop_search_background',
        },
    },
    header_desktop_search_border: {
        id: null,
        type: 'color',
        position: 77,
        value: '#F6F7F9',
        options: {
            labelTrans: 'settings.styles.header_desktop_search_border',
        },
    },
    header_desktop_search_text: {
        id: null,
        type: 'color',
        position: 78,
        value: '#4A5264',
        options: {
            labelTrans: 'settings.styles.header_desktop_search_text',
        },
    },
    header_desktop_search_icon: {
        id: null,
        type: 'color',
        position: 79,
        value: '#00838F',
        options: {
            labelTrans: 'settings.styles.header_desktop_search_icon',
        },
    },
    header_desktop_search_background_hover: {
        id: null,
        type: 'color',
        position: 80,
        value: '#F6F7F9',
        options: {
            labelTrans: 'settings.styles.header_desktop_search_background_hover',
        },
    },
    header_desktop_search_border_hover: {
        id: null,
        type: 'color',
        position: 81,
        value: '#00838F',
        options: {
            labelTrans: 'settings.styles.header_desktop_search_border_hover',
        },
    },
    header_desktop_search_text_hover: {
        id: null,
        type: 'color',
        position: 82,
        value: '#4A5264',
        options: {
            labelTrans: 'settings.styles.header_desktop_search_text_hover',
        },
    },
    header_desktop_search_icon_hover: {
        id: null,
        type: 'color',
        position: 83,
        value: '#4FB3BF',
        options: {
            labelTrans: 'settings.styles.header_desktop_search_icon_hover',
        },
    },
    footer_main_background: {
        id: null,
        type: 'color',
        position: 84,
        value: '#FFFFFF',
        options: {
            labelTrans: 'settings.styles.footer_main_background',
        },
    },
    footer_bottom_background: {
        id: null,
        type: 'color',
        position: 85,
        value: '#F6F7F9',
        options: {
            labelTrans: 'settings.styles.footer_bottom_background',
        },
    },
    footer_lines_background: {
        id: null,
        type: 'color',
        position: 86,
        value: '#DADDE4',
        options: {
            labelTrans: 'settings.styles.footer_lines_background',
        },
    },
    footer_icon_color: {
        id: null,
        type: 'color',
        position: 87,
        value: '#B3B9C7',
        options: {
            labelTrans: 'settings.styles.footer_icon_color',
        },
    },
    footer_icon_background: {
        id: null,
        type: 'color',
        position: 88,
        value: '#F6F7F9',
        options: {
            labelTrans: 'settings.styles.footer_icon_background',
        },
    },
    footer_text: {
        id: null,
        type: 'color',
        position: 89,
        value: '#67728B',
        options: {
            labelTrans: 'settings.styles.footer_text',
        },
    },
    footer_link: {
        id: null,
        type: 'color',
        position: 90,
        value: '#2D323D',
        options: {
            labelTrans: 'settings.styles.footer_link',
        },
    },
    footer_link_hover: {
        id: null,
        type: 'color',
        position: 91,
        value: '#B3DCE1',
        options: {
            labelTrans: 'settings.styles.footer_link_hover',
        },
    },
    footer_link_active: {
        id: null,
        type: 'color',
        position: 92,
        value: '#429BA6',
        options: {
            labelTrans: 'settings.styles.footer_link_active',
        },
    },
    footer_bottom_text: {
        id: null,
        type: 'color',
        position: 93,
        value: '#67728B',
        options: {
            labelTrans: 'settings.styles.footer_bottom_text',
        },
    },
    footer_social_icon: {
        id: null,
        type: 'color',
        position: 94,
        value: '#6FC1CB',
        options: {
            labelTrans: 'settings.styles.footer_social_icon',
        },
    },
    footer_social_icon_hover: {
        id: null,
        type: 'color',
        position: 95,
        value: '#B3DCE1',
        options: {
            labelTrans: 'settings.styles.footer_social_icon_hover',
        },
    },
    footer_social_icon_active: {
        id: null,
        type: 'color',
        position: 96,
        value: '#429BA6',
        options: {
            labelTrans: 'settings.styles.footer_social_icon_active',
        },
    },
    footer_menu_text_link: {
        id: null,
        type: 'color',
        position: 97,
        value: '#2D323D',
        options: {
            labelTrans: 'settings.styles.footer_menu_text_link',
        },
    },
    footer_menu_text_link_hover: {
        id: null,
        type: 'color',
        position: 98,
        value: '#B3DCE1',
        options: {
            labelTrans: 'settings.styles.footer_menu_text_link_hover',
        },
    },
    footer_menu_text_link_active: {
        id: null,
        type: 'color',
        position: 99,
        value: '#429BA6',
        options: {
            labelTrans: 'settings.styles.footer_menu_text_link_active',
        },
    },
    footer_submenu_text_link: {
        id: null,
        type: 'color',
        position: 100,
        value: '#2D323D',
        options: {
            labelTrans: 'settings.styles.footer_submenu_text_link',
        },
    },
    footer_submenu_text_link_hover: {
        id: null,
        type: 'color',
        position: 101,
        value: '#B3DCE1',
        options: {
            labelTrans: 'settings.styles.footer_submenu_text_link_hover',
        },
    },
    footer_submenu_text_link_active: {
        id: null,
        type: 'color',
        position: 102,
        value: '#429BA6',
        options: {
            labelTrans: 'settings.styles.footer_submenu_text_link_active',
        },
    },
    form_input_background: {
        id: null,
        type: 'color',
        position: 103,
        value: '#F6F7F9',
        options: {
            labelTrans: 'settings.styles.form_input_background',
        },
    },
    form_input_border: {
        id: null,
        type: 'color',
        position: 104,
        value: '#9DADAF',
        options: {
            labelTrans: 'settings.styles.form_input_border',
        },
    },
    form_input_border_hover: {
        id: null,
        type: 'color',
        position: 105,
        value: '#51C1C1',
        options: {
            labelTrans: 'settings.styles.form_input_border_hover',
        },
    },
    form_input_text: {
        id: null,
        type: 'color',
        position: 106,
        value: '#67728B',
        options: {
            labelTrans: 'settings.styles.form_input_text',
        },
    },
    form_input_text_placeholder: {
        id: null,
        type: 'color',
        position: 107,
        value: '#FFFFFF',
        options: {
            labelTrans: 'settings.styles.form_input_text_placeholder',
        },
    },
    form_select_background: {
        id: null,
        type: 'color',
        position: 108,
        value: '#F6F7F9',
        options: {
            labelTrans: 'settings.styles.form_select_background',
        },
    },
    form_select_border: {
        id: null,
        type: 'color',
        position: 109,
        value: '#9DADAF',
        options: {
            labelTrans: 'settings.styles.form_select_border',
        },
    },
    form_select_text: {
        id: null,
        type: 'color',
        position: 110,
        value: '#020305',
        options: {
            labelTrans: 'settings.styles.form_select_text',
        },
    },
    form_select_icon: {
        id: null,
        type: 'color',
        position: 111,
        value: '#6FC1CB',
        options: {
            labelTrans: 'settings.styles.form_select_icon',
        },
    },
    form_select_option_text: {
        id: null,
        type: 'color',
        position: 112,
        value: '#020305',
        options: {
            labelTrans: 'settings.styles.form_select_option_text',
        },
    },
    form_select_option_text_hover: {
        id: null,
        type: 'color',
        position: 113,
        value: '#C7CCD6',
        options: {
            labelTrans: 'settings.styles.form_select_option_text_hover',
        },
    },
    form_select_option_background_hover: {
        id: null,
        type: 'color',
        position: 114,
        value: '#51C1C1',
        options: {
            labelTrans: 'settings.styles.form_select_option_background_hover',
        },
    },
    success: {
        id: null,
        type: 'color',
        position: 115,
        value: '#388E3C',
        options: {
            labelTrans: 'settings.styles.success',
        },
    },
    success_bg: {
        id: null,
        type: 'color',
        position: 116,
        value: ' #F6FAF5',
        options: {
            labelTrans: 'settings.styles.success_bg',
        },
    },
    warning: {
        id: null,
        type: 'color',
        position: 117,
        value: '#FFA000',
        options: {
            labelTrans: 'settings.styles.warning',
        },
    },
    warning_bg: {
        id: null,
        type: 'color',
        position: 118,
        value: '#FFF9EE',
        options: {
            labelTrans: 'settings.styles.warning_bg',
        },
    },
    error: {
        id: null,
        type: 'color',
        position: 119,
        value: '#E64A19',
        options: {
            labelTrans: 'settings.styles.error',
        },
    },
    error_bg: {
        id: null,
        type: 'color',
        position: 120,
        value: '#FEF2EE',
        options: {
            labelTrans: 'settings.styles.error_bg',
        },
    },
    accent1: {
        id: null,
        type: 'color',
        position: 121,
        value: '#F57C00',
        options: {
            labelTrans: 'settings.styles.accent1',
        },
    },
    accent1_bg: {
        id: null,
        type: 'color',
        position: 122,
        value: '#FFEDDB',
        options: {
            labelTrans: 'settings.styles.accent1_bg',
        },
    },
    accent2: {
        id: null,
        type: 'color',
        position: 123,
        value: '#FBC02D',
        options: {
            labelTrans: 'settings.styles.accent2',
        },
    },
    accent2_bg: {
        id: null,
        type: 'color',
        position: 124,
        value: '#FEF3D7',
        options: {
            labelTrans: 'settings.styles.accent2_bg',
        },
    },
    accent3: {
        id: null,
        type: 'color',
        position: 125,
        value: '#00B08D',
        options: {
            labelTrans: 'settings.styles.accent3',
        },
    },
    accent3_bg: {
        id: null,
        type: 'color',
        position: 126,
        value: '#E0FFF9',
        options: {
            labelTrans: 'settings.styles.accent3_bg',
        },
    },
    accent4: {
        id: null,
        type: 'color',
        position: 127,
        value: '#7D76DF',
        options: {
            labelTrans: 'settings.styles.accent4',
        },
    },
    accent4_bg: {
        id: null,
        type: 'color',
        position: 128,
        value: '#E7E6F9',
        options: {
            labelTrans: 'settings.styles.accent4_bg',
        },
    },
    product_list_background: {
        id: null,
        type: 'color',
        position: 129,
        value: '#FFFFFF',
        options: {
            labelTrans: 'settings.styles.product_list_background',
        },
    },
    product_list_title: {
        id: null,
        type: 'color',
        position: 130,
        value: '#020305',
        options: {
            labelTrans: 'settings.styles.product_list_title',
        },
    },
    product_list_description: {
        id: null,
        type: 'color',
        position: 131,
        value: '#67728B',
        options: {
            labelTrans: 'settings.styles.product_list_description',
        },
    },
    product_list_price: {
        id: null,
        type: 'color',
        position: 132,
        value: '#020305',
        options: {
            labelTrans: 'settings.styles.product_list_price',
        },
    },
    product_list_price_discount: {
        id: null,
        type: 'color',
        position: 133,
        value: '#67728B',
        options: {
            labelTrans: 'settings.styles.product_list_price_discount',
        },
    },
    product_list_reviews_start: {
        id: null,
        type: 'color',
        position: 134,
        value: '#FBC02D',
        options: {
            labelTrans: 'settings.styles.product_list_reviews_start',
        },
    },
    product_list_reviews_text: {
        id: null,
        type: 'color',
        position: 135,
        value: '#4A5264',
        options: {
            labelTrans: 'settings.styles.product_list_reviews_text',
        },
    },
    product_list_availability: {
        id: null,
        type: 'color',
        position: 136,
        value: '#4A5264',
        options: {
            labelTrans: 'settings.styles.product_list_availability',
        },
    },
    product_list_button_buy_background: {
        id: null,
        type: 'color',
        position: 137,
        value: '#00838F',
        options: {
            labelTrans: 'settings.styles.product_list_button_buy_background',
        },
    },
    product_list_button_buy_background_hover: {
        id: null,
        type: 'color',
        position: 138,
        value: '#4FB3BF',
        options: {
            labelTrans: 'settings.styles.product_list_button_buy_background_hover',
        },
    },
    product_list_button_buy_background_active: {
        id: null,
        type: 'color',
        position: 139,
        value: '#005662',
        options: {
            labelTrans: 'settings.styles.product_list_button_buy_background_active',
        },
    },
    product_list_button_buy_background_disabled: {
        id: null,
        type: 'color',
        position: 140,
        value: '#E9EAEA',
        options: {
            labelTrans: 'settings.styles.product_list_button_buy_background_disabled',
        },
    },
    product_list_button_buy_text: {
        id: null,
        type: 'color',
        position: 141,
        value: '#FFFFFF',
        options: {
            labelTrans: 'settings.styles.product_list_button_buy_text',
        },
    },
    product_list_button_buy_text_hover: {
        id: null,
        type: 'color',
        position: 142,
        value: '#FFFFFF',
        options: {
            labelTrans: 'settings.styles.product_list_button_buy_text_hover',
        },
    },
    product_list_button_buy_text_active: {
        id: null,
        type: 'color',
        position: 143,
        value: '#FFFFFF',
        options: {
            labelTrans: 'settings.styles.product_list_button_buy_text_active',
        },
    },
    product_list_counter_background: {
        id: null,
        type: 'color',
        position: 144,
        value: '#F6F7F9',
        options: {
            labelTrans: 'settings.styles.product_list_counter_background',
        },
    },
    product_list_counter_color: {
        id: null,
        type: 'color',
        position: 145,
        value: '#020305',
        options: {
            labelTrans: 'settings.styles.product_list_counter_color',
        },
    },
    product_list_counter_btn_background: {
        id: null,
        type: 'color',
        position: 146,
        value: '#FFFFFF',
        options: {
            labelTrans: 'settings.styles.product_list_counter_btn_background',
        },
    },
    product_list_counter_btn_color: {
        id: null,
        type: 'color',
        position: 147,
        value: '#6FC1CB',
        options: {
            labelTrans: 'settings.styles.product_list_counter_btn_color',
        },
    },
    product_list_counter_btn_background_hover: {
        id: null,
        type: 'color',
        position: 148,
        value: '#FFFFFF',
        options: {
            labelTrans: 'settings.styles.product_list_counter_btn_background_hover',
        },
    },
    product_list_counter_btn_color_hover: {
        id: null,
        type: 'color',
        position: 149,
        value: '#B3DCE1',
        options: {
            labelTrans: 'settings.styles.product_list_counter_btn_color_hover',
        },
    },
    product_list_counter_btn_background_active: {
        id: null,
        type: 'color',
        position: 150,
        value: '#FFFFFF',
        options: {
            labelTrans: 'settings.styles.product_list_counter_btn_background_active',
        },
    },
    product_list_counter_btn_color_active: {
        id: null,
        type: 'color',
        position: 151,
        value: '#429BA6',
        options: {
            labelTrans: 'settings.styles.product_list_counter_btn_color_active',
        },
    },
    product_list_parameters_background: {
        id: null,
        type: 'color',
        position: 152,
        value: '#FFFFFF',
        options: {
            labelTrans: 'settings.styles.product_list_parameters_background',
        },
    },
    product_list_parameters_icon_color: {
        id: null,
        type: 'color',
        position: 153,
        value: '#FFFFFF',
        options: {
            labelTrans: 'settings.styles.product_list_parameters_icon_color',
        },
    },
    product_list_parameters_icon_background: {
        id: null,
        type: 'color',
        position: 154,
        value: '#6FC1CB',
        options: {
            labelTrans: 'settings.styles.product_list_parameters_icon_background',
        },
    },
    product_list_parameters_text_color: {
        id: null,
        type: 'color',
        position: 155,
        value: '#020305',
        options: {
            labelTrans: 'settings.styles.product_list_parameters_text_color',
        },
    },
    product_list_parameters_text_border: {
        id: null,
        type: 'color',
        position: 156,
        value: '#B3B9C7',
        options: {
            labelTrans: 'settings.styles.product_list_parameters_text_border',
        },
    },
    product_list_options_button_background: {
        id: null,
        type: 'color',
        position: 157,
        value: '#E9EAEA',
        options: {
            labelTrans: 'settings.styles.product_list_options_button_background',
        },
    },
    product_list_options_button_border: {
        id: null,
        type: 'color',
        position: 158,
        value: '#E9EAEA',
        options: {
            labelTrans: 'settings.styles.product_list_options_button_border',
        },
    },
    product_list_options_button_text: {
        id: null,
        type: 'color',
        position: 159,
        value: '#020305',
        options: {
            labelTrans: 'settings.styles.product_list_options_button_text',
        },
    },
    product_list_options_button_background_hover: {
        id: null,
        type: 'color',
        position: 160,
        value: '#B3DCE1',
        options: {
            labelTrans: 'settings.styles.product_list_options_button_background_hover',
        },
    },
    product_list_options_button_border_hover: {
        id: null,
        type: 'color',
        position: 161,
        value: '#B3DCE1',
        options: {
            labelTrans: 'settings.styles.product_list_options_button_border_hover',
        },
    },
    product_list_options_button_text_hover: {
        id: null,
        type: 'color',
        position: 162,
        value: '#FFFFFF',
        options: {
            labelTrans: 'settings.styles.product_list_options_button_text_hover',
        },
    },
    product_list_options_button_background_selected: {
        id: null,
        type: 'color',
        position: 163,
        value: '#6FC1CB',
        options: {
            labelTrans: 'settings.styles.product_list_options_button_background_selected',
        },
    },
    product_list_options_button_border_selected: {
        id: null,
        type: 'color',
        position: 164,
        value: '#6FC1CB',
        options: {
            labelTrans: 'settings.styles.product_list_options_button_border_selected',
        },
    },
    product_list_options_button_text_selected: {
        id: null,
        type: 'color',
        position: 165,
        value: '#FFFFFF',
        options: {
            labelTrans: 'settings.styles.product_list_options_button_text_selected',
        },
    },
    product_list_options_select_background: {
        id: null,
        type: 'color',
        position: 166,
        value: '#FFFFFF',
        options: {
            labelTrans: 'settings.styles.product_list_options_select_background',
        },
    },
    product_list_options_select_border: {
        id: null,
        type: 'color',
        position: 167,
        value: '#9DADAF',
        options: {
            labelTrans: 'settings.styles.product_list_options_select_border',
        },
    },
    product_list_options_select_text: {
        id: null,
        type: 'color',
        position: 168,
        value: '#020305',
        options: {
            labelTrans: 'settings.styles.product_list_options_select_text',
        },
    },
    product_list_options_select_icon: {
        id: null,
        type: 'color',
        position: 169,
        value: '#6FC1CB',
        options: {
            labelTrans: 'settings.styles.product_list_options_select_icon',
        },
    },
    product_list_options_select_option_text: {
        id: null,
        type: 'color',
        position: 170,
        value: '#020305',
        options: {
            labelTrans: 'settings.styles.product_list_options_select_option_text',
        },
    },
    product_list_options_select_option_text_hover: {
        id: null,
        type: 'color',
        position: 171,
        value: '#C7CCD6',
        options: {
            labelTrans: 'settings.styles.product_list_options_select_option_text_hover',
        },
    },
    product_list_options_select_option_background_hover: {
        id: null,
        type: 'color',
        position: 172,
        value: '#51C1C1',
        options: {
            labelTrans: 'settings.styles.product_list_options_select_option_background_hover',
        },
    },
    filter_desktop_background: {
        id: null,
        type: 'color',
        position: 173,
        value: '#FFFFFF',
        options: {
            labelTrans: 'settings.styles.filter_desktop_background',
        },
    },
    filter_desktop_border: {
        id: null,
        type: 'color',
        position: 174,
        value: '#E8E9EE',
        options: {
            labelTrans: 'settings.styles.filter_desktop_border',
        },
    },
    filter_desktop_text: {
        id: null,
        type: 'color',
        position: 174,
        value: '#343A47',
        options: {
            labelTrans: 'settings.styles.filter_desktop_text',
        },
    },
    filter_desktop_icon: {
        id: null,
        type: 'color',
        position: 174,
        value: '#5A6794',
        options: {
            labelTrans: 'settings.styles.filter_desktop_icon',
        },
    },
    filter_mobile_background: {
        id: null,
        type: 'color',
        position: 174,
        value: '#FFFFFF',
        options: {
            labelTrans: 'settings.styles.filter_mobile_background',
        },
    },
    filter_mobile_border: {
        id: null,
        type: 'color',
        position: 174,
        value: '#E8E9EE',
        options: {
            labelTrans: 'settings.styles.filter_mobile_border',
        },
    },
    filter_mobile_text: {
        id: null,
        type: 'color',
        position: 174,
        value: '#343A47',
        options: {
            labelTrans: 'settings.styles.filter_mobile_text',
        },
    },
    filter_mobile_icon: {
        id: null,
        type: 'color',
        position: 174,
        value: '#5A6794',
        options: {
            labelTrans: 'settings.styles.filter_mobile_icon',
        },
    },
    filter_button_background: {
        id: null,
        type: 'color',
        position: 175,
        value: '#00838F',
        options: {
            labelTrans: 'settings.styles.filter_button_background',
        },
    },
    filter_button_background_hover: {
        id: null,
        type: 'color',
        position: 176,
        value: '#4FB3BF',
        options: {
            labelTrans: 'settings.styles.filter_button_background_hover',
        },
    },
    filter_button_background_active: {
        id: null,
        type: 'color',
        position: 177,
        value: '#005662',
        options: {
            labelTrans: 'settings.styles.filter_button_background_active',
        },
    },
    filter_button_background_disabled: {
        id: null,
        type: 'color',
        position: 178,
        value: '#E9EAEA',
        options: {
            labelTrans: 'settings.styles.filter_button_background_disabled',
        },
    },
    filter_button_text: {
        id: null,
        type: 'color',
        position: 179,
        value: '#FFFFFF',
        options: {
            labelTrans: 'settings.styles.filter_button_text',
        },
    },
    filter_button_text_hover: {
        id: null,
        type: 'color',
        position: 180,
        value: '#FFFFFF',
        options: {
            labelTrans: 'settings.styles.filter_button_text_hover',
        },
    },
    filter_button_text_active: {
        id: null,
        type: 'color',
        position: 181,
        value: '#FFFFFF',
        options: {
            labelTrans: 'settings.styles.filter_button_text_active',
        },
    },
    paging_background: {
        id: null,
        type: 'color',
        position: 182,
        value: '#FFFFFF',
        options: {
            labelTrans: 'settings.styles.paging_background',
        },
    },
    paging_background_hover: {
        id: null,
        type: 'color',
        position: 183,
        value: '#4FB3BF',
        options: {
            labelTrans: 'settings.styles.paging_background_hover',
        },
    },
    paging_background_active: {
        id: null,
        type: 'color',
        position: 184,
        value: '#00838F',
        options: {
            labelTrans: 'settings.styles.paging_background_active',
        },
    },
    paging_background_disabled: {
        id: null,
        type: 'color',
        position: 185,
        value: '#E9EAEA',
        options: {
            labelTrans: 'settings.styles.paging_background_disabled',
        },
    },
    paging_text: {
        id: null,
        type: 'color',
        position: 186,
        value: '#020305',
        options: {
            labelTrans: 'settings.styles.paging_text',
        },
    },
    paging_text_hover: {
        id: null,
        type: 'color',
        position: 187,
        value: '#FFFFFF',
        options: {
            labelTrans: 'settings.styles.paging_text_hover',
        },
    },
    paging_text_active: {
        id: null,
        type: 'color',
        position: 188,
        value: '#FFFFFF',
        options: {
            labelTrans: 'settings.styles.paging_text_active',
        },
    },
    paging_text_disabled: {
        id: null,
        type: 'color',
        position: 189,
        value: '#020305',
        options: {
            labelTrans: 'settings.styles.paging_text_disabled',
        },
    },
    product_card_background: {
        id: null,
        type: 'color',
        position: 190,
        value: '#FFFFFF',
        options: {
            labelTrans: 'settings.styles.product_card_background',
        },
    },
    product_card_title: {
        id: null,
        type: 'color',
        position: 191,
        value: '#020305',
        options: {
            labelTrans: 'settings.styles.product_card_title',
        },
    },
    product_card_description: {
        id: null,
        type: 'color',
        position: 192,
        value: '#67728B',
        options: {
            labelTrans: 'settings.styles.product_card_description',
        },
    },
    product_card_price: {
        id: null,
        type: 'color',
        position: 193,
        value: '#020305',
        options: {
            labelTrans: 'settings.styles.product_card_price',
        },
    },
    product_card_price_discount: {
        id: null,
        type: 'color',
        position: 194,
        value: '#67728B',
        options: {
            labelTrans: 'settings.styles.product_card_price_discount',
        },
    },
    product_card_reviews_start: {
        id: null,
        type: 'color',
        position: 195,
        value: '#FBC02D',
        options: {
            labelTrans: 'settings.styles.product_card_reviews_start',
        },
    },
    product_card_reviews_text: {
        id: null,
        type: 'color',
        position: 196,
        value: '#4A5264',
        options: {
            labelTrans: 'settings.styles.product_card_reviews_text',
        },
    },
    product_card_availability: {
        id: null,
        type: 'color',
        position: 197,
        value: '#4A5264',
        options: {
            labelTrans: 'settings.styles.product_card_availability',
        },
    },
    product_card_button_buy_background: {
        id: null,
        type: 'color',
        position: 198,
        value: '#00838F',
        options: {
            labelTrans: 'settings.styles.product_card_button_buy_background',
        },
    },
    product_card_button_buy_background_hover: {
        id: null,
        type: 'color',
        position: 199,
        value: '#4FB3BF',
        options: {
            labelTrans: 'settings.styles.product_card_button_buy_background_hover',
        },
    },
    product_card_button_buy_background_active: {
        id: null,
        type: 'color',
        position: 200,
        value: '#005662',
        options: {
            labelTrans: 'settings.styles.product_card_button_buy_background_active',
        },
    },
    product_card_button_buy_background_disabled: {
        id: null,
        type: 'color',
        position: 201,
        value: '#E9EAEA',
        options: {
            labelTrans: 'settings.styles.product_card_button_buy_background_disabled',
        },
    },
    product_card_button_buy_text: {
        id: null,
        type: 'color',
        position: 202,
        value: '#FFFFFF',
        options: {
            labelTrans: 'settings.styles.product_card_button_buy_text',
        },
    },
    product_card_button_buy_text_hover: {
        id: null,
        type: 'color',
        position: 203,
        value: '#FFFFFF',
        options: {
            labelTrans: 'settings.styles.product_card_button_buy_text_hover',
        },
    },
    product_card_button_buy_text_active: {
        id: null,
        type: 'color',
        position: 204,
        value: '#FFFFFF',
        options: {
            labelTrans: 'settings.styles.product_card_button_buy_text_active',
        },
    },
    product_card_counter_background: {
        id: null,
        type: 'color',
        position: 205,
        value: '#F6F7F9',
        options: {
            labelTrans: 'settings.styles.product_card_counter_background',
        },
    },
    product_card_counter_color: {
        id: null,
        type: 'color',
        position: 206,
        value: '#020305',
        options: {
            labelTrans: 'settings.styles.product_card_counter_color',
        },
    },
    product_card_counter_btn_background: {
        id: null,
        type: 'color',
        position: 207,
        value: '#FFFFFF',
        options: {
            labelTrans: 'settings.styles.product_card_counter_btn_background',
        },
    },
    product_card_counter_btn_color: {
        id: null,
        type: 'color',
        position: 208,
        value: '#6FC1CB',
        options: {
            labelTrans: 'settings.styles.product_card_counter_btn_color',
        },
    },
    product_card_counter_btn_background_hover: {
        id: null,
        type: 'color',
        position: 209,
        value: '#FFFFFF',
        options: {
            labelTrans: 'settings.styles.product_card_counter_btn_background_hover',
        },
    },
    product_card_counter_btn_color_hover: {
        id: null,
        type: 'color',
        position: 210,
        value: '#B3DCE1',
        options: {
            labelTrans: 'settings.styles.product_card_counter_btn_color_hover',
        },
    },
    product_card_counter_btn_background_active: {
        id: null,
        type: 'color',
        position: 211,
        value: '#FFFFFF',
        options: {
            labelTrans: 'settings.styles.product_card_counter_btn_background_active',
        },
    },
    product_card_counter_btn_color_active: {
        id: null,
        type: 'color',
        position: 212,
        value: '#429BA6',
        options: {
            labelTrans: 'settings.styles.product_card_counter_btn_color_active',
        },
    },
    product_card_parameters_background: {
        id: null,
        type: 'color',
        position: 213,
        value: '#FFFFFF',
        options: {
            labelTrans: 'settings.styles.product_card_parameters_background',
        },
    },
    product_card_parameters_icon_color: {
        id: null,
        type: 'color',
        position: 214,
        value: '#FFFFFF',
        options: {
            labelTrans: 'settings.styles.product_card_parameters_icon_color',
        },
    },
    product_card_parameters_icon_background: {
        id: null,
        type: 'color',
        position: 215,
        value: '#6FC1CB',
        options: {
            labelTrans: 'settings.styles.product_card_parameters_icon_background',
        },
    },
    product_card_parameters_text_color: {
        id: null,
        type: 'color',
        position: 216,
        value: '#020305',
        options: {
            labelTrans: 'settings.styles.product_card_parameters_text_color',
        },
    },
    product_card_parameters_text_border: {
        id: null,
        type: 'color',
        position: 217,
        value: '#B3B9C7',
        options: {
            labelTrans: 'settings.styles.product_card_parameters_text_border',
        },
    },
    product_card_options_button_background: {
        id: null,
        type: 'color',
        position: 218,
        value: '#E9EAEA',
        options: {
            labelTrans: 'settings.styles.product_card_options_button_background',
        },
    },
    product_card_options_button_border: {
        id: null,
        type: 'color',
        position: 219,
        value: '#E9EAEA',
        options: {
            labelTrans: 'settings.styles.product_card_options_button_border',
        },
    },
    product_card_options_button_text: {
        id: null,
        type: 'color',
        position: 220,
        value: '#020305',
        options: {
            labelTrans: 'settings.styles.product_card_options_button_text',
        },
    },
    product_card_options_button_background_hover: {
        id: null,
        type: 'color',
        position: 221,
        value: '#B3DCE1',
        options: {
            labelTrans: 'settings.styles.product_card_options_button_background_hover',
        },
    },
    product_card_options_button_border_hover: {
        id: null,
        type: 'color',
        position: 222,
        value: '#B3DCE1',
        options: {
            labelTrans: 'settings.styles.product_card_options_button_border_hover',
        },
    },
    product_card_options_button_text_hover: {
        id: null,
        type: 'color',
        position: 223,
        value: '#FFFFFF',
        options: {
            labelTrans: 'settings.styles.product_card_options_button_text_hover',
        },
    },
    product_card_options_button_background_selected: {
        id: null,
        type: 'color',
        position: 224,
        value: '#6FC1CB',
        options: {
            labelTrans: 'settings.styles.product_card_options_button_background_selected',
        },
    },
    product_card_options_button_border_selected: {
        id: null,
        type: 'color',
        position: 225,
        value: '#6FC1CB',
        options: {
            labelTrans: 'settings.styles.product_card_options_button_border_selected',
        },
    },
    product_card_options_button_text_selected: {
        id: null,
        type: 'color',
        position: 226,
        value: '#FFFFFF',
        options: {
            labelTrans: 'settings.styles.product_card_options_button_text_selected',
        },
    },
    product_card_options_select_background: {
        id: null,
        type: 'color',
        position: 227,
        value: '#FFFFFF',
        options: {
            labelTrans: 'settings.styles.product_card_options_select_background',
        },
    },
    product_card_options_select_border: {
        id: null,
        type: 'color',
        position: 228,
        value: '#9DADAF',
        options: {
            labelTrans: 'settings.styles.product_card_options_select_border',
        },
    },
    product_card_options_select_text: {
        id: null,
        type: 'color',
        position: 229,
        value: '#020305',
        options: {
            labelTrans: 'settings.styles.product_card_options_select_text',
        },
    },
    product_card_options_select_icon: {
        id: null,
        type: 'color',
        position: 230,
        value: '#6FC1CB',
        options: {
            labelTrans: 'settings.styles.product_card_options_select_icon',
        },
    },
    product_card_options_select_option_text: {
        id: null,
        type: 'color',
        position: 231,
        value: '#020305',
        options: {
            labelTrans: 'settings.styles.product_card_options_select_option_text',
        },
    },
    product_card_options_select_option_text_hover: {
        id: null,
        type: 'color',
        position: 232,
        value: '#C7CCD6',
        options: {
            labelTrans: 'settings.styles.product_card_options_select_option_text_hover',
        },
    },
    product_card_options_select_option_background_hover: {
        id: null,
        type: 'color',
        position: 233,
        value: '#51C1C1',
        options: {
            labelTrans: 'settings.styles.product_card_options_select_option_background_hover',
        },
    },
    cart_border: {
        id: null,
        type: 'color',
        position: 234,
        value: '#DADDE4',
        options: {
            labelTrans: 'settings.styles.cart_border',
        },
    },
    neutral1: {
        id: null,
        position: 35,
        type: 'color',
        value: '#1C2135',
        options: {},
    },
    neutral4: {
        id: null,
        position: 38,
        type: 'color',
        value: '#D3CAD3',
        options: {},
    },
    neutral5: {
        id: null,
        position: 39,
        type: 'color',
        value: '#E8E9EE',
        options: {},
    },
};
