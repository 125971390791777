import { reduce } from 'lodash';

export const HistoryTypes = {
    mutations: {
        UPDATE_HISTORY_POSITION: 'history/UPDATE_HISTORY_POSITION',
        ADD_HISTORY_ITEM: 'history/ADD_HISTORY_ITEM',
        CLEAR_HISTORY: 'history/CLEAR_HISTORY',
    },
    actions: {
        UPDATE_HISTORY: 'history/UPDATE_HISTORY',
        RESET_HISTORY: 'history/RESET_HISTORY',
        UPDATE_CURRENT: 'history/UPDATE_CURRENT',
    },
    getters: {
        CURRENT_PAGE: 'history/CURRENT_PAGE',
        CURRENT_SETTINGS: 'history/CURRENT_SETTINGS',
    },
};

export const AuthTypes = {
    mutations: {
        ADD_LAST_FAILED_REQUEST: 'auth/ADD_LAST_FAILED_REQUEST',
    },
    actions: {
        LOGOUT: 'auth/LOGOUT',
    },
};

/**
 * Функция удаляет namespace из значений констант стора
 * @param {Object} types - объект с типов стора
 * @returns
 */
export function removeNamespace(types) {
    return reduce(
        types,
        (typeObj, typeValue, typeName) => {
            typeObj[typeName] = reduce(
                typeValue,
                (obj, value, key) => {
                    const valueArr = value.split('/');
                    obj[key] = valueArr[valueArr.length - 1];
                    return obj;
                },
                {}
            );
            return typeObj;
        },
        {}
    );
}
