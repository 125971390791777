import { SectionFixtureType, SectionGroupFixtureType } from '@/entities/section/fixtures';

// id: 'fixture',
const style1Prev1: SectionFixtureType = {
    preview: require('@/assets/images/sections/products/product-style1prev1.jpg'),
    title: 'Article Category Widget',
    group: 'Media',
    data: {
        id: 'fixture',
        items: [],
        required: false,
        fixed: false,
        type: 'SectionMediaWidget',
        options: {
            section: {
                width: 'full',
                padding: {
                    desktop: { top: 28, right: 0, bottom: 28, left: 0 },
                    tablet: { top: 28, right: 0, bottom: 28, left: 0 },
                    mobile: { top: 16, right: 0, bottom: 16, left: 0 },
                },
                borderRadius: {
                    desktop: 0,
                    tablet: 0,
                    mobile: 0,
                },
                background: 'none',
            },
            container: {
                width: 'content',
                padding: {
                    desktop: null,
                    tablet: null,
                    mobile: null,
                },
                borderRadius: {
                    desktop: 0,
                    tablet: 0,
                    mobile: 0,
                },
                background: 'none',
            },
            heading: {
                title: 'Article Category Widget',
                colorTitle: '#1c1c1c',
                note: null,
                colorNote: '#292929',
                textAlign: 'center',
                marginBottom: {
                    desktop: null,
                    tablet: null,
                    mobile: null,
                },
            },
            content: {
                htmlBefore: null,
                htmlAfter: null,
                colorText: null,
            },
            limit: '4',
            catalogSlug: {
                id: '148501d8-8fdd-49ee-89ba-7bb138eb49e5',
                slug: 'anima-hookah',
            },
            list: {
                useDefault: true,
                columns: {
                    desktop: 3,
                    tablet: 2,
                    mobile: 1,
                },
            },
            btnSeeCatalog: {
                isShow: true,
                text: 'See All Products',
            },
            footer: {
                note: null,
                colorNote: null,
                textAlign: 'center',
                marginTop: {
                    desktop: null,
                    tablet: null,
                    mobile: null,
                },
                button: {
                    isShow: false,
                    styles: 'primary',
                    text: 'See all products',
                    textColor: null,
                    bgColor: null,
                    link: null,
                    iconLeft: null,
                    iconRight: null,
                },
            },
        },
    },
};

const summary: SectionGroupFixtureType = {
    style1Prev1,
};

export default summary;
