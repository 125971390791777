import { uuid } from 'vue-uuid';

/**
 * Класс экземпляра айтема настроек
 */
export default class SettingItem {
    /**
     *
     * @param {Object}  data - объект данных айтема настроек
     */
    constructor({ id, position = 0, type = '', value = '', options = [] } = {}) {
        this.id = id || uuid.v4();
        this.position = position;
        this.type = type;
        this.value = value;
        this.options = options;
    }

    get data() {
        return {
            id: this.id,
            type: this.type,
            position: this.position,
            value: this.value,
            options: this.options,
        };
    }
}
