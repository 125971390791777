<template>
    <div class="array-field">
        <heading
            type="body-2"
            v-if="label"
            class="array-field__label"
            :class="{ '-has-errors': Object.keys(errors).length }"
            >{{ $t(label) }}</heading
        >
        <div class="array-field__list form-item">
            <div class="array-field__list-item" v-for="(item, index) in values" :key="index">
                <component
                    :class="{ '-has-errors': hasError(index) }"
                    v-model="values[index]"
                    v-bind="$options.fields[field].props"
                    :is="$options.fields[field].component"
                    class="array-field__field"
                ></component>
                <Button
                    v-if="removed"
                    type="icon"
                    class="array-field__field-btn-remove"
                    icon="delete"
                    @click="removeField(index)"
                />
                <div v-if="errors[index]" class="form-error">
                    <ul class="form-item-error__list">
                        <li v-for="(error, index) in errors[index]" :key="index">{{ $t(error) }}</li>
                    </ul>
                </div>
            </div>
        </div>
        <Button type="text" icon="add_circle_outline" class="array-field__btn-add" @click="addField">
            {{ newBtnLabel }}
        </Button>
    </div>
</template>

<script>
import { isEqual } from 'lodash';
import Button from '@/components/common/Button';
import inputText from '@/components/form/controls/InputText';
import Textarea from '@/components/form/controls/Textarea';
import Heading from '@/components/common/Heading';

export default {
    name: 'ArrayField',

    inheritAttrs: false,

    components: {
        Textarea,
        inputText,
        Button,
        Heading,
    },

    fields: {
        input: {
            component: 'input-text',
        },
        textarea: {
            component: 'textarea',
        },
    },

    model: {
        prop: 'value',
        event: 'change-value',
    },

    props: {
        label: {
            type: String,
        },
        field: {
            type: String,
            default: 'input',
            validator(value) {
                return ['input', 'textarea'].includes(value);
            },
        },
        newBtnLabel: {
            type: String,
            default: 'Add item',
        },
        value: {
            type: Array,
        },
        errors: {
            type: Object,
            default: () => {},
        },
        name: {
            type: String,
        },
        group: {
            type: String,
        },
    },

    data() {
        return {
            values: { 0: '' },
        };
    },

    computed: {
        removed() {
            return Object.keys(this.values).length > 1;
        },
    },

    methods: {
        hasError(index) {
            return this.errors[index];
        },
        addField() {
            this.values = { ...this.values, [Object.keys(this.values).length]: '' };
        },
        removeField(index) {
            const values = Object.entries(this.values);
            values.splice(index, 1);
            this.values = Object.fromEntries(values);
        },
    },

    watch: {
        value: {
            immediate: true,
            handler(newValue) {
                this.values = newValue.length ? { ...newValue } : { 0: '' };
            },
        },
        values: {
            deep: true,
            handler(newValue) {
                const values = Object.values(newValue);
                if (!isEqual(this.value, values)) {
                    this.$emit('change-value', values);
                    this.$emit('update-field', {
                        name: this.name,
                        group: this.group,
                        value: newValue,
                    });
                }
            },
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.array-field {
    &__label {
        &.-has-errors {
            color: red;
        }
    }

    .-has-errors {
        fieldset {
            border-color: red !important;
        }
    }

    .heading {
        margin-bottom: 8px;
    }
    &__list {
        &-item {
            display: flex;
            align-items: center;
            margin-bottom: $spacer * 5;
            flex-wrap: wrap;

            &:last-of-type {
                margin-bottom: 0;
            }
            .form-error {
                width: 100%;
                margin-top: 10px;
                color: var(--v-warning-base);
            }
        }
    }

    &__field {
        flex: auto;

        &-btn-remove {
            margin-left: $spacer * 2;
        }
    }

    &__btn-add {
        margin-top: $spacer;
    }
}
</style>
