import { SectionFixtureType } from '@/entities/section/fixtures';
import { createLayoutVariation } from '@/entities/section/fixtures/_base';

const itemStyleVariations = {
    style1: 'style1',
};

const itemsFixture = [
    {
        rating: 4,
        text:
            'We have been working with the company for quite some time. No complaints, only positive impressions. We will continue cooperation.',
        author: 'Anna',
        avatar: 'ff282f07-6728-4a03-ac52-342d8e7c97cf',
    },
    {
        rating: 5,
        text:
            'Our company first approached us about six months ago with the task of developing a selling website that actually generates sales.',
        author: 'Sam',
        avatar: '16c94b57-f0e0-408b-aa6a-26935be9e3b2',
    },
    {
        rating: 5,
        text:
            'The results exceeded expectations: sales growth for the first quarter was +96%, and this was during the \u201clow season\u201d.',
        author: 'Nick',
        avatar: 'd7f6837e-a4c8-4e9f-b796-a454119614b3',
    },
];

const createItem = (args: { text: any; avatar: any; author: any; rating: any; template: string; position: number }) => {
    return {
        id: 'fixture',
        type: args.template,
        options: {
            content: {
                rating: args.rating,
                text: args.text,
                author: args.author,
                avatar: args.avatar,
            },
        },
        position: args.position,
    };
};

const createSectionFixture = (args: {
    preview: string;
    title: string;
    itemLayout: any;
    itemsTemplate: string;
    items: any[];
    options: any;
}): SectionFixtureType => {
    return {
        preview: args.preview,
        title: args.title,
        group: 'Testimonial',
        data: {
            id: 'fixture',
            items: args.items,
            required: false,
            fixed: false,
            type: 'SectionTestimonialBase',
            options: {
                ...args.options,
                itemsLayout: args.itemLayout,
                itemsStyle: {
                    template: args.itemsTemplate,
                    gap: 20,
                },
            },
        },
    };
};

const summary: any = {};

summary.style1__list_column_3_no_btn = createSectionFixture({
    title: 'Testimonial list with 3 columns, item style 1',
    preview: require('@/assets/images/sections/testimonials/testimonials-style1prev1.jpg'),
    options: {
        section: {
            width: 'full',
            padding: {
                desktop: { top: 28, right: 0, bottom: 28, left: 0 },
                tablet: { top: 28, right: 0, bottom: 28, left: 0 },
                mobile: { top: 16, right: 0, bottom: 16, left: 0 },
            },
            borderRadius: {
                desktop: 0,
                tablet: 0,
                mobile: 0,
            },
            background: 'none',
        },
        container: {
            width: 'content',
            padding: {
                desktop: null,
                tablet: null,
                mobile: null,
            },
            borderRadius: {
                desktop: 0,
                tablet: 0,
                mobile: 0,
            },
            background: 'none',
        },
        heading: {
            title: 'What do they think about us',
            colorTitle: null,
            note: null,
            colorNote: null,
            textAlign: 'center',
            marginBottom: {
                desktop: null,
                tablet: null,
                mobile: null,
            },
        },
        content: {
            htmlBefore: null,
            htmlAfter: null,
            colorText: null,
            listWidth: 'normal',
            useCarousel: 'no',
        },
        footer: {
            note: null,
            colorNote: null,
            textAlign: 'center',
            marginTop: {
                desktop: null,
                tablet: null,
                mobile: null,
            },
            button: {
                isShow: false,
                styles: 'primary',
                text: 'Button',
                textColor: null,
                bgColor: null,
                link: null,
                iconLeft: null,
                iconRight: null,
                type: 'Link',
                url: null,
            },
        },
    },
    itemLayout: {
        desktop: createLayoutVariation({ type: 'list', columns: 3 }),
        tablet: createLayoutVariation({ type: 'list', columns: 2 }),
        mobile: createLayoutVariation({ type: 'list', columns: 1 }),
    },
    itemsTemplate: itemStyleVariations.style1,
    items: (() => {
        const items: any[] = [];
        for (let i = 0; i < 3; i++) {
            items.push(
                createItem({
                    template: itemStyleVariations.style1,
                    position: i,
                    ...itemsFixture[i],
                })
            );
        }
        return items;
    })(),
});

summary.style1__list_column_3 = createSectionFixture({
    title: 'Testimonial list with 3 columns and button, item style 1',
    preview: require('@/assets/images/sections/testimonials/testimonials-style1prev2.jpg'),
    options: {
        section: {
            width: 'full',
            padding: {
                desktop: { top: 28, right: 0, bottom: 28, left: 0 },
                tablet: { top: 28, right: 0, bottom: 28, left: 0 },
                mobile: { top: 16, right: 0, bottom: 16, left: 0 },
            },
            borderRadius: {
                desktop: 0,
                tablet: 0,
                mobile: 0,
            },
            background: 'middle',
        },
        container: {
            width: 'content',
            padding: {
                desktop: null,
                tablet: null,
                mobile: null,
            },
            borderRadius: {
                desktop: 0,
                tablet: 0,
                mobile: 0,
            },
            background: 'none',
        },
        heading: {
            title: 'What do they think about us',
            colorTitle: null,
            note: null,
            colorNote: null,
            textAlign: 'center',
            marginBottom: {
                desktop: null,
                tablet: null,
                mobile: null,
            },
        },
        content: {
            htmlBefore: null,
            htmlAfter: null,
            colorText: null,
            listWidth: 'normal',
            useCarousel: 'no',
        },
        footer: {
            note: null,
            colorNote: null,
            textAlign: 'center',
            marginTop: {
                desktop: null,
                tablet: null,
                mobile: null,
            },
            button: {
                isShow: true,
                styles: 'primary',
                text: 'Leave feedback',
                textColor: null,
                bgColor: null,
                link: null,
                iconLeft: null,
                iconRight: 'arrow_forward_ios',
            },
        },
    },
    itemLayout: {
        desktop: createLayoutVariation({ type: 'list', columns: 3 }),
        tablet: createLayoutVariation({ type: 'list', columns: 2 }),
        mobile: createLayoutVariation({ type: 'list', columns: 1 }),
    },
    itemsTemplate: itemStyleVariations.style1,
    items: (() => {
        const items: any[] = [];
        for (let i = 0; i < 3; i++) {
            items.push(
                createItem({
                    template: itemStyleVariations.style1,
                    position: i,
                    ...itemsFixture[i],
                })
            );
        }
        return items;
    })(),
});

export default summary;
