import { uuid } from 'vue-uuid';

/**
 * Класс экземпляра значения опции товаров
 */
export default class ProductOptionEntryValue {
    /**
     *
     * @param {Object}  data - объект данных значения опции
     */
    constructor({ id = uuid.v4(), optionValueId = '', position = null, title = '' } = {}) {
        this.id = id;
        this.optionValueId = optionValueId;
        this.position = position;
        this.title = title;
    }

    get data() {
        return {
            id: this.id,
            optionValueId: this.optionValueId,
            position: this.position,
            title: this.title,
        };
    }
}
